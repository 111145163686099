/* eslint-disable no-useless-constructor */
/**
 * create by Taobao dev team 18/1/2018
 */
import React, {Component} from 'react'
import Dropzone from "react-dropzone"
import {fromJS} from "immutable";
class ReactDropzone extends Component {

    static defaultProps = {
        maxLength: 10,
        inputProps:{}
    };

    constructor(props) {
        super(props);
    }


    /**
     * thêm ảnh
     * @param files
     */
    onDrop = (files) => {
        let stateFiles =fromJS(this.props.files).toJS();
        stateFiles= [...stateFiles,...files];
        if (stateFiles.length <= this.props.maxLength) {
            if (this.props.addFiles) {
                this.props.addFiles(stateFiles);
            }
        }

    };

    /**
     * xóa ảnh theo vị trí
     * @param index
     */
    removeImage = (index) => {
        let stateFiles =fromJS(this.props.files).toJS();
        stateFiles.splice(index, 1);
        if (this.props.addFiles) {
            this.props.addFiles(stateFiles);
        }
    };

    render() {
        // console.log(this.state.files);
        return (
            <section>
                <div className="dropzone">
                    {
                        this.props.files&&this.props.files.length < this.props.maxLength &&
                        <Dropzone
                            // accept={'image/*'}
                            className={this.props.className?this.props.className:''}
                            onDrop={this.onDrop}
                            inputProps={this.props.inputProps}
                            multiple={!this.props.single}
                        >
                            <i className="la la-plus txt-size-h1"/>
                        </Dropzone>
                    }
                    {
                        this.props.files&&this.props.files.map((item, index) => <div key={index} className={'dropped-file mgr5'} style={{backgroundImage: `url(${item.preview})`}}>
                            <span className={'remove-image-btn cursor-pointer'} onClick={() => this.removeImage(index)}>
                                <i className="la la-times-circle"/>
                            </span>
                        </div>)
                    }
                </div>
            </section>
        )
    }
}


export default ReactDropzone;
