import { useQuery } from 'react-query'
import TaobaoService from '../services/taobao.service'
import OrderTaobao from '../types/TaobaoOrder'

const usePeerPayment = (orderList: OrderTaobao[], filter: any) => {
   
    let codes: Array<string> = []
   
    orderList?.forEach((item: OrderTaobao) => {
        if (item.status !== 'PENDING') {
            codes.push(`orderCodes=${item?.code}`)
        }
    })
    
    let queryString = codes?.join('&')

    const getPeerPayment = async () => {
        return new Promise((resolve, reject) => {
            TaobaoService.getPeerPaymentCode(queryString, (error: any, response: any, headers: any) => {
                if (error) {
                    reject(error)
                } else {
                    resolve({ data: response })
                }
            })
        })
    }

    const peerPaymentQuery = useQuery(['getPeerPayment', orderList], () => getPeerPayment(), {
        enabled: orderList?.length > 0 && filter.statuses !== 'PENDING',
        retry: 0,
    })

    return {
        ...peerPaymentQuery,
        data: peerPaymentQuery.data,
    }
}

export default usePeerPayment
