/* eslint-disable array-callback-return */
import React, {Fragment} from 'react';
import {Avatar, Button, Col, Input, List, notification, Row, Tooltip, Typography} from 'antd';
import * as AntdIcon from "@ant-design/icons"

import {Link} from 'react-router-dom';
import _ from 'lodash';
import {checkIsLink, linkify, moneyCeil, moneyFormat, quantityFormat} from "../../utils/stringUtils"

import {LocalStore} from "../../utils/LocalStore";
import ExportExcel from "../ExportExcel";
import OrderService from "../../services/orders.service";

const { TextArea } = Input;

const pageSize = 25;
class TabProduct extends React.Component{
    constructor(props){
        super(props);
        this.state={
            targetCurrency: 'VND',
            dataList: Array.isArray(props.dataSource) ? props.dataSource.slice(0, pageSize) : []
        }
    }

    componentDidMount() {
        const {dataSource} = this.props;
        this.setState({
            originData: _.cloneDeep(dataSource)
        });
        if (!LocalStore.getInstance().read('cartLanguage')) {
            this.setState({cartLanguage: 'VN'}, () => {
                LocalStore.getInstance().save('cartLanguage', this.state.cartLanguage);
            })
        } else {
            this.setState({cartLanguage: LocalStore.getInstance().read('cartLanguage')});
        }

        this.getProductsActivities();
    }

    componentDidUpdate (prevProps) {
        const {dataSource} = this.props;

        if (!_.isEqual(prevProps.dataSource, dataSource)) {
            const {isLoadMore} = this.state;
            this.setState({
                dataList: isLoadMore ? dataSource : dataSource.slice(0, pageSize),
                originData: _.cloneDeep(dataSource)
            })
        }
    }

    confirmProduct = (productCode, body) => {
        const {orderDetail, getListProductOrder, t} = this.props;
        if (!orderDetail) return;
        if (this.state[`${productCode}confirming`]) return;
        this.setState({[`${productCode}confirming`]: true}, () => {
            OrderService.confirmProduct(orderDetail.code, productCode, body,(err) => {
                if (!err) {
                    this.setState({
                        [`${productCode}confirming`]: false,
                    });
                    if (getListProductOrder) getListProductOrder()
                } else {
                    if (err.title === 'product_was_confirmed') {
                        notification.error({
                            message: t('log_order.product_was_confirmed')
                        });
                    } else {
                        notification.error({message: t(err.message)})
                    }
                    this.setState({[`${productCode}confirming`]: false});
                }
            });
        });
    };

    getProductsActivities = () => {
        const {orderDetail} = this.props;
        if (!orderDetail) return;
        this.setState({initLoading: true, orderLogErrors: null, logs: []}, () => {
            OrderService.getProductsActivities(orderDetail.code,(err, res) => {
                if (!err) {
                    this.setState({
                        initLoading: false,
                        logs: this.parseLog(res),
                    });
                } else {
                    this.setState({initLoading: false, orderLogErrors: err});
                }
            })
        })
    };

    parseLog = (items) => {
        const {t} = this.props;
        let logs = [];

        if (!Array.isArray(items)) return [];

        items.filter(x => x.activity === 'ORDER_PRODUCT_UPDATE').map(item => {
            let itemTransform = {};
            itemTransform.fullname = item.actor.fullname ? item.actor.fullname : '---';
            itemTransform.timestamp = item.timestamp ? item.timestamp : '---';
            itemTransform.role = item.role;

            if (item.data.length > 0) {
                // purchasedQuantity, receivedQuantity,
                if (Array.isArray(item.data)) itemTransform.logs = [];
                item.data.forEach((ele) => {
                    if (ele.property === 'purchasedQuantity') {
                        itemTransform.logs.push({
                            fullname: item.actor.fullname ? item.actor.fullname : '---',
                            role: item.role,
                            timestamp: item.timestamp ? item.timestamp : '---',
                            name: item.reference.code,
                            property: ele.property,
                            oldValue: ele.oldValue ? quantityFormat(ele.oldValue) : ele.oldValue === 0 ? t('log_product.out_of_stock') : t('log_product.not_buy'),
                            newValue: ele.newValue === null ? t('log_product.not_buy') : ele.newValue.toString() === (0).toString() ? t('log_product.out_of_stock') : quantityFormat(ele.newValue),
                            originNewValue: ele.newValue
                        })
                    } else if (ele.property === "actualPrice") {
                        itemTransform.logs.push({
                            fullname: item.actor.fullname ? item.actor.fullname : '---',
                            role: item.role,
                            name: item.reference.code,
                            timestamp: item.timestamp ? item.timestamp : '---',
                            property: ele.property,
                            oldValue: moneyFormat(ele.oldValue, item.reference.currency),
                            newValue: moneyFormat(ele.newValue, item.reference.currency),
                            originNewValue: ele.newValue
                        })
                    }
                });
            }


            if (itemTransform.logs && itemTransform.logs.length > 0)
                logs.push(...itemTransform.logs);
            else if (itemTransform.property)
                logs.push(itemTransform);
        });

        return logs;
    };

    // shouldComponentUpdate (nextProps) {
    //     const {dataSource} = this.props;
    //     return JSON.stringify(nextProps.dataSource) !== JSON.stringify(dataSource);
    // }

    /**
     * input change event
     * @param value
     * @param prop
     * @param item
     */
    onInputChange = (value, prop, item) => {
        let {dataList} = this.state;
        // lấy ra index của item đang thao tác
        let index = dataList.findIndex(x => x.id === item.id);
        dataList[index][prop] = value;
        this.setState({dataList})
    };

    /**
     * update ghi chú sản phẩm khi Ctrl + Enter
     * @param e
     * @param prop
     * @param item
     * @return {*}
     */
    onKeyDown = (e, prop, item) => {
        if (e.target.value !== '' && e.target.value.trim() === '') return;
        let {dataList, originData} = this.state;

        // lấy ra index của item đang thao tác
        let index = dataList.findIndex(x => x.id === item.id);

        if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
            if (dataList[index][prop] !== originData[index][prop]) {
                this.props.updateCustomerOrderProduct(item, () => {
                    originData[index] = _.cloneDeep(item);
                    if (prop === 'remark') {
                        this.setState({
                            ['showRemarkProduct'+item.id]: false,
                            originData
                        })
                    } else {
                        this.setState({
                            ['showNoteDetail'+item.id]: false,
                            ['showNoteProduct'+ item.id]: false,
                            originData
                        })
                    }
                });
            } else {
                if (prop === 'remark') {
                    this.setState({['showRemarkProduct'+item.id]: false});
                } else if (prop === 'note') {
                    this.setState({['showNoteProduct'+item.id]: false});
                    if (e.target.value === '') {
                        this.setState({['showNoteDetail'+item.id]: false})
                    }
                }
            }
        }
    };

    /**
     * Call func submit note order if click outside
     * @param value
     * @param prop
     * @param item
     * @return {*}
     */
    onBlurInput = (value, prop, item) => {
        if (value !== '' && value.trim() === '') return;
        let {dataList, originData} = this.state;

        //lấy ra index của item đang thao tác
        let index = dataList.findIndex(x => x.id === item.id);
        if (dataList[index][prop] !== originData[index][prop]) {
            this.props.updateCustomerOrderProduct(item, () => {
                originData[index] = _.cloneDeep(item);
                if (prop === 'remark') {
                    this.setState({
                        ['showRemarkProduct'+item.id]: false,
                        originData
                    })
                } else {
                    this.setState({
                        ['showNoteDetail'+item.id]: false,
                        ['showNoteProduct'+ item.id]: false,
                        originData
                    })
                }
            });
        } else {
            if (prop === 'remark') {
                this.setState({['showRemarkProduct'+item.id]: false});
            } else if (prop === 'note') {
                this.setState({['showNoteProduct'+item.id]: false});
                if (value === '') {
                    this.setState({['showNoteDetail'+item.id]: false})
                }
            }
        }
    };

    /**
     * Change Language
     */
    onChangeLanguage = () => {
        const {cartLanguage} = this.state;
        this.setState({cartLanguage: cartLanguage === 'VN' ? 'CN' : 'VN'}, () => {
            LocalStore.getInstance().save('cartLanguage', this.state.cartLanguage);
        });
    };

    formatLogsContent = (data) => {
        if (!data) return '';
        const {t} = this.props;
        return `${t(`log_order.${data.property}`, data)}`;
    };

    /**
     * render header of list
     * @returns {*}
     */
    renderHeaderList = () => {
        const {exportExcel, orderDetail, t, onExport} = this.props;
        const hasInspection = orderDetail && Array.isArray(orderDetail.services) ? orderDetail.services.find(x => x.code === 'inspection') : null;

        let totalQuanty = orderDetail ? orderDetail.orderedQuantity : null;
        let purchasedQuantity = orderDetail ? orderDetail.purchasedQuantity : null;
        let receivedQuantity = orderDetail ? orderDetail.receivedQuantity : null;

        return (
            <div className={'headerlist pdl15 pdr15 pdt5 pdbt5 bd1px bd-color-gray bg-color-gray'}>
                <Row className={'headerlist__title txt-color-black'}>
                    <Col span={10} className="pdt5">
                        <span className='txt-size-h8 robotoregular txt-color-black'>
                        {t('order.products')}
                        </span>
                        {/*<span className="txt-size-h8 txt-color-blue mgr5 mgl5">|</span> */}
                        {/*<span className="txt-color-blue txt-size-h8 robotoregular cursor-pointer" onClick={this.onChangeLanguage}>{t('order.change_language')}</span>*/}
                    </Col>
                    <Col span={14}>
                        <Row>
                            <Col span={6} className="txt-size-h8 robotoregular txt-color-black pdt7">
                                {quantityFormat(totalQuanty)}/{quantityFormat(purchasedQuantity)}{hasInspection ? `/${quantityFormat(receivedQuantity)}` : ''} <Tooltip placement="top" title={t('order.order_buy_receive')}>
                                <AntdIcon.QuestionCircleOutlined type="question-circle" theme="filled"  className={'txt-size-h8 txt-color-gray opacity6'}/>
                            </Tooltip>
                            </Col>
                            <Col span={6} className="txt-size-h8 robotoregular txt-color-black pdt7">
                                {t('order.sale_price')}
                            </Col>
                            <Col span={6} className="txt-size-h8 robotoregular txt-color-black pdt7">
                                {t('order.total_price')}
                            </Col>
                            <Col span={6} className="txt-right">
                                <ExportExcel
                                    {...this.props}
                                    small={true}
                                    onExport={onExport}
                                    exportExcel={exportExcel}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    };


    /**
     * render item of list
     * @param item
     * @returns {*}
     */
    renderListItem = (item) => {
        const {isLoadingCard, addItemWishlist, orderDetail, t} = this.props;
        const {cartLanguage, itemsSaved = [], logs = []} = this.state;

        const hrefCreate = `/tickets/create?orderCode=${orderDetail.code}&productCode=${item.code}`;

        let currentLog = _.sortBy(logs.filter(x => x.name === item.code), 'timestamp').reverse()[0] || {};
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        const tenantConfig = currentProjectInfo.tenantConfig || {};
        const orderConfig = tenantConfig.orderConfig || {};
        // const hasInspection = listFees.find(x => x.service === 'inspection');
        const hasInspection = orderDetail && Array.isArray(orderDetail.services) ? orderDetail.services.find(x => x.code === 'inspection') : null;

        return (
            <React.Fragment>
                {
                    item.needToConfirm
                        ?
                        <List.Item className="_product-confirm-row list-alert-warning pdl15 pdr15 dpl-flex justify-content-between width-100-percent">
                            <div className="txt-warning warning-row pdr15">
                                {
                                    <div className='dpl-flex align-items-center'>
                                        <AntdIcon.ExclamationCircleOutlined type="exclamation-circle" className={'pdr5 line-height134'} />
                                        <div
                                            className={`whitespace-pre-wrap break-word line-height134`}
                                            dangerouslySetInnerHTML={{__html: this.formatLogsContent(currentLog)}}
                                        />
                                    </div>
                                }
                            </div>
                            <div className="flex-none dpl-flex">
                                {
                                    this.state[`${item.code}confirming`] ?
                                        <AntdIcon.Loading3QuartersOutlined style={{paddingTop: 3, paddingBottom: 3}} className="txt-size-h4" type="loading-3-quarters" spin={true}/>
                                        :
                                        <>
                                            <Button key={'true'} onClick={() => this.confirmProduct(item.code, {confirm: true, newValue: currentLog.originNewValue, property: currentLog.property})} type='primary' ghost={true} size={'small'} className='mgr15'>
                                                {t('button.accept')}
                                            </Button>
                                            <Button key={'false'} onClick={() => this.confirmProduct(item.code, {confirm: false, newValue: currentLog.originNewValue, property: currentLog.property})} type='default' size={'small'} className=''>
                                                {t('button.reject')}
                                            </Button>
                                        </>
                                }
                            </div>
                        </List.Item>
                        :
                        null
                }
                <List.Item className={`_cart-item-${item.id} tabdetailorder__items position-re bg-color-gray bd1px bd-color-gray mgbt15 pd15`}>
                    <Col span={10} className="dpl-flex align-items-start ">
                        <div className="pdr5 flex-none">
                            {
                                item && item.productUrl ?
                                    <a href={item.productUrl} target={'_blank'} rel="noreferrer">
                                        <Avatar
                                            className={`_cart-item-avatar-${item.id} cartitemavatar`}
                                            shape="square"
                                            size={58}
                                            src={item.variantImage}
                                        />
                                    </a>
                                    :
                                    <Avatar
                                        className={`_cart-item-avatar-${item.id}`}
                                        shape="square"
                                        size={60}
                                        src={item.variantImage}
                                    />
                            }
                            <p className="txt-size-h8 txt-color-black robotoregular whitespace-initial wordbreakall mgt5">#{item.code}</p>
                        </div>

                        <div className='pdr10 pdl5'>
                            {
                                item && item.productUrl ?
                                    <a href={item.productUrl} target={'_blank'} rel="noreferrer">
                                        <h3 className={`_cart-item-name-${item.id} txt-size-h7 txt-color-black break-word`}>{cartLanguage === 'CN' ? item.originalName : item.name}</h3>
                                    </a>
                                    :
                                    <h3 className={`_cart-item-name-${item.id} txt-size-h7 txt-color-black break-word`}>{cartLanguage === 'CN' ? item.originalName : item.name}</h3>
                            }
                            {Array.isArray(item.variantProperties) && item.variantProperties.map((x, y) => {
                                    // let maxIndex = item.variantProperties.lastIndexOf(item.variantProperties.slice(-1)[0]);
                                    return (
                                        <span key={y} className={`_cart-item-properties-${item.id}-${y} txt-size-h8 txt-color-gray ${y !== 0 ? 'pdl10' : ''}`}>{cartLanguage === 'CN' ? x.originalValue :  x.value}</span>
                                    )
                                }
                            )}
                            {/*{item.variantProperties.map((x, y) => <div key={y} className={`_cart-item-properties-${item.id}-${y} txt-size-h8 txt-color-gray`}>{x.name}: {x.value}</div>)}*/}
                        </div>
                    </Col>
                    <Col span={14}>
                        <Row>
                            <Col span={6} className={'position-re'}>
                                <span className="txt-color-black robotobold txt-size-h7 ">
                                    {quantityFormat(item.quantity)}/{quantityFormat(item.purchasedQuantity)}{hasInspection ? `/${quantityFormat(item.receivedQuantity)}` : ''}
                                </span>
                            </Col>
                            <Col span={6}>
                                <h3 className={`_cart-item-price-${item.id} txt-size-h7 txt-color-black break-word pdr5`}>{moneyFormat(moneyCeil(item.exchangedActualPrice))}</h3>
                                <span className={`_cart-item-price-origin-${item.id} txt-size-h8 break-word pdr5`}>
                                    {item.noBargainPrice ? (
                                        <>
                                            <strike className={'txt-color-gray'}>{moneyFormat(item.noBargainPrice, item.currency && item.currency.code)} </strike> / <b>{moneyFormat(item.actualPrice, item.currency && item.currency.code)}</b>
                                        </>
                                    ) : (
                                        <span className={'txt-color-gray'}>
                                            {moneyFormat(item.actualPrice, item.currency && item.currency.code)}
                                        </span>
                                    )}
                                </span>
                            </Col>
                            <Col span={6}>
                                <h3 className={`_cart-item-totalPrice-${item.id} txt-size-h7 txt-color-black break-word`}>{moneyFormat(moneyCeil(item.exchangedTotalAmount))}</h3>
                                <span className={`_cart-item-totalPrice-origin-${item.id} txt-size-h8 txt-color-gray break-word`}>{moneyFormat(item.totalAmount, item.currency && item.currency.code)}</span>
                            </Col>
                            <Col span={6}>
                                <div
                                    onClick={() => addItemWishlist(item.id, () => this.setState({itemsSaved: [...itemsSaved, item.id]}))}
                                    className={'cursor-pointer txt-size-h8 robotoregular txt-right pdr10'}
                                >
                                    <Typography.Link>
                                    {
                                        itemsSaved.indexOf(item.id) > -1 ? <AntdIcon.HeartOutlined type="heart" theme="filled" twoToneColor="#1890ff" className={'txt-size-h7'}/> : <AntdIcon.HeartOutlined type="heart" className={'txt-size-h7'}/>
                                    } {t('button.save')}
                                    </Typography.Link>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {
                                orderConfig.productRemarkEnabled ? <Col span={24} className={'mgt10 mgbt10'}>
                                    <span className="robotoregular txt-size-h8" style={{display: 'inline'}}>
                                        <Typography.Link>{t('order.remark')}:</Typography.Link>
                                    </span>
                                    {
                                        checkIsLink(item.remark)
                                            ?
                                            <div
                                                className="txt-color-gray txt-size-h8 robotoregular white-space-pre-wrap break-word pdl5 mgr10"
                                                style={{display: 'inline'}}
                                                dangerouslySetInnerHTML={{__html: linkify(item.remark)}}
                                            />
                                            :
                                            <div
                                                className="txt-color-gray txt-size-h8 robotoregular white-space-pre-wrap break-word pdl5 mgr10"
                                                style={{display: 'inline'}}
                                            >{item.remark}</div>
                                    }
                                </Col>
                                    :
                                    <div className={'mgt10'}/>
                            }


                            <Col span={24}>
                                {
                                    this.state['showNoteDetail'+item.id]
                                    ?
                                        <Fragment>
                                            <TextArea
                                                value={item.note}
                                                maxLength={1000}
                                                autosize={{ minRows: 1, maxRows: 3 }}
                                                className={`_orderDetail-product-note-${item.id}`}
                                                placeholder={t('order.note')}
                                                onChange={(e) => this.onInputChange(e.target.value, 'note', item)}
                                                onKeyDown={(e) => this.onKeyDown(e, 'note', item)}
                                                onBlur={(e) => this.onBlurInput(e.target.value, 'note', item)}
                                                disabled={isLoadingCard}
                                                ref={ref => this[`noteProduct${item.id}`] = ref}
                                            />
                                            {item.note === '' && (
                                                <span className={`txt-size-h8 txt-warning dpl-block pdt5`}>
                                                    {t('message.warning_empty')}
                                                </span>
                                            )}
                                            <span className={`txt-size-h8 txt-color-gray dpl-block`}>
                                                {t('order.note_keydown')}
                                            </span>
                                        </Fragment>
                                    :
                                        this.state['showNoteProduct'+item.id] === true
                                        ?
                                            <Fragment>
                                                <TextArea
                                                    value={item.note}
                                                    maxLength={1000}
                                                    autosize={{ minRows: 1, maxRows: 3 }}
                                                    className={`_cart-item-user-note-${item.id}`}
                                                    placeholder={t('order.note')}
                                                    onChange={(e) => this.onInputChange(e.target.value, 'note', item)}
                                                    onKeyDown={(e) => this.onKeyDown(e, 'note', item)}
                                                    onBlur={(e) => this.onBlurInput(e.target.value, 'note', item)}
                                                    disabled={isLoadingCard}
                                                    ref={ref => this[`noteProduct${item.id}`] = ref}
                                                />
                                                {item.note === '' && (
                                                    <span className={`txt-size-h8 txt-warning dpl-block pdt5`}>
                                                    {t('message.warning_empty')}
                                                </span>
                                                )}
                                                <span className={`txt-size-h8 txt-color-gray dpl-block`}>
                                                    {t('order.note_keydown')}
                                                </span>
                                            </Fragment>
                                        :
                                            item.note
                                            ?
                                                <Fragment>
                                                    <span className="txt-size-h8 robotoregular" style={{display: 'inline'}}>{t('orderDetail.note_product')}:</span>
                                                    {
                                                        checkIsLink(item.note)
                                                            ?
                                                            <div
                                                                className="txt-color-gray txt-size-h8 robotoregular white-space-pre-wrap pdl5 break-word mgr5"
                                                                style={{display: 'inline'}}
                                                                dangerouslySetInnerHTML={{__html: linkify(item.note)}}
                                                            />
                                                            :
                                                            <div
                                                                className="txt-color-gray txt-size-h8 robotoregular white-space-pre-wrap pdl5 break-word mgr5"
                                                                style={{display: 'inline'}}
                                                            >{item.note}</div>
                                                    }
                                                    <AntdIcon.EditOutlined className="_btn-edit-note-sku txt-size-h7" type="edit" onClick={() =>
                                                        this.setState({['showNoteProduct'+ item.id]: !this.state['showNoteProduct'+ item.id]}, () => {
                                                            this[`noteProduct${item.id}`].focus();
                                                        })
                                                    } />
                                                </Fragment>
                                            :
                                                <span
                                                    className={`_orderDetail-item-showNoteDetail-btn-${item.id} txt-size-h8 robotoregular cursor-pointer`}
                                                    onClick={() =>
                                                        this.setState({['showNoteDetail'+item.id]: !this.state['showNoteDetail'+item.id]}, () => {
                                                            this[`noteProduct${item.id}`].focus();
                                                        })
                                                    }
                                                >
                                                    {t('order.note')}? <Tooltip placement="top" title={t('product_tab.personal_note_content')}>
                                                        <AntdIcon.QuestionCircleOutlined type="question-circle" theme="filled"  className={'txt-size-h8 opacity6'}/>
                                                    </Tooltip>
                                                </span>
                                }
                            </Col>

                        </Row>
                    </Col>
                    <Link
                        to={hrefCreate}
                        className="_btn-create-ticket-product btn-delete-cart-item btn__delete cursor-pointer"

                    >
                        <AntdIcon.FrownOutlined type="frown" />
                    </Link>
                </List.Item>
            </React.Fragment>
        )
    };

    /**
     * render loadmore button
     * @returns {null}
     */
    renderLoadMore = () => {
        const {dataSource, t} = this.props;
        const { initLoading, loading, isLoadMore } = this.state;

        return !initLoading && !loading && Array.isArray(dataSource) && dataSource.length > pageSize ?
            !isLoadMore
                ?
                <div style={{
                    textAlign: 'center', marginTop: 12, height: 32, lineHeight: '32px',
                }}
                >
                    <Button className={'_order-detail-btn-loadmore'} onClick={this.onLoadMore}>{t('button.loadmore')}</Button>
                </div>
                :
                <div style={{
                    textAlign: 'center', marginTop: 12, height: 32, lineHeight: '32px',
                }}
                >
                    <Button className={`_cart-btn-collapse-${this.props.index}`} onClick={this.onLoadMore}>{t('button.collapse')}</Button>
                </div>
            : null
    };

    /**
     * loadmore data
     */
    onLoadMore = () => {
        const {dataSource} = this.props;
        let {dataList} = this.state;
        let isLoadMore = false;
        this.setState({
            loading: true,
            initLoading: true
        }, () => {
            setTimeout(() => {
                if (dataList.length === dataSource.length) {
                    dataList = dataSource.slice(0, pageSize)
                } else {
                    dataList = dataSource;
                    isLoadMore = true
                }
                this.setState({
                    isLoadMore,
                    dataList,
                    loading: false,
                    initLoading: false
                })
            }, 500)
        });
    };

    render(){
        const { initLoading, dataList } = this.state;
        return(
            <React.Fragment>
                <List
                    className={'list-carts mgbt5 list-hover-item'}
                    // dataSource={dataSource}
                    dataSource={dataList}
                    header={this.renderHeaderList()}
                    renderItem={item => {
                        return (this.renderListItem(item))
                    }}
                    loading={initLoading}
                    loadMore={this.renderLoadMore()}
                />
            </React.Fragment>
        );
    }
}
export default TabProduct;
