import PolimClient from "../utils/api/PolimClient"
import { LocalStore } from "../utils/LocalStore";

export default class TaobaoService {
    static currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};

    static getListOrders(query, cb) {
        PolimClient.request({
            endpoint: `/customer/taobao/orders${query || ''}`,
            method: 'GET',
        }, cb)
    }

    static getListAccountTaobao(cb) {
        PolimClient.request({
            endpoint: `/customer/taobao/credential_accounts`,
            method: 'GET',
        }, cb)
    }

    static getProductsOrder(id, cb) {
        PolimClient.request({
            endpoint: `/customer/taobao/orders/${id}`,
            method: 'GET',
        }, cb)
    }

    static getCodeLogistics(code, cb) {
        PolimClient.request({
            endpoint: `/customer/taobao/orders/${code}/logistics`,
            method: 'GET',
        }, cb)
    }

    static cancelOrder(id, body, cb) {
        PolimClient.request({
            endpoint: `/customer/taobao/orders/${id}/cancel`,
            method: 'POST',
            body
        }, cb)
    }

    static getCredentialAccount(params, cb) {
        PolimClient.request({
            endpoint: `/customer/taobao/credential_accounts/info${params}`,
            method: 'GET',
        }, cb)
    }

    static getPeerPaymentCode(query, cb) {
        PolimClient.request({
            endpoint: `/customer/taobao/orders/peer_payment?${query || ''}`,
            method: 'GET',
        }, cb)
    }

    static getAccountAlias(query, cb) {
        PolimClient.request({
            endpoint: `/customer/configuration/purchasing_accounts?accounts=${query || ''}&marketplace=taobao`,
            method: 'GET',
        }, cb)
    }
}