import React from 'react'
import { Pagination, PaginationProps, Select } from 'antd'

interface CustomPaginationProps extends PaginationProps {
    onChange?: (page: number, pageSize?: number) => void
    pagination: any
    className?: string
}

const CustomPagination = (props: CustomPaginationProps) => {
    const { onChange, pagination: { current = 0, total = 0, pageSize = 25 }, pageSizeOptions = [25, 50, 100], className } = props

    return total > 0 ? (
        <div className={`flex items-center custom-pagination whitespace-nowrap ${className}`}>
            <div
                className={'mr-2 flex items-center font-normal text-main'}
                style={{ lineHeight: 1.675 }}>
                <span className={'mr-1'}>Trang </span>
                <span>
                    {current} / {Math.ceil(total / pageSize)}
                </span>
            </div>
            <Pagination
                {...props}
                {...props.pagination}
                onChange={(page: number) => onChange && onChange(page)}
                showSizeChanger={false}
            />
            {
                total > 0 &&
                <div className={'ml-2 flex items-center'}>
                    <Select
                        style={{ width: 125 }}
                        className={`w-full ant-pagination ant-pagination-options-size-changer `}
                        onChange={(size: number) => onChange && onChange(1, size)}
                        value={pageSize}
                        suffixIcon={<i className="fa-solid fa-angle-down fsz-14" />}>
                        {pageSizeOptions?.map((size: any) => {
                            return (
                                <Select.Option
                                    key={size}
                                    value={size}>
                                    {`${size} / trang`}
                                </Select.Option>
                            )
                        })}
                    </Select>
                </div>
            }
        </div>
    ) : (
        <></>
    )
}

export default CustomPagination
