import { useEffect, useState } from 'react';
import { Row, Col, Input, notification, Form, Button } from 'antd'
import Modal from '../../components/commons/Modal'
import {LocalStore} from "../../utils/LocalStore";
import lodash from 'lodash'
import CategoriesService from "../../services/categories.service";
import { formatNumber, formatAmountTyping, formatPrice } from '../../utils/stringUtils'
import FloatLabel from '../commons/Input/FloatLabel';
import UserService from "../../services/user.service";
import { moneyFormat } from "../../utils/stringUtils";
import InputItem from '../commons/Input';
import { Bank } from '../../types/RequestWithDrawal';
import SuperSelect from '../commons/Select/SuperSelect';
import {Account} from '../../types/RequestWithDrawal';

interface ModalProps {
    t: (text: string) => string,
    getWithdrawals: () => void,
    historyPush: (callback: Function) => void,
    getLisWithdrawalStatus: () => void,
    getListWithDrawalSlipStatistic: () => void,
    listBank: Array<Bank>
}

const CreateWithdrawPaymentModal = (props: ModalProps) => {
    const [form] = Form.useForm();
    const { t, listBank } = props;
    const [isShowModalAddRequest, setIsShowModalAddRequest] = useState<boolean>(false)
    const [balance, setBalance] = useState(0)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isDisabledButton, setIsDisabledButton] = useState<boolean>(false)
    const [selectedBank, setSelectedBank] = useState<string | null>('other')

    const [beneficiaryBank, setBeneficiaryBank] = useState("")
    const [beneficiaryBankBranch, setBeneficiaryBankBranch] = useState("")

    const [accountList, setAccountList] = useState<Account[]>([])
    const [accountSelected, setAccountSelected] = useState<string | null>(null)
    const amount = Form.useWatch('amount', form)
    const beneficiaryName = Form.useWatch('beneficiaryName', form)
    const beneficiaryAccount = Form.useWatch('beneficiaryAccount', form)
    const memo = Form.useWatch('memo', form)
    const defaultValues = {
        amount: balance,
        beneficiaryName: '',
        beneficiaryAccount: '',
        memo: ''
    };
    let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
    const tenantConfig = currentProjectInfo?.tenantConfig || {};
    
    useEffect(() => {
        if(tenantConfig?.diorConfig?.enabled) {
            getAccountByUsername();
        }
        getBalanceUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
    * lấy số dư tài khoản của khách hàng
    */
    const getBalanceUser = () => {
        UserService.getBalanceUser((err: any, res: any) => {
            if (!err) {
                setBalance(res ? formatPrice(moneyFormat(res.balance)) : 0)
            }
        })
    };

    useEffect(() => {
        validateSubmitButton(form.getFieldsValue())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [beneficiaryBank, form])

    const validateSubmitButton = (values: any) => {
        if (selectedBank === 'other' && beneficiaryBank === '') {
            setIsDisabledButton(true)
        } else {
            if (values && (values.beneficiaryName === '' || values.beneficiaryAccount === '' || lodash.isEmpty(values.amount) || values.amount === "0")) {
                setIsDisabledButton(true)
            } else {
                setIsDisabledButton(false)
            }
        }
    }

    const resetForm = () => {
        setBeneficiaryBank("");
        setBeneficiaryBankBranch("");
        form.resetFields(["amount", "beneficiaryName", "beneficiaryAccount", "memo"])
    }

    /**
     * lấy ra danh sách tài khoản theo username
     */
    const getAccountByUsername = () => {
        CategoriesService.getAccountByUsername((err: any, res: any, headers: any) => {
            if (!err) {
                setAccountList(res)
                if(res.length > 0 ) {
                    const defaultWalletBalance = formatPrice(moneyFormat(lodash.get(res, '0.balance'), lodash.get(res, '0.currency')))
                    setBalance(defaultWalletBalance)
                }
            }
        })
    };

    const onInputChange = (value: string, prop: string) => {
        if (prop === 'amount') {
            form.setFieldValue("amount", value)
        } else if (prop === 'beneficiaryName') {
            form.setFieldValue("beneficiaryName", value)
        } else if (prop === 'beneficiaryBank') {
            setBeneficiaryBank(value)
        } else if (prop === 'beneficiaryBankBranch') {
            setBeneficiaryBankBranch(value)
        } else if (prop === 'memo') {
            form.setFieldValue("memo", value)
        } else {
            form.setFieldValue("beneficiaryAccount", value)
        }
    };

    const onSubmit = () => {
        let data = {
            account: accountSelected ? accountSelected : lodash.get(accountList, '0.account'),
            amount: formatNumber(amount),
            beneficiaryName,
            beneficiaryAccount,
            beneficiaryBank: selectedBank,
            beneficiaryBankName: selectedBank === 'other' ? beneficiaryBank : listBank.find(b => b.code === selectedBank)?.name,
            beneficiaryBankBranch: beneficiaryBankBranch,
            memo
        }
        if(!tenantConfig?.diorConfig?.enabled) {
            delete data.account
        }
        setIsLoading(true);
        UserService.createNewWithdrawSlip(data, (err: any, res: any, headers: any) => {
            if (!err) {
                notification['success']({
                    message: t('message.success'),
                });
                setIsLoading(false);
                setIsShowModalAddRequest(false)
                props.getWithdrawals();
                props.historyPush(() => { })
                props.getLisWithdrawalStatus()
                props.getListWithDrawalSlipStatistic()
                resetForm();
            } else {
                setIsLoading(false);
                notification['error']({
                    message: t(err.message),
                });
            }
        })
    };

    const handleChangeRadio = (v: string) => {
        setSelectedBank(v);
    }

    const handleChangeWallet = (v: string) => {
        setAccountSelected(v);
        const accountSelected: object | undefined = accountList.find(x => x.code === v);
        if(accountSelected !== undefined) {
            const walletBalanceSelected = formatPrice(moneyFormat(lodash.get(accountSelected, 'balance'), lodash.get(accountSelected, 'currency')))
            setBalance(walletBalanceSelected)
            form.setFieldValue("amount", walletBalanceSelected)
        }
        
    }

    const renderModalAddRequest = () => {
        return <Modal
            title={t('request_withdraw.modal_title')}
            visible={isShowModalAddRequest}
            onCancel={() => {
                setIsShowModalAddRequest(false)
                resetForm()
            }}
            width={560}
            listAction={[
                <Button
                    className={"btn-cancel space-x-1.5 _btn-cancel-confirm mr-2"}
                    onClick={() => {
                        setIsShowModalAddRequest(false)
                        resetForm()
                    }}
                >
                    <i className="fa-regular fa-xmark"></i>
                    {t('button.cancel')}
                </Button>,
                <Button
                    type="primary"
                    htmlType="submit"
                    className="space-x-1.5 _btn-submit-confirm"
                    onClick={e => {
                        form
                            .validateFields()
                            .then((values) => {
                                resetForm();
                                onSubmit();
                            })
                    }}
                    disabled={(isLoading || isDisabledButton)}
                    loading={isLoading}
                >
                    <i className="fa-regular fa-check"></i>
                    {t('modal.confirm')}
                </Button>
            ]}
        >
            <Form
                form={form}
                layout="horizontal"
                name="form_in_modal"
                labelCol={{ span: 24 }}
                labelAlign={'left'}
                wrapperCol={{ span: 24 }}
                initialValues={defaultValues}
                onValuesChange={(changedValues, allValues) => {
                    validateSubmitButton(allValues);
                }}
            >
                <Row>
                    {tenantConfig?.diorConfig?.enabled && accountList.length > 1 && <Col span={24}>
                        <div className='form-select mb-4'>
                            <FloatLabel isRequired={true} label={'Chọn tài khoản nguồn'} value={accountSelected ? accountSelected : lodash.get(accountList, '0.account')}>
                                <SuperSelect
                                    onChange={(e: any) => handleChangeWallet(e)}
                                    className={"_select-bank w-full"}
                                    selectedValue={accountSelected ? accountSelected : lodash.get(accountList, '0.account')}
                                    options={accountList}
                                    renderOptionLabel={(item: any) => <>
                                        <span className={`_service-name txt-size-h8 mr-1 robotoregular txt-color-black`}>{item.account}</span>
                                    </>}
                                />
                            </FloatLabel>
                        </div>
                    </Col>}
                    <Col span={24}>
                        <div className='form-select'>
                            <FloatLabel isRequired={true} label={t('request_withdraw.select_bank')} value={selectedBank}>
                                <SuperSelect
                                    onChange={(e: any) => handleChangeRadio(e)}
                                    className={"_select-bank w-full"}
                                    selectedValue={selectedBank}
                                    options={listBank}
                                    renderOptionLabel={(item: any) => <>
                                        <span className={`_service-name txt-size-h8 mr-1 robotoregular txt-color-black`}>{item.name} ({item.code})</span>
                                    </>}
                                />
                            </FloatLabel>
                        </div>
                        {selectedBank === 'other' &&
                            <div className='mt-4'>
                                <InputItem
                                    isRequired
                                    id={"_bank-name-input"}
                                    className={`_bank-name-input width-100-percent`}
                                    title={t('request_withdraw.beneficiary_bank')}
                                    value={beneficiaryBank}
                                    onChange={(e: any) => {
                                        onInputChange(e.target.value, 'beneficiaryBank')
                                    }}
                                    allowClear
                                />
                            </div>
                        }
                    </Col>
                    <Col span={24} className="_container_beneficiary_account mt-4">
                        <Form.Item
                            className='_beneficiary_account'
                            name="beneficiaryAccount"
                            label={''}
                            rules={[
                                {
                                    required: true,
                                    message: t('customer_info.required'),
                                }
                            ]}
                        >
                            <InputItem
                                isRequired
                                title={t('request_withdraw.beneficiary_account')}
                                onChange={(e: any) => onInputChange(e.target.value, 'beneficiaryAccount')}
                                value={form.getFieldValue("beneficiaryAccount")} 
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="_container_beneficiary_name">
                        <Form.Item
                            className=' _withdraw-beneficiaryName'
                            name="beneficiaryName"
                            label={''}
                            rules={[
                                {
                                    required: true,
                                    message: t('customer_info.required'),
                                }
                            ]}
                        >
                            <InputItem
                                isRequired
                                title={t('request_withdraw.beneficiary_name')}
                                onChange={(e: any) => onInputChange(e.target.value, 'beneficiaryName')}
                                value={form.getFieldValue("beneficiaryName")} 
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="_container_amount">
                        <Form.Item
                            name="amount"
                            label={''}
                            rules={[
                                {
                                    required: true,
                                    message: t('customer_info.required'),
                                }
                            ]}
                            normalize={(value, prevValue) => {
                                let raw = formatAmountTyping(value, false);
                                console.log('raw', raw);
                                console.log('balance', formatAmountTyping(balance, false));
                                if (raw > formatAmountTyping(balance, false)) {
                                    notification['error']({
                                        message: t('request_withdraw.msg_limit_amount'),
                                    });
                                    raw = formatAmountTyping(prevValue, false);
                                }
                                return !lodash.isNil(value) ? formatPrice(raw) : value;
                            }}
                        >
                            <InputItem
                                id={"_amount-input"}
                                isRequired
                                className={`_withdraw_amount width-100-percent`}
                                title={t('request_withdraw.withdraw_amount')}
                                value={balance}
                                autoComplete={'off'}
                                allowClear
                            />
                        </Form.Item>
                    </Col>                    
                    <Col span={24} className="_container_memo">
                        <Form.Item
                            className=' _withdraw-memo'
                            name="memo"
                            label={''}
                        >
                            <div className='form-textarea'>
                                <FloatLabel label={t('request_withdraw.withdraw_description')} value={form.getFieldValue("memo")}>
                                    <Input.TextArea
                                        maxLength={1000}
                                        rows={2}
                                        value={form.getFieldValue("memo")}
                                        onChange={(e: any) => onInputChange(e.target.value, 'memo')}
                                    />
                                </FloatLabel>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    };

    return <div className="dpl-il-block mgl15 v2-layout">
        <Button
            className=' _btn-create-payment flex items-center'
            onClick={() => {
                setIsShowModalAddRequest(true)
                validateSubmitButton(defaultValues)
            }}
        >
            <i className="fa-light fa-plus text-[20px] mr-[6px]" />
            {t('request_withdraw.modal_title')}
        </Button>

        {isShowModalAddRequest && renderModalAddRequest()}
    </div>
}
export default CreateWithdrawPaymentModal
