import React from 'react'
import DocumentTitle from "react-document-title"
import {LocalStore} from "../../utils/LocalStore";
import {Button} from 'antd'
import {USER_STATUS} from "../../constants/config";
import {withNamespaces} from "react-i18next";

class Page403 extends React.Component {
    constructor (props) {
        super(props);

        this.state = {}
    }

    // logout = () => {
        //     UserServices.logout()
        // };

    logout = () => {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null;
        LocalStore.getInstance().save('loginSession', null);
        this.props.history.push({
            pathname: '/login'
        })
    };

    render() {

        const {t} = this.props;

        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        const currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {};

        return(
            <DocumentTitle title={`${currentLoggedUser.customerStatus === USER_STATUS.LOCKED ? t('register.locked_title') : t('register.not_active_title')} | ${currentProjectInfo ? currentProjectInfo.name : ''}`}>
                <div className="login-wrapper">
                    <div className={'page403-container'}>
                        <div className="page403-content bg-color-white align-items-center justify-content flex-direction-column pd30">
                            <img className="logo-image mgbt40" src={currentProjectInfo.tenantConfig ? currentProjectInfo.tenantConfig.logoStandard : ''} alt=''/>
                            {
                                currentLoggedUser.customerStatus === USER_STATUS.LOCKED
                                ?
                                <h1 className="bold txt-color-black mgbt10 txt-size-h2">
                                    {t('register.account_locked')}
                                </h1>
                                :
                                <h1 className="bold txt-color-black mgbt10 txt-size-h2">
                                    {t('register.account_not_active')}
                                </h1>
                            }
                            <div className="txt-size-h7 ">
                                {t('register.support_info')}
                            </div>
                            <div className="dpl-flex mgt40">
                                <Button onClick={this.logout} type="primary" shape="round" size={'large'} className="_btn-logout cursor-pointer custom-btn-large">
                                    {t('register.other_account')}
                                </Button>
                            </div>
                            {/*<img className="login-image pdt20 pdbt20" src={currentLoggedUser.customerStatus === USER_STATUS.LOCKED ? require('../../resources/images/img-xoa.png') : require('../../resources/images/img-chuakichhoat.png')}/>*/}
                        </div>
                        {/*<div className="txt-size-h8 mgt30 txt-color-gray txt-center">© 2019 - nhaphang.com - hệ thống nhập hàng TQ hàng đầu VN. Sử dụng hệ thống đăng nhập hỗ trợ bởi Authen.me</div>*/}
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default withNamespaces()(Page403);