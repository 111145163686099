export const TYPE_REASON_ORDER='ORDER';
export const TYPE_REASON_PRODUCT='PRODUCT';
export const TYPE_REASON_SHIPMENT='SHIPMENT';
export const TYPE_REASON_PRODUCT_SHIPMENT='SHIPMENT_PRODUCT';
export const TYPE_REASON_PRODUCT_ORDER='ORDER_PRODUCT';
export const TYPE_LIST='LIST';
export const TYPE_KANBAN='KANBAN';
export const TYPE_UNASSIGN='UnAssignUser';
export const FOMAT_FILE_ARR = [".ANI",".ANIM",".APNG",".ART",".BMP",".BPG",".BSAVE",".CAL",".CIN",".CPC"
    ,".CPT",".DDS",".DPX",".ECW",".EXR",".FITS",".FLIC",".FLIF",".FPX",".GIF",".HDRi",".HEVC",".ICER",".ICNS",
    ".ICO/CUR",".ICS",".ILBM",".JBIG",".JBIG2",".JNG", ".JPG",".JPEG",".JPEG-LS",".JPEG2000",".JPEG",".XR",".XT",".JPEG-HDR"
    ,".KRA",".MNG",".MIFF",".NRRD",".ORA",".PAM",".PBM",".PGM"
    ,".PPM",".PNM",".PCX",".PGF",".PICTOR",".PSD",".PSB",".PSP",".PNG", ".CSV", ".MP4"
    ,".QTVR",".RAS",".RGBE","LOGLUVTIFF",".SGI",".TGA",".TIFF",".TIFF/EP",".TIFF/IT",".UFO/UFP",".WBMP",".WEBP",".XBM",".XCF",".XPM"
    ,".XWD", ".DOC",".DOCX",".ODT",".PDF",".XLS",".XLSX",".ODS",".PPT",".PPTX"
    ,".TXT", ".7Z", ".RAR",".ZIP", ".GZIP", ".TAR", ".MP3"];

export const ORDER_DETAIL_TAB_PRODUCT="PRODUCT";
export const ORDER_DETAIL_TAB_FEES="FEES";
export const ORDER_DETAIL_TAB_PACKAGES="PACKAGES";
export const ORDER_DETAIL_TAB_TICKETS="TICKETS";
export const ORDER_DETAIL_TAB_FINANCIAL="FINANCIAL";
export const ORDER_DETAIL_TAB_HISTORY="HISTORY";
export const ORDER_DETAIL_TAB_LOG="LOG";
export const ORDER_DETAIL_TAB_CREDIT = "CREDIT"


export const SHIPMENT_DETAIL_TAB_FEES="FEES";
export const SHIPMENT_DETAIL_TAB_PACKAGES="PACKAGES";
export const SHIPMENT_DETAIL_TAB_WAYBILLS="WAYBILLS";
export const SHIPMENT_DETAIL_TAB_FINANCE="FINANCE";
export const SHIPMENT_DETAIL_TAB_LOG="LOG";
export const SHIPMENT_DETAIL_TAB_HISTORY="HISTORY";


export const WS_ON_CONNECT = "WS_ON_CONNECT";
export const WS_RECONNECT = "WS_RECONNECT";
export const WS_CHECK_CONNECT = "WS_CHECK_CONNECT";
export const SKIP_WAITING = "SKIP_WAITING";

export const RSOCKET_GET_TOTAL_UNREAD_NOTIFICATION = "RSOCKET_GET_TOTAL_UNREAD_NOTIFICATION";


export const USER_STATUS = {
    "ACTIVE": "ACTIVE",
    "LOCKED": "LOCKED",
    "BANNED": "BANNED",
    "INACTIVE": "INACTIVE"
};

export const MAX_UPLOAD_FILE_SIZE = 10000000 // 10MB
