import React from 'react';
import { Row, Col, Input , Form, Button} from 'antd';
import {withNamespaces} from "react-i18next";

class FormUpdatePIN extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            bodyData: {},
            error: {},
            errUpdate: this.props.errUpdate
        };
    }

    componentDidUpdate (prevProps) {
        const {errUpdate, t} = this.props;
        const {error = {}} = this.state;
        if (JSON.stringify(prevProps.errUpdate) !== JSON.stringify(errUpdate)) {
            this.setState({
                currentPIN: '',
                newPIN: '',
                rePIN: ''
            });

            error.currentPIN = t('customer_info.incorrect_pin')
            this.setState({
                error
            })
        }
    }

    onValidate = () => {
        let {bodyData = {}, error = {}} = this.state;

        const {t} = this.props;
        let check = 0;

        if (!bodyData.currentPIN) {
            error.currentPIN = t('customer_info.input_old_pin')
            check = check + 1
        } else {
            error.currentPIN = ''
        }

        if (!bodyData.newPIN) {
            error.newPIN = t('customer_info.input_new_pin')
            check = check + 1
        } else if (bodyData.newPIN.length < 4) {
            error.newPIN = t('customer_info.least_four_characters')
            check = check + 1
        } else if (!bodyData.newPIN.trim()) {
            error.newPIN = t('customer_info.not_space')
            check = check + 1
        } else if (bodyData.newPIN === bodyData.currentPIN) {
            error.newPIN = t('customer_info.coincident_pin')
            check = check + 1
        } else if (!/^[0-9]+$/i.test(bodyData.newPIN)) {
            error.newPIN = t('customer_info.numbers_required')
            check = check + 1
        } else {
            error.newPIN = ''
        }

        if (bodyData.rePIN !== bodyData.newPIN) {
            error.rePIN = t('customer_info.not_match_pin')
            check = check + 1
        } else {
            error.rePIN = ''
        }

        this.setState({error});
        if (check > 0) {
            return false
        }
        return true
    };

    onInputChange = (value, prop, cb) => {
        let bodyData = this.state.bodyData;

        bodyData[prop] = value;

        this.setState({
            bodyData
        }, () => {
            if (cb) cb()
        })
    };

    /**
     * Handle submit create a customer address
     * @param e
     */
    handleSubmit = (e) => {
        const {
            bodyData
        } = this.state;

        if (!this.onValidate()) {
            return
        }

        this.props.onSubmit(bodyData);
    };


    /**
     * Handle cancel update PIN
     * @param e
     */
    handleCancel = (e) => {
        this.setState({
            currentPIN: '',
            newPIN: '',
            rePIN: ''
        }, () => {
            this.props.togglePIN()
        });
    };

    render() {
        const {
            error = {},
            bodyData = {}
        } = this.state;
        const {t} = this.props;

        return (
            <Form className={'formChangePIN'} onSubmit={this.handleSubmit}>
                <Row>
                    <Col md={12} className={'pdr5'}>
                        <Row>
                            <Col md={24} className={'pdr5 _oldPinContainer'}>
                                <h5 className='_oldPin txt-size-h7 txt-color-black robotoregular'>{t('customer_info.old_pin')}<span
                                    className={'txt-color-red mgl5'}>*</span></h5>
                                <Form.Item>
                                    <Input.Password
                                        className="_currentPINValue"
                                        placeholder={t('customer_info.input_old_pin')}
                                        value={bodyData.currentPIN}
                                        onChange={(e) => this.onInputChange(e.target.value, 'currentPIN')}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                                this.handleSubmit()
                                            }
                                        }}
                                    />
                                    {
                                        error.currentPIN &&
                                        <div className="pdt3 texterror position-ab txt-size-h8 txt-color-red2 robotoregular">
                                            {error.currentPIN}
                                        </div>
                                    }
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={24} className={'pdr5 _newPinContainer'}>
                                <h5 className="_newPin txt-size-h7 txt-color-black robotoregular">{t('customer_info.new_pin')}<span
                                    className={'txt-color-red mgl5'}>*</span></h5>
                                <Form.Item>
                                    <Input.Password
                                        className="_newPinValue"
                                        placeholder={t('customer_info.input_new_pin')}
                                        value={bodyData.newPIN}
                                        onChange={(e) => this.onInputChange(e.target.value, 'newPIN')}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                                this.handleSubmit()
                                            }
                                        }}
                                    />
                                    {
                                        error.newPIN &&
                                        <div className="pdt3 texterror position-ab txt-size-h8 txt-color-red2 robotoregular">
                                        {error.newPIN}
                                        </div>
                                    }
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={24} className={'pdr5 _rePinContainer'}>
                                <h5 className="_rePin txt-size-h7 txt-color-black robotoregular">{t('customer_info.retype_pin')}<span
                                    className={'txt-color-red mgl5'}>*</span></h5>
                                <Form.Item>
                                    <Input.Password
                                        className="_rePinValue"
                                        placeholder={t('customer_info.please_retype_pin')}
                                        value={bodyData.rePIN}
                                        onChange={(e) => this.onInputChange(e.target.value, 'rePIN')}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                                this.handleSubmit()
                                            }
                                        }}
                                    />
                                    {
                                        error.rePIN &&
                                        <div className="pdt3 texterror position-ab txt-size-h8 txt-color-red2 robotoregular">
                                        {error.rePIN}
                                        </div>
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Button className='mgr10 _btn-cancel-PIN' onClick={this.handleCancel}>{t('button.cancel')}</Button>
                                <Button type="primary" className='_btn-submit-PIN' onClick={this.handleSubmit}>{t('button.save')}</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12} className={'pdr5'}>
                        <div className='txt-center mgbt20 '>
                            <img src={require('../../resources/images/img-pin.png')} alt="" />
                        </div>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default withNamespaces() (FormUpdatePIN)
