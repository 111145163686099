/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import MainLayout from '../../../components/Layout/MainLayout'
import { Button, Checkbox, notification } from 'antd'
import Step1 from './Step1/Step1'
import Step2 from './Step2/Step2'
import { cloneDeep, first, flattenDeep, get, groupBy, isEmpty, keys, set, sumBy } from 'lodash'
import cartTaobaoGlobal from '../../../services/cartTaobaoGlobal.service'
import { parseQueryStringToObject } from '../../../utils/stringUtils'
import { formatCurrencyChina } from '../../../utils/helper'
import queryString from 'query-string'
import ModalOrderSuccess from './components/ModalOrderSuccess'
import DeleteProduct from './Step1/Component/DeleteProduct'

interface Props extends RouteComponentProps, WithNamespaces {
    // Other props go here
}

interface CartItem {
    merchant: {
        id: string
        name: string
        avatar: string
    }
    product: {
        id: string
        name: string
        image: string
    }
    sku: {
        id: string
        skuId: string
        quantity: string
        price: string
        image: string
        itemId: string
        variantProperties: Array<any>
    }
}

const TaobaoGlobalCart: React.FC<Props> = ({ t, history, ...props }) => {
    const [step, setStep] = useState(1)
    const [checkedItems, setCheckedItems] = useState<any>([])
    const [checkAllMerchants, setCheckAllMerchants] = useState(false)
    const [cartListItem, setCartListItem] = useState<any>([])
    const [loading, setLoading] = useState<any>(false)
    const [loadingOrder, setLoadingOrder] = useState<boolean>(false)
    const [totalItem, setTotalItem] = useState(0)
    const [selectedAddress, setSelectedAddress] = useState<string>()
    const [accountsConnect, setAccountsConnect] = useState<Array<any>>()
    const [accountsConnectSelected, setAccountsConnectSelected] = useState<string>()
    const [pagination, setPagination] = useState<any>()
    const [filter, setFilter] = useState<any>()
    const [loadingAccount, setLoadingAccount] = useState<boolean>(false)
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [orders, setOrders] = useState<any>([])
    const [, setErrorsOrder] = useState<any>([])
    const getListCartItem = useCallback(
        (loading: boolean, filterTemp?: any, callback?: any) => {
            setLoading(loading)

            let defaultParams = {}
            setFilter(filterTemp)
            if (!isEmpty(filterTemp)) {
                set(defaultParams, 'page', filterTemp.page ? filterTemp.page - 1 : 0)
                set(defaultParams, 'size', get(filterTemp, 'pageSize', 10))
            } else {
                defaultParams = {
                    page: 0,
                    size: 10,
                    sort: 'createdAt:desc',
                }
            }
            const query = queryString.stringify(defaultParams)
            cartTaobaoGlobal.getListCartItem(query, (err: any, res: any, header: any) => {
                if (!err) {
                    setCartListItem(res)
                    setPagination({
                        pageCount: parseInt(get(header, 'x-page-count')),
                        current: header ? parseInt(get(header, 'x-page-number')) + 1 : 1,
                        pageSize: parseInt(get(header, 'x-page-size')),
                        total: parseInt(get(header, 'x-total-count')),
                    })

                    let totalProduct = 0
                    res.forEach((items: any) => {
                        items?.cartItems?.forEach((cartItem: any) => {
                            cartItem?.skuViews?.forEach((skuView: any) => {
                                totalProduct += parseInt(skuView?.quantity)
                            })
                        })
                    })
                    setTotalItem(totalProduct)

                    if (typeof callback === 'function') callback()
                }
                setLoading(false)
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [history.location.search]
    )

    useEffect(() => {
        const queryParse: any = parseQueryStringToObject(history.location.search)
        if (history.location.search.includes('?link') && !queryParse?.link) {
            getListCartItem(false, queryParse, () => {})
        } else {
            getListCartItem(true, queryParse, () => {})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getListCartItem])

    const getAccountConnect = useCallback(() => {
        setLoadingAccount(true)
        cartTaobaoGlobal.getAccountConnect((err: any, res: any, header: any) => {
            if (!err) {
                handleSetAccountsConnectSelected(get(first(res), 'tbgAccount') as any)
                setAccountsConnect(res)
            }
            setLoadingAccount(false)
        })
    }, [])

    useEffect(() => {
        getAccountConnect()
    }, [getAccountConnect])

    const handleSetAccountsConnectSelected = (account: string) => {
        setAccountsConnectSelected(account)
    }

    const updateTotalPriceWhenChangeQuantiy = (itemId: string, skuId: string, quantity: any) => {
        const newState = cloneDeep(
            [...checkedItems].map((item: any) =>
                item?.sku?.skuId === skuId && itemId === item?.sku?.itemId
                    ? {
                          ...item,
                          sku: {
                              ...item.sku,
                              quantity,
                          },
                      }
                    : item
            )
        )
        setCheckedItems(newState)
    }

    const generateCartItem = (merchant: any, productMapping: any, skuItem: any): CartItem => ({
        merchant: {
            id: merchant.id,
            name: merchant.name,
            avatar: merchant.avatar,
        },
        product: {
            id: productMapping?.mappingId,
            name: productMapping.name,
            image: productMapping.image,
        },
        sku: {
            id: skuItem?.id,
            skuId: skuItem.skuMappingId,
            quantity: skuItem.quantity,
            price: skuItem.price,
            image: skuItem.image,
            itemId: skuItem.productMappingId,
            variantProperties: skuItem.variantProperties,
        },
    })

    const handleCheckboxChange = (merchant: any, skuItem: any, productMapping: any) => {
        const newState = [...checkedItems]

        const itemIndex = newState.findIndex(item => item?.sku?.skuId === skuItem?.skuMappingId)
        if (itemIndex === -1) {
            const data = generateCartItem(merchant, productMapping, skuItem)
            newState.push(data)
        } else {
            newState.splice(itemIndex, 1)
        }
        handleChangeCheckedItems(newState)
    }

    const handleCheckAllChange = (merchantData: any, checked: boolean, cartList: any) => {
        const merchantItems = cartListItem.find((merchant: any) => merchant.merchant.id === merchantData?.id)?.cartItems
        const setCheckedItemsToEmpty = [...checkedItems].filter(item => item.merchant.id !== merchantData.id)
        if (!merchantItems) return
        if (checked) {
            const newState = [
                ...setCheckedItemsToEmpty,
                merchantItems.map((item: any) =>
                    item?.skuViews?.map((sku: any) => generateCartItem(merchantData, item.productMapping, sku))
                ),
            ]
            handleChangeCheckedItems(flattenDeep(newState))
        } else {
            handleChangeCheckedItems([...checkedItems].filter(item => item?.merchant?.id !== merchantData?.id))
        }
    }

    const handleCheckAllMerchantsChange = (checked: boolean) => {
        setCheckAllMerchants(checked)
        if (checked) {
            const newState = flattenDeep(
                cartListItem.map((cart: any) => {
                    const merchant = cart?.merchant
                    const cartItems = cart?.cartItems
                    return cartItems.map((item: any) =>
                        item?.skuViews?.map((sku: any) => generateCartItem(merchant, item.productMapping, sku))
                    )
                })
            )
            handleChangeCheckedItems(newState)
        } else {
            handleChangeCheckedItems([])
        }
    }

    const onSubmit = async () => {
        setLoadingOrder(true)
        try {
            const cloneData = cloneDeep(checkedItems)

            const mechantGroup = groupBy(cloneData, 'merchant.id')

            const data = Object.values(mechantGroup)

            const results = []
            const errors = []

            for (const item of data) {
                try {
                    const result = await orderFn(item)
                    results.push(result)
                } catch (error) {
                    errors.push(error)
                }
            }

            if (results.length > 0) {
                setOpenModal(true)
                setOrders(results)
                setCheckedItems([])
            } else {
                notification.error({ message: t('message.error_order') as string, duration: 1000 })
            }
            setErrorsOrder(errors)
        } finally {
            setLoadingOrder(false)
        }
    }

    const orderFn = (data: any) => {
        const products: any = []

        const dataGroup = groupBy(data, 'product.id')

        keys(dataGroup).forEach(key => {
            products.push({
                itemId: key,
                skus: dataGroup[key].map(({ sku: { skuId, quantity } }) => ({
                    skuId,
                    quantity,
                })),
            })
        })

        const body = {
            addressId: selectedAddress,
            tbgAccount: accountsConnectSelected,
            products,
        }

        return new Promise((resolve, reject) => {
            cartTaobaoGlobal.onOrder(body, (err: any, res: any, header: any) => {
                if (err) {
                    reject(err)
                } else {
                    resolve(res)
                }
            })
        })
    }

    const handleSelectedAddress = (selectedAddress: string) => {
        setSelectedAddress(selectedAddress)
    }

    const handleChangeCheckedItems = (newState: any) => {
        setCheckedItems(newState)
    }

    const onChangePage = (page: any, pageSize: any) => {
        history.push(
            history.location.pathname +
                '?' +
                queryString.stringify({
                    page: page,
                })
        )
        handleChangeCheckedItems([])
    }

    const onClearParams = () => {
        history.push(history.location.pathname)
        handleChangeCheckedItems([])
    }

    const totalPrice = useMemo(
        () => sumBy(checkedItems, (item: any) => item.sku?.price * item.sku?.quantity),
        [checkedItems, cartListItem]
    )
    const totalProductCart = useMemo(
        () => sumBy(cartListItem, (cart: any) => sumBy(cart?.cartItems, (cartItem: any) => cartItem?.skuViews?.length)),
        [cartListItem]
    )
    const totalQuantity = useMemo(() => sumBy(checkedItems, (item: any) => item?.sku?.quantity), [checkedItems, cartListItem])
    const totalShopChoosed = groupBy(checkedItems, 'merchant.id')
    const totalProductChoosed = groupBy(checkedItems, 'product.id')

    const continueOrder = () => {
        setStep(1)
        getListCartItem(true)
        setOpenModal(false)
        setOrders([])
    }

    const renderStep = () => {
        let component = null

        switch (step) {
            case 1:
                component = (
                    <Step1
                        totalProductCart={totalProductCart}
                        loadingListItem={loading}
                        cartListItem={cartListItem}
                        getListCartItem={getListCartItem}
                        checkedItems={checkedItems}
                        pagination={pagination}
                        filter={filter}
                        onClearParams={onClearParams}
                        handleCheckboxChange={handleCheckboxChange}
                        handleCheckAllChange={handleCheckAllChange}
                        handleCheckAllMerchantsChange={handleCheckAllMerchantsChange}
                        checkAllMerchants={checkAllMerchants}
                        totalItem={totalItem}
                        onChangePage={onChangePage}
                        history={history}
                        updateTotalPriceWhenChangeQuantiy={updateTotalPriceWhenChangeQuantiy}
                        accountsConnect={accountsConnect}
                        t={t}
                        loadingAccount={loadingAccount}
                    />
                )
                break
            case 2:
                component = (
                    <Step2
                        handleSetAccountsConnectSelected={handleSetAccountsConnectSelected}
                        accountsConnectSelected={accountsConnectSelected}
                        accountsConnect={accountsConnect}
                        handleSelectedAddress={handleSelectedAddress}
                        selectedAddressForOrder={selectedAddress}
                        checkedItems={checkedItems}
                        t={t}
                    />
                )
                break
            default:
                return
        }
        return component
    }

    const renderFixedLayout = () => {
        return (
            <div className="container-common pb-[100px] mgr20 relative v2-layout">
                <div
                    style={{ boxShadow: '4px -2px 6px 0px rgba(0, 0, 0, 0.10)' }}
                    className="Footer fixed bottom-[1px] right-[-20px] left-[36px] bg-white py-6 pl-[60px] pr-[60px]  mr-[20px] flex items-center justify-between z-10"
                >
                    {step === 1 ? (
                        <div className="flex items-center gap-4">
                            <Checkbox
                                onChange={e => handleCheckAllMerchantsChange(e.target.checked)}
                                checked={
                                    (checkAllMerchants && checkedItems.length > 0) ||
                                    (checkedItems.length > 0 && checkedItems.length === totalProductCart)
                                }
                                indeterminate={checkedItems.length > 0 && checkedItems.length !== totalProductCart}
                            >
                                {`${t('taobaoGlobalCart.selectAll')}`}
                            </Checkbox>
                            <div>|</div>
                            <div className="whitespace-nowrap"> {keys(totalShopChoosed).length} Shop</div>
                            <div>|</div>
                            <div className="whitespace-nowrap">
                                {' '}
                                {totalQuantity} {`${t('taobaoGlobalCart.product')}`}
                            </div>
                            <div>|</div>
                            <div className="whitespace-nowrap"> {keys(totalProductChoosed).length} Link</div>
                            {checkedItems.length > 0 && (
                                <>
                                    <div>|</div>
                                    <DeleteProduct
                                        type="all"
                                        checkedItems={checkedItems}
                                        getListCartItem={getListCartItem}
                                        onClearParams={onClearParams}
                                    />
                                </>
                            )}
                        </div>
                    ) : (
                        <Button
                            className="rounded capitalize"
                            icon={<i className="fa-regular fa-arrow-left mr-2" />}
                            onClick={() => {
                                setStep(prevStep => prevStep - 1)
                                getListCartItem(filter)
                            }}
                            type="primary"
                        >
                            {`${t('button.back')}`}
                        </Button>
                    )}
                    <div className="flex items-center gap-4">
                        <div className="flex items-center">
                            {' '}
                            {`${t('taobaoGlobalCart.money')}`}:{' '}
                            <span className="text-blue-primary font-medium text-xl ml-1"> {formatCurrencyChina(totalPrice)}</span>
                        </div>
                        {step === 1 ? (
                            <Button
                                className="rounded capitalize"
                                style={{ height: '40px' }}
                                disabled={checkedItems.length === 0}
                                onClick={() => setStep(prevStep => prevStep + 1)}
                                type="primary"
                            >
                                {`${t('deposit.depositContinue')}`}
                            </Button>
                        ) : (
                            step === 2 && (
                                <Button
                                    className="rounded capitalize"
                                    onClick={() => onSubmit()}
                                    style={{ height: '40px' }}
                                    disabled={accountsConnect?.length === 0}
                                    loading={loadingOrder}
                                    type="primary"
                                >
                                    {`${t('taobaoGlobalCart.buyNow')}`}
                                </Button>
                            )
                        )}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <MainLayout
            {...props}
            headerTitle={t('Taobao')}
            title={t('Taobao')}
            noFooter={true}
        >
            <div className="container-common pb-[100px] mgr20 relative">
                {renderStep()}
                {step !== 3 && renderFixedLayout()}
            </div>
            <ModalOrderSuccess
                t={t}
                history={history}
                openModal={openModal}
                orders={orders}
                continueOrder={continueOrder}
            />
        </MainLayout>
    )
}

export default withRouter(withNamespaces('translation')(TaobaoGlobalCart))
