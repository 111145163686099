import React from "react";
import {withNamespaces} from 'react-i18next';
import {Col, List, Row} from "antd";
import NoData from "../NoData";
import lodash from "lodash";
import Loading from "../Loading";
import {LogItem} from "./LogItem";
import UserService from "../../services/user.service";


class TabLog extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			initLoading: true,
			loadingMore: false,
			logs: [],
			page: 1,
			size: 25,
			logErrors: null,
			showLoadMore: false
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.code !== this.props.code
			|| JSON.stringify(prevProps.peerPayment) !== JSON.stringify(this.props.peerPayment)
			|| JSON.stringify(prevProps.location.search) !== JSON.stringify(this.props.location.search)
		) {
			this.getLog({
				limit: this.state.size,
				offset: this.state.page > 0 ? (this.state.page - 1) * this.state.size : 0
			});
		}
	}

	componentDidMount() {
		this.getLog({
			limit: this.state.size + 1,
			offset: this.state.page > 0 ? (this.state.page - 1) * this.state.size : 0
		});
	}

	getLog = (params = {}) => {
		const {logs} = this.state
		this.setState({initLoading: true, logErrors: null}, () => {
			UserService.getPeerPaymentLogs(this.props.code, {
				sort: 'timestamp:desc',
				...params
			}).then(res => {
				let showMore = true;
				if (res.data.length <= this.state.size) {
					showMore = false
				}

				if (res.data.length > this.state.size) {
					res.data.pop()
				}

				this.setState({
					initLoading: false,
					logs: lodash.uniqBy([...logs, ...res.data], 'id'),
					showLoadMore: showMore
				});
			}).catch(err => {
				this.setState({initLoading: false, logErrors: err});
			})
		});
	};

	onLoadMore = () => {
		this.setState({
			loadingMore: true,
			logErrors: null,
			page: this.state.page + 1
		}, () => {
			this.getLog({
				sort: 'timestamp:desc',
				size: this.state.size + 1,
				offset: (this.state.page - 1) * this.state.size
			})
		});
	};

	onRecall = () => {
		this.getLog();
	};

	render() {
		let {t, peerPayment, statuses, internalStatuses} = this.props;
		let {initLoading, loadingMore, showLoadMore, logs, logErrors} = this.state;
		const loadMoreDisplay = !initLoading && !loadingMore && showLoadMore ?
			(<div className={'mgt5 mgbt5'} style={{textAlign: 'center', height: 32, lineHeight: '32px'}}>
				<span className={'_text-load-more cursor-pointer'} onClick={this.onLoadMore}>{t('log_product.loading_more')}</span>
			</div>)
			: null;
		return (
			<Row>
				<Col span={24}>
					{!lodash.isEmpty(logs) ?
						<List
							className="demo-loadmore-list _order-logs order-log-list pdl20"
							loading={initLoading}
							itemLayout="horizontal"
							loadMore={loadMoreDisplay}
							dataSource={logs}
							renderItem={(item, index) => {
								return (
									<LogItem
										t={t}
										key={index}
										item={item}
										currency={lodash.get(peerPayment, 'currency')}
										statuses={statuses}
										internalStatuses={internalStatuses}
									/>
								)
							}}
						>
						</List>
						:
						initLoading ?
							<div className={'txt-center'}>
								<Loading height="30vh"/>
							</div>
							:
							<NoData error={logErrors} onRecall={this.onRecall}/>}
				</Col>
			</Row>

		);
	};
}

export default withNamespaces()(TabLog);
