/* eslint-disable array-callback-return */
import { Component } from 'react'
import { LocalStore } from '../../utils/LocalStore'
import { Popover, Divider, Row } from 'antd'


import CategoriesService from '../../services/categories.service'
import ReactCountryFlag from 'react-country-flag'
import viVN from 'antd/lib/locale-provider/vi_VN'
import enUS from 'antd/lib/locale-provider/en_US'
import kmKH from '../../constants/locale/km/km_KH'
import zhCN from 'antd/lib/locale-provider/zh_CN'
import thTH from 'antd/lib/locale-provider/th_TH'

class index extends Component {
    constructor(props) {
        super(props)
        this.isUnmounted = false
        this.state = {
            currentLanguage: null,
        }
    }

    componentDidMount() {
        this.setState(
            {
                currentProjectInfo: LocalStore.getInstance().read('currentProjectInfo') || {},
            },
            () => {
                this.initLanguage()
            }
        )
    }

    language = lang => {
        switch (lang) {
            case 'zh':
                return zhCN
            case 'vi':
                return viVN
            case 'km':
                return kmKH
            case 'en':
                return enUS
            case 'th':
                return thTH
            default:
                return viVN
        }
    }

    initLanguage = () => {
        const { currentProjectInfo = {} } = this.state
        CategoriesService.getLanguages((err, res) => {
            if (!err) {
                LocalStore.getInstance().save('languagesSupport', res)
                let currentLanguage = LocalStore.getInstance().read('currentLanguage')
                if (res.length > 0 && !currentLanguage) {
                    if (currentProjectInfo && currentProjectInfo.tenantConfig) {
                        if (!currentProjectInfo.tenantConfig.language) {
                            LocalStore.getInstance().save('currentLanguage', res[0])
                            currentLanguage = res[0]
                        } else {
                            res.map(item => {
                                if (item.code === currentProjectInfo.tenantConfig.language) {
                                    LocalStore.getInstance().save('currentLanguage', item)
                                    currentLanguage = item
                                }
                            })
                        }
                    }
                }
                this.setState(
                    {
                        currentLanguage,
                    },
                    () => {
                        const { i18n } = this.props
                        if (currentLanguage && currentLanguage.languageCode) {
                            if (i18n && i18n.changeLanguage) {
                                i18n.changeLanguage(currentLanguage.languageCode)
                                this.setState({ locale: this.language(currentLanguage.languageCode) })
                            }
                        }
                    }
                )
            }
        })
    }

    selectLanguage = item => {
        LocalStore.getInstance().save('currentLanguage', item)
        this.handleVisibleChange(false)
        this.setState(
            {
                currentLanguage: item,
            },
            () => {
                document.location.reload()
            }
        )
    }

    renderLanguages = languages => {
        return (
            <div>
                {languages.map((item, index) => {
                    return (
                        <div key={index}>
                            {index !== 0 && <Divider className="m-0" />}
                            <div
                                className={`px-4 py-3 cursor-pointer hover:bg-gallery-200 ${index === 0 &&  "rounded-t-2xl"} ${(index === languages?.length - 1) && "rounded-b-2xl"}`}
                                onClick={() => this.selectLanguage(item)}
                            >
                                <Row align="middle">
                                    <ReactCountryFlag
                                        style={{
                                            fontSize: '2em',
                                            lineHeight: '2em',
                                        }}
                                        countryCode={item.countryCode}
                                        svg
                                    />
                                    <span className={'text-sm text-grey-200 robotoregular mgl5 mgr5'}>{item.name}</span>
                                </Row>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    handleVisibleChange = visible => {
        this.setState({ visibleLanguage: visible })
    }

    render() {
        const { currentLanguage } = this.state

        let languages = LocalStore.getInstance().read('languagesSupport') || []

        if (!Array.isArray(languages) || languages.length === 0) return null

        return (
            <Popover
                overlayClassName="popup-profile pt-0"
                content={this.renderLanguages(languages)}
                visible={this.state.visibleLanguage}
                onVisibleChange={this.handleVisibleChange}
                placement="bottomRight"
                trigger="click"
            >
                {!!currentLanguage && (
                    <div className={'drop-down-user mgr25 cursor-pointer'}>
                        <ReactCountryFlag
                            style={{
                                fontSize: '2em',
                                lineHeight: '2em',
                            }}
                            countryCode={currentLanguage.countryCode}
                            svg
                        />
                        <span className={'txt-size-h8 txt-color-black robotoregular mgl5 mgr5'}>{currentLanguage.name}</span>
                        <i class="fa-solid fa-chevron-down text-xs"></i>
                    </div>
                )}
            </Popover>
        )
    }
}

export default index
