import { Button, Radio, Table } from 'antd'
import React from 'react'
import Modal from '../commons/Modal'
import lodash from 'lodash';

interface ListAddressChinaModalProps {
    showModalListAddress: () => void
    isOpenModalListAddress: boolean
    handleSubmitModalAddressList: () => void,
    handleCancelModalAddressList: () => void,
    ListAddressChina: any,
    showModalEditAddress: (item:any) => void,
}

const ListAddressChinaModal: React.FC<ListAddressChinaModalProps> = (props) => {
    const {
        isOpenModalListAddress,
        showModalEditAddress,
        handleSubmitModalAddressList,
        handleCancelModalAddressList,
        ListAddressChina } = props

    const columns = [
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            width: "5%",
            render: (value: any, record: any) => {
                return (
                    <div>
                        <Radio
                        //   checked={selectedItem?.id === value}
                        //   onChange={(e) => {
                        //     setSelectedItem(record)
                        //   }}
                        />
                    </div>
                )
            },
        },
        {
            title: <span className={'txt-centermb'}>{('Tên người nhận')}</span>,
            dataIndex: 'recipient',
            key: 'recipient',
            width: "15%",
            render: (value: any, record: any) => {
                return <span className={'txt-size-h7 robotomedium'}>{record.recipient}</span>
            }
        },
        {
            title: ('Điện thoại'),
            dataIndex: 'phone',
            key: 'phone',
            width: "15%",
            render: (value: any, record: any) => {
                return <span className={'txt-size-h7 robotoregular'}>{record.phone}</span>
            },
        },
        {
            title: ('address.address-name'),
            dataIndex: 'name',
            key: 'name',
            width: "50%",
            render: (value: any, record: any) => {
                const defaultAddress = record?.defaultAddress
                const state = lodash.get(record, "state") || '---'
                const district = lodash.get(record, "district") || '---'
                const city = lodash.get(record, "city") || '---'
                return <>
                    <span className='text-sm text-[#1A1A1A] font-normal mr-4'>
                        {state} - {district} - {city} - {`china`}
                    </span>
                    {defaultAddress && <span className='border text-xs font-medium text-[#1890FF] px-4 py-[4px] border-solid border-[#1890FF] rounded-2xl'>Mặc định </span>}
                </>
            },
        },
        {
            title: <span className={'flr pd-r-24'}>{('address.address-actions')}</span>,
            dataIndex: 'action',
            key: 'action',
            align: "right" as "right",
            width: "15%",
            render: (_: any, record: any) => {
                return (
                    <span className='underline text-[#1890FF] text-sm font-normal hover:cursor-pointer'
                        onClick={() => {
                            showModalEditAddress(record)
                        }}>Chỉnh sửa</span>
                )
            },
        },
    ]


    return (
        <Modal
            centered
            closeIcon={<i className="fa-solid fa-xmark" />}
            title={('listAddressChinaModal.modal_title')}
            visible={isOpenModalListAddress}
            onCancel={
                handleCancelModalAddressList
            }
            width={850}
            listAction={[
                <Button
                    className={"btn-cancel space-x-1.5 _btn-cancel-confirm mr-2"}
                    onClick={handleCancelModalAddressList}
                >
                    <i className="fa-regular fa-xmark"></i>
                    {('button.cancel')}
                </Button>,
                <Button
                    type="primary"
                    htmlType="submit"
                    className="space-x-1.5 _btn-submit-confirm"
                    onClick={handleSubmitModalAddressList}
                // loading={isLoading}
                >
                    <i className="fa-regular fa-check"></i>
                    {('modal.confirm')}
                </Button>
            ]}
        >
            <Table
                rowKey={(record: any) => record.id}
                className="request-draw-table"
                dataSource={ListAddressChina}
                columns={columns}
                pagination={false}
            // scroll={{ y: 400, scrollToFirstRowOnChange: true, x: 'auto' }}
            //   rowClassName={(record, idx) => {
            //     const rowClassesByIdx = idx % 2 === 0 ? 'row-light' : 'row-dark'
            //     return `${rowClassesByIdx}`
            //   }}
            //   onRow={(record: any, rowIndex: any) => {
            //     return {
            //       onClick: (event) => {
            //         setSelectedItem(record)
            //       },
            //     }
            //   }}
            />
        </Modal>
    )
}

export default ListAddressChinaModal