import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import * as servicesWorker from './serviceWorker'
import '../src/resources/localization/i18n'
import { Provider } from 'react-redux'
import { store } from './helpers/store'
import { QueryClient, QueryClientProvider } from 'react-query'
// if (process.env.SENTRY_INIT === true || process.env.SENTRY_INIT === 'true') {
//     Sentry.init({dsn: "https://70f8bee4db6d420d9d08cb4031e9a33f@o202005.ingest.sentry.io/5256255"});
// }

// Sentry.init({dsn: "https://70f8bee4db6d420d9d08cb4031e9a33f@o202005.ingest.sentry.io/5256255"});
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
})
const root = ReactDOM.createRoot(document.getElementById('root') as Element)
root.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <App />
        </QueryClientProvider>
    </Provider>
)

servicesWorker.register()
