/**
 * validate function for validate form data,
 * if value not match then return error message, otherwise return undefined
 * created by Taobao dev team - 18/1/2018
 */
import lang from "../resources/localization/Localization"

const validateRequired = value => (value && value.trim() ? undefined : lang.message.warning_empty);
const maxLength = (name, max) => value =>
    value && value.length > max ? `${name ? name : ''} ${lang.message.max_length} ${max} ${lang.message.characters}` : undefined;
const maxLength15 = maxLength(null, 15);
const minLength = (name, min) => value =>
    value && value.length < min ? `${name ? name : ''} ${lang.message.min_length} ${min} ${lang.message.characters}` : undefined;

const validateMinLength = (value, name, min) => value && value.length < min ? `${name ? name : ''} ${lang.message.min_length} ${min} ${lang.message.characters}` : undefined;
const validateMaxLength = (value, name, max) => value && value.length > max ? `${name ? name : ''} ${lang.message.max_length} ${max} ${lang.message.characters}` : undefined;

const minLength2 = minLength(null, 2);
const number = name => value =>
    value && isNaN(Number(value)) ? `${name} phải là các ký tự số` : undefined;
const minValue = (name, min) => value =>
    value && value < min ? `${name} phải lớn hơn hoặc bằng ${min}` : undefined;

const validateEmail = value =>
    value && !/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{1,255})+$/.test(value)
        ? 'register.email_invalid'
        : undefined;


const orEmail = value =>
    value.indexOf("@") === -1 ? undefined : validateEmail(value);

const alphaNumeric = value =>
    value && /[^a-zA-Z0-9 ]/i.test(value)
        ? 'Chỉ chứa ký tự chữ cái hoặc số'
        : undefined;
const phoneNumber = value =>
    value && !/^[0-9+.() -]+$/i.test(value)
        ? 'validate.phone_invalid'
        : undefined;
const validatePhoneNumber = value => {
    // value = value.replace(/"[ .-]"/g ,"");
    // var re = /((^(([+](0-9)?[)]{2,5})|01[2689]|09|08)[0-9]{8})|^(02)[0-9]{9})$/;
    let re = /((^((0|([(]{0,1}[+][0-9]{2,6}[)]{0,1}))1[2689]|(0|([(]{0,1}[+][0-9]{2,6}[)]{0,1}))9|(0|([(]{0,1}[+][0-9]{2,6}[)]{0,1}))8)[0-9]{8})|^((0|([(]{0,1}[+][0-9]{2,6}[)]{0,1}))2)[0-9]{9})$/;//^[0-9]*$
    // let re = /^[+]?[0-9]{3}|^[(]?[+][0-9]{3,5}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,7}$/im;
    return !re.test(value) ? 'Số điện thoại không hợp lệ' : undefined;
};

const validateUserName = (value) => {
    if (value.length < 6) return 'validate.username_min_length';
    if (value.length > 20) return 'validate.username_max_length';
    let rgex = /^([A-Za-z]|[A-Za-z][0-9]*|[0-9]*[A-Za-z])+$/i.test(value);
    if (!rgex) return 'validate.username_special';

    // if (value.startsWith('.') || value.startsWith('_') || value.endsWith('.') || value.endsWith('_')) {
    //     return 'Tài khoản không thể bắt đầu hoặc kết thúc bằng ký tự "." và "_"';
    // }
    //
    // if (value.indexOf('..') >= 0 || value.indexOf('__') >= 0) {
    //     return 'Tài khoản không hợp lệ';
    // }

    let includeSpecialWord = false;
    let keyName = ['admin', 'administrator', 'god', 'mod', 'moderator', 'quantri', 'seudo', 'baogam', 'gobiz', 'fuck', 'hochiminh', 'hochutich', 'bacho'];

    let i;
    for (i = 0; i < keyName.length; i++) {
        if (value.toLowerCase().indexOf(keyName[i]) !== -1) {
            includeSpecialWord = true;
        }
    }

    if (includeSpecialWord)
        return 'validate.username_invalid';
    else
        return undefined;

};

const validatePropName = value =>
    value && (/^@?[a-zA-Z_]\w*(\.@?[a-zA-Z_]\w*)*$/i.test(value)) ? 'Key không thể chứa ký' : undefined;


const validateFullName = value =>
    value && /[0-9\-@_.,!@#$%^&*()+=`~\\/{}[\]'":;?]/i.test(value)
        ? 'Họ và tên không chứa ký tự đặc biệt hoặc số'
        : undefined;

const validateClientName = value => {
    if (value.length < 3) return 'Tên ứng dụng không ít hơn 3 ký tự';
    if (value.length > 69) return 'Tên ứng dụng không nhiều hơn 69 ký tự';
    return value && /[0-9\-@_.,!@#$%^&*()+=`~\\/{}[\]'":;?]/i.test(value)
        ? 'Tên ứng dụng không chứa ký tự đặc biệt hoặc số'
        : undefined;
};

const validateRole = value => {
    if (value.length < 3) return 'Tên nhóm quyền không ít hơn 3 ký tự';
    if (value.length > 69) return 'Tên nhóm quyền không nhiều hơn 69 ký tự';
    return value && /[@,!#$%^&*()+=`~\\/{}[\]'":;?]/i.test(value)
        ? 'Không được chứa ký tự đặc biệt'
        : undefined;
};

const validatePermission = value => {
    if (value.length < 3) return 'Tên quyền không ít hơn 3 ký tự';
    if (value.length > 69) return 'Tên quyền không nhiều hơn 69 ký tự';
    return value && /[@,!#$%^&*()+=`~\\/{}[\]'":;?]/i.test(value)
        ? 'Không được chứa ký tự đặc biệt'
        : undefined;
};

const validateCode = value => {
    if (value.length < 3) return 'Code không ít hơn 3 ký tự';
    if (value.length > 69) return 'Code không nhiều hơn 69 ký tự';
    return value && !/^([a-z0-9]{1,})(_[a-z0-9]{1,})*$/.test(value)
        ? 'Code không đúng định dạng'
        : undefined;
};

const validateCodePermission = value => {
    if (value.length < 3) return 'Code không ít hơn 3 ký tự';
    if (value.length > 69) return 'Code không nhiều hơn 69 ký tự';
    return undefined;
};

const validateLink = (text) => {
    let urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/ig;
    return urlRegex.test(text);
};

export {
    validateRequired,
    maxLength,
    maxLength15,
    number,
    minLength,
    minLength2,
    validateEmail,
    orEmail,
    phoneNumber,
    alphaNumeric,
    minValue,
    validatePhoneNumber,
    validateUserName,
    validateFullName,
    validateClientName,
    validatePropName,
    validateMinLength,
    validateMaxLength,
    validateRole,
    validateCode,
    validatePermission,
    validateCodePermission,
    validateLink
}
