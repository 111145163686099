import PolimClient from '../utils/api/PolimClient';

export default class DeliveryService {

    /**
     * lấy ra danh sách kiện hàng
     * @param cb
     */
    static getListAvailableOrder (cb) {
        PolimClient.request({
            endpoint: `/customer/delivery_requests/available_orders?size=9999`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra danh sách packages của đơn
     * @param code
     * @param cb
     */
    static getListOrderPackages (code, cb) {
        PolimClient.request({
            endpoint: `/customer/orders/${code}/packages`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra danh sách packages của đơn
     * @param code
     * @param cb
     */
    static getListOrderAvailablePackages (code, cb) {
        PolimClient.request({
            endpoint: `/customer/delivery_requests/available_orders/${code}/available_packages`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra danh sách packages của đơn ký gửi
     * @param code
     * @param cb
     */
    static getListShipmentAvailablePackages (code, cb) {
        PolimClient.request({
            endpoint: `/customer/delivery_requests/available_orders/${code}/available_parcels`,
            method: 'GET',
        }, cb)
    }

    /**
     * create a delivery request
     * @param data
     * @param cb
     */
    static createDelivery (data, cb) {
        PolimClient.request({
            endpoint: `/customer/delivery_requests`,
            method: 'POST',
            body: data
        }, cb)
    }

    /**
     * lấy ra danh sách yêu cầu giao của customer
     * @param query
     * @param cb
     */
    static getCustomerDelivery(query, cb) {
        PolimClient.request({
            endpoint: `/customer/delivery_requests${query || ''}`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra danh sách yêu cầu giao của customer
     * @param code
     * @param cb
     */
    static getDeliveryPackages(code, cb) {
        PolimClient.request({
            endpoint: `/customer/delivery_requests/${code}/packages`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra danh sách trạng thái yêu cầu giao
     * @param cb
     */
    static getDeliveryStatuses(cb) {
        PolimClient.request({
            endpoint: `/categories/delivery_request_statuses?size=1000&sort=position:asc`,
            method: 'GET',
        }, cb)
    }
}
