import React, {useEffect, useState} from "react";
import UserService from "../../services/user.service";
import {Modal, Table} from "antd";
import moment from "moment";
import * as AntdIcon from '@ant-design/icons'
import {moneyFormat} from "../../utils/stringUtils";
import CategoriesService from "../../services/categories.service";

export const ModalFinancial = (props = {
    peerPaymentCode: undefined,
    t: () => {},
    onVisibleChange: () => {}
}) => {
    const {t} = props
    const [loading, setLoading] = useState(false)
    const [listFinancialTypes, setListFinancialTypes] = useState([])
    const [originalFinancials, setOriginalFinancials] = useState([])
    const [financials, setFinancials] = useState([])
    const [page, setPage] = useState(1)
    const pageSize = 25

    const getPeerPaymentFinancials = (query = {}) => {
        setLoading(true)
        query = {
            ...query,
            type: 'claim',
            sort: 'timestamp:desc'
        }
        UserService.getPeerPaymentFinancials(props.peerPaymentCode, query, (err, res) => {
            setLoading(false)
            if (!err) {
                setOriginalFinancials([...res])
                if (res.length > pageSize) {
                    res.pop()
                }

                setFinancials([
                    ...financials,
                    ...res
                ])
            }
        })
    }

    useEffect(() => {
        getPeerPaymentFinancials({
            offset: 0,
            limit: pageSize + 1
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.peerPaymentCode])

    useEffect(() => {
        CategoriesService.getFinancialTypes((err, res) => {
            if (!err) {
                setListFinancialTypes(res)
            }
        })
    }, [props.peerPaymentCode])

    const handleLoadMore = () => {
        let newPage = page + 1
        setPage(newPage)
        getPeerPaymentFinancials({
            offset: (newPage - 1) * pageSize,
            limit: pageSize + 1
        })
    }

    return (
        <Modal
            width={1050}
            visible={true}
            destroyOnClose={true}
            title={t('modalPeerPaymentFinancial.title')}
            footer={null}
            onCancel={() => props.onVisibleChange(false)}
        >
            <Table
                loading={loading}
                columns={[
                    {
                        title: t('peer_payment.teller'),
                        dataIndex: 'teller',
                        key: 'teller',
                        className: '_finance-teller',
						width: 150,
                        render: (text) => (
                            <div className={'_financial-teller-text txt-size-h7 txt-color-black roboregular mgbt5 break-word'}>
                                {text || '---'}
                            </div>
                        ),
                    },
                    {
                        title: t('financial_tab.time'),
                        dataIndex: 'timestamp',
                        key: 'timestamp',
                        className: '_finance-time',
						width: 150,
                        render: (text, record) => (
                            <div className={'_financial-time-text txt-size-h7 txt-color-black roboregular mgbt5 break-word'}>
                                {moment(record.timestamp).format('HH:mm DD/MM/YYYY')}
                            </div>
                        ),
                    },
                    {
                        title: t('financial_tab.foreignAmount'),
                        key: 'foreignAmount',
                        className: '_finance-amount',
						width: 150,
                        render: (record) => {
                            let amount = record.amount / props.exchangeRate
                            return (
                                <div className={`_financial-amount-text txt-size-h7 roboregular mgbt5 break-word `}>
                                    {moneyFormat(amount, props.currency)}
                                </div>
                            )
                        }
                    },
                    {
                        title: t('financial_tab.amount'),
                        dataIndex: 'amount',
                        key: 'amount',
                        className: '_finance-amount',
						width: 150,
                        render: (text, record) => {
                            return (
                                <div className={`_financial-amount-text txt-size-h7 txt-color-red2 roboregular mgbt5 break-word ` + (record.amount >= 0 ? 'txt-color-green' : 'txt-color-red')}>
                                    {record.amount >= 0 ? '+' + moneyFormat(record.amount) : moneyFormat(record.amount)}
                                </div>
                            )
                        }
                    },
                    {
                        title: t('financial_tab.transaction_type'),
                        dataIndex: 'financialType',
                        key: 'financialType',
                        className: '_finance-type whitespace',
                        render: (text) => {
                            let current = listFinancialTypes.find(x => x.code === text) || {}
                            return (
                                <div className={'_financial-type-text txt-size-h7 txt-color-black roboregular mgbt5 whitespace break-word'}>
                                    {current.name || text}
                                </div>
                            )
                        },
                    },
                    {
                        title: t('financial_tab.transaction_reason'),
                        dataIndex: 'reason',
                        key: 'reason',
                        className: '_finance-reason whitespace',
                        render: (text) => {
                            return <div className={'whitespace-pre-wrap break-word'}>{text}</div>
                        },
                    },
                    {
                        title: t('financial_tab.content'),
                        key: 'content',
                        className: '_finance-content table__name',
                        render: (text, record) => (
                            <div className={'table__name'}>
                                <div className="_finance-content-trxId txt-size-h8 txt-color-gray roboregular mgbt0 ">Mã: {record.trxId}</div>
                                <div className="_finance-content-text whitespace-pre-wrap break-word mgbt0 txt-size-h7 txt-color-black">
                                    {record.memo}
                                </div>
                            </div>
                        ),
                    }
                ]}
                dataSource={financials}
                pagination={false}
                rowKey={'id'}
            />

            {originalFinancials.length > pageSize && (
                <div className="_a-open text-center cursor-pointer pdt5 pdbt5" onClick={handleLoadMore}>
                    <span className={'txt-color-blue'}><AntdIcon.ArrowsAltOutlined type="arrows-alt" />{t('button.loadmore')}</span>
                </div>
            )}
        </Modal>
    )
}
