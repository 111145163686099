/* eslint-disable array-callback-return */
import React from 'react'
import moment from 'moment'
import lodash from 'lodash'
import MainLayout from '../../components/Layout/MainLayout'
import Loading from '../../components/Loading'
import {Link} from 'react-router-dom'
import {
	Button,
	Col,
	DatePicker,
	Empty,
	Input,
	Modal,
	notification,
	Popconfirm,
	Row,
	Table,
	Tag,
	Typography
} from 'antd';
import * as AntdIcon from "@ant-design/icons"
import {LocalStore} from "../../utils/LocalStore";

import {getUrlQueryString} from "../../utils/helper";
import {
	hashCode,
	intToRGB,
	parseQueryStringToObject,
	quantityFormat,
	updateQueryStringParameter
} from "../../utils/stringUtils";
import CategoriesService from "../../services/categories.service";
import UserService from "../../services/user.service";
import {withNamespaces} from "react-i18next";
import ExportExcel from "../../components/ExportExcel";
import OrderServices from "../../services/orders.service";
import saveAs from "file-saver";
import queryString from "query-string";

let pageSize = 25;
class Waybills extends React.Component {
    constructor(props) {
        super(props);
        this.isUnmounted = false;
        this.state = {
            page: 1,
            pageSize: pageSize,
            isLoading: true,
            listWaybills: [],
            listChecked: {},
            startTime: undefined,
            endTime: undefined,
            querySearch: '',
            typeSearch: '',
        }
    }

    componentDidMount() {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null;
        // window.addEventListener('resize', this.windowResize);
        window.addEventListener("popstate", this.onPopState.bind(this));

        if (typeof LocalStore.getInstance().read('currentLoggedUser') === 'object') {
            this.setState({isLoading: true}, () => {
                this.loadFromQueryString();

                this.getWaybillStatuses();
            })
        }
    }

    /**
     * Khi unmount ra thì bỏ sự kiện popstate
     */
    componentWillUnmount() {
        this.isUnmounted = true;
        window.removeEventListener("popstate", this.onPopState.bind(this));
    }

    /**
     * On popstate
     */
    onPopState() {
        if (!this.isUnmounted) this.setState({isLoading: true}, ()=> {
            this.loadFromQueryString();
        });
    };

    /**
     * Load từ QueryString (dành cho lần load đầu tiên)
     */
    loadFromQueryString = () => {
        let queryString = getUrlQueryString();
        let queryObj = parseQueryStringToObject(queryString) || {};

        // console.log('queryObj', queryObj);
        // chuyển giá trị từ queryObj vào state;
        let arrayValue = [];
        //reset các param khi load từ url
        let listChecked = {};
        let querySearch = '';
        let startTime = undefined;
        let endTime = undefined;
        for (let p in queryObj) {
            if (p === 'statuses') {
                // xử lý với obj list checked
                arrayValue = queryObj[p].split(',');
                // console.log('arrayValue', arrayValue)
                listChecked[p] = arrayValue;
            } else if (p === 'receivedTimeFrom') {
                // xử lý với với timestamp
                startTime = decodeURIComponent(queryObj['receivedTimeFrom']);
            } else if (p === 'receivedTimeTo') {
                // xử lý với với timestamp
                endTime = decodeURIComponent(queryObj['receivedTimeTo']);
            } else {
                // xử lý với query
                querySearch = decodeURIComponent(queryObj['query'] || '')
            }
        }

        this.setState({
            querySearch,
            startTime,
            endTime,
            listChecked,
            page: queryObj.page,
            pageSize: queryObj.size || pageSize,
        }, ()=> {
            this.loadData();
        });
    };

    /**
     * load data
     */
    loadData = (cb) => {
        const {t} = this.props;
        const {page, pageSize} = this.state;
        let queryString = this.buildQueryString();
        if (queryString) {
            queryString = queryString + `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        } else {
            queryString = `?page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        }
        this.setState({showProgressBar: true}, () => {
            UserService.getCustomerWaybills(queryString, (err, res, headers) => {
                if (!err) {
                    this.setState({
                        listWaybills: res,
                        metadata: {
                            pageCount: headers['x-page-count'],
                            page: headers['x-page-number'],
                            size: headers['x-page-size'],
                            total: headers['x-total-count'],
                        }
                    }, () => {
                        //window.scrollTo(0, 0);
                        if (this.mainLayoutElement && this.mainLayoutElement.scrollLayoutElement) this.mainLayoutElement.scrollLayoutElement.scrollTop = 0;
                    });
                } else {
                    notification['error']({
                        message: t(err.message) || t('message.unconnected_error'),
                    });
                }
                if(cb) cb();
                this.setState({showProgressBar: false, isLoading: false})
            })
        })
    };

    /**
     * export excel data
     */
    exportExcel = (password, cb, setError) => {
        const {t} = this.props;
        const {page, pageSize} = this.state;
        let queryString = this.buildQueryString();
        if (queryString) {
            queryString = queryString + `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        } else {
            queryString = `?page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        }

        if (!password) {
            if (setError) setError(t('cartCheckout.incorrect_pin'));

            return;
        }

        this.setState({showProgressBar: true, onExport: true}, () => {
            OrderServices.exportCsvWaybills(queryString, {secret: password}, (err, res, headers) => {
                if (!err) {
                    this.downloadFile(res);
                    if(cb) cb()
                } else {
                    if (err.title === 'invalid_pin' || err.title === 'invalid_password') {
                        if (setError) setError(t('cartCheckout.incorrect_pin'));
                    }
                }
                this.setState({showProgressBar: false, onExport: false})
            })
        })
    };

    checkExportBtn = () => {
        let urlQueryString = getUrlQueryString();
        let queryObj = parseQueryStringToObject(urlQueryString) || {};
        let datetimeRange = [];
        for (let p in queryObj) {
            if (p === 'receivedTimeFrom') {
                // xử lý với với timestamp
                datetimeRange[0] = decodeURIComponent(queryObj['receivedTimeFrom']);
            }
            if (p === 'receivedTimeTo') {
                // xử lý với với timestamp
                datetimeRange[1] = decodeURIComponent(queryObj['receivedTimeTo']);
            }
        }

        if (!datetimeRange[0]) {
            return false;
        }
        if (moment(datetimeRange[0]).add(3, 'M').isBefore(moment(datetimeRange[1]).startOf("date"))) {
            return false;
        }
        return true;
    };

    downloadFile = (res) => {
        if (res && res.headers && res.headers.map) {
            const fileName = res.headers.map["content-disposition"].split('filename=')[1];
            saveAs(res._bodyBlob, fileName)
        }
    };

    onPageChanged = (page, pageSize) => {
        this.setState({page: page}, () => {
            let locationQuery = lodash.get(this.props.location, 'search', '');
            let queryObj = locationQuery?parseQueryStringToObject(locationQuery): {};
            queryObj.page = page;
            queryObj.size = pageSize;
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: queryString.stringify(queryObj)
            });
            // window.scrollTo(0, 0);
        });
    };

    /**
     * lấy ra danh sách trạng thái của package
     */
    getWaybillStatuses = () => {
        CategoriesService.getWaybillStatuses((err, res, headers) => {
            if (err === null) {
                this.setState({
                    listWaybillStatuses: res || [],
                });
            }
        });
    };

    /**
     * lấy ra prop giá trị của obj prop
     * @param props
     * @returns {*}
     */
    getValuePropOfProp = (props) => {
        switch (props) {
            case 'statuses':
                return 'code';
            default:
                return 'id'
        }
    };

    /**
     * xây dựng query string theo các giá trị trong state
     * @returns {string}
     */
    buildQueryString = () => {
        let {listChecked, startTime, endTime, querySearch} = this.state;
        let queryString = '';
        for (let p in listChecked) {
            let value = '';
            if (Array.isArray(listChecked[p]) && listChecked[p].length > 0) {
                listChecked[p].map(x => {
                    value = value + x + ',';
                });

                value = value.slice(0, -1)
            }
            if (value) queryString = updateQueryStringParameter(queryString, p, value)
        }

        if (startTime) {
            queryString = updateQueryStringParameter(queryString, 'receivedTimeFrom', startTime)
        }

        if (endTime) {
            queryString = updateQueryStringParameter(queryString, 'receivedTimeTo', endTime)
        }

        if (querySearch) {
            queryString = updateQueryStringParameter(queryString, 'query', querySearch.trim())
        }

        return queryString
    };

    /**
     * submit
     */
    onSubmit = () => {
        const {stringNewWaybills, note, onSubmit} = this.state;
        const {t} = this.props;

        if (!stringNewWaybills || !stringNewWaybills.trim()) {
            this.setState({
                stringNewWaybillsError: t('shipment.require')
            });
            return;
        }

        if (note) {
            if (note.length > 255) {
                this.setState({
                    noteError: t('error.length_255')
                });
                return;
            }
        }

        if (onSubmit) return;

        let arrString = stringNewWaybills.trim().split(/[^A-Za-z0-9]/);
        arrString = arrString.filter(x => !!x);
        let body = {waybillNumbers: arrString, description: note};
        this.setState({onSubmit: true}, () => {
            UserService.createNewWaybills(body,(err, res, headers) => {
                if (!err) {
                    notification['success']({
                        message: t('message.success'),
                    });
                    this.setState({
                        showModalCreate: false,
                        onSubmit: false
                    });
                    this.loadData();
                } else {
                    if (err.title === "too_much_waybill") {
                        notification['error']({
                            message: t("waybill.create_waybill_too_much"),
                        });
                    } else if (err.title === "default_address_not_found") {
                        notification['error']({
                            message: t("waybill.default_address_not_found"),
                        });
                    } else {
                        notification['error']({
                            message: t(err.message),
                        });
                    }
                    this.setState({onSubmit: false})
                }
            })
        })
    };

    /**
     * submit
     */
    onEdit = (item, data) => {
        const {t} = this.props;

        if (!item || !item.id || this.state[`onEdit${item.id}`] || !data) return;

        if (data.description) {
            if (data.description.length > 255) {
                notification.error({
                    message: t('error.length_255')
                });
                return;
            }
        }

        if (item.description === data.description) {
            return
        }


        let body = {description: data.description};
        this.setState({[`onEdit${item.id}`]: true}, () => {
            UserService.editWaybills(item.id, body,(err, res, headers) => {
                if (!err) {
                    notification['success']({
                        message: t('message.success'),
                    });
                    this.loadData();
                    // let newArr = listWaybills;
                    // // Find item index using _.findIndex (thanks @AJ Richardson for comment)
                    // let index = _.findIndex(newArr, {id: res.id});
                    // // Replace item at index using native splice
                    // newArr.splice(index, 1, res);
                    //
                    this.setState({
                        showModalCreate: false,
                        [`onEdit${item.id}`]: false,
                    });
                } else {
                    if (err.title === "too_much_waybill") {
                        notification['error']({
                            message: t("waybill.create_waybill_too_much"),
                        });
                    } else if (err.title === "default_address_not_found") {
                        notification['error']({
                            message: t("waybill.default_address_not_found"),
                        });
                    } else {
                        notification['error']({
                            message: t(err.message),
                        });
                    }
                    this.setState({[`onEdit${item.id}`]: false})
                }
            })
        })
    };

    /**
     * xóa bản ghi
     * @param code
     */
    deleteData = (code) => {
        const {t} = this.props;
        UserService.deleteWaybills(code, (err, res, headers) => {
            if (!err) {
                notification['success']({
                    message: t('message.delete_success'),
                });
                this.loadData();
            } else {
                notification['error']({
                    message: t(err.message),
                });
            }
        });
    };

    /**
     * xác nhận xóa bản ghi
     * @param id
     */
    confirmDelete = (id) => {
        this.deleteData(id);
    };

    onClearSearch = () => {
        this.setState({
            page: 1,
            querySearch: '',
            startTime: undefined,
            endTime: undefined,
            listChecked: {},
        }, () => {
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: this.buildQueryString()
            });
        })
    };

    /**
     * xử lý với dữ liệu search trong state
     */
    onSubmitSearch = () => {
        this.setState({page: 1}, () => {
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: this.buildQueryString()
            });
        })
    };

    startTimeChange = (date) => {
        this.setState({
            startTime: date ? date.startOf('day').toISOString() : undefined
        })
    };

    endTimeChange = (date) => {
        this.setState({
            endTime: date ? date.endOf('day').toISOString() : undefined
        })
    };

    disabledEndDate = (endValue) => {
        const startValue = this.state.startValue;
        if (!endValue || !startValue) {
            return false;
        }
        return endValue.valueOf() <= startValue.valueOf();
    };

    onCheckBoxCheck = (item, prop) => {
        let {listChecked} = this.state;

        let arrayPropChecked = listChecked[prop] || [];
        if (arrayPropChecked.find(x => x === item[this.getValuePropOfProp(prop)])) {
            arrayPropChecked = arrayPropChecked.filter(x => x !== item[this.getValuePropOfProp(prop)]);
        } else {
            arrayPropChecked.push(item[this.getValuePropOfProp(prop)]);
        }

        listChecked[prop] = arrayPropChecked;

        this.setState({
            listChecked
        })
    };

    onChangeDes = (str, item) => {
        this.onEdit(item, { description: str })
    };

    /**
     * render orderStatus Search
     * @param item
     * @param index
     * @param prop
     */
    renderStatusSearchItem = (item, index, prop) => {
        const arrayPropChecked = this.state.listChecked[prop] || [];
        // console.log('arrayPropChecked', arrayPropChecked)
        let checked = Array.isArray(arrayPropChecked) && arrayPropChecked.find(x => x === item[this.getValuePropOfProp(prop)]) !== undefined;
        return <Tag.CheckableTag
            key={index}
            onChange={() => this.onCheckBoxCheck(item, prop)}
            className={`_check-${prop}-item cursor-pointer txt-size-h7 robotoregular txt-capitalize box-shadow-blue pdl10 pdr10`}
            checked={checked}
        >
            <Typography.Link style={checked ? {color: '#ffffff'} : {}}>{item.name}</Typography.Link>
        </Tag.CheckableTag>
    };

    /**
     * render header page
     * @returns {*}
     */
    renderSearchBox = () => {
        const {
            listWaybillStatuses = [],
            startTime,
            endTime,
            querySearch
        } = this.state;
        const {t} = this.props;

        return (
            <div className="searchbox bg-color-white pd20 mgbt20">
                <Row className={'borderdotted-bootom-2x bd-color-gray pdbt20'}>
                    <Col span={24} className="dpl-flex">
                        <span className={'searchbox__label txt-color-black txt-size-h7 robotoregular'}>{t('delivery.status')}:</span>
                        <div className={'txt-size-h7 txt-color-blue robotoregular txt-capitalize width-100-percent flex-1-1'}>
                            {listWaybillStatuses.map((item, index) => this.renderStatusSearchItem(item, index, 'statuses'))}
                        </div>
                    </Col>
                </Row>
                <Row className={'dpl-flex bd-color-gray borderdotted-bootom-2x pdbt20 pdt15'}>
                    <Col span={12} className="pdr10">
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('shipment.wayBill')}:
                        </div>
                        <Input
                            value={querySearch}
                            onChange={(e) => this.setState({querySearch: e.target.value})}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.onSubmitSearch()
                                }
                            }}
                            className={'_search-box-input-query'}
                        />
                    </Col>
                    <Col span={12} className="pdl10">
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('waybill.receivedTime')}:
                        </div>
                        <Row gutter={20}>
                            <Col span={12}>
                                <DatePicker
                                    disabledDate={this.disabledStartDate}
                                    className="_datepicker width-100-percent"
                                    format={'DD/MM/YYYY'}
                                    value={startTime ? moment(startTime) : undefined}
                                    placeholder={t('package.start_date')}
                                    onChange={this.startTimeChange}
                                    // onOpenChange={this.handleStartOpenChange}
                                />
                            </Col>
                            <Col span={12}>
                                <DatePicker
                                    disabledDate={this.disabledEndDate}
                                    className="_datepicker width-100-percent"
                                    format={'DD/MM/YYYY'}
                                    value={endTime ? moment(endTime) : undefined}
                                    placeholder={t('package.end_date')}
                                    onChange={this.endTimeChange}
                                    // onOpenChange={this.handleEndOpenChange}
                                />
                            </Col>
                        </Row>

                    </Col>

                </Row>
                <div
                    className=" dpl-flex align-items-center justify-content-between pdt10"
                >
                    <div />
                    <div className="dpl-flex align-items-center">
                        <span onClick={this.onClearSearch} className="_btn-clear mgr20 txt-color-gray txt-size-h8 cursor-pointer robotoregular">
                            <AntdIcon.SyncOutlined type={'sync'} className={'mgr5'}/> {t('order.filter_refresh')}
                        </span>
                        <Button onClick={this.onSubmitSearch} type="primary" className={'_btn-search min-width-200'}>{t('order.search')}</Button>
                    </div>
                </div>
            </div>

        )
    };

    renderModalCreate = () => {
        const {t} = this.props;
        const {stringNewWaybillsError, stringNewWaybills, showModalCreate, note, noteError} = this.state;
        return <Modal
            title={t('waybill.create_waybill')}
            visible={showModalCreate}
            onCancel={() => this.setState({
                showModalCreate: false
            })}
            onOk={this.onSubmit}
            okText={t('customer_info.new_address')}
            cancelText={t('modal.cancel')}
            okButtonProps={{className: "_btn-submit-confirm"}}
            cancelButtonProps={{className: "_btn-cancel-confirm"}}
        >
            <Input.TextArea
                placeholder={t('shipment.wayBills')}
                value={stringNewWaybills}
                onChange={e => {
                    this.setState({stringNewWaybills: e.target.value})
                }}
                rows={6}
                // onBlur={this.onValidateUsernameInput}
                onKeyDown={(e) => {
                    if ((e.metaKey || e.shiftKey || e.altKey || e.ctrlKey) && e.keyCode === 13) {
                        this.onSubmit()
                    }
                }}
            />
            {
                !!stringNewWaybillsError && <div className="txt-color-red txt-size-h8 pdt5">{stringNewWaybillsError}</div>
            }

            <div className="txt-color-gray2 txt-size-h8 pdt5 pdbt5">{t('waybill.string_waybills_note')}</div>

            <div className="txt-color-black txt-size-h7 pdt15 pdbt5">{t('waybill.customer_note')}</div>
            <Input.TextArea
                placeholder={t('customerAddress.typing_note')}
                value={note}
                onChange={e => {
                    this.setState({note: e.target.value})
                }}
                rows={3}
                // onBlur={this.onValidateUsernameInput}
                onKeyDown={(e) => {
                    if ((e.metaKey || e.shiftKey || e.altKey || e.ctrlKey) && e.keyCode === 13) {
                        this.onSubmit()
                    }
                }}
            />
            {
                !!noteError && <div className="txt-color-red txt-size-h8 pdt5">{noteError}</div>
            }
        </Modal>
    };

    render() {
        const {
            listWaybills = [],
            isLoading,
            metadata,
            listWaybillStatuses = [],
            onExport
        } = this.state;
        const {t} = this.props;
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        let  isSupplier = lodash.get(currentProjectInfo,'tenantConfig.generalConfig.isSupplier',false);
        let columns = [{
            title: t('shipment.wayBill'),
            dataIndex: 'code',
            key: 'code',
            className: 'whitespace',
            render: (text) =>
                <Typography.Paragraph style={{marginBottom: 0}} copyable={{ text: text }}>
                    <a href={`https://m.kuaidi100.com/result.jsp?nu=${text}`} target={'_blank'} className="_table-row-code whitespace" rel="noreferrer">{text}</a>
                </Typography.Paragraph>

        }];
        if(isSupplier){
            columns.push(
                {
                    title: t('orderDetail.refCustomerCode'),
                    dataIndex: 'refCustomerCode',
                    key: 'refCustomerCode',
                    className: 'whitespace',
                    render: (text, row) => text ? text : '---'
                }
            )
        }
        columns = columns.concat([
            {
                title: t('package.order_code'),
                dataIndex: 'shipmentCode',
                key: 'shipmentCode',
                className: 'whitespace',
                render: (text, row) => text ? <Link to={`/shipments/${text}`} className="_table-row-orderCode txt-uppercase">{text}</Link> : '---'
            }, {
                title: t('waybill.receiving_warehouse'),
                dataIndex: 'receivingWarehouse',
                key: 'receivingWarehouse',
                className: 'whitespace',
                render: (text, row) => text && text.name ? text.name : '---'
            }, {
                title: t('waybill.customer_note'),
                dataIndex: 'description',
                key: 'description',
                className: 'whitespace-pre-wrap max-width-400',
                render: (text, row) => {
                    // return <InputEdit
                    //     type={'area'}
                    //     onPressEnter={(value) => {
                    //         if(value!==text)
                    //             this.updateStaffNote(record.code, value);
                    //     }
                    //     }
                    //     loading={isUpdating}
                    //     value={lodash.get(row, 'description', '')}
                    // />;
                    return <Typography.Paragraph
                        style={{marginBottom: 0}}
                        className={`${this.state[`onEdit${row.id}`] ? 'opacity6' : ''}`}
                        editable={{ onChange: (str) => this.onChangeDes(str, row) }}>
                        {text || ''}
                    </Typography.Paragraph>;
                }
            }, {
                title: t('tickets.status'),
                dataIndex: 'status',
                key: 'status',
                className: 'whitespace',
                render: (text, row) => {
                    let itemStatus = listWaybillStatuses.find(x => x.code === text) || {};
                    return <Tag style={{backgroundColor: `#${intToRGB(hashCode(text))}`}} className={'_table-row-status listorders__items_right--status whitespace'}>{itemStatus.name}</Tag>
                },
            }, {
                title: t('waybill.receivedTime'),
                dataIndex: 'receivedTime',
                key: 'receivedTime',
                className: 'whitespace',
                render: (text) => <span className="_table-row-createdAt whitespace">{text ? moment(text).format('DD/MM/YYYY') : '---'}</span>
            }, {
                title: t('package.created_at'),
                dataIndex: 'createdAt',
                key: 'createdAt',
                className: 'whitespace',
                render: (text) => <span className="_table-row-createdAt whitespace">{moment(text).format('DD/MM/YYYY')}</span>
            }, {
                title: "",
                dataIndex: 'action',
                key: 'action',
                className: 'whitespace',
                render: (text, record) => record.status === "INITIALIZATION" ? <Popconfirm
                    placement="topRight"
                    overlayClassName={`_address-delete-popconfirm-overlay`}
                    className={`_address-delete-popconfirm`}
                    title={t('message.delete_confirm')}
                    onConfirm={() => this.confirmDelete(record.code)}
                    okText={t('button.yes')}
                    cancelText={t('button.no')}
                >
                    <span className={`_delete-btn cursor-pointer`}>{t('button.delete')}</span>
                </Popconfirm> : ''
            }
        ])

        return <MainLayout
            ref={ref => this.mainLayoutElement = ref}
            {...this.props}
            showProgressBar={this.state.showProgressBar}
            headerTitle={t('shipment.wayBills')}
            title={t('shipment.wayBills')}
        >
            <div className={'container-common pdbt30 mgr20'}>
                {this.renderSearchBox()}
                <div className="dpl-flex justify-content-between align-items-center bg-color-white pd20 border-bottom-1x bd-color-gray">
                    {
                        metadata
                        ?
                        <span className={'txt-color-black txt-size-h5 robotobmedium'}>{t('shipment.wayBills')} (<span className={'_total-item-in-page'}>{quantityFormat(metadata.total)}</span>)</span>
                        :
                        <span />
                    }

                    <div>
                        <span
                            onClick={() => this.setState({showModalCreate: true, stringNewWaybills: '', stringNewWaybillsError: '', note: ''})}
                            className="_btn-create-waybills ant-btn ant-btn-primary ant-btn-background-ghost cursor-pointer position-re pdr30 pdl30 mgr20"
                        >
                            {t('waybill.create_waybill')}
                        </span>

                        <ExportExcel
                            {...this.props}
                            validate={() => {
                                if (!this.checkExportBtn()) {
                                    notification['error']({
                                        message: t('transaction.export_csv_btn_error'),
                                    });
                                    return true
                                }
                                return false;
                            }}
                            onExport={onExport}
                            exportExcel={this.exportExcel}
                        />
                    </div>
                </div>
                <div className="pd20 bg-color-white">
                    {
                        Array.isArray(listWaybills) && listWaybills.length > 0
                            ?
                            <Table
                                // ref={ref => this.table = ref}
                                className={'_table-data custom-empty table-expand-row'}
                                rowKey={'createdAt'}
                                columns={columns}
                                dataSource={listWaybills}
                                rowClassName={() => `_table-row`}
                                pagination={{
                                    className: '_pagination text-center width-100-percent mgt20',
                                    hideOnSinglePage: true,
                                    current: metadata ? parseFloat(metadata.page) + 1 : 1,
                                    total: metadata ? parseFloat(metadata.total) : 1,
                                    pageSize: metadata ? parseFloat(metadata.size) : pageSize,
                                    onChange: (page, pageSize) => this.onPageChanged(page, pageSize)
                                }}
                            />
                            :
                            isLoading ?
                                <Loading/> :
                                <div className="pdt50 pdbt60 bg-color-white">
                                    <Empty
                                        image={require('../../resources/images/empty-image.png')}
                                        description={t('message.empty')}
                                    />
                                </div>
                    }
                </div>
                {this.renderModalCreate()}
            </div>
        </MainLayout>;
    }
}
export default withNamespaces()(Waybills);
