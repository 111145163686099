/* eslint-disable array-callback-return */
import React, { Fragment } from 'react'
import MainLayout from '../../components/Layout/MainLayout'

import ProfileSidebar from '../../components/Profile/Leftbar'
import { LocalStore } from '../../utils/LocalStore'

import { Row, Col, notification, Collapse, Table, Checkbox, Switch, Spin, Card } from 'antd'
import * as AntdIcon from '@ant-design/icons'
import { withNamespaces } from 'react-i18next'
import NotificationServices from '../../services/notifications.service'
import CategoriesService from '../../services/categories.service'

const Panel = Collapse.Panel

class NotificationConfig extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeNotification: 0,
            listNotification: [],
            eventsSetting: [],
            listStatuses: [],
            listShipmentStatuses: [],
        }
    }

    componentWillMount() {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null

        if (typeof LocalStore.getInstance().read('currentLoggedUser') === 'object') {
            this.setState({ isLoading: true }, () => {
                this.getNotificationChannel()
                this.getEventSettings()
                this.getNotificationEvents()
                this.getNotificationEventGroups()
                this.getOrderStatuses()
                this.getShipmentStatuses()
            })
        }
    }

    getOrderStatuses = () => {
        CategoriesService.getOrderStatuses((err, res) => {
            if (!err) {
                this.setState({
                    listStatuses: res,
                })
            }
        })
    }

    getShipmentStatuses = () => {
        CategoriesService.getShipmentStatuses((err, res) => {
            if (!err) {
                this.setState({
                    listShipmentStatuses: res,
                })
            }
        })
    }

    getListNotificationSetting = cb => {
        const { events } = this.state

        this.setState({ showProgressBar: true }, () => {
            NotificationServices.getNotificationSettings((err, res) => {
                if (!err) {
                    let current = null
                    let state = {}
                    if (Array.isArray(res)) {
                        res.map(item => {
                            current = events.find(x => x.eventCode === item.eventCode)
                            if (current) {
                                ;['web', 'zalo', 'email', 'telegram'].forEach(channel => {
                                    if (item[channel]) {
                                        if (Array.isArray(state[`${current.groupCode}_${channel}_listSelected`])) {
                                            state[`${current.groupCode}_${channel}_listSelected`].push(current)
                                        } else {
                                            state[`${current.groupCode}_${channel}_listSelected`] = [current]
                                        }
                                        state[`${current.groupCode}_${channel}_checkAll`] = true
                                    }
                                })
                            }
                        })
                        this.setState({ ...state, listNotification: res })
                    }
                }
                if (cb) cb()
                this.setState({ showProgressBar: false, isLoading: false })
            })
        })
    }

    getNotificationChannel = () => {
        this.setState({ showProgressBar: true }, () => {
            NotificationServices.getNotificationChannel((err, res) => {
                if (!err) {
                    this.setState({ channels: res })
                } else {
                    this.setState({ isLoading: false, showProgressBar: true })
                }
            })
        })
    }

    getEventSettings = () => {
        this.setState({ showProgressBar: true }, () => {
            NotificationServices.getEventSettings((err, res) => {
                if (!err) {
                    this.setState({ eventsSetting: res })
                } else {
                    this.setState({ isLoading: false, showProgressBar: true })
                }
            })
        })
    }

    getNotificationEvents = () => {
        this.setState({ showProgressBar: true }, () => {
            NotificationServices.getNotificationEvents((err, res) => {
                if (!err) {
                    this.setState({ events: res }, () => {
                        this.getListNotificationSetting()
                    })
                } else {
                    this.setState({ isLoading: false, showProgressBar: true })
                }
            })
        })
    }

    getNotificationEventGroups = () => {
        this.setState({ showProgressBar: true }, () => {
            NotificationServices.getNotificationEventGroups((err, res) => {
                if (!err) {
                    this.setState({ eventGroups: res })
                } else {
                    this.setState({ isLoading: false, showProgressBar: true })
                }
            })
        })
    }

    /**
     * check all Email
     * @param e
     * @param groupCode
     * @param channelCode
     */
    onCheckAll = (e, groupCode, channelCode) => {
        let { events = [] } = this.state

        // console.log('onCheckAll', groupCode, channelCode);

        const currentEvents = events.filter(x => x.groupCode === groupCode)
        this.onUpdatePatchNotification(e.target.checked, channelCode, currentEvents)
        this.setState({
            [`${groupCode}_${channelCode}_listSelected`]: e.target.checked ? currentEvents : [],
            [`${groupCode}_${channelCode}_indeterminate`]: false,
            [`${groupCode}_${channelCode}_checkAll`]: e.target.checked,
        })
    }

    /**
     * update batch notification config
     */
    onUpdatePatchNotification = (isChecked, channelCode, listEvents) => {
        const { t } = this.props
        const { confirmLoading } = this.state
        const mapKeyEvent = listEvents.map(x => x.eventCode)
        let body = {
            events: mapKeyEvent,
            channel: channelCode,
            value: isChecked,
        }

        if (confirmLoading) return
        this.setState({ confirmLoading: true }, () => {
            //Thực hiện đẩy dữ liệu và call api
            NotificationServices.updateNotificationSettings(body, err => {
                if (!err) {
                    notification['success']({
                        message: t('message.success'),
                    })
                    this.setState({ confirmLoading: false })
                } else {
                    notification['error']({
                        message: t(err.message),
                    })
                }
            })
        })
    }

    /**
     * receive notification via Email
     * @param item
     * @param groupCode
     * @param channelCode
     */
    onChangeItem = (groupCode, channelCode, item) => {
        let { events = [] } = this.state
        const currentEvents = events.filter(x => x.groupCode === groupCode)
        let currentListSelected = this.state[`${groupCode}_${channelCode}_listSelected`] || []

        let value = false

        if (Array.isArray(currentListSelected) && currentListSelected.find(x => x.eventCode === item.eventCode)) {
            currentListSelected = currentListSelected.filter(x => x.eventCode !== item.eventCode)
        } else {
            currentListSelected.push(item)
            value = true
        }

        this.setState(
            {
                [`${groupCode}_${channelCode}_listSelected`]: currentListSelected,
                [`${groupCode}_${channelCode}_indeterminate`]:
                    !!currentListSelected.length && currentListSelected.length < currentEvents.length,
                [`${groupCode}_${channelCode}_checkAll`]: currentListSelected.length === currentEvents.length,
            },
            () => {
                this.onSubmitNotification(groupCode, channelCode, item, value)
            }
        )
    }

    /**
     * submit notification config
     */
    onSubmitNotification = (groupCode, channelCode, item, value) => {
        let { t } = this.props
        let { confirmLoading } = this.state

        let body = {
            eventCode: item.eventCode,
            [channelCode]: value,
        }

        if (confirmLoading) return
        this.setState({ confirmLoading: true }, () => {
            //Thực hiện đẩy dữ liệu và call api
            NotificationServices.addNotificationSettings(body, err => {
                if (!err) {
                    notification['success']({
                        message: t('message.success'),
                    })
                    this.setState({ confirmLoading: false })
                } else {
                    notification['error']({
                        message: t(err.message),
                    })
                    this.setState({ confirmLoading: false })
                }
                this.getListNotificationSetting()
            })
        })
    }

    /**
     * render header của panel
     * @param item
     */
    renderPanelHeader = item => {
        return (
            <div className={`panel-notification-header _notificationTitle`}>
                <span className="text-black-100 text-base pdr5 txt-uppercase bold">{item.name}</span>
            </div>
        )
    }

    renderGroupNotification = item => {
        const { events = [], eventsSetting } = this.state
        const currentEvents = events
            .filter(x => x.groupCode === item.code)
            .map(x => {
                const eventSetting = eventsSetting.find(xx => xx.eventCode === x.eventCode)
                if (!eventSetting || !eventSetting.channels || eventSetting.channels.length === 0) return null

                return { ...x, channelsSetting: eventSetting.channels }
            })
            .filter(Boolean)
        if (currentEvents.length === 0) return null
        return (
            <Card
                className=" mb-2 px-4 py-3 rounded-2xl"
                bodyStyle={{ padding: 0 }}
            >
                <Collapse
                    ghost
                    expandIconPosition="end"
                    expandIcon={({ isActive }) =>
                        isActive ? (
                            <div>
                                <i class="fa-solid fa-chevron-up text-base"></i>
                            </div>
                        ) : (
                            <div>
                                <i class="fa-solid fa-chevron-down text-base"></i>
                            </div>
                        )
                    }
                    className="notification-collaspe"
                >
                    <Panel header={this.renderPanelHeader(item)}>{this.renderContentNotification(item, currentEvents)}</Panel>
                </Collapse>
            </Card>
        )
    }

    /**
     * render content notification
     * @return {*}
     */
    renderContentNotification = (item, currentEvents) => {
        const { channels = [], listStatuses, listNotification, listShipmentStatuses } = this.state
        const { t } = this.props
        const columns = [
            {
                dataIndex: 'name',
                key: 'name',
                className: 'bg-color-white _notification-name',
                render: text => {
                    return (
                        <div>
                            <span className="_financial-type-detail vertical-align-middle txt-size-h7 break-word text-black-100 roboregular mgbt5 ">
                                {text}
                            </span>
                        </div>
                    )
                },
            },
        ]

        channels.map(x => {
            const checkedList = currentEvents.map(v => !v.channelsSetting.includes(x.channel))
            columns.push({
                title: () => {
                    return (
                        <Fragment>
                            <Checkbox
                                disabled={checkedList.filter(Boolean).length === currentEvents.length}
                                checked={
                                    checkedList.filter(Boolean).length < currentEvents.length &&
                                    this.state[`${item.code}_${x.channel}_checkAll`]
                                }
                                indeterminate={this.state[`${item.code}_${x.channel}_indeterminate`]}
                                onChange={e => this.onCheckAll(e, item.code, x.channel)}
                            />
                            <span className="ml-2 txt-size-h7 text-black-100">{x.name}</span>
                        </Fragment>
                    )
                },
                dataIndex: x.channel,
                key: x.channel,
                className: `bg-color-white  _notification_listcheck table__name`,
                render: (text, record) => {
                    return (
                        <Checkbox
                            disabled={!record.channelsSetting.includes(x.channel)}
                            checked={
                                record.channelsSetting.includes(x.channel) &&
                                Array.isArray(this.state[`${item.code}_${x.channel}_listSelected`]) &&
                                !!this.state[`${item.code}_${x.channel}_listSelected`].find(i => i.eventCode === record.eventCode)
                            }
                            onChange={() => this.onChangeItem(item.code, x.channel, record)}
                        />
                    )
                },
            })
        })

        return (
            <Table
                className={'_notification-table pdl10 table-settings-notification'}
                rowKey={record => record.id}
                columns={columns}
                dataSource={currentEvents || []}
                pagination={{
                    hideOnSinglePage: true,
                    simple: true,
                    pageSize: Array.isArray(currentEvents) ? currentEvents.length : 0,
                }}
                expandable={{
                    showExpandColumn: false,
                    expandedRowRender: record => {
                        const item = listNotification?.find(i => i?.eventCode === record?.eventCode)
                        const listStatus = record?.eventCode === 'ORDER_STATUS_UPDATE' ? listStatuses : listShipmentStatuses
                        const listArray = item?.additionalCondition
                            ? JSON.parse(
                                  item?.additionalCondition
                                      ?.replace('{', '[')
                                      ?.replace('}', ']')
                                      ?.replaceAll("'", '"')
                                      .replace('.contains', '')
                                      .replace(/\(.*?\)/g, '')
                              )
                            : []
                        return (
                            <Collapse
                                ghost
                                expandIconPosition="end"
                                expandIcon={({ isActive }) =>
                                    isActive ? (
                                        <div>
                                            <i class="fa-solid fa-chevron-up text-base"></i>
                                        </div>
                                    ) : (
                                        <div>
                                            <i class="fa-solid fa-chevron-down text-base"></i>
                                        </div>
                                    )
                                }
                                className="notification-collaspe-p-0"
                            >
                                <Panel
                                    header={`(${t('notificationSettings.listStatusNoti')})`}
                                    key={record?.eventCode}
                                >
                                    <Row gutter={40}>
                                        {Array.isArray(listStatus) &&
                                            listStatus.map((item, index) => {
                                                return (
                                                    <Col
                                                        span={8}
                                                        key={index}
                                                    >
                                                        <Row
                                                            align="middle"
                                                            className=" mb-6"
                                                            gutter={12}
                                                        >
                                                            <Col>
                                                                <Switch
                                                                    defaultChecked={listArray?.includes(item?.code)}
                                                                    onChange={value => {
                                                                        let newArray = null
                                                                        if (value) {
                                                                            newArray = [...listArray, item?.code]
                                                                        } else {
                                                                            newArray = listArray?.filter(i => i !== item?.code)
                                                                        }
                                                                        const data =
                                                                            newArray?.length > 0
                                                                                ? `${JSON.stringify(newArray)
                                                                                      ?.replace('[', '{')
                                                                                      ?.replace(']', '}')
                                                                                      ?.replaceAll(
                                                                                          '"',
                                                                                          "'"
                                                                                      )}.contains(statusCode)`
                                                                                : null
                                                                        this.onSubmitNotification(
                                                                            null,
                                                                            'additionalCondition',
                                                                            record,
                                                                            data
                                                                        )
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col>{item.name}</Col>
                                                        </Row>
                                                    </Col>
                                                )
                                            })}
                                    </Row>
                                </Panel>
                            </Collapse>
                        )
                    },
                    defaultExpandAllRows: true,
                    expandIcon: () => <></>,
                    rowExpandable: record =>
                        record?.eventCode === 'ORDER_STATUS_UPDATE' || record?.eventCode === 'SHIPMENT_STATUS_UPDATE',
                }}
            />
        )
    }

    render() {
        const { confirmLoading, isLoading, eventGroups = [] } = this.state
        const { t } = this.props
        return (
            <MainLayout
                {...this.props}
                showProgressBar={this.state.showProgressBar}
                headerTitle={t('userProfile.notification_config')}
                title={t('userProfile.notification_config')}
            >
                <div className={'container-common pdbt30 pdt20 mgr20'}>
                    <Row>
                        <Col
                            span={6}
                            className={'pdr20'}
                        >
                            <ProfileSidebar currentRoute={'notification'} />
                        </Col>
                        <Col
                            span={18}
                            className="pt-[45px]"
                        >
                            <h3 className="txt-color-gray txt-size-h7 mb-6">{t('userProfile.notification_config_des')}</h3>

                            {isLoading ? (
                                <div className="dpl-flex align-items-center justify-content pdt30 pdbt30">
                                    <Spin
                                        indicator={
                                            <AntdIcon.Loading3QuartersOutlined
                                                type="loading"
                                                style={{ fontSize: 24 }}
                                                spin
                                            />
                                        }
                                    />
                                </div>
                            ) : (
                                <Spin
                                    spinning={confirmLoading === true}
                                    indicator={
                                        <AntdIcon.Loading3QuartersOutlined
                                            type="loading"
                                            style={{ fontSize: 24 }}
                                            spin
                                        />
                                    }
                                >
                                    {eventGroups.map((item, index) => this.renderGroupNotification(item, index))}
                                </Spin>
                            )}
                        </Col>
                    </Row>
                </div>
            </MainLayout>
        )
    }
}
export default withNamespaces()(NotificationConfig)
