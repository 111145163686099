/* eslint-disable array-callback-return */
import React from 'react';
import { Alert, Button, Col, Input, InputNumber, Modal, notification, Radio, Row, Steps } from 'antd'
import * as AntdIcon from "@ant-design/icons"
import lodash, { debounce } from 'lodash'
import { withNamespaces } from "react-i18next";
import { LocalStore } from "../../utils/LocalStore";
import UserService from "../../services/user.service";
import UserServices from "../../services/user.service";
import { moneyFormat } from "../../utils/stringUtils";

let check = false;
class ModalCreatePeerPayment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentLoggedUser: {},
            bodyData: {},
            step: 1
        };
        this.onBlurInputAmountDebounce = debounce(this.onBlurInputAmount, 300)
    }

    // scroll top khi bắt đầu load trang
    componentDidMount() {
        this.setState({
            currentProjectInfo: LocalStore.getInstance().read('currentProjectInfo') || {},
            currentLoggedUser: LocalStore.getInstance().read('currentLoggedUser') || {}
        }, () => {
            const { tenantConfigPayment } = this.props
            let type = ''

            if (lodash.get(tenantConfigPayment, 'config.transferAlipay') && lodash.get(tenantConfigPayment, 'config.transferBank')) {
                type = 'bank_transfer'
            } else if (lodash.get(tenantConfigPayment, 'config.transferAlipay')) {
                type = 'alipay'
            } else {
                type = 'bank_transfer'
            }

            this.setState({
                bodyData: {
                    paymentMethodCode: type
                }
            })
        });
    }

    /**
     * lấy số dư tài khoản của khách hàng
     */
    getBalanceUser = (cb) => {
        UserServices.getBalanceUser((err, res) => {
            if (!err) {
                this.setState({
                    balance: res
                }, () => {
                    if (cb) cb()
                })
            }
        })
    };

    /**
     * lấy ra danh sách trạng thái của package
     */
    getExchangeRate = (query = "", cb) => {
        UserService.getExchangeRatePeerPayMent(query, (err, res, headers) => {
            if (err === null) {
                this.setState({
                    exchangeRate: res || {},
                }, () => {
                    if (cb) cb()
                });
            }
        });
    };

    onInputChange = (value, prop, cb) => {
        let bodyData = this.state.bodyData;

        bodyData[prop] = value;

        this.setState({
            bodyData
        }, () => {
            if (cb) cb()
        })
    };

    onBlurInputAmount = (cb) => {
        const { bodyData = {}, amountQuery, paymentMethodCodeQuery } = this.state;
        // console.log('bodyData', bodyData, paymentMethodCodeQuery)
        if (!cb) {
            if (amountQuery === bodyData.amount && paymentMethodCodeQuery === bodyData.paymentMethodCode) return
        }
        if (!bodyData.amount) return
        let query = `?amount=${bodyData.amount}&paymentMethodCode=${bodyData.paymentMethodCode}`
        this.setState({ amountQuery: bodyData.amount, paymentMethodCodeQuery: bodyData.paymentMethodCode }, () => {
            this.getExchangeRate(query, cb)
        })
    };

    onSubmit = (totalMoney) => {
        const { t, loadFromQueryString } = this.props;
        const { bodyData = {}, isSubmit } = this.state;

        if (!this.onValidate()) {
            return
        }

        if (check) return;

        check = true;

        // this.getBalanceUser(() => {
        //
        //     const {balance = {}} = this.state;
        //
        //     if (balance.balance + balance.creditLimit < totalMoney) {
        //         notification['error']({
        //             message: t('message.not_enough_money')
        //         });
        //         check = false;
        //         return
        //     }

        this.setState({
            isSubmit: true,
        }, () => {
            if (isSubmit === true) return;
            UserService.createPeerPayment(bodyData, (err, res, header) => {
                if (!err) {
                    notification['success']({
                        message: t('message.success'),
                    });
                    this.setState({ isSubmit: false, showModalAddProduct: false }, () => {
                        check = false
                    });
                    if (loadFromQueryString) loadFromQueryString()
                } else {
                    if (err.title === 'insufficient_balance') {
                        this.setState({ showModalAddProduct: false });
                        if (loadFromQueryString) loadFromQueryString()
                        notification['success']({
                            message: t('message.success'),
                        });
                        // notification['error']({
                        //     message: t('message.not_enough_money')
                        // });
                    } else if (err.title === 'customer_not_found') {
                        notification['error']({
                            message: t('peer_payment.customer_not_found')
                        });
                    } else if (err.title === 'payment_method_not_found') {
                        notification['error']({
                            message: t('peer_payment.payment_method_not_found')
                        });
                    } else if (err.title === 'payment_account_not_found') {
                        notification['error']({
                            message: t('peer_payment.payment_account_not_found')
                        });
                    } else if (err.title === 'shipment_required_when_create_pp') {
                        notification['error']({
                            message: t('peer_payment.shipment_required_when_create_pp')
                        });
                    } else {
                        notification['error']({
                            message: t(err.message)
                        });
                    }

                    this.setState({ isSubmit: false }, () => {
                        check = false
                    });
                }
            });
        });
        // })

    };

    onGetFee = () => {
        if (!this.onValidate()) {
            return
        }
        this.onBlurInputAmount(() => {
            const { bodyData = {}, isSubmit, exchangeRate = {} } = this.state;

            let api = UserService.getPaymentQuotation;

            if (check) return;

            check = true;

            this.setState({
                isSubmit: true,
            }, () => {
                if (isSubmit === true) return;
                let payload = {
                    ...bodyData,
                    exchangeRate: exchangeRate.rate,
                };
                api(payload, (err, res, header) => {
                    if (!err) {
                        this.setState({
                            isSubmit: false,
                            draftFees: res,
                            step: 2,
                        }, () => {
                            check = false
                        });
                    } else {

                        this.setState({ isSubmit: false }, () => {
                            check = false
                        });
                    }
                });
            });
        })
    }

    onValidate = () => {
        let { bodyData = {}, error = {} } = this.state;

        const { t } = this.props;
        let check = 0;
        if (!bodyData.amount) {
            error.amount = t('order.quantity_required');
            check = check + 1
        } else {
            error.amount = ''
        }

        if (!bodyData.memo || !(bodyData.memo.trim())) {
            error.memo = t('order.quantity_required');
            check = check + 1
        } else {
            if (bodyData.memo.length > 50) {
                error.memo = t('peer_payment.memo_error');
                check = check + 1
            } else {
                error.memo = ''
            }
        }

        if (bodyData.note) {
            if (bodyData.note.length > 50) {
                error.note = t('peer_payment.memo_error');
                check = check + 1
            } else {
                error.note = ''
            }
        }

        if (!bodyData.beneficiaryAccount || !(bodyData.beneficiaryAccount.trim())) {
            error.beneficiaryAccount = t('order.quantity_required');
            check = check + 1
        } else {
            error.beneficiaryAccount = ''
        }

        if (!bodyData.beneficiaryName || !(bodyData.beneficiaryName.trim())) {
            error.beneficiaryName = t('order.quantity_required');
            check = check + 1
        } else {
            error.beneficiaryName = ''
        }

        if (bodyData.paymentMethodCode !== 'alipay') {
            if (!bodyData.beneficiaryBank || !(bodyData.beneficiaryBank.trim())) {
                error.beneficiaryBank = t('order.quantity_required');
                check = check + 1
            } else {
                error.beneficiaryBank = ''
            }
        }

        this.setState({ error });
        if (check > 0) {
            return false
        }
        return true
    };

    renderFormAlipay = () => {
        const { t } = this.props;
        const { bodyData = {}, error = {} } = this.state;
        return <div>
            <Row className="mgbt20 _container_beneficiaryAccount">
                <div className="pdbt5">{t('peer_payment.beneficiaryAccount')} <span className="txt-color-red">*</span></div>
                <Input
                    className={'_input_beneficiaryAccount'}
                    placeholder={t('peer_payment.beneficiaryAccount_placeholder')}
                    onChange={(e) => this.onInputChange(e.target.value, 'beneficiaryAccount')}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            this.onGetFee()
                        }
                    }}
                    value={bodyData.beneficiaryAccount}
                />
                {
                    error.beneficiaryAccount &&
                    <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">
                        {error.beneficiaryAccount}
                    </div>
                }
            </Row>

            <Row className="mgbt20 _container_beneficiaryName">
                <div className="pdbt5">{t('peer_payment.beneficiaryName')} <span className="txt-color-red">*</span></div>
                <Input
                    className={'_input_beneficiaryName'}
                    placeholder={t('peer_payment.beneficiaryName_placeholder')}
                    onChange={(e) => this.onInputChange(e.target.value, 'beneficiaryName')}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            this.onGetFee()
                        }
                    }}
                    value={bodyData.beneficiaryName}
                />
                {
                    error.beneficiaryName &&
                    <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">
                        {error.beneficiaryName}
                    </div>
                }
            </Row>

            <Row className="mgbt20 _container_memo">
                <div className="pdbt5">{t('peer_payment.memo')} <span className="txt-color-red">*</span></div>
                <Input.TextArea
                    placeholder={t('peer_payment.memo_placeholder')}
                    className={'_input_beneficiaryName'}
                    onChange={(e) => this.onInputChange(e.target.value, 'memo')}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            this.onGetFee()
                        }
                    }}
                    value={bodyData.memo}
                />
                {
                    error.memo &&
                    <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">
                        {error.memo}
                    </div>
                }
            </Row>

            <Row className="_container_note">
                <div className="pdbt5">{t('peer_payment.note')}</div>
                <Input.TextArea
                    placeholder={t('peer_payment.note_placeholder')}
                    className={'_input_beneficiaryName'}
                    onChange={(e) => this.onInputChange(e.target.value, 'note')}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            this.onGetFee()
                        }
                    }}
                    value={bodyData.note}
                />
                {
                    error.note &&
                    <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">
                        {error.note}
                    </div>
                }
            </Row>
        </div>
    };

    renderFormBank = () => {
        const { t } = this.props;
        const { bodyData = {}, error = {} } = this.state;
        return <div>
            <Row gutter={20} className="mgbt20">
                <Col span={12} className="_container_bank_name">
                    <div className="pdbt5">{t('peer_payment.bank_name')} <span className="txt-color-red">*</span></div>
                    <Input
                        className={'_input_beneficiaryBank'}
                        placeholder={t('peer_payment.bank_name_placeholder')}
                        onChange={(e) => this.onInputChange(e.target.value, 'beneficiaryBank')}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                this.onGetFee()
                            }
                        }}
                        value={bodyData.beneficiaryBank}
                    />
                    {
                        error.beneficiaryBank &&
                        <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">
                            {error.beneficiaryBank}
                        </div>
                    }
                </Col>
                <Col span={12} className="_container_beneficiaryAccount">
                    <div className="pdbt5">{t('peer_payment.beneficiaryAccount')} <span className="txt-color-red">*</span></div>
                    <Input
                        className={'_input_beneficiaryAccount'}
                        placeholder={t('peer_payment.beneficiaryAccount_placeholder')}
                        onChange={(e) => this.onInputChange(e.target.value, 'beneficiaryAccount')}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                this.onGetFee()
                            }
                        }}
                        value={bodyData.beneficiaryAccount}
                    />
                    {
                        error.beneficiaryAccount &&
                        <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">
                            {error.beneficiaryAccount}
                        </div>
                    }
                </Col>
            </Row>

            <Row className="mgbt20 _container_bank_branch">
                <div className="pdbt5">{t('peer_payment.bank_branch')}</div>
                <Input
                    className={'_input_beneficiaryBankBranch'}
                    placeholder={t('peer_payment.bank_branch_enter')}
                    onChange={(e) => this.onInputChange(e.target.value, 'beneficiaryBankBranch')}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            this.onGetFee()
                        }
                    }}
                    value={bodyData.beneficiaryBankBranch}
                />
                {
                    error.beneficiaryBankBranch &&
                    <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">
                        {error.beneficiaryBankBranch}
                    </div>
                }
            </Row>

            <Row className="mgbt20 _container_beneficiaryName">
                <div className="pdbt5">{t('peer_payment.beneficiaryName')} <span className="txt-color-red">*</span></div>
                <Input
                    className={'_input_beneficiaryName'}
                    placeholder={t('peer_payment.beneficiaryName_placeholder')}
                    onChange={(e) => this.onInputChange(e.target.value, 'beneficiaryName')}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            this.onGetFee()
                        }
                    }}
                    value={bodyData.beneficiaryName}
                />
                {
                    error.beneficiaryName &&
                    <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">
                        {error.beneficiaryName}
                    </div>
                }
            </Row>

            <Row className="mgbt20 _container_memo">
                <div className="pdbt5">{t('peer_payment.memo')} <span className="txt-color-red">*</span></div>
                <Input.TextArea
                    placeholder={t('peer_payment.memo_placeholder')}
                    className={'_input_beneficiaryName'}
                    onChange={(e) => this.onInputChange(e.target.value, 'memo')}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            this.onGetFee()
                        }
                    }}
                    value={bodyData.memo}
                />
                {
                    error.memo &&
                    <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">
                        {error.memo}
                    </div>
                }
            </Row>

            <Row className="_container_note">
                <div className="pdbt5">{t('peer_payment.note')}</div>
                <Input.TextArea
                    placeholder={t('peer_payment.note_placeholder')}
                    className={'_input_beneficiaryName'}
                    onChange={(e) => this.onInputChange(e.target.value, 'note')}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            this.onGetFee()
                        }
                    }}
                    value={bodyData.note}
                />
                {
                    error.note &&
                    <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">
                        {error.note}
                    </div>
                }
            </Row>
        </div>
    };

    renderModalAddProduct = () => {
        const { t, listFees = [], tenantConfigPayment } = this.props;
        const { bodyData = {}, error = {}, exchangeRate = {}, isSubmit, step, draftFees = {} } = this.state;

        let totalMoney = 0;
        let showWarning = false;
        if (Array.isArray(draftFees.listFees)) {
            totalMoney = bodyData.amount * exchangeRate.rate
            draftFees.listFees.map(item => {
                totalMoney = totalMoney + item.provisionalAmount

                if (item.provisionalAmount === null || item.provisionalAmount === undefined) {
                    showWarning = true;
                }
            })
        }

        return <Modal
            title={t('peer_payment.create_transfer')}
            visible={this.state.showModalAddProduct}
            onCancel={() => {
                if (step > 1) {
                    this.setState({
                        step: step - 1,
                    })
                } else {
                    this.setState({
                        showModalAddProduct: false
                    })
                }
            }}
            width={600}
            onOk={() => {
                if (step === 1) {
                    this.onGetFee()
                } else {
                    this.onSubmit(totalMoney)
                }
            }}
            okText={t('modal.confirm')}
            cancelText={step > 1 ? t('forgot_pass.back') : t('button.cancel')}
            okButtonProps={{ className: "_btn-submit-confirm", disabled: isSubmit || !exchangeRate.rate}}
            cancelButtonProps={{ className: "_btn-cancel-confirm" }}
        >
            <div>

                <div className="max-width-400 pdbt25" style={{ margin: 'auto' }}>
                    <Steps current={Number.isFinite(parseFloat(step)) ? parseFloat(step) - 1 : 0}>
                        <Steps.Step title={t('peer_payment.transfer_info')} />
                        <Steps.Step title={t('modal.confirm')} />
                    </Steps>
                </div>
                {
                    step === 1 ? <div>
                        <Row gutter={20} type={'flex'} className="mgbt20">
                            <Col span={12} className="_container_amount">
                                <div className="pdbt5">{t('peer_payment.amount')} <span className="txt-color-red">*</span></div>
                                <InputNumber
                                    precision={2} // bắt chỉ cho nhập integer
                                    className={`_input_amount width-100-percent`}
                                    placeholder={t('peer_payment.amount_placeholder')}
                                    min={1}
                                    value={bodyData.amount}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}

                                    onBlur={(e) => this.onBlurInputAmount()}

                                    onChange={(value) => {
                                        if (typeof value === 'number') {
                                            // console.log('chán', value)
                                            this.onInputChange(value, 'amount', this.onBlurInputAmountDebounce)
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            this.onGetFee()
                                        }
                                    }}
                                />
                                {
                                    error.amount &&
                                    <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">
                                        {error.amount}
                                    </div>
                                }

                                {exchangeRate.rate === null && (
                                        <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">
                                           {t('peerPayment.minTransfer', { value: exchangeRate.minPayment})}
                                        </div>
                                )}

                                {
                                    !!exchangeRate.base && <div className={'txt-color-gray mgt15'}>
                                        <span className={"txt-size-h8 txt-color-gray robotoregular"}>{t('header.exchange')}</span>
                                        <span className={'_exchange-rate txt-color-black robotoregular txt-size-h7 mgl5'}>
                                            {moneyFormat(1, exchangeRate.base)} = {moneyFormat(exchangeRate.rate, exchangeRate.exchange)}
                                        </span>
                                    </div>
                                }
                            </Col>

                            <Col span={12} className="_container_exchangedAmount">
                                <div className="exchanged-amount-box">
                                    <div>{t('peer_payment.exchangedAmount')}</div>
                                    <div className="exchanged-amount-box-value">
                                        {bodyData.amount !== undefined ? moneyFormat(bodyData.amount * exchangeRate.rate) : moneyFormat(0)}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Radio.Group
                            className="width-100-percent mgbt20"
                            onChange={(e) => {
                                this.onInputChange(e.target.value, 'paymentMethodCode', () => this.onBlurInputAmount());
                                this.onInputChange("", 'beneficiaryBank')
                            }}
                            value={bodyData.paymentMethodCode}
                        >
                            <Row gutter={20} type={'flex'} justify={'space-between'}>
                                {lodash.get(tenantConfigPayment, 'config.transferAlipay') === true && (
                                    <Col>
                                        <Radio value={'alipay'}>{t('peer_payment.alipay')}</Radio>
                                    </Col>
                                )}

                                {lodash.get(tenantConfigPayment, 'config.transferBank') === true && (
                                    <Col>
                                        <Radio value={'bank_transfer'}>{t('peer_payment.bank_transfer')}</Radio>
                                    </Col>
                                )}
                            </Row>
                        </Radio.Group>

                        {
                            bodyData && bodyData.paymentMethodCode === 'alipay'
                                ?
                                this.renderFormAlipay()
                                :
                                this.renderFormBank()
                        }
                    </div>
                        :
                        <div>
                            <div className="box-fee-peerpayment">

                                <Row>
                                    <Col span={12} className="pdr15 border-right-2x bd-color-gray2">
                                        <div className="dpl-flex pdbt5 justify-content-between align-items-center">
                                            <div className="txt-size-h7">{t('peer_payment.amount')}:</div>
                                            <div className="_bodyData_amount txt-color-black bold">{moneyFormat(bodyData.amount, 'CNY')}</div>
                                        </div>

                                        <div className="dpl-flex pdbt5 justify-content-between align-items-center">
                                            <div className="txt-size-h7">{t('peer_payment.exchangedAmount')}:</div>
                                            <div className="_bodyData_exchangedAmount txt-color-black bold">{bodyData.amount !== undefined ? moneyFormat(bodyData.amount * exchangeRate.rate) : moneyFormat(0)}</div>
                                        </div>
                                    </Col>

                                    <Col span={12} className="pdl15">
                                        <div className="dpl-flex pdbt5 justify-content-between align-items-center">
                                            <div className="txt-color-black bold">{t('fee_tab.service_fee')}:</div>
                                        </div>

                                        {
                                            Array.isArray(draftFees.listFees) && draftFees.listFees.map((item, index) => {
                                                let currentFee = listFees.find(x => x.code === item.feeCode)
                                                return <div key={index} className="dpl-flex pdbt5 justify-content-between align-items-center">
                                                    <div className="_bodyData_fee_label txt-size-h7">{currentFee ? currentFee.name || item.feeCode : item.feeCode}:</div>
                                                    <div className="_bodyData_fee_value txt-color-black bold">{moneyFormat(item.provisionalAmount)}</div>
                                                </div>
                                            })
                                        }

                                    </Col>
                                </Row>
                            </div>
                            <div className="dpl-flex justify-content-between align-items-center pd15 box-fee-peerpayment-total">
                                <div />

                                <div className="txt-color-black">
                                    {t('orderDetail.total_money')}: <span className="_bodyData_total txt-size-h5 txt-color-orange">{moneyFormat(totalMoney)}</span>
                                </div>
                            </div>

                            <Alert
                                className="mgbt10 mgt10 _create_bodyData_warning"
                                message={<span className="txt-size-h8">{t('peer_payment.create_warning_message')}</span>}
                                type="warning"
                                showIcon
                                closable
                            />

                            {
                                showWarning
                                    ? <Alert
                                        className="mgbt10 mgt10 _bodyData_warning"
                                        message={<span className="txt-size-h8">{t('peer_payment.fee_warning')}</span>}
                                        type="warning"
                                        showIcon
                                        closable
                                    />
                                    :
                                    null
                            }
                        </div>
                }
            </div>

        </Modal>
    };

    render() {
        const { t } = this.props;
        return <div className="dpl-il-block">
            <Button
                className="rounded"
                type='primary'
                onClick={() => {
                    this.setState({
                        showModalAddProduct: true,
                        bodyData: {
                            paymentMethodCode: 'bank_transfer'
                        },
                        error: {},
                        exchangeRate: {},
                        amountQuery: 0,
                        step: 1
                    })
                }}
            >
                <AntdIcon.TransactionOutlined type="transaction" style={{ fontSize: 14 }} className="" /> {t('peer_payment.create_transfer')}
            </Button>

            {this.renderModalAddProduct()}
        </div>
    }
}
export default withNamespaces()(ModalCreatePeerPayment)
