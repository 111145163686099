import React from 'react';
import UserServices from '../../services/user.service';
import {moneyCeil, moneyFormat} from "../../utils/stringUtils";

export default class Balance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            balance: 0,
        };
    }

    componentDidMount() {
        this.getBalanceUser();
    }

    static getDerivedStateFromProps(nextProps, preState) {
        let nextState = {...preState};
        if (nextProps.balance !== preState.balance && nextProps.balance != null) {
            nextState.balance = nextProps.balance;
            return nextState;
        }
        return null;
    }
    /**
     * lấy số dư tài khoản của khách hàng
     */
    getBalanceUser = () => {
        UserServices.getBalanceUser((err, res) => {
            if (!err) {
                this.setState({
                    balance: res ? moneyCeil(res.balance) : 0
                })
            }
        })
    };

    render() {
        const {balance} = this.state;

        return <p
            className={`_user-money mgbt0 line-height-1 txt-size-h8 robotoregular ${balance >= 0 ? 'txt-color-green' : 'txt-color-red'}`}>
            {
                balance >= 0 ? `+${moneyFormat(balance)}` : `${moneyFormat(balance)}`
            }
        </p>
    }
}
