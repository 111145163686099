import React from 'react'
import {
    Tabs,
    List,
    Spin,
    Avatar, Button
} from 'antd';
import * as AntdIcon from "@ant-design/icons"
import InfiniteScroll from 'react-infinite-scroller';
import {Link} from 'react-router-dom'
import MainLayout from '../../components/Layout/MainLayout'
import {parseQueryStringToObject, timeAgo, updateQueryStringParameter} from "../../utils/stringUtils";
import * as Constants from "../../constants/config";
import {withNamespaces} from "react-i18next";
import {LocalStore} from "../../utils/LocalStore";
import NotificationServices from "../../services/notifications.service";
import {getUrlQueryString} from "../../utils/helper";
const Favicon = require('favico.js');

class Notifications extends React.Component {
    constructor(props) {
        super(props);
        this.isUnmounted = false;
        this.favicon = new Favicon({
            animation: 'none'
        });
        this.state = {
            page: 0,
            pageSize: 25,
            groupCode: 'ALL',
            data: [],
            loading: false,
            hasMore: true,
            currentProjectInfo: {},
        };
    }

    componentDidMount() {
        window.addEventListener("popstate", this.onPopState.bind(this));
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        const currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {};
        this.setState({currentProjectInfo, currentLoggedUser});

        this.loadFromQueryString();

        // this.getNotification();
        this.getNotificationEventGroups();
    }

    /**
     * Khi unmount ra thì bỏ sự kiện popstate
     */
    componentWillUnmount() {
        this.isUnmounted = true;
        window.removeEventListener("popstate", this.onPopState.bind(this));
    }

    /**
     * On popstate
     */
    onPopState() {
        if (!this.isUnmounted) this.setState({isLoading: true}, ()=> {
            this.loadFromQueryString();
        });
    };

    /**
     * Load từ QueryString (dành cho lần load đầu tiên)
     */
    loadFromQueryString = () => {
        const {pageSize} = this.state;
        let queryString = getUrlQueryString();
        let queryObj = parseQueryStringToObject(queryString) || {};

        //reset các param khi load từ url
        let groupCode = 'ALL';

        for (let p in queryObj) {
            if (p === 'groupCode') {
                groupCode = decodeURIComponent(queryObj['groupCode'] || '')
            }
        }

        this.setState({
            groupCode,
            page: queryObj.page,
            pageSize: queryObj.size || pageSize,
        }, ()=> {
            this.getNotification();
        });
    };

    /**
     * xây dựng query string theo các giá trị trong state
     * @returns {string}
     */
    buildQueryString = () => {
        let {groupCode} = this.state;
        let queryString = '';

        if (groupCode && groupCode !== 'ALL') {
            queryString = updateQueryStringParameter(queryString, 'groupCode', groupCode.trim())
        }

        return queryString
    };

    getNotificationEventGroups = () => {
        NotificationServices.getNotificationEventGroups((err, res) => {
            if (!err) {
                this.setState({eventGroups: res})
            }
        });
    };

    /**
     * lấy ra danh sách notifications
     */
    getNotification = () => {
        const {listNotifications = [], isLoadingNotifications, page, pageSize} = this.state;

        let queryString = this.buildQueryString();
        if (queryString) {
            queryString = queryString + `&offset=${(page || 0)*pageSize}&limit=${pageSize+1}&sort=publishDate:desc`
        } else {
            queryString = `?offset=${(page || 0)*pageSize}&limit=${pageSize+1}&sort=publishDate:desc`;
        }

        // let queryString = `?skip=${(page || 0)*25}&take=26${type ? `&groupCode=${type}` : ''}&sort=publishDate:desc`;
        if (isLoadingNotifications) return;
        this.setState({isLoadingNotifications: true}, () => {
            NotificationServices.getNotifications(queryString, (err, res, headers) => {
                if (!err && Array.isArray(res)) {
                    this.setState({
                        listNotifications: !page || page === 0 ? [...res.slice(0, pageSize)] : [...listNotifications, ...res.slice(0, pageSize)],
                        pageNo: page || 0,
                        hasMore: res.length > pageSize,
                    }, () => {
                        if(navigator && navigator.serviceWorker && navigator.serviceWorker.controller && navigator.serviceWorker.controller.postMessage) navigator.serviceWorker.controller.postMessage({title: Constants.RSOCKET_GET_TOTAL_UNREAD_NOTIFICATION})
                    });
                }
                this.setState({isLoadingNotifications: false})
            })
        });
    };

    /**
     * next comment page
     */
    nextPage = () => {
        const {pageNo, hasMore, isLoadingNotifications} = this.state;
        if (hasMore && !isLoadingNotifications) {
            this.setState({page: parseFloat(pageNo) + 1}, () => {
                let queryString = getUrlQueryString();
                queryString = updateQueryStringParameter(queryString ? '?'+ queryString : '', 'page', parseFloat(pageNo) + 1);
                this.props.history.push({
                    pathname: this.props.location.pathname,
                    search: queryString
                });
            });
        }
    };

    /**
     * chuyển trạng thái của notification sang đã đọc
     * @param id
     */
    markReadNotification = (id) => {
        NotificationServices.markNotificationRead(id, () => {
            if(navigator && navigator.serviceWorker && navigator.serviceWorker.controller && navigator.serviceWorker.controller.postMessage) navigator.serviceWorker.controller.postMessage({title: Constants.RSOCKET_GET_TOTAL_UNREAD_NOTIFICATION})
        })
    };

    /**
     * chuyển trạng thái của tất cả notification sang đã đọc
     */
    markReadAllNotification = () => {
        NotificationServices.markAllNotificationsRead(() => {
            this.setState({visible: false}, () => {
                if(navigator && navigator.serviceWorker && navigator.serviceWorker.controller && navigator.serviceWorker.controller.postMessage) navigator.serviceWorker.controller.postMessage({title: Constants.RSOCKET_GET_TOTAL_UNREAD_NOTIFICATION})
            })
        })
    };

    tabChange = (e) => {
        this.setState({
            groupCode: e,
            listNotifications: [],
        }, () => {
            //lấy ra danh sách noti theo type
            // this.getNotification(null, e !== 'all' ? e : null)
            let groupCode = e || 'ALL';
            let queryString = getUrlQueryString();
            queryString = updateQueryStringParameter(queryString ? '?'+ queryString : '', 'groupCode', groupCode);
            queryString = updateQueryStringParameter(queryString ? ''+ queryString : '', 'page', 0);
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: queryString
            });

        })
    };

    /**
     * lấy ra link theo type của event group
     * @param data
     * @returns {string}
     */
    getLinkByEventGroup = (data) => {
        const refData = data.refData || {};
        const eventCode = data.eventCode;
        switch (eventCode) {
            case 'FINANCIAL_COLLECT':
            case 'FINANCIAL_EMD':
            case 'FINANCIAL_PAYMENT':
            case 'FINANCIAL_CLAIM':
                return '/profile/transactions';
            case 'DELIVERY_REQ_STATUS_UPDATE':
                if (refData.deliveryRequest) return `/delivery?query=${refData.deliveryRequest.code}`;
                return '/delivery';
            case 'ORDER_PACKAGE_UPDATE':
            case 'SHIPMENT_PACKAGE_UPDATE':
                if (refData.order) {
                    if (refData.order.updatedPackageCode) {
                        return `/packages?query=${refData.order.updatedPackageCode}`;
                    } else {
                        if (refData.order.code) return `/${refData.order.isShipment ? 'shipments' : 'orders'}/${refData.order.code}`;
                    }
                }
                return '/packages';
            case 'PROFILE':
                return '/profile';
            default:
                if (refData.type === "peer_payment") {
                    if (refData.order.code) return `/peer-payments/${refData.order.code}`;
                } else if (refData.order) {
                    if (refData.order.code) return `/${refData.order.isShipment ? 'shipments' : 'orders'}/${refData.order.code}`;
                    return '/orders';
                }
                return '/'
        }
    };

    renderTabContents = () => {
        const {isLoadingNotifications, listNotifications, currentProjectInfo, hasMore} = this.state;
        const {t} = this.props;

        // console.log(listNotifications)

        return <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={this.nextPage}
            hasMore={!isLoadingNotifications && hasMore}
            useWindow={false}
            className={'notification__content'}
        >
            <List
                dataSource={listNotifications}
                renderItem={item => {
                    const link = this.getLinkByEventGroup(item);
                    return (
                        <Link to={link} className="notification-item" onClick={() => {
                            this.markReadNotification(item.id);
                        }}>
                            <List.Item key={item.id} className={`notification__content_list ${item.read ? 'active' : ''} justify-content-between pdl15 pdr15`}>
                                <List.Item.Meta
                                    avatar={<Avatar src={(item.refData.order && item.refData.order.image) || (currentProjectInfo.tenantConfig && currentProjectInfo.tenantConfig.logoLite)}/>}
                                    title={<span>
                                        <span className='txt-size-h8 robotomedium txt-color-black pdt10 dpl-block'>{item.messageData}</span>
                                        {/*<span className='txt-size-h8 txt-color-gray robotoregular line-height134'> {item.messageData}</span>*/}
                                    </span>}
                                    // description={item.email}
                                />
                                <div className='txt-size-h8 txt-color-gray2 robotoregular pdt2 flex-none'>{timeAgo(item.publishDate, t)}</div>
                                {/*<div className='txt-size-h8 txt-color-gray2 robotoregular pdt2'>{moment(item.publishDate).format("DD/MM/YYYY")}</div>*/}
                                {/*<div className='txt-size-h8 txt-color-gray2 robotoregular pdt2 flex-none'>{timeAgo(item.publishDate * 1000, t)}</div>*/}
                            </List.Item>
                        </Link>
                    )
                }}
            >
                {isLoadingNotifications && hasMore && (
                    <div className="demo-loading-container text-center pdt15 pdbt15">
                        <Spin indicator={<AntdIcon.Loading3QuartersOutlined type="loading" style={{ fontSize: 24 }} spin />} />
                    </div>
                )}
            </List>
        </InfiniteScroll>
    };

    renderContent = () => {
        const {t} = this.props;
        const {groupCode, notificationsUnread, eventGroups = []} = this.state;
        return (
            <div className='notification__header width-100-percent bg-color-white shadow-top'>
                <Tabs activeKey={groupCode} onChange={this.tabChange}>
                    <Tabs.TabPane tab={`${t('notifications.all')}${notificationsUnread ? ` (${notificationsUnread})` : ''}`} key="ALL">
                        <div className="scroller-container">
                            {this.renderTabContents()}
                        </div>
                    </Tabs.TabPane>

                    {
                        eventGroups.map(item => <Tabs.TabPane tab={`${item.name}`} key={item.code}>
                                <div className="scroller-container">
                                    {this.renderTabContents()}
                                </div>
                            </Tabs.TabPane>
                        )
                    }

                </Tabs>
            </div>
        );
    };

    renderTitle = () => {
        const {t} = this.props;
        const {notificationsUnread} = this.state;
        return <div className="dpl-flex align-items-center justify-content-between pdt15 pdbt15 pdr10 pdl10">
            <span className="txt-color-black txt-uppercase txt-size-h7">{`${t('notifications.title')}${notificationsUnread ? ` (${notificationsUnread})` : ''}`}</span>

            <span onClick={this.markReadAllNotification} className="txt-color-blue cursor-pointer txt-size-h8">
                {t('notifications.mark_as_read_all')}
            </span>
        </div>
    };

    render() {
        const {t} = this.props;
        const {hasMore} = this.state;
        return <MainLayout
            ref={ref => this.mainLayoutElement = ref}
            {...this.props}
            className="width-100-percent"
            showProgressBar={this.state.showProgressBar}
            headerTitle={t('notifications.title')}
            title={t('notifications.title')}
        >
            <div className={'container-common pdbt30 mgr20'}>
                {this.renderTitle()}
                {this.renderContent()}

                {
                    hasMore && <div style={{
                        textAlign: 'center', marginTop: 12, height: 32, lineHeight: '32px',
                    }}
                    >
                        <Button className={'_btn-loadmore'} onClick={this.nextPage}>{t('button.loadmore')}</Button>
                    </div>
                }

            </div>
        </MainLayout>;
    }
}
export default withNamespaces()(Notifications);
