import React from 'react'
import _ from 'lodash'
import MainLayout from '../../components/Layout/MainLayout'
import OrderInfo from '../../components/OrderDetail/OrderInfo'
import TabLayout from '../../components/OrderDetail/TabLayout'
import Comment from '../../components/OrderDetail/Comment'
import Page404 from '../Page404'

import CategoriesService from '../../services/categories.service'
import OrdersService from '../../services/orders.service'
import * as Constants from '../../constants/config'
import { LocalStore } from '../../utils/LocalStore'
import { Layout, notification, Spin, Col, Row, Modal, Avatar, Checkbox, Input } from 'antd'
import * as AntdIcon from '@ant-design/icons'
import { fromJS } from 'immutable/dist/immutable'
import uploadFile from '../../utils/api/uploadFile'
import saveAs from 'file-saver'
import UserService from '../../services/user.service'
import { moneyCeil, moneyFormat, parseQueryStringToObject, updateQueryStringParameter } from '../../utils/stringUtils'
import { getUrlQueryString } from '../../utils/helper'
import TicketService from '../../services/tickets.service'
import { withNamespaces } from 'react-i18next'
import BifinService from '../../services/bifin.service'
import OrderService from '../../services/orders.service'
import moment from 'moment'

let currentLoggedUser = null
const initialCommentPage = 0

class OrderDetail extends React.Component {
    constructor(props) {
        super(props)
        this.isUnmounted = false

        this.state = {
            paramCode: '',
            activeTab: Constants.ORDER_DETAIL_TAB_PRODUCT,
            currentCommentTab: 'customer',
            isLoadingDetail: false,
            orderDetail: null,
            warehousesDelivery: [],
            warehousesReceive: [],
            loanCredits: [],
            listAttachments: []
        }
    }

    componentDidMount() {
        window.addEventListener('popstate', this.onPopState.bind(this))

        if (this.mainLayoutElement && this.mainLayoutElement.scrollLayoutElement) {
            this.mainLayoutElement.scrollLayoutElement.addEventListener('scroll', this.windowOnScrollHandle)
        }

        if (typeof LocalStore.getInstance().read('currentLoggedUser') === 'object') {
            currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {}

            this.setState({ paramCode: this.props.match.params.id }, () => {
                this.getCredits()
                this.getOrderDetail()
                this.loadFromQueryString()
                this.getExchangeRate()
                this.getPackageStatuses()
                this.getTicketStatuses()
                this.getListProductOrder()
                this.getOrderStatuses()
                this.getListFinancialClaim('claim')
                this.getListFinancialClaim('collect')
                this.getDeliveryWarehouses()
                this.getReceiveWarehouses()
                this.getOrderFees()
            })
        }
    }

    componentDidUpdate(prevProps, prevState){
        const { orderDetail } = this.state
        if(prevState?.orderDetail === null && orderDetail){
            this.loadDataComments(initialCommentPage)
        }
    }

    componentWillUnmount() {
        this.isUnmounted = true
        window.removeEventListener('popstate', this.onPopState.bind(this))
        if (this.mainLayoutElement && this.mainLayoutElement.scrollLayoutElement)
            this.mainLayoutElement.scrollLayoutElement.removeEventListener('scroll', this.windowOnScrollHandle)
    }

    /**
     * bắt sự kiện scroll để fixed comment box
     */
    windowOnScrollHandle = () => {
        const scrollLayoutElement = this.mainLayoutElement.scrollLayoutElement
        if (!this.commentComponent.col3Container) return
        const elementWidth = this.commentComponent.col3Container.getBoundingClientRect().width
        // nếu Offset scrollTop scroll element lớn hơn height của header = 66 thì fixed
        if (!this.isUnmounted)
            this.commentComponent.setState({
                commentBoxFixed: scrollLayoutElement.scrollTop > 66,
                commentBoxHeight: scrollLayoutElement.scrollTop > 66 ? window.innerHeight - 30 : window.innerHeight - 66 - 15,
                commentBoxFixedWidth: elementWidth,
            })
    }

    /**
     * Lấy danh sách kho phân phối
     */
    getDeliveryWarehouses = () => {
        CategoriesService.getWarehouseDelivery((err, res, headers) => {
            if (!err) {
                this.setState({ warehousesDelivery: res })
            }
        })
    }

    /**
     * Lấy danh sách kho nhận
     */
    getReceiveWarehouses = () => {
        CategoriesService.getWarehouseReceive((err, res, headers) => {
            if (!err) {
                this.setState({ warehousesReceive: res })
            }
        })
    }

    /**
     * On popstate
     */
    onPopState() {
        if (!this.isUnmounted)
            this.setState({ isLoading: true }, () => {
                this.loadFromQueryString()
            })
    }

    /**
     * Load từ QueryString (dành cho lần load đầu tiên)
     */
    loadFromQueryString = () => {
        let queryObj = parseQueryStringToObject(getUrlQueryString())
        const { paramCode } = this.state

        let href = window.location.href

        if (!this.isUnmounted) {
            let idString = href.split('/#/orders/')[1]

            if (idString) {
                let id = idString.split('?')[0]

                if (id !== paramCode) {
                    this.setState(
                        {
                            paramCode: id,
                        },
                        () => {
                            this.getCredits()
                            this.getOrderDetail()
                            this.loadFromQueryString()
                            this.getExchangeRate()
                            this.getPackageStatuses()
                            this.getTicketStatuses()
                            this.getListProductOrder()
                            this.loadDataComments(initialCommentPage)
                            this.getOrderStatuses()
                            this.getListFinancialClaim('claim')
                            this.getListFinancialClaim('collect')
                            this.getDeliveryWarehouses()
                            this.getReceiveWarehouses()
                            this.getOrderFees()
                        }
                    )
                }
            }
        }

        //load active tab
        if (queryObj.tab) {
            this.loadDataOnChangeTab(queryObj.tab)
        }
    }

    getCredits = () => {
        OrdersService.getCredits(this.state.paramCode, (err, res, header) => {
            console.log(res, 'res')
            if (!err) {
                this.setState({
                    credits: res,
                })
            }
        })
    }

    /**
     * lấy ra tỷ giá
     */
    getExchangeRate = () => {
        CategoriesService.getCurrentExchangeRate((err, res, header) => {
            if (!err) {
                this.setState({
                    exchangeRate: res,
                })
            }
        })
    }

    /**
     * lấy ra danh sách trạng thái đơn
     */
    getOrderStatuses = () => {
        CategoriesService.getOrderStatuses((err, res, headers) => {
            if (!err) {
                this.setState({
                    listStatuses: res,
                })
            }
        })
    }

    getTablePrice = () => {
        const { orderDetail } = this.state
        let configGroupId = orderDetail.configGroupId
        CategoriesService.getOrderFeesConfigGroup(configGroupId, (err, res, headers) => {
            if (!err) {
                this.setState({
                    shipmentFees: res,
                })
            }
        })
    }

    /**
     * export excel data
     */
    exportExcel = (password, cb, setError) => {
        const { t } = this.props
        const { paramCode } = this.state

        if (!password) {
            if (setError) setError(t('cartCheckout.incorrect_pin'))

            return
        }

        this.setState({ showProgressBar: true, onExport: true }, () => {
            OrdersService.exportCsvOrderProducts(paramCode, { secret: password }, (err, res, headers) => {
                if (!err) {
                    this.downloadFile(res)
                    if (cb) cb()
                } else {
                    if (err.title === 'invalid_pin' || err.title === 'invalid_password') {
                        if (setError) setError(t('cartCheckout.incorrect_pin'))
                    }
                }
                this.setState({ showProgressBar: false, onExport: false })
            })
        })
    }

    downloadFile = res => {
        if (res && res.headers && res.headers.map) {
            const fileName = res.headers.map['content-disposition'].split('filename=')[1]
            saveAs(res._bodyBlob, fileName)
        }
    }

    getOrderDetail = (isLoading = true) => {
        const { paramCode } = this.state
        this.setState(
            {
                isLoadingDetail: isLoading,
                is404: false,
            },
            () => {
                OrdersService.getOrderDetail(paramCode, (err, res) => {
                    if (!err) {
                        this.setState(
                            {
                                isLoadingDetail: false,
                                orderDetail: res,
                            },
                            () => {
                                this.getTablePrice()
                                res?.contractWithShopkeeper && this.getThirdPatiesLoans(res)
                            }
                        )
                    } else {
                        if (err.status === 404) {
                            this.setState({
                                isLoadingDetail: false,
                                is404: true,
                            })
                        } else {
                            this.setState({
                                isLoadingDetail: false,
                            })
                        }
                    }
                })
            }
        )
    }

    getThirdPatiesLoans = data => {
        let queryString = `?orderCodes=${data.code}`
        BifinService.getThirdPatiesLoans(queryString, (err, res) => {
            if (!err) {
                this.setState({
                    loanCredits: res?.loanCredits,
                })
            }
        })
    }

    getListProductOrder = () => {
        const { paramCode } = this.state
        this.setState(
            {
                isLoadingListProduct: true,
            },
            () => {
                OrdersService.getListProductOrder(paramCode, (err, res) => {
                    if (!err) {
                        this.setState({ listProduct: _.sortBy(res, 'timestamp').reverse() })
                    }
                    this.setState({ isLoadingListProduct: false })
                })
            }
        )
    }

    /**
     * cập nhật ghi chú cho chi tiết đơn
     * @param item
     * @param data
     * @param prop
     * @param cb
     */
    updateCustomerOrder = (item, data, prop, cb) => {
        let payload = {
            [prop]: data,
        }
        const { t } = this.props
        this.setState({ showProgressBar: true, [`isLoading${item.id}`]: true }, () => {
            OrdersService.updateCustomerOrder(item.code, payload, (err, res) => {
                if (!err) {
                    let { orderDetail } = this.state
                    let immutableOrderDetail = fromJS(orderDetail)
                    immutableOrderDetail = immutableOrderDetail.setIn(['remark'], res.remark)
                    immutableOrderDetail = immutableOrderDetail.setIn(['note'], res.note)
                    this.setState(
                        {
                            orderDetail: immutableOrderDetail.toJS(),
                        },
                        () => {
                            if (cb) cb()
                        }
                    )
                } else {
                    if (err.statusCode !== 'Network fail')
                        notification['error']({
                            message: t(err.message),
                        })
                }
                this.setState({ showProgressBar: false, [`isLoading${item.id}`]: false })
            })
        })
    }

    /**
     * cập nhật ghi chú cho sản phẩm trong chi tiết đơn
     * @param item
     * @param cb
     */
    updateCustomerOrderProduct = (item, cb) => {
        const { t } = this.props
        const { orderDetail, paramCode } = this.state
        let productId = item.id
        let payload = {
            note: item.note || '',
            remark: item.remark || '',
        }
        this.setState({ showProgressBar: true, [`isLoadingProduct${orderDetail.id}`]: true }, () => {
            OrdersService.updateCustomerOrderProduct(paramCode, productId, payload, (err, res) => {
                if (!err) {
                    let { listProduct } = this.state
                    // tìm ra id của shop trong list
                    let indexListProduct = listProduct.findIndex(x => x.id === res.id)
                    if (indexListProduct >= 0) {
                        listProduct[indexListProduct] = res
                        this.setState(
                            {
                                listProduct,
                            },
                            () => {
                                if (cb) cb()
                            }
                        )
                    }
                } else {
                    if (err.statusCode !== 'Network fail')
                        notification['error']({
                            message: t(err.message),
                        })
                }
                this.setState({ showProgressBar: false, [`isLoadingProduct${orderDetail.id}`]: false })
            })
        })
    }

    /**
     * lấy ra danh sách comment theo paramCode
     */
    getOrderFees = () => {
        const { paramCode } = this.state
        this.setState({ isLoadingFees: true, showProgressBar: true }, () => {
            OrdersService.getOrderFees(paramCode, (err, res, headers) => {
                if (err === null) {
                    this.setState({
                        isLoadingFees: false,
                        showProgressBar: false,
                        listFees: res || [],
                        listFeesMetadata: {
                            pageCount: headers['x-page-count'],
                            page: headers['x-page-number'],
                            size: headers['x-page-size'],
                            total: headers['x-total-count'],
                        },
                    })
                } else {
                    this.setState({ showProgressBar: false, isLoadingFees: false })
                }
            })
        })
    }

    /**
     * lấy ra danh sách comment theo paramCode
     */
    getOrderPackages = () => {
        const { paramCode } = this.state
        this.setState({ isLoadingPackages: true, showProgressBar: true }, () => {
            OrdersService.getOrderPackages(paramCode, (err, res, headers) => {
                if (err === null) {
                    this.setState({
                        isLoadingPackages: false,
                        showProgressBar: false,
                        listPackages: res || [],
                        listPackagesMetadata: {
                            pageCount: headers['x-page-count'],
                            page: headers['x-page-number'],
                            size: headers['x-page-size'],
                            total: headers['x-total-count'],
                        },
                    })
                } else {
                    this.setState({ showProgressBar: false, isLoadingPackages: false })
                }
            })
        })
    }

    /**
     * lấy ra danh sách PackageMileStones
     */
    getOrderPackageMileStones = packageCode => {
        const { listPackageMileStones = {}, isLoadingPackageMileStones = {} } = this.state
        isLoadingPackageMileStones[packageCode] = true
        this.setState({ isLoadingPackageMileStones, showProgressBar: true }, () => {
            OrdersService.getOrderPackageMileStones(packageCode, (err, res, headers) => {
                isLoadingPackageMileStones[packageCode] = false
                if (err === null) {
                    listPackageMileStones[packageCode] = res || []
                    this.setState({
                        isLoadingPackageMileStones,
                        showProgressBar: false,
                        listPackageMileStones,
                    })
                } else {
                    this.setState({ showProgressBar: false, isLoadingPackageMileStones })
                }
            })
        })
    }

    /**
     * lấy ra danh sách trạng thái của package
     */
    getPackageStatuses = () => {
        CategoriesService.getPackageStatuses((err, res, headers) => {
            if (err === null) {
                this.setState({
                    listPackageStatuses: res || [],
                })
            }
        })
    }

    /**
     * lấy ra danh sách trạng thái của package
     */
    getTicketStatuses = () => {
        TicketService.getPublicStates((err, res, headers) => {
            if (!err) {
                this.setState({
                    listStatusesTicket: res,
                })
            }
        })
    }

    /**
     * lấy ra danh sách comment theo paramCode
     */
    loadDataComments = (page) => {
        let { listComments = [], commentLoading, orderDetail } = this.state
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const taiyiConfig = _.get(currentProjectInfo, 'tenantConfig.taiyiConfig.enabled')
        const enableTime = _.get(currentProjectInfo, 'tenantConfig.taiyiConfig.updateToNewCommentTime')
        const checkTimeNew = moment(orderDetail?.createdAt)?.isAfter(moment(enableTime))
        if (page === undefined || page === null || page === 0) {
            page = 0
            listComments = []
        }

        if (commentLoading) return
        const { paramCode } = this.state
        this.setState({ commentLoading: true, showProgressBar: true }, () => {
            if (taiyiConfig && checkTimeNew) {
                    OrdersService.getCommentsPosedon(paramCode, page, (err, res, headers) => {
                        if (err === null) {
                            this.setState(
                                {
                                    commentLoading: false,
                                    showProgressBar: false,
                                    listComments: [...listComments, ...res],
                                    listCommentsMetadata: {
                                        pageCount: headers['x-page-count'],
                                        page: headers['x-page-number'],
                                        size: headers['x-page-size'],
                                        total: headers['x-total-count'],
                                    },
                                },
                                () => {
                                    if (this.commentComponent) {
                                        if (page === 0) {
                                            this.commentComponent.chatbox.scrollTop = 0
                                        }
                                    }
                                }
                            )
                        } else {
                            this.setState({ commentLoading: false, showProgressBar: false })
                        }
                    })

                    OrdersService.getAttachmentPosedon(paramCode, (err, res, headers) => {
                        if (err === null) {
                            this.setState(
                                {
                                    listAttachments: res,
                                },
                            )
                        } 
                    })
            } else {
                OrdersService.getComments(paramCode, page, (err, res, headers) => {
                    // console.log('api call', [...listComments, ...res.data]);
                    if (err === null) {
                        this.setState(
                            {
                                commentLoading: false,
                                showProgressBar: false,
                                listComments: [...listComments, ...res],
                                listCommentsMetadata: {
                                    pageCount: headers['x-page-count'],
                                    page: headers['x-page-number'],
                                    size: headers['x-page-size'],
                                    total: headers['x-total-count'],
                                },
                            },
                            () => {
                                if (this.commentComponent) {
                                    if (page === 0) {
                                        this.commentComponent.chatbox.scrollTop = 0
                                    }
                                }
                            }
                        )
                    } else {
                        this.setState({ commentLoading: false, showProgressBar: false })
                    }
                })
            }
        })
    }

    /**
     * Lấy danh sách giao dịch trong chi tiết đơn
     */

    getListFinancial = () => {
        const { paramCode } = this.state
        this.setState(
            {
                isLoadingListFinancial: true,
            },
            () => {
                OrdersService.getListFinancialOrder(paramCode, '', (err, res, headers) => {
                    if (!err) {
                        this.setState({
                            listFinancial: res,
                            metadataFinancial: {
                                pageCount: headers['x-page-count'],
                                page: headers['x-page-number'],
                                size: headers['x-page-size'],
                                total: headers['x-total-count'],
                            },
                        })
                    }
                    this.setState({ isLoadingListFinancial: false })
                })
            }
        )
    }

    /**
     * Lấy danh sách giao dịch trong chi tiết đơn
     */
    getListFinancialClaim = type => {
        const { paramCode } = this.state
        this.setState(
            {
                isLoadingListFinancialClaim: true,
            },
            () => {
                OrdersService.getListFinancialOrder(paramCode, type, (err, res, headers) => {
                    if (!err) {
                        if (type === 'claim') {
                            this.setState({
                                listFinancialClaim: res,
                                metadataFinancialClaim: {
                                    pageCount: headers['x-page-count'],
                                    page: headers['x-page-number'],
                                    size: headers['x-page-size'],
                                    total: headers['x-total-count'],
                                },
                            })
                        } else {
                            this.setState({
                                listFinancialCollect: res,
                                metadataFinancialCollect: {
                                    pageCount: headers['x-page-count'],
                                    page: headers['x-page-number'],
                                    size: headers['x-page-size'],
                                    total: headers['x-total-count'],
                                },
                            })
                        }
                    }
                    this.setState({ isLoadingListFinancialClaim: false })
                })
            }
        )
    }

    /**
     * Lấy danh sách lịch sử các mốc đặt hàng trong đơn
     */
    getListMilestones = () => {
        const { paramCode } = this.state
        this.setState(
            {
                isLoadingListMilestones: true,
            },
            () => {
                OrdersService.getOrderMileStones(paramCode, (err, res) => {
                    if (!err) {
                        this.setState({
                            listMilestones: res,
                        })
                    }
                    this.setState({ isLoadingListMilestones: false })
                })
            }
        )
    }

    /**
     * Lấy danh sách các khiếu nại thuộc đơn
     */
    getOrderTickets = page => {
        const { paramCode } = this.state

        const queryString = `?page=${page ? page - 1 : 0}&size=10000&sort=createdAt:desc`

        this.setState(
            {
                isLoadingListTickets: true,
            },
            () => {
                OrdersService.getOrderTickets(paramCode, queryString, (err, res, headers) => {
                    if (!err) {
                        this.setState({
                            listTickets: res,
                            orderTicketsMetadata: {
                                pageCount: headers['x-page-count'],
                                page: headers['x-page-number'],
                                size: headers['x-page-size'],
                                total: headers['x-total-count'],
                            },
                        })
                    }
                    this.setState({ isLoadingListTickets: false })
                })
            }
        )
    }

    /**
     * upload ảnh comment
     * @param data
     * @param cb
     */
    uploadImageComment = (data = {}, cb) => {
        const { t } = this.props
        const { isCommenting, isUploading, paramCode, orderDetail } = this.state
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const taiyiConfig = _.get(currentProjectInfo, 'tenantConfig.taiyiConfig.enabled')
        const enableTime = _.get(currentProjectInfo, 'tenantConfig.taiyiConfig.updateToNewCommentTime')
        const checkTimeNew = moment(orderDetail?.createdAt)?.isAfter(moment(enableTime))
        if (
            isCommenting === true ||
            isUploading === true ||
            (data?.content?.trim().replace(/<[^>]*>?/gm, '')?.length === 0 && data?.files?.length === 0)
        )
            return

        if (taiyiConfig && checkTimeNew) {
            if (data?.files?.length > 0) {
                this.setState({
                    isUploading: true,
                })
                let apiServiceUrl = `/customer/comments/attachments`
                uploadFile(apiServiceUrl, data.files || [], {
                    command:{
                        module: 'CUSTOMER_ORDER_MODULE',
                        thread: paramCode,
                    }
                },'attachment').then(response => {

                    let content = ''
                    if(response?.mimeType?.indexOf('image/') >= 0){
                        content = `<img src="${response?.location}" alt="${response.name}"></img>`
                    } else {
                        content = `<a target="_blank" href="${response?.location}"><i class="fa-solid fa-paperclip"></i> ${response.name}</a>`
                    }
                    OrderService.createCommentInOrder(paramCode, { content }, (err, res, headers) => {
                        if (!err) {
                            this.loadDataComments()
                            this.setState({
                                isUploading: false,
                            })
                        } else {
                            notification['error']({
                                message: t(err.message),
                            })
                            this.setState({ isUploading: false, isCommenting: false })
                        }
                    })
                })
             
            } else {
                this.setState({
                    isCommenting: true,
                })
                OrderService.createCommentInOrder(paramCode, { content: data.content?.trim() }, (err, res, headers) => {
                    if (!err) {
                        this.setState(
                            {
                                isCommenting: false,
                            },
                            () => {
                                this.loadDataComments()
                            }
                        )
                    } else {
                        notification['error']({
                            message: t(err.message),
                        })
                        this.setState({ isUploading: false, isCommenting: false })
                    }
                })
            }
        } else {
            this.setState({ isUploading: true, isCommenting: true }, () => {
                let apiServiceUrl = `/customer/orders/${paramCode}/comments`
                let body = data.content ? { comment: { content: data.content?.trim() } } : null
                uploadFile(apiServiceUrl, data.files || [], body)
                    .then(response => {
                        this.setState(
                            {
                                isCommenting: false,
                                isUploading: false,
                            },
                            () => {
                                if (cb) cb()
                                this.loadDataComments()
                            }
                        )
                    })
                    .catch(error => {
                        notification['error']({
                            message: t(error.message),
                        })
                        this.setState({ isUploading: false, isCommenting: false })
                    })
            })
        }
    }

    /**
     * next comment page
     */
    nextCommentPage = () => {
        const { listCommentsMetadata } = this.state
        if (!listCommentsMetadata) return
        if (parseFloat(listCommentsMetadata.page) + 1 < parseFloat(listCommentsMetadata.pageCount)) {
            this.loadDataComments(parseFloat(listCommentsMetadata.page) + 1)
        }
    }

    /**
     * Thêm 1 sản phẩm vào wishlist
     * @param id
     * @param cb
     */
    addItemWishlist = (id, cb) => {
        const { t } = this.props
        this.setState({ showProgressBar: true }, () => {
            UserService.addItemWishlist('order', id, (err, res, headers) => {
                if (!err) {
                    if (cb) cb()
                    notification['success']({
                        message: t('message.successfully_saved_product'),
                    })
                } else {
                    notification['error']({
                        message: t('message.fail_saved_product'),
                    })
                }
                this.setState({ showProgressBar: false })
            })
        })
    }

    /**
     * xác nhận đã nhận hàng
     */
    confirmReceivedOrder = () => {
        const { paramCode, isSubmit } = this.state
        const { t } = this.props
        if (isSubmit === true) return
        this.setState({ showProgressBar: true, isSubmit: true }, () => {
            OrdersService.confirmReceivedOrder(paramCode, (err, res, headers) => {
                if (!err) {
                    this.getOrderDetail()
                    notification['success']({
                        message: t('orderDetail.successfully_change_status'),
                    })
                } else {
                    if (err.title === 'order_status_not_allow') {
                        notification['error']({
                            message: t('orderDetail.changed_status'),
                        })
                    } else {
                        if (err.statusCode !== 'Network fail')
                            notification['error']({
                                message: t(err.message),
                            })
                    }
                }
                this.setState({ showProgressBar: false, isSubmit: false })
            })
        })
    }
    /**
     * lấy số dư tài khoản của khách hàng
     */
    getBalanceUser = () => {
        UserService.getBalanceUser((err, res) => {
            if (!err) {
                this.setState({
                    balance: res ? moneyCeil(res.balance) : 0,
                })
            }
        })
    }
    /**
     * xác nhận hủy đơn
     */
    confirmCancelOrder = () => {
        const { paramCode, isSubmit } = this.state
        const { t } = this.props
        if (isSubmit === true) return
        this.setState({ showProgressBar: true, isSubmit: true }, () => {
            OrdersService.confirmCancelOrder(paramCode, (err, res, headers) => {
                if (!err) {
                    this.getOrderDetail()
                    notification['success']({
                        message: t('orderDetail.order_cancel'),
                    })

                    // lấy lại số tiền còn lại của khách
                    // if (this.mainLayoutElement && this.mainLayoutElement.BalanceComponent) this.mainLayoutElement.BalanceComponent.getBalanceUser();
                    this.getBalanceUser()
                } else {
                    if (err.title === 'order_status_not_allow') {
                        notification['error']({
                            message: t('orderDetail.changed_status'),
                        })
                    } else {
                        if (err.statusCode !== 'Network fail')
                            notification['error']({
                                message: t(err.message),
                            })
                    }
                }
                this.setState({ showProgressBar: false, isSubmit: false })
            })
        })
    }

    /**
     * xác nhận thanh toán
     */
    confirmPayOrder = password => {
        const { paramCode, isSubmit, showModalConfirm, savePassword } = this.state
        const { t } = this.props

        if (showModalConfirm && !password) {
            this.setState({
                passwordError: t('orderDetail.input_pin_error'),
            })
            return
        }

        let body = {}

        if (password) {
            body.password = password

            if (savePassword) {
                body.savePassword = true
            }
        }

        if (isSubmit === true) return

        this.setState({ showProgressBar: true, isSubmit: true }, () => {
            OrdersService.confirmPayOrder(paramCode, body, (err, res, headers) => {
                if (!err) {
                    LocalStore.getInstance().save('pinToken', headers['x-pin-token'] || '')

                    this.getOrderDetail()
                    notification['success']({
                        message: t('orderDetail.successfully_pay'),
                    })

                    // lấy lại số tiền còn lại của khách
                    // if (this.mainLayoutElement && this.mainLayoutElement.BalanceComponent) this.mainLayoutElement.BalanceComponent.getBalanceUser();
                    this.getBalanceUser()
                    this.setState({
                        password: '',
                        passwordError: '',
                        showModalConfirm: false,
                    })
                } else {
                    if (err.title === 'empty_password') {
                        LocalStore.getInstance().save('pinToken', '')
                        this.setState({
                            password: '',
                            passwordError: '',
                            showModalConfirm: true,
                        })
                    } else if (err.title === 'password_not_match' || err.title === 'invalid_password') {
                        LocalStore.getInstance().save('pinToken', '')
                        if (!showModalConfirm) {
                            this.setState({
                                password: '',
                                passwordError: '',
                                showModalConfirm: true,
                            })
                        } else {
                            this.setState({
                                passwordError: t('orderDetail.incorrect_pin'),
                            })
                        }
                    } else if (err.title === 'insufficient_balance') {
                        notification['error']({
                            message: t('cartCheckout.not_enough_money'),
                        })
                    } else if (err.title === 'order_status_not_allow') {
                        notification['error']({
                            message: t('message.changed_status'),
                        })
                    } else {
                        if (err.statusCode !== 'Network fail')
                            notification['error']({
                                message: t(err.message),
                            })
                    }
                }
                this.setState({ showProgressBar: false, isSubmit: false })
            })
        })
    }

    /**
     * toggle tab
     * @param tab
     */
    toggleCommentTab = tab => {
        const { currentCommentTab } = this.state
        if (tab === currentCommentTab) return
        this.setState(
            {
                currentCommentTab: tab,
                listComments: [],
            },
            () => {
                this.loadDataComments(0)
            }
        )
    }

    /**
     * Thay đổi tabbar
     * @param key
     */
    onChangedTab = key => {
        let queryString = updateQueryStringParameter('', 'tab', key)
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: queryString,
        })
    }

    /**
     * lấy dữ liệu của tab khi chuyển tab
     */
    loadDataOnChangeTab = key => {
        this.setState(
            {
                activeTab: key,
            },
            () => {
                // load dữ liệu nếu chuyển sang tab Packages
                if (key === Constants.ORDER_DETAIL_TAB_PACKAGES) {
                    this.getOrderPackages()
                } else if (key === Constants.ORDER_DETAIL_TAB_FINANCIAL) {
                    this.getListFinancial()
                } else if (key === Constants.ORDER_DETAIL_TAB_TICKETS) {
                    this.getOrderTickets()
                } else if (key === Constants.ORDER_DETAIL_TAB_HISTORY) {
                    this.getListMilestones()
                }
            }
        )
    }

    /**
     * Change Language
     */
    // onChangeLanguage = () => {
    //     const {cartLanguage} = this.state;
    //     this.setState({cartLanguage: cartLanguage === 'VN' ? 'CN' : 'VN'}, () => {
    //         LocalStore.getInstance().save('cartLanguage', this.state.cartLanguage);
    //     });
    // };

    renderModalConfirmPin = () => {
        const { t } = this.props
        const { orderDetail, isSubmit, showModalConfirm, password, savePassword, passwordError } = this.state

        return (
            <Modal
                title={t('cartCheckout.confirm_pin')}
                visible={showModalConfirm}
                onOk={() => this.confirmPayOrder(password)}
                confirmLoading={isSubmit}
                onCancel={() =>
                    this.setState({
                        showModalConfirm: false,
                        password: '',
                    })
                }
                okText={t('modal.confirm')}
                cancelText={t('modal.cancel')}
                okButtonProps={{ className: '_btn-submit-confirm' }}
                cancelButtonProps={{ className: '_btn-cancel-confirm' }}
            >
                <p className="mgbt5">{t('modal.confirm_payment')}</p>
                <div className="dpl-flex align-items-start mgbt5">
                    <Avatar
                        className="_avatar-seller mgr10 flex-none"
                        shape="square"
                        size={40}
                        src={orderDetail && orderDetail.image}
                    />
                    <div className="txt-color-black txt-size-h7 break-word">
                        <div className="">#{orderDetail && orderDetail.code}</div>
                        <div className="">
                            {orderDetail && orderDetail.totalUnpaid >= 0 ? t('order.need_payment') : t('order.excess_cash')}:{' '}
                            {orderDetail && moneyFormat(moneyCeil(orderDetail.totalUnpaid), null, true)}
                        </div>
                    </div>
                </div>
                <p className="mgbt5">{t('cartCheckout.please_input_pin')}</p>
                <Input.Password
                    className={'_input-password'}
                    placeholder={t('cartCheckout.input_pin')}
                    value={password}
                    onChange={e => this.setState({ password: e.target.value })}
                    onKeyDown={e => {
                        if (e.keyCode === 13) {
                            this.confirmPayOrder(password)
                        }
                    }}
                />
                {passwordError && <p className="_txt-error-confirm mgt5 mgbt0 txt-error ">{passwordError}</p>}

                <Checkbox
                    className={'_check-box-confirm mgt10'}
                    onChange={() => this.setState({ savePassword: !savePassword })}
                    checked={savePassword}
                >
                    {t('modal.save_password_60m')}
                </Checkbox>
            </Modal>
        )
    }

    render() {
        const {
            exchangeRate,
            activeTab,
            orderDetail,
            isLoadingDetail,
            listProduct,
            isLoadingListMilestones,
            isLoadingListFinancial,
            listPackages,
            listPackageMileStones,
            isLoadingPackages,
            listCommentsMetadata,
            isLoadingListTickets,
            listTickets,
            isLoadingPackageMileStones,
            listPackageStatuses,
            listFinancial,
            metadataFinancial,
            onExport,
            listMilestones,
            listFees,
            isLoadingFees,
            listStatuses,
            is404,
            listStatusesTicket,
            listFinancialClaim,
            listFinancialCollect,
            balance,
            isSubmit,
            warehousesDelivery,
            warehousesReceive,
            shipmentFees,
            loanCredits,
            listAttachments
        } = this.state

        const { t } = this.props
        if (is404) return <Page404 />

        return (
            <MainLayout
                ref={ref => (this.mainLayoutElement = ref)}
                {...this.props}
                noFooter={true}
                balance={balance}
                headerTitle={`${t('order.title')} #` + (orderDetail ? orderDetail.code : '')}
                title={orderDetail ? '#' + orderDetail.code : ''}
            >
                <div className={'container-common mgr20 mgbt20'}>
                    {isLoadingDetail ? (
                        <Spin
                            indicator={
                                <AntdIcon.Loading3QuartersOutlined
                                    type="loading"
                                    style={{ fontSize: 24 }}
                                    spin
                                />
                            }
                        />
                    ) : (
                        <Layout>
                            <Row>
                                <Col
                                    className={'orderdetail pdr20'}
                                    span={17}
                                >
                                    <Spin
                                        spinning={this.state[`isLoading${orderDetail && orderDetail.id}`] === true}
                                        indicator={
                                            <AntdIcon.Loading3QuartersOutlined
                                                type="loading"
                                                style={{ fontSize: 24 }}
                                                spin
                                            />
                                        }
                                    >
                                        <OrderInfo
                                            t={t}
                                            ref={e => (this.OrderInfo = e)}
                                            history={this.props.history}
                                            loanCredits={loanCredits}
                                            getOrderDetail={this.getOrderDetail}
                                            listStatuses={listStatuses}
                                            data={orderDetail}
                                            updateCustomerOrder={this.updateCustomerOrder}
                                            confirmReceivedOrder={this.confirmReceivedOrder}
                                            confirmCancelOrder={this.confirmCancelOrder}
                                            confirmPayOrder={this.confirmPayOrder}
                                            isSubmit={isSubmit}
                                        />
                                    </Spin>
                                    <TabLayout
                                        t={t}
                                        onExport={onExport}
                                        exportExcel={this.exportExcel}
                                        exchangeRate={exchangeRate}
                                        onChangedTab={this.onChangedTab}
                                        activeTab={activeTab}
                                        listProduct={listProduct}
                                        listStatuses={listStatuses}
                                        updateCustomerOrderProduct={this.updateCustomerOrderProduct}
                                        loadingProduct={this.state[`isLoadingProduct${orderDetail && orderDetail.id}`]}
                                        addItemWishlist={this.addItemWishlist}
                                        getListProductOrder={this.getListProductOrder}
                                        //action packages
                                        isLoadingPackageMileStones={isLoadingPackageMileStones}
                                        isLoadingPackages={isLoadingPackages}
                                        listPackages={listPackages}
                                        listPackageMileStones={listPackageMileStones}
                                        listPackageStatuses={listPackageStatuses}
                                        getOrderPackageMileStones={this.getOrderPackageMileStones}
                                        //giao dich tài chính
                                        isLoadingListFinancial={isLoadingListFinancial}
                                        listFinancial={listFinancial}
                                        metadata={metadataFinancial}
                                        listFinancialClaim={listFinancialClaim}
                                        listFinancialCollect={listFinancialCollect}
                                        shipmentFees={shipmentFees}
                                        //Khiếu nại của đơn
                                        isLoadingListTickets={isLoadingListTickets}
                                        listTickets={listTickets}
                                        listStatusesTicket={listStatusesTicket}
                                        //lịch sử đơn hàng
                                        isLoadingListMilestones={isLoadingListMilestones}
                                        listMilestones={listMilestones}
                                        orderDetail={orderDetail}
                                        //action fees
                                        isLoadingFees={isLoadingFees}
                                        listFees={listFees}
                                        warehousesDelivery={warehousesDelivery}
                                        warehousesReceive={warehousesReceive}
                                    />
                                </Col>
                                <div
                                    className={`ant-col-7 flex-column bg-color-white bd-rdu bd1px bd-color-gray boxchat`}
                                    // style={this.state.commentBoxFixed ? {height: this.state.commentBoxHeight, width: this.state.commentBoxFixedWidth} : {height: this.state.commentBoxHeight}}
                                >
                                    <Comment
                                        ref={e => (this.commentComponent = e)}
                                        mainLayoutElement={this.mainLayoutElement}
                                        t={t}
                                        currentLoggedUser={currentLoggedUser || {}}
                                        hasMore={
                                            listCommentsMetadata
                                                ? parseFloat(listCommentsMetadata.page) + 1 <
                                                  parseFloat(listCommentsMetadata.pageCount)
                                                : false
                                        }
                                        orderDetail={orderDetail}
                                        //action tabs
                                        currentCommentTab={this.state.currentCommentTab}
                                        toggleCommentTab={this.toggleCommentTab}
                                        //action api
                                        // confirmDeleteToggle={this.deleteComment}
                                        listComments={this.state.listComments}
                                        loadDataComments={this.loadDataComments}
                                        nextCommentPage={this.nextCommentPage}
                                        commentLoading={this.state.commentLoading}
                                        isUploading={this.state.isUploading}
                                        isCommenting={this.state.isCommenting}
                                        uploadImageComment={this.uploadImageComment}
                                        listAttachments={listAttachments}
                                    />
                                </div>
                            </Row>
                        </Layout>
                    )}
                    {this.renderModalConfirmPin()}
                </div>
            </MainLayout>
        )
    }
}

export default withNamespaces()(OrderDetail)
