import React from 'react'
let linkEl;

const faviconSize = 16;

function drawIcon(src, num, cb) {
    const img = document.createElement('img');
    img.crossOrigin = '';
    img.onload = function() {
        const canvas = document.createElement('canvas');
        canvas.width = faviconSize;
        canvas.height = faviconSize;
        const length = num.toString().length;

        const context = canvas.getContext('2d');
        context.clearRect(0, 0, img.width, img.height);
        context.drawImage(img, 0, 0, canvas.width, canvas.height);

        let top = canvas.height - 9,
            left = canvas.width - 8,
            bottom = faviconSize,
            right = faviconSize,
            radius = 4;

        if (length === 2) {
            left = canvas.width - 13;
        }
        if (length === 3) {
            left = canvas.width - 15;
        }

        context.fillStyle = '#F03D25';
        context.strokeStyle = '#F03D25';
        context.lineWidth = 1;

        context.beginPath();
        context.moveTo(left + radius, top);
        context.quadraticCurveTo(left, top, left, top + radius);
        context.lineTo(left, bottom - radius);
        context.quadraticCurveTo(left, bottom, left + radius, bottom);
        context.lineTo(right - radius, bottom);
        context.quadraticCurveTo(right, bottom, right, bottom - radius);
        context.lineTo(right, top + radius);
        context.quadraticCurveTo(right, top, right - radius, top);
        context.closePath();
        context.fill();

        context.font = 'bold 10px arial';
        context.fillStyle = '#FFF';
        context.textAlign = 'right';
        context.textBaseline = 'top';
        context.fillText(num, 15, 7);

        cb(null, context.canvas.toDataURL());
    };
    img.src = src;
}

export default class Favicon extends React.Component {
    static displayName = 'Favicon';

    static defaultProps = {
        alertCount: null,
        animated: true,
        animationDelay: 500,
        keepIconLink: function() { return false; }
    };

    static mountedInstances = [];

    static getActiveInstance() {
        return Favicon.mountedInstances[Favicon.mountedInstances.length-1];
    }

    static draw() {
        if (typeof document === 'undefined') return;

        const activeInstance = Favicon.getActiveInstance();
        if (typeof linkEl === 'undefined') {
            const head = document.getElementsByTagName('head')[0];
            linkEl = document.createElement('link');
            linkEl.type = 'image/x-icon';
            linkEl.rel = 'icon';

            // remove existing favicons
            const links = head.getElementsByTagName("link");
            for (let i = links.length; --i >= 0;) {
                if (/\bicon\b/i.test(links[i].getAttribute("rel")) && !activeInstance.props.keepIconLink(links[i])) {
                    head.removeChild(links[i])
                }
            }

            head.appendChild(linkEl);
        }

        let currentUrl;

        if (activeInstance.props.url instanceof Array) {
            currentUrl = activeInstance.props.url[activeInstance.state.animationIndex];
        } else {
            currentUrl = activeInstance.props.url;
        }

        if (activeInstance.props.alertCount) {
            drawIcon(currentUrl, activeInstance.props.alertCount, function(err, url) {
                linkEl.href = url;
            });
        } else {
            linkEl.href = currentUrl;
        }

    }

    static update() {
        if (typeof document === 'undefined') return;

        const activeInstance = Favicon.getActiveInstance();
        const isAnimated = activeInstance.props.url instanceof Array && activeInstance.props.animated;

        // clear any running animations
        let intervalId = null;
        clearInterval(activeInstance.state.animationLoop);

        if (isAnimated) {
            const animateFavicon = function animateFavicon() {
                const nextAnimationIndex = (activeInstance.state.animationIndex+1)%activeInstance.props.url.length;
                Favicon.draw();
                activeInstance.setState({animationIndex: nextAnimationIndex});
            };
            intervalId = setInterval(animateFavicon, activeInstance.props.animationDelay);
            animateFavicon();
        } else {
            Favicon.draw();
        }

        activeInstance.setState({animationLoop:intervalId});
    }

    state = {
        animationIndex: 0,
        animationLoop: null,
        animationRunning: false
    };

    componentDidMount() {
        Favicon.mountedInstances.push(this);
        Favicon.update();
    }

    componentWillUnmount() {
        const activeInstance = Favicon.getActiveInstance();
        clearInterval(activeInstance.state.animationLoop);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.url === this.props.url &&
            prevProps.animated === this.props.animated &&
            prevProps.alertCount === this.props.alertCount &&
            prevProps.keepIconLink === this.props.keepIconLink) return;

        Favicon.update();
    }

    render() {
        return null;
    }
}
