import PolimClient from '../utils/api/PolimClient'

export default class AddressChinaService {
    /**
     * lấy danh sách địa chỉ của người dùng
     * @param cb
     */
    static getListAddressesChinaUser(cb) {
        PolimClient.request(
            {
                endpoint: `customer/global_addresses?size=1000`,
                method: 'GET',
            },
            cb
        )
    }

    /**
     * xóa address của người dùng
     * @param username
     * @param id
     * @param cb
     */
    static deleteAddressChinaUser(id, cb) {
        PolimClient.request(
            {
                endpoint: `/customer/global_addresses/${id}`,
                method: 'DELETE',
            },
            cb
        )
    }

    /**
     * Create a Customer Address
     * @param username
     * @param data
     * @param cb
     */
    static createCustomerChinaAddress(data, cb) {
        PolimClient.request(
            {
                endpoint: `/customer/global_addresses`,
                method: 'POST',
                body: data,
            },
            cb
        )
    }

    /**
     * Update a Customer Address
     * @param username
     * @param id
     * @param data
     * @param cb
     */
    static updateCustomerChinaAddress(id, data, cb) {
        PolimClient.request(
            {
                endpoint: `/customer/global_addresses/${id}`,
                method: 'PATCH',
                body: data,
            },
            cb
        )
    }

    /**
     * Get Locations
     * @param query
     * @param cb
     */
    static getLocations(query, cb) {
        PolimClient.request(
            {
                endpoint: `/${query}/children`,
                method: 'GET',
                mode: 'free',
            },
            cb
        )
    }

    static getCityChina(cb) {
        PolimClient.request(
            {
                endpoint: `https://underlord.dota.gobizdev.com/public/locations/CN/children`,
                method: 'GET',
            },
            cb
        )
    }
}
