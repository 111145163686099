import React from 'react';
import { Timeline, Spin } from 'antd';
import * as AntdIcon from "@ant-design/icons"

import moment from 'moment';

class TabHistory extends React.Component{
    /**
     * render Timeline History
     */
    render(){
        const {listMilestones, t, listStatuses = [], isLoadingListMilestones} = this.props;

        if (isLoadingListMilestones === true) {
            return <div className="mgt25 mgbt25 txt-center">
                <Spin spinning={isLoadingListMilestones === true} indicator={<AntdIcon.Loading3QuartersOutlined type="loading" style={{ fontSize: 24 }} spin />} />
            </div>
        }

        return(
            <Timeline mode="alternate">
                {
                    Array.isArray(listMilestones) && listMilestones.map((item, index) => {
                        const status = listStatuses.find(x => x.code === item.status);
                        let day = '';
                        if (item.handlingTime > 1) {
                            day = t('label.days');
                        } else {
                            day = t('label.day');
                        }
                        return (
                            <Timeline.Item
                                key={index}
                                color={index === 0 ? 'red' : 'green'}
                                dot={
                                    index === 0 ? <AntdIcon.ClockCircleOutlined type="clock-circle-o" style={{ fontSize: '24px'}} className={'ic__clockcircle'}/> : ''
                                }>
                                <span className='txt-color-gray txt-size-h7 robotoregular pdr5'>{status ? status.name : '---'}:</span>
                                <span className='txt-color-black txt-size-h7 robotobold pdr5'>{moment(item.timestamp).format('HH:mm DD/MM')}</span>
                                <span className='txt-color-black txt-size-h7 robotobold'>{item.handlingTime === null ? '(' + t('orderDetail.undefined') + ')'  : '(' + item.handlingTime + ' ' + day + ')'}</span>
                            </Timeline.Item>
                        )
                    })
                }

            </Timeline>
        );
    }
}
export default TabHistory;
