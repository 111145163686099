/* eslint-disable array-callback-return */
import React from 'react';
import {Avatar, Button, Col, Input, InputNumber, List, Modal, notification, Popconfirm, Row, Upload} from 'antd';
import * as AntdIcon from "@ant-design/icons"
import AntdIconSVG, {UploadOutlined} from "@ant-design/icons"
import _ from 'lodash';
import lodash from 'lodash';
import {moneyCeil, moneyFormat, quantityFormat} from "../../utils/stringUtils"

import {LocalStore} from "../../utils/LocalStore";
import {validateLink} from "../../utils/validate";
import {ReactComponent as iconShipment} from "../../resources/images/ic_Kygui.svg";
import {getBase64} from "../../utils/helper";
import {MAX_UPLOAD_FILE_SIZE} from "../../constants/config";

const pageSize = 25;
class TabProduct extends React.Component{
    constructor(props){
        super(props);
        this.isUnmounted = false;
        this.state={
            targetCurrency: 'VND',
            // isMobile: window.innerWidth < 981,
            // isTablet: window.innerWidth < 1366,
            dataList: Array.isArray(props.dataSource) ? props.dataSource.slice(0, pageSize) : [],
            uploadList: [],
            previewVisible: false,
            previewUrl: null,
            previewTitle: null
        }
    }

    componentDidMount() {
        // window.addEventListener('resize', this.windowResize);
        if (!LocalStore.getInstance().read('cartLanguage')) {
            this.setState({cartLanguage: 'VN'}, () => {
                LocalStore.getInstance().save('cartLanguage', this.state.cartLanguage);
            })
        } else {
            this.setState({cartLanguage: LocalStore.getInstance().read('cartLanguage')});
        }
    }

    componentDidUpdate (prevProps, prevState) {
        const {dataSource} = this.props;

        if (!_.isEqual(prevProps.dataSource, dataSource)) {
            const {isLoadMore} = this.state;
            this.setState({
                dataList: isLoadMore ? dataSource : dataSource.slice(0, pageSize),
            })
        }

        if (JSON.stringify(prevState.currentProduct) !== JSON.stringify(this.state.currentProduct)) {
            if (this.state.currentProduct && this.state.currentProduct.productImage) {
                this.setState({
                    previewTitle: this.state.currentProduct.name,
                    previewUrl: this.state.currentProduct.productImage,
                    uploadList: [
                        {
                            uid: this.state.currentProduct.productImage,
                            name: this.state.currentProduct.name,
                            status: 'done',
                            url: this.state.currentProduct.productImage
                        }
                    ]
                })
            }
        }
    }

    componentWillUnmount() {
        this.isUnmounted = true;
        // window.removeEventListener('resize', this.windowResize);
    }

    // /**
    //  * khi window resize
    //  */
    // windowResize = () => {
    //     const windowWidth = window.innerWidth;
    //     if (!this.isUnmounted) this.setState({
    //         isMobile: windowWidth < 981,
    //         isTablet: windowWidth < 1025
    //     })
    // };

    /**
     * render header of list
     * @returns {*}
     */
    renderHeaderList = () => {
        const {t, listStatuses = [], orderDetail = {}} = this.props;
        // const {isTablet} = this.state;
        let currentStatus = listStatuses.find(x => x.code === orderDetail.status) || {};

        return (
            <div className={'headerlist pdl15 pdr15 pdt5 pdbt5 bd1px bd-color-gray bg-color-gray'}>
                <Row className={'headerlist__title txt-color-black'}>
                    <Col span={10} className="pdt5">
                        <span className='txt-size-h8 robotoregular txt-color-black'>
                        {t('order.products')}
                        </span>
                    </Col>
                    <Col span={14}>
                        <Row>
							<Col span={6} className="txt-size-h8 robotoregular txt-color-black pdt7 txt-right">
								{t('order.hsCode')}
							</Col>
                            <Col span={6} className="txt-size-h8 robotoregular txt-color-black pdt7 txt-right">
                                {t('order.quantity')}
                            </Col>
                            <Col span={6} className="txt-size-h8 robotoregular txt-color-black pdt7 txt-right">
                                {t('order.sale_price')}
                            </Col>
                            <Col span={6} className="txt-right">
                                {
                                    currentStatus.productUpdatable && <Button
                                        onClick={() => this.setState({modalCreateShow: true})}
                                        className={'_btn-export-csv-products btn--white'}
                                    >
                                        {t('button.add_products')}
                                    </Button>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    };
    showProductCode=(item)=>{
        let str = lodash.get(item, 'code', '');
        if(str.indexOf('_')>0){
            str = str.substring(str.indexOf('_')+1);
        }
        return str;
    }
    /**
     * render item of list
     * @param item
     * @returns {*}
     */
    renderListItem = (item) => {
        const {t, deleteProduct, listStatuses = [], orderDetail = {}, listHS} = this.props;
        let currentStatus = listStatuses.find(x => x.code === orderDetail.status) || {};
        let variantPropertiesString = ""
        if (Array.isArray(item.variantProperties)) {
            item.variantProperties.map((x, y) => {
                if(x.originalName!==null&&x.originalValue!==null)
                variantPropertiesString = variantPropertiesString + (y === 0 ? '' : ', ') + x.originalName + ': ' + x.originalValue
            })
        }

        let hsItem = lodash.find(listHS, x => x.code === item.hsCode)
		let hsName = lodash.get(hsItem, 'name') || '---'
        return (
            <List.Item className={`_cart-item-${item.id} tabdetailorder__items position-re bg-color-gray bd1px bd-color-gray mgbt15 pd15`}>
                <Col span={10} className="dpl-flex align-items-start">
                    <div className="pdr10">
                        {
                            item.productImage ?
                            <Avatar
                                className={`_order-avatar mgr5`}
                                shape="square" size={44}
                                src={item.productImage}
                            />
                            :
                            <AntdIconSVG className=" custom-icon-shipment-big mgr5" component={iconShipment} style={{fontSize: 30}}/>
                        }
                        <div className="txt-size-h8 txt-color-black break-word">
                            #{this.showProductCode(item)}
                        </div>
                    </div>
                    <div className="pdr5">
                        {item.productUrl ? <a target="_blank" href={item.productUrl} className="txt-size-h7 txt-color-blue bold break-word" rel="noreferrer">{item.name || '---'}</a> : <div className="txt-size-h7 txt-color-black bold break-word">{item.name || '---'}</div>}
                        <div className="txt-size-h7 txt-color-gray break-word">
                            {item.translatedName || '---'}
                        </div>
                        {
                            variantPropertiesString && <div className="txt-size-h7 txt-color-gray break-word">
                                {variantPropertiesString}
                            </div>
                        }
                        <div className="txt-size-h7 txt-color-gray break-word">
                            <AntdIcon.ShopOutlined type="shop" className="pdr5"/> {item.merchantName || '---'}
                        </div>
                        <div className="txt-size-h7 txt-color-gray break-word">
                            <AntdIcon.EnvironmentOutlined type="environment" className="pdr5"/> {item.merchantContact || '---'}
                        </div>
                    </div>
                </Col>
                <Col span={14}>
                    <Row>
						<Col span={6} className={'position-re'}>
							<div className="txt-color-black bold txt-size-h7 txt-right">{hsName}</div>
						</Col>
                        <Col span={6} className={'position-re'}>
                            <div className="txt-color-black bold txt-size-h7 txt-right">{quantityFormat(item.quantity)}</div>
                        </Col>
                        <Col span={6}>
                            <h3 className={`_cart-item-price bold txt-size-h7 txt-color-black txt-right`}>{moneyFormat(moneyCeil(item.unitPrice), orderDetail.currency)}</h3>
                        </Col>
                        <Col span={6}>
                            {
                                currentStatus.productUpdatable && <div className="dpl-flex justify-content-end align-items-center">
                                    <div
                                        onClick={() => this.setState({modalCreateShow: true, currentProduct: item, modifiedProduct: _.cloneDeep(item)})}
                                        className={'txt-color-blue cursor-pointer txt-size-h8 robotoregular txt-right'}
                                    >
                                        {t('button.edit')}
                                    </div>
                                    <span className="pdr10 pdl10">|</span>
                                    <Popconfirm
                                        overlayClassName={`_btn-delete-shop-popconfirm-overlay`}
                                        className={`_btn-delete-shop-popconfirm`}
                                        title={t('message.delete_confirm')}
                                        onConfirm={() => deleteProduct(item.code)}
                                        okText={t('button.yes')}
                                        cancelText={t('button.no')}
                                    >
                                        <div
                                            className={'txt-color-blue cursor-pointer txt-size-h8 robotoregular txt-right'}
                                        >
                                            {t('button.delete')}
                                        </div>
                                    </Popconfirm>

                                </div>
                            }
                        </Col>
                    </Row>
                </Col>
            </List.Item>
        )
    };

    /**
     * render loadmore button
     * @returns {null}
     */
    renderLoadMore = () => {
        const {dataSource, t} = this.props;
        const { initLoading, loading, isLoadMore } = this.state;

        return !initLoading && !loading && Array.isArray(dataSource) && dataSource.length > pageSize ?
            !isLoadMore
                ?
                <div style={{
                    textAlign: 'center', marginTop: 12, height: 32, lineHeight: '32px',
                }}
                >
                    <Button className={'_order-detail-btn-loadmore'} onClick={this.onLoadMore}>{t('button.loadmore')}</Button>
                </div>
                :
                <div style={{
                    textAlign: 'center', marginTop: 12, height: 32, lineHeight: '32px',
                }}
                >
                    <Button className={`_cart-btn-collapse-${this.props.index}`} onClick={this.onLoadMore}>{t('button.collapse')}</Button>
                </div>
            : null
    };

    /**
     * loadmore data
     */
    onLoadMore = () => {
        const {dataSource} = this.props;
        let {dataList} = this.state;
        let isLoadMore = false;
        this.setState({
            loading: true,
            initLoading: true
        }, () => {
            setTimeout(() => {
                if (dataList.length === dataSource.length) {
                    dataList = dataSource.slice(0, pageSize)
                } else {
                    dataList = dataSource;
                    isLoadMore = true
                }
                this.setState({
                    isLoadMore,
                    dataList,
                    loading: false,
                    initLoading: false
                })
            }, 500)
        });
    };

    handleSubmit = () => {
        const {modifiedProduct = {}, currentProduct} = this.state;
        const {productCreating, createProduct} = this.props;
        if (!productCreating && !this.onValidate()) {
            let body = {};
            if (currentProduct) {
                for (let p in currentProduct) {
                    if (currentProduct[p] !== modifiedProduct[p]) {
                        body[p] = modifiedProduct[p];
                    }
                }
            } else {
                body = modifiedProduct;
            }

            createProduct(body, currentProduct, () => {
                this.setState({modalCreateShow: false, currentProduct: null, modifiedProduct: {}})
                this.setState({
                    uploadList: [],
                    previewUrl: null,
                    previewTitle: null,
                    previewVisible: false
                })
            })
        }
    };

    onInputChange = (value, props) => {
        let {modifiedProduct = {}} = this.state;
        modifiedProduct[props] = value;
        this.setState({
            modifiedProduct
        })
    };

    onValidate = () => {
        const {t} = this.props;
        let {validateError = {}, modifiedProduct = {}} = this.state;
        let err = false;
        if (!modifiedProduct['name'] || !modifiedProduct['name'].toString().trim()) {
            validateError['name'] = t('shipment.require');
            err = true
        } else {
            validateError['name'] = '';
        }
        if (!modifiedProduct['translatedName'] || !modifiedProduct['translatedName'].toString().trim()) {
            validateError['translatedName'] = t('shipment.require');
            err = true
        } else {
            validateError['translatedName'] = '';
        }
        if (modifiedProduct['quantity'] !== 0 && (!modifiedProduct['quantity'] || !modifiedProduct['quantity'].toString().trim())) {
            validateError['quantity'] = t('shipment.require');
            err = true
        } else {
            validateError['quantity'] = '';
        }
        if (modifiedProduct['unitPrice'] !== 0 && (!modifiedProduct['unitPrice'] || !modifiedProduct['unitPrice'].toString().trim())) {
            validateError['unitPrice'] = t('shipment.require');
            err = true
        } else {
            validateError['unitPrice'] = '';
        }
        if (!modifiedProduct['productUrl'] || !modifiedProduct['productUrl'].toString().trim()) {
            validateError['productUrl'] = t('shipment.require');
            err = true
        } else {
            if (!validateLink(modifiedProduct['productUrl'])) {
                validateError['productUrl'] = t('shipment.link_error');
                err = true
            } else {
                validateError['productUrl'] = '';
            }
        }

        this.setState({validateError});

        return err;
    };

    /**
     * validate khi blur
     * @param props
     */
    onBlurInput = (props) => {
        const {t} = this.props;
        let {validateError = {}, modifiedProduct = {}} = this.state;
        switch (props) {
            case 'name':
            case 'translatedName':
            case 'quantity':
            case 'unitPrice':
                if (modifiedProduct[props] !== 0 && (!modifiedProduct[props] || !modifiedProduct[props].toString().trim())) {
                    validateError[props] = t('shipment.require');
                } else {
                    validateError[props] = '';
                }
                this.setState({
                    validateError
                });
                break;
            case 'productUrl':
                if ((!modifiedProduct[props] || !modifiedProduct[props].toString().trim())) {
                    validateError[props] = t('shipment.require');
                } else {
                    if (!validateLink(modifiedProduct[props])) {
                        validateError[props] = t('shipment.link_error');
                    } else {
                        validateError[props] = '';
                    }
                }
                this.setState({
                    validateError
                });
                break;
            default:
                break;
        }
    };

    handleBeforeUploadImage = () => {
        return false
    }

    handleUploadChange = async (data) => {
        const {modifiedProduct = {}} = this.state;
        if (data.fileList.length > 0) {

            let file
            for (let item of data.fileList) {
                if (item.uid === data.file.uid) {
                    file = item
                    break
                }
            }

            const {t} = this.props
            if (!file.type.startsWith('image/')) {
                notification.error({
                    message: t('shipment.uploadErrorImageFileTypeInvalid')
                })
                return
            }

            if (file.size > MAX_UPLOAD_FILE_SIZE) {
                notification.error({
                    message: t('shipment.uploadErrorMaxFileSize')
                })
                return
            }

            file.status = 'uploading'
            this.setState({
                uploadList: [file]
            })

            const formData = new FormData()
            formData.append('attachments', file.originFileObj)
            this.props.onUploadImage(formData)
                .then(response => {
                    file.status = 'done'
                    file.url = response.url
                    this.setState({
                        uploadList: [file]
                    })

                    modifiedProduct.productImage = response.url
                    this.setState({modifiedProduct})
                })
        }
        else {
            modifiedProduct.productImage = null
            this.setState({
                uploadList: [],
                modifiedProduct
            })
        }
    }

    handlePreviewImage = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.handlePreviewImagePreviewVisible(true);
        this.setState({
            previewTitle: file.name,
            previewUrl: file.url
        })
    }

    handlePreviewImagePreviewVisible = (visible) => {
        this.setState({previewVisible: visible})
    }

    renderModalCreate = () => {
        const {modalCreateShow, currentProduct, modifiedProduct = {}, validateError = {}, uploadList} = this.state;
        const {productCreating, t, orderDetail = {}} = this.props;

        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        let currencies = LocalStore.getInstance().read('currencies') || [];

        let tenantConfig = currentProjectInfo.tenantConfig || {};
        let currency = tenantConfig.currency || {};

        let unit = '';

        if (orderDetail && orderDetail.currency) {
            const unitCurrency = currencies.find(x => x.code === orderDetail.currency) || {};
            unit = unitCurrency.prefix || unitCurrency.suffix;
        } else {
            unit = currency.prefix || currency.suffix;
        }

        return <Modal
            className="_modalAddress modalform"
            title={currentProduct ? `${t('order.products')} ${currentProduct.name}` : t('button.add_products')}
            visible={modalCreateShow}
            destroyOnClose={true}
            onOk={() => this.handleSubmit()}
            confirmLoading={productCreating}
            onCancel={() => {
                this.setState({
                    modalCreateShow: false,
                    currentProduct: null,
                    modifiedProduct: {},
                    uploadList: []
                })
            }}
            okText={currentProduct ? t('button.save') : t('button.add_products')}
            okButtonProps={{className:"_btn-add-address"}}
            cancelText={t('button.cancel')}
            cancelButtonProps={{className:"_btn-cancel-address"}}
        >
            <div>
                <Row gutter={20} className="mgbt10">
                    <Col span={24} className={'mgbt10'}>
                        <h5 className='_fullnameTitle txt-size-h7 txt-color-black robotoregular mgbt5'>{t('shipment.productImage')}</h5>
                        <Upload
                            accept={"image/*"}
                            fileList={uploadList}
                            multiple={false}
                            listType="picture-card"
                            showUploadList={{
                                showPreviewIcon: true,
                                showRemoveIcon: true
                            }}
                            beforeUpload={this.handleBeforeUploadImage}
                            onPreview={this.handlePreviewImage}
                            onChange={this.handleUploadChange}
                        >
                            <div className={''}>
                                <UploadOutlined />
                            </div>
                        </Upload>
                    </Col>
                    <Col span={24}>
                        <h5 className='_fullnameTitle txt-size-h7 txt-color-black robotoregular mgbt5'>{t('shipment.productLink')}<span className={'txt-color-red mgl5'}>*</span></h5>
                        <Input
                            className={'txt-size-h7'}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.handleSubmit()
                                }
                            }}
                            onBlur={() => this.onBlurInput('productUrl')}
                            ref={ref => this[`productUrl`] = ref}
                            value={modifiedProduct.productUrl}
                            onChange={(e) => this.onInputChange(e.target.value, 'productUrl')}
                        />
                        {
                            validateError.productUrl && <div className='txt-error mgt5 txt-size-h8'>
                                {validateError.productUrl}
                            </div>
                        }
                    </Col>
                </Row>
                <Row gutter={20} className="mgbt10">
                    <Col span={12}>
                        <h5 className='_fullnameTitle txt-size-h7 txt-color-black robotoregular mgbt5'>{t('order.quantity')}<span className={'txt-color-red mgl5'}>*</span></h5>
                        <InputNumber
                            className="width-100-percent"
                            precision={0} // bắt chỉ cho nhập integer
                            min={0}
                            ref={ref => this[`quantity`] = ref}
                            value={modifiedProduct.quantity}
                            formatter={value => {
                                let splitValue = `${value}`.split('.');
                                return `${splitValue[0]}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (splitValue[1] !== undefined ? `.${splitValue[1]}` : '') ;
                            }}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(value) => {
                                if (typeof value === 'number') {
                                    this.onInputChange(value, 'quantity')
                                }
                            }}
                            onBlur={() => this.onBlurInput('quantity')}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.handleSubmit()
                                }
                            }}
                        />
                        {
                            validateError.quantity && <div className='txt-error mgt5 txt-size-h8'>
                                {validateError.quantity}
                            </div>
                        }
                    </Col>
                    <Col span={12}>
                        <h5 className='_fullnameTitle txt-size-h7 txt-color-black robotoregular mgbt5'>{t('order.sale_price')} {unit ? `(${unit.trim()})`: ''}<span className={'txt-color-red mgl5'}>*</span></h5>
                        <InputNumber
                            className="width-100-percent"
                            // precision={0} // bắt chỉ cho nhập integer
                            min={0}
                            ref={ref => this[`unitPrice`] = ref}
                            value={modifiedProduct.unitPrice}
                            formatter={value => {
                                let splitValue = `${value}`.split('.');
                                return `${splitValue[0]}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (splitValue[1] !== undefined ? `.${splitValue[1]}` : '') ;
                            }}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(value) => {
                                if (typeof value === 'number') {
                                    this.onInputChange(value, 'unitPrice')
                                }
                            }}
                            onBlur={() => this.onBlurInput('unitPrice')}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.handleSubmit()
                                }
                            }}
                        />
                        {
                            validateError.unitPrice && <div className='txt-error mgt5 txt-size-h8'>
                                {validateError.unitPrice}
                            </div>
                        }
                    </Col>
                </Row>
                <Row gutter={20} className="mgbt10">
                    <Col span={24}>
                        <h5 className='_fullnameTitle txt-size-h7 txt-color-black robotoregular mgbt5'>{t('shipment.name')}<span className={'txt-color-red mgl5'}>*</span></h5>
                        <Input
                            className={'txt-size-h7'}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.handleSubmit()
                                }
                            }}
                            onBlur={() => this.onBlurInput('name')}
                            ref={ref => this[`name`] = ref}
                            value={modifiedProduct.name}
                            onChange={(e) => this.onInputChange(e.target.value, 'name')}
                        />
                        {
                            validateError.name && <div className='txt-error mgt5 txt-size-h8'>
                                {validateError.name}
                            </div>
                        }
                    </Col>
                </Row>
                <Row gutter={20} className="pdbt10">
                    <Col span={24}>
                        <h5 className='_fullnameTitle txt-size-h7 txt-color-black robotoregular mgbt5'>{t('shipment.translatedName')}<span className={'txt-color-red mgl5'}>*</span></h5>
                        <Input
                            className={'txt-size-h7'}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.handleSubmit()
                                }
                            }}
                            onBlur={() => this.onBlurInput('translatedName')}
                            ref={ref => this[`translatedName`] = ref}
                            value={modifiedProduct.translatedName}
                            onChange={(e) => this.onInputChange(e.target.value, 'translatedName')}
                        />
                        {
                            validateError.translatedName && <div className='txt-error mgt5 txt-size-h8'>
                                {validateError.translatedName}
                            </div>
                        }
                    </Col>
                </Row>
                <Row gutter={20} className="pdbt10">
                    <Col span={24}>
                        <h5 className='_fullnameTitle txt-size-h7 txt-color-black robotoregular mgbt5'>{t('shipment.shopName')}</h5>
                        <Input
                            className={'txt-size-h7'}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.handleSubmit()
                                }
                            }}
                            onBlur={() => this.onBlurInput('merchantName')}
                            ref={ref => this[`merchantName`] = ref}
                            value={modifiedProduct.merchantName}
                            onChange={(e) => this.onInputChange(e.target.value, 'merchantName')}
                        />
                    </Col>
                </Row>
                <Row className="pdbt10">
                    <Col span={24}>
                        <h5 className='_fullnameTitle txt-size-h7 txt-color-black robotoregular mgbt5'>{t('shipment.addressShop')}</h5>
                        <Input
                            className={'txt-size-h7'}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.handleSubmit()
                                }
                            }}
                            onBlur={() => this.onBlurInput('merchantContact')}
                            ref={ref => this[`merchantContact`] = ref}
                            value={modifiedProduct.merchantContact}
                            onChange={(e) => this.onInputChange(e.target.value, 'merchantContact')}
                        />
                    </Col>
                </Row>
            </div>
        </Modal>
    };

    render(){
        const { initLoading, dataList = [], previewVisible, previewTitle, previewUrl } = this.state;
        return(
            <React.Fragment>
                <List
                    className={'list-carts mgbt5 list-hover-item'}
                    dataSource={dataList}
                    header={this.renderHeaderList()}
                    renderItem={item => {
                        return (this.renderListItem(item))
                    }}
                    loading={initLoading}
                    loadMore={this.renderLoadMore()}
                />
                {this.renderModalCreate()}

                <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={() => this.handlePreviewImagePreviewVisible(false)}
                >
                    <div style={{paddingBottom: 25}}>
                        <img
                            alt="example"
                            style={{ width: '100%' }}
                            src={previewUrl}
                        />
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}
export default TabProduct;
