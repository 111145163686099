import PolimClient from '../utils/api/PolimClient';
import {LocalStore} from "../utils/LocalStore";

export default class ShipmentsService {
    /**
     * Lấy thông tin chi tiết đơn ký gửi
     * @param orderId
     * @param cb
     */
    static getShipmentDetail(orderId, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/${orderId}`,
            method: 'GET'
        }, cb)
    }

    /**
     * Lấy danh sách phí của đơn ký gửi
     * @param code
     * @param cb
     */
    static getShipmentProducts(code, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/${code}/products`,
            method: 'GET'
        }, cb)
    }

    /**
     * Lấy danh sách phí của đơn ký gửi
     * @param code
     * @param body
     * @param cb
     */
    static createShipmentProducts(code, body, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/${code}/products`,
            method: 'POST',
            body
        }, cb)
    }

    /**
     * Lấy danh sách phí của đơn ký gửi
     * @param code
     * @param productCode
     * @param body
     * @param cb
     */
    static editShipmentProducts(code, productCode, body, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/${code}/products/${productCode}`,
            method: 'PATCH',
            body
        }, cb)
    }

    /**
     * Lấy danh sách phí của đơn ký gửi
     * @param code
     * @param productCode
     * @param cb
     */
    static deleteShipmentProducts(code, productCode, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/${code}/products/${productCode}`,
            method: 'DELETE',
        }, cb)
    }

    /**
     * Lấy danh sách phí của đơn ký gửi
     * @param code
     * @param cb
     */
    static getShipmentFees(code, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/${code}/fees`,
            method: 'GET'
        }, cb)
    }

    /**
     * Lấy danh sách mã vận đơn của đơn ký gửi
     * @param code
     * @param cb
     */
    static getShipmentWaybill(code, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/${code}/waybill`,
            method: 'GET'
        }, cb)
    }

    /**
     * tạo mã vận đơn của đơn ký gửi
     * @param code
     * @param body
     * @param cb
     */
    static createShipmentWaybill(code, body, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/${code}/waybill`,
            method: 'POST',
            body: body
        }, cb)
    }

    /**
     * xóa mã vận đơn của đơn ký gửi
     * @param code
     * @param waybillCode
     * @param cb
     */
    static deleteShipmentWaybill(code, waybillCode, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/${code}/waybill/${waybillCode}`,
            method: 'DELETE'
        }, cb)
    }

    /**
     * Lấy danh sách mã vận đơn của đơn ký gửi
     * @param code
     * @param cb
     */
    static getShipmentParcel(code, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/${code}/parcels?size=9999`,
            method: 'GET'
        }, cb)
    }

    /**
     * Lấy danh sách milestones parcel
     * @param parcelCode
     * @param cb
     */
    static getParcelMileStone(parcelCode, cb) {
        PolimClient.request({
            endpoint: `/customer/parcels/${parcelCode}/milestones`,
            method: 'GET'
        }, cb)
    }

    /**
     * thay đổi giá trị hàng hóa trong đơn ký gửi
     * @param code
     * @param body
     * @param cb
     */
    static editShipment(code, body, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/${code}`,
            method: 'PATCH',
            body
        }, cb)
    }

    /**
     * lấy danh sách log
     * @param code
     * @param page
     * @param cb
     */
    static getLog(code, page, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/${code}/activities?sort=timestamp:desc&page=${page || 0}&size=25`,
            method: 'GET'
        }, cb)
    }

    /**
     * Lấy danh sách comment
     * @param page
     * @param code
     * @param cb
     */
    static getComments(code, page, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/${code}/comments?sort=timestamp:DESC&page=${page}&size=25`,
            method: 'GET'
        }, cb)
    }

    /**
     * Tạo comment
     * @param code
     * @param data
     * @param cb
     */
    static createComment(code, data, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/${code}/comments`,
            method: 'POST',
            body: data
        }, cb)
    }

    /**
     * lấy danh sách giao dich
     * @param code
     * @param cb
     */
    static getFinance(code, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/${code}/financial?sort=timestamp:desc&size=1000`,
            method: 'GET'
        }, cb)
    }

    /**
     *
     * @param code
     * @param cb
     */
    static confirmReceived(code,cb){
        PolimClient.request({
            endpoint: `/customer/shipments/${code}/confirm`,
            method: 'POST',
        }, cb)
    }

    /**
     * xác nhận thanh toán đơn hàng
     * @param code
     * @param body
     * @param cb
     */
    static confirmPayOrder(code, body, cb) {

        let pinToken = LocalStore.getInstance().read('pinToken') || "";

        let headers = {};

        if (pinToken) {
            headers["X-PIN-TOKEN"] = pinToken
        } else if (body.password) {
            headers["X-PIN"] = body.password;
            if (body.savePassword) {
                headers["X-REMEMBER-PIN"] = true
            }
        }

        PolimClient.request({
            endpoint: `/customer/shipments/${code}/settlement`,
            method: 'POST',
            headers,
            body
        }, cb)
    }

    /**
     * xác nhận hủy đơn hàng
     * @param code
     * @param cb
     */
    static confirmCancelOrder(code, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/${code}/cancel`,
            method: 'POST',
        }, cb)
    }

    /**
     * lấy ra lịch sử các mốc đặt hàng
     * @param code
     * @param cb
     */
    static getShipmentMileStones(code, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/${code}/milestones?sort=timestamp:ASC`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra danh sách count theo từng trạng thái
     * @param cb
     */
    static getShipmentsStatistics(cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/statistics`,
            method: 'GET',
        }, cb)
    }

    /**
     * Lấy danh sách giao dịch trong chi tiết đơn
     * @param orderCode
     * @param type
     * @param cb
     */
    static getListFinancialOrder(orderCode, type, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/${orderCode}/financial${type ? `?type=${type}` : ''}`,
            method: 'GET'
        }, cb)
    }

    /**
     * Lấy danh sách hóa đơn gốc
     * @param orderCode
     * @param cb
     */
    static getListOriginalReceipts(orderCode, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/${orderCode}/original_receipts`,
            method: 'GET'
        }, cb)
    }

    /**
     * thêm hóa đơn gốc
     * @param orderCode
     * @param body
     * @param cb
     */
    static addOriginalReceipt(orderCode, body, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/${orderCode}/original_receipts`,
            method: 'POST',
            body
        }, cb)
    }

    /**
     * thêm hóa đơn gốc
     * @param orderCode
     * @param waybillCode
     * @param cb
     */
    static checkWallbillDuplicate(orderCode, waybillCode, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/${orderCode}/waybill/${waybillCode}/check_create`,
            method: 'GET',
        }, cb)
    }

    /**
     * xóa hóa đơn gốc
     * @param orderCode
     * @param body
     * @param cb
     */
    static deleteOriginalReceipt(orderCode, body, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/${orderCode}/original_receipts`,
            method: 'DELETE',
            body
        }, cb)
    }

    /**
     * xóa hóa đơn gốc
     * @param orderCode
     * @param query
     * @param cb
     */
    static printParcels(orderCode, query, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/${orderCode}/parcels/shipping_label_template${query}`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra danh sách khiếu nại của đơn
     * @param code
     * @param queryString
     * @param cb
     */
    static getShipmentTickets(code, queryString, cb) {
        PolimClient.request({
            endpoint: `/customer/canines/claims/orderCode/${code}${queryString || ''}`,
            method: 'GET',
        }, cb)
    }

    /**
     * Lấy ra hành trình của kiện
     * @param waybillCode
     * @param cb
     */
    static getPackageIOHistories(code, cb) {
        PolimClient.request({
            endpoint: `/customer/parcels/${code}/io-histories?sort=createdAt:desc`,
            method: 'GET',
        }, cb)
    }

    static uploadImage(code, file, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/${code}/upload-product-image`,
            method: 'POST',
            file: file
        }, cb)
    }

    static getCommentsPosedon(code, page, cb) {
        PolimClient.request({
            endpoint: `/customer/comments/shipments/${code}?sort=createdAt:DESC&page=${page}&size=25`,
            method: 'GET',
        }, cb)
    }

    static createCommentInShipment(code,body, cb){
        PolimClient.request({
            endpoint: `/customer/comments/shipments/${code}`,
            method: 'POST',
            body: body
        }, cb)
    }

    static getAttachmentPosedon(code, cb) {
        PolimClient.request({
            endpoint: `/customer/comments/shipments/${code}/attachments`,
            method: 'GET',
        }, cb)
    }
}
