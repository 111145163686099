import React from 'react'
import _ from 'lodash'
import { Avatar, Input, Modal, notification, Spin } from 'antd'
import * as AntdIcon from '@ant-design/icons'
import AntdIconSVG from '@ant-design/icons'
import DataTransfer from 'fbjs/lib/DataTransfer'
import CameraDropzone from '../ReactDropzone/Camera'
import Download from '../Download'

import { validateMaxLength, validateRequired } from '../../utils/validate'
import { checkIsLink, formatFileName, linkify, timeAgo } from '../../utils/stringUtils'
import InfiniteScroll from 'react-infinite-scroller'
import { ReactComponent as iconVip } from '../../resources/images/ic-vp.svg'

import { ReactComponent as iconDoc } from '../../resources/images/_doc - docx.svg'
import { ReactComponent as iconOther } from '../../resources/images/_other.svg'
import { ReactComponent as iconPdf } from '../../resources/images/_pdf.svg'
import { ReactComponent as iconPttx } from '../../resources/images/_ppt pttx.svg'
import { ReactComponent as iconXls } from '../../resources/images/_xls - xlsx.svg'
import { ReactComponent as iconZip } from '../../resources/images/_zip.svg'
import { LocalStore } from '../../utils/LocalStore'

import TextEditor from '../commons/TextEditor'
import moment from 'moment'

export default class Comment extends React.Component {
    constructor(props) {
        super(props)
        this.isUnmounted = false

        this.state = {
            commentContent: '',
            validateError: {},
            // isMobile: window.innerWidth < 981,
            commentBoxHeight: window.innerHeight - 66 - 15,
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.windowResize)
    }

    componentWillUnmount() {
        this.isUnmounted = true
        window.removeEventListener('resize', this.windowResize)
    }

    /**
     * khi window resize
     */
    windowResize = () => {
        this.scrollToTop()
        const { commentBoxFixed } = this.state

        // thay đổi nếu window resize
        if (!this.col3Container) {
            // if (!this.isUnmounted) this.setState({
            //     isMobile: windowWidth < 981
            // });
            return
        }

        const elementWidth = this.col3Container.getBoundingClientRect().width
        if (!this.isUnmounted)
            this.setState({
                commentBoxHeight: commentBoxFixed ? window.innerHeight - 30 : window.innerHeight - 66 - 15,
                commentBoxFixedWidth: elementWidth,
                // isMobile: windowWidth < 981
            })
    }

    pasteHandle = pasteEvent => {
        const { t } = this.props
        const data = new DataTransfer(pasteEvent.clipboardData)
        const files = data.getFiles()

        if (Array.isArray(files) && files.length > 0) {
            // validate nếu số lượng file lớn hơn 10 file thì thông báo lỗi
            if (files.length <= 10) {
                // validate nếu có file có size lớn hơn 10Mb hoặc là ko đúng định dạng ảnh thì sẽ hiển thị thông báo
                if (files.find(x => x.size > 10485760) || files.find(x => x.type.indexOf('image') < 0)) {
                    notification['error']({
                        message: t('error.upload_image'),
                    })
                } else {
                    this.uploadFiles(files)
                }
            } else {
                notification['error']({
                    message: t('error.upload_picture'),
                })
            }
            pasteEvent.preventDefault()
        }
    }

    /**
     * scroll to bottom
     */
    scrollToTop = () => {
        if (this.chatbox) this.chatbox.scrollTo = 0 // this.chatbox.scrollHeight;
    }

    /**
     * thay đổi dữ liệu khi typing
     * @param e
     */
    onChangeInput = e => {
        this.setState({
            commentContent: e.target.value,
        })
    }

    /**
     * validate khi blur
     * @param field
     */
    onBlurInput = field => {
        let { validateError } = this.state
        switch (field) {
            case 'content':
                // clear error
                validateError.content = ''
                this.setState({
                    validateError,
                })
                break
            default:
                break
        }
    }

    /**
     * sự kiện keydown của textarea
     * @param e
     */
    textAreaKeyDown = e => {
        const { uploadImageComment } = this.props
        const { commentContent, files = [] } = this.state
        // bắt sự kiện submit khi ctr enter hoặc cmd enter
        if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
            //bắt sự kiện khi ctrl + enter hoặc cmd + enter
            if (!this.createValidate()) {
                this.scrollToTop()
                uploadImageComment({ content: commentContent.trim(), files }, () =>
                    this.setState({
                        commentContent: '',
                        files: [],
                    })
                )
            }
        }
    }

    /**
     * validate khi create
     * @returns {boolean}
     */
    createValidate = () => {
        let { commentContent, validateError, files = [] } = this.state
        let validate = false

        //validate content
        if (validateRequired(commentContent.trim())) {
            validateError.content = validateRequired(commentContent.trim())
            this.setState({
                validateError,
            })
            validate = true
        } else if (validateMaxLength(commentContent, 'Comment', 1000)) {
            validateError.content = validateMaxLength(commentContent, 'Comment', 1000)
            this.setState({
                validateError,
            })
            validate = true
        } else {
            validateError.content = ''
            this.setState({
                validateError,
            })
        }

        if (files.length > 0) {
            return false
        }

        return validate
    }

    handlePreview = item => {
        this.setState({
            previewItem: item,
            previewVisible: true,
        })
    }

    uploadFiles = filesUpload => {
        const { t } = this.props
        const { isCommenting, isUploading, files = [] } = this.state

        if (isCommenting === true || isUploading === true) return

        if ([...files, ...filesUpload].length > 10) {
            notification['error']({
                message: t('error.upload_picture'),
            })
        } else {
            this.setState({ files: [...files, ...filesUpload] })
        }
    }

    uploadFilesPosedon = filesUpload => {
        const { uploadImageComment } = this.props
        // const { t } = this.props
        // const { isCommenting, isUploading, files = [] } = this.state

        // if (isCommenting === true || isUploading === true) return

        // if ([...files, ...filesUpload].length > 10) {
        //     notification['error']({
        //         message: t('error.upload_picture'),
        //     })
        // } else {
        //     this.setState({ files: [...files, ...filesUpload] })
        // }
        uploadImageComment({ content: null, files: filesUpload }, () =>
            this.setState({
                commentContent: '',
                files: [],
            })
        )
    }

    removeFile = file => {
        const { files = [] } = this.state

        this.setState({ files: files.filter(x => x !== file), previewRemoveIcon: null })
    }

    renderIcon = type => {
        switch (type) {
            case 'application/java-archive':
            case 'application/vnd.rar':
            case 'application/x-7z-compressed':
            case 'application/zip':
                return <AntdIconSVG component={iconZip} />
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return <AntdIconSVG component={iconDoc} />
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                return <AntdIconSVG component={iconPttx} />
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return <AntdIconSVG component={iconXls} />
            case 'application/pdf':
                return <AntdIconSVG component={iconPdf} />
            default:
                return <AntdIconSVG component={iconOther} />
        }
    }

    /**
     * render row image
     * @param item
     * @param index
     * @returns {*}
     */
    renderRowImage = (item, index) => {
        const { fileDownloadIcon } = this.state
        if (item.type.indexOf('video') < 0 && item.type.indexOf('image') < 0) {
            return (
                <div
                    className={'mgbt5 mgr5'}
                    key={index}
                >
                    <Download
                        file={item.name}
                        content={item.url}
                        onMouseOver={() => this.setState({ fileDownloadIcon: item.url })}
                        onMouseLeave={() => this.setState({ fileDownloadIcon: null })}
                    >
                        <div
                            className="_chat-attachments-item chat-attachments-item cursor-pointer position-re dpl-flex align-items-center justify-content overflow-hidden"
                            style={{ fontSize: 30, border: '1px solid #a0abb8', color: '#a0abb8' }}
                        >
                            {fileDownloadIcon === item.url ? (
                                <AntdIcon.DownloadOutlined type="download" />
                            ) : (
                                this.renderIcon(item.type)
                            )}
                        </div>
                    </Download>
                    <p className="_file-name txt-color-gray txt-size-h9 text-center mgbt0">{formatFileName(item.name, 12)}</p>
                </div>
            )
        }

        

        return (
            <div
                className={'mgbt5 mgr5'}
                key={index}
            >
                <div
                    onClick={() => this.handlePreview(item)}
                    className="_chat-attachments-item chat-attachments-item cursor-pointer position-re dpl-flex align-items-center justify-content overflow-hidden"
                    style={item.type.indexOf('video') > -1 ? { fontSize: 30, border: '1px solid #a0abb8', color: '#a0abb8' } : {}}
                >
                    {item.type.indexOf('video') > -1 ? (
                        <AntdIcon.PlayCircleOutlined type="play-circle" />
                    ) : (
                        <div
                            className="image-preview"
                            style={{ backgroundImage: `url(${item.url})` }}
                        />
                    )}
                </div>
                <p className="_file-name txt-color-gray txt-size-h9 text-center mgbt0">{formatFileName(item.name, 12)}</p>
            </div>
        )
    }

    renderRowImagePosedon = (item) => {
        const { fileDownloadIcon } = this.state
        if (item.mimeType.indexOf('video') < 0 && item.mimeType.indexOf('image') < 0) {
            return (
                <div
                    className={'mgbt5 mgr5'}
                    key={item?.id}
                >
                    <Download
                        file={item.name}
                        content={item.location}
                        onMouseOver={() => this.setState({ fileDownloadIcon: item.location})}
                        onMouseLeave={() => this.setState({ fileDownloadIcon: null })}
                    >
                        <div
                            className="_chat-attachments-item chat-attachments-item cursor-pointer position-re dpl-flex align-items-center justify-content overflow-hidden"
                            style={{ fontSize: 30, border: '1px solid #a0abb8', color: '#a0abb8' }}
                        >
                            {fileDownloadIcon === item.url ? (
                                <AntdIcon.DownloadOutlined type="download" />
                            ) : (
                                this.renderIcon(item.mimeType)
                            )}
                        </div>
                    </Download>
                    <p className="_file-name txt-color-gray txt-size-h9 text-center mgbt0">{formatFileName(item.name, 12)}</p>
                </div>
            )
        }
        return (
            <div
                className={'mgbt5 mgr5'}
                key={item?.id}
            >
                <div
                    onClick={() => this.handlePreview(item)}
                    className="_chat-attachments-item chat-attachments-item cursor-pointer position-re dpl-flex align-items-center justify-content overflow-hidden"
                    style={item.mimeType.indexOf('video') > -1 ? { fontSize: 30, border: '1px solid #a0abb8', color: '#a0abb8' } : {}}
                >
                    {item.mimeType.indexOf('video') > -1 ? (
                        <AntdIcon.PlayCircleOutlined type="play-circle" />
                    ) : (
                        <div
                            className="image-preview"
                            style={{ backgroundImage: `url(${item.location})` }}
                        />
                    )}
                </div>
                <p className="_file-name txt-color-gray txt-size-h9 text-center mgbt0">{formatFileName(item.name, 12)}</p>
            </div>
        )
    }

    /**
     * render dòng comment Posedon
     * @param item
     * @param index
     */
    renderRowCommentPosedon = (item, index) => {
        const { listAttachments, t } = this.props
        const attachments = listAttachments?.filter((i) => i?.messageId === item?.id)
        return (
            <div
                className={`${index !== 0 && 'mgt20'} comment-item ${
                    item?.from?.trusted?.customer
                        ? 'bg-color-yellow2'
                        : 'bg-color-gray3'
                } dpl-flex`}
                key={index}
            >
                <Avatar src={item.from.card.photo} />
                <div className={'pdl10 width-100-percent flex-1-1'}>
                    <div className="dpl-flex justify-content-between align-items-center">
                        <span className="_comment-author txt-size-h9 robotoregular txt-color-gray2">
                            {item.from.card.fn}{' '}
                            {item?.from?.trusted?.customer && (
                                <AntdIconSVG
                                    component={iconVip}
                                    style={{ fontSize: 12 }}
                                />
                            )}
                            , <span className="_comment-createdAt">{timeAgo(item?.createdAt || item.updatedAt, t)}</span>
                        </span>
                    </div>

                    {item?.content?.plainText && (
                        <div className={`bd-rdu mgbt5`}>
                            {checkIsLink(item?.content?.plainText) ? (
                                <div
                                    className={`_txt-comment-item is-link line-height134 txt-color-black dpl-il-block break-word txt-size-h8 whitespace-pre-wrap max-width-100`}
                                    dangerouslySetInnerHTML={{ __html: linkify(item?.content?.plainText) }}
                                />
                            ) : (
                                <div
                                    className={`_txt-comment-item line-height134 w-full h-full txt-color-black dpl-il-block break-word txt-size-h8 whitespace-pre-wrap max-width-100`}
                                    dangerouslySetInnerHTML={{ __html: item?.content?.plainText }}
                                ></div>
                            )}
                        </div>
                    )}

                    {
                    attachments?.length > 0 &&
                    <div className="_chat-attachments-wrapper chat-attachments-wrapper">
                        {
                            attachments.map((i) => this.renderRowImagePosedon(i))
                        }
                    </div>
                }
                </div>
            </div>
        )
    }

    renderRecallPosedon = (item, index) => {
        const { t } = this.props
        return (
            <div
                className={`${index !== 0 && 'mgt20'} comment-item ${
                    item?.from?.trusted?.customer
                        ? 'bg-color-yellow2'
                        : 'bg-color-gray3'
                } _item-recalled`}
                key={index}
            >
                <span className="_comment-author txt-size-h8 utmhelve-bold txt-color-gray2 line-height167">
                    {item.from.card.fn}{' '}
                    {item?.from?.trusted?.customer && (
                        <AntdIconSVG
                            component={iconVip}
                            style={{ fontSize: 12 }}
                        />
                    )}
                </span>
                <div className={`pd10 bd-rdu mgbt5`}>
                    <p
                        className={
                            '_txt-comment-item utmhelve-regular line-height134 dpl-il-block break-word txt-size-h7 whitespace-pre-wrap break-word max-width-100 txt-color-gray'
                        }
                    >
                        {t('message.deleted_comment')}
                    </p>
                </div>
                <div className="">
                    <span className="_comment-createdAt txt-size-h9 utmhelve-regular txt-color-gray">
                        {timeAgo(item.recallTime || new Date(), t)}
                    </span>
                </div>
            </div>
        )
    }

    /**
     * render dòng comment cũ
     * @param item
     * @param index
     */

    renderRowComment = (item, index) => {
        const { currentLoggedUser, t } = this.props
        return (
            <div
                className={`${index !== 0 && 'mgt20'} comment-item ${
                    currentLoggedUser.username === item.author.username && !item.staff ? 'bg-color-yellow2' : 'bg-color-gray3'
                } dpl-flex`}
                key={index}
            >
                <Avatar src={item.author.avatar} />
                <div className={'pdl10 width-100-percent flex-1-1'}>
                    <div className="dpl-flex justify-content-between align-items-center">
                        <span className="_comment-author txt-size-h9 robotoregular txt-color-gray2">
                            {item.author.fullname}{' '}
                            {currentLoggedUser.username === item.author.username && !item.staff && (
                                <AntdIconSVG
                                    component={iconVip}
                                    style={{ fontSize: 12 }}
                                />
                            )}
                            , <span className="_comment-createdAt">{timeAgo(item.timestamp, t)}</span>
                        </span>
                    </div>

                    {item.content && (
                        <div className={`bd-rdu mgbt5`}>
                            {checkIsLink(item.content) ? (
                                <div
                                    className={`_txt-comment-item is-link line-height134 txt-color-black robotomedium dpl-il-block break-word txt-size-h8 whitespace-pre-wrap max-width-100`}
                                    dangerouslySetInnerHTML={{ __html: linkify(item.content) }}
                                />
                            ) : (
                                <div
                                    className={`_txt-comment-item line-height134 txt-color-black robotomedium dpl-il-block break-word txt-size-h8 whitespace-pre-wrap max-width-100`}
                                >
                                    {item.content}
                                </div>
                            )}
                        </div>
                    )}

                    {item.attachments.length > 0 && (
                        <div className="_chat-attachments-wrapper chat-attachments-wrapper">
                            {item.attachments.map((i, j) => this.renderRowImage(i, j))}
                        </div>
                    )}
                </div>
            </div>
        )
    }

    renderRecall = (item, index) => {
        const { currentLoggedUser, t } = this.props
        return (
            <div
                className={`${index !== 0 && 'mgt20'} comment-item ${
                    currentLoggedUser.username === item.author.username && !item.staff ? 'bg-color-yellow2' : 'bg-color-gray3'
                } _item-recalled`}
                key={index}
            >
                <span className="_comment-author txt-size-h8 utmhelve-bold txt-color-gray2 line-height167">
                    {item.author.fullname}{' '}
                    {currentLoggedUser.username === item.author.username && !item.staff && (
                        <AntdIconSVG
                            component={iconVip}
                            style={{ fontSize: 12 }}
                        />
                    )}
                </span>
                <div className={`pd10 bd-rdu mgbt5`}>
                    <p
                        className={
                            '_txt-comment-item utmhelve-regular line-height134 dpl-il-block break-word txt-size-h7 whitespace-pre-wrap break-word max-width-100 txt-color-gray'
                        }
                    >
                        {t('message.deleted_comment')}
                    </p>
                </div>
                <div className="">
                    <span className="_comment-createdAt txt-size-h9 utmhelve-regular txt-color-gray">
                        {timeAgo(item.recallTime || new Date(), t)}
                    </span>
                </div>
            </div>
        )
    }

    renderCommentLog = () => {
        const { listComments = [], commentLoading, orderDetail } = this.props
        const newListComments = _.cloneDeep(listComments)
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const taiyiConfig = _.get(currentProjectInfo, 'tenantConfig.taiyiConfig.enabled')
        const enableTime = _.get(currentProjectInfo, 'tenantConfig.taiyiConfig.updateToNewCommentTime')
        const checkTimeNew = moment(orderDetail?.createdAt)?.isAfter(moment(enableTime))
        // console.log(orderDetail, enableTime, checkTimeNew)
        if (taiyiConfig && checkTimeNew) {
            return (
                <Spin spinning={commentLoading}  indicator={<AntdIcon.LoadingOutlined />}>
                    <div
                        className="boxchat__content pd15"
                        ref={e => (this.chatbox = e)}
                    >
                        {newListComments.map((item, index) => {
                            if (item.recall) return this.renderRecallPosedon(item, index)
                            return this.renderRowCommentPosedon(item, index)
                        })}
                    </div>
                </Spin>
            )
        } else {
            return (
                <Spin spinning={commentLoading}  indicator={<AntdIcon.LoadingOutlined />}>
                    <div
                        className="boxchat__content pd15"
                        ref={e => (this.chatbox = e)}
                    >
                        {newListComments.map((item, index) => {
                            if (item.recall) return this.renderRecall(item, index)
                            return this.renderRowComment(item, index)
                        })}
                    </div>
                </Spin>
            )
        }
    }

    renderPreviewList = () => {
        const { files = [], previewRemoveIcon } = this.state
        return files.map((item, index) => {
            if (item.type.indexOf('video') < 0 && item.type.indexOf('image') < 0) {
                return (
                    <div
                        className={'mgbt5 mgr5'}
                        key={index}
                    >
                        <div
                            onMouseOver={() => this.setState({ previewRemoveIcon: index })}
                            onMouseLeave={() => this.setState({ previewRemoveIcon: null })}
                            className="_chat-attachments-item chat-attachments-item cursor-pointer position-re dpl-flex align-items-center justify-content overflow-hidden"
                            style={{ fontSize: 30, border: '1px solid #a0abb8', color: '#a0abb8' }}
                            onClick={() => this.removeFile(item)}
                        >
                            {previewRemoveIcon === index ? <AntdIcon.CloseOutlined type="close" /> : this.renderIcon(item.type)}
                        </div>
                        <p className="_file-name txt-color-gray txt-size-h9 text-center mgbt0">{formatFileName(item.name, 12)}</p>
                    </div>
                )
            }
            return (
                <div
                    className={'mgbt5 mgr5'}
                    key={index}
                >
                    <div
                        onMouseOver={() => this.setState({ previewRemoveIcon: index })}
                        onMouseLeave={() => this.setState({ previewRemoveIcon: null })}
                        onClick={() => this.removeFile(item)}
                        className="_chat-attachments-item chat-attachments-item cursor-pointer position-re dpl-flex align-items-center justify-content overflow-hidden"
                        style={
                            item.type.indexOf('video') > -1 || previewRemoveIcon === index
                                ? { fontSize: 30, border: '1px solid #a0abb8', color: '#a0abb8' }
                                : {}
                        }
                    >
                        {previewRemoveIcon === index ? (
                            <AntdIcon.CloseOutlined type="close" />
                        ) : item.type.indexOf('video') > -1 ? (
                            <AntdIcon.PlayCircleOutlined type="play-circle" />
                        ) : (
                            <div
                                className="image-preview"
                                style={{ backgroundImage: `url(${item.preview})` }}
                            />
                        )}
                    </div>
                    <p className="_file-name txt-color-gray txt-size-h9 text-center mgbt0">{formatFileName(item.name, 12)}</p>
                </div>
            )
        })
    }

    render() {
        const {
            isUploading,
            hasMore,
            nextCommentPage,
            uploadImageComment,
            commentLoading,
            t,
            isCommenting,
            orderDetail
        } = this.props
        const { commentContent, previewVisible, previewItem = {}, files = [] } = this.state

        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const taiyiConfig = _.get(currentProjectInfo, 'tenantConfig.taiyiConfig.enabled')
        const enableTime = _.get(currentProjectInfo, 'tenantConfig.taiyiConfig.updateToNewCommentTime')
        const checkTimeNew = moment(orderDetail?.createdAt)?.isAfter(moment(enableTime))

        return (
            <div
                ref={e => (this.col3Container = e)}
                className={`bg-color-white chat-container ${this.state.commentBoxFixed ? 'boxchat-fixed' : ''}`}
                style={
                    this.state.commentBoxFixed
                        ? {
                              height: this.state.commentBoxHeight,
                              width: this.state.commentBoxFixedWidth,
                          }
                        : { height: this.state.commentBoxHeight }
                }
            >
                <div
                    ref={e => (this.containerElement = e)}
                    className={`${'dpl-flex '} flex-direction-column height100`}
                >
                    {taiyiConfig && checkTimeNew ? (
                        <>
                                <div className="p-3">
                                    <TextEditor
                                        actionButton={
                                            <CameraDropzone
                                                t={t}
                                                maxLength={1}
                                                addFiles={this.uploadFilesPosedon}
                                                isUploading={isUploading}
                                            />
                                        }
                                        onSubmit={(values, cb) => {
                                            uploadImageComment({ content: values.trim(), files })
                                        }}
                                        isLoading={isCommenting}
                                    />
                                </div>
                        </>
                    ) : (
                        <>
                                <div
                                    ref={e => (this.textereaContainer = e)}
                                    className="boxchat__ip pd15 bg-color-gray border-bootom-1x bd-color-gray"
                                >
                                    <div className="position-re bg-color-white bd1px bd-color-gray border-radius2 pd5 pdr0">
                                        <Input.TextArea
                                            ref={e => (this.textarea = e)}
                                            autosize={{ minRows: 3, maxRows: 3 }}
                                            name="commentContent"
                                            placeholder={t('comment.place_holder')}
                                            className="_chatbox-textarea boxchat__textarea mgbt5"
                                            maxLength={1000}
                                            value={commentContent}
                                            onChange={this.onChangeInput}
                                            onBlur={() => this.onBlurInput('content')}
                                            onKeyDown={this.textAreaKeyDown}
                                            onPaste={this.pasteHandle}
                                        />

                                        <div className="dpl-flex justify-content-end pdr10">
                                            <CameraDropzone
                                                t={t}
                                                isUploading={isUploading}
                                                maxLength={10}
                                                addFiles={this.uploadFiles}
                                            />
                                            <div
                                                onClick={() => {
                                                    if (!this.createValidate()) {
                                                        this.scrollToTop()
                                                        uploadImageComment({ content: commentContent.trim(), files }, () =>
                                                            this.setState({
                                                                commentContent: '',
                                                                files: [],
                                                            })
                                                        )
                                                    }
                                                }}
                                                className="_chatbox-btn-send btn--blue cursor-pointer txt-color-white"
                                            >
                                                {t('comment.send')}
                                            </div>
                                        </div>
                                        {files.length > 0 && (
                                            <div className="pd5 dpl-flex bg-color-white flex-wrap">
                                                {this.renderPreviewList()}
                                            </div>
                                        )}
                                    </div>
                                </div>
                        </>
                    )}

                    <div className={'height-100-percent boxchatwrapper'}>
                        <InfiniteScroll
                            initialLoad={false}
                            pageStart={0}
                            loadMore={() => nextCommentPage()}
                            hasMore={!commentLoading === true && hasMore}
                            useWindow={false}
                        >
                            {orderDetail && this.renderCommentLog()}
                            {commentLoading && hasMore && (
                                <div className="demo-loading-container text-center pdt15 pdbt15">
                                    <Spin
                                        indicator={
                                            <AntdIcon.Loading3QuartersOutlined
                                                type="loading"
                                                style={{ fontSize: 24 }}
                                                spin
                                            />
                                        }
                                    />
                                </div>
                            )}
                        </InfiniteScroll>
                    </div>
                    {previewVisible && (
                        <Modal
                            width={700}
                            className={'modal-preview-image'}
                            visible={previewVisible}
                            footer={null}
                            onCancel={() => this.setState({ previewVisible: false })}
                        >
                            {((previewItem.type && previewItem.type.indexOf('video') > -1) || (previewItem.mimeType && previewItem.mimeType.indexOf('video') > -1)) ? (
                                <video
                                    width="100%"
                                    height="500"
                                    controls
                                >
                                    <source
                                        src={previewItem.url || previewItem?.location}
                                        type={previewItem.type || previewItem.mimeType}
                                    />
                                    {previewItem.name}
                                </video>
                            ) : (
                                <img
                                    alt={previewItem.name}
                                    style={{ width: '100%' }}
                                    src={previewItem.url || previewItem?.location}
                                />
                            )}
                        </Modal>
                    )}
                </div>
            </div>
        )
    }
}
