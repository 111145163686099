import {useState} from "react";

const FloatLabel = props => {
    const [focus, setFocus] = useState(false);
    const {children, label, value, isRequired} = props;
    console.log("value", value);
    const labelClass =
        focus || (value && value.length > 0) ? "label label-float" : "label";

    return (
        <div
            className={`float-label ${focus ? "float-label--focused" : ''}`}
            onBlur={() => setFocus(false)}
            onFocus={() => setFocus(true)}
        >
            {children}
            <label className={`${labelClass} robotoregular text-grey-400`}>{label} {isRequired ? <span className="text-red-100">*</span> : null}</label>
        </div>
    );
};

export default FloatLabel
