import React, { useState, useEffect } from 'react';
import { Table, Skeleton, Button, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { formatPriceByUnit, moneyFormat } from '../../utils/stringUtils';
import * as AntdIcon from "@ant-design/icons"
import moment from 'moment';
import { get } from 'lodash';
import BifinService from '../../services/bifin.service';
import { ILoansData, status } from '../../types/Bifin';
import { style, styleLoans } from '../OrderDetail/TabCredit';
interface DataType {

}


const TabCredit: React.FC = (props: any) => {
    const { t, orderInfo } = props

    const [loading, setLoading] = useState<boolean>(false)
    const [credits, setCredits] = useState([])
    const [loans, setLoans] = useState<ILoansData>()
    // const [error, setError] = useState()

    useEffect(() => {
        setLoading(true)
        getCredits()
        getLoans()
        setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCredits = () => {
        BifinService.getShipmentCredits(orderInfo?.code, (err: any, response: any) => {
            if (!err) {
                setCredits(response)
            } else {
                // setError(err)
            }
        })
    }

    const getLoans = () => {
        BifinService.getShipmentLoans(orderInfo?.code, (err: any, response: any) => {
            if (!err) {
                setLoans(response)
            } else {
                // setError(err)
            }
        })
    }

    const columns: ColumnsType<DataType> = [
        {
            title: <span className='robotomedium text-xs text-grey-400'>{t('bifiin.code')}</span>,
            dataIndex: 'loanId',
            key: 'loanId',
            render: (text: string) => <div>
                <span className="robotomedium text-blue-violet">#{text ? text : '---'}</span>
            </div>
        },
        // {
        //     title: <span className='robotomedium text-xs text-grey-400'>{t('bifiin.created_at')}</span>,
        //     dataIndex: 'timestamp',
        //     key: 'timestamp',
        //     align: 'right',
        //     render: (text: any) => <div>
        //         <span className="robotoregular text-black-100">{text ? moment(text).format('HH:mm DD/MM/YYYY') : '---'}</span>
        //     </div>
        // },
        {
            title: <span className='robotomedium text-xs text-grey-400'>{t('tickets.created_at')}</span>,
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'right',
            render: (text: any) => <div>
                <span className="robotoregular text-black-100">{text ? moment(text).format('HH:mm DD/MM/YYYY') : '---'}</span>
            </div>
        },
        // {
        //     title: <span className='robotomedium text-xs text-grey-400'>{t('bifiin.settlementDate')}</span>,
        //     dataIndex: 'timestamp',
        //     key: 'timestamp',
        //     align: 'right',
        //     render: (text: any) => <div>
        //         <span className="robotoregular text-black-100">{text ? moment(text).format('HH:mm DD/MM/YYYY') : '---'}</span>
        //     </div>
        // },
        // {
        //     title: <span className='robotomedium text-xs text-grey-400'>{t('bifiin.totalPaid')}</span>,
        //     dataIndex: 'totalPaid',
        //     key: 'totalPaid',
        //     render: (text: any) => <div>
        //         <span className={`robotomedium ${text >= 0 ? 'text-blue-violet' : 'text-[#FF4559]'}`}>{text ? moneyFormat(text) : '---'}</span>
        //     </div>
        // },
        {
            title: <span className='robotomedium text-xs text-grey-400'>{t('bifiin.amount')}</span>,
            dataIndex: 'amount',
            key: 'amount',
            align: 'right',
            render: (text: any) => <div>
                <span className={`robotomedium ${text >= 0 ? 'text-blue-violet' : 'text-[#FF4559]'}`}>{text ? moneyFormat(text) : '---'}</span>
            </div>
        },
        {
            title: <span className='robotomedium text-xs text-grey-400'>{t('bifiin.status')}</span>,
            dataIndex: 'status',
            key: 'status',
            render: (text: status) => {
                return (
                    <div>
                        {
                            text ? <Button
                                type={'primary'}
                                onClick={(e) => e.stopPropagation()}
                                style={style[text]}
                                className={`h-[unset] border-none ml-1 px-3 py-1 rounded-3xl text-white text-xs robotoregular select-none`}
                            >
                                {t(`bifiinStatus.${text}`)}
                            </Button> : '---'
                        }
                    </div>
                )
            }
        },
    ];

    const toolTipAccount = <Tooltip className={'mgl5'} title={t('bifiin.remainingPrincipalAmountInfo')}>
        <AntdIcon.QuestionCircleOutlined
            className={`txt-size-h8 txt-color-gray opacity6 mgl5 _remark-icon-tooltip`} />
    </Tooltip>

    return (
        <Skeleton loading={loading} active>
            <div className="pdl15 pdr15">
                <div className="_info_credit rounded-md bg-blue-200 p-3 sm:w-full">
                    <span className="text-base robotobold text-black-100 capitalize">
                        {t('Bifiin.Title')}
                    </span>
                    <div className="mt-3 w-2/4 screen-xs:w-full">
                        <div className="mb-3 flex sm:items-start justify-between ">
                            <span className="robotoregular text-black-100">
                                {t('bifiin.status')}
                            </span>
                            {
                                get(loans, 'status', null) ?
                                    <Button
                                        type={'primary'}
                                        style={styleLoans[loans?.status || '']}
                                        onClick={(e) => e.stopPropagation()}
                                        className={`h-[unset] border-none ml-1 px-3 py-1 rounded-3xl text-white text-xs robotoregular select-none`}
                                    >
                                        {t(`bifiinStatusLoans.${get(loans, 'status', null)}`)}
                                    </Button> : '---'
                            }
                        </div>
                        <div className="mb-3 flex sm:items-start justify-between ">
                            <span className="robotoregular text-black-100 flex items-center">
                                Tỷ giá
                            </span>
                            <span className="robotobold text-blue-violet">
                                {loans?.currencyExchangeRate !== null ? formatPriceByUnit(loans?.currencyExchangeRate, loans?.currency) : '---'}
                            </span>
                        </div>
                        <div className="mb-3 flex sm:items-start justify-between ">
                            <span className="robotoregular text-black-100 flex items-center">
                                {t('bifiin.remainingPrincipalAmount')}
                                {toolTipAccount}
                            </span>
                            <span className="robotobold text-blue-violet">
                                {loans?.disbursedAmount !== null ? moneyFormat(loans?.disbursedAmount) : '---'}
                            </span>
                        </div>
                        <div className="mb-3 flex sm:items-start justify-between ">
                            <span className="robotoregular text-black-100 flex items-center">
                                {t('bifiin.remainingFeeAmount')}
                            </span>
                            <span className="robotobold text-blue-violet">
                                {loans?.feeAndInterestAccumulation !== null ? moneyFormat(loans?.feeAndInterestAccumulation) : '---'}
                            </span>
                        </div>
                        <div className="mb-3 flex sm:items-start justify-between ">
                            <span className="robotoregular text-black-100 flex items-center">
                                {t('bifiin.loanAmount')}
                            </span>
                            <span className="robotobold text-blue-violet">
                                {get(loans, 'loanAmount', null) ? moneyFormat(get(loans, 'loanAmount', null)) : '---'}
                            </span>
                        </div>
                        <div className="mb-3 flex sm:items-start justify-between ">
                            <span className="robotoregular text-black-100 flex items-center">
                                {t('bifiin.feeAmount')}
                            </span>
                            <span className="robotobold text-blue-violet">
                                {get(loans, 'feeAndInterestOriginal', null) ? moneyFormat(get(loans, 'feeAndInterestOriginal', null)) : '---'}
                            </span>
                        </div>
                        <div className="flex sm:items-start justify-between ">
                            <span className="robotoregular text-black-100 flex items-center">
                                {t('bifiin.totalAmountPay')}
                            </span>
                            <span className="robotobold text-blue-violet">
                                {loans?.totalAmountPay !== null ? moneyFormat(loans?.totalAmountPay) : '---'}
                            </span>
                        </div>
                    </div>
                </div>
                <div className='_credits my-3'>
                    <Table columns={columns} dataSource={credits} pagination={false} />
                </div>
            </div>
        </Skeleton>
    );
}

export default TabCredit;