import { Input } from 'antd'
import { quantityFormat } from '../../utils/stringUtils'

function InputMoney(props: any) {
    return (
        <div className="input-money w-fit">
            <Input
                ref={props?.innerref}
                {...props}
                onKeyPress={event => {
                    if (!/[0-9]/.test(event.key)) {
                        event.preventDefault()
                    }
                }}
                maxLength={17}
                min={0}
                value={props?.value ? quantityFormat(props?.value) : ''}
                onChange={(e: any) => props?.onChange(Number(e?.target?.value.replace(/\D/g, '')))}
            />
            <i className="fa-solid fa-dong-sign input-money_icon text-grey-400"></i>
        </div>
    )
}

export default InputMoney