import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {LocalStore} from "../../utils/LocalStore";

const NotLoggedRoute = ({ component: Component, ...rest }) => (
    <Route exact={true} {...rest} render={props => {
        return (
            !LocalStore.getInstance().read('loginSession')
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
    }} />
);

export default NotLoggedRoute