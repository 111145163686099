import _ from 'lodash'
import { FC, useEffect, useState } from 'react'
import viewQr from '../../../resources/images/viewQr.svg'
import { Col, Radio, Row } from 'antd'
import CategoriesService from '../../../services/categories.service'
import { moneyFormat } from '../../../utils/stringUtils'
import { LocalStore } from '../../../utils/LocalStore'
import { genQrCode } from '../../../utils/helper'

type Props = {
    data: {
        type: string
        money: number
    } | null
    t: any
}

export const QrCodePayment: FC<Props> = ({ data, t }) => {
    const [qrCode, setQrCode] = useState<any>(null)
    const [listBankAccount, setListBankAccount] = useState<any>(null)
    const [selectedBank, setSelectedBank] = useState<any>(null)
    const [listBankValid, setListBankValid] = useState<any>(null)
    const [listBank, setListBank] = useState<any>(null)

    const currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {}

    const onChangeBank = (dataBank: any) => {
        setSelectedBank(dataBank)
        setQrCode(
            genQrCode({
                bank: dataBank?.bankCode,
                accountName: dataBank?.beneficialName,
                accountNumber: dataBank?.bankAccount,
                amount: data?.money,
                memo: dataBank?.memoTemplate
                    ?.replace('[[username]]', currentLoggedUser?.username || '')
                    ?.replace('[[phone]]', currentLoggedUser?.phone || ''),
            })
        )
    }

    const checkReceiveCondition = (money?: number, purpose?: string, curl?: string) => {
        // eslint-disable-next-line no-eval
        return eval(curl || 'false')
    }

    const checkBankValid = (listBankTenant: any, listBank: any) => {
        const listBankValid = listBankTenant?.map((i: any) => {
            const bankQr = listBank?.find((el: any) => el?.qrBankCode === i?.bankCode)
            if (checkReceiveCondition(data?.money, data?.type, i.receiveCondition)) {
                return {
                    ...i,
                    logo: bankQr?.logo,
                    name: bankQr?.name,
                }
            } else return null
        })
        setListBankValid(_.compact(listBankValid))
        const random = _.random(_.compact(listBankValid)?.length - 1)
        if (listBankValid?.length > 0) {
            onChangeBank(_.compact(listBankValid)?.[random])
        }
    }

    useEffect(() => {
        if (listBankAccount && listBank) {
            checkBankValid(listBankAccount, listBank)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listBankAccount, data, listBank])

    useEffect(() => {
        CategoriesService.getListBankAccount((err: any, res: any) => {
            if (!err) {
                setListBankAccount(res)
            }
        })
        CategoriesService.getListBank((err: any, res: any) => {
            if (!err) {
                setListBank(res)
            }
        })
    }, [])

    return (
        <div>
            <div>
                {data?.money && Number(data?.money) >= 0 ? (
                    <Row
                        justify="space-between"
                        className="mb-3"
                    >
                        <Col className="text-grey-400">{t('deposit.money')}</Col>
                        <Col className=" text-base font-bold text-blue-primary">{moneyFormat(data?.money)}</Col>
                    </Row>
                ) : null}
                <Row justify="space-between">
                    <Col className="text-grey-400">{t('deposit.content')}</Col>
                    <Col>
                        {selectedBank?.memoTemplate
                            ?.replace('[[username]]', currentLoggedUser?.username || '')
                            ?.replace('[[phone]]', currentLoggedUser?.phone || '')}
                    </Col>
                </Row>
                <div className="custom-line-dash my-6"></div>
            </div>
            <div className="h-[308px] flex justify-center">
                {qrCode && listBankValid?.length > 0 && (
                    <div className="relative w-[300px]">
                        <img
                            alt="view-qrcode"
                            src={viewQr}
                            className="w-[300px]"
                        ></img>
                        <img
                            alt="qrcode"
                            src={qrCode}
                            className="w-[250px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                        ></img>
                    </div>
                )}
            </div>
            {listBankValid?.length > 1 && (
                <Radio.Group
                    value={selectedBank?.id}
                    className="w-full mt-6 px-2"
                >
                    <Row
                        gutter={[24, 24]}
                        justify={listBankValid?.length === 2 ? 'center' : 'start'}
                    >
                        {listBankValid?.map((i: any) => {
                            return (
                                <Col
                                    span={8}
                                    onClick={() => onChangeBank(i)}
                                    key={i?.id}
                                >
                                    <div className={`group-radio-button ${selectedBank?.id === i?.id && 'active'}`}>
                                        <Radio
                                            value={i?.id}
                                            className="w-full"
                                        >
                                            <Row
                                                gutter={4}
                                                align="middle"
                                            >
                                                {i?.logo && (
                                                    <Col>
                                                        <img
                                                            className="w-6"
                                                            alt="icon"
                                                            src={i?.logo}
                                                        ></img>
                                                    </Col>
                                                )}
                                                <Col>
                                                    <div>{i?.bankCode}</div>
                                                </Col>
                                            </Row>
                                        </Radio>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </Radio.Group>
            )}
        </div>
    )
}
