import PolimClient from "../utils/api/PolimClient";

export default class ProjectService {

    /**
     * lấy chi tiết project hiên tại
     * @param cb
     */
    static getCurrentProject (cb) {
        PolimClient.request({
            endpoint: `/tenants/current`,
            method: 'GET',
			notAuth: true
        }, cb)
    }

}
