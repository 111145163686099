import React from 'react';
import {Modal, Input, Button} from 'antd/lib/index';
import * as AntdIcon from "@ant-design/icons"

export default class Index extends React.Component{
    constructor(props){
        super(props);
        this.state={}
    }

    closeModal = () => {
        this.setState({showModalConfirm: false})
    };

    setError = (value) => {
        this.setState({passwordError: value})
    };

    renderModalConfirmPin = () => {
        const {showModalConfirm, password, passwordError} = this.state;
        const {t, onExport, exportExcel} = this.props;

        return <Modal
            key={'modal-confirm'}
            title={t('modal.confirm_pin')}
            visible={showModalConfirm}
            onOk={() => exportExcel(password, this.closeModal, this.setError)}
            confirmLoading={onExport}
            onCancel={() => this.setState({
                showModalConfirm: false,
                password: '',
            })}
            okText={t('cartCheckout.confirm')}
            cancelText={t('cartCheckout.cancel')}
            okButtonProps={{className:"_btn-submit-confirm"}}
            cancelButtonProps={{className:"_btn-cancel-confirm"}}
        >
            <p className="mgbt5">{t('cartCheckout.please_input_pin')}</p>
            <Input.Password
                className={'_input-password'}
                placeholder={t('orderDetail.input_pin')}
                autoFocus={true}
                value={password}
                onChange={(e) => this.setState({password: e.target.value})}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        exportExcel(password, this.closeModal, this.setError)
                    }
                }}
            />

            {
                passwordError && <p className="_txt-error-confirm mgt5 mgbt0 txt-error ">{passwordError}</p>
            }
            <span className='mgt10 txt-color-gray txt-size-h8'>{t('cartCheckout.default_pin')}</span>
        </Modal>
    };

    render(){
        const {t, onExport, small, validate} = this.props;
        return(<>
            {
                small
                ?
                    <Button
                        key={'btn'}
                        onClick={() => {
                            if (validate && validate()) return
                            this.setState({showModalConfirm: true})
                        }}
                        className={'_btn-export-csv-product rounded'}
                    >
                        {t('shipment.btn_export_csv')}
                    </Button>
                    :
                    <Button
                        key={'btna'}
                        type='default'
                        onClick={() => {
                            if (validate && validate()) return
                            this.setState({showModalConfirm: true})
                        }}
                       className="_btn-export-csv rounded"
                    >
                        {onExport && <AntdIcon.Loading3QuartersOutlined type="loading-3-quarters" spin={true} className="position-ab" style={{left: 10, top: 8}}/>}
                        {t('shipment.btn_export_csv')}
                    </Button>
            }
            {this.renderModalConfirmPin()}
        </>);
    }
}
