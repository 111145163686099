import {LocalStore} from "../../utils/LocalStore";
import lang from "../../resources/localization/Localization";

const HTTP_NO_CONTENT = 204;

/**
 * function fetch api from server
 * @param endpoint
 * @param files
 * @param body
 * @returns {Promise<*>}
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default async (endpoint, files, body, keyAttachment) => {
    let token = LocalStore.getInstance().read('loginSession');
    let fullURL = endpoint;

    let formData = new FormData();
    // eslint-disable-next-line array-callback-return
    files.map((file) => {
        formData.append(keyAttachment || 'attachments', new Blob([file]), file.name);
    });

    if (body) {
        for (let prop in body) {
            formData.append(prop, new Blob([JSON.stringify(body[prop])], {type: 'application/json'}));
        }
    }

    if (!endpoint.startsWith('http')) {
        // validate endpoint
        endpoint = endpoint.startsWith('/') ? endpoint : '/' + endpoint;

        const appApiUrl = process.env.REACT_APP_API_URL || window.location.hostname;

        // calc url
        fullURL = endpoint.indexOf(appApiUrl) === -1 ? appApiUrl + endpoint : endpoint;
    }

    let res;
    //nếu mất mạng sẽ chửi
    if (!navigator.onLine) {
        let error  = new Error('Có lỗi xảy ra, vui lòng thử lại!');
        error.message = "error.network";
        error.statusCode = "NetworkFail";
        throw error
    }

    try {
        res = await fetch(fullURL, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token.id
            },
            body: formData,
            timeout: 300000
        });
    } catch (err) {
        if (err.message === 'Network request failed') {
            // notification.warning({
            //     key: 'Network',
            //     message: "Vui lòng kiểm tra lại kết nối Internet."
            // });
            err.message = "error.network";
            err.statusCode = "NetworkFail";
            throw err
        }

        let error  = new Error('Có lỗi xảy ra, vui lòng thử lại!');
        error.statusCode = 'timeout';
        error.detail = lang.error.timeout;
        throw error;
    }

    // check status HTTP_NO_CONTENT
    if (res.status === HTTP_NO_CONTENT) {
        return true;
    }

    const json = await res.json();
    if (!res.ok) {
        // console.log('!res.ok', json, res);
        if (json.status || json.code || json.statusCode) throw json;
        throw typeof json.error === 'object' ? json.error : res;
    }

    return json;
};

