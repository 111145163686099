import {
    Button,
    Col,
    Divider,
    Empty, Popconfirm,
    Row,
    Table, Tag
} from 'antd';
import React from 'react';
import CreateAddressModal from '../../components/AddressDeposit/CreateAddressModal';
import MainLayout from '../../components/Layout/MainLayout';
import Loading from '../../components/Loading';
import ProfileSidebar from '../../components/Profile/Leftbar';
import { LocalStore } from "../../utils/LocalStore";

import { Badge, Tabs } from 'antd';
import { notification } from "antd/lib/index";
import lodash from 'lodash';
import queryString from 'query-string';
import { withNamespaces } from "react-i18next";
import AddressServices from "../../services/address.service";
import { getUrlQueryString } from "../../utils/helper";
import {
    parseQueryStringToObject,
    updateQueryStringParameter
} from "../../utils/stringUtils";
let pageSize = 25;
let currentLoggedUser = null;
class Address extends React.Component {
    constructor(props) {
        super(props);
        this.isUnmounted = false;
        this.state = {
            page: 1,
            pageSize: pageSize,
            isLoading: true,
            listAddress: [],
            activeTab:'address'
        }
    }

    componentDidMount() {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null;
        window.addEventListener("popstate", this.onPopState.bind(this));

        if (typeof LocalStore.getInstance().read('currentLoggedUser') === 'object') {
            currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {};

            this.setState({isLoading: true}, () => {
                this.loadFromQueryString();
            });
        }
    }

    /**
     * Khi unmount ra thì bỏ sự kiện popstate
     */
    componentWillUnmount() {
        this.isUnmounted = true;
        window.removeEventListener("popstate", this.onPopState.bind(this));
    }

    /**
     * On popstate
     */
    onPopState() {
        if (!this.isUnmounted) this.setState({isLoading: true}, ()=> {
            this.loadFromQueryString();
        });
    };

    /**
     * Load từ QueryString (dành cho lần load đầu tiên)
     */
    loadFromQueryString = () => {
        let queryString = getUrlQueryString();
        let queryObj = parseQueryStringToObject(queryString) || {};

        this.setState({
            page: queryObj.page,
            pageSize: queryObj.size || pageSize,
            activeTab: queryObj.tab || "address"
        }, ()=> {
            this.loadData();
        });
    };

    /**
     * load data
     */
    loadData = (cb) => {
        const {page, pageSize, activeTab} = this.state;
        let queryString = `?${activeTab ==='address'? 'receivingAddress=false' : 'receivingAddress=true'}&page=${page ? page - 1 : 0}&size=${pageSize}&sort=defaultAddress:desc,createdAt:desc`;
        this.setState({showProgressBar: true}, () => {
            AddressServices.getListAddressesUser(currentLoggedUser.username, queryString, (err, res, headers) => {
                if (!err) {
                    this.setState({
                        listAddress: res,
                        metadata: {
                            pageCount: headers['x-page-count'],
                            page: headers['x-page-number'],
                            size: headers['x-page-size'],
                            total: headers['x-total-count'],
                        }
                    }, () => {
                        if (this.mainLayoutElement && this.mainLayoutElement.scrollLayoutElement) this.mainLayoutElement.scrollLayoutElement.scrollTop = 0;
                        //window.scrollTo(0, 0);
                    });
                }
                if(cb) cb();
                this.setState({showProgressBar: false, isLoading: false})
            })
        })
    };

    /**
     * xóa bản ghi
     * @param id
     */
    deleteData = (id) => {
        AddressServices.deleteAddressUser(currentLoggedUser.username, id, (err, res, headers) => {
            if (!err) {
                notification['success']({
                    message: this.props.t('message.delete_success'),
                });
                this.loadData();
            } else {
                if (err.statusCode !== 'Network fail') notification['error']({
                    message: err.message,
                });
            }
        });
    };

    onPageChanged = (page, pageSize) => {
        this.setState({ page, pageSize }, () => {
            let locationQuery = lodash.get(this.props.location, 'search', '')
            let queryObj = locationQuery ? parseQueryStringToObject(locationQuery) : {}
            queryObj.page = page
            queryObj.size = pageSize
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: queryString.stringify(queryObj),
            })
            // window.scrollTo(0, 0);
        })
    }

    /**
     * xác nhận xóa bản ghi
     * @param id
     */
    confirmDelete = (id) => {
        this.deleteData(id);
    };

    onOpenEditAddress = (item) => {
        this.setState({toggleEditAddress: true, dataItem: item});
        this.toggleModalCreateAddress();
    };

    /**
     * mở modal tạo address
     */
    toggleModalCreateAddress = () => {
        this.setState({modalCreateAddressStatus: !this.state.modalCreateAddressStatus})
    };

    onChangedTab = key => {
        let queryString = updateQueryStringParameter('', 'tab', key)
        this.setState({metadata:{}})
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: queryString,
        })
    }

    render() {
        const {
            listAddress, isLoading, toggleEditAddress, activeTab,
            metadata, modalCreateAddressStatus, dataItem, toggleAddressOnList
        } = this.state;
        const {t} = this.props;

        const columns = [{
            title: t('customerAddress.fullname'),
            dataIndex: 'fullname',
            width:"10%",
            key: 'fullname',
            className: '_address-list-fullname',
        }, {
            title: t('customerAddress.phone'),
            dataIndex: 'phone',
            width:"10%",
            key: 'phone',
            className: '_address-list-phone',
        }, {
            title: t('customerAddress.zipCode'),
            dataIndex: 'zipCode',
            key: 'zipCode',
            width:"10%",
            className: '_address-list-zipCode',
            render: (zipCode) => zipCode || '---'
        },  {
            title: t('customerAddress.addressName'),
            dataIndex: 'addressName',
            key: 'addressName',
            width:"20%",
            className: '_address-list-addressName',
            render: (txt) => txt || '---'
        },{
            title: t('customerAddress.address'),
            dataIndex: 'detail',
            key: 'detail',
            width:"30%",
            className: '_address-list-detail',
            render: (text, record) => (
                <div className={'break-word'}>
                    <p className="_address-list-detail mgbt5 whitespace-pre-wrap break-word">{record.detail}</p>
                    <p className="_address-list-location-display whitespace-pre-wrap break-word mgbt0 txt-size-h8 txt-color-gray">{record.location.display}</p>
                </div>
            ),
        }, {
            title: '',
            dataIndex: 'defaultAddress',
            key: 'defaultAddress',
            width:"10%",
            className: '_address-list-default',
            render: (text, record) => (
                <span>
                    {
                        record.defaultAddress && !record.receivingAddress
                            ?
                            <Tag color={'green'} className='tag--green'>{t('customerAddress.default')}</Tag>
                            :
                            null
                    }
                    {
                        record.defaultAddress && record.receivingAddress
                            ?
                            <Tag color={'blue'} className='rounded-xl' >{<span className='mx-2 my-1'>{t('customerAddress.default')}</span>}</Tag>
                            :
                            null
                    }
                </span>
            ),
        }, {
            title: '',
            key: 'action',
            width:"10%",
            className: '_address-list-action',
            render: (text, record) => (
                <span className='whitespace-nowrap'>
                    <span className={`_address-edit-btn cursor-pointer`} onClick={() => this.onOpenEditAddress(record)}>{t('button.edit')}</span>
                    {
                        !record.defaultAddress && <React.Fragment>
                            <Divider type="vertical" style={{backgroundColor: '#1890ff'}}/>
                            <Popconfirm
                                placement="topRight"
                                overlayClassName={`_address-delete-popconfirm-overlay`}
                                className={`_address-delete-popconfirm`}
                                title={t('message.delete_confirm')}
                                onConfirm={() => this.confirmDelete(record.id)}
                                okText={t('button.agree')}
                                cancelText={t('button.disagree')}
                            >
                                <span className={`_address-delete-btn cursor-pointer`}>{t('button.delete')}</span>
                            </Popconfirm>
                        </React.Fragment>
                    }
                </span>
            ),
        }];
        
        return <MainLayout
            ref={ref => this.mainLayoutElement = ref}
            {...this.props}
            showProgressBar={this.state.showProgressBar}
            headerTitle={t('customer_info.your_address')}
            title={t('customer_info.personal_info')}
        >
            <div className={'container-common pdbt30 pdt20 mgr20'}>
                <Row>
                    <Col span={6} className={'pdr10'}>
                        <ProfileSidebar currentRoute={'address'}/>
                    </Col>
                    <Col span={18} className={'pdl10'}>

                        <div className="bg-color-white rounded-xl p-4">
                            <Tabs className="tab-common-antd"
                                tabBarExtraContent ={{right: <Button
                                    className={'_btn-export-csv mr-4'}
                                    type="primary"
                                    onClick={() => {
                                        this.setState({dataItem: null, toggleAddressOnList: true}, () => {
                                            this.toggleModalCreateAddress();
                                        })
                                    }}
                                >
                                    {t('customerAddress.new_address')}
                                </Button>}}
                                activeKey={activeTab ? activeTab : "address"}
                                defaultActiveKey={activeTab ? activeTab : "address"} onChange={this.onChangedTab}>
                                <Tabs.TabPane tabKey='' className={"_tabFrees"} tab={<span className={'_tabFrees-btn'}>{
                                   metadata && activeTab ==='address'? <span className={'txt-color-black txt-size-h5 robotobmedium'}>{t('customer_info.received_address_list')} <Badge className='text-blue-primary ml-2' count={metadata.total}/></span>
                                :
                                <span className={'txt-color-black txt-size-h5 robotobmedium'}>{t('customer_info.received_address_list')}</span>
                                }</span>} key={"address"}>
                                    <div className="tableresponsive pd10">
                                {
                                    Array.isArray(listAddress) && listAddress.length > 0 && !isLoading
                                        ?
                                        <Table
                                            className={'table-v2 custom-empty table-transaction'}
                                            rowKey={'id'}
                                            columns={columns}
                                            dataSource={listAddress}
                                            rowClassName={(record, index) => index % 2 !== 0 && 'table-row-odd'}
                                            pagination={{
                                                className: '_pagination text-center width-100-percent mgt20',
                                                // hideOnSinglePage: true,
                                                current: metadata ? parseFloat(metadata.page) + 1 : 1,
                                                total: metadata ? parseFloat(metadata.total) : 1,
                                                pageSize: metadata ? parseFloat(metadata.size) : pageSize,
                                                onChange: (page, pageSize) => this.onPageChanged(page, pageSize),
                                            }}
                                        />
                                        :
                                        isLoading ?
                                        <Loading/> :
                                        <div className="pdt50 pdbt60 bg-color-white">
                                            <Empty
                                                image={require('../../resources/images/empty-image.png')}
                                                description={t('message.empty')}
                                            />
                                        </div>
                                }
                            </div>
                                </Tabs.TabPane>

                                <Tabs.TabPane className={"_tabFrees"} tab={<span className={'_tabFrees-btn'}>{
                                    <span className={'_tabFrees-btn'}>
                                    {metadata && activeTab ==='receivingAddress' ? (
                                        <span className={'txt-color-black txt-size-h5 robotobmedium'}>
                                            {t('customer_info.receivingAddress')} 
                                            <Badge className='text-blue-primary ml-2' count={metadata.total}/>
                                        </span>
                                    ) : (
                                        <span className={'txt-color-black txt-size-h5 robotobmedium'}>
                                            {t('customer_info.receivingAddress')}
                                        </span>
                                    )}
                                </span>
                                }</span>} key={"receivingAddress"}>
                                    <div className="tableresponsive pd10">
                                    {
                                        Array.isArray(listAddress) && listAddress.length > 0 && !isLoading
                                            ?
                                            <Table
                                                className={'table-v2 custom-empty table-transaction'}
                                                rowKey={'id'}
                                                columns={columns}
                                                dataSource={listAddress}
                                                rowClassName={(record, index) => index % 2 !== 0 && 'table-row-odd'}
                                                pagination={{
                                                    className: '_pagination text-center width-100-percent mgt20',
                                                    // hideOnSinglePage: true,
                                                    current: metadata ? parseFloat(metadata.page) + 1 : 1,
                                                    total: metadata ? parseFloat(metadata.total) : 1,
                                                    pageSize: metadata ? parseFloat(metadata.size) : pageSize,
                                                    onChange: (page, pageSize) => this.onPageChanged(page, pageSize),
                                                }}
                                            />
                                            :
                                            isLoading ?
                                            <Loading/> :
                                            <div className="pdt50 pdbt60 bg-color-white">
                                                <Empty
                                                    image={require('../../resources/images/empty-image.png')}
                                                    description={t('message.empty')}
                                                />
                                            </div>
                                    }
                            </div>
                                </Tabs.TabPane>
                                
                            </Tabs>
                        </div>
                    </Col>

                </Row>
                {modalCreateAddressStatus && <CreateAddressModal
                    toggleCreateModal={this.toggleModalCreateAddress}
                    modalCreateAddressStatus={modalCreateAddressStatus}
                    loadDataList={this.loadData}
                    toggleEditAddress={toggleEditAddress}
                    dataItem={dataItem}
                    toggleAddressOnList={toggleAddressOnList}
                    isCheckReceivingAddress={activeTab !=='address'}
                />}
            </div>
        </MainLayout>;
    }
}
export default withNamespaces()(Address);