import React from 'react';
import MainLayout from '../../components/Layout/MainLayout'
import ProfileSidebar from '../../components/Profile/Leftbar'
import UserService from "../../services/user.service";

import {Button, Col, notification, Row, Tooltip, Typography} from 'antd';
import * as AntdIcon from "@ant-design/icons"
import {withNamespaces} from "react-i18next";
import {LocalStore} from "../../utils/LocalStore";
import PinConfirmModal from "../../components/PINConfirmModal";

class Zalo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            zaloToken: '',
			refreshTime: null
        }
    }

	/**
     * Load từ QueryString (dành cho lần load đầu tiên)
     */
    generateZaloToken = () => {
        const {t} = this.props;
        this.setState({genToken: true}, () => {
            UserService.generateZaloToken((err, res, headers) => {
                if (!err) {
                    this.setState({
                        zaloToken: res.token,
                        genToken: false
                    }, () => {
                        window.ZaloSocialSDK.reload()
                    });
                } else {
                    if (err.title === 'customer_zalo_id_existed') {
                        notification['error']({
                            message: t('message.customer_zalo_id_existed'),
                        });
                    } else {
                        notification['error']({
                            message: err.title,
                        });
                    }
                    this.setState({
                        genToken: false
                    });
                }
            });
        });
    };
    disconnectZalo=(secret)=>{
        const {t} = this.props;
        this.setState({isDisconnecting: true}, () => {
            UserService.disconnectZalo({secret},(err, res, headers) => {
                if (!err) {
                    UserService.getCurrentUser({},(error, res) => {
                            if (!error) {
                                LocalStore.getInstance().save('currentLoggedUser', res);
                            }
                        notification['success']({
                            message: t('message.disconnect_zalo_success'),
                        });
                            this.setState({
                                isDisconnecting: false,
                                showConfirmDisconnect: false
                            });
                        })
                } else {
                    if(err.code===500)
                    notification['error']({
                        message: t(`error.error`),
                    });
                    else
                        notification['error']({
                            message: t(`message.${err.title}`),
                        });
                    this.setState({
                        isDisconnecting: false
                    });
                }
            });
        });
    }

	render() {
        const {zaloToken, isChat, showConfirmDisconnect, isDisconnecting} = this.state;
        const {t} = this.props;


        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};

        const zaloConfig = currentProjectInfo && currentProjectInfo.tenantConfig ? currentProjectInfo.tenantConfig.zaloConfig : {};
        const currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {};

        return <MainLayout
            ref={ref => this.mainLayoutElement = ref}
            {...this.props}
            showProgressBar={this.state.showProgressBar}
            headerTitle={"Zalo"}
            title={"Zalo"}
        >
            <div className={'container-common pdbt30 pdt20 mgr20'}>
                <Row>
                    <Col span={6} className={'pdr10'}>
                        <ProfileSidebar currentRoute={'zalo'}/>
                    </Col>
                    <Col span={18} className={'pdl10'}>
                        {
                            currentLoggedUser.zaloId ?
                                <Row className="pd20 pdbt50" style={{backgroundColor:'#EDF1FF'}}>
                                    <Col lg={12} className={'pdt20'}>
                                        <img className={'width100'} src={require('../../resources/images/zalo_connected.png')} alt=''></img>
                                    </Col>
                                    <Col lg={12} className={'txt-center pdt140'}>
                                        <span className={'txt-size-h7 robotomedium txt-color-green dpl-block mgbt20'}>{t('zalo.connected')}</span>
                                        <Button className={'buttonm buttonm__cancel mgau'} onClick={()=>this.setState({showConfirmDisconnect:true})} icon={<AntdIcon.CloseOutlined />}>{t('zalo.disconnect')}</Button>
                                    </Col>

                                </Row>
                                :
                                <div className="zalo-page-container pd20 bg-color-white">
                                    <div className="zalo-page-step dpl-flex align-items-center mgbt30">

                                        <div className="icon-step pdr20">
                                            <div className="zalo-step-dot"/>
                                            <div className="zalo-step-line"/>
                                            <div className="zalo-step-num">
                                                1
                                            </div>
                                        </div>

                                        <div className="width-100-percent">
                                            <Row gutter={30}>
                                                <Col span={6}>
                                                    <div className="txt-size-h2 bold">
                                                        {t('message.gen_code_step_1')}
                                                    </div>
                                                </Col>
                                                <Col span={18} className="dpl-flex align-items-center">
                                                    <Button className="mgr15" ghost type="primary" onClick={this.generateZaloToken}>
                                                        {t('message.gen_code')}
                                                    </Button>
                                                    <Tooltip placement="top" title={t('message.zalo_tooltip')}>
                                                        <AntdIcon.QuestionCircleOutlined type="question-circle" theme="filled" className={'txt-size-h8 txt-color-gray opacity6 mgr15'}/>
                                                    </Tooltip>
                                                    {
                                                        zaloToken && <Typography.Paragraph className={"bold txt-color-black"} style={{marginBottom: 0}} copyable={{ text: zaloToken }}>{zaloToken}</Typography.Paragraph>
                                                    }
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                    {
                                        zaloToken && <div className="zalo-page-step dpl-flex align-items-center">

                                            <div className="icon-step pdr20">
                                                <div className="zalo-step-dot"/>
                                                <div className="zalo-step-line"/>
                                                <div className="zalo-step-num">
                                                    2
                                                </div>
                                            </div>

                                            <div className="width-100-percent">
                                                <Row gutter={30} className="dpl-flex align-items-center">
                                                    <Col span={6}>
                                                        <div className="txt-size-h2 bold">
                                                            {t('message.gen_code_step_2')}
                                                        </div>
                                                    </Col>
                                                    <Col span={18} className="">
                                                        <div className="dpl-flex align-items-center mgbt10">
                                                            {t('message.follow_zalo_tenant')}

                                                            <div
                                                                style={{height: 20, marginLeft: 10}}
                                                                dangerouslySetInnerHTML={{__html: `<div class="zalo-follow-only-button" data-oaid=${zaloConfig.zaloOaId} />`}}
                                                            />

                                                        </div>

                                                        <div className="dpl-flex align-items-center">
                                                            {t('message.syntax_message_zalo_1')}

                                                            {
                                                                zaloToken && <Typography.Paragraph className={"bold txt-color-black"} style={{marginBottom: 0}} copyable={{ text: `/register ${zaloToken}` }}> /register {zaloToken}</Typography.Paragraph>
                                                            }
                                                        </div>
                                                        <div>
                                                            {/*<a>{t('message.syntax_message_zalo_2')}</a>*/}
                                                            {
                                                                // zaloToken && <a href={`https://zalo.me/${zaloConfig.zaloOaId}`} target={"_blank"}>{t('message.syntax_message_zalo_2')}</a>
                                                                zaloToken && <span onClick={() => this.setState({isChat: true, refreshTime: (new Date()).getMilliseconds()}, () => {
                                                                    window.ZaloSocialSDK.reload()
                                                                })}>{t('message.syntax_message_zalo_2')}</span>
                                                            }
                                                            {
                                                                isChat && zaloToken &&

                                                                <div
                                                                    dangerouslySetInnerHTML={{__html: `<div class="zalo-chat-widget" data-oaid=${zaloConfig.zaloOaId} data-welcome-message="/register ${zaloToken}" data-autopopup="0" data-width="350" data-height="420"></div>`}}
                                                                />
                                                            }
                                                        </div>

                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    }
                                </div>
                        }
                    </Col>
                </Row>
                {showConfirmDisconnect&&<PinConfirmModal loading={isDisconnecting}
                                                  t={t}
                                                  visible={showConfirmDisconnect}
                                                  onCancel={() => this.setState({
                                                      showConfirmDisconnect: false})}
                                                  onSubmit={this.disconnectZalo}/>
                }
            </div>
        </MainLayout>;
    }
}
export default withNamespaces()(Zalo);
