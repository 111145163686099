/* eslint-disable array-callback-return */
import React, { Fragment } from 'react'
import {
    Alert,
    Avatar,
    Button,
    Col,
    Divider,
    Input,
    InputNumber,
    Modal,
    notification,
    Popconfirm,
    Row,
    Select,
    Tooltip,
    Typography,
} from 'antd'
import * as AntdIcon from '@ant-design/icons'
import AntdIconSVG from '@ant-design/icons'

import _, { first, get } from 'lodash'
import lodash from 'lodash'
import { Link } from 'react-router-dom'
import { checkIsLink, linkify, moneyCeil, moneyFormat, quantityFormat } from '../../utils/stringUtils'
import ShipmentsService from '../../services/shipments.service'
import { ReactComponent as iconShipment } from '../../resources/images/ic_Kygui.svg'
import { LocalStore } from '../../utils/LocalStore'
import ModalPaymentBifin from '../../containers/Orders/components/ModalPaymentBifin'

const { TextArea } = Input

class ShipmentInfo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isExpand: true,
            remark: '',
            note: '',
            refShipmentCode: '',
            refCustomerCode: '',
            isLoadLevel: true,
        }
    }

    componentDidMount() {
        const { data } = this.props
        this.setState({
            currentData: _.cloneDeep(data),
            remark: data && data.remark,
            note: data && data.note,
            refShipmentCode: data && data.refShipmentCode,
            refCustomerCode: data && data.refCustomerCode,
        })
    }

    componentDidUpdate(prevProps) {
        const { data } = this.props
        if (prevProps.data && data && prevProps.data.remark !== data.remark) {
            this.setState({
                currentData: _.cloneDeep(data),
                remark: data && data.remark,
            })
        }
        if (prevProps.data && data && prevProps.data.note !== data.note) {
            this.setState({
                currentData: _.cloneDeep(data),
                note: data && data.note,
            })
        }
        if (prevProps.data && data && prevProps.data.refShipmentCode !== data.refShipmentCode) {
            this.setState({
                currentData: _.cloneDeep(data),
                refShipmentCode: data && data.refShipmentCode,
            })
        }
        if (prevProps.data && data && prevProps.data.refCustomerCode !== data.refCustomerCode) {
            this.setState({
                currentData: _.cloneDeep(data),
                refCustomerCode: data && data.refCustomerCode,
            })
        }
    }

    editShipment = body => {
        const { data, setData, t } = this.props
        const { submitting, declareValue, expectedPackages } = this.state

        if (body.hasOwnProperty('expectedPackages') && data.expectedPackages === expectedPackages) {
            this.setState({
                expectedPackagesEditing: false,
            })
            return
        } else if (body.hasOwnProperty('declareValue') && data.declareValue === declareValue) {
            this.setState({
                declareValueEditing: false,
            })
            return
        } else if (body.hasOwnProperty('hsCode') && body.hsCode === data.hsCode) {
            this.setState({
                hsCodeEditing: false,
            })
            return
        }

        if (submitting) return

        this.setState({ submitting: true }, () => {
            ShipmentsService.editShipment(data.code, body, (err, res, header) => {
                if (!err) {
                    if (setData) setData(res)
                    notification.success({
                        message: t('order.successfully_handle'),
                    })
                    this.setState({
                        submitting: false,
                        declareValueEditing: false,
                        hsCodeEditing: false,
                        expectedPackagesEditing: false,
                    })
                } else {
                    if (err.title === 'action_do_not_allow') {
                        notification['error']({
                            message: t('waybill.action_do_not_allow'),
                        })
                    } else {
                        notification['error']({
                            message: t(err.message),
                        })
                    }
                    this.setState({
                        submitting: false,
                    })
                }
            })
        })
    }

    /**
     * cập nhật ghi chú cho chi tiết đơn
     * @param item
     * @param value
     * @param prop
     * @param cb
     */
    updateShipment = (item, value, prop, cb) => {
        const { data, setData, t } = this.props
        const { editing } = this.state

        let payload = {
            [prop]: value,
        }
        if (editing) return

        this.setState({ editing: true }, () => {
            ShipmentsService.editShipment(data.code, payload, (err, res) => {
                if (!err) {
                    if (setData) setData(res)
                    if (cb) cb()
                } else {
                    notification['error']({
                        message: t(err.message),
                    })
                }
                this.setState({ editing: false })
            })
        })
    }

    onToggle = () => {
        this.setState({
            isExpand: !this.state.isExpand,
        })
    }

    onDeclareValueKeyDown = e => {
        const { declareValue } = this.state
        let payload = {}
        if (declareValue === undefined || declareValue === null || declareValue === '' || declareValue < 0) {
            payload.declareValue = null
        } else {
            payload.declareValue = parseFloat(declareValue)
        }

        if (e.keyCode === 13) {
            this.editShipment(payload)
        }
    }

    onExpectedPackagesKeyDown = e => {
        const { expectedPackages } = this.state
        let payload = {}
        if (expectedPackages === undefined || expectedPackages === null || expectedPackages === '' || expectedPackages < 0) {
            payload.expectedPackages = null
        } else {
            payload.expectedPackages = parseFloat(expectedPackages)
        }

        if (e.keyCode === 13) {
            this.editShipment(payload)
        }
    }

    confirmReceivedOrder = () => {
        this.props.confirmReceivedOrder()
    }

    onFocusInput = () => {
        setTimeout(() => {
            if (this.inputFocus) this.inputFocus.focus()
        }, 500)
    }

    /**
     * Change value input
     * @param value
     * @param prop
     */
    onChangeInput = (value, prop) => {
        if (value !== '' && value.trim() === '') return
        this.setState({ [prop]: value })
    }

    /**
     * Call func submit note order if click outside
     * @param e
     * @param prop
     * @return {*}
     */
    onBlurInput = (e, prop) => {
        let { currentData, remark, refShipmentCode, note, refCustomerCode } = this.state
        // if (e.target.value !== '' && e.target.value.trim() === '') return;
        if (currentData.remark !== remark && remark !== '') {
            this.updateShipment(currentData, remark, prop, () => {
                if (prop === 'remark') {
                    this.setState({
                        ['showRemark' + currentData.id]: false,
                    })
                }
            })
        } else if (currentData.note !== note) {
            this.updateShipment(currentData, note, prop, () => {
                this.setState({
                    ['showNote' + currentData.id]: false,
                    ['showNoteDetail' + currentData.id]: false,
                })
            })
        } else if (currentData.refShipmentCode !== refShipmentCode) {
            this.updateShipment(currentData, refShipmentCode, prop, () => {
                this.setState({
                    ['showRefShipmentCode' + currentData.id]: false,
                    ['showRefShipmentCodeDetail' + currentData.id]: false,
                })
            })
        } else if (currentData.refCustomerCode !== refCustomerCode) {
            this.updateShipment(currentData, refCustomerCode, prop, () => {
                this.setState({
                    ['showRefCustomerCode' + currentData.id]: false,
                    ['showRefCustomerCodeDetail' + currentData.id]: false,
                })
            })
        } else {
            if (prop === 'remark') {
                if (remark === '') {
                    this.setState({ remark: currentData.remark })
                }
                this.setState({ ['showRemark' + currentData.id]: false })
            } else if (prop === 'note') {
                if (note === '') {
                    this.setState({ note: currentData.note })
                }
                this.setState({ ['showNote' + currentData.id]: false })
                if (e.target.value === '') {
                    this.setState({ ['showNoteDetail' + currentData.id]: false })
                }
            } else if (prop === 'refShipmentCode') {
                if (refShipmentCode === '') {
                    this.setState({ refShipmentCode: currentData.refShipmentCode })
                }
                this.setState({ ['showRefShipmentCode' + currentData.id]: false })
                if (e.target.value === '') {
                    this.setState({ ['showRefShipmentCodeDetail' + currentData.id]: false })
                }
            } else if (prop === 'refCustomerCode') {
                if (refCustomerCode === '') {
                    this.setState({ refCustomerCode: currentData.refCustomerCode })
                }
                this.setState({ ['showRefCustomerCode' + currentData.id]: false })
                if (e.target.value === '') {
                    this.setState({ ['showRefCustomerCodeDetail' + currentData.id]: false })
                }
            }
        }
    }

    onKeyDownInput = (e, prop) => {
        let { currentData, refShipmentCode, refCustomerCode } = this.state
        if (e.keyCode === 13) {
            if (currentData.refShipmentCode !== refShipmentCode) {
                this.updateShipment(currentData, refShipmentCode, prop, () => {
                    this.setState({
                        ['showRefShipmentCode' + currentData.id]: false,
                        ['showRefShipmentCodeDetail' + currentData.id]: false,
                    })
                })
            } else if (currentData.refCustomerCode !== refCustomerCode) {
                this.updateShipment(currentData, refCustomerCode, prop, () => {
                    this.setState({
                        ['showRefCustomerCode' + currentData.id]: false,
                        ['showRefCustomerCodeDetail' + currentData.id]: false,
                    })
                })
            } else {
                if (prop === 'refShipmentCode') {
                    if (refShipmentCode === '') {
                        this.setState({ refShipmentCode: currentData.note })
                    }
                    this.setState({ ['showRefShipmentCode' + currentData.id]: false })
                    if (e.target.value === '') {
                        this.setState({ ['showRefShipmentCodeDetail' + currentData.id]: false })
                    }
                }
                if (prop === 'refCustomerCode') {
                    if (refCustomerCode === '') {
                        this.setState({ refCustomerCode: currentData.note })
                    }
                    this.setState({ ['showRefCustomerCode' + currentData.id]: false })
                    if (e.target.value === '') {
                        this.setState({ ['showRefCustomerCodeDetail' + currentData.id]: false })
                    }
                }
            }
        }
    }

    /**
     * Call func submit note order if Enter
     * @param e
     * @param prop
     * @return {*}
     */
    onKeyDown = (e, prop) => {
        let { currentData, remark, note, refShipmentCode, refCustomerCode } = this.state
        //if (e.target.value !== '' && e.target.value.trim() === '') return;

        if ((e.metaKey || e.shiftKey || e.altKey || e.ctrlKey) && e.keyCode === 13) {
            if (currentData.remark !== remark && remark !== '') {
                this.updateShipment(currentData, remark, prop, () => {
                    if (prop === 'remark') {
                        this.setState({
                            ['showRemark' + currentData.id]: false,
                        })
                    }
                })
            } else if (currentData.note !== note) {
                this.updateShipment(currentData, note, prop, () => {
                    this.setState({
                        ['showNote' + currentData.id]: false,
                        ['showNoteDetail' + currentData.id]: false,
                    })
                })
            } else if (currentData.refShipmentCode !== refShipmentCode) {
                this.updateShipment(currentData, refShipmentCode, prop, () => {
                    this.setState({
                        ['showRefShipmentCode' + currentData.id]: false,
                        ['showRefShipmentCodeDetail' + currentData.id]: false,
                    })
                })
            } else if (currentData.refCustomerCode !== refCustomerCode) {
                this.updateShipment(currentData, refCustomerCode, prop, () => {
                    this.setState({
                        ['showRefCustomerCode' + currentData.id]: false,
                        ['showRefCustomerCodeDetail' + currentData.id]: false,
                    })
                })
            } else {
                if (prop === 'remark') {
                    if (remark === '') {
                        this.setState({ remark: currentData.remark })
                    }
                    this.setState({ ['showRemark' + currentData.id]: false })
                } else if (prop === 'note') {
                    if (note === '') {
                        this.setState({ note: currentData.note })
                    }
                    this.setState({ ['showNote' + currentData.id]: false })
                    if (e.target.value === '') {
                        this.setState({ ['showNoteDetail' + currentData.id]: false })
                    }
                } else if (prop === 'refShipmentCode') {
                    if (refShipmentCode === '') {
                        this.setState({ refShipmentCode: currentData.note })
                    }
                    this.setState({ ['showRefShipmentCode' + currentData.id]: false })
                    if (e.target.value === '') {
                        this.setState({ ['showRefShipmentCodeDetail' + currentData.id]: false })
                    }
                } else if (prop === 'refCustomerCode') {
                    if (refCustomerCode === '') {
                        this.setState({ refCustomerCode: currentData.note })
                    }
                    this.setState({ ['showRefCustomerCode' + currentData.id]: false })
                    if (e.target.value === '') {
                        this.setState({ ['showRefCustomerCodeDetail' + currentData.id]: false })
                    }
                }
            }
        }
    }

    renderModalOriginalReceipts = () => {
        const { t, deleteOriginalReceiptLoading, addOriginalReceiptsLoading, addOriginalReceipts, deleteOriginalReceipts } =
            this.props
        const {
            modalOriginalReceipts,
            listOriginalReceiptsState = [],
            newOriginalReceipt,
            listOriginalReceiptsDelete = [],
        } = this.state
        return (
            <Modal
                title={t('shipment.editOriginalReceipt')}
                visible={modalOriginalReceipts}
                onOk={() => {
                    if (listOriginalReceiptsDelete.length > 0) {
                        deleteOriginalReceipts(
                            listOriginalReceiptsDelete.map(x => x.code),
                            () =>
                                this.setState({
                                    modalOriginalReceipts: false,
                                    newOriginalReceipt: '',
                                })
                        )
                    } else {
                        this.setState({
                            modalOriginalReceipts: false,
                            newOriginalReceipt: '',
                            listOriginalReceiptsDelete: [],
                        })
                    }
                }}
                confirmLoading={deleteOriginalReceiptLoading}
                onCancel={() =>
                    this.setState({
                        modalOriginalReceipts: false,
                        newOriginalReceipt: '',
                        listOriginalReceiptsDelete: [],
                    })
                }
                okText={t('button.save')}
                cancelText={t('modal.cancel')}
                okButtonProps={{ className: '_btn-submit-confirm' }}
                cancelButtonProps={{ className: '_btn-cancel-confirm' }}
            >
                <div className="pdbt5">{t('shipment.add_OriginalReceipt')}</div>
                <Input
                    addonAfter={
                        addOriginalReceiptsLoading ? (
                            <AntdIcon.Loading3QuartersOutlined
                                type="loading-3-quarters"
                                spin={true}
                            />
                        ) : (
                            <AntdIcon.PlusOutlined
                                type="plus"
                                onClick={() => {
                                    if (newOriginalReceipt && !addOriginalReceiptsLoading) {
                                        addOriginalReceipts({ code: newOriginalReceipt }, res => {
                                            this.setState({
                                                newOriginalReceipt: '',
                                                listOriginalReceiptsState: res || [],
                                                listOriginalReceiptsDelete: [],
                                            })
                                        })
                                    }
                                }}
                            />
                        )
                    }
                    value={newOriginalReceipt}
                    onChange={e => this.setState({ newOriginalReceipt: e.target.value })}
                    onKeyDown={e => {
                        if (e.keyCode === 13 && newOriginalReceipt) {
                            addOriginalReceipts({ code: newOriginalReceipt }, res => {
                                this.setState({
                                    newOriginalReceipt: '',
                                    listOriginalReceiptsState: res || [],
                                    listOriginalReceiptsDelete: [],
                                })
                            })
                        }
                    }}
                    ref={ref => (this.inputFocus = ref)}
                />
                <div className="pdt10">
                    <div className="pdbt5">{t('shipment.originalReceipts')}</div>
                    <div className="dpl-flex flex-wrap">
                        {listOriginalReceiptsState.map((x, y) => (
                            <div
                                key={y}
                                className="pd5 mgr5 mgbt5 bg-color-gray3 break-word"
                            >
                                {x.code}
                                <AntdIcon.CloseOutlined
                                    type="close"
                                    className="mgl5 cursor-pointer"
                                    onClick={() =>
                                        this.setState({
                                            listOriginalReceiptsState: listOriginalReceiptsState.filter(a => a.code !== x.code),
                                            listOriginalReceiptsDelete: [...listOriginalReceiptsDelete, x],
                                        })
                                    }
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </Modal>
        )
    }

    renderHSCodeSelect = () => {
        const { t, data = {}, listHS = [] } = this.props
        return (
            <Select
                className="width-100-percent"
                showSearch
                optionFilterProp="children"
                value={data.hsCode}
                onChange={value => this.editShipment({ hsCode: value })}
                onBlur={() => this.setState({ hsCodeEditing: false })}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                ref={ref => (this.hsCodeSelect = ref)}
            >
                <Select.Option value={''}>{t('shipments.clearSelect')}</Select.Option>
                {listHS.map((item, index) => (
                    <Select.Option
                        value={item.code || ''}
                        key={index}
                    >
                        {item.name || '---'}
                    </Select.Option>
                ))}
            </Select>
        )
    }

    render() {
        const {
            isExpand,
            remark,
            note,
            refShipmentCode,
            refCustomerCode,
            declareValue,
            declareValueEditing,
            hsCodeEditing,
            expectedPackages,
            expectedPackagesEditing,
        } = this.state
        const {
            data,
            listStatuses = [],
            t,
            confirmCancelOrder,
            listHS = [],
            listOriginalReceipts = [],
            listProduct = [],
            peerPaymentInfo,
            peerPaymentStatuses,
            globalTenantConfig,
            loanCredits,
        } = this.props
        let itemStatus = {}
        let hsCode = {}

        if (!data) {
            return <div />
        }

        if (data) {
            itemStatus = listStatuses.find(x => x.code === data.status) || {}
            hsCode = listHS.find(x => x.code === data.hsCode) || {}
        }

        let stringListOriginalReceipts = ''
        if (Array.isArray(listOriginalReceipts)) {
            listOriginalReceipts.map((x, y) => {
                stringListOriginalReceipts += (y !== 0 ? ', ' : '') + x.code
            })
        } else {
            stringListOriginalReceipts = '---'
        }
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        let taxFreeThreshHold =
            currentProjectInfo.tenantConfig && currentProjectInfo.tenantConfig.shipmentConfig
                ? currentProjectInfo.tenantConfig.shipmentConfig.taxFreeThreshHold
                : null
        let showPackageAlert = false
        let freePackages = 0
        let totalValueProduct = 0
        listProduct.map(item => (totalValueProduct = totalValueProduct + item.exchangedActualPrice * item.quantity))

        if (taxFreeThreshHold && data) {
            freePackages = Math.ceil(totalValueProduct / taxFreeThreshHold)
            if (totalValueProduct > taxFreeThreshHold) {
                showPackageAlert = true
            }
        }

        // console.log(totalValueProduct, taxFreeThreshHold, freePackages)

        let peerPaymentStatus = peerPaymentStatuses.find(x => x.code === _.get(peerPaymentInfo, 'status', null))
        let isShowPeerPaymentCode = _.get(globalTenantConfig, 'config.shipmentRequired')
        let renderPeerPaymentCode
        if (isShowPeerPaymentCode) {
            let peerPaymentCodeComponent
            if (_.get(data, 'peerPaymentCode')) {
                peerPaymentCodeComponent = (
                    <Link
                        to={`/peer-payments/${_.get(data, 'peerPaymentCode')}`}
                        target={'_blank'}
                    >
                        {_.get(data, 'peerPaymentCode')}
                    </Link>
                )
            } else {
                peerPaymentCodeComponent = <span className="txt-size-h7 txt-color-black robotomedium mgr5">---</span>
            }

            renderPeerPaymentCode = (
                <>
                    <Row>
                        <Col
                            span={24}
                            className="dpl-flex align-items-center"
                        >
                            <span className="txt-size-h8 txt-color-gray robotoregular pdr5 flex-none pdt2">
                                {t('orderDetail.peerPaymentCode')}:
                            </span>
                            <div>
                                <div className="dpl-flex align-items-center">
                                    {peerPaymentCodeComponent}

                                    {_.get(data, 'peerPaymentCode') && (
                                        <span
                                            className="btn__status btn__status--blue dpl-il-block mgl15"
                                            style={{ backgroundColor: _.get(peerPaymentStatus, 'color', '') }}
                                        >
                                            {_.get(peerPaymentStatus, 'name', '')}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                </>
            )
        }
        let totalNeedPay = data?.totalUnpaid
        if (loanCredits.length > 0) totalNeedPay += first(loanCredits)?.status === 'ACTIVE' ? first(loanCredits)?.totalAmountPay : 0
        const totalNeedPayFormat = moneyFormat(moneyCeil(totalNeedPay), null, true)

        const contractWithShopkeeper = get(data, 'contractWithShopkeeper', false)
        return (
            <div className={'orderdetail__top mgbt20'}>
                <div className="pdbt10 dpl-flex">
                    <Link
                        className={'_shipments'}
                        to={'/shipments'}
                    >
                        <AntdIcon.ArrowLeftOutlined
                            className="txt-color-blue"
                            type="arrow-left"
                        />
                    </Link>
                    <Link
                        className={'_shipments'}
                        to={'/shipments'}
                    >
                        <div className={'cursor-pointer pdl10 txt-color-blue txt-size-h8 robotoregular'}>
                            {t('shipment.shipment_list')}
                        </div>
                    </Link>
                </div>

                {data && data.deliveryNotice && (
                    <Alert
                        className="mgbt20"
                        message={<span className="bold txt-size-h6">{t('order.notification')}</span>}
                        description={
                            <span className="txt-size-h7">
                                {t('order_detail.deliveryNotice1')}{' '}
                                <Link to={'/delivery/create'}>{t('order_detail.deliveryNotice2')}</Link>{' '}
                                {t('order_detail.deliveryNotice3')}
                            </span>
                        }
                        type="success"
                        showIcon
                    />
                )}

                {showPackageAlert && (
                    <Alert
                        className="mgbt20"
                        message={<span className="bold txt-size-h6">{t('order.notification')}</span>}
                        description={
                            <span className="txt-size-h7">
                                {t('message.freePackagesAlert')
                                    .replace('[[taxFreeThreshHold]]', moneyFormat(taxFreeThreshHold))
                                    .replace('[[freePackages]]', freePackages)}
                            </span>
                        }
                        type="warning"
                        showIcon
                    />
                )}

                <div className={'bg-color-white border-top-3x bd-color-green pd15'}>
                    <Row className="dpl-flex width-100-percent align-items-center">
                        <Col span={7}>
                            <div className="dpl-flex flex-wrap align-items-center">
                                <div className={'pdr10'}>
                                    <div className={'dpl-flex align-items-center'}>
                                        {data && data.image ? (
                                            <Avatar
                                                className={`_order-avatar mgr5`}
                                                shape="square"
                                                size={44}
                                                src={data && data.image}
                                            />
                                        ) : (
                                            <AntdIconSVG
                                                className=" custom-icon-shipment-big mgr5"
                                                component={iconShipment}
                                                style={{ fontSize: 30 }}
                                            />
                                        )}
                                        <div>
                                            <div
                                                className={'txt-size-h7 robotomedium txt-color-black wordbreakall line-height134'}
                                            >
                                                #{data && data.code}
                                            </div>
                                            <div>
                                                <span
                                                    style={{ backgroundColor: itemStatus.color || '#898989' }}
                                                    className={'listorders__items_right--status dpl-il-block mgt5'}
                                                >
                                                    {itemStatus.name}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={7}>
                            <div className={'pdl10 pdr10'}>
                                <div className={'orderdetail__top_totalprice dpl-flex align-items-center'}>
                                    <span className={'txt-size-h8 txt-color-gray robotoregular mgr5'}>
                                        {t('shipment.totalFee')}:
                                    </span>
                                    <span className={'txt-size-h7 robotobold txt-color-black'}>
                                        {data && moneyFormat(moneyCeil(data.totalFee))}
                                    </span>
                                    {/*<span className={"txt-size-h4 robotomedium txt-color-black pdt5"}>---</span>*/}
                                </div>
                                <div className={'orderdetail__top_totalprice dpl-flex align-items-center'}>
                                    <span className={'txt-size-h8 txt-color-gray robotoregular mgr5'}>
                                        {t('cartFinishOrder.amount_total')}:
                                    </span>
                                    <span className={'txt-size-h7 robotobold txt-color-black'}>
                                        {data && moneyFormat(moneyCeil(data.totalValue), data.currency)}
                                    </span>
                                    {/*<span className={"txt-size-h4 robotomedium txt-color-black pdt5"}>---</span>*/}
                                </div>
                            </div>
                        </Col>
                        <Col span={10}>
                            <div className="txt-right dpl-flex justify-content-end">
                                {/* {data && data.totalUnpaid && itemStatus.negativeEnd === false ? (
                                    <Button
                                        onClick={() => confirmPayOrder('')}
                                        // onClick={() => this.setState({
                                        //     passwordError: '',
                                        //     password: '',
                                        //     showModalConfirm: true
                                        // })}
                                        type="primary"
                                        ghost
                                        className="_btn-received txt-uppercase pdl25 pdr25 mgl10"
                                    >
                                        {t('orderDetail.payment')}
                                    </Button>
                                ) : null} */}
                                {itemStatus.confirmable && (
                                    <Button
                                        type="primary"
                                        ghost
                                        className={`_btn-received txt-uppercase pdl25 pdr25 mgl10`}
                                        onClick={() => {
                                            Modal.confirm({
                                                maskClosable: true,
                                                icon: null,
                                                title: t('orderDetail.confirm_question'),
                                                okText: t('button.agree'),
                                                cancelText: t('button.disagree'),
                                                onOk: () => this.confirmReceivedOrder(),
                                            })
                                        }}
                                    >
                                        {t('shipments.bt_received')}
                                    </Button>
                                )}

                                {itemStatus.cancellable && (
                                    <Popconfirm
                                        overlayClassName={`_btn-cancel-order-overlay`}
                                        title={
                                            <>
                                                <span>{t('orderDetail.confirm_question')}</span>
                                                {contractWithShopkeeper && (
                                                    <h5 className="text-error text-base robotomedium">
                                                        {t('shipmentInfo.CancelConfirmBiFin')}
                                                    </h5>
                                                )}
                                            </>
                                        }
                                        onConfirm={confirmCancelOrder}
                                        // onCancel={() => console.log('ggwp')}
                                        okText={t('button.yes')}
                                        cancelText={t('button.no')}
                                    >
                                        <Button
                                            type="primary"
                                            ghost
                                            className="_btn-cancel-order txt-uppercase pdl25 pdr25 mgl10"
                                        >
                                            {t('orderDetail.cancel_order')}
                                        </Button>
                                    </Popconfirm>
                                )}
                            </div>
                        </Col>
                    </Row>
                    {isExpand && (
                        <Fragment>
                            <Divider />
                            <Row
                                className={'mt-5'}
                                gutter={10}
                            >
                                <Col span={6}>
                                    <h3 className={'txt-size-h8 txt-color-gray robotoregular _order-detail-heavy'}>
                                        {t('orderDetail.costing_weight')}
                                    </h3>
                                    <span className={'txt-size-h7 txt-color-black robotomedium _order-detail-heavy-value'}>
                                        {data && data.actualWeight
                                            ? `${quantityFormat(data.actualWeight)} kg`
                                            : t('orderDetail.undefined')}
                                    </span>
                                </Col>
                                <Col span={6}>
                                    <h3 className={'txt-size-h8 txt-color-gray robotoregular _order-detail-volume'}>
                                        {t('orderDetail.volume')}
                                    </h3>
                                    <span className={'txt-size-h7 txt-color-black robotomedium _order-detail-volume-value'}>
                                        {data && data.volumetric
                                            ? `${quantityFormat(data.volumetric)} cm3`
                                            : t('orderDetail.undefined')}
                                    </span>
                                </Col>

                                <Col span={6}>
                                    <h3 className={'txt-size-h8 txt-color-gray robotoregular _order-detail-declareValue'}>
                                        {t('shipment.declareValue')}
                                    </h3>
                                    {declareValueEditing && itemStatus.updatable ? (
                                        <div className="dpl-flex align-items-center max-width-250">
                                            <InputNumber
                                                ref={ref => (this.declareValue = ref)}
                                                precision={0} // bắt chỉ cho nhập integer
                                                className="_search-box-input-query width-100-percent txt-size-h7"
                                                placeholder={t('shipment.declareValue')}
                                                min={0}
                                                value={declareValue}
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                onChange={value => {
                                                    if (typeof value === 'number') {
                                                        if (value < 0) {
                                                            this.setState({ declareValue: null })
                                                        } else {
                                                            this.setState({ declareValue: value })
                                                        }
                                                    } else {
                                                        this.setState({ declareValue: null })
                                                    }
                                                }}
                                                onBlur={e => this.setState({ declareValueEditing: false })}
                                                onKeyDown={this.onDeclareValueKeyDown}
                                            />
                                            <AntdIcon.SaveOutlined
                                                className="txt-color-blue pdl10 txt-size-h5 _save_profile flex-none"
                                                type="save"
                                                onMouseDown={e => this.editShipment({ declareValue })}
                                            />
                                        </div>
                                    ) : (
                                        <div className="dpl-flex align-items-center">
                                            <span className="txt-size-h7 txt-color-black robotomedium _order-detail-declareValue-value mgr5">
                                                {data ? `${moneyFormat(moneyCeil(data.declareValue))}` : '---'}
                                            </span>
                                            {itemStatus.updatable && (
                                                <AntdIcon.EditOutlined
                                                    className="_btn-edit-_order-detail-declareValue-value txt-color-blue txt-size-h7"
                                                    type="edit"
                                                    onClick={() =>
                                                        this.setState(
                                                            {
                                                                declareValueEditing: true,
                                                                declareValue: data.declareValue,
                                                            },
                                                            () => {
                                                                this.declareValue.focus()
                                                            }
                                                        )
                                                    }
                                                />
                                            )}
                                        </div>
                                    )}
                                </Col>

                                <Col span={6}>
                                    <h3 className={'txt-size-h8 txt-color-gray robotoregular _order-detail-declareValue'}>
                                        {t('shipments.hsCode')}
                                    </h3>

                                    {hsCodeEditing ? (
                                        this.renderHSCodeSelect()
                                    ) : (
                                        <div className="dpl-flex align-items-center">
                                            <span className="txt-size-h7 txt-color-black robotomedium _order-detail-declareValue-value mgr5">
                                                {hsCode.name || '---'}
                                            </span>
                                            {!itemStatus.negativeEnd && (
                                                <AntdIcon.EditOutlined
                                                    className="_btn-edit-_order-detail-declareValue-value cursor-pointer txt-color-blue txt-size-h7"
                                                    type="edit"
                                                    onClick={() =>
                                                        this.setState({ hsCodeEditing: true }, () => {
                                                            this.hsCodeSelect.focus()
                                                        })
                                                    }
                                                />
                                            )}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            {totalNeedPay > 0 && (
                                <>
                                    <Divider />
                                    <Row>
                                        <Col
                                            span={24}
                                            className="dpl-flex align-items-center"
                                        >
                                            <div className="flex flex-col gap-3 v2-layout">
                                                {data.contractWithShopkeeper && (
                                                    <div>
                                                        <span className={'_note txt-color-gray txt-size-h7 robotoregular mr-1'}>
                                                            {t('order_pay.bifin')}:
                                                        </span>
                                                        <span className="txt-size-h7 robotomedium mr-3 text-blue-primary">
                                                            {moneyFormat(
                                                                moneyCeil(
                                                                    loanCredits[0]?.status  !== 'ACTIVE'
                                                                        ? 0
                                                                        : loanCredits[0]?.totalAmountPay  || 0
                                                                ),
                                                                null,
                                                                true
                                                            )}
                                                        </span>
                                                    </div>
                                                )}
                                                <div>
                                                    <span className={'_note txt-color-gray txt-size-h7 robotoregular mr-1'}>
                                                        {t('order_pay.amount_need_pay')}:
                                                    </span>
                                                    <span className="txt-size-h7 robotomedium mr-3 text-blue-primary">
                                                        {totalNeedPayFormat}
                                                    </span>
                                                    {(data.status === 'READY_FOR_DELIVERY' || data.status === 'DELIVERING') && (
                                                        <ModalPaymentBifin
                                                            t={t}
                                                            isShipment
                                                            contractWithShopkeeper={data.contractWithShopkeeper}
                                                            code={data?.code}
                                                            refetchData={this.props.getShipmentDetail}
                                                            totalNeedPay={totalNeedPayFormat}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            )}
                            <Divider />
                            <Row>
                                <Col
                                    span={24}
                                    className="dpl-flex align-items-center"
                                >
                                    <span className="txt-size-h8 txt-color-gray robotoregular pdr5 flex-none pdt2">
                                        {t('shipments.expectedPackages')}:
                                    </span>
                                    <div>
                                        {expectedPackagesEditing && itemStatus.updatable ? (
                                            <div className="dpl-flex align-items-center max-width-250">
                                                <InputNumber
                                                    ref={ref => (this.expectedPackages = ref)}
                                                    precision={0} // bắt chỉ cho nhập integer
                                                    className="_expectedPackages_input width-100-percent txt-size-h7"
                                                    placeholder={t('shipments.expectedPackages')}
                                                    min={0}
                                                    value={expectedPackages}
                                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                    onChange={value => {
                                                        if (typeof value === 'number') {
                                                            if (value < 0) {
                                                                this.setState({ expectedPackages: null })
                                                            } else {
                                                                this.setState({ expectedPackages: value })
                                                            }
                                                        } else {
                                                            this.setState({ expectedPackages: null })
                                                        }
                                                    }}
                                                    onBlur={e => this.setState({ expectedPackagesEditing: false })}
                                                    onKeyDown={this.onExpectedPackagesKeyDown}
                                                />
                                                <AntdIcon.SaveOutlined
                                                    className="txt-color-blue pdl10 txt-size-h5 _save_profile flex-none"
                                                    type="save"
                                                    onMouseDown={e => this.editShipment({ expectedPackages })}
                                                />
                                            </div>
                                        ) : (
                                            <div className="dpl-flex align-items-center">
                                                <span className="txt-size-h7 txt-color-black robotomedium _order-detail-declareValue-value mgr5">
                                                    {data ? `${quantityFormat(data.expectedPackages)}` : '---'}
                                                </span>
                                                {itemStatus.updatable && (
                                                    <AntdIcon.EditOutlined
                                                        className="_btn-edit-_order-detail-declareValue-value txt-color-blue txt-size-h7"
                                                        type="edit"
                                                        onClick={() =>
                                                            this.setState(
                                                                {
                                                                    expectedPackagesEditing: true,
                                                                    expectedPackages: data.expectedPackages,
                                                                },
                                                                () => {
                                                                    this.expectedPackages.focus()
                                                                }
                                                            )
                                                        }
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                            <Divider />

                            {renderPeerPaymentCode}

                            <Row>
                                <Col
                                    span={24}
                                    className="dpl-flex align-items-center"
                                >
                                    <span className="txt-size-h8 txt-color-gray robotoregular pdr5 flex-none pdt2">
                                        {t('shipments.actualPackages')}:
                                    </span>
                                    <div>
                                        <div className="dpl-flex align-items-center">
                                            <span className="txt-size-h7 txt-color-black robotomedium _order-detail-declareValue-value mgr5">
                                                {data ? `${quantityFormat(data.actualPackages)}` : '---'}
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Divider />
                            <Row>
                                <Col
                                    span={24}
                                    className="dpl-flex align-items-start"
                                >
                                    <span className="txt-size-h8 txt-color-gray robotoregular pdr5 flex-none pdt2">
                                        {t('shipment.originalReceipts')}:
                                    </span>
                                    <div>
                                        <span className="txt-color-black txt-size-h8 robotoregular break-word">
                                            {stringListOriginalReceipts}
                                        </span>
                                        {itemStatus.updatable && (
                                            <AntdIcon.EditOutlined
                                                onClick={() =>
                                                    this.setState(
                                                        {
                                                            modalOriginalReceipts: true,
                                                            listOriginalReceiptsState: listOriginalReceipts,
                                                        },
                                                        () => {
                                                            this.onFocusInput()
                                                        }
                                                    )
                                                }
                                                type="edit"
                                                className="txt-color-blue cursor-pointer mgl5"
                                            />
                                        )}
                                    </div>
                                </Col>
                            </Row>
                            <Divider />
                            <Row>
                                <Col span={24}>
                                    <span className="txt-size-h8 txt-color-gray robotoregular">{t('orderDetail.service')}: </span>
                                    {data && data.services && Array.isArray(data.services) && data.services.length > 0
                                        ? data.services.map((x, y) => {
                                              let indexMax = data.services.length - 1
                                              return (
                                                  <Fragment key={y}>
                                                      <span
                                                          className={`${
                                                              x.approved === null ? 'txt-color-yellow' : 'txt-color-black'
                                                          } txt-size-h8 robotoregular ${
                                                              x.approved === false ? 'line-through' : ''
                                                          }`}
                                                      >
                                                          {x.name}
                                                      </span>
                                                      <span className="pdr5">{`${y === indexMax ? '' : ','}`}</span>
                                                  </Fragment>
                                              )
                                          })
                                        : '---'}
                                </Col>
                            </Row>

                            {lodash.get(data, 'receivingWarehouse.displayName') && (
                                <>
                                    <Divider />
                                    <Row>
                                        <Col span={24}>
                                            <span className="txt-size-h8 txt-color-gray robotoregular">
                                                {t('shipments.receivingWarehouseDisplayName')}:{' '}
                                            </span>
                                            <span className="txt-color-black txt-size-h8 robotoregular">
                                                {lodash.get(data, 'receivingWarehouse.displayName')}
                                            </span>
                                        </Col>
                                    </Row>
                                </>
                            )}

                            <Divider />
                            <Row>
                                <Col span={24}>
                                    <span className="txt-size-h8 txt-color-gray robotoregular">
                                    {data?.receiptAddress ? t('orderDetail.delivery_receiptAddress') : t('orderDetail.delivery_address')}:{' '}
                                    </span>
                                    <span className="txt-color-black txt-size-h8 robotoregular">
                                        {data &&
                                            data.address.fullname +
                                                ' / ' +
                                                data.address.phone +
                                                ' / ' +
                                                data.address.address +
                                                ', ' +
                                                data.address.location.display}
                                    </span>
                                </Col>
                            </Row>
                            <Divider />
                            {data?.receiptAddress && (
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <span className="txt-size-h8 txt-color-gray robotoregular">
                                                {t('orderDetail.delivery_address')}:{' '}
                                            </span>
                                            <span className="txt-color-black txt-size-h8 robotoregular">
                                                {data.receiptAddress.fullname +
                                                    ' / ' +
                                                    data.receiptAddress.phone +
                                                    ' / ' +
                                                    data.receiptAddress.detail +
                                                    ', ' +
                                                    data.receiptAddress.location.display}
                                            </span>
                                        </Col>
                                    </Row>
                                    <Divider />
                                </>
                            )}
                            <Row>
                                <Col
                                    span={24}
                                    className="dpl-flex align-items-center"
                                >
                                    <span className="txt-color-gray robotoregular txt-size-h8 flex-none pdr5">
                                        {t('orderDetail.refShipmentCode')}:
                                    </span>
                                    {this.state['showRefShipmentCode' + (data && data.id)] === true ? (
                                        <Input
                                            value={refShipmentCode}
                                            maxLength={1000}
                                            className={`_refShipmentCode`}
                                            placeholder={t('orderDetail.refShipmentCode')}
                                            onChange={e => this.onChangeInput(e.target.value, 'refShipmentCode')}
                                            onKeyDown={e => this.onKeyDownInput(e, 'refShipmentCode')}
                                            onBlur={e => this.onBlurInput(e, 'refShipmentCode')}
                                            ref={ref => (this[`refShipmentCode${data && data.id}`] = ref)}
                                        />
                                    ) : (
                                        <div
                                            className={`txt-color-black txt-size-h8 robotoregular white-space-pre-wrap break-word dpl-il-block`}
                                        >
                                            {refShipmentCode}
                                        </div>
                                    )}
                                    <AntdIcon.EditOutlined
                                        className="_btn-edit-refShipmentCode cursor-pointer txt-color-blue mgl5 txt-size-h7"
                                        type="edit"
                                        onClick={() => {
                                            this.setState(
                                                {
                                                    ['showRefShipmentCode' + (data && data.id)]:
                                                        !this.state['showRefShipmentCode' + (data && data.id)],
                                                },
                                                () => {
                                                    if (this[`refShipmentCode${data.id}`])
                                                        this[`refShipmentCode${data.id}`].focus()
                                                }
                                            )
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Divider />

                            <Row>
                                <Col
                                    span={24}
                                    className="dpl-flex align-items-center"
                                >
                                    <span className="txt-color-gray robotoregular txt-size-h8 flex-none pdr5">
                                        {t('orderDetail.refCustomerCode')}:
                                    </span>
                                    {this.state['showRefCustomerCode' + (data && data.id)] === true ? (
                                        <Input
                                            value={refCustomerCode}
                                            maxLength={1000}
                                            className={`_ctm-c`}
                                            placeholder={t('orderDetail.refCustomerCode')}
                                            onChange={e => this.onChangeInput(e.target.value, 'refCustomerCode')}
                                            onKeyDown={e => this.onKeyDownInput(e, 'refCustomerCode')}
                                            onBlur={e => this.onBlurInput(e, 'refCustomerCode')}
                                            ref={ref => (this[`refCustomerCode${data && data.id}`] = ref)}
                                            autocomplete="off"
                                        />
                                    ) : (
                                        <div
                                            className={`txt-color-black txt-size-h8 robotoregular white-space-pre-wrap break-word dpl-il-block`}
                                        >
                                            {refCustomerCode}
                                        </div>
                                    )}
                                    <AntdIcon.EditOutlined
                                        className="_btn-edit-refCustomerCode cursor-pointer txt-color-blue mgl5 txt-size-h7"
                                        type="edit"
                                        onClick={() => {
                                            this.setState(
                                                {
                                                    ['showRefCustomerCode' + (data && data.id)]:
                                                        !this.state['showRefCustomerCode' + (data && data.id)],
                                                },
                                                () => {
                                                    if (this[`refCustomerCode${data.id}`])
                                                        this[`refCustomerCode${data.id}`].focus()
                                                }
                                            )
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Divider />

                            <Row>
                                {remark && (
                                    <Col span={24}>
                                        <span
                                            className="txt-color-gray robotoregular txt-size-h8"
                                            style={{ display: 'inline' }}
                                        >
                                            {t('orderDetail.note_order')}:
                                        </span>
                                        {checkIsLink(remark) ? (
                                            <div
                                                className="txt-color-black txt-size-h8 robotoregular white-space-pre-wrap pdl5 break-word mgr10"
                                                style={{ display: 'inline' }}
                                                dangerouslySetInnerHTML={{ __html: linkify(remark) }}
                                            />
                                        ) : (
                                            <div
                                                className="txt-color-black txt-size-h8 robotoregular white-space-pre-wrap pdl5 break-word mgr10"
                                                style={{ display: 'inline' }}
                                            >
                                                {remark}
                                            </div>
                                        )}
                                    </Col>
                                )}
                                <Col span={24}>
                                    {this.state['showNoteDetail' + (data && data.id)] ? (
                                        <Fragment>
                                            <TextArea
                                                value={note}
                                                maxLength={1000}
                                                autosize={{ minRows: 1, maxRows: 3 }}
                                                className={`_orderDetail-note mgt10`}
                                                placeholder={t('order.note')}
                                                onChange={e => this.onChangeInput(e.target.value, 'note')}
                                                onKeyDown={e => this.onKeyDown(e, 'note')}
                                                onBlur={e => this.onBlurInput(e, 'note')}
                                                // disabled={isLoadingDisable}
                                                ref={ref => (this[`order${data.id}`] = ref)}
                                            />
                                            {/*{note === '' && (*/}
                                            {/*    <span className={`txt-size-h8 txt-warning dpl-block pdt5`}>*/}
                                            {/*    {t('message.warning_empty')}*/}
                                            {/*</span>*/}
                                            {/*)}*/}
                                            <span className={`txt-size-h8 txt-color-gray dpl-block`}>
                                                {t('order.note_keydown')}
                                            </span>
                                        </Fragment>
                                    ) : this.state['showNote' + (data && data.id)] === true ? (
                                        <Fragment>
                                            <TextArea
                                                value={note}
                                                maxLength={1000}
                                                autosize={{ minRows: 1, maxRows: 3 }}
                                                className={`_cart-item-note mgt10`}
                                                placeholder={t('order.note')}
                                                onChange={e => this.onChangeInput(e.target.value, 'note')}
                                                onKeyDown={e => this.onKeyDown(e, 'note')}
                                                onBlur={e => this.onBlurInput(e, 'note')}
                                                // disabled={isLoadingDisable}
                                                ref={ref => (this[`order${data.id}`] = ref)}
                                            />
                                            {/*{note === '' && (*/}
                                            {/*    <span className={`txt-size-h8 txt-warning dpl-block pdt5`}>*/}
                                            {/*        {t('message.warning_empty')}*/}
                                            {/*    </span>*/}
                                            {/*)}*/}
                                            <span className={`txt-size-h8 txt-color-gray dpl-block`}>
                                                {t('order.note_keydown')}
                                            </span>
                                        </Fragment>
                                    ) : note ? (
                                        <Fragment>
                                            <span className="txt-color-gray robotoregular txt-size-h8">
                                                {t('orderDetail.personal_note_for_order')}:
                                            </span>
                                            {checkIsLink(note) ? (
                                                <div
                                                    className="txt-color-black txt-size-h8 robotoregular mgr5 white-space-pre-wrap pdl5 break-word mgr5"
                                                    style={{ display: 'inline' }}
                                                    dangerouslySetInnerHTML={{ __html: linkify(note) }}
                                                />
                                            ) : (
                                                <div
                                                    className="txt-color-black txt-size-h8 robotoregular mgr5 white-space-pre-wrap pdl5 break-word mgr5"
                                                    style={{ display: 'inline' }}
                                                >
                                                    {note}
                                                </div>
                                            )}
                                            <AntdIcon.EditOutlined
                                                className="_btn-edit-note-order cursor-pointer txt-color-blue txt-size-h7"
                                                type="edit"
                                                onClick={() => {
                                                    this.setState(
                                                        {
                                                            ['showNote' + (data && data.id)]:
                                                                !this.state['showNote' + (data && data.id)],
                                                        },
                                                        () => {
                                                            this[`order${data.id}`].focus()
                                                        }
                                                    )
                                                }}
                                            />
                                        </Fragment>
                                    ) : (
                                        <span
                                            className={`_cart-item-showUserNote-btn txt-size-h8 txt-color-blue robotoregular`}
                                            onClick={() => {
                                                this.setState(
                                                    {
                                                        ['showNoteDetail' + (data && data.id)]:
                                                            !this.state['showNoteDetail' + (data && data.id)],
                                                    },
                                                    () => {
                                                        this[`order${data.id}`].focus()
                                                    }
                                                )
                                            }}
                                        >
                                            <span className={'mgr5'}>{t('orderDetail.personal_note_for_order')}</span>
                                            <Tooltip
                                                placement="top"
                                                title={t('cartGroup.order_note_content')}
                                            >
                                                <AntdIcon.QuestionCircleOutlined
                                                    type="question-circle"
                                                    theme="filled"
                                                    className={'txt-size-h8 txt-color-gray opacity6'}
                                                />
                                            </Tooltip>
                                        </span>
                                    )}
                                </Col>
                            </Row>

                            {data && data.receivingWarehouse && data.receivingWarehouse.address && (
                                <div className="mgt10">
                                    <div className="dpl-flex">
                                        <span className="txt-color-gray robotoregular txt-size-h8 flex-none pdr10">
                                            {t('shipments.receivingWarehouse')}:
                                        </span>
                                        <div className="width-100-percent">
                                            <div className="position-re ">
                                                <TextArea
                                                    style={{ backgroundColor: '#fff', color: '#000' }}
                                                    value={data.receivingWarehouse.address.trim()}
                                                    autoSize={{ minRows: 1, maxRows: 999 }}
                                                    className="_cart-item-note bg-color-white txt-color-black break-word whitespace-pre-wrap"
                                                    // disabled={true}
                                                    readOnly={true}
                                                />
                                                <Typography.Paragraph
                                                    className="position-ab"
                                                    style={{ bottom: 4, right: 7, marginBottom: 0 }}
                                                    copyable={{ text: data.receivingWarehouse.address.trim() }}
                                                />
                                            </div>
                                            <div className="pdt5 txt-size-h8">{t('shipments.receivingWarehouse_note')}</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Fragment>
                    )}
                </div>

                <div className={'pt-2 pl-2 pr-2 pb-2 text-center pdbt20 showmore'}>
                    <span
                        className="_a-open open-states cursor-pointer"
                        onClick={this.onToggle}
                    >
                        {!isExpand ? (
                            <span>
                                <AntdIcon.ArrowsAltOutlined
                                    type="arrows-alt"
                                    className={'mgr15'}
                                />
                                {t('button.loadmore')}
                            </span>
                        ) : (
                            <span>
                                <AntdIcon.ShrinkOutlined
                                    type="shrink"
                                    className={'mgr15'}
                                />
                                {t('button.collapse')}
                            </span>
                        )}
                    </span>
                </div>
                {this.renderModalOriginalReceipts()}
            </div>
        )
    }
}

export default ShipmentInfo
