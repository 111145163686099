import React, { useCallback, useEffect } from 'react'
import { WithNamespaces, withNamespaces } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import MainLayout from '../../../components/Layout/MainLayout'
import { Badge, Button, Empty, Table, Tooltip, notification } from 'antd'
import { useState } from 'react'
import { AddressChinaResponseInterface } from '../../../types/AddressChina'
import CreateAddressChina from '../../../components/AddressChina/CreateAddressChina'
import AddressChinaService from '../../../services/addressChina.service'
import lodash from 'lodash'
import { PlusOutlined } from '@ant-design/icons'
import confirm from 'antd/lib/modal/confirm'
import { SkeletonTable, SkeletonTableColumnsType } from '../../../components/SkeletonTable/SkeletonTable'

interface Props extends RouteComponentProps, WithNamespaces {
    // Other props go here
}

const AddressChinaList: React.FC<Props> = ({ t, history, ...props }) => {
    const [loading, setLoading] = useState(false)
    const [isModalOpenCreate, setIsModalOpenCreate] = useState(false)
    const [ListAddressChina, setListAddressChina] = useState<AddressChinaResponseInterface[]>([])
    const [selectedAddress, setSelectedAddress] = useState<any>()
    const handleCancelModalAddress = () => {
        setIsModalOpenCreate(false)
    }

    //
    const showModalEditAddress = (item: any) => {
        setSelectedAddress(item)
        setIsModalOpenCreate(true)
    }
    // modal create
    const showModalCreate = () => {
        setSelectedAddress({})
        setIsModalOpenCreate(true)
    }

    const handleSubmitCreateAddress = (id: any, values: any) => {
        let data = {
            zipCode: values?.zipCode || '',
            address1: values?.address1 || '',
            phone: values?.phone || '',
            city: values?.city || '',
            district: values?.district || '',
            recipient: values?.recipient || '',
            state: values?.state || '',
            addressName: values?.addressName || '',
            defaultAddress: values?.defaultAddress ? true : false,
            nation: 'CN',
        }
        if (!id) {
            AddressChinaService.createCustomerChinaAddress(data, (err: any, res: any) => {
                if (!err) {
                    setIsModalOpenCreate(false)
                    getListAddress()
                    notification.success({ message: 'Thêm mới địa chỉ thành công' })
                } else {
                    notification.error({ message: 'Thêm mới địa chỉ thất bại' })
                }
            })
        } else {
            AddressChinaService.updateCustomerChinaAddress(id, data, (err: any, res: any) => {
                if (!err) {
                    setIsModalOpenCreate(false)
                    getListAddress()
                    notification.success({ message: 'Cập nhật địa chỉ thành công' })
                } else {
                    notification.error({ message: 'Cập nhật địa chỉ thất bại' })
                }
            })
        }

        // setIsModalOpenCreate(false);
    }

    const handleDeleteAddress = (id: string) => {
        AddressChinaService.deleteAddressChinaUser(id, (err: any, res: any) => {
            if (!err) {
                getListAddress()
                notification.success({ message: 'Xoá địa chỉ thành công' })
            } else {
                notification.error({ message: 'Xoá địa chỉ thất bại' })
            }
        })
    }

    const onClickDeleteAddress = (id: string) => {
        if (id) {
            confirm({
                title: 'Xoá địa chỉ',
                width: '500px',
                centered: true,
                okText: 'Xác nhận',
                cancelText: 'Hủy bỏ',
                className: 'modalConfirm',
                okButtonProps: {
                    icon: <i className="fa-light fa-trash mr-2"></i>,
                    color: '',
                    style: {
                        backgroundColor: '#FF4559',
                        border: 'none',
                    },
                },
                bodyStyle: { padding: '24px' },
                style: { padding: '24px' },
                content: <span>Bạn có chắc chắn muốn xoá địa chỉ này ra khỏi danh sách địa chỉ?</span>,
                closable: true,
                closeIcon: <i className="fa-solid fa-xmark"></i>,
                onOk() {
                    handleDeleteAddress(id)
                },
                onCancel() {},
                icon: null,
            })
        }
    }

    const handleSelectAddressDefault = (record: any) => {
        let id = record?.id
        let data = {
            zipCode: record?.zipCode || '',
            address1: record?.address1 || '',
            phone: record?.phone || '',
            city: record?.city || '',
            district: record?.district || '',
            recipient: record?.recipient || '',
            state: record?.state || '',
            addressName: record?.addressName || '',
            defaultAddress: true,
            nation: 'CN',
        }
        if (id) {
            AddressChinaService.updateCustomerChinaAddress(id, data, (err: any, res: any) => {
                if (!err) {
                    setIsModalOpenCreate(false)
                    getListAddress()
                    notification.success({ message: 'Cập nhật địa chỉ mặc định thành công' })
                } else {
                    notification.error({ message: 'Cập nhật địa chỉ mặc định thất bại' })
                }
            })
        }
    }

    const getListAddress = useCallback(() => {
        setLoading(true)
        AddressChinaService.getListAddressesChinaUser((err: any, res: any) => {
            if (!err) {
                const sortByDefaultAddress = res.sort((a: any, b: any) => {
                    if (b.defaultAddress && !a.defaultAddress) return 1
                    // If a has defaultAddress set to true, move it up in the array
                    if (a.defaultAddress && !b.defaultAddress) return -1
                    // Otherwise, keep the current order
                    return 0
                })
                // console.log(sortByDefaultAddress);

                setListAddressChina(sortByDefaultAddress)
            }
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        getListAddress()
    }, [getListAddress])

    const columns: any = [
        {
            title: <span className={''}>{`${t('taobaoGlobalCart.recipient')}`}</span>,
            dataIndex: 'recipient',
            key: 'recipient',
            width: '15%',
            render: (value: any, record: any) => {
                return (
                    <div className="flex">
                        <span className={'txt-size-h7 robotomedium'}>{record.recipient}</span>
                    </div>
                )
            },
        },
        {
            title: <span className="mr-3">{`${t('taobaoGlobalCart.phone')}`}</span>,
            dataIndex: 'phone',
            key: 'phone',
            align: 'right' as 'right',
            width: 180,
            render: (value: any, record: any) => {
                return (
                    <div>
                        <span className={'txt-size-h7 robotoregular mr-3'}>{record.phone}</span>
                    </div>
                )
            },
        },
        {
            title: <span>{`${t('taobaoGlobalCart.address')}`}</span>,
            dataIndex: 'name',
            key: 'name',
            width: '50%',
            render: (value: any, record: any) => {
                const defaultAddress = record?.defaultAddress
                const state = lodash.get(record, 'state') || '---'
                const district = lodash.get(record, 'district') || '---'
                const city = lodash.get(record, 'city') || '---'
                return (
                    <div>
                        <span className="text-sm text-[#1A1A1A] font-normal mr-4">
                            {state} - {district} - {city} - {`china`}
                        </span>
                        {defaultAddress && (
                            <span className="border text-xs font-medium text-[#1890FF] px-4 py-[4px] border-solid border-[#1890FF] rounded-2xl">
                                Mặc định{' '}
                            </span>
                        )}
                    </div>
                )
            },
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            align: 'right',
            // width: '25%',
            render: (_: any, record: any) => {
                return (
                    <div className="actions">
                        <div className="flex items-center justify-end">
                            {!record?.defaultAddress && (
                                <span
                                    className="underline text-blue-primary px-2"
                                    onClick={() => handleSelectAddressDefault(record)}
                                >{`${t('taobaoGlobalCart.saveDefault')}`}</span>
                            )}
                            <Tooltip
                                title={`${t('taobaoGlobalCart.addressEdit')}`}
                                placement="top"
                            >
                                <i
                                    className="cursor-pointer fa-regular fa-pen-to-square text-blue-primary px-2"
                                    onClick={() => {
                                        showModalEditAddress(record)
                                    }}
                                ></i>
                            </Tooltip>
                            {!record?.defaultAddress && (
                                <Tooltip
                                    title={`${t('taobaoGlobalCart.addressDelete')}`}
                                    placement="top"
                                >
                                    <i
                                        onClick={() => onClickDeleteAddress(record?.id)}
                                        className="cursor-pointer fa-regular fa-trash text-error px-2"
                                    />
                                </Tooltip>
                            )}
                        </div>
                    </div>
                )
            },
        },
    ]

    return (
        <MainLayout
            {...props}
            headerTitle={t('addressList.Title')}
            title={t('addressList.Title')}
            noFooter={true}
        >
            <div className="container-common pb-[30px] mgr20 relative v2-layout">
                <div className="p-6 bg-white rounded-xl">
                    <div className="flex items-center justify-between mb-6">
                        <div className="text-[#1A1A1A] flex items-center text-base font-medium">
                            {`${t('taobaoGlobalCart.ChinaWarehouseAddressTitle')}`}
                            <Badge
                                count={ListAddressChina.length}
                                className="ml-2"
                                showZero
                            />
                        </div>
                        <Button
                            icon={<PlusOutlined />}
                            className="btn-outline rounded"
                            onClick={showModalCreate}
                        >
                            {`${t('taobaoGlobalCart.addNew')}`}
                        </Button>
                    </div>

                    <SkeletonTable
                        loading={loading}
                        className="address-china-table"
                        columns={columns as SkeletonTableColumnsType[]}
                        rowCount={ListAddressChina.length}
                    >
                        <Table
                            rowKey={(record: any) => record.id}
                            className="address-china-table"
                            dataSource={ListAddressChina}
                            columns={columns}
                            pagination={false}
                            rowClassName={(record: any, index: number): string =>
                                `_row-order cursor-pointer ${index % 2 !== 0 ? 'tr-even row_bg' : ''}`
                            }
                            locale={{
                                emptyText: (
                                    <Empty
                                        image={require('../../../resources/images/addressEmpty.png')}
                                        description={
                                            <div className="grid justify-center gap-4 mt-2">
                                                <span>{`${t('taobaoGlobalCart.addressEmpty')}`}</span>
                                                <Button
                                                    onClick={showModalCreate}
                                                    className="rounded"
                                                    size="large"
                                                    type="primary"
                                                    icon={<PlusOutlined />}
                                                >{`${t('taobaoGlobalCart.addNewAddress')}`}</Button>
                                            </div>
                                        }
                                    />
                                ),
                            }}
                        />
                    </SkeletonTable>
                </div>
            </div>

            {isModalOpenCreate && (
                <CreateAddressChina
                    showModalCreate={showModalCreate}
                    isModalOpenCreate={isModalOpenCreate}
                    handleSubmitCreateAddress={handleSubmitCreateAddress}
                    handleCancelModalAddress={handleCancelModalAddress}
                    selectedAddress={selectedAddress}
                    t={t}
                />
            )}
        </MainLayout>
    )
}

export default withRouter(withNamespaces('translation')(AddressChinaList))
