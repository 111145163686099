import { Avatar, Button, Col, Divider, notification, Row, Space, Tooltip } from 'antd'
import React, { useState } from 'react'
import Modal from '../../components/commons/Modal'
import _ from 'lodash'
import InputItem from '../../components/commons/Input'
import bgVoucher from '../../resources/images/voucher.svg'
import UserService from '../../services/user.service'
import moment from 'moment'
import OrderService from '../../services/orders.service'

type Props = {
    t: Function
    visible: boolean
    setVisible: (value: boolean) => void
    orderCode?: string
    setVoucherApply: (value: any) => void
    voucherApply: any
}

const ModalVoucher: React.FC<Props> = ({ visible, setVisible, orderCode, setVoucherApply, voucherApply, t }) => {
    const [voucher, setVoucher] = useState<any>(null)
    const [checking, setChecking] = useState(false)
    const [infoVoucher, setInfoVoucher] = useState<any>(null)

    const onCheckCoupon = () => {
        if (voucher?.length > 0) {
            setChecking(true)
            setInfoVoucher(null)
            UserService.checkCouponPost(voucher, undefined, undefined, (err: any, res: any) => {
                if (!err && res.code) {
                    setChecking(false)
                    setInfoVoucher(res)
                } else {
                    setChecking(false)
                    notification.error({
                        message: t(`message.${err?.title || 'coupon_invalid_for_you'}`),
                    })
                }
            })
        }
    }

    const onSubmit = () => {
        OrderService.applyCouponDraftOrder(
            orderCode,
            {
                couponCode: infoVoucher?.code,
            },
            (err: any, res: any) => {
                if (!err) {
                    const couponApply = {
                        code: infoVoucher?.code,
                        totalDiscountFee: _.sum(
                            _.flatten(
                                res?.merchantCoupons?.map((i: any) => {
                                    return i?.coupons?.map((el: any) => el?.totalDiscountFee)
                                })
                            )
                        ),
                    }
                    const newListVoucherApply = _.uniqBy(_.concat(voucherApply, couponApply), 'code')
                    setVoucherApply(newListVoucherApply)
                    setVisible(false)
                    setInfoVoucher(null)
                    setVoucher(null)
                } else {
                    notification.error({
                        message: t(`message.${err?.title || 'coupon_invalid_for_you'}`),
                    })
                }
            }
        )
    }

    return (
        <Modal
            centered
            closeIcon={<i className="fa-solid fa-xmark" />}
            title={t('coupon.modalTitle')}
            visible={visible}
            onCancel={() => {
                setVisible(false)
                setInfoVoucher(null)
                setVoucher(null)
            }}
            width={660}
            listAction={[
                <Button
                    className={'btn-cancel space-x-1.5 _btn-cancel-confirm mr-2'}
                    onClick={() => {
                        setVisible(false)
                        setInfoVoucher(null)
                        setVoucher(null)
                    }}
                >
                    {t('button.cancel')}
                </Button>,
                <Button
                    type="primary"
                    htmlType="submit"
                    className="space-x-1.5 _btn-submit-confirm"
                    onClick={() => onSubmit()}
                    disabled={!infoVoucher}
                >
                    {t('coupon.apply')}
                </Button>,
            ]}
        >
            <div className="p-4 bg-gray-100 rounded-xl">
                <div className="mb-3 text-black-100">{t('coupon.inputVoucher')}</div>
                <Row
                    gutter={12}
                    justify="space-between"
                >
                    <Col flex="auto">
                        <InputItem
                            title={t('coupon.voucherCode')}
                            className={'_search-box-input-query'}
                            onChange={(e: any) => setVoucher(e.target.value)}
                            value={voucher || ''}
                            onPressEnter={() => onCheckCoupon()}
                            allowClear
                        />
                    </Col>
                    <Col flex="none">
                        <Button
                            className={'btn-cancel _btn-cancel-confirm h-11 hover:bg-gallery-100 border-none'}
                            onClick={() => onCheckCoupon()}
                            loading={checking}
                        >
                            {t('modal.confirm')}
                        </Button>
                    </Col>
                </Row>
            </div>

            {infoVoucher && (
                <div>
                    <Divider className="my-4" />
                    <Row>
                        <Col>
                            <div className="relative w-full">
                                <img
                                    src={bgVoucher}
                                    alt="coupon"
                                    className="w-full h-[120px]"
                                ></img>
                                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
                                    {infoVoucher?.image ? (
                                        <Avatar
                                            size={60}
                                            src={infoVoucher?.image}
                                        />
                                    ) : null}
                                    <div className="text-white font-medium mt-4">{infoVoucher?.code}</div>
                                </div>
                            </div>
                        </Col>
                        <Col
                            flex="1 1"
                            className="border-solid border border-gallery-100 rounded-r-md border-l-0"
                        >
                            <div className="px-4 py-2 h-full flex flex-col justify-between">
                                <div>
                                    <Space>
                                        <div className=" font-bold text-black-100">{infoVoucher?.title}</div>
                                        <div className=" text-[10px] text-red-100">
                                            {t('coupon.voucherRemaining', { value: infoVoucher?.remaining })}
                                        </div>
                                    </Space>
                                    <Tooltip
                                        placement="top"
                                        title={<div className="text-white">{infoVoucher?.description}</div>}
                                    >
                                        <div className="text-black-100 line-clamp-2">{infoVoucher?.description}</div>
                                    </Tooltip>

                                    <div className="text-black-100 text-xs">
                                        {t('coupon.voucherCustomerLimit', { value: infoVoucher?.customerLimit })}
                                    </div>
                                </div>
                                <div className="text-grey-200 text-xs">
                                    {t('coupon.voucherValidTo', {
                                        value: moment(infoVoucher?.validTo).format('DD/MM/YYYY'),
                                    })}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div
                        className="text-black-100 text-xs mt-4 max-h-60 overflow-auto"
                        dangerouslySetInnerHTML={{
                            __html: infoVoucher?.termsAndConditions,
                        }}
                    ></div>
                </div>
            )}
        </Modal>
    )
}

export default ModalVoucher
