/* eslint-disable array-callback-return */
import React from 'react';
import _ from 'lodash'
import {
    Radio,
    Checkbox
} from 'antd';
import * as AntdIcon from "@ant-design/icons"

import categoriesService from '../../services/categories.service'
import Loading from "../Loading";

export default class ServiceGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapse: true,
            checkedList: []
        }
    }

    componentDidMount() {
        const {draftShipment} = this.props;
        this.setState({
            checkedList: draftShipment && Array.isArray(draftShipment.services) ? draftShipment.services : []
        });
        this.getGroupServices();
        this.getShipmentServices()
    }

    componentDidUpdate(prevProps) {
        const {draftShipment} = this.props;
        if (JSON.stringify(draftShipment) !== JSON.stringify(prevProps.draftShipment)) {
            this.setState({
                checkedList: draftShipment && Array.isArray(draftShipment.services) ? draftShipment.services : []
            })
        }
    }

    getShipmentServices = () => {
        this.setState({loadServices: true}, () => {
            categoriesService.getShipmentService((err, res, header) => {
                if (!err) {
                    this.setState({
                        shipmentServices: Array.isArray(res) ? res.filter(x => x.onlyStaff !== true) : [],
                        loadServices: false
                    })
                } else {
                    this.setState({
                        loadServices: false
                    })
                }
            })
        })

    };

    /**
     * lấy ra danh sách nhóm services
     */
    getGroupServices = () => {
        this.setState({loadGroup: true}, () => {
            categoriesService.getOrderServicesGroup((err, res, headers) => {
                if (!err) {
                    // console.log('headers', headers)
                    this.setState({
                        listGroupServices: res,
                        loadGroup: false,
                        metadataListGroupServices: {
                            pageCount: headers['x-page-count'],
                            page: headers['x-page-number'],
                            size: headers['x-page-size'],
                            total: headers['x-total-count'],
                        }
                    });
                } else {
                    this.setState({
                        loadGroup: false,
                    });
                }
            })
        })
    };

    checkDisable = (item) => {
        let {checkedList} = this.state;
        let disable = false;

        // if (Array.isArray(item.requires) && item.requires.length > 0) {
        //     let hasOne = false;
        //     item.requires.map(x => {
        //         if (checkedList.find(y => y.code === x)) {
        //             hasOne = true;
        //         }
        //     });
        //
        //     disable = !hasOne;
        // }
        // if (Array.isArray(item.requireGroups) && item.requireGroups.length > 0) {
        //     let hasOne = false;
        //     item.requireGroups.map(x => {
        //         if (checkedList.find(y => y.serviceGroup && y.serviceGroup.code === x)) {
        //             hasOne = true;
        //         }
        //     });
        //
        //     disable = !hasOne;
        // }

        // nếu item là dependencies của 1 phần tử trong checkedList
        if (checkedList.find(x => x.dependencies.find(y => y === item.code))) {
            disable = true;
        }

        // nếu trong list check có phần tử excludes item thì disable item
        if (checkedList.length > 0 && checkedList.find(x => x.excludes.indexOf(item.code) > -1)) {
            disable = true;
        }

        // nếu trong list check có phần tử excludes item thì disable item
        if (item.serviceGroup) {
            if (checkedList.length > 0 && checkedList.find(x => Array.isArray(x.excludeGroups) && x.excludeGroups.indexOf(item.serviceGroup.code) > -1)) {
                disable = true;
            }
        }

        return disable
    };

    /**
     * item checked
     * @param item
     */
    onItemCheck = (item, isCallback, checkedListParam) => {
        const {shipmentServices = []} = this.state;
        let checkedList = checkedListParam || this.state.checkedList || [];

        // console.log("isCallback", item, isCallback);

        // nếu item đã tồn trịa trong mảng checked thì bỏ phần tử đó ra khỏi mảng checked
        if (checkedList.find(x => x.code === item.code)) {
            checkedList = checkedList.filter(x => x.code !== item.code);

            // loại bỏ các phân tử đã được check nhưng có requires của item
            // và ko còn chứa bất kỳ 1 required nào khác
            let listHasRequiresItem = checkedList.filter(x => x.requires.indexOf(item.code) > -1);
            listHasRequiresItem.map(x => {
                let remove = true;
                x.requires.map(y => {
                    if (checkedList.find(z => z.code === y)) {
                        remove = false;
                    }
                });
                if (remove) {
                    checkedList = checkedList.filter(y => y.code !== x.code)
                }
            });

            // loại bỏ các phân tử dependencies của item
            if (Array.isArray(item.dependencies) && item.dependencies.length > 0) {
                item.dependencies.map(x => {
                    checkedList = checkedList.filter(y => y.code !== x)
                })
            }

            // loại bỏ các phân tử requireGroup của group chứa item nếu không còn
            if (item.serviceGroup) {
                if (checkedList.filter(x => x.serviceGroup && x.serviceGroup.code === item.serviceGroup.code).length === 0) {
                    checkedList = checkedList.filter(y => !Array.isArray(y.requireGroups) || y.requireGroups.indexOf(item.serviceGroup.code) === -1)
                }
            }

        } else {
            //loại bỏ các phần tử trong mảng checkedList nếu item có excludes phần tử đó
            checkedList = checkedList.filter(x => item.excludes.indexOf(x.code) === -1);

            //loại bỏ các phần tử trong mảng checkedList nếu item có excludeGroups phần tử đó
            if (Array.isArray(item.excludeGroups)) {
                item.excludeGroups.map(x => {
                    checkedList = checkedList.filter(y => !y.serviceGroup || y.serviceGroup.code !== x);
                })
            }

            //loại bỏ các phần từ trong mảng checkedList nếu các phần tử đó cùng group với item và group có single === true
            if (item.serviceGroup && item.serviceGroup.single) {
                checkedList = checkedList.filter(x => !(x.serviceGroup && x.serviceGroup.code === item.serviceGroup.code))
            }

            // tự động thêm các phần tử thuộc dependencies của item
            if (Array.isArray(item.dependencies) && item.dependencies.length > 0) {
                item.dependencies.map(x => {
                    if (shipmentServices.find(y => y.code === x)) {
                        checkedList = _.unionBy(checkedList, this.onItemCheck(shipmentServices.find(y => y.code === x), true, checkedList), 'code');
                        // checkedList.push(shipmentServices.find(y => y.code === x))
                    }
                })
            }
            checkedList.push(item);
        }

        if (isCallback) {
            this.validateServices();
            return checkedList
        } else {
            this.setState({
                checkedList,
            }, () => {
                this.validateServices();
            });
        }
        this.onCheckServiceGroup(item)
    };

    /**
     * thay đổi giá trị của radio
     * @param e
     */
    onChangeRadio = (e) => {
        const {shipmentServices = []} = this.state;
        // lấy ra item được chọn
        let item = shipmentServices.find(x => x.code === e.target.value);
        if (!item) return;

        let checkedList = this.state.checkedList || [];

        //loại bỏ các phần tử trong mảng checkedList nếu item có excludes phần tử đó
        checkedList = checkedList.filter(x => item.excludes.indexOf(x.code) === -1);

        //loại bỏ các phần từ trong mảng checkedList nếu các phần tử đó cùng group với item và group có single === true
        if (item.serviceGroup && item.serviceGroup.single) {
            checkedList.filter(x => (x.serviceGroup && x.serviceGroup.code === item.serviceGroup.code)).map(x => {

                // loại bỏ các phân tử đã được check nhưng có requires của các phần sắp bị loại bỏ do cùng group với item
                // và ko còn chứa bất kỳ 1 required nào khác
                let listHasRequiresItem = checkedList.filter(y => y.requires.indexOf(y.code) > -1);
                listHasRequiresItem.map(p => {
                    let remove = true;
                    p.requires.map(y => {
                        let itemRelate = checkedList.find(z => z.code === y);
                        if (itemRelate) {
                            if (!itemRelate.serviceGroup || itemRelate.serviceGroup.code !== item.serviceGroup.code) {
                                remove = false;
                            }
                        }
                    });
                    if (remove) {
                        checkedList = checkedList.filter(y => y.code !== p.code)
                    }
                });

                // loại bỏ các phân tử dependencies của item sắp bị bỏ khỏi mảng checkedList
                if (Array.isArray(x.dependencies) && x.dependencies.length > 0) {
                    x.dependencies.map(y => {
                        checkedList = checkedList.filter(z => z.code !== y)
                    });
                }

                // loại bỏ các phân tử requireGroup của group chứa item sắp bị bỏ khỏi mảng checkedList
                if (x.serviceGroup) {
                    if (checkedList.filter(y => y.serviceGroup && y.serviceGroup.code === x.serviceGroup.code).length === 0) {
                        checkedList = checkedList.filter(y => !Array.isArray(y.requireGroups) || y.requireGroups.indexOf(x.serviceGroup.code) === -1)
                    }
                }
            });

            //loại bỏ các phần tử trong cùng group ra khỏi checkedList
            checkedList = checkedList.filter(x => !(x.serviceGroup && x.serviceGroup.code === item.serviceGroup.code))
        }

        // tự động thêm các phần tử thuộc dependencies của item
        if (Array.isArray(item.dependencies) && item.dependencies.length > 0) {
            item.dependencies.map(x => {
                if (shipmentServices.find(y => y.code === x)) {
                    checkedList = _.unionBy(checkedList, this.onItemCheck(shipmentServices.find(y => y.code === x), true, checkedList), 'code');
                    // checkedList.push(shipmentServices.find(y => y.code === x))
                }
            })
        }

        checkedList.push(item);

        //loại bỏ các phần tử trong mảng checkedList nếu item có excludeGroups phần tử đó
        if (Array.isArray(item.excludeGroups)) {
            item.excludeGroups.map(x => {
                checkedList = checkedList.filter(y => !y.serviceGroup || y.serviceGroup.code !== x);
            })
        }

        this.setState({
            checkedList,
        }, () => {
            this.validateServices();
        });
        this.onCheckServiceGroup(item)
    };

    onCheckServiceGroup = (item) => {
        const {listGroupServices} = this.state;

        if (item.serviceGroup) {
            let index = listGroupServices.findIndex(y => y.code === item.serviceGroup.code);
            listGroupServices[index].error = false;

            this.setState({listGroupServices})
        }
    };

    /**
     * validate để add service vào merchant
     */
    validateServices = (notCreate) => {
        const {listGroupServices = [], checkedList = [], shipmentServices = []} = this.state;
        const {createDraftShipment, handleDisable, t} = this.props;
        let isError = false;
        // lấy ra danh sách checkedList của ServiceGroup component
        // let checkedList = this.ServiceGroup.state.checkedList;
        // console.log('checkedList', checkedList)
        // let isSubmit = false;
       
        // listGroupServices.filter(x => x.required).map((x) => {
        let listServiceTemp = _.uniq(shipmentServices?.map((listService)=> listService?.serviceGroup?.code).filter((serviceGroup)=>serviceGroup))
        listGroupServices.filter(i=> {
                return listServiceTemp.includes(i.code) && i.required 
                }).map(x => {
            let index = listGroupServices.findIndex(y => y.code === x.code);
            if (checkedList.filter(y => (y.serviceGroup && y.serviceGroup.code === x.code)).length === 0) {
                isError = true;
                // console.log('vào lỗi services')
                if (index >= 0) {
                    listGroupServices[index].error = true;
                }
            } else {
                if (index >= 0) {
                    listGroupServices[index].error = false;
                }
            }

            this.setState({listGroupServices})
        });

        let totalRequired = 0;
        checkedList.map((x, index) => {
            totalRequired = 0;
            if (Array.isArray(x.requires) && x.requires.length > 0) {
                x.requires.map(y => {
                    if (!!checkedList.find(j => j.code === y)) {
                        totalRequired = totalRequired + 1
                    }
                });
                if(totalRequired < x.requires.length) {
                    isError = true;
                    checkedList[index].requiresMessage = t("error.requiresMessage", {
                        service: checkedList[index].name,
                        services: x.requires.map((y, i) => {
                            return shipmentServices.find(a => a.code === y) ? shipmentServices.find(a => a.code === y).name + (i < x.requires.length - 1 ? ', ' : '') : ''
                        })
                    })
                } else {
                    checkedList[index].requiresMessage = ""
                }
            }

            totalRequired = 0;
            if (Array.isArray(x.requireGroups) && x.requireGroups.length > 0) {
                x.requireGroups.map(y => {
                    if (!!checkedList.find(j => j.serviceGroup && j.serviceGroup.code === y)) {
                        totalRequired = totalRequired + 1
                    }
                });
                if(totalRequired < x.requireGroups.length) {
                    isError = true;
                    checkedList[index].requireGroupsMessage = t("error.requireGroupsMessage", {
                        service: checkedList[index].name,
                        serviceGroup: x.requireGroups.map((y, i) => {
                            return listGroupServices.find(a => a.code === y) ? listGroupServices.find(a => a.code === y).name + (i < x.requires.length - 1 ? ', ' : '') : ''
                        })
                    });
                } else {
                    checkedList[index].requireGroupsMessage = ""
                }
            }
        });

        // if (!draftShipment || !Array.isArray(draftShipment.services)) return;

        //kiểm tra xem hai mảng có phần tử khác nhau nào ko
        // checkedList.map(x => {
        //     if (draftShipment.services.filter(y => y.code === x.code).length === 0) {
        //         isSubmit = true
        //     }
        // });
        // if (checkedList.length !== draftShipment.services.length) {
        //     isSubmit = true
        // }
        // kiểm tra nếu 2 mảng bằng về độ dài thì kiểm tra xem có phần tử nào khác nhau ko
        // if (!isSubmit) {
        //     return;
        // }

        if (!isError) {
            // nếu ko có lỗi thì add services vào order
            if (handleDisable) handleDisable(false);
            if (createDraftShipment && !notCreate) createDraftShipment();
        } else {
            if (handleDisable) handleDisable(true)
        }
        return isError
    };

    /**
     * render check box
     * @param item
     * @returns {*}
     */
    renderCheckbox = (item) => {
        const {checkedList} = this.state;
        return <Checkbox
            disabled={this.checkDisable(item)}
            key={item.code}
            onChange={() => this.onItemCheck(item)}
            className={`_service-checkbox mgbt10`}
            checked={checkedList.find(x => x.code === item.code) !== undefined}
        >
            <span className={'_service-name txt-size-h8 robotoregular txt-color-black'}>
                {item.name}
            </span>
        </Checkbox>
    };

    // /**
    //  * render check box ko có chức năng edit
    //  * @param item
    //  * @returns {*}
    //  */
    // renderCheckboxDisable = (item) => {
    //     const {checkedList} = this.state;
    //     return <Checkbox
    //         disabled={this.checkDisable(item)}
    //         key={item.code}
    //         className={`_service-checkbox`}
    //         checked={checkedList.find(x => x.code === item.code) !== undefined}
    //     >
    //         <span className={'_service-name txt-size-h8 robotoregular txt-color-black'}>
    //             {item.name}
    //         </span>
    //     </Checkbox>
    // };

    /**
     * render mô tả của service
     * @param item
     * @returns {null}
     */
    renderDescription = (item) => {
        const {t} = this.props;
        const {checkedList = []} = this.state;
        let currentItem = checkedList.find(x => x.code === item.code);
        return  currentItem !== undefined ? <React.Fragment key={item.code}>
            {
                !!currentItem.requiresMessage && <p className="txt-error txt-size-h8 mgbt10">
                    <AntdIcon.ExclamationCircleOutlined type="exclamation-circle" className={'pdr5'}/> <span dangerouslySetInnerHTML={{__html: currentItem.requiresMessage}}/>
                </p>
            }
            {
                !!currentItem.requireGroupsMessage && <p className="txt-error txt-size-h8 mgbt10">
                    <AntdIcon.ExclamationCircleOutlined type="exclamation-circle" className={'pdr5'}/> <span dangerouslySetInnerHTML={{__html: currentItem.requireGroupsMessage}}/>
                </p>
            }
            {
                item.description && <p className="txt-color-gray txt-size-h8 mgbt10">
                    <span className={`_service-name`}>{item.name}</span>: <span className={`_service-description`}>{item.description}</span>
                </p>
            }
            {
                item.needApprove && <p className={`_need-approve txt-warning txt-size-h8 mgbt10`}>
                    <AntdIcon.WarningOutlined type="warning" className={'pdr5'}/> {t('orderServiceGroup.service')} {item.name} {t('orderServiceGroup.approved_privilege')}
                </p>
            }
        </React.Fragment> : null
    };

    /**
     * render radio group
     * @param group
     * @returns {*}
     */
    renderRadioGroup = (group) => {
        const {checkedList, shipmentServices = []} = this.state;
        const currentItem = checkedList.find(x => (x.serviceGroup && x.serviceGroup.code === group.code));
        return <div className={`_service-radio-group-${group.code} checkbox-group`} key={group.code}>
            <Radio.Group
                key={group.code}
                // className={`_service-radio-group-${group.code}`}
                onChange={this.onChangeRadio}
                value={currentItem ? currentItem.code : null}
            >
                {
                    _.sortBy(shipmentServices.filter(x => x.serviceGroup && x.serviceGroup.code === group.code), 'position').map(x => <Radio
                        key={x.code}
                        className={`_service-checkbox mgbt10`}
                        value={x.code}
                        disabled={this.checkDisable(x)}
                    >
                        <span className={`_service-name txt-size-h8 robotoregular txt-color-black`}>{x.name}</span>
                    </Radio>)
                }
            </Radio.Group>
            {
                _.sortBy(shipmentServices.filter(x => x.serviceGroup && x.serviceGroup.code === group.code), 'position').map(this.renderDescription)
            }
        </div>
    };

    /**
     * render checkbox group
     * @param group
     * @returns {*}
     */
    renderCheckboxGroup = (group) => {
        const {shipmentServices = []} = this.state;

        return <div className={`_service-checkbox-group-${group.code} checkbox-group `} key={group.code}>
            {
                _.sortBy(shipmentServices.filter(x => x.serviceGroup && x.serviceGroup.code === group.code), 'position').map(x => this.renderCheckbox(x))
            }
            {
                _.sortBy(shipmentServices.filter(x => x.serviceGroup && x.serviceGroup.code === group.code), 'position').map(this.renderDescription)
            }
        </div>
    };

    /**
     * render group của service
     * @param group
     * @param index
     * @returns {*}
     */
    renderGroup = (group, index) => {
        const {t} = this.props;
        const {shipmentServices = []} = this.state;

        if (shipmentServices.filter(x => x.serviceGroup && x.serviceGroup.code === group.code).length === 0) {
            return null;
        }
        return <div key={`${group.code}-index`} className={`_group-service-${group.code} group-service ${group.error ? 'error' : ''}`}>
            {/*<Divider key={`${group.code}-index`}/>*/}

            <div className="dpl-flex align-items-start">
                <div className="txt-color-black txt-size-h7 bold width-150 flex-none">{group.name}:</div>
                {
                    group.single
                    ?
                        group.required ?
                        this.renderRadioGroup(group) :
                        this.renderCheckboxGroup(group)
                    :
                        this.renderCheckboxGroup(group)
                }
            </div>
            {
                group.error && <p className="txt-error mgt10">
                    <AntdIcon.ExclamationCircleOutlined type="exclamation-circle" className={'pdr5'}/> {t('orderServiceGroup.choose_error')} {group.name}
                </p>
            }
        </div>
    };

    render() {
        const {t, className, header} = this.props;
        const {listGroupServices = [], shipmentServices = [], loadServices, loadGroup} = this.state;

        if (loadGroup || loadServices) return <Loading />;

        return (
            <div className={`servicesbox ${className || ''}`}>
                <div className="servicesbox__title sidebar__title dpl-flex justify-content-between align-items-center">
                    <span className={'txt-size-h7 txt-color-black robotomedium'}>{t('orderServiceGroup.choose_service')}</span>
                    {header}
                </div>
                <div className={'servicesbox__notgroup servicesbox__content'}>
                    {
                        shipmentServices.filter(x => !x.serviceGroup).length > 0 && <div className='dpl-flex align-items-start'>
                            <div className="txt-color-black txt-size-h7 bold width-150 flex-none">{t('orderServiceGroup.other_service')}:</div>
                            <div>
                                {
                                    _.sortBy(shipmentServices.filter(x => !x.serviceGroup), 'position').map(this.renderCheckbox)
                                }
                                {
                                    _.sortBy(shipmentServices.filter(x => !x.serviceGroup), 'position').map(this.renderDescription)
                                }
                            </div>
                        </div>
                    }
                    {
                        _.sortBy(listGroupServices, 'position').map(this.renderGroup)
                    }
                    {/*<Button*/}
                    {/*    htmlType={'button'} block*/}
                    {/*    className={'_btn-save-services btn--blue'}*/}
                    {/*    onClick={() => validateServices(dataSource)}*/}
                    {/*>*/}
                    {/*    <Icon type="save" /> Lưu dịch vụ*/}
                    {/*</Button>*/}
                </div>
            </div>
        )
    }
}
