import React, {Component} from 'react'
import Dropzone from "react-dropzone"
import {notification, Input} from 'antd';
import * as AntdIcon from "@ant-design/icons"
class ImportExcel extends Component {

    static defaultProps = {
        inputProps:{}
    };

    constructor(props) {
        super(props);
        this.state = {}
    }

    onReject = () => {
        const {t} = this.props;
        notification['error']({
            message: t('error.error_upload_file_type')
        });
    };

    /**
     * thêm ảnh
     * @param files
     */
    onDropAccepted = (files) => {
        // this.props.addFiles(files);
        this.setState({file: files[0]});
        this.props.fileUpLoaded(files[0])
    };



    render() {
        const {className, inputProps, isUploading, t} = this.props;
        const {file = {}} = this.state;
        return (
            <div className="import-dropzone">
                <Dropzone
                    multiple={false}
                    disabled={isUploading}
                    accept={'.xlsx'}
                    className={className ? className : ''}
                    onDropAccepted={this.onDropAccepted}
                    onDropRejected={this.onReject}
                    maxSize={1024*1024*3} // max 3mb
                    inputProps={inputProps}
                >
                    <Input value={file.name} className="width-100-percent"/>
                    <span className="_chatbox-camera ant-btn ant-btn-primary flex-none">
                        <AntdIcon.UploadOutlined type="upload" /> {t('shipment.add_file')}
                    </span>
                </Dropzone>
            </div>
        )
    }
}


export default ImportExcel;