/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Empty, Pagination, Skeleton, Table, Tag, Tooltip, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import _, { reverse, isArray, isEmpty, get, set, merge, range } from 'lodash'
import queryString from 'query-string'

import CreateWithdrawPaymentModal from '../../components/RequestWithDrawal/CreateWithdrawPaymentModal'
import MainLayout from '../../components/Layout/MainLayout'
import Filter from '../../components/RequestWithDrawal/Filter'
import WithDrawalService from '../../services/withdrawal.service'
import {
    moneyCeil,
    moneyFormat,
    parseQueryStringToObject,
    updateQueryStringParameter,
} from '../../utils/stringUtils'
import { getUrlQueryString } from '../../utils/helper'
import CancelRequestButton from '../../components/RequestWithDrawal/CancelRequestButton'
import { Bank, DataType, StatisticsItem } from '../../types/RequestWithDrawal'
import moment from 'moment'
import ModalLog from '../../components/RequestWithDrawal/ModalLog'
import CategoriesService from '../../services/categories.service'

const PAGE_SIZE_DEFAULT = 25

const DEFAULT_FILTER = {
    page: 0,
    size: PAGE_SIZE_DEFAULT,
    sort: 'createdAt:desc',
}

interface Props extends RouteComponentProps, WithNamespaces {
    // Other props go here
}

interface IPagination {
    pageCount: number
    current: number
    pageSize: number
    total: number
}

const RequestWithDrawal: React.FC<Props> = ({ t, history, ...props }) => {
    const [showProgressBar, setShowProgressBar] = useState<boolean>(false)
    const [, setError] = useState()
    const [listRequestWithDrawal, setListRequestWithDrawal] = useState<DataType[]>()
    const [listStatus, setListStatus] = useState<[]>([])
    const [statistics, setStatistics] = useState<StatisticsItem[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [logs, setLogs] = useState(range(2))
    const [openModalLog, setOpenModalLog] = useState<boolean>(false)
    const [listBank, setListBank] = useState<Array<Bank>>([])
    //filter
    const [, setFilter] = useState<Object>({})
    const [query, setQuery] = useState<string>('')
    const [numberBank, setNumberBank] = useState<string>('')
    const [timestampFrom, setTimestampFrom] = useState<string | undefined>()
    const [timestampTo, setTimestampTo] = useState<string | undefined>()
    const [listStatusChecked, setListStatusChecked] = useState<any>([])
    const [selectedBank, setSelectedBank] = useState<string>('')

    const staticData = useRef({
        pageCount: 1,
        current: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        total: 1,
    } as IPagination)

    useEffect(() => {
        loadFromQueryString()
        getLisWithdrawalStatus()
        getListWithDrawalSlipStatistic()
        getBanks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getWithdrawals = (filter?: any, notNeedLoading: boolean = false): void => {
        if (!notNeedLoading) {
            setLoading(true)
        }
        setShowProgressBar(true)
        setFilter({ ...filter })

        const filterTemp = {
            ...filter,
        }

        if (!isEmpty(filterTemp)) {
            set(filterTemp, 'page', filterTemp.page ? filterTemp.page - 1 : DEFAULT_FILTER.page)
            set(filterTemp, 'size', get(filterTemp, 'size', PAGE_SIZE_DEFAULT))
            set(filterTemp, 'sort', get(filterTemp, 'sort', DEFAULT_FILTER.sort))
        } else {
            set(filterTemp, 'size', PAGE_SIZE_DEFAULT)
            set(filterTemp, 'page', DEFAULT_FILTER.page)
            set(filterTemp, 'sort', DEFAULT_FILTER.sort)
        }

        let query = queryString.stringify(filterTemp)

        WithDrawalService.getListWithDrawalSlip(query, (err: any, res: any, headers: any) => {
            if (!err) {
                staticData.current = {
                    pageCount: parseInt(get(headers, 'x-page-count')),
                    current: headers ? parseInt(get(headers, 'x-page-number')) + 1 : 1,
                    pageSize: parseInt(get(headers, 'x-page-size')),
                    total: parseInt(get(headers, 'x-total-count')),
                }
                setListRequestWithDrawal(res)
            } else {
                setError(err)
            }
            setLoading(false)
            setShowProgressBar(false)
        })
    }

    const getBanks = () => {
        CategoriesService.getListBank((err: any, res: any, headers: any) => {
            if (!err) {
                let resBanks = res.concat([
                    {
                        code: 'other',
                        name: 'Khác',
                    },
                ])
                setListBank(resBanks)
            }
        })
    }

    const handleModalLog = (boolean: boolean): void => {
        setOpenModalLog(boolean)
    }

    const loadFromQueryString = () => {
        let queryString: string = getUrlQueryString()
        let queryObj: any = parseQueryStringToObject(queryString)
        let arrayValue = []
        //reset các param khi load từ url
        let query = ''
        let selectedBank = ''
        let numberBank = ''

        let timestampFrom = ''
        let timestampTo = ''
        for (let key in queryObj) {
            if (key === 'statuses') {
                // xử lý với obj list checked
                arrayValue = queryObj[key].split(',')
            } else if (key === 'timestampFrom') {
                timestampFrom = decodeURIComponent(queryObj['timestampFrom'])
            } else if (key === 'timestampTo') {
                timestampTo = decodeURIComponent(queryObj['timestampTo'])
            } else if (key === 'beneficiaryBank') {
                selectedBank = decodeURIComponent(queryObj['beneficiaryBank'])
            } else if (key === 'beneficiaryAccount') {
                numberBank = decodeURIComponent(queryObj['beneficiaryAccount'])
            } else if (key === 'query') {
                query = decodeURIComponent(queryObj['query'])
            }
        }

        setQuery(query)
        setSelectedBank(selectedBank)
        setNumberBank(numberBank)
        setListStatusChecked(arrayValue)
        setTimestampFrom(timestampFrom)
        setTimestampTo(timestampTo)
        getWithdrawals(merge(queryObj))
    }

    const getLisWithdrawalStatus = () => {
        WithDrawalService.getWithDrawalSlipStatuses((err: any, response: any, headers: any) => {
            if (!err) {
                const data: [] = response
                if (isArray(data)) {
                    setListStatus(reverse(data))
                }
            } else {
                setError(err)
            }
        })
    }

    const getListLogByCodeWithdrawal = (code: string) => {
        WithDrawalService.getLogWithDrawlSlip(code, (err: any, response: any, headers: any) => {
            if (!err) {
                setLogs(parseLog(response))
            } else {
                // setError(err)
            }
        })
    }

    const formatLogsContent = (item: any): string => {
        let result = `${t(`log_requestWithDrawal.${item.property}`, item)}`
        return result
    }

    const parseLog = (items: any): any => {
        const result: any = []
        items.map((item: any) => {
            let itemTransform = {} as any
            itemTransform.fullname = item.actor.fullname ? item.actor.fullname : '---'
            itemTransform.timestamp = item.timestamp ? item.timestamp : '---'
            itemTransform.role = item.role
            itemTransform.items = []

            switch (item.activity) {
                case 'WITHDRAWAL_SLIP_CREATE':
                    itemTransform.property = item.activity
                    itemTransform.code = item.data.code
                    break
                case 'WITHDRAWAL_SLIP_CANCELLED':
                case 'WITHDRAWAL_SLIP_STATUS_UPDATE':
                    if (Array.isArray(item.data)) {
                        item.data.forEach((element: any) => {
                            if (element.type === 'FULL_VALUE_CHANGE') {
                                if (element.property === 'status') {
                                    const oldObj = listStatus.find(
                                        (x: any) =>
                                            x.code ===
                                            _.get(Array.isArray(item.data) ? item.data[0] : item.data, 'oldValue.code', '---')
                                    )
                                    const newObj = listStatus.find(
                                        (x: any) =>
                                            x.code ===
                                            _.get(Array.isArray(item.data) ? item.data[0] : item.data, 'newValue.code', '---')
                                    )
                                    itemTransform.items.push({
                                        property: element.property,
                                        oldValue: _.get(oldObj, 'name', '---'),
                                        newValue: _.get(newObj, 'name', '---'),
                                    })
                                }
                            }
                        })
                    }
                    break
                default:
            }

            result.push(itemTransform)
        })
        return result
    }

    const getListWithDrawalSlipStatistic = () => {
        setLoading(true)
        WithDrawalService.getWithDrawalSlipStatistics((err: any, res: any, headers: any) => {
            if (!err) {
                setStatistics(res)
            } else {
            }
        })
    }

    const onChangeTimeStampFrom = (date: any): void => {
        setTimestampFrom(date ? date.startOf('day').toISOString() : undefined)
    }

    const onChangeTimeStampTo = (date: any): void => {
        setTimestampTo(date ? date.endOf('day').toISOString() : undefined)
    }

    const onChangeQuerySearch = (value: string): void => {
        setQuery(value.trim())
    }

    const onChangeNumberBank = (value: string): void => {
        setNumberBank(value.trim())
    }

    const onChangeSelectedBank = (value: string) => {
        setSelectedBank(value)
    }

    const onClearSearch = (): void => {
        setQuery('')
        setTimestampFrom('')
        setTimestampTo('')
        setListStatusChecked([])
        let queryString: string = getUrlQueryString()
        if (queryString) {
            historyPush(() => {})
            loadFromQueryString()
        }
    }

    const historyPush = (callback: Function) => {
        if (typeof callback !== 'function') return
        history.push({
            pathname: props.location.pathname,
            search: callback(),
        })
    }

    const renderColor = (status: string) => {
        switch (status) {
            case 'CANCELLED':
                return {
                    backgroundColor: 'rgba(255, 69, 89, 0.1)',
                    color: '#FF4559',
                }
            case 'PENDING':
                return {
                    backgroundColor: 'rgba(51, 157, 255, 0.1)',
                    color: '#339DFF',
                }
            case 'PROCESSED':
                return {
                    backgroundColor: 'rgba(0, 175, 167, 0.1)',
                    color: '#00AFA7',
                }
            case 'PROCESSING':
                return {
                    backgroundColor: 'rgba(253, 185, 36, 0.1)',
                    color: '#FDB924',
                }
            default:
                return {}
        }
    }

    const columns: ColumnsType<DataType> = [
        {
            title: <span className="robotomedium text-xs  text-grey-400">{`${t('request_withdraw.code')}`}</span>,
            dataIndex: 'code',
            key: 'name',
            render: text => (
                <div>
                    <Typography.Paragraph
                        className="robotomedium text-black-100 "
                        style={{ marginBottom: 0 }}
                        copyable={{ text: text, icon: <i className="fa-light fa-copy ml-1"></i> }}
                    >
                        #{text}
                    </Typography.Paragraph>
                </div>
            ),
        },
        {
            title: <span className="robotomedium text-xs  text-grey-400">{`${t('request_withdraw.receivedTime')}`}</span>,
            dataIndex: 'timestamp',
            key: 'timestamp',
            width: 160,
            render: text => {
                return (
                    <div>
                        <span className="robotoregular text-grey-500">
                            {text ? moment(text).format('HH:mm DD/MM/YYYY') : '---'}
                        </span>
                    </div>
                )
            },
        },
        {
            title: <span className="robotomedium text-xs  text-grey-400">{`${t('request_withdraw.amount')}`}</span>,
            dataIndex: 'amount',
            key: 'amount',
            align: 'right',
            render: (text, record) => {
                const currencySymbol: any = get(record, 'diorAccount.currency', undefined)
                return (
                    <div>
                        <span className="robotomedium text-black-100">
                            {text ? moneyFormat(moneyCeil(text), currencySymbol) : '---'}
                        </span>
                    </div>
                )
            },
        },
        {
            title: <span className="robotomedium text-xs text-grey-400">Thông tin thụ hưởng</span>,
            dataIndex: 'nameBank',
            key: 'nameBank',
            width: 310,
            render: (text, record: DataType) => {
                return (
                    <div>
                        <span className="robotoregular text-black-100">Tên ngân hàng: </span>
                        {record.beneficiaryBank && record.beneficiaryBank.name ? (
                            <span className="robotoregular text-grey-200">{record.beneficiaryBank.name || '---'}</span>
                        ) : (
                            <span className="robotoregular text-grey-200">{record.beneficiaryBank?.code || '---'}</span>
                        )}
                        <div>
                            <span className="robotoregular text-black-100">Tên TK: </span>
                            <span className="robotoregular text-grey-200">
                                {record.beneficiaryName ? record.beneficiaryName : '---'}
                            </span>
                        </div>
                        <div>
                            <span className="robotoregular text-black-100">STK: </span>
                            <Typography.Paragraph
                                className="inline-block robotomedium text-grey-200"
                                style={{ marginBottom: 0 }}
                                copyable={{ text: record.beneficiaryAccount, icon: <i className="fa-light fa-copy ml-1"></i> }}
                            >
                                {record.beneficiaryAccount ? record.beneficiaryAccount : '---'}
                            </Typography.Paragraph>
                        </div>
                    </div>
                )
            },
        },
        {
            title: <span className="robotomedium text-xs text-grey-400">Nội dung</span>,
            dataIndex: 'memo',
            width: 310,
            key: 'memo',
            render: text => {
                return (
                    <div className="relative">
                        <span className="robotoregular text-ellipse-requestWithDrawal text-grey-200">
                            <Tooltip
                                placement="top"
                                title={<span className="robotoregular text-xs">{text ? text : '---'}</span>}
                            >
                                {text ? text : '---'}
                            </Tooltip>
                        </span>
                    </div>
                )
            },
        },
        {
            title: <span className="robotomedium text-xs text-grey-400">{`${t('request_withdraw.status')}`}</span>,
            dataIndex: 'status',
            key: 'status',
            align: 'right',
            width: 100,
            render: (text, record) => {
                let itemStatus = listStatus.find((status: any, index) => status.code === text) as any
                return (
                    <div>
                        <Tag
                            style={renderColor(itemStatus && itemStatus.code)}
                            className={'_table-row-status border-none mr-0 robotomedium py-1 px-4 rounded-2xl robotomedium'}
                        >
                            {itemStatus ? itemStatus.name : ''}
                        </Tag>
                    </div>
                )
            },
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                const cancelableItem = record.status === 'PENDING'

                return (
                    <div className="flex">
                        <div className="w-[25px]">
                            {cancelableItem && (
                                <CancelRequestButton
                                    t={t}
                                    callbackGetData={getWithdrawals}
                                    getListWithDrawalSlipStatistic={getListWithDrawalSlipStatistic}
                                    requestCode={record.code}
                                />
                            )}
                        </div>
                        <div className="ml-3">
                            <span
                                className="_log-btn text-[#339DFF] cursor-pointer"
                                onClick={() => {
                                    handleModalLog(true)
                                    getListLogByCodeWithdrawal(record.code)
                                }}
                            >
                                <i className="fa-regular fa-file mr-1" />
                                Log
                            </span>
                        </div>
                    </div>
                )
            },
        },
    ]

    const onChangePage = (page: any, pageSize: any) => {
        let locationQuery = getUrlQueryString()
        let queryParse = locationQuery ? parseQueryStringToObject(locationQuery) : {}

        set(queryParse, 'page', page)
        set(queryParse, 'size', pageSize)
        historyPush(() => queryString.stringify(queryParse))
        loadFromQueryString()
    }

    const onSearch = (data?: any) => {
        // const searchParams: any = { ...filter }
        // keys(data).forEach((key) => {
        //     if (data[key]) {
        //         searchParams[key] = data[key]
        //     } else {
        //         delete data[key]
        //     }
        // })
        historyPush(buildQueryString)
        loadFromQueryString()
    }

    const buildQueryString = () => {
        let queryString = ''
        let value = ''
        if (Array.isArray(listStatusChecked) && listStatusChecked.length > 0) {
            listStatusChecked.map((x: any) => {
                value = value + x + ','
            })

            value = value.slice(0, -1)
            if (value) queryString = updateQueryStringParameter(queryString, 'statuses', value)
        }

        if (timestampFrom) {
            queryString = updateQueryStringParameter(queryString, 'timestampFrom', timestampFrom)
        }

        if (timestampTo) {
            queryString = updateQueryStringParameter(queryString, 'timestampTo', timestampTo)
        }

        if (query) {
            queryString = updateQueryStringParameter(queryString, 'query', query.trim())
        }

        if (selectedBank) {
            queryString = updateQueryStringParameter(queryString, 'beneficiaryBank', selectedBank.trim())
        }

        if (numberBank) {
            queryString = updateQueryStringParameter(queryString, 'beneficiaryAccount', numberBank.trim())
        }
        return queryString
    }

    const onChangeListStatues = (value: any): void => {
        setListStatusChecked(value)
    }

    const onCheckBoxCheck = (item: any): void => {
        let newState = [...listStatusChecked]
        if (newState.find((x: any) => x === item.code)) {
            newState = newState.filter((x: any) => x !== item.code)
        } else {
            newState.push(item.code)
        }

        setListStatusChecked(newState)
    }

    return (
        <MainLayout
            {...props}
            showProgressBar={showProgressBar}
            headerTitle={t('request_withdraw.list')}
            title={t('request_withdraw.title')}
        >
            <div className="container-common v2-layout pdbt30 mgr20">
                <Filter
                    t={t}
                    listStatusChecked={listStatusChecked}
                    statistics={statistics}
                    statuses={listStatus}
                    query={query}
                    timestampFrom={timestampFrom}
                    timestampTo={timestampTo}
                    listBank={listBank}
                    selectedBank={selectedBank}
                    numberBank={numberBank}
                    onChangeNumberBank={onChangeNumberBank}
                    onSearch={onSearch}
                    onChangeListStatues={onChangeListStatues}
                    onChangeTimeStampFrom={onChangeTimeStampFrom}
                    onChangeTimeStampTo={onChangeTimeStampTo}
                    onChangeQuerySearch={onChangeQuerySearch}
                    onClearSearch={onClearSearch}
                    onCheckBoxCheck={onCheckBoxCheck}
                    onChangeSelectedBank={onChangeSelectedBank}
                />
                <div className="py-4 px-6 bg-color-white shadow-box rounded-md mt-3">
                    <div className="flex items-center justify-between mb-4">
                        <div className="flex items-center">
                            <span className="_drawal-title text-base robotomedium text-black-100 capitalize">
                                {`${t('request_withdraw.list')}`}
                            </span>
                            <span className="_drawal-quantity drawal-quantity px-2 robotomedium text-[10px] rounded-2xl ml-2">
                                {staticData.current.total}
                            </span>
                        </div>
                        <CreateWithdrawPaymentModal
                            t={t}
                            historyPush={historyPush}
                            getWithdrawals={getWithdrawals}
                            getLisWithdrawalStatus={getLisWithdrawalStatus}
                            getListWithDrawalSlipStatistic={getListWithDrawalSlipStatistic}
                            listBank={listBank}
                        />
                    </div>
                    {isArray(listRequestWithDrawal) && listRequestWithDrawal.length > 0 ? (
                        <Skeleton
                            loading={loading}
                            active
                        >
                            <div>
                                <Table
                                    className={'request-draw-table'}
                                    dataSource={listRequestWithDrawal as DataType[]}
                                    columns={columns}
                                    bordered={false}
                                    scroll={{ x: 'max-content' }}
                                    rowClassName={(record: any, index: number): string =>
                                        `_row-order cursor-pointer ${index % 2 !== 0 ? 'tr-even row_bg' : ''}`
                                    }
                                    pagination={false}
                                />
                                <div className="flex justify-end mt-3 text-center screen-sm:justify-center space-x-4">
                                    <Pagination
                                        className={'request-drawal-pagination pagination-custom-v2'}
                                        {...staticData.current}
                                        defaultCurrent={6}
                                        total={staticData.current.total || 0}
                                        onChange={(page, pageSize) => {
                                            window.scrollTo(0, 0)
                                            onChangePage(page, pageSize)
                                        }}
                                    />
                                </div>
                            </div>
                        </Skeleton>
                    ) : (
                        <div className="pdt50 pdbt60 bg-color-white">
                            <Empty
                                image={require('../../resources/images/empty-image.png')}
                                description={<span>{t('message.empty') as string}</span>}
                            />
                        </div>
                    )}
                </div>
            </div>
            {openModalLog && (
                <ModalLog
                    t={t}
                    open={openModalLog}
                    formatLogsContent={formatLogsContent}
                    onCancel={handleModalLog}
                    logs={logs}
                />
            )}
        </MainLayout>
    )
}

export default withRouter(withNamespaces()(RequestWithDrawal))
