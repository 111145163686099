import PolimClient from "../utils/api/PolimClient";
import {LocalStore} from "../utils/LocalStore";
export default class NotificationServices {

    /**
     * lấy ra danh sách notification
     * @param query
     * @param cb
     */
    static getNotifications(query, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_KUN_LUN}/notification-service/customer/all${query || ''}`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }

    // /**
    //  * lấy ra danh sách event
    //  * @param cb
    //  */
    // static getNotificationEvents(cb) {
    //     PolimClient.request({
    //         endpoint: `/events`,
    //         method: 'GET',
    //     }, cb)
    // }
    //
    // /**
    //  * lấy ra danh sách nhóm event
    //  * @param cb
    //  */
    // static getNotificationEventGroups(cb) {
    //     PolimClient.request({
    //         endpoint: `/event_groups`,
    //         method: 'GET',
    //     }, cb)
    // }

    // /**
    //  * lấy ra số lượng noti chưa đọc
    //  * @param cb
    //  */
    // static getQuantityNotificationsUnread(cb) {
    //     PolimClient.request({
    //         endpoint: `/customer/profile/notifications/statistics`,
    //         method: 'GET',
    //     }, cb)
    // }


    static getQuantityNotificationsUnread(cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_KUN_LUN}/notification-service/customer/unread_count`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }

    /**
     * chuyển notification sang trạng thái đã đọc
     * @param id
     * @param cb
     */
    static markNotificationRead (id, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_KUN_LUN}/notification-service/customer/mark_as_read`,
            method: 'PATCH',
            headers: {
                'X-Tenant': currentProjectInfo.code
            },
            body: {id}
        }, cb)
    }

    /**
     * chuyển tất cả noti sang trạng thái dã đọc
     * @param cb
     */
    static markAllNotificationsRead (cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_KUN_LUN}/notification-service/customer/mark_as_read_all`,
            method: 'PATCH',
            headers: {
                'X-Tenant': currentProjectInfo.code
            },
        }, cb)
    }

    /**
     * lấy về cấu hình noti
     * @param cb
     */
    static getNotificationSettings (cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_KUN_LUN}/notification-service/customer/notification_settings?offset=0&limit=10000`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }

    /**
     * lấy về event setting notification
     * @param cb
     */
    static getEventSettings (cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_KUN_LUN}/notification-service/categories/event_settings`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }

    /**
     * lấy về cấu hình noti
     * @param body
     * @param cb
     */
    static addNotificationSettings (body, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_KUN_LUN}/notification-service/customer/notification_settings`,
            method: 'POST',
            headers: {
                'X-Tenant': currentProjectInfo.code
            },
            body
        }, cb)
    }

    /**
     * cập nhập cấu hình noti
     * @param body
     * @param cb
     */
    static updateNotificationSettings (body, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_KUN_LUN}/notification-service/customer/notification_settings/batch`,
            method: 'POST',
            headers: {
                'X-Tenant': currentProjectInfo.code
            },
            body
        }, cb)
    }

    /**
     * lấy về cấu hình noti
     * @param cb
     */
    static getNotificationChannel (cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_KUN_LUN}/notification-service/categories/channels`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }

    /**
     * lấy về cấu hình noti
     * @param cb
     */
    static getNotificationEvents (cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_KUN_LUN}/notification-service/categories/events`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }

    /**
     * lấy về cấu hình noti
     * @param cb
     */
    static getNotificationEventGroups (cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_KUN_LUN}/notification-service/categories/event_groups`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }

    /**
     * kết nối telegram
     * @param cb
     */
    static connectTelegram (telegramUsername, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_KUN_LUN}/subscribe/customer/telegram/generate_deep_link`,
            method: 'POST',
            headers: {
                'X-Tenant': currentProjectInfo.code
            },
            body:{ telegramUsername:telegramUsername }
        }, cb)
    }
}
