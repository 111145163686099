import React from "react";
import {Row, Col, Table, Spin} from 'antd'
import * as AntdIcon from "@ant-design/icons"

import {Link} from 'react-router-dom'
import moment from 'moment'
import {LocalStore} from "../../utils/LocalStore";

import {moneyCeil, moneyFormat, quantityFormat} from "../../utils/stringUtils";
import _ from "lodash";

export default class TabClaims extends React.Component{
    constructor(props) {
        super(props);
        this.isUnmounted = false;
        this.state = {}
    }

    componentDidMount() {
        if (typeof LocalStore.getInstance().read('currentProjectInfo') === 'object') {
            this.setState({
                currentProjectInfo: LocalStore.getInstance().read('currentProjectInfo') || {}
            });
        }
    }

    renderEmpty = () => {
        const {orderDetail = {}, t} = this.props;
        const href = `/tickets/create?orderCode=${orderDetail.code}&isShipment=true`;

        return <Row>
            <Col span={10} className="pdl0">
                <img width={'100%'} src={require('../../resources/images/empty-image-claim.png')} alt=""/>
            </Col>
            <Col span={14} className="pdr10">
                <div className="dpl-flex align-items-center">
                    <Link to={href} className="pdl20 pdr20 border-radius4 btn--blue _btn-create-claim-empty ant-btn-primary">
                        {t('tickets.create')}
                    </Link>
                </div>
            </Col>
        </Row>
    };

    render () {
        const {
            isLoadingListTickets,
            listTickets = [],
            orderDetail = {},
            t
        } = this.props;

        const hrefCreate = `/tickets/create?orderCode=${orderDetail.code}&isShipment=true`;

        const columns = [
            {
                title: t('complaint_tab.complaint_code'), dataIndex: 'code', key: 'code',
                render: (txt, record) => {
                    const href = `/tickets/${record.code}`;
                    return <Link to={href} className="_item-code cursor-pointer txt-color-blue">#{txt}</Link>
                }
            },
            {
                title: t('complaint_tab.complaint_name'), dataIndex: 'name', key: 'name',
                render: (txt, record) => {
                    const href = `/tickets/${record.code}`;
                    return <Link to={href} className="_item-name cursor-pointer txt-color-blue">{txt}</Link>
                }
            },
            {
                title: t('complaint_tab.time'), dataIndex: 'createdAt', key: 'createdAt',
                render: (txt) => {
                    return <span className={'_createdAt'}>{moment(txt).format('HH:mm DD/MM')}</span>
                }
            },
            {
                title: t('complaint_tab.status'),
                dataIndex: 'state',
                key: 'state',
                render: (txt, record) => {
                    // const itemStatus = listStatusesTicket.find(x => x.code === txt) || {};
                    if (!record.publicStateNewView) return <span />;
                    return <span className={'_status dpl-flex align-items-center'}><span className="status-dot" style={{backgroundColor: record.publicStateNewView.color || '#FFC107'}}/> {record.publicStateNewView.name}{record.archived && ' (Đã đóng)'}</span>
                }
            },
            {
                title: t('complaint_tab.refund'),
                dataIndex: 'totalRefund',
                key: 'totalRefund',
                render: (text) => <span className="_totalRefund txt-color-green bold break-word">{moneyFormat(moneyCeil(text))}</span>,
            },
            {
                title: '', dataIndex: 'action',
                key: 'action',
                className: 'hidden-md',
                render: (txt, record) => {
                    const href = `/tickets/${record.code}`;
                    return <Link to={href} className="_view-detail cursor-pointer txt-color-blue">{t('complaint_tab.detail')}</Link>
                }
            }
        ];

        return(
            <div className={'mgbt30 pd10'}>
                <Spin spinning={isLoadingListTickets === true} indicator={<AntdIcon.Loading3QuartersOutlined type="loading" style={{ fontSize: 24 }} spin />} >
                    {
                        listTickets.length > 0
                            ?
                            <React.Fragment>
                                <div className="dpl-flex justify-content-between align-items-center bg-color-white pdbt15 border-bottom-1x bd-color-gray">
                                    {
                                        listTickets.length > 0
                                            ?
                                            <span className={'txt-color-black txt-size-h5 robotobmedium'}>{t('ticket_add.list_claims')} (<span className={'_total-item-in-page'}>{quantityFormat(listTickets.length)}</span>)</span>
                                            :
                                            <span className={'txt-color-black txt-size-h5 robotobmedium'}>{t('ticket_add.list_claims')}</span>
                                    }
                                    <Link
                                        to={hrefCreate}
                                        className={'_btn-export-csv ant-btn cursor-pointer pdl15 pdr15 ant-btn-primary ant-btn-sm ant-btn-background-ghost'}
                                    >
                                        {t('complaint_tab.create_complaint')}
                                    </Link>
                                </div>
                                <Table
                                    rowKey={'code'}
                                    className={'custom-empty'}
                                    columns={columns}
                                    dataSource={_.sortBy(listTickets, 'createdAt').reverse()}
                                    pagination={{
                                        hideOnSinglePage: true,
                                        pageSize: 10,
                                    }}
                                />
                            </React.Fragment>
                            :
                        this.renderEmpty()
                    }
                </Spin>
            </div>
        );
    }
}
