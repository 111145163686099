import React from 'react'
import DocumentTitle from "react-document-title"
import {Link} from 'react-router-dom'
import {LocalStore} from "../../utils/LocalStore";
import {Input} from 'antd'
import userService from "../../services/user.service";
import {validateEmail} from "../../utils/validate";
import {withNamespaces} from "react-i18next";
import Language from "../../components/Language";
import * as AntdIcon from "@ant-design/icons"

class ForgotPassword extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            email: '',
        }
    }

    /**
     * submit
     */
    onSubmit = () => {
        if (this.onValidateEmailInput()) return;

        const {email, onSubmit} = this.state;

        this.setState({onSubmit: true}, () => {
            if (onSubmit) return;

            userService.forgotPassword({email},(err, res, headers) => {
                this.setState({onSubmit: false, success: true})
            })
        })
    };

    /**
     * sự kiện thay đổi giá trị của input
     * @param value
     * @param props
     */
    onChangeInput = (value, props) => {
        this.setState({[props]: value})
    };

    /**
     * sự kiện validate của input fullName
     */
    onValidateEmailInput = () => {
        const {email} = this.state;
        const {t} = this.props;
        if (!email) {
            this.setState({
                emailError: t('forgot_pass.email_require')
            });
            return true
        } else {
            if (validateEmail(email)) {
                this.setState({
                    emailError: t('forgot_pass.email_invalid')
                });
                return true
            } else {
                this.setState({
                    emailError: ""
                })
            }
        }
        return false
    };

    render() {
        const {email, emailError, onSubmit, success} = this.state;
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        const {t} = this.props;
        return(
            <DocumentTitle title={`${t('forgot_pass.title')} | ${currentProjectInfo ? currentProjectInfo.name : ''}`}>
                <div className="scroll-wrapper">
                    <div className="wrapper login-wrapper width100 dpl-flex min-width-page position-re">
                        {
                            success
                            ?
                            <div className="boxform bg-color-white">
                                <h1 className="txt-center mgbt20">
                                    <img src={require('../../resources/images/img-success.png')} alt=''/>
                                </h1>
                                <h2 className="txt-size-h3 robotobold txt-color-black txt-center">
                                    {t('forgot_pass.success')}
                                </h2>
                                <span className="txt-size-h7 txt-color-black robotoregular dpl-block mgt20 txt-center line-height134">
                                    {t('forgot_pass.check_email')}
                                </span>
                                <div className="boxform__content mgt25">
                                    <div className="txt-center mgt30 mgbt20">
                                        <Link to={'/login'} className="btn--blue dpl-il-block">{t('login.title')}</Link>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="boxform bg-color-white">
                                <h1 className="txt-center mgbt20">
                                    <img className={'login-logo-image mx-auto'} src={currentProjectInfo && currentProjectInfo.tenantConfig ? currentProjectInfo.tenantConfig.logoStandard : ''} alt='' />
                                </h1>
                                <h2 className="txt-size-h3 robotobold txt-color-black txt-center">
                                    {t('forgot_pass.title')}
                                </h2>
                                <span className="txt-size-h7 txt-color-black robotoregular dpl-block mgt20">
                                    {t('forgot_pass.email')}
                                </span>
                                <div className="boxform__content mgt25">
                                    <ul className="pdl0">
                                        <li className={`position-re mgbt30 ${emailError && 'errorip'}`}>
                                            <Input
                                                type="email"
                                                name="email"
                                                placeholder={t('forgot_pass.email_place_holder')}
                                                value={email}
                                                onChange={(e) => this.onChangeInput(e.target.value, 'email')}
                                                onBlur={this.onValidateEmailInput}
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 13) {
                                                        this.onSubmit()
                                                    }
                                                }}
                                            />
                                            <span className="iconip position-ab txt-size-h5">
                                                <AntdIcon.MailOutlined type="mail"/>
                                            </span>
                                            {
                                                emailError && <span className="texterror position-ab txt-size-h8 txt-color-red2 robotoregular">
                                                    {emailError}
                                                </span>
                                            }
                                        </li>
                                    </ul>
                                    <div className="txt-center mgt30 mgbt20">
                                        <span onClick={this.onSubmit} className="_btn-submit btn--blue cursor-pointer dpl-il-block">
                                            <span className="position-re">
                                                {onSubmit && <AntdIcon.Loading3QuartersOutlined type="loading-3-quarters" spin={true} className="position-ab" style={{left: -25, top: 1}}/>}
                                                {t('forgot_pass.get_pass')}
                                            </span>
                                        </span>
                                        <Link to={'/login'} className="txt-color-blue txt-size-h8 robotoregular dpl-block mgt15">{t('forgot_pass.back')}</Link>
                                    </div>
                                </div>
                            </div>
                        }
                        <div
                            className="position-ab"
                            style={{top: 20, right: 20}}
                        >
                            <Language {...this.props}/>
                        </div>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default withNamespaces() (ForgotPassword);
