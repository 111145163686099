import React from 'react'
import MainLayout from '../../components/Layout/MainLayout'
import {
    Row, Col, Button, notification, Input, Modal
} from 'antd';

import ProfileSidebar from '../../components/Profile/Leftbar'
import FormUpdatePIN from "../../components/Profile/FormUpdatePIN";
import FormUpdatePassword from "../../components/Profile/FormUpdatePassword";
import FormUpdateProfile from "../../components/Profile/FormUpdateProfile";
import UserService from "../../services/user.service";
import { LocalStore } from "../../utils/LocalStore";
import { withNamespaces } from 'react-i18next';
import lodash from "lodash";
import VipLevelBox from "../../components/Profile/VipLevelBox";
import RegisterConnectionFormModal from "../../components/Profile/RegisterConnectionFormModal";


let currentLoggedUser = null;
class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isUpdatePIN: false,
            isUpdatePassword: false,
            user_profile: [],
            isShowEdit: false,
            isEditFullName: false,
            isEditAddress: false,
            isEditDob: false,
            isEditPhone: false,
            showRegisterConnection: false

        }
    }

    componentWillMount() {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null;

        if (typeof LocalStore.getInstance().read('currentLoggedUser') === 'object') {
            currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {};
            this.setState({ user_profile: currentLoggedUser });
        }
        if (typeof LocalStore.getInstance().read('currentProjectInfo') === 'object') {
            this.setState({
                currentProjectInfo: LocalStore.getInstance().read('currentProjectInfo') || {}
            });
        }
    }


    /**
     * Toggle cập nhật mã PIN
     */
    togglePIN = () => {
        this.setState({ isUpdatePIN: !this.state.isUpdatePIN });
    };

    /**
     * Toggle cập nhật mật khẩu
     */
    togglePassword = () => {
        this.setState({ isUpdatePassword: !this.state.isUpdatePassword });
    };

    /**
     * call api update mã PIN
     * @param data
     */
    handleUpdate = (data) => {
        const { t } = this.props;
        const { isLoadingSubmit } = this.state;
        this.setState({ showProgressBar: true, isLoadingSubmit: true }, () => {
            if (isLoadingSubmit !== true) {
                UserService.updatePIN(data, (err, res) => {
                    if (!err) {
                        notification['success']({
                            message: t('message.success'),
                            className: '_toastItem'
                        });
                        this.setState({ errUpdate: false });
                        this.togglePIN();
                    } else {
                        // console.log("handleUpdate err", err);
                        // notification['error']({
                        //     message: err.title === 'invalid_pin' ? 'Mã PIN cũ không đúng' : err.title === 'duplicate_pin' ? 'Mã PIN mới trùng mã PIN cũ' : err.message
                        // });
                        if (err.code !== "Network fail") {
                            if (err.code !== 500) {
                                this.setState({ errUpdate: true }, () => {
                                    setTimeout(() => {
                                        this.setState({ errUpdate: false });
                                    }, 500);
                                });
                            } else {
                                notification.error({
                                    message: "Vui lòng kiểm tra lại kết nối Internet",
                                    className: '_toastItem'
                                })
                            }
                        }
                    }
                    this.setState({ showProgressBar: false, isLoadingSubmit: false });
                });
            }
        });
    };

    /**
     * call api update mật khẩu
     * @param data
     */
    handleUpdatePassword = (data) => {
        const { isLoadingSubmit } = this.state;
        const { t } = this.props;
        this.setState({ showProgressBar: true, isLoadingSubmit: true }, () => {
            if (isLoadingSubmit !== true) {
                UserService.updatePassword(data, (err, res) => {
                    if (!err) {
                        notification['success']({
                            message: t('message.success'),
                        });
                        this.setState({ errUpdate: false });
                        this.togglePassword();
                    } else {
                        if (err.code !== "Network fail") {
                            if (err.code !== 500) {
                                this.setState({ errUpdate: true, errPassword: err }, () => {
                                    setTimeout(() => {
                                        this.setState({ errUpdate: false, errPassword: err });
                                    }, 500);
                                });
                            } else {
                                notification.error({
                                    message: "Vui lòng kiểm tra lại kết nối Internet"
                                })
                            }
                        }
                    }
                    this.setState({ showProgressBar: false, isLoadingSubmit: false });
                });
            }
        });
    };

    /**
     * call api update mật khẩu
     */
    recoverPin = (password) => {
        const { isLoadingSubmit } = this.state;
        const { t } = this.props;

        if (!password) {
            this.setState({
                passwordResetPinError: t('login.password_error')
            });
            return;
        }

        let body = {
            password: password,
        };

        this.setState({ showProgressBar: true, isLoadingSubmit: true }, () => {
            if (isLoadingSubmit !== true) {
                UserService.recoverPin(body, (err, res) => {
                    if (!err) {
                        this.setState({
                            passwordResetPinError: '',
                            passwordResetPin: '',
                            showModalConfirmResetPin: false
                        });
                        notification['success']({
                            message: t('forgot_pin.check_email'),
                        });
                    } else {
                        if (err.title === "invalid_password") {
                            this.setState({
                                passwordResetPinError: t('login.error_password')
                            });
                        } else if (err.statusCode !== 'Network fail') notification['error']({
                            message: t(err.message),
                        });
                    }
                    this.setState({ showProgressBar: false, isLoadingSubmit: false });
                });
            }
        });
    };

    onKeyDown = (e, prop) => {
        if (e.target.value !== '' && e.target.value.trim() === '') return;

        let payload = {};
        payload[prop] = e.target.value.trim();

        if (e.keyCode === 13) {
            this.setState({ isLoadingSubmit: true }, () => {
                this.onSubmitUserProfile(payload);
            })
        }

    };

    onSubmitUserProfile = (payload) => {
        const { t } = this.props;
        this.setState({ isLoadingSubmit: true }, () => {
            UserService.updateProfile(payload, (err, res) => {
                if (!err) {
                    notification['success']({
                        message: t('message.success'),
                    });
                    LocalStore.getInstance().save('currentLoggedUser', res);
                    this.setState({ errUpdate: false, isLoadingSubmit: false, user_profile: res });
                } else {
                    if (err.code !== "Network fail") {
                        if (err.code !== 500) {
                            this.setState({ errUpdate: true }, () => {
                                setTimeout(() => {
                                    this.setState({ errUpdate: false });
                                }, 500);
                            });
                        } else {
                            notification.error({
                                message: "Vui lòng kiểm tra lại kết nối Internet"
                            })
                        }
                    }
                }
                this.setState({ showProgressBar: false, isLoadingSubmit: false });
            })
        })
    };
    onRegisterConnection = (data) => {
        delete data.agreement;
        data.nickname = lodash.get(this.state.user_profile, 'nickname', '');
        const { t } = this.props;
        this.setState({ isRegisterLoading: true }, () => {
            UserService.registerConnection(data, (err, res) => {
                if (!err) {
                    UserService.getToken((err, res) => {
                        if (!err) {
                            notification['success']({
                                message: t('message.register_connection_success'),
                            });
                            this.setState({ showRegisterConnection: false, isRegisterLoading: false });
                            window.open(`${process.env.REACT_APP_M24}?token=${res.token}&domain=${this.state.currentProjectInfo.domain}&username=${res.customer.username}&tenant=${this.state.currentProjectInfo.code}`, '_blank');
                        } else {
                            if (err.code === 403) {
                                notification['error']({
                                    message: t('error.error403'),
                                });
                            } else
                                notification['error']({
                                    message: t(`message.${err.title}`),
                                });
                            this.setState({ isRegisterLoading: false });
                        }
                    })
                } else {
                    notification['error']({
                        message: t(`message.${err.title}`),
                    });
                    this.setState({ isRegisterLoading: false });
                }
            })
        })
    }
    connectM24 = () => {
        const { t } = this.props;
        this.setState({
            connecting: true
        }, () => {
            UserService.getToken((err, res) => {
                if (!err) {
                    this.setState({ connecting: false });
                    window.open(`${process.env.REACT_APP_M24}?token=${res.token}&domain=${this.state.currentProjectInfo.domain}&username=${res.customer.username}&tenant=${this.state.currentProjectInfo.code}`, '_blank');
                } else {
                    if (err.code === 403) {
                        notification['error']({
                            message: t('error.error403'),
                        });
                    } else
                        notification['error']({
                            message: t(`message.${err.title}`),
                        });
                    this.setState({ connecting: false });
                }
            })
        });
    }
    renderModalConfirmResetPin = () => {
        const { showModalConfirmResetPin, passwordResetPin, orderLoading, passwordResetPinError } = this.state;
        const { t } = this.props;

        return <Modal
            title={t('login.password')}
            visible={showModalConfirmResetPin}
            onOk={() => this.recoverPin(passwordResetPin)}
            confirmLoading={orderLoading}
            onCancel={() => this.setState({
                showModalConfirmResetPin: false,
                passwordResetPin: '',
            })}
            okText={t('cartCheckout.confirm')}
            cancelText={t('cartCheckout.cancel')}
            okButtonProps={{ className: "_btn-submit-confirm" }}
            cancelButtonProps={{ className: "_btn-cancel-confirm" }}
        >
            <p className="mgbt5">{t('login.password')}</p>
            <Input.Password
                className={'_input-password'}
                placeholder={t('login.password')}
                autoFocus={true}
                value={passwordResetPin}
                onChange={(e) => this.setState({ passwordResetPin: e.target.value })}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        this.recoverPin(passwordResetPin)
                    }
                }}
            />

            {
                passwordResetPinError && <p className="_txt-error-confirm mgt5 mgbt0 txt-error ">{passwordResetPinError}</p>
            }
        </Modal>
    };

    render() {
        const { isUpdatePIN, errUpdate, isRegisterLoading, isUpdatePassword, errPassword, currentProjectInfo, showRegisterConnection, user_profile } = this.state;
        let tenant = lodash.get(currentProjectInfo, 'tenantConfig.externalIntegrationConfig', null);
        let openAPI = lodash.get(currentLoggedUser, 'customerAuthorities.openAPI', false);

        const { t } = this.props;
        return <MainLayout
            {...this.props}
            showProgressBar={this.state.showProgressBar}
            headerTitle={t('customer_info.personal_info')}
            title={t('customer_info.personal_info')}
        >
            <div className={'container-common pdbt30 pdt20 mgr20'}>
                <Row>
                    <Col span={6} className={'pdr10'}>
                        <ProfileSidebar currentRoute={'profile'} />
                    </Col>
                    <Col span={18} className={'pdl10'}>
                        {currentProjectInfo && currentProjectInfo.tenantConfig.generalConfig && currentProjectInfo.tenantConfig.generalConfig.enableRewardPoint && currentProjectInfo.tenantConfig.generalConfig.enableCustomerLevel ?
                            <VipLevelBox /> : null
                        }
                        <div className="bg-color-white pd20">
                            {
                                isUpdatePIN === true || isUpdatePassword ? (isUpdatePIN ? <FormUpdatePIN
                                    togglePIN={this.togglePIN}
                                    onSubmit={this.handleUpdate}
                                    errUpdate={errUpdate || false}
                                /> : <FormUpdatePassword
                                    togglePassword={this.togglePassword}
                                    onSubmit={this.handleUpdatePassword}
                                    errUpdate={errUpdate || false}
                                    errPassword={errPassword || {}}
                                />) : <FormUpdateProfile
                                    ref={ref => this.FormUpdateProfile = ref}
                                    togglePIN={this.togglePIN}
                                    toggleRecoverPin={() => this.setState({
                                        passwordResetPinError: '',
                                        passwordResetPin: '',
                                        showModalConfirmResetPin: true
                                    })}
                                    togglePassword={this.togglePassword}
                                />
                            }
                        </div>
                        {openAPI && tenant && tenant.enable && tenant.wasp.enable && <div className="bg-color-white pd20 mgt20">
                            <Row><span className={'robotobold txt-size-h7'}>{t('customer_info.connectM24')}</span></Row>
                            <Row className={'align-items-center mgt20'}>
                                <Button loading={this.state.connecting} onClick={this.connectM24}
                                    icon={<i className="mgr5 fas fa-link"></i>}
                                    type={'primary'}>{t('customer_info.connect')}</Button>
                                <span className={'txt-size-h7 mgl15 mgr15'}>{t('customer_info.connectionDes')}</span>
                                <Button onClick={() => this.setState({ showRegisterConnection: true })}
                                    icon={<i className="mgr5 fas fa-plus"></i>} ghost
                                    type={'primary'}>{t('customer_info.register')}</Button>
                            </Row>
                        </div>
                        }
                    </Col>
                    {showRegisterConnection && <RegisterConnectionFormModal
                        username={lodash.get(user_profile, 'username', '')}
                        email={lodash.get(user_profile, 'email', '')}
                        fullname={lodash.get(user_profile, 'fullname', '')}
                        onCancel={() => this.setState({ showRegisterConnection: false })}
                        t={t}
                        loading={isRegisterLoading}
                        onCreate={this.onRegisterConnection}
                        visible={showRegisterConnection} />}
                </Row>
                {this.renderModalConfirmResetPin()}
            </div>
        </MainLayout>;
    }
}
export default withNamespaces()(Profile);
