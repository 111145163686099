import {Button, Modal, Row, Col, Alert, message} from 'antd';
import lodash from "lodash";
import Clipboard from "../Clipboard";
import {CopyOutlined} from "@ant-design/icons";

const ModalCreateConnectApplicationSuccess = ({visible, onCancel, t, data}) => {
    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            width={800}
            className={'_modal-create'}
            title={<span style={{color: '#149851'}}>{t('message.create_connect_application_success')}</span>}
            footer={[
                <Button type={'primary'} className={'buttonm _bt-confirm'} key="back" onClick={onCancel}>
                    <span className="mgr10"><i className="fal fa-check"></i></span>{t('modal.confirm')}
                </Button>
            ]}
        >
            <div>
                <Row className={'mgbt16'}>
                    <Col span={6}>
                        <span className={'txt-color-black2 txt-size-h7'}>{t('connect_application.id')}</span>
                    </Col>
                    <Col span={10}>
                        <span className={'txt-color-black robotomedium txt-size-h7 _id'}>{lodash.get(data, 'id', '')}</span>
                    </Col>
                    <Col span={6}>
                        <Clipboard
                            value={lodash.get(data, 'id', '')}></Clipboard>
                    </Col>
                </Row>
                {/*<Row className={'dpl-flex justify-content-end right24'}>*/}
                {/*    */}
                {/*</Row>*/}
                <Row className={'mgbt16'}>
                    <Col span={6}>
                        <span className={'txt-color-black2 txt-size-h7'}>SecretKey</span>
                    </Col>
                    <Col span={10}>
                        <span className={'txt-color-black robotomedium txt-size-h7 _secret'}>{lodash.get(data, 'secret', '')}
                        </span>
                    </Col>
                    <Col span={2}>
                        <Clipboard
                            value={lodash.get(data, 'secret', '')}></Clipboard>
                    </Col>
                    <Col span={6}>
                        <Button type={'primary'} className={'button _bt-copy-all'} ghost icon={<CopyOutlined/>} onClick={()=>{
                            const textField = document.createElement('textarea');
                            textField.innerHTML = `ID: ${lodash.get(data, 'id', '')}\r\nSecretKey: ${lodash.get(data, 'secret', '')}`;
                            document.body.appendChild(textField);
                            textField.select();
                            document.execCommand('copy');
                            textField.remove();
                            message.success('Copied');
                        }
                        }>
                            Sao chép tất cả
                        </Button>
                    </Col>
                </Row>

                <Row className={'mgbt16'}>
                    <Col span={6}>
                        <span className={'txt-color-black2 txt-size-h7'}>{t('connect_application.name')}</span>
                    </Col>
                    <Col span={10}>
                        <span className={'txt-color-black robotomedium txt-size-h7 _name'}>{lodash.get(data, 'name', '')}</span>
                    </Col>
                </Row>
                <Row className={'mgbt16'}>
                    <Col span={6}>
                        <span className={'txt-color-black2 txt-size-h7'}>{t('connect_application.desc')}</span>
                    </Col>
                    <Col span={10}>
                        <span className={'txt-color-black robotomedium txt-size-h7 _desc'}>{lodash.get(data, 'description', '')}</span>
                    </Col>
                </Row>
                <Row className={'mgt20 alert-custom'}>
                    <Alert
                        message="Lưu ý"
                        description={<Col>
                            <Row>
                                <span>- Thông tin chỉ hiển thị một lần duy nhất. Vui lòng lưu lại trước khi đóng tab</span>
                            </Row>
                            <Row>
                                <span> - Khoá này cực kỳ quan trọng, bạn không nên tuỳ tiện cung cấp cho người khác nếu chưa hiểu rõ!</span>
                            </Row>
                        </Col>}
                        type="error"
                    />
                </Row>
            </div>
        </Modal>
    );
};
export default ModalCreateConnectApplicationSuccess;
