const datePickerLocale = {

    lang: {

        placeholder: 'ជ្រើសរើសកាលបរិច្ឆេទ', //select date
        rangePlaceholder: ['កាលបរិច្ឆេទចាប់ផ្តើម', 'កាលបរិច្ឆេទបញ្ចប់\n'],

        today: 'Այսօր',

        now: 'Հիմա',

        backToToday: 'Վերադառնալ այսօր',

        ok: 'Օկ',

        clear: 'Մաքրել',

        month: 'Ամիս',

        year: 'Տարի',

        timeSelect: 'ընտրեք ժամը',

        dateSelect: 'ընտրեք ամսաթիվը',

        weekSelect: 'Ընտրեք շաբաթը',

        monthSelect: 'Ընտրեք ամիսը',

        yearSelect: 'Ընտրեք տարին',

        decadeSelect: 'Ընտրեք տասնամյակը',

        yearFormat: 'YYYY',

        dateFormat: 'DD/MM//YYYY',

        dayFormat: 'DD',

        dateTimeFormat: 'DD/MM//YYYY HH:mm:ss',

        monthBeforeYear: true,

        previousMonth: 'Անցած ամիս (PageUp)',

        nextMonth: 'Մյուս ամիս (PageDown)',

        previousYear: 'Անցած տարի (Control  left)',

        nextYear: 'Մյուս տարի (Control  right)',

        previousDecade: 'Անցած տասնամյակ',

        nextDecade: 'Մյուս տասնամյակ',

        previousCentury: 'Անցած դար',

        nextCentury: 'Մյուս դար',

    },

    timePickerLocale: {

        placeholder: 'Ընտրեք ժամը',

    },

};
// eslint-disable-next-line import/no-anonymous-default-export
export default {

    locale: 'km-KH',
    DatePicker: datePickerLocale,

    TimePicker: {

        placeholder: 'Ընտրեք ժամը',

    },

    // Calendar: datePickerLocale,

    global: {

        placeholder: 'Ընտրեք',

    },
    Empty:{
        description:'គ្មាន​ទិន្នន័យ'
    }
};