// components/NotFound.js
import React from 'react';
import {Link} from 'react-router-dom'
import lang from "../../resources/localization/Localization";
import image404 from '../../resources/images/img404.png'

// TODO: need remake up this page
export default class Page404 extends React.Component {
    render () {
        return <div className="wrapper wrapper404 position-re">
            <div className="wp404 position-fix txt-center dpl-flex justify-content align-items-center flex-direction-column" style={{bottom: 0, left: 0, right: 0, top: 0}}>
                <div className="wpimg mgbt20">
                    <img src={image404} alt="" />
                </div>
                <h3 className="txt-size-h4 txt-color-black2 utmhelve-regular line-height134 mgbt40">
                    {lang.error.not_found}
                </h3>
                <div className="wpbtn">
                    <Link to="/" className="btn btn--orange cursor-pointer">Quay lại trang chủ</Link>
                </div>
            </div>
        </div>
    }
}