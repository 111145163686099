import { useState } from 'react'
import { Button, notification } from 'antd'
import Modal from '../../../components/commons/Modal'
import BifinService from '../../../services/bifin.service'
import InputPassword from '../../../components/commons/InputPassword'
import { LocalStore } from '../../../utils/LocalStore'
import DepositModal from '../../../components/DepositModal'
import _ from 'lodash'

const ModalPaymentBifin = ({ t, code, refetchData = () => {}, totalNeedPay, isShipment, contractWithShopkeeper }) => {
    const [visible, setVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [isOpenModalDeposit, setIsOpenModalDeposit] = useState(false)

    const currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {}
    const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
    const depositWizard = _.get(currentProjectInfo, 'tenantConfig.generalConfig.depositWizard')

    const handleVisibleModal = () => {
        setVisible(!visible)
    }

    const orderPayPromise = () => {
        return new Promise((resolve, reject) => {
            BifinService.orderPay(code, { password }, (err, res) => {
                if (err) {
                    reject(err)
                } else {
                    resolve(res)
                }
            })
        })
    }

    const shipmentPayPromise = () => {
        return new Promise((resolve, reject) => {
            BifinService.shipmentsrPay(code, { password }, (err, res) => {
                if (err) {
                    reject(err)
                } else {
                    resolve(res)
                }
            })
        })
    }

    const onSubmit = () => {
        setIsLoading(true)
        const onSubmitService = () => (!isShipment ? orderPayPromise() : shipmentPayPromise())
        onSubmitService()
            .then(() => {
                setTimeout(() => {
                    notification['success']({
                        message: t('order_pay.pay_success'),
                    })
                    refetchData()
                }, 2000)
            })
            .catch(err => {
                if (err?.title === 'insufficient_balance') {
                    notification['error']({
                        message: (
                            <>
                                {t(`error.${err?.title}`)}{' '}
                                <span
                                    className={depositWizard ? `underline text-blue-primary cursor-pointer` : ''}
                                    onClick={() => {
                                        depositWizard && setIsOpenModalDeposit(true)
                                    }}
                                >
                                    {t('error.insufficient_balance_payment')}
                                </span>
                            </>
                        ),
                    })
                } else {
                    notification['error']({
                        message: t('order_pay.pay_fail'),
                    })
                }
            })
            .finally(() => {
                setTimeout(() => {
                    setVisible(false)
                    setIsLoading(false)
                }, 2000)
            })
    }

    return (
        <>
            <span
                onClick={() => handleVisibleModal()}
                className="robotoregular text-blue-primary hover:text-dodger-blue-500 underline cursor-pointer"
            >
                {t('order_pay.btn_pay')}
            </span>
            <Modal
                visible={visible}
                title={t('order_pay.payment')}
                onCancel={() => handleVisibleModal()}
                listAction={[
                    <Button
                        className="btn-cancel capitalize"
                        onClick={() => handleVisibleModal()}
                    >
                        {t('button.cancel')}
                    </Button>,
                    <Button
                        type="primary"
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={!password || !code}
                    >
                        {t('orderDetail.payment')}
                    </Button>,
                ]}
            >
                <div>
                    <div className="mb-4">
                        <span
                            className="robotoregular block"
                            dangerouslySetInnerHTML={{
                                __html: t('order_pay.label', {
                                    username: currentLoggedUser?.username,
                                    code: code,
                                    amount: totalNeedPay,
                                }),
                            }}
                        ></span>
                        {contractWithShopkeeper && <i className="text-grey-400 text-xs robotoregular">{t('order_pay.note')}</i>}
                    </div>
                    <InputPassword
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        title={t('connect_application.pin')}
                        isRequired
                    />
                    <span className="pl-4 mt-1 text-xs text-grey-400">{t('cartCheckout.default_pin')}</span>
                </div>
            </Modal>
            <DepositModal
                visible={isOpenModalDeposit}
                onClose={() => setIsOpenModalDeposit(false)}
                maskClosable={true}
            />
        </>
    )
}

export default ModalPaymentBifin
