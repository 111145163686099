/* eslint-disable array-callback-return */
import React from 'react'
import MainLayout from '../../components/Layout/MainLayout'
import Loading from '../../components/Loading'
import {Link} from 'react-router-dom'
import moment from 'moment'
import {
    Row, Col, Empty, Input, Select,
    Table, Tag, Button, notification, Modal, Typography
} from 'antd';
import * as AntdIcon from "@ant-design/icons"
import {LocalStore} from "../../utils/LocalStore";

import {getUrlQueryString} from "../../utils/helper";
import {
    hashCode, intToRGB,
    parseQueryStringToObject,
    quantityFormat,
    updateQueryStringParameter
} from "../../utils/stringUtils";
import UserService from "../../services/user.service";
import {withNamespaces} from "react-i18next";

let pageSize = 25;
class PackageDamage extends React.Component {
    constructor(props) {
        super(props);
        this.isUnmounted = false;
        this.state = {
            page: 1,
            pageSize: pageSize,
            isLoading: true,
            listPackageDamage: [],
            listChecked: {},
            startTime: undefined,
            endTime: undefined,
            querySearch: '',
            typeSearch: '',
        }
    }

    componentDidMount() {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null;
        // window.addEventListener('resize', this.windowResize);
        window.addEventListener("popstate", this.onPopState.bind(this));

        if (typeof LocalStore.getInstance().read('currentLoggedUser') === 'object') {
            this.setState({isLoading: true}, () => {
                this.loadFromQueryString();

                this.getPackageDamageStatusesM5();
                this.getPackageDamageStatusesM2();
            })
        }
    }

    /**
     * Khi unmount ra thì bỏ sự kiện popstate
     */
    componentWillUnmount() {
        this.isUnmounted = true;
        window.removeEventListener("popstate", this.onPopState.bind(this));
    }

    /**
     * On popstate
     */
    onPopState() {
        if (!this.isUnmounted) this.setState({isLoading: true}, ()=> {
            this.loadFromQueryString();
        });
    };

    /**
     * Load từ QueryString (dành cho lần load đầu tiên)
     */
    loadFromQueryString = () => {
        let queryString = getUrlQueryString();
        let queryObj = parseQueryStringToObject(queryString) || {};

        // console.log('queryObj', queryObj);
        // chuyển giá trị từ queryObj vào state;
        let arrayValue = [];
        //reset các param khi load từ url
        let listChecked = {};
        let querySearch = '';
        let orderCode = '';
        let productCode = '';
        let startTime = undefined;
        let endTime = undefined;
        for (let p in queryObj) {
            if (p === 'statusDamage') {
                // xử lý với obj list checked
                arrayValue = queryObj[p].split(',');
                // console.log('arrayValue', arrayValue)
                listChecked[p] = arrayValue;
            } else if (p === 'receivedTimeFrom') {
                // xử lý với với timestamp
                startTime = decodeURIComponent(queryObj['receivedTimeFrom']);
            } else if (p === 'receivedTimeTo') {
                // xử lý với với timestamp
                endTime = decodeURIComponent(queryObj['receivedTimeTo']);
            } else if (p === 'orderCode') {
                orderCode = decodeURIComponent(queryObj['orderCode'] || '')
            } else if (p === 'productCode') {
                productCode = decodeURIComponent(queryObj['productCode'] || '')
            } else {
                // xử lý với query
                querySearch = decodeURIComponent(queryObj['query'] || '')
            }
        }

        this.setState({
            querySearch,
            startTime,
            endTime,
            listChecked,
            orderCode,
            productCode,
            page: queryObj.page,
            pageSize: queryObj.size || pageSize,
        }, ()=> {
            this.loadData();
        });
    };

    /**
     * load data
     */
    loadData = (cb) => {
        const {t} = this.props;
        const {page, pageSize} = this.state;
        let queryString = this.buildQueryString();
        if (queryString) {
            queryString = queryString + `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        } else {
            queryString = `?page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        }
        this.setState({showProgressBar: true}, () => {
            UserService.getPackageDamage(queryString, (err, res, headers) => {
                if (!err) {
                    this.setState({
                        listPackageDamage: res,
                        metadata: {
                            pageCount: headers['x-page-count'],
                            page: headers['x-page-number'],
                            size: headers['x-page-size'],
                            total: headers['x-total-count'],
                        }
                    }, () => {
                        //window.scrollTo(0, 0);
                        if (this.mainLayoutElement && this.mainLayoutElement.scrollLayoutElement) this.mainLayoutElement.scrollLayoutElement.scrollTop = 0;
                    });
                } else {
                    notification['error']({
                        message: t(err.message) || t('message.unconnected_error'),
                    });
                }
                if(cb) cb();
                this.setState({showProgressBar: false, isLoading: false})
            })
        })
    };

    /**
     * lấy ra danh sách log
     */
    getLog = (code) => {
        const {t} = this.props;
        this.setState({isLoadLog: true}, () => {
            UserService.getPackageDamageLogs(code, (err, res, headers) => {
                if (err === null) {
                    this.setState({
                        listLogs: res || [],
                        isLoadLog: false,
                    });
                } else {
                    this.setState({
                        isLoadLog: false,
                    });
                    notification['error']({
                        message: t(err.message) || t('message.unconnected_error'),
                    });
                }
            });
        })
    };

    onPageChanged = (page) => {
        this.setState({page: page}, () => {
            let queryString = getUrlQueryString();
            queryString = updateQueryStringParameter(queryString ? '?'+ queryString : '', 'page', page);
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: queryString
            });
            // window.scrollTo(0, 0);
        });
    };

    /**
     * lấy ra danh sách trạng thái của package
     */
    getPackageDamageStatusesM5 = () => {
        UserService.getPackageDamageStatus('m5', (err, res, headers) => {
            if (err === null) {
                this.setState({
                    listPackageDamageStatusesM5: res.status_damages || [],
                });
            }
        });
    };

    /**
     * lấy ra danh sách trạng thái của package
     */
    getPackageDamageStatusesM2 = () => {
        UserService.getPackageDamageStatus('m2', (err, res, headers) => {
            if (err === null) {
                this.setState({
                    listPackageDamageStatusesM2: res.status_damages || [],
                });
            }
        });
    };

    /**
     * lấy ra danh sách trạng thái của package
     */
    editPackageDamage = () => {
        const {editItem = {}, isSubmit} = this.state;
        const {t} = this.props;
        let body = {
            packageDamage: editItem.packageCode,
            statusDamage: editItem.statusDamage,
            note: editItem.note
        };

        if (!body.statusDamage) {
            this.setState({
                statusDamageError: t('message.warning_empty')
            })
            return
        }

        this.setState({
            isSubmit: true
        }, () => {
            if (isSubmit) return;
            UserService.updatePackageDamage(body, (err, res, headers) => {
                if (err === null) {
                    notification['success']({
                        message: t('message.success'),
                        className: '_toastItem'
                    });
                    this.loadData();
                    this.setState({
                        isSubmit: false,
                        isShowModalEdit: false
                    })
                } else {
                    if (err.title === "action_do_not_allow") {
                        notification['error']({
                            message: t("package_damage.action_do_not_allow"),
                        });
                    } else {
                        notification['error']({
                            message: t(err.message),
                        });
                    }
                    this.setState({
                        isSubmit: false
                    })
                }

            });
        });
    };

    /**
     * lấy ra prop giá trị của obj prop
     * @param props
     * @returns {*}
     */
    getValuePropOfProp = (props) => {
        switch (props) {
            case 'statusDamage':
                return 'code';
            default:
                return 'id'
        }
    };

    /**
     * xây dựng query string theo các giá trị trong state
     * @returns {string}
     */
    buildQueryString = () => {
        let {listChecked, startTime, endTime, querySearch, orderCode, productCode} = this.state;
        let queryString = '';
        for (let p in listChecked) {
            let value = '';
            if (Array.isArray(listChecked[p]) && listChecked[p].length > 0) {
                listChecked[p].map(x => {
                    value = value + x + ',';
                });

                value = value.slice(0, -1)
            }
            if (value) queryString = updateQueryStringParameter(queryString, p, value)
        }

        if (startTime) {
            queryString = updateQueryStringParameter(queryString, 'receivedTimeFrom', startTime)
        }

        if (endTime) {
            queryString = updateQueryStringParameter(queryString, 'receivedTimeTo', endTime)
        }

        if (orderCode) {
            queryString = updateQueryStringParameter(queryString, 'orderCode', orderCode.trim())
        }

        if (productCode) {
            queryString = updateQueryStringParameter(queryString, 'productCode', productCode.trim())
        }

        if (querySearch) {
            queryString = updateQueryStringParameter(queryString, 'query', querySearch.trim())
        }

        return queryString
    };

    onClearSearch = () => {
        this.setState({
            page: 1,
            querySearch: '',
            orderCode: '',
            productCode: '',
            startTime: undefined,
            endTime: undefined,
            listChecked: {},
        }, () => {
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: this.buildQueryString()
            });
        })
    };

    /**
     * xử lý với dữ liệu search trong state
     */
    onSubmitSearch = () => {
        this.setState({page: 1}, () => {
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: this.buildQueryString()
            });
        })
    };

    disabledEndDate = (endValue) => {
        const startValue = this.state.startValue;
        if (!endValue || !startValue) {
            return false;
        }
        return endValue.valueOf() <= startValue.valueOf();
    };

    onCheckBoxCheck = (item, prop) => {
        let {listChecked} = this.state;

        let arrayPropChecked = listChecked[prop] || [];
        if (arrayPropChecked.find(x => x === item[this.getValuePropOfProp(prop)])) {
            arrayPropChecked = arrayPropChecked.filter(x => x !== item[this.getValuePropOfProp(prop)]);
        } else {
            arrayPropChecked.push(item[this.getValuePropOfProp(prop)]);
        }

        listChecked[prop] = arrayPropChecked;

        this.setState({
            listChecked
        })
    };

    handlePreview = (item) => {
        this.setState({
            previewItem: item,
            previewVisible: true,
        });
    };

    parseLogs = (prop, item) => {
        const {t} = this.props;
        const {listPackageDamageStatusesM5 = []} = this.state;
        let field = "";
        let newValue = "";
        let oldValue = "";
        switch (prop) {
            case 'status_damaged':
                let newStatus = listPackageDamageStatusesM5.find(x => x.code === item.payload.change_fields.status_damaged.new) || {};
                let oldStatus = listPackageDamageStatusesM5.find(x => x.code === item.payload.change_fields.status_damaged.old) || {};
                field = t('package_damage.statusDamage')
                newValue = newStatus.name || '---'
                oldValue = oldStatus.name || '---'
                break;
            case 'note_status':
                field = t('package_damage.note')
                newValue = item.payload.change_fields.note_status.new || '---'
                oldValue = item.payload.change_fields.note_status.old || '---'
                break;
            default:
                field = prop
                newValue = '---'
                oldValue = '---'
        }

        return t('package_damage.log_content', {field, newValue, oldValue})
    }

    renderLog = (item) => {
        let array = [];
        for (let prop in item.payload.change_fields) {
            if (item.payload.change_fields.hasOwnProperty(prop)) {
                array.push(<div
                    key={prop}
                    className="mgt5"
                    dangerouslySetInnerHTML={{__html: this.parseLogs(prop, item)}}
                />)
            }
        }

        return array
    }

    /**
     * render orderStatus Search
     * @param item
     * @param index
     * @param prop
     */
    renderStatusSearchItem = (item, index, prop) => {
        const arrayPropChecked = this.state.listChecked[prop] || [];
        // console.log('arrayPropChecked', arrayPropChecked)
        let checked = Array.isArray(arrayPropChecked) && arrayPropChecked.find(x => x === item[this.getValuePropOfProp(prop)]) !== undefined;
        return <Tag.CheckableTag
            key={index}
            onChange={() => this.onCheckBoxCheck(item, prop)}
            className={`_check-${prop}-item cursor-pointer txt-size-h7 robotoregular txt-capitalize box-shadow-blue pdl10 pdr10`}
            checked={checked}
        >
            <Typography.Link style={checked ? {color: '#ffffff'} : {}}>
                {item.name}
            </Typography.Link>
        </Tag.CheckableTag>
    };

    /**
     * render header page
     * @returns {*}
     */
    renderSearchBox = () => {
        const {
            listPackageDamageStatusesM5 = [],
            orderCode,
        } = this.state;
        const {t} = this.props;

        return (
            <div className="searchbox bg-color-white pd20 mgbt20">
                <Row className={'borderdotted-bootom-2x bd-color-gray pdbt20'}>
                    <Col span={24} className="dpl-flex">
                        <span className={'searchbox__label txt-color-black txt-size-h7 robotoregular'}>{t('package_damage.statusDamage')}:</span>
                        <div className={'txt-size-h7 txt-color-blue robotoregular txt-capitalize width-100-percent flex-1-1'}>
                            {listPackageDamageStatusesM5.map((item, index) => this.renderStatusSearchItem(item, index, 'statusDamage'))}
                        </div>
                    </Col>
                </Row>
                <Row gutter={20} className={'dpl-flex bd-color-gray borderdotted-bootom-2x pdbt20 pdt15'}>
                    {/*<Col span={12} className="">*/}
                        {/*<div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>*/}
                            {/*{t('package_damage.productCode')}:*/}
                        {/*</div>*/}
                        {/*<Input*/}
                            {/*value={productCode}*/}
                            {/*onChange={(e) => this.setState({productCode: e.target.value})}*/}
                            {/*onKeyDown={(e) => {*/}
                                {/*if (e.keyCode === 13) {*/}
                                    {/*this.onSubmitSearch()*/}
                                {/*}*/}
                            {/*}}*/}
                            {/*className={'_search-box-input-productCode'}*/}
                        {/*/>*/}
                    {/*</Col>*/}

                    <Col span={12} className="">
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('shipment.order_code')}:
                        </div>
                        <Input
                            value={orderCode}
                            onChange={(e) => this.setState({orderCode: e.target.value})}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.onSubmitSearch()
                                }
                            }}
                            className={'_search-box-input-orderCode'}
                        />
                    </Col>
                </Row>
                <div
                    className=" dpl-flex align-items-center justify-content-between pdt10"
                >
                    <div />
                    <div className="dpl-flex align-items-center">
                        <span onClick={this.onClearSearch} className="_btn-clear mgr20 txt-color-gray txt-size-h8 cursor-pointer robotoregular">
                            <AntdIcon.SyncOutlined type={'sync'} className={'mgr5'}/> {t('order.filter_refresh')}
                        </span>
                        <Button onClick={this.onSubmitSearch} type="primary" className={'_btn-search min-width-200'}>{t('order.search')}</Button>
                    </div>
                </div>
            </div>

        )
    };

    renderModalEdit = () => {
        const {
            listPackageDamageStatusesM2 = [],
            isShowModalEdit,
            editItem = {},
            statusDamageError
        } = this.state;
        const {t} = this.props;
        return <Modal
            title={t('package_damage.edit_statusDamage')}
            visible={isShowModalEdit}
            onOk={() => this.editPackageDamage()}
            onCancel={() => this.setState({isShowModalEdit: false})}
            okText={t('button.agree')}
            cancelText={t('cartCheckout.cancel')}
        >
            <div className="pdbt5 txt-size-h8 txt-color-gray">{t('package_damage.statusDamage')}</div>
            <Select
                placeholder={t('package_damage.edit_statusDamage')}
                className="width-100-percent"
                onChange={(value) => this.setState({
                    editItem: {...editItem, statusDamage: value}
                })}
                value={editItem.statusDamage || []}
            >
                {
                    listPackageDamageStatusesM2.map((i,j) => <Select.Option key={j} value={i.code}>{i.name}</Select.Option>)
                }
            </Select>
            {
                statusDamageError && <div className="pdt5 txt-size-h8 txt-color-red">
                    {statusDamageError}
                </div>
            }
            <div className="pdt10 pdbt5 txt-size-h8 txt-color-gray">{t('package_damage.note')}</div>
            <Input.TextArea
                value={editItem.note}
                onChange={(e) => this.setState({
                    editItem: {...editItem, note: e.target.value}
                })}
                placeholder={t('package_damage.edit_note')}
                rows={3}
            />
        </Modal>
    };

    renderModalLogs = () => {
        const {
            isLoadLog,
            listLogs,
            isShowModalLog
        } = this.state;
        const {t} = this.props;

        return <Modal
            title={t('package_damage.log_modal')}
            className={"modal-log-package-damage"}
            visible={isShowModalLog}
            onCancel={() => this.setState({isShowModalLog: false})}
            footer={null}
        >
            {
                Array.isArray(listLogs) && listLogs.length > 0
                ?
                    listLogs.map((item, index) => <div className={`package-damage-log-item ${index === 0 ? '' : 'mgt10'}`} key={index}>
                        <div className="">
                            <span className="bold pdr5">{moment(item.time).format('HH:mm DD/MM/YYYY')}</span>
                            {
                                !!item.creator && <span>
                                    {item.creator.is_admin ? t('shipment_log.staff') : t('shipment_log.customer')}: <span className='bold'>{item.creator.name}</span> ({item.creator.username})
                                </span>
                            }
                        </div>
                        {
                            item.payload && item.payload.change_fields ? this.renderLog(item) : ''
                        }
                    </div>)
                    :
                    isLoadLog ?
                    <Loading/> :
                    <div className="pdt50 pdbt60 bg-color-white">
                        <Empty
                            image={require('../../resources/images/empty-image.png')}
                            description={t('message.empty')}
                        />
                    </div>
            }

        </Modal>
    };

    render() {
        const {
            listPackageDamage = [],
            isLoading,
            metadata,
            listPackageDamageStatusesM5 = [],
            previewVisible,
            previewItem
        } = this.state;
        const {t} = this.props;
        const columns = [{
            title: t('shipment.order_code'),
            dataIndex: 'orderCode',
            key: 'orderCode',
            className: 'whitespace',
            render: (text, row) =>
                <Typography.Paragraph style={{marginBottom: 0}} copyable={{ text: text }}>
                    <Link to={row.isShipment ? `/shipments/${text}` : `/orders/${text}`}  className="_table-row-code whitespace">{text}</Link>
                </Typography.Paragraph>

        },
        //     {
        //     title: t('package_damage.productCode'),
        //     dataIndex: 'productCode',
        //     key: 'productCode',
        //     className: 'whitespace-pre-wrap',
        //     render: (text, row) => text || '---'
        // },
            {
            title: t('package_damage.imageUrl'),
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            className: 'whitespace-pre-wrap width-300',
            render: (text, row) => <div className="chat-attachments-wrapper ">
                {
                    Array.isArray(text) && text.map((i, j) => <div className={'mgbt5 mgr5'} key={j}>
                        <div
                            onClick={() => this.handlePreview(i)}
                            className="_chat-attachments-item chat-attachments-item cursor-pointer position-re dpl-flex align-items-center justify-content overflow-hidden border-radius4"
                        >
                            <div className="image-preview" style={{backgroundImage: `url(${i})`}}/>

                            <div className="overlay-item-preview">
                                <AntdIcon.EyeOutlined className={'icon-preview'} type="eye" />
                            </div>
                        </div>
                    </div>)
                }
            </div>
        }, {
            title: t('package_damage.statusDamage'),
            dataIndex: 'statusDamage',
            key: 'statusDamage',
            className: 'text-center',
            render: (text, row) => {
                let itemStatus = listPackageDamageStatusesM5.find(x => x.code === text) || {};
                return <div className="text-center">
                    <Tag style={{backgroundColor: `#${intToRGB(hashCode(text))}`}} className={'_table-row-status whitespace listorders__items_right--status'}>{itemStatus.name}</Tag>
                    {
                        text === 'checking' && <div onClick={() => this.setState({isShowModalEdit: true, editItem: {...row, statusDamage: ''}, statusDamageError: ''})} className="txt-color-blue txt-underline cursor-pointer whitespace mgt5">
                            {t('package_damage.request_status_damage')}?
                        </div>
                    }
                </div>
            },
        }, {
            title: t('fee_tab.reason'),
            dataIndex: 'reason',
            key: 'reason',
            className: 'whitespace',
            render: (text, row) => <div>
                {Array.isArray(row.damagedReasons) && row.damagedReasons.map((item, index) => <div className="pdt3 pdbt3 whitespace" key={index}>
                    - {item.name}
                </div>)}
            </div>
        }, {
            title: t('package_damage.note'),
            dataIndex: 'note',
            key: 'note',
            className: 'whitespace-pre-wrap break-word max-width-400',
            render: (text, row) => text || '---'
        }, {
            title: "",
            dataIndex: 'action',
            key: 'action',
            className: 'whitespace-pre-wrap',
            render: (text, row) => row.packageCode ? <div className="_btnLog btn-package-damage-log" onClick={() => this.setState({isShowModalLog: true, listLogs: []}, () => {this.getLog(row.packageCode)})}>Log</div> : ''
        }];

        return <MainLayout
            ref={ref => this.mainLayoutElement = ref}
            {...this.props}
            showProgressBar={this.state.showProgressBar}
            headerTitle={t('package_damage.title_page')}
            title={t('package_damage.title_page')}
        >
            <div className={'container-common pdbt30 mgr20'}>
                {this.renderSearchBox()}
                <div className="dpl-flex justify-content-between align-items-center bg-color-white pd20 border-bottom-1x bd-color-gray">
                    {
                        metadata
                        ?
                        <span className={'txt-color-black txt-size-h5 robotobmedium'}>{t('package_damage.title_page')} (<span className={'_total-item-in-page'}>{quantityFormat(metadata.total)}</span>)</span>
                        :
                        <span />
                    }
                </div>
                <div className="pd20 bg-color-white">
                    {
                        Array.isArray(listPackageDamage) && listPackageDamage.length > 0
                            ?
                            <Table
                                ref={ref => this.table = ref}
                                className={'_table-data custom-empty table-expand-row custom-table'}
                                rowKey={'packageCode'}
                                columns={columns}
                                dataSource={listPackageDamage}
                                rowClassName={() => `_table-row`}
                                pagination={{
                                    className: '_pagination text-center width-100-percent mgt20',
                                    hideOnSinglePage: true,
                                    current: metadata ? parseFloat(metadata.page) + 1 : 1,
                                    total: metadata ? parseFloat(metadata.total) : 1,
                                    pageSize: metadata ? parseFloat(metadata.size) : pageSize,
                                    onChange: (page) => this.onPageChanged(page)
                                }}
                            />
                            :
                            isLoading ?
                                <Loading/> :
                                <div className="pdt50 pdbt60 bg-color-white">
                                    <Empty
                                        image={require('../../resources/images/empty-image.png')}
                                        description={t('message.empty')}
                                    />
                                </div>
                    }
                </div>
                {
                    previewVisible && <Modal
                        width={700}
                        className={'modal-preview-image'}
                        visible={previewVisible}
                        footer={null}
                        onCancel={() => this.setState({previewVisible: false})}
                    >
                        <img alt={previewItem} style={{width: '100%'}} src={previewItem}/>
                    </Modal>
                }

                {this.renderModalEdit()}
                {this.renderModalLogs()}

            </div>
        </MainLayout>;
    }
}
export default withNamespaces()(PackageDamage);
