import React, {useEffect, useState} from 'react'
import {Modal, Spin, Table} from "antd";
import lodash from 'lodash'
import {moneyFormat} from "../../utils/stringUtils";

export const ModalChargeRequestResult = (props) => {

    const {t, result, items, loading, exchangeRatesByAmount} = props

    const [hasNewExchangeRate, setHasNewExchangeRate] = useState(false)

    useEffect(() => {
        if (exchangeRatesByAmount.length) {
            for (let row of items) {
                const exchangeRate = exchangeRatesByAmount.find(item => item.refId === `${row.amount}|${row.paymentMethodCode}`)
                const newRate = lodash.get(exchangeRate, 'exchangeRate.rate')
                if (newRate !== row.exchangeRate) {
                    setHasNewExchangeRate(true)
                    break
                }
            }
        }
    }, [items, exchangeRatesByAmount])

    return (
        <Modal
            width={950}
            visible={true}
            className={'modal-confirm-yctt'}
            title={t('peer_payment.popupChargeResultTitle')}
            cancelText={<><i className="fas fa-check"/>&nbsp; {t('peer_payment.closeCharge')}</>}
            closable={false}
            onOk={() => props.onVisibleChange(false)}
            onCancel={() => props.onConfirmedChargeSuccess(false)}
            okButtonProps={{
                className: 'd-none'
            }}
            cancelButtonProps={{
                loading: loading,
                disabled: loading
            }}
            maskClosable={false}
        >
            <Table
                columns={[
                    {
                        key: "code",
                        title: t('peer_payment.code'),
                        dataIndex: ['code'],
                        render: code => <span className={'code'}>{code}</span>
                    },
                    {
                        key: "foreignAmount",
                        title: <div className={'text-right'}>{t('peer_payment.amount')}</div>,
                        dataIndex: ['amount'],
                        render: (amount, row) => <div className={'text-right'}>{moneyFormat(amount, row.currency)}</div>
                    },
                    {
                        key: "exchangeAmount",
                        title: <div className={'text-right'}>{t('peer_payment.exchangedAmount')}</div>,
                        dataIndex: ['exchangedAmount'],
                        render: (amount) => {
                            return (
                                <div className={'text-right'}>{moneyFormat(amount)}</div>
                            )
                        }
                    },
                    {
                        key: "newExchangeAmount",
                        title: <div className={'text-right'}>{t('peer_payment.newExchangeAmount')}</div>,
                        render: (row) => {
                            const exchangeRate = exchangeRatesByAmount.find(item => item.refId === `${row.amount}|${row.paymentMethodCode}`)
                            const newRate = lodash.get(exchangeRate, 'exchangeRate.rate')
                            let newAmount = row.exchangedAmount;
                            if (newRate !== row.exchangeRate) {
                                newAmount = row.amount * newRate
                            }

                            return (
                                <>
                                    <div className={`text-right ${hasNewExchangeRate ? 'new-exchange-hl' : ''}`}>{moneyFormat(newAmount)}</div>
                                </>
                            )
                        }
                    },
                    {
                        key: "status",
                        title: t('peer_payment.ycttStatus'),
                        render: row => {
                            if (lodash.get(result, [row.code, 'error'])) {
                                return <span className={'charge-error'}>{lodash.get(result, [row.code, 'error'])}</span>
                            }
                            else if (lodash.get(result, [row.code, 'success'])) {
                                return <span className={'charge-success'}>{lodash.get(result, [row.code, 'success'])}</span>
                            }
                            else {
                                return <span className={'charge-loading'}>
                                    <Spin spinning={true} /> {t('peer_payment.chargeLoading')}
                                </span>
                            }
                        }
                    }
                ]}
                dataSource={items}
                rowKey={'code'}
                pagination={false}
            />
        </Modal>
    )
}
