/* eslint-disable array-callback-return */
import React from 'react'
import moment from 'moment'
import MainLayout from '../../components/Layout/MainLayout'
import Loading from '../../components/Loading'
import {Link} from 'react-router-dom'
import {
    Row, Col, Empty, Input,
    Table, Tag, Button, Checkbox, Typography
} from 'antd';
import * as AntdIcon from "@ant-design/icons"
import TicketsServices from "../../services/tickets.service";

import {LocalStore} from "../../utils/LocalStore";
// import lang from "../../resources/localization/Localization";

import {getUrlQueryString} from "../../utils/helper";
import {
    moneyCeil,
    moneyFormat,
    parseQueryStringToObject,
    quantityFormat,
    replaceSpecialCharacters,
    updateQueryStringParameter
} from "../../utils/stringUtils";
import {withNamespaces} from "react-i18next";
import _ from 'lodash'

let pageSize = 25;
class Tickets extends React.Component {
    constructor(props) {
        super(props);
        this.isUnmounted = false;
        this.state = {
            page: 1,
            pageSize: pageSize,
            isLoading: true,
            listTickets: [],
            listChecked: {},
            datetimeRange: [],
            code: '',
            relatedOrder: '',
            relatedProduct: '',
        }
    }

    componentDidMount() {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null;
        window.addEventListener("popstate", this.onPopState.bind(this));

        if (typeof LocalStore.getInstance().read('currentLoggedUser') === 'object') {
            this.setState({isLoading: true}, () => {
                this.loadFromQueryString();

                this.getStatuses();
                this.getSolutions();
            })
        }
    }

    /**
     * Khi unmount ra thì bỏ sự kiện popstate
     */
    componentWillUnmount() {
        this.isUnmounted = true;
        window.removeEventListener("popstate", this.onPopState.bind(this));
    }

    /**
     * On popstate
     */
    onPopState() {
        if (!this.isUnmounted) this.setState({isLoading: true}, ()=> {
            this.loadFromQueryString();
        });
    };

    /**
     * Load từ QueryString (dành cho lần load đầu tiên)
     */
    loadFromQueryString = () => {
        let queryString = getUrlQueryString();
        let queryObj = parseQueryStringToObject(queryString) || {};

        // console.log('queryObj', queryObj);
        // chuyển giá trị từ queryObj vào state;
        let arrayValue = [];
        //reset các param khi load từ url
        let listChecked = {};
        let code = '';
        let relatedOrder = '';
        let relatedProduct = '';
        let datetimeRange = [];
        let ticketType = '';
        for (let p in queryObj) {
            if (p === 'publicStates' || p === 'solutionCode') {
                // xử lý với obj list checked
                arrayValue = queryObj[p].split(',');
                // console.log('arrayValue', arrayValue)
                listChecked[p] = arrayValue;
            } else if (p === 'createdFrom' || p === 'createdTo') {
                // xử lý với với timestamp
                datetimeRange[0] = decodeURIComponent(queryObj['createdFrom']);
                datetimeRange[1] = decodeURIComponent(queryObj['createdTo']);
            } else if (p === 'code') {
                // xử lý với query
                code = decodeURIComponent(queryObj['code'] || '')
            } else if (p === 'relatedOrder') {
                // xử lý với query
                relatedOrder = decodeURIComponent(queryObj['relatedOrder'] || '')
            } else if (p === 'relatedProduct') {
                // xử lý với query
                relatedProduct = decodeURIComponent(queryObj['relatedProduct'] || '')
            } else if (p === 'ticketType') {
                // xử lý với query
                ticketType = decodeURIComponent(queryObj['ticketType'] || '')
            }
        }

        this.setState({
            code,
            relatedOrder,
            relatedProduct,
            datetimeRange,
            listChecked,
            ticketType,
            page: queryObj.page,
            pageSize: queryObj.size || pageSize,
        }, ()=> {
            this.loadData();
        });
    };

    /**
     * load data
     */
    loadData = (cb) => {
        const {page, pageSize} = this.state;
        let queryString = this.buildQueryString();
        if (queryString) {
            queryString = queryString + `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        } else {
            queryString = `?page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        }
        this.setState({showProgressBar: true}, () => {
            TicketsServices.getTickets(queryString, (err, res, headers) => {
                if (!err) {
                    this.setState({
                        listTickets: res,
                        metadata: {
                            pageCount: headers['x-page-count'],
                            page: headers['x-page-number'],
                            size: headers['x-page-size'],
                            total: headers['x-total-count'],
                        }
                    }, () => {
                        if (this.mainLayoutElement && this.mainLayoutElement.scrollLayoutElement) this.mainLayoutElement.scrollLayoutElement.scrollTop = 0;
                        //window.scrollTo(0, 0);
                    });
                }
                if(cb) cb();
                this.setState({showProgressBar: false, isLoading: false})
            })
        })
    };

    onPageChanged = (page, pageSize) => {
        this.setState({page: page, pageSize: pageSize}, () => {
            let queryString = getUrlQueryString();
            queryString = updateQueryStringParameter(queryString ? '?' + queryString : '', 'page', page);
            queryString = updateQueryStringParameter(queryString, 'size', pageSize);
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: queryString
            });
            // window.scrollTo(0, 0);
        });
    };

    /**
     * lấy ra danh sách trạng thái đơn
     */
    getStatuses = () => {
        TicketsServices.getPublicStates((err, res, headers) => {
            if (!err) {
                this.setState({
                    listStatuses: res,
                });
            }
        })
    };

    /**
     * lấy ra danh sách phương án xử lý
     */
    getSolutions = () => {
        TicketsServices.getSolutionStatics((err, res, headers) => {
            if (!err) {
                this.setState({
                    listSolutions: res,
                });
            }
        })
    };

    /**
     * lấy ra prop giá trị của obj prop
     * @param props
     * @returns {*}
     */
    getValuePropOfProp = (props) => {
        switch (props) {
            case 'solutionCode':
            case 'publicStates':
                return 'code';
            default:
                return 'id'
        }
    };

    /**
     * xây dựng query string theo các giá trị trong state
     * @returns {string}
     */
    buildQueryString = () => {
        let {listChecked, datetimeRange, code, relatedOrder, relatedProduct, ticketType} = this.state;
        let queryString = '';
        for (let p in listChecked) {
            let value = '';
            if (Array.isArray(listChecked[p]) && listChecked[p].length > 0) {
                listChecked[p].map(x => {
                    value = value + x + ',';
                });

                value = value.slice(0, -1)
            }
            if (value) queryString = updateQueryStringParameter(queryString, p, value)
        }

        if (datetimeRange.length > 0) {
            queryString = updateQueryStringParameter(queryString, 'createdAtFrom', datetimeRange[0]);
            queryString = updateQueryStringParameter(queryString, 'createdAtTo', datetimeRange[1]);
        }

        if (code) {
            queryString = updateQueryStringParameter(queryString, 'code', replaceSpecialCharacters(code.trim()))
        }

        if (relatedOrder) {
            queryString = updateQueryStringParameter(queryString, 'relatedOrder', replaceSpecialCharacters(relatedOrder.trim()))
        }

        if (relatedProduct) {
            queryString = updateQueryStringParameter(queryString, 'relatedProduct', replaceSpecialCharacters(relatedProduct.trim()))
        }

        if (ticketType) {
            queryString = updateQueryStringParameter(queryString, 'ticketType', ticketType)
        }

        return queryString
    };

    onClearSearch = () => {
        this.setState({
            page: 1,
            code: '',
            relatedOrder: '',
            relatedProduct: '',
            datetimeRange: [],
            listChecked: {},
            ticketType: ''
        }, () => {
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: this.buildQueryString()
            });
        })
    };

    /**
     * xử lý với dữ liệu search trong state
     */
    onSubmitSearch = () => {
        this.setState({page: 1}, () => {
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: this.buildQueryString()
            });
        })
    };

    dateTimeChange = (date) => {
        // console.log('dateTimeChange ', date, dateString);

        this.setState({
            datetimeRange: date.length > 0 ? [date[0].startOf('day').toISOString(), date[1].endOf('day').toISOString()] : []
        })
    };

    onCheckBoxCheck = (item, prop) => {
        let {listChecked} = this.state;

        let arrayPropChecked = listChecked[prop] || [];
        if (arrayPropChecked.find(x => x === item[this.getValuePropOfProp(prop)])) {
            arrayPropChecked = arrayPropChecked.filter(x => x !== item[this.getValuePropOfProp(prop)]);
        } else {
            arrayPropChecked.push(item[this.getValuePropOfProp(prop)]);
        }

        listChecked[prop] = arrayPropChecked;

        this.setState({
            listChecked
        })
    };

    /**
     * render orderStatus Search
     * @param item
     * @param index
     * @param prop
     */
    renderStatusSearchItem = (item, index, prop) => {
        const arrayPropChecked = this.state.listChecked[prop] || [];
        // console.log('arrayPropChecked', arrayPropChecked)
        let checked = Array.isArray(arrayPropChecked) && arrayPropChecked.find(x => x === item[this.getValuePropOfProp(prop)]) !== undefined;
        return <Tag.CheckableTag
            key={index}
            onChange={() => this.onCheckBoxCheck(item, prop)}
            className={`_check-${prop}-item cursor-pointer txt-size-h7 robotoregular txt-capitalize box-shadow-blue pdl10 pdr10`}
            checked={checked}
        >
            <Typography.Link style={checked ? {color: '#ffffff'} : {}}>{item.name}</Typography.Link>
        </Tag.CheckableTag>
    };

    getSolutionsByTicketType = () => {
        const {
            listSolutions = [],
            ticketType
        } = this.state;
        if (ticketType !== '') return _.sortBy(listSolutions.filter((i) => i.subject === ticketType), 'code')
        return _.sortBy(_.uniqBy(listSolutions, 'name'), 'code')
    }

    /**
     * render header page
     * @returns {*}
     */
    renderSearchBox = () => {
        const {
            listStatuses = [],
            code,
            relatedOrder,
            relatedProduct,
            ticketType
        } = this.state;
        const {t} = this.props;
        const uniqListSolution = this.getSolutionsByTicketType()
        return (
            <div className="searchbox bg-color-white pd20 mgbt20">
                <Row className={'bd-color-gray borderdotted-bootom-2x pdbt15'}>
                    <Col span={24} className="dpl-flex ">
                        <span className={'searchbox__label txt-color-black txt-size-h7 robotoregular'}>{t('tickets.status')}:</span>
                        <div className={'txt-size-h7 txt-color-blue robotoregular txt-capitalize width-100-percent flex-1-1'}>
                            {listStatuses.map((item, index) => this.renderStatusSearchItem(item, index, 'publicStates'))}
                        </div>
                    </Col>
                </Row>

                <Row className={'bd-color-gray borderdotted-bootom-2x pdbt15 pdt15'}>
                    <Col span={24} className="dpl-flex ">
                        <span className={'searchbox__label txt-color-black txt-size-h7 robotoregular'}>{t('tickets.solution')}:</span>
                        <div className={'txt-size-h7 txt-color-blue robotoregular txt-capitalize width-100-percent flex-1-1'}>
                            {uniqListSolution.map((item, index) => this.renderStatusSearchItem(item, index, 'solutionCode'))}
                        </div>
                    </Col>
                </Row>

                <Row className={'bd-color-gray borderdotted-bootom-2x pdbt15 pdt15'}>
                    <Col span={24} className="dpl-flex ">
                        <Checkbox className="" checked={ticketType === 'order'} onChange={(e) => this.setState({ticketType: e.target.checked ? 'order' : ''})}>{t('menu.orders')}</Checkbox>
                        <Checkbox className="" checked={ticketType === 'shipment'} onChange={(e) => this.setState({ticketType: e.target.checked ? 'shipment' : ''})}>{t('menu.shipments')}</Checkbox>
                    </Col>
                </Row>

                <Row className={'dpl-flex bd-color-gray borderdotted-bootom-2x pdbt20 pdt15'}>
                    <Col span={8} className="pdr10">
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('tickets.enter_code')}:
                        </div>
                        <Input
                            placeholder={t('tickets.code')}
                            value={code}
                            onChange={(e) => this.setState({code: e.target.value})}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.onSubmitSearch()
                                }
                            }}
                            className={'_search-box-input-query'}
                        />
                    </Col>
                    <Col span={8} className="pdl5 pdr5">
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('tickets.enter_order_code')}:
                        </div>
                        <Input
                            value={relatedOrder}
                            placeholder={t('tickets.order_code')}
                            onChange={(e) => this.setState({relatedOrder: e.target.value})}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.onSubmitSearch()
                                }
                            }}
                            className={'_search-box-input-orderCode'}
                        />
                    </Col>
                    <Col span={8} className="pdl10">
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('tickets.enter_product_code')}:
                        </div>
                        <Input
                            placeholder={t('tickets.product_code')}
                            value={relatedProduct}
                            onChange={(e) => this.setState({relatedProduct: e.target.value})}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.onSubmitSearch()
                                }
                            }}
                            className={'_search-box-input-productCode'}
                        />
                    </Col>
                    {/*<Col span={12} className="pdl10">*/}
                    {/*    <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>*/}
                    {/*        Thời gian tạo:*/}
                    {/*    </div>*/}
                    {/*    <DatePicker.RangePicker*/}
                    {/*        className={'_search-date-time width-100-percent'}*/}
                    {/*        format={'DD/MM/YYYY'}*/}
                    {/*        value={datetimeRange.length > 0 ? [moment(datetimeRange[0]), moment(datetimeRange[1])] : []}*/}
                    {/*        onChange={this.dateTimeChange}*/}
                    {/*    />*/}
                    {/*</Col>*/}
                </Row>
                <div
                    className=" dpl-flex align-items-center justify-content-between pdt10"
                >
                    <div />
                    <div className="dpl-flex align-items-center">
                        <span onClick={this.onClearSearch} className="_btn-clear mgr20 txt-color-gray txt-size-h8 cursor-pointer robotoregular">
                            <AntdIcon.SyncOutlined type={'sync'} className={'mgr5'}/> {t('order.filter_refresh')}
                        </span>
                        <Button onClick={this.onSubmitSearch} type="primary" className={'_btn-search min-width-200'}>{t('order.search')}</Button>
                    </div>
                </div>
            </div>

        )
    };

    render() {
        const {
            listTickets,
            isLoading,
            metadata,
        } = this.state;
        const {t} = this.props;
        const columns = [{
            title: t('tickets.code'),
            dataIndex: 'code',
            key: 'code',
            render: (text, record) => <Link to={`/tickets/${record.code}`} className="_table-row-code txt-uppercase">#{text}</Link>
        }, {
            title: t('tickets.name'),
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <Link to={`/tickets/${record.code}`} className="_table-row-name txt-uppercase break-word">{text}</Link>
        }, {
            title: t('tickets.created_at'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            className: 'hidden-md',
            render: (text) => <span className="_table-row-createdAt txt-uppercase ">{moment(text).format('HH:mm DD/MM/YYYY')}</span>
        }, {
            title: t('tickets.status'),
            dataIndex: 'publicStateNewView',
            key: 'publicStateNewView',
            render: (text, record) => {
                // const itemStatus = listStatuses.find(x => x.code === text) || {};
                if (!record.publicStateNewView) return <span />;
                return <Tag style={{backgroundColor: record.publicStateNewView.color || '#898989'}} className={'_table-row-state listorders__items_right--status'}>{record.publicStateNewView.name}{record.archived && ' (Đã đóng)'}</Tag>
            },
        }, {
            title: t('tickets.total_refund'),
            dataIndex: 'totalRefund',
            key: 'totalRefund',
            render: (text) => <span className="_table-row-totalRefund txt-color-green bold break-word">{moneyFormat(moneyCeil(text))}</span>,
        }, {
            title: t('tickets.solution'),
            dataIndex: 'solutionView',
            key: 'solutionView',
            render: (text, record) => {
                if (!record.solutionView) return <span />;
                return <span className={'_table-row-solution'}>{record.solutionView.name}</span>
            },
        }, {
            title: '',
            dataIndex: 'action',
            key: 'action',
            className: 'txt-right',
            render: (text, record) => (
                <Link to={`/tickets/${record.code}`} className="_table-row-action whitespace">
                    {t('tickets.detail')}
                </Link>
            ),
        }];

        return <MainLayout
            ref={ref => this.mainLayoutElement = ref}
            {...this.props}
            showProgressBar={this.state.showProgressBar}
            headerTitle={t('tickets.title')}
            title={t('tickets.title')}
        >
            <div className={'container-common pdbt30 mgr20'}>
                {this.renderSearchBox()}
                <div className="dpl-flex justify-content-between align-items-center bg-color-white pd20 border-bottom-1x bd-color-gray">
                    <span className={'txt-color-black txt-size-h5 robotobmedium'}>{t('tickets.title')} {metadata && <span className={'_total-item-in-page'}>({quantityFormat(metadata.total)})</span>}</span>
                    <Link to={'/tickets/create'} className={'_btn-create ant-btn ant-btn-primary ant-btn-background-ghost'}>{t('tickets.create')}</Link>
                </div>
                <div className="pd20 bg-color-white">
                    {
                        Array.isArray(listTickets) && listTickets.length > 0
                            ?
                            <Table
                                className={'_table-data custom-empty'}
                                rowKey={'code'}
                                columns={columns}
                                dataSource={listTickets}
                                rowClassName={() => `_table-row vertical-align-text-top`}
                                pagination={{
                                    className: '_pagination text-center width-100-percent mgt20',
                                    hideOnSinglePage: true,
                                    current: metadata ? parseFloat(metadata.page) + 1 : 1,
                                    total: metadata ? parseFloat(metadata.total) : 1,
                                    pageSize: metadata ? parseFloat(metadata.size) : pageSize,
                                    onChange: (page, pageSize) => this.onPageChanged(page, pageSize)
                                }}
                            />
                            :
                            isLoading ?
                                <Loading/> :
                                <div className="pdt50 pdbt60 bg-color-white">
                                    <Empty
                                        image={require('../../resources/images/empty-image.png')}
                                        description={t('message.empty')}
                                    />
                                </div>
                    }
                </div>
            </div>
        </MainLayout>;
    }
}
export default withNamespaces()(Tickets);
