/* eslint-disable array-callback-return */

import React from 'react'
import { Table, Steps, Popover, Spin, Empty, Col, Row, Tag, Timeline } from 'antd'
import * as AntdIcon from '@ant-design/icons'
import moment from 'moment'
import _ from 'lodash'
import CategoriesService from '../../services/categories.service'
import lodash from 'lodash'
import OrderService from '../../services/orders.service'
import { LocalStore } from '../../utils/LocalStore'
import TooltipCommon from '../commons/Tooltip'
import { renderTimeMidMile } from '../../utils/helper'

export default class TabPackages extends React.Component {
    constructor(props) {
        super(props)
        this.isUnmounted = false
        this.state = {
            listcouries: [],
            // isMobile: window.innerWidth < 981,
            // isTablet: window.innerWidth < 1025
        }
    }

    componentDidMount() {
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}

        this.setState({
            showPackageIoHistory: lodash.get(currentProjectInfo, 'tenantConfig.generalConfig.showPackageIoHistory', false),
        })
        this.getCategoriesCouriers()
    }

    componentWillUnmount() {
        this.isUnmounted = true
        // window.removeEventListener('resize', this.windowResize);
    }

    // /**
    //  * khi window resize
    //  */
    // windowResize = () => {
    //     const windowWidth = window.innerWidth;
    //     if (!this.isUnmounted) this.setState({
    //         isMobile: windowWidth < 981,
    //         isTablet: windowWidth < 1025
    //     })
    // };

    /**
     * lấy ra danh sách couries
     */
    getCategoriesCouriers = () => {
        CategoriesService.getCouriers((err, res, headers) => {
            this.setState({
                listcouries: res || [],
            })
        })
    }

    renderDot = (dot, { status, index }) => {
        return (
            <Popover
                content={
                    <span>
                        step {index} status: {status}
                    </span>
                }
            >
                {dot}
            </Popover>
        )
    }

    renderSteps = record => {
        // const {isTablet} = this.state;
        const { listPackageMileStones = {}, isLoadingPackageMileStones = {}, listPackageStatuses = [], t } = this.props
        if (isLoadingPackageMileStones[record.code])
            return (
                <div className="dpl-flex align-items-center justify-content pdt10 pdbt10">
                    <Spin
                        indicator={
                            <AntdIcon.Loading3QuartersOutlined
                                type="loading"
                                style={{ fontSize: 24 }}
                                spin
                            />
                        }
                    />
                </div>
            )

        const current = listPackageStatuses.findIndex(x => x.code === record.status)
        const currentPackageStatus = listPackageStatuses.find(x => x.code === record.status) || {}
        const listMileStones = listPackageMileStones[record.code] || []
        let currentMileStone = []

        if (!currentPackageStatus.negativeEnd) {
            return (
                <Steps
                    className={'custom-step'}
                    size={'small'}
                    current={current || 0}
                    progressDot
                    direction={'horizontal'}
                >
                    {listPackageStatuses
                        .filter(x => !x.negativeEnd)
                        .map(x => {
                            currentMileStone = listMileStones.filter(y => y.status === x.code)
                            let dayOrDays = true
                            let checkNull = false
                            let day = ''
                            if (currentMileStone.length > 0) {
                                currentMileStone.map(item => {
                                    if (item.handlingTime === 0) {
                                        dayOrDays = false
                                    } else dayOrDays = true
                                    if (item.handlingTime === null) {
                                        checkNull = true
                                    } else {
                                        checkNull = false
                                    }
                                })
                            }
                            if (dayOrDays && !checkNull) {
                                day = t('label.days')
                            } else if (!dayOrDays && !checkNull) {
                                day = t('label.day')
                            }
                            moment(_.sortBy(currentMileStone, 'timestamp').reverse()).format('HH:mm DD/MM')
                            return (
                                <Steps.Step
                                    key={x.id}
                                    title={x.name}
                                    description={
                                        currentMileStone.length > 0
                                            ? currentMileStone.map((item, y) => {
                                                  return (
                                                      <Col className="grid items-center justify-center">
                                                          <Row style={y === 0 ? { fontWeight: 'bold' } : { fontWeight: '100' }}>
                                                              {moment(item.timestamp).format('HH:mm DD/MM')}
                                                          </Row>
                                                          <Row
                                                              style={y === 0 ? { fontWeight: 'bold' } : { fontWeight: '100' }}
                                                              className="block"
                                                          >
                                                              {item.handlingTime === null
                                                                  ? '( ' + t('orderDetail.undefined') + ')'
                                                                  : '( ' + item.handlingTime + ' ' + day + ')'}
                                                          </Row>
                                                      </Col>
                                                  )
                                              })
                                            : t('orderDetail.undefined')
                                    }
                                />
                            )
                        })}
                </Steps>
            )
        } else {
            // trường hợp trạng thái cuối cùng của package là thất lạc
            let newArray = _.sortBy(
                listMileStones.filter(x => x.status !== currentPackageStatus.code),
                'timestamp'
            ).reverse()
            const lastMileStones = newArray.length > 0 ? newArray[0] : null
            const lastMileStonesIndex = lastMileStones ? listPackageStatuses.findIndex(x => x.code === lastMileStones.status) : 0
            // tạo ra mảng chỉ chứa các phần tử từ đầu đến lastActiveStatus
            const newListPackageStatuses = _.cloneDeep(listPackageStatuses).slice(0, lastMileStonesIndex + 1)

            const mileStonesNegativeEnd = listMileStones.filter(y => y.status === currentPackageStatus.code)

            return (
                <Steps
                    className={'custom-step flex-wrap'}
                    size={'small'}
                    current={lastMileStonesIndex + 1}
                    progressDot
                    direction={'horizontal'}
                    status={'error'}
                >
                    {lastMileStones &&
                        newListPackageStatuses
                            .filter(x => !x.negativeEnd)
                            .map(x => {
                                currentMileStone = listMileStones.filter(y => y.status === x.code)
                                let dayOrDays = true
                                let checkNull = false
                                let day = ''
                                if (currentMileStone.length > 0) {
                                    currentMileStone.map(item => {
                                        if (item.handlingTime === 0) {
                                            dayOrDays = false
                                        } else dayOrDays = true
                                        if (item.handlingTime === null) {
                                            checkNull = true
                                        } else {
                                            checkNull = false
                                        }
                                    })
                                }
                                if (dayOrDays && !checkNull) {
                                    day = t('label.days')
                                } else if (!dayOrDays && !checkNull) {
                                    day = t('label.day')
                                }
                                moment(_.sortBy(currentMileStone, 'timestamp').reverse()).format('HH:mm DD/MM')
                                return (
                                    <Steps.Step
                                        key={x.id}
                                        title={x.name}
                                        description={
                                            currentMileStone.length > 0
                                                ? currentMileStone.map((item, y) => {
                                                      return (
                                                          <Col className="grid items-center justify-center">
                                                              <Row
                                                                  style={y === 0 ? { fontWeight: 'bold' } : { fontWeight: '100' }}
                                                              >
                                                                  {moment(item.timestamp).format('HH:mm DD/MM')}
                                                              </Row>
                                                              <Row
                                                                  style={y === 0 ? { fontWeight: 'bold' } : { fontWeight: '100' }}
                                                                  className="block"
                                                              >
                                                                  {item.handlingTime === null
                                                                      ? '( ' + t('orderDetail.undefined') + ')'
                                                                      : '( ' + item.handlingTime + ' ' + day + ')'}
                                                              </Row>
                                                          </Col>
                                                      )
                                                  })
                                                : t('orderDetail.undefined')
                                        }
                                    />
                                )
                            })}
                    <Steps.Step
                        key={currentPackageStatus.id}
                        title={currentPackageStatus.name}
                        description={
                            mileStonesNegativeEnd.length > 0
                                ? moment(_.sortBy(mileStonesNegativeEnd, 'timestamp').reverse()[0].timestamp).format(
                                      'HH:mm DD/MM'
                                  )
                                : t('orderDetail.undefined')
                        }
                    />
                </Steps>
            )
        }
    }
    getPackageIOHistories = code => {
        this.setState(
            {
                isLoadingListMilestones: true,
            },
            () => {
                OrderService.getPackageIOHistories(code, (err, res) => {
                    if (!err) {
                        this.setState({
                            listMilestones: res,
                        })
                    }
                    this.setState({ isLoadingListMilestones: false })
                })
            }
        )
    }
    renderTimeLine = () => {
        const { t } = this.props
        const { listMilestones = [], isLoadingListMilestones } = this.state

        if (isLoadingListMilestones === true) {
            return (
                <div className="mgt25 mgbt25 txt-center">
                    <Spin
                        spinning={isLoadingListMilestones === true}
                        indicator={
                            <AntdIcon.Loading3QuartersOutlined
                                type="loading"
                                style={{ fontSize: 24 }}
                                spin
                            />
                        }
                    />
                </div>
            )
        }

        return (
            <Timeline>
                {Array.isArray(listMilestones) &&
                    listMilestones.map((item, index) => {
                        // const status = listStatuses.find(x => x.code === item.status);
                        return (
                            <Timeline.Item color={index === 0 ? 'green' : 'gray'}>
                                <p className="mgbt0">
                                    {moment(item.createdAt).format('HH:mm DD/MM')} -{' '}
                                    {t(`package.${item.statusWarehouse === 'IN' ? 'inWarehouse' : 'outWarehouse'}`, {
                                        value: lodash.get(item, 'warehouse.mask', '---'),
                                    })}
                                </p>
                            </Timeline.Item>
                        )
                    })}
            </Timeline>
        )
    }
    render() {
        const {
            isLoadingPackages,
            listPackageStatuses = [],
            listPackages = [],
            getOrderPackageMileStones,
            t,
            orderDetail,
        } = this.props

        const { expandedRow = [], showPackageIoHistory, listcouries } = this.state
        const tenant = LocalStore.getInstance().read('currentProjectInfo')
        const showPackageNote = lodash.get(tenant, 'tenantConfig.generalConfig.showPackageNote', false)

        const columns = [
            {
                title: '#',
                dataIndex: 'key',
                key: 'key',
                render: (txt, record, index) => {
                    return (
                        <span className={'_index flex flex-col'}>
                            {index + 1}
                            {showPackageNote && record?.note && (
                                <TooltipCommon
                                    content={record?.note}
                                    placement={'top'}
                                    label={
                                        <span className="text-blue-primary cursor-pointer underline">{t('delivery.note')}</span>
                                    }
                                />
                            )}
                        </span>
                    )
                },
            },
            {
                title: t('package_tab.package_code'),
                dataIndex: 'code',
                key: 'code',
                render: (txt, record) => (
                    <div>
                        <span className="_code txt-uppercase font-medium">{txt}</span>
                        {record?.status === 'TRANSPORTING' &&
                            record?.courier &&
                            renderTimeMidMile(record, listcouries, orderDetail?.deliveryWarehouse?.code)}
                    </div>
                ),
            },
            {
                title: t('package_tab.waybills'),
                dataIndex: 'trackingNumber',
                key: 'trackingNumber',
                render: trackingNumber => <span className="_code txt-uppercase font-medium">{trackingNumber || '---'}</span>,
            },
            {
                title: t('package_tab.weight'),
                dataIndex: 'weight',
                key: 'weight',
                render: (text, record) => {
                    const weight = record.actualWeight //Math.max(record.actualWeight, record.dimensionalWeight);
                    return (
                        <span className={'_weight'}>{Number.isFinite(weight) ? `${weight} kg` : t('orderDetail.undefined')}</span>
                    )
                },
            },
            {
                title: t('package.information'),
                dataIndex: 'information',
                key: 'information',
                render: (text, record) => {
                    return (
                        <div className="whitespace">
                            <div className={'_length whitespace'}>
                                {t('package.length')}: {Number.isFinite(record.length) ? `${record.length} cm` : '---'}
                            </div>
                            <div className={'_width whitespace'}>
                                {t('package.width')}: {Number.isFinite(record.width) ? `${record.width} cm` : '---'}
                            </div>
                            <div className={'_height whitespace'}>
                                {t('package.height')}: {Number.isFinite(record.height) ? `${record.height} cm` : '---'}
                            </div>
                        </div>
                    )
                },
            },
            {
                title: t('package_tab.status'),
                dataIndex: 'status',
                key: 'status',
                render: (txt, record) => {
                    const currentStatus = listPackageStatuses.find(x => x.code === txt)
                    if (!showPackageIoHistory)
                        return (
                            <Tag
                                style={{ backgroundColor: currentStatus.color || '#898989' }}
                                className={`_status listorders__items_right--status ${showPackageIoHistory && 'cursor-pointer'}`}
                            >
                                {currentStatus.name}
                            </Tag>
                        )
                    return (
                        <Popover
                            trigger={'click'}
                            overlayClassName={'white-tool-tip timeline-tooltip'}
                            onVisibleChange={visible => {
                                if (visible) this.getPackageIOHistories(record.code)
                            }}
                            placement="left"
                            title={null}
                            content={this.renderTimeLine(record)}
                        >
                            <Tag
                                style={{ backgroundColor: currentStatus.color || '#898989' }}
                                className={'_status listorders__items_right--status cursor-pointer'}
                            >
                                {currentStatus.name}
                            </Tag>
                        </Popover>
                    )
                },
            },
            {
                title: t('package_tab.update'),
                dataIndex: 'lastStatusTime',
                key: 'lastStatusTime',
                render: txt => {
                    return <span className={'_lastStatusTime'}>{moment(txt).format('HH:mm DD/MM')}</span>
                },
            },
        ]

        return (
            <div className={'mgbt30 pd10'}>
                <Spin
                    spinning={isLoadingPackages === true}
                    indicator={
                        <AntdIcon.Loading3QuartersOutlined
                            type="loading"
                            style={{ fontSize: 24 }}
                            spin
                        />
                    }
                >
                    <Table
                        rowKey={'code'}
                        className={'custom-empty'}
                        columns={columns}
                        expandedRowKeys={expandedRow}
                        expandedRowRender={this.renderSteps}
                        expandIcon={expand => {
                            return expand.expanded ? (
                                <AntdIcon.DownOutlined
                                    onClick={e => expand.onExpand(expand.record, e)}
                                    type={expand.expanded ? 'down' : 'right'}
                                />
                            ) : (
                                <AntdIcon.RightOutlined onClick={e => expand.onExpand(expand.record, e)} />
                            )
                        }}
                        onExpand={(expanded, record) => {
                            if (expanded) {
                                this.setState({
                                    expandedRow: [record.code],
                                })
                                getOrderPackageMileStones(record.code)
                            } else {
                                this.setState({
                                    expandedRow: [],
                                })
                            }
                        }}
                        scroll={{ x: 240 }}
                        dataSource={listPackages}
                        pagination={{
                            hideOnSinglePage: true,
                            pageSize: 100,
                        }}
                    />
                    {listPackages.length === 0 && (
                        <Empty
                            className={'mgbt25 mgt15'}
                            image={require('../../resources/images/empty-image.png')}
                            description={t('message.empty')}
                        />
                    )}
                </Spin>
            </div>
        )
    }
}
