import React, {Component} from 'react'

import * as AntdIcon from "@ant-design/icons"


export default class Loading extends Component {

    render() {
        return (
            <div className="dpl-flex justify-content flex-direction-column pdt25 pdbt25">
                <AntdIcon.Loading3QuartersOutlined spin={true} type="loading" style={{fontSize: 36}}/>
            </div>
        )
    }
}
