import { useState, useEffect } from "react";
import lodash from 'lodash'
import {
    Row, Col, Typography, Input, Table, Empty
} from 'antd';
import * as AntdIcon from "@ant-design/icons"
import type { ColumnsType } from 'antd/es/table';
import MainLayout from '../../components/Layout/MainLayout'
import ProfileSidebar from '../../components/Profile/Leftbar'
import Loading from '../../components/Loading'


import { withNamespaces } from "react-i18next";
import UserService from "../../services/user.service";

const TYPE = {
    TEXT: 'text',
    PASSWORD: 'password'
}

interface TypeInput {
    type?: string
}

interface DataType {
    username: string;
    password: string;
    type?: TypeInput
}

function PurchasingAccount(props: any) {
    const { t } = props

    const [loading, setLoading] = useState(false)
    const [purchasingAccounts, setPurchasingAccounts] = useState<DataType[]>([])
    const [totalPurchasingAccount, setTotalPurchasingAccount] = useState<number | string>()

    useEffect(() => {
        getPurchasingAccounts()
    }, [])

    const getPurchasingAccounts = () => {
        setLoading(true)
        UserService.getPurchasingAccounts((err: any, data: any) => {
            const newState = lodash.map(data, (item: any) => {
                return {
                    username: item.username,
                    password: item.password,
                    type: TYPE.PASSWORD
                } as DataType
            })
            const totalRecord = newState.length

            setPurchasingAccounts(newState || [])
            setTotalPurchasingAccount(totalRecord > 0 ? totalRecord : '')
            setLoading(false)
        })
    }

    const handleChangeType = (type: string, index: number) => {
        const newState = [...purchasingAccounts]
        const purchasingAccount = newState[index]
        purchasingAccount.type = type as any
        setPurchasingAccounts(newState)
    }

    const columns: ColumnsType<DataType> = [
        {
            title: t('customer_info.purchasing_account_username'),
            dataIndex: 'username',
            key: 'username',
            render: (text: string) => {
                return text ?
                    <Typography.Paragraph style={{ marginBottom: 0, paddingLeft: 10 }} copyable={{ text: text }}>
                        {text}
                    </Typography.Paragraph> : <span>---</span>
            }
        },
        {
            title: t('customer_info.purchasing_account_password'),
            dataIndex: 'password',
            key: 'password',
            render: (text: string, record: any, index: number) => {
                return text ?
                    <div className="dpl-flex alignitemscenter">
                        <Typography.Paragraph
                            style={{ marginBottom: 0 }}
                            className="dpl-flex alignitemscenter"
                            copyable={{ text: text }}
                        >
                            <Input bordered={false} style={{ paddingLeft: 0, color: 'black' }} type={record.type} value={text} disabled />
                        </Typography.Paragraph>
                        <span className="cursor-pointer mgl5">
                            {record.type === TYPE.PASSWORD ?
                                <AntdIcon.EyeOutlined
                                    onClick={() => handleChangeType(TYPE.TEXT, index)}
                                /> :
                                <AntdIcon.EyeInvisibleOutlined
                                    onClick={() => handleChangeType(TYPE.PASSWORD, index)}
                                />
                            }
                        </span>
                    </div> :
                    <span>---</span>
            }
        },
    ];
    
    // if(loading){
    //     return <Loading />
    // }


    // if(lodash.isEmpty(purchasingAccounts)){
    //     return <Page404 />
    // }

    return (
        <MainLayout
            {...props}

            headerTitle={t('customer_info.purchasing_account')}
            title={t('customer_info.purchasing_account')}
        >
            <div className={'container-common pdbt30 pdt20 mgr20'}>
                <Row>
                    <Col span={6} className={'pdr10'}>
                        <ProfileSidebar currentRoute={'purchasing-account'} />
                    </Col>
                    <Col span={18} className={'pdl10'}>
                        <div className="bg-color-white pd20 h-100">
                            <span className="txt-color-black txt-size-h5 robotobmedium">
                                {t('customer_info.purchasing_account')} <span className="txt-size-h10">({totalPurchasingAccount ? totalPurchasingAccount : 0})</span>
                            </span>
                            <div className="mgt10 mgbt5">
                                <Row className="justify-content-center">
                                    {
                                        Array.isArray(purchasingAccounts) && purchasingAccounts.length > 0
                                            ?
                                            <Table
                                                style={{ width: '100%' }}
                                                dataSource={purchasingAccounts}
                                                columns={columns}
                                                pagination={false}
                                            />
                                            :
                                            loading ?
                                                <Loading /> :
                                                <div className="pdt50 pdbt60 bg-color-white">
                                                    <Empty
                                                        image={require('../../resources/images/empty-image.png')}
                                                        description={t('message.empty')}
                                                    />
                                                </div>
                                    }
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </MainLayout>
    );
}

export default withNamespaces()(PurchasingAccount);