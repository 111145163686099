/* eslint-disable array-callback-return */
import React from 'react'
import MainLayout from '../../components/Layout/MainLayout'
import Comment from '../../components/OrderDetail/Comment'
import Page404 from '../Page404'

import { LocalStore } from '../../utils/LocalStore'
import { Button, Col, Input, Layout, Modal, notification, Popconfirm, Row, Spin, Table, Tag, Tooltip } from 'antd'
import * as AntdIcon from '@ant-design/icons'
import uploadFile from '../../utils/api/uploadFile'
import saveAs from 'file-saver'
import UserService from '../../services/user.service'
import { isInSuspensionTime } from './helper/checkIsInSuspensionTime'

import { withNamespaces } from 'react-i18next'
import { hashCode, intToRGB, moneyFormat, parseQueryStringToObject, updateQueryStringParameter } from '../../utils/stringUtils'
import CategoriesService from '../../services/categories.service'
import moment from 'moment'
import lodash from 'lodash'
import TabLayout from '../../components/PeerPayment/TabLayout'
import * as Constants from '../../constants/config'
import { getUrlQueryString } from '../../utils/helper'
import { Link } from 'react-router-dom'
import Loading from '../../components/Loading'
import { ModalConfirmPayment } from './ModalConfirmPayment'

let currentLoggedUser = null
const initialCommentPage = 0
const listTypePeerPayment = ['payment', 'taobao_global']
class Detail extends React.Component {
    constructor(props) {
        super(props)
        this.isUnmounted = false

        this.state = {
            paramCode: '',
            isLoadingDetail: false,
            dataDetail: {},
            activeTab: Constants.ORDER_DETAIL_TAB_FEES,
            listInternalPaymentStatus: [],
            globalTenantConfig: {},
            isLoadingExchange: false,
            isOpenConfirmPayment: false,
            isEnabledBiffin: false,
            userBalance: {
                balance: 0,
                creditLimit: 0,
            },
            listAttachments:[]
        }
    }

    componentDidMount() {
        window.addEventListener('popstate', this.onPopState.bind(this))

        if (this.mainLayoutElement && this.mainLayoutElement.scrollLayoutElement) {
            this.mainLayoutElement.scrollLayoutElement.addEventListener('scroll', this.windowOnScrollHandle)
        }

        if (typeof LocalStore.getInstance().read('currentLoggedUser') === 'object') {
            const currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {}
            const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
            const shopkeeperAuth = lodash.get(currentLoggedUser, 'customerAuthorities.shopkeeper')
            const bifinEnabled = lodash.get(
                currentProjectInfo,
                'tenantConfig.externalIntegrationConfig.shopkeeper.enabled',
                false
            )
            const bifinEnabledPeerpayment = lodash.get(
                currentProjectInfo,
                'tenantConfig.externalIntegrationConfig.shopkeeper.enabledPeerpayment',
                false
            )
            this.setState({ paramCode: this.props.match.params.id }, () => {
                this.getPaymentStatus()
                this.getInternalPaymentStatus()
                this.getListOriginalReceipts()
                this.getDetail()
                this.loadFromQueryString()
                this.getGlobalTenantConfig()
                if (bifinEnabled && bifinEnabledPeerpayment && shopkeeperAuth) this.setState({ isEnabledBiffin: true })
            })
        }
    }

    componentDidUpdate(prevProps, prevState){
        const { dataDetail } = this.state
        if(prevState?.dataDetail?.id === undefined && dataDetail?.id){
            this.loadDataComments(initialCommentPage)
        }
    }

    componentWillUnmount() {
        this.isUnmounted = true
        window.removeEventListener('popstate', this.onPopState.bind(this))
        if (this.mainLayoutElement && this.mainLayoutElement.scrollLayoutElement)
            this.mainLayoutElement.scrollLayoutElement.removeEventListener('scroll', this.windowOnScrollHandle)
    }

    getGlobalTenantConfig = () => {
        CategoriesService.getTenantConfigPayment((error, response) => {
            if (!error) {
                this.setState({
                    globalTenantConfig: response,
                })
            } else {
                this.setState({
                    globalTenantConfig: {},
                })
            }
        })
    }

    /**
     * bắt sự kiện scroll để fixed comment box
     */
    windowOnScrollHandle = () => {
        const scrollLayoutElement = this.mainLayoutElement.scrollLayoutElement
        if (!this.commentComponent.col3Container) return
        const elementWidth = this.commentComponent.col3Container.getBoundingClientRect().width
        // nếu Offset scrollTop scroll element lớn hơn height của header = 66 thì fixed
        if (!this.isUnmounted)
            this.commentComponent.setState({
                commentBoxFixed: scrollLayoutElement.scrollTop > 66,
                commentBoxHeight: scrollLayoutElement.scrollTop > 66 ? window.innerHeight - 30 : window.innerHeight - 66 - 15,
                commentBoxFixedWidth: elementWidth,
            })
    }

    /**
     * On popstate
     */
    onPopState() {
        if (!this.isUnmounted)
            this.setState({ isLoading: true }, () => {
                this.loadFromQueryString()
            })
    }

    /**
     * Thay đổi tabbar
     * @param key
     */
    onChangedTab = key => {
        let queryString = updateQueryStringParameter('', 'tab', key)
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: queryString,
        })
    }

    /**
     * Load từ QueryString (dành cho lần load đầu tiên)
     */
    loadFromQueryString = () => {
        let queryObj = parseQueryStringToObject(getUrlQueryString())
        const { paramCode } = this.state

        let href = window.location.href

        if (!this.isUnmounted) {
            let idString = href.split('/#/peer-payments/')[1]

            if (idString) {
                let id = idString.split('?')[0]

                if (id !== paramCode) {
                    this.setState(
                        {
                            paramCode: id,
                        },
                        () => {
                            this.getPaymentStatus()
                            this.getListOriginalReceipts()
                            this.getDetail()
                            this.loadFromQueryString()
                            this.loadDataComments(initialCommentPage)
                        }
                    )
                }
            }

            //load active tab
            if (queryObj.tab) {
                this.setState({
                    activeTab: queryObj.tab,
                })
            }
        }
    }

    downloadFile = res => {
        if (res && res.headers && res.headers.map) {
            const fileName = res.headers.map['content-disposition'].split('filename=')[1]
            saveAs(res._bodyBlob, fileName)
        }
    }

    getDetail = () => {
        const { paramCode } = this.state
        this.setState(
            {
                isLoadingDetail: true,
                is404: false,
            },
            () => {
                UserService.getPeerPayment(paramCode, (err, res) => {
                    if (!err) {
                        this.setState({
                            isLoadingDetail: false,
                            dataDetail: res,
                        })
                    } else {
                        if (err.status === 404) {
                            this.setState({
                                isLoadingDetail: false,
                                is404: true,
                            })
                        } else {
                            this.setState({
                                isLoadingDetail: false,
                            })
                        }
                    }
                })
            }
        )
    }

    /**
     * lấy ra danh sách comment theo paramCode
     */
    loadDataComments = (page) => {
        let { listComments = [], commentLoading, dataDetail } = this.state
        if (page === undefined || page === null || page === 0) {
            page = 0
            listComments = []
        }

        if (commentLoading) return
        const { paramCode } = this.state
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const taiyiConfig = lodash.get(currentProjectInfo, 'tenantConfig.taiyiConfig.enabled')
        const enableTime = lodash.get(currentProjectInfo, 'tenantConfig.taiyiConfig.updateToNewCommentTime')
        const checkTimeNew = moment(dataDetail?.createdAt)?.isAfter(moment(enableTime))

        this.setState({ commentLoading: true, showProgressBar: true }, () => {
            if (taiyiConfig && checkTimeNew) {
                    UserService.getCommentsPosedon(paramCode, page, (err, res, headers) => {
                        if (err === null) {
                            this.setState(
                                {
                                    commentLoading: false,
                                    showProgressBar: false,
                                    listComments: [...listComments, ...res],
                                    listCommentsMetadata: {
                                        pageCount: headers['x-page-count'],
                                        page: headers['x-page-number'],
                                        size: headers['x-page-size'],
                                        total: headers['x-total-count'],
                                    },
                                },
                                () => {
                                    if (this.commentComponent) {
                                        if (page === 0) {
                                            this.commentComponent.chatbox.scrollTop = 0
                                        }
                                    }
                                }
                            )
                        } else {
                            this.setState({ commentLoading: false, showProgressBar: false })
                        }
                    })
                    UserService.getAttachmentPosedon(paramCode, (err, res, headers) => {
                        if (err === null) {
                            this.setState(
                                {
                                    listAttachments: res,
                                },
                            )
                        } 
                    })
            } else {
                UserService.getPeerPaymentComments(paramCode, page, (err, res, headers) => {
                    // console.log('api call', [...listComments, ...res.data]);

                    if (err === null) {
                        this.setState(
                            {
                                commentLoading: false,
                                showProgressBar: false,
                                listComments: [...listComments, ...res],
                                listCommentsMetadata: {
                                    pageCount: headers['x-page-count'],
                                    page: headers['x-page-number'],
                                    size: headers['x-page-size'],
                                    total: headers['x-total-count'],
                                },
                            },
                            () => {
                                if (this.commentComponent) {
                                    if (page === 0) {
                                        this.commentComponent.chatbox.scrollTop = 0
                                    }
                                }
                            }
                        )
                    } else {
                        this.setState({ commentLoading: false, showProgressBar: false })
                    }
                })
            }
        })
    }

    /**
     * lấy ra danh sách trạng thái của package
     */
    getPaymentStatus = () => {
        CategoriesService.getPaymentStatus((err, res) => {
            if (err === null) {
                this.setState({
                    listPaymentStatus: res || [],
                })
            }
        })
    }

    getInternalPaymentStatus = () => {
        CategoriesService.getInternalPaymentStatus((err, res) => {
            if (err === null) {
                this.setState({
                    listInternalPaymentStatus: res || [],
                })
            }
        })
    }

    /**
     * upload ảnh comment
     * @param data
     * @param cb
     */
    uploadImageComment = (data = {}, cb) => {
        const { t } = this.props
        const { isCommenting, isUploading, paramCode, dataDetail } = this.state
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const taiyiConfig = lodash.get(currentProjectInfo, 'tenantConfig.taiyiConfig.enabled')
        const enableTime = lodash.get(currentProjectInfo, 'tenantConfig.taiyiConfig.updateToNewCommentTime')
        const checkTimeNew = moment(dataDetail?.createdAt)?.isAfter(moment(enableTime))
        if (
            isCommenting === true ||
            isUploading === true ||
            (data?.content?.trim().replace(/<[^>]*>?/gm, '')?.length === 0 && data?.files?.length === 0)
        )
            return

        if (taiyiConfig && checkTimeNew) {
            if (data?.files?.length > 0) {
                this.setState({
                    isUploading: true,
                })
                let apiServiceUrl = `/customer/comments/attachments`
                uploadFile(
                    apiServiceUrl,
                    data.files || [],
                    {
                        command: {
                            module: 'CUSTOMER_PEER_PAYPEMT_MODULE',
                            thread: paramCode,
                        },
                    },
                    'attachment'
                ).then(response => {
                    let content = ''
                    if (response?.mimeType?.indexOf('image/') >= 0) {
                        content = `<img src="${response?.location}" alt="${response.name}"></img>`
                    } else {
                        content = `<a target="_blank" href="${response?.location}"><i class="fa-solid fa-paperclip"></i> ${response.name}</a>`
                    }
                    UserService.createCommentInPeerPayment(paramCode, { content }, (err, res, headers) => {
                        if (!err) {
                            this.loadDataComments()
                            this.setState({
                                isUploading: false,
                            })
                        } else {
                            notification['error']({
                                message: t(err.message),
                            })
                            this.setState({ isUploading: false, isCommenting: false })
                        }
                    })
                })
            } else {
                this.setState({
                    isCommenting: true,
                })
                UserService.createCommentInPeerPayment(paramCode, { content: data.content?.trim() }, (err, res, headers) => {
                    if (!err) {
                        this.setState(
                            {
                                isCommenting: false,
                            },
                            () => {
                                this.loadDataComments()
                            }
                        )
                    } else {
                        notification['error']({
                            message: t(err.message),
                        })
                        this.setState({ isUploading: false, isCommenting: false })
                    }
                })
            }
        } else {
            this.setState({ isUploading: true, isCommenting: true }, () => {
                let apiServiceUrl = `/customer/peer_payments/${paramCode}/comments`
                let body = data.content ? { comment: { content: data.content.trim() } } : null

                uploadFile(apiServiceUrl, data.files || [], body)
                    .then(response => {
                        this.setState(
                            {
                                isCommenting: false,
                                isUploading: false,
                            },
                            () => {
                                if (cb) cb()
                                this.loadDataComments()
                            }
                        )
                    })
                    .catch(error => {
                        notification['error']({
                            message: t(error.message),
                        })
                        this.setState({ isUploading: false, isCommenting: false })
                    })
            })
        }
    }

    /**
     * next comment page
     */
    nextCommentPage = () => {
        const { listCommentsMetadata } = this.state
        if (!listCommentsMetadata) return
        if (parseFloat(listCommentsMetadata.page) + 1 < parseFloat(listCommentsMetadata.pageCount)) {
            this.loadDataComments(parseFloat(listCommentsMetadata.page) + 1)
        }
    }


    onFocusInput = () => {
        setTimeout(() => {
            if (this.inputFocus) this.inputFocus.focus()
        }, 500)
    }

    /**
     * lấy ra danh sách trạng thái đơn
     */
    getListOriginalReceipts = cb => {
        const { paramCode } = this.state
        UserService.getListPeerPaymentOriginalReceipts(paramCode, (err, res, headers) => {
            if (!err) {
                this.setState(
                    {
                        listOriginalReceipts: lodash.sortBy(res, 'createdAt') || [],
                    },
                    () => {
                        if (cb) cb(lodash.sortBy(res, 'createdAt'))
                    }
                )
            }
        })
    }

    /**
     * Hủy YCTT
     */
    cancelPeerPayment = () => {
        const { paramCode } = this.state
        const { t } = this.props
        UserService.cancelPeerPayment(paramCode, (err, res, headers) => {
            if (!err) {
                notification['success']({
                    message: t('message.success'),
                })
                this.getDetail()
            }
        })
    }

    /**
     * lấy ra danh sách trạng thái đơn
     */
    addOriginalReceipts = (body, cb) => {
        const { t } = this.props
        const { paramCode, addOriginalReceiptsLoading, listOriginalReceipts = [] } = this.state
        if (addOriginalReceiptsLoading) return

        this.setState({ addOriginalReceiptsLoading: true }, () => {
            UserService.addPeerPaymentOriginalReceipt(paramCode, body, (err, res, headers) => {
                if (!err) {
                    this.setState(
                        {
                            listOriginalReceipts: lodash.sortBy([...listOriginalReceipts, res], 'createdAt'),
                        },
                        () => {
                            if (cb) cb(lodash.sortBy([...listOriginalReceipts, res], 'createdAt'))
                        }
                    )
                } else {
                    if (err.title === 'duplicate_code') {
                        notification['error']({
                            message: t('shipment.OriginalReceipt_duplicate_code'),
                        })
                    } else if (err.title === 'action_do_not_allow') {
                        notification['error']({
                            message: t('waybill.action_do_not_allow'),
                        })
                    } else {
                        notification['error']({
                            message: t(err.message),
                        })
                    }
                }
                this.setState({ addOriginalReceiptsLoading: false })
            })
        })
    }

    /**
     * lấy ra danh sách trạng thái đơn
     */
    deleteOriginalReceipts = (body = [], cb) => {
        const { t } = this.props
        const { paramCode, deleteOriginalReceiptLoading } = this.state
        if (deleteOriginalReceiptLoading) return
        this.setState({ deleteOriginalReceiptLoading: true }, () => {
            let query = '?listCodes='
            body.map((x, y) => {
                query = query + (y === 0 ? x : `,${x}`)
            })
            UserService.deletePeerPaymentOriginalReceipt(paramCode, query, (err, res, headers) => {
                if (!err) {
                    this.getListOriginalReceipts(cb)
                } else {
                    if (err.title === 'action_do_not_allow') {
                        notification['error']({
                            message: t('waybill.action_do_not_allow'),
                        })
                    } else {
                        notification['error']({
                            message: t(err.message),
                        })
                    }
                }

                this.setState({ deleteOriginalReceiptLoading: false })
            })
        })
    }

    getUserBalancePromise = () => {
        return new Promise((resolve, reject) => {
            UserService.getBalanceUser((error, response) => {
                if (error) reject(error)
                else resolve(response)
            })
        })
    }

    getExchangeRate = (query = '') => {
        UserService.getExchangeRatePeerPayMent(query, (err, res, headers) => {
            if (err === null) {
                this.setState(
                    {
                        exchangeRateByAmount: res || {},
                    },
                    () => {
                        setTimeout(() => {
                            this.setState({ isLoadingExchange: false })
                        }, 1000)
                    }
                )
            } else {
                this.setState({
                    isLoadingExchange: false,
                })
            }
        })
    }

    getUserBalance = () => {
        this.getUserBalancePromise().then(response => {
            this.setState({
                userBalance: {
                    balance: response.balance,
                    creditLimit: response.creditLimit,
                },
            })
        })
    }

    chargeRequest = async code => {
        const { t } = this.props
        const { chargeRequest, dataDetail } = this.state
        if (chargeRequest) return
        await this.getUserBalancePromise()
        await this.getUserBalance()
        this.setState({ chargeRequest: true }, () => {
            UserService.chargeRequest(code, (err, res, headers) => {
                if (err === null) {
                    this.setState({
                        chargeRequest: false,
                    })
                    this.getDetail()
                    notification['success']({
                        message: t('message.success'),
                    })
                } else {
                    if (err.title === 'invalid_amount') {
                        notification['error']({
                            message: 'Đã có thay đổi về dữ liệu, vui lòng tải lại trang.',
                        })
                    } else if (err.title === 'insufficient_balance') {
                        const { balance, creditLimit } = this.state.userBalance

                        const totalMoney = dataDetail.totalFee + dataDetail.exchangedAmount
                        const topUpMoney = totalMoney - (balance + creditLimit)

                        const compiled = lodash.template(t('cartCheckout.notEnoughMoney'))
                        notification['error']({
                            message: compiled({
                                money: moneyFormat(topUpMoney),
                            }),
                        })
                    } else {
                        notification['error']({
                            message: t(err.message),
                        })
                    }
                    this.setState({
                        chargeRequest: false,
                    })
                }
            })
        })
    }

    openConfirmPayment = () => {
        const { isOpenConfirmPayment } = this.state
        this.setState({
            isOpenConfirmPayment: !isOpenConfirmPayment,
        })
    }

    renderModalOriginalReceipts = () => {
        const { t } = this.props
        const {
            modalOriginalReceipts,
            listOriginalReceiptsState = [],
            newOriginalReceipt,
            listOriginalReceiptsDelete = [],
            addOriginalReceiptsLoading,
            deleteOriginalReceiptLoading,
        } = this.state
        return (
            <Modal
                title={t('shipment.editOriginalReceipt')}
                visible={modalOriginalReceipts}
                onOk={() => {
                    if (listOriginalReceiptsDelete.length > 0) {
                        this.deleteOriginalReceipts(
                            listOriginalReceiptsDelete.map(x => x.code),
                            () => this.setState({ modalOriginalReceipts: false, newOriginalReceipt: '' })
                        )
                    } else {
                        this.setState({
                            modalOriginalReceipts: false,
                            newOriginalReceipt: '',
                            listOriginalReceiptsDelete: [],
                        })
                    }
                }}
                confirmLoading={deleteOriginalReceiptLoading}
                onCancel={() =>
                    this.setState({
                        modalOriginalReceipts: false,
                        newOriginalReceipt: '',
                        listOriginalReceiptsDelete: [],
                    })
                }
                okText={t('button.save')}
                cancelText={t('modal.cancel')}
                okButtonProps={{ className: '_btn-submit-confirm' }}
                cancelButtonProps={{ className: '_btn-cancel-confirm' }}
            >
                <div className="pdbt5">{t('shipment.add_OriginalReceipt')}</div>
                <Input
                    addonAfter={
                        addOriginalReceiptsLoading ? (
                            <AntdIcon.Loading3QuartersOutlined
                                type="loading-3-quarters"
                                spin={true}
                            />
                        ) : (
                            <AntdIcon.PlusOutlined
                                type="plus"
                                onClick={() => {
                                    if (newOriginalReceipt && !addOriginalReceiptsLoading) {
                                        this.addOriginalReceipts({ code: newOriginalReceipt }, res => {
                                            this.setState({
                                                newOriginalReceipt: '',
                                                listOriginalReceiptsState: res || [],
                                                listOriginalReceiptsDelete: [],
                                            })
                                        })
                                    }
                                }}
                            />
                        )
                    }
                    value={newOriginalReceipt}
                    onChange={e => this.setState({ newOriginalReceipt: e.target.value })}
                    onKeyDown={e => {
                        if (e.keyCode === 13 && newOriginalReceipt) {
                            this.addOriginalReceipts({ code: newOriginalReceipt }, res => {
                                this.setState({
                                    newOriginalReceipt: '',
                                    listOriginalReceiptsState: res || [],
                                    listOriginalReceiptsDelete: [],
                                })
                            })
                        }
                    }}
                    ref={ref => (this.inputFocus = ref)}
                />
                <div className="pdt10">
                    <div className="pdbt5">{t('shipment.originalReceipts')}</div>
                    <div className="dpl-flex flex-wrap">
                        {listOriginalReceiptsState.map((x, y) => (
                            <div
                                key={y}
                                className="pd5 mgr5 mgbt5 bg-color-gray3 break-word"
                            >
                                {x.code}
                                <AntdIcon.CloseOutlined
                                    type="close"
                                    className="mgl5 cursor-pointer"
                                    onClick={() =>
                                        this.setState({
                                            listOriginalReceiptsState: listOriginalReceiptsState.filter(a => a.code !== x.code),
                                            listOriginalReceiptsDelete: [...listOriginalReceiptsDelete, x],
                                        })
                                    }
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </Modal>
        )
    }

    renderModalConfirmPayment = () => {
        const { t } = this.props
        const { isOpenConfirmPayment, dataDetail } = this.state
        if (!isOpenConfirmPayment) return <div></div>
        return (
            <ModalConfirmPayment
                t={t}
                peerPaymentData={dataDetail}
                chargeRequest={this.chargeRequest}
                onVisibleChange={() => {
                    this.openConfirmPayment()
                }}
            />
        )
    }

    render() {
        const {
            dataDetail = {},
            isLoadingDetail,
            listCommentsMetadata,
            is404,
            listPaymentStatus = [],
            listOriginalReceipts = [],
            activeTab,
            listInternalPaymentStatus,
            globalTenantConfig,
            exchangeRateByAmount = {},
            isLoadingExchange,
            listAttachments
        } = this.state

        const { t } = this.props
        if (is404) return <Page404 />

        let itemStatus = listPaymentStatus.find(x => x.code === dataDetail.status) || {}

        let stringListOriginalReceipts = ''
        if (Array.isArray(listOriginalReceipts)) {
            listOriginalReceipts.map((x, y) => {
                stringListOriginalReceipts += (y !== 0 ? ', ' : '') + x.code
            })
        } else {
            stringListOriginalReceipts = '---'
        }

        let newExchangedAmount = dataDetail.amount * exchangeRateByAmount.rate
        let arr = [
            {
                id: 1,
                oldLabel: `${t('peer_payment.old_exchangeRate')}:`,
                newLabel: `${t('peer_payment.new_exchangeRate')}:`,
                old: moneyFormat(dataDetail.exchangeRate),
                new: moneyFormat(exchangeRateByAmount.rate),
            },
            {
                id: 2,
                oldLabel: `${t('peer_payment.old_amount')}:`,
                newLabel: `${t('peer_payment.new_amount')}:`,
                old: moneyFormat(dataDetail.exchangedAmount),
                new: moneyFormat(newExchangedAmount),
            },
        ]

        const col = [
            {
                title: '',
                dataIndex: 'oldLabel',
                key: 'oldLabel',
            },
            {
                title: '',
                dataIndex: 'old',
                key: 'old',
                render: text => <div className="_old-value txt-color-red">{text}</div>,
            },
            {
                title: '',
                dataIndex: 'abc',
                key: 'abc',
                render: () => {
                    return '-->'
                },
            },
            {
                title: '',
                dataIndex: 'newLabel',
                key: 'newLabel',
            },
            {
                title: '',
                dataIndex: 'new',
                key: 'new',
                render: text => <div className="_new-value txt-color-green">{text}</div>,
            },
        ]

        if (is404) return <Page404 />

        const isShowShipmentCode =
            lodash.get(globalTenantConfig, 'config.shipmentRequired') === true &&
            listTypePeerPayment.includes(dataDetail.peerPaymentType)
        const { suspensionSchedule } = globalTenantConfig

        const isInSuspensionSchedule =
            suspensionSchedule && isInSuspensionTime(suspensionSchedule.startTime, suspensionSchedule.endTime)
        const contractWithShopkeeper = lodash.get(dataDetail, 'contractWithShopkeeper', '')

        let hasCredit = contractWithShopkeeper === 'CONTRACTED' || contractWithShopkeeper === 'NOSHOW'

        return (
            <MainLayout
                ref={ref => (this.mainLayoutElement = ref)}
                {...this.props}
                noFooter={true}
                headerTitle={
                    `${
                        dataDetail.peerPaymentType === listTypePeerPayment[1]
                            ? t('peer_payment.title_page_TBG')
                            : t('peer_payment.title_page')
                    } #` + (dataDetail.code || '')
                }
                title={dataDetail.code ? '#' + dataDetail.code : ''}
            >
                <div className={'container-common mgr20 mgbt20'}>
                    {isLoadingDetail ? (
                        <Spin
                            indicator={
                                <AntdIcon.Loading3QuartersOutlined
                                    type="loading"
                                    style={{ fontSize: 24 }}
                                    spin
                                />
                            }
                        />
                    ) : (
                        <Layout>
                            <Row>
                                <Col
                                    className={'pdr20'}
                                    span={17}
                                >
                                    <div className={'bg-color-white border-top-3x bd-color-green pdl15 pdr15'}>
                                        <div className="pdt15 pdbt15 border-bottom-1px-solid-gray dpl-flex justify-content-between align-items-center">
                                            <div className="dpl-flex align-items-center">
                                                <div>
                                                    <div className="_code bold mgr15">#{dataDetail.code}</div>
                                                    {hasCredit && (
                                                        <Tooltip
                                                            placement="top"
                                                            title={
                                                                <span>
                                                                    Tín dụng:{' '}
                                                                    {contractWithShopkeeper !== 'NOSHOW'
                                                                        ? `${t('peerPayment.loanApproved')}`
                                                                        : `${t('peerPayment.loanCanceled')}`}
                                                                </span>
                                                            }
                                                        >
                                                            <i
                                                                className={`fa-regular fa-credit-card text-base cursor-pointer ${
                                                                    contractWithShopkeeper === 'NOSHOW'
                                                                        ? 'text-red-500'
                                                                        : 'text-stone-500'
                                                                }`}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </div>
                                                <Tag
                                                    style={{ backgroundColor: `#${intToRGB(hashCode(dataDetail.status))}` }}
                                                    className={'_status listorders__items_right--status'}
                                                >
                                                    {itemStatus.name || dataDetail.status || '---'}
                                                </Tag>
                                            </div>

                                            <div className="dpl-flex align-items-center">
                                                {(dataDetail.status === 'WAIT_FOR_PAYMENT' ||
                                                    dataDetail.status === 'REQUEST_FOR_PAY') &&
                                                    dataDetail.totalUnpaid > 0 && (
                                                        <Popconfirm
                                                            disabled={isInSuspensionSchedule}
                                                            placement="topRight"
                                                            overlayClassName={`_pay-popconfirm-overlay`}
                                                            className={`_pay-popconfirm`}
                                                            title={
                                                                <div>
                                                                    <div>{t('message.delete_confirm')}</div>

                                                                    {isLoadingExchange && <Loading />}

                                                                    {!isLoadingExchange &&
                                                                        exchangeRateByAmount.rate !== dataDetail.exchangeRate &&
                                                                        !dataDetail.fixedExchangeRate && (
                                                                            <div className="dpl-flex pdt10">
                                                                                <div className="width-300">
                                                                                    {t('peer_payment.amount')}:{' '}
                                                                                    <span className="_amount-popup bold txt-color-green">
                                                                                        {moneyFormat(
                                                                                            dataDetail.amount,
                                                                                            dataDetail.currency
                                                                                        )}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        )}

                                                                    {!isLoadingExchange &&
                                                                        exchangeRateByAmount.rate !== dataDetail.exchangeRate &&
                                                                        !dataDetail.fixedExchangeRate && (
                                                                            <Table
                                                                                className="_table-data custom-empty table-hidden-header table-expand-row border-bottom-radius table-mini"
                                                                                rowKey={(x, y) => y}
                                                                                columns={col}
                                                                                dataSource={arr}
                                                                                rowClassName={() => `_table-row`}
                                                                                pagination={{
                                                                                    hideOnSinglePage: true,
                                                                                    pageSize: arr.length,
                                                                                }}
                                                                            />
                                                                        )}
                                                                </div>
                                                            }
                                                            onConfirm={() => this.chargeRequest(dataDetail.code)}
                                                            okText={t('button.yes')}
                                                            cancelText={t('button.no')}
                                                            onVisibleChange={visible => {
                                                                if (visible) {
                                                                    let query =
                                                                        `?amount=${dataDetail.amount}&paymentMethodCode=${dataDetail.paymentMethodCode}` +
                                                                        (dataDetail.peerPaymentType === 'taobao_global'
                                                                            ? `&peerPaymentType=taobao_global`
                                                                            : '')
                                                                    this.setState(
                                                                        {
                                                                            isLoadingExchange: true,
                                                                        },
                                                                        () => this.getExchangeRate(query)
                                                                    )
                                                                }
                                                            }}
                                                        >
                                                            <Button
                                                                className={`${
                                                                    isInSuspensionSchedule ? 'btn-disabled' : ''
                                                                } btn-payment-detail _btn-payment pd10 pdt5 pdbt5 border-radius8 cursor-pointer mgr10`}
                                                                type="primary"
                                                            >
                                                                {t('orderDetail.payment')}
                                                            </Button>
                                                        </Popconfirm>
                                                    )}
                                                {(dataDetail.status === 'WAIT_FOR_PAYMENT' ||
                                                    dataDetail.status === 'PAYMENT_PAID' ||
                                                    dataDetail.status === 'REQUEST_FOR_PAY') && (
                                                    <Popconfirm
                                                        placement="topRight"
                                                        overlayClassName={`_cancel-popconfirm-overlay`}
                                                        className={`_cancel-popconfirm`}
                                                        title={t('message.delete_confirm')}
                                                        onConfirm={() => this.cancelPeerPayment()}
                                                        okText={t('button.yes')}
                                                        cancelText={t('button.no')}
                                                    >
                                                        <div
                                                            className="_btn_cancel pd10 pdt5 pdbt5 border-radius8 bd-color-red border-1px-solid cursor-pointer"
                                                            style={{ color: '#F5222D' }}
                                                        >
                                                            {t('button.disagree')}
                                                        </div>
                                                    </Popconfirm>
                                                )}
                                            </div>
                                            {/*<Link*/}
                                            {/*    to={'/tickets'}*/}
                                            {/*    target={"_blank"}*/}
                                            {/*    className="pd10 pdt5 pdbt5 border-radius8 bd-color-red border-1px-solid cursor-pointer"*/}
                                            {/*    style={{color: '#F5222D'}}*/}
                                            {/*>*/}
                                            {/*    {t('tickets.complaint')}*/}
                                            {/*</Link>*/}
                                        </div>

                                        <div className="pdt15 pdbt15 border-bottom-1px-solid-gray dpl-flex justify-content-between align-items-center">
                                            <div>
                                                <span>{t('fee_tab.amount')}:</span>
                                                <span className="pdl15 txt-color-orange txt-size-h3 bold">
                                                    {moneyFormat(dataDetail.amount, dataDetail.currency)} -{' '}
                                                    {moneyFormat(dataDetail.exchangedAmount)}
                                                </span>
                                                <span className="pdl25">{t('mainLayout.exchange_rate')}:</span>
                                                {dataDetail.exchangeRate ? (
                                                    <span className="_exchangeRate pdl15 txt-color-orange txt-size-h4">
                                                        {moneyFormat(1, dataDetail.currency)} ={' '}
                                                        {moneyFormat(dataDetail.exchangeRate)}
                                                    </span>
                                                ) : (
                                                    <span className="_exchangeRate pdl15 txt-color-orange txt-size-h4">---</span>
                                                )}
                                            </div>
                                        </div>

                                        {listTypePeerPayment.includes(dataDetail.peerPaymentType) && (
                                            <div className="pdt15 pdbt15 border-bottom-1px-solid-gray dpl-flex align-items-center">
                                                <span>{t('shipment.originalReceipts')}:</span>

                                                <span className="_trxTime pdl15 txt-size-h4 txt-color-black">
                                                    {stringListOriginalReceipts}{' '}
                                                    <AntdIcon.EditOutlined
                                                        onClick={() =>
                                                            this.setState(
                                                                {
                                                                    modalOriginalReceipts: true,
                                                                    listOriginalReceiptsState: listOriginalReceipts,
                                                                },
                                                                () => {
                                                                    this.onFocusInput()
                                                                }
                                                            )
                                                        }
                                                        type="edit"
                                                        className="txt-color-blue cursor-pointer mgl5"
                                                    />
                                                </span>
                                            </div>
                                        )}

                                        {isShowShipmentCode && (
                                            <div className="pdt15 pdbt15 border-bottom-1px-solid-gray dpl-flex align-items-center">
                                                <span>{t('peer_payment.shipmentCode')}:</span>
                                                {lodash.get(dataDetail, 'shipmentCode') ? (
                                                    <Link
                                                        to={`/shipments/${lodash.get(dataDetail, 'shipmentCode')}`}
                                                        target={'_blank'}
                                                        className="txt-size-h4 pdl15"
                                                    >
                                                        {lodash.get(dataDetail, 'shipmentCode') || '---'}
                                                    </Link>
                                                ) : (
                                                    <span className="_trxTime pdl15 txt-size-h4 txt-color-black">
                                                        {lodash.get(dataDetail, 'shipmentCode') || '---'}
                                                    </span>
                                                )}
                                            </div>
                                        )}

                                        <div className="pdt15 pdbt15 border-bottom-1px-solid-gray dpl-flex align-items-center">
                                            <span>{t('peer_payment.trxTime')}:</span>
                                            <span className="_trxTime pdl15 txt-size-h4 txt-color-black">
                                                {dataDetail.trxTime
                                                    ? moment(dataDetail.trxTime).format('hh:mm DD/MM/YYYY')
                                                    : '---'}
                                            </span>
                                        </div>

                                        <div className="pdt15 pdbt15 border-bottom-1px-solid-gray dpl-flex align-items-center">
                                            <span>
                                                {dataDetail.peerPaymentType === 'transfer'
                                                    ? t('peer_payment.beneficiaryAccount')
                                                    : t('peer_payment.paymentAccount')}
                                                :
                                            </span>
                                            <span className="_peerPaymentType pdl15 txt-size-h4 txt-color-black">
                                                {dataDetail.peerPaymentType === 'transfer'
                                                    ? `${dataDetail.beneficiaryAccount} - ${dataDetail.beneficiaryBank} - ${
                                                          dataDetail.beneficiaryName
                                                      }${
                                                          !!dataDetail.beneficiaryBankBranch
                                                              ? ` - ${dataDetail.beneficiaryBankBranch}`
                                                              : ''
                                                      }`
                                                    : dataDetail.paymentAccount}
                                            </span>
                                        </div>

                                        <div className="pdt15 pdbt15 border-bottom-1px-solid-gray dpl-flex align-items-center">
                                            <span>{t('peer_payment.memo')}:</span>
                                            <span className="_memo pdl15 txt-size-h4 txt-color-black">
                                                {dataDetail.memo || '---'}
                                            </span>
                                        </div>

                                        <div className="pdt15 pdbt15 border-bottom-1px-solid-gray dpl-flex align-items-center">
                                            <span>{t('peer_payment.note')}:</span>
                                            <span className="_note pdl15 txt-size-h4 txt-color-black">
                                                {dataDetail.note || '---'}
                                            </span>
                                        </div>
                                    </div>

                                    {dataDetail.code && (
                                        <TabLayout
                                            t={t}
                                            dataDetail={dataDetail}
                                            listPaymentStatus={listPaymentStatus}
                                            listInternalPaymentStatus={listInternalPaymentStatus}
                                            onChangedTab={this.onChangedTab}
                                            activeTab={activeTab}
                                            location={this.props.location}
                                        />
                                    )}
                                </Col>
                                <div className={`ant-col-7 flex-column bg-color-white bd-rdu bd1px bd-color-gray boxchat`}>
                                    <Comment
                                        ref={e => (this.commentComponent = e)}
                                        mainLayoutElement={this.mainLayoutElement}
                                        t={t}
                                        currentLoggedUser={currentLoggedUser || {}}
                                        hasMore={
                                            listCommentsMetadata
                                                ? parseFloat(listCommentsMetadata.page) + 1 <
                                                  parseFloat(listCommentsMetadata.pageCount)
                                                : false
                                        }
                                        orderDetail={dataDetail}
                                        //action api
                                        // confirmDeleteToggle={this.deleteComment}
                                        listComments={this.state.listComments || []}
                                        loadDataComments={this.loadDataComments}
                                        nextCommentPage={this.nextCommentPage}
                                        commentLoading={this.state.commentLoading}
                                        isUploading={this.state.isUploading}
                                        uploadImageComment={this.uploadImageComment}
                                        listAttachments={listAttachments}
                                    />
                                </div>
                            </Row>
                        </Layout>
                    )}
                    {this.renderModalOriginalReceipts()}
                    {/* {this.renderModalConfirmPayment()} */}
                </div>
            </MainLayout>
        )
    }
}

export default withNamespaces()(Detail)
