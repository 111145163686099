import {Popconfirm, notification} from "antd";
import WithDrawalService from "../../services/withdrawal.service";

interface Props {
    requestCode: string
    t: any
    callbackGetData?: (filter: {}, notNeedLoading: boolean) => void,
    getLisWithdrawalStatus?: () => void,
    getListWithDrawalSlipStatistic?:() => void
}

export default function CancelRequestButton(props: Props) {
    const {t, requestCode, callbackGetData, getLisWithdrawalStatus,getListWithDrawalSlipStatistic} = props

    const cancelWithdrawalSlip = (withdrawalSlipCode: string) => {
        WithDrawalService.deleteWithDrawalSlip(withdrawalSlipCode, (err: any, res: any, headers: any) => {
            if(!err) {
                notification['success']({
                    message: t('request_withdraw.message_success'),
                });
                callbackGetData?.({}, true);
                getLisWithdrawalStatus?.();
                getListWithDrawalSlipStatistic?.()
            } else {
                notification['error']({
                    message: t(err.message),
                });
            }
        })
    }

    return (
        <Popconfirm
            placement="topRight"
            overlayClassName={`_address-delete-popconfirm-overlay`}
            className={`_address-delete-popconfirm`}
            title={<>{t('message.delete_confirm')}</>}
            onConfirm={() => cancelWithdrawalSlip(requestCode)}
            okText={<>{t('button.yes')}</>}
            cancelText={<>{t('button.no')}</>}
        >
            <span className={`_delete-btn underline cursor-pointer text-[#FF4559] robotoregular`}>Hủy</span>
        </Popconfirm>
    )
};
