/* eslint-disable array-callback-return */
/**
 * create by Taobao dev team 18/1/2018
 */
import React, { Component, Fragment } from 'react'
import { Avatar, Button, Checkbox, Col, Empty, Input, InputNumber, List, Popconfirm, Row, Table, Tooltip, Typography } from 'antd'
import * as AntdIcon from '@ant-design/icons'

import _, { find, isArray } from 'lodash'
import { checkIsLink, linkify, moneyCeil, moneyFormat, quantityFormat } from '../../utils/stringUtils'
import { modifyArrayDataSource } from '../../utils/arrayUltils'
import { LocalStore } from '../../utils/LocalStore'
import Modal from '../../components/commons/Modal'
const { TextArea } = Input

export default class CartGroup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            initLoading: true,
            dataList: _.sortBy(modifyArrayDataSource(props.dataSource), 'timestamp').reverse().slice(0, props.productPerMerchant),
            isModalOpen: false,
        }
    }

    componentDidMount() {
        this.setState({
            initLoading: false,
            originData: _.cloneDeep(_.sortBy(modifyArrayDataSource(this.props.dataSource), 'timestamp').reverse()),
        })
    }

    componentDidUpdate(prevProps) {
        if (
            JSON.stringify(prevProps.dataSource) !== JSON.stringify(this.props.dataSource) ||
            prevProps.productPerMerchant !== this.props.productPerMerchant
        ) {
            const { isLoadMore } = this.state
            this.setState({
                dataList: isLoadMore
                    ? _.sortBy(modifyArrayDataSource(this.props.dataSource), 'timestamp').reverse()
                    : _.sortBy(modifyArrayDataSource(this.props.dataSource), 'timestamp')
                        .reverse()
                        .slice(0, this.props.productPerMerchant),
                originData: _.cloneDeep(_.sortBy(modifyArrayDataSource(this.props.dataSource), 'timestamp').reverse()),
            })
        }
    }

    handleModal = () => {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        })
    }

    /**
     *  validate item
     */
    validateNumber = (value, item) => {
        const { t } = this.props
        if (!Number.isFinite(parseFloat(value))) {
            // console.log('isFinite', !Number.isFinite(value))
            return false
        }
        let { dataList } = this.state
        let error = false
        // lấy ra index của item đang thao tác
        let index = dataList.findIndex(x => x.id === item.id)
        // console.log('validateNumber', value)
        //required
        if (!value || parseFloat(value) <= 0) {
            error = true
            dataList[index].errorMessage = t('order.quantity_required')
        } else {
            value = parseFloat(value.replace(/,/g, ''))
            dataList[index].errorMessage = ''

            dataList[index].quantity = value
        }

        dataList[index].error = error

        this.setState({ dataList })
        return !dataList[index].error
    }

    /**
     * show warning khi vừa load data về
     * @param item
     */
    showWarningNumber = item => {
        const { t } = this.props
        let warning = false
        let error = false
        if (!item.quantity) {
            error = true
            item.errorMessage = t('order.quantity_required')
            item.warningMultiplesMessage = ''
            item.warningMaxMessage = ''
        } else {
            //kiểm tra bội số
            if (item.quantity % item.product.batchSize !== 0) {
                warning = true
                item.warningMultiplesMessage = `${t('order.multiples_message')} ${item.product.batchSize}`
            } else {
                item.warningMultiplesMessage = ''
            }

            //kiểm tra vượt quá hàng tồn
            if (item.quantity > item.stock) {
                warning = true
                item.warningMaxMessage = `${t('order.max_message')} ${quantityFormat(item.stock)}`
            } else {
                item.warningMaxMessage = ''
            }

            //kiểm tra mua ít hơn tối thiểu
            let totalQuantity = 0
            item.product.skus
                .filter(x => x.id !== item.id)
                .map(x => {
                    totalQuantity = totalQuantity + x.quantity
                })
            let minQuantity = item.product.minQuantity - totalQuantity

            if (item.quantity < minQuantity) {
                warning = true
                item.warningMinMessage = `${t('order.cannot_be_less_than')} ${item.product.minQuantity}`
            } else {
                // item.errorMessage = '';
                item.warningMinMessage = ''
            }
        }

        item.error = error
        item.warning = warning

        return item
    }

    /**
     * on blur event
     * @param value
     * @param item
     */
    onBlurNumber = (value, item) => {
        const { dataSource, updateSkuItem } = this.props
        let currentItem = Array.isArray(modifyArrayDataSource(dataSource))
            ? modifyArrayDataSource(dataSource).find(x => x.id === item.id)
            : {}
        // console.log('onBlurNumber', this.validateNumber(value, item))

        if (this.validateNumber(value, item) && parseFloat(value.replace(/,/g, '')) !== currentItem.quantity) {
            updateSkuItem(item.id, { quantity: parseFloat(value.replace(/,/g, '')) }, dataSource.id)
        }
    }

    /**
     * input change event
     * @param value
     * @param prop
     * @param item
     */
    onInputChange = (value, prop, item) => {
        let { dataList } = this.state
        // lấy ra index của item đang thao tác
        let index = dataList.findIndex(x => x.id === item.id)
        dataList[index][prop] = value
        this.setState({ dataList })
    }

    /**
     * Call func submit note order if Enter
     * @param e
     * @param prop
     * @param item
     * @return {*}
     */
    onKeyDown = (e, prop, item) => {
        // e.preventDefault();
        if (e.target.value !== '' && e.target.value.trim() === '') return
        let { dataList, originData } = this.state
        const { dataSource } = this.props

        // lấy ra index của item đang thao tác
        let index = dataList.findIndex(x => x.id === item.id)

        if ((e.metaKey || e.shiftKey || e.altKey || e.ctrlKey) && e.keyCode === 13) {
            if (dataList[index][prop] !== originData[index][prop]) {
                this.props.updateNoteSku(item, prop, dataSource.id, () => {
                    if (prop === 'remark') {
                        this.setState({
                            ['showRemarkSku' + item.id]: false,
                        })
                    } else {
                        this.setState({
                            ['showUserNote' + item.id]: false,
                            ['showNoteSku' + item.id]: false,
                        })
                    }
                })
            } else {
                if (prop === 'remark') {
                    this.setState({ ['showRemarkSku' + item.id]: false })
                } else if (prop === 'note') {
                    this.setState({ ['showNoteSku' + item.id]: false })
                    if (e.target.value === '') {
                        this.setState({ ['showUserNote' + item.id]: false })
                    }
                }
            }
        }
    }

    /**
     * Call func submit note order if click outside
     * @param value
     * @param prop
     * @param item
     * @return {*}
     */
    onBlurInput = (value, prop, item) => {
        if (value !== '' && value.trim() === '') return
        let { dataList, originData } = this.state
        const { dataSource } = this.props

        // lấy ra index của item đang thao tác
        let index = dataList.findIndex(x => x.id === item.id)
        if (dataList[index][prop] !== originData[index][prop]) {
            this.props.updateNoteSku(item, prop, dataSource.id, () => {
                if (prop === 'remark') {
                    this.setState({
                        ['showRemarkSku' + item.id]: false,
                    })
                } else {
                    this.setState({
                        ['showUserNote' + item.id]: false,
                        ['showNoteSku' + item.id]: false,
                    })
                }
            })
        } else {
            if (prop === 'remark') {
                this.setState({ ['showRemarkSku' + item.id]: false })
            } else if (prop === 'note') {
                this.setState({ ['showNoteSku' + item.id]: false })
                if (value === '') {
                    this.setState({ ['showUserNote' + item.id]: false })
                }
            }
        }
    }

    handleEditSalePrice = item => {
        this.props.onEditSkuItemSalePriceVisibleChange(item, true)
    }

    getMarketplaceByCode = (code) => {
        const { exchangeRateByMarketplaces } = this.props
        return find(exchangeRateByMarketplaces, (marketplace) => marketplace?.codeMarketplace === code) || {};
    }

    /**
     * render header of list
     * @returns {*}
     */
    renderHeaderList = () => {
        const { t, dataSource, deleteCartItem, onCheckAll, indeterminate, checkAll, configGroup, markupRateViewTemplate } =
            this.props
        let totalQuanty = 0
        modifyArrayDataSource(dataSource).map(item => {
            totalQuanty = totalQuanty + item.quantity
        })
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}

        const tenantConfig = currentProjectInfo.tenantConfig || {}
        let exchangeRate = LocalStore.getInstance().read('exchangeRates') || []

        const codeMarketplaceDataSource = dataSource?.marketplace?.code
        let exchangeRateMarketplace = this.getMarketplaceByCode(codeMarketplaceDataSource);

        const rate = Array.isArray(exchangeRate)
            ? exchangeRate.find(x => x.code === `${dataSource.marketplace.currency}/${tenantConfig.defaultCurrency}`)
            : null
        let textRender = ``
        let markupRateViewTemplateValid = isArray(markupRateViewTemplate) && markupRateViewTemplate?.length > 0 && markupRateViewTemplate?.every(i => i.price !== null)

        if (markupRateViewTemplate && isArray(markupRateViewTemplate) && rate && rate?.rate) {
            const newRate = exchangeRateMarketplace?.rate

            textRender = `${moneyFormat(1, exchangeRateMarketplace?.base)} = `
            let scope = configGroup?.markupRateScope
            let priceFirst = _.first(markupRateViewTemplate)?.price
            let priceLast = _.last(markupRateViewTemplate)?.price
            if (typeof scope === 'string' && scope.toLowerCase() === 'rate') {
                textRender += `${moneyFormat(
                    newRate + newRate * parseFloat(priceLast / 100)
                )} - ${moneyFormat(newRate + (newRate * parseFloat(priceLast / 100)))}`
            }

            if (typeof scope === 'string' && scope.toLowerCase() === 'value') {
                textRender += `${moneyFormat(newRate + parseFloat(priceFirst))} - ${moneyFormat(
                    newRate + parseFloat(priceLast)
                )}`
            }
        }

        return (
            <div className={'headerlist'}>
                <Row className={'headerlist__top'}>
                    <Col
                        span={24}
                        className="dpl-flex justify-content-between align-items-center"
                    >
                        <div className={'headerlist__top_right flex-2'}>
                            {dataSource.url ? (
                                <a
                                    href={dataSource.url}
                                    target={'_blank'}
                                    rel='noreferrer'
                                >
                                    <Avatar
                                        className={`_avatar-seller`}
                                        shape="square"
                                        size={24}
                                        src={dataSource.marketplace.image}
                                    />
                                </a>
                            ) : (
                                <Avatar
                                    className={`_avatar-seller`}
                                    shape="square"
                                    size={24}
                                    src={dataSource.marketplace.image}
                                />
                            )}
                            {dataSource.url ? (
                                <span className={'pdl10'}>
                                    {t('order.seller')}:{' '}
                                    <a
                                        href={dataSource.url}
                                        target={'_blank'}
                                        rel='noreferrer'
                                    >
                                        <span className={`_cart-seller txt-color-black`}>
                                            {dataSource.name || dataSource.code}
                                        </span>
                                    </a>
                                    <span className="txt-color-gray mgl10">|</span>
                                </span>
                            ) : (
                                <span className={'pdl10'}>
                                    {t('order.seller')}:{' '}
                                    <span className={`_cart-seller txt-color-black`}>{dataSource.name || dataSource.code}</span>
                                    <span className="txt-color-gray mgl10">|</span>
                                </span>
                            )}
                            <Popconfirm
                                overlayClassName={`_btn-delete-shop-popconfirm-overlay`}
                                className={`_btn-delete-shop-popconfirm`}
                                title={t('message.delete_confirm')}
                                onConfirm={() => deleteCartItem(dataSource.id)}
                                okText={t('button.yes')}
                                cancelText={t('button.no')}
                            >
                                <span className={`_delete-shop txt-color-gray txt-size-h8 robotoregular cursor-pointer mgl10`}>
                                    {t('cartGroup.delete_seller')}
                                </span>
                            </Popconfirm>
                            {_.get(configGroup, 'markupRateScope') &&
                                markupRateViewTemplateValid &&
                                markupRateViewTemplate &&
                                !_.isEmpty(markupRateViewTemplate) &&
                                markupRateViewTemplate.length > 0 ? (
                                <>
                                    <span className="txt-color-gray mgl10">|</span>
                                    <Button
                                        type="default"
                                        className="ml-2"
                                        onClick={this.handleModal}
                                    >
                                        {t('peer_payment.exchange_range')} {textRender}
                                    </Button>
                                </>
                            ) : (
                                rate &&
                                exchangeRate.length > 1 && (
                                    <span className={'txt-color-gray'}>
                                        <span className="txt-color-gray mgl10 mgr10">|</span>
                                        <span className={'txt-size-h8 txt-color-gray robotoregular'}>{t('header.exchange')}</span>
                                        <span className={'_header-exchange-rate txt-color-black robotoregular txt-size-h7 mgl5'}>
                                            {moneyFormat(1, rate.base)} = {moneyFormat(rate.rate, rate.exchange)}
                                        </span>
                                    </span>
                                )
                            )}
                        </div>
                        <div className={'headerlist__top_right flex-1 txt-color-gray txt-right'}>
                            <span className="txt-size-h8 txt-color-gray robotoregular mgr5">{t('order.quantity')}:</span>
                            <span className={`txt-color-black _cart-totalItems`}>
                                <span className="txt-size-h7 robotomedium txt-color-black">{quantityFormat(totalQuanty)}</span>
                                <span className="txt-size-h8 robotoregular txt-color-black"> {t('order.products')}</span>{' '}
                                <span className="txt-size-h7 robotomedium txt-color-black">
                                    / {modifyArrayDataSource(dataSource).length}
                                </span>{' '}
                                <span className="txt-size-h8 robotoregular txt-color-black">{t('cartGroup.link')} </span>
                                <Tooltip
                                    placement="top"
                                    title={t('cartGroup.product_quantity')}
                                >
                                    <AntdIcon.QuestionCircleOutlined
                                        type="question-circle"
                                        theme="filled"
                                        className={'txt-size-h8 txt-color-gray opacity6 mgl5'}
                                    />
                                </Tooltip>
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row className={'headerlist__title txt-color-black'}>
                    <Col
                        span={1}
                        className={'text-center'}
                    >
                        <Checkbox
                            className={`_cart-checkAll`}
                            indeterminate={indeterminate}
                            onChange={e => onCheckAll(e, dataSource)}
                            checked={checkAll}
                        />
                    </Col>
                    <Col span={10}>{t('order.products')}</Col>
                    <Col span={13}>
                        <Col span={7}>{t('order.quantity')}</Col>
                        <Col span={7}>{t('order.sale_price')}</Col>
                        <Col span={7}>{t('order.total_price')}</Col>
                        <Col span={3} />
                    </Col>
                </Row>
            </div>
        )
    }

    /**
     * render item of list
     * @param item
     * @param index
     * @returns {*}
     */
    renderListItem = (item, index) => {
        const {
            t,
            deleteSkuItem,
            isLoadingDisable,
            addItemWishlist,
            onItemCheck,
            dataSource,
            checkedList,
            cartLanguage,
            currentLoggedUser,
        } = this.props
        const { originData, itemsSaved = [], dataList = [] } = this.state
        item = this.showWarningNumber(item)
        let indexOriginData = originData && originData.findIndex(x => x.id === item.id)
        let remarkCurrent = originData && originData[indexOriginData] ? originData[indexOriginData].remark : ''
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const tenantConfig = currentProjectInfo.tenantConfig || {}
        const orderConfig = tenantConfig.orderConfig || {}
        const isShowEditIcon = _.get(currentLoggedUser, 'customerAuthorities.editCart')

        return (
            <React.Fragment>
                {item.warningMaxMessage || item.warningMinMessage ? (
                    <List.Item className={`_cart-item-row-error-${index} list-alert-warning`}>
                        <Row>
                            <Col
                                span={24}
                                className={'txt-warning warning-row'}
                            >
                                <AntdIcon.ExclamationCircleOutlined
                                    type="exclamation-circle"
                                    className={'pdr5'}
                                />{' '}
                                {t('cartGroup.quantity_warning_message')}
                            </Col>
                        </Row>
                    </List.Item>
                ) : null}
                <List.Item className={`_cart-item-${index} position-re`}>
                    <Col
                        span={1}
                        className={'text-center'}
                    >
                        <Checkbox
                            className={`_cart-item-checkbox-${index}`}
                            onChange={() => onItemCheck(item, dataSource)}
                            checked={Array.isArray(checkedList) && checkedList.find(x => x.id === item.id) !== undefined}
                        />
                    </Col>
                    <Col
                        span={10}
                        className="pdr10 dpl-flex align-items-start"
                    >
                        {item.product.url ? (
                            <a
                                href={item.product.url}
                                target={'_blank'} rel="noreferrer"
                            >
                                <Avatar
                                    className={`_cart-item-avatar-${index} bd1px bd-color-gray`}
                                    shape="square"
                                    size={60}
                                    src={item.image}
                                />
                            </a>
                        ) : (
                            <Avatar
                                className={`_cart-item-avatar-${index} bd1px border-color-gray`}
                                shape="square"
                                size={60}
                                src={item.image}
                            />
                        )}
                        <div className={'pdl10'}>
                            {item.product.url ? (
                                <a
                                    href={item.product.url}
                                    target={'_blank'} rel="noreferrer"
                                >
                                    <h3 className={`_cart-item-name-${index} txt-size-h7 txt-color-black break-word`}>
                                        {cartLanguage === 'CN' ? item.product.originalName : item.product.name}
                                    </h3>
                                </a>
                            ) : (
                                <h3 className={`_cart-item-name-${index} txt-size-h7 txt-color-black break-word`}>
                                    {cartLanguage === 'CN' ? item.product.originalName : item.product.name}
                                </h3>
                            )}
                            {item.variantProperties.map((x, y) => {
                                return (
                                    <span
                                        key={y}
                                        className={`_cart-item-properties-${item.id}-${y} txt-size-h8 txt-color-gray ${y !== 0 ? 'pdl10' : ''
                                            }`}
                                    >
                                        {cartLanguage === 'CN' ? x.originalValue : x.value}
                                    </span>
                                )
                            })}
                        </div>
                    </Col>
                    <Col span={13}>
                        <Row>
                            <Col
                                span={7}
                                className={'position-re'}
                            >
                                <InputNumber
                                    disabled={isLoadingDisable}
                                    precision={0} // bắt chỉ cho nhập integer
                                    className={`_cart-item-number-${index} ipcustom ${item.error ? 'has-error' : item.warning ? 'has-warning' : ''
                                        }`}
                                    min={1}
                                    placeholder={t('order.quantity')}
                                    value={item.quantity}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={value => {
                                        if (typeof value === 'number') {
                                            this.onInputChange(value, 'quantity', item)
                                        }
                                    }}
                                    onBlur={e => this.onBlurNumber(e.target.value, item)}
                                    onKeyDown={e => {
                                        //detect ENTER
                                        if (e.keyCode === 13) this.onBlurNumber(e.target.value, item)
                                    }}
                                />
                            </Col>
                            <Col span={7}>
                                <h3 className={`_cart-item-price-${index} txt-size-h7 txt-color-black break-word pdr5`}>
                                    {item.bargainPrice !== null ? (
                                        <>
                                            <strike className={'txt-color-gray'}>
                                                {moneyFormat(moneyCeil(item.exchangedSalePrice))}
                                            </strike>{' '}
                                            / <b>{moneyFormat(moneyCeil(item.exchangedBargainPrice))}</b>
                                        </>
                                    ) : (
                                        <span>{moneyFormat(moneyCeil(item.exchangedSalePrice))}</span>
                                    )}
                                </h3>
                                <span className={`_cart-item-price-origin-${index} txt-size-h8 break-word pdr5`}>
                                    {item.bargainPrice !== null ? (
                                        <>
                                            <Tooltip title={t('cart.originalPrice_bargainPrice')}>
                                                <AntdIcon.InfoCircleOutlined className={'cursor-pointer mgr5'} />
                                            </Tooltip>
                                            <span className={'mgr5'}>
                                                <strike className={'txt-color-gray'}>
                                                    {moneyFormat(item.salePrice, item.currency)}
                                                </strike>{' '}
                                                / <b>{moneyFormat(item.bargainPrice, item.currency)}</b>
                                            </span>
                                        </>
                                    ) : (
                                        <span className={'mgr5'}>{moneyFormat(item.salePrice, item.currency)}</span>
                                    )}

                                    {isShowEditIcon && (
                                        <Typography.Link>
                                            <AntdIcon.EditOutlined
                                                className={'cursor-pointer'}
                                                onClick={() => this.handleEditSalePrice(item)}
                                            />
                                        </Typography.Link>
                                    )}
                                </span>
                            </Col>
                            <Col span={7}>
                                <h3 className={`_cart-item-totalPrice-${index} txt-size-h7 txt-color-black break-word`}>
                                    {moneyFormat(moneyCeil(item.exchangedTotalAmount))}
                                </h3>
                                <span className={`_cart-item-totalPrice-origin-${index} txt-size-h8 break-word`}>
                                    {item.bargainPrice !== null ? (
                                        <>
                                            <strike className={'txt-color-gray'}>
                                                {moneyFormat(item.salePrice * item.quantity, item.currency)}
                                            </strike>{' '}
                                            / <b>{moneyFormat(item.bargainPrice * item.quantity, item.currency)}</b>
                                        </>
                                    ) : (
                                        <span>{moneyFormat(item.salePrice * item.quantity, item.currency)}</span>
                                    )}
                                </span>
                            </Col>
                            <Col span={3}>
                                <Typography.Link
                                    onClick={() =>
                                        addItemWishlist(item.id, () => this.setState({ itemsSaved: [...itemsSaved, item.id] }))
                                    }
                                    className={`_cart-item-btn-save-${index} cursor-pointer txt-size-h8 robotoregular`}
                                >
                                    {itemsSaved.indexOf(item.id) > -1 ? (
                                        <AntdIcon.HeartOutlined
                                            type="heart"
                                            theme="filled"
                                            twoToneColor="#1890ff"
                                            className={'txt-size-h7'}
                                        />
                                    ) : (
                                        <AntdIcon.HeartOutlined
                                            type="heart"
                                            className={'txt-size-h7'}
                                        />
                                    )}{' '}
                                    {t('button.save')}
                                </Typography.Link>
                            </Col>
                        </Row>
                        <Row>
                            {item.errorMessage && (
                                <span className={`_errorMessage-${index} txt-error dpl-block`}>{item.errorMessage}</span>
                            )}
                            {item.warningMinMessage && (
                                <span className={`_warningMinMessage-${index} txt-warning dpl-block`}>
                                    {item.warningMinMessage}
                                </span>
                            )}
                            {item.warningMaxMessage && (
                                <span className={`_warningMaxMessage-${index} txt-warning dpl-block`}>
                                    {item.warningMaxMessage}
                                </span>
                            )}
                            {item.warningMultiplesMessage && (
                                <span className={`_warningMultiplesMessage-${index} txt-warning dpl-block`}>
                                    {item.warningMultiplesMessage}
                                </span>
                            )}
                        </Row>
                        <Row>
                            <Col
                                span={24}
                                className={'mgt10'}
                            >
                                {orderConfig.productRemarkEnabled ? (
                                    !remarkCurrent || this.state['showRemarkSku' + item.id] ? (
                                        <Fragment>
                                            <TextArea
                                                value={item.remark}
                                                maxLength={1000}
                                                autosize={{ minRows: 1, maxRows: 3 }}
                                                className={`_cart-item-product-note-${index}`}
                                                placeholder={t('order.remark')}
                                                onChange={e => this.onInputChange(e.target.value, 'remark', item)}
                                                onKeyDown={e => this.onKeyDown(e, 'remark', item)}
                                                onBlur={e => this.onBlurInput(e.target.value, 'remark', item)}
                                                disabled={isLoadingDisable}
                                                ref={ref => (this[`remark${item.id}`] = ref)}
                                            />
                                            <span className={`txt-size-h8 txt-color-gray dpl-block`}>
                                                {t('cartGroup.manipulation_note')}
                                            </span>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <span
                                                className="txt-color-gray robotobold txt-size-h8 bold"
                                                style={{ display: 'inline' }}
                                            >
                                                {t('cartGroup.product_note')}:
                                            </span>
                                            {checkIsLink(item.remark) ? (
                                                <div
                                                    className="txt-color-black txt-size-h8 robotoregular white-space-pre-wrap pdl5 break-word mgr10"
                                                    style={{ display: 'inline' }}
                                                    dangerouslySetInnerHTML={{ __html: linkify(item.remark) }}
                                                />
                                            ) : (
                                                <div
                                                    className="txt-color-black txt-size-h8 robotoregular white-space-pre-wrap pdl5 break-word mgr10"
                                                    style={{ display: 'inline' }}
                                                >
                                                    {item.remark}
                                                </div>
                                            )}
                                            <Typography.Link>
                                                <AntdIcon.EditOutlined
                                                    className={`_btn-edit-remark-sku-${index} txt-size-h7`}
                                                    type="edit"
                                                    onClick={() =>
                                                        this.setState(
                                                            {
                                                                ['showRemarkSku' + item.id]:
                                                                    !this.state['showRemarkSku' + item.id],
                                                            },
                                                            () => {
                                                                this[`remark${item.id}`].focus()
                                                            }
                                                        )
                                                    }
                                                />
                                            </Typography.Link>
                                        </Fragment>
                                    )
                                ) : (
                                    <div />
                                )}
                            </Col>

                            <Col span={24}>
                                {this.state['showUserNote' + item.id] ? (
                                    <Fragment>
                                        <TextArea
                                            value={item.note}
                                            maxLength={1000}
                                            autosize={{ minRows: 1, maxRows: 3 }}
                                            className={`_cart-item-user-note-${index} mgt10`}
                                            placeholder={t('order.note')}
                                            onChange={e => this.onInputChange(e.target.value, 'note', item)}
                                            onKeyDown={e => this.onKeyDown(e, 'note', item)}
                                            onBlur={e => this.onBlurInput(e.target.value, 'note', item)}
                                            disabled={isLoadingDisable}
                                            ref={ref => (this[`noteSkus${item.id}`] = ref)}
                                        />
                                        <span className={`txt-size-h8 txt-color-gray dpl-block`}>
                                            {t('cartGroup.manipulation_note')}
                                        </span>
                                    </Fragment>
                                ) : this.state['showNoteSku' + item.id] === true ? (
                                    <Fragment>
                                        <TextArea
                                            value={item.note}
                                            maxLength={1000}
                                            autosize={{ minRows: 1, maxRows: 3 }}
                                            className={`_cart-item-user-note-${index} mgt10`}
                                            placeholder={t('order.note')}
                                            onChange={e => this.onInputChange(e.target.value, 'note', item)}
                                            onKeyDown={e => this.onKeyDown(e, 'note', item)}
                                            onBlur={e => this.onBlurInput(e.target.value, 'note', item)}
                                            disabled={isLoadingDisable}
                                            ref={ref => (this[`noteSkus${item.id}`] = ref)}
                                        />
                                        <span className={`txt-size-h8 txt-color-gray dpl-block`}>
                                            {t('cartGroup.manipulation_note')}
                                        </span>
                                    </Fragment>
                                ) : item.note ? (
                                    <Fragment>
                                        <span
                                            className="txt-color-gray txt-size-h8 robotobold bold"
                                            style={{ display: 'inline' }}
                                        >
                                            {t('cartGroup.product_personal_note')}:
                                        </span>
                                        {checkIsLink(item.note) ? (
                                            <div
                                                className="txt-color-black txt-size-h8 robotoregular white-space-pre-wrap pdl5 break-word mgr5"
                                                style={{ display: 'inline' }}
                                                dangerouslySetInnerHTML={{ __html: linkify(item.note) }}
                                            />
                                        ) : (
                                            <div
                                                className="txt-color-black txt-size-h8 robotoregular white-space-pre-wrap pdl5 break-word mgr5"
                                                style={{ display: 'inline' }}
                                            >
                                                {item.note}
                                            </div>
                                        )}
                                        <AntdIcon.EditOutlined
                                            className={`_btn-edit-note-sku-${index} txt-color-blue txt-size-h7`}
                                            type="edit"
                                            onClick={() =>
                                                this.setState(
                                                    { ['showNoteSku' + item.id]: !this.state['showNoteSku' + item.id] },
                                                    () => {
                                                        this[`noteSkus${item.id}`].focus()
                                                    }
                                                )
                                            }
                                        />
                                    </Fragment>
                                ) : (
                                    <Typography.Link
                                        className={`_cart-item-showUserNote-btn-${index} txt-size-h8 robotoregular`}
                                        onClick={() =>
                                            this.setState(
                                                { ['showUserNote' + item.id]: !this.state['showUserNote' + item.id] },
                                                () => {
                                                    this[`noteSkus${item.id}`].focus()
                                                }
                                            )
                                        }
                                    >
                                        {t('order.note')}{' '}
                                        <Tooltip
                                            placement="top"
                                            title={t('cartGroup.order_note_content')}
                                        >
                                            <AntdIcon.QuestionCircleOutlined
                                                type="question-circle"
                                                theme="filled"
                                                className={'txt-size-h8 txt-color-gray opacity6'}
                                            />
                                        </Tooltip>
                                    </Typography.Link>
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Popconfirm
                        overlayClassName={`_btn-delete-order-overlay-${index}`}
                        className={`_btn-delete-order-${index} btn-delete-cart-item btn__delete`}
                        title={t('message.delete_confirm')}
                        onConfirm={() => deleteSkuItem(item.id, dataSource, dataList.length === 1)}
                        okText={t('button.yes')}
                        cancelText={t('button.no')}
                    >
                        <AntdIcon.DeleteOutlined type="delete" />
                    </Popconfirm>
                </List.Item>
            </React.Fragment>
        )
    }

    /**
     * render loadmore button
     * @returns {null}
     */
    renderLoadMore = () => {
        const { t, dataSource, productPerMerchant } = this.props
        const { initLoading, loading, isLoadMore } = this.state

        return !initLoading &&
            !loading &&
            Array.isArray(modifyArrayDataSource(dataSource)) &&
            modifyArrayDataSource(dataSource).length > productPerMerchant ? (
            !isLoadMore ? (
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: 12,
                        height: 32,
                        lineHeight: '32px',
                    }}
                >
                    <Button
                        className={`_cart-btn-loadmore`}
                        onClick={this.onLoadMore}
                    >
                        {t('button.loadmore')}
                    </Button>
                </div>
            ) : (
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: 12,
                        height: 32,
                        lineHeight: '32px',
                    }}
                >
                    <Button
                        className={`_cart-btn-collapse`}
                        onClick={this.onLoadMore}
                    >
                        {t('button.collapse')}
                    </Button>
                </div>
            )
        ) : null
    }

    /**
     * loadmore data
     */
    onLoadMore = () => {
        const { dataSource, productPerMerchant } = this.props
        let { dataList } = this.state
        let isLoadMore = false
        this.setState(
            {
                loading: true,
                initLoading: true,
            },
            () => {
                setTimeout(() => {
                    if (dataList.length === modifyArrayDataSource(dataSource).length) {
                        dataList = _.sortBy(modifyArrayDataSource(dataSource), 'timestamp').reverse().slice(0, productPerMerchant)
                    } else {
                        dataList = _.sortBy(modifyArrayDataSource(dataSource), 'timestamp').reverse()
                        isLoadMore = true
                    }
                    this.setState({
                        isLoadMore,
                        dataList,
                        loading: false,
                        initLoading: false,
                    })
                }, 500)
            }
        )
    }

    render() {
        const { initLoading, dataList } = this.state
        const { t, checkedList = [], dataSource, configGroup, markupRateViewTemplate } = this.props
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const tenantConfig = currentProjectInfo.tenantConfig || {}

        const codeMarketplaceDataSource = dataSource?.marketplace?.code
        let exchangeRateMarketplace = this.getMarketplaceByCode(codeMarketplaceDataSource);
        const newRate = exchangeRateMarketplace?.rate
        const maxSkus = tenantConfig.maxSkuByMerchant


        const columns = [
            {
                title: t('peer_payment.range'),
                dataIndex: 'fromAmount',
                key: 'fromAmount',
            },
            {
                title: t('peer_payment.exchage_rate'),
                dataIndex: 'price',
                key: 'price',
                className: 'txt-right',
                render: (text, row) => {
                    let data = 0
                    if (configGroup?.markupRateScope?.toLowerCase() === 'value' && newRate) {
                        data = newRate + (text ? parseFloat(text) : newRate)
                    }

                    if (configGroup?.markupRateScope?.toLowerCase() === 'rate') {
                        data = newRate + newRate * (parseFloat(text) / 100)
                    }
                    return moneyFormat(data,)
                },
            },
        ]

        return (
            <React.Fragment>
                {maxSkus && checkedList.length > maxSkus ? (
                    <List.Item className={`_cart-item-row-error list-alert-error`}>
                        <Row>
                            <Col
                                span={24}
                                className={'txt-error'}
                            >
                                <AntdIcon.ExclamationCircleOutlined
                                    type="exclamation-circle"
                                    className={'pdr5'}
                                />{' '}
                                {t('cart.maxSkusError1')} {dataSource.name || dataSource.code} {t('cart.maxSkusError2')}{' '}
                                {quantityFormat(maxSkus)}
                            </Col>
                        </Row>
                    </List.Item>
                ) : null}
                <List
                    className={`_item-merchant list-carts mgbt25`}
                    header={this.renderHeaderList()}
                    dataSource={dataList}
                    renderItem={(item, index) => this.renderListItem(item, index)}
                    loading={initLoading}
                    loadMore={this.renderLoadMore()}
                />
                {dataList.length === 0 && (
                    <Empty
                        className={'mgbt25'}
                        image={require('../../resources/images/empty-image.png')}
                        description={t('message.empty')}
                    />
                )}

                {this.state.isModalOpen && (
                    <Modal
                        title="Tỷ giá"
                        visible={this.state.isModalOpen}
                        onCancel={this.handleModal}
                    >
                        <Table
                            className={'_table-data custom-empty table-expand-row '}
                            rowKey={(x, y) => y}
                            columns={columns}
                            dataSource={markupRateViewTemplate}
                            rowClassName={() => `_table-row`}
                            pagination={false}
                        />
                    </Modal>
                )}
            </React.Fragment>
        )
    }
}
