import React, {Component} from 'react'
import DocumentTitle from 'react-document-title'
import BarLoader from '../../components/Spinner/BarLoader'


export default class SplashScreen extends Component {
    render() {
        return (
            <DocumentTitle title="Loading...">
                <div className={'splash-container'}>
                    <div className={'text-center margin-auto utmhelve-regular'}>
                        {/*<img src={logo} alt={"Ibizan"} className={"logo-image"}/>*/}
                        <BarLoader
                            className="margin: auto; margin-top: 10px"
                            sizeUnit={"px"}
                            size={30}
                            color={'#1890ff'}
                            loading={true}
                        />
                    </div>
                </div>
            </DocumentTitle>
        )
    }
}
