import { Button, Col, DatePicker, Row } from "antd"
import moment from "moment";
import { isEmpty } from "lodash";
import { Bank, StatisticsItem } from "../../types/RequestWithDrawal";
import { quantityFormat } from "../../utils/stringUtils";
import InputItem from "../commons/Input";
import SuperSelect from "../commons/Select/SuperSelect";
import FloatLabel from "../commons/Input/FloatLabel";

interface FilterProps {
    t: any,
    statuses: [];
    statistics: StatisticsItem[];
    listStatusChecked: any;
    onSearch: () => void;
    query: string;
    selectedBank: string;
    numberBank: string;
    listBank: Array<Bank>;
    timestampFrom: string | undefined;
    timestampTo: string | undefined;
    onChangeTimeStampFrom: (date: any) => void;
    onChangeTimeStampTo: (date: any) => void;
    onChangeQuerySearch: (value: string) => void;
    onClearSearch: () => void;
    onChangeListStatues: (value: any) => void;
    onCheckBoxCheck: (item: any) => void;
    onChangeSelectedBank: (value: string) => void;
    onChangeNumberBank: (value: string) => void;
}

const Filter = (props: FilterProps) => {
    const {
        t,
        statuses,
        statistics,
        listStatusChecked,
        query,
        timestampFrom,
        timestampTo,
        selectedBank,
        listBank,
        numberBank,
        onSearch,
        onChangeTimeStampFrom,
        onChangeTimeStampTo,
        onChangeQuerySearch,
        onClearSearch,
        onChangeNumberBank,
        onCheckBoxCheck,
        onChangeSelectedBank
    } = props

    const search = () => {
        if (timestampFrom || timestampTo || query !== '' || !isEmpty(listStatusChecked) || numberBank || selectedBank) {
            onSearch()
        }
    }

    const disabledBtnSearch = !query && !timestampFrom && !timestampTo && isEmpty(listStatusChecked) && !numberBank && !selectedBank

    return (
        <div className="searchbox v2-layout bg-color-white py-3 px-6 shadow-box rounded-md">
            <div className='flex justify-between items-center mb-4'>
                <h2 className='text-lg capitalize cursor-pointer robotomedium w-full'>{t('order.search')}</h2>
            </div>
            <Row className={'dpl-flex bd-color-gray '}>
                <Col span={4} className="pr-4">
                    <InputItem
                        title={t('request_withdraw.code')}
                        className={"_search-box-input-query"}
                        onKeyDown={(e: any) => {
                            if (e.keyCode === 13) {
                                onSearch()
                            }
                        }}
                        onChange={(e: any) => onChangeQuerySearch(e.currentTarget.value)}
                        value={query || ''}
                        allowClear
                    />
                </Col>
                <Col span={6} className="">
                    <div className='datepicker-range w-full flex items-center border rounded-lg border-solid border-[#C0C0C0]'>
                        <div className='datepicker-field flex-1'>
                            <DatePicker
                                className={`_order-date-from v2-layout robotoregular w-full ${isEmpty(timestampFrom) ? '' : 'input-has-value'}`}
                                popupClassName={`v2-layout`}
                                locale={"vi" as any}
                                suffixIcon={null}
                                value={timestampFrom ? moment(timestampFrom) : undefined}
                                showTime={
                                    {
                                        format: 'HH:mm',
                                        defaultValue: moment('00:00:00', 'HH:mm')
                                    }
                                }
                                bordered={false}
                                format={'DD/MM/YYYY'}
                                placeholder=""
                                onChange={onChangeTimeStampFrom}
                            />
                            <label className='robotoregular form-label text-grey-400'>{t('request_withdraw.from_date')}</label>
                        </div>
                        <i className="fa-solid fa-arrow-right text-xs text-grey-500" />
                        <div className='datepicker-field flex-1'>
                            <DatePicker
                                className={`_order-date-to robotoregular w-full ${isEmpty(timestampTo) ? '' : 'input-has-value'}`}
                                popupClassName={`v2-layout`}
                                showTime={
                                    {
                                        format: 'HH:mm',
                                        defaultValue: moment('23:59:59', 'HH:mm')
                                    }
                                }
                                suffixIcon={<i className="fa-solid fa-calendar-days text-grey-500 text-xs" />}
                                value={timestampTo ? moment(timestampTo) : undefined}
                                locale={"vi" as any}
                                bordered={false}
                                format={'DD/MM/YYYY'}
                                placeholder=""
                                onChange={onChangeTimeStampTo}
                            />
                            <label className='robotoregular form-label text-grey-400'>{t('request_withdraw.to_date')}</label>
                        </div>
                    </div>
                </Col>
                <Col span={8} className="pl-4">
                    <div className='form-select'>
                        <FloatLabel label={t('request_withdraw.select_bank')} value={selectedBank}>
                            <SuperSelect
                                onChange={(e: any) => onChangeSelectedBank(e)}
                                className={"_select-bank w-full"}
                                selectedValue={selectedBank}
                                options={listBank}
                                renderOptionLabel={(item: any) => <>
                                    <span className={`_service-name txt-size-h8 mr-1 robotoregular txt-color-black`}>{item.name} ({item.code})</span>
                                    {/* <img src={x.logo} alt="Logo Bank" className='object-contain object-left h-[30px]' /> */}
                                </>}
                            />
                        </FloatLabel>
                    </div>
                </Col>
                <Col span={6} className="pl-4">
                    <InputItem
                        title={'Số tài khoản'}
                        className={"_search-box-input-query"}
                        onKeyDown={(e: any) => {
                            if (e.keyCode === 13) {
                                onSearch()
                            }
                        }}
                        onChange={(e: any) => onChangeNumberBank(e.currentTarget.value)}
                        value={numberBank || ''}
                        allowClear
                    />
                </Col>
            </Row>
            <Row className={'mt-4'}>
                <Col span={24} className="flex items-center">
                    <div className={'mr-2 text-grey-400  robotoregular'}>{t('delivery.status')}:</div>
                    <div className={''}>
                        {statuses.map((item: any, index: number) => {
                            const statisticsValid = statistics.find((statistic: StatisticsItem) => statistic.status === item.code)

                            return (
                                <Button
                                    key={index}
                                    onClick={() => onCheckBoxCheck(item)}
                                    className={`status--btn screen-sm:flex-initial text-xs robotomedium mr-3 rounded-2xl px-3 h-6 border-none hover:shadow-none ${listStatusChecked.includes(item.code) ? 'activeBtn' : 'hover:bg-[#EBEBEB]'}`}
                                >
                                    {item.name} {statisticsValid ? `(${quantityFormat(statisticsValid?.total)})` : ''}
                                </Button>

                            )
                        })}
                    </div>
                </Col>
            </Row>
            <div
                className=" dpl-flex align-items-center justify-content-between pdt10"
            >
                <div />
                <div className="dpl-flex align-items-center">
                    {
                        // checkShowRefresh()
                        // &&
                        <span onClick={onClearSearch} className="_btn-clear mr-3 text-[#707070] flex items-center cursor-pointer">
                            <i className="fa-regular fa-rotate-right text-[9px]" />
                            <span className="robotoregular text-xs ml-1">
                                {t('order.filter_refresh')}
                            </span>
                        </span>
                    }
                    <Button
                        type="primary"
                        disabled={disabledBtnSearch}
                        onClick={() => search()}
                        className='rounded border-none _order-filter'
                    >
                        {t('order.search')}
                    </Button>
                </div>
            </div>
        </div>

    )
}

export default Filter