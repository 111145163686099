import React from "react";
import { Tabs } from 'antd';
import * as Constants from '../../constants/config';
import TabFees from './TabFees';
import TabFinance from "./TabFinance";
import TabHistory from "./TabHistory";
import TabLog from "./TabLog";
import TabCredit from "./TabCredit";

const TabPane = Tabs.TabPane;

class TabLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const {
            onChangedTab,
            activeTab,
            dataDetail = {},
            t,
            listPaymentStatus,
            location,
            listInternalPaymentStatus
        } = this.props;
        let contractWithShopkeeper = dataDetail?.contractWithShopkeeper
        return (
            <div className='bg-color-white'>
                <Tabs className="wrappertabs"
                    activeKey={activeTab ? activeTab : Constants.ORDER_DETAIL_TAB_FEES}
                    defaultActiveKey={activeTab ? activeTab : Constants.ORDER_DETAIL_TAB_FEES} onChange={onChangedTab}
                >
                    <TabPane className={"_tabFrees"} tab={<span className={'_tabFrees-btn'}>{t('eventGroup.FINANCIAL')}</span>} key={Constants.ORDER_DETAIL_TAB_FEES}>
                        <TabFees
                            t={t}
                            dataDetail={dataDetail}
                        />
                    </TabPane>
                    {
                       contractWithShopkeeper && contractWithShopkeeper !== 'NONE' && dataDetail &&
                        <TabPane className={"_tabCredit"} tab={<span className={'_tabCredit-btn'}>Tín dụng</span>} key={Constants.ORDER_DETAIL_TAB_CREDIT}>
                            <TabCredit
                                t={t}
                                orderInfo={dataDetail}
                            />
                        </TabPane>
                    }
                    <TabPane className={"_tabFinance"} tab={<span className={'_tabFinance-btn'}>{t('financial_tab.transaction')}</span>} key={Constants.ORDER_DETAIL_TAB_FINANCIAL}>
                        <TabFinance
                            t={t}
                            dataDetail={dataDetail}
                        />
                    </TabPane>
                    <TabPane className={"_tabHistory"} tab={<span className={'_tabHistory-btn'}>{t('history_tab.history')}</span>} key={Constants.ORDER_DETAIL_TAB_HISTORY}>
                        <TabHistory
                            t={t}
                            dataDetail={dataDetail}
                            listStatuses={listPaymentStatus}
                        />
                    </TabPane>
                    <TabPane className={"_tabLog"} tab={<span className={'_tabLog-btn'}>{t('log_tab.log')}</span>} key={Constants.ORDER_DETAIL_TAB_LOG}>
                        <TabLog
                            t={t}
                            statuses={listPaymentStatus}
                            internalStatuses={listInternalPaymentStatus}
                            code={dataDetail.code}
                            peerPayment={dataDetail}
                            location={location}
                        />
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}
export default TabLayout;
