import React, { useState, useEffect } from 'react'
import { Empty, Row, Skeleton } from "antd"
import Modal from "../commons/Modal"
import moment from 'moment'

interface ModalLogProps {
    t: (text: string) => string,
    open: boolean,
    logs: any,
    onCancel: (bool: boolean) => void,
    formatLogsContent: (item: any) => string;
}

const ModalLog = (props: ModalLogProps) => {
    const { t, open, onCancel, logs, formatLogsContent } = props
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 500)
    }, [])

    return (
        <Modal
            title={t('request_withdraw.log')}
            visible={open}
            width={560}
            listAction={[]}
            onCancel={() => onCancel(false)}
        >
            <div className="_content">
                <Skeleton loading={loading} active>
                    {
                        logs.length > 0 ? logs.map((log: any, index: number) => {
                            return (
                                <div>
                                    <div className="_log-item">
                                        <span className="block">
                                            <span className="text-xs text-[#BDBDBD] robotoregular mr-1">
                                                {t('order.time')}:
                                            </span>
                                            <span className="text-xs robotomedium text-black-100 mr-3">
                                                {moment(log.timestamp).format('HH:mm DD/MM/YYYY')}
                                            </span>
                                            <span className="text-xs text-[#BDBDBD] robotoregular mr-1">
                                                {log.role === 'STAFF' ? 'Nhân viên:' : 'Khách hàng:'}
                                            </span>
                                            <span>{log.fullname}</span>
                                        </span>
                                        <span>
                                            {
                                                Array.isArray(log.items) && log.items.length > 0 ? log.items.map((x: any) =>
                                                    <Row className="break-words whitespace-pre-wrap robotoregular text-black-100" dangerouslySetInnerHTML={{ __html: formatLogsContent(x) }}>
                                                    </Row>)
                                                    :
                                                    <Row className="break-words whitespace-pre-wrap robotoregular text-black-100" dangerouslySetInnerHTML={{ __html: formatLogsContent(log) }}>
                                                    </Row>
                                            }

                                        </span>
                                    </div>
                                    {
                                        logs.length - 1 !== index && <div className="h-[1px] bg-[#EBEBEB] w-full my-3"></div>
                                    }
                                </div>
                            )
                        }) :
                            <div className="pdt50 pdbt60 bg-color-white">
                                <Empty
                                    image={require('../../resources/images/empty-image.png')}
                                    description={<span>{t('message.empty') as string}</span>}
                                />
                            </div>
                    }
                </Skeleton>

            </div>
        </Modal>
    )
}

export default ModalLog