import PolimClient from '../utils/api/PolimClient';

export default class WithDrawalService {

    /**
     * lấy ra danh sách yêu cầu rút tiền
     * @param query
     * @param cb
     */
    static getListWithDrawalSlip(query, cb) {
        PolimClient.request({
            endpoint: `/customer/withdrawal_slip?${query || ''}`,
            method: 'GET',
        }, cb)
    }

     /**
     * lấy ra danh sách log 
     * @param withdrawalSlipCode
     * @param cb
     */
     static getLogWithDrawlSlip(withdrawalSlipCode, cb) {
        PolimClient.request({
            endpoint: `/customer/withdrawal_slip/${withdrawalSlipCode}/logs?sort=timestamp:desc`,
            method: 'GET',
        }, cb)
    }

    /**
   * danh sách trạng thái yêu cầu
   * @param cb
   */
    static getWithDrawalSlipStatuses(cb) {
        PolimClient.request({
            endpoint: `/categories/withdrawal_slip_public_status`,
            method: 'GET',
        }, cb)
    }

    static getWithDrawalSlipStatistics(cb) {
        PolimClient.request({
            endpoint: `/customer/withdrawal_slip/statistics`,
            method: 'GET',
        }, cb)
    }

    static deleteWithDrawalSlip(withdrawalSlipCode, cb) {
        PolimClient.request({
            endpoint: `/customer/withdrawal_slip/${withdrawalSlipCode}/cancel`,
            method: 'POST',
        }, cb)
    }
}
