import React from 'react';
import {Button, Col, Form, Input, Row} from 'antd';
import {ReactComponent as Resetpass} from '../../resources/images/img-pass.svg'
import {withNamespaces} from "react-i18next";

class FormUpdatePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            bodyData: {},
            error: {},
            errUpdate: this.props.errUpdate
        };
    }

    componentDidUpdate (prevProps) {
        const {errUpdate, t} = this.props;
        const {error = {}} = this.state;
        if (JSON.stringify(prevProps.errUpdate) !== JSON.stringify(errUpdate)) {
            this.setState({
                currentPassword: '',
                newPassword: '',
                rePassword: ''
            });
            error.currentPassword = t('customer_info.incorrect_pass')
            this.setState({
                error
            })
        }

    }

    onValidate = () => {
        let {bodyData = {}, error = {}} = this.state;

        const {t} = this.props;
        let check = 0;

        if (!bodyData.currentPassword) {
            error.currentPassword = t('customer_info.not_empty_pass')
            check = check + 1
        } else {
            error.currentPassword = ''
        }

        if (!bodyData.newPassword) {
            error.newPassword = t('customer_info.not_empty_newPass')
            check = check + 1
        } else if (bodyData.newPassword.length < 6 || bodyData.newPassword.length > 32) {
            error.newPassword = t('customer_info.require_characters_length')
            check = check + 1
        } else if (!bodyData.newPassword.trim()) {
            error.newPassword = t('customer_info.not_space')
            check = check + 1
        } else if (bodyData.newPassword === bodyData.currentPassword) {
            error.newPassword = t('customer_info.coincident_pass')
            check = check + 1
        } else {
            error.newPassword = ''
        }

        if (bodyData.newPassword !== bodyData.rePassword) {
            error.rePassword = t('customer_info.not_match_pass')
            check = check + 1
        } else {
            error.rePassword = ''
        }

        this.setState({error});
        if (check > 0) {
            return false
        }
        return true
    };

    onInputChange = (value, prop, cb) => {
        let bodyData = this.state.bodyData;

        bodyData[prop] = value;

        this.setState({
            bodyData
        }, () => {
            if (cb) cb()
        })
    };

    /**
     * Handle submit create a customer address
     * @param e
     */
    handleSubmit = (e) => {
        const {
            bodyData
        } = this.state;

        if (!this.onValidate()) {
            return
        }

        this.props.onSubmit(bodyData);
    };


    /**
     * Handle cancel update password
     */
    handleCancel = () => {
        this.setState({
            currentPassword: '',
            newPassword: '',
            rePassword: ''
        }, () => {
            this.props.togglePassword()
        });
    };

    render() {
        const {
            error = {},
            bodyData = {}
        } = this.state;
        const {t} = this.props;

        return (
            <div
                className={'formChangePassword'}
            >
                <Row>
                    <Col md={12} className={'pdr5'}>
                        <Row>
                            <Col md={24} className={'pdr5 _oldPasswordContainer'}>
                                <h5 className='_oldPassword txt-size-h7 txt-color-black robotoregular'>
                                    {t('customer_info.current_pass')}
                                    <span className={'txt-color-red mgl5'}>*</span>
                                </h5>
                                <Form.Item name="currentPassword">
                                    <Input.Password
                                        className="_currentPasswordValue"
                                        placeholder={t('customer_info.input_current_pass')}
                                        value={bodyData.currentPassword}
                                        onChange={(e) => this.onInputChange(e.target.value, 'currentPassword')}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                                this.handleSubmit()
                                            }
                                        }}
                                    />
                                    {
                                        error.currentPassword &&
                                        <div className="pdt3 texterror position-ab txt-size-h8 txt-color-red2 robotoregular">
                                            {error.currentPassword}
                                        </div>
                                    }
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={24} className={'pdr5 _newPasswordContainer'}>
                                <h5 className="_newPassword txt-size-h7 txt-color-black robotoregular">{t('customer_info.new_pass')}<span
                                    className={'txt-color-red mgl5'}>*</span></h5>
                                <Form.Item>
                                    <Input.Password
                                        className="_newPasswordValue"
                                        placeholder={t('customer_info.input_new_pin')}
                                        value={bodyData.newPassword}
                                        onChange={(e) => this.onInputChange(e.target.value, 'newPassword')}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                                this.handleSubmit()
                                            }
                                        }}
                                    />
                                    {
                                        error.newPassword &&
                                        <div className="pdt3 texterror position-ab txt-size-h8 txt-color-red2 robotoregular">
                                            {error.newPassword}
                                        </div>
                                    }
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={24} className={'pdr5 _rePasswordContainer'}>
                                <h5 className="_rePassword txt-size-h7 txt-color-black robotoregular">{t('customer_info.retype_new_pin')}<span className={'txt-color-red mgl5'}>*</span></h5>
                                <Form.Item>
                                    <Input.Password
                                        className="_rePasswordValue"
                                        placeholder={t('customer_info.please_retype_newPass')}
                                        value={bodyData.rePassword}
                                        onChange={(e) => this.onInputChange(e.target.value, 'rePassword')}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                                this.handleSubmit()
                                            }
                                        }}
                                    />
                                    {
                                        error.rePassword &&
                                        <div className="pdt3 texterror position-ab txt-size-h8 txt-color-red2 robotoregular">
                                            {error.rePassword}
                                        </div>
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Button className='mgr10 _btn-cancel-Password' onClick={this.handleCancel}>{t('button.cancel')}</Button>
                                <Button type="primary" className='_btn-submit-Password' onClick={this.handleSubmit}>{t('button.save')}</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12} className={'pdr5'}>
                        <div className='txt-center mgbt20 '>
                            <Resetpass className='width100'/>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withNamespaces()(FormUpdatePassword)
