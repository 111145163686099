import React, {Component} from 'react'

import * as AntdIcon from "@ant-design/icons"


export default class SmallLoading extends Component {

    render() {
        return (
            <div className="dpl-flex justify-content flex-direction-column">
                <AntdIcon.Loading3QuartersOutlined spin={true} type="loading" style={{fontSize: 20}}/>
            </div>
        )
    }
}