import PolimClient from "../utils/api/PolimClient";

export default class AddressService {

    /**
     * lấy danh sách địa chỉ của người dùng
     * @param username
     * @param query
     * @param cb
     */
    static getListAddressesUser (username, query, cb) {
        PolimClient.request({
            endpoint: `/customer/addresses${query || ''}`,
            method: 'GET',
        }, cb)
    }

    /**
     * xóa address của người dùng
     * @param username
     * @param id
     * @param cb
     */
    static deleteAddressUser (username, id, cb) {
        PolimClient.request({
            endpoint: `/customer/addresses/${id}`,
            method: 'DELETE',
        }, cb)
    }

    /**
     * Create a Customer Address
     * @param username
     * @param data
     * @param cb
     */
    static createCustomerAddress (username, data, cb) {
        PolimClient.request({
            endpoint: `/customer/addresses`,
            method: 'POST',
            body: data
        }, cb)
    }

    /**
     * Update a Customer Address
     * @param username
     * @param id
     * @param data
     * @param cb
     */
    static updateCustomerAddress (username, id, data, cb) {
        PolimClient.request({
            endpoint: `/customer/addresses/${id}`,
            method: 'PATCH',
            body: data
        }, cb)
    }

    /**
     * Get Locations
     * @param query
     * @param cb
     */
    static getLocations (query, cb) {
        PolimClient.request({
            endpoint: `/locations${query}`,
            method: 'GET',
            mode: 'free'
        }, cb)
    }
}