import React from 'react'
import moment from 'moment'
import DocumentTitle from "react-document-title"
import {Link} from 'react-router-dom';
import {LocalStore} from "../../utils/LocalStore";
import {Checkbox, Col, Empty, Input, notification, Row, Select, Spin} from 'antd'
import userService from "../../services/user.service";
import {validateEmail, validateUserName} from "../../utils/validate";
import PhoneInput, { isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import {extractParameters, parseQueryStringToObject} from "../../utils/stringUtils";
import {getUrlQueryString} from "../../utils/helper";
import {withNamespaces} from "react-i18next";
import Language from "../../components/Language";
import AddressService from "../../services/address.service";
import * as AntdIcon from "@ant-design/icons"
import lodash from 'lodash'

import "react-phone-number-input/style.css";
import imageBg from '../../resources/images/bgsignup.png'

let timeout = null;

class Register extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            successregister: false,
            acceptToS: false,
            username: '',
            fullName: '',
            email: '',
            phone: '',
            password: '',
            confirmPassword: '',
            currentProjectInfo: LocalStore.getInstance().read('currentProjectInfo')
        }
    }

    componentDidMount() {
        let queryObj = parseQueryStringToObject(getUrlQueryString());
        if (queryObj.token) {
            this.setState({
                token: queryObj.token
            }, () => {
                this.getProfileFacebook();
            })
        }

        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        const tenantConfig = currentProjectInfo.tenantConfig || {};
        const generalConfig = tenantConfig && tenantConfig.generalConfig ? tenantConfig.generalConfig : {};
        this.setState({
            currentProjectInfo,
            tenantConfig,
            generalConfig
        });
        this.getArticle()
        if (generalConfig.customerRegisterAddressEnabled) {
            this.loadDataLocations('PROVINCE');
        }
    }

    getArticle = () => {
        // query 1 lần duy nhất khi load trang
        userService.getPubArticle((err, res) => {
            if (!err) {
                let arr = Array.isArray(res) ? res.filter(x => x.purpose === 'WELCOME') : []
                this.setState({
                    article: arr[0]
                })
            }
        });
    };

    /**
     * lấy thông tin tỉnh/thành phố, quận/huyện, phường/xã
     */
    loadDataLocations = (type, search, parent) => {
        const queryString = `?type=${type || 'PROVINCE'}${search ? `&query=${search}` : ''}${parent ? `&parent=${parent}` : ''}&size=1000`;
        clearTimeout(timeout);
        this.setState({[`${type.toLowerCase()}Loading`]: true, fetching: true}, () => {
            setTimeout(() => {
                AddressService.getLocations(queryString, (err, res) => {
                    if (!err) {
                        if (Array.isArray(res) && res.length > 0) {
                            this.setState({[`${type.toLowerCase()}List`]: res});
                        } else {
                            if (type === 'PROVINCE') {
                                this.setState({provinceList: [], districtList: []});
                            } else if (type === 'DISTRICT') {
                                this.setState({districtList: [], wardList: []});
                            } else {
                                this.setState({wardList: []});
                            }
                        }
                    }
                    this.setState({[`${type.toLowerCase()}Loading`]: false, loadDataEdit: false, fetching: false})
                })
            }, 500)
        });
    };

    /**
     * lấy thông tin khi đăng ký bằng facebook
     */
    getProfileFacebook = () => {
        const {token} = this.state;
        userService.getProfileFacebook(token, (err, res, headers) => {
            if (!err) {
                this.setState({
                    fullName: res.fullname,
                    email: res.email,
                    socialRegister: true
                })
            }
        })
    };

    /**
     * submit
     */
    onSubmit = () => {
        if (!this.state.acceptToS || this.onValidate()) return;

        const {
            username, fullName, email,
            password, onSubmit, refState,
            phone, socialRegister, token,
            generalConfig = {},
            addressFullName = '',
            addressPhone = '',
            province = '',
            district = '',
            ward = '',
            address = '',
        } = this.state;
        // const {history} = this.props;

        const ref = LocalStore.getInstance().read('ref') || {};
        let registerService = userService.register;
        const user = {
            username,
            fullname: fullName,
            email,
            password,
            phone: phone ? phone : undefined,
            ref: refState ? refState : ref.expires && !moment(ref.expires).isBefore(moment().format()) ? ref.ref : undefined
        };
        const metadata = extractParameters(window.location.href)

        if (generalConfig.customerRegisterAddressEnabled) {
            registerService = userService.registerWithAddress;
            user.customerAddress = {
                fullname: addressFullName,
                phone: addressPhone,
                detail: address,
                location: typeof (ward) === 'string' && ward ? ward : district || province,
            }
        }

        this.setState({onSubmit: true}, () => {
            if (onSubmit) return;
            const {t} = this.props;
            if (socialRegister) {
                userService.registerWithFacebook(user, token, (err, res, headers) => {
                    if (!err) {
                        this.setState({successregister: true})
                    } else {
                        if (err.title === "username_duplicated") {
                            this.setState({
                                usernameError: t('register.username_duplicated'),
                            })
                        } else if (err.title === "email_duplicated") {
                            this.setState({
                                emailError: t('register.email_duplicated'),
                            })
                        } else if (err.title === 'phone_number_required') {
                            this.setState({
                                phoneError: t('register.phone_number_required')
                            })
                        } else if (err.title === 'invalid_phone_number') {
                            this.setState({
                                phoneError: t('register.invalid_phone_number')
                            })
                        } else {
                            if (err.statusCode !== 'Network fail') notification['error']({
                                message: t(err.message),
                            });
                        }
                    }
                    this.setState({onSubmit: false})
                })
            } else {
                registerService(user, metadata, (err, res, headers) => {
                    if (!err) {
                        this.setState({successregister: true})
                    } else {
                        if (err.title === "username_duplicated") {
                            this.setState({
                                usernameError: t('register.username_duplicated'),
                            })
                        } else if (err.title === "email_duplicated") {
                            this.setState({
                                emailError: t('register.email_duplicated'),
                            })
                        } else if (err.title === 'phone_number_required') {
                            this.setState({
                                phoneError: t('register.phone_number_required')
                            })
                        } else if (err.title === 'invalid_phone_number') {
                            this.setState({
                                phoneError: t('register.invalid_phone_number')
                            })
                        } else {
                            if (err.statusCode !== 'Network fail') notification['error']({
                                message: t(err.message),
                            });
                        }
                    }
                    this.setState({onSubmit: false})
                })
            }
        })
    };

    /**
     * sự kiện thay đổi giá trị của input
     * @param value
     * @param props
     */
    onChangeInput = (value, props) => {
        if (props === 'phone' && this.onValidatePhoneInput()) {
            this.setState({
                phoneError: ""
            })
        }
        this.setState({[props]: value})
    };

    /**
     * sự kiện validate của input username
     */
    onValidateUsernameInput = () => {
        const {username} = this.state;
        const {t} = this.props;
        if (!username) {
            this.setState({
                usernameError: t('register.username_require')
            });
            return true
        } else {
            if (validateUserName(username)) {
                this.setState({
                    usernameError: t(validateUserName(username))
                });
                return true
            } else {
                this.setState({
                    usernameError: ""
                })
            }
        }
        return false
    };

    /**
     * sự kiện validate của input fullName
     */
    onValidateFullNameInput = () => {
        const {fullName = ''} = this.state;
        const {t} = this.props;
        if (!fullName.trim()) {
            this.setState({
                fullNameError: t('register.fullname_require')
            });
            return true
        } else {
            this.setState({
                fullNameError: ""
            })
        }
        return false
    };

    /**
     * sự kiện validate của input fullName
     */
    onValidateEmailInput = () => {
        const {t} = this.props;
        const {email} = this.state;
        if (!email) {
            this.setState({
                emailError: t('register.email_require')
            });
            return true
        } else {
            if (validateEmail(email)) {
                this.setState({
                    emailError: t(validateEmail(email))
                });
                return true
            } else {
                this.setState({
                    emailError: ""
                })
            }
        }
        return false
    };

    /**
     * sự kiện validate của input phone
     */
    onValidatePhoneInput = () => {
        const {currentProjectInfo} = this.state

        const {t} = this.props;
        const {phone} = this.state;
        if (!phone || !phone.trim()) {
            if (lodash.get(currentProjectInfo, 'tenantConfig.generalConfig.customerRegisterPhoneRequired') === false) {
                return false;
            }

            this.setState({
                phoneError: t('register.mobile_require')
            });
            return true
        } else {
            if (!isValidPhoneNumber(phone)) {
                this.setState({
                    phoneError: t('validate.invalid_phone')
                });
                return true
            } else {
                this.setState({
                    phoneError: ""
                });
            }
        }
        return false
    };

    /**
     * sự kiện validate của input password
     */
    onValidatePasswordInput = () => {
        const {t} = this.props;
        const {password = ''} = this.state;
        if (!password) {
            this.setState({
                passwordError: t('register.pass_require')
            });
            return true
        } else {
            if (!password.trim()) {
                this.setState({
                    passwordError: t('register.pass_white_space')
                });
                return true
            }
            if (password.length < 6 || password.length > 32) {
                this.setState({
                    passwordError: t('register.pass_length')
                });
                return true
            } else {
                this.setState({
                    passwordError: ""
                })
            }
        }
        return false
    };

    /**
     * sự kiện validate của input confirmPassword
     */
    onValidateConfirmPasswordInput = () => {
        const {t} = this.props;
        const {password, confirmPassword} = this.state;
        if (confirmPassword !== password) {
            this.setState({
                confirmPasswordError: t('register.re_pass_valid')
            });
            return true
        } else {
            this.setState({
                confirmPasswordError: ""
            })
        }
        return false
    };

    onValidateAddressField = (props) => {
        const {t} = this.props;
        const {
            addressFullName = '',
            addressPhone = '',
            province = '',
            district = '',
            ward = '',
            address = '',
        } = this.state;
        switch (props) {
            case 'addressFullName': // validate fullName field
                if (addressFullName !== '' && addressFullName.trim() !== '') {
                    this.setState({
                        addressFullNameError: ""
                    });
                    return false
                } else {
                    this.setState({
                        addressFullNameError: t('message.warning_fullname')
                    });
                    return true
                }
            case 'address': // validate address field
                if (address !== '' && address.trim() !== '') {
                    this.setState({
                        addressError: ""
                    });
                    return false
                } else {
                    this.setState({
                        addressError: t('message.warning_address')
                    });
                    return true
                }
            case 'addressPhone': // validate phone field
                // let re = /^[0-9+.() -]+$/; //regex number and some special characters
                if (!addressPhone) {
                    this.setState({
                        addressPhoneError: t('message.required_phone')
                    });
                    return true
                } else if (!isValidPhoneNumber(addressPhone)) {
                    this.setState({
                        addressPhoneError: t('message.format_phone')
                    });
                    return true
                } else {
                    this.setState({
                        addressPhoneError: ""
                    });
                    return false
                }
            case 'province': // validate address field
                if (province !== '' && province.trim() !== '') {
                    this.setState({
                        provinceError: ""
                    });
                    return false
                } else {
                    this.setState({
                        provinceError: t('message.required_province')
                    });
                    return true
                }
            case 'district': // validate address field
                if (district !== '' && district.trim() !== '') {
                    this.setState({
                        districtError: ""
                    });
                    return false
                } else {
                    this.setState({
                        districtError: t('message.required_district')
                    });
                    return true
                }
            case 'ward': // validate address field
                if (ward !== '' && ward.trim() !== '') {
                    this.setState({
                        wardError: ""
                    });
                    return false
                } else {
                    this.setState({
                        wardError: t('message.required_ward')
                    });
                    return true
                }
            default:
                return false
        }
    };

    onValidate = () => {
        const {generalConfig = {}} = this.state;
        const validateUser = this.onValidateUsernameInput();
        const validateFullName = this.onValidateFullNameInput();
        const validateEmail = this.onValidateEmailInput();

        const validatePassword = this.onValidatePasswordInput();
        const validateConfirmPassword = this.onValidateConfirmPasswordInput();

        const validatePhone = this.onValidatePhoneInput();

        if (generalConfig.customerRegisterAddressEnabled) {
            const validateAddressFullName = this.onValidateAddressField("addressFullName");
            const validateAddress = this.onValidateAddressField("address");
            const validateAddressPhone = this.onValidateAddressField("addressPhone");
            const validateProvince = this.onValidateAddressField("province");
            const validateDistrict = this.onValidateAddressField("district");
            const validateWard = this.onValidateAddressField("ward");

            return validateAddressFullName || validateAddress || validateAddressPhone || validateProvince
                || validateProvince || validateDistrict || validateWard || validatePhone ||
                validateUser || validateFullName || validateEmail || validatePassword || validateConfirmPassword
        }

        return validateUser || validateFullName || validateEmail || validatePhone || validatePassword || validateConfirmPassword
    };

    /**
     * Handle change select
     * @param value
     * @param prop
     */
    handleSelectChange = (value, prop) => {
        const {province, district, ward} = this.state;

        if (prop === 'province' && province !== value) {
            this.setState({
                districtList: [],
                wardList: [],
                district: '',
                ward: '',
                provinceError: undefined,
                districtError: undefined,
                wardError: undefined
            }, () => {
                // call lai api "PROVINCE" cho truong hop tim kiem "PROVINCE" chinh xac tuyet doi
                this.loadDataLocations('DISTRICT', null, value);
            })
        }

        if (prop === 'district' && district !== value) {
            this.setState({
                wardList: [],
                ward: '',
                districtError: undefined,
                wardError: undefined
            }, () => {
                this.loadDataLocations('WARD', null, value);
            })
        }

        if (prop === 'ward' && ward !== value) {
            this.setState({
                wardError: undefined
            })
            this.loadDataLocations('WARD', null, district);
        }

        this.setState({[prop]: value, fetching: false});
    };

    renderAddressForm = () => {
        const {t} = this.props;
        const {
            addressFullName, addressFullNameError,
            addressPhone, addressPhoneError,
            province, provinceError,
            district, districtError,
            ward, wardError,
            address, addressError,
            provinceList = [],
            districtList = [],
            wardList = [],
            fetching
        } = this.state;
        const currentLanguage = LocalStore.getInstance().read('currentLanguage');
        return <div>
            <h2 className="new-login-boxform-title">
                {t('customerAddress.title')}
            </h2>
            <div className="mgt25">
                <div className="pdl0">
                    <Row gutter={20}>
                        <Col span={12}>
                            <div className={`mgbt16 ${addressFullNameError && 'errorip'}`}>
                                <Input
                                    type="text"
                                    name="username"
                                    prefix={<AntdIcon.SolutionOutlined type="solution"/>}
                                    placeholder={t('customerAddress.fullname_new_register')}
                                    value={addressFullName}
                                    onChange={(e) => this.onChangeInput(e.target.value, 'addressFullName')}
                                    onBlur={() => this.onValidateAddressField('addressFullName')}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            this.onSubmit()
                                        }
                                    }}
                                />
                                {/*<span className="iconip position-ab txt-size-h5">*/}
                                {/*    <AntdIcon.SolutionOutlined type="solution" />*/}
                                {/*</span>*/}
                                <div className="box-text-error">
                                    {
                                        !!addressFullNameError && <span className="texterror txt-size-h8 txt-color-red2 robotoregular">
                                            {addressFullNameError}
                                        </span>
                                    }
                                </div>
                            </div>
                        </Col>

                        <Col span={12}>
                            <div className={`mgbt16 ${addressPhoneError && 'errorip'}`}>
                                {/* <Input
                                    type="text"
                                    name="fullName"
                                    prefix={<AntdIcon.PhoneOutlined type="phone"/>}
                                    placeholder={t('customerAddress.phone_new_register')}
                                    value={addressPhone}
                                    onChange={(e) => this.onChangeInput(e.target.value, 'addressPhone')}
                                    onBlur={() => this.onValidateAddressField('addressPhone')}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            this.onSubmit()
                                        }
                                    }}
                                /> */}
                                <PhoneInput 
                                    defaultCountry={currentLanguage?.countryCode}
                                    international
                                    fullWidth
                                    value={addressPhone}
                                    placeholder={t('customerAddress.phone_new_register')}
                                    rules={{ required: true, validate: isPossiblePhoneNumber }}
                                    onChange={(value) => this.onChangeInput(value, 'addressPhone')}
                                    onBlur={() => this.onValidateAddressField('addressPhone')}
                                />

                                {/*<span className="iconip position-ab txt-size-h5">*/}
                                {/*    <AntdIcon.PhoneOutlined type="phone" />*/}
                                {/*</span>*/}
                                <div className="box-text-error">
                                    {
                                        !!addressPhoneError && <span className="texterror txt-size-h8 txt-color-red2 robotoregular">
                                            {addressPhoneError}
                                        </span>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={20}>
                        <Col span={12}>
                            <div className={`input-new-register mgbt16 ${provinceError && 'errorip'}`}>
                                <Select
                                    value={province || []}
                                    showSearch
                                    className={'width-100-percent'}
                                    defaultActiveFirstOption={false}
                                    notFoundContent={fetching ? <div className="txt-center"><Spin indicator={<AntdIcon.Loading3QuartersOutlined type="loading" style={{fontSize: 24}} spin/>}/></div> : Array.isArray(provinceList) && provinceList.length === 0 ? <Empty/> : null}
                                    placeholder={t('customerAddress.select_province')}
                                    onChange={(value) => this.handleSelectChange(value, 'province')}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {Array.isArray(provinceList) && provinceList.map((item, index) => {
                                        return (
                                                    !item?.ghost && <Select.Option className="_provinceValue" key={item.id} value={item.code} disabled={item?.ghost} >{item.name}</Select.Option>
                                        )
                                    })}
                                </Select>
                                <div className="box-text-error">
                                    {
                                        !!provinceError && <span className="texterror txt-size-h8 txt-color-red2 robotoregular">
                                            {provinceError}
                                        </span>
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className={`input-new-register mgbt16 ${districtError && 'errorip'}`}>
                                <Select
                                    value={district || []}
                                    dropdownClassName="_districtSelect"
                                    showSearch
                                    className={'width-100-percent'}
                                    placeholder={t('customerAddress.select_district')}
                                    disabled={!province}
                                    defaultActiveFirstOption={false}
                                    notFoundContent={fetching ? <div className="txt-center"><Spin indicator={<AntdIcon.Loading3QuartersOutlined type="loading" style={{fontSize: 24}} spin/>}/></div> : Array.isArray(districtList) && districtList.length === 0 ? <Empty/> : null}
                                    onChange={(value) => this.handleSelectChange(value, 'district')}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {Array.isArray(districtList) && districtList.map((item, index) => {
                                        return (

                                           !item?.ghost&& <Select.Option className="_districtValue" key={item.id} value={item.code} disabled={item?.ghost}>{item.name}</Select.Option>


                                        )
                                    })}
                                </Select>
                                <div className="box-text-error">
                                    {
                                        !!districtError && <span className="texterror txt-size-h8 txt-color-red2 robotoregular">
                                            {districtError}
                                        </span>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={20}>
                        <Col span={12}>
                            <div className={`input-new-register mgbt16 ${wardError && 'errorip'}`}>
                                <Select
                                    value={ward || []}
                                    showSearch
                                    className={'_wardSelect width-100-percent'}
                                    defaultActiveFirstOption={false}
                                    notFoundContent={fetching ? <div className="txt-center"><Spin indicator={<AntdIcon.Loading3QuartersOutlined type="loading" style={{fontSize: 24}} spin/>}/></div> : Array.isArray(wardList) && wardList.length === 0 ? <Empty/> : null}
                                    placeholder={t('customerAddress.select_ward')}
                                    disabled={!district}
                                    onChange={(value) => this.handleSelectChange(value, 'ward')}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {Array.isArray(wardList) && wardList.map((item, index) => {
                                        return (
                                            !item?.ghost && <Select.Option className="_wardValue" key={item.id} value={item.code} disabled={item?.ghost}>{item.name}</Select.Option>
                                        )
                                    })}
                                </Select>
                                <div className="box-text-error">
                                    {
                                        !!wardError && <span className="texterror txt-size-h8 txt-color-red2 robotoregular">
                                            {wardError}
                                        </span>
                                    }
                                </div>
                            </div>
                        </Col>

                        <Col span={12}>
                            <div className={`mgbt16 ${addressError && 'errorip'}`}>
                                <Input
                                    type="text"
                                    name="address"
                                    prefix={<AntdIcon.HomeOutlined type="home"/>}
                                    placeholder={t('customerAddress.address')}
                                    value={address}
                                    onChange={(e) => this.onChangeInput(e.target.value, 'address')}
                                    onBlur={() => this.onValidateAddressField('address')}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            this.onSubmit()
                                        }
                                    }}
                                />
                                {/*<span className="iconip position-ab txt-size-h5">*/}
                                {/*    <AntdIcon.HomeOutlined type="home" />*/}
                                {/*</span>*/}
                                <div className="box-text-error">
                                    {
                                        !!addressError && <span className="texterror txt-size-h8 txt-color-red2 robotoregular">
                                            {addressError}
                                        </span>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>

                </div>
            </div>
        </div>
    };

    render() {
        const {
            username, fullName, email, password, confirmPassword, acceptToS, phone, phoneError, article,
            usernameError, fullNameError, emailError, passwordError, confirmPasswordError,
            onSubmit, successregister, refState, socialRegister, generalConfig = {}, currentProjectInfo = {}, tenantConfig = {}
        } = this.state;

        const serviceAgreementUrl = generalConfig.serviceAgreementUrl;
        const ref = LocalStore.getInstance().read('ref');
        const currentLanguage = LocalStore.getInstance().read('currentLanguage');
        const {t} = this.props;
        return (
            <DocumentTitle title={`${t('register.title')} | ${currentProjectInfo.name || ''}`}>
                <div className="scroll-wrapper">
                    <div className="wrapper new-login-wrapper width100 min-width-page position-re dpl-flex pdt50 pdbt50">
                        {
                            !successregister
                                ?
                                <div className="new-login-boxform dpl-flex justify-content">
                                    <div className="new-login-boxform-left">
                                        {
                                            !generalConfig?.registerBackgroundImage ?
                                            <>
                                                <div className="new-login-boxform-logo">
                                                    <img src={tenantConfig.logoStandard} className="" alt=''/>
                                                </div>
                                                <img src={imageBg} className="new-login-boxform-bg" alt=''/>
                                            </> : <img src={generalConfig.registerBackgroundImage} className="new-login-boxform-bg" alt=''/>
                                        }
                                    </div>
                                    <div className="new-login-boxform-right">
                                        <h2 className="new-login-boxform-title">
                                            {t('register.title')}
                                        </h2>
                                        <div className="mgt25">
                                            <div className="pdl0">
                                                <Row gutter={20}>
                                                    <Col span={12}>
                                                        <div className={`mgbt16 ${usernameError && 'errorip'}`}>
                                                            <Input
                                                                type="text"
                                                                name="username"
                                                                prefix={<AntdIcon.UserOutlined type="user"/>}
                                                                placeholder={t('register.username')}
                                                                value={username}
                                                                onChange={(e) => this.onChangeInput(e.target.value, 'username')}
                                                                onBlur={this.onValidateUsernameInput}
                                                                onKeyDown={(e) => {
                                                                    if (e.keyCode === 13) {
                                                                        this.onSubmit()
                                                                    }
                                                                }}
                                                            />
                                                            {/*<span className="iconip position-ab txt-size-h5">*/}
                                                            {/*    <AntdIcon.UserOutlined type="user"/>*/}
                                                            {/*</span>*/}
                                                            <div className="box-text-error">
                                                                {
                                                                    usernameError && <span className="texterror txt-size-h8 txt-color-red2 robotoregular">
                                                                    {usernameError}
                                                                </span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col span={12}>
                                                        <div className={`mgbt16 ${fullNameError && 'errorip'}`}>
                                                            <Input
                                                                type="text"
                                                                name="fullName"
                                                                prefix={<AntdIcon.SolutionOutlined type="solution"/>}
                                                                placeholder={t('register.fullname')}
                                                                value={fullName}
                                                                onChange={(e) => this.onChangeInput(e.target.value, 'fullName')}
                                                                onBlur={this.onValidateFullNameInput}
                                                                onKeyDown={(e) => {
                                                                    if (e.keyCode === 13) {
                                                                        this.onSubmit()
                                                                    }
                                                                }}
                                                            />
                                                            {/*<span className="iconip position-ab txt-size-h5">*/}
                                                            {/*    <AntdIcon.SolutionOutlined type="solution" />*/}
                                                            {/*</span>*/}
                                                            <div className="box-text-error">
                                                                {
                                                                    fullNameError && <span className="texterror txt-size-h8 txt-color-red2 robotoregular">
                                                                    {fullNameError}
                                                                </span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <div className={` mgbt16 ${emailError && 'errorip'}`}>
                                                    {
                                                        socialRegister
                                                            ?
                                                            <Input
                                                                disabled={true}
                                                                type="email"
                                                                name="email"
                                                                prefix={<AntdIcon.MailOutlined type="mail"/>}
                                                                placeholder={t('register.email')}
                                                                value={email}
                                                            />
                                                            :
                                                            <Input
                                                                type="email"
                                                                name="email"
                                                                prefix={<AntdIcon.MailOutlined type="mail"/>}
                                                                placeholder={t('register.email')}
                                                                value={email}
                                                                onChange={(e) => this.onChangeInput(e.target.value, 'email')}
                                                                onBlur={this.onValidateEmailInput}
                                                                onKeyDown={(e) => {
                                                                    if (e.keyCode === 13) {
                                                                        this.onSubmit()
                                                                    }
                                                                }}
                                                            />
                                                    }
                                                    {/*<span className="iconip position-ab txt-size-h5">*/}
                                                    {/*    <AntdIcon.MailOutlined type="mail"/>*/}
                                                    {/*</span>*/}
                                                    <div className="box-text-error">
                                                        {
                                                            emailError && <span className="texterror txt-size-h8 txt-color-red2 robotoregular">
                                                            {emailError}
                                                        </span>
                                                        }
                                                    </div>
                                                </div>

                                                <div className={`mgbt16 ${phoneError && 'errorip'}`}>
                                                    <PhoneInput 
                                                        defaultCountry={currentLanguage?.countryCode}
                                                        international
                                                        fullWidth
                                                        value={phone}
                                                        placeholder={t('register.mobile')}
                                                        rules={{ required: lodash.get(currentProjectInfo, 'tenantConfig.generalConfig.customerRegisterPhoneRequired', true), validate: isPossiblePhoneNumber }}
                                                        onChange={(value) => this.onChangeInput(value, 'phone')}
                                                        onBlur={() => this.onValidatePhoneInput()}
                                                    />
                                                    {/* <Input
                                                        type="text"
                                                        name="phone"
                                                        placeholder={t('register.mobile')}
                                                        value={phone}
                                                        prefix={<AntdIcon.PhoneOutlined type="phone"/>}
                                                        onChange={(e) => this.onChangeInput(e.target.value, 'phone')}
                                                        onBlur={this.onValidatePhoneInput}
                                                        onKeyDown={(e) => {
                                                            if (e.keyCode === 13) {
                                                                this.onSubmit()
                                                            }
                                                        }}
                                                    /> */}
                                                    {/*<span className="iconip position-ab txt-size-h5">*/}
                                                    {/*    <AntdIcon.PhoneOutlined type="phone"/>*/}
                                                    {/*</span>*/}
                                                    <div className="box-text-error">
                                                        {
                                                            phoneError && <span className="texterror txt-size-h8 txt-color-red2 robotoregular">
                                                            {phoneError}
                                                        </span>
                                                        }
                                                    </div>
                                                </div>

                                                <Row gutter={20}>
                                                    <Col span={12}>
                                                        <div className={`mgbt16 ${passwordError && 'errorip'}`}>
                                                            <Input
                                                                type="password"
                                                                name="password"
                                                                placeholder={t('register.pass')}
                                                                value={password}
                                                                prefix={<AntdIcon.LockOutlined type="lock"/>}
                                                                onChange={(e) => this.onChangeInput(e.target.value, 'password')}
                                                                onBlur={this.onValidatePasswordInput}
                                                                onKeyDown={(e) => {
                                                                    if (e.keyCode === 13) {
                                                                        this.onSubmit()
                                                                    }
                                                                }}
                                                            />
                                                            {/*<span className="iconip position-ab txt-size-h5">*/}
                                                            {/*    <AntdIcon.LockOutlined type="lock"/>*/}
                                                            {/*</span>*/}
                                                            <div className="box-text-error">
                                                                    {
                                                                        passwordError && <span className="texterror txt-size-h8 txt-color-red2 robotoregular">
                                                                {passwordError}
                                                            </span>
                                                                    }
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col span={12}>
                                                        <div className={` mgbt16 ${confirmPasswordError && 'errorip'}`}>
                                                            <Input
                                                                type="password"
                                                                name="confirmPassword"
                                                                placeholder={t('register.re_pass')}
                                                                value={confirmPassword}
                                                                prefix={<AntdIcon.LockOutlined type="lock"/>}
                                                                onChange={(e) => this.onChangeInput(e.target.value, 'confirmPassword')}
                                                                onBlur={this.onValidateConfirmPasswordInput}
                                                                onKeyDown={(e) => {
                                                                    if (e.keyCode === 13) {
                                                                        this.onSubmit()
                                                                    }
                                                                }}
                                                            />
                                                        
                                                            <div className="box-text-error">
                                                                {
                                                                    confirmPasswordError && <span className="texterror txt-size-h8 txt-color-red2 robotoregular">
                                                            {confirmPasswordError}
                                                        </span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                {
                                                    !ref || moment(ref.expires).isBefore(moment().format()) ?
                                                        <div className={`mgbt16`}>
                                                            <Input
                                                                type="text"
                                                                name="ref"
                                                                prefix={<AntdIcon.LinkOutlined type="link"/>}
                                                                placeholder={t('register.code')}
                                                                value={refState}
                                                                onChange={(e) => this.onChangeInput(e.target.value, 'refState')}
                                                                onKeyDown={(e) => {
                                                                    if (e.keyCode === 13) {
                                                                        this.onSubmit()
                                                                    }
                                                                }}box-text-error
                                                            />
                                                        
                                                        </div>
                                                        :
                                                        null
                                                }

                                                {generalConfig.customerRegisterAddressEnabled && this.renderAddressForm()}

                                                <div>
                                                    <Checkbox
                                                        name="acceptToS"
                                                        value={acceptToS}
                                                        onChange={(e) => this.setState({acceptToS: e.target.checked})}
                                                    >
                                                        {
                                                            !serviceAgreementUrl ? 
                                                            <>
                                                                <span className="txt-size-h8">{t('register.agree1').split('$<Link/>&')[0]}</span>
                                                                <a href={serviceAgreementUrl} target={'_blank'} className="txt-size-h8 txt-color-blue" rel="noreferrer">{t('register.agree2')} </a>
                                                                <span className="txt-size-h8">{t('register.agree1').split('$<Link/>&')[1]}</span>
                                                            </> : <span className="txt-size-h8" dangerouslySetInnerHTML={{__html: serviceAgreementUrl}}></span>
                                                        }
                                                    </Checkbox>
                                                </div>
                                            </div>
                                            <div className="txt-right mgt30 mgbt20">
                                                <Link to={'/login'} className={`_btn-cancel btn-new-register cursor-pointer mgr10 disabled`}>
                                                <span className="position-re">
                                                    {t('button.cancel')}
                                                </span>
                                                </Link>
                                                <div onClick={this.onSubmit} className={`_btn-submit btn-new-register cursor-pointer ${acceptToS || 'disabled'}`}>
                                                <span className="position-re">
                                                    {onSubmit && <AntdIcon.Loading3QuartersOutlined type="loading-3-quarters" spin={true} className="position-ab" style={{left: -25, top: 1}}/>}
                                                    {t('register.register')}
                                                </span>
                                                </div>
                                            </div>
                                            {/*{*/}
                                            {/*    !socialRegister && <>*/}
                                            {/*        <span className="position-re bdor dpl-block txt-center">*/}
                                            {/*            <span className="txt-size-h8 txt-color-black robotoregular bg-color-white position-ab pdl10 pdr10">Hoặc</span>*/}
                                            {/*        </span>*/}
                                            {/*        <ul className="boxform__btnsocial dpl-flex justify-content">*/}
                                            {/*            <li className="">*/}
                                            {/*                <form*/}
                                            {/*                    method='post'*/}
                                            {/*                    action={`${process.env.REACT_APP_API_URL.replace('/api', '/signin')}/facebook`}*/}
                                            {/*                    // action="https://cvv.hiee.us/signin/facebook"*/}
                                            {/*                >*/}
                                            {/*                    <button*/}
                                            {/*                        type="submit"*/}
                                            {/*                        className="btn-social cursor-pointer btnsocial--fb position-re dpl-il-block txt-size-h8 robotoregular txt-color-white"*/}
                                            {/*                    >*/}
                                            {/*                        <img src={require('../../resources/images/ic-fb.png')} /> Tiếp tục với Facebook*/}
                                            {/*                    </button>*/}
                                            {/*                </form>*/}
                                            {/*            </li>*/}
                                            {/*        </ul>*/}
                                            {/*    </>*/}
                                            {/*}*/}
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="boxform bg-color-white">
                                    <h1 className="txt-center mgbt20">
                                        <img src={require('../../resources/images/img-success.png')} alt=''/>
                                    </h1>
                                    <h2 className="txt-size-h3 robotobold txt-color-black txt-center">
                                        {article && article.title ? <div dangerouslySetInnerHTML={{__html: article.title}}/> : t('register.success')}
                                    </h2>
                                    <span className="txt-size-h7 txt-color-black robotoregular dpl-block mgt20 txt-center line-height134">
                                    {article && article.content ? <div dangerouslySetInnerHTML={{__html: article.content}}/> : t('register.check_email')}
                                </span>
                                    <div className="boxform__content mgt25">
                                        <div className="txt-center mgt30 mgbt20">
                                            <Link to={'/login'} className="btn--blue dpl-il-block">{t('register.login_here')}</Link>
                                        </div>
                                    </div>
                                </div>
                        }
                        <div
                            className="position-ab"
                            style={{top: 20, right: 20}}
                        >
                            <Language {...this.props}/>
                        </div>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default withNamespaces()(Register);
