/* eslint-disable array-callback-return */
import React from 'react'
import moment from 'moment'
import MainLayout from '../../components/Layout/MainLayout'
import Loading from '../../components/Loading'
import { Link } from 'react-router-dom'
import { Button, Col, DatePicker, Empty, Input, notification, Row, Table, Badge, Space } from 'antd'
import * as AntdIcon from '@ant-design/icons'
import { LocalStore } from '../../utils/LocalStore'

import { getUrlQueryString } from '../../utils/helper'
import {
    moneyCeil,
    moneyFormat,
    parseQueryStringToObject,
    quantityFormat,
    updateQueryStringParameter,
} from '../../utils/stringUtils'
import UserService from '../../services/user.service'
import _ from 'lodash'
import { withNamespaces } from 'react-i18next'

let pageSize = 25

class DeliveryNotes extends React.Component {
    constructor(props) {
        super(props)
        this.isUnmounted = false
        this.state = {
            page: 1,
            pageSize: pageSize,
            isLoading: true,
            listDeliveryNotes: [],
            listChecked: {},
            startTime: undefined,
            endTime: undefined,
            querySearch: '',
        }
    }

    componentDidMount() {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null
        window.addEventListener('popstate', this.onPopState.bind(this))
        if (typeof LocalStore.getInstance().read('currentLoggedUser') === 'object') {
            this.setState({ isLoading: true }, () => {
                this.loadFromQueryString()
            })
        }
    }

    /**
     * Khi unmount ra thì bỏ sự kiện popstate
     */
    componentWillUnmount() {
        this.isUnmounted = true
        window.removeEventListener('popstate', this.onPopState.bind(this))
    }

    /**
     * On popstate
     */
    onPopState() {
        if (!this.isUnmounted)
            this.setState({ isLoading: true }, () => {
                this.loadFromQueryString()
            })
    }

    /**
     * Load từ QueryString (dành cho lần load đầu tiên)
     */
    loadFromQueryString = () => {
        let queryString = getUrlQueryString()
        let queryObj = parseQueryStringToObject(queryString) || {}

        // console.log('queryObj', queryObj);
        // chuyển giá trị từ queryObj vào state;
        let arrayValue = []
        //reset các param khi load từ url
        let listChecked = {}
        let querySearch = ''
        let startTime = undefined
        let endTime = undefined
        for (let p in queryObj) {
            if (p === 'statuses') {
                // xử lý với obj list checked
                arrayValue = queryObj[p].split(',')
                // console.log('arrayValue', arrayValue)
                listChecked[p] = arrayValue
            } else if (p === 'exportedAtFrom') {
                // xử lý với với timestamp
                startTime = decodeURIComponent(queryObj['exportedAtFrom'])
            } else if (p === 'exportedAtTo') {
                // xử lý với với timestamp
                endTime = decodeURIComponent(queryObj['exportedAtTo'])
            } else {
                // xử lý với query
                querySearch = decodeURIComponent(queryObj['code'] || '')
            }
        }

        this.setState(
            {
                querySearch,
                startTime,
                endTime,
                listChecked,
                page: queryObj.page,
                pageSize: queryObj.size || pageSize,
            },
            () => {
                this.loadData()
            }
        )
    }

    /**
     * load data
     */
    loadData = cb => {
        const { t } = this.props
        const { page, pageSize } = this.state
        let queryString = this.buildQueryString()
        if (queryString) {
            queryString = queryString + `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=exported_at:desc`
        } else {
            queryString = `?page=${page ? page - 1 : 0}&size=${pageSize}&sort=exported_at:desc`
        }
        this.setState({ showProgressBar: true }, () => {
            UserService.getDeliveryNotes(queryString, (err, res, headers) => {
                if (!err) {
                    this.setState(
                        {
                            expandedRow: [],
                            listDeliveryNotes: res || [],
                            metadata: {
                                pageCount: headers['x-page-count'],
                                page: headers['x-page-number'],
                                size: headers['x-page-size'],
                                total: headers['x-total-count'],
                            },
                        },
                        () => {
                            //window.scrollTo(0, 0);
                            if (this.mainLayoutElement && this.mainLayoutElement.scrollLayoutElement)
                                this.mainLayoutElement.scrollLayoutElement.scrollTop = 0
                        }
                    )
                } else {
                    notification['error']({
                        message: t(err.message) || t('message.unconnected_error'),
                    })
                }
                if (cb) cb()
                this.setState({ showProgressBar: false, isLoading: false })
            })
        })
    }

    onPageChanged = page => {
        this.setState({ page: page }, () => {
            let queryString = getUrlQueryString()
            queryString = updateQueryStringParameter(queryString ? '?' + queryString : '', 'page', page)
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: queryString,
            })
            // window.scrollTo(0, 0);
        })
    }

    /**
     * xây dựng query string theo các giá trị trong state
     * @returns {string}
     */
    buildQueryString = () => {
        let { listChecked, startTime, endTime, querySearch } = this.state
        let queryString = ''
        for (let p in listChecked) {
            let value = ''
            if (Array.isArray(listChecked[p]) && listChecked[p].length > 0) {
                listChecked[p].map(x => {
                    value = value + x + ','
                })

                value = value.slice(0, -1)
            }
            if (value) queryString = updateQueryStringParameter(queryString, p, value)
        }

        if (startTime) {
            queryString = updateQueryStringParameter(queryString, 'exportedAtFrom', startTime)
        }

        if (endTime) {
            queryString = updateQueryStringParameter(queryString, 'exportedAtTo', endTime)
        }

        if (querySearch) {
            queryString = updateQueryStringParameter(queryString, 'code', querySearch.trim())
        }

        return queryString
    }

    onClearSearch = () => {
        this.setState(
            {
                page: 1,
                querySearch: '',
                startTime: undefined,
                endTime: undefined,
                listChecked: {},
            },
            () => {
                this.props.history.push({
                    pathname: this.props.location.pathname,
                    search: this.buildQueryString(),
                })
            }
        )
    }

    /**
     * xử lý với dữ liệu search trong state
     */
    onSubmitSearch = () => {
        this.setState({ page: 1 }, () => {
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: this.buildQueryString(),
            })
        })
    }

    startTimeChange = date => {
        this.setState({
            startTime: date ? date.startOf('day').toISOString() : undefined,
        })
    }

    endTimeChange = date => {
        this.setState({
            endTime: date ? date.endOf('day').toISOString() : undefined,
        })
    }

    renderExpandedRow = record => {
        const { t } = this.props
        const columns = [
            {
                title: t('package.order_code'),
                dataIndex: 'code',
                key: 'code',
                render: (text, record) => (
                    <Link
                        className="whitespace"
                        to={record.is_shipment ? `/shipments/${text}` : `/orders/${text}`}
                        target={'_blank'}
                    >
                        #{text}
                    </Link>
                ),
            },
            {
                title: t('package_tab.package_code'),
                dataIndex: 'packages',
                key: 'packages',
                className: 'whitespace',
                render: (text, record) => (
                    <div>
                        {text.map((x, y) => (
                            <div
                                key={y}
                                className="text-grey-200"
                            >
                                {x.code || '---'}
                            </div>
                        ))}
                    </div>
                ),
            },
            {
                title: t('package_tab.package_barcode'),
                dataIndex: 'packages',
                key: 'packages',
                className: 'whitespace',
                render: (text, record) => (
                    <div>
                        {text.map((x, y) => (
                            <div
                                key={y}
                                className="text-grey-200"
                            >
                                {x?.barcode || '---'}
                            </div>
                        ))}
                    </div>
                ),
            },
            {
                title: t('package.weight'),
                dataIndex: 'total_weight',
                key: 'total_weight',
                className: 'txt-right text-grey-200',
                render: (text, record) => {
                    let weight = 0
                    record.packages.map(x => (weight = weight + x.weight_net))
                    return quantityFormat(weight) + ' kg'
                },
            },
        ]

        const dataGroup = _.groupBy(record.delivery_note_packages, 'order.code')

        const dataTable = []
        let order = {}
        for (let p in dataGroup) {
            order = dataGroup[p][0] ? dataGroup[p][0].order : {}
            dataTable.push({
                ...order,
                packages: dataGroup[p].map(x => x.package),
            })
        }
        return (
            <Table
                className={'table-inside custom-empty'}
                rowKey={'index'}
                columns={columns}
                dataSource={dataTable || []}
                rowClassName={() => `_table-row`}
                pagination={{
                    hideOnSinglePage: true,
                }}
            />
        )
    }

    /**
     * render header page
     * @returns {*}
     */
    renderSearchBox = () => {
        const { querySearch, startTime, endTime } = this.state
        const { t } = this.props

        return (
            <div className="searchbox bg-color-white pd20 mgbt20 rounded-xl">
                <Row
                    gutter={20}
                    className={'dpl-flex bd-color-gray borderdotted-bootom-2x pdbt20 pdt15'}
                >
                    <Col span={12}>
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('package.delivery_note_code')}:
                        </div>
                        <Input
                            value={querySearch}
                            onChange={e => this.setState({ querySearch: e.target.value })}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    this.onSubmitSearch()
                                }
                            }}
                            className={'_search-box-input-query'}
                        />
                    </Col>

                    <Col span={12}>
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('package.delivery_note_created_at')}:
                        </div>
                        {/*<DatePicker.RangePicker*/}
                        {/*className={'_search-date-time width-100-percent'}*/}
                        {/*format={'DD/MM/YYYY'}*/}
                        {/*value={datetimeRange.length > 0 ? [moment(datetimeRange[0]), moment(datetimeRange[1])] : []}*/}
                        {/*onChange={this.dateTimeChange}*/}
                        {/*/>*/}
                        <Row gutter={20}>
                            <Col span={12}>
                                <DatePicker
                                    disabledDate={this.disabledStartDate}
                                    className="_datepicker width-100-percent"
                                    format={'DD/MM/YYYY'}
                                    value={startTime ? moment(startTime) : undefined}
                                    placeholder={t('delivery.start_date')}
                                    onChange={this.startTimeChange}
                                    // onOpenChange={this.handleStartOpenChange}
                                />
                            </Col>
                            <Col span={12}>
                                <DatePicker
                                    disabledDate={this.disabledEndDate}
                                    className="_datepicker width-100-percent"
                                    format={'DD/MM/YYYY'}
                                    value={endTime ? moment(endTime) : undefined}
                                    placeholder={t('delivery.end_date')}
                                    onChange={this.endTimeChange}
                                    // onOpenChange={this.handleEndOpenChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className=" dpl-flex align-items-center justify-content-between pdt10">
                    <div />
                    <div className="dpl-flex align-items-center">
                        <span
                            onClick={this.onClearSearch}
                            className="_btn-clear mgr20 txt-color-gray txt-size-h8 cursor-pointer robotoregular"
                        >
                            <AntdIcon.SyncOutlined
                                type={'sync'}
                                className={'mgr5'}
                            />{' '}
                            {t('order.filter_refresh')}
                        </span>
                        <Button
                            onClick={this.onSubmitSearch}
                            type="primary"
                            className={'_btn-search min-width-200'}
                        >
                            {t('order.search')}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { listDeliveryNotes, isLoading, metadata, expandedRow = [] } = this.state
        const { t } = this.props
        const columns = [
            {
                title: t('package.delivery_note_code'),
                dataIndex: 'code',
                key: 'code',
                width: 180,
                render: (text, record) => (
                    <span className="_table-row-code txt-uppercase whitespace-pre-wrap">
                        {record.delivery_note ? record.delivery_note.code : '---'}
                    </span>
                ),
            },
            {
                title: t('package.delivery_note_created_at'),
                dataIndex: 'exported_at',
                key: 'exported_at',
                className: 'whitespace-pre-wrap txt-right',
                width: 180,
                render: (text, record) =>
                    record.delivery_note && record.delivery_note.exported_at
                        ? moment(record.delivery_note.exported_at).format('HH:mm DD/MM/YYYY')
                        : '---',
            },
            {
                title: t('delivery.total_packages'),
                dataIndex: 'package_number',
                key: 'package_number',
                className: 'txt-right',
                width: 140,
                render: (text, record) => (record.delivery_note ? quantityFormat(record.delivery_note.package_number) : '---'),
            },
            {
                title: t('package.delivery_note_total_weight'),
                dataIndex: 'total_weight',
                key: 'total_weight',
                className: 'txt-right',
                width: 140,
                render: (text, record) =>
                    record.delivery_note ? quantityFormat(record.delivery_note.total_weight) + ' kg' : '---',
            },
            {
                title: t('package.delivery_note_amount_collect'),
                dataIndex: 'amount_collect',
                key: 'amount_collect',
                className: 'txt-right',
                width: 120,
                render: (text, record) =>
                    record.delivery_note ? moneyFormat(moneyCeil(record.delivery_note.amount_collect)) : '---',
            },
            {
                title: t('delivery_note.tracking_bill'),
                dataIndex: 'tracking_bills',
                width: 200,
                key: 'tracking_bills',
                render: text => text.join(', '),
            },
            {
                title: t('package.delivery_note_customer_address'),
                dataIndex: 'customer_address',
                width: 350,
                key: 'customer_address',
                render: (text, record) => {
                    return record.delivery_note
                        ? record.delivery_note.customer_receiver + ' - ' + record.delivery_note.customer_address
                        : '---'
                },
            },
        ]

        return (
            <MainLayout
                ref={ref => (this.mainLayoutElement = ref)}
                {...this.props}
                showProgressBar={this.state.showProgressBar}
                headerTitle={t('package.delivery_notes')}
                title={t('package.delivery_notes')}
            >
                <div className={'container-common pdbt30 mgr20'}>
                    {this.renderSearchBox()}

                    <div className="pd20 bg-color-white rounded-xl">
                        <div className="dpl-flex justify-content-between align-items-center">
                            {metadata ? (
                                <Space
                                    align="center"
                                    size={2}
                                >
                                    <span className={'txt-color-black txt-size-h5 robotobmedium font-medium capitalize'}>
                                        {t('package.delivery_notes')}
                                    </span>{' '}
                                    <Badge count={quantityFormat(metadata.total)} />
                                </Space>
                            ) : (
                                <span />
                            )}
                        </div>
                        {Array.isArray(listDeliveryNotes) && listDeliveryNotes.length > 0 ? (
                            <Table
                                className="table-v2 custom-empty table-expandable"
                                rowKey={record => record.delivery_note.id}
                                columns={columns}
                                dataSource={listDeliveryNotes}
                                rowClassName={(record, index) => index % 2 !== 0 && 'table-row-odd'}
                                loading={isLoading}
                                bordered={false}
                                onRow={key => {
                                    return expandedRow.includes(key?.delivery_note?.id) && { className: 'table-row-expanded' }
                                }}
                                expandable={{
                                    expandedRowKeys: expandedRow,
                                    expandedRowRender: record => this.renderExpandedRow(record),
                                    expandIcon: expand => {
                                        return (
                                            <i
                                                class={`fa-solid fa-chevron-${
                                                    expand.expanded ? 'down' : 'right'
                                                } text-grey-400 cursor-pointer hover:text-black-100`}
                                                onClick={e => expand.onExpand(expand.record, e)}
                                            />
                                        )
                                    },
                                    onExpand: (expanded, record) => {
                                        if (expanded) {
                                            this.setState({
                                                expandedRow: [record.delivery_note.id],
                                            })
                                        } else {
                                            this.setState({
                                                expandedRow: [],
                                            })
                                        }
                                    },
                                }}
                                pagination={{
                                    className: '_pagination text-center width-100-percent mgt20',
                                    hideOnSinglePage: true,
                                    current: metadata ? parseFloat(metadata.page) + 1 : 1,
                                    total: metadata ? parseFloat(metadata.total) : 1,
                                    pageSize: metadata ? parseFloat(metadata.size) : pageSize,
                                    onChange: page => this.onPageChanged(page),
                                }}
                            />
                        ) : isLoading ? (
                            <Loading />
                        ) : (
                            <div className="pdt50 pdbt60 bg-color-white">
                                <Empty
                                    image={require('../../resources/images/empty-image.png')}
                                    description={t('message.empty')}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </MainLayout>
        )
    }
}
export default withNamespaces()(DeliveryNotes)
