import React from 'react'
import DocumentTitle from "react-document-title"
import {LocalStore} from "../../utils/LocalStore";
import {withNamespaces} from "react-i18next";
import image502 from "../../resources/images/img-maintenance.png";
import {Row, Col} from 'antd'
// import image404 from '../../resources/images/img404.png'
class Page404 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount () {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null;
        if (typeof LocalStore.getInstance().read('currentProjectInfo') === 'object') {
            this.setState({
                currentProjectInfo: LocalStore.getInstance().read('currentProjectInfo') || {}
            });
        }
    }

    render() {
        const {t} = this.props;
        return(
            <DocumentTitle title={`Oops`}>
                <div className="wrapper wrapper404 position-re">
                    <div className="wp404 position-fix txt-center dpl-flex justify-content align-items-center flex-direction-column" style={{bottom: 0, left: 0, right: 0, top: 0}}>
                        <Row gutter={20} className="width-100-percent dpl-flex align-items-center">
                            <Col span={12}>
                                <div className="">
                                    <img src={image502} alt="" style={{maxWidth: '100%'}}/>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="">
                                    <h3 style={{fontSize: 50}} className="txt-color-red utmhelve-regular line-height134 mgbt40 bold">
                                        {t('error.title_502page')}
                                    </h3>
                                    <div style={{fontSize: 40}} className="txt-color-blue">
                                        {t('error.page502_des_1')}
                                    </div>
                                    <div style={{fontSize: 40}} className="txt-color-blue">
                                        {t('error.page502_des_2')}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}
export default withNamespaces()(Page404);
