import { Col, Row, Space, Tooltip } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { withNamespaces } from 'react-i18next'
import StatisticService from '../../services/statistic.service'
import { moneyFormat, textUcFirst } from '../../utils/stringUtils'

const StatisticDetail = (props: any) => {
    const { t, type } = props

    const [dataMonth, setDataMonth] = useState<any>(null)
    const [dataYear, setDataYear] = useState<any>(null)
    const [dataMonthBefore, setDataMonthBefore] = useState<any>(null)
    const [dataYearBefore, setDataYearBefore] = useState<any>(null)

    const getDataStatistic = (type: string) => {
        if (type === 'request_payment') {
            StatisticService.getStatisticClarkMonthly(moment().format('YYYY'), moment().format('MM'), (err: any, res: any) => {
                if (!err) {
                    setDataMonth({ ...res, orderCount: res.total, expenditure: res.totalSpending })
                }
            })

            StatisticService.getStatisticClarkYearly(moment().format('YYYY'), (err: any, res: any) => {
                if (!err) {
                    setDataYear({ ...res, orderCount: res.total, expenditure: res.totalSpending })
                }
            })

            StatisticService.getStatisticClarkMonthly(
                moment().format('YYYY'),
                moment().subtract(1, 'M').format('MM'),
                (err: any, res: any) => {
                    if (!err) {
                        setDataMonthBefore({ ...res, orderCount: res.total, expenditure: res.totalSpending })
                    }
                }
            )

            StatisticService.getStatisticClarkYearly(moment().subtract(1, 'y').format('YYYY'), (err: any, res: any) => {
                if (!err) {
                    setDataYearBefore({ ...res, orderCount: res.total, expenditure: res.totalSpending })
                }
            })
        } else {
            StatisticService.getStatisticMonthly(type, moment().format('YYYY'), moment().format('MM'), (err: any, res: any) => {
                if (!err) {
                    setDataMonth(res)
                }
            })

            StatisticService.getStatisticYearly(type, moment().format('YYYY'), (err: any, res: any) => {
                if (!err) {
                    setDataYear(res)
                }
            })

            StatisticService.getStatisticMonthly(
                type,
                moment().format('YYYY'),
                moment().subtract(1, 'M').format('MM'),
                (err: any, res: any) => {
                    if (!err) {
                        setDataMonthBefore(res)
                    }
                }
            )

            StatisticService.getStatisticYearly(type, moment().subtract(1, 'y').format('YYYY'), (err: any, res: any) => {
                if (!err) {
                    setDataYearBefore(res)
                }
            })
        }
    }

    useEffect(() => {
        if (type) {
            getDataStatistic(type)
        }
    }, [type])

    const caculatorPercent = (now: number, before: number, typeNumber: string, typeDate: string) => {
        const type = now - before >= 0
        const percent = Math.round((Math.abs(now - before) / before) * 100)
        if (before) {
            return (
                <Tooltip
                    title={
                        <span className="text-xs">
                            {textUcFirst(
                                `${moment()
                                    .subtract(1, typeDate === 'MonthYear' ? 'M' : 'y')
                                    .format(t(`statistic.momentFormat${typeDate}`))}: ${
                                    before ? (typeNumber === 'currency' ? moneyFormat(before) : before) : 0
                                } ${t(`statistic.${typeNumber}`)}`
                            )}
                        </span>
                    }
                    className="cursor-pointer"
                    placement="right"
                >
                    <Space
                        align="center"
                        size={6}
                    >
                        <i
                            className={`fa-regular rounded-full p-[5.25px] text-[12px] h-6 ${
                                type
                                    ? 'fa-arrow-trend-up text-oceanGreen-500 bg-polar-50'
                                    : 'fa-arrow-trend-down text-red-100 bg-chablis-50'
                            }`}
                        ></i>
                        <span
                            className="robotoregular block text-xs"
                            dangerouslySetInnerHTML={{
                                __html: t(type ? 'statistic.increaseCompare' : 'statistic.decreaseCompare', {
                                    value: percent,
                                    date: moment()
                                        .subtract(1, typeDate === 'MonthYear' ? 'M' : 'y')
                                        .format(t(`statistic.momentFormat${typeDate}`)),
                                }),
                            }}
                        ></span>
                    </Space>
                </Tooltip>
            )
        }
        return null
    }

    return (
        <>
            <div className="mb-6">
                <div
                    className="robotoregular block mb-3 text-base font-medium"
                    dangerouslySetInnerHTML={{
                        __html: t('statistic.title', { date: moment().format(t('statistic.momentFormatMonthYear')) }),
                    }}
                ></div>
                <Row gutter={16}>
                    <Col>
                        <div className="w-[320px] p-6 rounded-2xl border border-solid border-gallery-100">
                            <Row
                                justify="space-between"
                                align="middle"
                                className="mb-4"
                            >
                                <Col>
                                    <div className="text-xs text-grey-400 mb-1">{t('statistic.totalOrder')}</div>
                                    <div>
                                        <Space
                                            align="baseline"
                                            size={2}
                                        >
                                            <span className="text-2xl font-bold text-black-100">
                                                {dataMonth?.orderCount || 0}
                                            </span>
                                            <span className="text-black-100">{t('statistic.orders')}</span>
                                        </Space>
                                    </div>
                                </Col>
                                <Col>
                                    <i className="fa-light fa-box-check text-xl text-black-100"></i>
                                </Col>
                            </Row>
                            <div>
                                {caculatorPercent(dataMonth?.orderCount, dataMonthBefore?.orderCount, 'orders', 'MonthYear')}
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="w-[320px] p-6 rounded-2xl border border-solid border-gallery-100">
                            <Row
                                justify="space-between"
                                align="middle"
                                className="mb-4"
                            >
                                <Col>
                                    <div className="text-xs text-grey-400 mb-1">{t('statistic.totalMoney')}</div>
                                    <div>
                                        <Space
                                            align="baseline"
                                            size={2}
                                        >
                                            <span className="text-2xl font-bold text-black-100">
                                                {moneyFormat(dataMonth?.expenditure || 0)}
                                            </span>
                                            <span className="text-black-100">{t('statistic.currency')}</span>
                                        </Space>
                                    </div>
                                </Col>
                                <Col>
                                    <i className="fa-light fa-yen-sign text-xl text-black-100"></i>
                                </Col>
                            </Row>
                            <div>
                                {caculatorPercent(dataMonth?.expenditure, dataMonthBefore?.expenditure, 'currency', 'MonthYear')}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="mb-6">
                <div
                    className="robotoregular block mb-3 text-base font-medium"
                    dangerouslySetInnerHTML={{
                        __html: t('statistic.title', { date: moment().format(t('statistic.momentFormatYear')) }),
                    }}
                ></div>
                <Row gutter={16}>
                    <Col>
                        <div className="w-[320px] p-6 rounded-2xl border border-solid border-gallery-100">
                            <Row
                                justify="space-between"
                                align="middle"
                                className="mb-4"
                            >
                                <Col>
                                    <div className="text-xs text-grey-400 mb-1">{t('statistic.totalOrder')}</div>
                                    <div>
                                        <Space
                                            align="baseline"
                                            size={2}
                                        >
                                            <span className="text-2xl font-bold text-black-100">
                                                {' '}
                                                {dataYear?.orderCount || 0}
                                            </span>
                                            <span className="text-black-100">{t('statistic.orders')}</span>
                                        </Space>
                                    </div>
                                </Col>
                                <Col>
                                    <i className="fa-light fa-box-check text-xl text-black-100"></i>
                                </Col>
                            </Row>
                            <div>{caculatorPercent(dataYear?.orderCount, dataYearBefore?.orderCount, 'orders', 'Year')}</div>
                        </div>
                    </Col>
                    <Col>
                        <div className="w-[320px] p-6 rounded-2xl border border-solid border-gallery-100">
                            <Row
                                justify="space-between"
                                align="middle"
                                className="mb-4"
                            >
                                <Col>
                                    <div className="text-xs text-grey-400 mb-1">{t('statistic.totalMoney')}</div>
                                    <div>
                                        <Space
                                            align="baseline"
                                            size={2}
                                        >
                                            <span className="text-2xl font-bold text-black-100">
                                                {moneyFormat(dataYear?.expenditure || 0)}
                                            </span>
                                            <span className="text-black-100">{t('statistic.currency')}</span>
                                        </Space>
                                    </div>
                                </Col>
                                <Col>
                                    <i className="fa-light fa-yen-sign text-xl text-black-100"></i>
                                </Col>
                            </Row>
                            <div>{caculatorPercent(dataYear?.expenditure, dataYearBefore?.expenditure, 'currency', 'Year')}</div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default withNamespaces()(StatisticDetail)
