import React from 'react'
import {notification} from 'antd';
import {getUrlQueryString} from "../../utils/helper";
import {parseQueryStringToObject} from "../../utils/stringUtils";
import {withNamespaces} from "react-i18next";
import OrderService from '../../services/orders.service';
import {LocalStore} from "../../utils/LocalStore";
import moment from 'moment';

class BiffinRedirect extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            access_token: '',
            nonce: '',
            onSubmit: true,
            confirmSuccess: false
        }
    }

    componentDidMount() {
        let queryObj = parseQueryStringToObject(getUrlQueryString());
        this.setState({
            access_token: queryObj.access_token,
            nonce: queryObj.nonce
        }, () => {
            this.onValidateLink();
        })
    }

    closeWindow = () => {
        setTimeout(() => {
            window.close();
        }, 0)
    }

    /**
     * validate link from bifin
     */
    onValidateLink = () => {
        const finNonce = LocalStore.getInstance().read('FinUuid');
        const {access_token, nonce} = this.state;
        // kiểm tra token có hợp lệ hoặc nonce có trùng so với lúc gửi đi không
        if (!this.state.access_token || nonce !== finNonce) {
            this.setState({onSubmit: false});
            notification.error({
                message: 'Token có vấn đề, vui lòng kiểm tra lại'
            });
            return;
        }
        this.setState({onSubmit: true}, () => {
            const body = {
                token: access_token,
                expiredAt: moment.utc(moment().add(365, 'days')).format(),
                validFrom: moment.utc(moment()).format()
            }

            OrderService.saveTokenBiffin(body, (err, res) => {
                if (!err) {
                    this.setState({
                        confirmSuccess: true
                    })
                    this.closeWindow();
                }
                this.setState({onSubmit: false})
            })
        })
    };

    render() {
        return <div></div>;
    }
}

export default withNamespaces()(BiffinRedirect);
