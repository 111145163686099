import { Button, Dropdown, Input, Pagination, Space, notification } from 'antd'
import React from 'react'
import CartListItem from './CartListItem'
import { useState } from 'react';
import ProductSkuAddCart from './Component/ProductSkuAddCart'
import Modal from '../../../../components/commons/Modal'
import cartTaobaoGlobal from '../../../../services/cartTaobaoGlobal.service'
import lodash from 'lodash';
import { useEffect } from 'react';
import { MenuProps } from 'rc-menu';


interface Step1Props {
    t: any,
    getListCartItem: (loading: boolean) => void;
    cartListItem: any
    checkedItems: any
    handleCheckboxChange: any
    handleCheckAllChange: any
    handleCheckAllMerchantsChange: any
    checkAllMerchants: any
    totalItem: any
    history: any
    pagination: any
    onChangePage: (page: any, pageSize: any) => void
    updateTotalPriceWhenChangeQuantiy: (itemId: string, skuId: string, quantity: any) => void
    loadingListItem: boolean,
    totalProductCart: number
    accountsConnect: any
    loadingAccount: boolean
    filter: any
    onClearParams: () => void
}

const REGEXS = [/mpId=(\d+)/, /[?&]id=(\d+)/]

const Step1: React.FC<Step1Props> = (props) => {

    const {
        t,
        loadingListItem,
        getListCartItem,
        cartListItem,
        checkedItems,
        handleCheckboxChange,
        handleCheckAllChange,
        handleCheckAllMerchantsChange,
        checkAllMerchants,
        totalItem,
        pagination,
        history,
        accountsConnect,
        updateTotalPriceWhenChangeQuantiy,
        onChangePage,
        totalProductCart,
        loadingAccount,
        onClearParams,
        filter
    } = props
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingMp, setLoadingMp] = useState<boolean>(false)
    const [isModalAddCartOpen, setIsModalAddCartOpen] = useState<boolean>(false)
    const [detailProductMp, setDetailProductMp] = useState<any>(null)
    const [textInput, setTextInput] = useState<string>()
    const [currentItemId, setCurrentItemId] = useState<any>()
    const credentialId = lodash.get(accountsConnect?.[0], 'id') ? lodash.get(accountsConnect?.[0], 'id') : null
    const [selectedThirtParty, setSelectedThirtParty] = useState('tb')

    // const [detailProductTb, setDetailProductTb] = useState<any>(null)

    /**
     * Hàm chuyển hóa dữ liệu từ API sang dữ liệu hiển thị
     * @param data
     */
    const transformProduct = (data: any): any => {
        if (!data) return {} as any
        const properties: any[] = []
        const attributes: any[] = []


        const mapAttribute = (item: any): any | undefined => {
            const find = attributes.find((i: any) => i.value_id === item.id && i.property.originalName === item.originalName)
            if (find) return find

            properties.find((i: any) => i.originalName === item.originalName) ||
                properties.push({
                    id: item.originalName,
                    name: item.originalName,
                    originalName: item.originalName,
                    // localized_name: mapLanguage(item.pTextMulti.langAndValueMap),
                    attributes: [],
                    is_image: false,
                    is_unique_image: false
                })

            const property = properties.find((i: any) => i.originalName === item.originalName)

            if (!property) return

            const attribute: any = {
                id: item.id,
                value_id: item.id,
                name: item.value,
                value: item.originalValue,
                // localized_value: mapLanguage(item.vTextMulti.langAndValueMap),
                property: property
            }

            property?.attributes.push(attribute)

            attributes.push(attribute)
            return attribute
        }

        const sku = data?.skus?.map((item: any): any => {
            return {
                attributes: item.variantProperties.map((i: any): any | undefined => {
                    return mapAttribute(i)
                }),
                images: item.images,
                skuId: item.skuId,
                inventory: item.stock,
                price: item.salePrice,
                currency: item.currency
            }
        })

        //map sku to attributes
        sku?.forEach((item: any) => {
            item.attributes.forEach((i: any | undefined) => {
                if (!i) return
                const find = attributes.find((a: any) => a.id === i.id)
                if (find) return
                attributes.push(i)
            })
        })

        const getImageId = (image: string | undefined) => {
            if (!image) return null

            const match = image.match(/\/([^/]*)\.jpg/)
            if (!match) return image
            return match?.[1]
        }

        //if all sku with same attributes has same image then set image to that attribute
        attributes.forEach((item: any) => {
            const images = sku.filter((i: any) => {
                return i.attributes.find((a: any) => a.id === item.id)
            }).map((i: any) => i.images?.[0])

            if (images.every((i: string) => i && getImageId(i) === getImageId(images[0]))) {
                item.image = images[0]
            }
        });

        //if attribute has unique image in the same property then set is_unique_image = true
        attributes.forEach((item: any) => {
            const find =
                attributes.find((i: any) => getImageId(i.image) === getImageId(item.image)
                    && i.id !== item.id && i.property.id === item.property.id)
            item.is_unique_image = !find
        })

        //property is image if all of its attributes has image
        properties.forEach((item: any) => {
            item.is_image = item.attributes.every((i: any) => i.image)
        })

        //property is unique image if all of its attributes has unique image
        properties.forEach((item: any) => {
            item.is_unique_image = item.attributes.every((i: any) => i.is_unique_image)
        })

        return {
            currency: data.currency,
            description: data.description,
            images: data.productImages,
            inventory: data.stock,
            item_id: data.mappingId,
            // localized_title: {
            //     US_en: data.title,
            //     data.cn_title
            // },
            // market_place: data.sourceMarket,
            price: data.salePrice,
            sku: sku,
            title: data.title,
            sub_title: data.short_title,
            properties: properties
        }
    }

    const getDetailProduct = (itemId: any, credentialId: string, type: string) => {
        setLoadingMp(true)
        cartTaobaoGlobal.getDetailProduct(itemId, credentialId, type, (err: any, res: any) => {
            if (!err) {
                if (res) {
                    setDetailProductMp(res)
                    setIsModalAddCartOpen(true)
                } else {
                    setDetailProductMp({})
                    setIsModalAddCartOpen(true)
                }
            }
            else {
                setIsModalAddCartOpen(true)
                setDetailProductMp({})
            }
            setLoadingMp(false)

        })
    }

    const onSearch = (urlTaobao?: any, credential: any = credentialId, type = selectedThirtParty) => {
        const itemId = textInput?.match(REGEXS[1]) || textInput?.match(REGEXS[0]) || urlTaobao?.match(REGEXS[1]) || urlTaobao?.match(REGEXS[0])
        const itemIdValue = (itemId && itemId[1]) || textInput
        getDetailProduct(itemIdValue, credential || [], type)
        setCurrentItemId(itemIdValue)
        setIsModalAddCartOpen(true)

    }

    const handleOnChangeFilter = (value: string, key: string) => {
        if (key === "itemId") {
            setTextInput(value)
        }
    }

    const onAddToCart = (sku: any, selectItem: any, quantity: number, callback?: () => void, type?: string) => {
        // const currentItemId = textInput?.match(REGEXS[1])

        const body: any = {
            itemId: detailProductMp.mappingId,
            oItemId: selectedThirtParty === 'tbg' ? null : currentItemId,
            skus: [{
                skuId: selectItem,
                quantity: quantity
            }]
        }
        setLoading(true)
        setIsModalAddCartOpen(false)
        cartTaobaoGlobal.addProductToCart(body, (err: any, res: any) => {
            if (!err) {
                notification['success']({
                    message: t('taobaoGlobalCart.addToCartSuccess'),
                });
                if (type !== 'url') {
                    getListCartItem(true)
                }
                else {
                    history.push({
                        pathname: history.location.pathname,
                        search: '',
                    });
                }
                setTextInput('')
                setDetailProductMp({})
            }
            else {
                notification['error']({
                    message: t('taobaoGlobalCart.addToCartError'),
                });
            }
            setLoading(false)


        })
    }

    useEffect(() => {
        const queryString = history.location.search
        if (queryString?.includes('?link=')) {
            const urlTaobao = queryString?.replace('?link=', '')
            credentialId && urlTaobao && onSearch(urlTaobao, credentialId, 'tb')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credentialId])

    const handleChangeThirtParty = (value: any) => {
        setSelectedThirtParty(value.key)
    };

    const handleClearParam = () => {
        const queryString = history.location.search
        if (queryString?.includes('?link=')) {
            history.push({
                pathname: history.location.pathname,
                search: '?link'
            });
        }
    }

    const checkTotalItem = (totalItem < 0 || totalItem === 0)
    const className = !checkTotalItem ? "text-black-100 font-medium text-sm" : "text-grey-200 font-normal text-sm"
    const checkFindProduct = detailProductMp && Object.values(detailProductMp)?.length > 0

    const items: MenuProps['items'] = [
        {
            key: "tb",
            label: <span className='text-grey-200 font-normal'>Taobao</span>,
            onClick: handleChangeThirtParty
        },
        {
            type: 'divider'
        },
        {
            key: 'tbg',
            label: <span className='text-grey-200 font-normal'>Taobao Global</span>,
            onClick: handleChangeThirtParty
        }
    ];

    return (
        <>
            <div className="TaobaoGlobalCart px-6 py-4 bg-white rounded-xl flex items-center justify-between">
                <div className="flex items-center gap-1">
                    <span className="font-medium text-base text-black-100 ">
                        {`${t('taobaoGlobalCart.cart')}`}
                    </span>
                    <span className='bg-blue-primary rounded-full font-medium text-[10px] text-white px-[6px] py-[1px]'>{totalItem}</span>
                </div>
                <div className='grid'>
                    <div className="flex items-center gap-2 ">
                        <div className='flex'>
                            <Space.Compact>
                                <div className='flex items-center tbg-select-thirtParty py-1 px-3'>
                                    <span className='mr-1'>{t('taobaoGlobalCart.findProduct')}</span>
                                    <Dropdown menu={{ items }} trigger={['click']} overlayClassName='tbg-select-popup'>
                                        <div className='flex items-center cursor-pointer'>
                                            <div className='w-[120px] font-medium text-black'>
                                                {selectedThirtParty === 'tbg' ? `Taobao Global ` : 'Taobao'}
                                                <i className="fa-solid fa-angle-down text-gray-400 ml-1"/>
                                            </div>
                                        </div>
                                    </Dropdown>
                                </div>
                                <Input
                                    className="tbg-input-search"
                                    allowClear
                                    placeholder={`${t('taobaoGlobalCart.pasteLink')} ${selectedThirtParty === 'tbg' ? 'taobao global' : 'taobao'}`}
                                    size='small'
                                    disabled={loading || !credentialId}
                                    value={textInput}
                                    onPressEnter={() => { textInput && onSearch() }}
                                    onChange={(e) => handleOnChangeFilter(e.target.value, "itemId")}
                                />
                            </Space.Compact>
                            <Button
                                className='tbg-btn-submit'
                                disabled={!credentialId}
                                onClick={() => { textInput && onSearch() }}
                                icon={<i className="fa-solid fa-magnifying-glass text-white" />} />
                        </div>
                    </div>
                    {loadingAccount ? <></> : !credentialId && <div className='text-end text-error mt-1'>{t('taobaoGlobalCart.inValidCredentialAccount')}</div>}
                </div>

            </div>

            <CartListItem
                totalProductCart={totalProductCart}
                getListCartItem={getListCartItem}
                t={t}
                filter={filter}
                className={className}
                totalItem={totalItem}
                updateTotalPriceWhenChangeQuantiy={updateTotalPriceWhenChangeQuantiy}
                checkedItems={checkedItems}
                onChangePage={onChangePage}
                pagination={pagination}
                loading={loading}
                onClearParams={onClearParams}
                loadingListItem={loadingListItem}
                cartListItem={cartListItem}
                checkAllMerchants={checkAllMerchants}
                handleCheckboxChange={handleCheckboxChange}
                handleCheckAllChange={handleCheckAllChange}
                handleCheckAllMerchantsChange={handleCheckAllMerchantsChange}
                currentItemId={currentItemId}
            />

            <div className="flex v2-layout justify-end mt-3 text-center screen-sm:justify-center space-x-4">
                <Pagination
                    className={"pagination"}
                    {...pagination}
                    total={pagination?.total || 0}
                    onChange={(page, pageSize) => {
                        window.scrollTo(0, 0);
                        onChangePage(page, pageSize)
                    }}
                />
            </div>
            {
                isModalAddCartOpen &&
                <Modal
                    maskClosable={!checkFindProduct ? true : false}
                    className="modalAddToCart"
                    bodyStyle={{ padding: "0 40px 40px 40px" }}
                    title={null}
                    visible={isModalAddCartOpen}
                    onCancel={() => {
                        setIsModalAddCartOpen(false)
                        setDetailProductMp({})
                        handleClearParam()
                    }}

                    width={checkFindProduct ? 860 : 640}
                >
                    <ProductSkuAddCart
                        key={detailProductMp?.item_id}
                        product={transformProduct(detailProductMp)}
                        onAddToCart={onAddToCart}
                        onClose={() => {
                            setIsModalAddCartOpen(false)
                        }}
                        loading={loadingMp}
                        t={t}
                        history={history}
                    />
                </Modal >
            }
        </>
    )
}

export default Step1