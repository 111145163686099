/* eslint-disable no-eval */
/* eslint-disable no-template-curly-in-string */
import { hoursDeadline, secondsDeadline } from './stringUtils'
import { LocalStore } from '../utils/LocalStore'
import moment from 'moment'

export function isPage(page) {
    let params = window.location.href.split('#')
    let activeTab = params[1] && params[1] !== '/' ? params[1].substr(1) : 'dashboard'
    if (page === activeTab) return true
    return false
}

/**
 * Get query string from URL
 * @param url
 * @returns {*}
 */
export function getUrlQueryString(url = '') {
    if (!url) url = window.location.href
    if (url.indexOf('?') < 0) {
        return ''
    }
    return url.substr(url.lastIndexOf('?') + 1)
}

/**
 * Get params from url by key
 * @param name
 * @param url
 * @returns {*}
 */
export function getUrlParameterByName(name, url) {
    if (!url) url = window.location.href
    name = name.replace(/[[\]]/g, '\\$&')
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

/**
 * Replace url parameter
 * @param url
 * @param paramName
 * @param paramValue
 * @returns string newURL
 */
export function replaceUrlParam(url, paramName, paramValue) {
    if (!url) url = window.location.href

    if (paramValue == null) {
        paramValue = ''
    }
    let pattern = new RegExp('\\b(' + paramName + '=).*?(&|$)')
    if (url.search(pattern) >= 0) {
        return url.replace(pattern, '$1' + paramValue + '$2')
    }
    url = url.replace(/\?$/, '')
    // return url + (url.indexOf('?')>0 ? '&' : '?') + paramName + '=' + paramValue;
    let queryString = paramName + '=' + paramValue
    let fullURL = url + (url.indexOf('?') > 0 ? '&' : '?') + queryString

    return fullURL
}

/**
 * Lấy module hiện tại trên URL
 * @returns {string}
 */
export function getCurrentModule(url) {
    if (!url) url = window.location.hash
    let regex = new RegExp(/#\/([a-z-]+)/g)
    let matches = regex.exec(url)
    if (matches && matches.length) {
        let currentModule = matches[1]
        return currentModule
    }
    return null
}

/**
 * Parse an object to http_query_string
 * @param obj
 * @returns {string}
 */
export function httpBuildQueryString(obj) {
    let keys = Object.keys(obj)
    let queryArray = []
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i]
        let value = obj[key]
        queryArray.push(`${key}=${value}`)
    }

    return queryArray.join('&')
}

/**
 * Kiểm tra hiển thị đánh dấu ticket|claim
 * @param item
 * @return boolean
 */
export const isUpdateTicket = item => {
    if (item.lastView !== null) {
        let lastView = new Date(item.lastView)
        let lastActivity = new Date(item.lastActivity)
        if (lastActivity > lastView) {
            return true
        }
        return false
    }
    return false
}

/**
 * Kiểm tra hiển hiện tooltip cảnh báo quá hạn, tới hạn, đánh dấu ticket|claim
 * @param item
 * @param currentState
 * @return {*}
 */
export const checkShowWarningDeadline = (item, currentState) => {
    if (!item) return
    let deadline = item && item.deadline ? item.deadline : null
    let currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser')
    let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo')
    let warningPeriod =
        currentProjectInfo && currentProjectInfo.applicationConfig ? currentProjectInfo.applicationConfig.warningPeriod : null
    if (item.modifiedBy !== currentLoggedUser.username && isUpdateTicket(item)) {
        return true
    } else if (
        item.state !== 'NEW' &&
        currentState &&
        currentState.lastState === false &&
        (secondsDeadline(deadline) <= 0 || hoursDeadline(deadline) <= warningPeriod) &&
        currentLoggedUser.internal
    ) {
        return true
    }
    return false
}

export const spawnNotification = (title, body, link, history) => {
    // const options = {
    //     body: body,
    //     icon: require('../resources/images/logo-collapsed.png'),
    // }
    // const n = new Notification(title, options);
    // n.onclick = function(event) {
    //     event.preventDefault(); // prevent the browser from focusing the Notification's tab
    //     if (history) {
    //         window.focus();
    //         history.push({
    //             pathname: link.pathname,
    //             search: link.queryString
    //         })
    //     } else {
    //         window.open(link.pathname + link.queryString, '_blank');
    //     }
    // };
    // console.log('spawnNotification', n)
}

export const browserVersion = () => {
    let ua = navigator.userAgent,
        tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || []
        return 'IE ' + (tem[1] || '')
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
        if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera')
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
    return M.join(' ')
}

export const loadJsAsync = (src, lastItem, cb) => {
    let n = document.createElement('script')
    n.type = 'text/javascript'
    n.src = src
    n.addEventListener(
        'load',
        () => {
            if (cb && lastItem) cb()
        },
        false
    )
    const a = document.getElementsByTagName('head')[0]
    a.appendChild(n)
}

export const initialJsAppend = res => {
    let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo')
    if (currentProjectInfo && currentProjectInfo.tenantConfig && currentProjectInfo.tenantConfig.customScript) {
        let scriptAppend = currentProjectInfo.tenantConfig.customScript

        scriptAppend = scriptAppend.replace('${customer.username}', res.username)
        scriptAppend = scriptAppend.replace('${customer.email}', res.email)
        scriptAppend = scriptAppend.replace('${customer.phone}', res.phone)

        window.eval(scriptAppend)
    }
}

export const initWechat = res => {
    // initialWechat
    let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo')

    if (currentProjectInfo && currentProjectInfo.tenantConfig && currentProjectInfo.tenantConfig.customExternalScripts) {
        let srcs = currentProjectInfo.tenantConfig.customExternalScripts || []
        //append customExternalScripts
        srcs.map((item, index) => loadJsAsync(item, index === srcs.length - 1, () => initialJsAppend(res)))
    } else {
        initialJsAppend()
    }
}

export const getBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onload = () => resolve(reader.result)

        reader.onerror = error => reject(error)
    })

export const toBoolean = value => {
    switch (value) {
        case 0:
        case '0':
        case false:
        case 'false':
            return false
        case 1:
        case '1':
        case true:
        case 'true':
            return true
        default:
    }
}

export const openPopupCenter = ({ url, title, w, h }, cb) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

    const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : window.screen.width
    const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : window.screen.height

    const systemZoom = width / window.screen.availWidth
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(
        url,
        title,
        `
      scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `
    )

    if (window.focus) newWindow.focus()

    if (typeof cb === 'function') cb(newWindow)
}

export function formatCurrencyChina(amount) {
    const formatter = new Intl.NumberFormat('zh-CN', {
        style: 'currency',
        currency: 'CNY',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    })

    return formatter.format(amount)
}

export function calculatePercentage(totalLoanAmount, emdAmount) {
    if (emdAmount === 0) {
        return 0
    }

    if (typeof totalLoanAmount !== 'number' || typeof emdAmount !== 'number') {
        return 0
    }

    if (totalLoanAmount < 0 || emdAmount <= 0) {
        return 0
    }

    if (totalLoanAmount > emdAmount) {
        return 0
    }

    const percentage = (totalLoanAmount / emdAmount) * 100
    return Math.round(percentage)
}

export function percentToMoney(percent, totalMoney) {
    return Math.floor((totalMoney * percent) / 100)
}

export function momeyToPercent(money, totalMoney) {
    if (totalMoney > 0 && money > 0) {
        return Math.floor((money / totalMoney) * 100)
    }
    return 0
}

export const genQrCode = ({ bank, accountName, accountNumber, amount, memo }) => {
    return `https://img.vietqr.io/image/${bank}-${accountNumber}-${
        amount && Number(amount) >= 0 ? 'compact2' : 'print'
    }.png?amount=${amount}&addInfo=${encodeURIComponent(memo)}&accountName=${encodeURIComponent(accountName)}`
}

export function renderTimeMidMile(record, categoriesCouriers, warehouse) {
    const courierSelected = categoriesCouriers?.find(i => i?.code === record?.courier)
    const checkWarehouse = courierSelected?.additionalConfig?.warehouses?.find(i => i?.code === warehouse)
    if (checkWarehouse?.minMidMileEstTime && checkWarehouse?.maxMidMileEstTime) {
        const startTime = moment(record?.lastStatusTime)
            .add(checkWarehouse?.minMidMileEstTime || 0, 's')
            .format('DD/MM')
        const endTime = moment(record?.lastStatusTime)
            .add(checkWarehouse?.maxMidMileEstTime || 0, 's')
            .format('DD/MM')

        let result = ''
        if (startTime === endTime) {
            result = startTime
        } else if (startTime.slice(2) === endTime.slice(2)) {
            result = `${startTime.slice(0, 2)} - ${endTime}`
        } else {
            result = `${startTime} - ${endTime}`
        }
        return (
            <div className="flex items-center gap-2 mt-1">
                <i className="fa-regular fa-truck-fast text-xs text-oceanGreen-500"></i>
                <span className="text-oceanGreen-500 text-xs">Dự kiến về kho {result}</span>
            </div>
        )
    } else return null
}
