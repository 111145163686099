import React from "react";
import {List, Spin, Row, Divider} from "antd";
import lodash from "lodash";
import moment from 'moment';
import NoData from "../NoData";
import Loading from "../Loading";
import {withNamespaces} from "react-i18next";

class TabLog extends React.Component{
    formatLogsContent = (data) => {
        const {t} = this.props;
        let result = `${t(`shipment_log.${data.property}`, data)}`;
        return result;

    };
    onRecall = () => {
        this.props.getOrderLog();
    };

    render() {
        let {t, initLoading, loadingMore, showLoadMore, logs, orderLogErrors, onLoadMore} = this.props;
        const loadMoreDisplay = !initLoading && !loadingMore && showLoadMore ?
            (<div className={'mgt5 mgbt5'} style={{textAlign: 'center', height: 32, lineHeight: '32px'}}>
                <span className={'_text-load-more cursor-pointer'} onClick={onLoadMore}>{t('shipment_log.loading_more')}</span>
            </div>)
            : null;

        return (
            !lodash.isEmpty(logs) ?
                <List
                    className="demo-loadmore-list _order-logs order-log-list pdl20"
                    loading={initLoading}
                    itemLayout="horizontal"
                    loadMore={loadMoreDisplay}
                    dataSource={logs}
                    renderItem={(itemTransform, index) => {
                        return (
                            <div>
                                <div>
                                    <Row key={lodash.uniqueId()}
                                         className={`_row-log-info txt-size-h7 txt-color-gray robotoregular dpl-block ${index !== 0 && 'mgt12'}`}>
                                        <span>{moment(itemTransform.timestamp).format('HH:mm DD/MM/YYYY')}</span>,
                                        <span>
                                            <span className={'pdl5'}>{itemTransform.role === 'CUSTOMER' ? t('shipment_log.customer') : t('shipment_log.staff')}:</span>
                                            <span className={'txt-color-black robotobold pdl5'}>{itemTransform.fullname}</span>
                                        </span>
                                    </Row>
                                    <Row
                                        className={`_row-log-content txt-size-h6 txt-color-black robotoregular dpl-block whitespace-pre-wrap break-word`}
                                        dangerouslySetInnerHTML={{__html: this.formatLogsContent(itemTransform)}}
                                    />
                                    <Divider className={'mgt12 mgbt0'} style={{backgroundColor: '#e8e8e8'}}/>
                                </div>
                            </div>
                        )
                    }}
                >
                    {loadingMore &&
                    <div className="text-center" style={{marginTop: 15, marginBottom: 15}}>
                        <Spin/>
                    </div>
                    }
                </List>
                :
                initLoading ?
                    <div className={'txt-center'}>
                        <Loading height="30vh"/>
                    </div>
                    :
                    <NoData error={orderLogErrors} onRecall={this.onRecall}/>
        );
    }
}

export default withNamespaces()(TabLog);