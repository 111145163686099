/* eslint-disable array-callback-return */
import { Col, List, Radio, Row, Typography } from 'antd'
import _, { isNull } from 'lodash'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import DeliveryService from '../../../services/delivery.service'
import UserService from '../../../services/user.service'
import OrderServices from '../../../services/orders.service'

import { LocalStore } from '../../../utils/LocalStore'
import { moneyCeil, moneyFormat } from '../../../utils/stringUtils'
import InputMoney from '../../InputMoney'
import BifinService from '../../../services/bifin.service'

type Props = {
    value: {
        type: string
        money: number
    } | null
    onChange: (value: { type: string; money: number }) => void
    t: any
}

export const ChoosePaymentMethod: FC<Props> = ({ value, onChange, t }) => {
    const ref: any = useRef(null)
    const currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {}
    const [listOrder, setListOrder] = useState<any>(null)
    const [balance, setBalance] = useState<number | null>(null)
    const [listCarts, setListCarts] = useState<any>(null)
    const [money, setMoney] = useState<any>(null)
    const [bifinLoanCredits, setBifinLoanCredits] = useState<number | null>(null)

    const username = currentLoggedUser?.fullname || ''

    const [loading, setLoading] = useState<{
        balanceLoading: boolean
        listCardLoading: boolean
        listOrderLoading: boolean
        loanCredits: boolean
    }>({
        balanceLoading: false,
        listCardLoading: false,
        listOrderLoading: false,
        loanCredits: false,
    })

    const getThirdPatiesLoans = (data: any) => {
        if (Array.isArray(data) && data.length === 0)
            return setLoading(value => ({
                ...value,
                loanCredits: false,
            }))
        let codes = data?.map((item: any) => item?.code)
        codes = codes.join(',')
        let queryString = `?orderCodes=${codes}`
        BifinService.getThirdPatiesLoans(queryString, (err: any, res: any) => {
            if (!err) {
                setBifinLoanCredits(
                    moneyCeil(
                        _.sumBy(res?.loanCredits, (o: any) => {
                            return o?.totalAmountPay || 0
                        })
                    )
                )
            }
            setLoading(value => ({
                ...value,
                loanCredits: false,
            }))
        })
    }

    useEffect(() => {
        setLoading({
            balanceLoading: true,
            listCardLoading: true,
            listOrderLoading: true,
            loanCredits: false,
        })
        UserService.getBalanceUser((err: any, res: any) => {
            if (!err) {
                setBalance(res?.balance || 0)
            }
            setLoading(value => ({
                ...value,
                balanceLoading: false,
            }))
        })
        OrderServices.getListCarts(
            currentLoggedUser.username,
            '?page=0&size=9999&sort=modifiedAt:desc',
            async (err: any, res: any) => {
                if (!err) {
                    setListCarts(res)
                }
                setLoading(value => ({
                    ...value,
                    listCardLoading: false,
                }))
            }
        )
        DeliveryService.getListAvailableOrder((err: any, res: any) => {
            if (!err) {
                setListOrder(res)
                setLoading(value => ({
                    ...value,
                    loanCredits: true,
                }))
                getThirdPatiesLoans(res)
            }
            setLoading(value => ({
                ...value,
                listOrderLoading: false,
            }))
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const caculateCart = (data: any) => {
        return moneyCeil(
            _.sumBy(data, (o: any) => {
                return o?.exchangedTotalValue || 0
            }) *
                ((currentLoggedUser?.customerGroup?.emdPercent || 100) / 100)
        )
    }

    const calculateOrder = (data: any) => {
        const listPackage = _.compact(_.flatten(data?.map((i: any) => i?.packages)))

        let listOrderPackages = listPackage.filter((x: any) => !x.isShipment)
        let listShipmentPackages = listPackage.filter((x: any) => x.isShipment)
        let arrOrder: any = []
        listOrderPackages.map((item: any) => {
            let curOrder = arrOrder.find((x: any) => x.orderCode === item.orderCode)
            if (curOrder) {
                if (!Array.isArray(curOrder.packages)) {
                    curOrder.packages = []
                }
                if (!curOrder.packages.find((x: any) => x === item.code)) {
                    curOrder.packages.push(item.code)

                    arrOrder = [...arrOrder.filter((x: any) => x.orderCode !== item.orderCode), curOrder]
                }
            } else {
                arrOrder = [...arrOrder, { orderCode: item.orderCode, packages: [item.code] }]
            }
        })
        let arrShipment: any = []
        listShipmentPackages.map((item: any) => {
            let curOrder = arrShipment.find((x: any) => x.orderCode === item.orderCode)
            if (curOrder) {
                if (!Array.isArray(curOrder.packages)) {
                    curOrder.packages = []
                }
                if (!curOrder.packages.find((x: any) => x === item.code)) {
                    curOrder.packages.push(item.code)

                    arrShipment = [...arrShipment.filter((x: any) => x.orderCode !== item.orderCode), curOrder]
                }
            } else {
                arrShipment = [...arrShipment, { orderCode: item.orderCode, packages: [item.code] }]
            }
        })
        let total: any = 0

        // đây là dành cho ordert thường
        arrOrder.map((x: any) => {
            let curOrder = data.find((y: any) => y.code === x.orderCode)
            if (curOrder && curOrder.totalUnpaid) total = total + curOrder.totalUnpaid
        })
        // đây dành cho đơn ký gửi
        arrShipment.map((x: any) => {
            let curShipment = data.find((y: any) => y.code === x.orderCode)
            if (curShipment) {
                total = total + curShipment.needToPaid

                curShipment.packages.map((y: any) => {
                    if (!listPackage.find((z: any) => z.code === y.code)) {
                        total = total - y.shippingFee
                    }
                })
            }
        })

        return moneyCeil(total)
    }

    const optionMethod = useMemo(
        () => [
            {
                title: t('deposit.purposePackage'),
                subTitle: (
                    <span className=" text-sm font-medium">
                        {moneyFormat(moneyCeil(calculateOrder(listOrder) - (balance || 0)) + (bifinLoanCredits || 0))}
                    </span>
                ),
                type: 'package',
                valueMoney: moneyCeil(calculateOrder(listOrder) - (balance || 0) + (bifinLoanCredits || 0)),
                visible:
                    listOrder?.length > 0 &&
                    !_.isNil(balance) &&
                    moneyCeil(calculateOrder(listOrder) - (balance || 0) + (bifinLoanCredits || 0)) > 0
                        ? true
                        : false,
            },
            {
                title: t('deposit.purposeOrder'),
                info: t('deposit.purposeOrderPercent', { value: currentLoggedUser?.customerGroup?.emdPercent || 100 }),
                subTitle: (
                    <span className=" text-sm font-medium">
                        {moneyFormat(moneyCeil(caculateCart(listCarts) - (balance || 0)))}
                    </span>
                ),
                type: 'order',
                valueMoney: moneyCeil(caculateCart(listCarts) - (balance || 0)),
                visible:
                    listCarts?.length > 0 && !_.isNil(balance) && moneyCeil(caculateCart(listCarts) - (balance || 0)) > 0
                        ? true
                        : false,
            },
            {
                title: t('deposit.purposeOther'),
                type: 'other',
                subTitle: <span className=" text-sm font-medium text-grey-400">{t('deposit.purposeOtherSubtitle')}</span>,
                visible: true,
                extend: (
                    <InputMoney
                        innerref={ref}
                        disabled={value?.type !== 'other'}
                        placeholder={t('deposit.anotherNumber')}
                        onChange={(value: any) => {
                            onChange({
                                type: 'other',
                                money: value,
                            })
                        }}
                        value={money}
                    />
                ),
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [value, listOrder, listCarts, currentLoggedUser, balance]
    )

    useEffect(() => {
        if (value?.type === 'other') {
            setMoney(value?.money || undefined)
        }

        if (isNull(value)) {
            setMoney(undefined)
        }
    }, [value])

    return (
        <div>
            <Typography.Paragraph className="text-grey-200 font-normal mb-5">
                <span dangerouslySetInnerHTML={{ __html: t('deposit.step1Content', { username }) }} />
            </Typography.Paragraph>
            <Radio.Group
                value={value?.type}
                className="w-full"
            >
                <List
                    loading={
                        loading?.balanceLoading || loading?.listCardLoading || loading?.listOrderLoading || loading?.loanCredits
                    }
                    itemLayout="horizontal"
                    dataSource={optionMethod}
                    renderItem={item => {
                        return (
                            item?.visible && (
                                <List.Item
                                    className="hover:bg-gallery-200 cursor-pointer"
                                    onClick={() => {
                                        onChange({
                                            type: item?.type,
                                            money: item?.valueMoney || money,
                                        })
                                        if (item?.extend && ref?.current) {
                                            setTimeout(() => ref?.current?.focus(), 100)
                                        }
                                    }}
                                    key={item?.type}
                                >
                                    <List.Item.Meta
                                        title={
                                            <Row align="middle">
                                                <Col className="text-center">
                                                    <Radio
                                                        className="mx-3"
                                                        value={item?.type}
                                                    ></Radio>
                                                </Col>
                                                <Col flex="auto">
                                                    <Row
                                                        justify="space-between"
                                                        align="middle"
                                                    >
                                                        <Col>
                                                            <div className="flex">
                                                                <span className=" text-xs font-normal text-black-100">
                                                                    {item?.title}
                                                                </span>
                                                                &nbsp;
                                                                <span className="text-xs font-normal text-grey-400">
                                                                    {item?.info}
                                                                </span>
                                                            </div>
                                                            <div>{item?.subTitle}</div>
                                                        </Col>
                                                        <Col>{item?.extend}</Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        }
                                    />
                                </List.Item>
                            )
                        )
                    }}
                />
            </Radio.Group>
        </div>
    )
}
