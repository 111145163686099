import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {LocalStore} from "../../utils/LocalStore";
import {USER_STATUS} from '../../constants/config'


export default class PrivateRoute extends React.Component{

    render(){
        const { component: Component, RouteKey, location, ...rest } = this.props;

        /**
         * Sometimes we need to force a React Route to re-render when the
         * search params (query) in the url changes. React Router does not
         * do this automatically if you are on the same page when the query
         * changes. By passing the `RouteKey`ro the `ScrollToTopRoute` and
         * setting it to true, we are passing the combination of pathname and
         * search params as a unique key to the component and that is a enough
         * and clear trigger for the component to re-render without side effects
         */
        const Key = RouteKey ? location.pathname + location.search : null;
        return(
            <Route exact={true} {...rest} key={Key} render={props => {
                const currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser');
                return (
                    LocalStore.getInstance().read('loginSession')
                        ?
                        currentLoggedUser.customerStatus === USER_STATUS.LOCKED || currentLoggedUser.customerStatus === USER_STATUS.INACTIVE
                            ?
                            <Redirect to={{ pathname: '/403', state: { from: location } }} />
                            :
                            <Component {...props} />
                        :
                        <Redirect to={{ pathname: '/login', state: { from: location } }} />
                )
            }} />
        );
    }
}
