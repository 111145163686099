import React from "react";
import {Spin, Tabs} from 'antd';
import * as AntdIcon from "@ant-design/icons"
import * as Constants from '../../constants/config';
import TabFees from './TabFees';
import TabWaybill from './TabWaybill';
import {withNamespaces} from "react-i18next";
import TabLog from "./TabLog";
import TabFinance from "./TabFinance";
import TabHistory from "./TabHistory";
import TabProduct from "./TabProduct";
import TabClaims from "./TabClaims";
import {quantityFormat} from "../../utils/stringUtils";
import TabCredit from "./TabCredit";

const TabPane = Tabs.TabPane;


class TabLayout extends React.Component{
    constructor(props){
        super(props);
        this.state = {
        }
    }

    render(){
        const {
            t, onChangedTab, listStatuses, activeTab, orderDetail, listFinancialClaim, listFinancialCollect, listFees, isLoadingFees, loadingProduct, listProduct,
            isLoadingWaybill, listWaybills, submittingWaybill, createWaybill, deletingWaybill, deleteWaybill, listWaybillParcels, isLoadingWaybillParcel, statusPackageList,
            initLoading, loadingMore, logs, orderLogErrors, showLoadMore, getOrderLog, onLoadMore, deleteProduct, productCreating, createProduct,
            finance, isLoadingFinance, errFinance, getFinance, isLoadingListMilestones, listMilestones, shipmentFees, listTickets, listStatusesTicket, isLoadingListTickets, onUploadImage, listHS
        } = this.props;
        return(
            <div className='bg-color-white'>
                <Tabs className="wrappertabs"
                    activeKey={activeTab ? activeTab : Constants.SHIPMENT_DETAIL_TAB_WAYBILLS}
                    defaultActiveKey={activeTab ? activeTab : Constants.SHIPMENT_DETAIL_TAB_WAYBILLS} onChange={onChangedTab}
                >
                    <TabPane className={'_tabProduct tabdetailorder'} tab={<span className={'_tabProduct-btn'}>{t('product_tab.product')}</span>} key={Constants.ORDER_DETAIL_TAB_PRODUCT}>
                        <Spin spinning={loadingProduct === true} indicator={<AntdIcon.Loading3QuartersOutlined type="loading" style={{ fontSize: 24 }} spin />} >
                            <TabProduct
                                t={t}
                                orderDetail={orderDetail}
                                listStatuses={listStatuses}
                                dataSource={listProduct}
                                deleteProduct={deleteProduct}
                                createProduct={createProduct}
                                productCreating={productCreating}
                                onUploadImage={onUploadImage}
								listHS={listHS}
                            />
                        </Spin>
                    </TabPane>
                    <TabPane className={"_tabWaybill"} tab={<span className={'_tabWaybill-btn'}>{t('waybill.title')}</span>} key={Constants.SHIPMENT_DETAIL_TAB_WAYBILLS}>
                        <TabWaybill
                            orderDetail={orderDetail}
                            listStatuses={listStatuses}
                            listWaybills={listWaybills}
                            listWaybillParcels={listWaybillParcels}
                            statusPackageList={statusPackageList}
                            isLoadingWaybillParcel={isLoadingWaybillParcel}
                            isLoading={isLoadingWaybill}
                            createWaybill={createWaybill}
                            submittingWaybill={submittingWaybill}
                            deleteWaybill={deleteWaybill}
                            deletingWaybill={deletingWaybill}
                        />
                    </TabPane>
                    {
                        orderDetail?.contractWithShopkeeper &&
                        <TabPane className={"_tabCredit"} tab={<span className={'_tabCredit-btn'}>{t('credit_tab.title')}</span>} key={Constants.ORDER_DETAIL_TAB_CREDIT}>
                            <TabCredit
                                t={t}
                                orderInfo={orderDetail}
                            />
                        </TabPane>
                    }
                    <TabPane className={"_tabFees"} tab={<span className={'_tabFees-btn'}>{t('fee_tab.finance')}</span>} key={Constants.SHIPMENT_DETAIL_TAB_FEES}>
                        <TabFees
                            isLoadingFees={isLoadingFees}
                            listFees={listFees}
                            orderDetail={orderDetail}
                            listStatuses={listStatuses}
                            listFinancialClaim={listFinancialClaim}
                            listFinancialCollect={listFinancialCollect}
                            shipmentFees={shipmentFees}
                        />
                    </TabPane>
                    <TabPane className={"_tabFinance"} tab={<span className={'_tabFinance-btn'}>{t('financial_tab.transaction')}</span>} key={Constants.SHIPMENT_DETAIL_TAB_FINANCE}>
                        <TabFinance
                            getFinance={getFinance}
                            finance={finance}
                            isLoadingFinance={isLoadingFinance}
                            errFinance={errFinance}
                        />
                    </TabPane>
                    <TabPane className={"_tabClaims"} tab={<span className={'_tabClaims-btn'}>{`${t('complaint_tab.complaint')}${listTickets && listTickets.length > 0 ? ` (${quantityFormat(listTickets.length)})` : ''}`}</span>} key={Constants.ORDER_DETAIL_TAB_TICKETS}>
                        <TabClaims
                            t={t}
                            orderDetail={orderDetail}
                            listTickets={listTickets}
                            listStatusesTicket={listStatusesTicket}
                            isLoadingListTickets={isLoadingListTickets}
                        />
                    </TabPane>
                    <TabPane className={"_tabHistory"} tab={<span className={'_tabHistory-btn'}>{t('history_tab.history')}</span>} key={Constants.SHIPMENT_DETAIL_TAB_HISTORY}>
                        <TabHistory
                            t={t}
                            isLoadingListMilestones={isLoadingListMilestones}
                            listStatuses={listStatuses}
                            listMilestones={listMilestones}
                            orderDetail={orderDetail}
                        />
                    </TabPane>
                    <TabPane className={"_tabLogs"} tab={<span className={'_tabLogs-btn'}>{t('shipment_tab.log')}</span>} key={Constants.SHIPMENT_DETAIL_TAB_LOG}>
                        <TabLog
                            getOrderLogData={getOrderLog}
                            initLoading={initLoading}
                            loadingMore={loadingMore}
                            showLoadMore={showLoadMore}
                            logs={logs}
                            orderLogErrors={orderLogErrors}
                            onLoadMore={onLoadMore}
                        />
                    </TabPane>

                </Tabs>
            </div>
        );
    }
}
export default withNamespaces('translation')(TabLayout);
