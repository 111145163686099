import React from 'react'
import DocumentTitle from "react-document-title"
import {Link} from 'react-router-dom'
import {LocalStore} from "../../utils/LocalStore";
import {Input, notification} from 'antd'
import userService from "../../services/user.service";
import {initWechat} from "../../utils/helper";
import * as Constants from "../../constants/config";
import {USER_STATUS} from "../../constants/config";
import orderService from "../../services/orders.service";
import {withNamespaces} from "react-i18next";
import * as Sentry from "@sentry/browser";
import Language from '../../components/Language'
import * as AntdIcon from "@ant-design/icons"
// import OpenReplay from "@openreplay/tracker";
import categoriesService from "../../services/categories.service";
import _ from 'lodash';

class Login extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            username: '',
            password: ''
        }
    }

    componentDidMount () {
        // clear old session
        LocalStore.getInstance().save('loginSession', null);
        LocalStore.getInstance().save('pinToken', "");
        LocalStore.getInstance().save('currentLoggedUser', null);
        LocalStore.getInstance().save('ordersStatistics', null);
        LocalStore.getInstance().save('articleList', []);
        LocalStore.getInstance().save('articleSeen', false);
        LocalStore.getInstance().save('articleShow', true);
        LocalStore.getInstance().save('rsocketConnected', {connected: false});
    }

    /**
     * submit
     */
    onSubmit = () => {
        if (this.onValidate()) return;

        const {username, password, onSubmit} = this.state;
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        const {t} = this.props;
        this.setState({onSubmit: true}, () => {
            if (onSubmit) return;

            userService.login({username, password}, currentProjectInfo.id, (err, res, headers) => {
                if (!err) {
                    // const tracker = new OpenReplay({
                    //     projectKey: process.env.REACT_APP_OPEN_REPLAY_KEY,
                    //     ingestPoint: process.env.REACT_APP_OPEN_REPLAY_SERVER
                    // });
                    // tracker.setUserID(`${currentProjectInfo.code}.${username}`);
                    this.callbackLoginSuccess(res)
                } else {
                    if (err.detail && err.detail.error === "invalid_grant") {
                        this.setState({
                            usernameError: true,
                            passwordError:t('login.error')
                        })
                    } else {
                        if (err.statusCode !== 'Network fail') notification['error']({
                            message:t(err.message),
                        });
                    }
                }
                this.setState({onSubmit: false, password: ''})
            })
        })
    };

    /**
     * call back sau khi đăng nhập thành công
     * @param params
     */
    callbackLoginSuccess = (params) => {
        if (params && params['access_token']) {
            const {history} = this.props;
            //lưu access_token vào local
            LocalStore.getInstance().save('loginSession', {id: params.access_token});

            //query với access_token để lấy user
            userService.getCurrentUser({
				headers: {
					Authorization: `Bearer ${params.access_token}`
				}
			},(error, res)=> {
                // console.log('getCurrentUser', error, res);
                if (!error) {
                    const redirectUrl = LocalStore.getInstance().read('redirectUrl') || '/';
                    LocalStore.getInstance().save('currentLoggedUser', res);
                    LocalStore.getInstance().save('redirectUrl', null);
                    Sentry.configureScope((scope) => {
                        scope.setUser({"email": res && res.email, username: res && res.username});
                    });

                    // config clarity
                    const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
                    const idUser = res.id
                    const userName = res.username
                    const tenant = currentProjectInfo.code
                    window.clarity("set", "custom-id", idUser)
                    window.clarity("set", "friendly-name", userName)
                    window.clarity("set", "tenant", tenant)

                    // initialWechat
                    initWechat(res);

                    //kiểm tra trạng thái của User
                    if (res.customerStatus === USER_STATUS.LOCKED || res.customerStatus === USER_STATUS.INACTIVE) {
                        history.push({
                            pathname: '/403'
                        })
                    } else {

                        // connect websocket
                        if(navigator && navigator.serviceWorker && navigator.serviceWorker.controller && navigator.serviceWorker.controller.postMessage) {
                            let endpoint = process.env.REACT_APP_NOTIFICATION_WSS + '?access_token=' + params.access_token;
                            const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
                            const tenantConfig = currentProjectInfo.tenantConfig || {};
                            navigator.serviceWorker.controller.postMessage({
                                title: Constants.WS_ON_CONNECT,
                                endpoint: endpoint,
                                appName: currentProjectInfo.name,
                                icon: tenantConfig.customFavicon
                            })
                        }

                        // lấy ra số lượng bản ghi order theo trạng thái
                        // query 1 lần duy nhất khi load trang
                        orderService.getOrdersStatistics((e, r) => {
                            if (!e) {
                                LocalStore.getInstance().save('ordersStatistics', r);
                            }

							if (redirectUrl === '/') {
								history.replace(redirectUrl);
							} else {
								let link = redirectUrl.split('#');
								history.replace(link[1]);
							}
                        });

                        // lấy ra các thông báo
                        this.getArticle();

                        this.getCustomerExchangeRate()
                    }
                } else {
                    LocalStore.getInstance().save('loginSession', null);
                    this.setState({
                        msg: error.message
                    })
                }
            });
        }
    };

    getCustomerExchangeRate = () => {
		categoriesService.getCurrentExchangeRate((error, res) => {
			if (!error) {
				LocalStore.getInstance().save('exchangeRates', res);
			}
		});
	}

    getArticle = () => {
        // query 1 lần duy nhất khi load trang
        userService.getArticle((err, res) => {
            if (!err) {
                let arr = Array.isArray(res) ? res.filter(x => x.purpose !== 'WELCOME') : []
                LocalStore.getInstance().save('articleList', arr);
            }
        });
    };

    /**
     * sự kiện thay đổi giá trị của input
     * @param value
     * @param props
     */
    onChangeInput = (value, props) => {
        this.setState({[props]: value})
    };

    /**
     * sự kiện validate của input username
     */
    onValidateUsernameInput = () => {
        const {username} = this.state;
        const {t} = this.props;
        if (!username) {
            this.setState({
                usernameError: t('login.username_error')
            });
            return true
        } else {
            this.setState({
                usernameError: ""
            })
        }
        return false
    };

    /**
     * sự kiện validate của input password
     */
    onValidatePasswordInput = () => {
        const {password} = this.state;
        const {t} = this.props;
        if (!password) {
            this.setState({
                passwordError: t('login.password_error')
            });
            return true
        } else {
            this.setState({
                passwordError: ""
            })
        }
        return false
    };

    onValidate = () => {
        const validateUser = this.onValidateUsernameInput();
        const validatePassword = this.onValidatePasswordInput();

        return validateUser || validatePassword
    };

    changeVersion = () => {
        let split = window.location.href.split(window.location.origin);
        LocalStore.getInstance().save('currentVer', "mobile");
        if (split[1].indexOf('/m') !== 0) {
            window.location.href = window.location.origin + '/m' + split[1];
        }
    };

   
    render() {
        const {username, password, usernameError, passwordError, onSubmit} = this.state;
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        const loginContact = _.get(LocalStore.getInstance().read('loginContact'),'0.content','');
        const parser = new DOMParser();
        const decodedHtml = parser.parseFromString(loginContact, "text/html").body.textContent;

        const {t} = this.props;
        return(
            <DocumentTitle title={`${t('login.title')} | ${currentProjectInfo ? currentProjectInfo.name : ''}`}>
                <div className="scroll-wrapper">
                    <div className="wrapper login-wrapper width100 min-width-page position-re dpl-flex ">
                        <div className="boxform bg-color-white">
                            <h1 className="txt-center mgbt20">
                                <img className={'login-logo mx-auto'} src={currentProjectInfo && currentProjectInfo.tenantConfig ? currentProjectInfo.tenantConfig.logoStandard : ''} alt='' />
                            </h1>
                            <h2 className="txt-size-h1 robotobold txt-color-black txt-center">
                                {t('login.title')}
                            </h2>
                            <span className="txt-size-h7 txt-color-black robotoregular dpl-block mgt20">
                                {t('login.welcome')}
                            </span>
                            <div className="boxform__content mgt25">
                                <ul className="pdl0">
                                    <li className={`position-re mgbt30 ${usernameError && 'errorip'}`}>
                                        <Input
                                            type="text"
                                            name="username"
                                            placeholder={t('login.username')}
                                            value={username}
                                            onChange={(e) => this.onChangeInput(e.target.value, 'username')}
                                            onBlur={this.onValidateUsernameInput}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    this.onSubmit()
                                                }
                                            }}
                                        />
                                        <span className="iconip position-ab txt-size-h5">
                                            <AntdIcon.UserOutlined />
                                        </span>
                                        {
                                            usernameError && <span className="texterror position-ab txt-size-h8 txt-color-red2 robotoregular">
                                                {usernameError}
                                            </span>
                                        }
                                    </li>
                                    <li className={`position-re mgbt30 ${passwordError && 'errorip'}`}>
                                        <Input
                                            type="password"
                                            name="password"
                                            placeholder={t('login.password')}
                                            value={password}
                                            onChange={(e) => this.onChangeInput(e.target.value, 'password')}
                                            onBlur={this.onValidatePasswordInput}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    this.onSubmit()
                                                }
                                            }}
                                        />
                                        <span className="iconip position-ab txt-size-h5">
                                            {/*<Icon type="lock"/>*/}
                                            <AntdIcon.LockOutlined />
                                        </span>
                                        {
                                            passwordError && <span className="texterror position-ab txt-size-h8 txt-color-red2 robotoregular">
                                                {passwordError}
                                            </span>
                                        }
                                    </li>
                                    <li>
                                        <ul className="pdl0 dpl-flex justify-content-between">
                                            <li />
                                            <li>
                                                <Link to={'/forgot-password'} className="txt-size-h7 robotoregular txt-color-blue">{t('login.forgot')}</Link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <div className="txt-center mgt30 mgbt20">
                                    <span onClick={this.onSubmit} className="_btn-submit btn--blue cursor-pointer dpl-il-block">
                                        <span className="position-re">
                                            {onSubmit && <AntdIcon.Loading3QuartersOutlined spin={true} className="position-ab" style={{left: -25, top: 1}}/>}
                                            {t('login.title')}
                                        </span>
                                    </span>
                                    <span className="txt-size-h8 robotoregular dpl-block mgt15">{t('login.not_account')} <Link to={'/register'} className="txt-color-blue">{t('login.register')}</Link></span>
                                </div>
                                {/*<span className="position-re bdor dpl-block txt-center">*/}
                                {/*    <span className="txt-size-h8 txt-color-black robotoregular bg-color-white position-ab pdl10 pdr10">Hoặc</span>*/}
                                {/*</span>*/}
                                {/*<ul className="boxform__btnsocial dpl-flex justify-content">*/}
                                {/*    <li className="">*/}
                                {/*        <form*/}
                                {/*            method='post'*/}
                                {/*            action={`${process.env.REACT_APP_API_URL.replace('/api', '/signin')}/facebook`}*/}
                                {/*            // action="https://cvv.hiee.us/signin/facebook"*/}
                                {/*        >*/}
                                {/*            <button*/}
                                {/*                type="submit"*/}
                                {/*                className="btn-social cursor-pointer btnsocial--fb position-re dpl-il-block txt-size-h8 robotoregular txt-color-white"*/}
                                {/*            >*/}
                                {/*                <img src={require('../../resources/images/ic-fb.png')} /> Tiếp tục với Facebook*/}
                                {/*            </button>*/}
                                {/*        </form>*/}
                                {/*    </li>*/}
                                {/*</ul>*/}
                            </div>
                        </div>
                        <div
                            className="position-ab dpl-flex align-items-center gap-5"
                            style={{top: 20, left: 20}} 
                        >
                            {loginContact && <span dangerouslySetInnerHTML={{__html:decodedHtml}}></span> }
                        </div>
                        <div
                            className="position-ab dpl-flex align-items-center gap-5"
                            style={{top: 20, right: 0}} 
                        >
                            <span
                                onClick={this.changeVersion}
                                className="_btn-change-version ant-btn ant-btn-primary ant-btn-background-ghost cursor-pointer position-re "
                            >
                                <AntdIcon.MobileOutlined /> {t('footer.change_mobile_version')}
                            </span>
                            <Language {...this.props}/>
                        </div>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default withNamespaces('translation')(Login);
