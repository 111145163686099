import { useState } from 'react'
import { Button, notification } from 'antd'
import { withNamespaces } from 'react-i18next'
import TaobaoService from '../../../../services/taobao.service'
import Modal from '../../../../components/commons/Modal'
import { useMutation, useQueryClient } from 'react-query'
import TextArea from '../../../../components/commons/Input/TextArea'

const CancelOrder = props => {
    const {
        t,
        orderInfo,
    } = props
    const [isLoading, setIsLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [reason, setReason] = useState("")
    const queryClient = useQueryClient()

    const cancelOrder = async (orderCode) => {
        setIsLoading(true);
        const body = {
            reason
        };

        const cancelPromise = new Promise((resolve, reject) => {
            TaobaoService.cancelOrder(orderCode, body, (error, response, headers) => {
                if (!error) {
                    resolve();
                } else {
                    reject(error);
                }
            });
        });

        try {
            await Promise.race([cancelPromise]);
            notification.success({ message: t("orderDetail.order_cancel") });
            setVisible(false);
        } catch (error) {
            notification.error({ message: t("orderDetail.cancel_order_fail") });
        } finally {
            setIsLoading(false);
        }
    };

    const delelteMutation = useMutation(cancelOrder, {
        onSuccess: () => {
            queryClient.invalidateQueries('listOrders')
        },
    })

    const onSubmit = async () => {
        await delelteMutation.mutateAsync(orderInfo?.code)
    };

    const handleCancelOrder = () => {
        setVisible(false)
        setReason("")
    }

    return (
        <>
            <span
                onClick={() => setVisible(true)}
                className='hover:text-error text-grey-200 robotoregular '
            >
                <i className="fa-regular fa-xmark mr-2 text-sm" />
                {t('orderTbg.cancel_order')}
            </span>
            <Modal
                title={t("orderTbg.title_modal_cancel")}
                className="v2-layout modal-delete-common"
                visible={visible}
                onCancel={() => handleCancelOrder()}
                listAction={[
                    <Button className='btn-cancel' onClick={() => handleCancelOrder()}>
                        {t("modal.cancel")}
                    </Button>,
                    <Button className='btn-confirm-cancel' onClick={onSubmit} loading={isLoading}>
                        {t("modal.confirm")}
                    </Button>,
                ]}
            >
                <div className='mb-3'>
                    <span className='robotoregular text-black-100'>{t('orderTbg.confirm_cancel_order')} <span className='robotoregular text-blue-1890FF'>#{orderInfo?.code}</span> ?</span>
                </div>
                <TextArea
                    label={t("fee_tab.reason")}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                />
            </Modal>
        </>
    )
}
export default withNamespaces('translation')(CancelOrder)
