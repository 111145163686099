import PolimClient from "../utils/api/PolimClient";
import { LocalStore } from "../utils/LocalStore";

export default class StatisticService {

    /**
     * lấy danh sách địa chỉ của người dùng
     * @param year
     * @param month
     * @param cb
     */
    static getStatisticMonthly (type, year, month, cb) {
        PolimClient.request({
            endpoint: `customer/summaries/${type}/monthly/${year}/${month}`,
            method: 'GET',
        }, cb)
    }


    static getStatisticYearly (type, year, cb) {
        PolimClient.request({
            endpoint: `customer/summaries/${type}/yearly/${year}`,
            method: 'GET',
        }, cb)
    }

    static getStatisticClarkMonthly (year, month, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};

        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/customer/summaries/monthly/${year}/${month}`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }

    static getStatisticClarkYearly (year, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};

        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/customer/summaries/yearly/${year}`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }
}