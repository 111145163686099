import { Avatar, Col, Row, Space, Tooltip } from 'antd'
import React from 'react'
import Modal from '../../components/commons/Modal'
import bgVoucher from '../../resources/images/voucher.svg'
import moment from 'moment'

type Props = {
    t: Function
    visible: boolean
    setVisible: (value: boolean) => void
    infoVoucher: any
}

const ModalInfoCoupon: React.FC<Props> = ({ visible, setVisible, t, infoVoucher }) => {
    return (
        <Modal
            centered
            closeIcon={<i className="fa-solid fa-xmark" />}
            title={t('customer_info.detailCoupon')}
            visible={visible}
            onCancel={() => {
                setVisible(false)
            }}
            width={660}
            listAction={null}
        >
            <div>
                <Row>
                    <Col flex="none">
                        <div className="relative w-full">
                            <img
                                src={bgVoucher}
                                alt="coupon"
                                className="w-full h-[120px]"
                            ></img>
                            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
                                {infoVoucher?.image ? (
                                    <Avatar
                                        size={60}
                                        src={infoVoucher?.image}
                                    />
                                ) : null}
                                <div className="text-white font-medium mt-4">{infoVoucher?.code}</div>
                            </div>
                        </div>
                    </Col>
                    <Col
                        flex="1 1"
                        className="border-solid border border-gallery-100 rounded-r-md border-l-0"
                    >
                        <div className="px-4 py-2 h-full flex flex-col justify-between">
                            <div>
                                <Space>
                                    <div className=" font-bold text-black-100">{infoVoucher?.title}</div>
                                    <div className=" text-[10px] text-red-100">
                                        {t('coupon.voucherRemaining', { value: infoVoucher?.remaining })}
                                    </div>
                                </Space>
                                <Tooltip
                                    placement="top"
                                    title={<div className="text-white">{infoVoucher?.description}</div>}
                                >
                                    <div className="text-black-100 line-clamp-2">{infoVoucher?.description}</div>
                                </Tooltip>
                                <div className="text-black-100 text-xs">
                                    {t('coupon.voucherCustomerLimit', { value: infoVoucher?.customerLimit })}
                                </div>
                            </div>
                            <div className="text-grey-200 text-xs">
                                {t('coupon.voucherValidTo', {
                                    value: moment(infoVoucher?.validTo).format('DD/MM/YYYY'),
                                })}
                            </div>
                        </div>
                    </Col>
                </Row>
                <div
                    className="text-black-100 text-xs mt-4 max-h-80 overflow-auto"
                    dangerouslySetInnerHTML={{
                        __html: infoVoucher?.termsAndConditions,
                    }}
                ></div>
            </div>
        </Modal>
    )
}

export default ModalInfoCoupon
