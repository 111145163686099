import React from 'react'
import DocumentTitle from "react-document-title"
import {Link} from 'react-router-dom'
import {LocalStore} from "../../utils/LocalStore";
import {Input, notification} from 'antd'
import * as AntdIcon from "@ant-design/icons"
import userService from "../../services/user.service";
import {getUrlQueryString} from "../../utils/helper";
import {parseQueryStringToObject} from "../../utils/stringUtils";
import {withNamespaces} from "react-i18next";

class ResetPin extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            email: '',
        }
    }

    componentDidMount() {
        let queryObj = parseQueryStringToObject(getUrlQueryString());
        this.setState({
            token: queryObj.token
        })
    }

    /**
     * submit
     */
    onSubmit = () => {
        if (!this.state.token || this.onValidate()) return;
        const {t} = this.props;
        const {password, token, onSubmit} = this.state;

        this.setState({onSubmit: true}, () => {
            if (onSubmit) return;

            userService.resetPin({newPIN: password, token},(err, res, headers) => {
                if (!err) {
                    this.setState({
                        resetPasswordSuccess: true
                    })
                } else {
                    if (err.title === "invalid_token") {
                        notification['error']({
                            message: t("error.token_expired"),
                        });
                    } else {
                        if (err.statusCode !== 'Network fail') notification['error']({
                            message: t(err.message),
                        });
                    }
                }
                this.setState({onSubmit: false})
            })
        })
    };

    /**
     * sự kiện thay đổi giá trị của input
     * @param value
     * @param props
     */
    onChangeInput = (value, props) => {
        this.setState({[props]: value})
    };

    /**
     * sự kiện validate của input password
     */
    onValidatePasswordInput = () => {
        const {t} = this.props;
        const {password = ''} = this.state;
        if (!password) {
            this.setState({
                passwordError: t("customer_info.required")
            });
            return true
        } else {
            if (!password.trim()) {
                this.setState({
                    passwordError: t("customer_info.required")
                });
                return true
            }
            if (!/^[0-9]+$/i.test(password)) {
                this.setState({
                    passwordError: t("customer_info.numbers_required")
                });
                return true
            } else if (password.length < 4) {
                this.setState({
                    passwordError: t("customer_info.least_four_characters")
                });
                return true
            } else {
                this.setState({
                    passwordError: ""
                })
            }
        }
        return false
    };

    /**
     * sự kiện validate của input confirmPassword
     */
    onValidateConfirmPasswordInput = () => {
        const {t} = this.props;
        const {password, confirmPassword} = this.state;
        if (confirmPassword !== password) {
            this.setState({
                confirmPasswordError: t("customer_info.incorrect_repin")
            });
            return true
        } else {
            this.setState({
                confirmPasswordError: ""
            })
        }
        return false
    };

    onValidate = () => {
        const validatePassword = this.onValidatePasswordInput();
        const validateConfirmPassword = this.onValidateConfirmPasswordInput();

        return validatePassword || validateConfirmPassword
    };

    render() {
        const {t} = this.props;
        const {
            password, confirmPassword,
            passwordError, confirmPasswordError,
            onSubmit, resetPasswordSuccess
        } = this.state;
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};

        return(
            <DocumentTitle title={`${t('forgot_pin.reset_pin')} | ${currentProjectInfo ? currentProjectInfo.name : ''}`}>
                <div className="scroll-wrapper">
                    <div className="wrapper login-wrapper width100 dpl-flex min-width-page position-re">
                        {
                            resetPasswordSuccess
                            ?
                            <div className="boxform bg-color-white">
                                <h1 className="txt-center mgbt20">
                                    <img className={'login-logo-image'} src={require('../../resources/images/img-success.png')} alt=''/>
                                </h1>
                                <h2 className="txt-size-h3 robotobold txt-color-black txt-center">
                                    {t("forgot_pass.success")}
                                </h2>
                                <span className="txt-size-h7 txt-color-black robotoregular dpl-block mgt20 txt-center line-height134">
                                    {t('forgot_pin.reset_pin_success')}.
                                </span>
                                <div className="boxform__content mgt25">
                                    <div className="txt-center mgt30 mgbt20">
                                        <Link to={'/login'} className="btn--blue dpl-il-block">{t("login.login_now")}</Link>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="boxform bg-color-white">
                                <h1 className="txt-center mgbt20">
                                    <img className={'login-logo-image'} src={currentProjectInfo && currentProjectInfo.tenantConfig ? currentProjectInfo.tenantConfig.logoStandard : ''} alt=''/>
                                </h1>
                                <h2 className="txt-size-h3 robotobold txt-color-black txt-center">
                                    {t('forgot_pin.reset_pin')}
                                </h2>

                                <div className="boxform__content mgt25">
                                    <ul className="pdl0">
                                        <li className={`position-re mgbt30 ${passwordError && 'errorip'}`}>
                                            <Input
                                                type="password"
                                                name="password"
                                                placeholder={t("customer_info.input_new_pin")}
                                                value={password}
                                                onChange={(e) => this.onChangeInput(e.target.value, 'password')}
                                                onBlur={this.onValidatePasswordInput}
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 13) {
                                                        this.onSubmit()
                                                    }
                                                }}
                                            />
                                            <span className="iconip position-ab txt-size-h5">
                                        <AntdIcon.LockOutlined type="lock"/>
                                    </span>
                                            {
                                                passwordError && <span className="texterror position-ab txt-size-h8 txt-color-red2 robotoregular">
                                            {passwordError}
                                        </span>
                                            }
                                        </li>
                                        <li className={`position-re mgbt30 ${confirmPasswordError && 'errorip'}`}>
                                            <Input
                                                type="password"
                                                name="confirmPassword"
                                                placeholder={t("customer_info.please_retype_pin")}
                                                value={confirmPassword}
                                                onChange={(e) => this.onChangeInput(e.target.value, 'confirmPassword')}
                                                onBlur={this.onValidateConfirmPasswordInput}
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 13) {
                                                        this.onSubmit()
                                                    }
                                                }}
                                            />
                                            <span className="iconip position-ab txt-size-h5">
                                                <AntdIcon.LockOutlined type="lock"/>
                                            </span>
                                            {
                                                confirmPasswordError && <span className="texterror position-ab txt-size-h8 txt-color-red2 robotoregular">
                                                    {confirmPasswordError}
                                                </span>
                                            }
                                        </li>
                                    </ul>
                                    <div className="txt-center mgt30 mgbt20">
                                        <span onClick={this.onSubmit} className="_btn-submit btn--blue cursor-pointer dpl-il-block">
                                            <span className="position-re">
                                                {onSubmit && <AntdIcon.Loading3QuartersOutlined type="loading-3-quarters" spin={true} className="position-ab" style={{left: -25, top: 1}}/>}
                                                {t('modal.confirm')}
                                            </span>
                                        </span>
                                        {/*<Link to={'/login'} className="txt-color-blue txt-size-h8 robotoregular dpl-block mgt15">Quay lại</Link>*/}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default withNamespaces() (ResetPin);
