import { useState } from 'react'
import { Col, Row, Button, notification } from 'antd'
import { withNamespaces } from 'react-i18next'
import MainLayout from '../../components/Layout/MainLayout'
import ProfileSidebar from '../../components/Profile/Leftbar'
import InputItem from '../../components/commons/Input'
import NotificationServices from '../../services/notifications.service'

const Telegram = props => {
    const { t } = props
    const [userNameTele, setUserNameTele] = useState('')
    const [loading, setLoading] = useState(false)

    const onConnectTelegram = () => {
        setLoading(true)
        NotificationServices.connectTelegram(userNameTele, (err, res) => {
            if (!err) {
                window.open(res.url, '_blank')
                setLoading(false)
            } else {
                setLoading(false)
                notification.error({
                    message: t(`message.${err?.title || 'coupon_invalid_for_you'}`),
                })
            }
        })
    }
    return (
        <MainLayout
            {...props}
            headerTitle={t('customer_info.telegram')}
            title={t('customer_info.telegram')}
        >
            <div className={'container-common pdbt30 pdt20 mgr20 '}>
                <Row>
                    <Col
                        span={6}
                        className={'pdr10'}
                    >
                        <ProfileSidebar currentRoute={'telegram'} />
                    </Col>
                    <Col
                        span={18}
                        className={'pdl10 mt-[50px]'}
                    >
                        <div className="bg-color-white pd24 h-100 rounded-xl">
                            <div className=" text-base font-medium text-black-100 mb-4">{t('customer_info.telegram')}</div>
                            <Row justify="center">
                                <Col span={14}>
                                    <Row
                                        gutter={12}
                                        justify="space-between"
                                    >
                                        <Col
                                            flex="auto"
                                            className="flex"
                                        >
                                            <InputItem
                                                title={t('customer_info.telegramUsername')}
                                                className={'_search-box-input-query'}
                                                onChange={e => setUserNameTele(e.target.value)}
                                                value={userNameTele || ''}
                                                onPressEnter={() => onConnectTelegram()}
                                                allowClear
                                            />
                                        </Col>
                                        <Col flex="none">
                                            <Button
                                                className={`h-11 rounded-3xl ${userNameTele ? 'bg-[#54a9eb]' : ''} `}
                                                onClick={() => onConnectTelegram()}
                                                // loading={loading}
                                                disabled={!userNameTele || loading}
                                                type="primary"
                                            >
                                                <div className='flex items-center'>
                                                    <i className="fa-brands fa-telegram mr-2 text-xl" />
                                                    {t('customer_info.buttonTelegram')}
                                                </div>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </MainLayout>
    )
}

export default withNamespaces()(Telegram)
