import React from 'react'
import _ from 'lodash'
import CartGroupCheckout from '../../components/Carts/CartGroupCheckout'

import { Checkbox, Col, Empty, Input, Modal, Row } from 'antd'

import DeliveryAddress from '../../components/AddressDeposit/DeliveryAddress'
import OrderService from '../../services/orders.service'
import { LocalStore } from '../../utils/LocalStore'
import { notification } from 'antd/lib/index'
import { TenantDeposit } from './TenantDeposit'

let currentLoggedUser = null
export default class Checkout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            voucherApply: [],
            loanAmount: 0,
            depositOnDemand: undefined,
        }
    }

    componentDidMount() {
        if (typeof LocalStore.getInstance().read('currentLoggedUser') === 'object') {
            currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser')
        }
    }

    /**
     * tạo mới order customer
     */

    onChangeDepositOnDemand = value => {
        this.setState({
            depositOnDemand: value,
        })
    }

    onChangeRate = value => {
        this.setState({
            loanAmount: value,
        })
    }
    createCustomerOrder = () => {
        const { draftOrder, nextStep, listCartsOrder, updateHeader } = this.props
        const { showModalConfirm, savePassword, loanAmount, voucherApply, depositOnDemand } = this.state
        const { t } = this.props
        const couponCodes = voucherApply?.length > 0 ? voucherApply?.map(i => i?.code) : undefined

        if (!draftOrder) return null
        const { password } = this.state
        if (showModalConfirm && !password) {
            this.setState({
                passwordError: t('cartCheckout.input_pin_error'),
            })
            return
        }

        if (!draftOrder.address) {
            notification['error']({
                message: t('cartCheckout.choose_address_error'),
            })
            return
        }

        let body = {
            draftOrder: draftOrder.id,
        }

        if (password) {
            body.password = password

            if (savePassword) {
                body.savePassword = true
            }
        }

        this.setState({ orderLoading: true }, () => {
            OrderService.createCustomerOrder(
                currentLoggedUser.username,
                { ...body, loanAmount, couponCodes, depositOnDemand },
                (err, res, headers) => {
                    if (!err) {
                        LocalStore.getInstance().save('pinToken', headers['x-pin-token'] || '')
                        listCartsOrder(res)
                        this.setState(
                            {
                                password: '',
                                passwordError: '',
                                showModalConfirm: false,
                            },
                            () => {
                                nextStep()
                                updateHeader()
                            }
                        )
                    } else {
                        if (err.code === 400) {
                            if (err.title === 'empty_password') {
                                LocalStore.getInstance().save('pinToken', '')
                                this.setState({
                                    password: '',
                                    passwordError: '',
                                    showModalConfirm: true,
                                })
                            } else if (err.title === 'password_not_match' || err.title === 'invalid_password') {
                                LocalStore.getInstance().save('pinToken', '')
                                if (!showModalConfirm) {
                                    this.setState({
                                        password: '',
                                        passwordError: '',
                                        showModalConfirm: true,
                                    })
                                } else {
                                    this.setState({
                                        passwordError: t('cartCheckout.incorrect_pin'),
                                    })
                                }
                                // notification['error']({
                                //     message: t(`message.${err.title}`),
                                // });
                            } else if (err.title === 'insufficient_balance') {
                                notification['error']({
                                    message: t('cartCheckout.not_enough_money'),
                                })
                            } else if (err.title === 'config_group_changed') {
                                notification['error']({
                                    message: t('cartCheckout.considering_before_deposit'),
                                })
                            } else if (err.title === 'system_currently_suspended') {
                                notification['error']({
                                    message: t('cartCheckout.order_sespension'),
                                })
                            } else if (err.title === 'warehouse_location_not_mapped') {
                                notification['error']({
                                    message: t('message.warehouse_location_not_mapped'),
                                })
                            } else if (err.title === 'other_marketplace_disabled') {
                                notification['error']({
                                    message: t('message.other_marketplace_disabled'),
                                })
                            } else if (
                                err.title === 'order_service_requires' ||
                                err.title === 'order_service_require_groups' ||
                                err.title === 'order_service_excludes' ||
                                err.title === 'order_service_exclude_groups'
                            ) {
                                notification['error']({
                                    message: t('error.service_change'),
                                })
                            } else {
                                notification['error']({
                                    message: t(`message.${err.title}`),
                                })
                            }
                        } else {
                            if (err.statusCode !== 'Network fail')
                                notification['error']({
                                    message: t(err.message),
                                })
                        }
                    }
                    this.setState({ orderLoading: false })
                }
            )
        })
    }

    /**
     * render related service
     */
    renderAddressDeposit = () => {
        const { t, updateDraftOrder, isUpdateDraftOrder, draftOrder, updateLoadingStatus } = this.props
        return (
            <DeliveryAddress
                t={t}
                updateLoadingStatus={updateLoadingStatus}
                updateDraftOrder={(body, cb) =>
                    updateDraftOrder(
                        draftOrder,
                        {
                            ...body,
                        },
                        cb
                    )
                }
                isUpdateDraftOrder={isUpdateDraftOrder}
                ref={ref => (this.deliveryAddress = ref)}
                showReceiveAddress={true}
                draftOrder={draftOrder}
            />
        )
    }

    /**
     * render cart item
     * @param item
     * @param index
     */
    renderCartGroup = (item, index) => {
        const { draftOrder, loansFailureList, t } = this.props
        return (
            <CartGroupCheckout
                t={t}
                ref={ref => (this[`cartGroup${item.id}`] = ref)}
                exchangeRate={draftOrder.exchangeRate || 1}
                key={index}
                dataSource={item}
                loansFailureList={loansFailureList}
                draftOrder={draftOrder}
                //language
                cartLanguage={this.props.cartLanguage}
            />
        )
    }

    renderModalConfirmPin = () => {
        const { showModalConfirm, password, orderLoading, passwordError, savePassword } = this.state
        const { t } = this.props

        return (
            <Modal
                title={t('modal.confirm_pin')}
                visible={showModalConfirm}
                onOk={this.createCustomerOrder}
                confirmLoading={orderLoading}
                onCancel={() =>
                    this.setState({
                        showModalConfirm: false,
                        password: '',
                    })
                }
                okText={t('cartCheckout.confirm')}
                cancelText={t('cartCheckout.cancel')}
                okButtonProps={{ className: '_btn-submit-confirm' }}
                cancelButtonProps={{ className: '_btn-cancel-confirm' }}
            >
                <p className="mgbt5">{t('cartCheckout.please_input_pin')}</p>
                <Input.Password
                    className={'_input-password'}
                    placeholder={t('orderDetail.input_pin')}
                    autoFocus={true}
                    value={password}
                    onChange={e => this.setState({ password: e.target.value })}
                    onKeyDown={e => {
                        if (e.keyCode === 13) {
                            this.createCustomerOrder()
                        }
                    }}
                />

                {passwordError && <p className="_txt-error-confirm mgt5 mgbt0 txt-error ">{passwordError}</p>}
                <span className="mgt10 txt-color-gray txt-size-h8">{t('cartCheckout.default_pin')}</span>

                <Checkbox
                    className={'_check-box-confirm mgt10'}
                    onChange={() => this.setState({ savePassword: !savePassword })}
                    checked={savePassword}
                >
                    {t('modal.save_password_60m')}
                </Checkbox>
            </Modal>
        )
    }

    setVoucherApply = value => {
        this.setState({ voucherApply: value })
    }

    renderDeposit = () => {
        const {
            t,
            draftOrder,
            balance,
            balanceObj = {},
            getBiffinConnect,
            totalLoanAmount,
            feeLoansAmount,
            isUpdateDraftOrder,
            isDraftOrderLoanable,
            isConnectedBiffin,
            isEnabledBiffin,
            isLoadingCheckShoppingCartLoanable,
        } = this.props
        const { orderLoading, voucherApply } = this.state
        return (
            <TenantDeposit
                draftOrder={draftOrder}
                balanceObj={balanceObj}
                balance={balance}
                t={t}
                isDraftOrderLoanable={isDraftOrderLoanable}
                getBiffinConnect={getBiffinConnect}
                isConnectedBiffin={isConnectedBiffin}
                isEnabledBiffin={isEnabledBiffin}
                totalLoanAmount={totalLoanAmount}
                feeLoansAmount={feeLoansAmount}
                onChangeDepositTab={this.props.onChangeDepositTab}
                orderLoading={orderLoading}
                onCreateOrder={this.createCustomerOrder}
                onSignUpBiffin={this.props.onSignUpBiffin}
                isLoadingCheckShoppingCartLoanable={isLoadingCheckShoppingCartLoanable}
                isUpdateDraftOrder={isUpdateDraftOrder}
                onChangeRate={this.onChangeRate}
                voucherApply={voucherApply}
                setVoucherApply={this.setVoucherApply}
                onChangeDepositOnDemand={this.onChangeDepositOnDemand}
            />
        )
    }

    render() {
        const { t, draftOrder } = this.props

        if (!draftOrder) return null

        let listCarts = Array.isArray(draftOrder.merchants) ? draftOrder.merchants : []

        return (
            <div className={'cartscontainer mgbt30 pdr20'}>
                <Row>
                    <Col
                        span={17}
                        className={'pdr10 list-cartsipad'}
                    >
                        {listCarts.length > 0 ? (
                            _.sortBy(listCarts, 'crossedThreshold').reverse().map(this.renderCartGroup)
                        ) : (
                            <Empty
                                image={require('../../resources/images/empty-image.png')}
                                description={t('message.empty')}
                            />
                        )}
                    </Col>
                    <Col
                        span={7}
                        className={'pdl10'}
                    >
                        {this.renderAddressDeposit()}
                        {this.renderDeposit()}
                    </Col>
                </Row>

                {this.renderModalConfirmPin()}
            </div>
        )
    }
}
