/* eslint-disable array-callback-return */
import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import MainLayout from '../../components/Layout/MainLayout'
import Loading from '../../components/Loading'
import { Button, Col, DatePicker, Empty, Space, Pagination, Row, Table, Tag, Typography, Select, Badge } from 'antd'
import * as AntdIcon from '@ant-design/icons'
import CategoriesService from '../../services/categories.service'

import ProfileSidebar from '../../components/Profile/Leftbar'

import { getUrlQueryString } from '../../utils/helper'
import {
    moneyCeil,
    moneyFormat,
    parseQueryStringToObject,
    quantityFormat,
    updateQueryStringParameter,
} from '../../utils/stringUtils'
import UserService from '../../services/user.service'
import { withNamespaces } from 'react-i18next'
import saveAs from 'file-saver'
import _, { isEmpty } from 'lodash'
import lodash from 'lodash'
import ExportExcel from '../../components/ExportExcel'
import queryString from 'query-string'
import { Input } from 'antd'
import { notification } from 'antd'

let pageSize = 25

const typeGroup = ['collect,refund', 'adjust,charge', 'transfer,payment', 'emd,emd']
class TransactionHistoryByAccount extends React.Component {
    constructor(props) {
        super(props)
        this.isUnmounted = false
        this.state = {
            page: 1,
            pageSize: pageSize,
            isLoading: true,
            listTransactions: [],
            listChecked: {},
            startValue: null,
            endValue: null,
            querySearch: '',
            accountSelected: null,
            accountList: [],
        }
    }

    componentDidMount() {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null
        window.addEventListener('popstate', this.onPopState.bind(this))

        this.setState({ isLoading: true }, () => {
            this.getAccountByUsername()
            this.loadFromQueryString()

            this.getFinancialTypes()
        })
    }

    /**
     * Khi unmount ra thì bỏ sự kiện popstate
     */
    componentWillUnmount() {
        this.isUnmounted = true
        window.removeEventListener('popstate', this.onPopState.bind(this))
    }

    /**
     * On popstate
     */
    onPopState() {
        if (!this.isUnmounted)
            this.setState({ isLoading: true }, () => {
                this.loadFromQueryString()
            })
    }

    /**
     * Load từ QueryString (dành cho lần load đầu tiên)
     */
    loadFromQueryString = () => {
        let { listChecked, startValue, endValue, querySearch } = this.state
        let queryString = getUrlQueryString()
        let queryObj = parseQueryStringToObject(queryString) || {}

        // console.log('queryObj', queryObj);
        // chuyển giá trị từ queryObj vào state;
        //reset các param khi load từ url
        listChecked = {}
        querySearch = ''
        let arrayValue = []
        for (let p in queryObj) {
            if (p === 'externalTypes') {
                // xử lý với obj list checked
                arrayValue = queryObj[p].split(',')
                // console.log('arrayValue', arrayValue)
                listChecked[p] = arrayValue
            } else if (p === 'nominalTimestampFrom' || p === 'nominalTimestampTo') {
                // xử lý với với timestamp
                startValue = decodeURIComponent(queryObj['nominalTimestampFrom'])
                endValue = decodeURIComponent(queryObj['nominalTimestampTo'])
            } else {
                // xử lý với query
                querySearch = decodeURIComponent(queryObj['query'] || '')
            }
        }

        this.setState(
            {
                querySearch,
                listChecked,
                startValue,
                endValue,
                page: queryObj.page,
                pageSize: queryObj.size || pageSize,
            },
            () => {
                this.loadData()
            }
        )
    }

    /**
     * load data
     */
    loadData = cb => {
        const { page, pageSize, accountList, accountSelected } = this.state
        let queryString = this.buildQueryString()
        if (queryString) {
            queryString = queryString + `&page=${page ? page - 1 : 0}&size=${pageSize}`
        } else {
            queryString = `?page=${page ? page - 1 : 0}&size=${pageSize}`
        }
        if (isEmpty(accountList)) {
            // this.setState({ isLoading: false })
            return
        }
        const account = accountSelected ? accountSelected : accountList[0].account

        this.setState({ showProgressBar: true, accountSelected: account }, () => {
            UserService.getTransactionsHistoryByAccount(queryString, account, (err, res, headers) => {
                if (!err) {
                    this.setState(
                        {
                            expandedRow: [],
                            listTransactions: res,
                            metadata: {
                                pageCount: headers['x-page-count'],
                                page: headers['x-page-number'],
                                size: headers['x-page-size'],
                                total: headers['x-total-count'],
                            },
                        },
                        () => {
                            if (this.mainLayoutElement && this.mainLayoutElement.scrollLayoutElement)
                                this.mainLayoutElement.scrollLayoutElement.scrollTop = 0
                            //window.scrollTo(0, 0);
                        }
                    )
                }
                if (cb) cb()
                this.setState({ showProgressBar: false, isLoading: false })
            })
        })
    }

    /**
     * export excel data
     */
    exportExcel = (password, cb, setError) => {
        const { t } = this.props
        const { page, pageSize, accountSelected, accountList } = this.state
        const account = accountSelected ? accountSelected : accountList[0].account
        let queryString = this.buildQueryString()
        if (queryString) {
            queryString = queryString + `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        } else {
            queryString = `?page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        }

        if (!password) {
            if (setError) setError(t('cartCheckout.incorrect_pin'))

            return
        }

        this.setState({ showProgressBar: true, onExport: true }, () => {
            UserService.exportCSVTransactionsDior(account, queryString, { secret: password }, (err, res, headers) => {
                if (!err) {
                    this.downloadFile(res)
                    if (cb) cb()
                } else {
                    if (err.title === 'invalid_pin' || err.title === 'invalid_password') {
                        if (setError) setError(t('cartCheckout.incorrect_pin'))
                    }
                }
                this.setState({ showProgressBar: false, onExport: false })
            })
        })
    }

    downloadFile = res => {
        if (res && res.headers && res.headers.map) {
            const fileName = res.headers.map['content-disposition'].split('filename=')[1]
            saveAs(res._bodyBlob, fileName)
        }
    }

    onPageChanged = (page, pageSize) => {
        this.setState({ page: page }, () => {
            let locationQuery = lodash.get(this.props.location, 'search', '')
            let queryObj = locationQuery ? parseQueryStringToObject(locationQuery) : {}
            queryObj.page = page
            queryObj.size = pageSize
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: queryString.stringify(queryObj),
            })
            // window.scrollTo(0, 0);
        })
    }

    /**
     * lấy ra danh sách trạng thái đơn
     */
    getFinancialTypes = () => {
        CategoriesService.getFinancialTypes((err, res, headers) => {
            if (!err) {
                this.setState({
                    listFinancialTypes: res,
                })
            }
        })
    }

    /**
     * lấy ra danh sách tài khoản theo username
     */
    getAccountByUsername = () => {
        CategoriesService.getAccountByUsername((err, res, headers) => {
            if (!err) {
                this.setState(
                    {
                        accountList: res,
                    },
                    () => {
                        this.loadFromQueryString()
                    }
                )
            }
        })
    }

    /**
     * lấy ra prop giá trị của obj prop
     * @param props
     * @returns {*}
     */
    getValuePropOfProp = props => {
        switch (props) {
            case 'externalTypes':
                return 'code'
            default:
                return 'id'
        }
    }

    checkDateTime(value) {
        if (value === 'undefined' || value === null) {
            return null
        }
        return moment(value)
    }

    disabledStartDate = startValue => {
        const endValue = this.state.endValue
        if (!startValue || !endValue) {
            return false
        }
        return startValue.valueOf() > endValue.valueOf()
    }

    disabledEndDate = endValue => {
        const startValue = this.state.startValue
        if (!endValue || !startValue) {
            return false
        }
        return endValue.valueOf() <= startValue.valueOf()
    }

    onChange = (field, value) => {
        this.setState({
            [field]: value,
        })
    }

    onStartChange = value => {
        if (value !== null) {
            this.onChange('startValue', value ? value.startOf('day').toISOString() : undefined)
        } else {
            this.onChange('startValue', value)
        }
    }

    onEndChange = value => {
        if (value !== null) {
            this.onChange('endValue', value ? value.endOf('day').toISOString() : undefined)
        } else {
            this.onChange('endValue', value)
        }
    }

    handleStartOpenChange = open => {
        if (!open) {
            this.setState({ endOpen: true })
        }
    }

    handleEndOpenChange = open => {
        this.setState({ endOpen: open })
    }

    /**
     * xây dựng query string theo các giá trị trong state
     * @returns {string}
     */
    buildQueryString = obj => {
        let { listChecked, startValue, endValue, querySearch } = this.state
        if (obj) {
            listChecked = obj.listChecked
            startValue = obj.startValue
            endValue = obj.endValue
            querySearch = obj.querySearch
        }
        let queryString = ''
        for (let p in listChecked) {
            let value = ''
            if (Array.isArray(listChecked[p]) && listChecked[p].length > 0) {
                listChecked[p].map(x => {
                    value = value + x + ','
                })

                value = value.slice(0, -1)
            }
            if (value) queryString = updateQueryStringParameter(queryString, p, value)
        }

        if (startValue !== 'undefined') {
            queryString = updateQueryStringParameter(queryString, 'nominalTimestampFrom', startValue)
        }

        if (endValue !== 'undefined') {
            queryString = updateQueryStringParameter(queryString, 'nominalTimestampTo', endValue)
        }

        if (querySearch) {
            queryString = updateQueryStringParameter(queryString, 'query', querySearch.trim())
        }

        return queryString
    }

    onClearSearch = () => {
        this.setState(
            {
                page: 1,
                querySearch: '',
                startValue: null,
                endValue: null,
                listChecked: {},
            },
            () => {
                this.props.history.push({
                    pathname: this.props.location.pathname,
                    search: this.buildQueryString(),
                })
            }
        )
    }

    /**
     * xử lý với dữ liệu search trong state
     */
    onSubmitSearch = () => {
        this.setState({ page: 1 }, () => {
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: this.buildQueryString(),
            })
        })
    }

    dateTimeChange = date => {
        // console.log('dateTimeChange ', date, dateString);

        this.setState({
            datetimeRange: date.length > 0 ? [date[0].startOf('day').toISOString(), date[1].endOf('day').toISOString()] : [],
        })
    }

    onCheckBoxCheck = (item, prop) => {
        let { listChecked } = this.state

        let arrayPropChecked = listChecked[prop] || []
        if (arrayPropChecked.find(x => x === item[this.getValuePropOfProp(prop)])) {
            arrayPropChecked = arrayPropChecked.filter(x => x !== item[this.getValuePropOfProp(prop)])
        } else {
            arrayPropChecked.push(item[this.getValuePropOfProp(prop)])
        }

        listChecked[prop] = arrayPropChecked

        this.setState({
            listChecked,
        })
    }

    checkExportBtn = () => {
        let urlQueryString = getUrlQueryString()
        let queryObj = parseQueryStringToObject(urlQueryString) || {}
        let datetimeRange = []
        for (let p in queryObj) {
            if (p === 'nominalTimestampFrom') {
                // xử lý với với timestamp
                datetimeRange[0] = decodeURIComponent(queryObj['nominalTimestampFrom'])
            }
            if (p === 'nominalTimestampTo') {
                // xử lý với với timestamp
                datetimeRange[1] = decodeURIComponent(queryObj['nominalTimestampTo'])
            }
        }

        if (!datetimeRange[0]) {
            return false
        }
        if (moment(datetimeRange[0]).add(3, 'M').isBefore(moment(datetimeRange[1]).startOf('date'))) {
            return false
        }
        return true
    }

    /**
     * render orderStatus Search
     * @param item
     * @param index
     * @param prop
     */
    renderStatusSearchItem = (item, index, prop) => {
        const arrayPropChecked = this.state.listChecked[prop] || []
        // console.log('arrayPropChecked', arrayPropChecked)
        let checked =
            Array.isArray(arrayPropChecked) && arrayPropChecked.find(x => x === item[this.getValuePropOfProp(prop)]) !== undefined
        return (
            <Tag.CheckableTag
                key={index}
                onChange={() => this.onCheckBoxCheck(item, prop)}
                className={`_check-${prop}-item cursor-pointer txt-size-h7 robotoregular txt-capitalize box-shadow-blue pdl10 pdr10`}
                checked={checked}
            >
                <Typography.Link style={checked ? { color: '#ffffff' } : {}}>{item.name}</Typography.Link>
            </Tag.CheckableTag>
        )
    }

    renderHeaderPage = () => {
        return (
            <div className="bg-color-white">
                <div className="pd20 border-bottom-1px-solid-gray">
                    <span className="txt-color-gray txt-size-h7 robotoregular pdr5">Lịch sử giao dịch: </span>{' '}
                    <span className="_account-number txt-color-black robotomedium txt-size-h7">
                        Số tài khoản 2014038213123123
                    </span>
                </div>
                <div className="pd20 border-bottom-1px-solid-gray dpl-flex align-items-center justify-content-between">
                    <div className="">
                        <p className="mgbt5 txt-size-h8 txt-color-gray">Đã đặt cọc</p>
                        <p className="_dadatcoc mgbt0 txt-size-h7 robotomedium txt-color-black">{moneyFormat(19000000)}</p>
                    </div>
                    <div className="">
                        <p className="mgbt5 txt-size-h8 txt-color-gray">Chờ duyệt</p>
                        <p className="_choduyet mgbt0 txt-size-h7 robotomedium txt-color-black">{moneyFormat()}</p>
                    </div>
                    <div className="">
                        <p className="mgbt5 txt-size-h8 txt-color-gray">Đã mua</p>
                        <p className="_damua mgbt0 txt-size-h7 robotomedium txt-color-black">{moneyFormat(100000000)}</p>
                    </div>
                    <div className="">
                        <p className="mgbt5 txt-size-h8 txt-color-gray">Chưa về</p>
                        <p className="_chuave mgbt0 txt-size-h7 robotomedium txt-color-black">{moneyFormat()}</p>
                    </div>
                    <div className="">
                        <p className="mgbt5 txt-size-h8 txt-color-gray">Chờ giao</p>
                        <p className="_chuave mgbt0 txt-size-h7 robotomedium txt-color-black">{moneyFormat()}</p>
                    </div>
                    <div className="">
                        <p className="mgbt5 txt-size-h8 txt-color-gray">Yêu cầu giao</p>
                        <p className="_chuave mgbt0 txt-size-h7 robotomedium txt-color-black">{moneyFormat(90000000)}</p>
                    </div>
                </div>
            </div>
        )
    }

    /**
     * render search box
     * @returns {*}
     */
    renderSearchBox = () => {
        const { startValue, endValue, querySearch, listFinancialTypes = [], accountList = [], accountSelected } = this.state
        const { t } = this.props
        return (
            <div className="searchbox bg-color-white">
                <Row className={'borderdotted-bootom-2x bd-color-gray pdbt15'}>
                    <Col
                        span={24}
                        className="dpl-flex"
                    >
                        <span className={'searchbox__label txt-color-black txt-size-h7 robotoregular width-300'}>
                            {t('customer_info.transaction_type')}:
                        </span>
                        <div className={'txt-size-h7 txt-color-blue robotoregular txt-capitalize width-100-percent flex-1-1'}>
                            {listFinancialTypes.map((item, index) => this.renderStatusSearchItem(item, index, 'externalTypes'))}
                        </div>
                    </Col>
                </Row>
                <Row className={'dpl-flex bd-color-gray borderdotted-bootom-2x pdbt15 pdt10'}>
                    <Col
                        span={8}
                        className="pdr10"
                    >
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('customer_info.input_code')}:
                        </div>
                        <Input
                            value={querySearch}
                            onChange={e => this.setState({ querySearch: e.target.value })}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    this.onSubmitSearch()
                                }
                            }}
                            className={'_search-box-input-query'}
                        />
                    </Col>
                    <Col
                        span={12}
                        className="pdl10"
                    >
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('customer_info.time')}:
                        </div>
                        <Row gutter={20}>
                            <Col
                                span={12}
                                className="pdr10"
                            >
                                <DatePicker
                                    disabledDate={this.disabledStartDate}
                                    className="_startDatepicker"
                                    style={{ minWidth: '100%' }}
                                    format={'DD/MM/YYYY'}
                                    value={this.checkDateTime(startValue)}
                                    placeholder={t('order.start')}
                                    onChange={this.onStartChange}
                                    onOpenChange={this.handleStartOpenChange}
                                />
                            </Col>
                            <Col span={12}>
                                <DatePicker
                                    className="_endDatepicker"
                                    disabledDate={this.disabledEndDate}
                                    style={{ minWidth: '100%' }}
                                    format={'DD/MM/YYYY'}
                                    value={this.checkDateTime(endValue)}
                                    placeholder={t('order.finish')}
                                    onChange={this.onEndChange}
                                    onOpenChange={this.handleEndOpenChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                    {Array.isArray(accountList) && accountList.length > 1 && (
                        <Col span={12}>
                            <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mt-2 mgbt5'}>
                                Số tài khoản:
                            </div>
                            <Row gutter={20}>
                                <Col span={12}>
                                    <Select
                                        showSearch
                                        value={accountSelected}
                                        onChange={value => {
                                            this.setState({ accountSelected: value }, () => {
                                                this.loadData()
                                            })
                                        }}
                                        className="w-full"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {Array.isArray(accountList) &&
                                            accountList.map((item, index) => {
                                                return (
                                                    <Select.Option
                                                        className="_provinceValue"
                                                        key={item.id}
                                                        value={item.account}
                                                    >
                                                        {item.account}
                                                    </Select.Option>
                                                )
                                            })}
                                    </Select>
                                </Col>
                            </Row>
                        </Col>
                    )}
                </Row>
                <div className=" dpl-flex align-items-center justify-content-between pdt10">
                    <div />
                    <div className="dpl-flex align-items-center">
                        <span
                            onClick={this.onClearSearch}
                            className="_btn-clear mgr20 txt-color-gray txt-size-h8 cursor-pointer robotoregular"
                        >
                            <AntdIcon.SyncOutlined
                                type={'sync'}
                                className={'mgr5'}
                            />{' '}
                            {t('order.filter_refresh')}
                        </span>
                        <Button
                            onClick={this.onSubmitSearch}
                            type="primary"
                            className={'_btn-search min-width-200'}
                        >
                            {t('customer_info.search')}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    groupData = data => {
        const result = []
        const removeData = []

        for (let i = 0; i < data?.length; i++) {
            const previousTransaction = data?.find(
                el =>
                    data[i]?.previousTransaction === el?.ledgeTx &&
                    ((el?.relId?.length > 0 && el?.relId?.length > 0 && el?.relId === data[i]?.relId) ||
                        (el?.batchId?.length > 0 && data[i]?.batchId?.length > 0 && el?.batchId === data[i]?.batchId))
            )
            if (previousTransaction) {
                if (
                    _.findIndex(typeGroup, el => {
                        const dataEl = el?.split(',')
                        return (
                            (dataEl[0] === previousTransaction?.externalType && dataEl[1] === data[i]?.externalType) ||
                            (dataEl[1] === previousTransaction?.externalType && dataEl[0] === data[i]?.externalType)
                        )
                    }) >= 0
                ) {
                    const newData = {
                        ...data[i],
                        memo: [data[i]?.memo, previousTransaction?.memo],
                        amount:
                            previousTransaction?.batchId && data[i]?.batchId && previousTransaction?.batchId === data[i]?.batchId
                                ? data[i]?.amount
                                : data[i]?.amount + previousTransaction?.amount,
                        externalType:
                            data[i]?.externalType === 'collect' && previousTransaction?.externalType === 'refund'
                                ? previousTransaction?.externalType
                                : data[i]?.externalType,
                        transactionBalanceAfter:
                            previousTransaction?.batchId && data[i]?.batchId && previousTransaction?.batchId === data[i]?.batchId
                                ? null
                                : data[i]?.transactionBalanceAfter,
                    }
                    result.push(newData)
                    removeData.push(previousTransaction)
                } else {
                    result.push({
                        ...data[i],
                        memo: [data[i]?.memo],
                    })
                }
            } else {
                result.push({
                    ...data[i],
                    memo: [data[i]?.memo],
                })
            }
        }
        return _.differenceBy(result, removeData, 'id')
    }

    formatMemo = text => {
        let type = text.indexOf('đơn ký gửi') >= 0 ? 'shipments' : text.indexOf('đơn hàng') >= 0 ? 'orders' : ''
        if (type !== '') {
            let arraySplit1 = text.split(type === 'shipments' ? 'đơn ký gửi' : type === 'orders' ? 'đơn hàng' : '')
            let arraySplitName = arraySplit1[0].split(/[\\[\]]/)
            let username = <span className="font-bold">{arraySplitName?.[1] || ''}</span>
            let arraySplit2 = arraySplit1[1].split(' ')
            let arraySplit3 = arraySplit2.filter(x => x.indexOf('#') >= 0)
            let array3 = []
            arraySplit3.map(x => {
                x = x.replace(/\\,/g, '').replace(/\\;/g, '').replace(/\\-/g, '')
                array3.push(x)
            })
            let arrayId = []
            array3.map(x => {
                arrayId = [...arrayId, ...x.split('#')]
            })
            arrayId = arrayId.filter(x => x)
            return (
                <div className="txt-size-h7 break-word robotoregular _transaction-history-item-memo txt-color-black break-word white-space-pre-wrap">
                    {arraySplitName[0]
                        ?.replace('Nạp khoản tín dụng', 'Nạp khoản ứng vốn từ BiFin')
                        ?.replace('Thu khoản tín dụng', 'Thu khoản ứng vốn từ BiFin')
                        ?.replace('Cân đối khoản tín dụng', 'Cân đối khoản ứng vốn từ BiFin') || ''}
                    {username}
                    {arraySplitName[2]}
                    {type === 'shipments' ? 'đơn ký gửi ' : type === 'orders' ? 'đơn hàng ' : ''}
                    {arrayId.map((x, y) => {
                        return (
                            <span key={y}>
                                <Link
                                    to={
                                        type === 'shipments'
                                            ? `/shipments/${x.replace('#', '')}`
                                            : type === 'orders'
                                            ? `/orders/${x.replace('#', '')}`
                                            : ''
                                    }
                                >
                                    #{x}
                                </Link>
                                {y + 1 === arrayId.length ? '' : ', '}
                            </span>
                        )
                    })}
                    {arraySplit2.filter(x => x.indexOf('#') < 0).join(' ')}
                </div>
            )
        } else {
            return <div>{text}</div>
        }
    }

    render() {
        const { listTransactions = [], isLoading, metadata, onExport, listFinancialTypes = [] } = this.state
        const { t } = this.props
        let listData = []
        if (Array.isArray(listTransactions)) {
            listTransactions.map(item => {
                if (Array.isArray(item.purchaseUnits) && item.purchaseUnits.length > 0) {
                    item.purchaseUnits.map(x => {
                        listData.push({
                            ...x,
                            transactionTimestamp: item.timestamp,
                            transactionId: item.id,
                            transactionBalanceAfter: item.balanceAfter,
                        })
                    })
                } else {
                    listData.push({
                        ...item,
                        transactionTimestamp: item.timestamp,
                        transactionId: item.id,
                        transactionBalanceAfter: item.balanceAfter,
                    })
                }
            })
        }
        const newListData = this.groupData(_.sortBy(listData, 'actualTimestamp').reverse())
        const data = _.groupBy(listData, 'transactionId')
        const columns = [
            {
                title: t('customer_info.time'),
                dataIndex: 'actualTimestamp',
                key: 'actualTimestamp',
                className: 'profiletableth',
                width: 220,
                render: (text, record) => {
                    const obj = {
                        children: (
                            <div>
                                <div className={'text-xs text-black-100 font-normal mb-1'}>
                                    {moment(text).format('HH:mm DD/MM/YYYY')}
                                </div>
                                <div className="text-sm text-black-100 font-normal mb-1 white-space-pre-wrap">
                                    {t('customer_info.bankNo')}: {record.account}
                                </div>
                                <div className="text-xs text-grey-400 font-normal mb-1 white-space-pre-wrap break-word">
                                    {t('customer_info.code')}: {record.txid}
                                </div>
                            </div>
                        ),
                        props: {
                            rowSpan:
                                data[record.transactionId].findIndex(x => x.id === record.id) === 0
                                    ? data[record.transactionId].length
                                    : 0,
                        },
                    }

                    return obj
                },
            },
            {
                title: t('customer_info.transaction_type'),
                dataIndex: 'externalType',
                key: 'externalType',
                className: 'profiletableth',
                width: 120,
                render: text => {
                    const currentType = listFinancialTypes?.find(x => x.code === text?.toLowerCase())
                    return (
                        <div className="txt-size-h7 txt-color-black robotoregular _transaction-history-customType">
                            {currentType ? currentType.name : '---'}
                        </div>
                    )
                },
            },
            {
                title: t('customer_info.content'),
                dataIndex: 'memo',
                key: 'memo',
                className: 'profiletableth',
                render: (text, record) => {
                    if (text) {
                        return (
                            <ul className={text?.length > 1 ? 'list-disc pl-4' : 'pl-0'}>
                                {text?.map(i => {
                                    return <li>{this.formatMemo(i)}</li>
                                })}
                            </ul>
                        )
                    }
                    return ''
                },
            },
            {
                title: t('customer_info.amount'),
                dataIndex: 'amount',
                key: 'amount',
                className: 'profiletableth txt-right',
                render: (text, record) => {
                    const currencySymbol = lodash.get(record, 'currency', undefined)
                    return (
                        <span className={`txt-right robotoregular hidden-md ${text >= 0 ? 'text-emerald-400' : 'text-red-100'}`}>
                            {text >= 0 ? '+' : ''}
                            {moneyFormat(moneyCeil(text), currencySymbol)}
                        </span>
                    )
                },
            },
            {
                title: t('customer_info.balanceAfter'),
                dataIndex: 'transactionBalanceAfter',
                key: 'transactionBalanceAfter',
                className: 'profiletableth txt-right',
                render: (text, record) => {
                    const currencySymbol = lodash.get(record, 'currency', undefined)
                    const obj = {
                        children: (
                            <span className="font-medium text-black-100 robotoregular txt-right">
                                {text && moneyFormat(moneyCeil(text), currencySymbol)}
                            </span>
                        ),
                        props: {
                            rowSpan:
                                data[record.transactionId].findIndex(x => x.id === record.id) === 0
                                    ? data[record.transactionId].length
                                    : 0,
                        },
                    }

                    return obj
                },
            },
        ]

        return (
            <MainLayout
                ref={ref => (this.mainLayoutElement = ref)}
                {...this.props}
                showProgressBar={this.state.showProgressBar}
                headerTitle={t('customer_info.transaction_history_list')}
                title={t('customer_info.personal_info')}
            >
                <div className={'container-common pdbt30 pdt20 mgr20'}>
                    <Row gutter={20}>
                        <Col span={6}>
                            <ProfileSidebar currentRoute={'new-transactions'} />
                        </Col>
                        <Col span={18}>
                            <div>
                                <div className="bg-white px-6 py-4 rounded-xl mb-4">{this.renderSearchBox()}</div>

                                <div className="bg-white p-4 rounded-xl">
                                    <div className="dpl-flex justify-content-between align-items-center bg-color-white mb-4">
                                        {metadata ? (
                                            <Space
                                                align="center"
                                                size={4}
                                            >
                                                <span
                                                    className={' text-base text-black-100 font-medium robotobmedium capitalize'}
                                                >
                                                    {t('customer_info.transaction_history_list')}
                                                </span>
                                                <Badge count={quantityFormat(metadata.total)} />
                                            </Space>
                                        ) : (
                                            <span />
                                        )}
                                        <ExportExcel
                                            {...this.props}
                                            validate={() => {
                                                if (!this.checkExportBtn()) {
                                                    notification['error']({
                                                        message: t('transaction.export_csv_btn_error'),
                                                    })
                                                    return true
                                                }
                                                return false
                                            }}
                                            onExport={onExport}
                                            exportExcel={this.exportExcel}
                                        />
                                    </div>
                                    <div>
                                        {Array.isArray(listTransactions) && listTransactions.length > 0 ? (
                                            <div>
                                                <Table
                                                    className={'table-v2 custom-empty table-transaction'}
                                                    rowKey={'id'}
                                                    columns={columns}
                                                    dataSource={newListData}
                                                    rowClassName={(record, index) => index % 2 !== 0 && 'table-row-odd'}
                                                    pagination={{
                                                        className: '_pagination text-center width-100-percent mgt20',
                                                        hideOnSinglePage: true,
                                                        total: newListData.length,
                                                        pageSize: newListData.length,
                                                    }}
                                                />
                                                <Pagination
                                                    {...{
                                                        className: '_pagination text-center width-100-percent mgt20',
                                                        hideOnSinglePage: true,
                                                        current: metadata ? parseFloat(metadata.page) + 1 : 1,
                                                        total: metadata ? parseFloat(metadata.total) : 1,
                                                        pageSize: metadata ? parseFloat(metadata.size) : pageSize,
                                                        onChange: (page, pageSize) => this.onPageChanged(page, pageSize),
                                                    }}
                                                />
                                            </div>
                                        ) : isLoading ? (
                                            <Loading />
                                        ) : (
                                            <div className="pdt50 pdbt60 bg-color-white">
                                                <Empty
                                                    image={require('../../resources/images/empty-image.png')}
                                                    description={t('message.empty')}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </MainLayout>
        )
    }
}
export default withNamespaces()(TransactionHistoryByAccount)
