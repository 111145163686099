/* eslint-disable array-callback-return */
import React from "react";
import { List, Spin, Row, Divider } from "antd";
import moment from 'moment';
import NoData from "../NoData";
import lodash from "lodash";
import Loading from "../Loading";
import { ORDER_DETAIL_TAB_LOG } from "../../constants/config";
import OrderService from "../../services/orders.service";
import { moneyCeil, moneyFormat, quantityFormat } from "../../utils/stringUtils";

let paramCode = null;

class TabLog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            initLoading: true,
            loadingMore: false,
            logs: [],
            orderLogErrors: null,
            openOrderLog: false,
        }
    }

    componentDidMount() {
        paramCode = this.props.orderDetail.code;
        this.getLog();
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.activeTab !== this.props.activeTab && this.props.activeTab === ORDER_DETAIL_TAB_LOG) || JSON.stringify(prevProps.orderInfo) !== JSON.stringify(this.props.orderInfo)) {
            this.getLog();
        }
    }

    getLog = (page) => {
        const { logs = [] } = this.state;
        this.setState({ initLoading: true, orderLogErrors: null }, () => {
            OrderService.getLog(paramCode, page || 0, (err, res, headers) => {
                if (!err) {
                    this.setState({
                        initLoading: false,
                        logs: page ? [...logs, ...this.parseLog(res)] : this.parseLog(res),
                        metadata: {
                            pageCount: headers['x-page-count'],
                            page: headers['x-page-number'],
                            size: headers['x-page-size'],
                            total: headers['x-total-count'],
                        }
                    });
                } else {
                    this.setState({ initLoading: false, orderLogErrors: err });
                }
            });
        });
    };

    onLoadMore = () => {
        const { metadata } = this.state;
        if (!metadata) return;
        if (parseFloat(metadata.page) + 1 < parseFloat(metadata.pageCount)) {
            this.getLog(parseFloat(metadata.page) + 1);
        }
    };

    formatLogsContent = (data) => {
        const { t } = this.props;
        let result = `${t(`log_order.${data.property}`, data)}`;
        return result;
    };

    onRecall = () => {
        this.getLog();
    };

    parseLog = (items) => {
        const { t, warehousesDelivery, warehousesReceive, listStatuses, listPackageStatuses, orderDetail = {} } = this.props;
        let logs = [];
        let warehouses = [...warehousesDelivery, ...warehousesReceive];

        const orderCurrency = orderDetail.marketplace ? orderDetail.marketplace.currency : null;

        items.map(item => {
            let itemTransform = {};
            itemTransform.fullname = item.actor.fullname ? item.actor.fullname : '---';
            itemTransform.timestamp = item.timestamp ? item.timestamp : '---';
            itemTransform.role = item.role;
            switch (item.activity) {
                case 'ORDER_CREATE':
                case 'ORDER_RECEIPT_CREATE':
                case 'ORDER_RECEIPT_DELETE':
                    itemTransform.property = item.activity
                    itemTransform.value = item.data.code
                    break
                case 'ORDER_PRODUCT_UPDATE':
                    // , purchasingStaff,  actualPrice(don gia), staffRemark(ghi chu)
                    if (item.data.length > 0) {
                        // purchasedQuantity, receivedQuantity,
                        if (Array.isArray(item.data)) itemTransform.logs = []
                        item.data.forEach((ele, index) => {
                            if (ele.property === 'purchasedQuantity') {
                                itemTransform.logs.push({
                                    fullname: item.actor.fullname ? item.actor.fullname : '---',
                                    role: item.role,
                                    timestamp: item.timestamp ? item.timestamp : '---',
                                    name: item?.reference?.code,
                                    property: ele?.property,
                                    oldValue: ele?.oldValue
                                        ? quantityFormat(ele.oldValue)
                                        : ele.oldValue === 0
                                        ? t('log_product.out_of_stock')
                                        : t('log_product.not_buy'),
                                    newValue:
                                        ele.newValue === null
                                            ? t('log_product.not_buy')
                                            : ele.newValue.toString() === (0).toString()
                                            ? t('log_product.out_of_stock')
                                            : quantityFormat(ele.newValue),
                                })
                            }
                            //     if (ele.property === "receivedQuantity") {
                            //     itemTransform.logs.push({
                            //         fullname: item.actor.fullname ? item.actor.fullname : '---',
                            //         role: item.role,
                            //         timestamp: item.timestamp ? item.timestamp : '---',
                            //         name: item.reference.code,
                            //         property: ele.property,
                            //         oldValue: ele.oldValue ? quantityFormat(ele.oldValue) : ele.oldValue === 0 ? 0 : t('log_product.not_checked'),
                            //         newValue: !lodash.isNil(ele.newValue) ? quantityFormat(ele.newValue) : ele.newValue === 0 ? 0 : t('log_product.not_checked')
                            //     })
                            // }
                            // else
                            else if (ele.property === 'staffRemark') {
                                let oldRemark = ele.oldValue && ele.oldValue.toString().replace(/↵+/g, '').trim()
                                let newRemark = ele.newValue && ele.newValue.toString().replace(/↵+/gm, '').trim()

                                itemTransform.logs.push({
                                    fullname: item.actor.fullname ? item.actor.fullname : '---',
                                    role: item.role,
                                    name: item.reference.code,
                                    timestamp: item.timestamp ? item.timestamp : '---',
                                    property: ele.property,
                                    oldValue: !oldRemark || oldRemark === '' ? t('log_product.empty') : oldRemark,
                                    newValue: !newRemark || newRemark === '' ? t('log_product.empty') : newRemark,
                                })
                            } else if (ele.property === 'actualPrice') {
                                itemTransform.logs.push({
                                    fullname: item.actor.fullname ? item.actor.fullname : '---',
                                    role: item?.role,
                                    name: item?.reference?.code,
                                    timestamp: item.timestamp ? item.timestamp : '---',
                                    property: ele.property,
                                    oldValue: moneyFormat(ele.oldValue, item.reference?.currency),
                                    newValue: moneyFormat(ele.newValue, item.reference?.currency),
                                })
                            } else if (ele.property === 'quantity') {
                                itemTransform.logs.push({
                                    fullname: item.actor.fullname ? item.actor.fullname : '---',
                                    role: item?.role,
                                    name: item?.reference?.code,
                                    timestamp: item.timestamp ? item.timestamp : '---',
                                    property: ele.property,
                                    oldValue:ele.oldValue,
                                    newValue:ele.newValue,
                                })
                            } else if (ele.property === 'confirm') {
                                itemTransform.logs.push({
                                    fullname: item.actor.fullname ? item.actor.fullname : '---',
                                    role: item.role,
                                    name: item.reference.code,
                                    timestamp: item.timestamp ? item.timestamp : '---',
                                    property: ele.property,
                                    confirm: ele.newValue.confirm ? t('log_order.agree') : t('log_order.reject'),
                                    newValue:
                                        ele.newValue.property === 'actualPrice'
                                            ? moneyFormat(
                                                  moneyCeil(lodash.get(ele, 'newValue.newValue', t('shipment_log.empty'))),
                                                  item.reference.currency
                                              )
                                            : ele.newValue.newValue,
                                    type:
                                        ele.newValue.property === 'actualPrice'
                                            ? t('product_tab.sale_price')
                                            : t('log_order.purchasedQuantity_label'),
                                })
                            }
                        })
                    }
                    break
                case 'ORDER_UPDATE':
                    if (Array.isArray(item.data) && item.data.length > 0) {
                        itemTransform.property = lodash.get(item.data, '0.property')
                        if (lodash.get(item.data, '0.property') === 'merchantContacts') {
                            if (item.data[0].oldValue) {
                                itemTransform.oldValue = !lodash.isEmpty(item.data[0].oldValue.aliwangwang)
                                    ? item.data[0].oldValue.aliwangwang
                                    : t('log_product.empty')
                            } else itemTransform.oldValue = t('log_product.empty')

                            if (item.data[0].newValue) {
                                itemTransform.newValue = !lodash.isEmpty(item.data[0].newValue.aliwangwang)
                                    ? item.data[0].newValue.aliwangwang
                                    : t('log_product.empty')
                            } else itemTransform.newValue = t('log_product.empty')
                        } else if (item.data[0].property === 'purchasingStaff') {
                            itemTransform.oldValue = item.data[0].oldValue ? item.data[0].oldValue.code : t('log_product.empty')
                            itemTransform.newValue = item.data[0].newValue ? item.data[0].newValue.code : t('log_product.empty')
                        } else if (item.data[0].property === 'merchantShippingCost') {
                            itemTransform.oldValue = item.data[0].oldValue
                                ? moneyFormat(item.data[0].oldValue, orderCurrency)
                                : item.data[0].oldValue === 0
                                ? t('shipment_log.free')
                                : t('log_product.empty')
                            itemTransform.newValue = item.data[0].newValue
                                ? moneyFormat(item.data[0].newValue, orderCurrency)
                                : item.data[0].newValue === 0
                                ? t('shipment_log.free')
                                : t('log_product.empty')
                        } else if (
                            item.data[0].property === 'deliveryWarehouse' ||
                            item.data[0].property === 'receivingWarehouse'
                        ) {
                            // deliveryWarehouse, receivingWarehouse
                            itemTransform.oldValue = lodash.get(
                                lodash.filter(warehouses, { code: item.data[0].oldValue }),
                                '0.name',
                                t('log_product.empty')
                            )
                            itemTransform.newValue = lodash.get(
                                lodash.filter(warehouses, { code: item.data[0].newValue }),
                                '0.name',
                                t('log_product.empty')
                            )
                        } else if (item.data[0].property === 'emdPercent') {
                            itemTransform.property = 'ORDER_UPDATE_EMD'
                            itemTransform.oldValue = quantityFormat(lodash.get(item, 'data[0].oldValue', 0))
                            itemTransform.newValue = quantityFormat(lodash.get(item, 'data[0].newValue', 0))
                            itemTransform.name = item.reference ? item.reference.code : null
                        } else if (item.data[0].property === 'exchangeRate') {
                            itemTransform.oldValue = item.data[0].oldValue ? moneyFormat(item.data[0].oldValue) : 'trống'
                            itemTransform.newValue = item.data[0].newValue ? moneyFormat(item.data[0].newValue) : 'trống'
                        } else {
                            //purchasingAccount
                            itemTransform.oldValue = lodash.get(item, 'data[0].oldValue', t('log_product.empty'))
                            itemTransform.newValue = lodash.get(item, 'data[0].newValue', t('log_product.empty'))
                        }
                    }

                    break
                case 'ORDER_ADDRESS_UPDATE':
                    if (Array.isArray(item.data)) itemTransform.logs = []
                    item.data.forEach((ele, index) => {
                        if (ele.property === 'location' && ele.oldValue.display !== ele.newValue.display) {
                            itemTransform.logs.push({
                                fullname: item.actor.fullname ? item.actor.fullname : '---',
                                role: item.role,
                                timestamp: item.timestamp ? item.timestamp : '---',
                                property: 'ORDER_ADDRESS_UPDATE_LOCATION',
                                oldValue: lodash.get(ele, 'oldValue.display', t('log_product.empty')),
                                newValue: lodash.get(ele, 'newValue.display', t('log_product.empty')),
                            })
                        } else if (ele.property === 'note') {
                            if (
                                (ele.oldValue && ele.oldValue.toString().trim() !== ele.newValue.toString().trim()) ||
                                (!ele.oldValue && ele.newValue && ele.newValue.toString().trim() !== '')
                            )
                                itemTransform.logs.push({
                                    fullname: item.actor.fullname ? item.actor.fullname : '---',
                                    role: item.role,
                                    timestamp: item.timestamp ? item.timestamp : '---',
                                    property: 'ORDER_ADDRESS_UPDATE_note',
                                    oldValue: ele.oldValue && ele.oldValue.trim() !== '' ? ele.oldValue : t('log_product.empty'),
                                    newValue: ele.newValue.trim() !== '' ? ele.newValue : t('log_product.empty'),
                                })
                        } else if (ele.property && ele.oldValue && ele.oldValue.toString() !== ele.newValue.toString()) {
                            itemTransform.logs.push({
                                fullname: item.actor.fullname ? item.actor.fullname : '---',
                                role: item.role,
                                timestamp: item.timestamp ? item.timestamp : '---',
                                property: `ORDER_ADDRESS_UPDATE_${ele.property}`,
                                oldValue: ele.oldValue,
                                newValue: ele.newValue,
                            })
                        }
                    })
                    break
                case 'ORDER_STATUS_UPDATE':
                    itemTransform.property = item.activity
                    if (item.data && Array.isArray(item.data)) {
                        itemTransform.newValue = lodash.get(
                            listStatuses.find(it => it.code === item.data[0].newValue.code),
                            'name',
                            '---'
                        )
                        itemTransform.oldValue = lodash.get(
                            listStatuses.find(it => it.code === item.data[0].oldValue.code),
                            'name',
                            '---'
                        )
                    } else {
                        itemTransform.newValue = lodash.get(
                            listStatuses.find(it => it.code === item.data.newValue.code),
                            'name',
                            '---'
                        )
                        itemTransform.oldValue = lodash.get(
                            listStatuses.find(it => it.code === item.data.oldValue.code),
                            'name',
                            '---'
                        )
                    }
                    break
                case 'ORDER_CANCELLED':
                case 'ORDER_FEE_CALCULATE_ALL':
                    itemTransform.property = item.activity
                    itemTransform.name = item.data.code
                    break
                case 'ORDER_CONFIRM_RECEIVED':
                    // itemTransform.property = item.activity;
                    // itemTransform.newValue = lodash.get(lodash.filter(statuses, {code: item.data.newValue}), '0.name', '');
                    // itemTransform.oldValue = lodash.get(lodash.filter(statuses, {code: item.data.oldValue}), '0.name', '');
                    break
                case 'ORDER_FEE_CREATED':
                    itemTransform.property = item.activity
                    itemTransform.value = item.data.type.name
                    itemTransform.amount = moneyFormat(lodash.get(item, 'data.actualAmount', null))
                    itemTransform.reason = item.data.reason
                    break
                case 'ORDER_FEE_UPDATE':
                    itemTransform.logs = []
                    item.data.forEach((ele, index) => {
                        itemTransform.name = lodash.get(item.reference, 'type.name', '---')
                        if (ele.property === 'reason') {
                            // if (item.data.length > 1) itemTransform.reason = ele.newValue;
                            itemTransform.logs.push({
                                fullname: item.actor.fullname ? item.actor.fullname : '---',
                                role: item.role,
                                timestamp: item.timestamp ? item.timestamp : '---',
                                oldValue: ele.oldValue || t('shipment_log.empty'),
                                newValue: ele.newValue || '---',
                                name: lodash.get(item.reference, 'type.name', '---'),
                                property: 'ORDER_FEE_UPDATE_REASON',
                            })
                        } else if (ele.property === 'free' && ele.newValue !== ele.oldValue) {
                            if (ele.newValue === true) itemTransform.value = t('shipment_log.free')
                            else if (ele.newValue === false) itemTransform.value = t('shipment_log.cancel_free')
                            itemTransform.logs.push({
                                fullname: item.actor.fullname ? item.actor.fullname : '---',
                                role: item.role,
                                name: itemTransform.name,
                                timestamp: item.timestamp ? item.timestamp : '---',
                                reason: itemTransform.reason,
                                value: itemTransform.value,
                                property: 'ORDER_FEE_UPDATE_FREE',
                            })
                        }
                        if (ele.property === 'amount') {
                            itemTransform.property = 'ORDER_FEE_UPDATE_AMOUNT'
                            itemTransform.newValue = moneyFormat(ele.newValue)
                            itemTransform.oldValue = moneyFormat(ele.oldValue)
                            itemTransform.logs.push({
                                fullname: item.actor.fullname ? item.actor.fullname : '---',
                                role: item.role,
                                name: itemTransform.name,
                                reason: itemTransform.reason,
                                timestamp: item.timestamp ? item.timestamp : '---',
                                newValue: itemTransform.newValue,
                                oldValue: itemTransform.oldValue,
                                property: 'ORDER_FEE_UPDATE_AMOUNT',
                            })
                        }
                    })
                    break
                case 'ORDER_TAG_DELETE':
                    itemTransform.property = itemTransform.storageDescription ? 'ORDER_TAG_CREATE_DESCRIPTION' : item.activity
                    if (item.data.description) itemTransform.storageDescription = item.data.description
                    else itemTransform.reason = t('log_product.empty')
                    let tags = this.props.tagsList.find(tag => tag.code === item.data.tag)
                    itemTransform.value = tags ? tags.name : ''
                    break
                case 'ORDER_TRACKING_CREATE':
                case 'ORDER_PACKAGE_CREATE':
                case 'ORDER_PACKAGE_DELETE':
                case 'ORDER_TRACKING_DELETE':
                    itemTransform.property = item.activity
                    if (item.activity === 'ORDER_TRACKING_DELETE')
                        itemTransform.value = lodash.get(item, 'data[0].code', t('log_product.empty'))
                    else itemTransform.value = lodash.get(item, 'data.code', t('log_product.empty'))
                    break
                case 'ORDER_PACKAGE_UPDATE':
                    if (Array.isArray(item.data)) itemTransform.logs = []
                    if (item.data && Array.isArray(item.data)) {
                        item.data.forEach(ele => {
                            if (ele.property) {
                                let unit = ''
                                if (ele.property === 'volumetric') unit = ' cm3'
                                else if (
                                    ele.property === 'netWeight' ||
                                    ele.property === 'dimensionalWeight' ||
                                    ele.property === 'packagingWeight' ||
                                    ele.property === 'actualWeight'
                                ) {
                                    unit = ' kg'
                                } else if (ele.property === 'length' || ele.property === 'width' || ele.property === 'height') {
                                    unit = ' cm'
                                }
                                let itemTemp = {
                                    fullname: item.actor.fullname ? item.actor.fullname : '---',
                                    role: item.role,
                                    timestamp: item.timestamp ? item.timestamp : '---',
                                    property: `ORDER_PACKAGE_UPDATE_${ele.property}`,
                                    name: item.reference.code && item.reference.code,
                                    oldValue: ele.oldValue
                                        ? lodash.isNumber(ele.oldValue)
                                            ? quantityFormat(ele.oldValue) + unit
                                            : ele.property === 'status'
                                            ? lodash.get(
                                                  listPackageStatuses.find(it => it.code === ele.oldValue.code),
                                                  'name',
                                                  '---'
                                              )
                                            : ele.oldValue
                                        : ele.oldValue === 0
                                        ? 0 + unit
                                        : '---',
                                    newValue: ele.newValue
                                        ? lodash.isNumber(ele.newValue)
                                            ? quantityFormat(ele.newValue) + unit
                                            : ele.property === 'status'
                                            ? lodash.get(
                                                  listPackageStatuses.find(it => it.code === ele.newValue.code),
                                                  'name',
                                                  '---'
                                              )
                                            : ele.newValue
                                        : ele.newValue === 0
                                        ? 0 + unit
                                        : '---',
                                }
                                if (ele.property === 'status' && !ele.oldValue.hasOwnProperty('name')) {
                                    let oldStatus = listPackageStatuses.find(x => x.code === ele.oldValue.code)
                                    let newStatus = listPackageStatuses.find(x => x.code === ele.newValue.code)
                                    itemTemp.oldValue = lodash.get(oldStatus, 'name', '---')
                                    itemTemp.newValue = lodash.get(newStatus, 'name', '---')
                                }
                                itemTransform.logs.push(itemTemp)
                            }
                        })
                    } else {
                        itemTransform.property = `ORDER_PACKAGE_UPDATE_${item.data.property}`
                        itemTransform.oldValue =
                            item.data.property === 'status' || item.property === 'status'
                                ? lodash.get(
                                      listPackageStatuses.find(it => it.code === item.data.oldValue.code),
                                      'name',
                                      '---'
                                  )
                                : item.data.oldValue
                                ? item.data.oldValue
                                : '---'
                        itemTransform.newValue =
                            item.data.property === 'status' || item.property === 'status'
                                ? lodash.get(
                                      listPackageStatuses.find(it => it.code === item.data.newValue.code),
                                      'name',
                                      '---'
                                  )
                                : item.data.newValue
                                ? item.data.newValue
                                : '---'
                        itemTransform.name = item.reference.code
                    }
                    break
                case 'ORDER_SERVICE_UPDATE':
                    itemTransform.logs = []
                    if (lodash.get(item.data, '0.type') === 'SIMPLE_VALUE_CHANGE') {
                        itemTransform.logs.push({
                            fullname: item.actor.fullname ? item.actor.fullname : '---',
                            role: item.role,
                            timestamp: item.timestamp ? item.timestamp : '---',
                            property: `${item.activity}_SIMPLE_VALUE_CHANGE`,
                        })
                    } else {
                        lodash.get(item.data, '0.addedValues').forEach((ele, index) => {
                            if (ele)
                                itemTransform.logs.push({
                                    fullname: item.actor.fullname ? item.actor.fullname : '---',
                                    role: item.role,
                                    timestamp: item.timestamp ? item.timestamp : '---',
                                    property: `${item.activity}_ADD`,
                                    addValue: ele.name,
                                })
                        })
                        lodash.get(item.data, '0.removedValues').forEach((ele, index) => {
                            if (ele)
                                itemTransform.logs.push({
                                    fullname: item.actor.fullname ? item.actor.fullname : '---',
                                    role: item.role,
                                    timestamp: item.timestamp ? item.timestamp : '---',
                                    property: `${item.activity}_REMOVE`,
                                    removeValue: ele.name,
                                })
                        })
                    }
                    break
                case 'ORDER_SERVICE_APPROVED':
                    itemTransform.logs = []
                    item.data.forEach(ele => {
                        itemTransform.logs.push({
                            fullname: item.actor.fullname ? item.actor.fullname : '---',
                            role: item.role,
                            timestamp: item.timestamp ? item.timestamp : '---',
                            property: ele.newValue ? `${item.activity}_ADD` : `${item.activity}_REMOVE`,
                            service: item.reference.name,
                        })
                    })
                    break
                case 'payment':
                case 'claim':
                case 'emd':
                case 'refund':
                case 'collect':
                case 'gift':
                case 'deposit':
                case 'charge':
                case 'withdraw':
                case 'adjust':
                    itemTransform.reason = item.memo ? item.memo : '---'
                    itemTransform.property = item.activity
                    if (item.activity === 'emd')
                        itemTransform.amount = item.amount && moneyFormat(item.amount).toString().replace('-', '')
                    else itemTransform.amount = item.amount && moneyFormat(item.amount)
                    break
                case 'ORDER_COUPON_APPLY':
                    itemTransform.property = item.activity
                    itemTransform.content = item.data ? item.data.code + ' - ' + item.data.description : ''
                    break
                case 'ORDER_UPDATE_EMD':
                    itemTransform.oldValue = quantityFormat(lodash.get(item, 'data[0].oldValue', 0))
                    itemTransform.newValue = quantityFormat(lodash.get(item, 'data[0].newValue', 0))
                    itemTransform.property = item.activity
                    itemTransform.name = item.reference ? item.reference.code : null
                    break
                case 'ORDER_UPDATE_EXCHANGE_RATE':
                    itemTransform.oldValue = moneyFormat(lodash.get(item, 'data[0].oldValue', 0))
                    itemTransform.newValue = moneyFormat(lodash.get(item, 'data[0].newValue', 0))
                    itemTransform.property = 'edit_exchange_rate'
                    break
                case 'ORDER_UPDATE_INTERNAL_EXCHANGE_RATE':
                    itemTransform.property = 'edit_internal_exchange_rate'
                    break
                default:
                    break
            }
            if (itemTransform.logs && itemTransform.logs.length > 0)
                logs.push(...itemTransform.logs);
            else if (itemTransform.property)
                logs.push(itemTransform);
        });
        return logs;
    };


    render() {
        let { t } = this.props;
        let { initLoading, loadingMore, metadata, logs = [], orderLogErrors } = this.state;
        const loadMoreDisplay = !initLoading && !loadingMore && metadata && parseFloat(metadata.page) + 1 < parseFloat(metadata.pageCount) ?
            (<div key={2} className={'mgt5 mgbt5'} style={{ text: 'center', height: 32, lineHeight: '32px' }}>
                <span className={'_text-load-more cursor-pointer'} onClick={this.onLoadMore}>{t('log_product.loading_more')}</span>
            </div>)
            : <div key={2} />;
        return (
            !lodash.isEmpty(logs) ?
                <List
                    key={1}
                    className="demo-loadmore-list _order-logs order-log-list pdl20"
                    loading={initLoading}
                    itemLayout="horizontal"
                    loadMore={loadMoreDisplay}
                    dataSource={logs}
                    renderItem={(itemTransform, index) => {
                        return (
                            <div>
                                <div>
                                    <Row key={lodash.uniqueId()}
                                        className={`_row-log-info txt-size-h7 txt-color-gray robotoregular dpl-block ${index !== 0 && 'mgt12'}`}>
                                        <span>{moment(itemTransform.timestamp).format('HH:mm DD/MM/YYYY')}</span>,
                                        <span>
                                            <span
                                                className={'pdl5'}>{itemTransform.role === 'STAFF' ? `${t('shipment_log.staff')}:` : `${t('shipment_log.customer')}:`}</span>
                                            <span
                                                className={'txt-color-black robotobold pdl5'}>{itemTransform.fullname}</span>
                                        </span>
                                    </Row>
                                    <Row
                                        className={`_row-log-content txt-size-h6 txt-color-black dpl-block whitespace-pre-wrap break-word`}
                                        dangerouslySetInnerHTML={{ __html: this.formatLogsContent(itemTransform) }}
                                    />
                                    {itemTransform.storageDescription &&
                                        <div className={'_log-reason-storage whitespace-pre-wrap break-word'}>
                                            <strong>{itemTransform.storageDescription}</strong></div>}
                                    <Divider className={'mgt12 mgbt0'} style={{ backgroundColor: '#e8e8e8' }} />
                                </div>
                            </div>
                        )
                    }}
                >
                    {loadingMore &&
                        <div className="text-center" style={{ marginTop: 15, marginBottom: 15 }}>
                            <Spin />
                        </div>
                    }
                </List>
                :
                initLoading ?
                    <div className={'txt-center'}>
                        <Loading height="30vh" />
                    </div>
                    :
                    <NoData error={orderLogErrors} onRecall={this.onRecall} />
        );
    };
}

export default TabLog;
