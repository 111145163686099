/* eslint-disable array-callback-return */
/**
 * create by Taobao dev team 18/1/2018
 */
import React, {Component, Fragment} from 'react';
import {Alert, Avatar, Col, Empty, List, Row, Tooltip} from 'antd';
import * as AntdIcon from "@ant-design/icons"

import _ from 'lodash';
import {checkIsLink, linkify, moneyCeil, moneyFormat, quantityFormat} from "../../utils/stringUtils"
import {modifyArrayDataSource} from "../../utils/arrayUltils"
// const { TextArea } = Input;

export default class CartGroupCheckout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            targetCurrency: 'VND',
            initLoading: true,
            dataList: _.sortBy(modifyArrayDataSource(props.dataSource), 'timestamp').reverse(),
            remark: '',
            note: ''
        }
    }

    componentDidMount() {
        const {dataSource} = this.props;
        setTimeout(() => {
            this.setState({
                initLoading: false,
                remark: dataSource.remark,
                note: dataSource.note,
            })
        }, 500)
    }

    componentDidUpdate (prevProps) {
        const {dataSource} = this.props;
        if (JSON.stringify(prevProps.dataSource) !== JSON.stringify(dataSource)) {
            this.setState({
                remark: dataSource.remark,
                note: dataSource.note,
                dataList: _.sortBy(modifyArrayDataSource(dataSource), 'timestamp').reverse()
            })
        }
    }

    /**
     * show warning khi vừa load data về
     * @param item
     */
    showWarningNumber = (item) => {
        const {t} = this.props;
        let warning = false;
        let error = false;
        if (!item.quantity) {
            error = true;
            item.errorMessage = t('order.quantity_required');
            item.warningMultiplesMessage = '';
            item.warningMaxMessage = '';
        } else {
            //kiểm tra bội số
            if (item.quantity % item.product.batchSize !== 0) {
                warning = true;
                item.warningMultiplesMessage = `${t('order.multiples_message')} ${item.product.batchSize}`;
            } else {
                item.warningMultiplesMessage = '';
            }

            //kiểm tra vượt quá hàng tồn
            if (item.quantity > item.stock) {
                warning = true;
                item.warningMaxMessage = `${t('order.max_message')} ${quantityFormat(item.stock)}`;
            } else {
                item.warningMaxMessage = '';
            }

            //kiểm tra mua ít hơn tối thiểu
            let totalQuantity = 0;
            item.product.skus.filter(x => x.id !== item.id).map(x => {
                totalQuantity = totalQuantity + x.quantity;
            });
            let minQuantity = item.product.minQuantity - totalQuantity;

            if (item.quantity < minQuantity) {
                error = true;
                item.errorMessage = `${t('order.cannot_be_less_than')} ${item.product.minQuantity}`;
            } else {
                item.errorMessage = '';
            }

        }

        item.error = error;
        item.warning = warning;

        return item;
    };

    /**
     * input change event
     * @param value
     * @param prop
     * @param item
     */
    onInputChange = (value, prop, item) => {
        let {dataList} = this.state;
        // lấy ra index của item đang thao tác
        let index = dataList.findIndex(x => x.id === item.id);
        dataList[index][prop] = value;
        this.setState({dataList})
    };

    /**
     * render header of list
     * @returns {*}
     */
    renderHeaderList = () => {
        const {
            dataSource, draftOrder, t, loansFailureList
        } = this.props;
        let totalQuanty = 0;
        modifyArrayDataSource(dataSource).map(item => {
            totalQuanty = totalQuanty + item.quantity
        });

        const inValidMachine = loansFailureList.find((item) => {
            return item.referenceId === dataSource.code
        });

        return (
            <div className={'headerlist'}>
                <Row className={'headerlist__top'}>
                    <Col span={24} className="dpl-flex justify-content-between align-items-center">
                        <div className={'headerlist__top_right flex-1'}>
                            {
                                dataSource.url
                                ?
                                <a href={dataSource.url} target={'_blank'} rel="noreferrer" >
                                    <Avatar className={`_avatar-seller-${this.props.index}`} shape="square" size={24} src={dataSource.marketplace.image} />
                                </a>
                                :
                                <Avatar className={`_avatar-seller-${this.props.index}`} shape="square" size={24} src={dataSource.marketplace.image} />
                            }
                            {
                                dataSource.url
                                    ?
                                    <span className={`pdl10 ${!!inValidMachine ? 'text-red-100' : ''}`}>
                                        {t('order.seller')}: <a href={dataSource.url} target={'_blank'} rel="noreferrer"><span className={`_cart-seller-${this.props.index} ${!!inValidMachine ? 'text-red-100' : 'txt-color-black'}`}>{dataSource.name || dataSource.code}</span></a>
                                    </span>
                                    :
                                    <span className={`pdl10 ${!!inValidMachine ? 'text-red-100' : ''}`}>
                                        {t('order.seller')}: <span className={`_cart-seller-${this.props.index} ${!!inValidMachine ? 'text-red-100' : 'txt-color-black'}`}>{dataSource.name || dataSource.code}</span>
                                    </span>
                            }
                        </div>
                        <div className={'headerlist__top_right txt-color-gray txt-right flex-1'}>
                            {t('relatedService.amount_sum')}: <span className={`_shop-total-amount-${this.props.index} txt-color-black`}>{moneyFormat(moneyCeil(draftOrder.exchangedTotalValue))}</span>
                        </div>
                    </Col>
                </Row>
                <Row className={'headerlist__title txt-color-black'}>
                    <Col span={16}>
                        <div className={'txt-color-gray'}>{t('order.quantity')}: <span className={`txt-color-black _cart-totalItems-${this.props.index}`}>
                                {totalQuanty} {t('order.products')} / {modifyArrayDataSource(dataSource).length} link <Tooltip placement="top" title={t('cartGroup.product_quantity')}>
                                    <AntdIcon.QuestionCircleOutlined type="question-circle" theme="filled" className={'txt-size-h8 txt-color-gray opacity6 mgl5'}/>
                                </Tooltip>
                            </span>
                        </div>
                    </Col>
                    <Col span={4}>
                        {t('order.total_price')}
                    </Col>
                    <Col span={4}>
                        {t('order.quantity')}
                    </Col>
                </Row>
            </div>
        )
    };

    /**
     * render item of list
     * @param item
     * @param index
     * @returns {*}
     */
    renderListItem = (item, index) => {
        const {
            cartLanguage, t
        } = this.props;
        item = this.showWarningNumber(item);
        return (
            <React.Fragment>
                {
                    item.warningMaxMessage &&
                    <List.Item className={`_cart-item-row-error-${index} list-alert-warning`}>
                        <Row>
                            <Col span={24} className={'txt-warning warning-row'}><AntdIcon.ExclamationCircleOutlined type="exclamation-circle" className={'pdr5'} /> {t('cartGroup.quantity_warning_message')}</Col>
                        </Row>
                    </List.Item>
                }
                <List.Item className={`listcards__item _cart-item-${index} position-re`}>
                    <Row gutter={10} className="width-100-percent">
                        <Col span={16} className="pdr10 dpl-flex align-items-start">
                            {
                                item.product.url ?
                                    <a href={item.product.url} target={'_blank'} rel="noreferrer">
                                        <Avatar
                                            className={`_cart-item-avatar-${index}`}
                                            shape="square"
                                            size={60}
                                            src={item.image}
                                        />
                                    </a>
                                    :
                                    <Avatar
                                        className={`_cart-item-avatar-${index}`}
                                        shape="square"
                                        size={60}
                                        src={item.image}
                                    />
                            }
                            <div className={'pdl10'}>
                                {
                                    item.product.url ?
                                    <a href={item.product.url} target={'_blank'} rel="noreferrer">
                                        <h3 className={`_cart-item-name-${index} txt-size-h7 txt-color-black break-word`}>{cartLanguage === 'CN' ? item.product.originalName : item.product.name}</h3>
                                    </a>
                                    :
                                    <h3 className={`_cart-item-name-${index} txt-size-h7 txt-color-black break-word`}>{cartLanguage === 'CN' ? item.product.originalName : item.product.name}</h3>
                                }
                                {item.variantProperties.map((x, y) => {
                                        // let maxIndex = item.variantProperties.lastIndexOf(item.variantProperties.slice(-1)[0]);
                                        return (
                                            <span key={y} className={`_cart-item-properties-${item.id}-${y} txt-size-h8 txt-color-gray ${y !== 0 ? 'pdl10' : ''}`}>{cartLanguage === 'CN' ? x.originalValue :  x.value}</span>
                                        )
                                    }
                                )}
                                <Row>
                                    <Col span={24} className={'mgt5'}>
                                        {
                                            item.remark &&
                                                <Fragment>
                                                    <span className="txt-color-black robotoregular txt-size-h8 bold" style={{display: 'inline'}}>{t('cartGroup.product_note')}:</span>
                                                    {
                                                        checkIsLink(item.remark)
                                                            ?
                                                            <div
                                                                className="txt-color-gray txt-size-h8 robotoregular white-space-pre-wrap pdl5 break-word mgr10"
                                                                style={{display: 'inline'}}
                                                                dangerouslySetInnerHTML={{__html: linkify(item.remark)}}
                                                            />
                                                            :
                                                            <div
                                                                className="txt-color-gray txt-size-h8 robotoregular white-space-pre-wrap pdl5 break-word mgr10"
                                                                style={{display: 'inline'}}
                                                            >{item.remark}</div>
                                                    }
                                                </Fragment>
                                        }

                                    </Col>

                                    <Col span={24}>
                                        {
                                            item.note
                                                &&
                                                <Fragment>
                                                    <span className="txt-color-black robotoregular txt-size-h8" style={{display: 'inline'}}>{t('cartGroup.product_personal_note')}:</span>
                                                    {
                                                        checkIsLink(item.note)
                                                            ?
                                                            <div
                                                                className="txt-color-gray txt-size-h8 robotoregular white-space-pre-wrap pdl5 break-word mgr5"
                                                                style={{display: 'inline'}}
                                                                dangerouslySetInnerHTML={{__html: linkify(item.note)}}
                                                            />
                                                            :
                                                            <div
                                                                className="txt-color-gray txt-size-h8 robotoregular white-space-pre-wrap pdl5 break-word mgr5"
                                                                style={{display: 'inline'}}
                                                            >{item.note}</div>
                                                    }
                                                </Fragment>
                                        }
                                    </Col>

                                </Row>
                            </div>
                        </Col>
                        <Col span={4}>
                            <h3 className={`_cart-item-totalPrice-${index} txt-size-h7 break-word pdr5`}>
                                {item.bargainPrice !== null ? (
                                    <>
                                        <strike>{moneyFormat(moneyCeil(item.exchangedSalePrice * item.quantity))}</strike> / <b className={'txt-color-black'}>{moneyFormat(item.exchangedBargainPrice * item.quantity)}</b>
                                    </>
                                ) : (
                                    <b className={'txt-color-black'}>{moneyFormat(moneyCeil(item.exchangedTotalAmount))}</b>
                                )}
                            </h3>
                            <span className={`_cart-item-totalPrice-origin-${index} txt-size-h8 break-word pdr5`}>
                                {item.bargainPrice !== null ? (
                                    <>
                                        <strike className={'txt-color-gray'}>{moneyFormat(item.salePrice * item.quantity, item.currency)}</strike> / <b className={'txt-color-black'}>{moneyFormat(item.bargainPrice * item.quantity, item.currency)}</b>
                                    </>
                                ) : (
                                    <b className={'text-color-gray'}>{moneyFormat(item.totalAmount, item.currency)}</b>
                                )}
                            </span>
                        </Col>
                        <Col span={4}>
                            <h3 className={`_cart-item-quantity-${index} txt-size-h7 txt-color-black`}>{quantityFormat(item.quantity)} {t('cartCheckout.product')}</h3>
                        </Col>
                    </Row>
                </List.Item>
            </React.Fragment>
        )
    };

    render() {
        const { initLoading, dataList, remark, note} = this.state;
        const {dataSource, t} = this.props;
        // console.log('dataList', dataSource);
        if (dataSource.crossedThreshold) {
            return <div className={'mgbt15'}>
                <Alert
                    message={<span>{t('message.thresholdText1')} <span className="bold">{dataSource.name || dataSource.code}</span> {t('message.thresholdText2')} ({t('message.thresholdText3')} <span className="bold">{moneyFormat(dataSource.marketplace.orderValueThreshold)}</span>)</span>} type="warning" />
            </div>
        }
        return (
            <div className={'mgbt25'}>
                <List
                    className={`_item-merchant-${this.props.index} list-carts`}
                    header={this.renderHeaderList()}
                    dataSource={dataList}
                    renderItem={(item, index) => this.renderListItem(item, index)}
                    loading={initLoading}
                />
                <Row className="boxservices bd1px bd-color-gray dpl-flex bg-color-white pd10">
                    <span className="boxservices__lbl txt-color-gray txt-size-h8 robotoregular">{t('cartGroup.service')}</span>
                    <div>
                    {
                        dataSource && Array.isArray(dataSource.services) && _.sortBy(dataSource.services, 'position').map(x => <span key={x.id} className={'boxservices__item position-re _item-merchant-service txt-size-h8 txt-color-black robotoregular pdl10'}>
                            {x.name}
                        </span>)
                    }
                    </div>
                </Row>

                <Row className="boxnotemerchant bd1px bd-color-gray bg-color-white pd10">
                    <Col md={24} className={'mgbt5'}>
                        {
                            remark &&
                            <Fragment>
                                <span className="txt-color-black robotoregular txt-size-h8">{t('relatedService.order_note')}:</span>
                                {
                                    checkIsLink(remark)
                                        ?
                                        <div
                                            className="txt-color-gray txt-size-h8 robotoregular mgr5 white-space-pre-wrap break-word pdl5"
                                            style={{display: 'inline'}}
                                            dangerouslySetInnerHTML={{__html: linkify(remark)}}
                                        />
                                        :
                                        <div
                                            className="txt-color-gray txt-size-h8 robotoregular mgr5 white-space-pre-wrap break-word pdl5"
                                            style={{display: 'inline'}}
                                        >{remark}</div>
                                }
                            </Fragment>
                        }
                    </Col>
                    <Col span={24}>
                        {
                            note
                                &&
                                <Fragment>
                                    <span className="txt-color-black robotoregular txt-size-h8">{t('relatedService.order_personal_note')}:</span>
                                    {
                                        checkIsLink(note)
                                            ?
                                            <div
                                                className="txt-color-gray txt-size-h8 robotoregular mgr5 white-space-pre-wrap pdl5 break-word mgr5"
                                                style={{display: 'inline'}}
                                                dangerouslySetInnerHTML={{__html: linkify(note)}}
                                            />
                                            :
                                            <div
                                                className="txt-color-gray txt-size-h8 robotoregular mgr5 white-space-pre-wrap pdl5 break-word mgr5"
                                                style={{display: 'inline'}}
                                            >{note}</div>
                                    }

                                </Fragment>
                        }
                    </Col>


                </Row>
                {
                    dataList.length === 0 &&
                    <Empty
                        className={'mgbt25'}
                        image={require('../../resources/images/empty-image.png')}
                        description={t('message.empty')}
                    />
                }

            </div>
        )
    }
}
