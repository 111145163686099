import React, { Fragment } from 'react'
import { Alert, Avatar, Button, Col, Divider, Input, Modal, notification, Popconfirm, Row, Tooltip } from 'antd'
import * as AntdIcon from '@ant-design/icons'

import _, { first } from 'lodash'

import { Link } from 'react-router-dom'
import { checkIsLink, linkify, moneyCeil, moneyFormat, quantityFormat } from '../../utils/stringUtils'
import UserService from '../../services/user.service'
import { LocalStore } from '../../utils/LocalStore'
import OrderService from '../../services/orders.service'
import ModalPaymentBifin from '../../containers/Orders/components/ModalPaymentBifin'

const { TextArea } = Input

class OrderInfo extends React.Component {
    constructor(props) {
        super(props)
        this.isUnmounted = false
        this.state = {
            isExpand: true,
            remark: '',
            note: '',
            isLoadLevel: true,
            isEnabledBiffin: false,
        }
    }

    componentDidMount() {
        const { data } = this.props
        this.setState({
            currentData: _.cloneDeep(data),
            remark: data && data.remark,
            note: data && data.note,
        })
        this.getCustomerLevel()
        this.checkEnableBiffin()
    }

    checkEnableBiffin() {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const shopkeeperEnabled = currentProjectInfo?.tenantConfig?.externalIntegrationConfig?.shopkeeper?.enabled
        const bifinEnabledOrder = currentProjectInfo?.tenantConfig?.externalIntegrationConfig?.shopkeeper?.enabledOrder
        if (shopkeeperEnabled && bifinEnabledOrder) {
            this.setState({
                isEnabledBiffin: true,
            })
        }
    }

    componentWillUnmount() {
        this.isUnmounted = true
    }

    /**
     * lấy ra danh sách trạng thái đơn
     */
    getCustomerLevel = () => {
        this.setState({ isLoadLevel: true }, () => {
            UserService.getCustomerLevels((err, res) => {
                if (!err) {
                    this.setState({
                        customerLevels: res,
                        isLoadLevel: false,
                    })
                } else {
                    this.setState({
                        isLoadLevel: false,
                    })
                }
            })
        })
    }

    /**
     * thêm sản phẩm vào giỏ hàng từ đơn
     */
    addProductFromOrder = () => {
        const { data = {}, t } = this.props
        const { isAddProduct } = this.state
        if (isAddProduct) {
            return
        }
        this.setState({ isAddProduct: true }, () => {
            OrderService.addProductFromOrder(data.code, (err) => {
                if (!err) {
                    notification['success']({
                        message: t('order.successfully_handle'),
                    })
                    this.setState(
                        {
                            isAddProduct: false,
                        },
                        () => {
                            this.props.history.push({
                                pathname: '/carts',
                            })
                        }
                    )
                } else {
                    this.setState({
                        isAddProduct: false,
                    })
                    notification['error']({
                        message: t(err.message),
                    })
                }
            })
        })
    }

    componentDidUpdate(prevProps) {
        const { data } = this.props
        if (prevProps.data && data && prevProps.data.remark !== data.remark) {
            if (!this.isUnmounted)
                this.setState({
                    currentData: _.cloneDeep(data),
                    remark: data && data.remark,
                })
        }
        if (prevProps.data && data && prevProps.data.note !== data.note) {
            if (!this.isUnmounted)
                this.setState({
                    currentData: _.cloneDeep(data),
                    note: data && data.note,
                })
        }
    }

    onToggle = () => {
        this.setState({
            isExpand: !this.state.isExpand,
        })
    }

    /**
     * Change value input
     * @param value
     * @param prop
     */
    onChangeInput = (value, prop) => {
        if (value !== '' && value.trim() === '') return
        this.setState({ [prop]: value })
    }

    /**
     * Call func submit note order if click outside
     * @param e
     * @param prop
     * @return {*}
     */
    onBlurInput = (e, prop) => {
        let { currentData, remark, note } = this.state
        if (e.target.value !== '' && e.target.value.trim() === '') return
        if (currentData.remark !== remark && remark !== '') {
            this.props.updateCustomerOrder(currentData, remark, prop, () => {
                if (prop === 'remark') {
                    this.setState({
                        ['showRemark' + currentData.id]: false,
                    })
                }
            })
        } else if (currentData.note !== note) {
            this.props.updateCustomerOrder(currentData, note, prop, () => {
                this.setState({
                    ['showNote' + currentData.id]: false,
                    ['showNoteDetail' + currentData.id]: false,
                })
            })
        } else {
            if (prop === 'remark') {
                if (remark === '') {
                    this.setState({ remark: currentData.remark })
                }
                this.setState({ ['showRemark' + currentData.id]: false })
            } else if (prop === 'note') {
                if (note === '') {
                    this.setState({ note: currentData.note })
                }
                this.setState({ ['showNote' + currentData.id]: false })
                if (e.target.value === '') {
                    this.setState({ ['showNoteDetail' + currentData.id]: false })
                }
            }
        }
    }

    /**
     * Call func submit note order if Enter
     * @param e
     * @param prop
     * @return {*}
     */
    onKeyDown = (e, prop) => {
        let { currentData, remark, note } = this.state
        if (e.target.value !== '' && e.target.value.trim() === '') return

        if ((e.metaKey || e.shiftKey || e.altKey || e.ctrlKey) && e.keyCode === 13) {
            if (currentData.remark !== remark && remark !== '') {
                this.props.updateCustomerOrder(currentData, remark, prop, () => {
                    if (prop === 'remark') {
                        this.setState({
                            ['showRemark' + currentData.id]: false,
                        })
                    }
                })
            } else if (currentData.note !== note) {
                this.props.updateCustomerOrder(currentData, note, prop, () => {
                    this.setState({
                        ['showNote' + currentData.id]: false,
                        ['showNoteDetail' + currentData.id]: false,
                    })
                })
            } else {
                if (prop === 'remark') {
                    if (remark === '') {
                        this.setState({ remark: currentData.remark })
                    }
                    this.setState({ ['showRemark' + currentData.id]: false })
                } else if (prop === 'note') {
                    if (note === '') {
                        this.setState({ note: currentData.note })
                    }
                    this.setState({ ['showNote' + currentData.id]: false })
                    if (e.target.value === '') {
                        this.setState({ ['showNoteDetail' + currentData.id]: false })
                    }
                }
            }
        }
    }

    confirmReceivedOrder = () => {
        this.props.confirmReceivedOrder()
    }

    renderLoansStatus = () => {
        const { data, t } = this.props
        if (data.status === 'CANCELLED') {
            return t('order.loansStatusCancelled')
        }
        if (data.status === 'PENDING') {
            return t('order.loansStatusPending')
        } else {
            return t('order.loansStatusApprove')
        }
    }

    render() {
        const { isExpand, remark, note, isEnabledBiffin, customerLevels = [], isLoadLevel } = this.state
        const { data, listStatuses = [], confirmCancelOrder, t, loanCredits } = this.props
        let itemStatus = {}
        let customerlevel = t('orderDetail.no_level_yet')
        if (data) {
            itemStatus = listStatuses.find(x => x.code === data.status) || {}
            const currentCustomerLevel = customerLevels.find(x => x.id === data.customerLevelId)
            if (data.customerGroup && data.customerGroup.code !== 'default') {
                customerlevel = data.customerGroup.name
            } else if (currentCustomerLevel) {
                customerlevel = currentCustomerLevel.name
            }
        }
        const href = `/tickets/create?orderCode=${data && data.code}`

        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const tenantConfig = currentProjectInfo.tenantConfig || {}
        const generalConfig = tenantConfig.generalConfig || {}

        let totalNeedPay = data?.totalUnpaid
        if (loanCredits.length > 0)
            totalNeedPay += first(loanCredits)?.status === 'ACTIVE' ? first(loanCredits)?.totalAmountPay : 0
        const totalNeedPayFormat = moneyFormat(moneyCeil(totalNeedPay), null, true)

        return (
            <div className={'orderdetail__top mgbt20'}>
                <div className="pdt10 pdbt10 dpl-flex justify-content-between align-items-center">
                    <div className="dpl-flex">
                        <Link
                            className={'_orders'}
                            to={'/orders'}
                        >
                            <AntdIcon.ArrowLeftOutlined type="arrow-left" />
                        </Link>
                        <Link
                            className={'_orders'}
                            to={'/orders'}
                        >
                            <div className={'cursor-pointer pdl10 txt-size-h8 robotoregular'}>{t('orderDetail.order_list')}</div>
                        </Link>
                    </div>

                    {generalConfig.orderProductToCartEnable && (
                        <div>
                            <span
                                key={'btn-addproduct'}
                                onClick={this.addProductFromOrder}
                                className="_btn-export-csv ant-btn ant-btn-primary ant-btn-background-ghost cursor-pointer position-re pdr30 pdl30"
                            >
                                {t('orderDetail.re_order')}
                            </span>
                        </div>
                    )}
                </div>

                {data && data.deliveryNotice && (
                    <Alert
                        className="mgbt20"
                        message={<span className="bold txt-size-h6">{t('order.notification')}</span>}
                        description={
                            <span className="txt-size-h7">
                                {t('order_detail.deliveryNotice1')}{' '}
                                <Link to={'/delivery/create'}>{t('order_detail.deliveryNotice2')}</Link>{' '}
                                {t('order_detail.deliveryNotice3')}
                            </span>
                        }
                        type="success"
                        showIcon
                    />
                )}

                <div className={'bg-color-white border-top-3x bd-color-green pd15'}>
                    <Row className="dpl-flex width-100-percent align-items-center">
                        <Col span={18}>
                            <div className="dpl-flex flex-wrap align-items-center">
                                <div className={'pdr10'}>
                                    <div className={'orderdetail__top_name dpl-flex align-items-center'}>
                                        <Avatar
                                            className={`_order-avatar mgr5`}
                                            shape="square"
                                            size={44}
                                            src={data && data.image}
                                        />
                                        <div>
                                            <div
                                                className={'txt-size-h6 robotomedium txt-color-black wordbreakall line-height134'}
                                            >
                                                #{data && data.code}
                                            </div>
                                            <div>
                                                <span
                                                    style={{ backgroundColor: itemStatus.color || '#898989' }}
                                                    className={'listorders__items_right--status dpl-il-block mgt5'}
                                                >
                                                    {itemStatus.name}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'pdl10 pdr10 '}>
                                    <div className={'orderdetail__top_totalprice dpl-flex align-items-center'}>
                                        <span className={'txt-size-h8 txt-color-gray robotoregular mgr5'}>
                                            {t('order_detail.fee_total')}
                                        </span>
                                        <span className={'txt-size-h4 robotobold txt-color-black'}>
                                            {data && moneyFormat(moneyCeil(data.grandTotal))}
                                        </span>
                                        {/*<span className={"txt-size-h4 robotomedium txt-color-black pdt5"}>---</span>*/}
                                    </div>
                                    <div
                                        className={
                                            'orderdetail__top_seller w-[280px] text-ellipsis overflow-hidden whitespace-nowrap'
                                        }
                                    >
                                        <Avatar
                                            className={``}
                                            shape="square"
                                            size={14}
                                            src={data && data.marketplace && data.marketplace.image}
                                        />
                                        <span className={'txt-size-h8 txt-color-gray robotoregular mgr5'}>
                                            {t('order.seller')}:{' '}
                                        </span>
                                        <span className={'txt-size-h8 robotomedium txt-color-black wordbreakall line-height134'}>
                                            {data ? data.merchantName || data.merchantCode : '---'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className={'txt-right'}>
                                <Link
                                    to={href}
                                    className="_btn-create-claim-order"
                                >
                                    {t('orderDetail.complaint_order')}
                                </Link>

                                {itemStatus.confirmable && data.totalUnpaid === 0 && (
                                    <Button
                                        type="primary"
                                        ghost
                                        className={`_btn-received txt-uppercase pdl25 pdr25 mgl10`}
                                        onClick={() => {
                                            Modal.confirm({
                                                maskClosable: true,
                                                icon: null,
                                                title: t('orderDetail.confirm_question'),
                                                okText: t('button.agree'),
                                                cancelText: t('button.disagree'),
                                                onOk: () => this.confirmReceivedOrder(),
                                            })
                                        }}
                                    >
                                        {t('orderDetail.received')}
                                    </Button>
                                )}

                                {itemStatus.cancellable && (
                                    <Popconfirm
                                        overlayClassName={`_btn-cancel-order-overlay`}
                                        title={t('orderDetail.confirm_question')}
                                        onConfirm={confirmCancelOrder}
                                        // onCancel={() => console.log('ggwp')}
                                        okText={t('button.yes')}
                                        cancelText={t('button.no')}
                                    >
                                        <Button
                                            type="primary"
                                            ghost
                                            className="_btn-cancel-order txt-uppercase pdl25 pdr25 mgl10"
                                        >
                                            {t('orderDetail.cancel_order')}
                                        </Button>
                                    </Popconfirm>
                                )}
                            </div>
                        </Col>
                    </Row>
                    {isExpand && (
                        <Fragment>
                            <Divider />
                            <Row
                                className={'mt-5 mgbt10'}
                                gutter={10}
                            >
                                <Col span={5}>
                                    <h3 className={'txt-size-h8 txt-color-gray robotoregular _order-detail-member'}>
                                        {t('orderDetail.member')}
                                    </h3>
                                    <span className={'txt-size-h7 txt-color-black robotomedium _order-detail-member-value'}>
                                        {isLoadLevel ? '' : customerlevel}
                                    </span>
                                </Col>
                                <Col span={5}>
                                    <h3 className={'txt-size-h8 txt-color-gray robotoregular _order-detail-deposit'}>
                                        {t('orderDetail.deposit_rate')}
                                    </h3>
                                    <span className={'txt-size-h7 txt-color-black robotomedium _order-detail-deposit-value'}>
                                        {data ? data.emdPercent : '50'}%
                                    </span>
                                </Col>
                                <Col span={5}>
                                    <h3 className={'txt-size-h8 txt-color-gray robotoregular _order-detail-heavy'}>
                                        {t('orderDetail.costing_weight')}
                                    </h3>
                                    <span className={'txt-size-h7 txt-color-black robotomedium _order-detail-heavy-value'}>
                                        {data && data.actualWeight
                                            ? `${quantityFormat(data.actualWeight)}kg`
                                            : t('orderDetail.undefined')}
                                    </span>
                                </Col>
                                <Col span={5}>
                                    <h3 className={'txt-size-h8 txt-color-gray robotoregular _order-detail-heavy'}>
                                        {t('orderDetail.net_weight')}
                                    </h3>
                                    <span className={'txt-size-h7 txt-color-black robotomedium _order-detail-heavy-value'}>
                                        {data && data.netWeight
                                            ? `${quantityFormat(data.netWeight)}kg`
                                            : t('orderDetail.undefined')}
                                    </span>
                                </Col>
                                <Col span={4}>
                                    <h3 className={'txt-size-h8 txt-color-gray robotoregular _order-detail-heavy'}>
                                        {t('orderDetail.packaging_weight')}
                                    </h3>
                                    <span className={'txt-size-h7 txt-color-black robotomedium _order-detail-heavy-value'}>
                                        {data && data.packagingWeight
                                            ? `${quantityFormat(data.packagingWeight)}kg`
                                            : t('orderDetail.undefined')}
                                    </span>
                                </Col>
                            </Row>
                            <Row
                                className={'mt-5'}
                                gutter={10}
                            >
                                <Col span={5}>
                                    <h3 className={'txt-size-h8 txt-color-gray robotoregular _order-detail-heavy'}>
                                        {t('orderDetail.dimensional_weight')}
                                    </h3>
                                    <span className={'txt-size-h7 txt-color-black robotomedium _order-detail-heavy-value'}>
                                        {data && data.dimensionalWeight
                                            ? `${quantityFormat(data.dimensionalWeight)}kg`
                                            : t('orderDetail.undefined')}
                                    </span>
                                </Col>
                                <Col span={5}>
                                    <h3 className={'txt-size-h8 txt-color-gray robotoregular _order-detail-volume'}>
                                        {t('orderDetail.volume')}
                                    </h3>
                                    <span className={'txt-size-h7 txt-color-black robotomedium _order-detail-volume-value'}>
                                        {data && data.volumetric
                                            ? `${quantityFormat(data.volumetric)}cm3`
                                            : t('orderDetail.undefined')}
                                    </span>
                                </Col>
                                <Col span={5}>
                                    <h3 className={'txt-size-h8 txt-color-gray robotoregular _order-detail-exchange'}>
                                        {t('header.exchange')}
                                    </h3>
                                    {data && data.marketplace ? (
                                        <span className={'txt-size-h7 txt-color-black robotomedium _order-detail-exchange-value'}>
                                            {moneyFormat(1, data.marketplace.currency)} ={' '}
                                            {moneyFormat(data.exchangeRate, tenantConfig.defaultCurrency)}
                                        </span>
                                    ) : (
                                        <span className={'txt-size-h7 txt-color-black robotomedium _order-detail-exchange-value'}>
                                            ---
                                        </span>
                                    )}
                                </Col>
                                <Col span={5}>
                                    <h3 className={'txt-size-h8 txt-color-gray robotoregular _order-detail-volume'}>
                                        {t('order.discountAmount')}
                                    </h3>
                                    <span className={'txt-size-h7 txt-color-black robotomedium _order-detail-volume-value'}>
                                        {data && data.discountAmount
                                            ? `${moneyFormat(data.exchangedDiscountAmount)}(${moneyFormat(
                                                  data.discountAmount,
                                                  'CNY'
                                              )})`
                                            : '---'}
                                    </span>
                                </Col>
                                {isEnabledBiffin && data && data.contractWithShopkeeper && (
                                    <Col span={4}>
                                        <h3 className={'txt-size-h8 txt-color-gray robotoregular _order-detail-volume'}>
                                            {t('orderInfo.contractWithShopkeeper')}
                                        </h3>
                                        <span className={'txt-size-h7 txt-color-black robotomedium _order-detail-volume-value'}>
                                            {this.renderLoansStatus()}
                                        </span>
                                    </Col>
                                )}
                            </Row>
                            {totalNeedPay > 0 && (
                                <>
                                    {data.contractWithShopkeeper && (
                                        <>
                                            <Divider />
                                            <Row>
                                                <Col span={24}>
                                                    <div className="flex flex-col gap-3 v2-layout">
                                                        <div>
                                                            <span
                                                                className={'_note txt-color-gray txt-size-h7 robotoregular mr-1'}
                                                            >
                                                                {t('order_pay.bifin')}:
                                                            </span>
                                                            <span className="txt-size-h7 robotomedium mr-3 text-blue-primary">
                                                                {moneyFormat(
                                                                    moneyCeil(
                                                                        loanCredits[0]?.status !== 'ACTIVE'
                                                                            ? 0
                                                                            : loanCredits[0]?.totalAmountPay || 0
                                                                    )
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                    <Divider />
                                    <Row>
                                        <Col span={24}>
                                            <div className="flex flex-col gap-3 v2-layout">
                                                <div>
                                                    <span className={'_note txt-color-gray txt-size-h7 robotoregular mr-1'}>
                                                        {t('order_pay.amount_need_pay')}:
                                                    </span>
                                                    <span className="txt-size-h7 robotomedium mr-3 text-blue-primary">
                                                        {totalNeedPayFormat}
                                                    </span>
                                                    {(data.status === 'READY_FOR_DELIVERY' || data.status === 'DELIVERING') && (
                                                        <ModalPaymentBifin
                                                            t={t}
                                                            contractWithShopkeeper={data.contractWithShopkeeper}
                                                            code={data?.code}
                                                            refetchData={() => this.props.getOrderDetail(false)}
                                                            totalNeedPay={totalNeedPayFormat}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            )}

                            <Divider />
                            <Row>
                                <Col span={24}>
                                    <span className="txt-size-h8 txt-color-gray robotoregular">{t('orderDetail.service')}: </span>
                                    {data && data.services && Array.isArray(data.services) && data.services.length > 0
                                        ? data.services.map((x, y) => {
                                              let indexMax = data.services.length - 1
                                              return (
                                                  <Fragment key={y}>
                                                      <span
                                                          className={`${
                                                              x.approved === null ? 'txt-color-yellow' : 'txt-color-black'
                                                          } txt-size-h8 robotoregular ${
                                                              x.approved === false ? 'line-through' : ''
                                                          }`}
                                                      >
                                                          {x.name}
                                                      </span>
                                                      <span className="pdr5">{`${y === indexMax ? '' : ','}`}</span>
                                                  </Fragment>
                                              )
                                          })
                                        : '---'}
                                </Col>
                            </Row>
                            <Divider />
                            <Row>
                                <Col span={24}>
                                    <span className="txt-size-h8 txt-color-gray robotoregular">
                                        {data?.receiptAddress ? t('orderDetail.delivery_receiptAddress') : t('orderDetail.delivery_address')}:{' '}
                                    </span>
                                    <span className="txt-color-black txt-size-h8 robotoregular">
                                        {data &&
                                            data.address.fullname +
                                                ' / ' +
                                                data.address.phone +
                                                ' / ' +
                                                data.address.detail +
                                                ', ' +
                                                data.address.location.display}
                                    </span>
                                </Col>
                            </Row>
                            <Divider />
                            {data?.receiptAddress && (
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <span className="txt-size-h8 txt-color-gray robotoregular">
                                                {t('orderDetail.delivery_address')}:{' '}
                                            </span>
                                            <span className="txt-color-black txt-size-h8 robotoregular">
                                                {data.receiptAddress.fullname +
                                                    ' / ' +
                                                    data.receiptAddress.phone +
                                                    ' / ' +
                                                    data.receiptAddress.detail +
                                                    ', ' +
                                                    data.receiptAddress.location.display}
                                            </span>
                                        </Col>
                                    </Row>
                                    <Divider />
                                </>
                            )}
                            <Row>
                                {remark && (
                                    <Col span={24}>
                                        <span
                                            className="txt-color-gray robotoregular txt-size-h8"
                                            style={{ display: 'inline' }}
                                        >
                                            {t('orderDetail.note_order')}:
                                        </span>
                                        {checkIsLink(remark) ? (
                                            <div
                                                className="txt-color-black txt-size-h8 robotoregular white-space-pre-wrap pdl5 break-word mgr10"
                                                style={{ display: 'inline' }}
                                                dangerouslySetInnerHTML={{ __html: linkify(remark) }}
                                            />
                                        ) : (
                                            <div
                                                className="txt-color-black txt-size-h8 robotoregular white-space-pre-wrap pdl5 break-word mgr10"
                                                style={{ display: 'inline' }}
                                            >
                                                {remark}
                                            </div>
                                        )}
                                    </Col>
                                )}
                                <Col span={24}>
                                    {this.state['showNoteDetail' + (data && data.id)] ? (
                                        <Fragment>
                                            <TextArea
                                                value={note}
                                                maxLength={1000}
                                                autosize={{ minRows: 1, maxRows: 3 }}
                                                className={`_orderDetail-note mgt10`}
                                                placeholder={t('order.note')}
                                                onChange={e => this.onChangeInput(e.target.value, 'note')}
                                                onKeyDown={e => this.onKeyDown(e, 'note')}
                                                onBlur={e => this.onBlurInput(e, 'note')}
                                                // disabled={isLoadingDisable}
                                                ref={ref => (this[`order${data.id}`] = ref)}
                                            />
                                            {/*{note === '' && (*/}
                                            {/*    <span className={`txt-size-h8 txt-warning dpl-block pdt5`}>*/}
                                            {/*    {t('message.warning_empty')}*/}
                                            {/*</span>*/}
                                            {/*)}*/}
                                            <span className={`txt-size-h8 txt-color-gray dpl-block`}>
                                                {t('order.note_keydown')}
                                            </span>
                                        </Fragment>
                                    ) : this.state['showNote' + (data && data.id)] === true ? (
                                        <Fragment>
                                            <TextArea
                                                value={note}
                                                maxLength={1000}
                                                autosize={{ minRows: 1, maxRows: 3 }}
                                                className={`_cart-item-note mgt10`}
                                                placeholder={t('order.note')}
                                                onChange={e => this.onChangeInput(e.target.value, 'note')}
                                                onKeyDown={e => this.onKeyDown(e, 'note')}
                                                onBlur={e => this.onBlurInput(e, 'note')}
                                                // disabled={isLoadingDisable}
                                                ref={ref => (this[`order${data.id}`] = ref)}
                                            />
                                            {/*{note === '' && (*/}
                                            {/*    <span className={`txt-size-h8 txt-warning dpl-block pdt5`}>*/}
                                            {/*        {t('message.warning_empty')}*/}
                                            {/*    </span>*/}
                                            {/*        )}*/}
                                            <span className={`txt-size-h8 txt-color-gray dpl-block`}>
                                                {t('order.note_keydown')}
                                            </span>
                                        </Fragment>
                                    ) : note ? (
                                        <Fragment>
                                            <span className="txt-color-gray robotoregular txt-size-h8">
                                                {t('relatedService.order_personal_note')}:
                                            </span>
                                            {checkIsLink(note) ? (
                                                <div
                                                    className="txt-color-black txt-size-h8 robotoregular mgr5 white-space-pre-wrap pdl5 break-word mgr5"
                                                    style={{ display: 'inline' }}
                                                    dangerouslySetInnerHTML={{ __html: linkify(note) }}
                                                />
                                            ) : (
                                                <div
                                                    className="txt-color-black txt-size-h8 robotoregular mgr5 white-space-pre-wrap pdl5 break-word mgr5"
                                                    style={{ display: 'inline' }}
                                                >
                                                    {note}
                                                </div>
                                            )}
                                            <AntdIcon.EditOutlined
                                                className="_btn-edit-note-order cursor-pointer txt-size-h7"
                                                type="edit"
                                                onClick={() => {
                                                    this.setState(
                                                        {
                                                            ['showNote' + (data && data.id)]:
                                                                !this.state['showNote' + (data && data.id)],
                                                        },
                                                        () => {
                                                            this[`order${data.id}`].focus()
                                                        }
                                                    )
                                                }}
                                            />
                                        </Fragment>
                                    ) : (
                                        <span
                                            className={`_cart-item-showUserNote-btn cursor-pointer txt-size-h8 robotoregular`}
                                            onClick={() => {
                                                this.setState(
                                                    {
                                                        ['showNoteDetail' + (data && data.id)]:
                                                            !this.state['showNoteDetail' + (data && data.id)],
                                                    },
                                                    () => {
                                                        this[`order${data.id}`].focus()
                                                    }
                                                )
                                            }}
                                        >
                                            <span className={'mgr5'}>{t('relatedService.personal_note_title')}</span>
                                            <Tooltip
                                                placement="top"
                                                title={t('relatedService.personal_note_content')}
                                            >
                                                <AntdIcon.QuestionCircleOutlined
                                                    type="question-circle"
                                                    theme="filled"
                                                    className={'txt-size-h8 opacity6'}
                                                />
                                            </Tooltip>
                                        </span>
                                    )}
                                </Col>
                            </Row>
                        </Fragment>
                    )}
                </div>

                <div className={'pt-2 pl-2 pr-2 pb-2 text-center pdbt20 showmore'}>
                    <span
                        className="_a-open open-states cursor-pointer"
                        onClick={this.onToggle}
                    >
                        {!isExpand ? (
                            <span>
                                <AntdIcon.ArrowsAltOutlined
                                    type="arrows-alt"
                                    className={'mgr15'}
                                />
                                {t('button.loadmore')}
                            </span>
                        ) : (
                            <span>
                                <AntdIcon.ShrinkOutlined
                                    type="shrink"
                                    className={'mgr15'}
                                />
                                {t('button.collapse')}
                            </span>
                        )}
                    </span>
                </div>
            </div>
        )
    }
}

export default OrderInfo
