import React from 'react';
import {Timeline, Spin, Empty} from 'antd';
import * as AntdIcon from "@ant-design/icons"
import moment from 'moment';
import UserService from "../../services/user.service";

class TabHistory extends React.Component{
    constructor(props) {
        super(props);
        this.state={

        }
    }
    componentDidMount() {
        this.getListMilestones();
    }
    /**
     * Lấy danh sách lịch sử các mốc đặt hàng trong đơn
     */
    getListMilestones = () => {
        const {dataDetail = {}} = this.props;
        this.setState({
            isLoadingListMilestones: true
        }, () => {
            UserService.getHistoryPeerPayment(dataDetail.code, (err, res) => {
                if (!err) {
                    this.setState({
                        listMilestones: res
                    })
                }
                this.setState({isLoadingListMilestones: false});
            });
        })
    };
    /**
     * render Timeline History
     */
    render(){
        const { t, listStatuses = []} = this.props;
        const {listMilestones, isLoadingListMilestones} = this.state;

        if (isLoadingListMilestones === true) {
            return <div className="mgt25 mgbt25 txt-center">
                <Spin spinning={isLoadingListMilestones === true} indicator={<AntdIcon.Loading3QuartersOutlined type="loading" style={{ fontSize: 24 }} spin />} />
            </div>
        }

        return(
            <Timeline mode="alternate">
                {
                    Array.isArray(listMilestones) &&listMilestones.length>0 ? listMilestones.map((item, index) => {
                        const status = listStatuses.find(x => x.code === item.status);
                        // let day = '';
                        // if (item.handlingTime > 1) {
                        //     day = t('label.days');
                        // } else {
                        //     day = t('label.day');
                        // }
                        return (
                            <Timeline.Item
                                key={index}
                                color={index === 0 ? 'red' : 'green'}
                                dot={
                                    index === 0 ? <AntdIcon.ClockCircleOutlined type="clock-circle-o" style={{ fontSize: '24px'}} className={'ic__clockcircle'}/> : ''
                                }>
                                <span className='txt-color-gray txt-size-h7 robotoregular pdr5'>{status ? status.name : '---'}:</span>
                                <span className='txt-color-black txt-size-h7 robotobold pdr5'>{moment(item.timestamp).format('HH:mm DD/MM')}</span>
                                {/*<span className='txt-color-black txt-size-h7 robotobold'>{item.handlingTime === null ? '(' + t('orderDetail.undefined') + ')'  : '(' + item.handlingTime + ' ' + day + ')'}</span>*/}
                            </Timeline.Item>
                        )
                    }):
                        <Empty
                            className={'mgbt25 mgt15'}
                            image={require('../../resources/images/empty-image.png')}
                            description={t('message.empty')}
                        />
                }

            </Timeline>
        );
    }
}
export default TabHistory;
