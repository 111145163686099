/**
 * create by Taobao dev team 19/1/2018
 */
import React, {Fragment} from 'react';
import OrderServiceGroup from './OrderServiceGroup'
import {
    Row, Col, Divider,
    Input, Button, Tooltip, Modal, Typography
} from 'antd';
import * as AntdIcon from "@ant-design/icons"

import _ from "lodash";
import {checkIsLink, linkify, moneyCeil, moneyFormat, quantityFormat} from "../../utils/stringUtils";
import OrderServices from '../../services/orders.service';
import {LocalStore} from "../../utils/LocalStore";

const { TextArea } = Input;

export default class RelatedService extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            targetCurrency: 'VND',
            remark: '',
            note: '',
            listMerchantFee: []
        }
    }

    componentDidMount() {
        const {dataSource} = this.props;
        this.setState({
            currentData: _.cloneDeep(dataSource),
            remark: dataSource.remark,
            note: dataSource.note
        });
    }

    componentDidUpdate (prevProps) {
        const {dataSource} = this.props;
        if (prevProps.dataSource.remark !== dataSource.remark) {
            this.setState({
                currentData: _.cloneDeep(dataSource),
                remark: dataSource.remark
            });
        }
        if (prevProps.dataSource.note !== dataSource.note) {
            this.setState({
                currentData: _.cloneDeep(dataSource),
                note: dataSource.note,
            });
        }
    }

    /**
     * lấy ra danh sách phí dịch vụ của merchant
     */
    getMerchantFees = (id) => {
        OrderServices.getMerchantFees(id, (err, res) => {
            if (!err) {
                this.setState({
                    listMerchantFee: res,
                });
            }
        })
    };

    /**
     * Change value input
     * @param value
     * @param prop
     * @return {*}
     */
    onChangeInput = (value, prop) => {
        if (value !== '' && value.trim() === '') return;
        this.setState({[prop]: value});
    };

    /**
     * Call func submit note order if click outside
     * @param e
     * @param prop
     * @param item
     * @return {*}
     */
    onBlurInput = (e, prop, item) => {
        let {currentData, remark, note} = this.state;
        let {dataSource} = this.props;
        if (e.target.value !== '' && e.target.value.trim() === '') return;
        if (currentData.remark !== remark) {
            this.props.updateNoteOrder(currentData.id, remark, prop, () => {
                if (prop === 'remark') {
                    this.setState({
                        ['showRemarkOrder'+dataSource.id]: false
                    })
                }
            });
        } else if (currentData.note !== note) {
            this.props.updateNoteOrder(currentData.id, note, prop, () => {
                this.setState({
                    ['showNote'+item.id]: false,
                    ['showNoteOrder'+dataSource.id]: false
                })
            });
        } else {
            if (prop === 'remark') {
                this.setState({['showRemarkOrder'+dataSource.id]: false});
            } else if (prop === 'note') {
                this.setState({['showNoteOrder'+dataSource.id]: false});
                if (e.target.value === '') {
                    this.setState({['showNote'+item.id]: false})
                }
            }
        }
    };

    /**
     * Call func submit note order if Enter
     * @param e
     * @param prop
     * @param item
     * @return {*}
     */
    onKeyDown = (e, prop, item) => {
        let {currentData, remark, note} = this.state;
        let {dataSource} = this.props;
        if (e.target.value !== '' && e.target.value.trim() === '') return;

        if ((e.metaKey || e.shiftKey || e.altKey || e.ctrlKey) && e.keyCode === 13) {
            if (currentData.remark !== remark) {
                this.props.updateNoteOrder(currentData.id, remark, prop, () => {
                    if (prop === 'remark') {
                        this.setState({
                            ['showRemarkOrder'+dataSource.id]: false
                        })
                    }
                });
            } else if (currentData.note !== note) {
                this.props.updateNoteOrder(currentData.id, note, prop, () => {
                    this.setState({
                        ['showNote'+item.id]: false,
                        ['showNoteOrder'+dataSource.id]: false
                    })
                });
            } else {
                if (prop === 'remark') {
                    this.setState({['showRemarkOrder'+dataSource.id]: false});
                } else if (prop === 'note') {
                    this.setState({['showNoteOrder'+dataSource.id]: false});
                    if (e.target.value === '') {
                        this.setState({['showNote'+item.id]: false})
                    }
                }
            }
        }
    };

    render() {
        const {remark, note, currentData, listMerchantFee} = this.state;
        const {
            t, validateOrder, orderServiceGroups, orderServices, dataSource,
            onCheckServiceGroup, isLoadingDisable, validateServices, isBanned, showNotiMaxSkus
        } = this.props;
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        let suspensionSchedule = currentProjectInfo.suspensionSchedule;
        let disabled = suspensionSchedule && Array.isArray(suspensionSchedule.appliedFor) && !!suspensionSchedule.appliedFor.find(x => x === "ALL" || x === "ORDER")

        let currentRemark = currentData && currentData.remark;
        const tenantConfig = currentProjectInfo.tenantConfig || {};
        const maxSkus = tenantConfig.maxSkuByMerchant;
        const disableCustomerOrderNote = currentProjectInfo.tenantConfig && currentProjectInfo.tenantConfig.generalConfig && currentProjectInfo.tenantConfig.generalConfig.disableCustomerOrderNote;

        return (
            <div className={'order-relate sidebar mgbt25'}>
                <OrderServiceGroup
                    t={t}
                    ref={ref => this.orderServiceGroup = ref}
                    onCheckServiceGroup={onCheckServiceGroup}
                    orderServiceGroups={orderServiceGroups}
                    orderServices={orderServices}
                    dataSource={dataSource}
                    validateServices={validateServices}
                />

                <div className={'_order-financial-info sidebar__financialinfo'}>
                    <div className="sidebar__financialinfo_box ">
                        <div className={'mgbt10 dpl-flex align-items-center justify-content-between'}>
                            <div className={'txt-size-h7 txt-color-black robotoregular opacity6'}>{t('relatedService.money')}</div>
                            <div className={'_totalProductPrice txt-size-h7 txt-color-black robotoregular'}>{moneyFormat(moneyCeil(dataSource.exchangedTotalValue))}</div>
                        </div>
                        <div className={'mgbt10 dpl-flex align-items-center justify-content-between'}>
                            <div onClick={() => this.setState({showListFees: !this.state.showListFees}, () => {
                                if (this.state.showListFees === true) {
                                    this.getMerchantFees(dataSource.id)
                                }
                            })} className={'txt-size-h7 cursor-pointer txt-color-black robotoregular opacity6'}>
                                {t('relatedService.provisional_fee')} {this.state.showListFees ? <AntdIcon.UpOutlined /> : <AntdIcon.DownOutlined />}
                            </div>
                            <div className={'_totalFees txt-size-h7 txt-color-black robotoregular '}>{moneyFormat(moneyCeil(dataSource.totalFee))}</div>
                        </div>
                        <Divider />
                        {
                            this.state.showListFees &&
                            <React.Fragment>
                                {
                                    listMerchantFee.map(x => <div key={x.id} className={'mgbt10 dpl-flex align-items-center justify-content-between'}>
                                        <div className={'_feeName txt-size-h7 txt-color-black robotoregular opacity6'}>- {x.type && x.type.name ? x.type.name : '---'} {
                                            x.type && !x.type.minFee && !x.type.maxFee ?
                                                null
                                                :
                                            <Tooltip title={<div>
                                                    <div >{t('fee_tab.min_fee')}: {x.type && moneyFormat(moneyCeil(x.type.minFee))}</div>
                                                    <div >{t('fee_tab.max_fee')}: {x.type && moneyFormat(moneyCeil(x.type.maxFee))}</div>
                                                </div>}
                                            >
                                                <AntdIcon.InfoCircleOutlined className="txt-size-h8 mgl5" type="info-circle" />
                                            </Tooltip>
                                        }
                                        </div>
                                        <div className={'_feeValue txt-size-h7 txt-color-black robotoregular'}>{x.provisionalAmount !== null ? moneyFormat(moneyCeil(x.provisionalAmount)) : '---'}</div>
                                    </div>)
                                }
                                <Divider />
                            </React.Fragment>
                        }

                        <div className={'mgbt15 dpl-flex align-items-center justify-content-between'}>
                            <div className={'txt-size-h5 txt-color-black robotobold'}>{t('relatedService.amount_sum')}</div>
                            <div className={'_totalMoneny txt-size-h5 txt-color-black robotobold'}>{moneyFormat(moneyCeil(dataSource.grandTotal))}</div>
                        </div>
                        <Row>
                            {
                                !disableCustomerOrderNote && <Col md={24} className={'mgbt15'}>
                                    {!currentRemark || this.state['showRemarkOrder'+dataSource.id] === true ? (
                                        <Fragment>
                                            <Input.TextArea
                                                className='note-order txt-size-h7 txt-color-black robotoregular'
                                                maxLength={1000}
                                                type='text'
                                                placeholder={t('customerAddress.typing_note')}
                                                autosize={{ minRows: 1, maxRows: 3 }}
                                                value={remark}
                                                onChange={(e) => this.onChangeInput(e.target.value, 'remark')}
                                                onKeyDown={(e) => this.onKeyDown(e, 'remark', dataSource)}
                                                onBlur={(e) => this.onBlurInput(e, 'remark', dataSource)}
                                                disabled={isLoadingDisable}
                                                ref={node => this.remarkOrder = node}
                                            />
                                            <span className={`txt-size-h8 txt-color-gray dpl-block`}>{t('cartGroup.manipulation_note')}</span>
                                        </Fragment>

                                    ) : (
                                        <Fragment>
                                            <span className="txt-color-gray robotobold txt-size-h8 bold">{t('relatedService.order_note')}:</span>
                                            {
                                                checkIsLink(remark)
                                                    ?
                                                    <div
                                                        className="txt-color-gray txt-size-h8 robotoregular mgr5 white-space-pre-wrap break-word pdl5"
                                                        style={{display: 'inline'}}
                                                        dangerouslySetInnerHTML={{__html: linkify(remark)}}
                                                    />
                                                    :
                                                    <div
                                                        className="txt-color-gray txt-size-h8 robotoregular mgr5 white-space-pre-wrap break-word pdl5"
                                                        style={{display: 'inline'}}
                                                    >
                                                        {remark}
                                                    </div>
                                            }
                                            <AntdIcon.EditOutlined className="_btn-edit-remark-order txt-color-blue txt-size-h7" type="edit" onClick={() =>
                                                this.setState({['showRemarkOrder'+dataSource.id]: !this.state['showRemarkOrder'+dataSource.id]}, () => {
                                                    this.remarkOrder.focus();
                                                })
                                            } />
                                        </Fragment>
                                    )}
                                </Col>
                            }
                            <Col span={24}>
                                {
                                    this.state['showNote'+dataSource.id]
                                    ?
                                    <Fragment>
                                        <TextArea
                                            value={note}
                                            maxLength={1000}
                                            autosize={{ minRows: 1, maxRows: 3 }}
                                            className={`_cart-item-note`}
                                            placeholder={t('order.note')}
                                            onChange={(e) => this.onChangeInput(e.target.value, 'note')}
                                            onKeyDown={(e) => this.onKeyDown(e, 'note', dataSource)}
                                            onBlur={(e) => this.onBlurInput(e, 'note', dataSource)}
                                            disabled={isLoadingDisable}
                                            ref={node => this.noteOrder = node}
                                        />
                                        <span className={`txt-size-h8 txt-color-gray dpl-block`}>{t('cartGroup.manipulation_note')}</span>
                                    </Fragment>

                                    :
                                        this.state['showNoteOrder'+dataSource.id] === true
                                        ?
                                        <Fragment>
                                            <TextArea
                                                value={note}
                                                maxLength={1000}
                                                autosize={{ minRows: 1, maxRows: 3 }}
                                                className={`_cart-item-note`}
                                                placeholder={t('order.note')}
                                                onChange={(e) => this.onChangeInput(e.target.value, 'note')}
                                                onKeyDown={(e) => this.onKeyDown(e, 'note', dataSource)}
                                                onBlur={(e) => this.onBlurInput(e, 'note', dataSource)}
                                                disabled={isLoadingDisable}
                                                ref={node => this.noteOrder = node}
                                            />
                                            <span className={`txt-size-h8 txt-color-gray dpl-block`}>{t('cartGroup.manipulation_note')}</span>
                                        </Fragment>
                                        :
                                            note
                                            ?
                                            <Fragment>
                                                <span className="txt-color-gray robotobold txt-size-h8 bold">{t('relatedService.order_personal_note')}:</span>
                                                {
                                                    checkIsLink(note)
                                                        ?
                                                        <div
                                                            className="txt-color-gray txt-size-h8 dpl-il-block robotoregular mgr5 white-space-pre-wrap pdl5 break-word mgr5"
                                                            style={{display: 'inline'}}
                                                            dangerouslySetInnerHTML={{__html: linkify(note)}}
                                                        />
                                                        :
                                                        <div
                                                            className="txt-color-gray txt-size-h8 robotoregular mgr5 white-space-pre-wrap pdl5 break-word mgr5"
                                                            style={{display: 'inline'}}
                                                        >{note}</div>
                                                }
                                                <AntdIcon.EditOutlined className="_btn-edit-note-order txt-color-blue txt-size-h7" type="edit" onClick={() => {
                                                    this.setState({['showNoteOrder'+dataSource.id]: !this.state['showNoteOrder'+dataSource.id]}, () => {
                                                        this.noteOrder.focus();
                                                    })
                                                }} />
                                            </Fragment>
                                            :
                                            <Typography.Link
                                                className={`_cart-item-showUserNote-btn txt-size-h8 robotoregular`}
                                                onClick={() => {
                                                    this.setState({['showNote'+dataSource.id]: !this.state['showNote'+dataSource.id]}, () => {
                                                        this.noteOrder.focus();
                                                    })
                                                }}
                                            >
                                                <span className={'mgr5'}>{t('relatedService.personal_note_title')}</span><Tooltip placement="top" title={t('relatedService.personal_note_content')}>
                                                <AntdIcon.QuestionCircleOutlined type="question-circle" theme="filled"  className={'txt-size-h8 opacity6'}/>
                                            </Tooltip>
                                            </Typography.Link>
                                }
                            </Col>
                        </Row>
                            <Row className='mgt20'>
                                <Col md={24}>
                                    <Button
                                        disabled={isLoadingDisable||disabled}
                                        htmlType={'button'}
                                        block
                                        className={`_btn-order-now btn--blue`}
                                        onClick={() => {
                                            if (isBanned) {
                                                Modal.info({
                                                    className: 'modal-user-banned',
                                                    maskClosable: true,
                                                    title: t('cart.suspension_notify'),
                                                    content: (
                                                        <div>
                                                            <p className="txt-size-h7 mgbt5">{t('cart.order_function_locked_account')}</p>
                                                            <p className="txt-size-h7 mgbt5">{t('cart.contact_consultant')}</p>
                                                        </div>
                                                    ),
                                                    // okText: ''
                                                });
                                            } else if (showNotiMaxSkus) {
                                                Modal.info({
                                                    className: 'modal-user-banned',
                                                    maskClosable: true,
                                                    title: t('cart.suspension_notify'),
                                                    content: (
                                                        <div>{t('cart.maxSkusError1')} {dataSource.name || dataSource.code} {t('cart.maxSkusError2')} {quantityFormat(maxSkus)}</div>
                                                    ),
                                                    // okText: ''
                                                });
                                            } else {
                                                validateOrder();
                                            }
                                        }}
                                    >
                                        <span className='robotomedium'>{t('relatedService.order_now')}</span>
                                    </Button>
                                </Col>
                            </Row>
                    </div>
                </div>
            </div>
        )
    }
}