import React from 'react'
import { Spin, Tabs } from 'antd'
import * as AntdIcon from '@ant-design/icons'

import * as Constants from '../../constants/config'
// import _ from "lodash";
import TabHistory from './TabHistory'
import TabFinance from './TabFinance'
import TabProduct from './TabProduct'
import TabPackages from './TabPackages'
import TabClaims from './TabClaims'
import TabFees from './TabFees'
import TabLog from './TabLog'
import TabCredit from './TabCredit'
const TabPane = Tabs.TabPane

class TabLayout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const {
            onChangedTab,
            exchangeRate,
            addItemWishlist,
            listTickets,
            isLoadingListTickets,
            activeTab,
            updateCustomerOrderProduct,
            isLoadingListFinancial,
            loadingProduct,
            listProduct,
            listStatuses,
            isLoadingListMilestones,
            listPackageMileStones,
            listPackages,
            getOrderPackageMileStones,
            getListProductOrder,
            isLoadingPackages,
            isLoadingPackageMileStones,
            listPackageStatuses,
            listFinancial,
            metadata,
            listMilestones,
            orderDetail,
            listFinancialClaim,
            listFinancialCollect,
            listFees,
            isLoadingFees,
            onExport,
            exportExcel,
            listStatusesTicket,
            t,
            warehousesDelivery,
            warehousesReceive,
            shipmentFees,
        } = this.props

        return (
            <div className="bg-color-white">
                <Tabs
                    className="wrappertabs"
                    activeKey={activeTab ? activeTab : Constants.ORDER_DETAIL_TAB_PRODUCT}
                    defaultActiveKey={activeTab ? activeTab : Constants.ORDER_DETAIL_TAB_PRODUCT}
                    onChange={onChangedTab}
                >
                    <TabPane
                        className={'_tabProduct tabdetailorder'}
                        tab={<span className={'_tabProduct-btn'}>{t('product_tab.product')}</span>}
                        key={Constants.ORDER_DETAIL_TAB_PRODUCT}
                    >
                        <Spin
                            spinning={loadingProduct === true}
                            indicator={
                                <AntdIcon.Loading3QuartersOutlined
                                    type="loading"
                                    style={{ fontSize: 24 }}
                                    spin
                                />
                            }
                        >
                            <TabProduct
                                t={t}
                                orderDetail={orderDetail}
                                addItemWishlist={addItemWishlist}
                                exchangeRate={exchangeRate}
                                onExport={onExport}
                                exportExcel={exportExcel}
                                dataSource={listProduct}
                                updateCustomerOrderProduct={updateCustomerOrderProduct}
                                getListProductOrder={getListProductOrder}
                                listFees={listFees}
                            />
                        </Spin>
                    </TabPane>
                    <TabPane
                        className={'_tabFrees'}
                        tab={<span className={'_tabFrees-btn'}>{t('fee_tab.finance')}</span>}
                        key={Constants.ORDER_DETAIL_TAB_FEES}
                    >
                        <TabFees
                            t={t}
                            isLoadingFees={isLoadingFees}
                            listFees={listFees}
                            orderDetail={orderDetail}
                            listStatuses={listStatuses}
                            listFinancialClaim={listFinancialClaim}
                            listFinancialCollect={listFinancialCollect}
                            shipmentFees={shipmentFees}
                        />
                    </TabPane>
                    {orderDetail?.contractWithShopkeeper && (
                        <TabPane
                            className={'_tabCredit'}
                            tab={<span className={'_tabCredit-btn'}>{t('credit_tab.title')}</span>}
                            key={Constants.ORDER_DETAIL_TAB_CREDIT}
                        >
                            <TabCredit
                                t={t}
                                orderInfo={orderDetail}
                            />
                        </TabPane>
                    )}
                    <TabPane
                        className={'_tabPackages'}
                        tab={<span className={'_tabPackages-btn'}>{t('package_tab.package')}</span>}
                        key={Constants.ORDER_DETAIL_TAB_PACKAGES}
                    >
                        <TabPackages
                            t={t}
                            orderDetail={orderDetail}
                            isLoadingPackages={isLoadingPackages}
                            isLoadingPackageMileStones={isLoadingPackageMileStones}
                            listPackages={listPackages}
                            listPackageMileStones={listPackageMileStones}
                            listPackageStatuses={listPackageStatuses}
                            getOrderPackageMileStones={getOrderPackageMileStones}
                        />
                    </TabPane>
                    <TabPane
                        className={'_tabFinance'}
                        tab={<span className={'_tabFinance-btn'}>{t('financial_tab.transaction')}</span>}
                        key={Constants.ORDER_DETAIL_TAB_FINANCIAL}
                    >
                        <TabFinance
                            t={t}
                            isLoadingListFinancial={isLoadingListFinancial}
                            listFinancial={listFinancial}
                            metadata={metadata}
                        />
                    </TabPane>
                    <TabPane
                        className={'_tabClaims'}
                        tab={<span className={'_tabClaims-btn'}>{t('complaint_tab.complaint')}</span>}
                        key={Constants.ORDER_DETAIL_TAB_TICKETS}
                    >
                        <TabClaims
                            t={t}
                            orderDetail={orderDetail}
                            listTickets={listTickets}
                            listStatusesTicket={listStatusesTicket}
                            isLoadingListTickets={isLoadingListTickets}
                        />
                    </TabPane>
                    <TabPane
                        className={'_tabHistory'}
                        tab={<span className={'_tabHistory-btn'}>{t('history_tab.history')}</span>}
                        key={Constants.ORDER_DETAIL_TAB_HISTORY}
                    >
                        <TabHistory
                            t={t}
                            isLoadingListMilestones={isLoadingListMilestones}
                            listStatuses={listStatuses}
                            listMilestones={listMilestones}
                            orderDetail={orderDetail}
                        />
                    </TabPane>
                    <TabPane
                        className={'_tabLogs'}
                        tab={<span className={'_tabLogs-btn'}>{t('log_tab.log')}</span>}
                        key={Constants.ORDER_DETAIL_TAB_LOG}
                    >
                        <TabLog
                            t={t}
                            orderDetail={orderDetail}
                            listStatuses={listStatuses}
                            warehousesDelivery={warehousesDelivery}
                            warehousesReceive={warehousesReceive}
                            listPackageStatuses={listPackageStatuses}
                        />
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}
export default TabLayout
