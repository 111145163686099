import React from 'react';
import {
    Row, Col,Rate, Modal, Input
} from 'antd';


export default class ViewVote extends React.Component {

    constructor(props) {
        super(props);
        this.buttonSubmit = null;
        this.state = {
            isModalClose:false,
            isShowConfirm:false,
            rating: 0,
            comment:'',
            archived: false

        }
    }

    handleChange = value => {
        this.setState({ rating : value});
    };

    componentDidMount = () => {
        let rating = this.convertRating();
        let ratingComment = this.props.issueDetail && this.props.issueDetail.hasOwnProperty('ratingComment') ? (this.props.issueDetail.ratingComment === null ? '' : this.props.issueDetail.ratingComment)  : '';
        let archived = this.props.issueDetail && this.props.issueDetail.hasOwnProperty('archived') ? this.props.issueDetail.archived  : false;
        this.setState({
            rating: rating ,
            comment: ratingComment,
            archived: archived
        });
    };

    componentDidUpdate(preProps, preState) {
        if(JSON.stringify(preProps.issueDetail.rating) !== JSON.stringify(this.props.issueDetail.rating) || JSON.stringify(preProps.issueDetail.ratingComment) !== JSON.stringify(this.props.issueDetail.ratingComment)) {
            let rating = this.convertRating();
            let ratingComment = this.props.issueDetail && this.props.issueDetail.hasOwnProperty('ratingComment') ? (this.props.issueDetail.ratingComment === null ? '' : this.props.issueDetail.ratingComment)  : '';
            let archived = this.props.issueDetail && this.props.issueDetail.hasOwnProperty('archived') ? this.props.issueDetail.archived  : false;

            this.setState({
                rating: rating ,
                comment: ratingComment,
                archived: archived
            });
        }
    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            isShowConfirm: nextProps.isShowConfirm,
        });
    }

    archiveIssue = () => {
        this.props.onArchiveIssue();
        this.setState({
            isModalClose: true,
            archived: true,
        });
    };

    convertRating = () => {
        if (!isNaN(Number(this.props.rating)) && this.props.rating >= 2) {
            return this.props.rating / 2 ;
        } else {
            return this.props.rating ? this.props.rating  : 0;
        }
    };

    /**
     * submit Rate
     */
    submitRating = () => {
        let payload = {
            rating: this.state.rating * 2,
            comment: this.state.comment.trim()
        };
        // console.log('payload: ', payload);
        this.props.onSubmitRating(payload);
        this.setState({
            isModalClose: false
        });
    };

    onCancelRating = () => {
        let rating = this.convertRating();
        let ratingComment = this.props.comment || '';;
        this.setState({
            rating: rating ,
            comment: ratingComment,
            isModalClose:false
        });
    };

    /**
     * Thay đổi giá trị input
     * @param value
     * @param prop
     */
    onInputChange = (value, prop) => {
        if (value !== '' && value.trim() === '') return;
        this.setState({[prop]: value});
    };

    /**
     * Disable button update khi không có sự thay đổi
     * @returns {boolean}
     */
    checkDisable = () => {
        const {comment, rating} = this.state;
        let ratingProps = this.convertRating();
        let ratingComment = this.props.comment || '';
        if (comment !== '' && rating === 0) {
            return true;
        }

        if (comment === '' && rating === 0) {
            return true;
        }

        return (
            rating === ratingProps && comment.trim() === ratingComment
        )

    };


    render() {
        const {isShowConfirm, isModalClose, comment, rating, archived} = this.state;

        return <React.Fragment>
            {
                archived ?
                    <div
                        className="bg-color-white bd-rdu bd1px bd-color-gray mgr30 mgbt15 "
                    >
                        <Row className={'mgt10 mgl10'}>
                            <Col span={8}>
                                Nhận xét
                            </Col>
                            <Col span={8}>
                                Nội dung
                            </Col>
                            <Col span={8}>
                                <p onClick={() => this.setState({isModalClose: true, isShowConfirm: false})}
                                   className="txt-right cursor-pointer _order-item-code listorders__items_codFe txt-color-blue txt-size-h7 robotoregular pdr20">
                                    {!rating ? "Đánh giá" : 'Chỉnh sửa'}
                                </p>
                            </Col>
                        </Row>
                        <Row className={'pdl10 pdbt10'}>
                            <Col span={8}>
                                <p onClick={() => this.setState({isModalClose: true, isShowConfirm: false})} className="rate_complaint" style={{"margin-top":"-8px"}}>
                                    <Rate disabled={true} value={rating}/>
                                </p>
                            </Col>
                            <Col className={"break-word pdr20"} span={16}>
                                {comment}
                            </Col>

                        </Row>

                    </div> : ''

            }


            {
                isShowConfirm && <Modal
                    onCancel={() => this.setState({
                        isShowConfirm: false,
                    })}
                    visible={isShowConfirm}

                    okText={'Xác nhận'}
                    cancelText={'Hủy'}
                    okButtonProps={{className: "_btn-submit-confirm"}}
                    cancelButtonProps={{className: "_btn-cancel-confirm"}}
                    onOk={this.archiveIssue}
                >
                    <p className="mgbt5">Không thể chỉnh sửa nội dung khiếu nại khi đã đóng. Bạn có chắc chắn đóng không?</p>

                </Modal>
            }

            {
                isModalClose && <Modal
                    onCancel={this.onCancelRating}
                    visible={isModalClose}
                    title="ĐÁNH GIÁ CỦA BẠN"
                    okText={'Xác nhận'}
                    cancelText={'Hủy'}
                    okButtonProps={{className: this.checkDisable() ? "classDisabled" : "_btn-submit-confirm"}}
                    cancelButtonProps={{className: "_btn-cancel-confirm"}}
                    onOk={this.submitRating}
                >
                    <p className="mgbt5 txt-color-black">Đánh giá của bạn giúp chúng tôi hoàn thiện hơn.</p>
                    <span className="mgbt5">
                        <Rate className={"size16"} onChange={this.handleChange}  value={rating}/>
                    </span>
                    <p className="mgbt5 mgt10 txt-color-black">Nhận xét </p>
                    <Input.TextArea
                        className="width100 _rating_comment height50 "
                        name='comment'
                        maxLength={500}
                        height={200}
                        onChange={(e) => this.onInputChange(e.target.value, 'comment')}
                        value={comment}

                    />

                </Modal>
            }
        </React.Fragment>
    }
}
