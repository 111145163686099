/* eslint-disable array-callback-return */
import React from "react";
import {Row, Col, Input, Table} from "antd";
import lodash from 'lodash';
import CategoriesService from "../../services/categories.service";
import {quantityFormat} from "../../utils/stringUtils";
import {LocalStore} from "../../utils/LocalStore";
import Loading from "../Loading";

export default class Shipping extends React.Component {
	static defaultProps = {
		isEdit: true,
	};
	constructor(props) {
		super(props);
		this.state = {
			feeObj: null,
			priceList: [],
			quantityList: [],
			tableData: [],
			shippingFees: [],
			locations: [],
			weights: [],
			addressList: [],
			newWeight: {
				"price": null,
				"minWeight": undefined,
				"maxWeight": undefined,
				"priceFormula": null
			},
            loadLocation: true
		};

	}

	componentDidMount() {
		this.initData();
	}
	initData=()=>{
		const {feeObj} = this.props;
		if(!feeObj.shippingFees || feeObj.shippingFees.length === 0)
		{
			this.getShippingFees();
		}
		else{
			this.setState({shippingFees:feeObj.shippingFees}, () => {
				this.getLocationFromShippingFees();
			});
		}
	};

	getShippingFees = () => {
		const {feeObj = {}, configGroupId, isShipment} = this.props;
		let getShippingFees;
		if (isShipment) {
			getShippingFees = CategoriesService.getShipmentShippingFees
		} else {
			getShippingFees = CategoriesService.getShippingFees
		}

		getShippingFees(configGroupId, feeObj.shippingClass, (err, res) => {
			if (!err) {
				let shippingFees = res;
				if (shippingFees.length === 0) {
					shippingFees.push({
						"location": {code: "newLocation"},
						"price": null,
						"minWeight": undefined,
						"maxWeight": undefined,
						"priceFormula": null
					});
				}
				this.setState({shippingFees}, () => {
					this.getLocationFromShippingFees();
				});
			}
			this.setState({loadLocation: false})
		})
	};

	getLocationFromShippingFees = () => {
		const {shippingFees} = this.state;
		let items = lodash.uniqBy(shippingFees, item => item.location.code);
		let locations = [];
		items.map(item => {
			locations.push(item.location);
		});
		let weights = shippingFees.filter(item => item.location.code === locations[0].code);
		this.setState({locations, weights});
	};

	render() {

		let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
		let tenantConfig = currentProjectInfo.tenantConfig || {};
		let currency = tenantConfig.currency || {};

		let defaultCurrency = (currency.prefix || '') + (currency.suffix || '');

		const{isEdit, t} = this.props;
		const {weights, locations, shippingFees, loadLocation} = this.state;
		const columns = [
			{
				title: t('config_group.location'),
				dataIndex: 'location',
				className: 'min-width-200',
				key: 'location',
				render: ((text, record, index) => <Row
					className={'dpl-flex align-items-center'}>
					<span>{lodash.get(record, 'display', '')}</span>
				</Row>)
			}
		];

		weights.map((item, index) => {
			columns.push({
				title: () => <Row
					key={index}
					className={'dpl-flex justify-content-between align-items-center'}
				>
					<span>{`${t('config_group.from')} ${quantityFormat(item.minWeight)}(kg) ${t('config_group.to')} ${quantityFormat(item.maxWeight)} (kg)`}</span>
				</Row>,
				className: 'min-width-200',
				dataIndex: item.price,
				key: item.price,
				render: ((text, record, index) => {
					let obj = shippingFees.find(x => x.location.code === record.code && x.minWeight === item.minWeight && x.maxWeight === item.maxWeight);
					return (<Row className={'dpl-flex justify-content-center align-items-center'}>
						<Col span={18}>
							<Input
								disabled={!isEdit}
								value={quantityFormat(lodash.get(obj, 'price', ''))}
							/>
						</Col>
						<Col span={6}>
							<span className={'mgl5'}>{defaultCurrency}/kg</span>
						</Col>
					</Row>);
				})
			})
		});
		return (
			<div>
				<Row>
					<Col span={24}>
						{
                            loadLocation ?
								<Loading/>
								:
                                <Table dataSource={locations}
                                       columns={columns}
                                       pagination={false}
                                       bordered
                                       scroll={{x: 200 * weights.length + 250}}
                                       rowKey={record => record.code}>
                                </Table>
						}
					</Col>
				</Row>
			</div>
		);
	}

}
