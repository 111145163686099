import React from 'react'
import Content404 from "../../components/NotFound/404";
import DocumentTitle from "react-document-title"
import {LocalStore} from "../../utils/LocalStore";
import {withNamespaces} from "react-i18next";

// import image404 from '../../resources/images/img404.png'
class Page404 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount () {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null;
        if (typeof LocalStore.getInstance().read('currentProjectInfo') === 'object') {
            this.setState({
                currentProjectInfo: LocalStore.getInstance().read('currentProjectInfo') || {}
            });
        }
    }

    render() {
        const {currentProjectInfo} = this.state;
        const {t} = this.props;
        return(
            <DocumentTitle title={`${t('error.not_found')} | ${currentProjectInfo ? currentProjectInfo.name : ''}`}>
                <Content404 {...this.props}/>
            </DocumentTitle>
        );
    }
}
export default withNamespaces()(Page404);
