/* eslint-disable import/no-anonymous-default-export */
import lang from "../resources/localization/Localization";

/**
 * created by Taobao dev team - 15/1/2018
 */
const HTTP_NO_CONTENT = 204;

/**
 * function fetch api from server
 * @param endpoint
 * @param payload
 * @param keepStableUrl
 * @returns {Promise<*>}
 */
export default async(endpoint, payload, keepStableUrl) => {

    if (!global.fetchOverwrite) {
        global.fetch = null;
        require('whatwg-fetch-timeout');
        global.fetchOverwrite = true;
    }

    let fullURL = endpoint;

    if (!endpoint.startsWith('http') && !endpoint.startsWith('//') && !keepStableUrl) {
        // validate endpoint
        endpoint = endpoint.startsWith('/') ? endpoint : '/' + endpoint;

        const appApiUrl = process.env.REACT_APP_API_URL || window.location.hostname;

        // calc url
        fullURL = endpoint.indexOf(appApiUrl) === -1 ? appApiUrl + endpoint : endpoint;
    }

    let res;

    try {
        res = await fetch(fullURL, {...payload, timeout: 120000, mode: 'no-cors'});
        // check status HTTP_NO_CONTENT
        if (res.status === HTTP_NO_CONTENT) {
            return true
        }

        const dataRes = await res.json();
        if (!res.ok) {
            throw dataRes;
        }
        // console.log('res.headers', res)
        return {headers: res.headers.map, response: dataRes};
    } catch (err) {
        if (err.message === 'Network request failed') {
            // notification.warning({
            //     key: 'Network',
            //     message: "Vui lòng kiểm tra lại kết nối Internet."
            // });
            err.message = "error.network";
            err.statusCode = "NetworkFail";
            throw err
        }

        if (res.status >= 200 && res.status < 300) {
            // const dataText = await res.arrayBuffer();
            return {headers: res.headers.map, response: res};
        }

        if (err.status) {
            throw err
        }
        if (err.error === 'invalid_token' || err.error === 'unauthorized') {
            let error  = new Error('');
            error.statusCode = 401;
            error.message = lang.error.error401;
            throw error;
        } else {
            let error = new Error('Có lỗi xảy ra, vui lòng thử lại!');
            error.statusCode = 'timeout';
            error.message = lang.error.timeout;
            error.detail = err;
            throw error;
        }
    }
};

