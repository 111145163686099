import { Modal as ModalAnt } from 'antd'

const MODAL_DEFAULT_WIDTH = 500
const MODAL_DEFAULT_TITLE = 'Modal Title'

function Modal(props) {
    const {
        title = MODAL_DEFAULT_TITLE,
        visible = false,
        width = MODAL_DEFAULT_WIDTH,
        onCancel = () => { },
        className,
        destroyOnClose = false,
        listAction = [],
        closeIcon,
        children,
    } = props
    return (
        <ModalAnt
            {...props}
            className={`modal-common-v2 v2-layout ${className ? className : ''}`}
            title={
                <div className='flex items-center justify-between'>
                    <span className='robotomedium text-black-100 capitalize'>
                        {title}
                    </span>
                    <span onClick={() => onCancel()}>
                        <i className="fa-solid fa-xmark text-grey-400 cursor-pointer"></i>
                    </span>
                </div>
            }
            open={visible}
            destroyOnClose={destroyOnClose}
            width={width}
            closable={false}
            closeIcon={closeIcon}
            onCancel={() => onCancel()}
            footer={listAction}
            centered
        >
            <div className="w-full">{children}</div>
        </ModalAnt>
    )
}

export default Modal
