import { notification } from 'antd';
import * as AntdIcon from "@ant-design/icons"
import { useEffect, useState } from 'react';
import CameraDropzone from '../ReactDropzone/Camera';
import {formatFileName} from "../../utils/stringUtils";

import AntdIconSVG from "@ant-design/icons"
import {ReactComponent as iconDoc} from '../../resources/images/_doc - docx.svg'
import {ReactComponent as iconOther} from '../../resources/images/_other.svg'
import {ReactComponent as iconPdf} from '../../resources/images/_pdf.svg'
import {ReactComponent as iconPttx} from '../../resources/images/_ppt pttx.svg'
import {ReactComponent as iconXls} from '../../resources/images/_xls - xlsx.svg'
import {ReactComponent as iconZip} from '../../resources/images/_zip.svg'

export const UploadQrCodePeerPaymentPay = (props) => {
    const {t, isUploading, uploadImageQrCode, fileUploaded, callBackRemoveQrCode} = props
    const [files, setFiles] = useState([])
    const [previewRemoveIcon, setPreviewRemoveIcon] = useState(null)

    useEffect(() => {
        setFiles(fileUploaded)
    }, [fileUploaded])

    const uploadFiles = (filesUpload) => {
        if (isUploading === true) return;

        if ([...files, ...filesUpload].length > 1) {
            notification['error']({
                message: "Bạn chỉ được upload 1 file ảnh"
            });
        } else {
            setFiles([...files, ...filesUpload]);
            uploadImageQrCode({files: [...files, ...filesUpload]})
        }
    };

    const renderIcon = (type) => {
        switch (type) {
            case 'application/java-archive':
            case 'application/vnd.rar':
            case 'application/x-7z-compressed':
            case 'application/zip':
                return <AntdIconSVG component={iconZip}/>;
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return <AntdIconSVG component={iconDoc}/>;
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                return <AntdIconSVG component={iconPttx}/>;
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return <AntdIconSVG component={iconXls}/>;
            case 'application/pdf':
                return <AntdIconSVG component={iconPdf}/>;
            default:
                return <AntdIconSVG component={iconOther}/>;
        }
    };

    const renderPreviewList = () => {
        return files.map((item, index) => {
            if (item.type.indexOf('video') < 0 && item.type.indexOf('image') < 0) {
                return <div className={'mgbt5 mgr5'} key={index}>
                    <div
                        onMouseOver={() => {setPreviewRemoveIcon(index)}}
                        onMouseLeave={() => {setPreviewRemoveIcon(null)}}
                        className="_chat-attachments-item chat-attachments-item cursor-pointer position-re dpl-flex align-items-center justify-content overflow-hidden"
                        style={{fontSize: 30, border: '1px solid #a0abb8', color: '#a0abb8'}}
                        onClick={() => removeFile(item)}
                    >
                        {previewRemoveIcon === index ? <AntdIcon.CloseOutlined type="close" /> : renderIcon(item.type)}
                    </div>
                    <p className="_file-name txt-color-gray txt-size-h9 text-center mgbt0">
                        {formatFileName(item.name, 12)}
                    </p>
                </div>
            }
            return <div className={'mgbt5 mgr5'} key={index}>
                <div
                    onMouseOver={() => {setPreviewRemoveIcon(index)}}
                    onMouseLeave={() => {setPreviewRemoveIcon(null)}}
                    onClick={() => removeFile(item)}
                    className="_chat-attachments-item chat-attachments-item cursor-pointer position-re dpl-flex align-items-center justify-content overflow-hidden"
                    style={item.type.indexOf('video') > -1 || previewRemoveIcon === index ? {fontSize: 30, border: '1px solid #a0abb8', color: '#a0abb8'} : {}}
                >
                    {
                        previewRemoveIcon === index
                            ?
                            <AntdIcon.CloseOutlined type="close" />
                            :
                            item.type.indexOf('video') > -1
                                ?
                                <AntdIcon.PlayCircleOutlined type="play-circle" />
                                :
                                <div className="image-preview" style={{backgroundImage: `url(${item.preview})`}} />
                    }
                </div>
                <p className="_file-name txt-color-gray txt-size-h9 text-center mgbt0">
                    {formatFileName(item.name, 12)}
                </p>
            </div>
        })
    };

    const removeFile = (file) => {
        setPreviewRemoveIcon(null);
        setFiles(files.filter(x => x !== file))
        callBackRemoveQrCode()
    };

    return (<div className='width-100-percent'>
        <CameraDropzone
            t={t}
            isUploading={isUploading}
            maxLength={1}
            acceptedFiles={'image/*'}
            addFiles={uploadFiles}
            isUploadIcon
        />
        {
            files.length > 0 && <div className="pd5 dpl-flex bg-color-white flex-wrap">
                {renderPreviewList()}
            </div>
        }
    </div>)
}