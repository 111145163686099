import React, {Fragment} from 'react';
import MainLayout from '../../components/Layout/MainLayout';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import {
    Row, Col, Button, notification, Divider, Input, InputNumber, Tooltip,
    Collapse, Spin, Popconfirm, Checkbox, Select, Radio
} from 'antd';
import * as AntdIcon from "@ant-design/icons"
import {LocalStore} from "../../utils/LocalStore";

import {checkIsLink, linkify, moneyCeil, moneyFormat, quantityFormat} from "../../utils/stringUtils";
import DeliveryAddress from "../../components/AddressDeposit/DeliveryAddress";
import ServiceGroup from "../../components/Shipment/ServiceGroup";
import UserService from "../../services/user.service";
import Loading from "../../components/Loading";
import CategoriesService from "../../services/categories.service";
import {withNamespaces} from "react-i18next";
import {USER_STATUS} from "../../constants/config";

const Panel = Collapse.Panel;
const { TextArea } = Input;
class Create extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isCheckAll: true,
            indeterminate: false,
            isEditRemark: true,
            isEditRefShipmentCode: true,
            isEditRefCustomerCode: true,
            isEditExpectedPackages: true,
            isWarehouseEnabled: false,
            sourceWarehouses: null,
        }
    }

    componentDidMount() {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null;
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        const isWarehouseEnabled = _.get(currentProjectInfo, 'tenantConfig.generalConfig.customerWarehouseEnabled', false)
        if (!currentProjectInfo.tenantConfig || !currentProjectInfo.tenantConfig.shipmentConfig || !currentProjectInfo.tenantConfig.shipmentConfig.enable) {
            this.props.history.push('/404')
        }
        let currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {};

        this.setState({user_profile: currentLoggedUser, isWarehouseEnabled});
        if (currentLoggedUser.customerStatus === USER_STATUS.BANNED) {
            this.props.history.push('/404')
        }

        this.getDraftShipment();
        this.getShipmentFees()
    }

    /**
     * tạo draft shipment
     */
    createDraftShipment = (cb) => {
        const {isDraftSubmit, address} = this.state;
        this.setState({isDraftSubmit: true}, () => {
            if (isDraftSubmit) return;
            // lấy ra danh sách checkedList của ServiceGroup component  
            let checkedList = this.ServiceGroup.state.checkedList;
            let body = {
                addressId: address,
                services: checkedList.map(x => x.code)
            };
            const {t} = this.props;
            // console.log('body', body)
            if (!Array.isArray(body.services) || body.services.length === 0) {
                this.setState({
                    isDraftSubmit: false
                });
                return;
            }

            UserService.createDraftShipment(body, (err, res, header) => {
                if(!err) {
                    this.setState({
                        sourceWarehouses: res.sourceWarehouses,
                        draftShipment: res,
                        isDraftSubmit: false,
                    }, () => {
                        if(res.sourceWarehouses && res.sourceWarehouses.length > 0) {
                            this.setState({
                                customWarehouseReceived: res.sourceWarehouses[0].code
                            })
                        }
                        if (cb) cb()
                    })
                } else {
                    notification['error']({
                        message: t(err.message),
                    });
                    this.setState({
                        isDraftSubmit: false
                    })
                }
            })
        })
    };

    /**
     * tạo đơn ký gửi
     */
    createShipment = () => {
        const {isSubmit, draftShipment = {}, note, remark, isWarehouseEnabled, customWarehouseReceived,
            refShipmentCode, refCustomerCode, expectedPackages} = this.state;
        const {t} = this.props;
        if (this.ServiceGroup.validateServices(true)) return;

        this.setState({isSubmit: true}, () => {
            if (isSubmit) return;
            let body = {
                draftShipmentId: draftShipment.id,
                note,
                remark,
                refShipmentCode,
                refCustomerCode,
                expectedPackages,
            };
            if(isWarehouseEnabled) {
                body = {...body, receivingWarehouse: customWarehouseReceived}
            }
            UserService.createShipment(body, (err, res, header) => {
                if(!err) {
                    notification.success({
                        message: t('shipment.create_success')
                    });
                    this.setState({
                        isSubmit: false,
                        showModalConfirm: false
                    }, () => {
                        this.props.history.push('/shipments')
                    })
                } else {
                    if (err.title === 'config_group_changed') {
                        notification['error']({
                            message: t('shipment.create_confirm_text'),
                        });
                        this.setState({showModalConfirm: true}, () => {
                            this.ServiceGroup.getShipmentServices();
                            this.createDraftShipment();
                        })
                    } else if (err.title === 'warehouse_location_not_mapped') {
                        notification['error']({
                            message: t('message.warehouse_location_not_mapped'),
                        });
                    } else {
                        notification['error']({
                            message: t(err.message),
                        });
                    }
                    this.setState({
                        isSubmit: false
                    })
                }
            })
        })
    };

    onSubmitUserProfile = (payload) => {
        const {t} = this.props;
        this.setState({isSubmitProfile: true}, () => {
            UserService.updateProfile(payload, (err, res) => {
                if (!err) {
                    LocalStore.getInstance().save('currentLoggedUser', res);
                    this.setState({
                        isSubmitProfile: false,
                        user_profile:res
                    });
                } else {
                    if (err.code !== "Network fail") {
                        if (err.code !== 500) {
                            notification.error({
                                message: t(err.message)
                            })
                        } else {
                            notification.error({
                                message: "Vui lòng kiểm tra lại kết nối Internet"
                            })
                        }
                    }
                }
                this.setState({isSubmitProfile: false});
            })
        })
    };

    /**
     * lấy danh sách hàng đã về kho || tạm fake
     */
    getDraftShipment = () => {
        UserService.getDraftShipment((err, res) => {
            if (!err) {
                const {sourceWarehouses} = res
                this.setState({
                    draftShipment: res,
                    address: res.addressId ? res.addressId : this.state.address,
                    sourceWarehouses: res.sourceWarehouses,
                    isLoading: false
                });
                if(sourceWarehouses && sourceWarehouses.length > 0) {
                    this.setState({
                        customWarehouseReceived: sourceWarehouses[0].code
                    })
                }
            } else {
                this.setState({isLoading: false});
            }
        })
    };

    /**
     * lấy danh sách hàng đã về kho || tạm fake
     */
    getShipmentFees = () => {
        CategoriesService.getShipmentFees((err, res) => {
            if (!err) {
                this.setState({
                    listFees: res,
                });
            }
        })
    };

    /**
     * render related service
     */
    renderAddressDeposit = () => {
        const {isUpdateDraftOrder, address} = this.state;
        // console.log('addressaddressaddress', address)
        return (
            <DeliveryAddress
                className={"mgt20"}
                updateLoadingStatus={(value) => this.setState({isUpdateDraftOrder: value})}
                defaultAddress={address}
                updateDraftOrder={(body, cb) => {
                    this.setState({
                        address: body.address,
                        isUpdateDraftOrder: true,
                    }, () => {
                        this.getDraftShipment();
                        this.createDraftShipment();
                        this.setState({isUpdateDraftOrder: false});
                        if (cb) cb()
                    })
                }}
                isUpdateDraftOrder={isUpdateDraftOrder}
                ref={ref => this.deliveryAddress = ref}
                showReceiveAddress={false}
            />
        )
    };

    onChangeWarehouseReceive = (warehouseCode) => {
        this.setState({
            customWarehouseReceived: warehouseCode
        })
    }

    renderWarehouseSelect = () => {
        const {t} = this.props;
        const { Option } = Select;
        const {sourceWarehouses, customWarehouseReceived} = this.state;

        return (
            <Collapse accordion defaultActiveKey={['1']} className={`sidebar deliveryaddress position-re mgt20`}>
                <Panel header={t("shipments.receivingWarehouse")} key={1}>
                    <div className={'deliveryaddress__box pd20'}>
                        <Row type="flex" align="middle">
                            <Col span={3}>
                                <span className='txt-color-black txt-size-h7 bold width-150 flex-none'>{t("shipments.receivingWarehouseDisplayName")}</span>
                            </Col>
                            <Col span={21}>
                                {sourceWarehouses.length <= 3 ? 
                                <div className={`_warehouse-radio-group checkbox-group`}>
                                    <Radio.Group
                                        key={'customer_warehouse_codes'}
                                        onChange={(e) => {this.onChangeWarehouseReceive(e.target.value)}}
                                        value={customWarehouseReceived}
                                    >
                                        {sourceWarehouses && sourceWarehouses.map(x => <Radio
                                                key={x.code}
                                                className={`__warehouse-checkbox`}
                                                value={x.code}
                                            >
                                                <span className={`__warehouse-name txt-size-h8 robotoregular txt-color-black`}>{x.displayName}</span>
                                            </Radio>)
                                        }
                                    </Radio.Group>
                                </div> : 
                                <Select 
                                    value={customWarehouseReceived}
                                    defaultValue={sourceWarehouses && sourceWarehouses[0].code}
                                    style={{ width: 150 }} 
                                    onChange={(code) => {this.onChangeWarehouseReceive(code)}}
                                >
                                    {sourceWarehouses && sourceWarehouses.map((item) => (<Option key={item.code} value={item.code}>{item.displayName}</Option>))}
                                </Select>}
                            </Col>
                        </Row>
                    </div>               
                </Panel>
            </Collapse>
        )
    }

    setDisable = (value) => {
        this.setState({
            disabled: value
        })
    };

    renderRightBox = () => {
        const {t} = this.props;
        const {
            draftShipment = {}, showModalConfirm, isEditNote, isEditRemark, note, remark, address, listFees = [], disabled,
            isEditRefShipmentCode, refShipmentCode, refCustomerCode, isEditRefCustomerCode, expectedPackages, isEditExpectedPackages
        } = this.state;

        let fees = draftShipment.fees || [];
        fees = fees.map(x => {
            const current = listFees.find(y => y.code === x.feeType) || {};
            if (current) {
                x.name = current.name;
                x.position = current.position
            } else {
                x.name = t('delivery.undefined');
                x.position = 0
            }
            return x
        });

        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        const disableCustomerOrderNote = currentProjectInfo.tenantConfig && currentProjectInfo.tenantConfig.generalConfig && currentProjectInfo.tenantConfig.generalConfig.disableCustomerOrderNote;
        return <div className={'_order-financial-info sidebar__financialinfo'}>
            <div className="sidebar__financialinfo_box">
                {
                    _.sortBy(fees, 'position').map((x, j) => <div key={j} className={'mgbt10 dpl-flex align-items-center justify-content-between'}>
                        <div className={'txt-size-h7 txt-color-black robotoregular opacity6'}>- {x.name}</div>
                        <div className={'_totalProductPrice txt-size-h7 txt-color-black robotoregular'}>{moneyFormat(moneyCeil(x.provisionalAmount))}</div>
                    </div>)
                }

                <Divider style={{marginTop:10, marginBottom: 10}}/>

                <div className={'mgbt15 dpl-flex align-items-center justify-content-between'}>
                    <div className={'txt-size-h5 txt-color-black robotobold'}>{t('relatedService.provisional_fee')}</div>
                    <div className={'_totalMoneny txt-size-h5 txt-color-black robotobold'}>{moneyFormat(moneyCeil(draftShipment.totalFee))}</div>
                </div>

                {
                    !expectedPackages || isEditExpectedPackages ? <div>
                            <InputNumber
                                ref={node => this.expectedPackagesInput = node}
                                precision={0} // bắt chỉ cho nhập integer
                                className='note-order txt-size-h7 txt-color-black robotoregular mgbt15 width-100-percent'
                                placeholder={t('shipments.expectedPackages')}
                                min={0}
                                value={expectedPackages}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                onBlur={(e) => {
                                    this.setState({
                                        // expectedPackages: e.target.value,
                                        isEditExpectedPackages: expectedPackages === undefined
                                    })
                                }}
                                onChange={(value) => {
                                    if (typeof value === 'number') {
                                        this.setState({expectedPackages: value})
                                    }
                                }}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        this.setState({
                                            // expectedPackages: e.target.value,
                                            isEditExpectedPackages: expectedPackages === undefined
                                        })
                                    }
                                }}
                            />
                        </div>
                        :
                        <div className="mgbt15">
                            <span className="txt-color-gray robotobold txt-size-h8 bold">{t('shipments.expectedPackages')}:</span>

                            <div
                                className="txt-color-gray txt-size-h8 robotoregular mgr5 white-space-pre-wrap break-word pdl5"
                                style={{display: 'inline'}}
                            >
                                {quantityFormat(expectedPackages)}
                            </div>
                            <AntdIcon.EditOutlined
                                className="_btn-edit-remark-order txt-color-blue txt-size-h7"
                                type="edit"
                                onClick={() => this.setState({isEditExpectedPackages: true})}
                            />
                        </div>
                }

                {
                    !refShipmentCode || isEditRefShipmentCode ? <div>
                            <Input
                                value={refShipmentCode}
                                className='note-order txt-size-h7 txt-color-black robotoregular mgbt15'
                                maxLength={1000}
                                type='text'
                                placeholder={t('orderDetail.refShipmentCode')}
                                onChange={(e) => this.setState({refShipmentCode: e.target.value})}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        this.setState({
                                            refShipmentCode: e.target.value.trim(),
                                            isEditRefShipmentCode: !e.target.value.trim()
                                        })
                                    }
                                }}
                                onBlur={(e) => {
                                    this.setState({
                                        refShipmentCode: e.target.value.trim(),
                                        isEditRefShipmentCode: !e.target.value.trim()
                                    })
                                }}
                                ref={node => this.refShipmentCodeInput = node}
                            />
                        </div>
                        :
                        <div className="mgbt15">
                            <span className="txt-color-gray robotobold txt-size-h8 bold">{t('orderDetail.refShipmentCode')}:</span>

                            <div
                                className="txt-color-gray txt-size-h8 robotoregular mgr5 white-space-pre-wrap break-word pdl5"
                                style={{display: 'inline'}}
                            >
                                {refShipmentCode}
                            </div>
                            <AntdIcon.EditOutlined
                                className="_btn-edit-remark-order txt-color-blue txt-size-h7"
                                type="edit"
                                onClick={() => this.setState({isEditRefShipmentCode: true})}
                            />
                        </div>
                }

                {
                    !refCustomerCode || isEditRefCustomerCode ? <div>
                            <Input
                                value={refCustomerCode}
                                className='note-order txt-size-h7 txt-color-black robotoregular mgbt15'
                                maxLength={1000}
                                type='text'
                                placeholder={t('orderDetail.refCustomerCode')}
                                onChange={(e) => this.setState({refCustomerCode: e.target.value})}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        this.setState({
                                            refCustomerCode: e.target.value.trim(),
                                            isEditRefCustomerCode: !e.target.value.trim()
                                        })
                                    }
                                }}
                                onBlur={(e) => {
                                    this.setState({
                                        refCustomerCode: e.target.value.trim(),
                                        isEditRefCustomerCode: !e.target.value.trim()
                                    })
                                }}
                                ref={node => this.refCustomerCodeInput = node}
                            />
                        </div>
                        :
                        <div className="mgbt15">
                            <span className="txt-color-gray robotobold txt-size-h8 bold">{t('orderDetail.refCustomerCode')}:</span>

                            <div
                                className="txt-color-gray txt-size-h8 robotoregular mgr5 white-space-pre-wrap break-word pdl5"
                                style={{display: 'inline'}}
                            >
                                {refCustomerCode}
                            </div>
                            <AntdIcon.EditOutlined
                                className="_btn-edit-remark-order txt-color-blue txt-size-h7"
                                type="edit"
                                onClick={() => this.setState({isEditRefCustomerCode: true})}
                            />
                        </div>
                }

                {
                    !disableCustomerOrderNote && (
                    !remark || isEditRemark ? <div>
                    <Input.TextArea
                        value={remark}
                        className='note-order txt-size-h7 txt-color-black robotoregular'
                        maxLength={1000}
                        type='text'
                        placeholder={t('relatedService.order_note')}
                        autosize={{ minRows: 1, maxRows: 3 }}
                        onChange={(e) => this.setState({remark: e.target.value})}
                        onKeyDown={(e) => {
                        if ((e.metaKey || e.shiftKey || e.altKey || e.ctrlKey) && e.keyCode === 13) {
                            this.setState({
                                remark: e.target.value.trim(),
                                isEditRemark: !e.target.value.trim()
                            })
                        }
                        }}
                            onBlur={(e) => {
                            this.setState({
                                remark: e.target.value.trim(),
                                isEditRemark: !e.target.value.trim()
                            })
                        }}
                        ref={node => this.remarkInput = node}
                    />
                    <span className={`mgt5 txt-size-h8 txt-color-gray dpl-block`}>
                    {t('cartGroup.manipulation_note')}
                    </span>
                    </div>
                    :
                    <Fragment>
                    <span className="txt-color-gray robotobold txt-size-h8 bold">{t('relatedService.order_note')}:</span>
                    {
                        checkIsLink(remark)
                            ?
                            <div
                                className="txt-color-gray txt-size-h8 robotoregular mgr5 white-space-pre-wrap break-word pdl5"
                                style={{display: 'inline'}}
                                dangerouslySetInnerHTML={{__html: linkify(remark)}}
                            />
                            :
                            <div
                                className="txt-color-gray txt-size-h8 robotoregular mgr5 white-space-pre-wrap break-word pdl5"
                                style={{display: 'inline'}}
                            >
                                {remark}
                            </div>
                    }
                    <AntdIcon.EditOutlined
                        className="_btn-edit-remark-order txt-color-blue txt-size-h7"
                        type="edit"
                        onClick={() => this.setState({isEditRemark: true})}
                    />
                    </Fragment>
                    )
                }

                {
                    isEditNote ?
                        <div className="mgt10">
                            <TextArea
                                value={note}
                                maxLength={1000}
                                autosize={{ minRows: 1, maxRows: 3 }}
                                className={`_cart-item-note`}
                                placeholder={t('orderDetail.personal_note_for_order')}
                                onChange={(e) => this.setState({note: e.target.value})}
                                onKeyDown={(e) => {
                                    if ((e.metaKey || e.shiftKey || e.altKey || e.ctrlKey) && e.keyCode === 13) {
                                        this.setState({
                                            note: e.target.value.trim(),
                                            isEditNote: false
                                        })
                                    }
                                }}
                                onBlur={(e) => {
                                    this.setState({
                                        note: e.target.value.trim(),
                                        isEditNote: false
                                    })
                                }}
                                ref={node => this.noteInput = node}
                            />
                            <span className={`txt-size-h8 txt-color-gray dpl-block mgt5`}>
                                {t('cartGroup.manipulation_note')}
                            </span>
                        </div>
                        :
                            note
                            ?
                                <div className="mgt10">
                                    <span className="txt-color-gray robotobold txt-size-h8 bold">{t('orderDetail.personal_note_for_order')}:</span>
                                    {
                                        checkIsLink(note)
                                            ?
                                            <div
                                                className="txt-color-gray txt-size-h8 dpl-il-block robotoregular mgr5 white-space-pre-wrap pdl5 break-word mgr5"
                                                style={{display: 'inline'}}
                                                dangerouslySetInnerHTML={{__html: linkify(note)}}
                                            />
                                            :
                                            <div
                                                className="txt-color-gray txt-size-h8 robotoregular mgr5 white-space-pre-wrap pdl5 break-word mgr5"
                                                style={{display: 'inline'}}
                                            >{note}</div>
                                    }
                                    <AntdIcon.EditOutlined
                                        className="_btn-edit-note-order txt-color-blue txt-size-h7"
                                        type="edit"
                                        onClick={() => {
                                            this.setState({isEditNote: true}, () => {
                                                this.noteInput.focus();
                                            })
                                        }}
                                    />
                                </div>
                            :
                                <div className="mgt10">
                                    <span
                                        className={`_cart-item-showUserNote-btn txt-size-h8 txt-color-blue cursor-pointer robotoregular`}
                                        onClick={() => {
                                            this.setState({isEditNote: true}, () => {
                                                this.noteInput.focus();
                                            })
                                        }}
                                    >
                                        <span className={'mgr5'}>{t('orderDetail.personal_note_for_order')}</span><Tooltip placement="top" title={t('cartGroup.order_note_content')}>
                                            <AntdIcon.QuestionCircleOutlined type="question-circle" theme="filled"  className={'txt-size-h8 txt-color-blue opacity6'}/>
                                        </Tooltip>
                                    </span>
                                </div>
                }

                {
                    showModalConfirm && draftShipment.id && address && !disabled ?
                        <Popconfirm
                            placement="topRight"
                            title={t('shipment.create_confirm_text')}
                            onConfirm={() => this.createShipment()}
                            okText={t('modal.confirm')}
                            cancelText={t('button.cancel')}
                        >
                            <Button
                                htmlType={'button'}
                                block
                                className={`_btn-order-now btn--blue mgt20`}
                            >
                                <span className='robotomedium txt-uppercase'>{t('shipment.btn_create_shipment')}</span>
                            </Button>
                        </Popconfirm>
                        :
                        <Button
                            disabled={!draftShipment.id || !address || disabled}
                            htmlType={'button'}
                            block
                            className={`_btn-order-now btn--blue mgt20`}
                            onClick={() => {
                                this.createShipment()
                            }}
                        >
                            <span className='robotomedium txt-uppercase'>{t('shipment.btn_create_shipment')}</span>
                        </Button>
                }
            </div>
        </div>
    };

    render() {
        const {t} = this.props;
        const {isLoading, isSubmit, isDraftSubmit, isWarehouseEnabled, sourceWarehouses, user_profile = {}} =  this.state;

        return <MainLayout
            {...this.props}
            showProgressBar={this.state.showProgressBar}
            headerTitle={t('shipment.create_shipment')}
            title={t('shipment.create_shipment')}
        >
            <div className={'container-common pdbt30 mgr20'}>
                <div className=" pdbt20 dpl-flex justify-content-between align-items-center">
                    <Link className={'shipments'} to={'/shipments'}>
                        <AntdIcon.ArrowLeftOutlined className="txt-color-blue" type="arrow-left" /> <span className={'cursor-pointer pdl10 txt-color-blue txt-size-h8 robotoregular'}>{t('shipment.shipment_list')}</span>
                    </Link>
                </div>
                <Spin wrapperClassName="width-100-percent" spinning={isDraftSubmit === true || isSubmit === true} indicator={<AntdIcon.Loading3QuartersOutlined type="loading" style={{ fontSize: 24 }} spin />} >
                {
                    isLoading
                        ?
                        <Loading />
                        :
                        <Row gutter={20}>
                            <Col span={16}>
                                <ServiceGroup
                                    t={t}
                                    handleDisable={this.setDisable}
                                    createDraftShipment={this.createDraftShipment}
                                    draftShipment={this.state.draftShipment}
                                    ref={ref => this.ServiceGroup = ref}
                                    header={
                                        <Checkbox
                                            onChange={(e) => this.onSubmitUserProfile({draftServicesEnable: e.target.checked})}
                                            className={`_save_draft-checkbox pdr5`}
                                            checked={user_profile.customerAuthorities && user_profile.customerAuthorities.draftServicesEnable}
                                        >
                                            <span className={'_save_draft-checkbox-name txt-size-h8 robotoregular txt-color-black'}>
                                                {t('shipment.save_draft_shipment')}
                                            </span>
                                        </Checkbox>
                                    }
                                />
                                {this.renderAddressDeposit()}
                                {isWarehouseEnabled && !_.isEmpty(sourceWarehouses) && this.renderWarehouseSelect()}
                            </Col>

                            <Col span={8}>
                                {this.renderRightBox()}
                            </Col>
                        </Row>
                }
                </Spin>
            </div>
        </MainLayout>;
    }
}
export default withNamespaces()(Create);
