import React from 'react'
import { Input } from 'antd'
import lodash from 'lodash'

function InputPassword(props) {
    const { title, value, className, containerClassName, required } = props
    return (
        <div className={`form-field-password w-full ${containerClassName ? containerClassName : ''}`}>
            <Input.Password
                className={`form-input w-full robotoregular  ${className ? className : ''} ${
                    !lodash.isEmpty(value) ? 'input-has-value' : ''
                }`}
                {...props}
            />
            <label className="robotoregular form-label">
                {title} {!!required ? <span className="text-red-100">*</span> : null}
            </label>
        </div>
    )
}

export default InputPassword
