import React, { Component } from "react";
import saveAs from 'file-saver'
export default class Download extends Component {
    downloadFile = (fileName, fileLink) => {
        saveAs(fileLink, fileName)
    };

    render() {
        const { children, file, content, style, className, onMouseOver, onMouseLeave } = this.props;

        return (
            <div
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
                className={
                    "react-download-container" + (className ? " " + className : "")
                }
                onClick={() => this.downloadFile(file, content)}
                style={style}
            >
                {children}
            </div>
        );
    }
}