import React, {useEffect, useState} from 'react'
import {Alert, Modal, Table} from "antd";
import lodash from 'lodash';
import {formatValue, moneyFormat} from "../../utils/stringUtils";

export const ModalSelectedPeerPayment = (props) => {
    const {t, exchangeRatesByAmount, items, tenantConfigPayment} = props
    const [hasNewExchangeRate, setHasNewExchangeRate] = useState(false)
    const maxCombine1688Bills = lodash.get(tenantConfigPayment, 'config.maxCombine1688Bills')
    const showWarningLimitCombinePeerPayment = maxCombine1688Bills && items.length > maxCombine1688Bills

    useEffect(() => {
        if (exchangeRatesByAmount.length) {
            for (let row of items) {
                const exchangeRate = exchangeRatesByAmount.find(item => item.refId === `${row.amount}|${row.paymentMethodCode}`)
                const newRate = lodash.get(exchangeRate, 'exchangeRate.rate')
                if (newRate !== row.exchangeRate) {
                    setHasNewExchangeRate(true)
                    break;
                }
            }
        }
    }, [items, exchangeRatesByAmount])


    const getTotalAmount = () => {
        let amount = 0
        for (let item of props.items) {
            amount += item.amount
        }
        return amount
    }

    const getExchangeAmount = () => {
        let amount = 0
        for (let row of props.items) {
            const exchangeRate = exchangeRatesByAmount.find(item => item.refId === `${row.amount}|${row.paymentMethodCode}`)
            const newRate = lodash.get(exchangeRate, 'exchangeRate.rate')
            let newAmount = row.exchangedAmount;
            if (newRate !== row.exchangeRate) {
                newAmount = row.amount * newRate
            }
            amount += newAmount
        }
        return amount
    }

    const getTopUpMoney = () => {
        const {balance, creditLimit} = props.userBalance
        let totalMoney = 0
        for (let row of props.items) {
            const exchangeRate = exchangeRatesByAmount.find(item => item.refId === `${row.amount}|${row.paymentMethodCode}`)
            const newRate = lodash.get(exchangeRate, 'exchangeRate.rate')
            let newAmount = row.exchangedAmount;
            if (newRate !== row.exchangeRate) {
                newAmount = row.amount * newRate
            }
            totalMoney += newAmount
        }
        return totalMoney - (balance + creditLimit)
    }

    return (
        <>
            <Modal
                width={950}
                visible={true}
                className={'modal-confirm-yctt'}
                title={t('peer_payment.modalConfirmYctt')}
                okText={t('peer_payment.modalConfirmYcttBtnConfirm')}
                cancelText={t('peer_payment.modalConfirmYcttBtnCancel')}
                onOk={props.onSubmit}
                okButtonProps={{disabled: showWarningLimitCombinePeerPayment}}
                onCancel={() => props.onVisibleChange(false)}
                maskClosable={false}
            >
                {showWarningLimitCombinePeerPayment && <Alert className={'mgbt10'} message={`Bạn chỉ được phép thanh toán gộp tối đa ${maxCombine1688Bills} Mã HĐG`} type="warning" showIcon />}
                {hasNewExchangeRate && <Alert className={'mgbt10'} message={t('peer_payment.warningNewExchangeRate')} type="warning" showIcon />}
                <div className={'info-money mgbt10'}>
                    <div>{t('peer_payment.amount')}: <span className={'hl-red'}>¥{formatValue(getTotalAmount())}</span></div>
                    <div>{t('peer_payment.exchangedAmount')}: <span className={'hl-red'}>{moneyFormat(getExchangeAmount())}</span></div>
                    {getTopUpMoney() > 0 && (
                        <div>{t('peer_payment.topUpMoney')}: <span className={'hl-red'}>{moneyFormat(getTopUpMoney())}</span></div>
                    )}
                </div>


                <Table
                    columns={[
                        {
                            key: "code",
                            title: t('peer_payment.code'),
                            dataIndex: ['code'],
                            render: code => <span className={'code'}>{code}</span>
                        },
                        {
                            key: "foreignAmount",
                            title: <div className={'text-right'}>{t('peer_payment.amount')}</div>,
                            dataIndex: ['amount'],
                            render: (amount, row) => <div className={'text-right'}>{moneyFormat(amount, row.currency)}</div>
                        },
                        {
                            key: "exchangeRate",
                            title: <div className={'text-right'}>{t('peer_payment.exchangeRate')}</div>,
                            render: row => {
                                return (
                                    <div className={'text-right'}>{moneyFormat(1, row.currency)} = {moneyFormat(row.exchangeRate)}</div>
                                )
                            }
                        },
                        {
                            key: "exchangeAmount",
                            title: <div className={'text-right'}>{t('peer_payment.exchangedAmount')}</div>,
                            dataIndex: ['exchangedAmount'],
                            render: (amount) => {
                                return (
                                    <div className={'text-right'}>{moneyFormat(amount)}</div>
                                )
                            }
                        },
                        {
                            key: "newExchangeRate",
                            title: <div className={'text-right'}>{t('peer_payment.newExchangeRate')}</div>,
                            render: row => {
                                const exchangeRate = exchangeRatesByAmount.find(item => item.refId === `${row.amount}|${row.paymentMethodCode}`)
                                let newRate = row.exchangeRate
                                if (lodash.get(exchangeRate, 'exchangeRate.rate') !== row.exchangeRate) {
                                    newRate = lodash.get(exchangeRate, 'exchangeRate.rate')
                                }

                                return (
                                    <div className={`text-right ${hasNewExchangeRate ? 'new-exchange-hl' : ''}`}>{moneyFormat(1, row.currency)} = {moneyFormat(newRate)}</div>
                                )
                            }
                        },
                        {
                            key: "newExchangeAmount",
                            title: <div className={'text-right'}>{t('peer_payment.newExchangeAmount')}</div>,
                            dataIndex: ['exchangedAmount'],
                            render: (exchangedAmount, row) => {
                                const exchangeRate = exchangeRatesByAmount.find(item => item.refId === `${row.amount}|${row.paymentMethodCode}`)
                                const newRate = lodash.get(exchangeRate, 'exchangeRate.rate')
                                let newAmount = exchangedAmount;
                                if (newRate !== row.exchangeRate) {
                                    newAmount = row.amount * newRate
                                }

                                return (
                                    <div className={`text-right ${hasNewExchangeRate ? 'new-exchange-hl' : ''}`}>{moneyFormat(newAmount)}</div>
                                )
                            }
                        },
                    ]}
                    dataSource={items}
                    rowKey={'code'}
                    pagination={false}
                    scroll={{ y: 400 }}
                />

            </Modal>
        </>
    )
}
