/* eslint-disable array-callback-return */
import React from 'react'
import lodash from 'lodash'
import MainLayout from '../../components/Layout/MainLayout'
import ShipmentInfo from '../../components/ShipmentDetail/ShipmentInfo'
import TabLayout from '../../components/ShipmentDetail/TabLayout'
import Page404 from '../Page404'
import CategoriesService from '../../services/categories.service'
import ShipmentsService from '../../services/shipments.service'
import * as Constants from '../../constants/config'
import { LocalStore } from '../../utils/LocalStore'
import { Avatar, Checkbox, Col, Input, Layout, Modal, notification, Row, Spin } from 'antd'
import * as AntdIcon from '@ant-design/icons'
import AntdIconSVG from '@ant-design/icons'
import {
    formatValue,
    moneyCeil,
    moneyFormat,
    parseQueryStringToObject,
    quantityFormat,
    updateQueryStringParameter,
} from '../../utils/stringUtils'
import { getUrlQueryString } from '../../utils/helper'
import { withNamespaces } from 'react-i18next'
import Comment from '../../components/OrderDetail/Comment'
import uploadFile from '../../utils/api/uploadFile'
import UserService from '../../services/user.service'
import TicketService from '../../services/tickets.service'
import { ReactComponent as iconShipment } from '../../resources/images/ic_Kygui.svg'
import BifinService from '../../services/bifin.service'
import moment from 'moment'

let currentLoggedUser = null
const initialCommentPage = 0

class ShipmentDetail extends React.Component {
    constructor(props) {
        super(props)
        this.isUnmounted = false

        this.state = {
            paramCode: '',
            activeTab: Constants.ORDER_DETAIL_TAB_PRODUCT,
            isLoadingDetail: false,
            currentCommentTab: 'customer',
            orderDetail: null,
            //log
            initLoading: true,
            loadingMore: false,
            logs: [],
            page: 0,
            orderLogErrors: null,
            showLoadMore: false,

            warehousesDelivery: [],
            warehousesReceive: [],
            statusPackageList: [],
            // finance
            finance: [],
            isLoadingFinance: false,
            errFinance: null,
            peerPaymentInfo: null,
            peerPaymentStatuses: [],
            globalTenantConfig: {},
            loanCredits: [],
            listAttachments: []
        }
    }

    componentDidMount() {
        window.addEventListener('popstate', this.onPopState.bind(this))
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        if (
            !currentProjectInfo.tenantConfig ||
            !currentProjectInfo.tenantConfig.shipmentConfig ||
            !currentProjectInfo.tenantConfig.shipmentConfig.enable
        ) {
            this.props.history.push('/404')
        }
        if (typeof LocalStore.getInstance().read('currentLoggedUser') === 'object') {
            currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {}

            this.setState({ paramCode: this.props.match.params.id }, () => {
                this.getShipmentDetail()
                this.loadFromQueryString()
                this.getShipmentStatuses()
                this.getDeliveryWarehouses()
                this.getReceiveWarehouses()
                this.getStatusPackageList()
                this.getListFinancialClaim('claim')
                this.getListFinancialClaim('collect')
                this.getListHS()
                this.getListOriginalReceipts()
                this.getShipmentProducts()
                this.getTicketStatuses()
                this.getOrderTickets()
                this.getGlobalTenantConfig()
            })
        }
    }

    componentDidUpdate(prevProps, prevState){
        const { orderDetail } = this.state
        if(prevState?.orderDetail === null && orderDetail){
            this.loadDataComments(initialCommentPage)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.onPopState.bind(this))
    }

    getGlobalTenantConfig = () => {
        CategoriesService.getTenantConfigPayment((error, response) => {
            if (!error) {
                this.setState({
                    globalTenantConfig: response,
                })
            } else {
                this.setState({
                    globalTenantConfig: {},
                })
            }
        })
    }

    /**
     * On popstate
     */
    onPopState() {
        if (!this.isUnmounted)
            this.setState({ isLoading: true }, () => {
                this.loadFromQueryString()
            })
    }

    /**
     * Load từ QueryString (dành cho lần load đầu tiên)
     */
    loadFromQueryString = () => {
        let queryObj = parseQueryStringToObject(getUrlQueryString())

        const { paramCode } = this.state

        let href = window.location.href

        if (!this.isUnmounted) {
            let idString = href.split('/#/shipments/')[1]

            if (idString) {
                let id = idString.split('?')[0]

                if (id !== paramCode) {
                    this.setState(
                        {
                            paramCode: id,
                        },
                        () => {
                            this.getShipmentDetail()
                            this.loadFromQueryString()
                            this.getShipmentStatuses()
                            this.loadDataComments(initialCommentPage)
                            this.getDeliveryWarehouses()
                            this.getReceiveWarehouses()
                            this.getStatusPackageList()
                            this.getListFinancialClaim('claim')
                            this.getListFinancialClaim('collect')
                            this.getListHS()
                            this.getListOriginalReceipts()
                            this.getShipmentProducts()
                            this.getTicketStatuses()
                            this.getOrderTickets()
                        }
                    )
                }
            }
        }

        //load active tab
        if (queryObj.tab) {
            this.loadDataOnChangeTab(queryObj.tab)
        }
    }

    getThirdPatiesLoans = data => {
        let queryString = `?orderCodes=${data.code}`
        BifinService.getThirdPatiesLoans(queryString, (err, res) => {
            if (!err) {
                this.setState({
                    loanCredits: res?.loanCredits,
                })
            }
        })
    }

    getStatusPackageList = () => {
        CategoriesService.getShipmentParcelStatus((err, res, headers) => {
            if (!err) {
                this.setState({
                    statusPackageList: res,
                })
            }
        })
    }

    getListHS = () => {
        CategoriesService.getListHS((err, res, header) => {
            if (!err) {
                this.setState({
                    listHS: res || [],
                })
            }
        })
    }

    /**
     * lấy ra danh sách trạng thái đơn
     */
    getShipmentStatuses = () => {
        CategoriesService.getShipmentStatuses((err, res, headers) => {
            if (!err) {
                this.setState({
                    listStatuses: res,
                })
            }
        })
    }

    /**
     * lấy ra danh sách trạng thái đơn
     */
    getShipmentProducts = () => {
        const { paramCode } = this.state

        this.setState({ loadingProduct: true }, () => {
            ShipmentsService.getShipmentProducts(paramCode, (err, res, headers) => {
                if (!err) {
                    this.setState({
                        loadingProduct: false,
                        listProduct: lodash.sortBy(res, 'createdAt').reverse(),
                    })
                } else {
                    this.setState({ loadingProduct: false })
                }
            })
        })
    }

    getTablePrice = () => {
        const { orderDetail } = this.state
        let configGroupId = orderDetail.configGroupId
        CategoriesService.getShipmentFeesConfigGroup(configGroupId, (err, res, headers) => {
            if (!err) {
                this.setState({
                    shipmentFees: res,
                })
            }
        })
    }

    /**
     * lấy ra danh sách trạng thái đơn
     */
    getListOriginalReceipts = cb => {
        const { paramCode } = this.state
        ShipmentsService.getListOriginalReceipts(paramCode, (err, res, headers) => {
            if (!err) {
                this.setState(
                    {
                        listOriginalReceipts: lodash.sortBy(res, 'createdAt') || [],
                    },
                    () => {
                        if (cb) cb(lodash.sortBy(res, 'createdAt'))
                    }
                )
            }
        })
    }

    /**
     * lấy ra danh sách trạng thái đơn
     */
    addOriginalReceipts = (body, cb) => {
        const { t } = this.props
        const { paramCode, addOriginalReceiptsLoading, listOriginalReceipts = [] } = this.state
        if (addOriginalReceiptsLoading) return

        this.setState({ addOriginalReceiptsLoading: true }, () => {
            ShipmentsService.addOriginalReceipt(paramCode, body, (err, res, headers) => {
                if (!err) {
                    this.setState(
                        {
                            listOriginalReceipts: lodash.sortBy([...listOriginalReceipts, res], 'createdAt'),
                        },
                        () => {
                            if (cb) cb(lodash.sortBy([...listOriginalReceipts, res], 'createdAt'))
                        }
                    )
                } else {
                    if (err.title === 'duplicate_code') {
                        notification['error']({
                            message: t('shipment.OriginalReceipt_duplicate_code'),
                        })
                    } else if (err.title === 'action_do_not_allow') {
                        notification['error']({
                            message: t('waybill.action_do_not_allow'),
                        })
                    } else {
                        notification['error']({
                            message: t(err.message),
                        })
                    }
                }
                this.setState({ addOriginalReceiptsLoading: false })
            })
        })
    }

    /**
     * lấy ra danh sách trạng thái đơn
     */
    deleteOriginalReceipts = (body, cb) => {
        const { t } = this.props
        const { paramCode, deleteOriginalReceiptLoading } = this.state
        if (deleteOriginalReceiptLoading) return
        this.setState({ deleteOriginalReceiptLoading: true }, () => {
            ShipmentsService.deleteOriginalReceipt(paramCode, body, (err, res, headers) => {
                if (!err) {
                    this.getListOriginalReceipts(cb)
                } else {
                    if (err.title === 'action_do_not_allow') {
                        notification['error']({
                            message: t('waybill.action_do_not_allow'),
                        })
                    } else {
                        notification['error']({
                            message: t(err.message),
                        })
                    }
                }

                this.setState({ deleteOriginalReceiptLoading: false })
            })
        })
    }

    getShipmentDetail = (isLoading = true) => {
        const { paramCode } = this.state
        this.setState(
            {
                isLoadingDetail: isLoading,
                is404: false,
            },
            () => {
                ShipmentsService.getShipmentDetail(paramCode, (err, res) => {
                    if (!err) {
                        this.setState(
                            {
                                isLoadingDetail: false,
                                orderDetail: res,
                            },
                            () => {
                                this.getTablePrice()
                                res?.contractWithShopkeeper && this.getThirdPatiesLoans(res)
                            }
                        )

                        if (res.peerPaymentCode) {
                            this.getPeerPaymentDetail(res.peerPaymentCode)
                            this.getPeerPaymentStatuses()
                        }
                    } else {
                        if (err.status === 404) {
                            this.setState({
                                isLoadingDetail: false,
                                is404: true,
                            })
                        } else {
                            this.setState({
                                isLoadingDetail: false,
                            })
                        }
                    }
                })
            }
        )
    }

    getPeerPaymentDetail = peerPaymentCode => {
        UserService.getPeerPayment(peerPaymentCode, (error, response) => {
            if (!error) {
                this.setState({ peerPaymentInfo: response })
            }
        })
    }

    getPeerPaymentStatuses = () => {
        CategoriesService.getPaymentStatus((err, res) => {
            if (err === null) {
                this.setState({
                    peerPaymentStatuses: res || [],
                })
            }
        })
    }

    /**
     * Lấy danh sách giao dịch trong chi tiết đơn
     */
    getListFinancialClaim = type => {
        const { paramCode } = this.state
        this.setState(
            {
                isLoadingListFinancialClaim: true,
            },
            () => {
                ShipmentsService.getListFinancialOrder(paramCode, type, (err, res, headers) => {
                    if (!err) {
                        if (type === 'claim') {
                            this.setState({
                                listFinancialClaim: res,
                                metadataFinancialClaim: {
                                    pageCount: headers['x-page-count'],
                                    page: headers['x-page-number'],
                                    size: headers['x-page-size'],
                                    total: headers['x-total-count'],
                                },
                            })
                        } else {
                            this.setState({
                                listFinancialCollect: res,
                                metadataFinancialCollect: {
                                    pageCount: headers['x-page-count'],
                                    page: headers['x-page-number'],
                                    size: headers['x-page-size'],
                                    total: headers['x-total-count'],
                                },
                            })
                        }
                    }
                    this.setState({ isLoadingListFinancialClaim: false })
                })
            }
        )
    }

    /**
     * Lấy danh sách kho phân phối
     */
    getDeliveryWarehouses = () => {
        CategoriesService.getWarehouseDelivery((err, res, headers) => {
            if (!err) {
                this.setState({ warehousesDelivery: res })
            }
        })
    }

    /**
     * Lấy danh sách kho nhận
     */
    getReceiveWarehouses = () => {
        CategoriesService.getWarehouseReceive((err, res, headers) => {
            if (!err) {
                this.setState({ warehousesReceive: res })
            }
        })
    }

    getFinance = () => {
        const { paramCode } = this.state
        this.setState({ isLoadingFinance: true, errFinance: null }, () => {
            ShipmentsService.getFinance(paramCode, (err, res, header) => {
                if (!err) {
                    this.setState({ finance: res, isLoadingFinance: false })
                } else {
                    this.setState({ errFinance: err, isLoadingFinance: false })
                }
            })
        })
    }

    /**
     * lấy ra danh sách Log
     */
    getOrderLog = () => {
        const { paramCode } = this.state
        this.setState({ initLoading: true, orderLogErrors: null, logs: [] }, () => {
            ShipmentsService.getLog(paramCode, 0, (err, res, header) => {
                if (!err) {
                    let total = lodash.get(header, 'x-total-count')
                    let showMore = true
                    if (res.length === parseInt(total)) {
                        showMore = false
                    }
                    this.setState({
                        initLoading: false,
                        page: 1,
                        logs: this.parseLog(res),
                        showLoadMore: showMore,
                    })
                } else {
                    this.setState({ initLoading: false, orderLogErrors: err })
                }
            })
        })
    }

    onLoadMore = () => {
        const { paramCode } = this.state
        this.setState({ loadingMore: true, orderLogErrors: null }, () => {
            ShipmentsService.getLog(paramCode, this.state.page, (err, res, header) => {
                if (!err) {
                    let currentPage = lodash.get(header, 'x-page-number', null)
                    let pageCount = lodash.get(header, 'x-page-count', null)

                    let showMore = true
                    if (parseInt(currentPage) === parseInt(pageCount) - 1) {
                        showMore = false
                    }
                    let list = this.state.logs.concat(this.parseLog(res))
                    this.setState({
                        logs: list,
                        loadingMore: false,
                        page: parseInt(currentPage) + 1,
                        showLoadMore: showMore,
                    })
                } else {
                    this.setState({ loadingMore: false, orderLogErrors: err })
                }
            })
        })
    }

    parseLog = items => {
        const { t } = this.props
        let { orderDetail, warehousesDelivery, warehousesReceive, listHS = [] } = this.state
        let warehouses = [...warehousesDelivery, warehousesReceive]
        let currency = lodash.get(orderDetail, 'currency')
        let logs = []
        items.map(item => {
            let itemTransform = {}
            itemTransform.fullname = item.actor.fullname ? item.actor.fullname : '---'
            itemTransform.timestamp = item.timestamp ? item.timestamp : '---'
            itemTransform.role = item.role
            switch (item.activity) {
                case 'SHIPMENT_WAYBILL_CREATE':
                case 'SHIPMENT_WAYBILL_DELETE':
                case 'SHIPMENT_PACKAGE_CREATE':
                case 'SHIPMENT_PACKAGE_DELETE':
                case 'SHIPMENT_DECLARE_VALUE_CREATE':
                case 'SHIPMENT_DECLARE_VALUE_DELETE':
                    itemTransform.property = item.activity
                    if (item.activity === 'SHIPMENT_DECLARE_VALUE_CREATE' || item.activity === 'SHIPMENT_DECLARE_VALUE_DELETE')
                        itemTransform.value =
                            item.data && item.data.declareValue !== null ? moneyFormat(item.data.declareValue) : ''
                    else itemTransform.value = lodash.get(item.data, 'code', '---')
                    break
                case 'SHIPMENT_STATUS_UPDATE':
                    itemTransform.property = item.activity
                    itemTransform.newValue = lodash.get(
                        Array.isArray(item.data) ? item.data[0] : item.data,
                        'newValue.name',
                        '---'
                    )
                    itemTransform.oldValue = lodash.get(
                        Array.isArray(item.data) ? item.data[0] : item.data,
                        'oldValue.name',
                        '---'
                    )
                    break
                case 'SHIPMENT_DECLARE_VALUE_UPDATE':
                    itemTransform.property = item.activity
                    itemTransform.newValue =
                        item.data && item.data[0] && item.data[0].newValue !== null ? moneyFormat(item.data[0].newValue) : '---'
                    itemTransform.oldValue =
                        item.data && item.data[0] && item.data[0].oldValue !== null ? moneyFormat(item.data[0].oldValue) : '---'
                    break
                case 'SHIPMENT_PACKAGE_UPDATE':
                    if (Array.isArray(item.data)) itemTransform.logs = []
                    if (item.data && Array.isArray(item.data)) {
                        item.data.forEach(ele => {
                            if (ele.property) {
                                let unit = ''
                                if (ele.property === 'volumetric') unit = ' cm3'
                                else if (
                                    ele.property === 'netWeight' ||
                                    ele.property === 'dimensionalWeight' ||
                                    ele.property === 'packagingWeight' ||
                                    ele.property === 'actualWeight'
                                ) {
                                    unit = ' kg'
                                }

                                itemTransform.logs.push({
                                    fullname: item.actor.fullname ? item.actor.fullname : '---',
                                    role: item.role,
                                    timestamp: item.timestamp ? item.timestamp : '---',
                                    property: `SHIPMENT_PACKAGE_UPDATE_${ele.property}`,
                                    name: lodash.get(item.reference, 'code', '---'),
                                    oldValue: ele.oldValue
                                        ? lodash.isNumber(ele.oldValue)
                                            ? formatValue(ele.oldValue) + unit
                                            : ele.property === 'status'
                                            ? ele.oldValue.name
                                            : ele.oldValue
                                        : ele.oldValue === 0
                                        ? 0 + unit
                                        : '---',
                                    newValue: ele.newValue
                                        ? lodash.isNumber(ele.newValue)
                                            ? formatValue(ele.newValue) + unit
                                            : ele.property === 'status'
                                            ? ele.newValue.name
                                            : ele.newValue
                                        : ele.newValue === 0
                                        ? 0 + unit
                                        : '---',
                                })
                            }
                        })
                    } else {
                        itemTransform.property = `SHIPMENT_PACKAGE_UPDATE_${item.data.property}`
                        itemTransform.oldValue = item.data
                            ? lodash.get(item.data, 'oldValue.name', '---')
                            : item.data.oldValue
                            ? item.data.oldValue
                            : '---'
                        itemTransform.newValue = item.data
                            ? lodash.get(item.data, 'newValue.name', '---')
                            : item.data.newValue
                            ? item.data.newValue
                            : '---'
                        itemTransform.name = lodash.get(item.reference, 'code', '---')
                    }
                    break
                case 'SHIPMENT_FEE_CREATED':
                    itemTransform.property = item.activity
                    itemTransform.value = lodash.get(item.data, 'type.name', '---')
                    itemTransform.amount = moneyFormat(lodash.get(item, 'data.actualAmount', t('shipment_log.empty')))
                    itemTransform.reason = lodash.get(item.data, 'reason', '---')
                    break
                case 'SHIPMENT_FEE_UPDATE':
                    itemTransform.logs = []
                    item.data.forEach((ele, index) => {
                        itemTransform.name = lodash.get(item.reference, 'type.name', '---')
                        if (ele.property === 'reason') {
                            t('shipment_log.empty')
                            itemTransform.logs.push({
                                fullname: item.actor.fullname ? item.actor.fullname : '---',
                                role: item.role,
                                timestamp: item.timestamp ? item.timestamp : '---',
                                oldValue: ele.oldValue ? ele.oldValue : t('shipment_log.empty'),
                                newValue: ele.newValue,
                                name: lodash.get(item.reference, 'type.name', '---'),
                                property: 'SHIPMENT_FEE_UPDATE_REASON',
                            })
                        } else if (ele.property === 'free' && ele.newValue !== ele.oldValue) {
                            if (ele.newValue === true) itemTransform.value = t('shipment_log.free')
                            else if (ele.newValue === false) itemTransform.value = t('shipment_log.cancel_free')
                            itemTransform.logs.push({
                                fullname: item.actor.fullname ? item.actor.fullname : '---',
                                role: item.role,
                                name: itemTransform.name,
                                timestamp: item.timestamp ? item.timestamp : '---',
                                reason: itemTransform.reason,
                                value: itemTransform.value,
                                property: 'SHIPMENT_FEE_UPDATE_FREE',
                            })
                        }
                        if (ele.property === 'amount') {
                            itemTransform.property = 'SHIPMENT_FEE_UPDATE_AMOUNT'
                            itemTransform.newValue = moneyFormat(ele.newValue)
                            itemTransform.oldValue = moneyFormat(ele.oldValue)
                            itemTransform.logs.push({
                                fullname: item.actor.fullname ? item.actor.fullname : '---',
                                role: item.role,
                                name: itemTransform.name,
                                reason: itemTransform.reason,
                                timestamp: item.timestamp ? item.timestamp : '---',
                                newValue: itemTransform.newValue,
                                oldValue: itemTransform.oldValue,
                                property: 'SHIPMENT_FEE_UPDATE_AMOUNT',
                            })
                        }
                    })
                    break
                case 'SHIPMENT_SERVICE_UPDATE':
                    itemTransform.logs = []
                    if (lodash.get(item.data, '0.type') === 'SIMPLE_VALUE_CHANGE') {
                        itemTransform.logs.push({
                            fullname: item.actor.fullname ? item.actor.fullname : '---',
                            role: item.role,
                            timestamp: item.timestamp ? item.timestamp : '---',
                            property: `${item.activity}_SIMPLE_VALUE_CHANGE`,
                        })
                    } else {
                        lodash.get(item.data, '0.addedValues').forEach((ele, index) => {
                            if (ele)
                                itemTransform.logs.push({
                                    fullname: item.actor.fullname ? item.actor.fullname : '---',
                                    role: item.role,
                                    timestamp: item.timestamp ? item.timestamp : '---',
                                    property: `${item.activity}_ADD`,
                                    addValue: ele.name,
                                })
                        })
                        lodash.get(item.data, '0.removedValues').forEach((ele, index) => {
                            if (ele)
                                itemTransform.logs.push({
                                    fullname: item.actor.fullname ? item.actor.fullname : '---',
                                    role: item.role,
                                    timestamp: item.timestamp ? item.timestamp : '---',
                                    property: `${item.activity}_REMOVE`,
                                    removeValue: ele.name,
                                })
                        })
                    }
                    break
                case 'SHIPMENT_SERVICE_APPROVED':
                    itemTransform.logs = []
                    item.data.forEach(ele => {
                        itemTransform.logs.push({
                            fullname: item.actor.fullname ? item.actor.fullname : '---',
                            role: item.role,
                            timestamp: item.timestamp ? item.timestamp : '---',
                            property: ele.newValue ? `${item.activity}_ADD` : `${item.activity}_REMOVE`,
                            service: item.reference.name,
                        })
                    })
                    break
                case 'SHIPMENT_ADDRESS_UPDATE':
                    if (Array.isArray(item.data)) itemTransform.logs = []
                    item.data &&
                        item.data.forEach((ele, index) => {
                            if (ele.property === 'location' && ele.oldValue.display !== ele.newValue.display) {
                                itemTransform.logs.push({
                                    fullname: lodash.get(item.actor, 'fullname', '---'),
                                    role: lodash.get(item, 'role', '---'),
                                    timestamp: lodash.get(item, 'timestamp', '---'),
                                    property: 'SHIPMENT_ADDRESS_UPDATE_LOCATION',
                                    oldValue: lodash.get(ele, 'oldValue.display', 'trống'),
                                    newValue: lodash.get(ele, 'newValue.display', 'trống'),
                                })
                            } else if (ele.property === 'note') {
                                if (
                                    (ele.oldValue &&
                                        ele.newValue &&
                                        ele.oldValue.toString().trim() !== ele.newValue.toString().trim()) ||
                                    (!ele.oldValue && ele.newValue && ele.newValue.toString().trim() !== '')
                                )
                                    itemTransform.logs.push({
                                        fullname: lodash.get(item.actor, 'fullname', '---'),
                                        role: lodash.get(item, 'role', '---'),
                                        timestamp: lodash.get(item, 'timestamp', '---'),
                                        property: 'SHIPMENT_ADDRESS_UPDATE_note',
                                        oldValue: ele.oldValue && ele.oldValue.trim() !== '' ? ele.oldValue : 'trống',
                                        newValue: ele.newValue && ele.newValue.trim() !== '' ? ele.newValue : 'trống',
                                    })
                            } else if (
                                ele.property &&
                                ele.oldValue &&
                                ele.newValue &&
                                ele.oldValue.toString() !== ele.newValue.toString()
                            ) {
                                itemTransform.logs.push({
                                    fullname: lodash.get(item.actor, 'fullname', '---'),
                                    role: lodash.get(item, 'role', '---'),
                                    timestamp: lodash.get(item, 'timestamp', '---'),
                                    property: `SHIPMENT_ADDRESS_UPDATE_${ele.property}`,
                                    oldValue: lodash.get(ele, 'oldValue', '---'),
                                    newValue: lodash.get(ele, 'newValue', '---'),
                                })
                            }
                        })
                    break
                case 'SHIPMENT_UPDATE':
                    if (Array.isArray(item.data) && item.data.length > 0) {
                        itemTransform.property = lodash.get(item.data, '0.property')
                        if (itemTransform.property === 'hsCode') {
                            itemTransform.oldValue = lodash.get(
                                lodash.filter(listHS, { code: item.data[0].oldValue }),
                                '0.name',
                                '---'
                            )
                            itemTransform.newValue = lodash.get(
                                lodash.filter(listHS, { code: item.data[0].newValue }),
                                '0.name',
                                '---'
                            )
                        } else if (itemTransform.property === 'expectedPackages' || itemTransform.property === 'actualPackages') {
                            itemTransform.oldValue = quantityFormat(item.data[0].oldValue)
                            itemTransform.newValue = quantityFormat(item.data[0].newValue)
                        } else if (itemTransform.property === 'declareValue') {
                            itemTransform.oldValue = moneyFormat(moneyCeil(item.data[0].oldValue))
                            itemTransform.newValue = moneyFormat(moneyCeil(item.data[0].newValue))
                        } else if (itemTransform.property === 'refShipmentCode') {
                            itemTransform.oldValue = item.data[0].oldValue || '---'
                            itemTransform.newValue = item.data[0].newValue || '---'
                        } else if (itemTransform.property === 'refCustomerCode') {
                            itemTransform.oldValue = item.data[0].oldValue || '---'
                            itemTransform.newValue = item.data[0].newValue || '---'
                        } else if (itemTransform.property === 'staffNote') {
                            itemTransform.oldValue = item.data[0].oldValue || '---'
                            itemTransform.newValue = item.data[0].newValue || '---'
                        } else {
                            itemTransform.oldValue = lodash.get(
                                lodash.filter(warehouses, { code: item.data[0].oldValue }),
                                '0.name',
                                '---'
                            )
                            itemTransform.newValue = lodash.get(
                                lodash.filter(warehouses, { code: item.data[0].newValue }),
                                '0.name',
                                '---'
                            )
                        }
                    }
                    break
                case 'payment':
                case 'claim':
                case 'emd':
                case 'refund':
                case 'collect':
                case 'gift':
                case 'deposit':
                case 'charge':
                case 'withdraw':
                case 'adjust':
                case 'SHIPMENT_CANCELLED':
                    itemTransform.reason = item.memo ? item.memo : '---'
                    itemTransform.property = item.activity
                    if (item.activity === 'emd')
                        itemTransform.amount = item.amount && moneyFormat(item.amount).toString().replace('-', '')
                    else itemTransform.amount = item.amount && moneyFormat(item.amount)
                    break
                case 'SHIPMENT_COUPON_APPLY':
                    itemTransform.property = item.activity
                    itemTransform.content = item.data ? item.data.code + ' - ' + item.data.description : ''
                    break
                case 'SHIPMENT_PRODUCT_DELETE':
                case 'SHIPMENT_PRODUCT_CREATE':
                    itemTransform.property = item.activity
                    itemTransform.value = lodash.get(item.data, 'name', '---')
                    itemTransform.code = lodash.get(item.data, 'code', '---')
                    break
                case 'SHIPMENT_PRODUCT_UPDATE':
                    if (item.data.length > 0) {
                        // purchasedQuantity, receivedQuantity,
                        if (Array.isArray(item.data)) itemTransform.logs = []
                        item.data.forEach((ele, index) => {
                            if (ele.property === 'unitPrice' || ele.property === 'quantity') {
                                itemTransform.logs.push({
                                    fullname: lodash.get(item.actor, 'fullname', '---'),
                                    role: lodash.get(item, 'role', '---'),
                                    timestamp: lodash.get(item, 'timestamp', '---'),
                                    property: `SHIPMENT_PRODUCT_UPDATE_${ele.property}`,
                                    oldValue:
                                        ele.property === 'quantity'
                                            ? quantityFormat(lodash.get(ele, 'oldValue', ''))
                                            : moneyFormat(moneyCeil(lodash.get(ele, 'oldValue', undefined)), currency),
                                    newValue:
                                        ele.property === 'quantity'
                                            ? quantityFormat(lodash.get(ele, 'newValue', ''))
                                            : moneyFormat(moneyCeil(lodash.get(ele, 'newValue', undefined)), currency),
                                    name: lodash.get(item.reference, 'code', '---'),
                                })
                            } else {
                                itemTransform.logs.push({
                                    fullname: lodash.get(item.actor, 'fullname', '---'),
                                    role: lodash.get(item, 'role', '---'),
                                    timestamp: lodash.get(item, 'timestamp', '---'),
                                    property: `SHIPMENT_PRODUCT_UPDATE_${ele.property}`,
                                    oldValue: ele.oldValue ? ele.oldValue : '---',
                                    newValue: ele.newValue ? ele.newValue : '---',
                                    name: lodash.get(item.reference, 'code', '---'),
                                })
                            }
                        })
                    }
                    break
                case 'SHIPMENT_RECEIPT_DELETE':
                case 'SHIPMENT_RECEIPT_CREATE':
                    itemTransform.property = item.activity
                    itemTransform.code = lodash.get(item.data, 'code', '---')
                    break
                default:
                    break
            }
            if (itemTransform.logs && itemTransform.logs.length > 0) logs.push(...itemTransform.logs)
            else if (itemTransform.property) logs.push(itemTransform)
        })
        return logs
    }

    /**
     * lấy ra danh sách Fee
     */
    getShipmentsFees = () => {
        const { paramCode } = this.state
        this.setState({ isLoadingFees: true, showProgressBar: true }, () => {
            ShipmentsService.getShipmentFees(paramCode, (err, res, headers) => {
                if (err === null) {
                    this.setState({
                        isLoadingFees: false,
                        showProgressBar: false,
                        listFees: res || [],
                        listFeesMetadata: {
                            pageCount: headers['x-page-count'],
                            page: headers['x-page-number'],
                            size: headers['x-page-size'],
                            total: headers['x-total-count'],
                        },
                    })
                } else {
                    this.setState({ showProgressBar: false, isLoadingFees: false })
                }
            })
        })
    }

    /**
     * lấy ra danh sách Mã vận đơn
     */
    getShipmentsWaybill = () => {
        const { paramCode } = this.state
        this.setState({ isLoadingWaybill: true, showProgressBar: true }, () => {
            ShipmentsService.getShipmentWaybill(paramCode, (err, res, headers) => {
                if (err === null) {
                    this.setState({
                        isLoadingWaybill: false,
                        showProgressBar: false,
                        listWaybills: res || [],
                        listWaybillMetadata: {
                            pageCount: headers['x-page-count'],
                            page: headers['x-page-number'],
                            size: headers['x-page-size'],
                            total: headers['x-total-count'],
                        },
                    })
                } else {
                    this.setState({ showProgressBar: false, isLoadingWaybill: false })
                }
            })
        })
    }

    /**
     * lấy ra danh sách Mã vận đơn
     */
    getShipmentParcels = () => {
        const { paramCode } = this.state
        this.setState({ isLoadingWaybillParcels: true }, () => {
            ShipmentsService.getShipmentParcel(paramCode, (err, res, headers) => {
                if (err === null) {
                    this.setState({
                        isLoadingWaybillParcels: false,
                        listWaybillParcels: res || [],
                        listWaybillParcelMetadata: {
                            pageCount: headers['x-page-count'],
                            page: headers['x-page-number'],
                            size: headers['x-page-size'],
                            total: headers['x-total-count'],
                        },
                    })
                } else {
                    this.setState({ isLoadingWaybillParcels: false })
                }
            })
        })
    }

    /**
     * tạo mã vận đơn
     * @param code
     * @param cb
     */
    createWaybill = (code, cb) => {
        const { t } = this.props
        const { submittingWaybill, paramCode, listWaybills } = this.state
        this.setState({ submittingWaybill: true }, () => {
            if (!submittingWaybill) {
                ShipmentsService.createShipmentWaybill(paramCode, { code }, (err, res, header) => {
                    if (!err) {
                        this.setState({
                            listWaybills: [...listWaybills, res],
                            submittingWaybill: false,
                        })
                        notification.success({
                            message: t('waybill.create_success'),
                        })
                        if (cb) cb()
                    } else {
                        if (err.title === 'duplicate_code') {
                            notification['error']({
                                message: t('waybill.duplicate_code'),
                            })
                        } else if (err.title === 'action_do_not_allow') {
                            notification['error']({
                                message: t('waybill.action_do_not_allow'),
                            })
                        } else {
                            notification['error']({
                                message: t(err.message),
                            })
                        }
                        this.setState({
                            submittingWaybill: false,
                        })
                    }
                })
            }
        })
    }

    /**
     * tạo mã vận đơn
     * @param code
     * @param cb
     */
    deleteWaybill = (code, cb) => {
        const { t } = this.props
        const { deletingWaybill, paramCode } = this.state
        this.setState({ deletingWaybill: true }, () => {
            if (!deletingWaybill) {
                ShipmentsService.deleteShipmentWaybill(paramCode, code, (err, res, header) => {
                    if (!err) {
                        notification.success({
                            message: t('waybill.delete_success'),
                        })
                        this.setState({
                            deletingWaybill: false,
                        })
                        this.getShipmentsWaybill()
                        this.getShipmentParcels()
                    } else {
                        if (err.title === 'action_do_not_allow') {
                            notification['error']({
                                message: t('waybill.action_do_not_allow'),
                            })
                        } else {
                            notification['error']({
                                message: t(err.message),
                            })
                        }
                        this.setState({
                            deletingWaybill: false,
                        })
                    }
                })
            }
        })
    }

    deleteProduct = productCode => {
        const { t } = this.props
        const { productDeleting, paramCode } = this.state
        if (productDeleting) return
        this.setState(
            {
                productDeleting: true,
            },
            () => {
                ShipmentsService.deleteShipmentProducts(paramCode, productCode, (err, res, headers) => {
                    if (!err) {
                        notification.success({
                            message: t('message.delete_success'),
                        })
                        this.setState({
                            productDeleting: false,
                        })
                        this.getShipmentProducts()
                    } else {
                        if (err.title === 'action_do_not_allow') {
                            notification['error']({
                                message: t('waybill.action_do_not_allow'),
                            })
                        } else {
                            notification['error']({
                                message: t(err.message),
                            })
                        }
                        this.setState({
                            productDeleting: false,
                        })
                    }
                })
            }
        )
    }

    createProduct = (data, originData, cb) => {
        const { t } = this.props
        const { productCreating, paramCode } = this.state
        if (productCreating) return
        this.setState(
            {
                productCreating: true,
            },
            () => {
                if (originData && originData.code) {
                    ShipmentsService.editShipmentProducts(paramCode, originData.code, data, (err, res, headers) => {
                        if (!err) {
                            notification.success({
                                message: t('forgot_pass.success'),
                            })
                            this.setState({
                                productCreating: false,
                            })
                            this.getShipmentProducts()

                            if (cb) cb()
                        } else {
                            if (err.title === 'action_do_not_allow') {
                                notification['error']({
                                    message: t('waybill.action_do_not_allow'),
                                })
                            } else {
                                notification['error']({
                                    message: t(err.message),
                                })
                            }
                            this.setState({
                                productCreating: false,
                            })
                        }
                    })
                } else {
                    ShipmentsService.createShipmentProducts(paramCode, data, (err, res, headers) => {
                        if (!err) {
                            notification.success({
                                message: t('forgot_pass.success'),
                            })
                            this.setState({
                                productCreating: false,
                            })
                            this.getShipmentProducts()

                            if (cb) cb()
                        } else {
                            if (err.title === 'action_do_not_allow') {
                                notification['error']({
                                    message: t('waybill.action_do_not_allow'),
                                })
                            } else {
                                notification['error']({
                                    message: t(err.message),
                                })
                            }
                            this.setState({
                                productCreating: false,
                            })
                        }
                    })
                }
            }
        )
    }

    /**
     * Thay đổi tabbar
     * @param key
     */
    onChangedTab = key => {
        this.setState(
            {
                activeTab: key,
            },
            () => {
                // this.loadDataOnChangeTab(key);
                let queryString = updateQueryStringParameter('', 'tab', key)
                this.props.history.push({
                    pathname: this.props.location.pathname,
                    search: queryString,
                })
            }
        )
    }
    /**
     * Lấy danh sách lịch sử các mốc đặt hàng trong đơn
     */
    getListMilestones = () => {
        const { paramCode } = this.state
        this.setState(
            {
                isLoadingListMilestones: true,
            },
            () => {
                ShipmentsService.getShipmentMileStones(paramCode, (err, res) => {
                    if (!err) {
                        this.setState({
                            listMilestones: res,
                        })
                    }
                    this.setState({ isLoadingListMilestones: false })
                })
            }
        )
    }

    /**
     * lấy ra danh sách trạng thái của package
     */
    getTicketStatuses = () => {
        TicketService.getPublicStates((err, res, headers) => {
            if (!err) {
                this.setState({
                    listStatusesTicket: res,
                })
            }
        })
    }

    /**
     * Lấy danh sách các khiếu nại thuộc đơn
     */
    getOrderTickets = page => {
        const { paramCode } = this.state

        const queryString = `?page=${page ? page - 1 : 0}&size=10000&sort=createdAt:desc`

        this.setState(
            {
                isLoadingListTickets: true,
            },
            () => {
                ShipmentsService.getShipmentTickets(paramCode, queryString, (err, res, headers) => {
                    if (!err) {
                        this.setState({
                            listTickets: res,
                            orderTicketsMetadata: {
                                pageCount: headers['x-page-count'],
                                page: headers['x-page-number'],
                                size: headers['x-page-size'],
                                total: headers['x-total-count'],
                            },
                        })
                    }
                    this.setState({ isLoadingListTickets: false })
                })
            }
        )
    }

    /**
     * lấy dữ liệu của tab khi chuyển tab
     */
    loadDataOnChangeTab = key => {
        this.setState(
            {
                activeTab: key,
            },
            () => {
                // load dữ liệu nếu chuyển sang tab Packages
                if (key === Constants.SHIPMENT_DETAIL_TAB_WAYBILLS) {
                    this.getShipmentsWaybill()
                    this.getShipmentParcels()
                } else if (key === Constants.SHIPMENT_DETAIL_TAB_FEES) {
                    this.getShipmentsFees()
                } else if (key === Constants.SHIPMENT_DETAIL_TAB_FINANCE) {
                    this.getFinance()
                } else if (key === Constants.SHIPMENT_DETAIL_TAB_LOG) {
                    this.getOrderLog()
                } else if (key === Constants.SHIPMENT_DETAIL_TAB_HISTORY) {
                    this.getListMilestones()
                }
            }
        )
    }

    /**
     * lấy ra danh sách comment theo paramCode
     */
    loadDataComments = (page) => {
        let { listComments = [], commentLoading, orderDetail } = this.state
        if (page === undefined || page === null || page === 0) {
            page = 0
            listComments = []
        }
        if (commentLoading) return
        const { paramCode } = this.state
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const taiyiConfig = lodash.get(currentProjectInfo, 'tenantConfig.taiyiConfig.enabled')
        const enableTime = lodash.get(currentProjectInfo, 'tenantConfig.taiyiConfig.updateToNewCommentTime')
        const checkTimeNew = moment(orderDetail?.createdAt)?.isAfter(moment(enableTime))

        this.setState({ commentLoading: true, showProgressBar: true }, () => {
            if (taiyiConfig && checkTimeNew) {
                ShipmentsService.getCommentsPosedon(paramCode, page, (err, res, headers) => {
                    if (err === null) {
                        this.setState(
                            {
                                commentLoading: false,
                                showProgressBar: false,
                                listComments: [...listComments, ...res],
                                listCommentsMetadata: {
                                    pageCount: headers['x-page-count'],
                                    page: headers['x-page-number'],
                                    size: headers['x-page-size'],
                                    total: headers['x-total-count'],
                                },
                            },
                            () => {
                                if (this.commentComponent) {
                                    if (page === 0) {
                                        this.commentComponent.chatbox.scrollTop = 0
                                    }
                                }
                            }
                        )
                    } else {
                        this.setState({ commentLoading: false, showProgressBar: false })
                    }
                })
                ShipmentsService.getAttachmentPosedon(paramCode, (err, res, headers) => {
                    if (err === null) {
                        this.setState(
                            {
                                listAttachments: res,
                            },
                        )
                    } 
                })
            } else {
                ShipmentsService.getComments(paramCode, page, (err, res, headers) => {
                    if (err === null) {
                        this.setState(
                            {
                                commentLoading: false,
                                showProgressBar: false,
                                listComments: [...listComments, ...res],
                                listCommentsMetadata: {
                                    pageCount: headers['x-page-count'],
                                    page: headers['x-page-number'],
                                    size: headers['x-page-size'],
                                    total: headers['x-total-count'],
                                },
                            },
                            () => {
                                if (this.commentComponent) {
                                    if (page === 0) {
                                        this.commentComponent.chatbox.scrollTop = 0
                                    }
                                }
                            }
                        )
                    } else {
                        this.setState({ commentLoading: false, showProgressBar: false })
                    }
                })
            }
        })
    }

    /**
     * upload ảnh comment
     * @param data
     * @param cb
     */
    uploadImageComment = (data = {}, cb) => {
        const { t } = this.props
        const { isCommenting, isUploading, paramCode, orderDetail } = this.state

        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const taiyiConfig = lodash.get(currentProjectInfo, 'tenantConfig.taiyiConfig.enabled')
        const enableTime = lodash.get(currentProjectInfo, 'tenantConfig.taiyiConfig.updateToNewCommentTime')
        const checkTimeNew = moment(orderDetail?.createdAt)?.isAfter(moment(enableTime))
        if (
            isCommenting === true ||
            isUploading === true ||
            (data?.content?.trim().replace(/<[^>]*>?/gm, '')?.length === 0 && data?.files?.length === 0)
        )
            return

        if (taiyiConfig && checkTimeNew) {
            if (data?.files?.length > 0) {
                this.setState({
                    isUploading: true,
                })
                let apiServiceUrl = `/customer/comments/attachments`
                uploadFile(
                    apiServiceUrl,
                    data.files || [],
                    {
                        command: {
                            module: 'CUSTOMER_SHIPMENT_MODULE',
                            thread: paramCode,
                        },
                    },
                    'attachment'
                ).then(response => {
                    let content = ''
                    if (response?.mimeType?.indexOf('image/') >= 0) {
                        content = `<img src="${response?.location}" alt="${response.name}"></img>`
                    } else {
                        content = `<a target="_blank" href="${response?.location}"><i class="fa-solid fa-paperclip"></i> ${response.name}</a>`
                    }
                    ShipmentsService.createCommentInShipment(paramCode, { content }, (err, res, headers) => {
                        if (!err) {
                            this.loadDataComments()
                            this.setState({
                                isUploading: false,
                            })
                        } else {
                            notification['error']({
                                message: t(err.message),
                            })
                            this.setState({ isUploading: false, isCommenting: false })
                        }
                    })
                })
            } else {
                this.setState({
                    isCommenting: true,
                })
                ShipmentsService.createCommentInShipment(paramCode, { content: data.content?.trim() }, (err, res, headers) => {
                    if (!err) {
                        this.setState(
                            {
                                isCommenting: false,
                            },
                            () => {
                                this.loadDataComments()
                            }
                        )
                    } else {
                        notification['error']({
                            message: t(err.message),
                        })
                        this.setState({ isUploading: false, isCommenting: false })
                    }
                })
            }
        } else {
            this.setState({ isUploading: true, isCommenting: true }, () => {
                let apiServiceUrl = `/customer/shipments/${paramCode}/comments`
                let body = data.content ? { comment: { content: data.content.trim() } } : null

                uploadFile(apiServiceUrl, data.files || [], body)
                    .then(response => {
                        this.setState(
                            {
                                isCommenting: false,
                                isUploading: false,
                            },
                            () => {
                                if (cb) cb()
                                this.loadDataComments()
                            }
                        )
                    })
                    .catch(error => {
                        notification['error']({
                            message: t(error.message),
                        })
                        this.setState({ isUploading: false, isCommenting: false })
                    })
            })
        }
    }

    /**
     * next comment page
     */
    nextCommentPage = () => {
        const { listCommentsMetadata } = this.state
        if (!listCommentsMetadata) return
        if (parseFloat(listCommentsMetadata.page) + 1 < parseFloat(listCommentsMetadata.pageCount)) {
            this.loadDataComments(parseFloat(listCommentsMetadata.page) + 1)
        }
    }

    /**
     * toggle tab
     * @param tab
     */
    toggleCommentTab = tab => {
        const { currentCommentTab } = this.state
        if (tab === currentCommentTab) return
        this.setState(
            {
                currentCommentTab: tab,
                listComments: [],
            },
            () => {
                this.loadDataComments(0)
            }
        )
    }

    confirmReceivedOrder = cb => {
        const { paramCode, isSubmit, activeTab } = this.state
        const { t } = this.props
        if (isSubmit === true) return
        this.setState({ showProgressBar: true, isSubmit: true }, () => {
            ShipmentsService.confirmReceived(paramCode, (err, res, headers) => {
                if (!err) {
                    this.getShipmentDetail()
                    if (activeTab === Constants.SHIPMENT_DETAIL_TAB_WAYBILLS) {
                        this.getShipmentsWaybill()
                        this.getShipmentParcels()
                    }
                    notification['success']({
                        message: t('orderDetail.successfully_change_status'),
                    })
                } else {
                    if (err.title === 'shipment_status_not_allow') {
                        notification['error']({
                            message: t('error.shipment_status_not_allow'),
                        })
                    } else {
                        if (err.statusCode !== 'Network fail')
                            notification['error']({
                                message: t(err.message),
                            })
                    }
                }
                this.setState({ showProgressBar: false, isSubmit: false })
            })
        })
    }

    /**
     * xác nhận hủy đơn
     */
    confirmCancelOrder = () => {
        const { paramCode, isSubmit } = this.state
        const { t } = this.props
        if (isSubmit === true) return
        this.setState({ showProgressBar: true, isSubmit: true }, () => {
            ShipmentsService.confirmCancelOrder(paramCode, (err, res, headers) => {
                if (!err) {
                    this.getShipmentDetail()
                    notification['success']({
                        message: t('orderDetail.order_cancel'),
                    })

                    // lấy lại số tiền còn lại của khách
                    // if (this.mainLayoutElement && this.mainLayoutElement.BalanceComponent) this.mainLayoutElement.BalanceComponent.getBalanceUser();
                    this.getBalanceUser()
                } else {
                    if (err.title === 'shipment_status_not_allow') {
                        notification['error']({
                            message: t('error.shipment_status_not_allow'),
                        })
                    } else {
                        if (err.statusCode !== 'Network fail')
                            notification['error']({
                                message: t(err.message),
                            })
                    }
                }
                this.setState({ showProgressBar: false, isSubmit: false })
            })
        })
    }

    /**
     * lấy số dư tài khoản của khách hàng
     */
    getBalanceUser = () => {
        UserService.getBalanceUser((err, res) => {
            if (!err) {
                this.setState({
                    balance: res ? moneyCeil(res.balance) : 0,
                })
            }
        })
    }

    /**
     * xác nhận thanh toán
     */
    confirmPayOrder = password => {
        const { paramCode, isSubmit, showModalConfirm, savePassword } = this.state
        const { t } = this.props

        if (showModalConfirm && !password) {
            this.setState({
                passwordError: t('orderDetail.input_pin_error'),
            })
            return
        }

        let body = {}

        if (password) {
            body.password = password

            if (savePassword) {
                body.savePassword = true
            }
        }

        if (isSubmit === true) return

        this.setState({ showProgressBar: true, isSubmit: true }, () => {
            ShipmentsService.confirmPayOrder(paramCode, body, (err, res, headers) => {
                if (!err) {
                    LocalStore.getInstance().save('pinToken', headers['x-pin-token'] || '')

                    this.getShipmentDetail()
                    notification['success']({
                        message: t('orderDetail.successfully_pay'),
                    })

                    this.getBalanceUser()
                    this.setState({
                        password: '',
                        passwordError: '',
                        showModalConfirm: false,
                    })
                } else {
                    if (err.title === 'empty_password') {
                        LocalStore.getInstance().save('pinToken', '')
                        this.setState({
                            password: '',
                            passwordError: '',
                            showModalConfirm: true,
                        })
                    } else if (err.title === 'password_not_match' || err.title === 'invalid_password') {
                        LocalStore.getInstance().save('pinToken', '')
                        if (!showModalConfirm) {
                            this.setState({
                                password: '',
                                passwordError: '',
                                showModalConfirm: true,
                            })
                        } else {
                            this.setState({
                                passwordError: t('orderDetail.incorrect_pin'),
                            })
                        }
                    } else if (err.title === 'insufficient_balance') {
                        notification['error']({
                            message: t('cartCheckout.not_enough_money'),
                        })
                    } else if (err.title === 'shipment_status_not_allow') {
                        notification['error']({
                            message: t('error.shipment_status_not_allow'),
                        })
                    } else {
                        if (err.statusCode !== 'Network fail')
                            notification['error']({
                                message: t(err.message),
                            })
                    }
                }
                this.setState({ showProgressBar: false, isSubmit: false })
            })
        })
    }

    renderModalConfirmPin = () => {
        const { t } = this.props
        const { orderDetail, isSubmit, showModalConfirm, password, passwordError, savePassword } = this.state
        return (
            <Modal
                title={t('cartCheckout.confirm_pin')}
                visible={showModalConfirm}
                onOk={() => this.confirmPayOrder(password)}
                confirmLoading={isSubmit}
                onCancel={() =>
                    this.setState({
                        showModalConfirm: false,
                        password: '',
                    })
                }
                okText={t('modal.confirm')}
                cancelText={t('modal.cancel')}
                okButtonProps={{ className: '_btn-submit-confirm' }}
                cancelButtonProps={{ className: '_btn-cancel-confirm' }}
            >
                <p className="mgbt5">{t('modal.confirm_payment')}</p>
                <div className="dpl-flex align-items-start mgbt5">
                    {orderDetail && orderDetail.image ? (
                        <Avatar
                            className="_avatar-seller mgr10 flex-none"
                            shape="square"
                            size={40}
                            src={orderDetail && orderDetail.image}
                        />
                    ) : (
                        <AntdIconSVG
                            className=" custom-icon-shipment-big mgr5"
                            component={iconShipment}
                            style={{ fontSize: 15 }}
                        />
                    )}
                    <div className="txt-color-black txt-size-h7 break-word">
                        <div className="">#{orderDetail && orderDetail.code}</div>
                        <div className="">
                            {orderDetail && orderDetail.totalUnpaid >= 0 ? t('order.need_payment') : t('order.excess_cash')}:{' '}
                            {orderDetail && moneyFormat(moneyCeil(orderDetail.totalUnpaid), null, true)}
                        </div>
                    </div>
                </div>
                <p className="mgbt5">{t('cartCheckout.please_input_pin')}</p>
                <Input.Password
                    className={'_input-password'}
                    placeholder={t('cartCheckout.input_pin')}
                    value={password}
                    onChange={e => this.setState({ password: e.target.value })}
                    onKeyDown={e => {
                        if (e.keyCode === 13) {
                            this.confirmPayOrder(password)
                        }
                    }}
                />
                {passwordError && <p className="_txt-error-confirm mgt5 mgbt0 txt-error ">{passwordError}</p>}
                <Checkbox
                    className={'_check-box-confirm mgt10'}
                    onChange={() => this.setState({ savePassword: !savePassword })}
                    checked={savePassword}
                >
                    {t('modal.save_password_60m')}
                </Checkbox>
            </Modal>
        )
    }

    uploadImage = formData => {
        return new Promise((resolve, reject) => {
            const { orderDetail } = this.state
            ShipmentsService.uploadImage(orderDetail.code, formData, (error, response) => {
                if (error) {
                    reject(error)
                } else {
                    resolve(response)
                }
            })
        })
    }

    render() {
        //console.log(this.state.listPrice);
        const { t } = this.props
        const {
            activeTab,
            orderDetail,
            isLoadingDetail,
            isLoadingListMilestones,
            isLoadingListFinancial,
            isLoadingListTickets,
            orderTicketsMetadata,
            listTickets,
            listCommentsMetadata,
            loadingProduct,
            listProduct,
            productCreating,
            listFinancial,
            metadataFinancial,
            initLoading,
            loadingMore,
            logs,
            orderLogErrors,
            showLoadMore,
            shipmentFees,
            listMilestones,
            listFees,
            isLoadingFees,
            listStatuses,
            is404,
            listStatusesTicket,
            listFinancialClaim,
            listFinancialCollect,
            listWaybills,
            isLoadingWaybill,
            submittingWaybill,
            deletingWaybill,
            listWaybillParcels,
            isLoadingWaybillParcel,
            statusPackageList,
            finance,
            isLoadingFinance,
            errFinance,
            isSubmit,
            balance,
            listHS = [],
            listOriginalReceipts = [],
            deleteOriginalReceiptsLoading,
            addOriginalReceiptsLoading,
            peerPaymentStatuses,
            peerPaymentInfo,
            globalTenantConfig,
            loanCredits,
            listAttachments
        } = this.state
        if (is404) return <Page404 />
        return (
            <MainLayout
                ref={ref => (this.mainLayoutElement = ref)}
                {...this.props}
                noFooter={true}
                balance={balance}
                headerTitle={t('menu.shipments') + ' #' + (orderDetail ? orderDetail.code : '')}
                title={orderDetail ? '#' + orderDetail.code : ''}
            >
                <div className={'container-common mgr20 mgbt20'}>
                    {isLoadingDetail ? (
                        <Spin
                            indicator={
                                <AntdIcon.Loading3QuartersOutlined
                                    type="loading"
                                    style={{ fontSize: 24 }}
                                    spin
                                />
                            }
                        />
                    ) : (
                        <Layout>
                            <Row>
                                <Col
                                    className={'orderdetail pdr20'}
                                    span={17}
                                >
                                    <Spin
                                        spinning={this.state[`isLoading${orderDetail && orderDetail.id}`] === true}
                                        indicator={
                                            <AntdIcon.Loading3QuartersOutlined
                                                type="loading"
                                                style={{ fontSize: 24 }}
                                                spin
                                            />
                                        }
                                    >
                                        <ShipmentInfo
                                            t={t}
                                            getShipmentDetail={this.getShipmentDetail}
                                            loanCredits={loanCredits}
                                            ref={e => (this.ShipmentInfo = e)}
                                            listStatuses={listStatuses}
                                            data={orderDetail}
                                            setData={data => this.setState({ orderDetail: data })}
                                            confirmReceivedOrder={this.confirmReceivedOrder}
                                            confirmCancelOrder={this.confirmCancelOrder}
                                            confirmPayOrder={this.confirmPayOrder}
                                            isSubmit={isSubmit}
                                            listHS={listHS}
                                            listOriginalReceipts={listOriginalReceipts}
                                            deleteOriginalReceipts={this.deleteOriginalReceipts}
                                            deleteOriginalReceiptsLoading={deleteOriginalReceiptsLoading}
                                            addOriginalReceipts={this.addOriginalReceipts}
                                            addOriginalReceiptsLoading={addOriginalReceiptsLoading}
                                            listWaybillParcels={listWaybillParcels}
                                            listProduct={listProduct}
                                            peerPaymentInfo={peerPaymentInfo}
                                            peerPaymentStatuses={peerPaymentStatuses}
                                            globalTenantConfig={globalTenantConfig}
                                        />
                                    </Spin>
                                    <TabLayout
                                        exportCsv={this.exportCsvOrderProducts}
                                        onChangedTab={this.onChangedTab}
                                        activeTab={activeTab}
                                        // tab product
                                        listProduct={listProduct}
                                        listStatuses={listStatuses}
                                        loadingProduct={loadingProduct}
                                        productCreating={productCreating}
                                        deleteProduct={this.deleteProduct}
                                        createProduct={this.createProduct}
                                        //action waybill
                                        isLoadingWaybill={isLoadingWaybill}
                                        isLoadingWaybillParcel={isLoadingWaybillParcel}
                                        listWaybills={lodash.sortBy(listWaybills, 'createdAt').reverse()}
                                        listWaybillParcels={listWaybillParcels}
                                        statusPackageList={statusPackageList}
                                        getShipmentsWaybill={this.getShipmentsWaybill}
                                        createWaybill={this.createWaybill}
                                        submittingWaybill={submittingWaybill}
                                        deleteWaybill={this.deleteWaybill}
                                        deletingWaybill={deletingWaybill}
                                        //giao dich tài chính
                                        isLoadingListFinancial={isLoadingListFinancial}
                                        listFinancial={listFinancial}
                                        metadata={metadataFinancial}
                                        listFinancialClaim={listFinancialClaim}
                                        listFinancialCollect={listFinancialCollect}
                                        shipmentFees={shipmentFees}
                                        //Khiếu nại của đơn
                                        orderTicketsMetadata={orderTicketsMetadata}
                                        isLoadingListTickets={isLoadingListTickets}
                                        listTickets={listTickets}
                                        listStatusesTicket={listStatusesTicket}
                                        //lịch sử đơn hàng
                                        isLoadingListMilestones={isLoadingListMilestones}
                                        listMilestones={listMilestones}
                                        orderDetail={orderDetail}
                                        //action fees
                                        isLoadingFees={isLoadingFees}
                                        listFees={listFees}
                                        // log
                                        getOrderLogData={this.getOrderLog}
                                        initLoading={initLoading}
                                        loadingMore={loadingMore}
                                        showLoadMore={showLoadMore}
                                        logs={logs}
                                        orderLogErrors={orderLogErrors}
                                        onLoadMore={this.onLoadMore}
                                        // finance
                                        getFinance={this.getFinance}
                                        finance={finance}
                                        isLoadingFinance={isLoadingFinance}
                                        errFinance={errFinance}
                                        onUploadImage={this.uploadImage}
                                        listHS={listHS}
                                    />
                                </Col>
                                <Col
                                    span={7}
                                    className="flex-column bg-color-white bd-rdu bd1px bd-color-gray boxchat"
                                >
                                    <Comment
                                        ref={e => (this.commentComponent = e)}
                                        mainLayoutElement={this.mainLayoutElement}
                                        t={t}
                                        currentLoggedUser={currentLoggedUser || {}}
                                        hasMore={
                                            listCommentsMetadata
                                                ? parseFloat(listCommentsMetadata.page) + 1 <
                                                  parseFloat(listCommentsMetadata.pageCount)
                                                : false
                                        }
                                        orderDetail={orderDetail}
                                        //action tabs
                                        currentCommentTab={this.state.currentCommentTab}
                                        toggleCommentTab={this.toggleCommentTab}
                                        //action api
                                        // confirmDeleteToggle={this.deleteComment}
                                        listComments={this.state.listComments}
                                        loadDataComments={this.loadDataComments}
                                        nextCommentPage={this.nextCommentPage}
                                        commentLoading={this.state.commentLoading}
                                        isUploading={this.state.isUploading}
                                        uploadImageComment={this.uploadImageComment}
                                        listAttachments={listAttachments}
                                    />
                                </Col>
                            </Row>
                        </Layout>
                    )}
                    {this.renderModalConfirmPin()}
                </div>
            </MainLayout>
        )
    }
}

export default withNamespaces('translation')(ShipmentDetail)
