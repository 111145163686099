import React from 'react';
import _ from 'lodash';
import {Table, Spin} from 'antd';
import * as AntdIcon from "@ant-design/icons"

import {moneyFormat} from "../../utils/stringUtils";
import moment from "moment";
import UserService from "../../services/user.service";
import CategoriesService from "../../services/categories.service";

class TabFinance extends React.Component{
    constructor(props){
        super(props);
        this.state={
            listFinancial: []
        }
    }

    componentDidMount() {
        this.getListFinancial()
        this.getFinancialTypes()
    }

    /**
     * Lấy danh sách giao dịch trong chi tiết đơn
     */
    getListFinancial = () => {
        const {dataDetail = {}} = this.props;
        this.setState({
            isLoadingListFinancial: true
        }, () => {
            UserService.getPeerPaymentFinancials(dataDetail.code, (err, res, headers) => {
                if (!err) {
                    this.setState({
                        listFinancial: res,
                        metadataFinancial: {
                            pageCount: headers['x-page-count'],
                            page: headers['x-page-number'],
                            size: headers['x-page-size'],
                            total: headers['x-total-count'],
                        }
                    })
                }
                this.setState({isLoadingListFinancial: false});
            });
        })
    };

    /**
     * lấy ra danh sách trạng thái đơn
     */
    getFinancialTypes = () => {
        CategoriesService.getFinancialTypes((err, res, headers) => {
            if (!err) {
                this.setState({
                    listFinancialTypes: res,
                });
            }
        })
    };


    /**
     * render giao dịch
     */
    renderFinance = () => {
        const {t} = this.props;
        const {metadataFinancial, listFinancial = [], isLoadingListFinancial, listFinancialTypes = []} = this.state;

        const columns = [{
            title: t('peer_payment.teller'),
            dataIndex: 'teller',
            key: 'teller',
            className: '_finance-teller',
            render: (text, record) => (
                <div className={'_financial-teller-text txt-size-h7 txt-color-black roboregular mgbt5 break-word'}>
                    {text || '---'}
                </div>
            ),
        }, {
            title: t('financial_tab.time'),
            dataIndex: 'timestamp',
            key: 'timestamp',
            className: '_finance-time',
            render: (text, record) => (
                <div className={'_financial-time-text txt-size-h7 txt-color-black roboregular mgbt5 break-word'}>
                    {moment(record.timestamp).format('HH:mm DD/MM/YYYY')}
                </div>
            ),
        }, {
            title: t('financial_tab.amount'),
            dataIndex: 'amount',
            key: 'amount',
            className: '_finance-amount',
            render: (text, record) => {
                return (
                    <div className={`_financial-amount-text txt-size-h7 txt-color-red2 roboregular mgbt5 break-word ` + (record.amount >= 0 ? 'txt-color-green' : 'txt-color-red')}>
                        {record.amount >= 0 ? '+' + moneyFormat(record.amount) : moneyFormat(record.amount)}
                    </div>
                )
            }
        }, {
            title: t('financial_tab.transaction_type'),
            dataIndex: 'financialType',
            key: 'financialType',
            className: '_finance-type whitespace',
            render: (text, record) => {
                let current = listFinancialTypes.find(x => x.code === text) || {}
                return (
                    <div className={'_financial-type-text txt-size-h7 txt-color-black roboregular mgbt5 whitespace break-word'}>
                        {current.name || text}
                    </div>
                )
            },
        }, {
            title: t('financial_tab.content'),
            key: 'content',
            className: '_finance-content table__name',
            render: (text, record) => (
                <div className={'table__name'}>
                    <div className="_finance-content-trxId txt-size-h8 txt-color-gray roboregular mgbt0 ">Mã: {record.trxId}</div>
                    <div className="_finance-content-text break-word mgbt0 txt-size-h7 txt-color-black">
                        {record.memo}
                    </div>
                </div>
            ),
        }];

        return (
            <Spin spinning={isLoadingListFinancial === true} indicator={<AntdIcon.Loading3QuartersOutlined type="loading" style={{ fontSize: 24 }} spin />} >
                <Table
                    className={'_finance-table pd15'}
                    rowKey={'id'}
                    columns={columns}
                    dataSource={_.sortBy(listFinancial, 'timestamp').reverse()}
                    rowClassName={() => `_finance-row`}
                    pagination={{
                        hideOnSinglePage: true,
                        simple: true,
                        total: metadataFinancial ? parseFloat(metadataFinancial.total) : 0,
                        pageSize: 5,
                    }}
                />
            </Spin>
        )
    };

    render(){
        return(
            this.renderFinance()
        );
    }
}
export default TabFinance;