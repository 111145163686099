import { FC, useEffect, useMemo, useState } from 'react'
import Modal from '../commons/Modal'
import { ChoosePaymentMethod } from './components/ChoosePaymentMethod'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Button, Col, Row, Steps, Modal as AntModal } from 'antd'
import { QrCodePayment } from './components/QrCodePayment'
import coins from '../../resources/images/coins.svg'

interface Props extends WithNamespaces {
    visible: boolean
    onClose: () => void
    data?: {
        step: number
        hideStep: boolean
        type: string
        money: number
    }
    maskClosable?: boolean
    t: any
}

const DepositModal: FC<Props> = ({ t, visible, onClose, data, maskClosable = false }) => {
    const [infoPayment, setInfoPayment] = useState<{
        type: string
        money: number
    } | null>(null)
    const [current, setCurrent] = useState(0)
    const steps = useMemo(
        () => [
            {
                title: t('deposit.chooseReason'),
                content: (
                    <ChoosePaymentMethod
                        t={t}
                        value={infoPayment}
                        onChange={setInfoPayment}
                    />
                ),
            },
            {
                title: t('deposit.scanDepositCode'),
                content: (
                    <QrCodePayment
                        data={infoPayment}
                        t={t}
                    />
                ),
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [infoPayment]
    )

    const items = steps.map(item => ({ key: item.title, title: item.title }))

    const onSuccessModal = () => {
        const modal = AntModal.success({
            content: (
                <>
                    <div className="text-center mb-6 min-h-[125px]">
                        <img
                            src={coins}
                            alt="coin"
                        ></img>
                    </div>
                    <div className="text-center mb-6 text-grey-400">{t('deposit.successContent')}</div>
                </>
            ),
            maskClosable: true,
            okText: t('button.close'),
            className: 'modal-success-custom',
            icon: null,
            centered: true,
            onOk: () => modal.destroy(),
        })
        return modal
    }

    useEffect(() => {
        if (data) {
            setInfoPayment({
                type: data?.type,
                money: data?.money,
            })
            setCurrent(data?.step)
        }
    }, [data])

    return (
        <Modal
            title={t('deposit.depositToAccount')}
            visible={visible}
            onCancel={() => {
                onClose()
                setCurrent(0)
                setInfoPayment(null)
            }}
            destroyOnClose={true}
            maskClosable={maskClosable}
            width={680}
            listAction={[
                <Button
                    key="back"
                    onClick={() => {
                        if (current === 0 || data?.hideStep) {
                            onClose()
                            setInfoPayment(null)
                        } else {
                            setCurrent(value => value - 1)
                        }
                    }}
                    className="btn-cancel px-4 capitalize"
                    type="default"
                >
                    <>{current === 0 || data?.hideStep ? t('button.disagree') : t('button.back')}</>
                </Button>,
                <Button
                    key="continue"
                    onClick={() => {
                        if (current === 1) {
                            onClose()
                            setInfoPayment(null)
                            setCurrent(0)
                            setTimeout(() => {
                                onSuccessModal()
                            }, 500)
                        } else {
                            setCurrent(value => value + 1)
                        }
                    }}
                    className="px-4 capitalize"
                    type="primary"
                    disabled={!infoPayment}
                >
                    {current === 0 && <i className="fa-regular fa-arrow-right mr-3"></i>}
                    <>{current === 1 ? t('deposit.depositDone') : t('deposit.depositContinue')}</>
                </Button>,
            ]}
        >
            {!data?.hideStep && (
                <Row
                    justify="center"
                    className="mb-6"
                >
                    <Col
                        span={24}
                        md={16}
                    >
                        <Steps
                            current={current}
                            items={items}
                        />
                    </Col>
                </Row>
            )}

            <div>{steps[current].content}</div>
        </Modal>
    )
}

export default withNamespaces('translation')(DepositModal)
