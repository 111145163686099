import React from 'react';
import { Table, Divider, Modal, Radio, Popconfirm, Tag, notification } from 'antd'
import CreateAddressModal from "./CreateAddressModal";
import {LocalStore} from "../../utils/LocalStore";
import AddressServices from '../../services/address.service'
import {withNamespaces} from "react-i18next";

let currentLoggedUser = null;

class ListAddressModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmLoading: false,
            valueRadio: null,
            loading: false
        };
    }

    componentDidMount() {
        if (typeof LocalStore.getInstance().read('currentLoggedUser') === 'object') {
            currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {};
            this.loadData();
        }
    }

    componentDidUpdate (prevProps) {
        const {selectedAddress, modalStatus, defaultAddress, isReceivingAddress, selectedReceiveAddress} = this.props;
        const {listAddress = []} = this.state;
        if (prevProps.modalStatus !== modalStatus && modalStatus === true) {
            this.setState({
                valueRadio: isReceivingAddress ?  (selectedReceiveAddress ? selectedReceiveAddress?.id : listAddress.find(x => x.defaultAddress && x?.receivingAddress)?.id )
                : (selectedAddress ? selectedAddress.id : defaultAddress ? defaultAddress : listAddress.find(x => x.defaultAddress) ? listAddress.find(x => x.defaultAddress).id : null),
            })
        }
        if(isReceivingAddress !== prevProps?.isReceivingAddress){
            this.loadData(null, true);
        }
    }

    /**
     * load data
     */
    loadData = (cb, preventLoadData) => {
        const {selectedAddress, changeAddressSelected, defaultAddress, isReceivingAddress, selectedReceiveAddress} = this.props;

        // console.log('defaultAddress', defaultAddress)
        const queryString = `?receivingAddress=${isReceivingAddress.toString()}&page=0&size=9999&sort=defaultAddress:desc,createdAt:desc`;
        AddressServices.getListAddressesUser(currentLoggedUser.username, queryString, (err, res, headers) => {
            if (!err) {
                // console.log('loadData', res, headers);
                // console.log('loadDataList===>', selectedAddress ? selectedAddress.id : defaultAddress ? defaultAddress : res.find(x => x.defaultAddress) ? res.find(x => x.defaultAddress).id : null);
                this.setState({
                    listAddress: res,
                    valueRadio: isReceivingAddress ?  (selectedReceiveAddress ? selectedReceiveAddress?.id : res.find(x => x.defaultAddress && x?.receivingAddress)?.id )
                : (selectedAddress ? selectedAddress.id : defaultAddress ? defaultAddress : res.find(x => x.defaultAddress) ? res.find(x => x.defaultAddress).id : null),
                    metadata: {
                        pageCount: headers['x-page-count'],
                        page: headers['x-page-number'],
                        size: headers['x-page-size'],
                        total: headers['x-total-count'],
                    }
                }, () => {
                    if(!preventLoadData){
                        if (!selectedAddress) {
                            if (defaultAddress) {
                                changeAddressSelected(res.find(x => x.id === defaultAddress) || res[0])
                            } else if (res.length > 0) {
                                changeAddressSelected(res.find(x => x.defaultAddress) || res[0])
                            } else {
                                changeAddressSelected()
                            }
                        } else {
                            //trường hợp đã chọn nhưng bản ghi được chọn có thay đổi
                            changeAddressSelected(res.find(x => x.id === selectedAddress.id))
                        }
                    }
                });

                if (cb) cb();
            }
        })
    };

    /**
     * xóa bản ghi
     * @param id
     */
    deleteData = (id) => {
        const { t, selectedAddress, changeAddressSelected} = this.props;
        const {listAddress} = this.state;

        AddressServices.deleteAddressUser(currentLoggedUser.username, id, (err, res, headers) => {
            if (!err) {
                notification['success']({
                    message: t('message.delete_success'),
                });
                this.loadData(() => {
                    if (selectedAddress && selectedAddress.id === id) {
                        changeAddressSelected(listAddress.find(x => x.defaultAddress));
                        this.setState({valueRadio: listAddress.find(x => x.defaultAddress).id})
                    }
                });
            } else {
                if (err.statusCode !== 'Network fail') notification['error']({
                    message: t(err.message),
                });
            }
        });
    };


    /**
     * chọn bản ghi
     * @param e
     */
    handleOk = (e) => {
        const {toggleListAddressModal, changeAddressSelected, isReceivingAddress, changeReceiveAddressSelected} = this.props;
        const {valueRadio, listAddress} = this.state;
        // console.log('Ok...');
        if(isReceivingAddress){
            changeReceiveAddressSelected(listAddress.find(x => x.id === valueRadio) || listAddress.find(x => x.defaultAddress), toggleListAddressModal);
        } else {
            if (listAddress.length > 0) {
                changeAddressSelected(listAddress.find(x => x.id === valueRadio) || listAddress.find(x => x.defaultAddress), toggleListAddressModal);
            }
        }
    };

    /**
     * thay đổi radio
     * @param e
     */
    onChangeRadio = (e) => {
        // console.log('radio checked', e.target.value);
        this.setState({
            valueRadio: e.target.value,
        });
    };

    /**
     * xác nhận xóa bản ghi
     * @param id
     */
    confirmDelete = (id) => {
        this.deleteData(id);
    };

    /**
     * load danh sách địa chỉ
     */
    handleGetListAddress = () => {
        this.loadData();
    };

    onOpenEditAddress = (item) => {
        const {toggleListAddressModal, toggleCreateModal} = this.props;
        this.setState({toggleEditAddress: true, dataItem: item});
        toggleListAddressModal();
        toggleCreateModal();
    };

    render() {
        const {t, toggleListAddressModal, modalStatus, toggleCreateModal, modalCreateStatus, modalCreateAddressStatus, isUpdateDraftOrder, isReceivingAddress} = this.props;
        const {metadata, listAddress, toggleEditAddress, dataItem, toggleAddressOnList} = this.state;

        const columns = [{
            title: '',
            key: 'radio',
            render: (text, record) => (
                <Radio value={record.id} className={`_address-radio-btn`} />
            ),
            className: '_address-list-checkbox',
        }, {
            title: t('customerAddress.fullname'),
            dataIndex: 'fullname',
            key: 'fullname',
            className: '_address-list-fullname whitespace-pre-wrap break-word',
        }, {
            title: t('customerAddress.phone'),
            dataIndex: 'phone',
            key: 'phone',
            className: '_address-list-phone',
        }, {
            title: t('customerAddress.zipCode'),
            dataIndex: 'zipCode',
            key: 'zipCode',
            width:"15%",
            className: '_address-list-zipCode',
            render: (zipCode) => zipCode || '---'
        }, {
            title: t('customerAddress.addressName'),
            dataIndex: 'addressName',
            key: 'addressName',
            width:"10%",
            className: '_address-list-addressName',
            render: (txt) => txt || '---'
        }, {
            title: t('customerAddress.address'),
            dataIndex: 'detail',
            key: 'detail',
            className: '_address-list-detail',
            render: (text, record) => (
                <div className={'break-word'}>
                    <p className="_address-list-detail mgbt5 whitespace-pre-wrap break-word">{record.detail}</p>
                    <p className="_address-list-location-display whitespace-pre-wrap break-word mgbt0 txt-size-h8 txt-color-gray">{record.location.display}</p>
                </div>
            ),
        }, {
            title: '',
            dataIndex: 'defaultAddress',
            key: 'defaultAddress',
            className: '_address-list-default',
            render: (text, record) => (
                <span>
                    {
                        record.defaultAddress && !record.receivingAddress
                        ?
                        <Tag color={'green'}>{t('customerAddress.default')}</Tag>
                        :
                        null
                    }
                    {
                        record.defaultAddress && record.receivingAddress
                        ?
                        <Tag color={'blue'}>{t('customerAddress.default')}</Tag>
                        :
                        null
                    }
                </span>
            ),
        }, {
            title: '',
            key: 'action',
            className: '_address-list-action',
            render: (text, record) => (
                <span>
                    <span className={`_address-edit-btn cursor-pointer`} onClick={() => this.onOpenEditAddress(record)}>{t('button.edit')}</span>
                    {
                        !record.defaultAddress && <React.Fragment>
                            <Divider type="vertical"/>
                            <Popconfirm
                                overlayClassName={`_address-delete-popconfirm-overlay`}
                                className={`_address-delete-popconfirm`}
                                title={t('message.delete_confirm')}
                                onConfirm={() => this.confirmDelete(record.id)}
                                okText={t('button.yes')}
                                cancelText={t('button.no')}
                            >
                                <span className={`_address-delete-btn cursor-pointer`}>{t('button.delete')}</span>
                            </Popconfirm>
                        </React.Fragment>
                    }
                </span>
            ),
        }];

        return <React.Fragment>
            <Modal className={'modalform'}
                title={
                    <span>
                        {t('customerAddress.address_list')} <span className="txt-colot-blue txt-size-h7 robotoregular mgl10 cursor-pointer"
                            onClick={() => {
                                this.setState({dataItem: null, toggleAddressOnList: true}, () => {
                                    toggleListAddressModal();
                                    toggleCreateModal();
                                })
                            }}
                        >
                            {t('customerAddress.new_address')}
                        </span>
                    </span>
                }
                visible={modalStatus}
                centered
                onOk={this.handleOk}
                confirmLoading={isUpdateDraftOrder}
                onCancel={() => toggleListAddressModal()}
                okText={(t('button.yes')).toUpperCase()}
                okButtonProps={{className:"_btn-list-address-yes"}}
                cancelText={(t('button.cancel')).toUpperCase()}
                cancelButtonProps={{className:"_btn-list-address-cancel"}}
                width={1100}
            >
                <Radio.Group onChange={this.onChangeRadio} value={this.state.valueRadio} style={{width: '100%'}}>
                    <Table
                        className={'_address-table'}
                        rowKey={'id'}
                        columns={columns}
                        dataSource={listAddress}
                        rowClassName={(record, index) => `_address-row`}
                        pagination={{
                            hideOnSinglePage: true,
                            simple: true,
                            // current: metadata ? parseFloat(metadata.page) : 0,
                            total: metadata ? parseFloat(metadata.total) : 0,
                            pageSize: 5,
                            // onChange: (page) => this.loadData(page)
                        }}
                    />
                </Radio.Group>
            </Modal>

            {modalCreateAddressStatus && <CreateAddressModal
                toggleCreateModal={toggleCreateModal}
                modalCreateAddressStatus={modalCreateStatus}
                toggleListAddressModal={toggleListAddressModal}
                loadDataList={this.loadData}
                toggleEditAddress={toggleEditAddress}
                dataItem={dataItem}
                toggleAddressOnList={toggleAddressOnList}
                isCheckReceivingAddress={isReceivingAddress}
            />}


        </React.Fragment>
    }
}
export default withNamespaces()(ListAddressModal);