import React from 'react';

import {Avatar, Button, Col, Row, Table, Tooltip} from 'antd';
import * as AntdIcon from "@ant-design/icons"
import AntdIconSVG from "@ant-design/icons"
import logo from "../../resources/images/dhtc.png";
import {Link} from "react-router-dom";
import {LocalStore} from "../../utils/LocalStore";
import {ReactComponent as iconShipment} from "../../resources/images/ic_Kygui.svg";
import {quantityFormat} from "../../utils/stringUtils";

export default class ImportExcelResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const {t, data = {}, onShowListSuccess} = this.props;
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo');

        const columns = [{
            title: '',
            dataIndex: 'image',
            key: 'image',
            className: "table-image-cell",
            render: (text, record) => record.image ? <Avatar className="_table-image" shape="square" src={record.image} alt={record.code}/> : <AntdIconSVG className="_table-image custom-icon-shipment" component={iconShipment} style={{fontSize: 12}}/>
        }, {
            title: t('shipment.order_code'),
            dataIndex: 'code',
            key: 'code',
            render: (text, record) => <Link to={`/shipments/${text}`}>
                <span className="_table-row-code txt-size-h7 txt-uppercase txt-color-blue">#{text}</span>
            </Link>
        }, {
            title: t('waybill.waybill_code'),
            dataIndex: 'waybillCode',
            key: 'waybillCode',
            render: (text, row) => {
                if (Array.isArray(row.waybills)) return <div className="txt-color-black">
                    {row.waybills.map((x, y) => {
                        if (y === 0) return x.code;
                        return ' - ' + x.code
                    })}
                </div>;
                return <div />
            }
        }, {
            title: <span className="txt-size-h7 txt-color-black robotoregular">
                {t('order.products_link')}
                <Tooltip placement="top" title={t('cartFinishOrder.product_quantity')}>
                    <AntdIcon.QuestionCircleOutlined type="question-circle" theme="filled" className={'txt-size-h8 txt-color-gray opacity6 mgl5'}/>
                </Tooltip>
            </span>,
            dataIndex: 'product',
            key: 'product',
            className: 'txt-right',
            render: (text, row) => {
                if (Array.isArray(row.products)) {
                    let total = 0;
                    let link = row.products.length;
                    row.products.map(x => total = total + x.quantity);
                    return quantityFormat(total) + '/' + quantityFormat(link)
                }
                return '---'
            }
        }];
        return <div className={'container-common pdbt30 mgr20'}>
            <Row>
                <Col span={17} className={'pdr15'}>
                    <h3 className={'txt-size-h4 robotoregular txt-color-black _order-finish-title mgbt20'}>{t('shipment.imported_excel_title')}</h3>
                    {
                        Array.isArray(data.shipments) && <Table
                            className='_table-data custom-empty bg-color-white'
                            rowKey={'code'}
                            columns={columns}
                            dataSource={data.shipments}
                            rowClassName={() => `_table-row vertical-align-text-top`}
                            pagination={{
                                className: '_pagination text-center width-100-percent mgt20',
                                hideOnSinglePage: true,
                                total: data.shipments.length,
                                pageSize: data.shipments.length,
                            }}
                        />
                    }
                </Col>

                <Col span={7} className={'pdr15'}>
                    <div className={'_order-finish'}>
                        <Row>
                            <Col md={24}>
                                <div className='txt-center mgbt20'>
                                    <img src={logo} className={"imagefinishlogo _order-finish-logo"} alt=''/>
                                </div>
                            </Col>

                            <Col md={24}>
                                <h3 className='txt-color-blue txt-size-h4 robotoregular txt-center _order-finish-notification'>{t('order.order_success')}</h3>
                            </Col>

                            <Col md={24}>
                                <p className={'txt-size-h8 txt-color-gray robotoregular line-height167 txt-center'}>
                                    {currentProjectInfo && currentProjectInfo.name ? currentProjectInfo.name : t('cartFinishOrder.we')} {t('cartFinishOrder.text_content')} {t('cartFinishOrder.updated_all_info')}
                                </p>
                            </Col>
                            <Col span={24} className={'txt-center'}>
                                <Button onClick={() => onShowListSuccess(false)} className={'btn--blue _order-finish-btn'}>
                                    {t('shipment.shipment_list')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    }
}
