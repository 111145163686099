import React, { useState, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import DataTable from './components/DataTable'
import MainLayout from '../../../components/Layout/MainLayout'
import TabsComponent from './components/Tabs'
import TaobaoService from '../../../services/taobao.service'
import OrderTaobao, { StatusOrderTbg } from '../../../types/TaobaoOrder'
import queryString from 'query-string'
import { parseQueryStringToObject } from '../../../utils/stringUtils'
import { flattenDeep, get, isArray, sortBy } from 'lodash'
import { useQuery } from 'react-query'
import { LocalStore } from '../../../utils/LocalStore'
import ShowProduct from './components/ShowProduct'
import { Badge } from 'antd'
import useOrders from '../../../react-query/useOrders'
import usePeerPayment from '../../../react-query/usePeerPayment'

interface Props extends RouteComponentProps, WithNamespaces {
    // Other props go here
}

interface IFilter {
    orderCode?: string | undefined
    globalOrderId?: string | undefined
    currentPage?: any
    size?: any
    credentialAccount?: string
    statuses: string
    sort?: string
}

const OrderTaobaoGlobal: React.FC<Props> = ({ t, history, ...props }) => {
    const [openDrawer, setOpenDrawer] = useState<boolean>(false)
    const [statuses, setStatuses] = useState<StatusOrderTbg[]>()
    const [orderSeletecd, setOrderSeletecd] = useState<OrderTaobao>()
    const [filter, setFilter] = useState<IFilter>({
        statuses: 'all',
    })

    useEffect(() => {
        setFilter({ ...(parseQueryStringToObject(props.location.search) as any) })
    }, [props.location])

    const getStatuses = async () => {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        try {
            const apiUrl = 'https://api-dev.dota.gobizdev.com/api/taobao/categories/order-statuses'
            const headers = {
                'x-tenant': currentProjectInfo.tenantConfig.m24Config.tenant,
            }
            const response = await fetch(apiUrl, { headers })

            let responseData = await response.json()
            responseData.unshift({
                position: 0,
                code: 'all',
                name: 'Tất cả',
            })
            setStatuses(sortBy(responseData, 'position'))
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    useEffect(() => {
        getStatuses()
    }, [])

    const getAccountsTaobao = async () => {
        return new Promise((resolve, reject) => {
            TaobaoService.getListAccountTaobao((error: any, response: any, headers: any) => {
                if (error) {
                    reject(error)
                } else {
                    resolve({ data: response, headers })
                }
            })
        })
    }

    const { data: accounts, isLoading: isLoadingAccTbg } = useQuery(['accountsTaobao'], () => getAccountsTaobao())

    const { data, isLoading: isLoadingOrder, refetch } = useOrders(filter, accounts)

    let currentPage = get(data, 'headers.x-page-number', '0') as any
    let total = get(data, 'headers.x-total-count', 0) as any
    let pageSize = get(data, 'headers.x-page-size') as any

    const pagination = {
        current: parseInt(currentPage) + 1,
        hideOnSinglePage: true,
        total: parseInt(total),
        pageSize: parseInt(pageSize),
    }

    const orders = get(data, 'data')

    const getCodeLogisticsPromise = (code: string) => {
        return new Promise((resolve, reject) => {
            TaobaoService.getCodeLogistics(code, (error: any, response: any, headers: any) => {
                if (!error) {
                    resolve(response)
                } else {
                    reject(error)
                }
            })
        })
    }

    const mapData = (productData: any, codeLogisticData: any) => {
        let codeLogistics: any = []
        if (isArray(codeLogisticData) && codeLogisticData) {
            for (const item of codeLogisticData) {
                codeLogistics = [...codeLogistics, item?.logistics]
            }
            codeLogistics = flattenDeep(codeLogistics)
        }

        let result = {
            ...productData,
        }

        result.products = result?.products?.map((product: any) => {
            const logistic = codeLogistics.find(
                (item: any) => item.mappingProductId === product.mappingProductId && item.mappingSkuId === product.mappingSkuId
            )

            return {
                ...product,
                logistic,
            }
        })

        let object: any = {}
        result.products.forEach((item: any, index: number) => {
            const trackingCode = item?.logistic?.trackingCode

            if (!item.logistic) {
                object[index] = [item]
            }

            if (!trackingCode) return
            if (!object[trackingCode]) {
                object[trackingCode] = [item]
            } else {
                object[trackingCode].push(item)
            }
        })
        return {
            ...result,
            products: object,
        }
    }

    const { data: dataPayment, isLoading: isLoadingPeerPayment } = usePeerPayment(orders!, filter)

    const peerPaymentList = get(dataPayment, 'data', [])

    // const { data: accountAlias, isLoading: isLoadingAccountAlias } = useAccountAlias(orders!, filter)

    // const accountAliasList = get(accountAlias, 'data', [])

    const getProductsByOrder = (id: string) => {
        TaobaoService.getProductsOrder(id, async (error: any, response: any, headers: any) => {
            if (!error) {
                let hasError = false
                let data = null
                try {
                    data = await getCodeLogisticsPromise(id)
                } catch (error) {
                    hasError = !!error
                }
                let newState = mapData(response, !hasError ? data : [])
                handleOrderSelected(newState)
            }
        })
    }

    const handleOpenDrawer = (isBol: boolean) => {
        setOpenDrawer(isBol)
    }

    const handleOrderSelected = (data: any) => {
        setOrderSeletecd(data)
    }

    const handleChangePage = (page: number, size?: number) => {
        addParams({
            page: page,
            size,
        })
    }

    const addParams = (params: any) => {
        const currentParams = parseQueryStringToObject(props.location.search)
        history.push(
            props.location.pathname +
            '?' +
            queryString.stringify({
                ...currentParams,
                ...params,
            })
        )
    }

    const onSearch = (params: any) => {
        const isEmpty = (value: any) => value === '';

        if (!isEmpty(params.orderCode) || !isEmpty(params.originalReceiptCode) || !isEmpty(params.peerpaymentCode)) {
            delete params.page;
        }
        
        ['orderCode', 'originalReceiptCode', 'peerpaymentCode'].forEach(key => {
            if (isEmpty(params[key])) {
                delete params[key];
            }
        });

        history.push(`${props.location.pathname}?${queryString.stringify({ ...params })}`);
    };

    const onClearParams = () => {
        history.push(props.location.pathname)
    }

    return (
        <MainLayout
            {...props}
            headerTitle={t('orderTbg.list')}
            title={t('orderTbg.list')}
        >
            <div className="container-common v2-layout pdbt30 mgr20">
                <div className="py-4 px-6 bg-color-white shadow-box rounded-md">
                    <div className="flex items-center mb-3">
                        <span className="_drawal-title flex items-center text-base robotomedium text-black-100 capitalize ">
                            {t('orderTbg.list') as any}
                            <Badge
                                count={pagination.total}
                                className="ml-2"
                                showZero
                            />
                        </span>
                    </div>
                    <TabsComponent
                        statuses={statuses}
                        addParams={addParams}
                        filter={filter}
                    />
                    <DataTable
                        t={t}
                        refetch={refetch}
                        statuses={statuses}
                        handleOrderSelected={handleOrderSelected}
                        getProductsByOrder={getProductsByOrder}
                        onSearch={onSearch}
                        filter={filter}
                        isLoading={isLoadingOrder || isLoadingAccTbg || isLoadingPeerPayment}
                        accountsTaobao={get(accounts, 'data')}
                        orders={orders}
                        onClearParams={onClearParams}
                        handleChangePage={handleChangePage}
                        pagination={pagination}
                        history={history}
                        handleOpenDrawer={handleOpenDrawer}
                        setFilter={setFilter}
                        peerPaymentList={peerPaymentList}
                        accountAliasList={get(accounts, 'data', [])}
                    />
                </div>
            </div>
            <ShowProduct
                t={t}
                orderSeletecd={orderSeletecd}
                open={openDrawer}
                onClose={() => handleOpenDrawer(false)}
            />
        </MainLayout>
    )
}

export default withRouter(withNamespaces()(OrderTaobaoGlobal))
