import React from 'react'
import DocumentTitle from "react-document-title"
import {Link} from 'react-router-dom'
import {LocalStore} from "../../utils/LocalStore";
import userService from "../../services/user.service";
import {getUrlQueryString} from "../../utils/helper";
import {parseQueryStringToObject} from "../../utils/stringUtils";
import {withNamespaces} from "react-i18next";
import * as AntdIcon from "@ant-design/icons"

class ConfirmEmail extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            email: '',
            onSubmit: true
        }
    }

    componentDidMount() {
        let queryObj = parseQueryStringToObject(getUrlQueryString());
        this.setState({
            token: queryObj.token
        }, () => {
            setTimeout(() => {
                this.onSubmit();
            }, 2000)
        })
    }

    /**
     * submit
     */
    onSubmit = () => {
        if (!this.state.token) {
            this.setState({
                onSubmit: false
            });
            return;
        }
        const {token} = this.state;
        this.setState({onSubmit: true}, () => {

            userService.confirmEmail({ token},(err, res, headers) => {
                if (!err) {
                    this.setState({
                        confirmSuccess: true
                    })
                }
                this.setState({onSubmit: false})
            })
        })
    };

    render() {
        const {
            confirmSuccess, onSubmit
        } = this.state;
        const {t} = this.props;
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};

        return(
            <DocumentTitle title={`${t('login.Validate_email')} | ${currentProjectInfo ? currentProjectInfo.name : ''}`}>
                <div className="scroll-wrapper">
                    <div className="wrapper login-wrapper width100 dpl-flex min-width-page position-re">
                        {
                            confirmSuccess
                                ?
                                <div className="boxform bg-color-white">
                                    <h1 className="txt-center mgbt20">
                                        <img src={require('../../resources/images/img-success.png')} alt=''/>
                                    </h1>
                                    <h2 className="txt-size-h3 robotobold txt-color-black txt-center">
                                        {t('login.confirm_success')}
                                    </h2>
                                    <span className="txt-size-h7 txt-color-black robotoregular dpl-block mgt20 txt-center line-height134">
                                        {t('login.account_activated')}
                                    </span>
                                    <div className="boxform__content mgt25">
                                        <div className="txt-center mgt30 mgbt20">
                                            <Link to={'/login'} className="_btn-login btn--blue dpl-il-block">{t('register.login_here')}</Link>
                                        </div>
                                    </div>
                                </div>
                                :
                                onSubmit ?
                                <div className="boxform bg-color-white dpl-flex align-items-center justify-content flex-direction-column">
                                    <h1 className="txt-center mgbt20">
                                        <AntdIcon.Loading3QuartersOutlined type="loading-3-quarters" spin={true}/>
                                    </h1>
                                    <h2 className="txt-size-h3 robotobold txt-color-black txt-center">
                                        {t('login.Validating')}
                                    </h2>
                                </div>
                                    :
                                <div className="boxform bg-color-white dpl-flex align-items-center justify-content flex-direction-column">
                                    <h1 className="txt-center mgbt20">
                                        <AntdIcon.ExclamationCircleOutlined type="exclamation-circle" />
                                    </h1>
                                    <h2 className="txt-size-h3 robotobold txt-color-black txt-center">
                                        {t('error.token_expired')}
                                    </h2>
                                </div>
                        }
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default withNamespaces()(ConfirmEmail);
