import PolimClient from '../utils/api/PolimClient'

export default class cartTaobaoGlobal {
    /**
     * lấy danh sách giỏ hàng của người dùng
     * @param username
     * @param query
     * @param cb
     */
    static getListCartItem(query, cb) {
        PolimClient.request(
            {
                endpoint: `/customer/taobao/cart?${query}`,
                method: 'GET',
            },
            cb
        )
    }

    // /**
    //  * Lấy danh chi tiết sản phẩm bằng mp_id
    //  * @param id
    //  * @param cb
    //  */
    static detailProductById(id, cb) {
        PolimClient.request(
            {
                endpoint: `/customer/taobao/product_detail?itemId=${id}`,
                method: 'GET',
            },
            cb
        )
    }

    // /**
    //  * lấy chi tiết sản phẩm từ id trên url taobao
    //  * @param productId
    //  * @param cb
    //  */
    static detailProduct(productId, cb) {
        PolimClient.request(
            {
                endpoint: `/customer/taobao/origin_product_detail?itemId=${productId}`,
                method: 'GET',
            },
            cb
        )
    }
    static getAccountConnect(cb) {
        PolimClient.request(
            {
                endpoint: `/customer/taobao/credential_accounts`,
                method: 'GET',
            },
            cb
        )
    }

    // /**
    //  * Thêm sản phẩm vào giỏ hàng
    //  * @param body
    //  * @param cb
    //  */
    static addProductToCart(body, cb) {
        PolimClient.request(
            {
                endpoint: `/customer/taobao/cart/add`,
                body: body,
                method: 'POST',
            },
            cb
        )
    }

    // /**
    //  * Cập nhật số lượng sản phẩm
    //  * @param body
    //  * @param cb
    //  */
    static updateQuantity(body, cb) {
        PolimClient.request(
            {
                endpoint: `/customer/taobao/cart/update`,
                body: body,
                method: 'POST',
            },
            cb
        )
    }

    static onOrder(body, cb) {
        PolimClient.request(
            {
                endpoint: `/customer/taobao/orders`,
                method: 'POST',
                body: body,
            },
            cb
        )
    }

    static getDetailProduct(itemId, credentialId, type, cb) {
        PolimClient.request(
            {
                endpoint: `/customer/taobao/fetch_${
                    type === 'tbg' ? 'global_' : ''
                }product?itemId=${itemId}&credentialId=${credentialId}`,
                method: 'GET',
            },
            cb
        )
    }

    static deleteProduct(params, cb) {
        PolimClient.request(
            {
                endpoint: `/customer/taobao/cart?${params}`,
                method: 'DELETE',
            },
            cb
        )
    }
}
