import { Component } from 'react'
import { Badge, Popover, Tabs, List, Spin, Avatar, Typography } from 'antd'
import * as AntdIcon from '@ant-design/icons'

import InfiniteScroll from 'react-infinite-scroller'
import { Link } from 'react-router-dom'
import { LocalStore } from '../../utils/LocalStore'
import { parseQueryStringToObject, timeAgo } from '../../utils/stringUtils'
import * as Constants from '../../constants/config'
import Favicon from '../Favicon'
import NotificationServices from '../../services/notifications.service'
import _ from 'lodash'

export default class Notifications extends Component {
    constructor(props) {
        super(props)
        // this.favicon = new Favicon({
        //     animation: 'popFade'
        // });
        this.state = {
            page: 1,
            pageSize: 25,
            currentTab: 'all',
            data: [],
            loading: false,
            hasMore: true,
            currentProjectInfo: {},
        }

        // customer.livestream -request/stream
        // customer.unread - request/response
        // customer.markAsRead - fire and forgot
    }

    componentDidMount() {
        if (navigator && navigator.serviceWorker) navigator.serviceWorker.addEventListener('message', this.onMessageFromWorker)

        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}

        this.setState({ currentProjectInfo })

        // mark read cho notification
        const obj = parseQueryStringToObject(this.props.location.search)
        if (obj && obj.notificationId) {
            this.markReadNotification(obj.notificationId)
        } else {
            this.getTotalUnread()
        }

        this.getNotificationEventGroups()

        if (
            navigator &&
            navigator.serviceWorker &&
            navigator.serviceWorker.controller &&
            navigator.serviceWorker.controller.postMessage
        ) {
            navigator.serviceWorker.controller.postMessage({ title: Constants.WS_CHECK_CONNECT })
        }
    }

    componentWillUnmount() {
        if (navigator && navigator.serviceWorker) navigator.serviceWorker.removeEventListener('message', this.onMessageFromWorker)
        if (this.client) {
            LocalStore.getInstance().save('rsocketConnected', { connected: false })
            this.client.close()
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.location.pathname !== prevProps.location.pathname ||
            this.props.location.search !== prevProps.location.search
        ) {
            const obj = parseQueryStringToObject(this.props.location.search)
            // // nếu có thay đổi về pathname hoặc search
            // // kiếm tra xem có param notificationId
            // // mark read cho notification
            if (obj && obj.notificationId) {
                this.markReadNotification(obj.notificationId)
            }
        }
    }

    getNotificationEventGroups = () => {
        NotificationServices.getNotificationEventGroups((err, res) => {
            if (!err) {
                this.setState({ eventGroups: res })
            }
        })
    }

    onMessageFromWorker = event => {
        if (!event) return
        if (!event.data) return
        switch (event.data.title) {
            case Constants.RSOCKET_GET_TOTAL_UNREAD_NOTIFICATION:
                this.getTotalUnread()
                break
            case Constants.WS_RECONNECT:
                // connect websocket
                if (
                    navigator &&
                    navigator.serviceWorker &&
                    navigator.serviceWorker.controller &&
                    navigator.serviceWorker.controller.postMessage
                ) {
                    const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
                    const loginSession = LocalStore.getInstance().read('loginSession') || {}
                    const tenantConfig = currentProjectInfo.tenantConfig || {}
                    let endpoint = process.env.REACT_APP_NOTIFICATION_WSS + '?access_token=' + loginSession.id
                    navigator.serviceWorker.controller.postMessage({
                        title: Constants.WS_ON_CONNECT,
                        endpoint: endpoint,
                        appName: currentProjectInfo.name,
                        icon: tenantConfig.customFavicon,
                    })
                }
                break
            default:
                break
        }
    }

    /**
     * chuyển trạng thái của notification sang đã đọc
     * @param id
     */
    markReadNotification = id => {
        NotificationServices.markNotificationRead(id, () => {
            this.getTotalUnread()
        })
    }

    /**
     * chuyển trạng thái của tất cả notification sang đã đọc
     */
    markReadAllNotification = () => {
        NotificationServices.markAllNotificationsRead(() => {
            this.setState({ visible: false }, () => {
                // lấy lại số lượng noti chưa đọc
                this.getTotalUnread()
            })
        })
    }

    /**
     * chuyển trạng thái của notification sang đã đọc
     */
    getTotalUnread = () => {
        this.setState({ isLoadingNotifications: true }, () => {
            NotificationServices.getQuantityNotificationsUnread((err, res, headers) => {
                if (!err) {
                    this.setState({
                        notificationsUnread: res,
                    })
                }
                this.setState({ isLoadingNotifications: false })
            })
        })
    }

    /**
     * lấy ra danh sách notifications
     * @param page
     * @param type
     */
    getNotification = (page, type) => {
        const { listNotifications = [], isLoadingNotifications } = this.state
        let queryString = `?skip=${(page || 0) * 25}&take=26${type ? `&groupCode=${type}` : ''}&sort=publishDate:desc`
        if (isLoadingNotifications) return
        this.setState({ isLoadingNotifications: true }, () => {
            NotificationServices.getNotifications(queryString, (err, res, headers) => {
                if (!err && Array.isArray(res)) {
                    this.setState(
                        {
                            listNotifications:
                                !page || page === 0 ? [...res.slice(0, 25)] : [...listNotifications, ...res.slice(0, 25)],
                            pageNo: page || 0,
                            hasMore: res.length > 25,
                        },
                        () => this.getTotalUnread()
                    )
                }
                this.setState({ isLoadingNotifications: false })
            })
        })
    }

    /**
     * next comment page
     */
    nextPage = () => {
        const { pageNo, hasMore, isLoadingNotifications } = this.state
        if (hasMore && !isLoadingNotifications) {
            this.getNotification(pageNo + 1)
        }
    }

    tabChange = e => {
        this.setState(
            {
                currentTab: e,
                listNotifications: [],
            },
            () => {
                //lấy ra danh sách noti theo type
                this.getNotification(null, e !== 'all' ? e : null)
            }
        )
    }

    /**
     * lấy ra link theo type của event group
     * @param data
     * @returns {string}
     */
    getLinkByEventGroup = data => {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const diorConfig = _.get(currentProjectInfo, 'tenantConfig.diorConfig.enabled', false)
        const refData = data.refData || {}
        const eventCode = data.eventCode
        switch (eventCode) {
            case 'FINANCIAL_COLLECT':
            case 'FINANCIAL_EMD':
            case 'FINANCIAL_PAYMENT':
            case 'FINANCIAL_CLAIM':
            case 'FINANCIAL_DEPOSIT':
                return  !diorConfig ? '/profile/transactions' : '/profile/new-transactions'
            case 'DELIVERY_REQ_STATUS_UPDATE':
                if (refData.deliveryRequest) return `/delivery?query=${refData.deliveryRequest.code}`
                return '/delivery'
            case 'ORDER_PACKAGE_UPDATE':
            case 'SHIPMENT_PACKAGE_UPDATE':
                if (refData.order) {
                    if (refData.order.updatedPackageCode) {
                        return `/packages?query=${refData.order.updatedPackageCode}`
                    } else {
                        if (refData.order.code)
                            return `/${refData.order.isShipment ? 'shipments' : 'orders'}/${refData.order.code}`
                    }
                }
                return '/packages'
            case 'PROFILE':
                return '/profile'
            default:
                if (refData.type === 'peer_payment') {
                    if (refData.order.code) return `/peer-payments/${refData.order.code}`
                } else if (refData.order) {
                    if (refData.order.code) return `/${refData.order.isShipment ? 'shipments' : 'orders'}/${refData.order.code}`
                    return '/orders'
                }
                return '/'
        }
    }

    renderTabContents = () => {
        const { isLoadingNotifications, listNotifications, currentProjectInfo, hasMore } = this.state
        const { t } = this.props

        return (
            <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={this.nextPage}
                hasMore={!isLoadingNotifications && hasMore}
                useWindow={false}
                className={'notification__content'}
            >
                <List
                    dataSource={listNotifications}
                    renderItem={item => {
                        const link = this.getLinkByEventGroup(item)
                        return (
                            <Link
                                to={link}
                                target={'_blank'}
                                className="notification-item"
                                onClick={() => {
                                    this.markReadNotification(item.id)
                                    this.setState({ visible: false })
                                }}
                            >
                                <List.Item
                                    key={item.id}
                                    className={`notification__content_list ${
                                        item.read ? 'active' : ''
                                    } justify-content-between pdl15 pdr15`}
                                >
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar
                                                src={
                                                    (item.refData.order && item.refData.order.image) ||
                                                    (currentProjectInfo.tenantConfig && currentProjectInfo.tenantConfig.logoLite)
                                                }
                                            />
                                        }
                                        title={
                                            <span>
                                                <span className="txt-size-h8 robotomedium txt-color-black pdt10 dpl-block">
                                                    {item.messageData}
                                                </span>
                                                {/*<span className='txt-size-h8 txt-color-gray robotoregular line-height134'> {item.messageData}</span>*/}
                                            </span>
                                        }
                                        // description={item.email}
                                    />
                                    <div className="txt-size-h8 txt-color-gray2 robotoregular pdt2 flex-none">
                                        {timeAgo(item.publishDate, t)}
                                    </div>
                                    {/*<div className='txt-size-h8 txt-color-gray2 robotoregular pdt2'>{timeAgo(moment(item.publishDate).format("HH:mm DD/MM/YYYY"))}</div>*/}
                                    {/*<div className='txt-size-h8 txt-color-gray2 robotoregular pdt2 flex-none'>{timeAgo(item.publishDate * 1000, t)}</div>*/}
                                </List.Item>
                            </Link>
                        )
                    }}
                >
                    {isLoadingNotifications && hasMore && (
                        <div className="demo-loading-container text-center pdt15 pdbt15">
                            <Spin
                                indicator={
                                    <AntdIcon.Loading3QuartersOutlined
                                        type="loading"
                                        style={{ fontSize: 24 }}
                                        spin
                                    />
                                }
                            />
                        </div>
                    )}
                </List>
            </InfiniteScroll>
        )
    }

    popoverContent = () => {
        const { t } = this.props
        const { currentTab, notificationsUnread, eventGroups = [] } = this.state
        return (
            <div className="notification__header">
                <Tabs
                    activeKey={currentTab}
                    onChange={this.tabChange}
                >
                    <Tabs.TabPane
                        tab={`${t('notifications.all')}${notificationsUnread ? ` (${notificationsUnread})` : ''}`}
                        key="all"
                    >
                        {this.renderTabContents()}
                    </Tabs.TabPane>
                    {eventGroups.map((item, index) => (
                        <Tabs.TabPane
                            key={item.code ? item.code.toUpperCase() : item.code}
                            tab={`${item.name}`}
                        >
                            {this.renderTabContents()}
                        </Tabs.TabPane>
                    ))}
                </Tabs>
                <div className="border-top-1x bd-color-gray pdt10 pdbt10 pdl10 text-center">
                    <Link
                        to={'/notifications'}
                        className="cursor-pointer"
                    >
                        {t('notifications.read_all')}
                    </Link>
                </div>
            </div>
        )
    }

    renderTitle = () => {
        const { t } = this.props
        const { notificationsUnread } = this.state
        return (
            <div className="dpl-flex align-items-center justify-content-between pdt5 pdbt5">
                <span className="txt-color-black txt-uppercase txt-size-h7">{`${t('notifications.title')}${
                    notificationsUnread ? ` (${notificationsUnread})` : ''
                }`}</span>
                <Typography.Link
                    className="cursor-pointer txt-size-h8"
                    onClick={this.markReadAllNotification}
                >
                    {t('notifications.mark_as_read_all')}
                </Typography.Link>
            </div>
        )
    }

    render() {
        const { visible, notificationsUnread, currentTab } = this.state
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const tenantConfig = currentProjectInfo.tenantConfig || {}
                return (
            <Popover
                overlayClassName="notification-popover"
                placement="bottomRight"
                title={this.renderTitle()}
                content={this.popoverContent()}
                trigger="click"
                onVisibleChange={e => {
                    this.setState({ visible: e }, () => {
                        if (e === true) {
                            this.setState(
                                {
                                    listNotifications: [],
                                },
                                () => {
                                    this.getNotification(null, currentTab !== 'all' ? currentTab : null)
                                }
                            )
                        }
                    })
                }}
                visible={visible}
            >
                <div className="popver-custom  px-5">
                    <Favicon
                        ref={ref => (this.favicon = ref)}
                        url={tenantConfig.customFavicon || 'favicon.ico'}
                        alertCount={notificationsUnread > 99 ? '+99' : notificationsUnread || 0}
                    />
                    <Badge count={notificationsUnread || 0}>
                        <i class="fa-regular fa-bell text-base text-grey-400"></i>
                    </Badge>
                </div>
            </Popover>
        )
    }
}
