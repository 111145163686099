// @ts-nocheck
import React from 'react';
// import { Detector } from "react-detect-offline";
import moment from 'moment'
import TagManager from 'react-gtm-module'
import './index.css'
import './App.less'
import Routes from './Routes';
import SplashScreen from './containers/SplashScreen';
import { ConfigProvider } from "antd";
import { withNamespaces } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import 'moment/locale/vi'
import { LocalStore } from "./utils/LocalStore";
import Favicon from './components/Favicon';

import userService from "./services/user.service";
import projectService from "./services/project.service";
import orderService from './services/orders.service';
import categoriesService from './services/categories.service';
import Page502 from './containers/Page502'
import PageError from './containers/PageError'

import viVN from 'antd/lib/locale-provider/vi_VN';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import enUS from 'antd/lib/locale-provider/en_US';
import kmKH from './constants/locale/km/km_KH';
import km from './constants/locale/km/km';
import thTH from 'antd/lib/locale-provider/th_TH';
//Css
import './resources/scss/custom.scss'

import { browserVersion, getUrlQueryString, initWechat } from "./utils/helper";
import numeral from 'numeral'
import { USER_STATUS } from './constants/config'
import { parseQueryStringToObject } from "./utils/stringUtils";
import { Redirect } from "react-router-dom";
// import OpenReplay from '@openreplay/tracker';
// import trackerAssist from '@openreplay/tracker-assist';
import CategoriesService from './services/categories.service';
//define new language
moment.defineLocale('km-KH', km);

const STATE_SUCCESS = 'success';
const STATE_FAILURE = 'failure';
const STATE_502 = '502';
const STATE_LOADING = 'loading';
const STATE_TRACKING = 'order-tracking';
// const DATADOG_CLIENT_TOKEN = 'pub1f0894de906f923cba2a48c623afd84b';
// const DATADOG_SITE ='datadoghq.com'

// @ts-ignore
numeral.register('locale', 'vi', {
    delimiters: {
        thousands: '.',
        decimal: ','
    }
});

class App extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.isUnmounted = false;

        this.state = {
            initAppStatus: STATE_LOADING,
            locale: null,
            currentProjectInfo: {}
        };
        // this.windowResize();
    }

    language = (lang) => {
        switch (lang) {
            case 'zh':
                return zhCN;
            case 'vi':
                return viVN;
            case 'km':
                return kmKH;
            case 'en':
                return enUS;
            case 'th':
                return thTH;
            default:
                return viVN;
        }
    };

    componentDidMount() {
        // window.addEventListener('resize', this.windowResize);
        // const tracker = new OpenReplay({
        //     projectKey: process.env.REACT_APP_OPEN_REPLAY_KEY,
        //     ingestPoint: process.env.REACT_APP_OPEN_REPLAY_SERVER
        // });
        // tracker.use(trackerAssist());
        // tracker.start();
        this.windowResize();

        this.saveCookie();

        const localStore = LocalStore.getInstance();
        let language = localStore.read("currentLanguage");
        if (!language || language === 'vi') {
            this.setState({ locale: viVN });
            numeral.locale('vi');
        } else {
            const { i18n } = this.props;
            i18n.changeLanguage(language.languageCode);
            this.setState({
                locale: this.language(language.languageCode)
            });
            if (language.languageCode !== 'vi') {
                numeral.locale('en');
            } else {
                numeral.locale('vi');
            }
        }
        LocalStore.getInstance().save('articleShow', true);

        // lấy ra thông tin project hiện tại
        projectService.getCurrentProject((err, response) => {
            if (!err) {
                // console.log('navigator.appVersion', navigator.appVersion);
                Sentry.configureScope((scope) => {
                    scope.setUser({
                        "tenant": response && response.code,
                        appVersion: navigator.appVersion,
                        platform: navigator.platform,
                        browserVersion: browserVersion(),
                        prod: process.env.IS_PRODUCT
                    });
                });

                // datadogLogs.init({
                //     clientToken: DATADOG_CLIENT_TOKEN,
                //     site: DATADOG_SITE,
                //     env: 'env_staging:east',
                //     forwardErrorsToLogs: true,
                //     service: response && response.code,
                //     version: navigator.appVersion,
                //     sessionSampleRate: 100,
                // })

                if (!!response?.tenantConfig?.gtmConfig) {
                    const { gtmId, dataLayer, dataLayerName, events, preview, auth } = response?.tenantConfig?.gtmConfig;
                    const tagManagerArgs = {
                        gtmId,
                        dataLayer,
                        dataLayerName,
                        events,
                        auth,
                        preview
                    }
                    const omitNilValue = _.omitBy(tagManagerArgs, _.isNil)
                    TagManager.initialize(omitNilValue)
                }
                LocalStore.getInstance().save('currentProjectInfo', response);

                // lấy danh sách tiền tệ
                categoriesService.getCurrencies((error, res, header) => {
                    if (!error) {
                        LocalStore.getInstance().save('currencies', res);
                    }
                });

                CategoriesService.getMarketplaces((error, res, header) => {
                    if (!error) {
                        LocalStore.getInstance().save('marketpalaces', res);
                    }
                });

                // lấy cấu hình login
                CategoriesService.getContactLogin((error, res, header) => {
                    if (!error) {
                        LocalStore.getInstance().save('loginContact', res);
                    }
                })

                // lấy danh sách exchange rate
                categoriesService.getCurrentExchangeRate((error, res, header) => {
                    if (!error) {
                        LocalStore.getInstance().save('exchangeRates', res);
                    }
                });

                if (!LocalStore.getInstance().read('loginSession')) {
                    //lưu lại redirectUrl
                    if (window.location.hash && window.location.hash.indexOf('#/authentication') < 0) {
                        LocalStore.getInstance().save('redirectUrl', window.location.href);
                    }
                    this.setState({ initAppStatus: STATE_SUCCESS, currentProjectInfo: response });
                } else {
                    //query với access_token để lấy user
                    userService.getCurrentUser({}, (error, res) => {
                        if (!error) {

                            Sentry.configureScope((scope) => {
                                scope.setUser({ "email": res && res.email, username: res && res.username });
                            });
                            LocalStore.getInstance().save('currentLoggedUser', res);
                            // tracker.setUserID(`${response.code}.${lodash.get(res,'username','user-m1-not-detect')}`);

                            // initialWechat
                            initWechat(res);

                            //kiểm tra trạng thái của User
                            if (res.customerStatus === USER_STATUS.LOCKED || res.customerStatus === USER_STATUS.INACTIVE) {
                                // @ts-ignore
                                window.location = window.location.origin + '/#/403';
                            }

                            // lấy ra các thông báo
                            this.getArticle();


                            // lấy ra số lượng bản ghi order theo trạng thái
                            // query 1 lần duy nhất khi load trang
                            orderService.getOrdersStatistics((e, r) => {
                                // console.log('getOrdersStatistics', e, r)
                                if (!e) {
                                    LocalStore.getInstance().save('ordersStatistics', r);
                                }
                                this.setState({ initAppStatus: STATE_SUCCESS, currentProjectInfo: response });
                            });

                        } else {
                            //khi gọi me thất bại thì coi như là lỗi hệ thống vì có thể có vấn đề xảy ra mà khó lường trước
                            LocalStore.getInstance().save('loginSession', null);
                            if (error.code === 401) {
                                this.setState({ initAppStatus: STATE_SUCCESS })
                            } else if (error.code === 502) {
                                this.setState({ initAppStatus: STATE_502 });
                            } else {
                                this.setState({ initAppStatus: STATE_FAILURE })
                            }
                        }
                    })
                }
            } else {
                //khi gọi đến thông tin project thất bại thì coi như là lỗi hệ thống vì có thể có vấn đề xảy ra mà khó lường trước
                if (err.code === 401) {
                    this.setState({ initAppStatus: STATE_SUCCESS })
                } else if (err.code === 502) {
                    LocalStore.getInstance().save('currentProjectInfo', null);
                    this.setState({ initAppStatus: STATE_502 });
                } else {
                    LocalStore.getInstance().save('currentProjectInfo', null);
                    this.setState({ initAppStatus: STATE_FAILURE });
                }
            }
        });
    }

    windowResize = () => {
        const windowWidth = window.innerWidth;
        let split = window.location.href.split(window.location.origin);
        let currentVer = LocalStore.getInstance().read('currentVer') || "";
        if (!this.isUnmounted && windowWidth < 1025) {
            if (currentVer !== 'desktop') {
                if (split[1].indexOf('/m') !== 0) {
                    window.location.href = window.location.origin + '/m' + split[1];
                }
            }
        }
    };

    //lưu lại ref vào LocalStore trong 7 ngày
    saveCookie = () => {
        let queryString: any = getUrlQueryString(null);
        let queryObj: any = parseQueryStringToObject(queryString) || {};
        if (queryObj.ref) {
            LocalStore.getInstance().save('ref', {
                ref: queryObj.ref,
                expires: moment().add(7, 'days').format()
            });
        }
    };

    getArticle = () => {
        // query 1 lần duy nhất khi load trang
        userService.getArticle((err: any, res: any) => {
            if (!err) {
                let arr = Array.isArray(res) ? res.filter(x => x.purpose !== 'WELCOME') : []
                LocalStore.getInstance().save('articleList', arr);
            }
        });
    };

    renderContent = () => {
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        const tenantConfig = currentProjectInfo?.tenantConfig || {};

        const generalConfig = tenantConfig && tenantConfig.generalConfig ? tenantConfig.generalConfig : {};
        ConfigProvider.config({
            theme: {
                primaryColor: generalConfig && generalConfig.themeColor ? generalConfig.themeColor : '#1890ff',
            },
        });
        switch (this.state.initAppStatus) {
            case STATE_SUCCESS:
                return <div id={"app-main"} className={""}>
                    <ConfigProvider locale={this.state.locale}>
                        <Routes />
                    </ConfigProvider>
                </div>;
            case STATE_FAILURE:
                // TODO: change content of failure page
                return (
                    <PageError />
                );
            case STATE_502:
                // TODO: change content of failure page
                return (
                    <Page502 />
                );
            case STATE_TRACKING:
                return <Redirect to={{ pathname: '/order-tracking' }} />;
            default:
                // show loading page
                return (
                    <SplashScreen />
                );
        }
    };

    render() {
        const { currentProjectInfo = {} } = this.state;
        const tenantConfig = currentProjectInfo.tenantConfig || {};

        return <>
            <Favicon url={tenantConfig.customFavicon || 'favicon.ico'} />

            {
                this.renderContent()
            }
        </>;


    }
}

export default withNamespaces('translation')(App);
