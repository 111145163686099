import React from 'react'
import { Input } from 'antd'
import lodash from "lodash";

function InputItem(props) {
    const { title, onChange,type, allowClear, autoComplete, onKeyDown, onPressEnter, value, className, disabled, classContainer, addonAfter, suffix, autoFocus, isRequired } = props
    return (
        <div className={`form-field w-full ${classContainer ? classContainer : ''}`}>
            <Input
                className={`form-input w-full robotoregular  ${className ? className : ''} ${!lodash.isEmpty(value) ? 'input-has-value' : ''}`}
                onChange={onChange}
                onKeyDown={onKeyDown}
                type={type}
                disabled={disabled}
                autoComplete={autoComplete}
                placeholder=" "
                onPressEnter={onPressEnter}
                value={value}
                autoFocus={autoFocus}
                suffix={suffix}
                addonAfter={addonAfter}
                allowClear={allowClear}
            />
            {/* <span>{children}</span> */}
            <label className='robotoregular form-label'>{title} {!!isRequired ? <span className="text-red-100">*</span> : null}</label>
        </div>
    )
}


export default InputItem
