/* eslint-disable array-callback-return */
import React, {Fragment} from 'react';

import {
    Row,
    Col,
    Button,
    Avatar,
    Tooltip,
    List
} from 'antd';
import * as AntdIcon from "@ant-design/icons"

import logo from "../../resources/images/dhtc.png";
import {Link} from "react-router-dom";
import {moneyCeil, moneyFormat} from "../../utils/stringUtils";
import {LocalStore} from "../../utils/LocalStore";

export default class FinishOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    /**
     * render deader of list
     */
    renderHeaderList = () => {
        const {t} = this.props;
        return (
            <div className={'_headerListOrderFinish headerlist__title'}>
                <Row className={'txt-color-black'}>
                    <Col span={10}>
                        {t('order.seller')}
                    </Col>
                    <Col span={14}>
                        <Col span={10} className={'txt-right'}>
                                <span className="txt-size-h7 txt-color-black robotoregular">
                                    {t('order.products_link')}
                                    <Tooltip placement="top" title={t('cartFinishOrder.product_quantity')}>
                                        <AntdIcon.QuestionCircleOutlined type="question-circle" theme="filled" className={'txt-size-h8 txt-color-gray opacity6 mgl5'}/>
                                    </Tooltip>
                            </span>
                        </Col>
                        <Col span={8} className={'txt-right txt-size-h7 txt-color-black robotoregular'}>
                            {t('cartFinishOrder.amount_total')}
                        </Col>
                    </Col>
                </Row>
            </div>
        )
    };

    /**
     * render item of list
     */
    renderListItem = (item) => {
        const {t} = this.props;
        let totalQuanty = 0;
        Array.isArray(item.products) && item.products.map(x => {
            totalQuanty = totalQuanty + x.quantity
        });
        return (
            <Fragment>
                <List.Item className={`_order-finish-item position-re`}>
                    <Col span={10} className="dpl-flex align-items-center flex-1-1">
                        <Avatar className={`_order-avatar-seller mgr10`} shape="square" size={22} src={item.marketplace.image} />
                        {
                            item.merchantUrl
                                ?
                                <a href={item.merchantUrl} target={'_blank'} rel="noreferrer">
                                    <Avatar
                                        className={`_order-finish-item-avatar-${item.id} orderfinishitemavatar mgr10 imgthumb35`}
                                        shape="square"
                                        src={item.image}
                                    />
                                </a>
                                :
                                <Avatar
                                    className={`_order-finish-item-avatar mgr10`}
                                    shape="square"
                                    size={35}
                                    src={item.image}
                                />
                        }
                        <div className={'pdr10'}>
                            <h3 className={`_order-finish-item txt-size-h7 txt-color-black robotoregular break-word`}>{t('shipment.order_code')}: <Link className={'_order-detail-href txt-color-blue'} to={'/orders/' + item.code} target="_blank">#{item.code || '---'}</Link></h3>
                            {
                                item.merchantUrl ?
                                    <a href={item.merchantUrl} target={'_blank'} rel="noreferrer">
                                        <h3 className={`_order-finish-item-name-${item.id} txt-size-h7 txt-color-black robotoregular break-word pdt5`}>{item.merchantName || item.merchantCode}</h3>
                                    </a>
                                    :
                                    <h3 className={`_order-finish-item-name-${item.id} txt-size-h7 txt-color-black robotoregular break-word`}>{item.merchantName || item.merchantCode}</h3>
                            }
                        </div>
                    </Col>
                    <Col span={14}>
                        <Row>
                            <Col span={10} className={'position-re txt-right'}>
                                <span className={`_order-finish-item-countProduct txt-size-h7 txt-color-black robotoregular`}>{totalQuanty}/{item.products.length} </span>
                            </Col>
                            <Col span={8} className={'txt-right'}>
                                <span className={`_order-item-totalPrice txt-size-h7 txt-color-black break-word pdl5`}>{moneyFormat(moneyCeil(item.exchangedTotalValue))}</span>
                                {/*<span className={`_order-finish-item-totalPrice txt-size-h7 txt-color-black`}>---</span>*/}
                            </Col>
                            <Col span={6} className={'txt-right'}>
                                <Link className={'_order-detail'} to={'/orders/' + item.code} target="_blank">
                                    <div className={'cursor-pointer txt-color-blue txt-size-h7 robotoregular'}>{t('order.view_details')}</div>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </List.Item>
            </Fragment>
        )
    };

    /**
     * Render danh sách đơn đã đặt
     */
    renderListOrderPlaced = () => {
        const {listCarts} = this.props;
        return (
            <List
                className={'list-carts mgbt25 _order-finish-list'}
                header={this.renderHeaderList()}
                dataSource={listCarts}
                renderItem={item => {
                    return (this.renderListItem(item))
                }}
            />
        )
    };

    render() {
        const {t, listCarts = []} = this.props;

        if (listCarts.length === 0) return null;
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo');

        return <div className={'cartscontainer mgbt30'}>
            <Row>
                <Col span={7} className={'pdr15'}>
                    <div className={'_order-finish'}>
                        <Row>
                            <Col md={24}>
                                <div className='txt-center mgbt20'>
                                    <img src={logo} className={"imagefinishlogo _order-finish-logo"} alt=''/>
                                </div>
                            </Col>

                            <Col md={24}>
                                <h3 className='txt-color-blue txt-size-h4 robotoregular txt-center _order-finish-notification'>{t('order.order_success')}</h3>
                            </Col>

                            <Col md={24}>
                                    <p className={'txt-size-h8 txt-color-gray robotoregular line-height167 txt-center'}>{currentProjectInfo && currentProjectInfo.name ? currentProjectInfo.name : t('cartFinishOrder.we')} {t('cartFinishOrder.text_content')}
                                        {t('cartFinishOrder.updated_all_info')}</p>
                            </Col>
                            <Col span={24} className={'txt-center'}>
                                <Link className={'_order'} to={'/orders'}>
                                    <Button htmlType={'button'} className={'btn--blue _order-finish-btn'}>
                                        {t('order.order_management')}
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </Col>

                <Col span={17} className={'pdr15'}>
                    <h3 className={'txt-size-h4 robotoregular txt-color-black _order-finish-title'}>{t('order.order_deposit')}</h3>
                    {this.renderListOrderPlaced()}
                </Col>
            </Row>
        </div>
    }
}