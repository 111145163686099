// import { IAttribute, IProduct, IProperty, ISku } from "@pages/Dashboard/components/DataModel"
import React, { useState } from "react"
import { Badge, Button, Image, Input, Skeleton, Tooltip } from "antd"
// import { formatPriceRMB } from "@/utils/helper"
import { first, isEmpty, get } from "lodash"
import { IAttribute, IProperty, ISku } from "../../../../../types/CartProduct"
import { MinusOutlined, PlusOutlined } from "@ant-design/icons"

interface IProductDetailSkuProps {
    product: any,
    onAddToCart: (sku: any, selectItem: any, quantity: number, callback?: () => void, type?:string) => void
    onClose: () => void,
    loading?: boolean,
    t?: any
    history?: any
}

const ProductSkuAddCart: React.FC<IProductDetailSkuProps> = (props) => {
    const [currentSku, setCurrentSku] = useState<ISku>(props.product?.sku?.[0])
    const [quantity, setQuantity] = useState<number>(1)
    // const onAddToCartClicked = () => {
    //     props.onAddToCart(currentSku, quantity)
    // }

    const onAddToCartAndCloseClicked = () => {
        const type:string = props.history.location.search?.includes('?link=') ? 'url' : ''
        const selectItem = get(currentSku, "skuId")
        props.onAddToCart(currentSku, selectItem, quantity, props.onClose, type)
    }

    //TODO: disable khi hết hàng
    const onAttributeClicked = (property: IProperty, attribute: IAttribute) => {
        if (isEmpty(currentSku)) {
            setCurrentSku(props.product?.sku?.[0]);
        }
        const attributes = currentSku.attributes.map((attr: any) => {
            if (attr.property.id === property.id) {
                return attribute
            }
            return attr
        })
        props.product.sku.find((sku: any) => {
            if (sku.attributes.map((attr: any) => attr.id).sort().join(",") ===
                attributes.map((attr: any) => attr.id)
                    .sort().join(",")) {
                setCurrentSku(sku)
                return true
            }
            return false
        })
    }

    const renderAttribute = (property: IProperty, attribute: IAttribute) => {
        if (isEmpty(currentSku)) {
            setCurrentSku(props.product?.sku?.[0]);
        }
        const selected = currentSku?.attributes.find((skuAttr: any) => {
            return skuAttr.property.id === property.id &&
                skuAttr.value_id === attribute.value_id
        });

        if (property.is_image && property.is_unique_image) {
            return <li
                key={attribute.id}
                onClick={() => {
                    setQuantity(1)
                    onAttributeClicked(property, attribute)
                }}
            >

                {/* <img className="rounded cursor-pointer" style={{
                    width: 40, height: 40, borderWidth: selected ? 1 : 0,
                    borderStyle: "solid",
                    borderColor: selected ? "#1890ff" : ""
                }} src={attribute.image} alt={attribute.value} /> */}
                {selected ?
                    <Badge style={{ top: "-5px" }} className="text-[#1890FF]" count={<span className="bg-[#FF2424] rounded-xl py-1 px-2 text-[#FFF] ">x{quantity}</span>}>
                        <Tooltip className="" placement="top" title={attribute.value} >
                            <img className="rounded cursor-pointer" style={{
                                width: 40, height: 40, borderWidth: selected ? 1 : 0,
                                borderStyle: "solid",
                                borderColor: selected ? "#1890ff" : ""
                            }} src={attribute.image} alt={attribute.value} />
                        </Tooltip>
                    </Badge> :
                    <Tooltip className="" placement="top" title={attribute.value} >
                        <img className="rounded cursor-pointer" style={{
                            width: 40, height: 40, borderWidth: selected ? 1 : 0,
                            borderStyle: "solid",
                            borderColor: selected ? "#1890ff" : ""
                        }} src={attribute.image} alt={attribute.value} />
                    </Tooltip>}
            </li>
        }

        return <li
            key={attribute.id}
            onClick={() => {
                setQuantity(1)
                onAttributeClicked(property, attribute)
            }}
            className={`
            cursor-pointer 
            py-2 px-3 border 
            border-solid
            rounded-xl 
            font-sans
            ${selected ? "text-[#1890FF] border-[#1890FF]" : " text-[#1A1A1A]  border-[#C0C0C0]"}
          `
            }
        >
            {attribute.value}
        </li>
    }

    const handleIncrease = () => {
        setQuantity(prevValue => (prevValue < currentSku?.inventory ? prevValue + 1 : prevValue));
    };

    const handleDecrease = () => {
        setQuantity(prevValue => (prevValue > 1 ? prevValue - 1 : 1));
    };

    const handleInputChange = (e: any) => {
        const inputValue = parseInt(e.target.value, 10);
        setQuantity(inputValue >= 0 && inputValue <= currentSku?.inventory ? inputValue : quantity);
    };

    return <div className={"w-full"}>
        <Skeleton loading={props.loading}>
            {props.product?.sku ? <div className="flex gap-6 ">
                <div >
                    <img src={first(currentSku?.images) || props.product.images[0]} alt={props.product.title} className={"max-w-[367px] !rounded"} />
                </div>
                <div>
                    <span className="text-[#1A1A1A] font-medium text-xl">{props.product.title}</span>
                    <div className="mb-2 flex items-center gap-2">
                        <span className="text-[#7D7E7E] font-normal text-base">Giá sản phẩm: </span>
                        <div className="text-[#1890FF] font-medium text-[18px]"> ¥ {(currentSku?.price / 100)}</div>
                    </div>

                    <div className="grid gap-2 flex-wrap">

                        {
                            props.product.properties.map((item: any) => {
                                return <dl key={`${item.id}`}>
                                    <dt className="font-sans text-baseCore">{
                                        item.name === '颜色分类' ?
                                            <span className="text-[#7D7E7E] font-normal text-base">Thuộc tính:</span> :
                                            <span className="text-[#7D7E7E] font-normal text-base">Kích cỡ:</span>
                                    }</dt>
                                    <ul className="flex flex-wrap gap-3 mt-2 pl-0">
                                        {
                                            item.attributes.map((attr: any) => renderAttribute(item, attr))
                                        }
                                    </ul>
                                </dl>
                            })
                        }

                        <dl className="flex items-center gap-4 justify-between">
                            <span className="text-baseCore">Số lượng</span>

                            <div className={'txt-secondary rounded-lg px-3 py-[1px] border border-solid border-[#C0C0C0] flex items-center '}>
                                <span className="px-2 py-1 hover:cursor-pointer" onClick={handleDecrease} >
                                    {/* <i className="fa-solid fa-minus text-sm font-medium"></i> */}
                                    <MinusOutlined />
                                </span>
                                <Input type='numeral' bordered={false} style={{ textAlign: 'center', padding: "0 10px", width: "100px" }} value={quantity}
                                    onChange={handleInputChange}
                                    min={1}
                                    max={currentSku?.inventory}
                                />
                                <span className="px-2 py-1 hover:cursor-pointer" onClick={handleIncrease} >
                                    {/* <i className="fa-solid fa-plus text-sm font-medium"></i> */}
                                    <PlusOutlined />
                                </span>
                            </div>

                            <span className="font-normal text-[#7d7e7e]">(Tồn kho: {currentSku?.inventory})</span>
                        </dl>
                    </div>
                    {/* <div className="flex gap-2 justify-center"> */}
                    {/* <Button
                            disabled={parseInt(currentSku?.inventory as any) === 0 || quantity === 0}
                            type="default"
                            size="large"
                            style={{ width: "50%" }}
                            onClick={onAddToCartAndCloseClicked}
                        >Thêm vào giỏ hàng
                        </Button> */}
                    <div>
                        <Button
                            className="rounded"
                            disabled={parseInt(currentSku?.inventory as any) === 0 || quantity === 0}
                            onClick={onAddToCartAndCloseClicked}
                            style={{ width: "100%" }}
                            size="large"
                            type="primary">
                            {`${props?.t('taobaoGlobalCart.addToCart')}`}
                        </Button>
                    </div>
                </div>
                {/* </div> */}
            </div> :
                <>
                    <div className="text-center">
                        <Image style={{ width: "200px" }} preview={false}
                            src={require('../../../../../resources/images/cartEmpty.png')} />
                        <div className='mt-6 text-[#B1B1B1] text-center font-normal text-sm'>
                            Sản phẩm không tồn tại hoặc đường dẫn bị sai!
                        </div>
                    </div>
                </>
            }
        </Skeleton>
    </div>
}

export default ProductSkuAddCart