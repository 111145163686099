import {Divider, Row} from "antd";
import moment from "moment";
import React from "react";
import lodash from 'lodash';
import {moneyFormat} from "../../utils/stringUtils";
import {toBoolean} from "../../utils/helper";

export const LogItem = (props = {
    item: {},
    currency: "",
    t: () => {},
    statuses: [],
	internalStatuses: []
}) => {
    const {item, t, statuses, internalStatuses} = props

    const wrapBoldText = (text) => {
        return `<strong>${text}</strong>`
    }

    const wrapDiv = (value) => {
        return `<div>${value}</div>`
    }

    const renderTemplate = (template, bindings) => {
        let compiled = lodash.template(template)
        return compiled(bindings)
    }

    const getStatusByCode = (code) => {
		let status = lodash.find(statuses, s => s.code === code)
		if (!status) {
			status = lodash.find(internalStatuses, s => s.code === code)
			status = lodash.find(statuses, s => s.code === status.publicStatus)
		}
		return status
	}

    const formatLogsContent = (item) => {
        switch (item.activity) {
            case 'PEER_PAYMENT_FEE_CREATE':
                if (item.change.type === 'NEW_OBJECT') {
                    let langKey = t(`logPeerPayment.${item.activity}`)
                    const data = item.change.newObject[0]
                    return wrapDiv(renderTemplate(langKey, {
                        name: wrapBoldText(data.feeName),
                        amount: wrapBoldText(moneyFormat(data.actualAmount, 'VND')),
                        reason: wrapBoldText(data.reason)
                    }))
                }
                break;
            case 'PEER_PAYMENT_FEE_UPDATE':
                let langKey = t(`logPeerPayment.${item.activity}`)
                let targetMetadata = item.target.metadata
                if (item.change.type === 'PROPERTY_CHANGE') {
                    let changedValues = lodash.get(item.change.valueChange, 'changedValues') || []
                    changedValues = lodash.map(changedValues, (it, i) => {
                        return {
                            ...it,
                            index: i
                        }
                    })
                    changedValues = changedValues.sort((a, b) => b.index - a.index)

                    const data = []
                    for (let it of changedValues) {
                        let oldValue = it.left
                        let newValue = it.right
                        let oldValueFormatted = oldValue !== null ? oldValue : t(`logPeerPayment.nullValue`)
                        let newValueFormatted = newValue !== null ? newValue : t(`logPeerPayment.nullValue`)
                        if (it.key === 'amount') {
                            if (oldValue !== null) {
                                oldValueFormatted = moneyFormat(oldValue, 'VND')
                            }
                            if (newValue !== null) {
                                newValueFormatted = moneyFormat(newValue, 'VND')
                            }
                        }

                        if (it.key === 'fee' || it.key === 'free') {
                            if (toBoolean(oldValue) === false) {
                                oldValueFormatted = t('logPeerPayment.notFree')
                            }
                            else if (toBoolean(oldValue) === true) {
                                oldValueFormatted = t('logPeerPayment.free')
                            }
                            else {
                                oldValueFormatted = t(`logPeerPayment.nullValue`)
                            }

                            if (toBoolean(newValue) === false) {
                                newValueFormatted = t('logPeerPayment.notFree')
                            }
                            else if (toBoolean(newValue) === true) {
                                newValueFormatted = t('logPeerPayment.free')
                            }
                            else {
                                newValueFormatted = t(`logPeerPayment.nullValue`)
                            }
                        }

                        data.push(wrapDiv(renderTemplate(langKey, {
                            field: wrapBoldText(t(`logPeerPayment.${it.key}`)),
                            feeName: wrapBoldText(targetMetadata.feeName),
                            oldValue: wrapBoldText(oldValueFormatted),
                            newValue: wrapBoldText(newValueFormatted)
                        })))
                    }
                    return data.join('')
                }
                break;

            case "PEER_PAYMENT_STATUS_UPDATE":
                if (item.change.type === 'PROPERTY_CHANGE') {
                    let langKey = t('logPeerPayment.fieldChange')
                    let changedValues = item.change.valueChange.changedValues
                    const data = []
                    for (let it of changedValues) {
                        let oldValue = it.left
                        let newValue = it.right
                        let oldValueFormatted = oldValue
                        let newValueFormatted = newValue

                        if (oldValue === null) oldValueFormatted = t('logPeerPayment.nullValue')
                        if (newValue === null) newValueFormatted = t('logPeerPayment.nullValue')

                        if (it.key === 'status') {
                        	let status = getStatusByCode(oldValue)
                            oldValueFormatted = lodash.get(status, 'name', '---')

                            status = getStatusByCode(newValue)
                            newValueFormatted = lodash.get(status, 'name', '---')
                        }

                        data.push(wrapDiv(renderTemplate(langKey, {
                            field: wrapBoldText(t(`logPeerPayment.${it.key}`)),
                            oldValue: wrapBoldText(oldValueFormatted),
                            newValue: wrapBoldText(newValueFormatted)
                        })))
                    }
                    return data.join('')
                }
                break;

            case "PEER_PAYMENT_EXCHANGE_RATE_UPDATE":
                if (item.change.type === 'PROPERTY_CHANGE') {
                    let langKey = t('logPeerPayment.fieldChange')
                    let changedValues = lodash.get(item.change.valueChange, 'changedValues') || []
                    const data = []
                    for (let it of changedValues) {
                        let oldValue = it.left
                        let newValue = it.right
                        let oldValueFormatted = oldValue
                        let newValueFormatted = newValue
                        if (it.key === 'exchangeRate') {
                            if (oldValue === null) {
                                oldValueFormatted = t(`logPeerPayment.nullValue`)
                            }
                            else {
                                oldValueFormatted = moneyFormat(oldValue, 'VND')
                            }

                            if (newValue === null) {
                                newValueFormatted = t(`logPeerPayment.nullValue`)
                            }
                            else {
                                newValueFormatted = moneyFormat(newValue, 'VND')
                            }
                        }

                        data.push(wrapDiv(renderTemplate(langKey, {
                            field: wrapBoldText(t(`logPeerPayment.${it.key}`)),
                            oldValue: wrapBoldText(oldValueFormatted),
                            newValue: wrapBoldText(newValueFormatted)
                        })))
                    }
                    return data.join('')
                }
                break;

            case "PEER_PAYMENT_UPDATE_TELLER":
                if (item.change.type === 'PROPERTY_CHANGE') {
                    let langKey = t('logPeerPayment.fieldChange')
                    let changedValues = lodash.get(item.change.valueChange, 'changedValues') || []
                    const data = []
                    for (let it of changedValues) {
                        let oldValue = it.left
                        let newValue = it.right
                        let oldValueFormatted = oldValue
                        let newValueFormatted = newValue
                        if (it.key === 'status') {
                            let status = getStatusByCode(oldValue)
                            oldValueFormatted = lodash.get(status, 'name', '---')

                            status = getStatusByCode(newValue)
                            newValueFormatted = lodash.get(status, 'name', '---')
                        }

                        if (it.key === 'teller') {
                            if (oldValue === null) {
                                oldValueFormatted = t(`logPeerPayment.nullValue`)
                            }

                            if (newValue === null) {
                                newValueFormatted = t(`logPeerPayment.nullValue`)
                            }
                        }

                        data.push(wrapDiv(renderTemplate(langKey, {
                            field: wrapBoldText(t(`logPeerPayment.${it.key}`)),
                            oldValue: wrapBoldText(oldValueFormatted),
                            newValue: wrapBoldText(newValueFormatted)
                        })))
                    }
                    return data.join('')
                }
                break;

            case "PEER_PAYMENT_CREATE":
                if (item.change.type === 'NEW_OBJECT') {
                    let langKey = t(`logPeerPayment.${item.activity}`)
                    const data = item.change.newObject[0]
                    let peerPaymentTypeFormatted = t('logPeerPayment.peerPaymentTypePayment')
                    if (data.peerPaymentType === 'transfer') {
                        peerPaymentTypeFormatted = t('logPeerPayment.peerPaymentTypeTransfer')
                    }
                    return wrapDiv(renderTemplate(langKey, {
                        code: wrapBoldText(data.code),
                        peerPaymentType: wrapBoldText(peerPaymentTypeFormatted),
                    }))
                }
                break;

			case "PEER_PAYMENT_ORIGINAL_RECEIPT_DELETE":
				if (item.change.type === 'OBJECT_REMOVED') {
					let langKey = t(`logPeerPayment.${item.activity}`)
					const data = item.change.removedObject[0]
					return wrapDiv(renderTemplate(langKey, {
						originalReceiptCode: wrapBoldText(data.code)
					}))
				}
				break;

			case "PEER_PAYMENT_ORIGINAL_RECEIPT_CREATE":
				if (item.change.type === 'NEW_OBJECT') {
					let langKey = t(`logPeerPayment.${item.activity}`)
					const data = item.change.newObject[0]
					return wrapDiv(renderTemplate(langKey, {
						originalReceiptCode: wrapBoldText(data.code)
					}))
				}
				break;
            default:
        }
    };

    return (
        <div>
            <div>
                <Row key={item.id}
                     className={`_row-log-info txt-size-h7 txt-color-gray robotoregular dpl-block mgt12`}>
                    <span>{moment(item.timestamp).format('HH:mm DD/MM/YYYY')}</span>,
                    <span>
                        <span
                            className={'pdl5'}>{t(`logPeerPayment.${item.actor.role}`)}</span>
                        <span
                            className={'txt-color-black robotobold pdl5'}>{item.actor.username}</span>
                    </span>
                </Row>

                <Row
                    className={`_row-log-content txt-size-h6 txt-color-black robotoregular dpl-block whitespace-pre-wrap break-word`}
                    dangerouslySetInnerHTML={{__html: formatLogsContent(item)}}
                />

                <Divider className={'mgt12 mgbt0'} style={{backgroundColor: '#e8e8e8'}}/>
            </div>
        </div>
    )
}
