/* eslint-disable array-callback-return */
import React from 'react'
import { Typography, Row, Col, Divider, Steps, Modal, notification } from 'antd'
import * as AntdIcon from '@ant-design/icons'
import { moneyCeil, moneyFormat, quantityFormat } from '../../utils/stringUtils'
import _ from 'lodash'
import Chatbox from '../../components/TicketDetail/ChatBox'
import TicketService from '../../services/tickets.service'
import MainLayout from '../../components/Layout/MainLayout'
import ViewVote from '../../components/Rating/ViewVote'
import moment from 'moment'

import OrdersService from '../../services/orders.service'
import { LocalStore } from '../../utils/LocalStore'
import uploadFile from '../../utils/api/uploadFile'

import { Link } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'
import Page404 from '../Page404'

let currentLoggedUser = null
const initialCommentPage = 0

const { Step } = Steps

let paramCode = null

class index extends React.Component {
    constructor(props) {
        super(props)
        this.buttonSubmit = null
        this.state = {
            listComments: [],
            commentLoading: '',
            listRelative: [],
            dataDetail: {},
            dataState: {},
            histories: {},
            paramCode: '',
            is404: false,
            // isScreenSmall: false,
            isRefund: false,
            isModalClose: false,
            isShowConfirm: false,
            rating: 0,
            comment: '',
            isUploading: false,
            isCommenting: false,
            listAttachment: []
        }
    }

    componentWillMount() {
        window.onbeforeunload = null

        paramCode = this.props.match.params.id
        this.setState({ paramCode: paramCode }, () => {
            this.initData()
        })

        // this.windowResize();
    }

    componentDidUpdate(prevProps, prevState) {
        const { dataDetail } = this.state
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.initData()
        }
        if(prevState?.dataDetail?.id === undefined && dataDetail?.id){
            this.loadDataComments(initialCommentPage)
        }
    }

    componentDidMount() {
        if (typeof LocalStore.getInstance().read('currentLoggedUser') === 'object') {
            currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {}
        }
        // window.addEventListener('resize', this.windowResize);
    }

    componentWillUnmount() {
        // window.removeEventListener('resize', this.windowResize);
    }

    initData() {
        paramCode = this.props.match.params.id
        TicketService.getDetailComplaint(paramCode, (err, res) => {
            if (err === null) {
                this.setState({
                    dataDetail: res || {},
                    isRefund: res.publicStateNewView && res.publicStateNewView.code === 'REFUND' ? true : false,
                    rating: res && res.rating,
                    comment: res && res.ratingComment,
                })
            } else {
                if (err.code === 400) {
                    this.setState({ is404: true })
                }
            }
        })

        TicketService.getPublicStates((err, res) => {
            if (err === null) {
                this.setState({ dataState: res || {} })
            }
        })

        TicketService.getHistories(paramCode, (err, res) => {
            if (err === null) {
                this.setState({ histories: res || {} })
            }
        })
    }

    // /**
    //  * khi window resize
    //  */
    // windowResize = () => {
    //     const windowWidth = window.innerWidth;
    //
    //     this.setState({
    //         isScreenSmall: windowWidth < 1180
    //     });
    //
    // };

    /**
     * lấy ra danh sách comment theo paramCode
     */
    loadDataComments = page => {
        if (page === undefined || page === null) page = 0
        let { listComments = [], commentLoading, dataDetail } = this.state
        let prevScrollHeight = 0
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const taiyiConfig = _.get(currentProjectInfo, 'tenantConfig.taiyiConfig.enabled')
        const enableTime = _.get(currentProjectInfo, 'tenantConfig.taiyiConfig.updateToNewCommentTime')
        const checkTimeNew = moment(dataDetail?.createdAt)?.isAfter(moment(enableTime))
        // lấy ra scroll height trước khi load thêm dữ liệu
        if (this.commentComponent) {
            prevScrollHeight = this.commentComponent?.chatScroller?.scrollHeight
        }

        if (commentLoading) return
        this.setState({ commentLoading: true, showProgressBar: true }, () => {
            if (taiyiConfig && checkTimeNew) {
                TicketService.getCommentsPosedon(this.props.match.params.id, page, (err, res, headers) => {
                    if (err === null) {
                        this.setState(
                            {
                                commentLoading: false,
                                showProgressBar: false,
                                listComments: [...listComments, ...res],
                                listCommentsMetadata: {
                                    pageCount: headers['x-page-count'],
                                    page: headers['x-page-number'],
                                    size: headers['x-page-size'],
                                    total: headers['x-total-count'],
                                },
                            },
                            () => {
                                if (this.commentComponent) {
                                    if (page === 0) {
                                        this.commentComponent.chatScroller.scrollTop =
                                            this.commentComponent.chatScroller.scrollHeight
                                    } else {
                                        this.commentComponent.chatScroller.scrollTop =
                                            this.commentComponent.chatScroller.scrollHeight - prevScrollHeight
                                    }
                                }
                            }
                        )
                    } else {
                        this.setState({ commentLoading: false, showProgressBar: false })
                    }
                })

                TicketService.getAttachmentPosedon(this.props.match.params.id, (err, res, headers) => {
                    if (err === null) {
                        this.setState(
                            {
                                listAttachment: res, 
                            },
                        )
                    } else {
                        this.setState({ commentLoading: false, showProgressBar: false })
                    }
                })
            } else {
                OrdersService.getCommentsTicket(this.props.match.params.id, page, (err, res, headers) => {
                    // console.log('api call', [...listComments, ...res.data]);

                    if (err === null) {
                        this.setState(
                            {
                                commentLoading: false,
                                showProgressBar: false,
                                listComments: [...listComments, ...res],
                                listCommentsMetadata: {
                                    pageCount: headers['x-page-count'],
                                    page: headers['x-page-number'],
                                    size: headers['x-page-size'],
                                    total: headers['x-total-count'],
                                },
                            },
                            () => {
                                if (this.commentComponent) {
                                    if (page === 0) {
                                        this.commentComponent.chatScroller.scrollTop =
                                            this.commentComponent.chatScroller.scrollHeight
                                    } else {
                                        this.commentComponent.chatScroller.scrollTop =
                                            this.commentComponent.chatScroller.scrollHeight - prevScrollHeight
                                    }
                                }
                            }
                        )
                    } else {
                        this.setState({ commentLoading: false, showProgressBar: false })
                    }
                })
            }
        })
    }

    /**
     * next comment page
     */
    nextCommentPage = () => {
        const { listCommentsMetadata } = this.state
        if (!listCommentsMetadata) return
        if (parseFloat(listCommentsMetadata.page) + 1 < parseFloat(listCommentsMetadata.pageCount)) {
            this.loadDataComments(parseFloat(listCommentsMetadata.page) + 1)
        }
    }

    renderStep = () => {
        let { dataState, histories, dataDetail } = this.state
        let arrayState = dataState
        Array.isArray(arrayState) &&
            arrayState.map((data, index) => {
                Array.isArray(histories) &&
                    histories.map(item => {
                        if (data.code === item.state) {
                            arrayState[index].timestamp = item.timestamp
                        }
                    })
            })

        const sortedArrayState = _.sortBy(arrayState, 'sort')
        let current_code = dataDetail.publicStateNewView ? dataDetail.publicStateNewView.code : 'NEW'

        let indexOfState = Array.isArray(arrayState)
            ? _.findIndex(sortedArrayState, function (o) {
                  return o.code === current_code
              })
            : 0

        return (
            <Steps
                progressDot
                current={indexOfState}
                className={'ticketdetailstep'}
            >
                {Array.isArray(arrayState) &&
                    sortedArrayState.map((data, index) => {
                        return (
                            <Step
                                className="_step-item"
                                key={data.code}
                                title={data.description}
                                description={data.timestamp ? moment(data.timestamp).format('HH:mm DD/MM/YYYY') : ''}
                            />
                        )
                    })}
            </Steps>
        )
    }

    handlePreview = item => {
        this.setState({
            previewItem: item,
            previewVisible: true,
        })
    }

    /**
     * tạo mới 1 comment
     * @param data
     */
    createComment = async data => {
        let { isCommenting, isUploading, dataDetail, initialCommentPage, paramCode } = this.state

        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const taiyiConfig = _.get(currentProjectInfo, 'tenantConfig.taiyiConfig.enabled')
        const enableTime = _.get(currentProjectInfo, 'tenantConfig.taiyiConfig.updateToNewCommentTime')
        const checkTimeNew = moment(dataDetail?.createdAt)?.isAfter(moment(enableTime))
        if (isCommenting === true || isUploading === true || data?.content?.trim().replace(/<[^>]*>?/gm, '')?.length === 0) return
        if (taiyiConfig && checkTimeNew) {
            this.setState({
                isCommenting: true,
            })
            TicketService.createCommentInClaim(paramCode, { content: data.content?.trim() }, (err, res, headers) => {
                if (!err) {
                    this.setState(
                        {
                            isCommenting: false,
                            listComments: [],
                        },
                        () => {
                            this.loadDataComments(initialCommentPage)
                        }
                    )
                } else {
                    this.setState({ isCommenting: false })
                }
            })
        } else {
            let formData = new FormData()
            formData.append('comment', new Blob([JSON.stringify(data)], { type: 'application/json' }))
            let token = LocalStore.getInstance().read('loginSession')

            let res

            try {
                this.setState({ isCommenting: true, showProgressBar: true })
                res = await fetch(
                    (process.env.REACT_APP_API_URL || window.location.hostname) +
                        '/customer/canines/claims/' +
                        dataDetail.code +
                        '/comments',
                    {
                        method: 'POST',
                        headers: {
                            Authorization: 'Bearer ' + token.id,
                        },
                        body: formData,
                    }
                )

                if (res.status === 200) {
                    this.setState(
                        {
                            listComments: [],
                        },
                        () => {
                            this.loadDataComments(initialCommentPage)
                        }
                    )
                }
            } catch (err) {
                notification['error']({
                    message: res.message,
                })
            }

            this.setState({ isCommenting: false, showProgressBar: false })
        }
    }

    /**
     * archive Issue
     */
    archiveIssue = () => {
        const { archiveIssue } = this.state
        const { t } = this.props
        this.setState(
            {
                showProgressBar: true,
            },
            () => {
                if (archiveIssue) return
                let issueCode = this.props.match.params.id
                TicketService.archiveIssue(issueCode, (err, res) => {
                    if (err) {
                        if (err.statusCode !== 'Network fail')
                            notification['error']({
                                message: t(err.message),
                            })
                    } else {
                        this.setState({ isShowConfirm: false, dataDetail: res })
                    }
                })
            }
        )

        this.setState({ showProgressBar: false })
    }

    /**
     * call api update Rate
     * @param data
     */
    updateRating = data => {
        if (this.state.isLoading === true) return
        const { t } = this.props
        this.setState({ showProgressBar: true, isLoadingRate: true }, () => {
            let issueCode = this.props.match.params.id

            TicketService.updateRating(data, issueCode, (error, res) => {
                if (!error) {
                    notification['success']({
                        message: t('message.success'),
                    })
                    this.setState({ rating: res.rating, comment: res.ratingComment })
                } else {
                    if (error.statusCode !== 'Network fail')
                        notification['error']({
                            message: error.message,
                        })
                }
                this.setState({ showProgressBar: false, isLoadingRate: false })
            })
        })
    }

    /**
     * upload ảnh comment
     * @param files
     */
    uploadImageComment = files => {
        const { isCommenting, isUploading, paramCode, dataDetail } = this.state
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const taiyiConfig = _.get(currentProjectInfo, 'tenantConfig.taiyiConfig.enabled')
        const enableTime = _.get(currentProjectInfo, 'tenantConfig.taiyiConfig.updateToNewCommentTime')
        const checkTimeNew = moment(dataDetail?.createdAt)?.isAfter(moment(enableTime))
        if (isCommenting === true || isUploading === true) return

        if (taiyiConfig && checkTimeNew) {
            this.setState({
                isUploading: true,
            })
            let apiServiceUrl = `/customer/comments/attachments`
            uploadFile(
                apiServiceUrl,
                files || [],
                {
                    command: {
                        module: 'CUSTOMER_CLAIM',
                        thread: paramCode,
                    },
                },
                'attachment'
            ).then(response => {
                let content = ''
                if (response?.mimeType?.indexOf('image/') >= 0) {
                    content = `<img src="${response?.location}" alt="${response.name}"></img>`
                } else {
                    content = `<a target="_blank" href="${response?.location}"><i class="fa-solid fa-paperclip"></i> ${response.name}</a>`
                }
                TicketService.createCommentInClaim(paramCode, { content }, (err, res, headers) => {
                    if (!err) {
                        this.setState({
                            isUploading: false,
                            listComments: []
                        })
                        this.loadDataComments(initialCommentPage)
                    } else {
                        this.setState({ isUploading: false, isCommenting: false })
                    }
                })
            })
        } else {
            this.setState({ isUploading: true, showProgressBar: true }, () => {
                let apiServiceUrl = `/customer/canines/claims/${paramCode}/comments`

                const body = {
                    comment: { content: '' },
                }
                uploadFile(apiServiceUrl, files, body)
                    .then(response => {
                        this.setState(
                            {
                                listComments: [],
                                isUploading: false,
                                showProgressBar: false,
                            },
                            () => {
                                if (this.commentComponent) {
                                    this.commentComponent.setState({ fileList: [] })
                                }
                                this.loadDataComments(initialCommentPage)
                            }
                        )
                    })
                    .catch(error => {
                        if (error.statusCode !== 'Network fail')
                            notification['error']({
                                message: error.message,
                            })
                        this.setState({ isUploading: false, showProgressBar: false })
                    })
            })
        }
    }

    goBack = () => {
        this.props.history.goBack()
    }

    checkShowCloseArchived = () => {
        const { dataDetail } = this.state

        if (dataDetail && !dataDetail.archived) {
            if (
                (dataDetail.publicStateNewView && dataDetail.publicStateNewView.code === 'REFUND') ||
                (dataDetail.publicStateNewView && dataDetail.publicStateNewView.code === 'REJECT')
            ) {
                return true
            }
        }

        return false
    }

    render() {
        const {
            listCommentsMetadata,
            dataDetail,
            previewVisible,
            previewItem = {},
            isRefund,
            is404,
            listAttachment
        } = this.state
        const { t } = this.props
        if (is404) return <Page404 />
        return (
            <MainLayout
                ref={ref => (this.mainLayoutElement = ref)}
                {...this.props}
                showProgressBar={this.state.showProgressBar}
                headerTitle={t('ticket_detail.title')}
                title={t('ticket_detail.title')}
            >
                <div className={'container-common pdbt30 mgr20'}>
                    <div className="pdt10 pdbt10 dpl-flex">
                        <Typography.Link
                            className={'_back-to-list cursor-pointer'}
                            onClick={this.goBack}
                        >
                            <AntdIcon.ArrowLeftOutlined
                                className=""
                                type="arrow-left"
                            />
                        </Typography.Link>
                        <Typography.Link
                            className={'_back-to-list-2'}
                            onClick={this.goBack}
                        >
                            <div className={'cursor-pointer pdl10 txt-size-h8 robotoregular'}>{t('login.go_back')}</div>
                        </Typography.Link>
                    </div>

                    <div className="bg-color-white mgbt20 pd20 pdbt0">
                        <Row className={'dpl-flex pdbt15 border-bottom-1x bd-color-gray'}>
                            <Col span={12}>
                                <div className={'search-box__label txt-color-black txt-size-h6 robotobold'}>
                                    #{dataDetail && dataDetail.code},{' '}
                                    {dataDetail && dataDetail.name ? (
                                        <span>
                                            {dataDetail.name.split(dataDetail.relatedOrder)[0]}
                                            <Link
                                                className="txt-color-blue _link-detail-order"
                                                to={
                                                    dataDetail.ticketType.toUpperCase() === 'SHIPMENT'
                                                        ? `/shipments/${dataDetail.relatedOrder}`
                                                        : `/orders/${dataDetail.relatedOrder}`
                                                }
                                                target={'_blank'}
                                            >
                                                {dataDetail.relatedOrder}
                                            </Link>
                                            {dataDetail.name.split(dataDetail.relatedOrder)[1]}
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </Col>
                            <Col
                                span={12}
                                className="txt-right"
                            >
                                {this.checkShowCloseArchived() ? (
                                    <span
                                        className={
                                            'cursor-pointer _order-item-code listorders__items_codFe txt-color-blue txt-size-h8 robotoregular mgr10'
                                        }
                                        onClick={() => this.setState({ isShowConfirm: true })}
                                    >
                                        {t('ticket_detail.close')}
                                    </span>
                                ) : (
                                    ''
                                )}

                                <span
                                    style={{
                                        backgroundColor: dataDetail.publicStateNewView && dataDetail.publicStateNewView.color,
                                    }}
                                    className={'listorders__items_right--status dpl-il-block'}
                                >
                                    {dataDetail.publicStateNewView && dataDetail.publicStateNewView.name}
                                </span>
                            </Col>
                        </Row>
                        <Row className={'pdt20 pdbt10 dpl-flex pdbt25 border-bottom-1x bd-color-gray'}>
                            <Col
                                span={12}
                                className="dpl-flex justify-content-between"
                            >
                                <div className="order-item-head__left dpl-flex align-items-center">
                                    <div className="align-items-center">
                                        <p className={'txt-color-gray txt-size-h8 robotoregular'}>{t('ticket_detail.reason')}</p>
                                        <p className={'txt-color-black txt-size-h7 robotomedium _reason-name'}>
                                            {dataDetail.reasonView && dataDetail.reasonView.name
                                                ? dataDetail.reasonView.name
                                                : '---'}
                                        </p>
                                    </div>
                                </div>
                                <Divider
                                    type="vertical"
                                    className={'ticket_vertical height100'}
                                />

                                <div className="order-item-head__left dpl-flex align-items-center ">
                                    <div className="align-items-center">
                                        <p className={'txt-color-gray txt-size-h8 robotoregular'}>
                                            {t('ticket_detail.solution')}
                                        </p>
                                        <p className={'txt-color-black txt-size-h7 robotomedium _solution-name'}>
                                            {dataDetail.solutionView && dataDetail.solutionView.name
                                                ? dataDetail.solutionView.name
                                                : '---'}
                                        </p>
                                    </div>
                                </div>
                                <Divider
                                    type="vertical"
                                    className={'ticket_vertical height100'}
                                />

                                <div className="order-item-head__left dpl-flex align-items-center">
                                    <div className="align-items-center">
                                        <p className={'txt-color-gray txt-size-h8 robotoregular'}>{t('ticket_detail.demain')}</p>
                                        <p className={'txt-color-black txt-size-h7 robotomedium _suggest'}>
                                            {moneyFormat(moneyCeil(dataDetail && dataDetail.suggest))}
                                        </p>
                                    </div>
                                </div>
                                <Divider
                                    type="vertical"
                                    className={'ticket_vertical height100'}
                                />

                                {dataDetail.reasonView ? (
                                    <React.Fragment>
                                        <div className="order-item-head__left dpl-flex align-items-center">
                                            <div className="align-items-center">
                                                <p className={'txt-color-gray txt-size-h8 robotoregular'}>
                                                    {t('ticket_detail.quantity_missing')}
                                                </p>
                                                <p className={'txt-color-black txt-size-h7 robotomedium _notReceived'}>
                                                    {dataDetail.notReceived === 0
                                                        ? t('ticket_detail.all')
                                                        : quantityFormat(dataDetail.notReceived)}
                                                </p>
                                            </div>
                                        </div>
                                        <Divider
                                            type="vertical"
                                            className={'ticket_vertical height100'}
                                        />
                                    </React.Fragment>
                                ) : (
                                    ''
                                )}
                                {dataDetail ? (
                                    <React.Fragment>
                                        <div className="order-item-head__left dpl-flex align-items-center">
                                            <div className="align-items-center">
                                                <p className={'txt-color-gray txt-size-h8 robotoregular'}>
                                                    {isRefund ? t('ticket_detail.refund') : t('ticket_detail.suggestion')}
                                                </p>
                                                <p className={'txt-color-black txt-size-h7 robotomedium _totalRefund'}>
                                                    {isRefund
                                                        ? moneyFormat(moneyCeil(dataDetail && dataDetail.totalRefund))
                                                        : moneyFormat(moneyCeil(dataDetail && dataDetail.estimatedRefundValue))}
                                                </p>
                                            </div>
                                        </div>
                                        <Divider
                                            type="vertical"
                                            className={'ticket_vertical height100'}
                                        />
                                    </React.Fragment>
                                ) : (
                                    ''
                                )}
                            </Col>

                            <Col span={12}>
                                <div className="order-item-head__left dpl-flex align-items-center">{this.renderStep()}</div>
                            </Col>
                        </Row>
                        {/*{*/}
                        {/*    isScreenSmall ?*/}
                        {/*        <Row className={'pdt20 pdbt10 dpl-flex pdbt25 border-bottom-1x bd-color-gray'}>*/}
                        {/*            <Col xl={24} md={24}>*/}
                        {/*                <div className="order-item-head__left dpl-flex align-items-center">*/}
                        {/*                    {this.renderStep()}*/}
                        {/*                </div>*/}
                        {/*            </Col>*/}
                        {/*        </Row>*/}
                        {/*        : ''*/}
                        {/*}*/}

                        <Row className={'pdt15 pdbt15 dpl-flex pdbt25'}>
                            <div className="order-item-head dpl-flex justify-content-between border-bottom-1x bd-color-gray w100">
                                <Row className={'pdt15 pdbt15 dpl-flex pdbt25 w100'}>
                                    <Col span={12}>
                                        <ViewVote
                                            issueDetail={dataDetail || {}}
                                            isShowConfirm={this.state.isShowConfirm}
                                            onSubmitRating={this.updateRating}
                                            onArchiveIssue={this.archiveIssue}
                                            rating={this.state.rating}
                                            comment={this.state.comment}
                                        />
                                        <div className="order-item-head__left dpl-flex align-items-center">
                                            <div className="align-items-center mgr30">
                                                {dataDetail.reasonView && dataDetail.description ? (
                                                    <React.Fragment>
                                                        <p className={'txt-color-gray txt-size-h8 robotoregular'}>
                                                            {t('ticket_detail.description')}
                                                        </p>
                                                        <p
                                                            className={
                                                                'txt-color-black txt-size-h7 robotorebold break-word _description'
                                                            }
                                                        >
                                                            {dataDetail.description}
                                                        </p>
                                                    </React.Fragment>
                                                ) : (
                                                    ''
                                                )}
                                                {Array.isArray(dataDetail.attachments) && dataDetail.attachments.length > 0 && (
                                                    <React.Fragment>
                                                        <p className={'txt-color-gray txt-size-h8 robotoregular'}>
                                                            {t('ticket_detail.image')}
                                                        </p>

                                                        <section>
                                                            <div className="dropzone ">
                                                                {Array.isArray(dataDetail.attachments)
                                                                    ? dataDetail.attachments.map(item => {
                                                                          return (
                                                                              <div
                                                                                  onClick={() => this.handlePreview(item)}
                                                                                  key={item.id}
                                                                                  className={
                                                                                      'dropped-file mgr30 _attachments-item'
                                                                                  }
                                                                                  style={{
                                                                                      'background-image':
                                                                                          "url('" + item.presignedUrl + "')",
                                                                                      width: '100px',
                                                                                      height: '100px',
                                                                                  }}
                                                                              ></div>
                                                                          )
                                                                      })
                                                                    : ''}
                                                            </div>
                                                        </section>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="order-item-head__left dpl-flex align-items-center">
                                            <div className="ant-col-24 flex-column bg-color-white bd-rdu bd1px bd-color-gray boxchat ">
                                                <Chatbox
                                                    t={t}
                                                    ref={e => (this.commentComponent = e)}
                                                    hasMore={
                                                        listCommentsMetadata
                                                            ? parseFloat(listCommentsMetadata.page) + 1 <
                                                              parseFloat(listCommentsMetadata.pageCount)
                                                            : false
                                                    }
                                                    currentLoggedUser={currentLoggedUser || {}}
                                                    listComments={this.state.listComments}
                                                    commentLoading={this.state.commentLoading}
                                                    nextCommentPage={this.nextCommentPage}
                                                    createComment={this.createComment}
                                                    uploadImageComment={this.uploadImageComment}
                                                    isReverse={true}
                                                    isUploading={this.state.isUploading}
                                                    isCommenting={this.state.isCommenting}
                                                    dataDetail={dataDetail}
                                                    listAttachment={listAttachment}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Row>
                    </div>

                    {previewVisible && (
                        <Modal
                            width={700}
                            className={'modal-preview-image _modal-preview'}
                            visible={previewVisible}
                            footer={null}
                            onCancel={() => this.setState({ previewVisible: false })}
                        >
                            {previewItem.type && previewItem.type.indexOf('video') > -1 ? (
                                <video
                                    className="_video-preview"
                                    width="100%"
                                    height="500"
                                    controls
                                >
                                    <source
                                        src={previewItem.presignedUrl}
                                        type={previewItem.type}
                                    />
                                    {previewItem.name}
                                </video>
                            ) : (
                                <img
                                    className="_img-preview"
                                    alt={previewItem.name}
                                    style={{ width: '100%' }}
                                    src={previewItem.presignedUrl}
                                />
                            )}
                        </Modal>
                    )}
                </div>
            </MainLayout>
        )
    }
}

export default withNamespaces()(index)
