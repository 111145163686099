import React from 'react'
import moment from 'moment'
import MainLayout from '../../components/Layout/MainLayout'
import Loading from '../../components/Loading'
import {
    Row, Col, Empty, Input, DatePicker, Popconfirm, Tooltip,
    Button, Spin, Pagination, List, Avatar, Tag
} from 'antd';
import * as AntdIcon from "@ant-design/icons"
import ProfileSidebar from '../../components/Profile/Leftbar'

import {getUrlQueryString} from "../../utils/helper";
import {
    linkify,
    moneyCeil,
    moneyFormat,
    parseQueryStringToObject, quantityFormat,
    updateQueryStringParameter
} from "../../utils/stringUtils";
import {LocalStore} from "../../utils/LocalStore";
import {notification} from "antd/lib/index";
import UserService from "../../services/user.service";
import _ from "lodash";
import CategoriesService from "../../services/categories.service";
import {withNamespaces} from "react-i18next";
let pageSize = 5;

class SavedProducts extends React.Component {
    constructor(props) {
        super(props);
        this.isUnmounted = false;
        this.state = {
            page: 1,
            pageSize: pageSize,
            isLoading: true,
            listProducts: [],
            originData: [],
            datetimeRange: [],
            startTime: undefined,
            endTime: undefined,
            querySearch: '',
            targetCurrency: 'VND',
        }
    }

    componentDidMount() {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null;
        window.addEventListener("popstate", this.onPopState.bind(this));

        if (typeof LocalStore.getInstance().read('currentLoggedUser') === 'object') {
            this.setState({isLoading: true}, () => {
                this.loadFromQueryString();

                this.getExchangeRate()
            });
        }
        if (!LocalStore.getInstance().read('cartLanguage')) {
            this.setState({cartLanguage: 'VN'}, () => {
                LocalStore.getInstance().save('cartLanguage', this.state.cartLanguage);
            })
        } else {
            this.setState({cartLanguage: LocalStore.getInstance().read('cartLanguage')});
        }
    }

    /**
     * Khi unmount ra thì bỏ sự kiện popstate
     */
    componentWillUnmount() {
        this.isUnmounted = true;
        window.removeEventListener("popstate", this.onPopState.bind(this));
    }

    /**
     * On popstate
     */
    onPopState() {
        if (!this.isUnmounted) this.setState({isLoading: true}, ()=> {
            this.loadFromQueryString();
        });
    };

    /**
     * Load từ QueryString (dành cho lần load đầu tiên)
     */
    loadFromQueryString = () => {
        let queryString = getUrlQueryString();
        let queryObj = parseQueryStringToObject(queryString) || {};

        // chuyển giá trị từ queryObj vào state;
        //reset các param khi load từ url
        let querySearch = '';
        let startTime = undefined;
        let endTime = undefined;
        for (let p in queryObj) {
            if (p === 'createdFrom') {
                // xử lý với với timestamp
                startTime = decodeURIComponent(queryObj['createdFrom']);
            } else if (p === 'createdTo') {
                // xử lý với với timestamp
                endTime = decodeURIComponent(queryObj['createdTo']);
            } else {
                // xử lý với query
                querySearch = decodeURIComponent(queryObj['query'] || '')
            }
        }

        this.setState({
            querySearch,
            startTime,
            endTime,
            page: queryObj.page,
            pageSize: queryObj.size || pageSize,
        }, ()=> {
            this.loadData();
        });
    };

    /**
     * load data
     */
    loadData = (cb) => {
        const {page, pageSize} = this.state;
        let queryString = this.buildQueryString();
        if (queryString) {
            queryString = queryString + `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        } else {
            queryString = `?page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        }
        this.setState({showProgressBar: true}, () => {
            UserService.getWishlist(queryString, (err, res, headers) => {
                if (!err) {
                    this.setState({
                        listProducts: res,
                        originData: _.cloneDeep(res),
                        metadata: {
                            pageCount: headers['x-page-count'],
                            page: headers['x-page-number'],
                            size: headers['x-page-size'],
                            total: headers['x-total-count'],
                        }
                    }, () => {
                        // scroll to top
                        if (this.mainLayoutElement && this.mainLayoutElement.scrollLayoutElement) this.mainLayoutElement.scrollLayoutElement.scrollTop = 0;
                    });
                }
                if(cb) cb();
                this.setState({showProgressBar: false, isLoading: false})
            })
        })
    };

    /**
     * lấy ra tỷ giá
     */
    getExchangeRate = () => {
        CategoriesService.getCurrentExchangeRate((err, res, header) => {
            if (!err) {
                this.setState({
                    exchangeRate: res
                })
            }
        })
    };

    /**
     * cập nhật ghi chú cá nhân
     * @param item
     * @param index
     */
    updateItem = (item, index) => {
        const {listProducts, originData} = this.state;
        const{t} = this.props;
        this.setState({showProgressBar: true, [`isLoading${item.id}`]: true}, () => {
            UserService.updateItemWishlist(item.id, item, (err, res) => {
                if (!err) {
                    listProducts[index] = res;
                    originData[index] = _.cloneDeep(res);
                    this.setState({
                        listProducts,
                        originData,
                        ['showEdit'+item.id]: false
                    })
                } else {
                    if (err.statusCode !== 'Network fail') notification['error']({
                        message: t(err.message),
                    });
                }
                this.setState({showProgressBar: false, [`isLoading${item.id}`]: false})
            });
        })
    };

    /**
     * xóa 1 bản ghi
     * @param item
     */
    deleteItem = (item) => {
        this.setState({showProgressBar: true, [`isLoading${item.id}`]: true}, () => {
            UserService.deleteItemWishlist(item.id, (err, res) => {
                if (!err) {
                    notification['success']({
                        message: this.props.t('message.delete_success'),
                    });
                    this.loadData()
                } else {const{t} = this.props;
                    if (err.statusCode !== 'Network fail') notification['error']({
                        message: t(err.message),
                    });
                }
                this.setState({showProgressBar: false, [`isLoading${item.id}`]: false})
            });
        })
    };

    onPageChanged = (page) => {
        this.setState({page: page}, () => {
            let queryString = getUrlQueryString();
            queryString = updateQueryStringParameter(queryString ? '?'+ queryString : '', 'page', page);
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: queryString
            });
        });
    };

    /**
     * xây dựng query string theo các giá trị trong state
     * @returns {string}
     */
    buildQueryString = () => {
        let {startTime, endTime, querySearch} = this.state;
        let queryString = '';

        if (startTime) {
            queryString = updateQueryStringParameter(queryString, 'createdFrom', startTime)
        }

        if (endTime) {
            queryString = updateQueryStringParameter(queryString, 'createdTo', endTime)
        }

        if (querySearch) {
            queryString = updateQueryStringParameter(queryString, 'query', querySearch.trim())
        }

        return queryString
    };

    onClearSearch = () => {
        this.setState({
            page: 1,
            querySearch: '',
            startTime: undefined,
            endTime: undefined,
        }, () => {
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: this.buildQueryString()
            });
        })
    };

    /**
     * xử lý với dữ liệu search trong state
     */
    onSubmitSearch = () => {
        this.setState({page: 1}, () => {
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: this.buildQueryString()
            });
        })
    };

    dateTimeChange = (date) => {
        this.setState({
            datetimeRange: date.length > 0 ? [date[0].startOf('day').toISOString(), date[1].endOf('day').toISOString()] : []
        })
    };

    startTimeChange = (date) => {
        this.setState({
            startTime: date ? date.startOf('day').toISOString() : undefined
        })
    };

    endTimeChange = (date) => {
        this.setState({
            endTime: date ? date.endOf('day').toISOString() : undefined
        })
    };

    /**
     * input change event
     * @param value
     * @param index
     */
    onInputChange = (value, index) => {
        let {listProducts} = this.state;
        listProducts[index]['note'] = value;
        this.setState({listProducts})
    };

    /**
     * Call func submit note order if Enter
     * @param e
     * @param index
     * @return {*}
     */
    onKeyDown = (e, index) => {
        if (e.target.value !== '' && e.target.value.trim() === '') return;
        let {listProducts, originData} = this.state;

        // console.log('etarget', e.metaKey)

        if ((e.metaKey || e.shiftKey || e.altKey || e.ctrlKey) && e.keyCode === 13) {

            // kiểm tra nếu có thay đổi dữ liệu thì sẽ lưu còn ko thì sẽ tắt edit
            if (listProducts[index]['note'] !== originData[index]['note']) {
                this.updateItem(listProducts[index], index)
            } else {
                this.setState({['showEdit'+listProducts[index].id]: false});
            }
        }
    };

    /**
     * Call func submit note order if click outside
     * @param value
     * @param index
     * @return {*}
     */
    onBlurInput = (value, index) => {
        if (value !== '' && value.trim() === '') return;
        let {listProducts, originData} = this.state;

        // kiểm tra nếu có thay đổi dữ liệu thì sẽ lưu còn ko thì sẽ tắt edit
        if (listProducts[index]['note'] !== originData[index]['note']) {
            this.updateItem(listProducts[index], index)
        } else {
            this.setState({['showEdit'+listProducts[index].id]: false});
        }
    };

    /**
     * render search box
     * @returns {*}
     */
    renderSearchBox = () => {
        const {
            startTime,
            endTime,
            querySearch,
        } = this.state;
        const {t} = this.props;

        return (
            <div className="searchbox bg-color-white mgbt10 border-1px-solid pd20">
                <Row className={'pdbt15 dpl-flex borderdotted-bootom-2x bd-color-gray'}>
                    <Col span={12} className="pdr15">
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('customer_info.input_product')}:
                        </div>
                        <Input
                            value={querySearch}
                            onChange={(e) => this.setState({querySearch: e.target.value})}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.onSubmitSearch()
                                }
                            }}
                            className={'_search-box-input-query'}
                        />
                    </Col>
                    <Col span={12} className="">
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('customer_info.time')}:
                        </div>
                        {/*<DatePicker.RangePicker*/}
                            {/*className={'_search-date-time width-100-percent'}*/}
                            {/*format={'DD/MM/YYYY'}*/}
                            {/*value={datetimeRange.length > 0 ? [moment(datetimeRange[0]), moment(datetimeRange[1])] : []}*/}
                            {/*onChange={this.dateTimeChange}*/}
                        {/*/>*/}
                        <Row gutter={20}>
                            <Col span={12}>
                                <DatePicker
                                    disabledDate={this.disabledStartDate}
                                    className="_datepicker width-100-percent"
                                    format={'DD/MM/YYYY'}
                                    value={startTime ? moment(startTime) : undefined}
                                    placeholder={t('customer_info.start_date')}
                                    onChange={this.startTimeChange}
                                />
                            </Col>
                            <Col span={12}>
                                <DatePicker
                                    disabledDate={this.disabledEndDate}
                                    className="_datepicker width-100-percent"
                                    format={'DD/MM/YYYY'}
                                    value={endTime ? moment(endTime) : undefined}
                                    placeholder={t('customer_info.end_date')}
                                    onChange={this.endTimeChange}
                                />
                            </Col>
                        </Row>

                    </Col>
                </Row>
                <div
                    className=" dpl-flex align-items-center justify-content-between pdt10"
                >
                    <span />
                    <div className="dpl-flex align-items-center">
                        <span onClick={this.onClearSearch} className="_btn-clear mgr20 txt-color-gray txt-size-h8 cursor-pointer robotoregular">
                            <AntdIcon.SyncOutlined type={'sync'} className={'mgr5'}/> {t('order.filter_refresh')}
                        </span>
                        <Button onClick={this.onSubmitSearch} type="primary" className={'_btn-search min-width-200'}>{t('customer_info.search')}</Button>
                    </div>
                </div>
            </div>
        )
    };

    renderAddTags = (id) => {
        const { tags = ['Unremovable', 'Tag 2', 'Tag 3'], inputShow = {}, inputValue } = this.state;

        const show = inputShow[id];
        return <div className="mgt10">
            {tags.map((tag, index) => {
                const isLongTag = tag.length > 20;
                const tagElem = (
                    <Tag key={tag} closable={index !== 0} afterClose={() => this.handleClose(tag)} className="mgbt5">
                        {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                    </Tag>
                );
                return isLongTag ? <Tooltip title={tag} key={tag}>{tagElem}</Tooltip> : tagElem;
            })}
            {
                show
                    ?
                    <Input
                        ref={ref => this.saveInputRef = ref}
                        type="text"
                        size="small"
                        style={{ width: 78 }}
                        value={inputValue}
                        onChange={(e) => this.setState({ inputValue: e.target.value })}
                        onBlur={() => console.log('save tag')}
                        onPressEnter={() => console.log('save tag')}
                    />
                    :
                    <Tag
                        onClick={() => this.setState({ inputShow: {[id]: true} }, () => this.saveInputRef.focus())}
                        style={{ background: '#fff', borderStyle: 'dashed' }}
                    >
                        <AntdIcon.PlusOutlined type="plus" /> Thêm Tag
                    </Tag>
            }
        </div>
    };

    /**
     * render item of list
     * @param item
     * @param index
     * @returns {*}
     */
    renderListItem = (item, index) => {
        const {
            cartLanguage, originData, targetCurrency
        } = this.state;
        const {t} = this.props;

        let noteOrigin = originData && originData[index] ? originData[index].note : '';
        return (
            <List.Item key={index} className={`_item position-re`}>
                <Spin key={index} wrapperClassName="width-100-percent" spinning={this.state[`isLoading${item.id}`] === true} indicator={<AntdIcon.Loading3QuartersOutlined type="loading" style={{ fontSize: 24 }} spin />} >
                    <Row>
                        <Col span={8} className="pdr10 dpl-flex align-items-start ">
                            {
                                item.productUrl ?
                                    <a href={item.productUrl} target={'_blank'} rel="noreferrer">
                                        <Avatar
                                            className={`_item-avatar imgthumb40`}
                                            shape="square"
                                            size={40}
                                            src={item.variantImage || item.productImage}
                                        />
                                    </a>
                                    :
                                    <Avatar
                                        className={`_item-avatar imgthumb40`}
                                        shape="square"
                                        size={40}
                                        src={item.variantImage || item.productImage}
                                    />
                            }

                            <div className={'pdl10'}>
                                {
                                    item.productUrl ?
                                        <a href={item.productUrl} target={'_blank'} rel="noreferrer">
                                            <h3 className={`_item-name txt-size-h7 txt-color-black break-word whitespace-pre-wrap`}>{cartLanguage === 'CN' ? item.originalName : item.name}</h3>
                                        </a>
                                        :
                                        <h3 className={`_item-name txt-size-h7 txt-color-black break-word whitespace-pre-wrap`}>{cartLanguage === 'CN' ? item.originalName : item.name}</h3>
                                }

                                <div className="dpl-flex align-items-center ">
                                    {
                                        item.merchant.url
                                            ?
                                            <a href={item.merchant.url} target={'_blank'} rel="noreferrer">
                                                <Avatar className={`_avatar-seller`} shape="square" size={16} src={item.merchant.marketplace.image} />
                                            </a>
                                            :
                                            <Avatar className={`_avatar-seller`} shape="square" size={16} src={item.merchant.marketplace.image} />
                                    }
                                    {
                                        item.merchant.url
                                            ?
                                                <a className={'pdl10 line-height-1'} href={item.merchant.url} target={'_blank'} rel="noreferrer">
                                                    <span className={`_cart-seller txt-color-black line-height-1`}>{item.merchant.name || item.merchant.code}</span>
                                                </a>
                                            :
                                                <span className={`_cart-seller txt-color-black pdl10 line-height-1`}>{item.merchant.name || item.merchant.code}</span>
                                    }
                                </div>
                                {
                                    item.variantProperties.map((x, y) => {
                                        return (
                                            <span key={y} className={`_item-properties-${item.id}-${y} txt-size-h8 txt-color-gray ${y !== 0 ? 'pdl10' : ''}`}>{cartLanguage === 'CN' ? x.originalValue :  x.value}</span>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                        <Col span={16}>
                            <Row>
                                <Col span={6} className={'position-re'}>
                                    {moment(item.createdAt).format('HH:mm DD/MM/YYYY')}
                                </Col>
                                <Col span={12}>
                                    {
                                        (!noteOrigin || this.state['showEdit'+item.id])
                                            ?
                                            <React.Fragment>
                                                <Input.TextArea
                                                    disabled={this.state[`isLoading${item.id}`]}
                                                    value={item.note}
                                                    maxLength={1000}
                                                    autosize={{ minRows: 1, maxRows: 3 }}
                                                    className={`_item-product-note`}
                                                    placeholder={t('order.remark')}
                                                    onChange={(e) => this.onInputChange(e.target.value, index)}
                                                    onKeyDown={(e) => this.onKeyDown(e, index)}
                                                    onBlur={(e) => this.onBlurInput(e.target.value, index)}
                                                    ref={ref => this[`note${item.id}`] = ref}
                                                />
                                                <span className={`txt-size-h8 txt-color-gray dpl-block`} style={{paddingTop: 3}}>
                                                    {t('order.note_keydown')}
                                                </span>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <span
                                                    className="txt-color-gray txt-size-h8 robotoregular white-space-pre-wrap break-word mgr10"
                                                    style={{display: 'inline'}}
                                                    dangerouslySetInnerHTML={{__html: linkify(item.note.trim())}}
                                                />
                                                <AntdIcon.EditOutlined
                                                    className={`_btn-edit-note txt-color-blue txt-size-h7`}
                                                    type="edit"
                                                    onClick={() =>
                                                        this.setState({['showEdit'+item.id]: !this.state['showEdit'+item.id]}, () => {
                                                            this[`note${item.id}`].focus();
                                                        })
                                                    }
                                                />
                                            </React.Fragment>
                                    }

                                    {/*{this.renderAddTags(item.id)}*/}
                                </Col>
                                <Col span={4} className={'txt-right'}>
                                    <h3 className={`_cart-item-price txt-size-h7 txt-color-black break-word pdl5`}>{moneyFormat(moneyCeil(item.exchangedSalePrice), targetCurrency)}</h3>
                                    <span className={`_cart-item-price-origin txt-size-h8 txt-color-gray break-word pdl5`}>{moneyFormat(item.salePrice, item.currency ? item.currency.code : 'CNY')}</span>
                                </Col>
                                <Col span={2}>
                                    <Popconfirm
                                        placement="topRight"
                                        overlayClassName={`_btn-delete-overlay`}
                                        className={`_btn-delete-${index}`}
                                        title={t('message.delete_confirm')}
                                        onConfirm={() => this.deleteItem(item)}
                                        okText={t('button.agree')}
                                        cancelText={t('button.disagree')}
                                    >
                                        <div className={`_item-btn-delete cursor-pointer txt-color-blue txt-size-h7 robotoregular txt-right`}>{t('button.delete')}</div>
                                    </Popconfirm>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Spin>
            </List.Item>
        )
    };

    /**
     * render header of list
     * @returns {*}
     */
    renderHeaderList = () => {
        const {metadata} = this.state;
        const {t} = this.props;

        return (
            <div className={'headerlist'}>
                <div className="dpl-flex justify-content-between align-items-center bg-color-white pd25 pdt15 pdbt15 border-bottom-1x bd-color-gray">
                    {
                        metadata
                            ?
                            <span className={'txt-color-black txt-size-h7 robotoregular'}>{t('customer_info.saved_product_list')} (<span className={'_total-item-in-page'}>{quantityFormat(metadata.total)}</span>)</span>
                            :
                            <span className={'txt-color-black txt-size-h7 robotoregular'}>{t('customer_info.saved_product_list')}</span>
                    }
                </div>
                <Row className={'headerlist__title txt-color-black'}>
                    <Col span={8}>
                        {t('order.products')}
                    </Col>
                    <Col span={16}>
                        <Col span={6}>
                            {t('customer_info.saved_day')}
                        </Col>
                        <Col span={12}>
                            {t('customer_info.note')}
                        </Col>
                        <Col span={4} className="txt-right">
                            {t('customer_info.price')}
                        </Col>
                        <Col span={2}/>
                    </Col>
                </Row>
            </div>
        )
    };

    renderList = () => {
        const {listProducts} = this.state;
        return (
            <React.Fragment>
                <List
                    className={`_item-merchant list-carts mgbt25`}
                    header={this.renderHeaderList()}
                    dataSource={listProducts}
                    renderItem={(item, index) => this.renderListItem(item, index)}
                />
            </React.Fragment>
        )
    };

    render() {
        const {listProducts, metadata, isLoading} = this.state;
        const {t} = this.props;

        return <MainLayout
            ref={ref => this.mainLayoutElement = ref}
            {...this.props}
            showProgressBar={this.state.showProgressBar}
            headerTitle={t('customer_info.saved_product')}
            title={t('customer_info.personal_info')}
        >
            <div className={'container-common pdbt30 pdt20 mgr20'}>
                <Row>
                    <Col span={6} className={'pdr10'}>
                        <ProfileSidebar currentRoute={'products'}/>
                    </Col>
                    <Col span={18} className={'pdl10'}>
                        {this.renderSearchBox()}

                        <div className={'cartscontainer pdbt70'}>
                            {
                                Array.isArray(listProducts) && listProducts.length > 0
                                    ?
                                    this.renderList()
                                    :
                                    isLoading ?
                                        <Loading/> :
                                        <Empty
                                            className={'pdt20'}
                                            image={require('../../resources/images/empty-image.png')}
                                            description={t('message.empty')}
                                        />
                            }

                            <Pagination
                                className={'text-center _pagination-carts'}
                                hideOnSinglePage={true}
                                showQuickJumper
                                current={metadata ? parseFloat(metadata.page) + 1 : 1}
                                total={metadata ? parseFloat(metadata.total) : 1}
                                pageSize={metadata ? parseFloat(metadata.size) : pageSize}
                                onChange={(page) => this.onPageChanged(page)}
                            />
                        </div>
                    </Col>

                </Row>
            </div>
        </MainLayout>;
    }
}
export default withNamespaces()(SavedProducts);
