import React from 'react'
import MainLayout from '../../components/Layout/MainLayout'
import Loading from '../../components/Loading'
import {Button, Col, notification, Row, Table, Tooltip,} from 'antd';
import ModalConfirm from '../../components/ModalConfirm'
import ProfileSidebar from '../../components/Profile/Leftbar'
import {ReactComponent as iconEmpty} from "../../resources/images/connect-app-empty.svg";
import AntdIconSVG, {PlusOutlined} from "@ant-design/icons"
import {getUrlQueryString} from "../../utils/helper";
import {quantityFormat, updateQueryStringParameter} from "../../utils/stringUtils";
import UserService from "../../services/user.service";
import {withNamespaces} from "react-i18next";
import ModalConnectApplication from "../../components/Profile/ModalConnectApplication";
import ModalCreateConnectApplicationSuccess from "../../components/Profile/ModalCreateConnectApplicationSuccess";
import WebhookUpdateModal from "../../components/Profile/WebhookUpdateModal";
import lodash from "lodash";

let pageSize = 25;

class ConnectApplication extends React.Component {
    constructor(props) {
        super(props);
        this.isUnmounted = false;
        this.state = {
            page: 1,
            pageSize: pageSize,
            isLoading: true,
            listData: [],
            showModalCreate: false,
            isUpdating: false,
            data: null,
            showModalResult:false,
            showModalEdit:false
        }
    }
    componentDidMount() {
        this.loadData();
    }

    /**
     * Load từ QueryString (dành cho lần load đầu tiên)
     */
    loadFromQueryString = () => {
        // let queryString = getUrlQueryString();
        // let queryObj = parseQueryStringToObject(queryString) || {};
        //
        // let querySearch = decodeURIComponent(queryObj['query'] || '');
        //
        // this.setState({
        //     querySearch,
        //     page: queryObj.page,
        //     pageSize: queryObj.size || pageSize,
        // }, ()=> {
        //    this.loadData();
        // });
    };

    /**
     * load data
     */
    loadData = () => {
        // const {page, pageSize} = this.state;
        // let queryString = '';
        // if (queryString) {
        //     queryString = queryString + `&page=${page ? page - 1 : 0}&size=${pageSize}`
        // } else {
        //     queryString = `?page=${page ? page - 1 : 0}&size=${pageSize}`
        // }
        this.setState({showProgressBar: true}, () => {
            UserService.getApplication((err, res, headers) => {
                if (!err) {
                    this.setState({
                        listData: res,
                    });
                }
                this.setState({showProgressBar: false, isLoading: false})
            })
        })
    };

    onPageChanged = (page) => {
        this.setState({page: page}, () => {
            let queryString = getUrlQueryString();
            queryString = updateQueryStringParameter(queryString ? '?'+ queryString : '', 'page', page);
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: queryString
            });
            // window.scrollTo(0, 0);
        });
    };
    onCreate=(body)=>{
        const {t} = this.props;
        this.setState({isUpdating:true},()=>{
            UserService.createApplication(body,(err, res, headers) => {
                if (!err) {
                    // notification['success']({
                    //     message: this.props.t('message.create_connect_application_success'),
                    // });
                    this.setState({isUpdating: false,showModalCreate:false, showModalResult: true, data: res});
                    // this.loadData();
                }else{
                    this.setState({isUpdating: false});
                    if (err.statusCode !== 'Network fail') notification['error']({
                        message: t(`message.${err.title}`),
                    });
                }

            })
        });
    }
    deleteApplication=()=>{
        const {t} = this.props;
        const {itemSelected} = this.state;
        this.setState({isUpdating:true},()=>{
            UserService.deleteApplication(itemSelected.id,(err, res, headers) => {
                if (!err) {
                    notification['success']({
                        message: this.props.t('message.delete_application_success'),
                    });
                    this.loadData();
                }else{
                    if (err.statusCode !== 'Network fail') notification['error']({
                        message: t(`message.${err.title}`),
                    });
                }
                this.setState({isUpdating: false,itemSelected:null, showConfirmDelete: false});
            })
        });
    }
    updateHook=(body)=>{
        const {t} = this.props;
        const {itemSelected} = this.state;
        this.setState({isUpdating:true},()=>{
            UserService.updateApplication(itemSelected.id,body,(err, res, headers) => {
                if (!err) {
                    notification['success']({
                        message: this.props.t('message.update_webhook_success'),
                    });
                    this.setState({isUpdating: false,showModalEdit:false,itemSelected:null});
                    this.loadData();
                }else{
                    this.setState({isUpdating: false});
                    if (err.statusCode !== 'Network fail') notification['error']({
                        message: t(`message.${err.title}`),
                    });
                }

            })
        });
    }
    testWebhook=(id)=>{
        const {t} = this.props;
        this.setState({isUpdating:true},()=>{
            UserService.testWebhook(id,(err, res, headers) => {
                if (!err) {
                    this.setState({isUpdating: false});
                    if(res.webhookActive){
                        notification['success']({
                            message: this.props.t('message.success'),
                        });
                        this.loadData();
                    }else{
                        notification['error']({
                            message: this.props.t('message.fail'),
                        });
                    }

                }else{
                    this.setState({isUpdating: false});
                    if (err.statusCode !== 'Network fail') notification['error']({
                        message: t(`message.${err.title}`),
                    });
                }

            })
        });
    }
    render() {
        const {
            listData = [], isLoading, showModalCreate, isUpdating, showConfirmDelete,
            showModalResult, data, showModalEdit, itemSelected
        } = this.state;
        const {t} = this.props;

        const columns = [{
            title: t('connect_application.id'),
            dataIndex: 'id',
            key: 'id',
            width: 270,
            className:'_id',
            render : (text, row) => {
                return <span className={'txt-color-blue '}>{text}</span>
            }
        }, {
            title: t('connect_application.name'),
            dataIndex: 'name',
            key: 'name',
            className:'_name'
        }, {
            title: t('connect_application.desc'),
            dataIndex: 'description',
            key: 'description',

            className:'_desc'
        }, {
            title: t('connect_application.webhook'),
            dataIndex: 'webhookUrl',
            key: 'webhookUrl',
            width:200,
            className:'_webhook',
            render:(text,record)=><span>{lodash.get(record,'webhookUrl','---')}</span>
        }, {
            dataIndex: 'webhookActive',
            key: 'webhookActive',
            className:'_webhookActive',
            width:70,
            render:(webhookActive,record)=>{
                if(webhookActive|| !record.webhookUrl) return null;
                return <Button className={'txt-size-h8 txt-color-blue'} disabled={isUpdating} onClick={()=>this.testWebhook(record.id)} type={'link'}>Test</Button>
            }
        }, {
            title:'',
            dataIndex: 'action',
            width: 100,
            key: 'action',
            render:(text, record)=> <Row className={'dpf-flex justify-content-center'}>
                <Tooltip title={t('connect_application.tooltip_bt_edit')}>
                    {/*<AntdIcon.EditOutlined className={'mgr10 cursor-pointer'} />*/}
                    <i className="fas fa-edit cursor-pointer txt-color-gray3 _edit" onClick={()=>{
                        this.setState({
                            itemSelected: record,
                            showModalEdit: true
                        });
                    }}></i>
                </Tooltip>
                <Tooltip title={t('connect_application.tooltip_bt_delete')}>
                    <i className="fas fa-trash cursor-pointer txt-color-gray3 mgl20 _delete" onClick={()=>{
                        this.setState({
                            itemSelected: record,
                            showConfirmDelete: true
                        });
                    }}></i>
                </Tooltip>
            </Row>
        }];

        return <MainLayout
            ref={ref => this.mainLayoutElement = ref}
            {...this.props}
            showProgressBar={this.state.showProgressBar}
            headerTitle={t('userProfile.connect_application')}
            title={t('userProfile.connect_application')}
        >
            <div className={'container-common pdbt30 pdt20 mgr20'}>
                <Row>
                    <Col span={6} className={'pdr10'}>
                        <ProfileSidebar currentRoute={'connect-application'}/>
                    </Col>
                    <Col span={18} className={'pdl10'}>
                        <div className="pd20 ">

                            <div className="mgt20">
                                <div className="bg-color-white dpl-flex justify-content-between align-items-center border-radius6 pdl24 pdr24 pdbt10 pdt10">
                                    <span className={'txt-color-black txt-size-h5 robotobmedium'}>{t('connect_application.list')} {listData&&listData.length>0&&<span className={'_total-item-in-page totalitempage'}>{quantityFormat(listData.length)}</span>}</span>
                                    <Button className="buttonm _bt-create-application" icon={<PlusOutlined />} onClick={()=>this.setState({showModalCreate: true})} type={''}>{t('connect_application.bt_create')}</Button>
                                    {showModalCreate&& <ModalConnectApplication
                                        visible={showModalCreate}
                                        onCreate={this.onCreate}
                                        loading={isUpdating}
                                        t={t}
                                        onCancel={() => {
                                            this.setState({showModalCreate: false});
                                        }}
                                    />}
                                </div>
                                <div className="mgt12 border-radius6 wptablem">
                                    {
                                        Array.isArray(listData) && listData.length > 0
                                            ?
                                            <div>
                                                <Table
                                                    className={'_table-data custom-empty'}
                                                    bordered={true}
                                                    rowKey={record=> record.id}
                                                    columns={columns}
                                                    dataSource={listData}
                                                    scroll={{ y: 500 }}
                                                    rowClassName={() => `_table-row`}
                                                    pagination={false}
                                                />
                                                {/*<Pagination*/}
                                                {/*    {...{*/}
                                                {/*        className: '_pagination text-center width-100-percent mgt20',*/}
                                                {/*        hideOnSinglePage: true,*/}
                                                {/*        current: metadata ? parseFloat(metadata.page) + 1 : 1,*/}
                                                {/*        total: metadata ? parseFloat(metadata.total) : 1,*/}
                                                {/*        pageSize: metadata ? parseFloat(metadata.size) : pageSize,*/}
                                                {/*        onChange: (page) => this.onPageChanged(page)*/}
                                                {/*    }}*/}
                                                {/*/>*/}
                                            </div>
                                            :
                                            isLoading ?
                                                <Loading/> :
                                                <Col className="pdt20 pdbt20 bg-color-white">
                                                    {/*<Empty*/}
                                                    {/*    image={require('../../resources/images/empty-image.png')}*/}
                                                    {/*    description={t('message.empty')}*/}
                                                    {/*/>*/}
                                                    <Row className={' dpl-flex align-items-center justify-content-center'}><AntdIconSVG component={iconEmpty} style={{fontSize: 60}}/></Row>
                                                    <Row  className={' dpl-flex align-items-center justify-content-center mgt20'}>
                                                        <span className={'txt-color-black3 txt-size-h7 robotoregular'}>{t('connect_application.add_new_data')}</span>
                                                        <Button className={'buttonm mgl10'} icon={<PlusOutlined />} onClick={()=>this.setState({showModalCreate: true})} type={''}>{t('connect_application.bt_create')}</Button>
                                                    </Row>
                                                </Col>
                                    }
                                </div>
                            </div>
                        </div>
                    </Col>

                </Row>
                {showConfirmDelete&&<ModalConfirm loading={isUpdating}
                                                 t={t}
                                                  title={t('connect_application.title_modal_confirm_delete')}
                                                 visible={showConfirmDelete}
                                                 onCancel={() => this.setState({
                                                     showConfirmDelete: false,
                                                     itemSelected: null})}
                                                 onSubmit={this.deleteApplication}
                                                 content={t('connect_application.content_modal_confirm_delete')}/>
                }
                {showModalResult&& <ModalCreateConnectApplicationSuccess
                    visible={showModalResult}
                    t={t}
                    data={data}
                    onCancel={() => {
                        this.setState({showModalResult: false});
                        this.loadData();
                    }}
                />}
                {showModalEdit&& <WebhookUpdateModal
                    visible={showModalEdit}
                    onUpdate={this.updateHook}
                    loading={isUpdating}
                    data={itemSelected}
                    t={t}
                    onCancel={() => {
                        this.setState({showModalEdit: false});
                    }}
                />}
            </div>
        </MainLayout>;
    }
}
export default withNamespaces()(ConnectApplication);
