/* eslint-disable array-callback-return */
import React from 'react';
import {Modal, Button, Row, Col, Input, notification, InputNumber} from 'antd'
import {withNamespaces} from "react-i18next";
import {LocalStore} from "../../utils/LocalStore";
import ReactDropzone from "../ReactDropzone";
import {validateLink} from "../../utils/validate";

class ModalAddProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentLoggedUser: {},
            bodyData: {}
        };
    }

    // scroll top khi bắt đầu load trang
    componentDidMount() {
        this.setState({
            currentProjectInfo: LocalStore.getInstance().read('currentProjectInfo') || {},
            currentLoggedUser: LocalStore.getInstance().read('currentLoggedUser') || {}
        });
    }

    onInputChange = (value, prop) => {
        let bodyData = this.state.bodyData;

        bodyData[prop] = value;

        this.setState({
            bodyData
        })
    }

    /**
     * Thêm file (ảnh) vào ticket
     * @param files
     */
    addFiles = (files) => {
        let images = [];
        const {t} = this.props;
        let showError = false;
        files.map(file => {
            // Hiển thị thông báo lỗi khi định dạng không phải ảnh hoặc ảnh lớn hơn 10MB
            if (file.type.indexOf('image') === -1) {
                showError = true;
            } else if (file.size < 11534336) {
                images.push(file);
            } else {
                showError = true;
            }
        });

        this.setState({images: images});
        if (showError)
            notification['error']({
                message: t('error.upload_image')
            });
    };

    /**
     * Thêm file (ảnh) vào ticket
     * @param index
     */

    addHide = (index) => {
        let hide = this.state.hide
        hide.push(index);
        this.setState({hide: hide});
    };

    /**
     * Thêm file (ảnh) vào ticket
     * @param index
     */

    removeHide = (index) => {
        let hide = this.state.hide;
        let key = hide.indexOf(index); // Let's say it's Bob.
        delete hide[key];
        this.setState({hide: hide})

    };

    onSubmit = async () => {
        const {t, loadFromQueryString} = this.props;
        const {bodyData = {}, images = [], isSubmit} = this.state;

        if (!this.onValidate()) {
            return
        }

        if (isSubmit === true) return;

        let payload = {
            "merchantName": bodyData.merchantName,
            "originalName": bodyData.originalName,
            "url": bodyData.url,
            "variantProperties": [
                {
                    "id": "1",
                    "originalValue": bodyData.variantProperties1,
                    "originalName": "Màu Sắc"
                },
                {
                    "id": "2",
                    "originalValue": bodyData.variantProperties2,
                    "originalName": "Kích Thước"
                }
            ],
            "quantity": bodyData.quantity,
            "salePrice": bodyData.salePrice
        };

        let formData = new FormData();
        images.map((file) => {
            formData.append('attachments', new Blob([file]), file.name);
        });
        formData.append('command', new Blob([JSON.stringify(payload)], {type: 'application/json'}));

        let token = LocalStore.getInstance().read('loginSession');
        let res;

        this.setState({
            isSubmit: true,
        }, async () => {
            try {
                res = await fetch((process.env.REACT_APP_API_URL || window.location.hostname) + '/customer/cart/products', {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token.id
                    },
                    body: formData
                });
            } catch (err) {
                if (err.message === 'Network request failed') {
                    notification['error']({
                        message: t('error.network'),
                    });
                }
                this.setState({isSubmit: false});
            }
            const dataResponse = await res.json();

            if (!res.ok) {
                notification['error']({
                    message: dataResponse.detail
                });
                this.setState({isSubmit: false});
            } else {
                notification['success']({
                    message: t('message.success'),
                });
                this.setState({isSubmit: false}, () => {
                    this.setState({showModalAddProduct: false});
                });
                if (loadFromQueryString) loadFromQueryString()
            }
        });
    }

    onValidate = () => {
        let {bodyData = {}, error = {}} = this.state;

        const {t} = this.props;
        let check = 0
        if (!bodyData.merchantName) {
            error.merchantName = t('order.quantity_required')
            check = check + 1
        } else {
            error.merchantName = ''
        }

        if (!bodyData.originalName) {
            error.originalName = t('order.quantity_required')
            check = check + 1
        } else {
            error.originalName = ''
        }

        if (!bodyData.quantity) {
            error.quantity = t('order.quantity_required')
            check = check + 1
        } else {
            error.quantity = ''
        }

        if (!bodyData.salePrice) {
            error.salePrice = t('order.quantity_required')
            check = check + 1
        } else {
            error.salePrice = ''
        }

        if (!bodyData.url) {
            error.url = t('order.quantity_required')
            check = check + 1
        } else {
            if (!validateLink(bodyData.url)) {
                error.url = t('shipment.link_error');
                check = check + 1
            } else {
                error.url = ''
            }
        }

        this.setState({error})
        if (check > 0) {
            return false
        }
        return true
    }

    renderModalAddProduct = () => {
        const {t} = this.props;
        const {bodyData = {}, error = {}, isSubmit} = this.state;
        return <Modal
            title={t('button.add_products')}
            visible={this.state.showModalAddProduct}
            onCancel={() => this.setState({
                showModalAddProduct: false
            })}
            onOk={() => this.onSubmit()}
            okText={t('button.add_products')}
            cancelText={t('button.cancel')}
            okButtonProps={{className:"_btn-submit-confirm", disabled:isSubmit, loading: isSubmit}}
            cancelButtonProps={{className:"_btn-cancel-confirm"}}
            width={1000}
        >
            <Row gutter={20}>
                <Col span={12}>
                    <div className="mgbt20">
                        <div className="pdbt5">{t('cart.modal_merchantName')} <span className="txt-color-red">*</span></div>
                        <Input
                            autoFocus={true}
                            className={'_input_merchantName'}
                            onChange={(e) => this.onInputChange(e.target.value, 'merchantName')}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.onSubmit()
                                }
                            }}
                            value={bodyData.merchantName}
                        />
                        {
                            error.merchantName &&
                            <div className="pdt3 texterror position-ab txt-size-h8 txt-color-red2 robotoregular">
                                {error.merchantName}
                            </div>
                        }
                    </div>

                    <div className="mgbt20">
                        <div className="pdbt5">{t('cart.modal_originalName')} <span className="txt-color-red">*</span></div>
                        <Input
                            className={'_input_originalName'}
                            onChange={(e) => this.onInputChange(e.target.value, 'originalName')}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.onSubmit()
                                }
                            }}
                            value={bodyData.originalName}
                        />
                        {
                            error.originalName &&
                            <div className="pdt3 texterror position-ab txt-size-h8 txt-color-red2 robotoregular">
                                {error.originalName}
                            </div>
                        }
                    </div>

                    <Row gutter={20}>
                        <Col span={12}>
                            <div className="mgbt20">
                                <div className="pdbt5">{t('cart.modal_variantProperties1')}</div>
                                <Input
                                    className={'_input_variantProperties1'}
                                    onChange={(e) => this.onInputChange(e.target.value, 'variantProperties1')}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            this.onSubmit()
                                        }
                                    }}
                                    value={bodyData.variantProperties1}
                                />
                            </div>
                        </Col>

                        <Col span={12}>
                            <div className="mgbt20">
                                <div className="pdbt5">{t('cart.modal_variantProperties2')}</div>
                                <Input
                                    className={'_input_variantProperties2'}
                                    onChange={(e) => this.onInputChange(e.target.value, 'variantProperties2')}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            this.onSubmit()
                                        }
                                    }}
                                    value={bodyData.variantProperties2}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <div className="mgbt20">
                        <div className="pdbt5">{t('cart.modal_url')} <span className="txt-color-red">*</span></div>
                        <Input
                            className={'_input_url'}
                            onChange={(e) => this.onInputChange(e.target.value, 'url')}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.onSubmit()
                                }
                            }}
                            value={bodyData.url}
                        />
                        {
                            error.url &&
                            <div className="pdt3 texterror position-ab txt-size-h8 txt-color-red2 robotoregular">
                                {error.url}
                            </div>
                        }
                    </div>

                    <Row gutter={20}>
                        <Col span={12}>
                            <div className="mgbt20">
                                <div className="pdbt5">{t('cart.modal_quantity')} <span className="txt-color-red">*</span></div>
                                <InputNumber
                                    precision={0} // bắt chỉ cho nhập integer
                                    className={`_input_quantity width-100-percent`}
                                    min={1}
                                    value={bodyData.quantity}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={(value) => {
                                        if (typeof value === 'number') {
                                            // console.log('chán', value)
                                            this.onInputChange(value, 'quantity')
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            this.onSubmit()
                                        }
                                    }}
                                />
                                {
                                    error.quantity &&
                                    <div className="pdt3 texterror position-ab txt-size-h8 txt-color-red2 robotoregular">
                                        {error.quantity}
                                    </div>
                                }
                            </div>
                        </Col>

                        <Col span={12}>
                            <div className="mgbt20">
                                <div className="pdbt5">{t('cart.modal_salePrice')} <span className="txt-color-red">*</span></div>
                                <InputNumber
                                    precision={3} // bắt chỉ cho nhập integer
                                    className={`_input_salePrice width-100-percent`}
                                    min={0.001}
                                    value={bodyData.salePrice}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={(value) => {
                                        if (typeof value === 'number') {
                                            // console.log('chán', value)
                                            this.onInputChange(value, 'salePrice')
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            this.onSubmit()
                                        }
                                    }}
                                />
                                {
                                    error.salePrice &&
                                    <div className="pdt3 texterror position-ab txt-size-h8 txt-color-red2 robotoregular">
                                        {error.salePrice}
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>

                    <div className="mgbt20">
                        <div className="pdbt5">{t('cart.modal_image')}</div>
                        <ReactDropzone
                            className={`${this.state.focusDropzone ? 'ibizan-focus' : ''}`}
                            inputProps={{
                                onFocus: (e) => {
                                    this.setState({
                                        focusDropzone: true
                                    });
                                },
                                onBlur: e => {
                                    this.setState({
                                        focusDropzone: false
                                    });
                                    // this.buttonSubmit.focus();
                                }
                            }}
                            addFiles={this.addFiles}
                            files={this.state.images || []}
                            addHide={this.addHide}
                            hide={this.state.hide}
                            removeHide={this.removeHide}
                            maxLength={1}
                            single={true}
                        />
                    </div>

                </Col>
            </Row>

        </Modal>
    };

    render() {
        const {t} = this.props;
        return <div className="dpl-il-block">
            <Button type="primary" ghost className="mgl20" onClick={() => {
                this.setState({showModalAddProduct: true, bodyData: {}, error: {}, images: []})
            }}>
                {t('button.add_products')}
            </Button>
            {this.renderModalAddProduct()}
        </div>
    }
}
export default withNamespaces()(ModalAddProduct)
