import React, { useState, useRef } from 'react'
import { Select } from 'antd'

const Option = Select.Option

export default function SuperSelect(props) {
    const [optionHover, setOptionHover] = useState(null)
    const inputSelect = useRef()

    const {
        style,
        showSearch,
        allowClear,
        className,
        showArrow,
        mode,
        disabled,
        loading,
        filterOption,
        dropdownClassName,
        optionClassName,
        dropdownMatchSelectWidth,
        keyCustomize,
        getPopupContainer,
        defaultValue
    } = props
    const { options, renderOptionLabel, selectedValue, onChange, onSearch, notFoundContent, open } = props

    return (
        <Select
            getPopupContainer={getPopupContainer}
            open={open}
            ref={inputSelect}
            notFoundContent={notFoundContent}
            showSearch={showSearch}
            allowClear={allowClear}
            className={className}
            dropdownClassName={dropdownClassName}
            dropdownMatchSelectWidth={dropdownMatchSelectWidth}
            optionFilterProp="children"
            suffixIcon={<i className="fa-regular fa-angle-down text-grey-400 text-sm" />}
            style={style}
            mode={mode}
            loading={loading}
            disabled={disabled}
            showArrow={showArrow}
            filterOption={filterOption ? filterOption : false}
            onSelect={value => {
                inputSelect && inputSelect.current.blur()
            }}
            onSearch={value => onSearch && onSearch(value)}
            onChange={value => {
                setOptionHover(null)
                onChange && onChange(value)
            }}
            defaultValue={defaultValue||''}
            placeholder={optionHover}
            value={optionHover ? optionHover : selectedValue}
        >
            {options?.map(item => (
                <Option
                    className={`${optionClassName} ${selectedValue === item?.code ? 'ant-select-item-option-selected' : ''}`}
                    value={keyCustomize ? item[keyCustomize] : item.code}
                    key={keyCustomize ? item[keyCustomize] : item.code}
                    onMouseEnter={() => {
                        setOptionHover(renderOptionLabel(item))
                    }}
                    onMouseLeave={() => {
                        setOptionHover(null)
                    }}
                >
                    {renderOptionLabel(item)}
                </Option>
            ))}
        </Select>
    )
}
