import PolimClient from "../utils/api/PolimClient";

export default class OrderTrackingService {

    /**
     * tra cứu thông tin đơn
     * @param cb
     */
    static orderTracking(type,body,cb) {
        PolimClient.request({
            endpoint: `/public-api/tracking/${type}`,
            method: 'POST',
            notAuth: true,
            body
        }, cb)
    }
}
