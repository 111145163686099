/* eslint-disable array-callback-return */
import React from 'react';

import {
    Button,
    Modal, notification
} from 'antd';

import ImportExcel from "../ReactDropzone/ImportExcel";
import uploadFile from "../../utils/api/uploadFile";
import XLSX from "xlsx";
import {validateLink} from "../../utils/validate";

export default class ImportExcelProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    /**
     * upload file
     */
    onCreate = () => {
        const {t} = this.props;
        const {isSubmitting, isUploading, disabled, fileDisabled} = this.state;

        const file = this.importExcel.state.file;

        const body = {};

        if (isSubmitting === true || isUploading === true || disabled || fileDisabled) return;
        this.setState({isSubmitting: true, showProgressBar: true}, () => {
            let apiServiceUrl = `/customer/cart/products/import_excel`;

            uploadFile(apiServiceUrl, [file], body).then(response => {
                this.setState({
                    excelImported: response,
                    isSubmitting: false,
                    showProgressBar: false,
                    modalImportShow: false
                });
                notification['success']({
                    message: t('message.success'),
                });
                window.location.reload();
            }).catch((error) => {
                if (error.title === 'data_sheet_not_found') {
                    notification['error']({
                        message: t('shipment.data_sheet_not_found'),
                    });
                } else {
                    notification['error']({
                        message: t(error.message),
                    });
                }
                this.setState({isSubmitting: false, showProgressBar: false});
            })
        });
    };

    fileUpLoaded = (file) => {
        const {t} = this.props;
        const {isUploading, isSubmitting} = this.state;
        if (isSubmitting === true || isUploading === true) return;
        this.setState({isUploading: true, excelImported: {}, fileDisabled: false}, () => {
            let fileErrors = [];
            const reader = new FileReader();
            reader.onload = (event) => {
                const resultFile =  event.target.result;
                // console.log('csac')
                const workbook = XLSX.read(resultFile, {type:'binary', sheets: 0});
                // console.log('workbook', workbook)
                const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
                // console.log('first_worksheet', first_worksheet)
                if (!first_worksheet) {
                    notification['error']({
                        message: t('shipment.data_sheet_not_found'),
                    });
                    this.setState({isUploading: false, fileErrors: fileErrors, fileDisabled: true});
                    return;
                }
                const data = XLSX.utils.sheet_to_json(first_worksheet, {range: 8, header: "A"});
                // validate theo từng column
                data.map((item, index) => {
                    let errText = "";

                    // if (!item['A'] || !item['A'].toString().trim()) {
                    //     errText = errText + `${t('shipment.column')} ${'A'} ${t('shipment.lack_of_information')}, `;
                    // } else {
                    //     if (isNaN(item['A'])) {
                    //         errText = errText + `${t('shipment.column')} ${'A'} ${t('shipment.wrong_data_type')}, `;
                    //     }
                    // }
                    // if (!item['B'] || !item['B'].toString().trim()) {
                    //     errText = errText + `${t('shipment.column')} ${'B'} ${t('shipment.lack_of_information')}, `;
                    // }
                    // if (!item['C'] || !item['C'].toString().trim()) {
                    //     errText = errText + `${t('shipment.column')} ${'C'} ${t('shipment.lack_of_information')}, `;
                    // }
                    if (!item['D'] || !item['D'].toString().trim()) {
                        errText = errText + `${t('shipment.column')} ${'D'} ${t('shipment.lack_of_information')}, `;
                    }
                    // if (!item['E'] || !item['E'].toString().trim()) {
                    //     errText = errText + `${t('shipment.column')} ${'E'} ${t('shipment.lack_of_information')}, `;
                    // }
                    // if (!item['F'] || !item['F'].toString().trim()) {
                    //     errText = errText + `${t('shipment.column')} ${'F'} ${t('shipment.lack_of_information')}, `;
                    // }

                    if (!item['G'] || !item['G'].toString().trim()) {
                        errText = errText + `${t('shipment.column')} ${'G'} ${t('shipment.lack_of_information')}, `;
                    } else {
                        if (isNaN(item['G'])) {
                            errText = errText + `${t('shipment.column')} ${'G'} ${t('shipment.wrong_data_type')}, `;
                        } else {
                            if (item['G'] < 1) {
                                errText = errText + `${t('shipment.column')} ${'G'} ${t('shipment.wrong_data_type')}, `;
                            }
                        }
                    }

                    // if (!item['J'] || !item['J'].toString().trim()) {
                    //     errText = errText + `${t('shipment.column')} ${'J'} ${t('shipment.lack_of_information')}, `;
                    // } else {
                    //     if (isNaN(item['J'])) {
                    //         errText = errText + `${t('shipment.column')} ${'J'} ${t('shipment.wrong_data_type')}, `;
                    //     }
                    // }
                    if (!item['K'] || !item['K'].toString().trim()) {
                        errText = errText + `${t('shipment.column')} ${'K'} ${t('shipment.lack_of_information')}, `;
                    } else {
                        if (isNaN(item['K'])) {
                            errText = errText + `${t('shipment.column')} ${'K'} ${t('shipment.wrong_data_type')}, `;
                        } else {
                            if (item['K'] <= 0) {
                                errText = errText + `${t('shipment.column')} ${'K'} ${t('shipment.wrong_data_type')}, `;
                            }
                        }
                    }
                    // if (!item['L'] || !item['L'].toString().trim()) {
                    //     errText = errText + `${t('shipment.column')} ${'L'} ${t('shipment.lack_of_information')}, `;
                    // } else {
                    //     if (isNaN(item['L'])) {
                    //         errText = errText + `${t('shipment.column')} ${'L'} ${t('shipment.wrong_data_type')}, `;
                    //     }
                    // }
                    // if (!item['M'] || !item['M'].toString().trim()) {
                    //     errText = errText + `${t('shipment.column')} ${'M'} ${t('shipment.lack_of_information')}, `;
                    // } else {
                    //     if (isNaN(item['M'])) {
                    //         errText = errText + `${t('shipment.column')} ${'M'} ${t('shipment.wrong_data_type')}, `;
                    //     }
                    // }

                    if (!item['N'] || !item['N'].toString().trim()) {
                        errText = errText + `${t('shipment.column')} ${'N'} ${t('shipment.lack_of_information')}, `;
                    }

                    // if (!item['O'] || !item['O'].toString().trim()) {
                    //     errText = errText + `${t('shipment.column')} ${'O'} ${t('shipment.lack_of_information')}, `;
                    // } else {
                    //     if (!validateLink(item['O'])) {
                    //         errText = errText + `${t('shipment.column')} ${'O'} ${t('shipment.link_error')}, `;
                    //     }
                    // }

                    // if (!item['P'] || !item['P'].toString().trim()) {
                    //     errText = errText + `${t('shipment.column')} ${'P'} ${t('shipment.lack_of_information')}, `;
                    // }
                    if (!item['Q'] || !item['Q'].toString().trim()) {
                        errText = errText + `${t('shipment.column')} ${'Q'} ${t('shipment.lack_of_information')}, `;
                    } else {
                        if (!validateLink(item['Q'])) {
                            errText = errText + `${t('shipment.column')} ${'Q'} ${t('shipment.link_error')}, `;
                        }
                    }

                    fileErrors[index] = errText;
                });

                this.setState({isUploading: false, fileErrors: fileErrors})
            };
            reader.readAsBinaryString(file);
        })
    };

    renderModal = () => {
        const {t} = this.props;
        const {modalImportShow, isUploading, isSubmitting, fileErrors = [], disabled, excelImported = {}, fileDisabled} = this.state;

        return <Modal
            key={'modal-import-excel'}
            className="_modalAddress modal-import"
            title={t('button.add_products_excel')}
            visible={modalImportShow}
            onCancel={() => this.setState({modalImportShow: false})}
            footer={<div className="dpl-flex align-items-center width-100-percent justify-content-between">
                <a href={'//cdn.gobiz.vn/import_product_to_cart.xlsx'} download className="txt-color-blue cursor-pointer">
                    {t('shipment.download_template')}
                </a>

                <div>
                    <Button
                        onClick={() => this.setState({modalImportShow: false})}
                        className="_btn-cancel"
                    >
                        {t('button.cancel')}
                    </Button>
                    <Button
                        onClick={this.onCreate}
                        disabled={(disabled || fileErrors.filter(x => x).length > 0) || fileDisabled}
                        loading={isUploading || isSubmitting}
                        type="primary"
                        className="_btn-add"
                    >
                        {t('cartCheckout.confirm')}
                    </Button>
                </div>
            </div>}
        >
            <ImportExcel
                t={t}
                ref={ref => this.importExcel = ref}
                // isUploading={isUploading}
                fileUpLoaded={this.fileUpLoaded}
            />
            {
                fileErrors.filter(x => x).length > 0 && <div className="pdt10">
                    {fileErrors.map((x, y) => {
                        if (!x) return;
                        return <div key={y} className="pdbt5 txt-color-red txt-size-h8">
                            {t('shipment.row')} {y + 9}, {x.substring(0, x.length - 2)}
                        </div>
                    })}
                </div>
            }

            {
                excelImported && Array.isArray(excelImported.errorCells) && excelImported.errorCells.length > 0 &&
                <div className="pdbt5 txt-color-red txt-size-h8">
                    {t('shipment.error_cell')}
                    {
                        excelImported.errorCells.map((x, y) => {
                            if (y === 0) return x;
                            return ', ' + x
                        })
                    }
                </div>
            }
        </Modal>
    }

    render() {
        const {t} = this.props;
        return [
            <Button
                type="primary"
                ghost
                key={'btn-import-excel'}
                className="mgl20"
                onClick={() => {
                this.setState({
                    modalImportShow: true,
                })
            }}>
                {t('button.add_products_excel')}
            </Button>
            ,
            this.renderModal()
        ]
    }
}
