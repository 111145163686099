import { Input } from "antd"
import FloatLabel from "./FloatLabel"

const TextArea = (props) => {
    const { value, label, className } = props
    return <div className={`form-textarea ${className || ''}`}>
        <FloatLabel label={label} value={value}>
            <Input.TextArea
                {...props}
                maxLength={1000}
                rows={2}
            />
        </FloatLabel>
    </div>
}

export default TextArea