import { Button } from 'antd'
import { useRef, useState } from 'react'
import { withNamespaces } from 'react-i18next'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const TextEditor = (props: any) => {
    const { t } = props
    const [value, setValue] = useState('')
    const quillRef: any = useRef()

    const formats = [
        'background',
        'bold',
        'color',
        'font',
        'code',
        'italic',
        'link',
        'size',
        'strike',
        'script',
        'underline',
        'blockquote',
        'header',
        'indent',
        'list',
        'align',
        'direction',
        'code-block',
        'formula',
        // 'image'
        // 'video'
    ]

    const onSubmit = () => {
        props?.onSubmit && props.onSubmit(value.replaceAll('<p><br></p>', ''))
        setValue('')
    }

    return (
        <div className="v2-layout">
            <ReactQuill
                ref={quillRef}
                theme="snow"
                value={value}
                onChange={setValue}
                className="custom-quill"
                modules={{
                    toolbar: {
                        container: [
                            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                            [{ header: [1, 2, 3, 4, 5, 6, false] }],
                            // ['image'],`
                        ],
                    },
                }}
                placeholder={t('comment.place_holder')}
                onKeyDown={e => {
                    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13 && !props?.isCommenting) {
                        onSubmit()
                    }
                }}
                formats={formats}
            />
            <div className=" flex justify-end mt-2">
                {props?.actionButton && props?.actionButton}
                <Button
                    type="primary"
                    onClick={() => onSubmit()}
                    loading={props?.isCommenting}
                >
                    {t('button.send')}
                </Button>
            </div>
        </div>
    )
}

export default withNamespaces('translation')(TextEditor)
