import { Button, Checkbox, Col, Divider, Image, Input, InputRef, Spin, notification, Row } from 'antd'
import { useState, useEffect, useRef } from 'react'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import cartTaobaoGlobal from '../../../../services/cartTaobaoGlobal.service'
import { formatCurrencyChina } from '../../../../utils/helper'
import DeleteProduct from './Component/DeleteProduct'

const ProductAtrribute = (props: any) => {
    const {
        quantity: propQuantity,
        productMapping,
        getListCartItem,
        itemId,
        inventory,
        skuId,
        image,
        variantProperties,
        price,
        totalPrice,
        productImage,
        productName,
        itemSku,
        currentItemId,
        onClearParams,
        filter,
        t,
    } = props
    const [quantity, setQuantity] = useState(propQuantity)
    const [loading, setLoading] = useState(false)
    const [updateTimeout, setUpdateTimeout] = useState<any>(null)

    const inputRef = useRef<InputRef>(null)

    useEffect(() => {
        // Lắng nghe sự thay đổi của propQuantity (props.quantity)
        // Cập nhật lại giá trị quantity trong state mỗi khi propQuantity thay đổi
        setQuantity(propQuantity)
    }, [propQuantity])

    const handleIncrease = () => {
        const newQuantity = quantity + 1
        if (newQuantity <= inventory) {
            setQuantity(newQuantity)
            updateQuantityAfterDelay(newQuantity)
        }
    }

    const handleDecrease = () => {
        const newQuantity = quantity - 1
        if (newQuantity >= 1) {
            setQuantity(newQuantity)
            updateQuantityAfterDelay(newQuantity)
        }
    }

    const handleInputChange = (e: any) => {
        const newQuantity = parseInt(e.target.value, 10)
        if (inputRef.current) {
            inputRef.current.blur() // Bỏ focus sau khi cập nhật
        }
        if (newQuantity >= 1 && newQuantity <= inventory) {
            setQuantity(newQuantity)
            updateQuantityAfterDelay(newQuantity)
        }
    }

    const handleUpdateQuantity = (itemId: string, skuId: string, quantity: number) => {
        const body = {
            itemId: itemId,
            oItemId: currentItemId,
            skus: [
                {
                    skuId: skuId,
                    quantity: quantity,
                },
            ],
        }
        if (props.quantity !== quantity) {
            setLoading(true)
            cartTaobaoGlobal.updateQuantity(body, (err: any, res: any) => {
                if (!err) {
                    getListCartItem(false, filter, () => {
                        props.checkedItems.length > 0 && props.updateTotalPriceWhenChangeQuantiy(itemId, skuId, quantity)
                    })
                    notification['success']({
                        message: t('cartTbg.change_quantity_success'),
                        key: 'updateQuantitySuccess',
                    })
                } else {
                    if (err.title === 'invalid_quantity') {
                        notification['error']({
                            message: t('cartTbg.exceed_quantity'),
                            key: 'inventory',
                        })
                    } else {
                        notification['error']({
                            message: t('cartTbg.change_quantity_fail'),
                            key: 'er',
                        })
                    }
                    getListCartItem(false, {}, () => {})
                }
                setLoading(false)
            })
        }
    }

    const updateQuantityAfterDelay = (newQuantity: number) => {
        // Xóa bỏ setTimeout trước đó nếu có
        if (updateTimeout) {
            clearTimeout(updateTimeout)
        }

        // setTimeout
        const newUpdateTimeout = setTimeout(() => {
            handleUpdateQuantity(itemId, skuId, newQuantity)
        }, 500) // 0.3s

        setUpdateTimeout(newUpdateTimeout)
    }

    return (
        <div className="px-6">
            <Divider className="my-0" />
            <Row
                align="middle"
                className="py-6"
            >
                <Col
                    span={12}
                    className="gap-4 flex items-center"
                    onClick={e => {
                        props.handleCheckboxChange(props.merchant, props.itemSku, productMapping)
                    }}
                >
                    <Checkbox checked={props.checkedItems.some((checkedItem: any) => checkedItem?.sku.skuId === props.skuId)} />
                    <Image
                        preview={false}
                        src={image || productImage || require('../../../../resources/images/productDefaultIcon.png')}
                        className="rounded w-[50px] max-w-[50px] h-[50px] max-h-[50px]"
                    />
                    <div className="grid items-center gap-[6px] cursor-pointer">
                        <span className="text-sm font-normal text-black-100 whitespace-break-spaces">{productName}</span>
                        <div className="flex items-center">
                            {variantProperties?.length > 0
                                ? variantProperties?.map((item: any) => {
                                      return (
                                          <span
                                              key={item.value}
                                              className="txt-color-gray text-xs mr-3"
                                          >
                                              {item?.value}
                                          </span>
                                      )
                                  })
                                : productName}
                        </div>{' '}
                    </div>
                </Col>

                <Col
                    span={3}
                    className="flex justify-end"
                >
                    {price ? <span>{formatCurrencyChina(price)}</span> : '---'}
                </Col>

                <Col
                    span={5}
                    className="flex justify-center "
                >
                    <Spin
                        spinning={loading}
                        className="btn-spinning"
                    >
                        <span
                            className={
                                ' text-blue-primary w-[120px] font-medium text-sm rounded-lg px-2 py-[1px] border border-solid border-[#c0c0c0] flex items-center hover:border-blue-primary'
                            }
                        >
                            <Button
                                className="border-none"
                                icon={<MinusOutlined />}
                                onClick={handleDecrease}
                            />
                            <Input
                                type="numeral"
                                bordered={false}
                                value={quantity}
                                onChange={handleInputChange}
                                min={1}
                                max={inventory}
                                ref={inputRef}
                                className="text-center py-0 px-[10px]"
                            />
                            <Button
                                className="border-none"
                                icon={<PlusOutlined />}
                                onClick={handleIncrease}
                            />
                        </span>
                    </Spin>
                </Col>

                <Col
                    span={2}
                    className="flex justify-end"
                >
                    <span className={'text-black-100 font-medium text-center text-sm basis-[15%]'}>
                        {totalPrice ? <span> {formatCurrencyChina(totalPrice)}</span> : '---'}
                    </span>
                </Col>

                <Col
                    span={2}
                    className="flex justify-end"
                >
                    <DeleteProduct
                        itemSku={itemSku}
                        onClearParams={onClearParams}
                        getListCartItem={getListCartItem}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default ProductAtrribute
