import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { Input, Modal, Spin, notification } from 'antd'
import { useState, useEffect } from 'react'
import { withNamespaces } from 'react-i18next'
import FloatLabel from '../../../components/commons/Input/FloatLabel'
import '../../../resources/scss/pages/TaobaoGlobal/ConfirmCreatePeerPayment.scss'
import { LocalStore } from '../../../utils/LocalStore'
import { first, get, isEmpty } from 'lodash'
import UserService from '../../../services/user.service'
import { moneyFormat } from '../../../utils/stringUtils'
import TaobaoService from '../../../services/taobao.service'
import queryString from 'query-string'
import { useQueryClient } from 'react-query'

const ModalCreateConfirm = props => {
    const {
        t,
        orderInfo: { code, purchaseAmount, credentialAccount, thirdParty },
    } = props
    const [isLoading, setIsLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [note, setNote] = useState('')
    const [exchangeRate, setExchangeRate] = useState({})
    const [credentialAccountInfo, setCredentialAccountInfo] = useState()
    const [subOriginalReceiptCode, setSubOriginalReceiptCode] = useState()
    const queryClient = useQueryClient();

    useEffect(() => {
        if (visible && code) {
            getExchangeRate()
            getCredentialAccount()
            getProductsByOrder(code)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, code])

    const getExchangeRate = () => {
        const query = `?peerPaymentType=taobao_global&amount=${purchaseAmount}&paymentMethodCode=alipay&paymentAccount`
        UserService.getExchangeRatePeerPayMent(query, (err, response) => {
            if (err) {
                notification.error({ message: 'Có lỗi khi lấy tỷ giá cho đơn hàng' })
            } else {
                setExchangeRate(response)
            }
        })
    }

    const handleConfirm = () => {
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        setIsLoading(true)
        const needPayOnRequest = get(currentProjectInfo, 'tenantConfig.peerPaymentConfig.needPayOnRequest')
        const body = {
            paymentMethodCode: 'alipay',
            amount: purchaseAmount,
            peerPaymentType: 'taobao_global',
            note,
            paymentAccount: credentialAccountInfo?.accountNote?.alipayName,
            exchangeRate: exchangeRate.rate,
            originalReceipts: [
                {
                    code,
                    billTo: credentialAccount,
                },
            ],
        }
        let api = UserService.askForPay
        if (needPayOnRequest) {
            api = UserService.createRequestForPay
        }
        api(body, err => {
            if (err) {
                if (err.title === 'insufficient_balance') {
                    notification['success']({
                        message: t('message.success'),
                    })
                } else if (err.title === 'customer_not_found') {
                    notification['error']({
                        message: t('peer_payment.customer_not_found'),
                    })
                } else if (err.title === 'payment_method_not_found') {
                    notification['error']({
                        message: t('peer_payment.payment_method_not_found'),
                    })
                } else if (err.title === 'payment_account_not_found') {
                    notification['error']({
                        message: t('peer_payment.payment_account_not_found'),
                    })
                } else if (err.title === 'shipment_required_when_create_pp') {
                    notification['error']({
                        message: t('peer_payment.shipment_required_when_create_pp'),
                    })
                } else if (err.title === 'invalid_original_receipt_code') {
                    notification['error']({
                        message: t('peer_payment.invalid_original_receipt_code'),
                    })
                } else if (err.title === 'invalid_global_order_info') {
                    notification['error']({
                        message: t('peer_payment.invalid_global_order_info'),
                    })
                } else if (err.title === 'amount_must_be_greater_than_min_payment') {
                    notification['error']({
                        message: t('peer_payment.amount_must_be_greater_than_min_payment'),
                    }   )
                } else {
                    notification['error']({
                        message: t(err.message),
                    })
                }
            } else {
                notification['success']({
                    message: t('message.success'),
                })
                setTimeout(() => {
                    queryClient.invalidateQueries('getPeerPayment');
                }, 800);
            }
            handleCancel()
        })
    }

    const getCredentialAccount = () => {
        const queryStr =
            '?' +
            queryString.stringify({
                account: credentialAccount,
                thirdParty,
            })
        TaobaoService.getCredentialAccount(queryStr, (err, res) => {
            setCredentialAccountInfo(first(res))
        })
    }

    const getProductsByOrder = code => {
        TaobaoService.getProductsOrder(code, (error, response, headers) => {
            if (!error) {
                setSubOriginalReceiptCode(response?.products)
            }
        })
    }

    const handleCancel = () => {
        setIsLoading(false)
        setVisible(false)
        setNote('')
        setExchangeRate({})
    }
    return (
        <>
            <span
                className='robotoregular block text-blue-primary text-sm mb-2'
                onClick={() => setVisible(true)}
            >
                <i class="fa-regular fa-plus mr-2" />
                {t('button.taobao_global_peer_payment_create')}
            </span>
            {visible && (
                <Modal
                    width={500}
                    className="modal-confirm-payment-taobao-global"
                    visible={visible}
                    destroyOnClose={true}
                    title={
                        <div className="text-base text-black-100 capitalize">{`${t(
                            'taobao_global.title_create_peer_payment'
                        )}`}</div>
                    }
                    okButtonProps={{ className: 'capitalize', loading: isLoading, icon: <PlusOutlined />, disabled: !exchangeRate.rate}}
                    cancelButtonProps={{ icon: <CloseOutlined /> }}
                    centered
                    closeIcon={<i className="fa-solid fa-xmark text-grey-400 cursor-pointer" />}
                    cancelText={t('button.cancel')}
                    okText={t('button.create_peer_payment')}
                    onOk={handleConfirm}
                    onCancel={handleCancel}
                >
                    <Spin spinning={isLoading}>
                        <div className="text-confirm">
                            {t('peer_payment.confirm_pay_taobao_global')}
                            <span className="text-blue-primary">{` #${code}`}</span>?
                        </div>
                        <div className="payment-info pt-4 text-grey-400">
                            <div className="flex justify-between">
                                <div>{t('peer_payment.amount')}</div>
                                <div className="text-black-100">
                                    {purchaseAmount &&
                                        !isEmpty(exchangeRate) &&
                                        `${moneyFormat(purchaseAmount, exchangeRate.base)}`}
                                </div>
                            </div>
                            {exchangeRate.rate === null && (
                                        <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular text-right">
                                           {t('peerPayment.minPayment', { value: exchangeRate.minPayment})}
                                        </div>
                            )}
                            <div className="flex justify-between pt-3">
                                <div>{t('peer_payment.exchangeRate')}</div>
                                <div className="text-black-100">
                                    {!isEmpty(exchangeRate) &&
                                        `${moneyFormat(1, exchangeRate.base)} = ${moneyFormat(
                                            exchangeRate.rate,
                                            exchangeRate.exchange
                                        )}`}
                                </div>
                            </div>
                            <div className="flex justify-between pt-3">
                                <div>{t('peer_payment.originalReceiptCode')}</div>
                                <div className="text-black-100 grid gap-1">
                                    {subOriginalReceiptCode?.map(item => {
                                        return <span>{item?.subOriginalReceiptCode}</span>
                                    })}
                                </div>
                            </div>
                            <div className="flex justify-between pt-3">
                                <div>{t('peer_payment.billTo')}</div>
                                <div className="text-black-100">{credentialAccount}</div>
                            </div>
                            <div className="flex justify-between pt-3">
                                <div>{t('peer_payment.paymentAccount')}</div>
                                <div className="text-black-100">{credentialAccountInfo?.accountNote?.alipayName}</div>
                            </div>
                            <div className="form-textarea form-field pt-3">
                                <FloatLabel
                                    label={t('taobao_global.note')}
                                    value={note}
                                >
                                    <Input.TextArea
                                        maxLength={1000}
                                        rows={2}
                                        value={note}
                                        onChange={e => setNote(e.target.value)}
                                    />
                                </FloatLabel>
                            </div>
                        </div>
                    </Spin>
                </Modal>
            )}
        </>
    )
}
export default withNamespaces('translation')(ModalCreateConfirm)
