import React from "react";
import { Row, Col, Input} from 'antd';
import {withNamespaces} from "react-i18next";
import lodash from 'lodash';
import Inspection from "./Inspection";
import Shipping from "./Shipping";
import Percentage from "./Percentage";
import {LocalStore} from "../../utils/LocalStore";

class DetailFee extends React.Component{

	fixedOrder = () => {
		const {t,feeObj} = this.props;
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        let tenantConfig = currentProjectInfo.tenantConfig || {};
        let currency = tenantConfig.currency || {};

        let defaultCurrency = (currency.prefix || '') + (currency.suffix || '');
		return (
			<Row className='mgt15 dpl-flex align-items-center'>
				<Col span={8}>
					<span>{t('config_group.applied')}</span>
				</Col>
				<Col span={10}>
					<Row className="dpl-flex align-items-center">
						<Col span={20} className={'pdr10'}>
							<Input
								autoFocus={true}
								value={lodash.get(feeObj, 'feeMetadata.data.value', '')}
								disabled={true}
							/>
						</Col>
						<span>{defaultCurrency}/{t('config_group.order')}</span>
					</Row>
				</Col>
			</Row>
		);
	};

	fixedPackage = () => {
		const {t, feeObj} = this.props;
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        let tenantConfig = currentProjectInfo.tenantConfig || {};
        let currency = tenantConfig.currency || {};

        let defaultCurrency = (currency.prefix || '') + (currency.suffix || '');
		return (
			<Row className={'mgt15 dpl-flex align-items-center'}>
				<Col span={8}>
					<span>{t('config_group.applied')}</span>
				</Col>
				<Col span={10}>
					<Row className="dpl-flex align-items-center">
						<Col span={20} className={'pdr10'}>
							<Input
								autoFocus={true}
								value={lodash.get(feeObj, 'feeMetadata.data.value', '')}
								disabled={true}
							/>
						</Col>
						<span>{defaultCurrency}/{t('config_group.package')}</span>
					</Row>
				</Col>
			</Row>
		);
	};

	renderFormular = () => {
		const {configGroupId, feeObj, isShipment, orderDetail, t} = this.props;
		// console.log('renderFormular', configGroupId, feeObj)
		let template = lodash.get(feeObj, 'feeMetadata.template', null);
		if (template === 'shipping') {
			return <Shipping t={t} isShipment={isShipment} isEdit={false} configGroupId={configGroupId} feeObj={feeObj}/>
		} else if (template === 'inspection') {
			return <Inspection orderDetail={orderDetail} t={t} isEdit={false} feeObj={feeObj}/>;
		} else if (template === 'percentage_of_total_value') {
			return <Percentage orderDetail={orderDetail} t={t} isEdit={false} feeObj={feeObj}/>
		} else if (template === 'fixed_order') {
			return this.fixedOrder();
		} else if (template === 'fixed_package') {
			return this.fixedPackage();
		} else return null;
	};

	render(){
		return(
			<div className={'mgt15'}>
				{this.renderFormular()}
			</div>
		);
	}
}
export default withNamespaces()(DetailFee);
