/* eslint-disable react-hooks/exhaustive-deps */
import { Tabs, TabsProps } from "antd"
import { StatusOrderTbg } from "../../../../types/TaobaoOrder"
import { useMemo } from "react"

interface IProps {
    statuses: StatusOrderTbg[] | undefined
    addParams: (params: any) => void
    filter: any
}

const TabsComponent: React.FC<IProps> = ({
    // children,
    addParams,
    statuses,
    filter
}) => {

    const renderLabel = (label: string, icon: React.ReactNode, count: any, code: string) => <span className="!hover:text-blue-primary">
        {icon}
        <span
            className={`robotoregular text-base  `}
        >
            {label}</span>
        {/* <span className="order-count-item robotoregular text-xs py-[2px] px-[6px] rounded-[10px] bg-[#FF2424] text-white ml-2">{count || 0}</span> */}
    </span>

    const renderIcon = (code: any): React.ReactNode => {
        let className = ''
        if (!code) return
        if (code === 'all') className = 'fa-box-open-full'
        if (code === 'DELIVERED') className = 'fa-box-check'
        if (code === 'CANCELLED') className = 'fa-ban'
        if (code === 'DELIVERING') className = 'fa-truck-fast'
        if (code === 'PAYMENT_FAILED') className = 'fa-diamond-exclamation'
        if (code === 'PAYMENT_PROCESSING') className = 'fa-money-check-dollar-pen'
        if (code === 'PENDING') className = 'fa-box-dollar'
        if (code === 'PURCHASED') className = 'fa-pallet-box'
        if (code === 'WAITING_FOR_PAYMENT') className = 'fa-hourglass-clock'
        if (code === 'WAITING_FOR_DELIVERY') className = 'fa-shelves'
        if (code === 'PENDING_CANCEL') className = 'fa-xmark'
        if (code === 'PENDING_PAYMENT') className = 'fa-sack-dollar'
        return <i className={`fa-regular ${className} hover:text-blue-primary mr-2`} />
    }

    const items: TabsProps['items'] = useMemo(() => statuses?.map((status: StatusOrderTbg) => ({
        key: status.code,
        label: renderLabel(status.name, renderIcon(status?.code), 0, status?.code),
    })), [statuses, filter])

    const onChange = (key: string) => {
        addParams({
            ...filter,
            statuses: key
        })
    }

    return <Tabs
        className="tab-common-antd"
        defaultActiveKey={'all'}
        items={items}
        activeKey={filter?.statuses}
        onChange={onChange}
    />
}

export default TabsComponent