import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd'
import React from 'react'
import InputItem from '../commons/Input'
import Modal from '../commons/Modal'
import { useEffect, useState } from 'react';
import FloatLabel from '../commons/Input/FloatLabel';
import { AddressChinaResponseInterface } from '../../types/AddressChina';
import { PlusOutlined } from '@ant-design/icons';

interface CreateAddressChinaProps {
    showModalCreate: any
    isModalOpenCreate: boolean
    handleSubmitCreateAddress: any
    handleCancelModalAddress: any
    selectedAddress: AddressChinaResponseInterface
    t?:any
}
const CreateAddressChina: React.FC<CreateAddressChinaProps> = (props) => {
    const {  isModalOpenCreate, handleSubmitCreateAddress, selectedAddress, handleCancelModalAddress,t } = props
    const [isDisabledButton, setisDisabledButton] = useState(true)
    const [valueAddress1, setValueAddress1] = useState("")
    const [form] = Form.useForm()
    const addressId = selectedAddress?.id;

    const initialValues = {
        zipCode: selectedAddress?.zipCode || "",
        address1: selectedAddress?.address1 || "",
        phone: selectedAddress?.phone || "",
        city: selectedAddress?.city || "",
        district: selectedAddress?.district || "",
        recipient: selectedAddress?.recipient || "",
        state: selectedAddress?.state || "",
        addressName: selectedAddress?.addressName || "",
        defaultAddress: selectedAddress?.defaultAddress ? true : false,
        nation: "CN"
    };



    const resetForm = () => {
        form.resetFields(
            [
                "zipCode",
                "address1",
                "phone",
                "city",
                "district",
                "recipient",
                "state",
                "addressName",
                "defaultAddress",
            ]
        )
    }


    useEffect(() => {
        validateSubmitButton(form.getFieldsValue())
    }, [form])

    const validateSubmitButton = (values: any) => {
        if (values && (values.zipCode?.trim() === '' ||
            values.address1.trim() === '' ||
            values.city.trim() === '' ||
            values.district.trim() === '' ||
            values.recipient.trim() === '' ||
            values.state.trim() === '' ||
            values.phone.trim() === '')
        ) {
            setisDisabledButton(true)
        } else {
            setisDisabledButton(false)
        }
    }

    const onInputChange = (value: string, key: string) => {
        if (key === "address1") {
            setValueAddress1(value)
        }
        form.setFieldValue(key, value)
    };

    return (
        <>
            <Modal
                title={selectedAddress && Object.keys(selectedAddress).length !== 0 ? <>{`${t('taobaoGlobalCart.titleUpdate')}`}</> : <>{`${t('taobaoGlobalCart.titleAddnew')}`}</>}
                visible={isModalOpenCreate}
                onCancel={() => {
                    handleCancelModalAddress(false)
                    resetForm()
                }}
                width={750}
                listAction={[
                    // <Button onClick={showModalCreate}>Thêm Địa Chỉ Mới</Button>,
                    <Button
                        className={"btn-cancel space-x-1.5 _btn-cancel-confirm mr-2"}
                        onClick={() => {
                            handleCancelModalAddress(false)
                            resetForm()
                        }}
                    >
                        <i className="fa-regular fa-xmark"></i>
                        {t('taobaoGlobalCart.cancel')}
                    </Button>,
                    <Button
                        type="primary"
                        htmlType="submit"
                        size='middle'
                        // className="space-x-1.5 _btn-submit-confirm"
                        onClick={() => handleSubmitCreateAddress(addressId, { ...form.getFieldsValue() })}
                        disabled={(isDisabledButton)}
                        icon={selectedAddress && Object?.keys(selectedAddress).length !== 0 ? <i className="fa-regular fa-check  mr-1"></i> : <PlusOutlined />}
                    // loading={isLoading}
                    >
                        {selectedAddress && Object?.keys(selectedAddress).length !== 0 ?
                            <>
                                {t('taobaoGlobalCart.save')}</> :
                            <>
                                {t('taobaoGlobalCart.addNew')}</>}
                    </Button>

                ]}
            >
                <Form
                    form={form}
                    layout="horizontal"
                    name="form_in_modal"
                    // labelCol={{ span: 24 }}
                    labelAlign={'left'}
                    // wrapperCol={{ span: 24 }}
                    initialValues={initialValues}
                    onValuesChange={(changedValues, allValues) => {
                        validateSubmitButton(allValues);
                    }}
                >
                    <Row>
                        <Col span={24} className='mb-1'>
                            <Form.Item
                                className=''
                                name="recipient"
                                label={''}
                                rules={[
                                    {
                                        required: true,
                                        message: ('Vui lòng nhập tên người nhận'),
                                    }
                                ]}
                            >
                                <InputItem
                                    isRequired
                                    title={t('taobaoGlobalCart.recipient')}
                                    onChange={(e: any) => onInputChange(e.target.value, 'recipient')}
                                    value={form.getFieldValue("recipient")}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} className='mb-1'>
                            <Form.Item
                                className=''
                                name="phone"
                                label={''}
                                rules={[
                                    {
                                        required: true,
                                        message: ('Vui lòng nhập số điện thoại'),
                                    }
                                ]}
                                normalize={(value) => {
                                    let raw = value.replace(/[^0-9-+. ()]/g, '')
                                    return raw
                                }}
                            >
                                <InputItem
                                    isRequired
                                    title={t('taobaoGlobalCart.phone')}
                                    onChange={(e: any) => onInputChange(e.target.value, 'phone')}
                                    value={form.getFieldValue("phone")}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} className='mb-1'>
                            <Form.Item
                                className=''
                                name="zipCode"
                                label={''}
                                rules={[
                                    {
                                        required: true,
                                        message: ('Vui lòng nhập zipcode'),
                                    }
                                ]}
                            >
                                <InputItem
                                    isRequired
                                    title={('Zip Code')}
                                    onChange={(e: any) => onInputChange(e.target.value, 'zipCode')}
                                    value={form.getFieldValue("zipCode")}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} className='mb-1'>
                            <div className='form-select mb-4'>
                                <FloatLabel isRequired={true} label={'Quốc gia'}
                                    value={"CN"}
                                >
                                    {/* <SuperSelect
                                        // onChange={(e: any) => handleChangeWallet(e)}
                                        disable
                                        defaultValue="CN"
                                        className={"_select-bank w-full"}
                                        selectedValue={"CN"}
                                        options={[
                                            {
                                                value: 'CN',
                                                label: 'Trung Quốc',
                                            },
                                        ]}

                                        renderOptionLabel={(item: any) => <>
                                            <span className={`_service-name txt-size-h8 mr-1 robotoregular txt-color-black`}>Trung Quốc</span>
                                        </>}
                                    /> */}
                                    <Select
                                        defaultValue="CN"
                                        className='w-full'
                                        disabled
                                        options={[
                                            {
                                                value: 'CN',
                                                label: 'Trung Quốc',
                                            },
                                        ]}
                                    />
                                </FloatLabel>
                            </div>
                        </Col>


                        <Row justify={"space-between"} gutter={20} style={{ width: "105%" }} className='mb-1'>
                            <Col span={8} className="" >
                                <Form.Item
                                    className=''
                                    name="state"
                                    label={''}
                                    rules={[
                                        {
                                            required: true,
                                            message: ('Vui lòng nhập Tỉnh'),
                                        }
                                    ]}
                                >
                                    <InputItem
                                        isRequired
                                        title={t('taobaoGlobalCart.state')}
                                        onChange={(e: any) => onInputChange(e.target.value, 'state')}
                                        value={form.getFieldValue("state")}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={8} className="">
                                <Form.Item
                                    className=''
                                    name="city"
                                    label={''}
                                    rules={[
                                        {
                                            required: true,
                                            message: ('Vui lòng nhập Thành phố'),
                                        }
                                    ]}
                                >
                                    <InputItem
                                        isRequired
                                        title={t('taobaoGlobalCart.city')}
                                        onChange={(e: any) => onInputChange(e.target.value, 'city')}
                                        value={form.getFieldValue("city")}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={8} className="" >
                                <Form.Item
                                    className=''
                                    name="district"
                                    label={''}
                                    rules={[
                                        {
                                            required: true,
                                            message: ('Vui lòng nhập Quận'),

                                        }
                                    ]}
                                >
                                    <InputItem
                                        isRequired
                                        title={t('taobaoGlobalCart.district')}
                                        onChange={(e: any) => onInputChange(e.target.value, 'district')}
                                        value={form.getFieldValue("district")}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Col span={24}>

                            <div className='form-textarea'>
                                <FloatLabel label={`${t('taobaoGlobalCart.addressDetail')}`} value={valueAddress1||form.getFieldValue('address1')} isRequired>
                                    <Form.Item
                                        className=''
                                        name="address1"
                                        label={''}
                                        rules={[
                                            {
                                                required: true,
                                                message: ('Vui lòng nhập địa chỉ cụ thể'),
                                            }
                                        ]}
                                    >
                                        <Input.TextArea
                                            required
                                            title={('createAddressChinaModal.address1')}
                                            style={{ padding: "12px 6px 12px 16px" }}
                                            onChange={(e: any) => onInputChange(e.target.value, 'address1')}
                                            value={form.getFieldValue("address1")}
                                        />
                                    </Form.Item>

                                </FloatLabel>
                            </div>
                        </Col>

                        <Col span={24}>
                            <span className="text-[#FF4559] italic">Lưu ý: trường <span className="font-bold">Địa chỉ người nhận cụ thể</span> không cần điền thông tin Tỉnh/ Thành Phố/ Quận</span>
                        </Col>
                        <Col span={24} className="mt-2">
                            <Form.Item
                                name='defaultAddress'
                                valuePropName='checked'
                                className=''
                            >
                                <Checkbox checked={selectedAddress?.defaultAddress ? true : false}>
                                   {`${t('taobaoGlobalCart.saveDefault')}`}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}

export default CreateAddressChina