/* eslint-disable no-self-assign */
import { useQuery } from "react-query";
import queryString from 'query-string';
import TaobaoService from "../services/taobao.service";
import { first, get } from "lodash";

const useOrders = (filter: any, accounts: any) => {
    const getOrders = async (params: any) => {
        return new Promise((resolve, reject) => {
            if (params.size) params.size = params.size
            else params.size = 25

            if (params.page) params.page = params.page - 1
            else params.page = 0

            if (params.statuses) {
                params.statues = params.statues
                if (params.statuses === 'all') delete params.statuses
            } else {
                delete params.statuses
            }
            const newQueryString = '?sort=timestamp:desc&' + queryString.stringify({ ...params })

            TaobaoService.getListOrders(newQueryString, (error: any, response: any, headers: any) => {
                if (error) {
                    reject(error);
                } else {
                    resolve({ data: response, headers });
                }
            });
        });
    };

    const orderQuery = useQuery(
        ['listOrders', filter, accounts],
        () => getOrders(
            {
                ...filter,
                credentialAccount: filter?.credentialAccount ? filter?.credentialAccount : get(first(get(accounts, 'data') as any), 'tbgAccount')
            }
        ),
        {
            enabled: !!accounts?.data,
        }
    );

    return {
        ...orderQuery,
        data: orderQuery.data
    }
}

export default useOrders