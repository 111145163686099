import React from 'react'
import { Avatar, Button, Tag, Typography } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import TableCommon from '../../../../components/commons/Table'
import OrderTaobao, { StatusOrderTbg } from '../../../../types/TaobaoOrder'
import { first, get} from 'lodash'
import { moneyFormat } from '../../../../utils/stringUtils'
import moment from 'moment'
import ModalCreateConfirm from '../../PeerPayment/ModalCreateConfirm'
import Filter from './Filter'
import { SkeletonTable } from '../../../../components/SkeletonTable/SkeletonTable'
import TooltipCommon from '../../../../components/commons/Tooltip'
import CancelOrder from './CancelOrder'
import { PeerPaymentCodeTBG } from '../../../../types/PeerPaymentCodeTBG'
import { Link } from 'react-router-dom'
interface IProps {
    t: any
    orders: OrderTaobao[] | undefined
    pagination: any
    handleChangePage: (page: number, size?: number) => void
    isLoading: boolean
    filter: any
    accountsTaobao: any
    onSearch: (params: any) => void
    onClearParams: () => void
    handleOpenDrawer: (isBol: boolean) => void
    handleOrderSelected: (data: any) => void
    getProductsByOrder: (data: string) => void
    setFilter: any
    history: any
    statuses: StatusOrderTbg[] | undefined,
    refetch: any,
    peerPaymentList: PeerPaymentCodeTBG[] | []
    accountAliasList: any
}

const DataTable: React.FC<IProps> = ({
    t,
    orders,
    pagination,
    isLoading,
    filter,
    onSearch,
    accountsTaobao,
    onClearParams,
    handleOpenDrawer,
    handleOrderSelected,
    getProductsByOrder,
    setFilter,
    statuses,
    history,
    refetch,
    peerPaymentList,
    accountAliasList,
    ...props
}) => {
    const getStyleStatus = (backgroundColor: string, color: string, border: string): { [key in string]: string } => ({
        backgroundColor,
        color,
        border
    })

    const renderColor = (status: string) => {
        switch (status) {
            case 'CANCELLED':
                return getStyleStatus('rgba(255, 69, 89, 0.1)', '#FF4559', '1px solid #FF8F9B')
            case 'DELIVERED':
                return getStyleStatus('#EEFBF6', '#4DD69C', '1px solid #94E6C4')
            case 'DELIVERING':
                return getStyleStatus('#EDF7FF', '#1890FF', '1px solid #57A6F5')
            case 'PAYMENT_FAILED':
                return getStyleStatus('#FFEDEF', '#FF4559', '1px solid #FF8F9B')
            case 'PAYMENT_PROCESSING':
                return getStyleStatus('#FFF', '#1A1A1A', '1px solid #C0C0C0')
            case 'PENDING':
                return getStyleStatus('#FFF9DF', '#1A1A1A', '1px solid #FCD535')
            case 'PURCHASED':
                return getStyleStatus('#FFF9EA', '#FDB924', '1px solid #FED57C')
            case 'WAITING_FOR_PAYMENT':
                return getStyleStatus('#EFF5FF', '#1C428A', '1px solid #6081BE')
            default:
                return {}
        }
    }
    const columns: ColumnsType<OrderTaobao> = [
        {
            title: <span className="text-grey-200 text-xs">{t('orderTbg.code')}</span>,
            dataIndex: 'code',
            key: 'code',
            width: 380,
            render: (text: string, record: OrderTaobao) => {

                let originalReceiptCodes: Array<string> = []
                let image: Array<string> = []

                record?.products?.forEach(product => {
                    originalReceiptCodes.push(product.originalReceiptCode)
                    if (product?.imageUrl) {
                        image.push(product?.imageUrl)
                    }
                });

                let nameAlias = accountAliasList?.find((item: any) => item.tbgAccount === record.credentialAccount)?.name

                return (
                    <div>
                        <div className="flex ">
                            <Avatar
                                shape="square"
                                className='rounded-[9px]'
                                src={first(image)}
                                icon={<img src={require('../../../../resources/images/shoping.png')} alt='' />}
                                size={80}
                            />
                            <div className="_content ml-4">
                                <Typography.Paragraph
                                    className="robotomedium text-blue-primary"
                                    style={{ marginBottom: 0 }}
                                    copyable={{ text: text, icon: <i className="fa-regular fa-copy ml-1"></i> }}
                                >
                                    #{record.code}
                                </Typography.Paragraph>
                                <span className="font-roboto flex mt-2">
                                    <span className='text-grey-400'>{t('orderTbg.accountConn')}:&nbsp;</span>
                                    <span className="font-normal font-roboto text-black-100]">{nameAlias || '---'}</span>
                                </span>
                                <span > {originalReceiptCodes?.length > 1 ? <>
                                    <TooltipCommon
                                        color='#1890FF'
                                        placement={'right'}
                                        overlayStyle={{ padding: '12px' }}
                                        content={
                                            <>
                                                {
                                                    originalReceiptCodes.map(originalReceiptCode =>
                                                        <Typography.Paragraph
                                                            className="robotomedium text-white "
                                                            style={{ marginBottom: 0 }}
                                                            copyable={{ text: originalReceiptCode as any, icon: <i className="fa-regular fa-copy ml-1 text-white font-medium"></i> }}
                                                        >
                                                            {originalReceiptCode || '---'}
                                                        </Typography.Paragraph>
                                                    )
                                                }
                                            </>
                                        }
                                        label={
                                            <span className="font-roboto text-grey-400 mt-2 hover:text-blue-primary underline">
                                                {t('orderTbg.see_originalReceiptCode')}&nbsp;({originalReceiptCodes.length})
                                            </span>
                                        }
                                    />
                                </> : <>
                                    {
                                        originalReceiptCodes.map((originalReceiptCode) => {
                                            if (originalReceiptCode === null) {
                                                return <span className='font-roboto text-grey-400 block mt-2'>{t('orderTbg.see_originalReceiptCode')}: <span className="text-black-100">---</span></span>
                                            }
                                            return <div className='flex'>
                                                <span className="font-roboto text-grey-400 block mt-2 mr-1">
                                                    {t('orderTbg.see_originalReceiptCode')}:
                                                </span>
                                                <Typography.Paragraph
                                                    className='mt-2'
                                                    style={{ marginBottom: 0 }}
                                                    copyable={{ text: originalReceiptCode as any, icon: <i className="fa-regular fa-copy ml-1 text-black-100 hover:text-blue-primary"></i> }}
                                                >
                                                    {originalReceiptCode || '---'}
                                                </Typography.Paragraph>
                                            </div>
                                        })
                                    }
                                </>
                                }

                                </span>

                            </div>
                        </div>
                    </div>
                )
            },
        },
        {
            title: <span className="text-grey-200 text-xs">{t('orderTbg.peerPaymentCode')}</span>,
            dataIndex: 'peerPaymentCode',
            key: 'peerPaymentCode',
            render: (_: any, record: OrderTaobao) => {

                const peerPaymentCode = peerPaymentList.find((item: PeerPaymentCodeTBG) => item.orderCode === record.code)
                if (!peerPaymentCode?.peerpaymentCode) {
                    return <div className="flex items-center">
                        <span className="text-black-100">---</span>
                    </div>
                }
                return (
                    <div className="flex items-center">
                        <Typography.Paragraph
                            className="mt-2 robotomedium text-blue-primary"
                            copyable={{ text: peerPaymentCode?.peerpaymentCode as any, icon: <i className="fa-regular fa-copy ml-1 text-blue-primary hover:text-blue-primary"></i> }}
                        >
                            <Link target='_blank' to={`peer-payments?query=${peerPaymentCode?.peerpaymentCode}&peerPaymentType=taobao_global`}>
                                {peerPaymentCode?.peerpaymentCode || '---'}
                            </Link>
                        </Typography.Paragraph>
                    </div>
                )
            }
        },
        {
            title: <span className="text-grey-200 text-xs">{t('cartGroup.quantity')}</span>,
            dataIndex: 'totalQuantity',
            align: 'right',
            key: 'totalQuantity',
            render: (text: string, record: OrderTaobao) => {
                return <div className="flex items-center justify-end"><span className="font-roboto">{record.totalSku || '---'}</span></div>
            }

        },
        {
            title: <span className="text-grey-200 text-xs">{t('orderTbg.purchaseAmount')}</span>,
            dataIndex: 'purchaseAmount',
            key: 'purchaseAmount',
            align: 'right',
            render: (text: string, record: OrderTaobao) => {
                return <div className="flex items-center justify-end"><span className="font-roboto font-medium">{moneyFormat((text), 'CNY' as any)}</span></div>
            }

        },
        {
            title: <span className="text-grey-200 text-xs">{t('orderTbg.created_at')}</span>,
            dataIndex: 'lastFetch',
            key: 'lastFetch',
            render: (text: string, record: OrderTaobao) => (
                <div className="flex items-center">
                    <div>
                        <span className="text-grey-400 robotoregular block">{moment(text).format('HH:mm DD/MM/YYYY')}</span>
                        <span
                            className="text-blue-primary underline robotoregular whitespace-nowrap"
                            onClick={() => {
                                handleOpenDrawer(true)
                                getProductsByOrder(record?.code)
                            }}
                        >
                            {t('orderTbg.see_product')}
                        </span>
                    </div>
                </div>
            ),
        },
        {
            title: <span className="text-grey-200 text-xs">{`${t('request_withdraw.status')}`}</span>,
            dataIndex: 'status',
            key: 'status',
            align: 'left',
            render: (text: string, record: OrderTaobao) => {
                const status = statuses?.find((status: StatusOrderTbg) => status.code === text)
                return (
                    <div className="flex justify-start items-center">
                        <Tag
                            style={renderColor(text)}
                            className={'_table-row-status  mr-0 robotomedium py-1 px-4 rounded-2xl robotomedium'}
                        >
                            {/* {t(`orderTbg.${text}`)} */}
                            {status?.name} {' '}
                        </Tag>
                    </div>
                )
            },
        },
        {
            title: <span className="text-grey-200 text-xs"> </span>,
            dataIndex: 'paymentRequest',
            key: 'paymentRequest',
            align: 'right',
            render: (text: string, record: OrderTaobao) => {
                const peerPaymentCode = peerPaymentList.find((item: PeerPaymentCodeTBG) => item.orderCode === record.code)

                return (
                    <div className="flex items-center justify-end">
                        <div className='text-left'>
                            {
                                record.status === "WAITING_FOR_PAYMENT" && !peerPaymentCode && <ModalCreateConfirm orderInfo={record} />
                            }
                            {
                                record.status === "WAITING_FOR_PAYMENT" && <CancelOrder orderInfo={record} />
                            }
                        </div>
                    </div>
                )
            }

        },
    ]

    const requiredKeys = ['orderCode', 'originalReceiptCode', 'peerpaymentCode']
    const hasAnyKey = requiredKeys.some(key => filter.hasOwnProperty(key));
    console.log(hasAnyKey);
    
    return <div className="">
        <Filter
            accountsTaobao={accountsTaobao}
            filter={filter}
            handleChangePage={props.handleChangePage}
            onClearParams={onClearParams}
            onSearch={onSearch}
            pagination={pagination}
            setFilter={setFilter}
            t={t}
        />
        <SkeletonTable
            loading={isLoading}
            columns={columns as any}
            rowCount={25}
        >
            <TableCommon
                columns={columns}
                dataTable={orders}
                locale={{
                    emptyText: <div className='text-center'>
                        {
                            !hasAnyKey ? <div className='text-center mx-auto w-[280px] v2-layout'>
                                <img src={require('../../../../resources/images/no-data.png')} alt="" className='w-[131px]' />
                                <span className='block my-4 text-gray-400 robotoregular'>
                                    {get(filter, 'statuses') === 'all' || get(filter, 'statuses') === undefined ? 'Chưa có đơn hàng!' : 'Chưa có đơn hàng ở trạng thái này!'}
                                </span>
                                <Button
                                    className='robotoregular'
                                    type='primary'
                                    size='large'
                                    icon={<i className="fa-regular fa-cart-shopping mr-2" />}
                                    onClick={() => {
                                        history.push('/taobaoglobal_cart')
                                    }}
                                >
                                    Đặt Hàng Ngay
                                </Button>
                            </div> : <div className='text-center mx-auto w-[280px] v2-layout'>
                                <img src={require('../../../../resources/images/nodatasearch.png')} alt="" className='w-[131px]' />
                                <span className='block my-4 text-gray-400 robotoregular'>Không có kết quả tìm kiếm phù hợp</span>
                            </div>
                        }
                    </div>
                }}
                pagination={false}
                rowKey={(row: any) => row?.id}
            // isLoading={isLoading}
            />
        </SkeletonTable>
    </div>
}

export default DataTable
