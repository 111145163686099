import React from 'react';
import lodash from 'lodash'
import MainLayout from '../../components/Layout/MainLayout'
import ProfileSidebar from '../../components/Profile/Leftbar'
import {LocalStore} from "../../utils/LocalStore";
import UserService from "../../services/user.service";
import {getUrlQueryString} from "../../utils/helper";
import {parseQueryStringToObject, updateQueryStringParameter} from "../../utils/stringUtils";

import {Col, Collapse, Empty, Pagination, Row, Spin,} from 'antd';
import * as AntdIcon from "@ant-design/icons"
import {withNamespaces} from "react-i18next";

const Panel = Collapse.Panel;

const pageSize = 25;
class Faqs extends React.Component {
    constructor(props) {
        super(props);
        this.isUnmounted = false;
        this.state = {
            page: 1,
            pageSize: pageSize,
            isLoading: true,
            listFaqs: [],
            activeFaq: 0
        }
    }

    componentDidMount() {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null;
        window.addEventListener("popstate", this.onPopState.bind(this));

        if (typeof LocalStore.getInstance().read('currentLoggedUser') === 'object') {

            let queryString = getUrlQueryString();
            let queryObj = parseQueryStringToObject(queryString) || {};
            if(lodash.has(queryObj, 'recharge')) {
                this.setState({activeFaq: 1});
            }

            this.setState({isLoading: true}, () => {
                this.loadFromQueryString();
            });
        }
    }

    /**
     * Khi unmount ra thì bỏ sự kiện popstate
     */
    componentWillUnmount() {
        this.isUnmounted = true;
        window.removeEventListener("popstate", this.onPopState.bind(this));
    }

    /**
     * On popstate
     */
    onPopState() {
        if (!this.isUnmounted) this.setState({isLoading: true}, ()=> {
            this.loadFromQueryString();
        });
    };

    /**
     * Load từ QueryString (dành cho lần load đầu tiên)
     */
    loadFromQueryString = () => {
        let queryString = getUrlQueryString();
        let queryObj = parseQueryStringToObject(queryString) || {};

        this.setState({
            page: queryObj.page,
            pageSize: queryObj.size || pageSize,
        }, ()=> {
            this.loadFaqs();
        });
    };


    loadFaqs = (cb) => {
        const {page, pageSize} = this.state;
        let queryString = `?page=${page ? page - 1 : 0}&size=${pageSize}&sort=position:asc`;
        this.setState({showProgressBar: true}, () => {
            UserService.loadDataFaqs(queryString, (err, res, headers) => {
                if (!err) {
                    this.setState({
                        listFaqs: res,
                        metadata: {
                            pageCount: headers['x-page-count'],
                            page: headers['x-page-number'],
                            size: headers['x-page-size'],
                            total: headers['x-total-count'],
                        }}, () => {
                        if (this.mainLayoutElement && this.mainLayoutElement.scrollLayoutElement) this.mainLayoutElement.scrollLayoutElement.scrollTop = 0;
                    });
                }
                if(cb) cb();
                this.setState({showProgressBar: false, isLoading: false})
            });
        });

    };

    onPageChanged = (page) => {
        this.setState({page: page}, () => {
            let queryString = getUrlQueryString();
            queryString = updateQueryStringParameter(queryString ? '?'+ queryString : '', 'page', page);
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: queryString
            });
        });
    };

    /**
     * render header của panel
     * @param item
     */
    renderPanelHeader = (item) => {
        return (
            <div className={`panel-faq-header _faqTitle-${item.id}`}>
                <h4 className='robotobold txt-color-black txt-size-h7'>{item.title}</h4>
            </div>
        )
    };

    render() {
        const {listFaqs = [], metadata, isLoading, activeFaq} = this.state;
        const {t} = this.props;
        return <MainLayout
            ref={ref => this.mainLayoutElement = ref}
            {...this.props}
            showProgressBar={this.state.showProgressBar}
            headerTitle={t('customer_info.frequently_asked_question')}
            title={t('customer_info.personal_info')}
        >
            <div className={'container-common pdbt30 pdt20 mgr20'}>
                <Row>
                    <Col span={6} className={'pdr10'}>
                        <ProfileSidebar currentRoute={'faqs'}/>
                    </Col>
                    <Col span={18} className={'pdl10'}>
                        <div className="pd20 bg-color-white">
                            {
                                isLoading ?
                                    <div className="dpl-flex align-items-center justify-content pdt30 pdbt30">
                                        <Spin indicator={<AntdIcon.Loading3QuartersOutlined type="loading" style={{ fontSize: 24 }} spin />} />
                                    </div>
                                    : listFaqs.length === 0 ?
                                    <Empty
                                        className={'mgbt25'}
                                        image={require('../../resources/images/empty-image.png')}
                                        description={t('message.empty')}
                                    /> : (
                                        <Collapse
                                            accordion
                                            activeKey={[`${activeFaq}`]}
                                            className='sidebar position-re'
                                            onChange={(e) => {
                                                this.setState({activeFaq: e});
                                            }}
                                        >
                                            {
                                                listFaqs.map((item, index) => {
                                                    return (
                                                        <Panel className={'bg-color-gray'} header={this.renderPanelHeader(item)} key={index}>
                                                            <div dangerouslySetInnerHTML={{__html: item.content}} className={`txt-size-h7 txt-color-black robotoregular line-height167 mgbt10 white-space-pre-wrap _faqValue-${index} max-width-100pc`}></div>
                                                        </Panel>
                                                    )
                                                })
                                            }
                                        </Collapse>
                                    )
                            }
                            <Pagination
                                className={'text-center _pagination-faqs mgt20'}
                                hideOnSinglePage={true}
                                showQuickJumper
                                current={metadata ? parseFloat(metadata.page) + 1 : 1}
                                total={metadata ? parseFloat(metadata.total) : 1}
                                pageSize={metadata ? parseFloat(metadata.size) : pageSize}
                                onChange={(page) => this.onPageChanged(page)}
                            />

                        </div>
                    </Col>
                </Row>
            </div>
        </MainLayout>;
    }
}
export default withNamespaces()(Faqs);
