import React, {useEffect, useState} from 'react'
import {Avatar, Col, Form, InputNumber, Modal, Row} from 'antd'
import lodash from 'lodash'
import {moneyFormat} from "../../utils/stringUtils";
import {LocalStore} from "../../utils/LocalStore";

export const ModalEditSkuItemSalePrice = (props = {
    skuItem: {},
    t: () => {},
    loading: false,
    onSubmit: () => {},
    onCancel: () => {}
}) => {
    const {skuItem, loading, t, onSubmit, onCancel} = props
    const [bargainPrice, setBargainPrice] = useState()
    const currencies = LocalStore.getInstance().read('currencies') || [];
    const unitCurrency = currencies.find(x => x.code === skuItem.currency) || {};

    useEffect(() => {
        setBargainPrice(skuItem.bargainPrice !== null ? skuItem.bargainPrice : undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Modal
            visible={true}
            title={t('modalEditSalePrice.title')}
            okText={t('modalEditSalePrice.okBtn')}
            cancelText={t('modalEditSalePrice.cancelBtn')}
            onOk={() => onSubmit({
                bargainPrice,
                id: skuItem.id
            })}
            onCancel={() => onCancel()}
            okButtonProps={{
                loading: loading,
                disabled: loading || (bargainPrice === null || bargainPrice === undefined)
            }}
            cancelButtonProps={{
                loading: loading,
                disabled: loading
            }}
        >
            <Form
                layout={'horizontal'}
                labelCol={{lg: 8}}
                wrapperCol={{lg: 16}}
            >
                <Row gutter={20}>
                    <Col span={8}>
                        <Avatar
                            src={skuItem.image}
                            size={100}
                        />
                    </Col>
                    <Col span={16}>
                        <Form.Item
                            label={t('modalEditSalePrice.name')}
                        >
                            <span>{lodash.get(skuItem, 'product.name')}</span>
                        </Form.Item>
                        <Form.Item
                            label={t('modalEditSalePrice.originalPrice')}
                        >
                            <span>{moneyFormat(skuItem.salePrice, skuItem.currency)}</span>
                        </Form.Item>
                        <Form.Item
                            label={`${t('modalEditSalePrice.bargainPrice')} (${unitCurrency.prefix})`}
                        >
                            <InputNumber
                                value={bargainPrice}
                                className={'w100pc'}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)|([^0-9.])/g, '')}
                                onChange={value => {
                                    setBargainPrice(value)
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
