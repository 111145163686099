import { Col, Image, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { formatCurrencyChina } from '../../../../utils/helper'

interface Props {
  checkedItems: any
  t: any
}
const ProductList: React.FC<Props> = ({
  checkedItems,
  t,
  ...props
}) => {
  const [dataSource, setDataSource] = useState<any>([])

  useEffect(() => {
    const groupedData: any = {};

    checkedItems.forEach((item: any, index: any) => {
      const merchantId = item.merchant.id;

      if (!groupedData[merchantId]) {
        groupedData[merchantId] = {
          merchant: item.merchant,
          products: [],
        };
      }

      const data = {
        ...item.product,
        sku: item.sku
      }
      groupedData[merchantId].products.push(data);
    });

    const newState = [];
    for (const key in groupedData) {
      newState.push(groupedData[key]);
    }

    setDataSource(newState)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div className='bg-white rounded-xl py-4 px-7 my-4'>
        <Row
          align={'middle'}>
          <Col
            span={12}
          >
            <span className='text-black-100 robotomedium'>
              {`${t('taobaoGlobalCart.product')}`}
            </span>
          </Col>
          <Col span={3} className='flex justify-center'>
            <Row>
              <span className={'txt-secondary'}>
                {`${t('taobaoGlobalCart.unitPrice')}`}

              </span>
            </Row>
          </Col>
          <Col span={5} className='flex justify-center'>
            <Row>
              {`${t('taobaoGlobalCart.quantity')}`}
            </Row>
          </Col>

          <Col span={2} className='flex justify-end'>
            <Row >
              <span className={'txt-secondary'}>
                {`${t('taobaoGlobalCart.money')}`}
              </span>
            </Row>
          </Col>
        </Row>
      </div>
      {
        dataSource.map((data: any) => {
          return <div key={data?.merchant?.id} className='bg-white rounded-xl py-4 px-7 mt-4 mb-4'>
            <Row
              className='mb-3'
              align={'middle'}
            >
              <Col
                span={24}
              >
                <span className='robotoregular'>
                  Shop:
                  <span className='ml-2'>{data?.merchant?.name}</span>
                </span>
              </Col>
            </Row>
            {
              data?.products.map((product: any, index: number) => {
                return <Row
                  className={`${data?.products.length !== index && 'mb-3'}`}
                  key={product?.id}
                  align={'middle'}>
                  <Col
                    span={12}
                  >
                    <div className='flex gap-3'>
                      <Image
                        src={product.sku.image}
                        style={{ width: "60px", maxWidth: "60px", height: "60px", maxHeight: "60px" }}
                        className='rounded'
                        preview={false}
                      />
                      <div>
                        <span className='robotoregular text-black-100'>
                          {product?.name}
                        </span>
                        <div className='grid items-center'>
                          {
                            product?.sku?.variantProperties?.length > 0 ? product?.sku?.variantProperties?.map((item: any) => {
                              return (
                                <span key={item.value} className='text-black-100 text-sm'>
                                  {item?.name} : {item?.value} <br></br>
                                </span>
                              )
                            }) : product?.name
                          }
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col span={3} className='flex justify-center'>
                    <Row>
                      <span className='robotoregular'>{formatCurrencyChina(product?.sku.price)}</span>
                    </Row>
                  </Col>
                  <Col span={5} className='flex justify-center'>
                    <Row>
                      <span className='robotoregular text-black-100 text-sm'>{product?.sku.quantity}</span>
                    </Row>
                  </Col>
                  <Col span={2} className='flex justify-end'>
                    <Row>
                      <span className='robotomedium text-black-100 text-sm'>{formatCurrencyChina(product?.sku?.price * product?.sku?.quantity)}</span>
                    </Row>
                  </Col>
                </Row>
              })
            }

          </div>
        })
      }

    </div>
  )
}

export default ProductList