/**
 * create by Taobao dev team 18/1/2018
 */
import { Component } from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import * as AntdIcon from '@ant-design/icons'
import { ReactComponent as IconMenuTaobaoGlobal } from '../../resources/images/iconMenuTaobaoGlobal.svg'
import AntdIconSVG from '@ant-design/icons'
import { LocalStore } from '../../utils/LocalStore'
import { quantityFormat } from '../../utils/stringUtils'
import _ from 'lodash'

export default class MenuContainer extends Component {
    render() {
        const { match, t } = this.props
        let currentRoute = match.path && match.path !== '/' ? match.path.split('/')[1] : 'dashboard'

        const ordersStatistics = LocalStore.getInstance().read('ordersStatistics') || []
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const tenantConfig = currentProjectInfo.tenantConfig || {}
        const orderConfig = tenantConfig ? tenantConfig.orderConfig : {}
        const shipmentConfig = tenantConfig ? tenantConfig.shipmentConfig : {}
        const generalConfig = tenantConfig ? tenantConfig.generalConfig : {}
        const m24Config = tenantConfig ? tenantConfig?.m24Config?.enabled : {}
        const isShowSpecialMenu = currentProjectInfo.name !== 'thanhtoan1688'
        const diorConfig = _.get(currentProjectInfo, 'tenantConfig.diorConfig.enabled', false)

        const listStatuses = [
            {
                id: 1,
                code: 'PENDING',
                name: t('status.PENDING'),
            },
            {
                id: 4,
                code: 'DELIVERED',
                name: t('status.CUSTOMER_RECEIVED'),
            },
            {
                id: 5,
                code: 'CANCELLED',
                name: t('status.CANCEL'),
            },
            {
                id: 6,
                code: 'MIA',
                name: t('status.MISSING'),
            },
            {
                id: 7,
                code: 'OUT_OF_STOCK',
                name: t('status.OUT_OF_STOCK'),
            },
        ]

        return (
            <Menu
                className={'_sidebar-menu sidebarleft__menu'}
                // defaultOpenKeys={[currentRoute]}
                selectedKeys={[currentRoute]}
                mode="inline"
            >
                <Menu.Item key="dashboard">
                    <Link
                        className={'_menu-dashboard'}
                        to={'/'}
                    >
                        <AntdIcon.DatabaseOutlined type="database" />
                        <span>{t('menu.dashboard')}</span>
                    </Link>
                </Menu.Item>

                {!orderConfig.disable && (
                    <Menu.SubMenu
                        key="orders"
                        title={
                            <span>
                                <Link
                                    className={'_menu-orders'}
                                    to={'/orders'}
                                >
                                    <AntdIcon.FileTextOutlined type="file-text" />
                                    <span>{t('menu.orders')}</span>
                                </Link>
                            </span>
                        }
                    >
                        {listStatuses.map(x => (
                            <Menu.Item key={x.id}>
                                <Link
                                    className={'_menu-orders'}
                                    to={`/orders?statuses=${x.code}`}
                                >
                                    {x.name}{' '}
                                    {ordersStatistics.find(y => y.status === x.code)
                                        ? `(${quantityFormat(ordersStatistics.find(y => y.status === x.code).total)})`
                                        : ''}
                                </Link>
                            </Menu.Item>
                        ))}
                    </Menu.SubMenu>
                )}

                {shipmentConfig.enable && (
                    <Menu.Item key="shipments">
                        <Link
                            className={'_menu-tickets'}
                            to={'/shipments'}
                        >
                            <AntdIcon.GoldOutlined type="gold" />
                            <span>{t('menu.shipments')}</span>
                        </Link>
                    </Menu.Item>
                )}

                {generalConfig.peerPaymentEnabled && m24Config && (
                    <Menu.SubMenu
                        key="taobaoglobal"
                        title={
                            <span>
                                <Link to={'/taobaoglobal_cart'}>
                                    <AntdIconSVG component={IconMenuTaobaoGlobal} />
                                    <span>{t('menu.taobaoglobal')}</span>
                                </Link>
                            </span>
                        }
                    >
                        <Menu.Item key="taobaoglobal_cart">
                            <Link to={'/taobaoglobal_cart'}>
                                <AntdIcon.GoldOutlined type="gold" />
                                <span>{t('menu.taobaoglobalcart')}</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key={'orders-taobao-global'}>
                            <Link
                                className={'_menu-orders-taobao-global'}
                                to={`/orders-taobao-global`}
                            >
                                <AntdIcon.FileTextOutlined type="file-text" />
                                <span>{t('menu.orders')}</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="global_address">
                            <Link to={'/global_address'}>
                                <AntdIcon.GoldOutlined type="gold" />
                                <span>{t('taobaoGlobalCart.ChinaWarehouseAddress')}</span>
                            </Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                )}

                {(shipmentConfig.enable || !orderConfig.disable) && isShowSpecialMenu && (
                    <Menu.SubMenu
                        key="delivery-packages"
                        title={
                            <span>
                                <Link
                                    className={'_menu-delivery'}
                                    to={'/delivery'}
                                >
                                    <AntdIcon.RocketOutlined type="rocket" />
                                    <span>{t('menu.delivery')}</span>
                                </Link>
                            </span>
                        }
                    >
                        <Menu.Item key={'delivery'}>
                            <Link
                                className={'_menu-delivery-delivery'}
                                to={`/delivery`}
                            >
                                {t('menu.delivery_request')}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key={'packages'}>
                            <Link
                                className={'_menu-delivery-packages'}
                                to={`/packages`}
                            >
                                {t('menu.package')}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key={'lieferscheine'}>
                            <Link
                                className={'_menu-delivery-lieferscheine'}
                                to={`/lieferscheine`}
                            >
                                {t('menu.lieferscheine')}
                            </Link>
                        </Menu.Item>
                        {tenantConfig.m6Activated && (
                            <Menu.Item key={'delivery-notes'}>
                                <Link
                                    className={'_menu-delivery-notes'}
                                    to={`/delivery-notes`}
                                >
                                    {t('package.delivery_notes')}
                                </Link>
                            </Menu.Item>
                        )}
                    </Menu.SubMenu>
                )}

                {shipmentConfig.enable && (
                    <Menu.Item key="waybills">
                        <Link
                            className={'_menu-wayBills'}
                            to={'/waybills'}
                        >
                            <AntdIcon.BarcodeOutlined type="barcode" />
                            <span>{t('shipment.wayBill')}</span>
                        </Link>
                    </Menu.Item>
                )}
                <Menu.Item key="transaction">
                    <Link
                        className={'_menu-transaction'}
                        to={`${diorConfig ? '/profile/new-transactions' : '/profile/transactions'}`}
                    >
                        <AntdIcon.ScheduleOutlined type="schedule" />
                        <span>{t('menu.transaction')}</span>
                    </Link>
                </Menu.Item>

                {(shipmentConfig.enable || !orderConfig.disable) &&
                    tenantConfig.m6Activated &&
                    generalConfig.packageDamageEnabled && (
                        <Menu.Item key="package-damage">
                            <Link
                                className={'_menu-package-damage'}
                                to={'/package-damage'}
                            >
                                <AntdIcon.ProjectOutlined type="project" />
                                <span>{t('package_damage.title_page')}</span>
                            </Link>
                        </Menu.Item>
                    )}

                {generalConfig.peerPaymentEnabled && (
                    <Menu.Item key="peer-payments">
                        <Link
                            className={'_menu-peer-payments'}
                            to={'/peer-payments'}
                        >
                            <AntdIcon.TransactionOutlined type="transaction" />
                            <span>{t('peer_payment.title_page')}</span>
                        </Link>
                    </Menu.Item>
                )}

                {(shipmentConfig.enable || !orderConfig.disable) && isShowSpecialMenu && (
                    <Menu.Item key="tickets">
                        <Link
                            className={'_menu-tickets'}
                            to={'/tickets'}
                        >
                            <AntdIcon.AuditOutlined type="audit" />
                            <span>{t('menu.tickets')}</span>
                        </Link>
                    </Menu.Item>
                )}
                {generalConfig && generalConfig.customerWithdrawalEnabled && (
                    <Menu.Item key="request-withdraw-money">
                        <Link
                            className={'_menu-request-withdrawals'}
                            to={'/request-withdraw-money'}
                        >
                            <AntdIcon.MoneyCollectOutlined type="audit" />
                            <span>{t('request_withdraw.title')}</span>
                        </Link>
                    </Menu.Item>
                )}

                <Menu.Item key="faqs">
                    <Link
                        className={'_menu-tickets'}
                        to={'/profile/faqs'}
                    >
                        <AntdIcon.QuestionCircleOutlined type="question-circle" />
                        <span>{t('customer_info.frequently_asked_question')}</span>
                    </Link>
                </Menu.Item>
            </Menu>
        )
    }
}
