import React from "react";
import {withNamespaces} from "react-i18next";
import {Table} from "antd";
import Loading from "../Loading";
import NoData from  "../NoData";
import {moneyFormat} from "../../utils/stringUtils";
import moment from 'moment';

class Finance extends React.Component {

    /**
     *
     * @param amount
     * @returns {*}
     */
    formatAmount = (amount) => {
        let color = amount < 0 ? 'txt-color-red txt-size-h7 robotoregular txt-initial' : 'txt-color-green txt-size-h7 robotoregular txt-initial';
        return <span className={color}>{moneyFormat(amount)}</span>
    };

    render() {
        const {isLoadingFinance, finance, errFinance, t} = this.props;

        if (isLoadingFinance) {
            return <Loading height="30vh"/>;
        }
        let columns = [
            {
                title: t('shipment.timestamp'),
                dataIndex: 'timestamp',
                className: "_financial-time",
                key: 'timestamp',
                render: (date) => <span className='txt-size-h7 txt-color-black robotoregular'>{date ? moment(date).format('HH:mm DD/MM/YYYY') : '---'}</span>
            }, {
                title: t('shipment.value'),
                className: "_financial-amount",
                dataIndex: 'amount',
                key: 'amount',
                render: (amount) => (<span className={''}>{this.formatAmount(amount)}</span>)
            }, {
                title: t('shipment.type'),
                className: "_financial-type whitespace",
                dataIndex: 'type',
                key: 'type',
                render: (type) => {
                    return type && type.name ? <span className='txt-size-h7 txt-color-black robotoregular whitespace'>{type.name}</span> : <span className='txt-size-h8 txt-color-black robotoregular'>'---'</span>;
                }
            }, {
                title: t('shipment.content'),
                dataIndex: 'memo',
                className: "_financial-memo",
                key: 'memo',
                render: (memo, record) =>
                    <div>
                        <div>
                            <span className={"_financial-item-transaction-code txt-size-h8 txt-color-gray robotomedium txt-initial"}>{`${t('shipment.title_transaction_code')} : ${record.txid}`}</span>
                        </div>
                        <div>
                            <span className='txt-size-h7 txt-color-black robotoregular txt-initial'>{memo}</span>
                        </div>
                    </div>
            }];

        return <div>
            {finance && finance.length > 0 ?
                <Table
                    pagination={false}
                    dataSource={finance}
                    columns={columns}
                    className='listdeal pd25 pdt10 _financial-container'
                    rowClassName={"_order-financial-item"}
                /> :
                <NoData
                    error={errFinance}
                    onRecall={this.props.getFinance}
                />
            }
        </div>
    }
}

export default withNamespaces()(Finance);
