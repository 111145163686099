import PolimClient from "../utils/api/PolimClient";
import { LocalStore } from "../utils/LocalStore";

export default class CategoriesService {


    /**
     * lấy ra config thông tin liên hệ
     * @param cb
     */
    static getContactLogin(cb) {
        PolimClient.request({
            endpoint: `categories/articles?purpose=LOGIN`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra tỷ giá hối đoái
     * @param cb
     */
    static getCurrentExchangeRate(cb) {
        PolimClient.request({
            endpoint: `/customer/exchange_rates/current`,
            method: 'GET',
        }, cb)
    }

    static getCurrentExchangeRateMarketplace(marketplace, cb) {
        PolimClient.request({
            endpoint: `/customer/exchange_rates/current/${marketplace}`,
            method: 'GET',
        }, cb)
    }

    static getExchangeRatesBatch(body, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/customer/exchange_rates/batch`,
            method: 'POST',
            body: body,
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }

    static getAccountByUsername(cb) {
        PolimClient.request({
            endpoint: `/customer/wallet/accounts`,
            method: 'GET',
        }, cb)
    }

    /**
     * danh sách services của order
     * @param cb
     */
    static getOrderServices(cb) {
        PolimClient.request({
            endpoint: `/categories/order_services?size=1000`,
            method: 'GET',
        }, cb)
    }

    static getShipmentFeesConfigGroup(configGroupId, cb) {
        PolimClient.request({
            endpoint: `/categories/shipment_fees?configGroupId=${configGroupId}`,
            method: 'GET',
        }, cb)
    }

    static getOrderFeesConfigGroup(configGroupId, cb) {
        PolimClient.request({
            endpoint: `/categories/fees?configGroupId=${configGroupId}`,
            method: 'GET',
        }, cb)
    }

    /**
     * danh sách ngân hàng
     * @param cb
     */
    static getWithdrawBanks(cb) {
        PolimClient.request({
            endpoint: `/categories/banks`,
            method: 'GET',
        }, cb)
    }


    /**
     * danh sách loại giao dịch
     * @param cb
     */
    static getFinancialTypes(cb) {
        PolimClient.request({
            endpoint: `/categories/financial_types?size=1000`,
            method: 'GET',
        }, cb)
    }

    /**
     * danh sách nhóm services của order
     * @param cb
     */
    static getOrderServicesGroup(cb) {
        PolimClient.request({
            endpoint: `/categories/service_groups?size=1000`,
            method: 'GET',
        }, cb)
    }

    /**
     * danh sách trạng thái đơn
     * @param cb
     */
    static getOrderStatuses(cb) {
        PolimClient.request({
            endpoint: `/categories/public_order_statuses?size=1000&sort=position:asc`,
            method: 'GET',
        }, cb)
    }

    /**
     * danh sách nguồn hàng
     * @param cb
     */
    static getMarketplaces(cb) {
        PolimClient.request({
            endpoint: `/categories/marketplaces?size=1000`,
            method: 'GET',
        }, cb)
    }

    /**
     * danh sách trạng thái của package
     * @param cb
     */
    static getPackageStatuses(cb) {
        PolimClient.request({
            endpoint: `/categories/public_package_statuses?size=1000&sort=position:asc`,
            method: 'GET',
        }, cb)
    }

    /**
     * danh sách trạng thái của Waybill
     * @param cb
     */
    static getWaybillStatuses(cb) {
        PolimClient.request({
            endpoint: `/categories/customer_waybill_status?size=1000&sort=position:asc`,
            method: 'GET',
        }, cb)
    }

    /**
     * danh sách phí dịch vụ
     * @param cb
     */
    static getListFees(cb) {
        PolimClient.request({
            endpoint: `/categories/fees`,
            method: 'GET',
        }, cb)
    }

    /**
     * danh sách kho nhận theo địa chỉ đã chọn
     * @param idAddress
     * @param cb
     */
    static getSourceWarehouseByIdAddress(idAddress, cb) {
        PolimClient.request({
            endpoint: `/customer/warehouses/${idAddress}/sourceWarehouse`,
            method: 'GET',
        }, cb)
    }

    /**
     * danh sách phí dịch vụ ký gửi
     * @param cb
     */
    static getShipmentFees(cb) {
        PolimClient.request({
            endpoint: `/categories/shipment_fees`,
            method: 'GET',
        }, cb)
    }

    /**
     * danh sách dịch vụ ký gửi
     * @param cb
     */
    static getShipmentService(cb) {
        PolimClient.request({
            endpoint: `/categories/shipment_services?size=1000&sort=position:asc`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra danh sách hình thức giao
     * @param cb
     */
    static getListShippingMethods(cb) {
        PolimClient.request({
            endpoint: `/categories/shipping_methods`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra danh sách trạng thái yêu cầu giao
     * @param cb
     */
    static getShipmentStatuses(cb) {
        PolimClient.request({
            endpoint: `/categories/public_shipment_statuses?size=1000&sort=position:asc`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra danh sách ngôn ngữ
     * @param cb
     */
    static getLanguages(cb) {
        PolimClient.request({
            endpoint: `/categories/languages`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra danh sách tiền tệ
     * @param cb
     */
    static getCurrencies(cb) {
        PolimClient.request({
            endpoint: `/categories/currencies`,
            method: 'GET',
            notAuth: true
        }, cb)
    }

    /**
     * lấy ra danh sách
     * @param cb
     */
    static getWarehouseReceive(cb) {
        PolimClient.request({
            endpoint: `/categories/warehouses?size=1000&sort=enabled:desc,position:asc&received=true`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra danh sách
     * @param cb
     */
    static getWarehouseDelivery(cb) {
        PolimClient.request({
            endpoint: `/categories/warehouses?size=1000&sort=enabled:desc,position:asc&delivered=true`,
            method: 'GET',
        }, cb)
    }

    /**
     * Lấy danh sách trạng thái shipment parcel
     * @param cb
     */
    static getShipmentParcelStatus(cb) {
        PolimClient.request({
            endpoint: `/categories/public_parcel_statuses?sort=position:asc`,
            method: 'GET'
        }, cb)
    }

    /**
     * Lấy danh sách trạng menu ở footer
     * @param cb
     */
    static getMenuFooter(cb) {
        PolimClient.request({
            endpoint: `/categories/navigation_menus`,
            method: 'GET'
        }, cb)
    }

    /**
     * Lấy danh sách harmonized_commodity
     * @param cb
     */
    static getListHS(cb) {
        PolimClient.request({
            endpoint: `/categories/harmonized_commodity`,
            method: 'GET'
        }, cb)
    }

    /**
     * Lấy danh sách phương thức thanh toán
     * @param cb
     */
    static getPaymentMethods(cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/categories/payment_methods`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }

    /**
     * Lấy danh sách trạng thái peer payments
     * @param cb
     */
    static getPaymentStatus(cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/categories/peer_payment_public_status`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }


    static getInternalPaymentStatus(cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/categories/peer_payment_status`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }

    /**
     * Lấy danh sách tài khoản thanh toán
     * @param query
     * @param cb
     */
    static getPaymentAccounts(query, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/categories/payment_accounts${query || ''}`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }

    /**
     * Lấy danh sách harmonized_commodity
     * @param cb
     */
    static getShippingFees(configGroupId, shippingClass, cb) {
        PolimClient.request({
            endpoint: `/categories/shipping_fees?configGroupId=${configGroupId}&shippingClass=${shippingClass}`,
            method: 'GET'
        }, cb)
    }

    /**
     * Lấy danh sách harmonized_commodity
     * @param cb
     */
    static getShipmentShippingFees(configGroupId, shippingClass, cb) {
        PolimClient.request({
            endpoint: `/categories/shipment_shipping_fees?configGroupId=${configGroupId}&shippingClass=${shippingClass}`,
            method: 'GET'
        }, cb)
    }

    static getTenantConfigPayment(cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/categories/tenant/current`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }

    static getListBankAccount(cb) {
        PolimClient.request({
            endpoint: `/categories/bank-accounts`,
            method: 'GET',
        }, cb)
    }
    
    static getListBank(cb) {
        PolimClient.request({
            endpoint: `/categories/banks`,
            method: 'GET',
        }, cb)
    }


    /**
     * lấy ra danh sách couriers
     * @param cb
     */
    static getCouriers(cb) {
        PolimClient.request({
            endpoint: `/categories/couriers`,
            method: 'GET',
        }, cb)
    }
}
