import PolimClient from "../utils/api/PolimClient";

export default class TicketService {

    /**
     * lấy danh sách tickets theo
     * @param filter
     * @param cb
     */
    static getTickets(filter, cb) {
        PolimClient.request({
            endpoint: `/customer/canines/claims${filter || ''}`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy chi tiết tickets theo code
     * @param code
     * @param isPublic
     * @param cb
     */
    static getTicketByCode (code, isPublic, cb) {
        PolimClient.request({
            endpoint: `/claims/${code}${isPublic ? '?public=true' : ''}`,
            method: 'GET',
        }, cb)
    }

    /**
     * Update ticket bằng code
     * @param code
     * @param body
     * @param cb
     */
    static updateTicket(code, body, cb) {
        PolimClient.request({
            endpoint: `/claims/${code}`,
            method: "PATCH",
            body: body
        }, cb)
    }

    /**
     * Archive ticket
     * @param code
     * @param cb
     */
    static archiveTicket(code, cb) {
        PolimClient.request({
            endpoint: `/claims/${code}/archive`,
            method: "PUT",
        }, cb)
    }

    /**
     * Tạo mới Ticket
     * @param files
     * @param data
     * @param cb
     */
    static createTicket(files, data, cb) {
        let formData = new FormData();
        formData.append('attachments', files);
        formData.append('claim', new Blob([JSON.stringify(data)], {type: 'application/json'}));
        PolimClient.request({
            endpoint: `/claims`,
            method: "POST",
            file: files,
            body: formData
        }, cb)
    }

    /**
     * Lấy danh sách lý do khiếu nại
     * @param type // order or product
     * @param cb
     */
    static getReasonProject(type, isShipment, cb) {
        PolimClient.request({
            endpoint: `/category/canines/reasons?size=1000&ticketTypes=${type}`,
            method: "GET"
        }, cb)
    }

    /**
     * get detail
     * @param code
     * @param cb
     */
    static getDetailComplaint(code, cb) {
        PolimClient.request({
            endpoint: `/customer/canines/claims/${code}`,
            method: "GET"
        }, cb)
    }

    /**
     * get lịch sử state
     * @param cb
     * @param code
     */
    static getHistories(code,cb) {
        PolimClient.request({
            endpoint: `/customer/canines/claims/${code}/histories`,
            method: "GET"
        }, cb)
    }

    /**
     * Lấy danh sách trình độ người dùng
     * @param cb
     */
    static getCustomerLevels(cb) {
        PolimClient.request({
            endpoint: `/customerLevels?size=1000&sort=sort`,
            method: "GET"
        }, cb)
    }

    /**
     * Lấy danh sách nguồn hàng
     * @param cb
     */
    static getSupplySources(cb) {
        PolimClient.request({
            endpoint: `/supplySources?size=1000`,
            method: "GET"
        }, cb)
    }

    /**
     * Lấy danh sách ticket theo mã sản phẩm
     * @param productCode
     * @param owner
     * @param cb
     */
    static getTicketsByProduct(productCode, owner, cb) {
        PolimClient.request({
           endpoint: `/claims/order-product?product=${productCode}&owner=${owner}`,
           method: "GET"
        }, cb)
    }

    /**
     * Lấy danh sách chi tiết của lý do khiếu nại
     * @param id
     * @param cb
     */
    static getReasonDetail(id, cb) {
        PolimClient.request({
            endpoint: `/reasons/${id}/details`,
            method: "GET"
        }, cb)
    }

    /**
     * Lấy danh sách ticket theo mã sản phẩm
     * @param cb
     */
    static getProductTickets(cb) {
        PolimClient.request({
            endpoint: `/tickets/product-ticket`,
            method: "GET"
        }, cb)
    }

    /**
     * Lấy danh sách phương án xử lý
     * @param cb
     */
    static getSolution(isShipment = false, cb) {
        PolimClient.request({
            endpoint: `/category/canines/solutions?ticketTypes=${isShipment ? 'shipment' : 'order'}`,
            method: "GET"
        }, cb)
    }

     /**
     * Lấy danh sách phương án xử lý
     * @param cb
     */
     static getSolutionStatics(cb) {
        PolimClient.request({
            endpoint: `/category/canines/solutions?ticketTypes=order&ticketTypes=shipment`,
            method: "GET"
        }, cb)
    }

    /**
     * Lấy danh sách trạng thái xử lý của tickets
     * @param cb
     */
    static getStatesByProjectCode(cb) {
        PolimClient.request({
            endpoint: `/states?sort=sort`,
            method: 'GET'
        }, cb)
    }

    /**
     * Call Api update rating
     * @param data
     * @param code
     * @param cb
     */
    static updateRating(data, code, cb) {
        PolimClient.request({
            endpoint: `/customer/canines/claims/${code}/rating`,
            method: 'PATCH',
            body: data
        }, cb)
    }

    /**
     * Archive issue
     * @param issueCode
     * @param cb
     */
    static archiveIssue(code, cb) {
        PolimClient.request({
            endpoint: `/customer/canines/claims/${code}/archive`,
            method: "PATCH",
        }, cb)
    }


    /**
     * Lấy danh sách trạng thái xử lý của tickets
     * @param cb
     */
    static getPublicStates(cb) {
        PolimClient.request({
            endpoint: `/category/canines/ticket_statuses`,
            method: 'GET'
        }, cb)
    }

    // ===> TICKET BASIC <===
    /**
     * Lấy danh sách loại issues
     * @param cb
     */
    static getIssueTypes(cb) {
        PolimClient.request({
            endpoint: `/issueTypes`,
            method: 'GET'
        }, cb)
    }

    /**
     * Lấy danh sách sản phẩm liên quan
     * @param cb
     */
    static getRelatedProducts(cb) {
        PolimClient.request({
            endpoint: `/products`,
            method: 'GET'
        }, cb)
    }

    /**
     * Call API tạo rating
     * @param data
     * @param code
     * @param cb
     */
    static createRating(data, code, cb) {
        PolimClient.request({
            endpoint: `/tickets/${code}/rating`,
            method: 'POST',
            body: data
        }, cb)
    }


    /**
     * get decisions by claim code
     * @param code
     * @param cb
     */
    static getDecisions(code, cb) {
        PolimClient.request({
            endpoint: `/claims/${code}/decisions?sort=createdAt&order=desc`,
            method: 'GET',
        }, cb)
    }

    /**
     * create decisions by claim code
     * @param code
     * @param data
     * @param cb
     */
    static createDecisions(code, data, cb) {
        PolimClient.request({
            endpoint: `/claims/${code}/decisions`,
            method: 'POST',
            body: data
        }, cb)
    }


    /**
     * Call Api xóa tài khoản khiếu nại
     * @param code: Code of claim
     * @param id: id of claim finance
     * @param cb
     */
    static deleteFinancial(code, id, cb) {
        PolimClient.request({
            endpoint: `/claims/${code}/finances/${id}`,
            method: "DELETE"
        }, cb)
    }

    /**
     * delete decisions by claim code
     * @param code
     * @param id
     * @param cb
     */
    static deleteDecisions(code, id, cb) {
        PolimClient.request({
            endpoint: `/claims/${code}/decisions/${id}`,
            method: 'DELETE',
        }, cb)
    }

    /**
     * get Order relate for a claim
     * @param code
     * @param refresh
     * @param cb
     */
    static getOrder(code, refresh, cb) {
        PolimClient.request({
            endpoint: `/claims/${code}/related?refresh=${refresh}`,
            method: 'GET',
        }, cb)
    }

    /**
     * Call Api lấy danh sách tài khoản khiếu nại
     * @param code
     * @param cb
     */
    static getListFinancials(code, cb){
        PolimClient.request({
            endpoint: `/claims/${code}/finances?sort=createdAt&order=DESC`,
            method: 'GET'
        }, cb)
    }

    /**
     * Call Api lấy danh sách loại Finance
     * @param cb
     */
    static getFinanceTypes(cb) {
        PolimClient.request({
            endpoint: `/financeTypes`,
            method: 'GET'
        }, cb)
    }

    /**
     * Call Api lấy tổng tiền tài chính khiếu nại
     * @param code
     * @param cb
     */
    static getTotalFinancials(code, cb) {
        PolimClient.request({
            endpoint: `/claims/${code}/finances/total`,
            method: 'GET'
        }, cb)
    }

    /**
     * Call Api tạo 1 tài chính khiếu nại
     * @param data: Object
     * @param code
     * @param cb
     */
    static createClaimFinance(data, code, cb) {
        PolimClient.request({
            endpoint: `/claims/${code}/finances`,
            method: 'POST',
            body: data
        }, cb)
    }

    /**
     * Call Api tạo nhiều tài chính khiếu nại
     * @param data: Array of Object
     * @param code
     * @param cb
     */
    static createClaimFinances(data, code, cb) {
        PolimClient.request({
            endpoint: `/claims/${code}/finances/batch`,
            method: 'POST',
            body: data
        }, cb)
    }
    /**
     * Call Api create the conclusion of ticket
     * @param data
     * @param code
     * @param cb
     */
    static addConclusion(data, code, cb) {
        PolimClient.request({
            endpoint: `/tickets/${code}/conclusions`,
            method: "POST",
            body: data
        }, cb)
    }

    /**
     * Call api lấy danh sách lỗi xuất phát
     * @param cb
     */
    static getMistakes(cb) {
        PolimClient.request({
            endpoint: `/mistakes`,
            method: "GET"
        }, cb)
    }

    /**
     * Call api lấy danh sách người gây lỗi
     * @param filter
     * @param cb
     */
    static getOfficers(filter,cb) {
        PolimClient.request({
            endpoint: `/officers${filter?'?'+filter:''}`,
            method: "GET"
        }, cb)
    }
    /**
     * get list relative by claim
     * @param ticketCode
     * @param isPublic
     * @param page
     * @param size
     * @param cb
     */
    static getRelative(ticketCode, isPublic, page, size, cb) {
        PolimClient.request({
           endpoint: `/claims/${ticketCode}/similars?page=${page || 0}&size=${size || 25}&sort=createdAt&order=desc${isPublic ? '&public=true' : ''}`,
            method: 'GET'
        }, cb);
    }

    /**
     * Lấy dữ liệu conclusion
     * @param code
     * @param cb
     */
    static getConclusion(code, cb) {
        PolimClient.request({
            endpoint: `/tickets/${code}/conclusions`,
            method: 'GET'
        }, cb)
    }

    /**
     * call api edit conclusion
     * @param data
     * @param code
     * @param cb
     */
    static editConclusion(data, code, cb) {
        PolimClient.request({
            endpoint: `/tickets/${code}/conclusions`,
            method: 'PUT',
            body: data
        }, cb)
    }
    /**
     * Lấy lịch sử thay đổi trạng thái của ticket
     * @param code
     * @param cb
     */
    static getHistoryChangeState(code, cb) {
        PolimClient.request({
            endpoint: `/customer/canines/claims/${code}/histories`,
            method: 'GET'
        }, cb)
    }
    /**
     * call api export csv
     * @param filter
     * @param cb
     */
    static exportCSV(filter, cb) {
        PolimClient.request({
            endpoint: `/claims/export${filter}`,
            method: 'GET'
        }, cb)
    }

    /**
     * Call api get data Log DVKH
     * @param code
     * @param page
     * @param isPublic
     * @param cb
     */
    static getLogs(code, page, isPublic, cb) {
        PolimClient.request({
            endpoint: `tickets/${code}/activities?sort=timestamp&order=DESC&page=${page || 0}&size=25${isPublic ? `&public=true` : ''}`,
            method: "GET"
        }, cb)
    }

    /**
     * Call api lấy danh sách trạng thái xử lý của khiếu nại người bán
     * @param cb
     */
    static getMerchantStates(cb) {
        PolimClient.request({
            endpoint: `/merchantStates?size=1000`,
            method: "GET"
        }, cb)
    }

    /**
     * Call api lấy danh sách lý do khiếu nại người bán
     * @param cb
     */
    static getMerchantReasons(cb) {
        PolimClient.request({
            endpoint: `/merchantReasons?size=1000`,
            method: "GET"
        }, cb)
    }

    /**
     * lấy danh sách tickets theo project code
     * @param code
     * @param filter
     * @param cb
     */
    static getListMerchantsByTicketCode(code, filter, cb) {
        PolimClient.request({
            endpoint: `/claims/${code}/merchants${filter}`,
            method: 'GET',
        }, cb)
    }

    static getCommentsPosedon(code, page, cb) {
        PolimClient.request({
            endpoint: `/customer/comments/claims/${code}?sort=createdAt:DESC&page=${page}&size=25`,
            method: 'GET',
        }, cb)
    }

    static createCommentInClaim(code,body, cb){
        PolimClient.request({
            endpoint: `/customer/comments/claims/${code}`,
            method: 'POST',
            body: body
        }, cb)
    }

    static getAttachmentPosedon(code, cb) {
        PolimClient.request({
            endpoint: `/customer/comments/claims/${code}/attachments`,
            method: 'GET',
        }, cb)
    }
}
