import { Tooltip } from "antd"

const TooltipCommon = ({
    color,
    content,
    label,
    placement,
    ...props
}) => {
    return <Tooltip
        {...props}
        placement={placement}
        overlayClassName='tooltip-common'
        color={color}
        getPopupContainer={(element) => element?.parentElement}
        title={content}
    >
        {label}
    </Tooltip>
}

export default TooltipCommon