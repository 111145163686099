import React from 'react'
import DocumentTitle from "react-document-title"
import {LocalStore} from "../../utils/LocalStore";
import {Col, Image, Input, Radio, Row, Timeline} from 'antd'
import {withNamespaces} from "react-i18next";
import lodash from "lodash";
import OrderTrackingService from "../../services/tracking.service";
import {parseQueryStringToObject, quantityFormat} from "../../utils/stringUtils";
import AntdIconSVG, * as AntdIcon from "@ant-design/icons"
import {ReactComponent as iconEmpty} from "../../resources/images/tracking-empty.svg";
import moment from "moment";
import {getUrlQueryString, httpBuildQueryString} from "../../utils/helper";

class OrderTracking extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            type: 'order',
            data: null,
            code:''
        }
    }

    componentDidMount() {
        this.getData();
    }
    getData = () => {
        let queryString = getUrlQueryString();
        let queryObj = parseQueryStringToObject(queryString) || {};
        if(queryObj.type&&queryObj.code){
            this.setState({type: queryObj.type},()=>{
                this.onSearch(queryObj.code);
            });
        }
    }
    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     console.log('xxxxx');
    // }

    onChangeTypeOrder = (e) => {
        this.setState({type: e.target.value});
    }

    onSearch = (value) => {
        const {type, code} = this.state;
        if(!value){
            if(value===code) return;
            this.setState({
                data:null,
                code: ''
            });
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: ''
            });
        }else{
            this.setState({
                isLoading: true,
                code:value
            }, () => {
                let filter = {
                    type,
                    code: value
                }
                this.props.history.push({
                    pathname: this.props.location.pathname,
                    search: httpBuildQueryString(filter)
                });
                OrderTrackingService.orderTracking(type, {code: value}, (err, res) => {
                    if (!err) {
                        this.setState({
                            isLoading: false,
                            data: res
                        });
                    } else {
                        this.setState({
                            isLoading: false,
                        });
                    }
                })
            });
        }

    }

    render() {
        const {data, isLoading, type, code} = this.state;
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        return <DocumentTitle title={`Tracking order | ${currentProjectInfo ? currentProjectInfo.name : ''}`}>
            <div style={{backgroundColor: '#EDF1FF'}} className={'minheight100'}>
                <Row>
                    <Col lg={11} className={'pdr24'}>
                        <Image
                            style={{marginTop: 67, paddingLeft: 64}}
                            preview={false}
                            src={require('../../resources/images/order-tracking.png')}
                        />
                    </Col>
                    <Col lg={13} className={'pdr24 pdt120'}>
                        <div className={'txt-center'}>
                            <Image
                                width={234}
                                height={105}
                                preview={false}
                                src={currentProjectInfo.tenantConfig ? currentProjectInfo.tenantConfig.logoStandard : ''}
                            />
                        </div>
                        <div className={'txt-center'}><span className={'txt-size-h1 txt-color-blue'}>Theo dõi hành trình đơn</span></div>
                        <Row className={'mgt40'}>
                            <Radio.Group value={type} onChange={this.onChangeTypeOrder}>
                                <Radio value={'order'}>Đơn mua hộ</Radio>
                                <Radio value={'shipment'}>Đơn ký gửi</Radio>
                            </Radio.Group>
                        </Row>
                        <Row style={{marginTop: 24}}>
                            <Col lg={24}>
                                <Input.Search
                                    placeholder={'Nhập mã đơn, mã vận đơn tại đây'}
                                    allowClear
                                    enterButton="Theo dõi đơn hàng"
                                    size="large"
                                    loading={isLoading}
                                    value={code}
                                    onChange={(e)=>this.setState({code: e.target.value})}
                                    onSearch={this.onSearch}
                                />
                            </Col>
                        </Row>
                        {data&& data.code && <div><Col lg={18}>
                            <Row className={'bg-color-white dpl-flex justify-content-between pd24 mgt24 border-radius6'}>
                                <Col>
                                    <Row><span className={'robotoregular txt-size-h7 mgbt10'}>Mã đơn gửi</span></Row>
                                    <span className={'robotobold txt-size-h7'}>{lodash.get(data,'code','--')}</span>
                                </Col>
                                <Col>
                                    <Row><span className={'robotoregular txt-size-h7 mgbt10'}>Trọng lượng</span></Row>
                                    <span className={'robotobold txt-size-h7'}>{quantityFormat(lodash.get(data,'totalWeight',''))}kg</span>
                                </Col>
                                <Col>
                                    <Row><span className={'robotoregular txt-size-h7 mgbt10'}>Trạng thái</span></Row>
                                    <span className={'robotobold txt-size-h7'}>{lodash.get(data,'status','--')}</span>
                                </Col>
                            </Row>
                        </Col>
                            <Row className={'wptracking mgt40'}>
                                <Col lg={10}>
                                    <Timeline mode="left">
                                    {data.milestones.map((item,index)=>
                                        <Timeline.Item
                                        key={index}
                                        color={index === 0 ? 'green' : 'blue'}
                                        label={ moment(item.timestamp).format('HH:mm DD/MM')}
                                        dot={
                                        index === 0 ? <AntdIcon.ClockCircleOutlined style={{ fontSize: '24px'}}/> : ''
                                    }>
                                        <span className='txt-color-gray txt-size-h7 robotoregular pdr5'>{item.status}</span>
                                        </Timeline.Item>)}
                                    </Timeline>
                                </Col>
                            </Row>
                        </div>}
                        {((code&&!isLoading&&!data) || (data&&!data.code)) &&
                        <Col lg={24} >
                            <Row className={'dpl-flex align-items-center justify-content-center mgt50'}><AntdIconSVG component={iconEmpty} style={{fontSize: 60}}/></Row>
                            <Row className={'dpl-flex align-items-center justify-content-center mgt20'}>
                                <span style={{color:'#BDBDBD', fontSize: 14}}>Chúng tôi không tìm thấy đơn hàng của bạn</span>
                            </Row>
                            <Row className={'dpl-flex align-items-center justify-content-center'}>
                                <span style={{color:'#BDBDBD', fontSize: 14}}>Mã đơn hàng không hợp lệ hoặc đã hết hạn. Vui lòng kiểm tra lại mã đơn hàng bạn đã nhập</span>
                            </Row>
                        </Col>}
                    </Col>
                </Row>
            </div>
        </DocumentTitle>;
    }
}

export default withNamespaces()(OrderTracking);
