import React from 'react';
import {Tooltip, Progress, Modal} from 'antd'
import * as AntdIcon from "@ant-design/icons"

import {withNamespaces} from "react-i18next";
import {LocalStore} from "../../utils/LocalStore";
import {
    quantityFormat, moneyFormat, moneyCeil
} from "../../utils/stringUtils";
import Loading from "../Loading";
import iconLevelMaxVip from "../../resources/images/icon-max-level-vip.png";
import iconNoLevel from "../../resources/images/icon-no-level.png";
import UserService from "../../services/user.service";
import CategoriesService from "../../services/categories.service";
import _ from "lodash";

class VipLevelBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentLoggedUser: {}
        };
    }

    // scroll top khi bắt đầu load trang
    componentDidMount() {
        this.getCustomerLevel();
        this.getCustomerDiscount();
        this.getListFees();
    }

    /**
     * lấy ra danh sách trạng thái đơn
     */
    getCustomerLevel = () => {
        this.setState({isLoadLevel: true}, () => {
            UserService.getCustomerLevels((err, res, headers) => {
                if (!err) {
                    this.setState({
                        customerLevel: res,
                        isLoadLevel: false
                    });
                } else {
                    this.setState({
                        isLoadLevel: false
                    });
                }
            })
        })
    };

    /**
     * lấy ra danh sách các discount
     */
    getCustomerDiscount = () => {
        this.setState({isLoadDiscount: true}, () => {
            UserService.getCustomerDiscount((err, res, headers) => {
                if (!err) {
                    this.setState({
                        customerDiscount: res,
                        isLoadDiscount: false
                    });
                } else {
                    this.setState({
                        isLoadDiscount: false
                    });
                }
            })
        })
    };

    /**
     * lấy ra danh sách services
     */
    getListFees = () => {
        CategoriesService.getListFees((err, res, headers) => {
            if (!err) {
                this.setState({
                    listFees: _.sortBy(res.filter(x => !x.additional), 'position'),
                });
            }
        })
    };

    renderModalDiscount = () => {
        const {customerDiscount = {}, customerLevel = [], listFees = []} = this.state;
        const {t} = this.props;
        const currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {};
        const currentLevel = currentLoggedUser.customerLevel;
        const nextLevelIndex = currentLevel ? customerLevel.findIndex(x => x.id === currentLevel.id) + 1 : 0;
        const nextLevel = customerLevel[nextLevelIndex];
        const discounts = customerDiscount.discounts || [];
        const emdLevels = customerDiscount.emdLevels || [];
        let currentLevelDiscount = [];
        let currentLevelEmdLevels = '';
        if (currentLevel) {
            currentLevelDiscount = discounts.filter(x => x.customerLevelId === currentLevel.id);
            currentLevelEmdLevels = emdLevels.find(x => x.customerLevelId === currentLevel.id);
        }
        let nextLevelDiscount = [];
        let nextLevelEmdLevels = '';
        if (nextLevel) {
            nextLevelDiscount = discounts.filter(x => x.customerLevelId === nextLevel.id);
            nextLevelEmdLevels = emdLevels.find(x => x.customerLevelId === nextLevel.id);
        }
        let currentValue = '---';
        return <Modal
            className="modal-no-header"
            title={t('customer_info.level_preferential_detail')}
            visible={this.state.visible}
            footer={false}
            onCancel={() => this.setState({visible : false})}
        >
            <div className="dpl-flex">
                <div className="flex-none">
                    <div className="customer-table-head border-1px-solid bd-color-gray3 bg-color-gray border-right-0x" />
                    <div className="customer-table-row bd-color-gray3 border-bottom-1x">
                        {t('customer_info.deposit')}
                    </div>
                    {listFees.map((x, index) => <div className="customer-table-row" key={index}>
                        {x.name}
                    </div>)}
                </div>
                {
                    currentLevel && <div className="width-100-percent">
                        <div className={`customer-table-head bold txt-right bd-color-gray3 bg-color-gray border-1px-solid ${nextLevel && 'border-right-0x'} `}>
                            {currentLevel.name}
                        </div>
                        <div className="customer-table-row bold txt-right bd-color-gray3 border-left-1x border-bottom-1x">
                            {currentLevelEmdLevels ? `${currentLevelEmdLevels.emdPercent}%` : '---'}
                        </div>
                        {listFees.map((x, index) => {
                            currentValue = currentLevelDiscount.find(y => y.feeCode === x.code);
                            if (!currentValue) return <div className="customer-table-row bd-color-gray3 border-left-1x bold txt-right" key={index}>---</div>;
                            return <div className="customer-table-row bold bd-color-gray3 border-left-1x txt-right" key={index}>
                                {currentValue.discountType === 'PERCENT' ? quantityFormat(currentValue.discountValue) + '%' : moneyFormat(moneyCeil(currentValue.discountValue))}
                            </div>
                        })}
                    </div>
                }
                {
                    nextLevel && <div className="width-100-percent">
                        <div className="customer-table-head border-1px-solid bd-color-gray3 bg-color-gray txt-right">
                            {nextLevel.name}
                        </div>
                        <div className="customer-table-row txt-right bd-color-gray3 border-left-1x border-bottom-1x">
                            {nextLevelEmdLevels ? `${nextLevelEmdLevels.emdPercent}%` : '---'}
                        </div>
                        {listFees.map((x, index) => {
                            currentValue = nextLevelDiscount.find(y => y.feeCode === x.code);
                            if (!currentValue) return <div className="customer-table-row txt-right bd-color-gray3 border-left-1x" key={index}>---</div>;
                            return <div className="customer-table-row txt-right bd-color-gray3 border-left-1x" key={index}>
                                {currentValue.discountType === 'PERCENT' ? quantityFormat(currentValue.discountValue) + '%' : moneyFormat(moneyCeil(currentValue.discountValue))}
                            </div>
                        })}
                    </div>
                }
            </div>
        </Modal>
    };

    render() {
        const {customerLevel = [], isLoadLevel} = this.state;
        const {t} = this.props;
        const currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {};
        const currentLevel = currentLoggedUser.customerLevel;
        const nextLevelIndex = currentLevel ? customerLevel.findIndex(x => x.id === currentLevel.id) + 1 : 0;
        const nextLevel = customerLevel[nextLevelIndex];

        let currentPecent = 0;

        if (nextLevel) {
            currentPecent = (currentLoggedUser.rewardPoint*100) / nextLevel.minPoint
        }

        if (isLoadLevel) return <Loading/>;

        if (customerLevel.length === 0) return <div />;

        if (!nextLevel) return <div className="customer-level-box align-items-center mgbt20">
            <div className="customer-current-level">
                {
                    currentLevel && <>
                        <img src={currentLevel.imageUrl} alt=''/>
                        <div className="customer-current-level-text bold mgt5">
                            {currentLevel.name}
                        </div>
                    </>
                }
            </div>
            <div className="customer-level-progress-box">
                <div className="customer-level-progress-box-text txt-color-white txt-size-h3 text-center">
                    <div>{t('customer_info.congratulation_on_highest_level')}</div>
                    {/*<div className="txt-size-h6">Nội dung ở đây content manager viết tiếp</div>*/}
                </div>
            </div>
            <div className="customer-next-level">
                <img src={iconLevelMaxVip} alt=''/>
            </div>

            <div className="_btn-show-discount btn-show-discount cursor-pointer  txt-color-white txt" onClick={() => this.setState({visible: true})}>
                {t('customer_info.detail')}
            </div>
            {this.renderModalDiscount()}
        </div>;

        return <div className="customer-level-box align-items-end mgbt20">
            <div className="customer-current-level">
                {
                    currentLevel ? <>
                            <img src={currentLevel.imageUrl} alt=''/>
                            <div className="customer-current-level-text bold mgt5">
                                {currentLevel.name}
                            </div>
                        </>
                        :
                        <>
                            <img src={iconNoLevel} alt=''/>
                            <div className="customer-current-level-text bold mgt5">
                                {t('orderDetail.no_level_yet')}
                            </div>
                        </>
                }
            </div>
            <div className="customer-level-progress-box">
                <div className="customer-level-progress-box-text txt-color-white txt-size-h2">
                    <span className="_current-level-rewardPoint bold">{quantityFormat(currentLoggedUser.rewardPoint)}</span> / <span className="_current-level-next-level-point txt-size-h4">{nextLevel && quantityFormat(nextLevel.minPoint)}</span>
                    <Tooltip
                        className="mgl10 txt-size-h5"
                        title={t('customer_info.updated_level')}
                    >
                        <AntdIcon.InfoCircleOutlined type="info-circle" />
                    </Tooltip>
                </div>
                <Progress className="customer-level-progress mgbt5" showInfo={false} percent={currentPecent} strokeColor={'#fff'}/>
            </div>
            <div className="customer-next-level">
                {
                    nextLevel
                    && <>
                        <img src={nextLevel.imageUrl} alt=''/>
                        <div className="customer-next-level-text bold mgt5">
                            {nextLevel.name}
                        </div>
                    </>
                }
            </div>

            <div className="_btn-show-discount btn-show-discount cursor-pointer txt-color-white txt" onClick={() => this.setState({visible: true})}>
                {t('customer_info.detail')}
            </div>
            {this.renderModalDiscount()}
        </div>
    }
}
export default withNamespaces()(VipLevelBox)
