/**
 * create by Taobao dev team 18/1/2018
 */
import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'
import _, { first, isArray, isEmpty } from 'lodash'
import lodash from 'lodash'
import ProgressBar from '../Spinner/ProgressBar'
import { LocalStore } from '../../utils/LocalStore'
import UserServices from '../../services/user.service'
import { Link } from 'react-router-dom'
import { Avatar, Button, Checkbox, Col, Divider, Layout, Modal, Popover, Row, Table } from 'antd'
import * as AntdIcon from '@ant-design/icons'
import ModalCommon from '../commons/Modal'
import { withNamespaces } from 'react-i18next'

import Notifications from '../Notifications'
// import Notifications from '../Notifications/Notification_bak'
import TotalSkusInCart from '../Carts/TotalSkusInCart'
import Balance from '../Profile/Balance'
import Language from '../Language'
import MenuContainer from '../Menu'
import CategoriesService from '../../services/categories.service'
import { linkify, moneyFormat } from '../../utils/stringUtils'
import moment from 'moment'
import DepositModal from '../DepositModal'

const PERCENT_COMPLETE = 100

class MainLayout extends Component {
    static defaultProps = {
        isLoadingPopup: false,
        isFocusSubmit: false,
    }

    constructor(props) {
        super(props)
        this.isUnmounted = false
        this.state = {
            baseCurrency: 'CNY',
            targetCurrency: 'VND',
            collapsed: true,
            currentLoggedUser: {},
            percent: -1,
            autoIncrement: false,
            intervalTime: 200,
            isModalOpen: false,
            isDepositModal: false,
            isPopupOpen: false,
            // isMobile: window.innerWidth < 981,
            // isTablet: window.innerWidth < 1025,
        }
        // this.TotalSkusInCart = React.createRef();
        this.BalanceComponent = React.createRef()
    }

    // scroll top khi bắt đầu load trang
    componentDidMount() {
        window.scrollTo(0, 0)
        const articleList = LocalStore.getInstance().read('articleList')

        const articleValid = purpose => {
            const result = _.sortBy(articleList, 'publishDate').reverse()
            return _.filter(result, article => article.purpose === purpose) || []
        }

        const newArticleList = articleValid('NEWS')
        const articleFooter = articleValid('FOOTER')
        const currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {}
        const configGroup = lodash.get(currentLoggedUser, 'customerGroup.configGroup')
        const marketpalaces = LocalStore.getInstance().read('marketpalaces')
        const firstMarketplace = first(marketpalaces)

        let markupRateViewTemplate = configGroup?.markupRateViewTemplate
        if (markupRateViewTemplate) {
            try {
                markupRateViewTemplate = JSON.parse(markupRateViewTemplate)
                markupRateViewTemplate = lodash.sortBy(markupRateViewTemplate, ['price'])
            } catch (error) {
                markupRateViewTemplate = []
            }
        }

        if (!isEmpty(markupRateViewTemplate)) this.getExchangeRateByMarketplace(firstMarketplace?.code)
        // window.addEventListener('resize', this.windowResize);
        this.setState({
            markupRateViewTemplate,
            configGroup,
            currentLoggedUser: currentLoggedUser,
            articleList: newArticleList,
            currentArticle: _.first(newArticleList),
            articleFooter: _.first(articleFooter),
            articleSeen: LocalStore.getInstance().read('articleSeen'),
            articleShow: LocalStore.getInstance().read('articleShow'),
        })

        if (typeof LocalStore.getInstance().read('currentProjectInfo') === 'object') {
            this.setState(
                {
                    currentProjectInfo: LocalStore.getInstance().read('currentProjectInfo') || {},
                },
                () => {
                    this.getMenuFooter()
                }
            )
        }
    }

    getMenuFooter = () => {
        CategoriesService.getMenuFooter((err, res) => {
            if (!err) {
                this.setState({
                    menuFooter: res || [],
                })
            }
        })
    }

    componentWillUnmount() {
        this.isUnmounted = true
        // window.removeEventListener('resize', this.windowResize);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showProgressBar !== this.props.showProgressBar) {
            // hiển thị progress bar
            if (this.props.showProgressBar === true) {
                this.startWithAutoIncrement()
            } else {
                // this.stopWithAutoIncrement();
                this.setPercent(PERCENT_COMPLETE)
            }
        }
    }

    // /**
    //  * khi window resize
    //  */
    // windowResize = () => {
    //     const windowWidth = window.innerWidth;
    //     if (!this.isUnmounted) this.setState({
    //         isMobile: windowWidth < 981,
    //         isTablet: windowWidth < 1025
    //     })
    // };

    /**
     * hiển thị progressbar
     **/
    startWithAutoIncrement = () => {
        this.setState({
            percent: 0,
            autoIncrement: true,
            intervalTime: Math.random() * 150,
        })
    }

    /**
     * Dừng progressbar
     **/
    stopWithAutoIncrement = () => {
        setTimeout(() => {
            this.setState({
                percent: -1,
                autoIncrement: false,
                intervalTime: 150,
            })
        }, 500)
    }

    setPercent = percent => {
        this.setState(
            {
                percent: percent,
            },
            () => {
                if (percent === PERCENT_COMPLETE) {
                    this.stopWithAutoIncrement()
                }
            }
        )
    }

    logout = () => {
        UserServices.logout()
    }

    getExchangeRateByMarketplace = (marketplace = '') => {
        CategoriesService.getCurrentExchangeRateMarketplace(marketplace.toString(), (err, res, header) => {
            if (!err) {
                this.setState({
                    exchangeRateByMarketplaceByHeader: { ...res, codeMarketplace: marketplace },
                })
            }
        })
    }

    renderDropDownAvatar = depositWizard => {
        const { t } = this.props
        return (
            <div className="popover-profile-content">
                <div className="hover:bg-gallery-200 py-3 px-4  hover:rounded-t-2xl">
                    <Link
                        to={'/profile'}
                        className="_popover-profile_profile text-sm popover-profile-content-item text-grey-200 "
                    >
                        <i className="fa-regular fa-user mr-3 text-sm"></i>
                        {t('customer_info.personal_info')}
                    </Link>
                </div>

                {/*<a className="_popover-profile_vippoint text-sm popover-profile-content-item text-grey-200 hover:bg-gallery-100 dpl-block mgbt10 pdl15 pdr20" href="/"><Icon component={iconVipPoint} style={{fontSize: 12}} className="mgr10"/> Điểm tích lũy</a>*/}
                {/* <a className="_popover-profile_charge text-sm popover-profile-content-item text-grey-200 hover:bg-gallery-100 dpl-block pdl15 pdr20" href="/"><Icon component={iconCharge} style={{fontSize: 12}} className="mgr10"/> Nạp tiền</a> */}
                {depositWizard && (
                    <>
                        <Divider className="my-0" />
                        <span
                            onClick={() => this.setState({ isDepositModal: true, isPopupOpen: false })}
                            className="text-grey-200 hover:bg-gallery-200 text-sm cursor-pointer dpl-block py-3 px-4 "
                        >
                            <i className="fa-solid fa-yen-sign mr-3 text-sm w-[14px]"></i>
                            <span>{t('header.deposit')}</span>
                        </span>
                    </>
                )}
                <>
                    <Divider className="my-0" />
                    <Link
                        to={'/statistics'}
                        className="text-grey-200 hover:bg-gallery-200 text-sm cursor-pointer dpl-block py-3 px-4 "
                    >
                        <i className="fa-regular fa-chart-simple mr-3 text-sm w-[14px]"></i>
                        <span>{t('header.statistics')}</span>
                    </Link>
                </>
                <Divider className="my-0" />
                <span
                    onClick={this.logout}
                    className="_logout-btn popover-profile-content-item text-grey-200 hover:bg-gallery-200 text-sm cursor-pointer dpl-block py-3 px-4 hover:rounded-b-2xl"
                >
                    <i className="fa-regular fa-arrow-right-from-bracket mr-3 text-sm"></i>
                    {t('login.logout_btn')}
                </span>
            </div>
        )
    }

    renderMenu = () => {
        const { menuFooter = [] } = this.state

        return (
            <div className="max-h-80 overflow-auto">
                {menuFooter.map((item, index) => {
                    return (
                        <div key={index}>
                            {index !== 0 && (
                                <Divider
                                    key={`device${index}`}
                                    className="m-0"
                                />
                            )}
                            <div
                                className={`px-4 py-3 cursor-pointer hover:bg-gallery-200 ${index === 0 && 'rounded-t-2xl'} ${
                                    index === menuFooter?.length - 1 && 'rounded-b-2xl'
                                }`}
                            >
                                <a
                                    href={item.href}
                                    target={item.target || '_blank'}
                                    key={index}
                                    className={`cursor-pointer dpl-block txt-color-gray`}
                                >
                                    <div className={'txt-size-h8 robotoregular'}>{item.name}</div>
                                </a>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    renderModalArticle = () => {
        const { t } = this.props
        const { articleList = [], articleSeen, currentArticle = {}, articleShow, notShowAgain } = this.state

        return (
            <Modal
                className=""
                width={800}
                title={
                    <div>
                        <div>{currentArticle.title}</div>
                        <div className="txt-size-h8 txt-color-gray2">
                            {moment(currentArticle.publishDate).format('HH:mm DD/MM/YYYY')}
                        </div>
                    </div>
                }
                open={!articleSeen && articleList.length > 0 && articleShow}
                onCancel={() => {
                    if (notShowAgain) {
                        this.setState({ articleSeen: true }, () => {
                            LocalStore.getInstance().save('articleSeen', true)
                        })
                    } else {
                        this.setState({ articleShow: false }, () => {
                            LocalStore.getInstance().save('articleShow', false)
                        })
                    }
                }}
                footer={[
                    <Button
                        onClick={() => {
                            if (notShowAgain) {
                                this.setState({ articleSeen: true }, () => {
                                    LocalStore.getInstance().save('articleSeen', true)
                                })
                            } else {
                                this.setState({ articleShow: false }, () => {
                                    LocalStore.getInstance().save('articleShow', false)
                                })
                            }
                        }}
                        className="pdl30 pdr30"
                        type="primary"
                    >
                        {t('button.close')}
                    </Button>,
                ]}
            >
                <div className="carousel-article">
                    {articleList.map((item, index) => {
                        return (
                            <div
                                className={`item-carousel-article ${currentArticle.id === item.id && 'active'}`}
                                key={index}
                            >
                                <div
                                    className={`box-article txt-color-black txt-size-h7 robotoregular white-space-pre-wrap break-word max-width-100pc`}
                                    dangerouslySetInnerHTML={{ __html: linkify(item.content) }}
                                />
                            </div>
                        )
                    })}
                    <div className="carousel-article-control-bar">
                        {articleList.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => this.setState({ currentArticle: item })}
                                className={`cursor-pointer carousel-article-control-bar-item ${
                                    currentArticle.id === item.id && 'active'
                                }`}
                            />
                        ))}
                    </div>
                </div>
                <div className="dpl-flex justify-content-between align-items-center mgt20">
                    <Checkbox
                        onChange={checked => this.setState({ notShowAgain: checked })}
                        className="mgr10"
                    >
                        {t('message.not_show_again')}
                    </Checkbox>
                </div>
            </Modal>
        )
    }

    changeVersion = () => {
        let split = window.location.href.split(window.location.origin)
        LocalStore.getInstance().save('currentVer', 'mobile')
        if (split[1].indexOf('/m') !== 0) {
            window.location.href = window.location.origin + '/m' + split[1]
        }
    }

    renderFooter = () => {
        const { t } = this.props
        const { currentProjectInfo = {}, articleFooter } = this.state
        const generalConfig = currentProjectInfo.tenantConfig ? currentProjectInfo.tenantConfig.generalConfig : {}
        return (
            <div className="max-w-full">
                {articleFooter && articleFooter.content !== '' && generalConfig.customFooterEnabled ? (
                    <div className="p-4 footer-customer">
                        <div dangerouslySetInnerHTML={{ __html: articleFooter.content }}></div>
                    </div>
                ) : (
                    <div>
                        <div className="pdl30 pdr30 pdt20 pdbt20">
                            <div>
                                <Row gutter={50}>
                                    <Col span={6}>
                                        <div className="footer-item-head">
                                            <AntdIcon.MailOutlined
                                                type="mail"
                                                className="pdr5"
                                            />{' '}
                                            {t('footer.contact')}
                                        </div>

                                        {!!generalConfig.emailContact && (
                                            <div className="dpl-flex pdbt5">
                                                <div className="txt-color-gray">Email:</div>
                                                <div className="txt-color-black pdl5">{generalConfig.emailContact}</div>
                                            </div>
                                        )}

                                        {Array.isArray(generalConfig.phoneContacts) && generalConfig.phoneContacts.length > 0 && (
                                            <div className="dpl-flex pdbt5">
                                                <div className="txt-color-gray">{t('footer.phone')}:</div>
                                                <div className="txt-color-black pdl5">
                                                    {generalConfig.phoneContacts.map((item, index) => (
                                                        <div
                                                            key={index}
                                                            className="pdbt3 line-underline"
                                                        >
                                                            {item}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </Col>

                                    <Col span={6}>
                                        <div className="footer-item-head">
                                            <AntdIcon.HomeOutlined
                                                type="home"
                                                className="pdr5"
                                            />{' '}
                                            {t('footer.address')}
                                        </div>
                                        <div className="dpl-flex txt-color-black txt-size-h7 pdbt5">
                                            {/*<Icon type="arrow-right" className="flex-none pdt3"/>*/}
                                            <div className="">{lodash.get(generalConfig, 'addressContact', '')}</div>
                                        </div>
                                    </Col>

                                    <Col span={6}>
                                        <div className="footer-item-head">
                                            <AntdIcon.BankOutlined
                                                type="bank"
                                                className="pdr5"
                                            />{' '}
                                            {t('footer.bank_info')}
                                        </div>

                                        <div className="dpl-flex txt-color-black txt-size-h7 pdbt5">
                                            {/*<Icon type="arrow-right" className="flex-none pdt3"/>*/}
                                            <div className="">{lodash.get(generalConfig, 'bankAccount', '')}</div>
                                        </div>
                                    </Col>

                                    <Col
                                        span={6}
                                        className="txt-center pdt20"
                                    >
                                        <span
                                            onClick={this.changeVersion}
                                            className="_btn-change-version ant-btn ant-btn-primary bg-color-white txt-color-blue cursor-pointer position-re pdr20 pdl20"
                                        >
                                            <AntdIcon.MobileOutlined type="mobile" /> {t('footer.change_mobile_version')}
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                )}

                {articleFooter && articleFooter.content !== '' && generalConfig.customFooterEnabled && (
                    <div className="pd10 text-center">
                        <span
                            onClick={this.changeVersion}
                            className="_btn-change-version ant-btn ant-btn-primary bg-color-white txt-color-blue cursor-pointer position-re pdr20 pdl20"
                        >
                            <AntdIcon.MobileOutlined type="mobile" /> {t('footer.change_mobile_version')}
                        </span>
                    </div>
                )}
                <div className="pd10 text-center footer-bottom">
                    Copyright © 2019{' '}
                    {currentProjectInfo && currentProjectInfo.domain ? currentProjectInfo.domain : 'nhaphang.com'} -{' '}
                    {currentProjectInfo && currentProjectInfo.description}. Powered By Gobiz.
                    {/*<a*/}
                    {/*    onClick={this.changeVersion}*/}
                    {/*    className="_btn-change-version ant-btn ant-btn-primary bg-color-white txt-color-blue cursor-pointer position-re pdr20 pdl20 mgl15"*/}
                    {/*>*/}
                    {/*    <Icon type="mobile" /> {t('footer.change_mobile_version')}*/}
                    {/*</a>*/}
                </div>
            </div>
        )
    }

    handleModal = () => {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        })
    }

    render() {
        const { t, title, children, headerTitle, noFooter, balance, totalSkus, exchangeRateObj } = this.props
        const {
            percent,
            autoIncrement,
            intervalTime,
            currentLoggedUser,
            currentProjectInfo = {},
            collapsed,
            menuFooter = [],
            articleFooter,
            markupRateViewTemplate,
            configGroup,
            exchangeRateByMarketplaceByHeader,
            isDepositModal,
            isPopupOpen,
        } = this.state
        const localStore = LocalStore.getInstance()

        let exchangeRate = exchangeRateObj ? [exchangeRateObj] : localStore.read('exchangeRates') || []
        const rate = exchangeRate.find(item => item?.exchange === currentProjectInfo?.tenantConfig?.defaultCurrency)
        const tenantConfig = currentProjectInfo.tenantConfig || {}
        const orderConfig = tenantConfig.orderConfig || {}
        const shipmentConfig = tenantConfig.shipmentConfig || {}

        let markupRateViewTemplateValid = isArray(markupRateViewTemplate) && markupRateViewTemplate?.every(i => i?.price !== null)

        const homePageLink = _.get(currentProjectInfo, 'tenantConfig.internalLink.poseidon.landing')
        const depositWizard = _.get(currentProjectInfo, 'tenantConfig.generalConfig.depositWizard')
        let textRender = ``
        if (markupRateViewTemplate && isArray(markupRateViewTemplate) && rate && rate?.rate) {
            const newRate = exchangeRateByMarketplaceByHeader?.rate

            textRender = `${moneyFormat(1, exchangeRateByMarketplaceByHeader?.base)} = `
            let scope = configGroup?.markupRateScope
            let priceFirst = _.first(markupRateViewTemplate)?.price
            let priceLast = _.last(markupRateViewTemplate)?.price
            if (typeof scope === 'string' && scope.toLowerCase() === 'rate') {
                textRender += `${moneyFormat(newRate + newRate * parseFloat(priceLast / 100))} - ${moneyFormat(
                    newRate + newRate * parseFloat(priceLast / 100)
                )}`
            }

            if (typeof scope === 'string' && scope.toLowerCase() === 'value') {
                textRender += `${moneyFormat(newRate + parseFloat(priceFirst))} - ${moneyFormat(newRate + parseFloat(priceLast))}`
            }
        }

        const rateFind = Array.isArray(exchangeRate) ? exchangeRate.find(x => x.exchange === tenantConfig.defaultCurrency) : null

        const columns = [
            {
                title: t('peer_payment.range'),
                dataIndex: 'fromAmount',
                key: 'fromAmount',
            },
            {
                title: t('peer_payment.exchage_rate'),
                dataIndex: 'price',
                key: 'price',
                className: 'txt-right',
                render: (text, row) => {
                    let data = 0
                    if (rateFind) {
                        if (configGroup?.markupRateScope?.toLowerCase() === 'value' && text) {
                            data = rateFind.rate + parseFloat(text)
                        }

                        if (configGroup?.markupRateScope?.toLowerCase() === 'rate' && text) {
                            data = rateFind.rate + rateFind.rate * (parseFloat(text) / 100)
                        }
                    }
                    return moneyFormat(data)
                },
            },
        ]
        return (
            <DocumentTitle
                title={`${title ? title + ' | ' : ''} ${
                    currentProjectInfo && currentProjectInfo.name ? currentProjectInfo.name : 'Loading...'
                }`}
            >
                <Layout className={`${collapsed ? 'menu-collapsed' : ''} min-width-page position-re`}>
                    <Layout.Sider
                        trigger={null}
                        collapsible={true}
                        collapsed={collapsed}
                        className={'sidebarleft'}
                        onMouseOver={() =>
                            this.setState({
                                collapsed: false,
                            })
                        }
                        onMouseLeave={() =>
                            this.setState({
                                collapsed: true,
                            })
                        }
                    >
                        <div className="sidebarleft__logo">
                            <a
                                href={currentProjectInfo.domain ? `//${currentProjectInfo.domain}` : '#'}
                                target="_blank"
                                className="sidebarleft__logo_menu"
                                rel="noreferrer"
                            >
                                <img src={currentProjectInfo.tenantConfig ? currentProjectInfo.tenantConfig.logoLite : ''} alt=''/>
                            </a>
                            <a
                                href={currentProjectInfo.domain ? `//${currentProjectInfo.domain}` : '#'}
                                target="_blank"
                                className="sidebarleft__logo_collapsed dpl-none"
                                rel="noreferrer"
                            >
                                <img src={currentProjectInfo.tenantConfig ? currentProjectInfo.tenantConfig.logoLite : ''} alt=''/>
                            </a>
                        </div>
                        <MenuContainer
                            {...this.props}
                            collapsed={collapsed}
                        />
                    </Layout.Sider>
                    <div
                        className="ant-layout"
                        id={'scrollLayoutElement'}
                        ref={ref => (this.scrollLayoutElement = ref)}
                    >
                        <Layout.Header className={'header mgbt20'}>
                            <div className={'container-common bg-white'}>
                                <div className={'dpl-flex justify-content-between mgr20'}>
                                    <div className={'header__left dpl-flex pdt20'}>
                                        <h2 className={'txt-size-h2 txt-color-black robotomedium line-height134'}>
                                            {headerTitle}
                                        </h2>
                                    </div>
                                    <div className={'header__right dpl-flex align-items-center'}>
                                        {homePageLink && (
                                            <div className={'header__right_item txt-color-gray mgr25'}>
                                                <a
                                                    href={homePageLink}
                                                    target="_blank"
                                                    className={'_header-exchange-rate txt-color-gray robotoregular txt-size-h8'}
                                                    rel="noreferrer"
                                                >
                                                    {t('header.homepage')}
                                                </a>
                                            </div>
                                        )}

                                        {menuFooter.length > 0 && (
                                            <div className={'header__right_item txt-color-gray mgr25 cursor-pointer'}>
                                                <Popover
                                                    overlayClassName="popup-profile"
                                                    content={this.renderMenu()}
                                                    visible={this.state.visibleLinkSupport}
                                                    onVisibleChange={visible => this.setState({ visibleLinkSupport: visible })}
                                                    placement="bottomRight"
                                                    trigger="click"
                                                >
                                                    <div className="txt-size-h8 txt-color-gray robotoregular line-height167">
                                                        <span className="mr-1">{t('header.support')}</span>
                                                        <i class="fa-solid fa-chevron-down text-xs"></i>
                                                    </div>
                                                </Popover>
                                            </div>
                                        )}

                                        {!orderConfig.disable && (
                                            <div className={'header__right_item txt-color-gray mgr25 dpl-flex'}>
                                                <Link
                                                    to={'/carts'}
                                                    className={'_link-to-carts'}
                                                >
                                                    <span className={'mgr5'}>
                                                        <i class="fa-regular fa-cart-shopping txt-color-red text-xs"></i>
                                                    </span>
                                                </Link>
                                                <Link to={'/carts'}>
                                                    <h3 className={'txt-size-h8 txt-color-gray robotoregular'}>
                                                        {t('header.cart')}
                                                        <TotalSkusInCart
                                                            totalSkus={totalSkus}
                                                            ref={ref => (this.TotalSkusInCart = ref)}
                                                        />{' '}
                                                        {t('header.product')}
                                                    </h3>
                                                </Link>
                                            </div>
                                        )}

                                        {exchangeRate.length < 2 &&
                                        _.get(configGroup, 'markupRateScope') &&
                                        markupRateViewTemplate !== null &&
                                        markupRateViewTemplateValid &&
                                        !_.isEmpty(markupRateViewTemplate) &&
                                        markupRateViewTemplate.length > 0 ? (
                                            <div className="header__right_item mgr25">
                                                <Button
                                                    type="default"
                                                    className="ml-2"
                                                    onClick={this.handleModal}
                                                >
                                                    {t('peer_payment.exchange_range')} {textRender}
                                                </Button>
                                            </div>
                                        ) : (
                                            (shipmentConfig.enable || !orderConfig.disable) &&
                                            rate &&
                                            exchangeRate.length < 2 && (
                                                <div className={'header__right_item txt-color-gray mgr25'}>
                                                    <span className={'txt-size-h8 txt-color-gray robotoregular'}>
                                                        {t('header.exchange')}
                                                    </span>
                                                    <span
                                                        className={
                                                            '_header-exchange-rate txt-color-black robotoregular txt-size-h7 mgl5'
                                                        }
                                                    >
                                                        {moneyFormat(1, rate.base)} = {moneyFormat(rate.rate, rate.exchange)}
                                                    </span>
                                                </div>
                                            )
                                        )}
                                        <Language {...this.props} />
                                        {depositWizard && (
                                            <span
                                                onClick={() => this.setState({ isDepositModal: true })}
                                                className="flex text-grey-200 text-sm cursor-pointer dpl-block"
                                            >
                                                <i className="fa-solid fa-yen-sign mr-2 text-sm max-w-[14px] text-blue-primary"></i>
                                                <span className="text-blue-primary mr-2 hover:underline">
                                                    {t('header.deposit')}
                                                </span>
                                                <span className=" text-[10px] px-2 bg-blue-primary rounded-2xl text-white">
                                                    {t('header.new')}
                                                </span>
                                            </span>
                                        )}
                                        <Notifications {...this.props} />
                                        <Popover
                                            overlayClassName="popup-profile"
                                            content={this.renderDropDownAvatar(depositWizard)}
                                            placement="bottomRight"
                                            trigger="click"
                                            open={isPopupOpen}
                                            onOpenChange={visible =>
                                                this.setState({
                                                    isPopupOpen: visible,
                                                })
                                            }
                                        >
                                            <div className={'drop-down-user cursor-pointer dpl-flex'}>
                                                <Avatar
                                                    src={currentLoggedUser.avatar}
                                                    className={'bg-color-blue3'}
                                                />
                                                <div className="_user-title pdl10 ">
                                                    <p
                                                        className={
                                                            '_user-name mgbt5 line-height-1 txt-size-h7 robotomedium txt-color-black'
                                                        }
                                                    >
                                                        {currentLoggedUser.fullname}
                                                    </p>
                                                    <Balance
                                                        balance={balance}
                                                        ref={this.BalanceComponent}
                                                    />
                                                </div>
                                            </div>
                                        </Popover>
                                    </div>
                                </div>
                            </div>
                        </Layout.Header>
                        <ProgressBar
                            percent={percent}
                            autoIncrement={autoIncrement}
                            intervalTime={intervalTime}
                            spinner={false}
                        />
                        <Layout.Content className="main-container">
                            {children}
                            {!noFooter && (
                                <Layout.Footer
                                    style={{
                                        backgroundColor: !articleFooter ? '' : '#e3ecf6',
                                        color: '#000',
                                        padding: '0 0 0 60px',
                                    }}
                                >
                                    {this.renderFooter()}
                                </Layout.Footer>
                            )}
                        </Layout.Content>
                        {this.renderModalArticle()}

                        {this.state.isModalOpen && (
                            <ModalCommon
                                title="Tỷ giá"
                                visible={this.state.isModalOpen}
                                onCancel={this.handleModal}
                            >
                                <Table
                                    className={'_table-data custom-empty table-expand-row '}
                                    rowKey={(x, y) => y}
                                    columns={columns}
                                    dataSource={markupRateViewTemplate}
                                    rowClassName={() => `_table-row`}
                                    pagination={false}
                                />
                            </ModalCommon>
                        )}
                        <DepositModal
                            visible={isDepositModal}
                            onClose={() =>
                                this.setState({
                                    isDepositModal: false,
                                })
                            }
                            // data={{
                            //     step: 1,
                            //     hideStep: true,
                            //     type: 'other',
                            //     money: 2000000,
                            // }}
                        />
                    </div>
                </Layout>
            </DocumentTitle>
        )
    }
}

export default withNamespaces('translation')(MainLayout)
