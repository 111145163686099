import React from 'react'
import { Link } from 'react-router-dom'
import { Menu, Avatar } from 'antd'
import * as AntdIcon from '@ant-design/icons'
import lodash from 'lodash'
import { withNamespaces } from 'react-i18next'
import { LocalStore } from '../../utils/LocalStore'
import { quantityFormat } from '../../utils/stringUtils'
import UserService from '../../services/user.service'

class Leftbar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentLoggedUser: {},
            purchasingAccounts: [],
        }
    }

    // scroll top khi bắt đầu load trang
    componentDidMount() {
        this.getPurchasingAccount()
        this.setState({
            currentProjectInfo: LocalStore.getInstance().read('currentProjectInfo') || {},
            currentLoggedUser: LocalStore.getInstance().read('currentLoggedUser') || {},
        })
    }

    getPurchasingAccount() {
        UserService.getPurchasingAccounts((err, data) => {
            this.setState({
                purchasingAccounts: data,
            })
        })
    }

    render() {
        const { currentLoggedUser = {} } = this.state
        const { currentRoute, t } = this.props
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const orderConfig = currentProjectInfo.tenantConfig ? currentProjectInfo.tenantConfig.orderConfig : {}
        const generalConfig = currentProjectInfo.tenantConfig ? currentProjectInfo.tenantConfig.generalConfig : {}
        const zaloConfig = currentProjectInfo.tenantConfig ? currentProjectInfo.tenantConfig.zaloConfig : {}
        const bifinConfigEnabled = currentProjectInfo.tenantConfig
            ? currentProjectInfo?.tenantConfig?.externalIntegrationConfig?.shopkeeper?.enabled
            : false
        const shopkeeperAuth = lodash.get(currentLoggedUser, 'customerAuthorities.shopkeeper')
        let openAPI = lodash.get(currentLoggedUser, 'customerAuthorities.openAPI', false)
        const diorConfig = currentProjectInfo?.tenantConfig?.diorConfig?.enabled
        const showHermes = lodash.get(currentProjectInfo, 'tenantConfig.diorConfig.showHermes', false)
        const telegramConfig = lodash.get(currentProjectInfo,'tenantConfig.externalIntegrationConfig.telegramConfig.enabled', false)


        return (
            <div className={'sidebarprofile bg-color-white pdbt10 mgt50 position-re'}>
                <div className={'sidebarprofile__info txt-center position-ab'}>
                    <Avatar
                        size={80}
                        src={currentLoggedUser.avatar}
                        className="sidebarprofile__info_avt bd2px border-color-white bg-color-gray mgbt10"
                    />
                </div>
                <h3 className={'txt-size-h1 txt-color-black robotoregular _profile-fullname txt-center break-word pdr10 pdl10'}>
                    {currentLoggedUser.fullname}
                </h3>
                <p className="txt-size-h7 txt-color-black robotoregular _profile-username txt-center break-word pdr10 pdl10 mgbt5">
                    {currentLoggedUser.username} | {currentLoggedUser.code}
                </p>
                {currentProjectInfo.tenantConfig &&
                currentProjectInfo.tenantConfig.generalConfig &&
                currentProjectInfo.tenantConfig.generalConfig.enableRewardPoint &&
                currentProjectInfo.tenantConfig.generalConfig.enableCustomerLevel ? (
                    <p className="txt-size-h7 txt-color-black robotoregular _profile-username txt-center break-word pdr10 pdl10">
                        {currentLoggedUser.customerLevel && currentLoggedUser.customerLevel.name
                            ? currentLoggedUser.customerLevel.name
                            : t('orderDetail.no_level_yet')}{' '}
                        | {quantityFormat(currentLoggedUser.rewardPoint)} {t('customer_info.point_2')}
                    </p>
                ) : null}
                <Menu
                    className={'sidebarprofile__menu _sidebar-profile'}
                    selectedKeys={[currentRoute]}
                >
                    <Menu.Item key="profile">
                        <Link
                            className={'_menu-profile-info'}
                            to={'/profile'}
                        >
                            <AntdIcon.UserOutlined type="user" />
                            <span className="txt-size-h7 robotoregular">{t('customer_info.account_info')}</span>
                        </Link>
                    </Menu.Item>

                    {generalConfig.peerPaymentEnabled && (
                        <Menu.Item key="purchasing-account">
                            <Link
                                className={'_menu-profile-purchasing-account'}
                                to={'/profile/purchasing-account'}
                            >
                                <AntdIcon.UserSwitchOutlined type="user" />
                                <span className="txt-size-h7 robotoregular">{t('customer_info.purchasingAccount')}</span>
                            </Link>
                        </Menu.Item>
                    )}
                    {bifinConfigEnabled && shopkeeperAuth && (
                        <Menu.Item key="bifiin">
                            <Link
                                className={'_menu-bifiin'}
                                to={'/profile/bifiin'}
                            >
                                <AntdIcon.UserSwitchOutlined type="user" />
                                <span className="txt-size-h7 robotoregular">{t('customer_info.bifin')}</span>
                            </Link>
                        </Menu.Item>
                    )}
                    {zaloConfig.zaloOaId && (
                        <Menu.Item key="zalo">
                            <Link
                                className={'_menu-profile-zalo'}
                                to={'/profile/zalo'}
                            >
                                <AntdIcon.MessageOutlined type="message" />
                                <span className="txt-size-h7 robotoregular">{t('customer_info.zalo')}</span>
                            </Link>
                        </Menu.Item>
                    )}
                    <Menu.Item key="address">
                        <Link
                            className={'_menu-profile-address'}
                            to={'/profile/address'}
                        >
                            <AntdIcon.EnvironmentOutlined type="environment" />
                            <span className="txt-size-h7 robotoregular">{t('customer_info.your_address')}</span>
                        </Link>
                    </Menu.Item>
                    {openAPI && (
                        <Menu.Item key="connect-application">
                            <Link
                                className={'_menu-profile-notification'}
                                to={'/profile/connect-application'}
                            >
                                <AntdIcon.ShareAltOutlined />
                                <span className="txt-size-h7 robotoregular">{t('userProfile.connect_application')}</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {showHermes && (
                        <Menu.Item key="transactions">
                            <Link
                                className={'_menu-profile-transaction-history'}
                                to={'/profile/transactions'}
                            >
                                <AntdIcon.FileDoneOutlined type="file-done" />
                                <span className="txt-size-h7 robotoregular">
                                    {diorConfig
                                        ? t('customer_info.transaction_history_old')
                                        : t('customer_info.transaction_history')}
                                </span>
                            </Link>
                        </Menu.Item>
                    )}

                    {diorConfig && (
                        <Menu.Item key="new-transactions">
                            <Link
                                className={'_menu-profile-transaction-history'}
                                to={'/profile/new-transactions'}
                            >
                                <AntdIcon.FileDoneOutlined type="file-done" />
                                <span className="txt-size-h7 robotoregular">{t('customer_info.transaction_history')} </span>
                            </Link>
                        </Menu.Item>
                    )}
                    {generalConfig.enableRewardPoint && generalConfig.enableCustomerLevel && (
                        <Menu.Item key="vip-levels">
                            <Link
                                className={'_menu-profile-vip-levels'}
                                to={'/profile/vip-levels'}
                            >
                                <AntdIcon.MoneyCollectOutlined type="money-collect" />
                                <span className="txt-size-h7 robotoregular">{t('customer_info.accumulated_point')}</span>
                            </Link>
                        </Menu.Item>
                    )}
                    {!orderConfig.disable && (
                        <Menu.Item key="products">
                            <Link
                                className={'_menu-profile-saved-products'}
                                to={'/profile/products'}
                            >
                                <AntdIcon.HeartOutlined type="heart" />
                                <span className="txt-size-h7 robotoregular">{t('customer_info.saved_product')}</span>
                            </Link>
                        </Menu.Item>
                    )}
                    <Menu.Item key="faqs">
                        <Link
                            className={'_menu-profile-faqs'}
                            to={'/profile/faqs'}
                        >
                            <AntdIcon.QuestionCircleOutlined type="question-circle" />
                            <span className="txt-size-h7 robotoregular">{t('customer_info.frequently_asked_question')}</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="coupon">
                        <Link
                            className={'_menu-profile-transaction-history'}
                            to={'/profile/coupon'}
                        >
                            <AntdIcon.GiftOutlined />
                            <span className="txt-size-h7 robotoregular">{t('customer_info.coupon')}</span>
                        </Link>
                    </Menu.Item>
                    {telegramConfig && 
                    <Menu.Item key="telegram">
                        <Link
                            className={'_menu-profile-notification'}
                            to={'/profile/telegram'}
                        >
                            <AntdIcon.ShareAltOutlined />
                            <span className="txt-size-h7 robotoregular">{t('userProfile.telegram')}</span>
                        </Link>
                    </Menu.Item>
                    }
                   
                    <Menu.Item key="notification">
                        <Link
                            className={'_menu-profile-notification'}
                            to={'/profile/notification'}
                        >
                            <AntdIcon.SettingOutlined type="setting" />
                            <span className="txt-size-h7 robotoregular">{t('userProfile.notification_config')}</span>
                        </Link>
                    </Menu.Item>
                </Menu>
            </div>
        )
    }
}
export default withNamespaces()(Leftbar)
