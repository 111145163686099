import {Modal, Form, Input, Checkbox} from 'antd';

const RegisterConnectionFormModal = ({loading, visible, onCreate, onCancel, t, username, email, fullname }) => {
    const [form] = Form.useForm();
    return (
        <Modal
            visible={visible}
            title={t('register_connect.title')}
            okText={t('button.agree')}
            cancelText={t('button.disagree')}
            width={600}
            okButtonProps={{
                loading: loading,
                disabled: loading
            }}
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        onCreate(values);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <span className={'txt-size-h7 mgbt10'}>{t('register_connect.note')}</span>
            <Form
                form={form}
                layout="horizontal"
                name="form_in_modal"
                className={'mgt10'}
                labelCol={{ span: 8 }}
                labelAlign={'left'}
                wrapperCol={{ span: 16 }}
                initialValues={{
                    username, email, fullname
                }}
            >
                <Form.Item
                    name="username"
                    label={t('register_connect.username')}
                    rules={[
                        {
                            required: true,
                            message: t('customer_info.required'),
                        },
                    ]}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    name="email"
                    label={t('register_connect.email')}
                >
                    <Input disabled/>
                </Form.Item>
                <Form.Item
                    name="fullname"
                    label={t('register_connect.fullname')}
                    rules={[
                        {
                            required: true,
                            message: t('customer_info.required'),
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="password"
                    label={t('register_connect.password')}
                    rules={[
                        {
                            required: true,
                            message: t('customer_info.required'),
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="confirmPassword"
                    label={t('register_connect.re-password')}
                    rules={[
                        {
                            required: true,
                            message: t('customer_info.required'),
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(t('customer_info.incorrect_repass')));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: (_, value) =>
                                value ? Promise.resolve() : Promise.reject(new Error(t('customer_info.require_check'))),
                        },
                    ]}
                >
                    <Checkbox>
                        Tôi đồng ý với <span className='cursor-pointer'>chính sách bảo mật</span> của Ubox.asia
                    </Checkbox>
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default RegisterConnectionFormModal;
