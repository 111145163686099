/* eslint-disable array-callback-return */
import React from 'react'
import MainLayout from '../../components/Layout/MainLayout'
import Loading from '../../components/Loading'
import { Link } from 'react-router-dom'
import { Button, Checkbox, Empty, Input, notification, Select, Table } from 'antd'
import * as AntdIcon from '@ant-design/icons'
import moment from 'moment'
import lodash, { unionBy } from 'lodash'
import { LocalStore } from '../../utils/LocalStore'
import { moneyCeil, moneyFormat, quantityFormat } from '../../utils/stringUtils'
import DeliveryService from '../../services/delivery.service'
import CategoriesService from '../../services/categories.service'
import orderService from '../../services/orders.service'
import { withNamespaces } from 'react-i18next'
import BifinService from '../../services/bifin.service'
import ModalDeliveryDeposit from './ModalDeliveryDeposit'
import UserService from '../../services/user.service'
import DepositModal from '../../components/DepositModal'

class CreateDelivery extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isCheckAll: true,
            indeterminate: false,
            note: '',
            currentProjectInfo: LocalStore.getInstance().read('currentProjectInfo'),
            loanCredits: [],
            isOpenModalDeliveryDeposit: false,
            isOpenModalDeposit: false,
            balance: 0,
            creditLimit: 0,
        }
    }

    componentDidMount() {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null

        if (typeof LocalStore.getInstance().read('currentLoggedUser') === 'object') {
            this.setState({ isLoading: true }, () => {
                this.getListAvailableOrder()
                this.getPackageStatuses()
                this.getParcelStatuses()
                this.getShippingMethods()
                this.getBalanceUser()
            })
        }
    }

    /**
     * lấy số dư tài khoản của khách hàng
     */
    getBalanceUser = () => {
        UserService.getBalanceUser((err, res) => {
            if (!err) {
                this.setState({
                    balance: res ? moneyCeil(res.balance) : 0,
                    creditLimit: res ? moneyCeil(res.creditLimit) : 0,
                })
            }
        })
    }

    /**
     * lấy danh sách hàng đã về kho || tạm fake
     */
    getListAvailableOrder = () => {
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const bifinEnabled = lodash.get(currentProjectInfo, 'tenantConfig.externalIntegrationConfig.shopkeeper.enabled', false)
        DeliveryService.getListAvailableOrder((err, res) => {
            if (!err) {
                this.setState(
                    {
                        listAvailableOrder: res,
                    },
                    () => {
                        this.onCheckAllInPage({ target: { checked: true } })
                        if (bifinEnabled) this.getThirdPatiesLoans(res)
                    }
                )
            }
            this.setState({ isLoading: false })
        })
    }

    /**
     * get loans
     */
    getThirdPatiesLoans = data => {
        if (Array.isArray(data) && data.length === 0) return
        let codes = data?.map(item => item?.code)
        codes = codes.join(',')
        let queryString = `?orderCodes=${codes}`
        BifinService.getThirdPatiesLoans(queryString, (err, res) => {
            // const { loanCredits } = res
            if (!err) {
                this.setState({
                    loanCredits: res?.loanCredits,
                })
            }
        })
    }

    /**
     * lấy ra danh sách trạng thái của package
     */
    getPackageStatuses = () => {
        CategoriesService.getPackageStatuses((err, res, headers) => {
            if (err === null) {
                this.setState({
                    listPackageStatuses: res || [],
                })
            }
        })
    }

    /**
     * lấy ra danh sách hình thức giao
     */
    getShippingMethods = () => {
        CategoriesService.getListShippingMethods((err, res, headers) => {
            if (!err) {
                this.setState({
                    listListShippingMethod: res || [],
                })
            }
        })
    }

    /**
     * lấy ra danh sách trạng thái của package
     */
    getParcelStatuses = () => {
        CategoriesService.getShipmentParcelStatus((err, res, headers) => {
            if (err === null) {
                this.setState({
                    listParcelStatuses: res || [],
                })
            }
        })
    }

    /**
     * checkAll in page
     * @param e
     */
    onCheckAllInPage = e => {
        const { listAvailableOrder = [] } = this.state
        let checkedListPage = []
        let checkedListObj = {}

        listAvailableOrder?.map(item => {
            if (!Array.isArray(item.packages)) item.packages = []
            checkedListPage = [...checkedListPage, ...item.packages]
            checkedListObj[`checkedList${item.code}`] = e.target.checked ? item.packages : []
            checkedListObj[`indeterminate${item.code}`] = false
            checkedListObj[`checkAll${item.code}`] = e.target.checked
        })

        this.setState({
            checkedListPage: e.target.checked ? checkedListPage : [],
            indeterminatePage: false,
            checkAllPage: e.target.checked,
            ...checkedListObj,
        })
    }

    /**
     * item checked
     * @param item
     * @param order
     */
    onPackageCheck = (item, order) => {
        let { listAvailableOrder = [] } = this.state
        let checkedList = this.state[`checkedList${order.code}`] || []
        let checkedListPage = this.state.checkedListPage || []
        if (!Array.isArray(order.packages)) order.packages = []

        let totalPackages = 0
        listAvailableOrder.map(x => (totalPackages = totalPackages + x.availablePackageCount))

        if (checkedList.find(x => x.code === item.code)) {
            checkedList = checkedList.filter(x => x.code !== item.code)
            checkedListPage = checkedListPage.filter(x => x.code !== item.code)
        } else {
            checkedList.push(item)
            checkedListPage.push(item)
        }

        //nếu listCheckedPage có độ dài bằng với tổng số lượng item skus trong trang thì checkAllPage = true, indeterminatePage = false

        this.setState({
            checkedListPage,
            indeterminatePage: !!checkedListPage.length && checkedListPage.length < totalPackages,
            checkAllPage: checkedListPage.length === totalPackages,
            [`checkedList${order.code}`]: checkedList,
            [`indeterminate${order.code}`]: !!checkedList.length && checkedList.length < order.packages.length,
            [`checkAll${order.code}`]: checkedList.length === order.packages.length,
        })
    }

    /**
     * check Item
     * @param e
     * @param item
     */
    onOrderChecked = (e, item) => {
        const { checkedListPage = [], listAvailableOrder = [] } = this.state
        let totalPackages = 0
        if (!Array.isArray(item.packages)) item.packages = []

        listAvailableOrder.map(x => (totalPackages = totalPackages + x.availablePackageCount))

        let arrayNoneDataSource = checkedListPage.filter(x => x.orderCode !== item.code)
        let arrayHasDataSource = [...arrayNoneDataSource, ...item.packages]

        this.setState({
            checkedListPage: e.target.checked ? arrayHasDataSource : arrayNoneDataSource,
            indeterminatePage: e.target.checked
                ? !!arrayHasDataSource.length && arrayHasDataSource.length < totalPackages
                : !!arrayNoneDataSource.length && arrayNoneDataSource.length < totalPackages,
            checkAllPage: e.target.checked
                ? arrayHasDataSource.length === totalPackages
                : arrayNoneDataSource.length === totalPackages,
            [`checkedList${item.code}`]: e.target.checked ? item.packages : [],
            [`indeterminate${item.code}`]: false,
            [`checkAll${item.code}`]: e.target.checked,
        })
    }

    renderLoansBifin = () => {
        const { checkedListPage = [], loanCredits } = this.state
        let total = 0
        unionBy(checkedListPage, 'orderCode').map(x => {
            let itemValid = loanCredits?.find(loan => {
                const code = loan?.shipmentCode || loan?.orderCode
                return code === x.orderCode
            })
            if (itemValid) {
                total += itemValid?.status !== 'ACTIVE' ? 0 : itemValid?.totalAmountPay
            }
        })
        return parseFloat(parseFloat(total).toFixed(4))
    }

    /**
     * submit create delivery
     */
    onSubmitCreateDelivery = event => {
        const { history, t } = this.props
        let { checkedListPage = [], shippingMethod, note, currentProjectInfo } = this.state
        const depositWizard = lodash.get(currentProjectInfo, 'tenantConfig.generalConfig.depositWizard')
        if (!checkedListPage.length) {
            notification.error({
                message: t('createDelivery.errorRequiredOrder'),
            })
            return
        }

        if (lodash.get(currentProjectInfo, 'tenantConfig.generalConfig.deliveryRequestShippingMethodRequired')) {
            if (!shippingMethod) {
                notification.error({
                    message: t('createDelivery.errorRequiredShippingMethod'),
                })
                return
            }
        }

        this.setState({ confirmLoading: true, isOpenModalDeliveryDeposit: false }, () => {
            if (checkedListPage.length > 0) {
                let listOrderPackages = checkedListPage.filter(x => !x.isShipment)
                let listShipmentPackages = checkedListPage.filter(x => x.isShipment)

                let arrOrder = []
                listOrderPackages.map(item => {
                    let curOrder = arrOrder.find(x => x.orderCode === item.orderCode)
                    if (curOrder) {
                        if (!Array.isArray(curOrder.packages)) {
                            curOrder.packages = []
                        }
                        if (!curOrder.packages.find(x => x === item.code)) {
                            curOrder.packages.push(item.code)

                            arrOrder = [...arrOrder.filter(x => x.orderCode !== item.orderCode), curOrder]
                        }
                    } else {
                        arrOrder = [...arrOrder, { orderCode: item.orderCode, packages: [item.code] }]
                    }
                })

                let arrShipment = []
                listShipmentPackages.map(item => {
                    let curOrder = arrShipment.find(x => x.orderCode === item.orderCode)
                    if (curOrder) {
                        if (!Array.isArray(curOrder.packages)) {
                            curOrder.packages = []
                        }
                        if (!curOrder.packages.find(x => x === item.code)) {
                            curOrder.packages.push(item.code)

                            arrShipment = [...arrShipment.filter(x => x.orderCode !== item.orderCode), curOrder]
                        }
                    } else {
                        arrShipment = [...arrShipment, { orderCode: item.orderCode, packages: [item.code] }]
                    }
                })

                let payload = {
                    orders: arrOrder,
                    shipments: arrShipment,
                    cod: true,
                    note,
                }

                if (shippingMethod) {
                    payload.shippingMethodCode = shippingMethod
                }

                //Thực hiện đẩy dữ liệu và call api
                DeliveryService.createDelivery(payload, (err, res) => {
                    if (!err) {
                        orderService.getOrdersStatistics((e, r) => {
                            if (!e) {
                                LocalStore.getInstance().save('ordersStatistics', r)
                                notification['success']({
                                    message: t('message.success'),
                                })
                                setTimeout(() => {
                                    this.setState({ confirmLoading: false })
                                    history.replace('/delivery')
                                }, 1000)
                            }
                        })
                    } else {
                        this.setState({ confirmLoading: false })
                        if (err.statusCode !== 'Network fail') {
                            if (err?.title === 'insufficient_balance') {
                                notification['error']({
                                    message: (
                                        <>
                                            {t(`error.${err?.title}`)}{' '}
                                            <span
                                                className={depositWizard ? `underline text-blue-primary cursor-pointer` : ''}
                                                onClick={() => {
                                                    depositWizard && this.setState({ isOpenModalDeposit: true })
                                                }}
                                            >
                                                {t('error.insufficient_balance_payment')}
                                            </span>
                                        </>
                                    ),
                                })
                            } else {
                                notification['error']({
                                    message: t(err.message),
                                })
                            }
                        }
                    }
                })
            } else {
                setTimeout(() => {
                    this.setState({
                        showWarning: true,
                        confirmLoading: false,
                    })
                }, 2000)
            }
        })
    }

    /**
     * render item of list
     * @param record
     * @returns {*}
     */
    renderExpandRow = record => {
        const { t } = this.props
        const { listPackageStatuses = [], listParcelStatuses = [] } = this.state

        const columns = [
            {
                title: t('delivery.package_code'),
                dataIndex: 'code',
                key: 'code',
                render: (text, row) => {
                    let checkedList = this.state[`checkedList${record.code}`]
                    return (
                        <span
                            className={
                                '_order-item-code dpl-flex listorders__items_code txt-color-black txt-size-h7 robotoregular'
                            }
                        >
                            <Checkbox
                                className="pdr10"
                                onChange={() => this.onPackageCheck(row, record)}
                                checked={Array.isArray(checkedList) && checkedList.find(x => x.code === text) !== undefined}
                            />
                            {text}
                        </span>
                    )
                },
            },
            {
                title: t('delivery.created_at'),
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: text => (
                    <span className="_table-row-total-package txt-color-black txt-size-h7 robotomedium">
                        {moment(text).format('HH:mm DD/MM/YYYY')}
                    </span>
                ),
            },
            {
                title: t('delivery.volumetric'),
                dataIndex: 'volumetric',
                key: 'volumetric',
                render: text => (
                    <span className="_table-row-provisionalAmount txt-color-black txt-size-h7 robotomedium">
                        {text ? quantityFormat(text) + ' cm3' : t('delivery.undefined')}
                    </span>
                ),
            },
            {
                title: t('delivery.weight'),
                dataIndex: 'weight',
                key: 'weight',
                render: (text, record) => {
                    const weight = record.actualWeight //Math.max(record.actualWeight, record.dimensionalWeight);
                    return (
                        <span className="_table-row-weight txt-color-black txt-size-h7 robotomedium">
                            {Number.isFinite(weight) ? `${quantityFormat(weight)} kg` : t('delivery.undefined')}
                        </span>
                    )
                },
            },
            {
                title: t('fee_tab.transport_fee'),
                dataIndex: 'totalUnpaid',
                key: 'totalUnpaid',
                render: (text, record) => {
                    if (!record.shippingFee) return '---'
                    return (
                        <span
                            className={`_table-row-refund ${
                                record.shippingFee > 0 ? 'txt-color-red' : 'txt-color-green'
                            } txt-size-h7 robotomedium`}
                        >
                            {moneyFormat(moneyCeil(record.shippingFee))}
                        </span>
                    )
                },
            },
            {
                title: t('delivery.status'),
                dataIndex: 'status',
                key: 'status',
                render: (text, row) => {
                    let itemStatus = {}
                    if (record.isShipment) {
                        itemStatus = listParcelStatuses.find(x => x.code === text) || {}
                    } else {
                        itemStatus = listPackageStatuses.find(x => x.code === text) || {}
                    }
                    return <span className="_table-row-status txt-color-black txt-size-h7 robotomedium">{itemStatus.name}</span>
                },
            },
        ]

        return (
            <Table
                className={'_table-data-child custom-empty'}
                rowKey={'code'}
                columns={columns}
                dataSource={record.packages}
                rowClassName={() => `_table-row`}
                pagination={{
                    className: '_pagination text-center width-100-percent mgt20',
                    hideOnSinglePage: true,
                    current: 1,
                    total: Array.isArray(record.packages) ? record.packages.length : 0,
                    pageSize: Array.isArray(record.packages) ? record.packages.length : 0,
                    // onChange: (page) => this.onPageChanged(page)
                }}
            />
        )
    }

    calculate = () => {
        const { checkedListPage = [], listAvailableOrder = [] } = this.state
        let listOrderPackages = checkedListPage.filter(x => !x.isShipment)
        let listShipmentPackages = checkedListPage.filter(x => x.isShipment)

        let arrOrder = []
        listOrderPackages.map(item => {
            let curOrder = arrOrder.find(x => x.orderCode === item.orderCode)
            if (curOrder) {
                if (!Array.isArray(curOrder.packages)) {
                    curOrder.packages = []
                }
                if (!curOrder.packages.find(x => x === item.code)) {
                    curOrder.packages.push(item.code)

                    arrOrder = [...arrOrder.filter(x => x.orderCode !== item.orderCode), curOrder]
                }
            } else {
                arrOrder = [...arrOrder, { orderCode: item.orderCode, packages: [item.code] }]
            }
        })

        let arrShipment = []
        listShipmentPackages.map(item => {
            let curOrder = arrShipment.find(x => x.orderCode === item.orderCode)
            if (curOrder) {
                if (!Array.isArray(curOrder.packages)) {
                    curOrder.packages = []
                }
                if (!curOrder.packages.find(x => x === item.code)) {
                    curOrder.packages.push(item.code)

                    arrShipment = [...arrShipment.filter(x => x.orderCode !== item.orderCode), curOrder]
                }
            } else {
                arrShipment = [...arrShipment, { orderCode: item.orderCode, packages: [item.code] }]
            }
        })

        let total = 0

        // đây là dành cho ordert thường
        arrOrder.map(x => {
            let curOrder = listAvailableOrder.find(y => y.code === x.orderCode)
            if (curOrder && curOrder.totalUnpaid) total = total + curOrder.totalUnpaid
        })

        // đây dành cho đơn ký gửi
        arrShipment.map(x => {
            let curShipment = listAvailableOrder.find(y => y.code === x.orderCode)
            // console.log('curShipment', curShipment)
            if (curShipment) {
                // (NeedToPaid + totalRefund - TotalPaid) + các kiện tick
                total = total + curShipment.needToPaid //(curShipment.needToPaid + curShipment.totalRefund - curShipment.totalPaid)

                curShipment.packages.map(y => {
                    if (!checkedListPage.find(z => z.code === y.code)) {
                        total = total - y.shippingFee
                    }
                })
            }
        })
        // listShipmentPackages.map(x => {
        //     if (x.shippingFee) total = total + x.shippingFee
        // });

        return parseFloat(parseFloat(total).toFixed(4))
    }

    render() {
        const { t } = this.props
        const {
            isLoading,
            confirmLoading,
            listAvailableOrder = [],
            expandedRow = [],
            checkedListPage = [],
            shippingMethod,
            listListShippingMethod = [],
            currentProjectInfo,
        } = this.state

        const bifinConfigEnabled = currentProjectInfo.tenantConfig
            ? currentProjectInfo?.tenantConfig?.externalIntegrationConfig?.shopkeeper?.enabled
            : false

        const columns = [
            {
                title: t('delivery.order'),
                dataIndex: 'code',
                key: 'code',
                render: (text, record) => {
                    let indeterminate = this.state[`indeterminate${record.code}`]
                    let checkAll = this.state[`checkAll${record.code}`]
                    return (
                        <span
                            className={
                                '_order-item-code listorders__items_code dpl-flex txt-color-blue txt-size-h7 robotoregular'
                            }
                        >
                            <Checkbox
                                className="pdr10"
                                indeterminate={indeterminate}
                                onChange={e => this.onOrderChecked(e, record)}
                                checked={checkAll}
                            />
                            <Link
                                target="_blank"
                                to={record.isShipment ? `/shipments/${record.code}` : `/orders/${record.code}`}
                            >
                                {record.code}
                            </Link>
                        </span>
                    )
                },
            },
            {
                title: t('delivery.total_packages'),
                dataIndex: 'availablePackageCount',
                key: 'availablePackageCount',
                render: text => (
                    <span className="_table-row-availablePackageCount txt-color-black txt-size-h7 robotomedium">
                        {quantityFormat(text)}
                    </span>
                ),
            },
            {
                title: t('delivery.total_weight_packages'),
                dataIndex: 'weight',
                key: 'weight',
                render: (text, record) => {
                    let totalWeight = 0
                    if (Array.isArray(record.packages)) record.packages.map(x => (totalWeight = totalWeight + x.actualWeight))
                    return (
                        <span className="_table-row-weight txt-color-black txt-size-h7 robotomedium">
                            {Number.isFinite(totalWeight) ? `${quantityFormat(totalWeight)} kg` : t('delivery.undefined')}
                        </span>
                    )
                },
            },
            {
                title: t('delivery.order_amount'),
                dataIndex: 'grandTotal',
                key: 'grandTotal',
                render: (text, record) => {
                    // const orderValue = (record.totalValue || 0) * (record.exchangeRate || 1) + (record.merchantShippingCost || 0) * (record.exchangeRate || 1) + (record.totalFee || 0);
                    return (
                        <span className="_table-row-orderValue txt-color-black txt-size-h7 robotomedium">
                            {moneyFormat(moneyCeil(text))}
                        </span>
                    )
                },
            },
            {
                title: t('delivery.paid_amount'),
                dataIndex: 'totalPaid',
                key: 'totalPaid',
                render: text => (
                    <span className="_table-row-totalPaid txt-color-black txt-size-h7 robotomedium">
                        {moneyFormat(moneyCeil(text))}
                    </span>
                ),
            },
            {
                title: t('delivery.total_refund'),
                dataIndex: 'totalRefund',
                key: 'totalRefund',
                render: text => (
                    <span className="_table-row-totalRefund txt-color-black txt-size-h7 robotomedium">
                        {moneyFormat(moneyCeil(text))}
                    </span>
                ),
            },
            {
                title: t('delivery.unpaid_amount'),
                dataIndex: 'totalUnpaid',
                key: 'totalUnpaid',
                render: (text, record) => {
                    if (record.isShipment) {
                        let totalUnpaid = 0
                        // checkedListPage.filter(x => x.orderCode === record.code).map(x => totalUnpaid = totalUnpaid + x.shippingFee);
                        // totalUnpaid = totalUnpaid + (record.needToPaid + record.totalRefund - record.totalPaid);
                        totalUnpaid = record.needToPaid
                        record.packages.map(y => {
                            if (!checkedListPage.find(z => z.code === y.code)) {
                                totalUnpaid = totalUnpaid - y.shippingFee
                            }
                        })

                        return (
                            <span
                                className={`_table-row-refund ${
                                    totalUnpaid > 0 ? 'txt-color-red' : 'txt-color-green'
                                } txt-size-h7 robotomedium`}
                            >
                                {moneyFormat(moneyCeil(totalUnpaid))}
                            </span>
                        )
                    }
                    return (
                        <span
                            className={`_table-row-refund ${
                                text > 0 ? 'txt-color-red' : 'txt-color-green'
                            } txt-size-h7 robotomedium`}
                        >
                            {moneyFormat(moneyCeil(text))}
                        </span>
                    )
                },
            },
            {
                title: t('delivery.address'),
                dataIndex: 'address',
                key: 'address',
                render: (text, record) => (
                    <span className={'_table-row-address txt-color-black txt-size-h7 robotomedium'}>
                        {record.address &&
                            record.address.fullName + ', ' + record.address.phone + ', ' + record.address.location.display}
                    </span>
                ),
            },
        ]

        let totalWeight = 0
        checkedListPage.map(x => (totalWeight = totalWeight + x.actualWeight))

        totalWeight = parseFloat(parseFloat(totalWeight).toFixed(4))

        const onCloseModalDeliveryDeposit = () => {
            this.setState({ isOpenModalDeliveryDeposit: false })
        }

        const onOpenModalDeposit = () => {
            this.setState({ isOpenModalDeliveryDeposit: false })
            this.setState({ isOpenModalDeposit: true })
        }

        const onCloseModalDeposit = () => {
            this.setState({ isOpenModalDeposit: false })
        }

        const amountNeedPaid = this.calculate() + this.renderLoansBifin() - this.state.balance
        const checkAmountNeedPaid = amountNeedPaid > 0 && this.calculate() > 0
        const depositWizard = lodash.get(currentProjectInfo, 'tenantConfig.generalConfig.depositWizard')
        const totalAmount = this.calculate() + this.renderLoansBifin()

        return (
            <MainLayout
                {...this.props}
                showProgressBar={this.state.showProgressBar}
                headerTitle={t('delivery.create_title')}
                title={t('delivery.create_title')}
            >
                <div className={'container-common pdbt30 mgr20'}>
                    <div className="dpl-flex justify-content-between align-items-center bg-color-white pd25 pdt15 pdbt15 border-bottom-1x bd-color-gray">
                        <span className={'txt-color-black txt-size-h5 robotomedium _list-delivered-title'}>
                            {t('delivery.available_order')}(<span className={'_total-items'}>{listAvailableOrder.length}</span>)
                        </span>
                    </div>
                    {Array.isArray(listAvailableOrder) && listAvailableOrder.length > 0 ? (
                        <Table
                            className={'_table-data custom-empty table-white-space'}
                            rowKey={'code'}
                            columns={columns}
                            dataSource={listAvailableOrder}
                            rowClassName={() => `_table-row bg-color-white`}
                            expandedRowKeys={expandedRow}
                            expandedRowRender={this.renderExpandRow}
                            expandIcon={expand => {
                                return expand.expanded ? (
                                    <AntdIcon.DownOutlined
                                        onClick={e => expand.onExpand(expand.record, e)}
                                        type={expand.expanded ? 'down' : 'right'}
                                    />
                                ) : (
                                    <AntdIcon.RightOutlined onClick={e => expand.onExpand(expand.record, e)} />
                                )
                            }}
                            onExpand={(expanded, record) => {
                                if (expanded) {
                                    this.setState({
                                        expandedRow: [record.code],
                                    })
                                } else {
                                    this.setState({
                                        expandedRow: [],
                                    })
                                }
                            }}
                            pagination={{
                                className: '_pagination text-center width-100-percent mgt20',
                                hideOnSinglePage: true,
                                current: 1,
                                total: listAvailableOrder.length,
                                pageSize: listAvailableOrder.length,
                            }}
                        />
                    ) : isLoading ? (
                        <Loading />
                    ) : (
                        <div className="pdt50 pdbt60 bg-color-white ">
                            <Empty
                                image={require('../../resources/images/empty-image.png')}
                                description={t('delivery.empty_available_order')}
                            />
                        </div>
                    )}
                    {Array.isArray(listAvailableOrder) && listAvailableOrder.length > 0 && (
                        <>
                            <div className={'pdl20 pdr20 pdt20 pdbt20 bg-color-white'}>
                                <Input.TextArea
                                    value={this.state.note}
                                    onChange={e => this.setState({ note: e.target.value })}
                                    placeholder={t('delivery.notePlaceholder')}
                                />
                            </div>
                            <div className="dpl-flex justify-content-between items-start bg-color-white px-16 py-6 border-top-1x bd-color-gray">
                                <div className="dpl-flex align-items-center width-300">
                                    <Checkbox
                                        indeterminate={this.state.indeterminatePage}
                                        onChange={e => this.onCheckAllInPage(e)}
                                        checked={this.state.checkAllPage}
                                        className={'txt-color-black txt-size-h5 robotobmedium _checkbox-checkAll flex-none'}
                                    >
                                        <span className="txt-size-h8 txt-color-gray robotoregular">
                                            {t('delivery.select_all')}
                                        </span>
                                    </Checkbox>

                                    <div
                                        style={{
                                            width: 1,
                                            height: 30,
                                            backgroundColor: '#f0f0f0',
                                            marginLeft: 10,
                                            marginRight: 20,
                                        }}
                                    />
                                    <Select
                                        placeholder={t('delivery.shipping_method')}
                                        dropdownClassName="_shipping_method_overlay"
                                        className={'_shipping_method width100'}
                                        onChange={value =>
                                            this.setState({
                                                shippingMethod: value,
                                            })
                                        }
                                        value={shippingMethod || []}
                                    >
                                        {Array.isArray(listListShippingMethod) &&
                                            listListShippingMethod.map((item, index) => {
                                                return (
                                                    <Select.Option
                                                        className="_option-shipping_method"
                                                        key={item.id}
                                                        value={item.code}
                                                    >
                                                        {item.name}
                                                    </Select.Option>
                                                )
                                            })}
                                    </Select>
                                </div>
                                <div className="grid gap-3 w-[320px]">
                                    {bifinConfigEnabled && this.renderLoansBifin() > 0 ? (
                                        <>
                                            <div className="flex justify-between items-center">
                                                <span className="font-normal text-sm text-grey-400">
                                                    {t('delivery.needPaiBifin')}
                                                </span>
                                                <span className={`font-medium text-sm text-black-100`}>
                                                    {moneyFormat(moneyCeil(this.renderLoansBifin()), null, true)}
                                                </span>
                                            </div>

                                            <div className="flex justify-between items-center">
                                                <span className="font-normal text-sm text-grey-400">
                                                    {t('delivery.needPaiTenant')}
                                                </span>
                                                <span className={`font-medium text-sm text-black-100`}>
                                                    {moneyFormat(moneyCeil(this.calculate()), null, true)}
                                                </span>
                                            </div>
                                            <div className="custom-line-dash"></div>
                                            <div className="flex items-center justify-between">
                                                <span className="txt-size-h7 text-black-100 robotomedium">
                                                    {this.calculate() > 0
                                                        ? t('delivery.total_unpaid_amount')
                                                        : t('delivery.total_refund_amount')}
                                                    :
                                                </span>
                                                <span className={`text-blue-primary font-semibold text-2xl`}>
                                                    {this.calculate() > 0
                                                        ? moneyFormat(moneyCeil(totalAmount))
                                                        : moneyFormat(moneyCeil(Math.abs(totalAmount)))}
                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="flex items-center justify-between">
                                                <span className="txt-size-h7 text-black-100 robotomedium">
                                                    {this.calculate() > 0
                                                        ? t('delivery.total_unpaid_amount')
                                                        : t('delivery.total_refund_amount')}
                                                    :
                                                </span>
                                                <span className={`text-blue-primary font-semibold text-2xl`}>
                                                    {this.calculate() > 0
                                                        ? moneyFormat(moneyCeil(totalAmount))
                                                        : moneyFormat(moneyCeil(Math.abs(totalAmount)))}
                                                </span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="dpl-flex justify-content-between align-items-center bg-color-white px-16 py-6 border-top-1x bd-color-gray">
                                <div>
                                    <span className="txt-size-h7 txt-color-gray robotomedium">
                                        {t('delivery.total_weight_packages')}:
                                        <span className={`font-medium text-sm text-black-100 pl-3`}>
                                            {quantityFormat(totalWeight)} kg
                                        </span>
                                    </span>
                                </div>
                                <div className="mgl15">
                                    <Button
                                        className={'_btn-create-delivery txt-uppercase w-[320px]'}
                                        type="primary"
                                        size="large"
                                        loading={confirmLoading}
                                        onClick={() =>
                                            checkAmountNeedPaid && depositWizard
                                                ? this.setState({ isOpenModalDeliveryDeposit: true })
                                                : this.onSubmitCreateDelivery()
                                        }
                                    >
                                        {t('delivery.btn_create')}
                                    </Button>
                                    {this.state.isOpenModalDeliveryDeposit && (
                                        <ModalDeliveryDeposit
                                            isOpenModalDeliveryDeposit={this.state.isOpenModalDeliveryDeposit}
                                            onClose={onCloseModalDeliveryDeposit}
                                            onSubmitCreateDelivery={this.onSubmitCreateDelivery}
                                            amountNeedPaid={amountNeedPaid}
                                            onOpenModalDeposit={onOpenModalDeposit}
                                            t={t}
                                        />
                                    )}
                                    {this.state.isOpenModalDeposit && (
                                        <DepositModal
                                            visible={this.state.isOpenModalDeposit}
                                            onClose={onCloseModalDeposit}
                                            maskClosable={true}
                                            data={{
                                                step: 1,
                                                hideStep: true,
                                                type: 'package',
                                                money: amountNeedPaid,
                                            }}
                                        />
                                    )}

                                    {this.state.showWarning && (
                                        <span className={`txt-size-h8 txt-color-red dpl-block mgt10`}>
                                            {t('delivery.warning_create')}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </MainLayout>
        )
    }
}
export default withNamespaces()(CreateDelivery)
