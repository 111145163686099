/* eslint-disable array-callback-return */
import * as AntdIcon from '@ant-design/icons'
import {
    Badge,
    Button,
    Col,
    DatePicker,
    Empty,
    Input,
    notification,
    Row,
    Select,
    Skeleton,
    Space,
    Spin,
    Steps,
    Table,
    Tag,
    Tooltip,
    Typography,
} from 'antd'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import MainLayout from '../../components/Layout/MainLayout'
import Loading from '../../components/Loading'
import OrderServices from '../../services/orders.service'

import { LocalStore } from '../../utils/LocalStore'

import { Popover } from 'antd'
import saveAs from 'file-saver'
import { default as _, default as lodash } from 'lodash'
import queryString from 'query-string'
import { withNamespaces } from 'react-i18next'
import ExportExcel from '../../components/ExportExcel'
import CategoriesService from '../../services/categories.service'
import ShipmentsService from '../../services/shipments.service'
import UserService from '../../services/user.service'
import { getUrlQueryString } from '../../utils/helper'
import { parseQueryStringToObject, quantityFormat, updateQueryStringParameter } from '../../utils/stringUtils'

let pageSize = 25
class Packages extends React.Component {
    constructor(props) {
        super(props)
        this.isUnmounted = false
        this.state = {
            page: 1,
            pageSize: pageSize,
            isLoading: true,
            listPackages: [],
            listChecked: {},
            startTime: undefined,
            endTime: undefined,
            packageCode: '',
            orderCode: '',
            trackingNumber: '',
            typeSearch: '',
            open: '',
            isLoadingDetail: false,
            // isMobile: window.innerWidth < 981,
            // isTablet: window.innerWidth < 1025
        }
    }

    componentDidMount() {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null
        // window.addEventListener('resize', this.windowResize);
        window.addEventListener('popstate', this.onPopState.bind(this))

        if (typeof LocalStore.getInstance().read('currentLoggedUser') === 'object') {
            this.setState({ isLoading: true }, () => {
                this.loadFromQueryString()
                this.getPackageStatuses()
                this.getParcelStatuses()
            })
        }
    }

    /**
     * Khi unmount ra thì bỏ sự kiện popstate
     */
    componentWillUnmount() {
        this.isUnmounted = true
        window.removeEventListener('popstate', this.onPopState.bind(this))
        // window.removeEventListener('resize', this.windowResize);
    }

    // /**
    //  * khi window resize
    //  */
    // windowResize = () => {
    //     const windowWidth = window.innerWidth;
    //     if (!this.isUnmounted) this.setState({
    //         isMobile: windowWidth < 981,
    //         isTablet: windowWidth < 1025
    //     })
    // };

    /**
     * On popstate
     */
    onPopState() {
        if (!this.isUnmounted)
            this.setState({ isLoading: true }, () => {
                this.loadFromQueryString()
            })
    }

    /**
     * Load từ QueryString (dành cho lần load đầu tiên)
     */
    loadFromQueryString = () => {
        let queryString = getUrlQueryString()
        let queryObj = parseQueryStringToObject(queryString) || {}

        // console.log('queryObj', queryObj);
        // chuyển giá trị từ queryObj vào state;
        let arrayValue = []
        //reset các param khi load từ url
        let listChecked = {}
        let packageCode = ''
        let trackingNumber = ''
        let orderCode = ''
        let startTime = undefined
        let endTime = undefined
        let handlingTimeFrom = ''
        let handlingTimeTo = ''
        let typeSearch = ''
        let cutOffStatus = ''
        for (let p in queryObj) {
            if (p === 'statuses') {
                // xử lý với obj list checked
                arrayValue = queryObj[p].split(',')
                // console.log('arrayValue', arrayValue)
                listChecked[p] = arrayValue
            } else if (p === 'createdFrom') {
                // xử lý với với timestamp
                startTime = decodeURIComponent(queryObj['createdFrom'])
            } else if (p === 'createdTo') {
                // xử lý với với timestamp
                endTime = decodeURIComponent(queryObj['createdTo'])
            } else if (p === 'handlingTimeFrom' || p === 'handlingTimeTo') {
                // xử lý với với timestamp
                if (p === 'handlingTimeFrom') {
                    handlingTimeFrom = decodeURIComponent(queryObj['handlingTimeFrom'])
                } else {
                    handlingTimeTo = decodeURIComponent(queryObj['handlingTimeTo'])
                }
            } else if (p === 'typeSearch' || p === 'cutOffStatus') {
                // xử lý với với timestamp
                typeSearch = decodeURIComponent(queryObj['typeSearch'])
                cutOffStatus = decodeURIComponent(queryObj['cutOffStatus'])
            } else {
                // xử lý với query
                packageCode = decodeURIComponent(queryObj['packageCode'] || '')
                trackingNumber = decodeURIComponent(queryObj['trackingNumber'] || '')
                orderCode = decodeURIComponent(queryObj['orderCode'] || '')
            }
        }

        this.setState(
            {
                packageCode,
                trackingNumber,
                orderCode,
                startTime,
                endTime,
                listChecked,
                handlingTimeFrom,
                handlingTimeTo,
                typeSearch,
                cutOffStatus,
                page: queryObj.page,
                pageSize: queryObj.size || pageSize,
            },
            () => {
                this.loadData()
            }
        )
    }

    /**
     * get note in detail order
     */

    /**
     * load data
     */
    loadData = cb => {
        const { t } = this.props
        const { page, pageSize } = this.state
        let queryString = this.buildQueryString()
        if (queryString) {
            queryString = queryString + `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        } else {
            queryString = `?page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        }
        this.setState({ showProgressBar: true }, () => {
            UserService.getCustomerPackages(queryString, (err, res, headers) => {
                if (!err) {
                    this.setState(
                        {
                            expandedRow: [],
                            listPackages: res,
                            metadata: {
                                pageCount: headers['x-page-count'],
                                page: headers['x-page-number'],
                                size: headers['x-page-size'],
                                total: headers['x-total-count'],
                            },
                        },
                        () => {
                            //window.scrollTo(0, 0);
                            if (this.mainLayoutElement && this.mainLayoutElement.scrollLayoutElement)
                                this.mainLayoutElement.scrollLayoutElement.scrollTop = 0
                        }
                    )
                    const queryWaybills = `?codes=${_.uniq(res?.map(i => i?.trackingNumber))?.toString()}`
                    UserService.getCustomerWaybills(queryWaybills, (err, response) => {
                        if (!err) {
                            this.setState({
                                listPackages: res?.map(i => {
                                    const element = response?.find(el => el?.code === i?.trackingNumber)
                                    if (element) {
                                        return {
                                            ...i,
                                            description: element?.description,
                                        }
                                    } else {
                                        return i
                                    }
                                }),
                            })
                        }
                    })
                } else {
                    notification['error']({
                        message: t(err.message) || t('message.unconnected_error'),
                    })
                }
                if (cb) cb()
                this.setState({ showProgressBar: false, isLoading: false })
            })
        })
    }

    onPageChanged = (page, pageSize) => {
        this.setState({ page: page }, () => {
            let locationQuery = lodash.get(this.props.location, 'search', '')
            let queryObj = locationQuery ? parseQueryStringToObject(locationQuery) : {}
            queryObj.page = page
            queryObj.size = pageSize
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: queryString.stringify(queryObj),
            })
            // window.scrollTo(0, 0);
        })
    }

    /**
     * lấy ra danh sách trạng thái của package
     */
    getPackageStatuses = () => {
        CategoriesService.getPackageStatuses((err, res, headers) => {
            if (err === null) {
                this.setState({
                    listPackageStatuses: res || [],
                })
            }
        })
    }

    /**
     * lấy ra danh sách trạng thái của package
     */
    getParcelStatuses = () => {
        CategoriesService.getShipmentParcelStatus((err, res, headers) => {
            if (err === null) {
                this.setState({
                    listParcelStatuses: res || [],
                })
            }
        })
    }

    /**
     * lấy ra danh sách comment theo paramCode
     */
    getOrderPackageMileStones = packageCode => {
        const { listPackageMileStones = {}, isLoadingPackageMileStones = {} } = this.state
        isLoadingPackageMileStones[packageCode] = true
        this.setState({ isLoadingPackageMileStones, showProgressBar: true }, () => {
            OrderServices.getOrderPackageMileStones(packageCode, (err, res, headers) => {
                isLoadingPackageMileStones[packageCode] = false
                if (err === null) {
                    listPackageMileStones[packageCode] = res || []
                    this.setState({
                        isLoadingPackageMileStones,
                        showProgressBar: false,
                        listPackageMileStones,
                    })
                } else {
                    this.setState({ showProgressBar: false, isLoadingPackageMileStones })
                }
            })
        })
    }

    /**
     * lấy ra danh sách comment theo paramCode
     */
    getParcelMileStone = packageCode => {
        const { listPackageMileStones = {}, isLoadingPackageMileStones = {} } = this.state
        isLoadingPackageMileStones[packageCode] = true
        this.setState({ isLoadingPackageMileStones, showProgressBar: true }, () => {
            ShipmentsService.getParcelMileStone(packageCode, (err, res, headers) => {
                isLoadingPackageMileStones[packageCode] = false
                if (err === null) {
                    listPackageMileStones[packageCode] = res || []
                    this.setState({
                        isLoadingPackageMileStones,
                        showProgressBar: false,
                        listPackageMileStones,
                    })
                } else {
                    this.setState({ showProgressBar: false, isLoadingPackageMileStones })
                }
            })
        })
    }

    /**
     * lấy ra prop giá trị của obj prop
     * @param props
     * @returns {*}
     */
    getValuePropOfProp = props => {
        switch (props) {
            case 'statuses':
                return 'code'
            default:
                return 'id'
        }
    }

    /**
     * xây dựng query string theo các giá trị trong state
     * @returns {string}
     */
    buildQueryString = () => {
        let {
            listChecked,
            startTime,
            endTime,
            orderCode,
            packageCode,
            trackingNumber,
            typeSearch,
            cutOffStatus,
            handlingTimeFrom,
            handlingTimeTo,
        } = this.state
        let queryString = ''
        for (let p in listChecked) {
            let value = ''
            if (Array.isArray(listChecked[p]) && listChecked[p].length > 0) {
                listChecked[p].map(x => {
                    value = value + x + ','
                })

                value = value.slice(0, -1)
            }
            if (value) queryString = updateQueryStringParameter(queryString, p, value)
        }

        if (startTime) {
            queryString = updateQueryStringParameter(queryString, 'createdFrom', startTime)
        }

        if (endTime) {
            queryString = updateQueryStringParameter(queryString, 'createdTo', endTime)
        }

        if (orderCode) {
            queryString = updateQueryStringParameter(queryString, 'orderCode', orderCode.trim())
        }

        if (trackingNumber) {
            queryString = updateQueryStringParameter(queryString, 'trackingNumber', trackingNumber.trim())
        }

        if (packageCode) {
            queryString = updateQueryStringParameter(queryString, 'packageCode', packageCode.trim())
        }

        if (handlingTimeFrom || handlingTimeTo) {
            queryString = updateQueryStringParameter(queryString, 'typeSearch', typeSearch)
            queryString = updateQueryStringParameter(queryString, 'cutOffStatus', cutOffStatus)
            if (handlingTimeFrom) {
                queryString = updateQueryStringParameter(queryString, 'handlingTimeFrom', handlingTimeFrom)
            }
            if (handlingTimeTo) {
                queryString = updateQueryStringParameter(queryString, 'handlingTimeTo', handlingTimeTo)
            }
        }

        return queryString
    }

    onClearSearch = () => {
        this.setState(
            {
                page: 1,
                orderCode: '',
                packageCode: '',
                trackingNumber: '',
                startTime: undefined,
                endTime: undefined,
                listChecked: {},
                cutOffStatus: '',
                handlingTimeFrom: '',
                handlingTimeTo: '',
                typeSearch: '',
            },
            () => {
                this.props.history.push({
                    pathname: this.props.location.pathname,
                    search: this.buildQueryString(),
                })
            }
        )
    }

    /**
     * xử lý với dữ liệu search trong state
     */
    onSubmitSearch = () => {
        this.setState({ page: 1 }, () => {
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: this.buildQueryString(),
            })
        })
    }

    startTimeChange = date => {
        this.setState({
            startTime: date ? date.startOf('day').toISOString() : undefined,
        })
    }

    endTimeChange = date => {
        this.setState({
            endTime: date ? date.endOf('day').toISOString() : undefined,
        })
    }

    disabledEndDate = endValue => {
        const startValue = this.state.startValue
        if (!endValue || !startValue) {
            return false
        }
        return endValue.valueOf() <= startValue.valueOf()
    }

    onCheckBoxCheck = (item, prop) => {
        let { listChecked } = this.state

        let arrayPropChecked = listChecked[prop] || []
        if (arrayPropChecked.find(x => x === item[this.getValuePropOfProp(prop)])) {
            arrayPropChecked = arrayPropChecked.filter(x => x !== item[this.getValuePropOfProp(prop)])
        } else {
            arrayPropChecked.push(item[this.getValuePropOfProp(prop)])
        }

        listChecked[prop] = arrayPropChecked

        this.setState({
            listChecked,
        })
    }

    /**
     * export excel data
     */
    exportExcel = (password, cb, setError) => {
        const { t } = this.props
        const { page, pageSize } = this.state
        let queryString = this.buildQueryString()
        if (queryString) {
            queryString = queryString + `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        } else {
            queryString = `?page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        }

        if (!password) {
            if (setError) setError(t('cartCheckout.incorrect_pin'))
            return
        }

        this.setState({ showProgressBar: true, onExport: true }, () => {
            UserService.exportCsvPackages(queryString, { secret: password }, (err, res, headers) => {
                if (!err) {
                    this.downloadFile(res)
                    if (cb) cb()
                } else {
                    if (err.title === 'invalid_pin' || err.title === 'invalid_password') {
                        if (setError) setError(t('cartCheckout.incorrect_pin'))
                    }
                }
                this.setState({ showProgressBar: false, onExport: false })
            })
        })
    }

    downloadFile = res => {
        if (res && res.headers && res.headers.map) {
            const fileName = res.headers.map['content-disposition'].split('filename=')[1]
            saveAs(res._bodyBlob, fileName)
        }
    }

    /**
     * render orderStatus Search
     * @param item
     * @param index
     * @param prop
     */
    renderStatusSearchItem = (item, index, prop) => {
        const arrayPropChecked = this.state.listChecked[prop] || []
        // console.log('arrayPropChecked', arrayPropChecked)
        let checked =
            Array.isArray(arrayPropChecked) && arrayPropChecked.find(x => x === item[this.getValuePropOfProp(prop)]) !== undefined
        return (
            <Tag.CheckableTag
                key={index}
                onChange={() => this.onCheckBoxCheck(item, prop)}
                className={`_check-${prop}-item cursor-pointer txt-size-h7 robotoregular txt-capitalize box-shadow-blue pdl10 pdr10`}
                checked={checked}
            >
                <Typography.Link style={checked ? { color: '#ffffff' } : {}}>{item.name}</Typography.Link>
            </Tag.CheckableTag>
        )
    }

    renderInputFromTypeSearch = typeSearch => {
        const { t } = this.props
        const { handlingTimeFrom, handlingTimeTo } = this.state
        switch (typeSearch) {
            case 'range':
                return (
                    <Input.Group compact>
                        <Input
                            style={{ width: '50%' }}
                            placeholder={t('order.cut_off_days_from')}
                            value={handlingTimeFrom}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    this.onSubmitSearch()
                                }
                            }}
                            onChange={e => this.setState({ handlingTimeFrom: e.target.value })}
                        />
                        <Input
                            style={{ width: '50%' }}
                            placeholder={t('order.cut_off_days_to')}
                            value={handlingTimeTo}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    this.onSubmitSearch()
                                }
                            }}
                            onChange={e => this.setState({ handlingTimeTo: e.target.value })}
                        />
                    </Input.Group>
                )
            case 'equal':
                return (
                    <Input
                        placeholder={t('order.cut_off_days')}
                        value={handlingTimeFrom}
                        onChange={e =>
                            this.setState({
                                handlingTimeFrom: e.target.value,
                                handlingTimeTo: e.target.value,
                            })
                        }
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                this.onSubmitSearch()
                            }
                        }}
                    />
                )
            case 'from':
                return (
                    <Input
                        placeholder={t('order.cut_off_days')}
                        value={handlingTimeFrom}
                        onChange={e =>
                            this.setState({
                                handlingTimeFrom: e.target.value,
                            })
                        }
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                this.onSubmitSearch()
                            }
                        }}
                    />
                )
            case 'to':
                return (
                    <Input
                        placeholder={t('order.cut_off_days')}
                        value={handlingTimeTo}
                        onChange={e =>
                            this.setState({
                                handlingTimeTo: e.target.value,
                            })
                        }
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                this.onSubmitSearch()
                            }
                        }}
                    />
                )
            default:
                return (
                    <Input.Group compact>
                        <Input
                            style={{ width: '50%' }}
                            placeholder={t('order.cut_off_days_from')}
                            value={handlingTimeFrom}
                            onChange={e => this.setState({ handlingTimeFrom: e.target.value })}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    this.onSubmitSearch()
                                }
                            }}
                        />
                        <Input
                            style={{ width: '50%' }}
                            placeholder={t('order.cut_off_days_to')}
                            value={handlingTimeTo}
                            onChange={e => this.setState({ handlingTimeTo: e.target.value })}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    this.onSubmitSearch()
                                }
                            }}
                        />
                    </Input.Group>
                )
        }
    }

    /**
     * render header page
     * @returns {*}
     */
    renderSearchBox = () => {
        const {
            listPackageStatuses = [],
            listParcelStatuses = [],
            startTime,
            endTime,
            cutOffStatus,
            typeSearch,
            orderCode,
            packageCode,
            trackingNumber,
        } = this.state
        const { t } = this.props
        let listStatuses = _.merge(listPackageStatuses, listParcelStatuses)

        return (
            <div className="searchbox bg-color-white pd20 mgbt20 rounded-xl">
                <Row className={'borderdotted-bootom-2x bd-color-gray pdbt20'}>
                    <Col
                        span={24}
                        className="dpl-flex"
                    >
                        <span className={'searchbox__label txt-color-black txt-size-h7 robotoregular'}>
                            {t('delivery.status')}:
                        </span>
                        <div className={'txt-size-h7 txt-color-blue robotoregular txt-capitalize width-100-percent flex-1-1'}>
                            {listStatuses.map((item, index) => this.renderStatusSearchItem(item, index, 'statuses'))}
                        </div>
                    </Col>
                </Row>
                <Row className={'dpl-flex bd-color-gray borderdotted-bootom-2x pdbt20 pdt15'}>
                    <Col
                        span={4}
                        className="pdr10"
                    >
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('customer_info.input_order_code')}:
                        </div>
                        <Input
                            value={orderCode}
                            onChange={e => this.setState({ orderCode: e.target.value })}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    this.onSubmitSearch()
                                }
                            }}
                            className={'_search-box-input-query'}
                        />
                    </Col>
                    <Col
                        span={4}
                        className="pdr10"
                    >
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('package.input_package_code')}:
                        </div>
                        <Input
                            value={packageCode}
                            onChange={e => this.setState({ packageCode: e.target.value })}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    this.onSubmitSearch()
                                }
                            }}
                            className={'_search-box-input-query'}
                        />
                    </Col>
                    <Col
                        span={4}
                        className="pdr10"
                    >
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('package.input_waybill_code')}:
                        </div>
                        <Input
                            value={trackingNumber}
                            onChange={e => this.setState({ trackingNumber: e.target.value })}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    this.onSubmitSearch()
                                }
                            }}
                            className={'_search-box-input-query'}
                        />
                    </Col>
                    <Col
                        span={12}
                        className="pdl10"
                    >
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('package.time')}:
                        </div>
                        {/*<DatePicker.RangePicker*/}
                        {/*className={'_search-date-time width-100-percent'}*/}
                        {/*format={'DD/MM/YYYY'}*/}
                        {/*value={datetimeRange.length > 0 ? [moment(datetimeRange[0]), moment(datetimeRange[1])] : []}*/}
                        {/*onChange={this.dateTimeChange}*/}
                        {/*/>*/}
                        <Row gutter={20}>
                            <Col span={12}>
                                <DatePicker
                                    disabledDate={this.disabledStartDate}
                                    className="_datepicker width-100-percent"
                                    format={'DD/MM/YYYY'}
                                    value={startTime ? moment(startTime) : undefined}
                                    placeholder={t('package.start_date')}
                                    onChange={this.startTimeChange}
                                    // onOpenChange={this.handleStartOpenChange}
                                />
                            </Col>
                            <Col span={12}>
                                <DatePicker
                                    disabledDate={this.disabledEndDate}
                                    className="_datepicker width-100-percent"
                                    format={'DD/MM/YYYY'}
                                    value={endTime ? moment(endTime) : undefined}
                                    placeholder={t('package.end_date')}
                                    onChange={this.endTimeChange}
                                    // onOpenChange={this.handleEndOpenChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className={'pdbt15 mgbt10 borderdotted-bootom-2x bd-color-gray'}>
                    <Col
                        span={24}
                        className="mgt10"
                    >
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('order.package_cut_off_time')}:
                        </div>

                        <Row
                            gutter={10}
                            className=""
                        >
                            <Col span={6}>
                                <Select
                                    className="flex-none width-100-percent"
                                    showSearch
                                    placeholder={t('order.order_status')}
                                    optionFilterProp="children"
                                    value={cutOffStatus || []}
                                    onChange={value => this.setState({ cutOffStatus: value })}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {listStatuses.map((item, index) => (
                                        <Select.Option
                                            key={index}
                                            value={item.code}
                                        >
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Col>

                            <Col span={6}>
                                <Select
                                    className="flex-none width-100-percent"
                                    showSearch
                                    placeholder={t('order.cut_off_range')}
                                    optionFilterProp="children"
                                    value={typeSearch || []}
                                    onChange={value =>
                                        this.setState({
                                            typeSearch: value,
                                            handlingTimeFrom: '',
                                            handlingTimeTo: '',
                                        })
                                    }
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option value="range">{t('order.cut_off_range')}</Select.Option>
                                    <Select.Option value="equal">{t('order.cut_off_equal')}</Select.Option>
                                    <Select.Option value="from">{t('order.cut_off_from')}</Select.Option>
                                    <Select.Option value="to">{t('order.cut_off_to')}</Select.Option>
                                </Select>
                            </Col>

                            <Col span={12}>{this.renderInputFromTypeSearch(typeSearch)}</Col>
                        </Row>
                    </Col>
                </Row>
                <div className=" dpl-flex align-items-center justify-content-between pdt10">
                    <div />
                    <div className="dpl-flex align-items-center">
                        <span
                            onClick={this.onClearSearch}
                            className="_btn-clear mgr20 txt-color-gray txt-size-h8 cursor-pointer robotoregular"
                        >
                            <AntdIcon.SyncOutlined
                                type={'sync'}
                                className={'mgr5'}
                            />{' '}
                            {t('order.filter_refresh')}
                        </span>
                        <Button
                            onClick={this.onSubmitSearch}
                            type="primary"
                            className={'_btn-search min-width-200'}
                        >
                            {t('order.search')}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    renderSteps = record => {
        const {
            listPackageMileStones = {},
            isLoadingPackageMileStones = {},
            listPackageStatuses = [],
            listParcelStatuses = [],
        } = this.state
        let listStatuses = record.isShipment ? listParcelStatuses : listPackageStatuses

        if (isLoadingPackageMileStones[record.code])
            return (
                <div className="dpl-flex align-items-center justify-content pdt10 pdbt10">
                    <Spin
                        indicator={
                            <AntdIcon.Loading3QuartersOutlined
                                type="loading"
                                style={{ fontSize: 24 }}
                                spin
                            />
                        }
                    />
                </div>
            )
        const { t } = this.props
        const current = listStatuses.findIndex(x => x.code === record.status)
        const currentPackageStatus = listStatuses.find(x => x.code === record.status) || {}
        const listMileStones = listPackageMileStones[record.code] || []

        let currentMileStone = []

        if (!currentPackageStatus.negativeEnd) {
            return (
                <Steps
                    className={'custom-step flex-wrap v2-steps'}
                    size={'small'}
                    current={current || 0}
                    progressDot={(icon, context) => {
                        return context?.status === 'finish' || context?.status === 'process' ? (
                            <i
                                class={`fa-regular fa-circle-dot ${
                                    context?.status === 'finish' ? 'text-cornflower-500' : 'text-blue-primary'
                                }`}
                            ></i>
                        ) : (
                            <>{/* <i class="fa-regular fa-circle text-cornflower-500"></i> */}</>
                        )
                    }}
                    direction={'horizontal'}
                >
                    {listStatuses
                        .filter(x => !x.negativeEnd)
                        .map(x => {
                            currentMileStone = listMileStones.filter(y => y.status === x.code)
                            moment(_.sortBy(currentMileStone, 'timestamp').reverse()).format('HH:mm DD/MM')
                            let dayOrDays = true
                            let checkNull = false
                            let day = ''
                            if (currentMileStone.length > 0) {
                                currentMileStone.map(item => {
                                    if (item.handlingTime === 0) {
                                        dayOrDays = false
                                    } else dayOrDays = true
                                    if (item.handlingTime === null) {
                                        checkNull = true
                                    } else {
                                        checkNull = false
                                    }
                                })
                            }
                            if (dayOrDays && !checkNull) {
                                day = t('label.days')
                            } else if (!dayOrDays && !checkNull) {
                                day = t('label.day')
                            }
                            return (
                                <Steps.Step
                                    key={x.id}
                                    title={x.name}
                                    description={
                                        currentMileStone.length > 0
                                            ? currentMileStone.map((item, y) => {
                                                  return (
                                                      <Col className="grid items-center justify-center">
                                                          <Row className=" font-thin">
                                                              {moment(item.timestamp).format('HH:mm DD/MM/YYYY')}
                                                          </Row>
                                                          <Row className=" font-thin block">
                                                              {item.handlingTime === null
                                                                  ? '(' + t('orderDetail.undefined') + ')'
                                                                  : '(' + item.handlingTime + ' ' + day + ')'}
                                                          </Row>
                                                      </Col>
                                                  )
                                              })
                                            : t('orderDetail.undefined')
                                    }
                                />
                            )
                        })}
                </Steps>
            )
        } else {
            // trường hợp trạng thái cuối cùng của package là thất lạc
            let newArray = _.sortBy(
                listMileStones.filter(x => x.status !== currentPackageStatus.code),
                'timestamp'
            ).reverse()
            const lastMileStones = newArray.length > 0 ? newArray[0] : null
            const lastMileStonesIndex = lastMileStones ? listStatuses.findIndex(x => x.code === lastMileStones.status) : 0
            // tạo ra mảng chỉ chứa các phần tử từ đầu đến lastActiveStatus
            const newListPackageStatuses = _.cloneDeep(listStatuses).slice(0, lastMileStonesIndex + 1)

            const mileStonesNegativeEnd = listMileStones.filter(y => y.status === currentPackageStatus.code)

            return (
                <Steps
                    className="custom-step flex-wrap v2-steps"
                    size={'small'}
                    current={lastMileStonesIndex + 1}
                    progressDot
                    direction={'horizontal'}
                    status={'error'}
                >
                    {lastMileStones &&
                        newListPackageStatuses
                            .filter(x => !x.negativeEnd)
                            .map(x => {
                                currentMileStone = listMileStones.filter(y => y.status === x.code)
                                let dayOrDays = true
                                let day = ''
                                let checkNull = false
                                if (currentMileStone.length > 0) {
                                    currentMileStone.map(item => {
                                        if (item.handlingTime === 0) {
                                            dayOrDays = false
                                        } else dayOrDays = true
                                        if (item.handlingTime === null) {
                                            checkNull = true
                                        } else {
                                            checkNull = false
                                        }
                                    })
                                }
                                if (dayOrDays && !checkNull) {
                                    day = t('label.days')
                                } else if (!dayOrDays && !checkNull) {
                                    day = t('label.day')
                                }
                                moment(_.sortBy(currentMileStone, 'timestamp').reverse()).format('HH:mm DD/MM')
                                return (
                                    <Steps.Step
                                        key={x.id}
                                        title={x.name}
                                        description={
                                            currentMileStone.length > 0
                                                ? currentMileStone.map((item, y) => {
                                                      return (
                                                          <Col>
                                                              <Row
                                                                  style={y === 0 ? { fontWeight: 'bold' } : { fontWeight: '100' }}
                                                              >
                                                                  {moment(item.timestamp).format('HH:mm DD/MM')}
                                                              </Row>
                                                              <Row
                                                                  style={y === 0 ? { fontWeight: 'bold' } : { fontWeight: '100' }}
                                                              >
                                                                  {item.handlingTime === null
                                                                      ? '( ' + t('orderDetail.undefined') + ')'
                                                                      : '( ' + item.handlingTime + ' ' + day + ')'}
                                                              </Row>
                                                          </Col>
                                                      )
                                                  })
                                                : t('orderDetail.undefined')
                                        }
                                    />
                                )
                            })}
                    <Steps.Step
                        key={currentPackageStatus.id}
                        title={currentPackageStatus.name}
                        description={
                            mileStonesNegativeEnd.length > 0
                                ? moment(_.sortBy(mileStonesNegativeEnd, 'timestamp').reverse()[0].timestamp).format(
                                      'HH:mm DD/MM'
                                  )
                                : t('orderDetail.undefined')
                        }
                    />
                </Steps>
            )
        }
    }

    render() {
        const {
            listPackages,
            isLoading,
            metadata,
            listPackageStatuses = [],
            listParcelStatuses = [],
            expandedRow = [],
            onExport,
            open,
            isLoadingDetail,
        } = this.state
        const { t } = this.props
        const tenant = LocalStore.getInstance().read('currentProjectInfo')
        const showPackageNote = lodash.get(tenant, 'tenantConfig.generalConfig.showPackageNote', false)

        const columns = [
            {
                title: t('delivery.package_code'),
                dataIndex: 'code',
                key: 'code',
                render: (text, record) => {
                    return (
                        <div>
                            <div className="mb-1">
                                <Typography.Paragraph
                                    style={{ marginBottom: 0 }}
                                    copyable={{ text: text }}
                                    className="whitespace-nowrap"
                                >
                                    <span className="_table-row-code txt-uppercase whitespace-pre-wrap">{text}</span>
                                </Typography.Paragraph>
                                {(record?.description || record?.note) && (
                                    <div>
                                        <Popover
                                            placement="rightTop"
                                            getPopupContainer={trigger => trigger.parentNode}
                                            content={
                                                <>
                                                    <div className=" text-base font-medium mb-4">
                                                        {t('package.notePackage')}{' '}
                                                        <span className="text-blue-primary">{text}</span>
                                                    </div>
                                                    {showPackageNote && record?.note && (
                                                        <>
                                                            <Skeleton
                                                                active
                                                                loading={isLoadingDetail}
                                                                paragraph={{ rows: 1 }}
                                                            >
                                                                <div className="text-sm text-grey-400 mb-4">
                                                                    <span className="text-black-100">{t('Ghi chú')}:</span>{' '}
                                                                    {record?.note || t('message.no_data')}
                                                                </div>
                                                            </Skeleton>
                                                        </>
                                                    )}

                                                    {record?.description && (
                                                        <>
                                                            <Skeleton
                                                                active
                                                                loading={isLoadingDetail}
                                                                paragraph={{ rows: 1 }}
                                                            >
                                                                <div className="text-sm text-grey-400 mb-4">
                                                                    <span className="text-black-100">
                                                                        {t('Ghi chú cá nhân')}:
                                                                    </span>{' '}
                                                                    {record?.description || t('message.no_data')}
                                                                </div>
                                                            </Skeleton>
                                                        </>
                                                    )}
                                                    <div>
                                                        <Button
                                                            type="primary"
                                                            size="middle"
                                                            className="w-full"
                                                            onClick={() => this.setState({ open: null })}
                                                        >
                                                            {t('button.close')}
                                                        </Button>
                                                    </div>
                                                </>
                                            }
                                            overlayClassName="v2-popover v2-layout"
                                            trigger="click"
                                            open={open === text}
                                            onOpenChange={value => {
                                                this.setState({ open: value ? text : null })
                                            }}
                                        >
                                            <span className="text-blue-primary cursor-pointer underline">
                                                {t('delivery.note')}
                                            </span>
                                        </Popover>
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                },
            },
            {
                title: t('waybill.waybill_code'),
                dataIndex: 'trackingNumber',
                key: 'trackingNumber',
                render: text => (
                    <Typography.Paragraph
                        style={{ marginBottom: 0 }}
                        copyable={{ text: text }}
                    >
                        <span className="_table-row-waybill-code txt-uppercase whitespace-pre-wrap ">{text}</span>
                    </Typography.Paragraph>
                ),
            },
            {
                title: t('package.order_code'),
                dataIndex: 'orderCode',
                key: 'orderCode',
                className: 'whitespace-pre-wrap',
                render: (text, row) => (
                    <Typography.Paragraph
                        style={{ marginBottom: 0 }}
                        copyable={{ text: text }}
                    >
                        <Link
                            to={row.isShipment ? `/shipments/${text}` : `/orders/${text}`}
                            className="_table-row-orderCode txt-uppercase font-medium"
                        >
                            {text}
                        </Link>
                    </Typography.Paragraph>
                ),
            },
            {
                title: <span>{t('package.information')} </span>,
                dataIndex: 'information',
                key: 'information',
                className: 'whitespace-pre-wrap text-right',
                render: (text, record) => {
                    return (
                        <>
                            <div>
                                <Space>
                                    <span className="_table-row-volume">
                                        {record?.volumetric
                                            ? quantityFormat(record?.volumetric) + ' cm3'
                                            : t('orderDetail.undefined')}
                                    </span>
                                    <Tooltip
                                        placement="top"
                                        title={
                                            <Space
                                                size={2}
                                                className="text-xs"
                                            >
                                                <span>{t('package.length')}</span>
                                                <i className="fa-regular text-[10px] fa-xmark"></i>
                                                <span>{t('package.width')}</span>
                                                <i className="fa-regular text-[10px] fa-xmark"></i>
                                                <span>{t('package.height')}</span>
                                            </Space>
                                        }
                                    >
                                        <AntdIcon.QuestionCircleOutlined
                                            type="question-circle"
                                            theme="filled"
                                            className={'txt-size-h8 txt-color-gray opacity6'}
                                        />
                                    </Tooltip>
                                </Space>
                            </div>

                            <Space
                                size={2}
                                className="whitespace text-xs text-grey-400 font-medium"
                            >
                                <span>{Number.isFinite(record.length) ? `${record.length} cm` : '---'}</span>
                                <i className="fa-regular text-[10px] fa-xmark"></i>
                                <span>{Number.isFinite(record.width) ? `${record.width} cm` : '---'}</span>
                                <i className="fa-regular text-[10px] fa-xmark"></i>
                                <span>{Number.isFinite(record.height) ? `${record.height} cm` : '---'}</span>
                            </Space>
                        </>
                    )
                },
            },
            {
                title: t('package.weight'),
                dataIndex: 'weight',
                key: 'weight',
                className: 'whitespace-pre-wrap text-right',
                render: (text, record) => {
                    const weight = record.actualWeight //Math.max(record.actualWeight, record.dimensionalWeight);
                    return (
                        <span className="_table-row-weight">
                            {Number.isFinite(weight) ? `${quantityFormat(weight)} kg` : t('orderDetail.undefined')}
                        </span>
                    )
                },
            },
            {
                title: t('package.created_at'),
                dataIndex: 'createdAt',
                key: 'createdAt',
                className: 'whitespace-pre-wrap text-right',
                render: text => <span className="_table-row-createdAt">{moment(text).format('DD/MM/YYYY')}</span>,
            },
            {
                title: t('package.status'),
                dataIndex: 'status',
                key: 'status',
                className: 'whitespace-pre-wrap text-right',
                render: (text, row) => {
                    let itemStatus = {}
                    if (row.isShipment) {
                        itemStatus = listParcelStatuses.find(x => x.code === text) || {}
                    } else {
                        itemStatus = listPackageStatuses.find(x => x.code === text) || {}
                    }
                    return (
                        <Tag
                            style={{ backgroundColor: itemStatus.color || '#898989' }}
                            className={'_table-row-status listorders__items_right--status'}
                        >
                            {itemStatus.name}
                        </Tag>
                    )
                },
            },
        ]

        return (
            <MainLayout
                ref={ref => (this.mainLayoutElement = ref)}
                {...this.props}
                showProgressBar={this.state.showProgressBar}
                headerTitle={t('package.title')}
                title={t('package.title')}
            >
                <div className={'container-common pdbt30 mgr20'}>
                    {this.renderSearchBox()}

                    <div className="pd20 bg-color-white  rounded-xl">
                        <div className="dpl-flex justify-content-between align-items-center v2-layout">
                            {metadata ? (
                                <Space
                                    align="center"
                                    size={2}
                                >
                                    <span className={'txt-color-black txt-size-h5 robotobmedium'}>{t('package.title')}</span>
                                    <Badge count={quantityFormat(metadata.total)} />
                                </Space>
                            ) : (
                                <span />
                            )}

                            <ExportExcel
                                {...this.props}
                                onExport={onExport}
                                exportExcel={this.exportExcel}
                            />
                        </div>
                        {Array.isArray(listPackages) && listPackages.length > 0 ? (
                            <Table
                                ref={ref => (this.table = ref)}
                                className={'table-v2 custom-empty table-expand-row'}
                                rowKey={'code'}
                                rowClassName={(record, index) => index % 2 !== 0 && 'table-row-odd'}
                                onRow={key => {
                                    return expandedRow.includes(key?.code) && { className: 'table-row-expanded' }
                                }}
                                columns={columns}
                                dataSource={listPackages}
                                expandedRowKeys={expandedRow}
                                expandedRowRender={this.renderSteps}
                                expandIcon={expand => {
                                    return (
                                        <i
                                            class={`fa-solid fa-chevron-${
                                                expand.expanded ? 'down' : 'right'
                                            } text-grey-400 cursor-pointer hover:text-black-100`}
                                            onClick={e => expand.onExpand(expand.record, e)}
                                        />
                                    )
                                }}
                                onExpand={(expanded, record) => {
                                    if (expanded) {
                                        this.setState({
                                            expandedRow: [record.code],
                                        })
                                        if (record.isShipment) {
                                            this.getParcelMileStone(record.code)
                                        } else {
                                            this.getOrderPackageMileStones(record.code)
                                        }
                                    } else {
                                        this.setState({
                                            expandedRow: [],
                                        })
                                    }
                                }}
                                pagination={{
                                    className: '_pagination text-center width-100-percent mgt20',
                                    hideOnSinglePage: true,
                                    current: metadata ? parseFloat(metadata.page) + 1 : 1,
                                    total: metadata ? parseFloat(metadata.total) : 1,
                                    pageSize: metadata ? parseFloat(metadata.size) : pageSize,
                                    onChange: (page, pageSize) => this.onPageChanged(page, pageSize),
                                }}
                            />
                        ) : isLoading ? (
                            <Loading />
                        ) : (
                            <div className="pdt50 pdbt60 bg-color-white">
                                <Empty
                                    image={require('../../resources/images/empty-image.png')}
                                    description={t('message.empty')}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </MainLayout>
        )
    }
}
export default withNamespaces()(Packages)
