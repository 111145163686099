import { PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Image, Radio, Skeleton } from 'antd'
import React from 'react'
import lodash from 'lodash'
import { FloatLabel } from '../../../../components/commons/Select'
import SuperSelect from '../../../../components/commons/Select/SuperSelect'

interface AddressChinaProps {
    showModalCreate: () => void
    ListAddressChina: any
    showModalListAddress: () => void
    showModalEditAddress: (item: any) => void
    handleSelectedAddress: (item: string) => void
    handleSetAccountsConnectSelected: (item: string) => void
    selectedAddress: string | undefined
    accountsConnect: any
    accountsConnectSelected: any
    loadingAddress: boolean
    t: any
}
const AddressChina: React.FC<AddressChinaProps> = props => {
    const {
        showModalCreate,
        ListAddressChina,
        showModalEditAddress,
        handleSelectedAddress,
        handleSetAccountsConnectSelected,
        selectedAddress,
        accountsConnect,
        accountsConnectSelected,
        loadingAddress,
        t,
    } = props

    return (
        <div className="bg-white rounded-xl pt-4 pb-6 px-7 v2-layout">
            <div className="flex justify-between">
                <div className="text-black-100 mt-4 flex items-center gap-3 text-base font-medium ">
                    {' '}
                    <i className="fa-light fa-location-dot" /> {t('customerAddress.title')}
                </div>
                <Button
                    icon={<PlusOutlined />}
                    className="btn-outline rounded"
                    onClick={showModalCreate}
                >
                    {t('taobaoGlobalCart.addNewAddress')}
                </Button>
            </div>
            {!loadingAddress ? (
                <>
                    {ListAddressChina?.length > 0 ? (
                        <>
                            <div className="grid grid-cols-4 gap-5 mt-4 overflow-x-auto max-h-[320px] pr-2">
                                {ListAddressChina?.map((item: any) => {
                                    const recipient = lodash.get(item, 'recipient') || '---'
                                    const defaultAddress = item?.defaultAddress
                                    const state = lodash.get(item, 'state') || '---'
                                    const addressDetail = lodash.get(item, 'address1') || '---'
                                    const id = lodash.get(item, 'id') || '---'
                                    const district = lodash.get(item, 'district') || '---'
                                    const city = lodash.get(item, 'city') || '---'
                                    const fullAddress = `${addressDetail} - ${district} - ${city} - ${state}`
                                    const checked = (!selectedAddress && defaultAddress) || selectedAddress === item?.id

                                    return (
                                        <div
                                            key={item?.id}
                                            onClick={() => handleSelectedAddress(item?.id)}
                                            className={`p-4 rounded-xl  cursor-pointer min-h-[140px] border-[1px] border-solid 
                                                        ${
                                                            checked
                                                                ? 'border-blue-primary bg-blue-alice-50'
                                                                : 'hover:bg-alabaster-50  border-gallery-100'
                                                        }`}
                                        >
                                            <div className="grid">
                                                <div className="flex gap-2 items-baseline">
                                                    <Radio
                                                        className="m-0"
                                                        value={id}
                                                        checked={checked}
                                                        onChange={() => handleSelectedAddress(id)}
                                                    />
                                                    <span
                                                        className={`${
                                                            checked
                                                                ? 'text-blue-primary text-base font-medium'
                                                                : 'text-black-100 text-base font-medium'
                                                        }`}
                                                    >
                                                        {recipient}
                                                    </span>
                                                    {checked ? (
                                                        <i
                                                            className="hover:cursor-pointer fa-regular fa-pen-to-square text-blue-primary"
                                                            onClick={() => showModalEditAddress(item)}
                                                        ></i>
                                                    ) : (
                                                        <div></div>
                                                    )}
                                                </div>
                                                {/* <Tooltip title={fullAddress}> */}
                                                <div className="mt-2 ml-6 flex gap-4 ">{fullAddress}</div>
                                                {/* </Tooltip> */}
                                                {defaultAddress && (
                                                    <div className="mt-[10px] ml-6 flex gap-4">
                                                        <span className="px-3 py-[2px] text-blue-primary border-solid rounded-2xl border border-blue-primary">
                                                            {t('customerAddress.default')}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <Divider style={{ margin: '20px 0' }} />
                            <div className="mb-3 ">
                                <span className="text-black-100 robotomedium text-base block">
                                    <i className="fa-light fa-bag-shopping mr-3" />
                                    {t('taobaoGlobalCart.accountBuyer')}
                                </span>
                            </div>
                            <div className="form-select w-[250px]">
                                <FloatLabel
                                    label={t('taobaoGlobalCart.accountTaobao')}
                                    value={accountsConnectSelected}
                                >
                                    <SuperSelect
                                        keyCustomize="tbgAccount"
                                        onChange={(e: any) => handleSetAccountsConnectSelected(e)}
                                        className={'_select-bank w-full'}
                                        selectedValue={accountsConnectSelected}
                                        options={accountsConnect}
                                        renderOptionLabel={(item: any) => (
                                            <>
                                                <span className={`_service-name txt-size-h8 mr-1 robotoregular txt-color-black`}>
                                                    {item.name}
                                                </span>
                                            </>
                                        )}
                                    />
                                </FloatLabel>
                            </div>
                        </>
                    ) : (
                        <div
                            style={{ margin: '0 auto' }}
                            className="grid items-center justify-center mt-5 mb-5"
                        >
                            <Image
                                rootClassName="image-center"
                                style={{ width: '250px', maxWidth: '250px' }}
                                preview={false}
                                src={require('../../../../resources/images/addressChinaEmpty.png')}
                            />
                            <span className="mt-6 text-grey-500 text-center text-base font-normal">
                                {t('taobaoGlobalCart.addressEmpty')}
                            </span>
                            <Button
                                className="mt-6 rounded text-blue-primary border-blue-primary"
                                size="large"
                                icon={<PlusOutlined />}
                                onClick={showModalCreate}
                            >
                                {t('taobaoGlobalCart.addNewAddress')}
                            </Button>
                        </div>
                    )}
                </>
            ) : (
                <>
                    <Skeleton />
                </>
            )}
        </div>
    )
}

export default AddressChina
