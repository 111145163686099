/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Divider, Radio, Row, Spin, Tooltip, Space } from 'antd'
import { moneyCeil, moneyFormat, quantityFormat } from '../../utils/stringUtils'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { modifyArrayDataSource } from '../../utils/arrayUltils'
import { LocalStore } from '../../utils/LocalStore'
import { CurrentProjectInfo } from '../../types/CurrentProjectInfo'
import lodash from 'lodash'
import { momeyToPercent, percentToMoney } from '../../utils/helper'
import DepositModal from '../../components/DepositModal'
import _ from 'lodash'
import ModalVoucher from './ModalVoucher'

interface Props {
    draftOrder: any
    balanceObj: any
    balance: any
    t: Function
    totalLoanAmount: number
    feeLoansAmount: number
    isDraftOrderLoanable: boolean
    isEnabledBiffin?: boolean
    isConnectedBiffin?: boolean
    getBiffinConnect: Function
    orderLoading?: boolean
    onCreateOrder: Function
    onSignUpBiffin: Function
    onChangeDepositTab: Function
    isLoadingCheckShoppingCartLoanable: boolean
    isUpdateDraftOrder: boolean
    onChangeRate: Function
    voucherApply: any
    setVoucherApply: any
    onChangeDepositOnDemand: any
}

const DEFAULT_METHOD_SELECTED = 'default'
const BIFFIN_METHOD_SELECTED = 'cam_do'
const DEFAULT_CODE = 'default'

export const TenantDeposit = (props: Props) => {
    const {
        draftOrder,
        balanceObj,
        balance,
        onSignUpBiffin,
        totalLoanAmount,
        orderLoading,
        isUpdateDraftOrder,
        isDraftOrderLoanable,
        isEnabledBiffin,
        isConnectedBiffin,
        t,
        isLoadingCheckShoppingCartLoanable,
        onChangeRate,
        voucherApply,
        setVoucherApply,
        onChangeDepositOnDemand,
    } = props
    let currentProjectInfo: CurrentProjectInfo = LocalStore.getInstance().read('currentProjectInfo')
    let currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser')
    let lastOptionBifin = LocalStore.getInstance().read('lastOptionBifin')

    const [depositMethodValue, setDepositMethodValue] = useState({
        method: DEFAULT_METHOD_SELECTED,
        percent: 0,
    })
    const [isOpenModalDeposit, setIsOpenModalDeposit] = useState(false)
    const [percentOfBifin, setPercentOfBifin] = useState(0)
    const [isOpenVoucher, setIsOpenVoucher] = useState(false)

    const [collaspe, setCollaspe] = useState(true)
    const loanPercentage = _.get(currentProjectInfo, 'tenantConfig.externalIntegrationConfig.shopkeeper.loanPercentage')
    const pierced = _.get(currentProjectInfo, 'tenantConfig.externalIntegrationConfig.shopkeeper.pierced')
    const tenantPercent = useMemo(() => {
        const customerLevel = _.get(currentLoggedUser, 'customerEmdLevel')
        const customerGroup = _.get(currentLoggedUser, 'customerGroup')
        const tenant = _.get(currentProjectInfo, 'tenantConfig')
        let emdPercent = 0
        if (tenant && tenant?.emdPercent) {
            emdPercent = tenant?.emdPercent
        }
        if (customerGroup) {
            if (customerGroup?.emdPercent) {
                emdPercent = customerGroup?.emdPercent
            }
            if (customerGroup?.code === DEFAULT_CODE) {
                if (customerLevel?.emdPercent) {
                    emdPercent = customerLevel?.emdPercent
                }
            }
        }
        return emdPercent
    }, [currentLoggedUser, currentProjectInfo])

    const items: any = useMemo(() => {
        const percentBifin = momeyToPercent(totalLoanAmount, draftOrder.exchangedTotalValue)
        const listPercent = loanPercentage
            ? [...loanPercentage?.filter((i: any) => i?.rate < percentBifin), { rate: percentBifin }]
            : [{ rate: percentBifin }]
        return listPercent
            ?.sort((a: any, b: any) => a?.rate - b?.rate)
            ?.map((i: any, index: number) => ({
                key: index,
                label: t('cartCheckout.advanceCapitalPercent', { bifinValue: i?.rate, value: 100 - i?.rate }),
                money: percentToMoney(100 - i?.rate, draftOrder.exchangedTotalValue),
                percent: i?.rate,
                moneyBifin: draftOrder.exchangedTotalValue - percentToMoney(100 - i?.rate, draftOrder.exchangedTotalValue),
            }))
    }, [draftOrder.exchangedTotalValue, totalLoanAmount, loanPercentage])

    useEffect(() => {
        if (totalLoanAmount && items && isEnabledBiffin && isConnectedBiffin) {
            const binfinMin = items?.[0]
            const lastOption = items?.find((i: any) => i?.percent === lastOptionBifin)
            const percentBifin = lastOption?.percent || binfinMin?.percent
            const loanAmount = lastOption?.moneyBifin ? lastOption?.moneyBifin : binfinMin?.moneyBifin
            props.onChangeDepositTab({
                key: BIFFIN_METHOD_SELECTED,
                loanAmount: loanAmount,
                loanPercentage: items?.length === 1 ? null : lastOption?.percent || binfinMin?.percent,
            })
            setDepositMethodValue({
                method: BIFFIN_METHOD_SELECTED,
                percent: percentBifin,
            })
            setPercentOfBifin(percentBifin)
            onChangeRate(loanAmount)
        } else {
            props.onChangeDepositTab({ key: DEFAULT_METHOD_SELECTED })
            setDepositMethodValue({
                method: DEFAULT_METHOD_SELECTED,
                percent: tenantPercent,
            })
            onChangeDepositOnDemand(tenantPercent)
        }
    }, [totalLoanAmount, isEnabledBiffin, isConnectedBiffin, tenantPercent])

    const openConnectBiffin = () => {
        onSignUpBiffin()
    }

    const isDisabledButton = () => {
        const currentProjectInfo: CurrentProjectInfo = LocalStore.getInstance().read('currentProjectInfo')
        const suspensionSchedule: any = currentProjectInfo.suspensionSchedule
        let disabled = false
        if (isEnabledBiffin) {
            if (!isConnectedBiffin && depositMethodValue?.method === BIFFIN_METHOD_SELECTED) {
                disabled = true
            }
            if (isConnectedBiffin && !isDraftOrderLoanable && depositMethodValue?.method === BIFFIN_METHOD_SELECTED) {
                disabled = true
            }
        } else {
            disabled =
                suspensionSchedule &&
                Array.isArray(suspensionSchedule.appliedFor) &&
                !!suspensionSchedule.appliedFor.find((x: any) => x === 'ALL' || x === 'ORDER')
        }
        return disabled
    }

    const onDepositNow = () => {
        props.onCreateOrder()
    }

    const onRemoveVoucher = (code: any) => {
        const newVouchers = voucherApply?.filter((i: any) => i?.code !== code)
        setVoucherApply(newVouchers)
    }

    let listCarts: Array<any> = Array.isArray(draftOrder.merchants) ? draftOrder.merchants : []

    let totalLink = 0
    let arrayModify = []
    let totalQuantity = 0
    let lackOfMoney = false

    listCarts.forEach(x => {
        if (x.crossedThreshold === false) {
            arrayModify = modifyArrayDataSource(x)
            totalLink = totalLink + arrayModify.length
            arrayModify.forEach(item => {
                totalQuantity = totalQuantity + item.quantity
            })
        }
    })

    const crossedThreshold: Array<any> = listCarts.filter(x => x.crossedThreshold === false)

    const depositWith = lodash.template(t('cart.deposit_with_tenant'))
    // const emdPercentFormula = currentProjectInfo?.tenantConfig?.externalIntegrationConfig?.shopkeeper?.emdPercentFormula
    const trueStrike = currentLoggedUser?.customerGroup?.trueStrike

    const percentOfCustomer = 100 - percentOfBifin
    const percentOfBifinText = lodash.template(t('cart.bifinAdvancecapital'))
    const percentOfCustomerText = lodash.template(t('cart.customerAdvancecapital'))
    const checkForceTrueStrike = trueStrike === null ? pierced : trueStrike

    const depositWizard = _.get(currentProjectInfo, 'tenantConfig.generalConfig.depositWizard')

    const bifinMoney = draftOrder.exchangedTotalValue - percentToMoney(100 - percentOfBifin, draftOrder.exchangedTotalValue)
    const totalLackOfMoney: number = moneyCeil(
        Number(draftOrder.emdAmount - balance - (depositMethodValue?.method === BIFFIN_METHOD_SELECTED ? bifinMoney : 0))
    )
    const depositOnDemand = _.get(currentProjectInfo, 'tenantConfig.orderConfig.depositOnDemand')
    const depositPercentage = _.get(currentProjectInfo, 'tenantConfig.orderConfig.depositPercentage')

    // kiểm tra số tiền cần đặt cọc và số tiền dư của tài khoản
    // khi mà đặt cọc với bifin thì cộng thêm số tiền mà nó cho vay
    if (
        draftOrder.emdAmount >
        balanceObj.balance + balanceObj.creditLimit + (depositMethodValue?.method === BIFFIN_METHOD_SELECTED ? bifinMoney : 0)
    ) {
        lackOfMoney = true
    }

    return (
        <div className={`_order-financial-info sidebar__financialinfo bg-white ${lackOfMoney ? 'warning' : ''}`}>
            <Spin spinning={isLoadingCheckShoppingCartLoanable || isUpdateDraftOrder}>
                <div className="sidebar__financialinfo_box">
                    <>
                        <Row
                            justify="space-between"
                            align="middle"
                            className="mb-2"
                        >
                            <Col className={'text-lg txt-color-black robotobold'}>{t('cart.deposit_information')}</Col>
                            <Col>
                                <i
                                    className={`fa-solid ${
                                        collaspe ? 'fa-chevron-up' : 'fa-chevron-down'
                                    } ml-1 text-grey-400 cursor-pointer hover:text-black-100`}
                                    onClick={() => setCollaspe(value => !value)}
                                ></i>
                            </Col>
                        </Row>
                        <div className="width-100-percent mb-0 max-h-[360px] overflow-auto">
                            <div className="flex flex-col w-full pl-1">
                                {!checkForceTrueStrike && tenantPercent && (
                                    <>
                                        <div
                                            className={
                                                !collaspe && depositMethodValue?.method !== DEFAULT_METHOD_SELECTED
                                                    ? 'hidden'
                                                    : ''
                                            }
                                        >
                                            <Radio
                                                checked={
                                                    DEFAULT_METHOD_SELECTED === depositMethodValue?.method &&
                                                    tenantPercent === depositMethodValue?.percent
                                                }
                                                onChange={e => {
                                                    props.onChangeDepositTab({
                                                        key: DEFAULT_METHOD_SELECTED,
                                                        loanAmount: percentToMoney(tenantPercent, draftOrder.exchangedTotalValue),
                                                        loanPercentage: tenantPercent,
                                                    })
                                                    setDepositMethodValue({
                                                        method: DEFAULT_METHOD_SELECTED,
                                                        percent: tenantPercent,
                                                    })
                                                    onChangeRate(0)
                                                    onChangeDepositOnDemand(tenantPercent)
                                                }}
                                                className="items-center"
                                            >
                                                <div>
                                                    <div className={`leading-6 text-sm`}>
                                                        {depositWith({
                                                            tenant_name: currentProjectInfo?.name,
                                                            percent: tenantPercent,
                                                        })}
                                                    </div>
                                                    <div>
                                                        <span className="text-blue-primary text-base font-medium">
                                                            {moneyFormat(
                                                                moneyCeil(
                                                                    percentToMoney(tenantPercent, draftOrder.exchangedTotalValue)
                                                                )
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Radio>
                                        </div>
                                    </>
                                )}

                                {!checkForceTrueStrike && depositOnDemand && (
                                    <div>
                                        {depositPercentage?.map((item: any, index: number) => (
                                            <div key={index}>
                                                <Divider
                                                    className={`my-3 border-silver-100 ${
                                                        (!collaspe || !tenantPercent) && 'hidden'
                                                    }`}
                                                />
                                                <div
                                                    key={index}
                                                    className={!collaspe && depositMethodValue?.percent !== item ? 'hidden' : ''}
                                                >
                                                    <Radio
                                                        checked={
                                                            item === depositMethodValue?.percent &&
                                                            DEFAULT_METHOD_SELECTED === depositMethodValue?.method
                                                        }
                                                        key={index}
                                                        onChange={e => {
                                                            props.onChangeDepositTab({
                                                                key: DEFAULT_METHOD_SELECTED,
                                                                loanAmount: percentToMoney(item, draftOrder.exchangedTotalValue),
                                                                loanPercentage: item,
                                                            })
                                                            setDepositMethodValue({
                                                                method: DEFAULT_METHOD_SELECTED,
                                                                percent: item,
                                                            })
                                                            onChangeRate(0)
                                                            onChangeDepositOnDemand(item)
                                                        }}
                                                        className="items-center"
                                                    >
                                                        <div>
                                                            <div className={`leading-6 text-sm`}>
                                                                {depositWith({
                                                                    tenant_name: currentProjectInfo?.name,
                                                                    percent: item,
                                                                })}
                                                            </div>
                                                            <div>
                                                                <span className="text-blue-primary text-base font-medium">
                                                                    {moneyFormat(
                                                                        moneyCeil(
                                                                            percentToMoney(item, draftOrder.exchangedTotalValue)
                                                                        )
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Radio>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {isEnabledBiffin && (
                                    <>
                                        {!isConnectedBiffin ? (
                                            <>
                                                <Divider
                                                    className={`my-3 border-silver-100 ${
                                                        (!collaspe || (!tenantPercent && !depositOnDemand)) && 'hidden'
                                                    }`}
                                                />
                                                <div
                                                    className={
                                                        !collaspe && depositMethodValue?.method !== BIFFIN_METHOD_SELECTED
                                                            ? 'hidden'
                                                            : ''
                                                    }
                                                >
                                                    <Radio
                                                        checked={BIFFIN_METHOD_SELECTED === depositMethodValue?.method}
                                                        className="items-center"
                                                    >
                                                        <div>
                                                            <div className={`leading-6 text-sm`}>
                                                                {t('cart.deposit_with_biffin')}
                                                            </div>
                                                            <div>
                                                                <span
                                                                    className="cursor-pointer underline text-red-100 text-sm"
                                                                    onClick={openConnectBiffin}
                                                                >
                                                                    {t('cart.connect_now')}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Radio>
                                                </div>
                                            </>
                                        ) : (
                                            <div>
                                                {items?.map((i: any, index: number) => (
                                                    <>
                                                        <Divider className={`my-3 border-silver-100 ${!collaspe && 'hidden'}`} />
                                                        <div
                                                            key={i?.key}
                                                            className={
                                                                !collaspe && depositMethodValue?.percent !== i?.percent
                                                                    ? 'hidden'
                                                                    : ''
                                                            }
                                                        >
                                                            <Radio
                                                                checked={
                                                                    i?.percent === depositMethodValue?.percent &&
                                                                    depositMethodValue?.method === BIFFIN_METHOD_SELECTED
                                                                }
                                                                className="items-center"
                                                                onChange={e => {
                                                                    props.onChangeDepositTab({
                                                                        key: BIFFIN_METHOD_SELECTED,
                                                                        loanAmount: i?.moneyBifin,
                                                                        loanPercentage:
                                                                            i?.percent ===
                                                                            momeyToPercent(
                                                                                totalLoanAmount,
                                                                                draftOrder.exchangedTotalValue
                                                                            )
                                                                                ? null
                                                                                : i?.percent,
                                                                    })
                                                                    LocalStore.getInstance().save('lastOptionBifin', i?.percent)
                                                                    setDepositMethodValue({
                                                                        method: BIFFIN_METHOD_SELECTED,
                                                                        percent: i?.percent,
                                                                    })
                                                                    setPercentOfBifin(i?.percent)
                                                                    onChangeRate(draftOrder.exchangedTotalValue - i?.money)
                                                                    onChangeDepositOnDemand(undefined)
                                                                }}
                                                            >
                                                                <div>
                                                                    <div className={`leading-6 text-sm`}>
                                                                        {i?.label}{' '}
                                                                        {index === items?.length - 1 && (
                                                                            <Tooltip
                                                                                title={t('cartCheckout.textPercentRounding')}
                                                                            >
                                                                                <i className="fa-regular fa-circle-question ml-1 hover:cursor-pointer" />
                                                                            </Tooltip>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        <span className="text-blue-primary text-base font-medium">
                                                                            {moneyFormat(moneyCeil(i?.money))}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </Radio>
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        <Divider className="my-4" />
                    </>

                    <Row className={'mgbt10'}>
                        <Col
                            md={24}
                            className={'text-lg txt-color-black robotobold mgbt5'}
                        >
                            {t('cartCheckout.product_info')}
                        </Col>
                        <Col
                            md={24}
                            className={'txt-size-h7 txt-color-black robotoregular mgbt5 justify-content-between dpl-flex'}
                        >
                            <span>{t('cartCheckout.seller')}:</span>
                            <span>{listCarts.length}</span>
                        </Col>
                        <Col
                            md={24}
                            className={'txt-size-h7 txt-color-black robotoregular justify-content-between dpl-flex'}
                        >
                            <span>{t('cartCheckout.link_total')}:</span>
                            <span>{totalLink}</span>
                        </Col>
                    </Row>
                    <Divider className="my-4" />

                    <Row
                        className={'mgbt10'}
                        justify="space-between"
                        align="middle"
                    >
                        <Col className={'text-lg txt-color-black robotobold'}>{t('cartCheckout.voucher')}</Col>
                        <Col
                            className={' text-sm text-blue-100 font-medium cursor-pointer hover:underline'}
                            onClick={() => setIsOpenVoucher(true)}
                        >
                            {t('coupon.voucher')}
                        </Col>
                    </Row>
                    {voucherApply?.map((i: any) => {
                        return (
                            <Row
                                justify="space-between"
                                align="middle"
                                className="mb-4"
                            >
                                <Col>
                                    <Space align="center">
                                        <i
                                            className="fa-solid fa-xmark text-red-100 text-sm cursor-pointer"
                                            onClick={() => onRemoveVoucher(i?.code)}
                                        ></i>
                                        <span>{i?.code}</span>
                                    </Space>
                                </Col>
                                <Col className="text-red-100">{moneyFormat(moneyCeil(-i.totalDiscountFee))}</Col>
                            </Row>
                        )
                    })}

                    <Divider className="my-4" />
                    <Row className={'mgbt15'}>
                        <Col
                            md={24}
                            className={'text-lg txt-color-black robotobold mgbt5'}
                        >
                            {t('cartCheckout.order_info')}
                        </Col>
                        <Col
                            md={24}
                            className={'txt-size-h7 txt-color-black robotoregular mgbt5 justify-content-between dpl-flex'}
                        >
                            <span>{t('cartCheckout.provisional_fee')}:</span>
                            <span className={'_totalMoney-page'}>{moneyFormat(moneyCeil(draftOrder.totalFee))}</span>
                        </Col>
                        {voucherApply?.length > 0 && (
                            <Col
                                md={24}
                                className={'txt-size-h7 txt-color-black robotoregular mgbt5 justify-content-between dpl-flex'}
                            >
                                <span>{t('cartCheckout.totalCoupon')}:</span>
                                <span className={'_totalMoney-page text-red-100'}>
                                    {moneyFormat(moneyCeil(-_.sumBy(voucherApply, 'totalDiscountFee')))}
                                </span>
                            </Col>
                        )}

                        <Col
                            md={24}
                            className={'txt-size-h7 txt-color-black robotoregular mgbt5 justify-content-between dpl-flex'}
                        >
                            <span>
                                {t('cartCheckout.provisional_cal')} (
                                <span className={'_totalQuantity-page'}>{quantityFormat(totalQuantity)}</span>{' '}
                                {t('cartCheckout.product')}):
                            </span>
                            <span className={'_totalMoney-page'}>{moneyFormat(moneyCeil(draftOrder.exchangedTotalValue))}</span>
                        </Col>
                        <Col
                            md={24}
                            className={'txt-size-h7 txt-color-black robotoregular mgbt5 justify-content-between dpl-flex'}
                        >
                            <span>
                                {t('cartCheckout.deposit')} {draftOrder.emdPercent}%:
                            </span>
                            <span className={'_deposit-page text-blue-100 txt-size-h5 bold'}>
                                {moneyFormat(moneyCeil(draftOrder.emdAmount))}
                            </span>
                        </Col>
                        {isEnabledBiffin && isConnectedBiffin && depositMethodValue?.method === BIFFIN_METHOD_SELECTED && (
                            <>
                                <Divider className="my-0 mb-2 bg-gray-100" />
                                <Col
                                    md={24}
                                    className={'txt-size-h7 txt-color-black robotoregular mgbt5 justify-content-between dpl-flex'}
                                >
                                    <span>{percentOfBifinText({ value: percentOfBifin })}:</span>
                                    <span className={'_totalMoney-page'}>{moneyFormat(moneyCeil(bifinMoney))}</span>
                                </Col>
                                <Col
                                    md={24}
                                    className={'txt-size-h7 txt-color-black robotoregular mgbt5 justify-content-between dpl-flex'}
                                >
                                    <span>{percentOfCustomerText({ value: percentOfCustomer })}:</span>
                                    <span className={'_totalMoney-page'}>
                                        {moneyFormat(moneyCeil(draftOrder.exchangedTotalValue - bifinMoney))}
                                    </span>
                                </Col>
                            </>
                        )}
                        {lackOfMoney && (
                            <React.Fragment>
                                <Col
                                    md={24}
                                    className={'txt-size-h7 txt-color-black robotoregular mgbt5 justify-content-between dpl-flex'}
                                >
                                    <span>{t('cartCheckout.balance_account')}:</span>
                                    <span className={'_balance-account txt-size-h6'}>
                                        {balance >= 0 ? `+${moneyFormat(balance)}` : `${moneyFormat(balance)}`}
                                    </span>
                                </Col>
                                <Col
                                    md={24}
                                    className={'txt-size-h7 txt-color-black robotoregular mgbt5 justify-content-between dpl-flex'}
                                >
                                    <span>{t('cartCheckout.lack_of_money')}:</span>
                                    <span className={'_lack-of-money txt-size-h6 txt-color-red'}>
                                        {moneyFormat(totalLackOfMoney)}
                                    </span>
                                </Col>
                            </React.Fragment>
                        )}
                    </Row>
                    <Row>
                        {crossedThreshold.length > 0 && (
                            <Col md={24}>
                                {lackOfMoney ? (
                                    <Fragment>
                                        {(isConnectedBiffin &&
                                            isDraftOrderLoanable &&
                                            depositMethodValue?.method === BIFFIN_METHOD_SELECTED) ||
                                        depositMethodValue?.method === DEFAULT_METHOD_SELECTED ? (
                                            <>
                                                {depositWizard ? (
                                                    <>
                                                        <Button
                                                            block
                                                            className={'_btn-recharge btn-recharge mgbt5'}
                                                            onClick={() => setIsOpenModalDeposit(true)}
                                                        >
                                                            {t('cartCheckout.recharge')}
                                                        </Button>
                                                        <DepositModal
                                                            visible={isOpenModalDeposit}
                                                            onClose={() => setIsOpenModalDeposit(false)}
                                                            maskClosable={true}
                                                            data={{
                                                                step: 1,
                                                                hideStep: true,
                                                                type: 'order',
                                                                money: totalLackOfMoney,
                                                            }}
                                                        />
                                                    </>
                                                ) : (
                                                    <Link
                                                        target="_blank"
                                                        to={`/profile/faqs?recharge`}
                                                    >
                                                        <Button
                                                            block
                                                            className={'_btn-recharge btn-recharge mgbt5'}
                                                            onClick={() => setIsOpenModalDeposit(true)}
                                                        >
                                                            {t('cartCheckout.recharge')}
                                                        </Button>
                                                    </Link>
                                                )}
                                            </>
                                        ) : (
                                            <Button
                                                htmlType={'button'}
                                                block
                                                disabled={isDisabledButton() || orderLoading}
                                                className={'_btn-show-modal-confirm h-[48px] rounded btn--blue'}
                                                loading={orderLoading}
                                                onClick={() => {
                                                    onDepositNow()
                                                }}
                                            >
                                                {t('cartCheckout.deposit_now')}
                                            </Button>
                                        )}
                                        <span className="flr">
                                            <Link
                                                target="_blank"
                                                to={`/profile/faqs?recharge`}
                                            >
                                                {t('cartCheckout.recharge_guide')}
                                            </Link>
                                            <Tooltip
                                                placement="top"
                                                title={t('cartCheckout.guide_recharge_into_account')}
                                            >
                                                <QuestionCircleOutlined className={'pdl5 txt-size-h8 txt-color-blue opacity6'} />
                                            </Tooltip>
                                        </span>
                                    </Fragment>
                                ) : (
                                    <Button
                                        htmlType={'button'}
                                        block
                                        disabled={isDisabledButton() || orderLoading}
                                        className={'_btn-show-modal-confirm h-[48px] rounded btn--blue'}
                                        loading={orderLoading}
                                        onClick={() => {
                                            onDepositNow()
                                        }}
                                    >
                                        {t('cartCheckout.deposit_now')}
                                    </Button>
                                )}
                                {isEnabledBiffin && depositMethodValue?.method === BIFFIN_METHOD_SELECTED && (
                                    <div className="mt-3">
                                        {!isConnectedBiffin && (
                                            <span className="text-red-100 text-[13px]">
                                                {t('cartCheckout.connectBifinToContinue', { name: currentProjectInfo?.name })}
                                            </span>
                                        )}
                                        {isConnectedBiffin && !isDraftOrderLoanable && (
                                            <span className="text-red-100 text-[13px]">
                                                {t('cartCheckout.notEnoughCondition', { name: currentProjectInfo?.name })}
                                            </span>
                                        )}
                                        {isConnectedBiffin ? (
                                            <div
                                                className="mt-3 text-black-100 mb-0"
                                                dangerouslySetInnerHTML={{
                                                    __html: t('cartCheckout.confirmBifin'),
                                                }}
                                            ></div>
                                        ) : (
                                            <>
                                                <p className="mt-3 text-black-100 mb-0">
                                                    {t('cartCheckout.introduceBifin', { name: currentProjectInfo?.name })}
                                                </p>
                                                <a
                                                    href="https://www.bifin.vn/"
                                                    target={'_blank'}
                                                    className="mt-3 float-left text-blue-100"
                                                    rel="noreferrer"
                                                >
                                                    {t('cartCheckout.biffin_note_more')}
                                                </a>
                                            </>
                                        )}
                                    </div>
                                )}
                            </Col>
                        )}
                    </Row>
                </div>
            </Spin>
            <ModalVoucher
                visible={isOpenVoucher}
                setVisible={setIsOpenVoucher}
                t={t}
                orderCode={draftOrder?.id}
                setVoucherApply={setVoucherApply}
                voucherApply={voucherApply}
            />
        </div>
    )
}
