import { first } from "lodash"
import { moneyFormat } from "../../../../utils/stringUtils"
import { Alert, Avatar, Button, Modal } from "antd"

const ModalOrderSuccess = ({ t, orders = [], openModal, history, continueOrder }) => {
    return <Modal
        className="modal-order-success"
        title={<span className="robotomedium text-2xl !capitalize">{t('order.order_success').toLowerCase()}</span>}
        open={openModal}
        footer={[]}
        width={500}
        closeIcon={null}
        closable={false}
        centered
    >
        <div>
            <div className="text-center">
                <span className="text-grey-200 text-base">
                    <i className="fa-solid fa-box-check text-shamrock-400 mr-1" />{t('taobaoGlobalCart.orderSuccess')} <span className="text-shamrock-400 robotomedium">{orders.length}</span> {t('orderDetail.order').toLowerCase()}
                </span>
            </div>
            <div className="my-6 max-h-[270px] overflow-y-scroll flex flex-col gap-y-3">
                {
                    orders.map((order, index) =>
                        <div key={index} className="flex items-center p-4 rounded-lg border border-solid border-gray-300">
                            <Avatar
                                shape="square"
                                size={44}
                                className="rounded-md"
                                src={first(order.products)?.imageUrl}
                                icon={<img src={require('../../../../resources/images/shoping.png')} alt='' />}
                            />
                            <div className="ml-4">
                                <span className="block robotomedium text-blue-primary cursor-pointer" onClick={() => history.push(`orders-taobao-global?orderCode=${order.code}`)}>
                                    #{order?.code}
                                </span>
                                <span className="text-grey-200 robotoregular">
                                    {t('order.quantity')}
                                    <span className="text-black-100 robotoregular">: {order.totalSku} {t('order.products').toLowerCase()}</span>
                                </span>
                            </div>
                            <div className="ml-10">
                                <span className="block robotoregular text-grey-200">
                                    {t('taobaoGlobalCart.money')}
                                </span>
                                <span className="robotomedium text-blue-primary">
                                    {moneyFormat((order.purchaseAmount), 'CNY')}
                                </span>
                            </div>
                        </div>
                    )
                }

            </div>
            <Alert
                className="rounded-lg mb-6 px-4 py-2"
                description={<div dangerouslySetInnerHTML={{ __html: t('taobaoGlobalCart.warning_message') }} />}
                type="info"
                icon={<i className="fa-solid fa-circle-info text-base mr-2" />}
                showIcon
            />
            <div className="flex gap-4 v2-layout">
                <Button
                    size="large"
                    className="w-full"
                    onClick={() => history.push(`orders-taobao-global`)}
                >
                    {t("taobaoGlobalCart.goToOrder")}
                    <i className="fa-regular fa-arrow-right ml-2" />
                </Button>
                <Button
                    className="w-full"
                    size="large"
                    type="primary"
                    onClick={() => {
                        continueOrder()
                    }}>
                    {t('taobaoGlobalCart.continueOrder')}
                    <i className="fa-regular fa-cart-shopping ml-2" />
                </Button>
            </div>
        </div>
    </Modal>
}

export default ModalOrderSuccess