/* eslint-disable array-callback-return */
import React from 'react'
import moment from 'moment'
import _ from 'lodash'
import lodash from 'lodash'
import MainLayout from '../../components/Layout/MainLayout'
import Loading from '../../components/Loading'
import { Link } from 'react-router-dom'
import XLSX from 'xlsx'
import {
    Alert,
    Avatar,
    Button,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Empty,
    Input,
    List,
    Modal,
    notification,
    Popover,
    Row,
    Select,
    Spin,
    Tag,
    Timeline,
    Tooltip,
    Typography,
} from 'antd'
import * as AntdIcon from '@ant-design/icons'
import AntdIconSVG from '@ant-design/icons'

import { LocalStore } from '../../utils/LocalStore'
import { getUrlQueryString } from '../../utils/helper'
import {
    moneyCeil,
    moneyFormat,
    parseQueryStringToObject,
    quantityFormat,
    updateQueryStringParameter,
} from '../../utils/stringUtils'
import UserService from '../../services/user.service'
import CategoriesService from '../../services/categories.service'
import ShipmentService from '../../services/shipments.service'
import saveAs from 'file-saver'
import { withNamespaces } from 'react-i18next'
import { ReactComponent as iconShipment } from '../../resources/images/ic_Kygui.svg'
import uploadFile from '../../utils/api/uploadFile'
import ImportExcel from '../../components/ReactDropzone/ImportExcel'
import ServiceGroup from '../../components/Shipment/ServiceGroup'
import ImportExcelResult from '../../components/Shipment/ImportExcelResult'
import { validateLink } from '../../utils/validate'
import { USER_STATUS } from '../../constants/config'
import ExportExcel from '../../components/ExportExcel'
import queryString from 'query-string'
import BifinService from '../../services/bifin.service'
import ModalPaymentBifin from '../Orders/components/ModalPaymentBifin'

let pageSize = 25

const KEY_ENTER = 13
class Shipment extends React.Component {
    constructor(props) {
        super(props)
        this.isUnmounted = false
        this.state = {
            page: 1,
            pageSize: pageSize,
            isLoading: true,
            listShipments: [],
            listChecked: {},
            datetimeRange: [],
            startTime: undefined,
            endTime: undefined,
            querySearch: '',
            typeSearch: '',
            globalTenantConfig: {},
            loanCredits: [],
        }
    }

    componentDidMount() {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null
        window.addEventListener('popstate', this.onPopState.bind(this))
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        if (
            !currentProjectInfo.tenantConfig ||
            !currentProjectInfo.tenantConfig.shipmentConfig ||
            !currentProjectInfo.tenantConfig.shipmentConfig.enable
        ) {
            this.props.history.push('/404')
        }
        let currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {}
        this.setState(
            {
                isLoading: true,
                currentLoggedUser,
                isBanned: currentLoggedUser.customerStatus === USER_STATUS.BANNED,
            },
            () => {
                this.loadFromQueryString()
                this.getGroupOrderServices()
                this.getShipmentStatuses()
                this.getShipmentService()
                this.getShipmentsStatistics()
                this.getGlobalTenantConfig()
            }
        )
    }

    /**
     * Khi unmount ra thì bỏ sự kiện popstate
     */
    componentWillUnmount() {
        this.isUnmounted = true
        window.removeEventListener('popstate', this.onPopState.bind(this))
    }

    getGlobalTenantConfig = () => {
        CategoriesService.getTenantConfigPayment((error, response) => {
            if (!error) {
                this.setState({
                    globalTenantConfig: response,
                })
            } else {
                this.setState({
                    globalTenantConfig: {},
                })
            }
        })
    }

    /**
     * On popstate
     */
    onPopState() {
        if (!this.isUnmounted)
            this.setState({ isLoading: true }, () => {
                this.loadFromQueryString()
            })
    }

    /**
     * Load từ QueryString (dành cho lần load đầu tiên)
     */
    loadFromQueryString = () => {
        let queryString = getUrlQueryString()
        let queryObj = parseQueryStringToObject(queryString) || {}

        // console.log('queryObj', queryObj);
        // chuyển giá trị từ queryObj vào state;
        let arrayValue = []
        //reset các param khi load từ url
        let listChecked = {}
        let querySearch = ''
        let originalReceiptCode = ''
        let wayBill = ''
        let merchantName = ''
        let existsProduct = ''
        let refShipmentCode = ''
        let refCustomerCode = ''
        let startTime = undefined
        let endTime = undefined
        let handlingTimeFrom = ''
        let handlingTimeTo = ''
        let typeSearch = ''
        let cutOffStatus = ''
        for (let p in queryObj) {
            if (p === 'statuses' || p === 'services') {
                // xử lý với obj list checked
                arrayValue = queryObj[p].split(',')
                // console.log('arrayValue', arrayValue)
                listChecked[p] = arrayValue
            } else if (p === 'timestampFrom') {
                // xử lý với với timestamp
                startTime = decodeURIComponent(queryObj['timestampFrom'])
            }
            if (p === 'timestampTo') {
                // xử lý với với timestamp
                endTime = decodeURIComponent(queryObj['timestampTo'])
            } else if (p === 'originalReceiptCode') {
                originalReceiptCode = decodeURIComponent(queryObj['originalReceiptCode'] || '')
            } else if (p === 'wayBill') {
                wayBill = decodeURIComponent(queryObj['wayBill'] || '')
            } else if (p === 'merchantName') {
                merchantName = decodeURIComponent(queryObj['merchantName'] || '')
            } else if (p === 'existsProduct') {
                existsProduct = decodeURIComponent(queryObj['existsProduct'] || '')
            } else if (p === 'handlingTimeFrom' || p === 'handlingTimeTo') {
                // xử lý với với timestamp
                if (p === 'handlingTimeFrom') {
                    handlingTimeFrom = decodeURIComponent(queryObj['handlingTimeFrom'])
                } else {
                    handlingTimeTo = decodeURIComponent(queryObj['handlingTimeTo'])
                }
            } else if (p === 'refShipmentCode') {
                refShipmentCode = decodeURIComponent(queryObj['refShipmentCode'])
            } else if (p === 'refCustomerCode') {
                refCustomerCode = decodeURIComponent(queryObj['refCustomerCode'])
            } else if (p === 'typeSearch' || p === 'cutOffStatus') {
                // xử lý với với timestamp
                typeSearch = decodeURIComponent(queryObj['typeSearch'])
                cutOffStatus = decodeURIComponent(queryObj['cutOffStatus'])
            } else {
                // xử lý với query
                querySearch = decodeURIComponent(queryObj['query'] || '')
            }
        }

        this.setState(
            {
                querySearch,
                originalReceiptCode,
                wayBill,
                merchantName,
                existsProduct,
                refShipmentCode,
                refCustomerCode,
                startTime,
                endTime,
                listChecked,
                handlingTimeFrom,
                handlingTimeTo,
                typeSearch,
                cutOffStatus,
                page: queryObj.page,
                pageSize: queryObj.size || pageSize,
            },
            () => {
                this.loadData()
            }
        )
    }

    /**
     * load data
     */
    loadData = cb => {
        const { page, pageSize } = this.state
        let queryString = this.buildQueryString()
        if (queryString) {
            queryString = queryString + `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        } else {
            queryString = `?page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        }

        this.setState({ showProgressBar: true }, () => {
            UserService.getShipments(queryString, (err, res, headers) => {
                if (!err) {
                    const data = res || []
                    const ordersValid = data.filter(item => item?.contractWithShopkeeper)
                    data.length > 0 && this.getThirdPatiesLoans(ordersValid)
                    this.setState(
                        {
                            expandedRow: [],
                            listShipments: data,
                            metadata: {
                                pageCount: headers['x-page-count'],
                                page: headers['x-page-number'],
                                size: headers['x-page-size'],
                                total: headers['x-total-count'],
                            },
                        },
                        () => {
                            if (this.mainLayoutElement && this.mainLayoutElement.scrollLayoutElement)
                                this.mainLayoutElement.scrollLayoutElement.scrollTop = 0
                            //window.scrollTo(0, 0);
                        }
                    )
                }
                if (cb) cb()
                this.setState({ showProgressBar: false, isLoading: false })
            })
        })
    }

    /**
     * get loans
     */
    getThirdPatiesLoans = data => {
        if (Array.isArray(data) && data.length === 0) return
        let codes = data?.map(item => item?.code)
        codes = codes.join(',')
        let queryString = `?orderCodes=${codes}`
        BifinService.getThirdPatiesLoans(queryString, (err, res) => {
            if (!err) {
                this.setState({
                    loanCredits: res?.loanCredits,
                })
            }
        })
    }

    /**
     * export excel data
     */
    exportExcel = (password, cb, setError) => {
        const { t } = this.props
        const { page, pageSize } = this.state
        let queryString = this.buildQueryString()
        if (queryString) {
            queryString = queryString + `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        } else {
            queryString = `?page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        }

        if (!password) {
            if (setError) setError(t('cartCheckout.incorrect_pin'))

            return
        }

        this.setState({ showProgressBar: true, onExport: true }, () => {
            UserService.exportShipment(queryString, { secret: password }, (err, res, headers) => {
                if (!err) {
                    this.downloadFile(res)
                    if (cb) cb()
                } else {
                    if (err.title === 'invalid_pin' || err.title === 'invalid_password') {
                        if (setError) setError(t('cartCheckout.incorrect_pin'))
                    }
                }
                this.setState({ showProgressBar: false, onExport: false })
            })
        })
    }

    downloadFile = res => {
        if (res && res.headers && res.headers.map) {
            const fileName = res.headers.map['content-disposition'].split('filename=')[1]
            saveAs(res._bodyBlob, fileName)
        }
    }

    /**
     * lấy ra danh sách services
     */
    getGroupOrderServices = () => {
        CategoriesService.getOrderServicesGroup((err, res, headers) => {
            if (!err) {
                // console.log('headers', headers)
                this.setState({
                    listGroupServices: res,
                    metadataListServices: {
                        pageCount: headers['x-page-count'],
                        page: headers['x-page-number'],
                        size: headers['x-page-size'],
                        total: headers['x-total-count'],
                    },
                })
            }
        })
    }

    /**
     * Lấy danh sách lịch sử các mốc đặt hàng trong đơn
     */
    getListMilestones = code => {
        this.setState(
            {
                isLoadingListMilestones: true,
            },
            () => {
                ShipmentService.getShipmentMileStones(code, (err, res) => {
                    if (!err) {
                        this.setState({
                            listMilestones: res,
                        })
                    }
                    this.setState({ isLoadingListMilestones: false })
                })
            }
        )
    }

    /**
     * lấy ra danh sách nhóm services
     */
    getShipmentService = () => {
        CategoriesService.getShipmentService((err, res, headers) => {
            if (!err) {
                this.setState({
                    listServices: res,
                    metadataListGroupServices: {
                        pageCount: headers['x-page-count'],
                        page: headers['x-page-number'],
                        size: headers['x-page-size'],
                        total: headers['x-total-count'],
                    },
                })
            }
        })
    }

    /**
     * lấy ra danh sách trạng thái đơn
     */
    getShipmentStatuses = () => {
        CategoriesService.getShipmentStatuses((err, res, headers) => {
            if (!err) {
                this.setState({
                    listStatuses: res,
                })
            }
        })
    }

    /**
     * lấy ra danh sách trạng thái đơn
     */
    getShipmentsStatistics = () => {
        ShipmentService.getShipmentsStatistics((err, res, headers) => {
            if (!err) {
                this.setState({
                    shipmentsStatistics: res,
                })
            }
        })
    }

    onPageChanged = (page, pageSize) => {
        this.setState({ page: page, size: pageSize }, () => {
            let locationQuery = lodash.get(this.props.location, 'search', '')
            let queryObj = locationQuery ? parseQueryStringToObject(locationQuery) : {}
            queryObj.page = page
            queryObj.size = pageSize
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: queryString.stringify(queryObj),
            })
            // window.scrollTo(0, 0);
        })
    }

    /**
     * thay đổi sort ở column
     * @param current
     * @param pageSize
     */
    onSizeChange = (current, pageSize) => {
        let queryString = this.buildQueryString()
        queryString = updateQueryStringParameter(queryString, 'size', pageSize)
        queryString = updateQueryStringParameter(queryString, 'page', null)

        this.setState(
            {
                page: 1,
                pageSize: pageSize,
            },
            () => {
                this.props.history.push({
                    pathname: this.props.location.pathname,
                    search: queryString,
                })
            }
        )
    }

    /**
     * lấy ra prop giá trị của obj prop
     * @param props
     * @returns {*}
     */
    getValuePropOfProp = props => {
        switch (props) {
            case 'services':
            case 'statuses':
                return 'code'
            default:
                return 'id'
        }
    }

    /**
     * xây dựng query string theo các giá trị trong state
     * @returns {string}
     */
    buildQueryString = () => {
        let {
            listChecked,
            startTime,
            endTime,
            querySearch,
            originalReceiptCode,
            wayBill,
            merchantName,
            existsProduct,
            typeSearch,
            cutOffStatus,
            handlingTimeFrom,
            handlingTimeTo,
            refShipmentCode,
            refCustomerCode,
        } = this.state
        let queryString = ''
        for (let p in listChecked) {
            let value = ''
            if (Array.isArray(listChecked[p]) && listChecked[p].length > 0) {
                listChecked[p].map(x => {
                    value = value + x + ','
                })

                value = value.slice(0, -1)
            }
            if (value) queryString = updateQueryStringParameter(queryString, p, value)
        }

        if (startTime) {
            queryString = updateQueryStringParameter(queryString, 'timestampFrom', startTime)
        }

        if (endTime) {
            queryString = updateQueryStringParameter(queryString, 'timestampTo', endTime)
        }

        if (querySearch) {
            queryString = updateQueryStringParameter(queryString, 'query', querySearch.trim())
        }

        if (originalReceiptCode) {
            queryString = updateQueryStringParameter(queryString, 'originalReceiptCode', originalReceiptCode.trim())
        }

        if (refShipmentCode) {
            queryString = updateQueryStringParameter(queryString, 'refShipmentCode', refShipmentCode.trim())
        }

        if (refCustomerCode) {
            queryString = updateQueryStringParameter(queryString, 'refCustomerCode', refCustomerCode.trim())
        }

        if (wayBill) {
            queryString = updateQueryStringParameter(queryString, 'wayBill', wayBill.trim())
        }

        if (merchantName) {
            queryString = updateQueryStringParameter(queryString, 'merchantName', merchantName.trim())
        }

        if (existsProduct) {
            queryString = updateQueryStringParameter(queryString, 'existsProduct', 'false')
        }

        if (handlingTimeFrom || handlingTimeTo) {
            queryString = updateQueryStringParameter(queryString, 'typeSearch', typeSearch)
            queryString = updateQueryStringParameter(queryString, 'cutOffStatus', cutOffStatus)
            if (handlingTimeFrom) {
                queryString = updateQueryStringParameter(queryString, 'handlingTimeFrom', handlingTimeFrom)
            }
            if (handlingTimeTo) {
                queryString = updateQueryStringParameter(queryString, 'handlingTimeTo', handlingTimeTo)
            }
        }

        return queryString
    }

    onClearSearch = () => {
        this.setState(
            {
                page: 1,
                querySearch: '',
                originalReceiptCode: '',
                refShipmentCode: '',
                refCustomerCode: '',
                wayBill: '',
                merchantName: '',
                startTime: undefined,
                endTime: undefined,
                listChecked: {},
                existsProduct: '',
                cutOffStatus: '',
                handlingTimeFrom: '',
                handlingTimeTo: '',
                searchType: '',
            },
            () => {
                this.props.history.push({
                    pathname: this.props.location.pathname,
                    search: this.buildQueryString(),
                })
            }
        )
    }

    /**
     * xử lý với dữ liệu search trong state
     */
    onSubmitSearch = () => {
        this.setState({ page: 1 }, () => {
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: this.buildQueryString(),
            })
        })
    }

    startTimeChange = date => {
        this.setState({
            startTime: date ? date.startOf('day').toISOString() : undefined,
        })
    }

    endTimeChange = date => {
        this.setState({
            endTime: date ? date.endOf('day').toISOString() : undefined,
        })
    }

    disabledEndDate = endValue => {
        const startValue = this.state.startValue
        if (!endValue || !startValue) {
            return false
        }
        return endValue.valueOf() <= startValue.valueOf()
    }

    dateTimeChange = date => {
        // console.log('dateTimeChange ', date, dateString);

        this.setState({
            datetimeRange: date.length > 0 ? [date[0].startOf('day').toISOString(), date[1].endOf('day').toISOString()] : [],
        })
    }

    /**
     * upload file
     */
    onCreate = () => {
        const { t } = this.props
        const { isSubmitting, isUploading, disabled, fileDisabled } = this.state

        const file = this.importExcel.state.file
        const checkedList = this.ServiceGroup.state.checkedList

        // validate services
        if (this.ServiceGroup.validateServices() || !file) return

        const body = {
            services: checkedList.map(x => x.code),
        }

        if (isSubmitting === true || isUploading === true || disabled || fileDisabled) return
        this.setState({ isSubmitting: true, showProgressBar: true }, () => {
            let apiServiceUrl = `/customer/shipments/import`

            uploadFile(apiServiceUrl, [file], body)
                .then(response => {
                    this.setState({
                        showListSuccess: true,
                        excelImported: response,
                        isSubmitting: false,
                        showProgressBar: false,
                    })
                })
                .catch(error => {
                    if (error.title === 'data_sheet_not_found') {
                        notification['error']({
                            message: t('shipment.data_sheet_not_found'),
                        })
                    } else {
                        notification['error']({
                            message: t(error.message),
                        })
                    }
                    this.setState({ isSubmitting: false, showProgressBar: false })
                })
        })
    }

    fileUpLoaded = file => {
        const { t } = this.props
        const { isUploading, isSubmitting } = this.state
        if (isSubmitting === true || isUploading === true) return
        this.setState({ isUploading: true, excelImported: {}, fileDisabled: false }, () => {
            let fileErrors = []
            const reader = new FileReader()
            reader.onload = event => {
                const resultFile = event.target.result
                // console.log('csac')
                const workbook = XLSX.read(resultFile, { type: 'binary', sheets: 0 })
                // console.log('workbook', workbook)
                const first_worksheet = workbook.Sheets[workbook.SheetNames[0]]
                // console.log('first_worksheet', first_worksheet)
                if (!first_worksheet) {
                    notification['error']({
                        message: t('shipment.data_sheet_not_found'),
                    })
                    this.setState({ isUploading: false, fileErrors: fileErrors, fileDisabled: true })
                    return
                }
                const data = XLSX.utils.sheet_to_json(first_worksheet, { range: 2, header: 'A' })
                // validate theo từng column
                data.map((item, index) => {
                    let errText = ''

                    if (!item['A'] || !item['A'].toString().trim()) {
                        errText = errText + `${t('shipment.column')} ${'A'} ${t('shipment.lack_of_information')}, `
                    } else {
                        if (isNaN(item['A'])) {
                            errText = errText + `${t('shipment.column')} ${'A'} ${t('shipment.wrong_data_type')}, `
                        }
                    }
                    if (!item['B'] || !item['B'].toString().trim()) {
                        errText = errText + `${t('shipment.column')} ${'B'} ${t('shipment.lack_of_information')}, `
                    }
                    if (!item['C'] || !item['C'].toString().trim()) {
                        errText = errText + `${t('shipment.column')} ${'C'} ${t('shipment.lack_of_information')}, `
                    } else {
                        if (!validateLink(item['C'])) {
                            errText = errText + `${t('shipment.column')} ${'C'} ${t('shipment.link_error')}, `
                        }
                    }
                    if (!item['E'] || !item['E'].toString().trim()) {
                        errText = errText + `${t('shipment.column')} ${'E'} ${t('shipment.lack_of_information')}, `
                    }
                    if (!item['F'] || !item['F'].toString().trim()) {
                        errText = errText + `${t('shipment.column')} ${'F'} ${t('shipment.lack_of_information')}, `
                    }
                    if (!item['I'] || !item['I'].toString().trim()) {
                        errText = errText + `${t('shipment.column')} ${'I'} ${t('shipment.lack_of_information')}, `
                    } else {
                        if (isNaN(item['I'])) {
                            errText = errText + `${t('shipment.column')} ${'I'} ${t('shipment.wrong_data_type')}, `
                        }
                    }
                    if (!item['J'] || !item['J'].toString().trim()) {
                        errText = errText + `${t('shipment.column')} ${'J'} ${t('shipment.lack_of_information')}, `
                    } else {
                        if (isNaN(item['J'])) {
                            errText = errText + `${t('shipment.column')} ${'J'} ${t('shipment.wrong_data_type')}, `
                        }
                    }
                    if (!item['M'] || !item['M'].toString().trim()) {
                        errText = errText + `${t('shipment.column')} ${'M'} ${t('shipment.lack_of_information')}, `
                    }
                    if (!item['N'] || !item['N'].toString().trim()) {
                        errText = errText + `${t('shipment.column')} ${'N'} ${t('shipment.lack_of_information')}, `
                    }
                    if (!item['O'] || !item['O'].toString().trim()) {
                        errText = errText + `${t('shipment.column')} ${'O'} ${t('shipment.lack_of_information')}, `
                    }
                    if (!item['P'] || !item['P'].toString().trim()) {
                        errText = errText + `${t('shipment.column')} ${'P'} ${t('shipment.lack_of_information')}, `
                    }
                    if (!item['Q'] || !item['Q'].toString().trim()) {
                        errText = errText + `${t('shipment.column')} ${'Q'} ${t('shipment.lack_of_information')}, `
                    }
                    if (!item['R'] || !item['R'].toString().trim()) {
                        errText = errText + `${t('shipment.column')} ${'R'} ${t('shipment.lack_of_information')}, `
                    }

                    fileErrors[index] = errText
                })

                this.setState({ isUploading: false, fileErrors: fileErrors })
            }
            reader.readAsBinaryString(file)
        })
    }

    onCheckBoxCheck = (item, prop) => {
        let { listChecked } = this.state

        let arrayPropChecked = listChecked[prop] || []
        if (arrayPropChecked.find(x => x === item[this.getValuePropOfProp(prop)])) {
            arrayPropChecked = arrayPropChecked.filter(x => x !== item[this.getValuePropOfProp(prop)])
        } else {
            arrayPropChecked.push(item[this.getValuePropOfProp(prop)])
        }

        listChecked[prop] = arrayPropChecked

        this.setState({
            listChecked,
        })
    }

    renderModalImportFileExcel = () => {
        const { t } = this.props
        const {
            modalImportShow,
            isUploading,
            isSubmitting,
            fileErrors = [],
            disabled,
            excelImported = {},
            fileDisabled,
        } = this.state
        return (
            <Modal
                className="_modalAddress modal-import"
                title={t('shipment.modal_import_title')}
                visible={modalImportShow}
                onCancel={() => this.setState({ modalImportShow: false })}
                footer={
                    <div className="dpl-flex align-items-center width-100-percent justify-content-between">
                        <a
                            href={'//cdn.gobiz.vn/Import_shipment_template.xlsx'}
                            download
                            className="txt-color-blue cursor-pointer"
                        >
                            {t('shipment.download_template')}
                        </a>

                        <div>
                            <Button
                                onClick={() => this.setState({ modalImportShow: false })}
                                className="_btn-cancel"
                            >
                                {t('button.cancel')}
                            </Button>
                            <Button
                                onClick={this.onCreate}
                                disabled={disabled || fileErrors.filter(x => x).length > 0 || fileDisabled}
                                loading={isUploading || isSubmitting}
                                type="primary"
                                className="_btn-add"
                            >
                                {t('cartCheckout.confirm')}
                            </Button>
                        </div>
                    </div>
                }
            >
                <ImportExcel
                    t={t}
                    ref={ref => (this.importExcel = ref)}
                    // isUploading={isUploading}
                    fileUpLoaded={this.fileUpLoaded}
                />
                {fileErrors.filter(x => x).length > 0 && (
                    <div className="pdt10">
                        {fileErrors.map((x, y) => {
                            if (!x) return
                            return (
                                <div
                                    key={y}
                                    className="pdbt5 txt-color-red txt-size-h8"
                                >
                                    {t('shipment.row')} {y + 3}, {x.substring(0, x.length - 2)}
                                </div>
                            )
                        })}
                    </div>
                )}

                {excelImported && Array.isArray(excelImported.errorCells) && excelImported.errorCells.length > 0 && (
                    <div className="pdbt5 txt-color-red txt-size-h8">
                        {t('shipment.error_cell')}
                        {excelImported.errorCells.map((x, y) => {
                            if (y === 0) return x
                            return ', ' + x
                        })}
                    </div>
                )}

                <ServiceGroup
                    t={t}
                    handleDisable={value =>
                        this.setState({
                            disabled: value,
                        })
                    }
                    ref={ref => (this.ServiceGroup = ref)}
                />
            </Modal>
        )
    }

    /**
     * render orderStatus Search
     * @param item
     * @param index
     * @param prop
     */
    renderStatusSearchItem = (item, index, prop) => {
        const { shipmentsStatistics = [] } = this.state
        const arrayPropChecked = this.state.listChecked[prop] || []
        // console.log('arrayPropChecked', arrayPropChecked)
        let checked =
            Array.isArray(arrayPropChecked) && arrayPropChecked.find(x => x === item[this.getValuePropOfProp(prop)]) !== undefined
        return (
            <Tag.CheckableTag
                key={index}
                onChange={() => this.onCheckBoxCheck(item, prop)}
                className={`_check-${prop}-item cursor-pointer txt-size-h7 robotoregular txt-capitalize box-shadow-blue pdl10 pdr10`}
                checked={checked}
            >
                <Typography.Link style={checked ? { color: '#ffffff' } : {}}>
                    {item.name}{' '}
                    {prop === 'statuses' && shipmentsStatistics.find(x => x.status === item.code)
                        ? `(${quantityFormat(shipmentsStatistics.find(x => x.status === item.code).total)})`
                        : ''}
                </Typography.Link>
            </Tag.CheckableTag>
        )
    }

    /**
     * render check box search
     * @param item
     * @param index
     * @param prop
     */
    renderCheckboxSearch = (item, index, prop) => {
        const arrayPropChecked = this.state.listChecked[prop] || []
        return (
            <Checkbox
                className={`_check-checkbox-${prop}-item txt-size-h7 txt-color-gray robotoregular mgbt10 mgr5`}
                key={index}
                onChange={() => this.onCheckBoxCheck(item, prop)}
                checked={
                    Array.isArray(arrayPropChecked) &&
                    arrayPropChecked.find(x => x === item[this.getValuePropOfProp(prop)]) !== undefined
                }
            >
                {item.name}
            </Checkbox>
        )
    }

    /**
     * render list check box theo group
     * @param group
     * @param index
     */
    renderGroupServices = (group, index) => {
        const { listServices = [] } = this.state

        if (listServices.filter(x => x.serviceGroup && x.serviceGroup.id === group.id).length === 0) {
            return
        }

        return (
            <React.Fragment key={index}>
                <Divider
                    type="vertical"
                    className={'mgr20'}
                />
                {_.sortBy(
                    listServices.filter(x => x.serviceGroup && x.serviceGroup.id === group.id),
                    'position'
                ).map((x, y) => this.renderCheckboxSearch(x, y, 'services'))}
            </React.Fragment>
        )
    }

    renderInputFromTypeSearch = typeSearch => {
        const { t } = this.props
        const { handlingTimeFrom, handlingTimeTo } = this.state
        switch (typeSearch) {
            case 'range':
                return (
                    <Input.Group compact>
                        <Input
                            style={{ width: '50%' }}
                            placeholder={t('order.cut_off_days_from')}
                            value={handlingTimeFrom}
                            onKeyDown={e => {
                                if (e.keyCode === KEY_ENTER) {
                                    this.onSubmitSearch()
                                }
                            }}
                            onChange={e => this.setState({ handlingTimeFrom: e.target.value })}
                        />
                        <Input
                            style={{ width: '50%' }}
                            placeholder={t('order.cut_off_days_to')}
                            value={handlingTimeTo}
                            onKeyDown={e => {
                                if (e.keyCode === KEY_ENTER) {
                                    this.onSubmitSearch()
                                }
                            }}
                            onChange={e => this.setState({ handlingTimeTo: e.target.value })}
                        />
                    </Input.Group>
                )
            case 'equal':
                return (
                    <Input
                        placeholder={t('order.cut_off_days')}
                        value={handlingTimeFrom}
                        onChange={e =>
                            this.setState({
                                handlingTimeFrom: e.target.value,
                                handlingTimeTo: e.target.value,
                            })
                        }
                        onKeyDown={e => {
                            if (e.keyCode === KEY_ENTER) {
                                this.onSubmitSearch()
                            }
                        }}
                    />
                )
            case 'from':
                return (
                    <Input
                        placeholder={t('order.cut_off_days')}
                        value={handlingTimeFrom}
                        onChange={e =>
                            this.setState({
                                handlingTimeFrom: e.target.value,
                            })
                        }
                        onKeyDown={e => {
                            if (e.keyCode === KEY_ENTER) {
                                this.onSubmitSearch()
                            }
                        }}
                    />
                )
            case 'to':
                return (
                    <Input
                        placeholder={t('order.cut_off_days')}
                        value={handlingTimeTo}
                        onChange={e =>
                            this.setState({
                                handlingTimeTo: e.target.value,
                            })
                        }
                        onKeyDown={e => {
                            if (e.keyCode === KEY_ENTER) {
                                this.onSubmitSearch()
                            }
                        }}
                    />
                )
            default:
                return (
                    <Input.Group compact>
                        <Input
                            style={{ width: '50%' }}
                            placeholder={t('order.cut_off_days_from')}
                            value={handlingTimeFrom}
                            onChange={e => this.setState({ handlingTimeFrom: e.target.value })}
                            onKeyDown={e => {
                                if (e.keyCode === KEY_ENTER) {
                                    this.onSubmitSearch()
                                }
                            }}
                        />
                        <Input
                            style={{ width: '50%' }}
                            placeholder={t('order.cut_off_days_to')}
                            value={handlingTimeTo}
                            onChange={e => this.setState({ handlingTimeTo: e.target.value })}
                            onKeyDown={e => {
                                if (e.keyCode === KEY_ENTER) {
                                    this.onSubmitSearch()
                                }
                            }}
                        />
                    </Input.Group>
                )
        }
    }

    /**
     * render header page
     * @returns {*}
     */
    renderSearchBox = () => {
        const { t } = this.props
        const {
            cutOffStatus,
            typeSearch,
            listServices = [],
            listGroupServices = [],
            listStatuses = [],
            startTime,
            endTime,
            querySearch,
            originalReceiptCode,
            refCustomerCode,
            refShipmentCode,
            wayBill,
            merchantName,
            existsProduct,
            showMoreSearch,
        } = this.state
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        let tenantConfig = currentProjectInfo.tenantConfig || {}
        let shipmentConfig = tenantConfig.shipmentConfig || {}

        return (
            <div className="searchbox bg-color-white pd20 mgbt20">
                <Row className={'bd-color-gray borderdotted-bootom-2x pdbt15'}>
                    <Col
                        span={24}
                        className="dpl-flex "
                    >
                        <span className={'searchbox__label txt-color-black txt-size-h7 robotoregular'}>
                            {t('order.order_status')}:
                        </span>
                        <div className={'txt-size-h7 txt-color-blue robotoregular txt-capitalize width-100-percent flex-1-1'}>
                            {listStatuses.map((item, index) => this.renderStatusSearchItem(item, index, 'statuses'))}
                        </div>
                    </Col>
                </Row>
                <Row
                    gutter={20}
                    className={'dpl-flex bd-color-gray borderdotted-bootom-2x pdbt20 pdt15'}
                >
                    <Col span={12}>
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('tickets.enter_order_code')}:
                        </div>
                        <Input
                            value={querySearch}
                            onChange={e => this.setState({ querySearch: e.target.value })}
                            onKeyDown={e => {
                                if (e.keyCode === KEY_ENTER) {
                                    this.onSubmitSearch()
                                }
                            }}
                            className={'_search-box-input-query'}
                        />
                    </Col>
                    <Col span={12}>
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('order.created_time')}:
                        </div>
                        {/*<DatePicker.RangePicker*/}
                        {/*className={'_search-date-time width-100-percent'}*/}
                        {/*format={'DD/MM/YYYY'}*/}
                        {/*value={datetimeRange.length > 0 ? [moment(datetimeRange[0]), moment(datetimeRange[1])] : []}*/}
                        {/*onChange={this.dateTimeChange}*/}
                        {/*/>*/}
                        <Row gutter={20}>
                            <Col span={12}>
                                <DatePicker
                                    disabledDate={this.disabledStartDate}
                                    className="_datepicker width-100-percent"
                                    format={'DD/MM/YYYY'}
                                    value={startTime ? moment(startTime) : undefined}
                                    placeholder={t('order.start')}
                                    onChange={this.startTimeChange}
                                    // onOpenChange={this.handleStartOpenChange}
                                />
                            </Col>
                            <Col span={12}>
                                <DatePicker
                                    disabledDate={this.disabledEndDate}
                                    className="_datepicker width-100-percent"
                                    format={'DD/MM/YYYY'}
                                    value={endTime ? moment(endTime) : undefined}
                                    placeholder={t('order.finish')}
                                    onChange={this.endTimeChange}
                                    // onOpenChange={this.handleEndOpenChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {showMoreSearch && (
                    <>
                        <Row
                            gutter={20}
                            className={'dpl-flex bd-color-gray borderdotted-bootom-2x pdbt20 pdt15'}
                        >
                            <Col
                                span={8}
                                className="pdr10"
                            >
                                <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                                    {t('shipment.originalReceiptCode')}:
                                </div>
                                <Input
                                    value={originalReceiptCode}
                                    onChange={e => this.setState({ originalReceiptCode: e.target.value })}
                                    onKeyDown={e => {
                                        if (e.keyCode === KEY_ENTER) {
                                            this.onSubmitSearch()
                                        }
                                    }}
                                    className={'_search-box-input-originalReceiptCode'}
                                />
                            </Col>
                            <Col
                                span={8}
                                className="pdr10"
                            >
                                <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                                    {t('shipment.wayBill')}:
                                </div>
                                <Input
                                    value={wayBill}
                                    onChange={e => this.setState({ wayBill: e.target.value })}
                                    onKeyDown={e => {
                                        if (e.keyCode === KEY_ENTER) {
                                            this.onSubmitSearch()
                                        }
                                    }}
                                    className={'_search-box-input-wayBill'}
                                />
                            </Col>
                            <Col
                                span={8}
                                className="pdr10"
                            >
                                <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                                    {t('shipment.merchantName')}:
                                </div>
                                <Input
                                    value={merchantName}
                                    onChange={e => this.setState({ merchantName: e.target.value })}
                                    onKeyDown={e => {
                                        if (e.keyCode === KEY_ENTER) {
                                            this.onSubmitSearch()
                                        }
                                    }}
                                    className={'_search-box-input-merchantName'}
                                />
                            </Col>
                            <Col
                                span={12}
                                className=" pdt15"
                            >
                                <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                                    {t('shipment.refShipmentCode')}:
                                </div>
                                <Input
                                    value={refShipmentCode}
                                    onChange={e => this.setState({ refShipmentCode: e.target.value })}
                                    onKeyDown={e => {
                                        if (e.keyCode === KEY_ENTER) {
                                            this.onSubmitSearch()
                                        }
                                    }}
                                    className={'_search-box-refShipmentCode'}
                                />
                            </Col>
                            <Col
                                span={12}
                                className=" pdt15"
                            >
                                <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                                    {t('shipment.refCustomerCode')}:
                                </div>
                                <Input
                                    value={refCustomerCode}
                                    onChange={e => this.setState({ refCustomerCode: e.target.value })}
                                    onKeyDown={e => {
                                        if (e.keyCode === KEY_ENTER) {
                                            this.onSubmitSearch()
                                        }
                                    }}
                                    className={'_search-box-refCustomerCode'}
                                />
                            </Col>
                        </Row>
                        <Row className={'pdt15 pdbt10 borderdotted-bootom-2x bd-color-gray'}>
                            <Col
                                span={24}
                                className="dpl-flex"
                            >
                                <span className={'searchbox__label2 txt-color-black txt-size-h7 robotoregular pdr15'}>
                                    {t('order.service')}:
                                </span>
                                <span>
                                    {_.sortBy(
                                        listServices.filter(x => !x.serviceGroup),
                                        'position'
                                    ).map((item, index) => this.renderCheckboxSearch(item, index, 'services'))}
                                    {_.sortBy(listGroupServices, 'position').map((item, index) =>
                                        this.renderGroupServices(item, index)
                                    )}
                                </span>
                            </Col>
                        </Row>

                        {shipmentConfig.enableProductInformation && (
                            <Row className={'pdt15 pdbt10 borderdotted-bootom-2x bd-color-gray'}>
                                <Col
                                    span={24}
                                    className="dpl-flex"
                                >
                                    <span className={'searchbox__label2 txt-color-black txt-size-h7 robotoregular pdr15'}>
                                        {t('shipments.other')}:
                                    </span>
                                    <Checkbox
                                        className={`_check-checkbox-existsProduct txt-size-h7 txt-color-gray robotoregular mgbt10 mgr5`}
                                        onChange={e => {
                                            this.setState({ existsProduct: e.target.checked })
                                        }}
                                        checked={!!existsProduct} // "false"
                                    >
                                        {t('shipments.lack_of_product_information_label')}
                                    </Checkbox>
                                </Col>
                            </Row>
                        )}

                        <Row className={'pdbt15 mgbt10 borderdotted-bootom-2x bd-color-gray'}>
                            <Col
                                span={24}
                                className="mgt10"
                            >
                                <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                                    {t('order.order_cut_off_time')}:
                                </div>

                                <Row
                                    gutter={10}
                                    className=""
                                >
                                    <Col span={6}>
                                        <Select
                                            className="flex-none width-100-percent"
                                            showSearch
                                            placeholder={t('order.order_status')}
                                            optionFilterProp="children"
                                            value={cutOffStatus || []}
                                            onChange={value => this.setState({ cutOffStatus: value })}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {listStatuses.map((item, index) => (
                                                <Select.Option
                                                    key={index}
                                                    value={item.code}
                                                >
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Col>

                                    <Col span={6}>
                                        <Select
                                            className="flex-none width-100-percent"
                                            showSearch
                                            placeholder={t('order.cut_off_range')}
                                            optionFilterProp="children"
                                            value={typeSearch || []}
                                            onChange={value =>
                                                this.setState({
                                                    typeSearch: value,
                                                    handlingTimeFrom: '',
                                                    handlingTimeTo: '',
                                                })
                                            }
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Select.Option value="range">{t('order.cut_off_range')}</Select.Option>
                                            <Select.Option value="equal">{t('order.cut_off_equal')}</Select.Option>
                                            <Select.Option value="from">{t('order.cut_off_from')}</Select.Option>
                                            <Select.Option value="to">{t('order.cut_off_to')}</Select.Option>
                                        </Select>
                                    </Col>

                                    <Col span={12}>{this.renderInputFromTypeSearch(typeSearch)}</Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                )}

                <div className=" dpl-flex align-items-center justify-content-between pdt15">
                    <span
                        className="_btn-show-more-search txt-size-h7 cursor-pointer txt-color-blue robotoregular"
                        onClick={() => this.setState({ showMoreSearch: !this.state.showMoreSearch })}
                    >
                        {showMoreSearch ? (
                            <AntdIcon.UpOutlined className={'mgr5'} />
                        ) : (
                            <AntdIcon.DownOutlined className={'mgr5'} />
                        )}{' '}
                        {!showMoreSearch ? t('order.search_expand') : t('order.search_collapse')}
                    </span>

                    <div className="dpl-flex align-items-center">
                        <span
                            onClick={this.onClearSearch}
                            className="_btn-clear mgr20 txt-color-gray txt-size-h8 cursor-pointer robotoregular"
                        >
                            <AntdIcon.SyncOutlined
                                type={'sync'}
                                className={'mgr5'}
                            />{' '}
                            {t('order.filter_refresh')}
                        </span>
                        <Button
                            onClick={this.onSubmitSearch}
                            type="primary"
                            className={'_btn-search min-width-200'}
                        >
                            {t('order.search')}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    /**
     * render item of list
     * @param item
     * @param index
     * @returns {*}
     */
    renderListItem = (item, index) => {
        const { listStatuses = [], globalTenantConfig, loanCredits } = this.state
        const { t } = this.props
        const itemStatus = listStatuses.find(x => x.code === item.status) || {}
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        let tenantConfig = currentProjectInfo.tenantConfig || {}
        let shipmentConfig = tenantConfig.shipmentConfig || {}
        const isShowPeerPaymentCode = lodash.get(globalTenantConfig, 'config.shipmentRequired')
        const loanCredit = loanCredits.find(loanCredit => loanCredit?.shipmentCode === item?.code)

        let totalNeedPay = item?.totalUnpaid
        if (loanCredit) totalNeedPay += loanCredit?.status === 'ACTIVE' ? loanCredit?.totalAmountPay : 0
        const totalNeedPayFormat = moneyFormat(moneyCeil(totalNeedPay), null, true)

        return (
            <div
                className={`_order-item mgbt15`}
                key={index}
            >
                {shipmentConfig.enableProductInformation && !item.existsProduct && (
                    <div className="pdr15 pdl15 pdt5 pdbt5 list-alert-warning">{t('shipments.lack_of_product_information')}</div>
                )}
                <div className="listorders__items bg-color-gray">
                    <div className="order-item-head dpl-flex justify-content-between pd15 border-bottom-1x bd-color-gray">
                        <div className="order-item-head__left dpl-flex align-items-center">
                            <Typography.Paragraph
                                style={{ marginBottom: 0 }}
                                copyable={{ text: item.code }}
                            >
                                <Link
                                    to={`/shipments/${item.code}`}
                                    className={
                                        '_order-item-code listorders__items_codFe txt-color-blue txt-size-h7 robotoregular'
                                    }
                                >
                                    #{item.code}
                                </Link>
                            </Typography.Paragraph>
                            <Divider
                                type="vertical"
                                className={'listorders__items_vertical'}
                            />
                            <div className="dpl-flex align-items-center">
                                {/*{*/}
                                {/*item.merchantUrl*/}
                                {/*?*/}
                                {/*<a href={item.merchantUrl} target="_blank">*/}
                                {/*<Avatar className={`_order-avatar-seller`} shape="square" size={18}*/}
                                {/*src={item.marketplace ? item.marketplace.image : ''}/>*/}
                                {/*</a>*/}
                                {/*:*/}
                                {/*<Avatar className={`_order-avatar-seller`} shape="square" size={18}*/}
                                {/*src={item.marketplace ? item.marketplace.image : ''}/>*/}
                                {/*}*/}
                                {item.merchantUrl ? (
                                    <span
                                        href={item.merchantUrl}
                                        target="_blank"
                                    >
                                        <span className={'pdl5 txt-color-gray'}>
                                            <span className={'_order-seller-name txt-color-black'}>
                                                <AntdIcon.ShopOutlined type="shop" />{' '}
                                                {item.merchantName || item.merchantCode || '---'}
                                            </span>
                                        </span>
                                    </span>
                                ) : (
                                    <span className={'pdl5 txt-color-gray'}>
                                        <span className={'_order-seller-name txt-color-black'}>
                                            <AntdIcon.ShopOutlined type="shop" />{' '}
                                            {item.merchantName || item.merchantCode || '---'}
                                        </span>
                                    </span>
                                )}

                                {lodash.get(item.receivingWarehouse, 'displayName') && (
                                    <>
                                        <Divider
                                            type="vertical"
                                            className={'listorders__items_vertical'}
                                        />
                                        <span>
                                            {t('shipments.receivingWarehouseDisplayName')}:{' '}
                                            <b>{lodash.get(item.receivingWarehouse, 'displayName')}</b>
                                        </span>
                                    </>
                                )}
                            </div>
                        </div>

                        <div className={'listorders__items_right'}>
                            <span className={'_order-item-status'}>
                                <Popover
                                    trigger={'click'}
                                    overlayClassName={'white-tool-tip timeline-tooltip'}
                                    onVisibleChange={visible => {
                                        if (visible) this.getListMilestones(item.code)
                                    }}
                                    placement="left"
                                    content={this.renderTimeLine(item)}
                                >
                                    <Tag
                                        style={{ backgroundColor: itemStatus.color || '#898989' }}
                                        className={'listorders__items_right--status listorders__items_right--blue'}
                                    >
                                        {itemStatus.name} <AntdIcon.InfoCircleOutlined type="info-circle" />
                                    </Tag>
                                </Popover>
                            </span>
                        </div>
                    </div>
                    {isShowPeerPaymentCode && (
                        <div className="pd15 border-bottom-1x bd-color-gray">
                            {item.peerPaymentCode ? (
                                <span className="_table-row-refShipmentCode txt-size-h8 robotoregular">
                                    <span className={'txt-color-gray'}>{t('orderDetail.peerPaymentCode')}:</span>{' '}
                                    <Link
                                        to={`/peer-payments/${item.peerPaymentCode}`}
                                        target={'_blank'}
                                    >
                                        {item.peerPaymentCode || '---'}
                                    </Link>
                                </span>
                            ) : (
                                <span className="_table-row-refShipmentCode txt-color-gray txt-size-h8 robotoregular">
                                    {t('orderDetail.peerPaymentCode')}:{' '}
                                    <span className="txt-color-black">{item.peerPaymentCode || '---'}</span>
                                </span>
                            )}
                        </div>
                    )}
                    <div className="pd15 border-bottom-1x bd-color-gray">
                        <span className={'_note txt-color-gray txt-size-h8 robotoregular pdr15'}>Mã vận đơn:</span>
                        <span>
                            {lodash.isArray(item?.waybillCodes) && item?.waybillCodes.length > 0
                                ? item.waybillCodes.map((code, index) => {
                                      return (
                                          <span>
                                              <span className="txt-size-h8 robotoregular">{code}</span>
                                              {item.waybillCodes.length - 1 === index ? '' : <span className={`mx-4`}>|</span>}
                                          </span>
                                      )
                                  })
                                : '---'}
                        </span>
                    </div>
                    <div className="pd15 border-bottom-1x bd-color-gray">
                        <span className="_table-row-refShipmentCode txt-color-gray txt-size-h8 robotoregular">
                            {t('orderDetail.refShipmentCode')}:{' '}
                            <span className="txt-color-black">{item.refShipmentCode || '---'}</span>
                        </span>
                    </div>
                    {totalNeedPay > 0 && (
                        <div className="v2-layout pd15 border-bottom-1x bd-color-gray flex flex-col gap-3">
                            {item.contractWithShopkeeper && (
                                <div>
                                    <span className={'_note txt-color-gray txt-size-h7 robotoregular mr-1'}>
                                        {t('order_pay.bifin')}:
                                    </span>
                                    <span className="txt-size-h7 robotomedium mr-3 text-blue-primary">
                                        {moneyFormat(
                                            moneyCeil(loanCredit?.status !== 'ACTIVE' ? 0 : loanCredit?.totalAmountPay || 0),
                                            null,
                                            true
                                        )}
                                    </span>
                                </div>
                            )}
                            <div>
                                <span className={'_note txt-color-gray txt-size-h7 robotoregular mr-1'}>
                                    {t('order_pay.amount_need_pay')}:
                                </span>
                                <span className="txt-size-h7 robotomedium mr-3 text-blue-primary">{totalNeedPayFormat}</span>
                                {(item.status === 'READY_FOR_DELIVERY' || item.status === 'DELIVERING') && (
                                    <ModalPaymentBifin
                                        t={t}
                                        isShipment
                                        contractWithShopkeeper={item.contractWithShopkeeper}
                                        code={item.code}
                                        refetchData={this.loadFromQueryString}
                                        totalNeedPay={totalNeedPayFormat}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                    <div className="pd15 border-bottom-1x bd-color-gray">
                        <span className="_table-row-services txt-color-gray txt-size-h8 robotoregular">
                            {t('shipment.services')}:{' '}
                            {Array.isArray(item.services)
                                ? _.sortBy(item.services, 'position').map((x, y) => {
                                      if (y === 0)
                                          return (
                                              <span
                                                  key={y}
                                                  className="txt-color-black"
                                              >
                                                  {x.name}
                                              </span>
                                          )
                                      return (
                                          <span
                                              key={y}
                                              className="txt-color-black"
                                          >
                                              {' '}
                                              | {x.name}
                                          </span>
                                      )
                                  })
                                : '---'}
                        </span>
                    </div>
                    <div className="order-item-body pd15">
                        <Row gutter={16}>
                            <Col
                                span={5}
                                className="dpl-flex align-items-center"
                            >
                                <Link to={`/shipments/${item.code}`}>
                                    {item.image ? (
                                        <Avatar
                                            className="_table-image"
                                            shape="square"
                                            src={item.image}
                                            alt={item.code}
                                            size={32}
                                        />
                                    ) : (
                                        <AntdIconSVG
                                            className="_table-image custom-icon-shipment"
                                            component={iconShipment}
                                            style={{ fontSize: 24 }}
                                        />
                                    )}
                                </Link>
                                <div className={'total-number pdl5'}>
                                    <p className={'mgbt0 txt-color-gray txt-size-h8 robotoregular'}>
                                        {t('order.quantity')}{' '}
                                        <Tooltip
                                            placement="top"
                                            title={t('order.order_receive')}
                                        >
                                            <AntdIcon.QuestionCircleOutlined
                                                type="question-circle"
                                                theme="filled"
                                                className={'txt-size-h8 txt-color-gray opacity6 mgl5'}
                                            />
                                        </Tooltip>
                                    </p>
                                    <p className={'_total-number robotomedium txt-size-h7 txt-color-black mgbt0'}>
                                        {quantityFormat(item.totalProducts)}/{quantityFormat(item.receivedQuantity)}
                                    </p>
                                </div>
                            </Col>
                            <Col span={5}>
                                <div className={'total-number pdl5'}>
                                    <p className={'mgbt0 txt-color-gray txt-size-h8 robotoregular'}>
                                        {t('cartFinishOrder.amount_total')}
                                    </p>
                                    <p
                                        className={`_totalValue mgbt0 ${
                                            item.totalValue >= 0 ? 'txt-color-green' : 'txt-color-red'
                                        } txt-size-h7 robotomedium`}
                                    >
                                        {moneyFormat(moneyCeil(item.totalValue), item.currency)}
                                    </p>
                                </div>
                            </Col>
                            <Col span={5}>
                                <div className={'total-number pdl5'}>
                                    <p className={'mgbt0 txt-color-gray txt-size-h8 robotoregular'}>{t('shipment.totalFee')}</p>
                                    <p
                                        className={`_totalFee mgbt0 ${
                                            item.totalFee >= 0 ? 'txt-color-green' : 'txt-color-red'
                                        } txt-size-h7 robotomedium`}
                                    >
                                        {moneyFormat(moneyCeil(item.totalFee))}
                                    </p>
                                </div>
                            </Col>
                            <Col span={5}>
                                <div className={'total-number pdl5'}>
                                    <p className={'mgbt0 txt-color-gray txt-size-h8 robotoregular'}>
                                        {t('shipment.totalUnpaid')}
                                    </p>
                                    <p
                                        className={`_totalUnpaid mgbt0 ${
                                            item.totalUnpaid >= 0 ? 'txt-color-green' : 'txt-color-red'
                                        } txt-size-h7 robotomedium`}
                                    >
                                        {moneyFormat(moneyCeil(item.totalUnpaid))}
                                    </p>
                                </div>
                            </Col>
                            <Col span={4}>
                                <div className={'total-number pdl5'}>
                                    <p className={'mgbt0 txt-color-gray txt-size-h8 robotoregular'}>{t('shipment.timestamp')}</p>
                                    <p className={'_timestamp mgbt0 txt-color-black txt-size-h7 robotomedium'}>
                                        {moment(item.timestamp).format('HH:mm DD/MM/YYYY')}
                                    </p>
                                </div>
                            </Col>
                            <Col
                                span={5}
                                className="mt-2"
                            >
                                <div className={'total-number pdl5'}>
                                    <p className={'mgbt0 txt-color-gray txt-size-h8 robotoregular'}>
                                        {t('orderDetail.totalPackages')}
                                    </p>
                                    <p className={'_timestamp mgbt0 txt-color-black txt-size-h7 robotomedium'}>
                                        {item?.totalPackages || '---'}
                                    </p>
                                </div>
                            </Col>
                            <Col
                                span={5}
                                className="mt-2"
                            >
                                <div className={'total-number pdl5'}>
                                    <p className={'mgbt0 txt-color-gray txt-size-h8 robotoregular'}>
                                        {t('orderDetail.costing_weight')}
                                    </p>
                                    <p className={'_timestamp mgbt0 txt-color-black txt-size-h7 robotomedium'}>
                                        {item?.actualWeight ? item.actualWeight + 'kg' : '---'}
                                    </p>
                                </div>
                            </Col>
                            {/*<Col span={4}>*/}
                            {/*    <div className={'total-number pdl5'}>*/}
                            {/*        <p className={'mgbt0 txt-color-gray txt-size-h8 robotoregular'}>Cập nhật trạng thái</p>*/}
                            {/*        <p className={'_refund-lastStatusTime mgbt0 txt-color-black txt-size-h7 robotomedium'}>{moment(item.lastStatusTime).format('HH:mm DD/MM/YYYY')}</p>*/}
                            {/*    </div>*/}
                            {/*</Col>*/}
                        </Row>
                    </div>
                </div>
            </div>
        )
    }

    renderTimeLine = () => {
        const { t } = this.props
        const { listMilestones = [], isLoadingListMilestones, listStatuses = [] } = this.state

        if (isLoadingListMilestones === true) {
            return (
                <div className="mgt25 mgbt25 txt-center">
                    <Spin
                        spinning={isLoadingListMilestones === true}
                        indicator={
                            <AntdIcon.Loading3QuartersOutlined
                                type="loading"
                                style={{ fontSize: 24 }}
                                spin
                            />
                        }
                    />
                </div>
            )
        }

        return (
            <Timeline>
                {Array.isArray(listMilestones) &&
                    listMilestones.map((item, index) => {
                        const status = listStatuses.find(x => x.code === item.status)
                        let day = ''
                        if (item.handlingTime > 1) {
                            day = t('label.days')
                        } else {
                            day = t('label.day')
                        }

                        return (
                            <Timeline.Item color={index === 0 ? 'green' : 'gray'}>
                                <p className="mgbt0">
                                    {status ? status.name : '---'} - {moment(item.timestamp).format('HH:mm DD/MM')}
                                </p>
                                <p className="mgbt0">
                                    {item.handlingTime === null
                                        ? '(' + t('orderDetail.undefined') + ')'
                                        : '(' + item.handlingTime + ' ' + day + ')'}
                                </p>
                            </Timeline.Item>
                        )
                    })}
            </Timeline>
        )
    }

    render() {
        const { t } = this.props
        const {
            listShipments,
            isLoading,
            metadata,
            excelImported,
            showListSuccess,
            onExport,
            shipmentsStatistics = [],
            isBanned,
        } = this.state
        let locationQuery = _.get(this.props, 'location.search', '')
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo')
        let suspensionSchedule = currentProjectInfo.suspensionSchedule
        let disabled =
            suspensionSchedule &&
            Array.isArray(suspensionSchedule.appliedFor) &&
            !!suspensionSchedule.appliedFor.find(x => x === 'ALL' || x === 'SHIPMENT')

        return (
            <MainLayout
                ref={ref => (this.mainLayoutElement = ref)}
                {...this.props}
                showProgressBar={this.state.showProgressBar}
                headerTitle={t('shipment.shipment_list')}
                title={t('shipment.shipment_list')}
            >
                {showListSuccess && excelImported && excelImported.status === 'SUCCESS' ? (
                    <ImportExcelResult
                        onShowListSuccess={value =>
                            this.setState({ showListSuccess: value, modalImportShow: false }, () => {
                                this.loadData()
                            })
                        }
                        data={excelImported}
                        {...this.props}
                    />
                ) : (
                    <div className={'container-common pdbt30 mgr20'}>
                        {shipmentsStatistics.find(x => x.status === 'DELIVERY_READY') &&
                        shipmentsStatistics.find(x => x.status === 'DELIVERY_READY').total !== '0' ? (
                            <Alert
                                className="mgbt20"
                                message={<span className="bold txt-size-h6">{t('order.notification')}</span>}
                                description={
                                    <span className="txt-size-h7">
                                        {t('order.have')}{' '}
                                        <span className="_DELIVERY_READY bold">
                                            {quantityFormat(shipmentsStatistics.find(x => x.status === 'DELIVERY_READY').total)}
                                        </span>{' '}
                                        {t('order.order_at_stock')}, {t('order.please')}{' '}
                                        <Link to={'/delivery/create'}>{t('order.delivery_request')}</Link>{' '}
                                        {t('order.to_delivery')}
                                    </span>
                                }
                                type="success"
                                showIcon
                                closable
                            />
                        ) : null}

                        {disabled && (
                            <Alert
                                className="custom-alert-suspension-schedule white-space-pre-wrap break-word mgbt20"
                                message={<div className="bold txt-size-h6">{t('cart.suspension_notify')}</div>}
                                description={
                                    <div
                                        className="txt-size-h7"
                                        dangerouslySetInnerHTML={{
                                            __html: suspensionSchedule ? suspensionSchedule.description : '',
                                        }}
                                    />
                                }
                                type="warning"
                                showIcon
                                closable
                            />
                        )}

                        {this.renderSearchBox()}
                        <div className="dpl-flex justify-content-between align-items-center bg-color-white pd20 border-bottom-1x bd-color-gray">
                            {metadata ? (
                                <span className={'txt-color-black txt-size-h5 robotobmedium'}>
                                    {t('shipment.shipment_list')} (
                                    <span className={'_total-item-in-page'}>{quantityFormat(metadata.total)}</span>)
                                </span>
                            ) : (
                                <span />
                            )}
                            <div className="dpl-flex">
                                <span
                                    onClick={() => {
                                        if (disabled) return
                                        if (isBanned) {
                                            Modal.info({
                                                className: 'modal-user-banned',
                                                maskClosable: true,
                                                title: t('order.notification'),
                                                content: (
                                                    <div>
                                                        <p className="txt-size-h7 mgbt5">
                                                            {t('cart.order_function_locked_account')}
                                                        </p>
                                                        <p className="txt-size-h7 mgbt5">{t('cart.contact_consultant')}</p>
                                                    </div>
                                                ),
                                                // okText: ''
                                            })
                                        } else {
                                            this.setState({ modalImportShow: true })
                                        }
                                    }}
                                    className={`_btn-create ant-btn ant-btn-primary mgr15 ${disabled ? 'disabled' : ''}`}
                                >
                                    {t('shipment.modal_import_title')}
                                </span>

                                <ExportExcel
                                    {...this.props}
                                    onExport={onExport}
                                    exportExcel={this.exportExcel}
                                />

                                {isBanned || disabled ? (
                                    <span
                                        onClick={() => {
                                            if (disabled) return
                                            Modal.info({
                                                className: 'modal-user-banned',
                                                maskClosable: true,
                                                title: t('order.notification'),
                                                content: (
                                                    <div>
                                                        <p className="txt-size-h7 mgbt5">
                                                            {t('cart.order_function_locked_account')}
                                                        </p>
                                                        <p className="txt-size-h7 mgbt5">{t('cart.contact_consultant')}</p>
                                                    </div>
                                                ),
                                                // okText: ''
                                            })
                                        }}
                                        className={`_btn-create cursor-pointer ant-btn ant-btn-primary mgl15 ${
                                            disabled ? 'disabled' : ''
                                        }`}
                                    >
                                        {t('shipment.btn_create_shipment')}
                                    </span>
                                ) : (
                                    <Link
                                        to={'/shipments/create'}
                                        className={`_btn-create ant-btn ant-btn-primary mgl15`}
                                    >
                                        {t('shipment.btn_create_shipment')}
                                    </Link>
                                )}
                            </div>
                        </div>
                        <div className="pd20 bg-color-white">
                            {Array.isArray(listShipments) && listShipments.length > 0 ? (
                                <List
                                    className={'_list-orders bg-color-white'}
                                    // header={this.renderHeaderList()}
                                    dataSource={listShipments}
                                    renderItem={this.renderListItem}
                                    // loadMore={this.renderLoadMore()}
                                    pagination={{
                                        className: '_pagination-order txt-right mgt20',
                                        hideOnSinglePage: true,
                                        current: metadata ? parseFloat(metadata.page) + 1 : 1,
                                        total: metadata ? parseFloat(metadata.total) : 1,
                                        pageSize: metadata ? parseFloat(metadata.size) : pageSize,
                                        onChange: (page, pageSize) => this.onPageChanged(page, pageSize),
                                    }}
                                />
                            ) : isLoading ? (
                                <Loading />
                            ) : (
                                <div className="pdt50 pdbt60 bg-color-white">
                                    <Empty
                                        image={require('../../resources/images/empty-image.png')}
                                        description={locationQuery === '' ? t('shipment.empty_list') : t('message.empty')}
                                    >
                                        {locationQuery === '' && (
                                            <Link
                                                to={'/shipments/create'}
                                                className={'_btn-create-in-table ant-btn ant-btn-primary'}
                                            >
                                                {t('shipment.btn_create_shipment')}
                                            </Link>
                                        )}
                                    </Empty>
                                </div>
                            )}
                        </div>
                        {this.renderModalImportFileExcel()}
                    </div>
                )}
            </MainLayout>
        )
    }
}
export default withNamespaces()(Shipment)
