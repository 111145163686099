/* eslint-disable array-callback-return */
import React from "react";
import {Row, Col, Input, Table} from "antd";
import { moneyFormat, quantityFormat} from "../../utils/stringUtils";
import {LocalStore} from "../../utils/LocalStore";

export default class Inspection extends React.Component {
	static defaultProps = {
		isEdit: true,
	};

	constructor(props) {
		super(props);
		this.state = {
			feeObj: null,
			priceList: [],
			quantityList: [],
			tableData: [],
		}
	}

	componentDidMount() {

		this.initInspectionTable(this.props.feeObj);
	}

	initQuantityTable = (data) => {
		let quantityList = [];
		let quantityKey = Object.keys(data['0']);
		quantityKey.map((key, index) => {
			quantityList.push({
				from: key === 'newQuantity' ? key : parseFloat(key),
				to: index < quantityKey.length - 1 ? quantityKey[index + 1] !== 'newQuantity' ? parseFloat(quantityKey[index + 1]) : parseFloat(key) + 1 : 0
			})
		});
		return quantityList;
	};

	initPriceTable = (data) => {
		let priceList = [];
		let priceKey = Object.keys(data);
		Object.keys(data).map((key, index) => {
			priceList.push({
				from: key === 'newPrice' ? key : parseFloat(key),
				to: index < priceKey.length - 1 ? priceKey[index + 1] !== 'newPrice' ? parseFloat(priceKey[index + 1]) : parseFloat(key) + 1 : 0
			});
		});
		return priceList;
	};

	initInspectionTable = (feeObj) => {
		let quantityList = this.initQuantityTable(feeObj.feeMetadata.data);
		let priceList = this.initPriceTable(feeObj.feeMetadata.data);
		let tableData = [];
		quantityList.map(qt => {
			let obj = {};
			obj.quantity = qt;
			priceList.map(pr => {
				obj[pr.from] = feeObj.feeMetadata.data[pr.from][qt.from];
			});
			tableData.push(obj);
		});
		this.setState({
			feeObj,
			quantityList,
			priceList,
			tableData
		});
	};

	render() {
		let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
		let tenantConfig = currentProjectInfo.tenantConfig || {};
		let currency = tenantConfig.currency || {};

		let defaultCurrency = (currency.prefix || '') + (currency.suffix || '');

		const {isEdit, orderDetail = {}, t} = this.props;
		const {tableData, priceList, quantityList} = this.state;

		const columns = [
			{
				title: <span>{t('config_group.number_products')} <span className={'flr mgr-10'}>{t('config_group.unitPrice')}</span></span>,
				dataIndex: 'quantity',
				width: 80,
				key: 'quantity',
				className: 'linedg',
				render: ((text, record, index) => {
					if (index === quantityList.length - 1) {
						return <Row
							className={'dpl-flex align-items-center'}>
							<span>{`${t('config_group.from')} ${quantityFormat(record.quantity.from)}`}</span>
						</Row>;
					} else
						return (
							<Row
								className={'dpl-flex align-items-center'}>
								<span>{`${t('config_group.from')} ${quantityFormat(record.quantity.from)} ${t('config_group.to')} ${t('config_group.below')} ${quantityFormat(record.quantity.to)}`}</span>
							</Row>
						);
				})
			}
		];
		priceList.map((item, index) => {
			columns.push({
				title: () => {
					if (index === priceList.length - 1) {
						return <Row
							className={' dpl-flex justify-content-center align-items-center'}>
							<span className={'whitespace'}>{`${t('config_group.from')} ${moneyFormat(item.from, orderDetail.currency)}`}</span>
						</Row>;
					} else
						return (
							<Row
								className={'dpl-flex justify-content-center align-items-center'}>
								<span>{`${t('config_group.from')} ${moneyFormat(item.from, orderDetail.currency)} ${t('config_group.to')} ${t('config_group.below')} ${moneyFormat(item.to, orderDetail.currency)}`}</span>
							</Row>
						);
				},
				width: 80,
				dataIndex: item.from,
				key: item.from,
				render: ((text, record, index) => {
					return (<Row className={'dpl-flex justify-content-center align-items-center'}>
						<Col span={18}>
							<Input disabled={!isEdit} value={quantityFormat(record[item.from])}/>
						</Col>
						<Col span={6}>
							<span className={'mgl5'}>{defaultCurrency}/sp</span>
						</Col>
					</Row>);
				})
			})
		});
		return (
			<div>
				<Row>
					<Col span={24}>
						<Table dataSource={tableData}
						       columns={columns}
						       pagination={false}
						       bordered
						       scroll={{x: 80 * priceList.length + 100}}
						       rowKey={record => record.quantity.from}>
						</Table>
					</Col>
				</Row>
			</div>
		);
	}

}
