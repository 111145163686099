import moment from 'moment/moment'
import lang from '../resources/localization/Localization'
import { LocalStore } from './LocalStore'
import numeral from 'numeral'
import { isUpdateTicket } from './helper'
import queryString from 'query-string'
import linkifyHtml from 'linkify-html'

const md5 = require('blueimp-md5')

/**
 * Lấy module hiện tại trên URL
 * @returns {string}
 */
export function getAvatarUser(email) {
    return '//www.gravatar.com/avatar/' + md5(email || '') + '?d=retro&s=40&r=g'
}

/**
 * Lấy module hiện tại trên URL
 * @returns {string}
 */
export function getCurrentModule(url) {
    return url || window.location.pathname
}

/**
 * Parse query string to an object
 * @param queryString
 * @returns {{}}
 */
export function parseQueryStringToObject(query) {
    if (query && query.indexOf('?') > -1) {
        query = query.replace('?', '')
    }

    let params = queryString.parse(query.replace('?', ''))
    // let params = {}, queries, temp, i, l;
    // // Split into key/value pairs
    // queries = queryString.split("&");
    // // Convert the array of strings into an object
    // for (i = 0, l = queries.length; i < l; i++) {
    //     temp = queries[i].split('=');
    //     params[temp[0]] = temp[1];
    // }
    return params
}

/**
 * thêm mới hoặc sửa lại param của query string
 * @param uri
 * @param key
 * @param value
 * @returns {*}
 */
export function updateQueryStringParameter(uri, key, value) {
    if (uri === undefined || uri === null) return ''
    let re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
    let separator = uri.indexOf('?') !== -1 ? '&' : '?'
    if (value === null) {
        // eslint-disable-next-line no-useless-concat
        return uri.replace(re, '$1' + '$2')
    }
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + '=' + encodeURIComponent(value) + '$2')
    } else {
        return uri + separator + key + '=' + encodeURIComponent(value)
    }
}

export function eclipseString(text, length, eclipseChar) {
    let result = ''
    eclipseChar = eclipseChar || '...'
    if (text && typeof text === 'string') {
        if (text.length > length) {
            result = text.substr(0, length - eclipseChar.length) + eclipseChar
        } else {
            result = text
        }
    }

    return result
}

/**
 * format số, số lượng có dấu , phân cách hàng nghìn
 * @param value
 * @param unit
 * @param noNegative
 * @returns {string}
 */
export const quantityFormat = (value, unit = '', noNegative = undefined) => {
    if (value === null || value === undefined || value === '') return '---'
    //loại bỏ tất cả các ký tự ',' trong value
    let temp = value.toString().replace(/[,-]/g, '')
    let result = `${numeral(parseFloat(temp)).format('0,0.[0000]')}`
    if (noNegative) {
        result.replace('-', '')
    }
    return result
}

/**
 * format tiền, số lượng có dấu , phân cách hàng nghìn, có đơn vị tiền tệ
 * @param value
 * @param unit
 * @param noNegative
 * @returns {string}
 */
export const moneyFormat = (value, unit = undefined, noNegative = undefined) => {
    let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
    let currencies = LocalStore.getInstance().read('currencies') || []

    let tenantConfig = currentProjectInfo.tenantConfig || {}
    let currency = tenantConfig.currency || {}
    if (value === null || value === '' || value === undefined) return '---'

    if (unit) {
        const unitCurrency = currencies.find(x => x.code === unit) || {}
        return (
            (!noNegative && parseFloat(value) < 0 ? '-' : '') +
            (unitCurrency.prefix || '') +
            quantityFormat(value, '', true) +
            (unitCurrency.suffix || '')
        )
    }
    return (
        (!noNegative && parseFloat(value) < 0 ? '-' : '') +
        (currency.prefix || '') +
        quantityFormat(value, '', true) +
        (currency.suffix || '')
    )
}

export function extractParameters(url) {
    const queryString = url.split('?')[1]
    if (!queryString) {
        return {}
    }
    const parameterPairs = queryString.split('&')
    const parameters = {}

    for (let pair of parameterPairs) {
        const [key, value] = pair.split('=')
        parameters[key] = value
    }

    return parameters
}

/**
 * format đơn giá
 * @param value
 * @returns {*}
 */
export const formatValue = value => {
    if (value === null || value === '') return
    return `${numeral(value).format('0,0.[0000]')}`
}

/**
 * format đơn giá
 * @param value
 * @param unit đơn vị tiền
 * @returns {*}
 */
export const formatPriceByUnit = (value, unit) => {
    if (value === undefined || value === null) return '---'
    if (value && value === 'trống') return value
    value = parseFloat(value)
    const localStore = LocalStore.getInstance()
    let currencies = localStore.read('currencies')
    let tenant = localStore.read('currentProjectInfo')
    if (!unit) unit = tenant?.tenantConfig?.defaultCurrency
    let currency = currencies.find(item => item.code === unit)
    let preCharacter = ''
    if (value < 0) {
        preCharacter = '-'
        value = Math.abs(value)
    }
    return `${preCharacter}${currency && currency.prefix ? currency.prefix : ''}${numeral(value).format('0,0.[0000]')}${
        currency && currency.suffix ? currency.suffix : ''
    }`
}

/**
 * format đơn giá
 * @param value
 * @param key: , hoặc . theo locale
 * @returns {*}
 */
export const formatPrice = (value, key = ',') => {
    if (value === null || value === undefined) return ''
    value = value.toString()
    value = value.replace(/[.]/g, '')
    if (value.indexOf(key) > 0) {
        let temp = value.split(key)
        return `${temp[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
    } else {
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    }
}

/**
 * làm tròn lên với tiền tệ
 * @param value
 * @returns {number}
 */
export const moneyCeil = value => {
    // if (!Number.isFinite(value)) return null;
    // if (value < 0) {
    //     return -Math.floor(-value)
    // }
    //
    // return Math.floor(value);
    return Math.round(value)
}

/**
 * Vi-vn là 1.000.000,00
 * En-us là 1,000,000.00
 * @param e
 */
export const formatNumber = e => {
    let raw = typeof e === 'string' ? e : ''
    raw = raw.replace(/[^\d,]/g, '')
    if (raw.indexOf(',') < 0 && raw.length > 0) raw = parseInt(raw)
    else if (raw.indexOf(',') === 0) raw = ''
    else if (raw.indexOf(',') > 0) {
        let temp = raw.split(',')
        let firstNumber = parseInt(temp[0]).toString()
        let secondNumber = temp[1]
        if (secondNumber.length > 4) {
            secondNumber = secondNumber.substring(0, 4)
        }
        raw = `${firstNumber},${secondNumber}`
    }
    return raw.toString()
}

/**
 * Format số theo định dạng
 * @param value
 * @param isAllowNegative số âm
 * @param isFloat nhập dấu ","
 * @param decimal lấy sau dấu phẩy mấy chữ s
 * @returns {string|number}
 */
export const formatAmountTyping = (value, isAllowNegative = false, isFloat = true, decimal = 4) => {
    let raw = typeof value === 'string' ? value : ''
    let isNegative = false
    if (raw.indexOf('-') === 0 && isAllowNegative) isNegative = true
    if (isFloat) raw = raw.replace(/[^\d+\\^]/g, '')
    else raw = raw.replace(/[^\d]/g, '')
    if (raw.indexOf(',') < 0 && raw.length > 0) raw = parseInt(raw)
    else if (raw.indexOf(',') === 0) raw = ''
    else if (raw.indexOf(',') > 0) {
        let temp = raw.split(',')
        let firstNumber = parseInt(temp[0]).toString()
        let secondNumber = temp[1]
        if (secondNumber.length > decimal) {
            secondNumber = secondNumber.substring(0, decimal)
        }
        raw = `${firstNumber},${secondNumber}`
    }
    if (isNegative) raw = '-' + raw
    return raw
}

/**
 * cắt tên file
 * @param fileName
 * @param maxLength
 * @returns {*}
 */
export const formatFileName = (fileName, maxLength) => {
    if (!fileName) return ''
    if (fileName.length <= maxLength) return fileName
    else {
        let endFile = fileName.substring(fileName.lastIndexOf('.'), fileName.length)
        let temp = maxLength - endFile.length
        if (temp > 3) {
            let startFile = fileName.substring(0, temp - 3)
            return startFile + '...' + endFile
        } else {
            let startFile = fileName.substring(0, temp)
            return startFile + endFile
        }
    }
}
/**
 *
 * @param text
 * @returns {string}
 */
export const linkify = text => {
    if (!text) return ''
    // let urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    // return text.replace(urlRegex, function(url) {
    //     return '<a target="_blank" href="' + url + '">' + url + '</a>';
    // });

    return linkifyHtml(text, {
        ignoreTags: ['script', 'style', 'img', 'iframe'],
    })
}

export const replaceSpecialCharacters = text => {
    if (!text) return ''
    return text.replace(/[^a-zA-Z0-9 ]/g, '')
}

export const checkIsLink = text => {
    if (!text) return false

    if (!text.startsWith('http')) return false

    const res = text.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g)
    if (res == null) return false
    else return true
}

export const validateLink = text => {
    let urlRegex = /^((http[s]?|ftp):\/)?\/?([^:\\/\s]+)((\/\w+)*\/)([\w\-\\.]+[^#?\s]+)(.*)?(#[\w\\-]+)?$/
    return urlRegex.test(text)
}

/**
 * Thay thế trong thẻ <a> target="_selft" => target = "_blank"
 * @param text
 */
export const editHTML = text => {
    let urlRegex = /self/gm
    return text.replace(urlRegex, '_blank')
}

/**
 * Thời gian bình luận
 * @returns {*}
 */
export const timeAgo = (dateString, t) => {
    let currentTime = new Date()
    let timeAgo = new Date(dateString)
    let seconds = currentTime - timeAgo

    let minutes = Math.ceil(seconds / (60 * 1000))
    let hours = Math.ceil(seconds / (3600 * 1000))
    // let days = Math.ceil(seconds / (86400 * 1000));

    if (seconds <= 59 * 1000) {
        return t('date.recent')
    } else if (minutes <= 60) {
        if (minutes === 1) {
            return ` 1 ${t('date.minute')}`
        } else {
            return minutes + ` ${t('date.minute')}`
        }
    } else if (hours <= 24) {
        if (hours === 1) {
            return ` 1 ${t('date.hour')}`
        } else {
            return hours + ` ${t('date.hour')}`
        }
    } else {
        return moment(dateString).format('HH:mm DD/MM/YYYY')
    }
}

/**
 * Tính số giờ đã tạo khiếu nại
 * @param dateString: thời gian tạo
 * @return hours: số giờ kể từ thời gian tạo
 */
export const hoursAgo = dateString => {
    if (!dateString) return

    let currentTime = new Date()
    let timeAgo = new Date(dateString)
    let seconds = currentTime - timeAgo
    let hours = Math.ceil(seconds / (3600 * 1000))
    return hours
}

/**
 * Tính số giây đến hạn xử lý
 * @param dateString
 * @return boolean
 */
export const secondsDeadline = dateString => {
    if (!dateString) return

    let currentTime = new Date()
    let deadlineTime = new Date(dateString)
    let seconds = deadlineTime - currentTime
    return seconds
}

/**
 * Tính số giờ đến hạn xử lý
 * @param dateString
 * @return boolean
 */
export const hoursDeadline = dateString => {
    if (!dateString) return

    let currentTime = new Date()
    let deadlineTime = new Date(dateString)
    let seconds = deadlineTime - currentTime
    let hours = Math.ceil(seconds / (3600 * 1000))
    return hours
}

/**
 * Check quá hạn xử lý và sắp đến hạn xử lý
 * @param item
 * @param currentState
 * @return message đa ngôn ngữ
 */
export const renderWarningPeriod = (item, currentState) => {
    if (!item) return
    let currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser')
    let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo')
    // let typeProject = currentProjectInfo ? currentProjectInfo.type : '';
    let warningPeriod =
        currentProjectInfo && currentProjectInfo.applicationConfig ? currentProjectInfo.applicationConfig.warningPeriod : null
    if (item.modifiedBy !== currentLoggedUser.username && isUpdateTicket(item)) {
        // if (typeProject === 'ADVANCE') {
        //     return lang.message.warning_claim;
        // }
        return lang.message.warning_ticket
    } else if (item.state !== 'NEW' && currentState && currentState.lastState === false) {
        if (secondsDeadline(item.deadline) <= 0) {
            return lang.message.overdue
        } else if (hoursDeadline(item.deadline) <= warningPeriod) {
            return lang.message.due_soon
        }
    }
}

export const hashCode = (str = '') => {
    // java String#hashCode
    let hash = 0
    if (!str) return hash
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    return hash
}

export const intToRGB = i => {
    const c = (i & 0x00ffffff).toString(16).toUpperCase()

    return '00000'.substring(0, 6 - c.length) + c
}

export const textUcFirst = string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}
