import React from 'react';
import {
    Row, Col, Button, Input, Spin, DatePicker, Form, notification, Select, Modal,
} from 'antd';
import * as AntdIcon from "@ant-design/icons"

import {LocalStore} from "../../utils/LocalStore";
import moment from 'moment';
import UserService from "../../services/user.service";
import {withNamespaces} from 'react-i18next';
import {validateEmail} from "../../utils/validate";

let currentLoggedUser = null;

class FormUpdateProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user_profile: [],
            isShowEdit:false,
            isEditFullName:false,
            isEditAddress:false,
            isEditDob:false,
            isEditPhone:false,
            isEditGender:false,
        }
    }

    componentWillMount() {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null;

        if (typeof LocalStore.getInstance().read('currentLoggedUser') === 'object') {
            currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {};
            this.setState({user_profile: currentLoggedUser});

        }
    }

    /**
     * Call func submit note order if click outside

     */
    onBlurInput = () => {
        const {showModalConfirm} = this.state;
        currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {};
        if (!showModalConfirm) {
            this.setState({
                user_profile: currentLoggedUser,
                isEditAddress:false,
                isEditFullName:false,
                isEditDob:false,
                isEditGender:false,
                isEditPhone:false,
                isEditEmail:false,
                errUpdate: false,
                isLoadingSubmit: false
            });
        }
    };

    onKeyDown = (e, prop) => {
        currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {};
        if (e.target.value === '' || e.target.value.trim() === '') return;

        let payload = {};
        payload[prop] = e.target.value.trim();

        if (e.keyCode === 13) {
            if (e.target.value.trim() !== currentLoggedUser[prop]) {
                this.setState({isLoadingSubmit: true}, () => {
                    this.onSubmitUserProfile(payload);
                })
            } else {
                this.onBlurInput()
            }
        }
    };

    /**
     * input change event
     * @param value
     * @param prop
     * @param item
     */
    onInputChange = (value, prop) => {
        if (value !== '' && value.trim() === '') return;
        this.setState(prevState => ({
            user_profile: {
                ...prevState.user_profile,
                [prop]: value
            }
        }))
    };

    onChangeSelect = (type, value) => {

        let payload = {};
        payload[type] = value.trim();

        this.onSubmitUserProfile(payload);


    };

    onSubmitUserProfile = (payload) => {
        const {t} = this.props;

        this.setState({isLoadingSubmit: true}, () => {
            UserService.updateProfile(payload, (err, res) => {
                if (!err) {
                    if (payload.email) {
                        Modal.success({
                            content: <div dangerouslySetInnerHTML={{__html: t("userProfile.mail_change_success", {newMail: payload.email})}}/>,
                        })
                    } else {
                        notification['success']({
                            message: t('message.success'),
                        });
                    }
                    LocalStore.getInstance().save('currentLoggedUser', res);
                    this.setState({
                        showModalConfirm: false,
                        password: '',
                    }, () => {
                        this.onBlurInput()
                    });
                } else {
                    if (err.title === 'password_not_match' || err.title === 'invalid_password') {
                        this.setState({
                            passwordError: t('login.error_password')
                        });
                    } else if (err.title === "email_duplicated" || err.title === 'duplicate_email') {
                        this.setState({
                            emailError: t('register.email_duplicated'),
                            showModalConfirm: false,
                            password: '',
                        })
                    } else {
                        this.setState({errUpdate: true}, () => {
                            setTimeout(() => {
                                this.setState({errUpdate: false});
                            }, 500);
                        });
                    }
                }
                this.setState({showProgressBar: false, isLoadingSubmit: false});
            })
        })
    };

    onClickSave = (e, prop) => {
        currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {};
        if (e.state['value'] === '' || e.state['value'].trim() === '') return;

        let payload = {};
        payload[prop] = e.state['value'].trim();

        if (e.state['value'].trim() !== currentLoggedUser[prop]) {
            this.onSubmitUserProfile(payload);
        } else {
            this.onBlurInput()
        }
    };

    DobChange = (date) => {
        let payload = {
            dob : date ? date.endOf('day').toISOString() : undefined
        };

        this.setState({isLoadingSubmit: true}, () => {
            this.onSubmitUserProfile(payload);
        })
    };

    handleStartOpenChange = open => {
        if (!open) {
            this.onBlurInput()
        }
    };

    /**
     * sự kiện validate của input fullName
     */
    onValidateFullNameInput = () => {
        const {user_profile = {}} = this.state;
        const {t} = this.props;
        if (!user_profile.fullname || !user_profile.fullname.trim()) {
            this.setState({
                fullNameError: t('customer_info.input_fullname')
            });
            return true
        } else {
            this.setState({
                fullNameError: ""
            })
        }
        return false
    };

    /**
     * sự kiện validate của input fullName
     */
    onValidateEmailInput = () => {
        const {user_profile = {}} = this.state;
        const {t} = this.props;
        if (!user_profile.email) {
            this.setState({
                emailError: t('register.email_require')
            });
            return true
        } else {
            if (validateEmail(user_profile.email)) {
                this.setState({
                    emailError: t(validateEmail(user_profile.email))
                });
                return true
            } else {
                this.setState({
                    emailError: ""
                })
            }
        }
        return false
    };

    renderModalConfirmPin = () => {
        const {showModalConfirm, password, isLoadingSubmit, passwordError, user_profile = {}} = this.state;
        const {t} = this.props;

        return <Modal
            title={t('login.password')}
            visible={showModalConfirm}
            onOk={() => {
                if (!password) {
                    this.setState({
                        passwordError: t('login.password_error')
                    });
                    return;
                }
                this.onSubmitUserProfile({email: user_profile.email, password})
            }}
            confirmLoading={isLoadingSubmit}
            onCancel={() => this.setState({
                showModalConfirm: false,
                password: '',
            }, () => {
                this.onBlurInput()
            })}
            okText={t('cartCheckout.confirm')}
            cancelText={t('cartCheckout.cancel')}
            okButtonProps={{className:"_btn-submit-confirm"}}
            cancelButtonProps={{className:"_btn-cancel-confirm"}}
        >
            <Input.Password
                className={'_input-password'}
                placeholder={t('login.password')}
                autoFocus={true}
                value={password}
                onChange={(e) => this.setState({password: e.target.value})}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        if (!password) {
                            this.setState({
                                passwordError: t('login.password_error')
                            });
                            return;
                        }
                        this.onSubmitUserProfile({email: user_profile.email, password})
                    }
                }}
            />

            {
                passwordError && <p className="_txt-error-confirm mgt5 mgbt0 txt-error ">{passwordError}</p>
            }
        </Modal>
    };

    render() {

        const {isLoadingSubmit, user_profile, isEditDob, isEditFullName, isEditAddress, isEditEmail, isEditPhone, isEditGender, emailError, fullNameError} = this.state;
        const {t} = this.props;
        currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {};

        return (
            <div>
                <Spin  spinning={isLoadingSubmit === true} indicator={<AntdIcon.Loading3QuartersOutlined type="loading" style={{ fontSize: 24 }} spin />} >
                    <Form className={'formChangeUserProfile'}>
                        <Row>
                            <Col lg={24} xl={12} xxl={12}>
                                <div className='_userName mgbt10'>
                                    <span className='lbl150 dpl-il-block txt-size-h7 txt-color-gray robotoregular flex-none'>{t('customer_info.username')}: </span>
                                    <span className='txt-size-h7 txt-color-black robotoregular'>{!user_profile.username ? '---' : user_profile.username}</span>
                                </div>
                                <div className='_fullName mgbt10 dpl-flex formChangeUserProfile__username'>
                                    <span className='lbl150 dpl-il-block txt-size-h7 txt-color-gray robotoregular flex-none required'>{t('customer_info.fullname')}: </span>
                                    {
                                        isEditFullName
                                            ?
                                            <React.Fragment>
                                                <div className='position-re formChangeUserProfile__username_wp'>
                                                    <Form.Item className={'searchbox txt-color-black txt-size-h8 robotoregular mgbt0'}>
                                                        <Input
                                                            className={'_search-box-input-query formChangeUserProfile__username_ip txt-size-h7'}
                                                            size="small"
                                                            onKeyDown={(e) => {
                                                                if (e.keyCode === 13) {
                                                                    if (user_profile.fullname !== currentLoggedUser.fullname) {
                                                                        if (this.onValidateFullNameInput()) {
                                                                            return;
                                                                        }
                                                                        this.onKeyDown(e, 'fullname')
                                                                    } else {
                                                                        this.onBlurInput()
                                                                    }
                                                                }
                                                            }}
                                                            onBlur={(e) => this.onBlurInput()}
                                                            ref={ref => this[`fullname`] = ref}
                                                            value={user_profile.fullname}
                                                            onChange={(e) => this.onInputChange(e.target.value, 'fullname')}
                                                        />
                                                        {
                                                            fullNameError && <p className="_email-error-confirm mgt5 mgbt0 txt-error ">{fullNameError}</p>
                                                        }
                                                    </Form.Item>
                                                    <span
                                                        className='formChangeUserProfile__username_icon cursor-pointer bg-color-blue position-ab'
                                                        onMouseDown={(e) => {
                                                            if (user_profile.fullname !== currentLoggedUser.fullname) {
                                                                if (this.onValidateFullNameInput()) {
                                                                    this.setState({isEditFullName:true},() => {
                                                                        this[`fullname`].focus();
                                                                    })
                                                                } else {
                                                                    this.onClickSave(this[`fullname`],'fullname')
                                                                }
                                                            } else {
                                                                this.onBlurInput()
                                                            }
                                                        }}
                                                    >
                                                        <AntdIcon.SaveOutlined className="txt-color-white txt-size-h7 _save_profile" type="save"/>
                                                    </span>
                                                </div>
                                            </React.Fragment>

                                            :
                                            <React.Fragment>
                                                <span className='txt-size-h7 txt-color-black robotoregular mgr10'>{!user_profile.fullname ? '---' : user_profile.fullname}</span>
                                                <AntdIcon.EditOutlined className="_btn-edit-remark-sku txt-color-blue txt-size-h7 pdt2" type="edit"
                                                      onClick={() =>
                                                          this.setState({isEditFullName:true},() => {
                                                              this[`fullname`].focus();
                                                          })
                                                      }
                                                />
                                            </React.Fragment>
                                    }

                                </div>
                                <div className=' mgbt10'>
                                    <div className='_dob'>
                                        <span className='lbl150 dpl-il-block txt-size-h7 txt-color-gray robotoregular flex-none'>{t('customer_info.date_of_birth')}: </span>
                                        {
                                            isEditDob
                                                ?
                                                <React.Fragment>
                                                    <DatePicker
                                                        showTime
                                                        style={{"width":"200px"}}
                                                        format={'DD/MM/YYYY'}
                                                        placeholder={t('customer_info.date_of_birth')}
                                                        onOk={this.DobChange}
                                                        ref={ref => this[`dob`] = ref}
                                                        onOpenChange={this.handleStartOpenChange}
                                                        // onBlur={(e) => this.onBlurInput()}
                                                        open={isEditDob}

                                                    />
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <span className=' txt-size-h7 txt-color-black robotoregular mgr10'>{user_profile.dob ? moment(user_profile.dob).format("DD/MM/YYYY") : '---'}</span>
                                                    <AntdIcon.EditOutlined className="_btn-edit-remark-sku txt-color-blue txt-size-h7" type="edit"
                                                          onClick={() =>
                                                              this.setState({isEditDob:true},() => {
                                                                  this[`dob`].focus();
                                                              })
                                                          }
                                                    />
                                                </React.Fragment>

                                        }

                                    </div>
                                </div>
                                <div className='mgbt10'>
                                    <div className='_contactAddress mgbt10 dpl-flex formChangeUserProfile__username'>
                                        <span className='lbl150 dpl-il-block txt-size-h7 txt-color-gray robotoregular flex-none'>{t('customer_info.contact_address')}: </span>

                                        {
                                            isEditAddress
                                                ?
                                                <React.Fragment>
                                                    <div style={{"width":"70%"}} className='position-re formChangeUserProfile__username_wp'>

                                                        <Input
                                                            className={'_search-box-input-query formChangeUserProfile__username_ip txt-size-h7'}
                                                            size="small"
                                                            onKeyDown={(e) => this.onKeyDown(e, 'contactAddress')}
                                                            onBlur={(e) => this.onBlurInput()}
                                                            ref={ref => this[`contactAddress`] = ref}
                                                            value={user_profile.contactAddress}
                                                            onChange={(e) => this.onInputChange(e.target.value, 'contactAddress')}

                                                        />
                                                        <span
                                                            onMouseDown={(e) =>
                                                                this.onClickSave(this[`contactAddress`],'contactAddress')
                                                            }
                                                            className='formChangeUserProfile__username_icon bg-color-blue cursor-pointer position-ab'>
                                                            <AntdIcon.SaveOutlined className="txt-color-white txt-size-h7 _save_profile" type="save"/>
                                                        </span>
                                                    </div>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <div className='position-re'>
                                                        <span className=' txt-size-h7 txt-color-black robotoregular mgr10'>{!user_profile.contactAddress ? '---' : user_profile.contactAddress}</span>
                                                        <AntdIcon.EditOutlined className="_btn-edit-remark-sku txt-color-blue pdt2 txt-size-h7" type="edit"
                                                              onClick={() =>
                                                                  this.setState({isEditAddress:true},() => {
                                                                      this[`contactAddress`].focus();
                                                                  })

                                                              }
                                                        />
                                                    </div>
                                                </React.Fragment>

                                        }
                                    </div>
                                </div>

                            </Col>

                            <Col lg={24} xl={12} xxl={12}>
                                <div className='_email mgbt10 dpl-flex formChangeUserProfile__username'>
                                    <span className='lbl150 dpl-il-block txt-size-h7 txt-color-gray robotoregular flex-none'>{t('customer_info.email')}: </span>

                                    {
                                        isEditEmail
                                            ?
                                            <div>
                                                <div className='position-re formChangeUserProfile__username_wp'>

                                                    <Input
                                                        className={'_search-box-input-query formChangeUserProfile__username_ip txt-size-h7'}
                                                        size="small"
                                                        onKeyDown={(e) => {
                                                            if (e.keyCode === 13) {
                                                                if (user_profile.email !== currentLoggedUser.email) {
                                                                    if (this.onValidateEmailInput()) {
                                                                        return;
                                                                    }
                                                                    this.setState({
                                                                        passwordError: '',
                                                                        password: '',
                                                                        showModalConfirm: true
                                                                    })
                                                                } else {
                                                                    this.onBlurInput()
                                                                }
                                                            }
                                                        }}
                                                        onBlur={(e) => this.onBlurInput()}
                                                        ref={ref => this[`email`] = ref}
                                                        value={user_profile.email}
                                                        onChange={(e) => this.onInputChange(e.target.value, 'email')}

                                                    />
                                                    <span
                                                        className='formChangeUserProfile__username_icon bg-color-blue cursor-pointer position-ab'
                                                        onMouseDown={() => {
                                                            if (user_profile.email !== currentLoggedUser.email) {
                                                                if (this.onValidateEmailInput()) {
                                                                    this.setState({isEditEmail:true},() => {
                                                                        this[`email`].focus();
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        passwordError: '',
                                                                        password: '',
                                                                        showModalConfirm: true
                                                                    })
                                                                }
                                                            } else {
                                                                this.onBlurInput()
                                                            }
                                                        }}
                                                    >
                                                        <AntdIcon.SaveOutlined className="txt-color-white txt-size-h7 _save_profile" type="save"/>
                                                    </span>
                                                </div>
                                                {
                                                    emailError && <p className="_email-error-confirm mgt5 mgbt0 txt-error ">{emailError}</p>
                                                }
                                            </div>
                                            :
                                            <React.Fragment>
                                                <div className='position-re'>
                                                    <span className=' txt-size-h7 txt-color-black robotoregular mgr10'>{!user_profile.email ? '---' : user_profile.email}</span>
                                                    <AntdIcon.EditOutlined className="_btn-edit-email txt-color-blue pdt2 txt-size-h7" type="edit"
                                                          onClick={() =>
                                                              this.setState({isEditEmail:true},() => {
                                                                  this[`email`].focus();
                                                              })
                                                          }
                                                    />
                                                </div>
                                            </React.Fragment>

                                    }

                                </div>

                                <div className='_gender mgbt10 dpl-flex formChangeUserProfile__gender'>
                                    <span className='lbl150 dpl-il-block txt-size-h7 txt-color-gray robotoregular flex-none'>{t('customer_info.gender')}: </span>
                                    {
                                        isEditGender
                                        ?
                                            <Select
                                                placeholder={t('customer_info.choose_gender')}
                                                dropdownClassName="_gender-overlay"
                                                ref={ref => this[`gender`] = ref}
                                                onBlur={(e) => this.onBlurInput()}
                                                onChange={(value) => this.onChangeSelect("gender", value)}
                                                value={user_profile.gender ? user_profile.gender : ''}

                                            >
                                                <Select.Option key={'male'} value=''>---</Select.Option>
                                                <Select.Option key={'male'} value='male'>{t('userProfile.male')}</Select.Option>
                                                <Select.Option key={'female'} value='female'>{t('userProfile.female')}</Select.Option>
                                            </Select>
                                            :
                                            <React.Fragment>
                                                <span className=' txt-size-h7 txt-color-black robotoregular'>{!user_profile.gender || user_profile.gender === '---' ? '---' : t(`userProfile.${user_profile.gender}`)}</span>
                                                <AntdIcon.EditOutlined className="_btn-edit-remark-sku txt-color-blue txt-size-h7 pdl5 pdt2" type="edit"
                                                      onClick={() =>
                                                          this.setState({isEditGender:true},() => {
                                                              this[`gender`].focus();
                                                          })
                                                      }
                                                />
                                            </React.Fragment>
                                    }


                                </div>

                                <div className='mgbt10'>
                                    <div className='_phone mgbt10 dpl-flex formChangeUserProfile__username'>
                                        <span className='lbl150 dpl-il-block txt-size-h7 txt-color-gray robotoregular flex-none'>{t('customer_info.phone')}: </span>
                                        {
                                            isEditPhone
                                                ?
                                                <React.Fragment>
                                                    <div className='position-re formChangeUserProfile__username_wp'>
                                                        <Input
                                                            className={'_search-box-input-query formChangeUserProfile__username_ip txt-size-h7'}
                                                            size="small"
                                                            onKeyDown={(e) => this.onKeyDown(e, 'phone')}
                                                            onBlur={(e) => this.onBlurInput()}
                                                            ref={ref => this[`phone`] = ref}
                                                            value={user_profile.phone}
                                                            onChange={(e) => this.onInputChange(e.target.value, 'phone')}

                                                        />
                                                        <span
                                                            className='formChangeUserProfile__username_icon bg-color-blue cursor-pointer position-ab'
                                                            onMouseDown={(e) =>
                                                                this.onClickSave(this[`phone`],'phone')
                                                            }
                                                        >
                                                            <AntdIcon.SaveOutlined className="txt-color-white txt-size-h7 _save_profile" type="save"/>
                                                        </span>
                                                    </div>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <span className=' txt-size-h7 txt-color-black robotoregular mgr10'>{!user_profile.phone ? '---' : user_profile.phone}</span>
                                                    <AntdIcon.EditOutlined className="_btn-edit-remark-sku pdt2 txt-color-blue txt-size-h7" type="edit"
                                                          onClick={() =>
                                                              this.setState({isEditPhone:true},() => {
                                                                  this[`phone`].focus();
                                                              })

                                                          }
                                                    />
                                                </React.Fragment>

                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
                <Row className='mgt20'>
                    <Button onClick={this.props.togglePassword} className='btn--white mgr10 _btn-modify-password'>{t('customer_info.change_password')}</Button>
                    <Button onClick={this.props.togglePIN} className='btn--white mgr10 _btn-modify-PIN'>{t('customer_info.change_pin')}</Button>
                    <Button
                        onClick={this.props.toggleRecoverPin}
                        className='btn--white _btn-reset-PIN'
                    >
                        {t('forgot_pin.reset_pin')}
                    </Button>
                </Row>
                {this.renderModalConfirmPin()}
            </div>
        )
    }
}

export default withNamespaces()(FormUpdateProfile);
