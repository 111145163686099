import React from 'react'
import MainLayout from '../../components/Layout/MainLayout'
import {
    notification} from 'antd';

import FormCreateTicket from "../../components/CreateTicket/FormCreateTicket";
import UserService from "../../services/user.service";
// import lang from "../../resources/localization/Localization";
import {LocalStore} from "../../utils/LocalStore";
import {withNamespaces} from "react-i18next";


let currentLoggedUser = null;
class CreateComplaint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isUpdatePIN: false,
            user_profile: [],
            isShowEdit:false,
            isEditFullName:false,
            isEditAddress:false,
            isEditDob:false,
            isEditPhone:false,
            isShipment: false
        }
    }

    componentWillMount() {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null;

        if (typeof LocalStore.getInstance().read('currentLoggedUser') === 'object') {
            currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {};
            this.setState({user_profile: currentLoggedUser});

        }
    }


    /**
     * Toggle cập nhật mã PIN
     */
    togglePIN = () => {
        this.setState({isUpdatePIN: !this.state.isUpdatePIN});
    };

    /**
     * call api update mã PIN
     * @param data
     */
    handleUpdate = (data) => {
        const {isLoadingSubmit} = this.state;
        const {t} = this.props;
        this.setState({showProgressBar: true, isLoadingSubmit: true}, () => {
            if (isLoadingSubmit !== true) {
                UserService.updatePIN(data, (err, res) => {
                    if (!err) {
                        notification['success']({
                            message: t('message.success'),
                        });
                        this.setState({errUpdate: false});
                        this.togglePIN();
                    } else {
                        // notification['error']({
                        //     message: err.title === 'invalid_pin' ? 'Mã PIN cũ không đúng' : err.title === 'duplicate_pin' ? 'Mã PIN mới trùng mã PIN cũ' : err.message
                        // });
                        this.setState({errUpdate: true}, () => {
                            setTimeout(() => {
                                this.setState({errUpdate: false});
                            }, 500);
                        });
                    }
                    this.setState({showProgressBar: false, isLoadingSubmit: false});
                });
            }
        });
    };

    onKeyDown = (e, prop) => {
        if (e.target.value !== '' && e.target.value.trim() === '') return;

        let payload = {};
        payload[prop] = e.target.value.trim();

        if (e.keyCode === 13) {
            this.setState({isLoadingSubmit: true}, () => {
                this.onSubmitUserProfile(payload);
            })
        }

    };

    onSubmitUserProfile = (payload) => {
        const {t} = this.props;
        this.setState({isLoadingSubmit: true}, () => {
            UserService.updateProfile(payload, (err, res) => {
                if (!err) {
                    notification['success']({
                        message: t('message.success'),
                    });
                    LocalStore.getInstance().save('currentLoggedUser', res);
                    this.setState({errUpdate: false, isLoadingSubmit: false, user_profile:res});
                } else {
                    this.setState({errUpdate: true}, () => {
                        setTimeout(() => {
                            this.setState({errUpdate: false});
                        }, 500);
                    });
                }
                this.setState({showProgressBar: false, isLoadingSubmit: false});
            })
        })
    };

    render() {
        const {history} = this.props;
        const {t} = this.props;
        return <MainLayout
            ref={ref => this.mainLayoutElement = ref}
            {...this.props}
            showProgressBar={this.state.showProgressBar}
            headerTitle={t('ticket_add.create_claims')}
            title={t('ticket_add.create_claims')}
        >
            <FormCreateTicket
                history={history}
            />
        </MainLayout>;
    }
}
export default withNamespaces()(CreateComplaint);