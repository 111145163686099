/* eslint-disable array-callback-return */
import React from 'react';
import _ from 'lodash'
import lodash from 'lodash'
import {Modal, Table} from 'antd'
import {withNamespaces} from "react-i18next";
import {LocalStore} from "../../utils/LocalStore";
import UserService from "../../services/user.service";
import {moneyFormat} from "../../utils/stringUtils";
import moment from "moment";

class ModalMarkupRateGroupsHardCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentLoggedUser: {},
        };
    }

    // scroll top khi bắt đầu load trang
    componentDidMount() {
        this.setState({
            currentProjectInfo: LocalStore.getInstance().read('currentProjectInfo') || {},
            currentLoggedUser: LocalStore.getInstance().read('currentLoggedUser') || {}
        });
        this.getExchangeRate()
    }

    /**
     * lấy ra danh sách trạng thái của package
     */
    getExchangeRate = (cb) => {
        UserService.getExchangeRatePeerPayMent("", (err, res, headers) => {
            if (err === null) {
                let listExchangeRate = []
                if (res) {
                    let arr = [
                        {
                            id: 1,
                            label: "Mức 1",
                            fromAmount: 0,
                            toAmount: 1000,
                            discountByRank: [80, 40, 30, 20],
                        },
                        {
                            id: 2,
                            label: "Mức 2",
                            fromAmount: 1000,
                            toAmount: 5000,
                            discountByRank: [60, 30, 25, 15],
                        },
                        {
                            id: 3,
                            label: "Mức 3",
                            fromAmount: 5000,
                            toAmount: 0,
                            discountByRank: [40, 25, 20, 10],
                        },
                    ]
                    let result = 0
                    arr.map(item => {
                        for(let i = 0; i < item.discountByRank.length; i++) {
                            result = res.rate + item.discountByRank[i]
                            listExchangeRate.push({
                                base: "CNY",
                                value: result,
                                currency: "VND"
                            })
                        }
                    })
                }

                this.setState({
                    exchangeRate: res || {},
                    listExchangeRate: _.sortBy(listExchangeRate, ['value'])
                }, () => {
                    if (cb) cb()
                });
            }
        });
    };
    parseDataToRender=(datasource)=>{
        let items=[];
        datasource.map(x=>{
            items.push({
                id: x.code,
                label: x.name
            });
           x.items.map(xx=>{
               items.push(xx);
           });
        });
        return items;
    }
    renderModalAddProduct = () => {
        const {listExchangeRate = [], exchangeRate = {}} = this.state;
        if (!listExchangeRate[0]) return <div/>
        let firstItem = listExchangeRate[0]
        let lastItem = listExchangeRate[listExchangeRate.length-1]
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        const currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {};

        const {t, peerPaymentConfig} = this.props;
        let arr=[];
        let temp = lodash.get(peerPaymentConfig,'exchangeRateBoardLayout','')
        if(temp){
            arr = JSON.parse(temp);
        }
        if (!Array.isArray(arr) || arr.length === 0) return <div />
        let currency = ["CNY", "VND"];
       let items = this.parseDataToRender(arr);
       // console.log(items);
        // if (item.exchangeRate) {
        //     currency = item.exchangeRate.split('/')
        // }

        const columns = [{
            title: `TỶ GIÁ HÔM NAY ${moment().format("DD/MM/YYYY")}`,
            children: [{
                title: "",
                dataIndex: 'label',
                key: 'label',
                render: (text, row) => {
                    if (row.id === "thanhtoanho" || row.id === "chuyentienho") {
                        return {
                            children: text,
                            props: {
                                colSpan: 6,
                                className: 'txt-center bold',
                            },
                        };
                    }

                    return text
                }
            }, {
                title: "Giá trị 1 YCTT",
                dataIndex: 'range',
                key: 'range',
                render: (text, row) => {
                    if (row.id === "thanhtoanho" || row.id === "chuyentienho") {
                        return {
                            children: '',
                            props: {
                                colSpan: 0,
                            },
                        };
                    }

                    let strHtml = ""
                    if (row.fromAmount && row.toAmount) {
                        strHtml = `<strong>${moneyFormat(row.fromAmount, currency[0])}</strong> đến <<strong>${moneyFormat(row.toAmount, currency[0])}</strong>`
                    } else if (row.fromAmount) {
                        strHtml = `>= <strong>${moneyFormat(row.fromAmount, currency[0])}</strong>`
                    } else if (row.toAmount) {
                        strHtml = `< <strong>${moneyFormat(row.toAmount, currency[0])}</strong>`
                    }
                    return <div dangerouslySetInnerHTML={{__html: strHtml}}/>
                }
            }, {
                title: 'Nhóm khách hàng',
                children: [
                    {
                        title: t('customerAddress.default'),
                        dataIndex: 'default',
                        key: 'default',
                        render: (text, row) => {
                            if (row.id === "thanhtoanho" || row.id === "chuyentienho") {
                                return {
                                    children: '',
                                    props: {
                                        colSpan: 0,
                                    },
                                };
                            }

                            let res = 0;
                            res = exchangeRate.rate + lodash.get(row,'discountByRank[0].discount',0);
                            return moneyFormat(res, currency[1])
                        }
                    },
                    {
                        title: "Vip 1",
                        dataIndex: 'vip1',
                        key: 'vip1',
                        render: (text, row) => {
                            if (row.id === "thanhtoanho" || row.id === "chuyentienho") {
                                return {
                                    children: '',
                                    props: {
                                        colSpan: 0,
                                    },
                                };
                            }

                            let res = 0;
                            res = exchangeRate.rate + lodash.get(row,'discountByRank[1].discount',0);
                            return moneyFormat(res, currency[1])
                        }
                    },
                    {
                        title: "Vip 2",
                        dataIndex: 'vip2',
                        key: 'vip2',
                        render: (text, row) => {
                            if (row.id === "thanhtoanho" || row.id === "chuyentienho") {
                                return {
                                    children: '',
                                    props: {
                                        colSpan: 0,
                                    },
                                };
                            }

                            let res = 0;
                            res = exchangeRate.rate + lodash.get(row,'discountByRank[2].discount',0);
                            return moneyFormat(res, currency[1])
                        }
                    },
                    {
                        title: "Vip 3",
                        dataIndex: 'vip3',
                        key: 'vip3',
                        render: (text, row) => {
                            if (row.id === "thanhtoanho" || row.id === "chuyentienho") {
                                return {
                                    children: '',
                                    props: {
                                        colSpan: 0,
                                    },
                                };
                            }

                            let res = 0;
                            res = exchangeRate.rate + lodash.get(row,'discountByRank[3].discount',0);
                            return moneyFormat(res, currency[1])
                        }
                    },
                ],
            }]
        }]

        let title = `${t('peer_payment.exchange_range')}: ${moneyFormat(1, firstItem.base)} = ${moneyFormat(firstItem.value, firstItem.currency)} - ${moneyFormat(lastItem.value, lastItem.currency)}`
        title = `TỶ GIÁ HÔM NAY ${moment().format("DD/MM/YYYY")}`
        return <Modal
            className="modal-log-package-damage"
            title={title}
            visible={this.state.showModalAddProduct}
            onCancel={() => this.setState({
                showModalAddProduct: false
            })}
            width={700}
            cancelText={t('modal.confirm')}
            okButtonProps={{className: "dpl-none"}}
            cancelButtonProps={{className:"_btn-cancel-confirm"}}
        >
            <div className="pd20">
                <div className="pdt5 pdbt5">Chào mừng Quý khách đến với dịch vụ <span className="bold txt-color-black">{currentProjectInfo.name}</span></div>
                <div className="pdt5 pdbt5 mgbt15">Quý khách hiện đang ở nhóm khách hàng: <span className="bold txt-color-black">
                        {currentLoggedUser.customerGroup ? currentLoggedUser.customerGroup.name : t('customerAddress.default')}
                    </span>
                </div>

                <div className="bg-color-white">
                    <Table
                        className='_table-data custom-empty table-hardcode table-expand-row border-bottom-radius table-mini'
                        rowKey={(x, y) => y}
                        columns={columns}
                        bordered
                        dataSource={items}
                        rowClassName={() => `_table-row`}
                        pagination={false}
                    />
                </div>

                <div className="pdt20">
                    <div className="bold txt-color-black pdbt5">
                        Ghi chú:
                    </div>
                    <div className="txt-size-h8 pdbt5">
                        Mỗi "yêu cầu thanh toán" của Quý khách (viết tắt là: YCTT) được hiểu là một khoản cần "thanh toán hộ" hoặc "chuyển tiền hộ"
                    </div>
                    <div className="txt-size-h8">
                        Biểu tỷ giá sẽ được cập nhật theo biến động thị trường.
                    </div>

                    {/*<div className="pd10 border-1px-solid bd-color-green bg-gradient-green4 mgt20">*/}
                        {/*<div className="pdbt5 bold txt-color-black">ƯU ĐÃI ĐẶC BIỆT: Từ 17/6 đến hết 30/6/2021</div>*/}
                        {/*<div className="pdbt5 bold">Quý khách được áp dụng mức tỷ giá ưu đãi đối với tất cả các YCTT</div>*/}
                        {/*<div className="pdbt5 bold">Tỷ giá ưu đãi hôm nay: <span className="bold txt-color-red">{moneyFormat(1, currency[0])} = {moneyFormat(exchangeRate.rate, currency[1])}</span></div>*/}
                        {/*<div className="bold">Ngoài ra chúng tôi còn free phí dịch vụ tất cả các YCTT</div>*/}
                    {/*</div>*/}
                </div>

            </div>
        </Modal>
    };

    render() {
        // const {listExchangeRate = []} = this.state;
        //
        // if (!listExchangeRate[0]) return <div/>
        // let firstItem = listExchangeRate[0]
        // let lastItem = listExchangeRate[listExchangeRate.length-1]

        return <div className="dpl-il-block">
            <div
                onClick={() => {
                    this.setState({showModalAddProduct: true})
                }}
                className="_btn-modal-markup-rate-groups btn-modal-markup-rate-groups mgl15"
            >
                {/*{t('peer_payment.exchange_range')}: {moneyFormat(1, firstItem.base)} = {moneyFormat(firstItem.value, firstItem.currency)} - {moneyFormat(lastItem.value, lastItem.currency)}*/}
                Xem tỷ giá
            </div>

            {this.renderModalAddProduct()}
        </div>
    }
}
export default withNamespaces()(ModalMarkupRateGroupsHardCode)
