/* eslint-disable array-callback-return */
import React from 'react'
import MainLayout from '../../components/Layout/MainLayout'
import Loading from '../../components/Loading'
import { Link } from 'react-router-dom'
import { Empty, notification, Table, Space, Badge, Button, DatePicker, Typography, Image } from 'antd'
import * as AntdIcon from '@ant-design/icons'
import { LocalStore } from '../../utils/LocalStore'
import { getUrlQueryString } from '../../utils/helper'
import { moneyFormat, parseQueryStringToObject, quantityFormat, updateQueryStringParameter } from '../../utils/stringUtils'
import UserService from '../../services/user.service'
import _, { isEmpty } from 'lodash'
import lodash from 'lodash'
import { withNamespaces } from 'react-i18next'
import queryString from 'query-string'
import { Row } from 'antd'
import { Col } from 'antd'
import NoData from '../../components/NoData'
import { Divider } from 'antd'
import { Spin } from 'antd'
import moment from 'moment'
import CategoriesService from '../../services/categories.service'
import InputItem from '../../components/commons/Input'
import { FloatLabel } from '../../components/commons/Select'
import SuperSelect from '../../components/commons/Select/SuperSelect'
import imgProductDefault from '../../resources/images/imgProductDefault.png'
let pageSize = 25
class Lieferscheine extends React.Component {
    constructor(props) {
        super(props)
        this.isUnmounted = false
        this.state = {
            page: 1,
            pageSize: pageSize,
            isLoading: true,
            listLiefercheine: [],
            listChecked: {},
            issueDateFrom: undefined,
            issueDateTo: undefined,
            query: '',
            orderCode: '',
        }
    }

    componentDidMount() {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null
        // window.addEventListener('resize', this.windowResize);
        window.addEventListener('popstate', this.onPopState.bind(this))

        if (typeof LocalStore.getInstance().read('currentLoggedUser') === 'object') {
            this.setState({ isLoading: true }, () => {
                this.loadFromQueryString()
            })
        }
    }

    /**
     * Khi unmount ra thì bỏ sự kiện popstate
     */
    componentWillUnmount() {
        this.isUnmounted = true
        window.removeEventListener('popstate', this.onPopState.bind(this))
        // window.removeEventListener('resize', this.windowResize);
    }

    /**
     * On popstate
     */
    onPopState() {
        if (!this.isUnmounted)
            this.setState({ isLoading: true }, () => {
                this.loadFromQueryString()
            })
    }

    /**
     * Load từ QueryString (dành cho lần load đầu tiên)
     */
    loadFromQueryString = () => {
        let queryString = getUrlQueryString()
        let queryObj = parseQueryStringToObject(queryString) || {}
        let query = ''
        let orderCode = ''
        let issueDateFrom = undefined
        let issueDateTo = undefined
        let status = ''
        for (let p in queryObj) {
            if (p === 'status') {
                status = decodeURIComponent(queryObj['status'])
            }  else if (p === 'issueDateFrom') {
                // xử lý với với timestamp
                issueDateFrom = decodeURIComponent(queryObj['issueDateFrom'])
            } else if (p === 'issueDateTo') {
                // xử lý với với timestamp
                issueDateTo = decodeURIComponent(queryObj['issueDateTo'])
            } 
            else {
                // xử lý với query
                query = decodeURIComponent(queryObj['query'] || '')
                orderCode = decodeURIComponent(queryObj['orderCode'] || '')
            }
        }

        this.setState(
            {
                query,
                orderCode,
                issueDateFrom,
                issueDateTo,
                status,
                page: queryObj.page,
                pageSize: queryObj.size || pageSize,
            },
            () => {
                this.loadData()
            }
        )
    }

    getFilterStatus = value => {
        switch (value) {
            case 'prepared':
                return {
                    prepared: true,
                    storekeeper: false,
                    delivered: false,
                    received: false,
                    // cancelled: false,
                    // status: undefined,
                }
            case 'storekeeper':
                return {
                    // prepared: true,
                    storekeeper: true,
                    delivered: false,
                    received: false,
                    // cancelled: false,
                    // status: undefined,
                }
            case 'delivered':
                return {
                    // prepared: true,
                    // storekeeper: true,
                    delivered: true,
                    received: false,
                    // cancelled: false,
                    // status: undefined,
                }
            case 'received':
                return {
                    // prepared: true,
                    // storekeeper: true,
                    // delivered: true,
                    received: true,
                    // cancelled: false,
                    // status: undefined,
                }
            case 'cancelled':
                return {
                    // prepared: true,
                    // storekeeper: true,
                    // delivered: true,
                    // received: true,
                    cancelled: true,
                    // status: undefined,
                }
            default:
                return {}
        }
    }

    /**
     * load data
     */
    loadData = cb => {
        const { t } = this.props
        const { page, pageSize, status } = this.state
        let queryString = this.buildQueryString()
        let addQuery=''
        if (queryString) {
            if (status) {
                const dataStatus = this.getFilterStatus(status)
                const query = Object.entries(dataStatus)
                    .map(([key, value]) => `${key}=${value}`)
                    .join('&')
                    addQuery += `${query}`
                queryString = queryString + `&${addQuery}&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
            } else {
                queryString = queryString + `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
            }
        } else {
            queryString = `?page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        }
        this.setState({ showProgressBar: true, isLoading: true }, () => {
            UserService.getCustomerLieferscheine(queryString, (err, res, headers) => {
                if (!err) {
                    this.setState(
                        {
                            expandedRow: [],
                            listLiefercheine: res,
                            metadata: {
                                pageCount: headers['x-page-count'],
                                page: headers['x-page-number'],
                                size: headers['x-page-size'],
                                total: headers['x-total-count'],
                            },
                        },
                        () => {
                            //window.scrollTo(0, 0);
                            if (this.mainLayoutElement && this.mainLayoutElement.scrollLayoutElement)
                                this.mainLayoutElement.scrollLayoutElement.scrollTop = 0
                        }
                    )
                } else {
                    notification['error']({
                        message: t(err.message) || t('message.unconnected_error'),
                    })
                }
                if (cb) cb()
                this.setState({ showProgressBar: false, isLoading: false })
            })
        })
    }

    onPageChanged = (page, pageSize) => {
        this.setState({ page: page }, () => {
            let locationQuery = lodash.get(this.props.location, 'search', '')
            let queryObj = locationQuery ? parseQueryStringToObject(locationQuery) : {}
            queryObj.page = page
            queryObj.size = pageSize
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: queryString.stringify(queryObj),
            })
            window.scrollTo(0, 0)
        })
    }

    /**
     * lấy ra danh sách packages
     */
    getPackages = packageCode => {
        this.setState({ isLoadingPackages: true, showProgressBar: true }, () => {
            UserService.getCustomerLieferscheinePackages(packageCode, (err, res, headers) => {
                if (err === null) {
                    this.setState({
                        isLoadingPackages: false,
                        showProgressBar: false,
                        listPackages: res || [],
                    })
                } else {
                    this.setState({ showProgressBar: false, isLoadingPackages: false })
                }
            })
        })
    }

    /**
     * lấy ra danh sách packages
     */
    getDeliveries = packageCode => {
        this.setState({ isLoadingPackages: true, showProgressBar: true }, () => {
            UserService.getCustomerLieferscheineDeliveries(packageCode, (err, res, headers) => {
                if (err === null) {
                    this.setState({
                        isLoadingPackages: false,
                        showProgressBar: false,
                        deliveries: res || [],
                    })
                } else {
                    this.setState({ showProgressBar: false, isLoadingPackages: false })
                }
            })
        })
    }

    /**
     * lấy ra danh sách couries
     */
    getCategoriesCouriers = () => {
        this.setState({ isLoadingPackages: true, showProgressBar: true }, () => {
            CategoriesService.getCouriers((err, res, headers) => {
                if (err === null) {
                    this.setState({
                        isLoadingPackages: false,
                        showProgressBar: false,
                        listcouries: res || [],
                    })
                } else {
                    this.setState({ showProgressBar: false, isLoadingPackages: false })
                }
            })
        })
    }

    checkStatusDeliveryNote = record => {
        const { t } = this.props
        let result = null
        if (record?.preparedBy) {
            result = {
                text: t('deliveryStatus.waiting'),
                color: 'bg-yellow-100',
                status: 'preparedBy',
            }
        }
        if (record?.storekeeper) {
            result = {
                text: t('deliveryStatus.delivering'),
                color: 'bg-blue-100',
                status: 'storekeeper',
            }
        }
        if (record?.deliveredBy) {
            result = {
                text: t('deliveryStatus.delivered'),
                color: 'bg-blue-violet',
                status: 'deliveredBy',
            }
        }
        if (record?.receivedBy) {
            result = {
                text: t('deliveryStatus.received'),
                color: 'bg-green-300',
                status: 'receivedBy',
            }
        }
        if (record?.fail) {
            result = {
                text: t('deliveryStatus.fail'),
                color: 'bg-red-300',
            }
        }
        if (record?.cancelled) {
            result = {
                text: t('deliveryStatus.cancelled'),
                color: 'bg-grey-400',
                status: 'cancelled',
            }
        }
        return result
    }

    /**
     * xây dựng query string theo các giá trị trong state
     * @returns {string}
     */
    buildQueryString = () => {
        let {
            issueDateFrom,
            issueDateTo,
            orderCode,
            query,
            status,
        } = this.state
        let queryString = ''
        
        if (status) {
            queryString = updateQueryStringParameter(queryString, 'status', status)
        }
        if (issueDateFrom) {
            queryString = updateQueryStringParameter(queryString, 'issueDateFrom', issueDateFrom)
        }
        if (issueDateTo) {
            queryString = updateQueryStringParameter(queryString, 'issueDateTo', issueDateTo)
        }

        if (orderCode) {
            queryString = updateQueryStringParameter(queryString, 'orderCode', orderCode.trim())
        }

        if (query) {
            queryString = updateQueryStringParameter(queryString, 'query', query.trim())
        }

        return queryString
    }

    renderPackages = () => {
        const { t } = this.props
        const { listPackages, isLoadingPackages, deliveries, listcouries, isLoading } = this.state
        const groupedData = _.groupBy(listPackages, 'orderCode')
        
        const mergedData = _.map(groupedData, group => {
            return group.reduce(
                (result, item) => {
                    result.packageCode.push(item.packageCode)
                    result.weight.push(item.netWeight)
                    result.allocatedFees = item.allocatedFees
                    result.totalUnpaid = item.totalUnpaid
                    result.orderImage = item.orderImage
                    result.isShipment = item.isShipment
                    return result
                },
                {
                    orderCode: group[0].orderCode,
                    packageCode: [],
                    weight: [],
                    allocatedFees: [],
                    totalUnpaid: 0,
                    orderImage: '',
                    isShipment: false,
                }
            )
        })

        if (isLoadingPackages)
            return (
                <div className="dpl-flex align-items-center justify-content pdt10 pdbt10">
                    <Spin
                        indicator={
                            <AntdIcon.Loading3QuartersOutlined
                                type="loading"
                                style={{ fontSize: 24 }}
                                spin
                            />
                        }
                    />
                </div>
            )
        
        const couriersCode = deliveries && _.get(deliveries[0], 'courier', null)
        const nameCouriers = listcouries && listcouries.find(item => item.code === couriersCode)?.name 

        return (
            <div className="ml-7">
                {listPackages?.length > 0 && !isLoadingPackages && !isLoading && (
                    <Row className=" px-4 pl-0 py-[6px] rounded-tl-md rounded-tr-md mb-2">
                        <Col span={3}>
                            <div className="robotomedium text-xs text-black-950 ml-6">{t('lieferscheine.orderCode')}</div>
                        </Col>
                        <Col span={3}>
                            <div className="robotomedium text-xs text-black-950">{t('lieferscheine.packageCode')}</div>
                        </Col>
                        <Col span={3}>
                            <div className="robotomedium text-xs text-black-950">{t('lieferscheine.waybill ')}</div>
                        </Col>
                        <Col span={3}>
                            <div className="robotomedium text-xs text-black-950">{t('lieferscheine.couries')}</div>
                        </Col>
                        <Col span={3}>
                            <div className=" robotomedium text-xs text-black-950">{t('lieferscheine.weight')}</div>
                        </Col>
                        <Col span={5}>
                            <div className=" robotomedium text-xs text-black-950">{t('lieferscheine.allocatedFees')}</div>
                        </Col>
                        <Col span={4}>
                            <div className=" robotomedium text-xs text-black-950">{t('lieferscheine.fee')}</div>
                        </Col>
                    </Row>
                )}

                {!isLoadingPackages && !isLoading && mergedData?.length > 0 ? (
                    mergedData?.map((packge, index) => {
                        const isLastItem = mergedData[mergedData.length - 1] === packge
                        const checkIsShipment = packge?.isShipment

                        return (
                            <div
                                className="w-full px-4 pl-0"
                                key={index}
                            >
                                <Row className="orderItem py-2">
                                    <Col
                                        span={3}
                                        className="flex items-center"
                                    >
                                        <div className="flex flex-col items-center gap-2">
                                            <Image
                                                className="rounded-md"
                                                width={50}
                                                height={50}
                                                preview={false}
                                                src={packge?.orderImage || imgProductDefault}
                                            />

                                            <Typography.Paragraph
                                                style={{ marginBottom: 0 }}
                                                copyable={{ text: packge?.orderCode }}
                                                className="whitespace-nowrap"
                                            >
                                                <Link
                                                    target="_blank"
                                                    to={`/${checkIsShipment ? 'shipments' : 'orders'}/${packge?.orderCode}`}
                                                >
                                                    <span className="text-blue-primary font-medium">#{packge?.orderCode} </span>
                                                </Link>
                                            </Typography.Paragraph>
                                        </div>
                                    </Col>
                                    <Col
                                        span={3}
                                        className="flex items-center"
                                    >
                                        <div className="grid gap-2 ">
                                            {packge?.packageCode?.map(item => (
                                                <div key={item}>{item}</div>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col
                                        span={3}
                                        className="flex items-center"
                                    >
                                        <div className="grid gap-2 ">
                                            {deliveries && deliveries[0]?.trackingNumber ? deliveries[0]?.trackingNumber : '---'}
                                        </div>
                                    </Col>
                                    <Col
                                        span={3}
                                        className="flex items-center"
                                    >
                                        <div className="grid gap-2 ">{nameCouriers ? nameCouriers : '---'}</div>
                                    </Col>
                                    <Col
                                        span={3}
                                        className="flex items-center"
                                    >
                                        <div className="grid gap-2 ">
                                            {packge?.weight.map(item => (
                                                <div> {`${quantityFormat(item)} kg` || '---'}</div>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col
                                        span={5}
                                        className="grid items-center"
                                    >
                                        <div className="grid gap-2">
                                            {packge?.allocatedFees?.length &&
                                                _.sortBy(packge?.allocatedFees, 'amount').map(item => {
                                                    return (
                                                        <div className="flex gap-2 justify-between">
                                                            <span className="font-normal text-sm whitespace-nowrap text-grey-400 basis-6/12">
                                                                {item?.fee?.name}:{' '}
                                                            </span>
                                                            <span className="font-medium text-sm basis-6/12">
                                                                {!item?.fee?.free ? (
                                                                    moneyFormat(item?.amount || 0)
                                                                ) : (
                                                                    <>
                                                                        <span className="line-through text-grey-400 font-light">
                                                                            {moneyFormat(item?.amount || 0)}
                                                                        </span>{' '}
                                                                        <span className="ml-1">Miễn phí</span>
                                                                    </>
                                                                )}
                                                            </span>
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                    </Col>
                                    <Col
                                        span={4}
                                        className="flex items-center"
                                    >
                                        <div className="font-medium text-sm">{moneyFormat(packge?.totalUnpaid)}</div>
                                    </Col>
                                </Row>
                                {!isLastItem ? <Divider style={{ margin: '10px 0px' }} /> : null}
                            </div>
                        )
                    })
                ) : (
                    <div className="block m-auto ">
                        <NoData />
                    </div>
                )}
            </div>
        )
    }

    onClearSearch = () => {
        this.setState(
            {
                page: 1,
                query: '',
                orderCode: '',
                issueDateFrom: undefined,
                issueDateTo: undefined,
                listChecked: {},
                status: undefined,
            },
            () => {
                this.props.history.push({
                    pathname: this.props.location.pathname,
                    search: this.buildQueryString(),
                })
            }
        )
    }

    /**
     * xử lý với dữ liệu search trong state
     */
    onSubmitSearch = () => {
        this.setState({ page: 1 }, () => {
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: this.buildQueryString(),
            })
        })
    }

    issueDateFromChange = date => {
        this.setState({
            issueDateFrom: date ? date.startOf('day').toISOString() : undefined,
        })
    }

    issueDateToChange = date => {
        this.setState({
            issueDateTo: date ? date.endOf('day').toISOString() : undefined,
        })
    }

    setSelectedStatus = status => {
        this.setState({
            status: status,
        })
    }

    renderSearchBox = () => {
        const { query, issueDateFrom, issueDateTo, orderCode, status, isLoading } = this.state
        const { t } = this.props
        const optionStatus = [
            {
                text: t('deliveryStatus.waiting'),
                value: 'prepared',
            },

            {
                text: t('deliveryStatus.delivering'),
                value: 'storekeeper',
            },
            {
                text: t('deliveryStatus.delivered'),
                value: 'delivered',
            },
            {
                text: t('deliveryStatus.received'),
                value: 'received',
            },
            // {
            //     text: t('deliveryStatus.cancelled'),
            //     value: 'cancelled',
            // },
        ]
        return (
            <div className="searchbox v2-layout bg-color-white py-3 px-6 shadow-box rounded-md">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg capitalize cursor-pointer robotomedium w-full">{t('order.search')}</h2>
                </div>
                <Row className={'dpl-flex bd-color-gray '}>
                    <Col
                        span={6}
                        className="pr-4"
                    >
                        <InputItem
                            title={t('lieferscheine.code')}
                            className={'_search-box-input-query'}
                            onChange={e => this.setState({ query: e.target.value })}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    !isLoading && this.onSubmitSearch()
                                }
                            }}
                            value={query}
                            allowClear
                        />
                    </Col>
                    <Col
                        span={6}
                        className="pr-4"
                    >
                        <InputItem
                            title={t('lieferscheine.orderCode')}
                            className={'_search-box-input-query'}
                            onChange={e => this.setState({ orderCode: e.target.value })}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    !isLoading && this.onSubmitSearch()
                                }
                            }}
                            value={orderCode}
                            allowClear
                        />
                    </Col>
                    <Col
                        span={6}
                        className=""
                    >
                        <div className="datepicker-range w-full flex items-center border rounded-lg border-solid border-[#C0C0C0]">
                            <div className="datepicker-field flex-1">
                                <DatePicker
                                    className={`_order-date-from v2-layout robotoregular w-full ${
                                        isEmpty(issueDateFrom) ? '' : 'input-has-value'
                                    }`}
                                    popupClassName={`v2-layout`}
                                    locale={'vi'}
                                    suffixIcon={null}
                                    value={issueDateFrom ? moment(issueDateFrom) : undefined}
                                    showTime={{
                                        format: 'HH:mm',
                                        defaultValue: moment('00:00:00', 'HH:mm'),
                                    }}
                                    bordered={false}
                                    format={'DD/MM/YYYY'}
                                    placeholder=""
                                    onChange={this.issueDateFromChange}
                                />
                                <label className="robotoregular form-label text-grey-400">
                                    {t('lieferscheine.issueDateFrom')}
                                </label>
                            </div>
                            <i className="fa-solid fa-arrow-right text-xs text-grey-500" />
                            <div className="datepicker-field flex-1">
                                <DatePicker
                                    className={`_order-date-to robotoregular w-full ${
                                        isEmpty(issueDateTo) ? '' : 'input-has-value'
                                    }`}
                                    popupClassName={`v2-layout`}
                                    showTime={{
                                        format: 'HH:mm',
                                        defaultValue: moment('23:59:59', 'HH:mm'),
                                    }}
                                    suffixIcon={<i className="fa-solid fa-calendar-days text-grey-500 text-xs" />}
                                    value={issueDateTo ? moment(issueDateTo) : undefined}
                                    locale={'vi'}
                                    bordered={false}
                                    format={'DD/MM/YYYY'}
                                    placeholder=""
                                    onChange={this.issueDateToChange}
                                />
                                <label className="robotoregular form-label text-grey-400">{t('lieferscheine.issueDateTo')}</label>
                            </div>
                        </div>
                    </Col>
                    <Col
                        span={6}
                        className="pl-4"
                    >
                        <div className="form-select">
                            <FloatLabel
                                label={t('deliveryNote.status')}
                                value={status}
                            >
                                <SuperSelect
                                    showSearch
                                    allowClear
                                    className={'_select-customer w-full'}
                                    dropdownClassName={'_dropdown-customer'}
                                    optionClassName={'_opt-customer'}
                                    style={{ width: '100%' }}
                                    filterOption={false}
                                    onChange={this.setSelectedStatus}
                                    selectedValue={status}
                                    keyCustomize="value"
                                    renderOptionLabel={item => <>{item.text}</>}
                                    options={optionStatus}
                                />
                            </FloatLabel>
                        </div>
                    </Col>
                </Row>
                <div className=" dpl-flex align-items-center justify-content-between pdt10">
                    <div />
                    <div className="dpl-flex align-items-center">
                        {
                            // checkShowRefresh()
                            // &&
                            <span
                                onClick={this.onClearSearch}
                                className="_btn-clear mr-3 text-[#707070] flex items-center cursor-pointer"
                            >
                                <i className="fa-regular fa-rotate-right text-[9px]" />
                                <span className="robotoregular text-xs ml-1">{t('order.filter_refresh')}</span>
                            </span>
                        }
                        <Button
                            type="primary"
                            // disabled={disabledBtnSearch}
                            onClick={this.onSubmitSearch}
                            className="rounded border-none _order-filter"
                        >
                            {t('order.search')}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { listLiefercheine, isLoading, metadata, expandedRow = [] } = this.state
        const { t } = this.props

        const columns = [
            {
                title: t('lieferscheine.code'),
                dataIndex: 'code',
                key: 'code',
                width: '13%',
                render: (text, record) => (
                    <div>
                        <Typography.Paragraph
                            style={{ marginBottom: 0 }}
                            copyable={{ text: text }}
                            className="whitespace-nowrap"
                        >
                            <span className="_table-row-code font-medium txt-uppercase text-blue-primary whitespace-pre-wrap">
                                {text}
                            </span>
                        </Typography.Paragraph>
                    </div>
                ),
            },
            {
                title: t('lieferscheine.totalPackage'),
                dataIndex: 'totalPackage',
                key: 'totalPackage',
                width: '13%',
                className: 'whitespace-pre-wrap text-center',
                render: text => (
                    <span className="_table-row-waybill-code whitespace-pre-wrap ">{text ? text + ' kiện hàng' : '---'}</span>
                ),
            },
            {
                title: <span>{t('lieferscheine.totalAmount')} </span>,
                dataIndex: 'totalUnpaid',
                key: 'totalUnpaid',
                className: 'whitespace-pre-wrap ',
                width: '13%',
                render: (text, row) => (
                    <span className="_table-row-waybill-code whitespace-pre-wrap ">
                        {text !== null ? moneyFormat(text || 0) : '---'}
                    </span>
                ),
            },
            {
                title: t('lieferscheine.totalUnpaid'),
                dataIndex: 'totalAmount',
                key: 'totalAmount',
                width: '13%',
                className: 'whitespace-pre-wrap ',
                render: (text, record) => {
                    return (
                        <span className="_table-row-waybill-code font-roboto font-medium text-black-100 whitespace-pre-wrap ">
                            {text !== null ? moneyFormat(text || 0) : '---'}
                        </span>
                    )
                },
            },
            {
                title: t('lieferscheine.totalWeight'),
                dataIndex: 'totalWeight',
                key: 'totalWeight',
                width: '10%',
                className: 'whitespace-pre-wrap ',
                render: (text, record) => {
                    return (
                        <span className="_table-row-weight">
                            {Number.isFinite(text) ? `${quantityFormat(text)} kg` : t('orderDetail.undefined')}
                        </span>
                    )
                },
            },
            {
                title: t('lieferscheine.issueDate'),
                dataIndex: 'issueDate',
                key: 'issueDate',
                width: '10%',
                className: 'whitespace-nowrap',
                render: (text, record) => {
                    return <span className="_table-row-weight">{text ? moment(text).format('HH:mm DD/MM/YYYY') : '---'}</span>
                },
            },
            {
                title: t('lieferscheine.warehouse'),
                dataIndex: 'address',
                key: 'address',
                width: '40%',
                className: 'whitespace-pre-wrap text-left',
                render: (text, record) => {
                    const recipient = _.get(record.address, 'recipient', '---')
                    const phone = _.get(record.address, 'phone', '---')
                    const location = _.get(record.address, 'location.display', '---')
                    const address1 = _.get(record.address, 'address1', '---')
                    const fullAddress = `${recipient} - ${phone}, ${address1}, ${location}`
                    return <span className="_table-row-waybill-code whitespace-pre-wrap ">{text ? fullAddress : '---'}</span>
                },
            },
            {
                title: t('lieferscheine.status'),
                dataIndex: 'address',
                key: 'address',
                width: '20%',
                className: 'whitespace-pre-wrap text-right',
                render: (text, record) => {
                    const statuses = this.checkStatusDeliveryNote(record)
                    return (
                        <div className={`px-4 py-[1px] rounded-[32px] ${statuses?.color} text-center float-right min-w-8`}>
                            <span className="font-normal text-white txt-size-h6 whitespace-nowrap">{statuses?.text}</span>
                        </div>
                    )
                },
            },
        ]

        return (
            <MainLayout
                ref={ref => (this.mainLayoutElement = ref)}
                {...this.props}
                showProgressBar={this.state.showProgressBar}
                headerTitle={t('lieferscheine.title')}
                title={t('lieferscheine.title')}
            >
                <div className={'container-common pdbt30 mgr20'}>
                    {this.renderSearchBox()}

                    <div className="pd20 bg-color-white  rounded-xl mt-4">
                        <div className="dpl-flex justify-content-between align-items-center v2-layout">
                            {metadata ? (
                                <Space
                                    align="center"
                                    size={2}
                                >
                                    <span className={'txt-color-black txt-size-h5 mr-2 robotobmedium'}>
                                        {t('lieferscheine.title')}
                                    </span>
                                    <Badge count={quantityFormat(metadata.total)} />
                                </Space>
                            ) : (
                                <span />
                            )}
                        </div>
                        {Array.isArray(listLiefercheine) && !isLoading && listLiefercheine.length > 0 ? (
                            <Table
                                ref={ref => (this.table = ref)}
                                className={'table-v2 custom-empty table-expand-row'}
                                rowKey={'code'}
                                rowClassName={(record, index) => index % 2 !== 0 && 'table-row-odd'}
                                onRow={key => {
                                    return expandedRow.includes(key?.code) && { className: 'table-row-expanded' }
                                }}
                                columns={columns}
                                dataSource={listLiefercheine}
                                expandedRowKeys={expandedRow}
                                expandedRowRender={this.renderPackages}
                                expandIcon={expand => {
                                    return (
                                        <i
                                            class={`fa-solid fa-chevron-${
                                                expand.expanded ? 'down' : 'right'
                                            } text-grey-400 cursor-pointer hover:text-black-100`}
                                            onClick={e => expand.onExpand(expand.record, e)}
                                        />
                                    )
                                }}
                                onExpand={(expanded, record) => {
                                    this.setState({
                                        listPackages:[],
                                        deliveries:[]
                                    })
                                    if (expanded) {
                                        this.setState({
                                            expandedRow: [record.code],
                                        })

                                        this.getPackages(record.code)
                                        this.getDeliveries(record.code)
                                        this.getCategoriesCouriers()
                                    } else {
                                        this.setState({
                                            expandedRow: [],
                                        })
                                    }
                                }}
                                locale={{
                                    emptyText: (
                                        <div className="pdt50 pdbt60 bg-color-white">
                                            <Empty
                                                image={require('../../resources/images/empty-image.png')}
                                                description={t('message.empty')}
                                            />
                                        </div>
                                    ),
                                }}
                                pagination={{
                                    className: '_pagination text-center width-100-percent mgt20',
                                    // hideOnSinglePage: true,  
                                    current: metadata ? parseFloat(metadata.page) + 1 : 1,
                                    total: metadata ? parseFloat(metadata.total) : 1,
                                    pageSize: metadata ? parseFloat(metadata.size) : pageSize,
                                    onChange: (page, pageSize) => this.onPageChanged(page, pageSize),
                                }}
                            />
                        ) : isLoading ? (
                            // <SkeletonTable
                            //     loading={isLoading}
                            //     columns={columns}
                            //     rowCount={25}
                            // ></SkeletonTable>
                            <Loading />
                        ) : (
                            <div className="pdt50 pdbt60 bg-color-white">
                                <Empty
                                    image={require('../../resources/images/empty-image.png')}
                                    description={t('message.empty')}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </MainLayout>
        )
    }
}
export default withNamespaces()(Lieferscheine)
