import { useEffect, useState } from 'react'
import { Button, Col, Row, notification } from 'antd'
import { withNamespaces } from 'react-i18next'
import { filter, interval, of, skipWhile, switchMap, tap } from 'rxjs'
import lodash from 'lodash'
import MainLayout from '../../components/Layout/MainLayout'
import ProfileSidebar from '../../components/Profile/Leftbar'
import UserService from '../../services/user.service'
import OrderService from '../../services/orders.service'
import Modal from '../../components/commons/Modal'
import { LocalStore } from '../../utils/LocalStore'
import moment from 'moment'
import { openPopupCenter } from '../../utils/helper'

const LinkBifiin = props => {
    const { t } = props

    const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
    const currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {}
    const [isConnectedBiffin, setIsConnectedBiffin] = useState(false)
    const [shopkeeperInfo, setShopkeeperInfo] = useState({})
    const [openModalConfirm, setOpenModalConfirm] = useState(false)
    useEffect(() => {
        const shopkeeperAuth = lodash.get(currentLoggedUser, 'customerAuthorities.shopkeeper')
        const bifinEnabled = lodash.get(currentProjectInfo, 'tenantConfig.externalIntegrationConfig.shopkeeper.enabled', false)
        if (bifinEnabled && shopkeeperAuth) {
            getBiffinConnect()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getBiffinConnect = () => {
        UserService.getConnectedToBiffin((err, res) => {
            if (!err) {
                if (res.token && res.tenantId) {
                    setIsConnectedBiffin(true)
                    setShopkeeperInfo(res)
                } else {
                    setIsConnectedBiffin(false)
                }
            } else {
                setIsConnectedBiffin(false)
            }
        })
    }

    const onDisconnectBifin = () => {
        UserService.disconnectBifin()
        setTimeout(() => {
            setOpenModalConfirm(false)
            getBiffinConnect()
            notification.success({
                message: t('message.success'),
            })
        }, 300)
    }

    const handleSignUpBiffin = () => {
        const partnerId = lodash.get(currentProjectInfo, 'tenantConfig.externalIntegrationConfig.shopkeeper.partnerId')
        const redirectUri = lodash.get(currentProjectInfo, 'tenantConfig.externalIntegrationConfig.shopkeeper.redirectUri')
        const username = lodash.get(currentLoggedUser, 'username')
        const email = lodash.get(currentLoggedUser, 'email')
        const fullname = lodash.get(currentLoggedUser, 'fullname')
        const phone = lodash.get(currentLoggedUser, 'phone')

        openPopupCenter(
            {
                url: OrderService.gotoBiffinUrl({
                    username,
                    partnerId,
                    redirectUri,
                    email,
                    fullname,
                    phone,
                }),
                title: 'Bifin Authentication',
                w: 1100,
                h: 600,
            },
            currentWindow => {
                let window$ = of(currentWindow)
                let itv$ = interval(500)

                let getDraftOrder$ = of(w => {
                    getBiffinConnect()
                    currentWindow.close()
                })

                let job$ = itv$
                    .pipe(
                        switchMap(() => window$),
                        skipWhile(w => !w.closed),
                        filter(w => w.closed),
                        tap(() => {
                            getDraftOrder$.subscribe(processFunc => {
                                processFunc()
                                job$.unsubscribe()
                            })
                        })
                    )
                    .subscribe()
            }
        )
    }

    return (
        <MainLayout
            {...props}
            headerTitle={t('profile.connectWithBifin')}
            title={t('profile.connectWithBifin')}
        >
            <div className={'container-common pdbt30 pdt20 mgr20'}>
                <Row>
                    <Col
                        span={6}
                        className={'pdr10'}
                    >
                        <ProfileSidebar currentRoute={'bifiin'} />
                    </Col>
                    <Col
                        span={18}
                        className={'pdl10'}
                    >
                        <div className="bg-color-white text-center pd20 h-100">
                            {!isConnectedBiffin ? (
                                <span className='cursor-pointer' onClick={handleSignUpBiffin}>
                                    {t('profile.please_connect_bifin_with')} {currentProjectInfo.name}
                                </span>
                            ) : (
                                <div className="text-center v2-layout">
                                    <p className="mb-0">
                                        {t('profile.connectedUserWithBifin', { value: currentProjectInfo.name })}
                                    </p>
                                    <p>
                                        {t('profile.expired_date')}{' '}
                                        <span className="font-medium">
                                            {moment(shopkeeperInfo?.expiredAt).format('HH:mm DD/MM/YYYY')}
                                        </span>
                                    </p>
                                    <div className="flex gap-2 justify-center">
                                        <Button
                                            danger
                                            onClick={() => {
                                                setOpenModalConfirm(true)
                                            }}
                                        >
                                            {t('profile.disconnectWithBifin')}
                                        </Button>
                                        <Button
                                            className={'_btn-update-token'}
                                            type="primary"
                                            onClick={() => {
                                                handleSignUpBiffin()
                                            }}
                                        >
                                            {t('profile.update_token')}
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal
                title={t('profile.disconnectWithBifin')}
                visible={openModalConfirm}
                onCancel={() => {
                    setOpenModalConfirm(false)
                }}
                listAction={[
                    <Button
                        className={'btn-cancel'}
                        onClick={() => {
                            setOpenModalConfirm(false)
                        }}
                    >
                        {t('button.cancel')}
                    </Button>,
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="middle"
                        onClick={() => {
                            onDisconnectBifin()
                        }}
                    >
                        {t('profile.disconnectedBifin')}
                    </Button>,
                ]}
            >
                <div>
                    <div className="mb-2">{t('profile.confirmDisconnectedWithBifin')}</div>
                    <div className="px-4 py-2 rounded-xl border border-solid border-mysin-400 bg-warning-50">
                        <div className="flex gap-1">
                            <div>
                                <i class="fa-solid fa-triangle-exclamation text-mysin-400 w-5 h-5"></i>
                            </div>
                            <div>{t('profile.warningDisconnectedWithBifin')}</div>
                        </div>
                    </div>
                </div>
            </Modal>
        </MainLayout>
    )
}

export default withNamespaces()(LinkBifiin)
