import React from 'react';
import {Empty, Popover, Spin, Timeline} from "antd";
import moment from "moment";
import {ClockCircleOutlined} from "@ant-design/icons";

export const MilestonePopover = (props) => {
    const {t, milestones, statuses} = props
    return (
        <Popover
            visible={props.visible}
            onVisibleChange={props.onVisibleChange}
            title={null}
            trigger={['click']}
            placement={props.placement || 'left'}
            content={
                <Spin spinning={props.loading}>
                    <Timeline className={'timeline-tooltip timeline-tooltip--milestone-peer-payment-list'}>
                        {
                            Array.isArray(statuses) && statuses.length > 0
                                ? statuses.map((item, index) => {
                                    const status = milestones.find(x => x.code === item.status);
                                    return (
                                        <Timeline.Item
                                            key={index}
                                            color={index === 0 ? 'red' : 'green'}
                                            dot={
                                                index === 0 ? <ClockCircleOutlined type="clock-circle-o" className={'ic__clockcircle'}/> : ''
                                            }
                                        >
                                            <span className='txt-color-gray txt-size-h7 robotoregular pdr5'>{status ? status.name : '---'}:</span>
                                            <span className='txt-color-black txt-size-h7 robotobold pdr5'>{moment(item.timestamp).format('HH:mm DD/MM')}</span>
                                        </Timeline.Item>
                                    )
                                }):
                                <Empty
                                    className={'mgbt25 mgt15'}
                                    image={require('../../resources/images/empty-image.png')}
                                    description={t('message.empty')}
                                />
                        }
                    </Timeline>
                </Spin>
            }
        >
            {props.children}
        </Popover>
    )
}
