import React, { useState, useEffect } from 'react';
import { Table, Skeleton, Button, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import OrderService from '../../services/orders.service';
import * as AntdIcon from "@ant-design/icons"
import {LocalStore} from "../../utils/LocalStore";
import { moneyFormat } from '../../utils/stringUtils';
import moment from 'moment';
import _, { get } from 'lodash';
import { ILoansData, status } from '../../types/Bifin';
interface DataType {

}

export const style: any = {
    NEW: {
        backgroundColor: '#1890ff'
    },
    REJECT: {
        backgroundColor: '#FF4559'
    },
    APPROVED: {
        backgroundColor: '#FDB924'
    },
    LIQUIDATED: {
        backgroundColor: '#10C278'
    }
}

export const styleLoans: any = {
    ACTIVE: {
        backgroundColor: '#1890ff'
    },
    REJECTED: {
        backgroundColor: '#FF4559'
    },
    DRAFT: {
        backgroundColor: '#f79b39'
    },
    DONE: {
        backgroundColor: '#10C278'
    },
    WAIT_FOR_APPROVE: {
        backgroundColor: '#FDB924'
    },
    CANCELED: {
        backgroundColor: '#fa3d2f'
    }
}

const TabCredit: React.FC = (props: any) => {
    const { t, orderInfo } = props
    const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};

    const [loading, setLoading] = useState<boolean>(false)
    const [credits, setCredits] = useState([])
    const [loans, setLoans] = useState<ILoansData>()
    // const [error, setError] = useState()

    const tosUrl = _.get(
        currentProjectInfo,
        'tenantConfig.externalIntegrationConfig.shopkeeper.tosUrl',
        false
    )

    useEffect(() => {
        initData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const initData = () => {
        setLoading(true)
        getCredits()
        getLoans()
        setLoading(false)
    }

    const getCredits = () => {
        OrderService.getCredits(orderInfo?.code, (err: any, response: any) => {
            if (!err) {
                setCredits(response)
            } else {
                // setError(err)
            }
        })
    }

    const getLoans = () => {
        OrderService.getLoans(orderInfo?.code, (err: any, response: any) => {
            if (!err) {
                setLoans(response)
            } else {
                // setError(err)
            }
        })
    }

    const columns: ColumnsType<DataType> = [
        {
            title: <span className='robotomedium text-xs text-grey-400'>{t('bifiin.code')}</span>,
            dataIndex: 'loanId',
            key: 'loanId',
            render: (text: string) => <div>
                <span className="robotomedium text-blue-violet">#{text ? text : '---'}</span>
            </div>
        },
        {
            title: <span className='robotomedium text-xs text-grey-400'>{t('bifiin.created_at')}</span>,
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'right',
            render: (text: any) => <div>
                <span className="robotoregular text-black-100">{text ? moment(text).format('HH:mm DD/MM/YYYY') : '---'}</span>
            </div>
        },
        {
            title: <span className='robotomedium text-xs text-grey-400'>{t('bifiin.disbursementDate')}</span>,
            dataIndex: 'disbursementDate',
            key: 'disbursementDate',
            align: 'right',
            render: (text: any) => <div>
                <span className="robotoregular text-black-100">{text ? moment(text).format('HH:mm DD/MM/YYYY') : '---'}</span>
            </div>
        },
        {
            title: <span className='robotomedium text-xs text-grey-400'>{t('bifiin.settlementDate')}</span>,
            dataIndex: 'settlementDate',
            key: 'settlementDate',
            align: 'right',
            render: (text: any) => <div>
                <span className="robotoregular text-black-100">{text ? moment(text).format('HH:mm DD/MM/YYYY') : '---'}</span>
            </div>
        },
        {
            title: <span className='robotomedium text-xs text-grey-400'>{t('bifiin.totalPaid')}</span>,
            dataIndex: 'totalPaid',
            key: 'totalPaid',
            render: (text: any) => <div>
                <span className={`robotomedium ${text >= 0 ? 'text-blue-violet' : 'text-[#FF4559]'}`}>{text ? moneyFormat(text) : '---'}</span>
            </div>
        },
        {
            title: <span className='robotomedium text-xs text-grey-400'>{t('bifiin.amount')}</span>,
            dataIndex: 'amount',
            key: 'amount',
            align: 'right',
            render: (text: any) => <div>
                <span className={`robotomedium ${text >= 0 ? 'text-blue-violet' : 'text-[#FF4559]'}`}>{text ? moneyFormat(text) : '---'}</span>
            </div>
        },
        {
            title: <span className='robotomedium text-xs text-grey-400'>{t('bifiin.status')}</span>,
            dataIndex: 'status',
            key: 'status',
            render: (text: status) => {
                return (
                    <div>
                        {
                            text ? <Button
                                type={'primary'}
                                onClick={(e) => e.stopPropagation()}
                                style={style[text]}
                                className={`h-[unset] border-none ml-1 px-3 py-1 rounded-3xl text-white text-xs robotoregular select-none`}
                            >
                                {t(`bifiinStatus.${text}`)}
                            </Button> : '---'
                        }
                    </div>
                )
            }
        },
    ];

    return (
        <Skeleton loading={loading} active>
            <div className="pdl15 pdr15">
                <div className="_info_credit rounded-md bg-blue-200 p-3 sm:w-full">
                    <span className="text-base robotobold text-black-100 capitalize">
                        {t('Bifiin.Title')}
                        {tosUrl && <a className="ml-3" href={tosUrl} target="_blank" rel="noreferrer">Xem thông tin chính sách và điều khoản</a>}
                    </span>
                    <div className="mt-3 w-2/6 screen-xs:w-full">
                        <div className="mb-3 flex sm:items-start justify-between ">
                            <span className="robotoregular text-black-100">
                                {t('bifiin.status')}
                            </span>
                            {
                                get(loans, 'status', null) ?
                                    <Button
                                        type={'primary'}
                                        style={styleLoans[loans?.status || '']}
                                        onClick={(e) => e.stopPropagation()}
                                        className={`h-[unset] border-none ml-1 px-3 py-1 rounded-3xl text-white text-xs robotoregular select-none`}
                                    >
                                        {t(`bifiinStatusLoans.${get(loans, 'status', null)}`)}
                                    </Button> : '---'
                            }
                        </div>
                        <div className="mb-3 flex sm:items-start justify-between ">
                            <span className="robotoregular text-black-100 flex items-center">
                                {t('bifiin.remainingPrincipalAmount')}
                                <Tooltip className={'mgl5'} title={t('bifiin.remainingPrincipalAmountInfo')}>
                                    <AntdIcon.QuestionCircleOutlined type="question-circle"
                                                            className={`txt-size-h8 txt-color-gray opacity6 mgl5 _remark-icon-tooltip`}/>
                                </Tooltip>
                            </span>
                            <span className="robotobold text-blue-violet">
                                {loans?.disbursedAmount !== null ? moneyFormat(loans?.disbursedAmount) : '---'}
                            </span>
                        </div>
                        <div className="flex mb-3 sm:items-start justify-between ">
                            <span className="robotoregular text-black-100 flex items-center">
                                {t('bifiin.remainingFeeAmount')}
                                <Tooltip className={'mgl5'} title={t('bifiin.remainingFeeAmountInfo')}>
                                    <AntdIcon.QuestionCircleOutlined type="question-circle"
                                                            className={`txt-size-h8 txt-color-gray opacity6 mgl5 _remark-icon-tooltip`}/>
                                </Tooltip>
                            </span>
                            <span className="robotobold text-blue-violet">
                                {loans?.feeAndInterestAccumulation !== null ? moneyFormat(loans?.feeAndInterestAccumulation) : '---'}
                            </span>
                        </div>
                        <div className="mb-3 flex sm:items-start justify-between ">
                            <span className="robotoregular text-black-100">
                                {t('bifiin.loanAmount')}
                                <Tooltip className={'mgl5'} title={t('bifiin.loanAmountInfo')}>
                                    <AntdIcon.QuestionCircleOutlined type="question-circle"
                                                            className={`txt-size-h8 txt-color-gray opacity6 mgl5 _remark-icon-tooltip`}/>
                                </Tooltip>
                            </span>
                            <span className="robotobold text-blue-violet">
                                {get(loans, 'loanAmount', null) ? moneyFormat(get(loans, 'loanAmount', null)) : '---'}
                            </span>
                        </div>
                        <div className="mb-3 flex sm:items-start justify-between ">
                            <span className="robotoregular text-black-100">
                                {t('bifiin.feeAmount')}
                                <Tooltip className={'mgl5'} title={t('bifiin.loanAmountInfo')}>
                                    <AntdIcon.QuestionCircleOutlined type="question-circle"
                                                            className={`txt-size-h8 txt-color-gray opacity6 mgl5 _remark-icon-tooltip`}/>
                                </Tooltip>
                            </span>
                            <span className="robotobold text-blue-violet">
                                {get(loans, 'feeAndInterestOriginal', null) ? moneyFormat(get(loans, 'feeAndInterestOriginal', null)) : '---'}
                            </span>
                        </div>
                        <div className="flex sm:items-start justify-between ">
                            <span className="robotoregular text-black-100">
                                {t('bifiin.totalAmountPay')}
                                <Tooltip className={'mgl5'} title={t('bifiin.totalAmountPayInfo')}>
                                    <AntdIcon.QuestionCircleOutlined type="question-circle"
                                                            className={`txt-size-h8 txt-color-gray opacity6 mgl5 _remark-icon-tooltip`}/>
                                </Tooltip>
                            </span>
                            <span className="robotobold text-blue-violet">
                                {get(loans, 'totalAmountPay') !== null ? moneyFormat(get(loans, 'totalAmountPay', null)) : '---'}
                            </span>
                        </div>
                    </div>
                </div>
                <div className='_credits my-3'>
                    <Table columns={columns} dataSource={credits} pagination={false} />
                </div>
            </div>
        </Skeleton>
    );
}

export default TabCredit;