/* eslint-disable array-callback-return */
import React from 'react'
import { Alert, Col, Input, InputNumber, Modal, notification, Radio, Row, Select, Steps, Button, Divider, Popconfirm } from 'antd'
import * as AntdIcon from '@ant-design/icons'
import lodash, { debounce } from 'lodash'
import { withNamespaces } from 'react-i18next'
import { LocalStore } from '../../utils/LocalStore'
import UserService from '../../services/user.service'
import { moneyFormat } from '../../utils/stringUtils'
import { validateLink } from '../../utils/validate'
import CategoriesService from '../../services/categories.service'
import { UploadQrCodePeerPaymentPay } from './UploadQrCodePeerPaymentPay'
import uploadFile from '../../utils/api/uploadFile'

let check = false
class ModalCreatePeerPaymentPay extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentLoggedUser: {},
            bodyData: {
                paymentMethodCode: 'alipay',
            },
            typePeerPayment: 'paymentAccount',
            type: 'alipay',
            accountList: [],
            isUploading: false,
            step: 1,
            files: [],
        }
        this.onBlurInputAmountDebounce = debounce(this.onBlurInputAmount, 300)

    }

    // scroll top khi bắt đầu load trang
    componentDidMount() {
        const { tenantConfigPayment } = this.props
        this.setState(
            {
                currentProjectInfo: LocalStore.getInstance().read('currentProjectInfo') || {},
                currentLoggedUser: LocalStore.getInstance().read('currentLoggedUser') || {},
            },
            () => {
                this.getPaymentAccounts()

                let type = ''

                if (
                    lodash.get(tenantConfigPayment, 'config.paymentAlipay') &&
                    lodash.get(tenantConfigPayment, 'config.payment1688Business')
                ) {
                    type = 'alipay'
                } else if (lodash.get(tenantConfigPayment, 'config.paymentAlipay')) {
                    type = 'alipay'
                } else {
                    type = 'company'
                }

                this.setState({
                    type: type,
                })
            }
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.step !== this.state.step && this.state.step > 1 && this.state.type !== 'alipay') {
            this.getBetterOffer()
        }
    }

    /**
     * lấy ra danh sách tài khoản theo username
     */
    getAccountByUsername = () => {
        let { currentProjectInfo } = this.state
        const tenantConfig = currentProjectInfo?.tenantConfig || {}
        if (tenantConfig?.diorConfig?.enabled) {
            CategoriesService.getAccountByUsername((err, res, headers) => {
                if (!err) {
                    this.setState(
                        {
                            accountList: res,
                        },
                        () => {
                            if (res.length >= 1) {
                                this.onInputChange(lodash.get(res, '0.account'), 'walletAccount')
                            }
                        }
                    )
                }
            })
        }
    }

    /**
     * lấy số dư tài khoản của khách hàng
     */
    getBalanceUser = cb => {
        UserService.getBalanceUser((err, res) => {
            if (!err) {
                this.setState(
                    {
                        balance: res,
                    },
                    () => {
                        if (cb) cb()
                    }
                )
            }
        })
    }

    /**
     * lấy ra danh sách trạng thái của package
     */
    getExchangeRate = (query = '', cb) => {
        UserService.getExchangeRatePeerPayMent(query, (err, res, headers) => {
            if (err === null) {
                this.setState(
                    {
                        exchangeRate: res || {},
                    },
                    () => {
                        if (cb) cb()
                    }
                )
            }
        })
    }

    /**
     * lấy ra danh sách trạng thái của package
     */
    getPaymentAccounts = () => {
        const { bodyData = {} } = this.state
        let queryString = `?paymentMethodCode=${bodyData.paymentMethodCode}&limit=1000`
        CategoriesService.getPaymentAccounts(queryString, (err, res, headers) => {
            if (err === null) {
                this.setState({
                    listPaymentAccounts: res || [],
                })
            }
        })
    }

    onInputChange = (value, prop, cb) => {
        let bodyData = this.state.bodyData

        if (prop === 'originalReceipts' && value) {
            value = value.toString().trim()
        }

        bodyData[prop] = value

        this.setState(
            {
                bodyData,
            },
            () => {
                if (cb) cb()
            }
        )
    }

    onBlurInputAmount = cb => {
        const { bodyData = {}, amountQuery, paymentMethodCodeQuery } = this.state
        // console.log('bodyData', bodyData, paymentMethodCodeQuery)
        if (!cb) {
            if (amountQuery === bodyData.amount && paymentMethodCodeQuery === bodyData.paymentMethodCode) return
        }
        if (!bodyData.amount) return
        let query = `?amount=${bodyData.amount}&paymentMethodCode=${bodyData.paymentMethodCode}`
        this.setState({ amountQuery: bodyData.amount, paymentMethodCodeQuery: bodyData.paymentMethodCode }, () => {
            this.getExchangeRate(query, cb)
        })
    }

    onSubmit = totalMoney => {
        const { t, loadFromQueryString } = this.props
        const { bodyData = {}, isSubmit, type, currentProjectInfo } = this.state
        const needPayOnRequest = lodash.get(currentProjectInfo, 'tenantConfig.peerPaymentConfig.needPayOnRequest')

        let api = UserService.askForPay
        if (needPayOnRequest) {
            api = UserService.createRequestForPay
        }

        if (!this.onValidate()) {
            return
        }

        if (type !== 'alipay') {
            api = UserService.askToPayAnInvoice
            if (needPayOnRequest) {
                api = UserService.createPayAnInvoice
            }
        }

        if (check) return

        check = true

        // this.getBalanceUser(() => {
        //     const {balance = {}} = this.state;
        //
        //     if (balance.balance + balance.creditLimit < totalMoney) {
        //         notification['error']({
        //             message: t('message.not_enough_money')
        //         });
        //         check = false;
        //         return
        //     }

        this.setState(
            {
                isSubmit: true,
            },
            () => {
                if (isSubmit === true) return
                const code = bodyData.originalReceipts

                let payload = {
                    ...bodyData,
                    originalReceipts: code
                        ? [
                            {
                                code: bodyData.originalReceipts,
                                billTo: bodyData.billTo,
                            },
                        ]
                        : undefined,
                    force: true,
                }
                api(payload, (err, res, header) => {
                    if (!err) {
                        notification['success']({
                            message: t('message.success'),
                        })
                        this.setState(
                            {
                                isSubmit: false,
                                showModalAddProduct: false,
                                bodyData: {
                                    paymentMethodCode: 'alipay',
                                },
                            },
                            () => {
                                check = false
                            }
                        )
                        if (loadFromQueryString) loadFromQueryString()
                    } else {
                        if (err.title === 'insufficient_balance') {
                            this.setState({
                                showModalAddProduct: false,
                                bodyData: {
                                    paymentMethodCode: 'alipay',
                                },
                            })
                            if (loadFromQueryString) loadFromQueryString()
                            notification['success']({
                                message: t('message.success'),
                            })
                            // notification['error']({
                            //     message: t('message.not_enough_money')
                            // });
                        } else if (err.title === 'customer_not_found') {
                            notification['error']({
                                message: t('peer_payment.customer_not_found'),
                            })
                        } else if (err.title === 'payment_method_not_found') {
                            notification['error']({
                                message: t('peer_payment.payment_method_not_found'),
                            })
                        } else if (err.title === 'payment_account_not_found') {
                            notification['error']({
                                message: t('peer_payment.payment_account_not_found'),
                            })
                        } else if (err.title === 'shipment_required_when_create_pp') {
                            notification['error']({
                                message: t('peer_payment.shipment_required_when_create_pp'),
                            })
                        } else if (err.title === 'invalid_original_receipt_code') {
                            notification['error']({
                                message: t('peer_payment.invalid_original_receipt_code'),
                            })
                        } else {
                            notification['error']({
                                message: t(err.message),
                            })
                        }

                        this.setState({ isSubmit: false }, () => {
                            check = false
                        })
                    }
                })
            }
        )
        // })
    }

    onGetFee = () => {
        if (!this.onValidate()) {
            return
        }
        this.onBlurInputAmount(() => {
            const { bodyData = {}, isSubmit, exchangeRate = {} } = this.state

            let api = UserService.getPaymentQuotation

            if (check) return

            check = true

            this.setState(
                {
                    isSubmit: true,
                },
                () => {
                    if (isSubmit === true) return
                    let payload = {
                        ...bodyData,
                        exchangeRate: exchangeRate.rate,
                        originalReceipts: [
                            {
                                code: bodyData.originalReceipts,
                                billTo: bodyData.billTo,
                            },
                        ],
                    }
                    api(payload, (err, res, header) => {
                        if (!err) {
                            this.setState(
                                {
                                    isSubmit: false,
                                    draftFees: res,
                                    step: 2,
                                },
                                () => {
                                    check = false
                                }
                            )
                        } else {
                            this.setState({ isSubmit: false }, () => {
                                check = false
                            })
                        }
                    })
                }
            )
        })
    }

    onValidate = () => {
        let { bodyData = {}, error = {}, type, typePeerPayment, accountList } = this.state

        const { t } = this.props
        let check = 0

        if (!bodyData.amount) {
            error.amount = t('order.quantity_required')
            check = check + 1
        } else {
            error.amount = ''
        }

        if (accountList.length > 1 && !bodyData.walletAccount) {
            error.walletAccount = t('order.quantity_required')
            check = check + 1
        } else {
            error.walletAccount = ''
        }

        if (bodyData.note) {
            if (bodyData.note.length > 50) {
                error.note = t('peer_payment.memo_error')
                check = check + 1
            } else {
                error.note = ''
            }
        }

        if (type === 'alipay') {
            if (typePeerPayment === 'qrCode') {
                if (!bodyData.qrCode || !bodyData.qrCode.trim()) {
                    error.qrCode = t('shipment.qrcode_required')
                    check = check + 1
                } else {
                    error.qrCode = ''
                }
            } else {
                if (!bodyData.paymentLink || !bodyData.paymentLink.trim()) {
                    error.paymentLink = t('order.quantity_required')
                    check = check + 1
                } else {
                    if (!validateLink(bodyData.paymentLink)) {
                        error.paymentLink = t('shipment.link_error')
                        check = check + 1
                    } else {
                        error.paymentLink = ''
                    }
                }

                if (!bodyData.paymentAccount || !bodyData.paymentAccount.trim()) {
                    error.paymentAccount = t('order.quantity_required')
                    check = check + 1
                } else {
                    error.paymentAccount = ''
                }
            }
        } else {
            if (!bodyData.originalReceipts || !bodyData.originalReceipts.trim()) {
                error.originalReceipts = t('order.quantity_required')
                check = check + 1
            } else {
                error.originalReceipts = ''
            }
            if (!bodyData.billTo || !bodyData.billTo.trim()) {
                error.billTo = t('order.quantity_required')
                check = check + 1
            } else {
                error.billTo = ''
            }
        }

        this.setState({ error })
        if (check > 0) {
            return false
        }
        return true
    }

    /**
     * upload ảnh YCTT
     * @param data
     * @param cb
     */
    uploadImageQrCode = (data, cb) => {
        const { t } = this.props
        let apiServiceUrl = `/customer/images/upload`
        const fileUploaded = data.files || []
        this.setState({ isUploading: true, files: fileUploaded }, () => {
            uploadFile(apiServiceUrl, fileUploaded, null)
                .then(response => {
                    if (response.url) {
                        this.setState({ error: { qrCode: '' } })
                        let qrCodeUrl = response.url
                        this.onInputChange(qrCodeUrl, 'qrCode')
                    }
                    this.setState(
                        {
                            isUploading: false,
                        },
                        () => {
                            if (cb) cb()
                        }
                    )
                })
                .catch(error => {
                    notification['error']({
                        message: t(error.message),
                    })
                    this.setState({ isUploading: false })
                })
        })
    }

    renderFormAlipay = () => {
        const { t } = this.props
        const { bodyData = {}, error = {}, files = [], listPaymentAccounts = [], typePeerPayment, isUploading } = this.state
        return (
            <div>
                <Row className="mgbt20 _container_originalReceipts">
                    <div className="pdbt5">{t('peer_payment.originalReceipts')}</div>
                    <Input
                        className={'_input_originalReceipts'}
                        placeholder={t('peer_payment.originalReceipt_enter')}
                        onChange={e => this.onInputChange(e.target.value, 'originalReceipts')}
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                this.onGetFee()
                            }
                        }}
                        value={bodyData.originalReceipts}
                    />
                    {error.originalReceipts && (
                        <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">{error.originalReceipts}</div>
                    )}
                </Row>
                <Radio.Group
                    className="width-100-percent mgbt20"
                    onChange={e => {
                        this.setState({
                            typePeerPayment: e.target.value,
                        })
                    }}
                    value={typePeerPayment}
                >
                    <Row
                        gutter={20}
                        type={'flex'}
                    >
                        <Col span={12}>
                            <Radio value={'paymentAccount'}>Chọn tài khoản thanh toán</Radio>
                        </Col>
                        <Col span={12}>
                            <Radio value={'qrCode'}>Tải ảnh QR Code</Radio>
                        </Col>
                    </Row>
                </Radio.Group>
                {typePeerPayment === 'qrCode' ? (
                    <Row className="mgbt20 _container_paymentAccount">
                        <div className="pdbt5">
                            Tải ảnh QR Code <span className="txt-color-red">*</span>
                        </div>
                        <UploadQrCodePeerPaymentPay
                            t={t}
                            isUploading={isUploading}
                            fileUploaded={files}
                            callBackRemoveQrCode={() => {
                                this.onInputChange('', 'qrCode')
                            }}
                            uploadImageQrCode={this.uploadImageQrCode}
                        />
                        {error.qrCode && (
                            <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">{error.qrCode}</div>
                        )}
                    </Row>
                ) : (
                    <>
                        <Row className="mgbt20 _container_paymentAccount">
                            <div className="pdbt5">
                                {t('peer_payment.paymentAccount')} <span className="txt-color-red">*</span>
                            </div>
                            <Select
                                value={bodyData.paymentAccount || []}
                                className="_search-box-input-paymentAccount width-100-percent"
                                onChange={value => this.onInputChange(value, 'paymentAccount')}
                                placeholder={t('peer_payment.select_paymentAccount')}
                            >
                                {Array.isArray(listPaymentAccounts) &&
                                    listPaymentAccounts.map((item, index) => (
                                        <Select.Option
                                            key={index}
                                            value={item.account}
                                        >
                                            {item.displayName} ({item.account})
                                        </Select.Option>
                                    ))}
                            </Select>
                            {error.paymentAccount && (
                                <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">
                                    {error.paymentAccount}
                                </div>
                            )}
                        </Row>
                        <Row className="mgbt20 _container_payment_link">
                            <div className="pdbt5">
                                {t('peer_payment.payment_link')} <span className="txt-color-red">*</span>
                            </div>
                            <Input
                                className={'_input_beneficiaryName'}
                                placeholder={t('peer_payment.payment_link_enter')}
                                onChange={e => this.onInputChange(e.target.value, 'paymentLink')}
                                onKeyDown={e => {
                                    if (e.keyCode === 13) {
                                        this.onGetFee()
                                    }
                                }}
                                value={bodyData.paymentLink}
                            />
                            {error.paymentLink && (
                                <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">{error.paymentLink}</div>
                            )}
                        </Row>
                    </>
                )}

                <Row className="_container_payment_note">
                    <div className="pdbt5">{t('peer_payment.note')}</div>
                    <Input.TextArea
                        placeholder={t('peer_payment.note_placeholder')}
                        className={'_input_beneficiaryName'}
                        onChange={e => this.onInputChange(e.target.value, 'note')}
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                this.onGetFee()
                            }
                        }}
                        value={bodyData.note}
                    />
                    {error.note && <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">{error.note}</div>}
                </Row>
            </div>
        )
    }

    renderFormCompany = () => {
        const { t } = this.props
        const { bodyData = {}, error = {} } = this.state
        return (
            <div>
                <Row className="mgbt20 _container_originalReceipts">
                    <div className="pdbt5">
                        {t('peer_payment.originalReceipts')} <span className="txt-color-red">*</span>
                    </div>
                    <Input
                        className={'_input_originalReceipts'}
                        placeholder={t('peer_payment.originalReceipt_enter')}
                        onChange={e => this.onInputChange(e.target.value, 'originalReceipts')}
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                this.onGetFee()
                            }
                        }}
                        value={bodyData.originalReceipts}
                    />
                    {error.originalReceipts && (
                        <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">{error.originalReceipts}</div>
                    )}
                </Row>

                <Row className="mgbt20 _container_billTo">
                    <div className="pdbt5">
                        {t('peer_payment.billTo')} <span className="txt-color-red">*</span>
                    </div>
                    <Input
                        className={'_input_beneficiaryName'}
                        placeholder={t('peer_payment.billTo_enter')}
                        onChange={e => this.onInputChange(e.target.value, 'billTo')}
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                this.onGetFee()
                            }
                        }}
                        value={bodyData.billTo}
                    />
                    {error.billTo && (
                        <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">{error.billTo}</div>
                    )}
                </Row>

                <Row className="_container_payment_note">
                    <div className="pdbt5">{t('peer_payment.note')}</div>
                    <Input.TextArea
                        placeholder={t('peer_payment.note_placeholder')}
                        className={'_input_beneficiaryName'}
                        onChange={e => this.onInputChange(e.target.value, 'note')}
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                this.onGetFee()
                            }
                        }}
                        value={bodyData.note}
                    />
                    {error.note && <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">{error.note}</div>}
                </Row>
            </div>
        )
    }

    getBetterOffer = () => {
        const {
            bodyData: { originalReceipts, billTo },
        } = this.state
        UserService.getBetterOffer(
            { thirdParty: '1688', credentialAccount: billTo, originalReceipt: originalReceipts },
            (err, res) => {
                let betterOffer = 0
                if (!err) {
                    const yoursTotalAmount = lodash.get(res, 'yours.totalAmount', 0)
                    const oursTotalAmount = lodash.get(res, 'ours.totalAmount', 0)
                    if (yoursTotalAmount > oursTotalAmount) {
                        betterOffer = oursTotalAmount
                        const { bodyData = {}, exchangeRate = {} } = this.state
                        let payload = {
                            ...bodyData,
                            exchangeRate: exchangeRate.rate,
                            originalReceipts: [
                                {
                                    code: bodyData.originalReceipts,
                                    billTo: bodyData.billTo,
                                },
                            ],
                            gobizOffer: {
                                oldOffer: lodash.get(res, 'yours', {}),
                                newOffer: lodash.get(res, 'ours', {}),
                            },
                        }
                        UserService.getPaymentQuotation(payload, (error, response, header) => {
                            let feesBetterOffer = {}
                            if (!error) {
                                feesBetterOffer = response
                            }
                            this.setState({ feesBetterOffer })
                        })
                    }
                }
                this.setState({
                    betterOffer,
                })
            }
        )
    }

    placeOrderWithBetterOffer = () => {
        const { t } = this.props
        const { bodyData } = this.state
        this.setState({ loadingPlaceOrder: true })
        const code = bodyData.originalReceipts
        let payload = {
            ...bodyData,
            paymentAccount: bodyData.billTo,
            originalReceipts: code
                ? [
                    {
                        code: bodyData.originalReceipts,
                        billTo: bodyData.billTo,
                        amount: bodyData.amount,
                    },
                ]
                : undefined,
        }
        UserService.placeOrderBetterOffer(payload, (err, res) => {
            if (!err) {
                this.setState({
                    files: [],
                    showModalAddProduct: false,
                    bodyData: {
                        paymentMethodCode: 'alipay',
                    },
                })
                notification.success({
                    message: t('message.place_order_with_better_offer_success'),
                })
            } else {
                if (err.title === 'customer_not_found') {
                    notification['error']({
                        message: t('peer_payment.customer_not_found'),
                    })
                } else if (err.title === 'payment_method_not_found') {
                    notification['error']({
                        message: t('peer_payment.payment_method_not_found'),
                    })
                } else if (err.title === 'payment_account_not_found') {
                    notification['error']({
                        message: t('peer_payment.payment_account_not_found'),
                    })
                } else if (err.title === 'shipment_required_when_create_pp') {
                    notification['error']({
                        message: t('peer_payment.shipment_required_when_create_pp'),
                    })
                } else if (err.title === 'invalid_original_receipt_code') {
                    notification['error']({
                        message: t('peer_payment.invalid_original_receipt_code'),
                    })
                } else {
                    notification['error']({
                        message: t(err.message),
                    })
                }
            }
            this.setState({ loadingPlaceOrder: false })
        })
    }

    renderModalAddProduct = () => {
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const tenantConfig = currentProjectInfo?.tenantConfig || {}
        const { t, listFees = [], tenantConfigPayment } = this.props
        const {
            bodyData = {},
            error = {},
            exchangeRate = {},
            type,
            isSubmit,
            step,
            draftFees = {},
            accountList = [],
            feesBetterOffer = {},
            betterOffer = 0,
        } = this.state

        let totalMoney = 0
        let showWarning = false
        if (Array.isArray(draftFees.listFees)) {
            totalMoney = bodyData.amount * exchangeRate.rate
            draftFees.listFees.map(item => {
                totalMoney = totalMoney + item.provisionalAmount

                if (item.provisionalAmount === null || item.provisionalAmount === undefined) {
                    showWarning = true
                }
            })
        }

        let totalBetterOffer = betterOffer * exchangeRate.rate
        if (Array.isArray(feesBetterOffer.listFees)) {
            feesBetterOffer.listFees.map(item => {
                totalBetterOffer = totalBetterOffer + item.provisionalAmount
            })
        }
        return (
            <Modal
                title={
                    <span className="text-black-100 font-medium text-base capitalize">
                        {t('peer_payment.create_request_for_pay')}
                    </span>
                }
                visible={this.state.showModalAddProduct}
                className={'modal-peer-payment'}
                width={!!this.state.betterOffer && step > 1 ? 800 : 600}
                onCancel={() => {
                    if (step > 1) {
                        this.setState({
                            step: step - 1,
                            betterOffer: 0,
                        })
                    } else {
                        this.setState({
                            files: [],
                            showModalAddProduct: false,
                            bodyData: {
                                paymentMethodCode: 'alipay',
                            },
                        })
                    }
                }}
                footer={[
                    <Button
                        key="cancel"
                        className="_btn-cancel-confirm rounded"
                        onClick={() => {
                            if (step > 1) {
                                this.setState({
                                    step: step - 1,
                                    betterOffer: 0,
                                })
                            } else {
                                this.setState({
                                    files: [],
                                    showModalAddProduct: false,
                                    bodyData: {
                                        paymentMethodCode: 'alipay',
                                    },
                                })
                            }
                        }}
                    >
                        {step > 1 ? t('forgot_pass.back') : t('button.cancel')}
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        icon={step > 1 ? <i class="fa-regular fa-plus mr-2"></i> : null}
                        className={`_btn-submit-confirm rounded ${!!this.state.betterOffer
                            ? 'shadow-none text-blue-primary border-pattens-blue-100 bg-pattens-blue-100'
                            : ''
                            }`}
                        style={{ textShadow: !!this.state.betterOffer ? 'none' : 'inherit' }}
                        disabled={isSubmit || !exchangeRate.rate}
                        onClick={() => {
                            if (step === 1) {
                                this.onGetFee()
                            } else {
                                this.onSubmit(totalMoney)
                            }
                        }}
                    >
                        {step === 1 ? t('modal.confirm') : t('delivery.create')}
                    </Button>,
                    !!this.state.betterOffer && step > 1 && (
                        <Popconfirm
                            overlayClassName="popup-confirm-place-order"
                            placement="topRight"
                            icon={<AntdIcon.InfoCircleFilled style={{ color: '#1890FF' }} />}
                            title="Bạn đồng ý mua hàng với giá ưu đãi và tạo YCTT cho đơn?"
                            okText="Xác nhận"
                            cancelText="Huỷ bỏ"
                            onConfirm={this.placeOrderWithBetterOffer}
                            cancelButtonProps={{
                                className: 'cancel-buy-better-offer text-shadow-none shadow-none border-none',
                                icon: <AntdIcon.CloseOutlined />,
                            }}
                            okButtonProps={{
                                className: 'confirm-buy-better-offer text-shadow-none shadow-none border-none',
                                icon: <AntdIcon.CheckOutlined />,
                                loading: this.state.loadingPlaceOrder,
                            }}
                        >
                            <Button
                                key="buy"
                                type="primary"
                                className="_btn-submit-confirm rounded border-blue-primary"
                                icon={<i class="fa-regular fa-percent mr-2"></i>}
                            >
                                {t('button.buy_with_better_offer')}
                            </Button>
                        </Popconfirm>
                    ),
                ]}
            >
                <div>
                    <div
                        className="max-width-400 pdbt25"
                        style={{ margin: 'auto' }}
                    >
                        <Steps current={Number.isFinite(parseFloat(step)) ? parseFloat(step) - 1 : 0}>
                            <Steps.Step title={t('peer_payment.request_for_pay_info')} />
                            <Steps.Step title={t('modal.confirm')} />
                        </Steps>
                    </div>

                    {step === 1 ? (
                        <div>
                            {tenantConfig?.diorConfig?.enabled && accountList.length > 1 && (
                                <Row
                                    gutter={20}
                                    type={'flex'}
                                    className="mgbt20"
                                >
                                    <Col span={24}>
                                        <div className="pdbt5">
                                            Tài khoản nguồn <span className="txt-color-red">*</span>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <Select
                                            value={bodyData.walletAccount || accountList[0].account}
                                            className="_search-box-input-paymentAccount width-100-percent"
                                            onChange={value => this.onInputChange(value, 'walletAccount')}
                                            placeholder={'Chọn tài khoản nguồn'}
                                        >
                                            {Array.isArray(accountList) &&
                                                accountList.map((item, index) => (
                                                    <Select.Option
                                                        key={index}
                                                        value={item.account}
                                                    >
                                                        {item.name}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                        {error.walletAccount && (
                                            <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">
                                                {error.walletAccount}
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            )}
                            <Row
                                gutter={20}
                                type={'flex'}
                                className="mgbt20"
                            >
                                <Col
                                    span={12}
                                    className="_container_amount"
                                >
                                    <div className="pdbt5">
                                        {t('peer_payment.amount')} <span className="txt-color-red">*</span>
                                    </div>
                                    <InputNumber
                                        precision={2} // bắt chỉ cho nhập integer
                                        className={`_input_amount width-100-percent`}
                                        placeholder={t('peer_payment.amount_placeholder')}
                                        min={1}
                                        value={bodyData.amount}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        onBlur={e => this.onBlurInputAmount()}
                                        onChange={value => {
                                            if (typeof value === 'number') {
                                                // console.log('chán', value)
                                                this.onInputChange(value, 'amount', this.onBlurInputAmountDebounce)
                                            }
                                        }}
                                        onKeyDown={e => {
                                            if (e.keyCode === 13) {
                                                this.onGetFee()
                                            }
                                        }}
                                    />
                                    {error.amount && (
                                        <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">
                                            {error.amount}
                                        </div>
                                    )}

                                    {exchangeRate.rate === null && (
                                        <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">
                                           {t('peerPayment.minPayment', { value: exchangeRate.minPayment})}
                                        </div>
                                    )}

                                    {!!exchangeRate.base && (
                                        <div className={'txt-color-gray mgt15'}>
                                            <span className={'txt-size-h8 txt-color-gray robotoregular'}>
                                                {t('header.exchange')}
                                            </span>
                                            <span className={'_exchange-rate txt-color-black robotoregular txt-size-h7 mgl5'}>
                                                {moneyFormat(1, exchangeRate.base)} ={' '}
                                                {moneyFormat(exchangeRate.rate, exchangeRate.exchange)}
                                            </span>
                                        </div>
                                    )}
                                </Col>

                                <Col
                                    span={12}
                                    className="_container_exchangedAmount"
                                >
                                    <div className="exchanged-amount-box">
                                        <div>{t('peer_payment.exchangedAmount')}</div>
                                        <div className="exchanged-amount-box-value">
                                            {bodyData.amount !== undefined
                                                ? moneyFormat(bodyData.amount * exchangeRate.rate)
                                                : moneyFormat(0)}
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Radio.Group
                                className="width-100-percent mgbt20"
                                onChange={e => {
                                    this.setState({
                                        type: e.target.value,
                                        betterOffer: 0,
                                    })
                                    // this.onInputChange(e.target.value, 'paymentMethodCode', () => this.onBlurInputAmount());
                                    this.onInputChange('', 'billTo')
                                    this.onInputChange('', 'paymentAccount')
                                    this.onInputChange('', 'paymentLink')
                                    this.onInputChange('', 'qrCode')
                                }}
                                value={type}
                            >
                                <Row
                                    gutter={20}
                                    type={'flex'}
                                    justify={'space-between'}
                                >
                                    {lodash.get(tenantConfigPayment, 'config.paymentAlipay') === true && (
                                        <Col>
                                            <Radio value={'alipay'}>{t('peer_payment.request_for_pay_ali')}</Radio>
                                        </Col>
                                    )}

                                    {lodash.get(tenantConfigPayment, 'config.payment1688Business') === true && (
                                        <Col>
                                            <Radio value={'company'}>{t('peer_payment.request_for_pay_company')}</Radio>
                                        </Col>
                                    )}
                                </Row>
                            </Radio.Group>

                            {type === 'alipay' ? this.renderFormAlipay() : this.renderFormCompany()}
                        </div>
                    ) : (
                        <div className="box-confirm-peerpayment">
                            <Row>
                                <Col
                                    span={!!this.state.betterOffer ? 12 : 24}
                                    className={!!this.state.betterOffer ? 'pr-4' : ''}
                                >
                                    <div className="payment-info">
                                        <div className="payment-info-title font-medium">
                                            {t('peer_payment.create_payment_with_normal')}
                                        </div>
                                        <div className="payment-info-detail">
                                            <div className="flex justify-between mb-3">
                                                <div>{t('peer_payment.amount')}:</div>
                                                <div>{moneyFormat(bodyData.amount, 'CNY')}</div>
                                            </div>
                                            <div className="flex justify-between mb-3">
                                                <div>{t('peer_payment.exchangedAmount')}:</div>
                                                <div>
                                                    {bodyData.amount !== undefined
                                                        ? moneyFormat(bodyData.amount * exchangeRate.rate)
                                                        : moneyFormat(0)}
                                                </div>
                                            </div>
                                            {Array.isArray(draftFees.listFees) &&
                                                draftFees.listFees.map((item, index) => {
                                                    let currentFee = listFees.find(x => x.code === item.feeCode)
                                                    return (
                                                        <div className="flex justify-between mb-3">
                                                            <div>
                                                                {currentFee ? currentFee.name || item.feeCode : item.feeCode}:
                                                            </div>
                                                            <div>{moneyFormat(item.provisionalAmount)}</div>
                                                        </div>
                                                    )
                                                })}
                                            <Divider
                                                dashed
                                                className="my-4"
                                                style={{ borderColor: '#6DB8FD' }}
                                            />
                                            <div className="total-info flex justify-between mb-3">
                                                <div>{t('orderDetail.total_money')}:</div>
                                                <div className="text-blue-100 font-medium text-base">
                                                    {moneyFormat(totalMoney)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                {!!this.state.betterOffer && (
                                    <Col
                                        span={12}
                                        className="pl-4 better-offer-info"
                                    >
                                        <div className="payment-info-title font-medium">
                                            {t('peer_payment.payment_with_better_offer')}
                                        </div>
                                        <div className="payment-info-detail">
                                            <div className="flex justify-between mb-3">
                                                <div>{t('peer_payment.better_offer_value')}:</div>
                                                <div>{moneyFormat(this.state.betterOffer, 'CNY')}</div>
                                            </div>
                                            <div className="flex justify-between mb-3">
                                                <div>{t('peer_payment.exchangedAmount')}:</div>
                                                <div>{moneyFormat(this.state.betterOffer * exchangeRate.rate)}</div>
                                            </div>
                                            {Array.isArray(feesBetterOffer.listFees) &&
                                                feesBetterOffer.listFees.map((item, index) => {
                                                    let currentFee = listFees.find(x => x.code === item.feeCode)
                                                    return (
                                                        <div className="flex justify-between mb-3">
                                                            <div>
                                                                {currentFee ? currentFee.name || item.feeCode : item.feeCode}:
                                                            </div>
                                                            <div>{moneyFormat(item.provisionalAmount)}</div>
                                                        </div>
                                                    )
                                                })}
                                            <Divider
                                                dashed
                                                className="my-4"
                                                style={{ borderColor: '#6DB8FD' }}
                                            />
                                            <div className="total-info flex justify-between mb-3">
                                                <div>{t('orderDetail.total_money')}:</div>
                                                <div className="text-blue-100 font-medium text-base">
                                                    {moneyFormat(totalBetterOffer)}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                )}
                                <Alert
                                    className="mgbt10 mgt10 _create_bodyData_warning"
                                    npm
                                    message={<span className="txt-size-h8">{t('peer_payment.create_warning_message')}</span>}
                                    type="warning"
                                    showIcon
                                    icon={<AntdIcon.WarningFilled />}
                                />

                                {showWarning ? (
                                    <Alert
                                        className="mgbt10 mgt10 _bodyData_warning"
                                        message={<span className="txt-size-h8">{t('peer_payment.fee_warning')}</span>}
                                        type="warning"
                                        showIcon
                                        icon={<AntdIcon.WarningFilled />}
                                    />
                                ) : null}
                            </Row>
                        </div>
                    )}
                </div>
            </Modal>
        )
    }

    render() {
        const { t } = this.props
        return (
            <div className="dpl-il-block">
                <Button
                    type='primary'
                    className="rounded"
                    onClick={() => {
                        this.setState({
                            showModalAddProduct: true,
                            bodyData: {
                                paymentMethodCode: 'alipay',
                                ...this.state.bodyData,
                            },
                            typePeerPayment: 'paymentAccount',
                            files: [],
                            error: {},
                            exchangeRate: {},
                            amountQuery: 0,
                            step: 1,
                        })
                    }}
                >
                    <AntdIcon.PayCircleOutlined
                        type="pay-circle"
                        style={{ fontSize: 14 }}
                        className=""
                    />{' '}
                    {t('peer_payment.create_request_for_pay')}
                </Button>

                {this.renderModalAddProduct()}
            </div>
        )
    }
}
export default withNamespaces()(ModalCreatePeerPaymentPay)
