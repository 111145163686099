/* eslint-disable array-callback-return */
import React from 'react';
import _ from 'lodash'
import {
    Button,
    Radio,
    Divider,
    Checkbox, Tooltip, Typography
} from 'antd';
import * as AntdIcon from "@ant-design/icons"

export default class OrderServiceGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapse: true,
            checkedList: []
        }
    }

    componentDidMount() {
        const {dataSource} = this.props;
        this.setState({
            checkedList: dataSource.services || []
        })
    }

    componentDidUpdate(prevProps) {
        const {dataSource} = this.props;
        if (JSON.stringify(dataSource) !== JSON.stringify(prevProps.dataSource)) {
            this.setState({
                checkedList: dataSource.services || []
            })
        }
    }

    setChecklist = (checkedList) => {
        this.setState({checkedList})
    };

    checkDisable = (item) => {
        let {checkedList} = this.state;
        let disable = false;

        // if (item.requires.length > 0) {
        //     let hasOne = false;
        //     item.requires.map(x => {
        //         if (checkedList.find(y => y.code === x)) {
        //             hasOne = true;
        //         }
        //     });
        //
        //     disable = !hasOne;
        // }
        // if (Array.isArray(item.requireGroups) && item.requireGroups.length > 0) {
        //     let hasOne = false;
        //     item.requireGroups.map(x => {
        //         if (checkedList.find(y => y.serviceGroup && y.serviceGroup.code === x)) {
        //             hasOne = true;
        //         }
        //     });
        //
        //     disable = !hasOne;
        // }

        // nếu item là dependencies của 1 phần tử trong checkedList
        if (checkedList.find(x => x.dependencies.find(y => y === item.code))) {
            disable = true;
        }

        // nếu trong list check có phần tử excludes item thì disable item
        if (checkedList.length > 0 && checkedList.find(x => x.excludes.indexOf(item.code) > -1)) {
            disable = true;
        }

        // nếu trong list check có phần tử excludes item thì disable item
        if (item.serviceGroup) {
            if (checkedList.length > 0 && checkedList.find(x => Array.isArray(x.excludeGroups) && x.excludeGroups.indexOf(item.serviceGroup.code) > -1)) {
                disable = true;
            }
        }


        return disable
    };

    /**
     * item checked
     * @param item
     */
    onItemCheck = (item, isCallback, checkedListParam) => {
        const {onCheckServiceGroup, orderServices = []} = this.props;
        let checkedList = checkedListParam || this.state.checkedList || [];

        // nếu item đã tồn tại trong mảng checked thì bỏ phần tử đó ra khỏi mảng checked
        if (checkedList.find(x => x.code === item.code)) {
            checkedList = checkedList.filter(x => x.code !== item.code);

            // loại bỏ các phân tử đã được check nhưng có requires của item
            // và ko còn chứa bất kỳ 1 required nào khác
            let listHasRequiresItem = checkedList.filter(x => x.requires.indexOf(item.code) > -1);
            listHasRequiresItem.map(x => {
                let remove = true;
                x.requires.map(y => {
                    if (checkedList.find(z => z.code === y)) {
                        remove = false;
                    }
                });
                if (remove) {
                    checkedList = checkedList.filter(y => y.code !== x.code)
                }
            });

            // loại bỏ các phân tử dependencies của item
            if (Array.isArray(item.dependencies) && item.dependencies.length > 0) {
                item.dependencies.map(x => {
                    checkedList = checkedList.filter(y => y.code !== x)
                })
            }

            // loại bỏ các phân tử requireGroup của group chứa item nếu không còn
            if (item.serviceGroup) {
                if (checkedList.filter(x => x.serviceGroup && x.serviceGroup.code === item.serviceGroup.code).length === 0) {
                    checkedList = checkedList.filter(y => !Array.isArray(y.requireGroups) || y.requireGroups.indexOf(item.serviceGroup.code) === -1)
                }
            }

        } else {
            //loại bỏ các phần tử trong mảng checkedList nếu item có excludes phần tử đó
            checkedList = checkedList.filter(x => item.excludes.indexOf(x.code) === -1);

            //loại bỏ các phần tử trong mảng checkedList nếu item có excludeGroups phần tử đó
            if (Array.isArray(item.excludeGroups)) {
                item.excludeGroups.map(x => {
                    checkedList = checkedList.filter(y => !y.serviceGroup || y.serviceGroup.code !== x);
                })
            }

            //loại bỏ các phần từ trong mảng checkedList nếu các phần tử đó cùng group với item và group có single === true
            if (item.serviceGroup && item.serviceGroup.single) {
                checkedList = checkedList.filter(x => !(x.serviceGroup && x.serviceGroup.code === item.serviceGroup.code))
            }

            // tự động thêm các phần tử thuộc dependencies của item
            if (Array.isArray(item.dependencies) && item.dependencies.length > 0) {
                item.dependencies.map(x => {
                    if (orderServices.find(y => y.code === x)) {
                        checkedList = _.unionBy(checkedList, this.onItemCheck(orderServices.find(y => y.code === x), true, checkedList), 'code');
                    }
                })
            }

            // console.log('checkedList', checkedList)

            checkedList.push(item);
        }

        if (isCallback) {
            return checkedList
        } else {
            this.setState({
                checkedList,
            });
        }
        onCheckServiceGroup(item)
    };

    /**
     * thay đổi giá trị của radio
     * @param e
     */
    onChangeRadio = (e) => {
        const {orderServices, onCheckServiceGroup} = this.props;
        // lấy ra item được chọn
        let item = orderServices.find(x => x.code === e.target.value);
        if (!item) return;

        let checkedList = this.state.checkedList || [];

        //loại bỏ các phần tử trong mảng checkedList nếu item có excludes phần tử đó
        checkedList = checkedList.filter(x => item.excludes.indexOf(x.code) === -1);

        //loại bỏ các phần từ trong mảng checkedList nếu các phần tử đó cùng group với item và group có single === true
        if (item.serviceGroup && item.serviceGroup.single) {
            checkedList.filter(x => (x.serviceGroup && x.serviceGroup.code === item.serviceGroup.code)).map(x => {

                // loại bỏ các phân tử đã được check nhưng có requires của các phần sắp bị loại bỏ do cùng group với item
                // và ko còn chứa bất kỳ 1 required nào khác
                let listHasRequiresItem = checkedList.filter(y => y.requires.indexOf(y.code) > -1);
                listHasRequiresItem.map(p => {
                    let remove = true;
                    p.requires.map(y => {
                        let itemRelate = checkedList.find(z => z.code === y);
                        if (itemRelate) {
                            if (!itemRelate.serviceGroup || itemRelate.serviceGroup.code !== item.serviceGroup.code) {
                                remove = false;
                            }
                        }
                    });
                    if (remove) {
                        checkedList = checkedList.filter(y => y.code !== p.code)
                    }
                });

                // loại bỏ các phân tử dependencies của item sắp bị bỏ khỏi mảng checkedList
                if (Array.isArray(x.dependencies) && x.dependencies.length > 0) {
                    x.dependencies.map(y => {
                        checkedList = checkedList.filter(z => z.code !== y)
                    });
                }

                // loại bỏ các phân tử requireGroup của group chứa item sắp bị bỏ khỏi mảng checkedList
                if (x.serviceGroup) {
                    if (checkedList.filter(y => y.serviceGroup && y.serviceGroup.code === x.serviceGroup.code).length === 0) {
                        checkedList = checkedList.filter(y => !Array.isArray(y.requireGroups) || y.requireGroups.indexOf(x.serviceGroup.code) === -1)
                    }
                }
            });

            //loại bỏ các phần tử trong cùng group ra khỏi checkedList
            checkedList = checkedList.filter(x => !(x.serviceGroup && x.serviceGroup.code === item.serviceGroup.code))
        }

        // tự động thêm các phần tử thuộc dependencies của item
        if (Array.isArray(item.dependencies) && item.dependencies.length > 0) {
            item.dependencies.map(x => {
                if (orderServices.find(y => y.code === x)) {
                    checkedList = _.unionBy(checkedList, this.onItemCheck(orderServices.find(y => y.code === x), true, checkedList), 'code');
                }
            })
        }

        checkedList.push(item);

        //loại bỏ các phần tử trong mảng checkedList nếu item có excludeGroups phần tử đó
        if (Array.isArray(item.excludeGroups)) {
            item.excludeGroups.map(x => {
                checkedList = checkedList.filter(y => !y.serviceGroup || y.serviceGroup.code !== x);
            })
        }

        this.setState({
            checkedList,
        });
        onCheckServiceGroup(item)
    };

    /**
     * render check box
     * @param item
     * @returns {*}
     */
    renderCheckbox = (item) => {
        const {checkedList} = this.state;
        return <Checkbox
            disabled={this.checkDisable(item)}
            key={item.code}
            onChange={() => this.onItemCheck(item)}
            className={`_service-checkbox`}
            checked={checkedList.find(x => x.code === item.code) !== undefined}
        >
            <span className={'_service-name txt-size-h8 robotoregular txt-color-black'}>
                {item.name} {item.domestic && <Tooltip title="Dịch vụ vận chuyển nội địa"><i className="fa-regular fa-location-check ml-2"></i></Tooltip>} {
                    item.serviceInfo && <Tooltip title={item.serviceInfo}>
                        <AntdIcon.InfoCircleOutlined className="txt-size-h8 mgl5" type="info-circle" />
                    </Tooltip>
                }
            </span>
        </Checkbox>
    };

    /**
     * render check box ko có chức năng edit
     * @param item
     * @returns {*}
     */
    renderCheckboxDisable = (item) => {
        const {checkedList} = this.state;
        return <Checkbox
            disabled={this.checkDisable(item)}
            key={item.code}
            className={`_service-checkbox`}
            checked={checkedList.find(x => x.code === item.code) !== undefined}
        >
            <span className={'_service-name txt-size-h8 robotoregular txt-color-black'}>
                {item.name}
            </span>
        </Checkbox>
    };

    /**
     * render mô tả của service
     * @param item
     * @returns {null}
     */
    renderDescription = (item) => {
        const {checkedList = []} = this.state;
        const {t} = this.props;
        let currentItem = checkedList.find(x => x.code === item.code);
        return currentItem !== undefined ? <React.Fragment key={item.code}>
            {
                !!currentItem.requiresMessage && <p className="txt-error txt-size-h8 mgbt10">
                    <AntdIcon.ExclamationCircleOutlined type="exclamation-circle" className={'pdr5'}/> <span dangerouslySetInnerHTML={{__html: currentItem.requiresMessage}}/>
                </p>
            }
            {
                !!currentItem.requireGroupsMessage && <p className="txt-error txt-size-h8 mgbt10">
                    <AntdIcon.ExclamationCircleOutlined type="exclamation-circle" className={'pdr5'}/> <span dangerouslySetInnerHTML={{__html: currentItem.requireGroupsMessage}}/>
                </p>
            }
            {
                item.description && <p className="txt-color-gray txt-size-h8 mgt5 mgbt0">
                    <span className={`_service-name`}>{item.name}</span>: <span className={`_service-description`}>{item.description}</span>
                </p>
            }
            {
                item.needApprove && <p className={`_need-approve txt-warning txt-size-h8 mgt5 mgbt0`}>
                    <AntdIcon.WarningOutlined type="warning" className={'pdr5'}/> {t('orderServiceGroup.service')} {item.name} {t('orderServiceGroup.approved_privilege')} 
                </p>
            }
        </React.Fragment> : null
    };

    /**
     * render radio group
     * @param group
     * @returns {*}
     */
    renderRadioGroup = (group) => {
        const {checkedList} = this.state;
        const {orderServices, orderServiceGroups} = this.props;
        const currentItem = checkedList.find(x => (x.serviceGroup && x.serviceGroup.code === group.code));

        let listServiceTemp = _.uniq(orderServices?.map((listService)=> listService?.serviceGroup?.code).filter((serviceGroup)=>serviceGroup))
        const listRequireService = orderServiceGroups.filter(i=> {
            return listServiceTemp.includes(i.code) && i.required 
        })
        const checkDisableRadio = listRequireService?.find(i => i?.position === group?.position && group?.domestic === false && i?.domestic === true)

        return <div className={`_service-radio-group-${group.code} checkbox-group vertical`} key={group.code}>
            <Radio.Group
                key={group.code}
                // className={`_service-radio-group-${group.code}`}
                onChange={this.onChangeRadio}
                value={currentItem ? currentItem.code : null}
            >
                {
                    _.sortBy(orderServices.filter(x => x.serviceGroup && x.serviceGroup.code === group.code), 'position').map(x => {
                        return  <Radio
                        key={x.code}
                        className={`_service-checkbox vertical-radio`}
                        value={x.code}
                        disabled={checkDisableRadio ? checkDisableRadio :this.checkDisable(x)}
                    >
                        <span className={`_service-name txt-size-h8 robotoregular txt-color-black`}>{x.name} {x.domestic && <Tooltip title="Dịch vụ vận chuyển nội địa"><i className="fa-regular fa-location-check ml-2"></i></Tooltip>} {
                            x.serviceInfo && <Tooltip title={x.serviceInfo}>
                                <AntdIcon.InfoCircleOutlined className="txt-size-h8 mgl5" type="info-circle" />
                            </Tooltip>
                        }</span>
                    </Radio>
                    })
                }
            </Radio.Group>
            {
                _.sortBy(orderServices.filter(x => x.serviceGroup && x.serviceGroup.code === group.code), 'position').map(this.renderDescription)
            }
        </div>
    };

    /**
     * render checkbox group
     * @param group
     * @returns {*}
     */
    renderCheckboxGroup = (group) => {
        const {orderServices} = this.props;

        return <div className={`_service-checkbox-group-${group.code} checkbox-group vertical`} key={group.code}>
            {
                _.sortBy(orderServices.filter(x => x.serviceGroup && x.serviceGroup.code === group.code), 'position').map(x => this.renderCheckbox(x))
            }
            {
                _.sortBy(orderServices.filter(x => x.serviceGroup && x.serviceGroup.code === group.code), 'position').map(this.renderDescription)
            }
        </div>
    };

    /**
     * render group của service
     * @param group
     * @param index
     * @returns {*}
     */
    renderGroup = (group, index) => {
        const {t, orderServices = []} = this.props;
        if (orderServices.filter(x => x.serviceGroup && x.serviceGroup.code === group.code).length === 0) {
            return null;
        }

        return <div key={`${group.code}-index`} className={`_group-service-${group.code} group-service ${group.error ? 'error' : ''}`}>
            <Divider key={`${group.code}-index`}/>
            <div className="txt-color-black txt-size-h7 bold mgbt15">{group.name}</div>
            {
                group.error && <p className="txt-error mgt10">
                    <AntdIcon.ExclamationCircleOutlined type="exclamation-circle" className={'pdr5'}/> {t('orderServiceGroup.choose_error')}
                </p>
            }
            {
                group.single
                ?
                    group.required ?
                    this.renderRadioGroup(group) :
                    this.renderCheckboxGroup(group)
                :
                    this.renderCheckboxGroup(group)
            }
        </div>
    };

    /**
     * render group của service
     * @param group
     * @param index
     * @returns {*}
     */
    renderGroupError = (group, index) => {
        const {t} = this.props;
        return <div key={`${group.code}-index`} className={`_group-service-${group.code} group-service ${group.error ? 'error' : ''}`}>
            {
                group.error && <p className="txt-error mgt10">
                    <AntdIcon.ExclamationCircleOutlined type="exclamation-circle" className={'pdr5'}/> {t('orderServiceGroup.choose_error')} {group.name}
                </p>
            }
        </div>
    };

    /**
     * render khối dịch vụ liên quan theo nhóm dịch vụ
     * */
    renderGroupView = () => {
        const {checkedList} = this.state;
        // const {orderServiceGroups = []} = this.props;
        const listGroupChecked = _.groupBy(checkedList, 'serviceGroup.code');
        let elementResult = [];
        for (let p in listGroupChecked) {
            if (p !== 'undefined') {
                elementResult.push(<div className="services-group-row line-height167" key={p}>
                    <span className="txt-color-gray txt-size-h8 pdr5">{listGroupChecked[p][0].serviceGroup.name}:</span>
                    <span className="txt-color-black txt-size-h8">
                        {listGroupChecked[p].map((x, y) => <React.Fragment key={x.code}>
                            {x.name}
                            {y < listGroupChecked[p].length - 1 && <Divider type="vertical"/>}
                        </React.Fragment>)}
                    </span>
                </div>)
            }
        }

        return elementResult;
    };

    render() {
        const {isCollapse, checkedList} = this.state;
        const { t, orderServiceGroups = [], orderServices = [], validateServices, dataSource} = this.props;
        const listGroupChecked = _.groupBy(checkedList, 'serviceGroup.code');

        if (isCollapse) {
            return <div className={'servicesbox'}>
                <div className={'servicesbox__title sidebar__title'}>
                    <span className={'txt-size-h7 txt-color-black robotomedium'}>{t('orderServiceGroup.accompanied_service')}</span>
                </div>
                <div className={'servicesbox__notgroup servicesbox__content'}>
                    <div className={'box-checkbox-custom'}>
                        {
                            Array.isArray(listGroupChecked['undefined']) && <div className="services-group-row line-height167">
                                <span className="txt-color-gray txt-size-h8 pdr5">{t('orderServiceGroup.other_service')}:</span>
                                <span className="txt-color-black txt-size-h8">
                                {listGroupChecked['undefined'].map((x, y) => <React.Fragment key={x.code}>
                                    {x.name}
                                    {y < listGroupChecked['undefined'].length - 1 && <Divider type="vertical"/>}
                                </React.Fragment>)}
                            </span>
                            </div>
                        }
                        {
                            this.renderGroupView()
                        }
                        {
                            _.sortBy(checkedList, 'position').map(this.renderDescription)
                        }
                        {
                            _.sortBy(orderServiceGroups, 'position').map(this.renderGroupError)
                        }
                    </div>
                </div>
                <div className={'_btn-collapse-service btn__collapseservice txt-size-h8 cursor-pointer'} 
                    onClick={() => this.setState({isCollapse: !isCollapse})}>
                    <Typography.Link>
                        <AntdIcon.ArrowsAltOutlined type="arrows-alt" /> {t('orderServiceGroup.choose_service')}
                    </Typography.Link>
                </div>
            </div>
        }

        return (
            <div className={'servicesbox'}>
                <div className="servicesbox__title sidebar__title dpl-flex justify-content-between align-items-center">
                    <span className={'txt-size-h7 txt-color-black robotomedium'}>{t('orderServiceGroup.accompanied_service')}</span>
                </div>
                <div className={'servicesbox__notgroup servicesbox__content'}>
                    {
                        orderServices.filter(x => !x.serviceGroup).length > 0 && <div className={'box-checkbox-custom'}>
                            <div className="txt-color-black txt-size-h7 bold mgbt15">{t('orderServiceGroup.other_service')}</div>
                            {
                                _.sortBy(orderServices.filter(x => !x.serviceGroup), 'position').map(this.renderCheckbox)
                            }
                            {
                                _.sortBy(orderServices.filter(x => !x.serviceGroup), 'position').map(this.renderDescription)
                            }
                        </div>
                    }
                    {
                        _.sortBy(orderServiceGroups, 'position').map(this.renderGroup)
                    }
                    <Button
                        htmlType={'button'} block
                        className={'_btn-save-services mgt10 btn--blue'}
                        onClick={() => validateServices(dataSource)}
                    >
                        <AntdIcon.SaveOutlined type="save" /> {t('orderServiceGroup.save_service')}
                    </Button>
                </div>
                <div className={'_btn-collapse-service btn__collapseservice txt-size-h8 cursor-pointer'} 
                    onClick={() => this.setState({isCollapse: !isCollapse})}>
                    <Typography.Link>
                        <AntdIcon.ShrinkOutlined type="shrink" /> {t('orderServiceGroup.collapse')}
                    </Typography.Link>
                </div>
            </div>
        )
    }
}
