import PolimClient from '../utils/api/PolimClient'
import { LocalStore } from '../utils/LocalStore'

export default class BifinService {
    static currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}

    /**
     * Lấy danh sách tín dụng khách hàng cho YCTT
     * @param code
     * @param cb
     */
    static getPaymentCredits(code, cb) {
        PolimClient.request(
            {
                endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/payment/peer_payments/${code}/credits`,
                method: 'GET',
                headers: {
                    'X-Tenant': BifinService.currentProjectInfo?.code,
                },
            },
            cb
        )
    }

    static orderPay(orderCode, body, cb) {
        let pinToken = LocalStore.getInstance().read('pinToken') || ''

        let headers = {}

        if (pinToken) {
            headers['X-PIN-TOKEN'] = pinToken
        } else if (body.password) {
            headers['X-PIN'] = body.password
        }
        PolimClient.request(
            {
                endpoint: `/customer/orders/${orderCode}/pay`,
                method: 'POST',
                headers,
                body,
            },
            cb
        )
    }

    static shipmentsrPay(shipmentCode, body, cb) {
        let pinToken = LocalStore.getInstance().read('pinToken') || ''

        let headers = {}

        if (pinToken) {
            headers['X-PIN-TOKEN'] = pinToken
        } else if (body.password) {
            headers['X-PIN'] = body.password
        }
        PolimClient.request(
            {
                endpoint: `/customer/shipments/${shipmentCode}/settlement`,
                method: 'POST',
                headers,
                body,
            },
            cb
        )
    }

    /**
     * Lấy danh sách tín dụng khách hàng
     * @param code
     * @param cb
     */
    static getShipmentCredits(code, cb) {
        PolimClient.request(
            {
                endpoint: `/customer/shipments/${code}/credits`,
                method: 'GET',
            },
            cb
        )
    }

    /**
     * Lấy danh sách tín dụng khách hàng
     * @param code
     * @param cb
     */
    static getShipmentLoans(code, cb) {
        PolimClient.request(
            {
                endpoint: `/customer/shipments/${code}/loans`,
                method: 'GET',
            },
            cb
        )
    }

    /**
     *
     * @param code
     * @param cb
     */
    static getPaymentLoans(code, cb) {
        PolimClient.request(
            {
                endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/payment/peer_payments/${code}/loans`,
                method: 'GET',
                headers: {
                    'X-Tenant': BifinService.currentProjectInfo?.code,
                },
            },
            cb
        )
    }

    /**
     * get loans
     * @param code
     * @param cb
     */
    static getThirdPatiesLoans(query, cb) {
        PolimClient.request(
            {
                endpoint: `/customer/third-parties/shopkeeper/loans${query}`,
                method: 'GET',
            },
            cb
        )
    }

    /**
     * ask loanable
     * @param body
     * @param cb
     */
    static askBiffinToBorrow(body, cb) {
        PolimClient.request(
            {
                endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/customer/third-parties/shopkeeper/create`,
                method: 'POST',
                headers: {
                    'X-Tenant': BifinService.currentProjectInfo?.code,
                },
                body: body,
            },
            cb
        )
    }
}
