/* eslint-disable array-callback-return */
// @ts-nocheck
import { Avatar, Drawer, DrawerProps, Tag } from 'antd'
import React, { useMemo } from 'react'
import { formatCurrencyChina } from '../../../../utils/helper'
import {keys, sumBy } from 'lodash'

interface IProps extends DrawerProps {
    orderSeletecd: any
    t: any
}

const ShowProduct: React.FC<IProps> = ({ orderSeletecd, onClose, title, t, open, ...props }) => {
    const totalAmount = useMemo(() => {
        let totalPrice = 0
        keys(orderSeletecd?.products).map(key => {
            totalPrice += sumBy(orderSeletecd?.products[key], item => item?.actualPrice)
        })
        return totalPrice
    }, [orderSeletecd])

    return (
        <Drawer
            title={t('mainLayout.product')}
            className="drawer-order-product"
            placement="right"
            closable={true}
            onClose={onClose}
            width={1000}
            closeIcon={<i className="fa-solid fa-xmark text-grey-400 cursor-pointer" />}
            open={open}
            {...props}
        >
            <div className="mb-4">
                <span className="text-base text-grey-400">
                    {t('orderDetail.total_money')}:{' '}
                    <span className="text-blue-primary text-xl robotomedium">{formatCurrencyChina(totalAmount)}</span>
                </span>
            </div>
            {keys(orderSeletecd?.products).map(key => {
                if (orderSeletecd?.products[key]?.length > 1) {
                    return (
                        <div
                            className="bg-gray-100 rounded-2xl py-4 px-6 mb-4"
                            key={key}
                        >
                            {orderSeletecd?.products[key]?.map((i, index) => (
                                <div
                                    className="mb-3"
                                    key={index}
                                >
                                    {index === 0 &&
                                        (i.logistic ? (
                                            <Tag
                                                color="processing"
                                                className="px-4 rounded-xl robotomedium"
                                            >
                                                {t('orderTbg.billCode')}: {i?.logistic?.trackingCode}
                                            </Tag>
                                        ) : (
                                            <>
                                                <span className="robotoregular text-black-100">
                                                    {t('message.tracking_code_order')}
                                                </span>
                                                <div class="custom-line-dash-2 my-4"></div>
                                            </>
                                        ))}

                                    <div className="flex justify-between items-center mt-3">
                                        <div className="flex">
                                            <Avatar
                                                shape="square"
                                                className="rounded"
                                                src={i?.imageUrl}
                                                icon={<img src={require('../../../../resources/images/shoping.png')} alt='' />}
                                                size={40}
                                            />
                                            <div className="_content ml-4">
                                                <span className="robotoregular text-grey-200 block">{i?.title || '---'}</span>
                                            </div>
                                        </div>
                                        <div className="">
                                            <span className="robotoregular text-grey-400 block">
                                                {formatCurrencyChina(parseFloat(i?.actualPrice / i?.quantity))}
                                                {' x '} {i?.quantity}
                                            </span>
                                        </div>

                                        <div className="">
                                            <span className="robotomedium text-black-100">
                                                {formatCurrencyChina(parseFloat(i?.actualPrice))}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )
                } else {
                    return (
                        <div
                            className="bg-gray-100 rounded-2xl py-4 px-6 mb-4"
                            key={key}
                        >
                            <div className="mb-3">
                                {orderSeletecd?.products[key][0]?.logistic ? (
                                    <Tag
                                        color="processing"
                                        className="px-4 rounded-xl robotomedium"
                                    >
                                        {t('orderTbg.billCode')}: {orderSeletecd?.products[key][0]?.logistic?.trackingCode}
                                    </Tag>
                                ) : (
                                    <>
                                        <span className="robotoregular text-black-100">{t('message.tracking_code_order')}</span>
                                        <div className="custom-line-dash-2 my-4"></div>
                                    </>
                                )}

                                <div className="flex justify-between items-center mt-3">
                                    <div className="flex">
                                        <Avatar
                                            shape="square"
                                            className="rounded"
                                            src={orderSeletecd?.products[key][0]?.imageUrl}
                                            icon={<img src={require('../../../../resources/images/shoping.png')} alt='' />}
                                            size={40}
                                        />
                                        <div className="_content ml-4">
                                            <span className="robotoregular text-grey-200 block">
                                                {orderSeletecd?.products[key][0]?.title || '---'}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="">
                                        <span className="robotoregular text-grey-400 block">
                                            {formatCurrencyChina(
                                                parseFloat(
                                                    orderSeletecd?.products[key][0]?.actualPrice /
                                                        orderSeletecd?.products[key][0]?.quantity
                                                )
                                            )}
                                            {' x '} {orderSeletecd?.products[key][0]?.quantity}
                                        </span>
                                    </div>

                                    <div className="">
                                        <span className="robotomedium text-black-100">
                                            {formatCurrencyChina(parseFloat(orderSeletecd?.products[key][0]?.actualPrice))}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            })}
        </Drawer>
    )
}

export default ShowProduct
