import React from 'react'
import Modal from '../../components/commons/Modal'
import deliveryDeposit from '../../resources/images/deliveryDeposit.svg'
import { LocalStore } from '../../utils/LocalStore'
import { Button } from 'antd'

interface Props {
    isOpenModalDeliveryDeposit: boolean
    onClose: Function
    onSubmitCreateDelivery: () => void
    amountNeedPaid: number
    onOpenModalDeposit: () =>void
    t: any
}

const ModalDeliveryDeposit = (props: Props) => {
    const { isOpenModalDeliveryDeposit, onClose, onSubmitCreateDelivery, onOpenModalDeposit, t } = props
    const currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {}
    return (
        <>
            <Modal
                visible={isOpenModalDeliveryDeposit}
                onCancel={onClose}
                title={t('delivery.createDelivery')}
                width={560}
                listAction={
                    [
                        <Button
                            className={"text-blue-primary border-pattens-blue-100 bg-pattens-blue-100"}
                            type="primary"
                            onClick={onSubmitCreateDelivery}
                        >
                            {t('delivery.createDelivery')}
                        </Button>,
    
                        <Button
                            type="primary"
                            htmlType="submit"
                            size='middle'
                            onClick={onOpenModalDeposit}
                        >
                            {t('delivery.createDeposit')}
                        </Button>
                    ]
                }
            >
                <img
                    alt="deliveryDeposit"
                    src={deliveryDeposit}
                    className="w-[160px] block m-auto mt-6"
                />
                <span className='text-center mt-6 text-sm' dangerouslySetInnerHTML={{ __html: t('delivery.title', { username:currentLoggedUser?.username }) }} />
            </Modal>
           
        </>
    )
}

export default ModalDeliveryDeposit