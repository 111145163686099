import React from 'react'
import { Input, Modal, notification } from 'antd'
import * as AntdIcon from '@ant-design/icons'
import AntdIconSVG from '@ant-design/icons'

import { LocalStore } from '../../utils/LocalStore'
import UserService from '../../services/user.service'
import { ReactComponent as bgCoupon } from '../../resources/images/bg-coupon.svg'
import { ReactComponent as bgCouponEN } from '../../resources/images/bg-coupon-en.svg'
import { ReactComponent as iconCoupon } from '../../resources/images/icon_coupon.svg'
import moment from 'moment'

class Voucher extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            code: '',
            pin: '',
        }
    }

    componentDidMount() {
        let currentLanguage = LocalStore.getInstance().read('currentLanguage')

        this.setState({ currentLanguage: currentLanguage })
    }

    onSubmit = () => {
        const { isValid, submitting, code } = this.state
        const { orderDetail = {}, t, isShipment } = this.props
        if (!isValid) return
        this.setState({ submitting: true }, () => {
            if (submitting) return
            let body = {
                couponCode: code,
            }
            UserService.applyCoupon(orderDetail.code, body, isShipment, (err, res, headers) => {
                if (!err) {
                    this.setState({
                        submitting: false,
                        isValid: false,
                        validMess: res.description,
                        visible: false,
                    })
                    notification.success({ message: t('message.coupon_apply_success') })
                    setInterval(() => {
                        window.location.reload()
                    }, 1000)
                } else {
                    // notification.error({message: t(err.message)})
                    if (err.status === 400) {
                        this.setState({
                            submitting: false,
                            validMess: t(`message.${err.title}`),
                            validTo: null
                        })
                    } else {
                        this.setState({
                            submitting: false,
                        })
                        notification.error({ message: t(`message.${err.title}`)})
                    }
                }
            })
        })
    }

    validate = () => {
        const { code, validating } = this.state
        const { orderDetail = {}, t, isShipment } = this.props
        if (!code) return
        console.log(isShipment)
        this.setState({ validating: true }, () => {
            if (validating) return
            UserService.checkCouponPost(code, orderDetail.code, isShipment, (err, res, headers) => {
                if (!err && res.code) {
                    this.setState({
                        validating: false,
                        isValid: true,
                        validMess: res.description,
                        validTo: res.validTo,
                    })
                } else {
                    // notification.error({message: t(err.message)})
                    if (err.status === 400) {
                        this.setState({
                            validating: false,
                            validMess: t(`message.${err.title}`),
                            validTo: null
                        })
                    } else {
                        this.setState({
                            validating: false,
                            validMess: t('message.coupon_invalid_for_you'),
                            validTo: null
                        })
                    }
                }
            })
        })
    }

    showModal = () => {
        this.setState({
            visible: true,
            isValid: false,
            code: '',
            validTo: null,
            validMess: '',
        })
    }

    handleCancel = e => {
        this.setState({
            visible: false,
            isValid: false,
            code: '',
            validTo: null,
            validMess: '',
        })
    }

    renderModal = () => {
        const { visible, code, isValid, validating, validMess, currentLanguage = {}, validTo } = this.state
        const { t } = this.props
        return (
            <Modal
                className="modal-voucher"
                visible={visible}
                width={600}
                onCancel={this.handleCancel}
                footer={null}
                closable={false}
            >
                <div className="position-re">
                    <AntdIconSVG
                        className="bg-svg"
                        component={currentLanguage.languageCode === 'vi' ? bgCoupon : bgCouponEN}
                        style={{ fontSize: 700 }}
                    />
                    <div className="voucher-input-container">
                        {!!validTo && (
                            <div className="txt-color-green voucher-alert">
                                Hạn sử dụng đến {moment(validTo).format('HH:mm DD/MM/YYYY')}
                            </div>
                        )}
                        <Input
                            className="_input-coupon voucher-input"
                            placeholder={t('message.enter_coupon')}
                            onChange={e => this.setState({ code: e.target.value,isValid: false  })}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                        this.validate()
                                }
                            }}
                            value={code}
                        />
                        <div
                            className="btn-check-voucher"
                            onClick={this.validate}
                        >
                            {validating ? (
                                <AntdIcon.Loading3QuartersOutlined
                                    type="loading-3-quarters"
                                    spin
                                />
                            ) : (
                                <AntdIconSVG
                                    className=""
                                    component={iconCoupon}
                                    style={{ fontSize: 16 }}
                                />
                            )}
                        </div>
                        {!!validMess && (
                            <div className="txt-color-red voucher-mess line-clamp-2">
                                {validMess}
                            </div>
                        )}
                    </div>
                    <div className="voucher-btn-container">
                        <span
                            className="mgr10 txt-color-gray cursor-pointer"
                            onClick={this.handleCancel}
                        >
                            {t('button.cancel')}
                        </span>
                        <span
                            className={`btn-accept-voucher ${!!isValid ? 'active' : ''} cursor-pointer`}
                            onClick={!isValid ? null : this.onSubmit}
                        >
                            {t('button.use')}
                        </span>
                    </div>
                </div>
            </Modal>
        )
    }

    render() {
        const { t, orderDetail = {}, listStatuses = [] } = this.props
        const itemStatus = listStatuses.find(x => x.code === orderDetail.status) || {}
        return (
            <div className="dpl-flex justify-content-end pdbt30">
                {!!itemStatus.couponEnabled && <span className='cursor-pointer' onClick={this.showModal}>{t('button.coupon')}</span>}
                {this.renderModal()}
            </div>
        )
    }
}
export default Voucher
