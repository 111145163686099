/* eslint-disable array-callback-return */
import React from 'react'
import { Button, Checkbox, Col, Input, Modal, notification, Row, Select } from 'antd'
import * as AntdIcon from '@ant-design/icons'
import lodash from 'lodash'
import * as Constants from '../../constants/config'
import ReactDropzone from '../../components/ReactDropzone'
import { parseQueryStringToObject, quantityFormat } from '../../utils/stringUtils'
import { getUrlQueryString } from '../../utils/helper'
import { LocalStore } from '../../utils/LocalStore'
// import lang from "../../resources/localization/Localization";
import TicketService from '../../services/tickets.service'
import { withNamespaces } from 'react-i18next'

class FormCreateTicket extends React.Component {
    constructor(props) {
        super(props)
        this.buttonSubmit = null
        this.state = {
            isShowReason: false,
            isCreating: false,
            isShowSolution: false,
            isLoadingReason: false,
            orderCode: '',
            productCode: '',
            nameComplain: '',
            notReceived: '',
            reasonDetail: [],
            description: '',
            images: [],
            solution: '',
            moneyCount: '',
            isLoading: false,
            reasonList: [],
            reasonOrderList: [],
            reasonProductList: [],
            reasonType: '',
            reasonSelected: null,
            itemsReasonSelected: [],
            productReasonDetail: [],
            solutions: [],
            requireAttachment: false,
            reasonDetailOther: false,
            solutionSelected: null,
            listTicketRelate: [],
            validateErrors: {},
            showProgressBar: false,
            openModal: false,
            isShipment: false,
        }
    }

    componentWillMount() {
        window.onbeforeunload = null

        let queryObj = parseQueryStringToObject(getUrlQueryString())

        this.addCodeFromUrl(queryObj)

        if (queryObj.isShipment) {
            this.setState({
                isShipment: true,
            })
        }
        TicketService.getReasonProject(
            queryObj.isShipment ? Constants.TYPE_REASON_SHIPMENT : Constants.TYPE_REASON_ORDER,
            queryObj.isShipment,
            (err, res) => {
                if (err === null) {
                    this.setState({ reasonOrderList: res })
                }
            }
        )
        TicketService.getReasonProject(
            queryObj.isShipment ? Constants.TYPE_REASON_PRODUCT_SHIPMENT : Constants.TYPE_REASON_PRODUCT_ORDER,
            queryObj.isShipment,
            (err, res) => {
                if (err === null) {
                    this.setState({ reasonProductList: res })
                }
            }
        )
    }

    componentDidMount() {
        const queryObj = parseQueryStringToObject(getUrlQueryString())
        this.setState({ showProgressBar: true }, () => {
            this.loadSolutions(queryObj.isShipment)
            // this.getListCustomers()
        })
    }

    /**
     * call API lấy danh sách phương án xử lý
     */
    loadSolutions = isShipment => {
        TicketService.getSolution(isShipment, (err, res) => {
            if (err === null) {
                const filterSolution = res.filter(s => (s.subject === isShipment ? 'shipment' : 'order'))
                this.setState({ solutions: filterSolution, showProgressBar: false })
            } else {
                this.setState({ showProgressBar: false })
            }
        })
    }

    /**
     * validate dữ liệu phương án xử lý
     * @returns {boolean|string}
     */
    validateSolution = () => {
        return !this.state.solutionSelected
    }

    /**
     * validate dữ liệu lý do khiếu nại
     * @returns {boolean|string|*}
     */
    validateReason = () => {
        if (!this.state.reasonSelected) return true
        else {
            if (
                ((this.state.reasonSelected.code === 'not_received' ||
                    this.state.reasonSelected.code === 'shipping_not_received') &&
                    (!this.state.notReceived || (this.state.notReceived && this.state.notReceived.length > 4))) ||
                (this.state.reasonSelected.code === 'other' && !this.state.description) ||
                (this.state.reasonSelected.code === 'faulty_product' && this.state.images.length === 0) ||
                (this.state.reasonSelected.code === 'shipping_faulty_product' && this.state.images.length === 0)
            ) {
                return true
            }
        }
        return false
    }

    /**
     * validate số tiền đề xuất
     * @returns {boolean|string|*}
     */
    validateMoney = () => {
        const { solutionSelected, moneyCount } = this.state
        if (solutionSelected && solutionSelected.code === 'compensate' && moneyCount !== '') {
            return false
        } else if (solutionSelected && solutionSelected.code === 'return') {
            return false
        } else if (solutionSelected && solutionSelected.code === 'redelivery') {
            return false
        } else if (solutionSelected && solutionSelected.code !== '') {
            return false
        }
        return true
    }

    /**
     * kiểm tra disable nút tạo nếu chưa nhập các trường bắt buộc
     */
    checkDisable = () => {
        return (
            this.state.isLoading ||
            !this.state.orderCode ||
            this.validateSolution() ||
            this.validateMoney() ||
            this.validateReason() ||
            (this.state.productCode && this.state.listTicketRelate.length >= 2) ||
            (this.state.reasonDetailOther && !this.state.description)
        )
    }

    /**
     * call API lấy danh sách chi tiết lý do khiếu nại
     */
    loadReasonDetail = id => {
        TicketService.getReasonDetail(id, (err, res) => {
            if (err === null) {
                let data = res.data
                data.map(reasonDetail => {
                    this.state.listTicketRelate.map(ticket => {
                        if (Array.isArray(ticket.reasonData.reasonDetail)) {
                            ticket.reasonData.reasonDetail.map(reason => {
                                if (reason.id === reasonDetail.id) {
                                    reasonDetail.isChecked = true
                                    reasonDetail.isDisable = true
                                    reasonDetail.ticket = ticket
                                }
                            })
                        }
                    })
                })
                this.setState({ productReasonDetail: data })
            }
        })
    }

    /**
     * xử lý với param Obj để lấy orderCode và productCode
     * @param queryObj
     */
    addCodeFromUrl = queryObj => {
        // xử lý để lấy orderCode và productCode từ url
        if (queryObj) {
            if (queryObj.orderCode) {
                if (queryObj.productCode) {
                    this.setState(
                        {
                            orderCode: queryObj.orderCode,
                            productCode: queryObj.productCode,
                            reasonType: Constants.TYPE_REASON_PRODUCT,
                            reasonList: [],
                            reasonSelected: null,
                        },
                        () => {
                            this.runValidate()
                            this.generateName()
                            // Call API lấy danh sách ticket theo mã sản phẩm
                            // this.loadTicketByProduct(queryObj.productCode);
                        }
                    )
                } else {
                    this.setState(
                        {
                            orderCode: queryObj.orderCode,
                        },
                        () => {
                            this.runValidate()
                            this.generateName()
                            // call API lấy danh sách lý do khiếu nại theo mã đơn
                            if (this.state.reasonType !== Constants.TYPE_REASON_ORDER) {
                                if (this.state.productCode === '') {
                                    this.setState({
                                        reasonType: Constants.TYPE_REASON_ORDER,
                                        reasonList: [],
                                        reasonSelected: null,
                                    })
                                }
                            }
                        }
                    )
                }
            }
        }
    }

    /**
     * kiểm tra nếu chọn phương án xử lý khác "bồi thường" thì chỉ có thể xem
     **/
    checkReadOnly = () => {
        const { solutionSelected } = this.state
        return solutionSelected && solutionSelected.code === 'compensate'
    }

    /**
     * enter thì submit
     * @param e
     */
    onKeyUp = e => {
        if (e.keyCode === 13) {
            this.buttonSubmit.click()
        }
    }

    /**
     * Hiển thị lỗi khi có
     */
    showTemplateError = prop => {
        let validateErrors = this.state.validateErrors
        if (this.hasErrors(prop)) {
            return (
                <label
                    className={'_ticket-create-' + [prop] + ' txt-color-pink txt-size-h8 utmhelve-regular line-height134 mgt5'}
                >
                    {validateErrors[prop]}
                </label>
            )
        }
        return null
    }

    hasErrors = prop => {
        let validateErrors = this.state.validateErrors
        return validateErrors.hasOwnProperty(prop)
    }

    /**
     * Xóa error
     * @param prop
     */
    clearErrors = prop => {
        if (!prop) {
            this.setState({ validateErrors: {} })
        } else {
            let validateErrors = this.state.validateErrors
            delete validateErrors[prop]
            this.setState({ validateErrors })
        }
    }

    /**
     * Thay đổi giá trị input
     * @param value
     * @param prop
     */
    onInputChange = (value, prop) => {
        this.clearErrors(prop)
        // if (value !== '' && value.trim() === '') return;
        if (prop === 'moneyCount' || prop === 'notReceived') {
            if (value.indexOf('0') === 0 && value.length > 1) return
        }
        if (prop === 'orderCode' || prop === 'productCode') {
            let validateErrors = this.state.validateErrors
            if (validateErrors['notReceived']) {
                delete validateErrors['notReceived']
            }
            this.setState({ validateErrors })

            value = value.toUpperCase()
            value = value.replace(/\s/g, '')
            this.setState({
                productReasonDetail: [],
                reasonSelected: null,
                notReceived: '',
                itemsReasonSelected: [],
            })
            if (prop === 'productCode') this.setState({ listTicketRelate: [] })
            if (value === '') {
                if ((prop === 'orderCode' && this.state.productCode === '') || prop === 'productCode') {
                    this.setState({ reasonSelected: null, notReceived: '', listTicketRelate: [] })
                    if (prop === 'productCode') {
                        this.setState({ reasonType: Constants.TYPE_REASON_ORDER })
                        // this.loadReason(Constants.TYPE_REASON_ORDER);
                    }
                }
            } else {
                if (!this.state.isLoadingReason) {
                    if (prop === 'orderCode' && this.state.reasonList.length === 0) {
                        this.setState({ reasonType: Constants.TYPE_REASON_ORDER })
                        // this.loadReason(Constants.TYPE_REASON_ORDER);
                    } else if (
                        prop === 'productCode' &&
                        (this.state.reasonType === Constants.TYPE_REASON_ORDER || this.state.reasonList.length === 0)
                    ) {
                        this.setState({ reasonType: Constants.TYPE_REASON_PRODUCT, reasonList: [] })
                        // this.loadReason(Constants.TYPE_REASON_PRODUCT);
                    }
                }
            }
        }
        if (prop !== 'description') value = value.trim()
        this.setState({ [prop]: value })
    }

    generateName() {
        const { t } = this.props
        this.setState({
            nameComplain:
                (this.state.orderCode && t('ticket_add.order')) +
                ' ' +
                this.state.orderCode.toUpperCase().trim() +
                (this.state.orderCode && this.state.productCode && ',') +
                ' ' +
                (this.state.productCode && t('ticket_add.product')) +
                ' ' +
                this.state.productCode.toUpperCase().trim(),
        })
    }

    setError = (prop, value) => {
        let validateErrors = this.state.validateErrors
        validateErrors[prop] = value
        this.setState({ validateErrors })
    }

    runValidate = () => {
        const { orderCode, reasonSelected, notReceived, solutionSelected, moneyCount, description, reasonDetailOther } =
            this.state

        const { t } = this.props
        if (!orderCode) {
            this.setError('orderCode', t('message.required'))
        }
        if (
            reasonSelected &&
            (reasonSelected.code === 'not_received' || reasonSelected.code === 'shipping_not_received') &&
            !notReceived
        ) {
            this.setError('notReceived', t('message.required'))
        } else {
            let notReceive = notReceived !== '' && parseInt(notReceived, 10)
            if (notReceive > 9999) {
                this.setError('notReceive', t('ticket_add.missing_number_type'))
            }
        }
        if (solutionSelected && solutionSelected.code === 'compensate' && !moneyCount) {
            this.setError('moneyCount', t('message.required'))
        } else {
            let moneyCount = parseInt(this.state.moneyCount, 10)
            if (moneyCount > 9999999999) {
                this.setError('moneyCount', t('ticket_add.count_money_type'))
            }
        }
        if ((reasonSelected && reasonSelected.code === 'other' && !description) || (reasonDetailOther && !description)) {
            this.setError('description', t('message.required'))
        }

        if (description && description.length > 1000) {
            this.setError('description', t('message.validate_characters'))
        }
    }

    /**
     * Xử lý khi click ra ngoài mã đơn
     */
    onBlurOrderCode = e => {
        this.runValidate()

        this.generateName()
        // call API lấy danh sách lý do khiếu nại theo mã đơn
        if (e.target.value === '') return
        if (this.state.reasonType !== Constants.TYPE_REASON_ORDER) {
            if (this.state.productCode === '') {
                this.setState({
                    reasonType: Constants.TYPE_REASON_ORDER,
                    reasonList: [],
                    reasonSelected: null,
                })
                // this.loadReason(Constants.TYPE_REASON_ORDER);
            }
        }
    }

    /**
     * Xử lý khi click ra ngoài nhập số lượng còn thiếu, số tiền, description
     */
    onBlurInput = () => {
        this.runValidate()
    }

    onChangeSolution = value => {
        let validateErrors = this.state.validateErrors
        if (value !== '') {
            this.setState({
                solutionSelected: {
                    code: value,
                },
            })
        }

        if (value !== 'compensate') {
            if (validateErrors['moneyCount']) {
                delete validateErrors['moneyCount']
            }
            this.setState({ validateErrors, moneyCount: '' })
        }
    }

    /**
     * Thêm file (ảnh) vào ticket
     * @param files
     */
    addFiles = files => {
        let images = []
        const { t } = this.props
        let showError = false
        files.map(file => {
            // Hiển thị thông báo lỗi khi định dạng không phải ảnh hoặc ảnh lớn hơn 10MB
            if (file.type.indexOf('image') === -1) {
                showError = true
            } else if (file.size < 11534336) {
                images.push(file)
            } else {
                showError = true
            }
        })

        this.setState({ images: images })
        if (showError)
            notification['error']({
                message: t('error.upload_image'),
            })
    }

    /**
     * Thêm file (ảnh) vào ticket
     * @param index
     */

    addHide = index => {
        let hide = this.state.hide
        hide.push(index)
        this.setState({ hide: hide })
    }

    /**
     * Thêm file (ảnh) vào ticket
     * @param index
     */

    removeHide = index => {
        let hide = this.state.hide
        var key = hide.indexOf(index) // Let's say it's Bob.
        delete hide[key]
        this.setState({ hide: hide })
    }

    /**
     * Tạo khiếu nại
     */
    createTicket = async () => {
        const { t } = this.props
        if (this.state.productCode && this.state.listTicketRelate.length >= 2) {
            notification['error']({
                message: t('error.product_code') + ' ' + this.state.productCode,
            })
            return
        }

        if (this.state.isLoading === true) return
        if (this.checkDisable()) return

        // Kiểm tra nếu đang tạo bản ghi thì không cảnh báo chuyển trang
        await this.setState({
            isCreating: true,
            isLoading: true,
            showProgressBar: true,
        })

        let payload = {
            name: this.state.nameComplain,
            description: this.state.description,
            relatedProduct: this.state.productCode,
            relatedOrder: this.state.orderCode,
            reasonCode: this.state.reasonSelected.code,
            solutionCode: this.state.solutionSelected.code,
            suggest: this.state.moneyCount ? this.state.moneyCount : null,
            reasonData: {
                reasonDetail: this.state.reasonDetail,
            },
            notReceived: this.state.notReceived,
            ticketType: this.state.isShipment ? Constants.TYPE_REASON_SHIPMENT : Constants.TYPE_REASON_ORDER,
        }

        // nếu có selectedCustomer thì add thêm vào body
        if (this.state.selectedCustomer && this.state.selectedCustomer.username) {
            payload.owner = this.state.selectedCustomer.username
        }

        // console.log(payload);
        let formData = new FormData()
        this.state.images.length > 0 &&
            this.state.images.map(file => {
                formData.append('attachments', new Blob([file]), file.name)
            })
        formData.append('claim', new Blob([JSON.stringify(payload)], { type: 'application/json' }))
        let token = LocalStore.getInstance().read('loginSession')
        let res

        try {
            res = await fetch((process.env.REACT_APP_API_URL || window.location.hostname) + '/customer/canines/claims', {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + token.id,
                },
                body: formData,
            })
        } catch (err) {
            let error = new Error(t('error.error'))
            error.statusCode = 'timeout'
            throw error
        }
        const dataResponse = await res.json()
        if (!res.ok) {
            if (dataResponse.title === 'order_not_found' || dataResponse.title === 'shipment_not_found') {
                notification['error']({
                    message: t('message.order_not_found'),
                })
            } else if (dataResponse.title === 'product_not_found') {
                notification['error']({
                    message: t('message.product_not_found'),
                })
            } else if (dataResponse.title === 'user_not_found') {
                notification['error']({
                    message: t('message.user_not_found'),
                })
            } else if (
                dataResponse.title === 'shipment_state_negative_end' ||
                dataResponse.title === 'order_state_negative_end'
            ) {
                notification['error']({
                    message: t('message.shipment_state_negative_end'),
                })
            } else if (dataResponse.title === 'invalid_not_received_quantity') {
                const detail = dataResponse?.detail
                const charIndex = detail.indexOf(':')
                const substring = charIndex >= 0 && detail.substring(charIndex + 1)
                notification['error']({
                    message: `${t('message.invalid_not_received_quantity')} ${substring}`,
                })
            } else {
                notification['error']({
                    message: dataResponse.detail,
                })
            }

            this.setState({ showProgressBar: false, isLoading: false, isCreating: false })
        } else {
            notification['success']({
                message: t('message.success'),
            })
            this.setState({ showProgressBar: false, isCreating: false })
            if (dataResponse.id) {
                this.props.history.push({
                    pathname: `/tickets/${dataResponse.code}`,
                })
            }
        }
    }

    onChangeSelect = value => {
        let validateErrors = this.state.validateErrors
        if (value !== '') {
            this.setState({
                reasonSelected: {
                    code: value,
                },
            })
        }

        if (value !== 'other' || value !== 'faulty_product') {
            if (validateErrors['description']) {
                delete validateErrors['description']
            }
            this.setState({ validateErrors, description: '' })
        }
    }

    onFilterReason() {
        return this.state.productCode ? this.state.reasonProductList : this.state.orderCode ? this.state.reasonOrderList : []
    }

    confirmModal = () => {
        this.props.history.goBack()
    }

    updateModal = () => {
        this.setState({
            openModal: true,
        })
    }

    onChangeIsShipment = event => {
        this.setState(
            {
                isShipment: event.target.checked,
                solutionSelected: null,
            },
            () => {
                this.setState({ reasonSelected: null, notReceived: '', listTicketRelate: [], productCode: '' })
                TicketService.getReasonProject(
                    event.target.checked ? Constants.TYPE_REASON_SHIPMENT : Constants.TYPE_REASON_ORDER,
                    event.target.checked,
                    (err, res) => {
                        if (err === null) {
                            this.setState({ reasonOrderList: res })
                        }
                    }
                )
                TicketService.getReasonProject(
                    event.target.checked ? Constants.TYPE_REASON_PRODUCT_SHIPMENT : Constants.TYPE_REASON_PRODUCT_ORDER,
                    event.target.checked,
                    (err, res) => {
                        if (err === null) {
                            this.setState({ reasonProductList: res })
                        }
                    }
                )
                this.loadSolutions(event.target.checked)
            }
        )
    }

    renderModalConfirmPin = () => {
        const { t } = this.props
        return (
            <Modal
                title={t('modal.navigate')}
                visible={this.state.openModal}
                onCancel={() =>
                    this.setState({
                        openModal: false,
                    })
                }
                onOk={this.confirmModal}
                okText={t('modal.confirm')}
                cancelText={t('modal.cancel')}
                okButtonProps={{ className: '_btn-submit-confirm' }}
                cancelButtonProps={{ className: '_btn-cancel-confirm' }}
            >
                <p className="mgbt5">{t('modal.navigate_confirm')}</p>
            </Modal>
        )
    }

    render() {
        const {
            reasonSelected,
            solutions,
            moneyCount,
            isShowReason,
            isShowSolution,
            solutionSelected,
            requireAttachment,
            isCreating,
            isShipment,
        } = this.state
        const reasonList = this.onFilterReason()
        const { t } = this.props

        return (
            <div className={'container-common pdbt30 mgr20'}>
                <div className="pdbt10 dpl-flex">
                    <div
                        onClick={this.updateModal}
                        className={'_orders cursor-pointer'}
                    >
                        <AntdIcon.ArrowLeftOutlined
                            className="txt-color-blue"
                            type="arrow-left"
                        />
                        <span className={' pdl10 txt-color-blue txt-size-h8 robotoregular'}>{t('login.go_back')}</span>
                    </div>
                </div>
                <div className="bg-color-white mgbt20 pd20">
                    <Row className={'dpl-flex'}>
                        <Col
                            span={16}
                            className="pdr25 bd-color-gray border-right-1x"
                        >
                            <Row className={'dpl-flex'}>
                                <Col
                                    span={8}
                                    className="pdr10"
                                >
                                    <label className="txt-color-black txt-size-h4 robotomedium mgbt5">
                                        {t('ticket_add.create_claims')}{' '}
                                        <Checkbox
                                            className="mgl10"
                                            checked={isShipment}
                                            onChange={this.onChangeIsShipment}
                                        >
                                            {t('menu.shipments')}
                                        </Checkbox>
                                    </label>
                                </Col>
                            </Row>

                            <Row className={'pdt15 pdbt15 dpl-flex pdbt25'}>
                                <Col
                                    span={12}
                                    className="pdr10"
                                >
                                    <label className="_order_code txt-color-black txt-size-h7 robotoregular dpl-block mgbt5">
                                        {t('ticket_add.order_code')} <span className="txt-color-pink">*</span>
                                    </label>
                                    <Input
                                        placeholder={t('ticket_add.order_code_type')}
                                        autoFocus={true}
                                        className={'_input_orderCode _search-box-input-query'}
                                        onChange={e => this.onInputChange(e.target.value, 'orderCode')}
                                        onBlur={this.onBlurOrderCode}
                                        value={this.state.orderCode}
                                    />
                                    {this.showTemplateError('orderCode')}
                                </Col>
                                {
                                    <Col
                                        span={12}
                                        className="pdl10"
                                    >
                                        <label className="_product_code txt-color-black txt-size-h7 robotoregular dpl-block mgbt5">
                                            {t('ticket_add.product_code')}
                                        </label>
                                        <Input
                                            placeholder={t('ticket_add.product_code_type')}
                                            className={'_input_product_code _search-box-input-query'}
                                            onChange={e => this.onInputChange(e.target.value, 'productCode')}
                                            onBlur={this.onBlurOrderCode}
                                            value={this.state.productCode}
                                        />
                                    </Col>
                                }
                            </Row>
                            <div className={'bg-color-gray pdl20 pdr20 pdbt30'}>
                                <Row className={'pdt15 dpl-flex pdbt25'}>
                                    <Col
                                        span={8}
                                        className="pdr10"
                                    >
                                        <label className="_claim_reason txt-color-black txt-size-h7 robotoregular dpl-block  mgbt5">
                                            {t('ticket_add.claim_reason')} <span className="txt-color-pink">*</span>
                                        </label>
                                        {
                                            <Select
                                                placeholder={t('ticket_add.claim_reason')}
                                                dropdownClassName="_gender-overlay"
                                                className={'_filterSelectReason width100'}
                                                onChange={value => this.onChangeSelect(value)}
                                                value={reasonSelected ? reasonSelected.code : []}
                                            >
                                                {Array.isArray(reasonList) &&
                                                    reasonList.map((item, index) => {
                                                        return (
                                                            <Select.Option
                                                                className="_option-claim-reason"
                                                                key={item.id}
                                                                value={item.code}
                                                            >
                                                                {item.name}
                                                            </Select.Option>
                                                        )
                                                    })}
                                            </Select>
                                        }
                                        {isShowReason && !reasonSelected && (
                                            <div className="txt-color-pink txt-size-h7 utmhelve-regular line-height134 mgt5 _create-ticket-error-reason">
                                                {t('message.claim_reason')}
                                            </div>
                                        )}
                                    </Col>

                                    {reasonSelected &&
                                        (reasonSelected.code === 'not_received' ||
                                            reasonSelected.code === 'shipping_not_received') && (
                                            <React.Fragment>
                                                <Col
                                                    span={8}
                                                    className="pdl10"
                                                >
                                                    <label className="_missing_number txt-color-black txt-size-h7 robotoregular dpl-block mgbt5">
                                                        {t('ticket_add.missing_number')}
                                                    </label>
                                                    <div className="position-re">
                                                        <Input
                                                            type="text"
                                                            name="notReceived"
                                                            placeholder={t('ticket_add.missing_number_type')}
                                                            className="width50 pdl10 _ticket-commodity-count"
                                                            value={
                                                                this.state.notReceived
                                                                    ? quantityFormat(this.state.notReceived)
                                                                    : ''
                                                            }
                                                            onKeyUp={this.onKeyUp}
                                                            onBlur={this.onBlurInput}
                                                            onChange={e =>
                                                                this.onInputChange(
                                                                    e.target.value.replace(/\D/gm, ''),
                                                                    'notReceived'
                                                                )
                                                            }
                                                        />
                                                        {this.showTemplateError('notReceived')}
                                                    </div>
                                                </Col>
                                                <Col
                                                    span={8}
                                                    className="pdl10"
                                                >
                                                    <span className="_entirely_missing txt-color-gray txt-size-h7 utmhelve-regular mgl10 pdt30 dpl-il-block">
                                                        {t('ticket_add.entirely_missing')}
                                                    </span>
                                                </Col>
                                            </React.Fragment>
                                        )}
                                </Row>
                                <Row className={'pdbt15 dpl-flex pdbt25'}>
                                    <Col span={24}>
                                        <label className="_ticket-description txt-color-black txt-size-h7 robotoregular dpl-block mgbt5">
                                            {t('ticket_add.descriptions')} {t('ticket_add.description_required')}
                                        </label>
                                        <Input.TextArea
                                            className="width100 _ticket-create-description height150"
                                            placeholder={t('ticket_add.descriptions_type')}
                                            name="description"
                                            maxLength={1000}
                                            value={this.state.description}
                                            onChange={e => this.onInputChange(e.target.value, 'description')}
                                            onKeyUp={this.onKeyUp}
                                            onBlur={this.onBlurInput}
                                            height={200}
                                        />
                                        {this.showTemplateError('description')}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col
                            span={8}
                            className="pdl25"
                        >
                            <Row className={'pdt40 pdbt15 dpl-flex'}>
                                <Col
                                    span={24}
                                    className="pdr10 pdt2"
                                >
                                    <label className="_solutions txt-color-black txt-size-h7 robotoregular dpl-block mgbt5">
                                        {t('ticket_add.solutions')} <span className="txt-color-pink">*</span>
                                    </label>
                                    <div className="position-re">
                                        {
                                            <Select
                                                placeholder={t('ticket_add.solutions_select')}
                                                className={'_filterSelectSelectSolution width100'}
                                                onChange={value => this.onChangeSolution(value)}
                                                value={solutionSelected ? solutionSelected.code : null}
                                            >
                                                {Array.isArray(solutions) &&
                                                    solutions.map((item, index) => {
                                                        return (
                                                            <Select.Option
                                                                className="_option-solution"
                                                                key={item.id}
                                                                value={item.code}
                                                            >
                                                                {item.name}
                                                            </Select.Option>
                                                        )
                                                    })}
                                            </Select>
                                        }
                                    </div>
                                    {isShowSolution && !solutionSelected && (
                                        <div className="txt-color-pink txt-size-h7 utmhelve-regular line-height134 mgt5 _ticket-create-error-solution">
                                            {t('message.solutions_select')}
                                        </div>
                                    )}
                                </Col>
                            </Row>

                            {lodash.get(solutionSelected, 'code') === 'compensate' && (
                                <Row className={'pdt15 pdbt15 dpl-flex pdbt25'}>
                                    <Col span={24}>
                                        <label className="_count_money txt-color-black txt-size-h7 robotoregular dpl-block mgbt5">
                                            {t('ticket_add.count_money')}{' '}
                                            {solutionSelected && solutionSelected.code === 'compensate' && (
                                                <span className="txt-color-pink">*</span>
                                            )}
                                        </label>
                                        <Input
                                            type="text"
                                            name="moneyCount"
                                            placeholder={t('ticket_add.count_money_type')}
                                            className={'width100 pdl10 _ticket-select-solution-value '}
                                            value={moneyCount ? quantityFormat(moneyCount) : ''}
                                            onChange={e => this.onInputChange(e.target.value.replace(/\D/g, ''), 'moneyCount')}
                                            onKeyUp={this.onKeyUp}
                                            onBlur={this.onBlurInput}
                                        />
                                    </Col>
                                </Row>
                            )}

                            <Row className={'pdt15 pdbt15 dpl-flex pdbt25'}>
                                <Col span={24}>
                                    <label className="_upload_picture txt-color-black txt-size-h7 robotoregular dpl-block mgbt5">
                                        {t('ticket_add.upload_picture')} {t('ticket_add.product_error_require')}
                                    </label>
                                    <div>
                                        <label className="txt-color-gray txt-size-h8 line-height134 robotoregular dpl-block mgbt20 _ticket-images-warning">
                                            * {t('ticket_add.picture_size')}.
                                        </label>
                                    </div>

                                    <div className="mgbt20">
                                        <Row>
                                            <Col span={24}>
                                                <ReactDropzone
                                                    className={`${this.state.focusDropzone ? 'ibizan-focus' : ''}`}
                                                    inputProps={{
                                                        onFocus: e => {
                                                            this.setState({
                                                                focusDropzone: true,
                                                            })
                                                        },
                                                        onBlur: e => {
                                                            this.setState({
                                                                focusDropzone: false,
                                                            })
                                                            // this.buttonSubmit.focus();
                                                        },
                                                    }}
                                                    addFiles={this.addFiles}
                                                    files={this.state.images}
                                                    addHide={this.addHide}
                                                    hide={this.state.hide}
                                                    removeHide={this.removeHide}
                                                />
                                            </Col>
                                        </Row>
                                    </div>

                                    {requireAttachment && this.state.images.length === 0 && (
                                        <p className="txt-color-red2 txt-size-h7 line-height134 utmhelve-regular mgbt20 _ticket-images-warning">
                                            * {t('message.picture')}
                                        </p>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className={'pdt15 dpl-flex txt-right'}>
                        <Col
                            span={24}
                            className="pdr10"
                        >
                            <Button
                                className={'_create_ticket bg-color-blue4 txt-color-white txt-uppercase'}
                                type="button"
                                disabled={this.checkDisable()}
                                loading={isCreating}
                                ref={ref => (this.buttonSubmit = ref)}
                                onClick={() => this.createTicket()}
                            >
                                {t('ticket_add.create_claims')}
                            </Button>
                        </Col>
                    </Row>
                </div>

                {this.renderModalConfirmPin()}
            </div>
        )
    }
}

export default withNamespaces()(FormCreateTicket)
