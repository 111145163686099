import { UserOutlined } from '@ant-design/icons'
import { Avatar, Checkbox, Col, Divider, Image, Row } from 'antd'
import React, { useState } from 'react'
import lodash, { sumBy } from 'lodash'
import ProductAtrribute from './ProductAtrribute'
import { formatCurrencyChina } from '../../../../utils/helper'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
interface CartListItemProps {
    totalItem: number
    t: any
    loading: boolean
    cartListItem: any
    checkedItems: any
    getListCartItem: (loading: boolean) => void
    className: string
    handleCheckboxChange: any
    handleCheckAllChange: any
    handleCheckAllMerchantsChange: any
    checkAllMerchants: any
    loadingListItem: boolean
    updateTotalPriceWhenChangeQuantiy: (itemId: string, skuId: string, quantity: any) => void
    totalProductCart: number
    currentItemId: any
    onChangePage: (page: any, pageSize: any) => void
    pagination: any
    filter: any
    onClearParams: () => void
}

// Rest of the interfaces

const CartListItem: React.FC<CartListItemProps> = props => {
    const {
        t,
        getListCartItem,
        cartListItem,
        loadingListItem,
        loading,
        checkedItems,
        className,
        handleCheckboxChange,
        handleCheckAllChange,
        handleCheckAllMerchantsChange,
        checkAllMerchants,
        totalProductCart,
        updateTotalPriceWhenChangeQuantiy,
        currentItemId,
        onChangePage,
        filter,
        pagination,
        onClearParams,
    } = props

    const [expandedItems, setExpandedItems] = useState<{ [key: string]: boolean }>({})

    const toggleExpandItem = (itemId: string) => {
        setExpandedItems(prevExpandedItems => ({
            ...prevExpandedItems,
            [itemId]: !prevExpandedItems[itemId],
        }))
    }

    const renderChildrenItem = (cartItems: any, merchant: any) => {
        return (
            <>
                {cartItems?.map((item: any) => {
                    const productImage = lodash.get(item, 'productMapping.image')?.replace('[', '')?.replace(']', '')
                    const productName = lodash.get(item, 'productMapping.name')
                    const productMapping = lodash.get(item, 'productMapping')
                    const skuViews = lodash.get(item, 'skuViews')
                    const skuViewsSort = lodash.sortBy(skuViews, item => new Date(item.createdAt)).reverse()
                    const key = lodash.get(item, 'productMapping.id')

                    return (
                        <React.Fragment key={key}>
                            <Col span={24}>
                                {skuViewsSort?.map((item: any, index: number) => {
                                    // return items?.map((item: any) => {
                                    const itemId = lodash.get(item, 'productMappingId')
                                    const skuId = lodash.get(item, 'skuMappingId')
                                    const image = lodash.get(item, 'image')
                                    const variantProperties = lodash.get(item, 'variantProperties')
                                    const price = lodash.get(item, 'price')
                                    const quantity = lodash.get(item, 'quantity')
                                    const totalPrice = quantity * price
                                    const FornatTotalPrice = Number(totalPrice.toFixed(2))
                                    const inventory = lodash.get(item, 'inventory')

                                    return (
                                        <React.Fragment key={index}>
                                            <ProductAtrribute
                                                itemSku={item}
                                                filter={filter}
                                                merchant={merchant}
                                                pagination={pagination}
                                                onClearParams={onClearParams}
                                                productMapping={productMapping}
                                                handleCheckboxChange={handleCheckboxChange}
                                                updateTotalPriceWhenChangeQuantiy={updateTotalPriceWhenChangeQuantiy}
                                                getListCartItem={getListCartItem}
                                                onChangePage={onChangePage}
                                                itemId={itemId}
                                                checkedItems={checkedItems}
                                                skuId={skuId}
                                                image={image}
                                                variantProperties={variantProperties}
                                                price={price}
                                                quantity={quantity}
                                                totalPrice={FornatTotalPrice}
                                                inventory={inventory}
                                                productImage={productImage}
                                                productName={productName}
                                                currentItemId={currentItemId}
                                                t={t}
                                            />
                                        </React.Fragment>
                                    )
                                })}
                            </Col>
                        </React.Fragment>
                    )
                })}
            </>
        )
    }

    const renderCartListItem = (cartListItems: any) => {
        return cartListItems?.map((item: any) => {
            let totalPrice = 0
            let totalQuantity = 0
            item?.cartItems?.forEach((cartItem: any) => {
                cartItem?.skuViews?.forEach((skuView: any) => {
                    totalPrice += parseFloat(skuView.price) * parseFloat(skuView.quantity)
                    totalQuantity += skuView.quantity
                })
            })

            const key = lodash.get(item, 'merchant.id')
            const merchantName = lodash.get(item, 'merchant.name')
            const avatar = lodash.get(item, 'merchant.avatar')
            const isExpanded = expandedItems[key]
            const cartItems = lodash.get(item, 'cartItems')
            const merchant = lodash.get(item, 'merchant')
            const countSku = sumBy(item?.cartItems, (item: any) => item?.skuViews.length)
            const merchantCheckedLength = checkedItems.filter((item: any) => item.merchant?.id === merchant.id).length
            const checked =
                countSku === checkedItems.filter((item: any) => item?.merchant.id === merchant.id).slice(0, countSku).length
            return (
                <div className="mt-4 bg-white rounded-xl overflow-auto">
                    <Row
                        key={key}
                        align={'middle'}
                        className="p-6"
                    >
                        <Col
                            span={12}
                            className="text-black-100 font-medium text-sm flex items-center gap-4"
                        >
                            <Checkbox
                                onChange={(e: any) => handleCheckAllChange(merchant, e.target.checked, item?.cartItems)}
                                checked={checked}
                                indeterminate={
                                    checkedItems.length > 0 && merchantCheckedLength > 0 && merchantCheckedLength !== countSku
                                }
                            />
                            <div className="flex gap-3 items-center">
                                <Avatar
                                    src={avatar || require('../../../../resources/images/merchanDefaultIcon.png')}
                                    size="default"
                                    icon={<UserOutlined />}
                                />
                                <span className="font-normal ">{merchantName}</span>
                            </div>
                            {/* <div className='flex gap-2 items-center'>
                            <i className="fa-light fa-trash"></i>
                            <span>Xoá người bán</span>
                        </div> */}
                        </Col>

                        <Col span={3}></Col>

                        <Col
                            span={5}
                            className="flex justify-center"
                        >
                            <Row className={'text-blue-primary text-center font-medium text-sm'}>
                                <span className={'txt-secondary'}>{totalQuantity ? totalQuantity : '---'}</span>
                            </Row>
                        </Col>

                        <Col
                            span={2}
                            className="flex justify-end"
                        >
                            <Row className={'text-blue-primary font-medium text-sm'}>
                                <span className={'txt-secondary '}>
                                    {totalPrice ? formatCurrencyChina(totalPrice.toFixed(2)) : '---'}
                                </span>
                            </Row>
                        </Col>

                        <Col
                            span={2}
                            className="flex justify-end"
                        >
                            <Row justify={'end'}>
                                <div
                                    className="flex items-center gap-2 hover:cursor-pointer hover:text-blue-primary"
                                    onClick={() => toggleExpandItem(key)}
                                >
                                    {isExpanded ? (
                                        <>
                                            <span>{t('taobaoGlobalCart.Expand')}</span>
                                            <i className="fa-solid fa-chevron-down mt-[0.5px]" />
                                        </>
                                    ) : (
                                        <>
                                            <span>{t('taobaoGlobalCart.Collapse')}</span>
                                            <i className="fa-solid fa-chevron-up mt-[0.5px]" />
                                        </>
                                    )}
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    <Row>{!isExpanded && renderChildrenItem(cartItems, merchant)}</Row>
                </div>
            )
        })
    }

    return (
        <>
            <>
                <Row
                    className="mt-4 px-6 py-4 bg-white rounded-xl"
                    align={'middle'}
                >
                    <Col
                        span={12}
                        className={className}
                    >
                        <Checkbox
                            className="mr-4"
                            onChange={e => handleCheckAllMerchantsChange(e.target.checked)}
                            checked={
                                (checkedItems.length > 0 && checkAllMerchants) ||
                                (checkedItems.length > 0 && checkedItems.length === totalProductCart)
                            }
                            indeterminate={checkedItems.length > 0 && checkedItems.length !== totalProductCart}
                        />
                        {`${t('taobaoGlobalCart.product')}`}
                    </Col>

                    {/* <Col span={7}>
                        <Row className={className}>
                            {`${t('taobaoGlobalCart.attribute')}`}
                        </Row>
                    </Col> */}

                    <Col
                        span={3}
                        className="flex justify-end"
                    >
                        <Row className={className}>
                            <span className={'txt-secondary'}>{`${t('taobaoGlobalCart.unitPrice')}`}</span>
                        </Row>
                    </Col>

                    <Col
                        span={5}
                        className="flex justify-center"
                    >
                        <Row className={className}>{`${t('taobaoGlobalCart.quantity')}`}</Row>
                    </Col>

                    <Col
                        span={2}
                        className="flex justify-end"
                    >
                        <Row className={className}>
                            <span className={'txt-secondary'}>{`${t('taobaoGlobalCart.money')}`}</span>
                        </Row>
                    </Col>

                    <Col
                        span={2}
                        className="flex justify-end"
                    >
                        <Row className={className}>{`${t('taobaoGlobalCart.action')}`}</Row>
                    </Col>

                    {!loadingListItem && !loading && totalProductCart === 0 && (
                        <>
                            <Divider />
                            <div
                                className="grid justify-center text-center"
                                style={{ margin: '0 auto' }}
                            >
                                <Image
                                    preview={false}
                                    style={{ width: '150px', maxWidth: '150px' }}
                                    src={require('../../../../resources/images/cartEmpty.png')}
                                />
                                <span className="mt-6 text-grey-500 font-normal text-base">{t('taobaoGlobalCart.empty')}</span>
                                <span className="mt-1  text-grey-500 font-normal text-base">
                                    {t('taobaoGlobalCart.emptyDesc')}
                                </span>
                            </div>
                        </>
                    )}
                </Row>
                {!loadingListItem && !loading ? (
                    <>{renderCartListItem(cartListItem)}</>
                ) : (
                    <>
                        <div>
                            <Row
                                className="mt-4 px-6 py-4 bg-white rounded-xl overflow-auto"
                                align={'middle'}
                            >
                                <Col
                                    span={9}
                                    className="text-black-100 font-medium text-sm flex items-center gap-4"
                                >
                                    <Checkbox />
                                    <div>
                                        <Skeleton
                                            width={24}
                                            height={24}
                                        />
                                    </div>
                                    <div>
                                        <Skeleton
                                            width={100}
                                            height={24}
                                            className="rounded-xl"
                                        />
                                    </div>
                                </Col>

                                <Col span={7}></Col>

                                <Col span={2}></Col>

                                <Col span={2}>
                                    <Row className={'text-blue-primary font-medium text-sm'}>
                                        <span className={'txt-secondary '}>
                                            <div>
                                                <Skeleton
                                                    width={80}
                                                    height={24}
                                                    className="rounded-xl"
                                                />
                                            </div>
                                        </span>
                                    </Row>
                                </Col>

                                <Col span={2}>
                                    <Row className={'text-blue-primary font-medium text-sm'}>
                                        <span className={'txt-secondary'}>
                                            <div>
                                                <Skeleton
                                                    width={80}
                                                    height={24}
                                                    className="rounded-xl"
                                                />
                                            </div>
                                        </span>
                                    </Row>
                                </Col>

                                <Col
                                    span={2}
                                    className="text-grey-400 text-sm"
                                >
                                    <Row justify={'end'}></Row>
                                </Col>
                            </Row>

                            <Row
                                className="mt-4 px-6 py-4 bg-white rounded-xl overflow-auto"
                                align={'middle'}
                            >
                                <Col
                                    span={9}
                                    className="text-black-100 font-medium text-sm flex items-center gap-4"
                                >
                                    <Checkbox />
                                    <div>
                                        <Skeleton
                                            width={24}
                                            height={24}
                                        />
                                    </div>
                                    <div>
                                        <Skeleton
                                            width={100}
                                            height={24}
                                            className="rounded-xl"
                                        />
                                    </div>
                                </Col>

                                <Col span={7}></Col>

                                <Col span={2}></Col>

                                <Col span={2}>
                                    <Row className={'text-blue-primary font-medium text-sm'}>
                                        <span className={'txt-secondary '}>
                                            <div>
                                                <Skeleton
                                                    width={80}
                                                    height={24}
                                                    className="rounded-xl"
                                                />
                                            </div>
                                        </span>
                                    </Row>
                                </Col>

                                <Col span={2}>
                                    <Row className={'text-blue-primary font-medium text-sm'}>
                                        <span className={'txt-secondary'}>
                                            <div>
                                                <Skeleton
                                                    width={80}
                                                    height={24}
                                                    className="rounded-xl"
                                                />
                                            </div>
                                        </span>
                                    </Row>
                                </Col>

                                <Col
                                    span={2}
                                    className="text-grey-400 text-sm"
                                >
                                    <Row justify={'end'}></Row>
                                </Col>
                            </Row>

                            <Row
                                className="mt-4 px-6 py-4 bg-white rounded-xl h-auto"
                                align={'middle'}
                            >
                                <Col
                                    span={9}
                                    className="text-black-100 font-medium text-sm flex items-center gap-4"
                                >
                                    <Checkbox />
                                    <div>
                                        <Skeleton
                                            width={24}
                                            height={24}
                                        />
                                    </div>
                                    <div>
                                        <Skeleton
                                            width={100}
                                            height={24}
                                            className="rounded-xl"
                                        />
                                    </div>
                                </Col>

                                <Col span={7}></Col>

                                <Col span={2}></Col>

                                <Col span={2}>
                                    <Row className={'text-blue-primary font-medium text-sm'}>
                                        <span className={'txt-secondary '}>
                                            <div>
                                                <Skeleton
                                                    width={80}
                                                    height={24}
                                                    className="rounded-xl"
                                                />
                                            </div>
                                        </span>
                                    </Row>
                                </Col>

                                <Col span={2}>
                                    <Row className={'text-blue-primary font-medium text-sm'}>
                                        <span className={'txt-secondary'}>
                                            <div>
                                                <Skeleton
                                                    width={80}
                                                    height={24}
                                                    className="rounded-xl"
                                                />
                                            </div>
                                        </span>
                                    </Row>
                                </Col>

                                <Col
                                    span={2}
                                    className="text-grey-400 text-sm"
                                >
                                    <Row justify={'end'}></Row>
                                </Col>
                                <div>
                                    <div className="flex items-start mt-10">
                                        <div className="flex items-center">
                                            <Checkbox className="mr-4" />
                                            <Skeleton
                                                className="rounded-xl mr-4"
                                                width={60}
                                                height={60}
                                            />
                                        </div>
                                        <div className="mr-6">
                                            <Skeleton
                                                className="rounded-xl"
                                                width={180}
                                                height={24}
                                            />
                                            <Skeleton
                                                className="rounded-xl"
                                                width={90}
                                                height={24}
                                            />
                                        </div>

                                        <div className="mr-6">
                                            <Skeleton
                                                className="rounded-xl"
                                                width={200}
                                                height={24}
                                            />
                                            <Skeleton
                                                className="rounded-xl"
                                                width={180}
                                                height={24}
                                            />
                                            <Skeleton
                                                className="rounded-xl"
                                                width={80}
                                                height={24}
                                            />
                                            <Skeleton
                                                className="rounded-xl"
                                                width={200}
                                                height={24}
                                            />
                                            <Skeleton
                                                className="rounded-xl"
                                                width={200}
                                                height={24}
                                            />
                                        </div>

                                        <div className="mr-6 grid gap-3">
                                            <Skeleton
                                                className="rounded-xl"
                                                width={100}
                                                height={24}
                                            />
                                            <Skeleton
                                                className="rounded-xl"
                                                width={100}
                                                height={24}
                                            />
                                            <Skeleton
                                                className="rounded-xl"
                                                width={100}
                                                height={24}
                                            />
                                            <Skeleton
                                                className="rounded-xl"
                                                width={100}
                                                height={24}
                                            />
                                        </div>

                                        <div className="mr-6 grid gap-3">
                                            <Skeleton
                                                className="rounded-xl"
                                                width={100}
                                                height={24}
                                            />
                                            <Skeleton
                                                className="rounded-xl"
                                                width={100}
                                                height={24}
                                            />
                                            <Skeleton
                                                className="rounded-xl"
                                                width={100}
                                                height={24}
                                            />
                                            <Skeleton
                                                className="rounded-xl"
                                                width={100}
                                                height={24}
                                            />
                                        </div>

                                        <div className="mr-6 grid gap-3">
                                            <Skeleton
                                                className="rounded-xl"
                                                width={100}
                                                height={24}
                                            />
                                            <Skeleton
                                                className="rounded-xl"
                                                width={100}
                                                height={24}
                                            />
                                            <Skeleton
                                                className="rounded-xl"
                                                width={100}
                                                height={24}
                                            />
                                            <Skeleton
                                                className="rounded-xl"
                                                width={100}
                                                height={24}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </>
                )}
            </>
        </>
    )
}

export default CartListItem
