import React from "react";
import {CopyOutlined} from "@ant-design/icons";
import { message} from 'antd';

export default class Clipboard extends React.Component{

    render(){
        const {value} = this.props;
        return(
            <CopyOutlined className={'mgl5 mgr5 txt-color-blue _btCopy'} onClick={()=>{
                const textField = document.createElement('textarea');
                textField.innerText = value;
                document.body.appendChild(textField);
                textField.select();
                document.execCommand('copy');
                textField.remove();
                message.success('Copied');
            }
            }
            />
        );
    }
}