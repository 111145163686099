import { Button, Modal, Form, Input } from 'antd';

const ModalConnectApplication = ({ visible, onCreate, onCancel, t, loading }) => {
    const [form] = Form.useForm();
    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            className={'_modal-create'}
            title={t('connect_application.title_modal_create')}
            footer={[
                <Button className="buttonm buttonm__cancel _bt-cancel" key="back" onClick={onCancel}>
                    <span className={'mgr10'}> <i className="fal fa-times"></i></span> {t('button.cancel')}
                </Button>,
                <Button className="buttonm _bt-submit" loading={loading} disabled={loading} key="submit" type="" onClick={()=>{
                    form
                        .validateFields()
                        .then((values) => {
                            onCreate(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}>
                    <span className={'mgr10'}><i className="fal fa-check"></i></span>{t('modal.confirm')}
                </Button>
            ]}
        >
            <Form
                form={form}
                layout="horizontal"
                name="form_in_modal"
                labelCol={{ span: 8 }}
                labelAlign={'left'}
                wrapperCol={{ span: 16 }}
            >
                <Form.Item
                    name="name"
                    label={t('connect_application.name')}
                    rules={[
                        {
                            required: true,
                            message: t("customer_info.required")
                        }, {
                            pattern: /^(?!\s*$).+/g,
                            message: t("message.invalid_format")
                        },
                    ]}
                >
                    <Input autoFocus={true} className={'_name'}/>
                </Form.Item>
                <Form.Item name="description"   label={t('connect_application.desc')}>
                    <Input.TextArea rows={4} className={'_desc'}/>
                </Form.Item>
                <Form.Item
                    name="secret"
                    label={t('connect_application.pin')}
                    rules={[
                        {
                            required: true,
                            message: t("customer_info.required")
                        },
                    ]}
                >
                    <Input.Password className={'_password'}/>
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default ModalConnectApplication;
