import { useEffect, useState } from 'react'
import UserService from '../../services/user.service'
import { Modal, Table, Typography, Radio, notification, Skeleton } from 'antd'
import { Link } from 'react-router-dom'
import { moneyFormat } from '../../utils/stringUtils'
import SmallLoading from '../../components/Loading/SmallLoading'
import _ from 'lodash'
import { LocalStore } from '../../utils/LocalStore'
import BifinService from '../../services/bifin.service'

const DEFAULT_METHOD_SELECTED = 'default'
const BIFFIN_METHOD_SELECTED = 'cam_do'

export const ModalConfirmPayment = (
    props = {
        userBalance: {},
        peerPaymentData: undefined,
        chargeRequestWithCredit: code => {},
        t: () => {},
        onVisibleChange: () => {},
    }
) => {
    const { t, peerPaymentData, exchangeRateByAmount, isLoadingExchange, userBalance } = props
    const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
    const [depositMethodValue, setDepositMethodValue] = useState(BIFFIN_METHOD_SELECTED)
    const [isConnectedBiffin, setIsConnectedBiffin] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isPeerPaymentLoanable, setIsPeerPaymentLoanable] = useState(true)
    const [totalLoanAmount, setTotalLoanAmount] = useState(0)
    const [feeAmount, setFeeAmount] = useState(0)
    const isBiffinSelected = depositMethodValue === BIFFIN_METHOD_SELECTED

    useEffect(() => {
        getBiffinConnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const newExchangedAmount = peerPaymentData.amount * exchangeRateByAmount.rate
    const arr = [
        {
            id: 1,
            oldLabel: `${t('peer_payment.old_exchangeRate')}:`,
            newLabel: `${t('peer_payment.new_exchangeRate')}:`,
            old: moneyFormat(peerPaymentData.exchangeRate),
            new: moneyFormat(exchangeRateByAmount.rate),
        },
        {
            id: 2,
            oldLabel: `${t('peer_payment.old_amount')}:`,
            newLabel: `${t('peer_payment.new_amount')}:`,
            old: moneyFormat(peerPaymentData.exchangedAmount),
            new: moneyFormat(newExchangedAmount),
        },
    ]

    const col = [
        {
            title: '',
            dataIndex: 'oldLabel',
            key: 'oldLabel',
        },
        {
            title: '',
            dataIndex: 'old',
            key: 'old',
            render: text => <div className="_old-value txt-color-red">{text}</div>,
        },
        {
            title: '',
            dataIndex: 'abc',
            key: 'abc',
            render: () => {
                return '-->'
            },
        },
        {
            title: '',
            dataIndex: 'newLabel',
            key: 'newLabel',
        },
        {
            title: '',
            dataIndex: 'new',
            key: 'new',
            render: text => <div className="_new-value txt-color-green">{text}</div>,
        },
    ]
    const remainingAmount = newExchangedAmount - totalLoanAmount

    const getBiffinConnect = () => {
        setIsLoading(true)
        UserService.getConnectedToBiffin((err, res) => {
            if (!err) {
                if (res.token && res.tenantId) {
                    setIsConnectedBiffin(true)
                    checkLoanableWithBifin(peerPaymentData)
                }
            } else {
                notification.error({message: 'Có lỗi xảy ra, không kết nối được với Bifin'})
                setIsLoading(false)
            }
        })
    }

    const checkLoanableWithBifin = peerPaymentData => {
        const body = {
            peerPaymentCode: peerPaymentData.code,
        }
        BifinService.askBiffinToBorrow(body, (err, res) => {
            if (!err) {
                if (res.status === 'success') {
                    setIsPeerPaymentLoanable(true)
                    setTotalLoanAmount(_.sumBy(res.loans, i => i.loanAmount))
                    setFeeAmount(_.sumBy(res.loans, i => i.feeAmount))
                } else {
                    setIsPeerPaymentLoanable(false)
                }
            } else {
                setIsPeerPaymentLoanable(false)
            }
            setIsLoading(false)
        })
    }

    const checkDisabledOkButton = () => {
        const { userBalance } = props
        const isBiffinMethodSelected = depositMethodValue === BIFFIN_METHOD_SELECTED
        const hasInsufficientBalance = userBalance?.balance < remainingAmount

        if (isBiffinMethodSelected) {
            if (!isConnectedBiffin || !isPeerPaymentLoanable || hasInsufficientBalance) {
                return true
            }
        }
        return false
    }

    return (
        <Modal
            width={700}
            visible={true}
            destroyOnClose={true}
            title={'Xác nhận thanh toán với BiFin'}
            // footer={null}
            okButtonProps={{ disabled: checkDisabledOkButton() }}
            okText={
                <div className="flex">
                    {isLoadingExchange && <SmallLoading />}
                    <span className="ml-1">Đồng ý</span>
                </div>
            }
            onOk={() => props.chargeRequestWithCredit(peerPaymentData.code)}
            onCancel={() => props.onVisibleChange(false)}
        >
            {isLoading ? (
                <Skeleton loading={true} active />                    
            ) : (
                <>
                    <div>
                        {!isLoadingExchange &&
                            exchangeRateByAmount.rate !== peerPaymentData.exchangeRate &&
                            !peerPaymentData.fixedExchangeRate && (
                                <div className="dpl-flex pdt10">
                                    <div className="width-300">
                                        {t('peer_payment.amount')}:{' '}
                                        <span className="_amount-popup bold txt-color-green">
                                            {moneyFormat(peerPaymentData.amount, peerPaymentData.currency)}
                                        </span>
                                    </div>
                                </div>
                            )}

                        {!isLoadingExchange &&
                            exchangeRateByAmount.rate !== peerPaymentData.exchangeRate &&
                            !peerPaymentData.fixedExchangeRate && (
                                <Table
                                    className="_table-data mb-2 custom-empty table-hidden-header table-expand-row border-bottom-radius table-mini"
                                    rowKey={(x, y) => y}
                                    columns={col}
                                    dataSource={arr}
                                    rowClassName={() => `_table-row`}
                                    pagination={{
                                        hideOnSinglePage: true,
                                        pageSize: arr.length,
                                    }}
                                />
                            )}
                    </div>
                    {isConnectedBiffin ? (
                        <div className="grid grid-cols-2">
                            <Radio.Group
                                className="width-100-percent hidden mb-0"
                                onChange={e => {
                                    setDepositMethodValue(e.target.value)
                                    if (isConnectedBiffin && e.target.value === BIFFIN_METHOD_SELECTED) {
                                        checkLoanableWithBifin(peerPaymentData)
                                    }
                                }}
                                value={depositMethodValue}
                            >
                                <div className="flex flex-col w-full">
                                    <div>
                                        <Radio value={DEFAULT_METHOD_SELECTED}>
                                            {!isBiffinSelected ? (
                                                <Typography.Link level={5}>
                                                    {t('peer_payment.with', { method: currentProjectInfo?.name })}
                                                </Typography.Link>
                                            ) : (
                                                <Typography.Text level={5}>
                                                    {t('peer_payment.with', { method: currentProjectInfo?.name })}
                                                </Typography.Text>
                                            )}
                                        </Radio>
                                    </div>
                                    <div className="relative w-full mt-3 flex items-center justify-between">
                                        <Radio value={BIFFIN_METHOD_SELECTED}>
                                            {isBiffinSelected ? (
                                                <Typography.Link level={5}>
                                                    {t('peer_payment.with', { method: 'BiFin' })}
                                                </Typography.Link>
                                            ) : (
                                                <Typography.Text level={5}>
                                                    {t('peer_payment.with', { method: 'BiFin' })}
                                                </Typography.Text>
                                            )}
                                        </Radio>
                                    </div>
                                </div>
                            </Radio.Group>
                            {isPeerPaymentLoanable && depositMethodValue === BIFFIN_METHOD_SELECTED && (
                                <div className="flex mb-3 flex-col">
                                    <div className="flex justify-between">
                                        Phí/Lãi tạm tính: <span>{moneyFormat(feeAmount)}</span>
                                    </div>
                                    <div className="flex justify-between">
                                        Số tiền Bifin ứng vốn: <span>{moneyFormat(totalLoanAmount)}</span>
                                    </div>
                                    <div className="flex justify-between">
                                        Số tiền khách ứng: <span>{moneyFormat(newExchangedAmount - totalLoanAmount)}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <Link
                            className={'_menu-bifiin'}
                            to={'/profile/bifiin'}
                        >
                            <span className="txt-size-h7 robotoregular">{t('cart.connect_now')}</span>
                        </Link>
                    )}
                    <div>
                        {!isConnectedBiffin && (
                            <span className="text-red-100 text-[13px]">
                                Vui lòng liên kết tài khoản BiFin với {currentProjectInfo?.name} để tiếp tục
                            </span>
                        )}
                        {isConnectedBiffin && userBalance.balance < remainingAmount && (
                            <span className="text-red-100 text-[13px]">
                                Bạn cần tối thiểu {moneyFormat(remainingAmount)} để thanh toán YCTT này
                            </span>
                        )}
                        {isConnectedBiffin && !isPeerPaymentLoanable && (
                            <span className="text-red-100 text-[13px]">
                                Bạn không đủ điều kiện ứng vốn từ BiFin, vui lòng tiếp tục thanh toán với{' '}
                                {currentProjectInfo?.name}
                            </span>
                        )}
                        {isConnectedBiffin ? (
                            <p className="mt-3 text-black-100 mb-0">
                                Bằng việc xác nhận thanh toán, bạn đã đồng ý với{' '}
                                <a href="https://bifin.vn/tai-lieu-huong-dan-su-dung/">chính sách ứng vốn</a> của BiFin
                            </p>
                        ) : (
                            <div>
                                <p className="mt-3 text-black-100 mb-0">
                                    BiFin là đối tác uy tín hàng đầu của {currentProjectInfo?.name} cung cấp giải pháp tài chính lên đến 70% giá trị đơn hàng
                                </p>
                                <a
                                    href="https://www.bifin.vn/"
                                    target={'_blank'}
                                    className="mt-3 float-left text-blue-100" rel="noreferrer"
                                >
                                    {t('cartCheckout.biffin_note_more')}
                                </a>
                            </div>
                        )}
                    </div>
                </>
            )}
        </Modal>
    )
}
