import React from 'react';
import _ from 'lodash';
import {Table, Spin} from 'antd';
import * as AntdIcon from "@ant-design/icons"

import {moneyFormat} from "../../utils/stringUtils";
import moment from "moment";
import { LocalStore } from '../../utils/LocalStore';
import oldWallet from "../../resources/images/old-wallet.svg";
import digitalWallet from "../../resources/images/digital-wallet.svg";

class TabFinance extends React.Component{
    constructor(props){
        super(props);
        this.state={
            listFinancial: []
        }
    }

    renderWallet = (record) => {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        const diorConfigEnabled = _.get(currentProjectInfo, 'tenantConfig.diorConfig.enabled', false)
        //enabled ví mới
        if(!diorConfigEnabled) return null
        //check ví mới hay ví cũ
        if(record.wallet === 'dior') {
            return <img className="mr-1" src={digitalWallet} style={{width: '16px', height: "16px"}} alt=''/>
        }
        return <img className="mr-1" src={oldWallet} style={{width: '16px', height: "16px"}} alt=''/>
    }

    /**
     * render giao dịch
     */
    renderFinance = () => {
        const {t} = this.props;
        const columns = [{
            title: t('financial_tab.time'),
            key: 'timestamp',
            className: '_finance-time',
            render: (text, record) => (
                <div className={'break-word'}>
                    <p className="_financial-type-detail txt-size-h7 txt-color-black roboregular mgbt5 ">{moment(record.timestamp).format('HH:mm DD/MM/YYYY')}</p>
                </div>
            ),
        }, {
            title: t('financial_tab.amount'),
            dataIndex: 'amount',
            key: 'amount',
            className: '_finance-amount',
            render: (text, record) => {
                return (
                    <div className={'break-word'}>
                        <p className={`_financial-type-detail txt-size-h7 txt-color-red2 roboregular mgbt5 ` + (record.amount >= 0 ? 'txt-color-green' : 'txt-color-red')}>
                            {record.amount >= 0 ? '+' + moneyFormat(record.amount) : moneyFormat(record.amount)}
                        </p>
                    </div>
                )
            }
        }, {
            title: t('financial_tab.transaction_type'),
            dataIndex: 'type',
            key: 'type',
            className: '_finance-type whitespace',
            render: (text, record) => {
                return (
                    <div className={'break-word whitespace'}>
                        <p className="_financial-type-detail txt-size-h7 txt-color-black roboregular mgbt5 whitespace">{record.type.name}</p>
                    </div>
                )
            },
        }, {
            title: t('financial_tab.content'),
            key: 'content',
            className: '_finance-content table__name',
            render: (text, record) => (<>
                <div className={'table__name'}>
                    <p className="_address-list-detail txt-size-h8 txt-color-gray roboregular mgbt0 ">{this.renderWallet(record)} Mã: {record.txid}</p>
                    <p className="_address-list-location-display break-word mgbt0 txt-size-h7 txt-color-black">
                        {record.memo}
                    </p>
                </div>
                </>
            ),
        }];

        const {metadata, listFinancial = [], isLoadingListFinancial} = this.props;

        return (
            <Spin spinning={isLoadingListFinancial === true} indicator={<AntdIcon.Loading3QuartersOutlined type="loading" style={{ fontSize: 24 }} spin />} >
                <Table
                    className={'_finance-table pd15'}
                    rowKey={'id'}
                    columns={columns}
                    dataSource={_.sortBy(listFinancial, 'timestamp').reverse()}
                    rowClassName={() => `_finance-row`}
                    pagination={{
                        hideOnSinglePage: true,
                        simple: true,
                        total: metadata ? parseFloat(metadata.total) : 0,
                        pageSize: 5,
                    }}
                />
            </Spin>
        )
    };

    render(){
        return(
            this.renderFinance()
        );
    }
}
export default TabFinance;