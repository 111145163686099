import _ from "lodash";
import PolimClient from "../utils/api/PolimClient";
import { LocalStore } from "../utils/LocalStore";

export default class UserService {

    /**
     * lấy user đang đăng nhập
     * @param config
     * @param cb
     */
    static getCurrentUser(config = {}, cb) {
        PolimClient.request({
            endpoint: "/customer/profile",
            method: "GET",
            ...config
        }, cb);
    }

    /**
     * lấy user đang đăng nhập
     * @param cb
     */
    static getBalanceUser(cb) {
        PolimClient.request({
            endpoint: "/customer/profile/balance",
            method: "GET",
        }, cb);
    }

    /**
     * check mã coupon
     * @param couponCode
     * @param orderCode
     * @param isShipment
     * @param cb
     */
    static checkCoupon(couponCode, orderCode, isShipment, cb) {
        PolimClient.request({
            endpoint: `/customer/customer_coupon/check?couponCode=${couponCode}&orderCode=${orderCode}&isShipment=${!!isShipment}`,
            method: "GET",
        }, cb);
    }

    static checkCouponPost(couponCode, orderCode, isShipment, cb) {
        PolimClient.request(
            {
                endpoint: `/customer/customer_coupon/check`,
                method: 'POST',
                body: {
                    code: couponCode,
                    orderCode,
                    isShipment: isShipment,
                },
            },
            cb
        )
    }

    /**
     * apply mã coupon
     * @param orderCode
     * @param body
     * @param isShipment
     * @param cb
     */
    static applyCoupon(orderCode, body, isShipment, cb) {

        PolimClient.request({
            endpoint: `/customer/${isShipment ? 'shipments' : 'orders'}/${orderCode}/apply_coupon`,
            method: "POST",
            body
        }, cb);
    }

    /**
   * get danh sách tài khoản cho mượn
   * @param cb
   */
    static getPurchasingAccounts(cb) {
        PolimClient.request({
            endpoint: `/customer/purchasing_accounts?size=${200}&sort=createdAt:desc`,
            method: "GET",
        }, cb);
    }

    /**
     * check mã coupon
     * @param orderCode
     * @param isShipment
     * @param cb
     */
    static getCouponOrder(orderCode, isShipment, cb) {
        PolimClient.request({
            endpoint: `/customer/${isShipment ? 'shipments' : 'orders'}/${orderCode}/coupons`,
            method: "GET",
        }, cb);
    }

    /**
     * lấy ra danh sách lichj sử giao dịch của tài khoản
     * @param query
     * @param cb
     */
    static getTransactionsHistory(query, cb) {
        PolimClient.request({
            endpoint: `/customer/profile/transactions${query || ''}`,
            method: "GET",
        }, cb);
    }

    static getTransactionsHistoryByAccount(query, account, cb) {
        PolimClient.request({
            endpoint: `/customer/wallet/accounts/${account}/transactions${query || ''}`,
            method: "GET",
        }, cb);
    }

    /**
     * lấy ra danh sách purchase_units
     * @param txid
     * @param cb
     */
    static getTransactionHistoryPurchaseUnits(txid, cb) {
        PolimClient.request({
            endpoint: `/customer/profile/transactions/${txid}/purchase_units`,
            method: "GET",
        }, cb);
    }

    /**
     * lấy ra danh sách lịch sử nhận điểm tích lũy
     * @param query
     * @param cb
     */
    static getRewardPointTransactions(query, cb) {
        PolimClient.request({
            endpoint: `/customer/reward_point/transaction${query || ''}`,
            method: "GET",
        }, cb);
    }

    /**
     * lấy ra danh sách lịch sử nhận điểm tích lũy
     * @param id
     * @param cb
     */
    static getRewardPointTransactionsHistory(id, cb) {
        PolimClient.request({
            endpoint: `/customer/reward_point/transaction/${id}/history`,
            method: "GET",
        }, cb);
    }

    /**
     * xuất csv của Transactions
     * @param query
     * @param body
     * @param cb
     */
    static exportCSVTransactions(query, body, cb) {
        PolimClient.request({
            endpoint: `/customer/profile/transactions/export_excel${query || ''}`,
            method: "POST",
            body
        }, cb);
    }

    /**
     * xuất csv của Transactions Dior
     * @param query
     * @param body
     * @param cb
     */
    static exportCSVTransactionsDior(account, query, body, cb) {
        PolimClient.request({
            endpoint: `/customer/wallet/accounts/${account}/export_excel${query || ''}`,
            method: "POST",
            body
        }, cb);
    }

    /**
     * lấy ra cấp vip của khách hàng
     * @param cb
     */
    static getCustomerLevels(cb) {
        PolimClient.request({
            endpoint: `/customer/customer_level`,
            method: "GET",
        }, cb);
    }

    /**
     * lấy ra discount theo cấp vip
     * @param cb
     */
    static getCustomerDiscount(cb) {
        PolimClient.request({
            endpoint: `/customer/customer_discount`,
            method: "GET",
        }, cb);
    }

    /**
     * lấy ra danh sách package của customer
     * @param query
     * @param cb
     */
    static getCustomerPackages(query, cb) {
        PolimClient.request({
            endpoint: `/customer/packages${query || ''}`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra danh sách lieferscheine của customer
     * @param query
     * @param cb
     */
    static getCustomerLieferscheine(query, cb) {
        PolimClient.request({
            endpoint: `/customer/lieferscheine${query || ''}`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra danh sách kiện trong phiếu giao của customer
     * @param query
     * @param cb
     */
    static getCustomerLieferscheinePackages(code, cb) {
        PolimClient.request({
            endpoint: `/customer/lieferscheine/${code}/packages`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra yêu cầu giao trong phiếu giao của customer
     * @param query
     * @param cb
     */
    static getCustomerLieferscheineDeliveries(code, cb) {
        PolimClient.request({
            endpoint: `/customer/lieferscheine/${code}/deliveries`,
            method: 'GET',
        }, cb)
    }


    /**
     * lấy ra danh sách package của customer
     * @param query
     * @param cb
     */
    static getCustomerWaybills(query, cb) {
        PolimClient.request({
            endpoint: `/customer/customer_waybills${query || ''}`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra danh sách phiếu giao
     * @param query
     * @param cb
     */
    static getDeliveryNotes(query, cb) {
        PolimClient.request({
            endpoint: `/customer/delivery_notes${query || ''}`,
            method: 'GET',
        }, cb)
    }

    /**
     * tạo mã zalo
     * @param cb
     */
    static generateZaloToken(cb) {
        PolimClient.request({
            endpoint: `/customer/gongbao/zalo/generate_token`,
            method: 'POST',
        }, cb)
    }
    /**
     * hủy kết nối zalo
     * @param cb
     */
    static disconnectZalo(body, cb) {
        PolimClient.request({
            endpoint: `/customer/gongbao/zalo/unregister`,
            method: 'POST',
            body
        }, cb)
    }
    // /**
    //  * lấy danh sách users
    //  * @param filter
    //  * @param type INTERNAL với EXTERNAL
    //  * @param cb
    //  */
    // static getUsersList(filter, type, cb) {
    //     let queryString = '';
    //
    //     if(type) {
    //         queryString = updateQueryStringParameter(queryString, 'type', type);
    //     }
    //
    //     if(filter) {
    //         queryString = updateQueryStringParameter(queryString, 'query', filter);
    //     }
    //
    //     queryString = updateQueryStringParameter(queryString, 'size', 10);
    //
    //     PolimClient.request({
    //         endpoint: `/category/users${queryString}`,
    //         method: "GET"
    //     }, cb);
    // }

    /**
     * đăng nhập
     * @param user
     * @param clientId
     * @param cb
     */
    static login(user = {}, clientId, cb) {
        let queryString = `?username=${user.username}&password=${encodeURIComponent(user.password)}&grant_type=password&scope=all&client_id=${clientId}`;

        PolimClient.request({
            endpoint: `${process.env.REACT_APP_LOGIN_URL}/token${queryString}`,
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            keepStableUrl: true
        }, cb);
    }

    /**
     * lấy thông tin đăng ký với facebook
     * @param token
     * @param cb
     */
    static getProfileFacebook(token, cb) {
        PolimClient.request({
            endpoint: `/customer/profile/register/social?token=${token}`,
            method: "GET",
        }, cb);
    }

    /**
     * đăng ký với facebook
     * @param user
     * @param token
     * @param cb
     */
    static registerWithFacebook(user, token, cb) {
        PolimClient.request({
            endpoint: `/customer/profile/register/social?token=${token}`,
            method: "POST",
            body: user
        }, cb);
    }

    /**
     * đăng ký
     * @param user
     * @param cb
     */
    static register(user, metadata, cb) {
        let queryString = `?${Object.keys(metadata).map((key, index) => `${key}=${_.get(metadata, key, '')}`).join('&')}`;
        PolimClient.request({
            endpoint: `/customer/profile/register${_.isEmpty(metadata) ? '' : queryString}`,
            method: "POST",
            body: user
        }, cb);
    }

    /**
     * đăng ký
     * @param user
     * @param cb
     */
    static registerWithAddress(user, metadata, cb) {
        let queryString = `?${Object.keys(metadata).map((key, index) => `${key}=${_.get(metadata, key, '')}`).join('&')}`;
        PolimClient.request({
            endpoint: `/customer/profile/sign_up${_.isEmpty(metadata) ? '' : queryString}`,
            method: "POST",
            body: user
        }, cb);
    }

    /**
     * quên mật khẩu
     * @param user
     * @param cb
     */
    static forgotPassword(user, cb) {
        PolimClient.request({
            endpoint: `/customer/profile/recover`,
            method: "POST",
            body: user
        }, cb);
    }

    /**
     * reset mật khẩu
     * @param data
     * @param cb
     */
    static resetPassword(data, cb) {
        PolimClient.request({
            endpoint: `/customer/profile/reset`,
            method: "POST",
            body: data
        }, cb);
    }

    /**
     * confirm email
     * @param data
     * @param cb
     */
    static confirmEmail(data, cb) {
        PolimClient.request({
            endpoint: `/customer/profile/verify`,
            method: "POST",
            body: data
        }, cb);
    }

    /**
     * confirm email
     * @param data
     * @param cb
     */
    static confirmEmailChange(data, cb) {
        PolimClient.request({
            endpoint: `/customer/profile/confirm_email_change`,
            method: "POST",
            body: data
        }, cb);
    }

    /**
     * tạo mới waybills
     * @param data
     * @param cb
     */
    static createNewWaybills(data, cb) {
        PolimClient.request({
            endpoint: `/customer/customer_waybills/add_batch`,
            method: "POST",
            body: data
        }, cb);
    }

    static getConnectedToBiffin (cb) {
		PolimClient.request({
			endpoint: `/customer/third-parties/shopkeeper`,
			method: 'GET'
		}, cb)
	}

    /**
     * tạo mới waybills
     * @param id
     * @param data
     * @param cb
     */
    static editWaybills(id, data, cb) {
        PolimClient.request({
            endpoint: `/customer/customer_waybills/${id}`,
            method: "PATCH",
            body: data
        }, cb);
    }

    /**
     * tạo mới waybills
     * @param code
     * @param cb
     */
    static deleteWaybills(code, cb) {
        PolimClient.request({
            endpoint: `/customer/customer_waybills/${code}`,
            method: "DELETE",
        }, cb);
    }

    /**
     * tạo mới withdrawal slip
     * @param data
     * @param cb
     */
    static createNewWithdrawSlip(data, cb) {
        PolimClient.request({
            endpoint: `/customer/withdrawal_slip`,
            method: "POST",
            body: data
        }, cb);
    }

    /**
     * get article
     * @param cb
     */
    static getArticle(cb) {
        PolimClient.request({
            endpoint: `/customer/article`,
            method: "GET",
        }, cb);
    }

    /**
     * get article
     * @param cb
     */
    static getPubArticle(cb) {
        PolimClient.request({
            endpoint: `/categories/articles`,
            method: "GET",
        }, cb);
    }

    /**
     * đăng xuất
     * @param cb
     */
    static logout(cb) {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null;
        LocalStore.getInstance().save('loginSession', null);
        // LocalStore.getInstance().save('currentLanguage', null);
        LocalStore.getInstance().save('languagesSupport', null);
        window.location = window.location.origin + '/#/login';
    }

    static isAllowed(permissions) {
        let permissionToCheck = [];

        if (!Array.isArray(permissions)) {
            if (typeof permissions === 'object') {
                for (let key in permissions) {
                    if (permissions.hasOwnProperty(key))
                        permissionToCheck.push(permissions[key]);
                }
            }
            else {
                permissionToCheck = [permissions];
            }
        }
        else {
            permissionToCheck = permissions;
        }
        let session = LocalStore.getInstance().read('currentLoggedUser');
        if (session) {
            if (!Array.isArray(session.authorities)) {
                session.authorities = [];
            }

            for (let permission of permissionToCheck) {
                if (session.authorities.indexOf(permission) >= 0) {
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * update userProfile
     * @param data
     * @param cb
     */
    static updateProfile(data, cb) {
        PolimClient.request({
            endpoint: `/customer/profile`,
            method: 'PATCH',
            body: data
        }, cb)
    }

    /**
     * update mã PIN
     * @param data
     * @param cb
     */
    static updatePIN(data, cb) {
        PolimClient.request({
            endpoint: `/customer/profile/change_pin`,
            method: 'POST',
            body: data
        }, cb)
    }

    /**
     * reset mã PIN
     * @param body
     * @param cb
     */
    static recoverPin(body, cb) {
        PolimClient.request({
            endpoint: `/customer/profile/recover_pin`,
            method: 'POST',
            body
        }, cb)
    }

    /**
     * reset mã PIN
     * @param data
     * @param cb
     */
    static resetPin(data, cb) {
        PolimClient.request({
            endpoint: `/customer/profile/reset_pin`,
            method: 'POST',
            body: data
        }, cb)
    }

    /**
     * update mật khẩu
     * @param data
     * @param cb
     */
    static updatePassword(data, cb) {
        PolimClient.request({
            endpoint: `/customer/profile/change_password`,
            method: 'POST',
            body: data
        }, cb)
    }

    /**
     * get Faqs
     * @param query
     * @param cb
     */
    static loadDataFaqs(query, cb) {
        PolimClient.request({
            endpoint: `/faqs${query || ''}`,
            method: 'GET'
        }, cb)
    }

    /**
     * lấy ra danh sách sản phẩm đã lưu
     * @param query
     * @param cb
     */
    static getWishlist(query, cb) {
        PolimClient.request({
            endpoint: `/customer/wishlist${query || ''}`,
            method: 'GET',
        }, cb)
    }

    /**
     * thêm 1 sản phẩm vào wishlist
     * @param source
     * @param data
     * @param cb
     */
    static addItemWishlist(source, data, cb) {
        PolimClient.request({
            endpoint: `/customer/wishlist?source=${source}&data=${data}`,
            method: 'POST',
        }, cb)
    }

    /**
     * sửa 1 sản phẩm trong wishlist
     * @param id
     * @param body
     * @param cb
     */
    static updateItemWishlist(id, body, cb) {
        PolimClient.request({
            endpoint: `/customer/wishlist/${id}`,
            method: 'PATCH',
            body: body
        }, cb)
    }

    /**
     * xóa 1 sản phẩm trong wishlist
     * @param id
     * @param cb
     */
    static deleteItemWishlist(id, cb) {
        PolimClient.request({
            endpoint: `/customer/wishlist/${id}`,
            method: 'DELETE',
        }, cb)
    }

    /**
     * lấy dữ liệu cấu hình thông báo
     * @param cb
     */
    static loadListNotificationSetting(cb) {
        PolimClient.request({
            endpoint: `/customer/profile/settings/notifications`,
            method: 'GET',
        }, cb)
    }

    /**
     * cập nhật cấu hình thông báo
     * @param data
     * @param cb
     */
    static updateNotificationConfig(data, cb) {
        PolimClient.request({
            endpoint: `/customer/profile/settings/notifications`,
            method: 'POST',
            body: data
        }, cb)
    }

    /**
     * lấy ra số lượng skus trong giỏ hàng
     * @param cb
     */
    static getTotalSkusInCart(cb) {
        PolimClient.request({
            endpoint: `/customer/cart/statistics`,
            method: 'GET',
        }, cb)
    }

    /**
     * tạo đơn ký gửi
     * @param body
     * @param cb
     */
    static createShipment(body, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments`,
            method: 'POST',
            body: body
        }, cb)
    }

    /**
     * lấy danh sách đơn ký gửi
     * @param query
     * @param cb
     */
    static getShipments(query, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments${query}`,
            method: 'GET',
        }, cb)
    }

    /**
     * tạo phiếu ký gửi nháp
     * @param body
     * @param cb
     */
    static createDraftShipment(body, cb) {
        PolimClient.request({
            endpoint: `/customer/draft_shipments`,
            method: 'POST',
            body: body
        }, cb)
    }

    /**
     * lấy ra phiếu ký gửi nháp
     * @param cb
     */
    static getDraftShipment(cb) {
        PolimClient.request({
            endpoint: `/customer/draft_shipments`,
            method: 'GET',
        }, cb)
    }

    /**
     * xuất csv
     * @param query
     * @param body
     * @param cb
     */
    static exportShipment(query, body, cb) {
        PolimClient.request({
            endpoint: `/customer/shipments/export_excel${query || ''}`,
            method: 'POST',
            body
        }, cb)
    }

    /**
     * xuất csv packages
     * @param query
     * @param body
     * @param cb
     */
    static exportCsvPackages(query, body, cb) {
        PolimClient.request({
            endpoint: `/customer/packages/export_excel${query || ''}`,
            method: 'POST',
            body
        }, cb)
    }

    /**
     * lấy ra Khối lượng hàng hóa
     * @param cb
     */
    static getDashboardWeightStatistics(cb) {
        PolimClient.request({
            endpoint: `/customer/dashboard/weight_statistics`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra Danh sách bình luận
     * @param cb
     */
    static getDashboardComments(cb) {
        PolimClient.request({
            endpoint: `/customer/dashboard/comments?size=4`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra Danh sách đơn
     * @param query
     * @param cb
     */
    static getDashboardOrders(query, cb) {
        PolimClient.request({
            endpoint: `/customer/dashboard/orders${query}`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra Danh sách kiện lỗi
     * @param query
     * @param cb
     */
    static getPackageDamage(query, cb) {
        PolimClient.request({
            endpoint: `/customer/package_damage${query || ''}`,
            method: 'GET',
        }, cb)
    }

    /**
     * update kiện lỗi
     * @param body
     * @param cb
     */
    static updatePackageDamage(body, cb) {
        PolimClient.request({
            endpoint: `/customer/package_damage`,
            method: 'PATCH',
            body
        }, cb)
    }

    /**
     * lấy ra Danh sách kiện lỗi
     * @param stage m2, m5
     * @param cb
     */
    static getPackageDamageStatus(stage, cb) {
        PolimClient.request({
            endpoint: `/customer/package_damage/statuses?stage=${stage}`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra Danh sách log kiện lỗi
     * @param code m2, m5
     * @param cb
     */
    static getPackageDamageLogs(code, cb) {
        PolimClient.request({
            endpoint: `/customer/package_damage/${code}`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra danh sách thanh toán hộ
     * @param query
     * @param cb
     */
    static getPeerPayments(query, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/payment/peer_payments${query || ''}`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }

    /**
     * yêu cầu thanh toán
     * @param code
     * @param cb
     */
    static chargeRequest(code, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/payment/peer_payments/${code}/charge_request`,
            method: 'POST',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }

    /**
     * yêu cầu thanh toán với tín dụng
     * @param code
     * @param cb
     */
    static chargeRequestWithCredit(code, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/payment/peer_payments/${code}/charge_request_with_credit`,
            method: 'POST',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }

    /**
     * tải ảnh qr code
     * @param code
     */
    static uploadImage(body, cb) {
        PolimClient.request({
            endpoint: `/customer/images/upload`,
            method: 'POST',
            header: { 'content-type': 'multipart/form-data'},
            body
        }, cb)
    }

    /**
     * yêu cầu thanh toán
     * @param body
     * @param cb
     */
    static createPeerPayment(body, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/payment/peer_payments/request_for_transfer`,
            method: 'POST',
            headers: {
                'X-Tenant': currentProjectInfo.code
            },
            body
        }, cb)
    }

    /**
     * yêu cầu thanh toán
     * @param body
     * @param cb
     */
    static createRequestForPay(body, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/payment/peer_payments/request_for_pay`,
            method: 'POST',
            headers: {
                'X-Tenant': currentProjectInfo.code
            },
            body
        }, cb)
    }

    static askForPay(body, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/payment/peer_payments/ask_for_pay`,
            method: 'POST',
            headers: {
                'X-Tenant': currentProjectInfo.code
            },
            body
        }, cb)
    }

    /**
     * yêu cầu thanh toán
     * @param body
     * @param cb
     */
    static createPayAnInvoice(body, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/payment/peer_payments/pay_an_invoice`,
            method: 'POST',
            headers: {
                'X-Tenant': currentProjectInfo.code
            },
            body
        }, cb)
    }

    static askToPayAnInvoice(body, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/payment/peer_payments/ask_to_pay_invoice`,
            method: 'POST',
            headers: {
                'X-Tenant': currentProjectInfo.code
            },
            body
        }, cb)
    }

    /**
     * lây ra tỷ giá
     * @param query
     * @param cb
     */
    static getExchangeRatePeerPayMent(query, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/customer/exchange_rates${query || ''}`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }

    /**
     * lây ra tỷ giá taobao global
     * @param query
     * @param cb
     */
    static getExchangeRatePeerPayMentTbg(body, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/customer/exchange_rates/batch`,
            method: 'POST',
            headers: {
                'X-Tenant': currentProjectInfo.code
            },
            body: body
        }, cb)
    }

    /**
     * lấy phí lúc tạo YCTT
     * @param body
     * @param cb
     */
    static getPaymentQuotation(body, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/john/payment/peer_payment_quotation`,
            method: 'POST',
            headers: {
                'X-Tenant': currentProjectInfo.code
            },
            body
        }, cb)
    }

    /**
     * lấy ra danh sách thanh toán hộ
     * @param peerPaymentCode
     * @param cb
     */
    static getPeerPayment(peerPaymentCode, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/payment/peer_payments/${peerPaymentCode}`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }

    /**
     * Lấy danh sách comment
     * @param peerPaymentCode
     * @param cb
     */
    static getListPeerPaymentOriginalReceipts(peerPaymentCode, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/payment/peer_payments/${peerPaymentCode}/original_receipts`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }

    /**
     * thêm hóa đơn gốc
     * @param peerPaymentCode
     * @param body
     * @param cb
     */
    static addPeerPaymentOriginalReceipt(peerPaymentCode, body, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/payment/peer_payments/${peerPaymentCode}/original_receipts`,
            method: 'POST',
            headers: {
                'X-Tenant': currentProjectInfo.code
            },
            body
        }, cb)
    }

    /**
     * xóa hóa đơn gốc
     * @param peerPaymentCode
     * @param query
     * @param cb
     */
    static deletePeerPaymentOriginalReceipt(peerPaymentCode, query, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/payment/peer_payments/${peerPaymentCode}/original_receipts${query || ''}`,
            method: 'DELETE',
            headers: {
                'X-Tenant': currentProjectInfo.code
            },
        }, cb)
    }

    /**
     * cancel
     * @param peerPaymentCode
     * @param cb
     */
    static cancelPeerPayment(peerPaymentCode, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/payment/peer_payments/${peerPaymentCode}/cancel`,
            method: 'POST',
            headers: {
                'X-Tenant': currentProjectInfo.code
            },
        }, cb)
    }

    /**
     * @param peerPaymentCode
     * @param params
     * @param cb
     */
    static getPeerPaymentFinancials(peerPaymentCode, params, cb) {
        if (typeof params === 'function') {
            cb = params
        }

        let queryString = {}
        if (typeof params === 'object') {
            queryString = params
        }

        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/payment/peer_payments/${peerPaymentCode}/financials`,
            method: 'GET',
            queryString,
            headers: {
                'X-Tenant': currentProjectInfo.code
            },
        }, cb)
    }

    /**
     * cancel
     * @param peerPaymentCode
     * @param cb
     */
    static getDetailPeerPaymentServices(peerPaymentCode, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/john/customer/peer_payments/${peerPaymentCode}/services`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            },
        }, cb)
    }

    /**
     * cancel
     * @param peerPaymentCode
     * @param cb
     */
    static getDetailPeerPaymentFees(peerPaymentCode, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/john/customer/peer_payments/${peerPaymentCode}/fees`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            },
        }, cb)
    }

    /**
     * @param cb
     */
    static getMarkupRateGroups(cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/customer/markup_rate_groups`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            },
        }, cb)
    }


    /**
     * @param cb
     */
    static getPeerPaymentService(configGroupId, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/john/categories/peer_payment_services${configGroupId ? `?configGroupId=${configGroupId}` : ''}`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            },
        }, cb)
    }

    /**
     * @param cb
     */
    static getPeerPaymentFees(configGroupId, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/john/categories/peer_payment_fees${configGroupId ? `?configGroupId=${configGroupId}` : ''}`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            },
        }, cb)
    }

    /**
     * @param cb
     */
    static getPeerPaymentDaily(cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/customer/summaries/daily_summary`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            },
        }, cb)
    }

    /**
     * Lấy danh sách comment
     * @param page
     * @param code
     * @param cb
     */
    static getPeerPaymentComments(code, page, cb) {
        PolimClient.request({
            endpoint: `/customer/peer_payments/${code}/comments?sort=timestamp:DESC&page=${page}&size=25`,
            method: 'GET'
        }, cb)
    }

    /**
     * Delete Comment bản thân
     * @param code
     * @param id
     * @param cb
     */
    static deletePeerPaymentComment(code, id, cb) {
        PolimClient.request({
            endpoint: `/customer/peer_payments/${code}/comments/${id}`,
            method: 'DELETE'
        }, cb)
    }

    /**
     * Tạo comment
     * @param code
     * @param data
     * @param cb
     */
    static createPeerPaymentComment(code, data, cb) {
        PolimClient.request({
            endpoint: `/customer/peer_payments/${code}/comments`,
            method: 'POST',
            body: data
        }, cb)
    }

    /**
     * kiểm tra xem địa chỉ có gần kho nhận
     * @param id
     * @param cb
     */
    static checkWareHouse(id, cb) {
        PolimClient.request({
            endpoint: `/customer/addresses/${id}/check_warehouse`,
            method: 'GET',
        }, cb)
    }

    /**
     * lây ra lịch sử
     * @param query
     * @param cb
     */
    static getHistoryPeerPayment(code, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/payment/peer_payments/${code}/milestones?sort=timestamp:desc`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }

    /**
     * xuất excel
     * @param query
     * @param body
     * @param cb
     */
    static exportPayments(query, body, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};

        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/export/peer_payments${query || ''}`,
            method: 'POST',
            headers: {
                'X-Tenant': currentProjectInfo.code
            },
            body
        }, cb)
    }

    /**
     * lấy về danh sách ứng dụng
     * @param cb
     */
    static getApplication(cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_KUN_LUN}/customer-profile/customer/pat?limit=1000&offset=0&sort=createdAt:desc`,
            method: 'GET',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }

    /**
     * Tạo ứng dụng
     * @param cb
     */
    static createApplication(body, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_KUN_LUN}/customer-profile/customer/pat`,
            method: 'POST',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }, body
        }, cb)
    }
    /**
     * Xóa ứng dụng
     * @param cb
     */
    static deleteApplication(id, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_KUN_LUN}/customer-profile/customer/pat/${id}`,
            method: 'DELETE',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }
    /**
     * Sửa webhook ứng dụng
     * @param cb
     */
    static updateApplication(id, body, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_KUN_LUN}/customer-profile/customer/pat/${id}`,
            method: 'PATCH',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }, body
        }, cb)
    }
    /**
     * Test webhook ứng dụng
     * @param cb
     */
    static testWebhook(id, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_KUN_LUN}/customer-profile/customer/pat/${id}/webhook/test`,
            method: 'POST',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }
    /**
     * Tạo tài khoản m24
     * @param cb
     */
    static registerConnection(body, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_KUN_LUN}/customer-profile/customer/integration/wasp/register`,
            method: 'POST',
            body,
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }
    /**
     * lấy token để kết nôi tài khoản m24
     * @param cb
     */
    static getToken(cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_KUN_LUN}/customer-profile/customer/integration/wasp/integrate`,
            method: 'POST',
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }

    static getPeerPaymentLogs(code, query) {
        return new Promise((resolve, reject) => {
            const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
            PolimClient.request({
                endpoint: `${process.env.REACT_APP_JUSTICE}/api/barry/payment/peer_payments/${code}/logs`,
                method: 'GET',
                queryString: query,
                headers: {
                    'X-Tenant': currentProjectInfo.code
                }
            }, (error, response, headers) => {
                if (!error) {
                    resolve({
                        data: response,
                        headers: headers
                    })
                }
                else {
                    reject(error)
                }
            })
        })
    }

    static getBetterOffer(body, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `/customer/alibaba/orders/better-offer`,
            method: 'POST',
            body,
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }

    // thay thì gọi:  .../request-for-pay-by-gobiz-way
    // thì gọi: ..../ask-for-pay-by-gobiz-way
    // fix MOM-4008
    static placeOrderBetterOffer(body, cb) {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        PolimClient.request({
            endpoint: `${process.env.REACT_APP_JUSTICE}/api/clark/payment/peer_payments/ask-for-pay-by-gobiz-way`,
            method: 'POST',
            body,
            headers: {
                'X-Tenant': currentProjectInfo.code
            }
        }, cb)
    }

        /**
     * disconnect bifin
     */
        static disconnectBifin(query, cb) {
            PolimClient.request({
                endpoint: `/customer/third-parties/shopkeeper/disconnect`,
                method: 'POST',
            }, cb)
        }

        static getListCoupon(page, size, cb) {
            PolimClient.request({
                endpoint: `/customer/customer_coupon?page=${page}&size=${size}`,
                method: 'GET',
            }, cb)
        } 
        
        static getCommentsPosedon(code, page, cb) {
            PolimClient.request({
                endpoint: `/customer/comments/peer_payments/${code}?sort=createdAt:DESC&page=${page}&size=25`,
                method: 'GET',
            }, cb)
        }
    
        static createCommentInPeerPayment(code,body, cb){
            PolimClient.request({
                endpoint: `/customer/comments/peer_payments/${code}`,
                method: 'POST',
                body: body
            }, cb)
        }
        static getAttachmentPosedon(code, cb) {
            PolimClient.request({
                endpoint: `/customer/comments/peer_payments/${code}/attachments`,
                method: 'GET',
            }, cb)
        }
}
