/* eslint-disable array-callback-return */
import React from 'react'
import moment from 'moment'
import MainLayout from '../../components/Layout/MainLayout'
import Loading from '../../components/Loading'
import {Link} from 'react-router-dom'
import {Button, Col, DatePicker, Empty, Input, Row, Spin, Table, Tag, Typography} from 'antd';
import * as AntdIcon from "@ant-design/icons"

import DeliveryServices from "../../services/delivery.service";

import {LocalStore} from "../../utils/LocalStore";

import {getUrlQueryString} from "../../utils/helper";
import {parseQueryStringToObject, quantityFormat, updateQueryStringParameter} from "../../utils/stringUtils";
import {withNamespaces} from "react-i18next";

let pageSize = 25;
class Delivery extends React.Component {
    constructor(props) {
        super(props);
        this.isUnmounted = false;
        this.state = {
            page: 1,
            pageSize: pageSize,
            isLoading: true,
            listDelivery: [],
            listChecked: {},
            datetimeRange: [],
            startTime: undefined,
            endTime: undefined,
            query: ''
        }
    }

    componentDidMount() {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null;
        window.addEventListener("popstate", this.onPopState.bind(this));

        if (typeof LocalStore.getInstance().read('currentLoggedUser') === 'object') {
            this.setState({isLoading: true}, () => {
                this.loadFromQueryString();

                this.getOrderStatuses();
            })
        }
    }

    /**
     * Khi unmount ra thì bỏ sự kiện popstate
     */
    componentWillUnmount() {
        this.isUnmounted = true;
        window.removeEventListener("popstate", this.onPopState.bind(this));
    }

    /**
     * On popstate
     */
    onPopState() {
        if (!this.isUnmounted) this.setState({isLoading: true}, ()=> {
            this.loadFromQueryString();
        });
    };

    /**
     * Load từ QueryString (dành cho lần load đầu tiên)
     */
    loadFromQueryString = () => {
        let queryString = getUrlQueryString();
        let queryObj = parseQueryStringToObject(queryString) || {};

        // console.log('queryObj', queryObj);
        // chuyển giá trị từ queryObj vào state;
        let arrayValue = [];
        //reset các param khi load từ url
        let listChecked = {};
        let query = '';
        let startTime = undefined;
        let endTime = undefined;
        for (let p in queryObj) {
            if (p === 'statuses') {
                // xử lý với obj list checked
                arrayValue = queryObj[p].split(',');
                // console.log('arrayValue', arrayValue)
                listChecked[p] = arrayValue;
            } else if (p === 'createdFrom') {
                // xử lý với với timestamp
                startTime = decodeURIComponent(queryObj['createdFrom']);
            } else if (p === 'createdTo') {
                // xử lý với với timestamp
                endTime = decodeURIComponent(queryObj['createdTo']);
            } else {
                // xử lý với query
                query = decodeURIComponent(queryObj['query'] || '')
            }
        }

        this.setState({
            query,
            startTime,
            endTime,
            listChecked,
            page: queryObj.page,
            pageSize: queryObj.size || pageSize,
        }, ()=> {
            this.loadData();
        });
    };

    /**
     * load data
     */
    loadData = (cb) => {
        const {page, pageSize} = this.state;
        let queryString = this.buildQueryString();
        if (queryString) {
            queryString = queryString + `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        } else {
            queryString = `?page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        }
        this.setState({showProgressBar: true}, () => {
            DeliveryServices.getCustomerDelivery(queryString, (err, res, headers) => {
                if (!err) {
                    this.setState({
                        expandedRow: [],
                        listDelivery: res,
                        metadata: {
                            pageCount: headers['x-page-count'],
                            page: headers['x-page-number'],
                            size: headers['x-page-size'],
                            total: headers['x-total-count'],
                        }
                    }, () => {
                        if (this.mainLayoutElement && this.mainLayoutElement.scrollLayoutElement) this.mainLayoutElement.scrollLayoutElement.scrollTop = 0;
                        //window.scrollTo(0, 0);
                    });
                }
                if(cb) cb();
                this.setState({showProgressBar: false, isLoading: false})
            })
        })
    };

    onPageChanged = (page) => {
        this.setState({page: page}, () => {
            let queryString = getUrlQueryString();
            queryString = updateQueryStringParameter(queryString ? '?'+ queryString : '', 'page', page);
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: queryString
            });
            // window.scrollTo(0, 0);
        });
    };

    /**
     * lấy ra danh sách trạng thái đơn
     */
    getOrderStatuses = () => {
        DeliveryServices.getDeliveryStatuses((err, res, headers) => {
            if (!err) {
                this.setState({
                    listStatuses: res,
                });
            }
        })
    };

    /**
     * lấy ra danh sách package của delivery
     */
    getDeliveryPackages = (code) => {
        const {listDeliveryPackages = {}, isLoadingPackages = {}} = this.state;
        isLoadingPackages[code] = true;
        this.setState({isLoadingPackages, showProgressBar: true}, () => {
            DeliveryServices.getDeliveryPackages(code, (err, res, headers) => {
                isLoadingPackages[code] = false;
                if (err === null) {
                    listDeliveryPackages[code] = res || [];
                    this.setState({
                        isLoadingPackages,
                        showProgressBar: false,
                        listDeliveryPackages
                    });
                } else {
                    this.setState({showProgressBar: false, isLoadingPackages})
                }
            });
        })
    };

    /**
     * lấy ra prop giá trị của obj prop
     * @param props
     * @returns {*}
     */
    getValuePropOfProp = (props) => {
        switch (props) {
            case 'statuses':
                return 'code';
            default:
                return 'id'
        }
    };

    /**
     * xây dựng query string theo các giá trị trong state
     * @returns {string}
     */
    buildQueryString = () => {
        let {listChecked, startTime, endTime, query} = this.state;
        let queryString = '';
        for (let p in listChecked) {
            let value = '';
            if (Array.isArray(listChecked[p]) && listChecked[p].length > 0) {
                listChecked[p].map(x => {
                    value = value + x + ',';
                });

                value = value.slice(0, -1)
            }
            if (value) queryString = updateQueryStringParameter(queryString, p, value)
        }

        if (startTime) {
            queryString = updateQueryStringParameter(queryString, 'createdFrom', startTime)
        }

        if (endTime) {
            queryString = updateQueryStringParameter(queryString, 'createdTo', endTime)
        }

        if (query) {
            queryString = updateQueryStringParameter(queryString, 'query', query.trim())
        }

        return queryString
    };

    onClearSearch = () => {
        this.setState({
            page: 1,
            query: '',
            startTime: undefined,
            endTime: undefined,
            listChecked: {},
        }, () => {
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: this.buildQueryString()
            });
        })
    };

    /**
     * xử lý với dữ liệu search trong state
     */
    onSubmitSearch = () => {
        this.setState({page: 1}, () => {
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: this.buildQueryString()
            });
        })
    };

    startTimeChange = (date) => {
        this.setState({
            startTime: date ? date.startOf('day').toISOString() : undefined
        })
    };

    endTimeChange = (date) => {
        this.setState({
            endTime: date ? date.endOf('day').toISOString() : undefined
        })
    };

    disabledEndDate = (endValue) => {
        const startValue = this.state.startValue;
        if (!endValue || !startValue) {
            return false;
        }
        return endValue.valueOf() <= startValue.valueOf();
    };

    onCheckBoxCheck = (item, prop) => {
        let {listChecked} = this.state;

        let arrayPropChecked = listChecked[prop] || [];
        if (arrayPropChecked.find(x => x === item[this.getValuePropOfProp(prop)])) {
            arrayPropChecked = arrayPropChecked.filter(x => x !== item[this.getValuePropOfProp(prop)]);
        } else {
            arrayPropChecked.push(item[this.getValuePropOfProp(prop)]);
        }

        listChecked[prop] = arrayPropChecked;

        this.setState({
            listChecked
        })
    };

    /**
     * render orderStatus Search
     * @param item
     * @param index
     * @param prop
     */
    renderStatusSearchItem = (item, index, prop) => {
        const arrayPropChecked = this.state.listChecked[prop] || [];
        // console.log('arrayPropChecked', arrayPropChecked)
        let checked = Array.isArray(arrayPropChecked) && arrayPropChecked.find(x => x === item[this.getValuePropOfProp(prop)]) !== undefined;
        return <Tag.CheckableTag
            key={index}
            onChange={() => this.onCheckBoxCheck(item, prop)}
            className={`_check-${prop}-item cursor-pointer txt-size-h7 robotoregular txt-capitalize box-shadow-blue pdl10 pdr10`}
            checked={checked}
        >
            <Typography.Link style={checked ? {color: '#ffffff'} : {}}>
            {item.name}
            </Typography.Link>
        </Tag.CheckableTag>
    };

    /**
     * render header page
     * @returns {*}
     */
    renderSearchBox = () => {
        const {t} = this.props;
        const {
            listStatuses = [],
            startTime,
            endTime,
            query,
        } = this.state;

        return (
            <div className="searchbox bg-color-white pd20 mgbt20">
                <Row className={'bd-color-gray borderdotted-bootom-2x pdbt20'}>
                    <Col span={24} className="dpl-flex ">
                        <span className={'searchbox__label txt-color-black txt-size-h7 robotoregular'}>{t('delivery.status')}:</span>
                        <div className={'txt-size-h7 txt-color-blue robotoregular txt-capitalize width-100-percent flex-1-1'}>
                            {listStatuses.map((item, index) => this.renderStatusSearchItem(item, index, 'statuses'))}
                        </div>
                    </Col>
                </Row>
                <Row className={'dpl-flex bd-color-gray borderdotted-bootom-2x pdbt20 pdt15'}>
                    <Col span={12} className="pdr10">
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('delivery.search_code')}:
                        </div>
                        <Input
                            value={query}
                            onChange={(e) => this.setState({query: e.target.value})}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.onSubmitSearch()
                                }
                            }}
                            className={'_search-box-input-query'}
                        />
                    </Col>
                    <Col span={12} className="pdl10">
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('delivery.created_time')}:
                        </div>
                        {/*<DatePicker.RangePicker*/}
                            {/*className={'_search-date-time width-100-percent'}*/}
                            {/*format={'DD/MM/YYYY'}*/}
                            {/*value={datetimeRange.length > 0 ? [moment(datetimeRange[0]), moment(datetimeRange[1])] : []}*/}
                            {/*onChange={this.dateTimeChange}*/}
                        {/*/>*/}
                        <Row gutter={20}>
                            <Col span={12} >
                                <DatePicker
                                    disabledDate={this.disabledStartDate}
                                    className="_datepicker width-100-percent"
                                    format={'DD/MM/YYYY'}
                                    value={startTime ? moment(startTime) : undefined}
                                    placeholder={t('delivery.start_date')}
                                    onChange={this.startTimeChange}
                                    // onOpenChange={this.handleStartOpenChange}
                                />
                            </Col>
                            <Col span={12} >
                                <DatePicker
                                    disabledDate={this.disabledEndDate}
                                    className="_datepicker width-100-percent"
                                    format={'DD/MM/YYYY'}
                                    value={endTime ? moment(endTime) : undefined}
                                    placeholder={t('delivery.end_date')}
                                    onChange={this.endTimeChange}
                                    // onOpenChange={this.handleEndOpenChange}
                                />
                            </Col>
                        </Row>

                    </Col>
                </Row>
                <div
                    className=" dpl-flex align-items-center justify-content-between pdt10"
                >
                    <div />
                    <div className="dpl-flex align-items-center">
                        <span onClick={this.onClearSearch} className="_btn-clear mgr20 txt-color-gray txt-size-h8 cursor-pointer robotoregular">
                            <AntdIcon.SyncOutlined type={'sync'} className={'mgr5'}/> {t('order.filter_refresh')}
                        </span>
                        <Button onClick={this.onSubmitSearch} type="primary" className={'_btn-search min-width-200'}>{t('order.search')}</Button>
                    </div>
                </div>
            </div>

        )
    };

    renderExpandRow = (record) => {
        const {t} = this.props;
        const {
            listDeliveryPackages = {},
            isLoadingPackages = {},
        } = this.state;
        if (isLoadingPackages[record.code]) return <div className="dpl-flex align-items-center justify-content pdt10 pdbt10">
            <Spin indicator={<AntdIcon.Loading3QuartersOutlined type="loading" style={{ fontSize: 24 }} spin />} />
        </div>;

        const list = listDeliveryPackages[record.code] || [];

        if (list.length === 0) return <Empty
            image={require('../../resources/images/empty-image.png')}
            description={t('message.empty')}
        />;

        const columns = [{
            title: t('delivery.package_code'),
            dataIndex: 'code',
            key: 'code',
            render: (text) => <span className={'_order-item-code listorders__items_code txt-color-black txt-size-h7 robotoregular'}>
                {text}
            </span>
        }, {
            title: t('delivery.order'),
            dataIndex: 'orderCode',
            key: 'orderCode',
            render: (text, row) => <Link to={row.isShipment ? `/shipments/${text}` : `/orders/${text}`} className="_table-row-orderCode txt-uppercase">{text}</Link>
        }, {
            title: t('delivery.created_at'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text) => <span className="_table-row-total-package txt-color-black txt-size-h7 robotoregular">{moment(text).format('HH:mm DD/MM/YYYY')}</span>
        }, {
            title: t('delivery.volumetric'),
            dataIndex: 'volumetric',
            key: 'volumetric',
            render: (text) => <span className="_table-row-provisionalAmount txt-color-black txt-size-h7 robotomedium">{text ? quantityFormat(text) + ' cm3' : t('delivery.undefined')}</span>
        }, {
            title: t('delivery.weight'),
            dataIndex: 'weight',
            key: 'weight',
            render: (text, record) => {
                const weight = record.actualWeight; //Math.max(record.actualWeight, record.dimensionalWeight);
                return <span className="_table-row-weight txt-color-black txt-size-h7 robotomedium">{Number.isFinite(weight) ? `${weight} kg` : t('delivery.undefined')}</span>
            }
        }];

        return <Table
            className={'_table-data custom-empty'}
            rowKey={'id'}
            columns={columns}
            dataSource={list}
            rowClassName={() => `_table-row`}
            pagination={{
                className: '_pagination text-center width-100-percent mgt20',
                hideOnSinglePage: true,
                current: 1,
                total: list.length,
                pageSize: list.length,
                // onChange: (page) => this.onPageChanged(page)
            }}
        />
    };

    render() {
        const {t} = this.props;
        const {
            listDelivery, isLoading, metadata,
            listStatuses = [], expandedRow = []
        } = this.state;
        const columns = [{
            title: t('delivery.delivery_code'),
            dataIndex: 'code',
            key: 'code',
            render: (text) => <span className="_table-row-code txt-uppercase">{text}</span>
        }, {
            title: t('delivery.created_time'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            // className: 'hidden-md',
            render: (text) => <span className="_table-row-createdAt txt-uppercase ">{moment(text).format('HH:mm DD/MM/YYYY')}</span>
        }, {
            title: t('delivery.shipping_method'),
            dataIndex: 'shippingMethod',
            key: 'shippingMethod',
            // className: 'hidden-md',
            render: (text) => <span className="_table-row-shippingMethod ">{text ? (text.name ? text.name : text.code || '---') : '---'}</span>
        },


        //     {
        //     title: t('delivery.delivery_time'),
        //     dataIndex: 'deliveryTime',
        //     key: 'deliveryTime',
        //     className: 'hidden-md',
        //     render: (text) => <span className="_table-row-deliveryTime txt-uppercase">{text ? moment(text).format('HH:mm DD/MM/YYYY') : '---'}</span>
        // }, {
        //     title: t('delivery.time'),
        //     dataIndex: 'timeColumn',
        //     key: 'timeColumn',
        //     className: 'hidden-xl',
        //     render: (text, record) => {
        //         return <div>
        //             <p className="_table-row-createdAt mgbt0">{t('delivery.create')}: {moment(record.createdAt).format('HH:mm DD/MM/YYYY')}</p>
        //             <p className="_table-row-deliveryTime mgbt0">{t('delivery.delivery')}: {record.deliveryTime ? moment(record.deliveryTime).format('HH:mm DD/MM/YYYY') : '---'}</p>
        //         </div>
        //     }
        // },
            {
            title: t('delivery.status'),
            dataIndex: 'status',
            key: 'status',
            render: (text) => {
                const itemStatus = listStatuses.find(x => x.code === text) || {};
                return <Tag style={{backgroundColor: itemStatus.color || '#898989'}} className={'_table-row-status listorders__items_right--status'}>{itemStatus.name}</Tag>
            },
        }, {
            title: t('delivery.weight'),
            dataIndex: 'totalWeight',
            key: 'totalWeight',
            className: 'txt-right',
            render: (text) => (
                <span className={'_table-row-weight'}>
                    {text ? `${quantityFormat(text)} kg` : t('delivery.undefined')}
                </span>
            ),
        },
        //     {
        //     title: t('delivery.cod'),
        //     dataIndex: 'codAmount',
        //     key: 'codAmount',
        //     className: 'txt-right',
        //     render: (text) => {
        //         return <span className={'_table-row-cod'}>
        //             {moneyFormat(moneyCeil(text))}
        //         </span>
        //     },
        // },
            {
            title: t('delivery.address'),
            dataIndex: 'address',
            key: 'address',
            render: (text, record) => (
                <span className="_table-row-address whitespace-pre-wrap">
                    {record.address && record.address.fullName + ', ' + record.address.address + ', ' + record.address.location.display}
                </span>
            ),
        }, {
            title: t('delivery.note'),
            dataIndex: 'note',
            key: 'note',
            render: (text, record) => (
                <span className="_table-row-address whitespace-pre-wrap">
                    {record.note ? record.note : '---'}
                </span>)
        }];

        return <MainLayout
            ref={ref => this.mainLayoutElement = ref}
            {...this.props}
            showProgressBar={this.state.showProgressBar}
            headerTitle={t('delivery.list_title')}
            title={t('delivery.list_title')}
        >
            <div className={'container-common pdbt30 mgr20'}>
                {this.renderSearchBox()}
                <div className="dpl-flex justify-content-between align-items-center bg-color-white pd20 border-bottom-1x bd-color-gray">
                    {
                        metadata
                        ?
                        <span className={'txt-color-black txt-size-h5 robotobmedium'}>{t('delivery.list_title')} (<span className={'_total-item-in-page'}>{quantityFormat(metadata.total)}</span>)</span>
                        :
                        <span />
                    }
                    <Link to={'/delivery/create'} className={'_btn-create ant-btn ant-btn-primary'}>{t('delivery.create_title')}</Link>
                </div>
                <div className="pd20 bg-color-white">
                    {
                        Array.isArray(listDelivery) && listDelivery.length > 0
                            ?
                            <Table
                                className={'_table-data custom-empty'}
                                rowKey={record=> record.code}
                                columns={columns}
                                dataSource={listDelivery}
                                rowClassName={() => `_table-row vertical-align-text-top`}
                                expandedRowKeys={expandedRow}
                                expandedRowRender={this.renderExpandRow}
                                expandIcon={(expand) => {
                                    return expand.expanded ? <AntdIcon.DownOutlined
                                            onClick={(e) => expand.onExpand(expand.record, e)}
                                        />
                                    :
                                        <AntdIcon.RightOutlined
                                            onClick={(e) => expand.onExpand(expand.record, e)}
                                        />
                                }}
                                onExpand={(expanded, record) => {
                                    if (expanded) {
                                        this.setState({
                                            expandedRow: [record.code]
                                        });
                                        this.getDeliveryPackages(record.code)
                                    } else {
                                        this.setState({
                                            expandedRow: []
                                        });
                                    }
                                }}
                                pagination={{
                                    className: '_pagination text-center width-100-percent mgt20',
                                    hideOnSinglePage: true,
                                    current: metadata ? parseFloat(metadata.page) + 1 : 1,
                                    total: metadata ? parseFloat(metadata.total) : 1,
                                    pageSize: metadata ? parseFloat(metadata.size) : pageSize,
                                    onChange: (page) => this.onPageChanged(page)
                                }}
                            />
                            :
                            isLoading ?
                                <Loading/> :
                                <div className="pdt50 pdbt60 bg-color-white">
                                    <Empty
                                        image={require('../../resources/images/empty-image.png')}
                                        description={t('message.empty')}
                                    />
                                </div>
                    }
                </div>
            </div>
        </MainLayout>;
    }
}

export default withNamespaces()(Delivery);
