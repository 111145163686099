import {useEffect, useState} from 'react';
import {Button, Modal, Form, Input, Row, Col} from 'antd';
import {v4 as uuidv4} from 'uuid';
import lodash from "lodash";

const WebhookUpdateModal = ({visible, onUpdate, onCancel, t, loading, data}) => {
    const [form] = Form.useForm();
    const [item, setItem] = useState(data);
    useEffect(() => {
        if (data) {
            if (!data.webhookSecret) {
                let secret = uuidv4();
                setItem({
                    ...item, webhookSecret: secret
                });
                form.setFieldsValue({webhookSecret: secret});
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
   const reset=()=>{
       let secret = uuidv4();
       setItem({
           ...item, webhookSecret: secret
       });
       form.setFieldsValue({webhookSecret: secret});
   }
    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            width={700}
            className={'_modal-edit-webhook'}
            title={t('connect_application.title_modal_webhook')}
            footer={[
                <Button className="buttonm buttonm__cancel _bt-cancel" key="back" onClick={onCancel}>
                    <span className={'mgr10'}> <i className="fal fa-times"></i></span> {t('button.cancel')}
                </Button>,
                <Button className="buttonm _bt-update" loading={loading} disabled={loading} key="submit" type="" 
                        onClick={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    onUpdate(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                        }}>
                    <span className={'mgr10'}><i className="fal fa-check"></i></span>{t('button.update')}
                </Button>
            ]}
        >
            <Form
                form={form}
                layout="horizontal"
                name="form_in_modal"
                labelCol={{span: 8}}
                labelAlign={'left'}
                wrapperCol={{span: 16}}
                initialValues={{
                    webhookSecret: lodash.get(item, 'webhookSecret', ''),
                    webhookUrl: lodash.get(item, 'webhookUrl', ''),
                }}
            >
                <Row><Col span={20}><Form.Item
                    name="webhookSecret"
                    label={t('connect_application.webhook_secret')}
                    rules={[
                        {
                            required: true,
                            message: t("customer_info.required")
                        },
                    ]}
                >
                    <Input.Password className={'_secret'} autoFocus={true}/>

                </Form.Item>
                </Col>
                    <Button onClick={reset} icon={<i className="fas fa-undo-alt txt-size-h9 pdr5"></i>} className={'_bt-reset buttonm buttonm__cancel mgl10'}>Reset</Button>
                </Row>
                <Row><Col span={20}><Form.Item
                    name="webhookUrl"
                    label={t('connect_application.webhook_url')}
                    rules={[
                        {
                            required: true,
                            message: t("customer_info.required")
                        }, {
                            type: 'url',
                            message: t("message.invalid_format")
                        },
                    ]}
                >
                    <Input className={'_url'}/>
                </Form.Item>
                </Col>
                </Row>
            </Form>
        </Modal>
    );
};
export default WebhookUpdateModal;
