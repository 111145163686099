import React, { useEffect, useState } from "react"
import InputItem from "../../../../components/commons/Input"
import { first, get } from "lodash"
import SuperSelect from "../../../../components/commons/Select/SuperSelect"
import { FloatLabel } from "../../../../components/commons/Select"
import CustomPagination from "../../../../components/commons/Pagination"

const Filter: React.FC<any> = ({ t, filter, accountsTaobao, onSearch, onClearParams, setFilter, pagination, ...props }) => {
    const [form, setForm] = useState<any>({})

    useEffect(() => {
        setForm({ ...filter })
    }, [filter])

    const handleChangedForm = (key: string, value: string) => {
        setForm({
            ...form,
            [key]: value,
        })
    }

    const requiredKeys = ['orderCode', 'originalReceiptCode', 'peerpaymentCode']
    const hasAnyKey = requiredKeys.some(key => filter.hasOwnProperty(key));

    return <div className="mb-4 flex items-center justify-between">
        <div className="basis-1/6">
            <InputItem
                title={'Mã đơn hàng'}
                className={"_search-box-input-query"}
                value={get(form, 'orderCode', '')}
                onChange={(event: any) => {
                    handleChangedForm('orderCode', event.currentTarget.value)
                }}
                onKeyDown={(e: any) => {
                    if (e.keyCode === 13) {
                        onSearch({ ...form, orderCode: e.currentTarget.value?.trim() })
                    }
                }}
                allowClear={{
                    clearIcon: <span onClick={() => {
                        if (filter.orderCode) {
                            onSearch({ ...filter, orderCode: "" })
                            handleChangedForm('orderCode', "")
                        }
                    }}><i className="fa-solid fa-circle-xmark"></i></span>
                }}
            />
        </div>
        <div className="ml-4 basis-1/6">
            <InputItem
                title={'Mã HĐG'}
                className={"_search-box-input-query"}
                value={get(form, 'originalReceiptCode', '')}
                onChange={(event: any) => {
                    handleChangedForm('originalReceiptCode', event.currentTarget.value)
                }}
                onKeyDown={(e: any) => {
                    if (e.keyCode === 13) {
                        onSearch({ ...form, originalReceiptCode: e.currentTarget.value?.trim() })
                    }
                }}
                allowClear={{
                    clearIcon: <span onClick={() => {
                        if (filter.originalReceiptCode) {
                            onSearch({ ...filter, originalReceiptCode: "" })
                            handleChangedForm('originalReceiptCode', "")
                        }
                    }}><i className="fa-solid fa-circle-xmark"></i></span>
                }}
            />
        </div>
        <div className="ml-4 basis-1/6">
            <InputItem
                title={'Mã YCTT'}
                className={"_search-box-input-query"}
                value={get(form, 'peerpaymentCode', '')}
                onChange={(event: any) => {
                    handleChangedForm('peerpaymentCode', event.currentTarget.value)
                }}
                onKeyDown={(e: any) => {
                    if (e.keyCode === 13) {
                        onSearch({ ...form, peerpaymentCode: e.currentTarget.value?.trim() })
                    }
                }}
                allowClear={{
                    clearIcon: <span onClick={() => {
                        if (filter.peerpaymentCode) {
                            onSearch({ ...filter, peerpaymentCode: "" })
                            handleChangedForm('peerpaymentCode', "")
                        }
                    }}><i className="fa-solid fa-circle-xmark"></i></span>
                }}
            />
        </div>
        <div className='form-select ml-4 basis-1/6'>
            <FloatLabel label={'Tài khoản kết nối'} value={get(form, 'credentialAccount', get(first(accountsTaobao as any), 'tbgAccount'))}>
                <SuperSelect
                    onChange={(value: any) => {
                        // setFilter({ ...filter, credentialAccount: value })
                        onSearch({ ...filter, credentialAccount: value })
                        setForm({
                            ...form,
                            credentialAccount: value
                        })
                    }}
                    keyCustomize='tbgAccount'
                    className={"_select-bank w-full"}
                    selectedValue={get(form, 'credentialAccount', get(first(accountsTaobao as any), 'tbgAccount'))}
                    options={accountsTaobao}
                    renderOptionLabel={(item: any) => <>
                        <span className={`_service-name txt-size-h8 mr-1 robotoregular txt-color-black`}>{item?.name}</span>
                        {/* <img src={x.logo} alt="Logo Bank" className='object-contain object-left h-[30px]' /> */}
                    </>}
                />
            </FloatLabel>

        </div>
        {hasAnyKey ? <span onClick={onClearParams} className="text-red-100 hover:cursor-pointer flex items-center gap-1">{t('orderTbg.action_delete_search')}</span> : null}
        <div className={`basis-1/6 ml-10`}>
            <CustomPagination
                className={"float-right"}
                pagination={pagination}
                onChange={props.handleChangePage}
            />
        </div>
    </div>
}

export default Filter