/* eslint-disable array-callback-return */
import React, { Fragment } from 'react'
import moment from 'moment'
import MainLayout from '../../components/Layout/MainLayout'
import Loading from '../../components/Loading'
import { Link } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'
import {
    Alert,
    Avatar,
    Button,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Empty,
    Input,
    List,
    Modal,
    notification,
    Popover,
    Row,
    Select,
    Spin,
    Tag,
    Timeline,
    Tooltip,
    Typography,
} from 'antd'
import * as AntdIcon from '@ant-design/icons'
import OrderServices from '../../services/orders.service'
import OrdersService from '../../services/orders.service'

import { LocalStore } from '../../utils/LocalStore'

import { getUrlQueryString } from '../../utils/helper'
import {
    moneyCeil,
    moneyFormat,
    parseQueryStringToObject,
    quantityFormat,
    updateQueryStringParameter,
} from '../../utils/stringUtils'
import CategoriesService from '../../services/categories.service'
import _ from 'lodash'
import lodash from 'lodash'
import saveAs from 'file-saver'
import ExportExcel from '../../components/ExportExcel'
import queryString from 'query-string'
import ModalPaymentBifin from './components/ModalPaymentBifin'
import BifinService from '../../services/bifin.service'

let currentLoggedUser = null
let pageSize = 25
class Orders extends React.Component {
    constructor(props) {
        super(props)
        this.isUnmounted = false
        this.state = {
            page: 1,
            pageSize: pageSize,
            isLoading: true,
            listOrders: [],
            listChecked: {},
            datetimeRange: [],
            querySearch: '',
            startValue: null,
            endValue: null,
            typeSearch: '',
            milestoneStatusFrom: null,
            milestoneStatusTo: null,
            milestoneStatus: undefined,
            isShowModalConfirm: false,
            isSubmit: false,
            itemSelected: null,
            password: '',
            passwordError: '',
            note: '',
            loanCredits: [],
            needPaid: false
        }
    }

    componentDidMount() {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null
        window.addEventListener('popstate', this.onPopState.bind(this))

        if (typeof LocalStore.getInstance().read('currentLoggedUser') === 'object') {
            currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser')
            this.setState({ isLoading: true }, () => {
                this.loadFromQueryString()

                this.getOrderServices()
                this.getGroupOrderServices()
                this.getOrderStatuses()
                this.getMarketplaces()
            })
        }
    }

    /**
     * Khi unmount ra thì bỏ sự kiện popstate
     */
    componentWillUnmount() {
        this.isUnmounted = true
        window.removeEventListener('popstate', this.onPopState.bind(this))
    }

    /**
     * On popstate
     */
    onPopState() {
        if (!this.isUnmounted)
            this.setState({ isLoading: true }, () => {
                this.loadFromQueryString()
            })
    }

    disabledStartDate = startValue => {
        const endValue = this.state.endValue
        if (!startValue || !endValue) {
            return false
        }
        return startValue.valueOf() > endValue.valueOf()
    }

    disabledEndDate = endValue => {
        const startValue = this.state.startValue
        if (!endValue || !startValue) {
            return false
        }
        return endValue.valueOf() <= startValue.valueOf()
    }

    onChange = (field, value) => {
        this.setState({
            [field]: value,
        })
    }

    onChangeDatePicker = (key, value) => {
        if (value !== null) {
            if (key === 'milestoneStatusFrom') {
                this.onChange(key, value ? value.startOf('day').toISOString() : undefined)
            } else {
                this.onChange(key, value ? value.endOf('day').toISOString() : undefined)
            }
        } else {
            this.onChange(key, value)
        }
    }

    onStartChange = value => {
        if (value !== null) {
            this.onChange('startValue', value ? value.startOf('day').toISOString() : undefined)
        } else {
            this.onChange('startValue', value)
        }
    }

    onEndChange = value => {
        if (value !== null) {
            this.onChange('endValue', value ? value.endOf('day').toISOString() : undefined)
        } else {
            this.onChange('endValue', value)
        }
    }

    checkDateTime(value) {
        if (value === 'undefined' || value === null) {
            return null
        }
        return moment(value)
    }

    /**
     * Load từ QueryString (dành cho lần load đầu tiên)
     */
    loadFromQueryString = () => {
        let { startValue, endValue } = this.state
        let queryString = getUrlQueryString()
        let queryObj = parseQueryStringToObject(queryString) || {}

        // console.log('queryObj', queryObj);
        // chuyển giá trị từ queryObj vào state;
        let arrayValue = []
        //reset các param khi load từ url
        let listChecked = {}
        let querySearch = ''
        let note = ''
        let handlingTimeFrom = ''
        let handlingTimeTo = ''
        let typeSearch = ''
        let cutOffStatus = ''
        let milestoneStatus = undefined
        let milestoneStatusFrom = null
        let milestoneStatusTo = null
        let datetimeRange = []
        let needPaid = false

        for (let p in queryObj) {
            if (p === 'services' || p === 'marketplaces' || p === 'statuses') {
                // xử lý với obj list checked
                arrayValue = queryObj[p].split(',')
                // console.log('arrayValue', arrayValue)
                listChecked[p] = arrayValue
            } else if (p === 'timestampFrom' || p === 'timestampTo') {
                // xử lý với với timestamp
                startValue = decodeURIComponent(queryObj['timestampFrom'])
                endValue = decodeURIComponent(queryObj['timestampTo'])
            } else if (p === 'handlingTimeFrom' || p === 'handlingTimeTo') {
                // xử lý với với timestamp
                if (p === 'handlingTimeFrom') {
                    handlingTimeFrom = decodeURIComponent(queryObj['handlingTimeFrom'])
                } else {
                    handlingTimeTo = decodeURIComponent(queryObj['handlingTimeTo'])
                }
            } else if (p === 'typeSearch' || p === 'cutOffStatus') {
                // xử lý với với timestamp
                typeSearch = decodeURIComponent(queryObj['typeSearch'])
                cutOffStatus = decodeURIComponent(queryObj['cutOffStatus'])
            } else if (p === 'milestoneStatus') {
                milestoneStatus = decodeURIComponent(queryObj['milestoneStatus'])
            } else if (p === 'milestoneStatusFrom' || p === 'milestoneStatusTo') {
                // xử lý với với timestamp
                milestoneStatusFrom = decodeURIComponent(queryObj['milestoneStatusFrom'])
                milestoneStatusTo = decodeURIComponent(queryObj['milestoneStatusTo'])
            } else if (p === 'note') {
                // xử lý với với ghi chú
                note = queryObj['note']
            } else if (p === 'needPaid') {
                needPaid = queryObj['needPaid']
            } else{
                // xử lý với query
                querySearch = decodeURIComponent(queryObj['query'] || '')
            }
        }
        // console.log(queryObj);
        this.setState(
            {
                querySearch,
                note,
                datetimeRange,
                startValue,
                endValue,
                listChecked,
                handlingTimeFrom,
                handlingTimeTo,
                typeSearch,
                cutOffStatus,
                milestoneStatusFrom,
                milestoneStatusTo,
                milestoneStatus,
                page: queryObj.page,
                pageSize: queryObj.size || pageSize,
                needPaid
            },
            () => {
                this.loadData()
            }
        )
    }

    /**
     * load data
     */
    loadData = cb => {
        const { page, pageSize } = this.state
        let queryString = this.buildQueryString()
        if (queryString) {
            queryString = queryString + `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        } else {
            queryString = `?page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        }
        this.setState({ showProgressBar: true }, () => {
            OrderServices.getListOrders(currentLoggedUser.username, queryString, (err, res, headers) => {
                if (!err) {
                    const data = res || []
                    const ordersValid = data.filter(item => item?.contractWithShopkeeper)
                    data.length > 0 && this.getThirdPatiesLoans(ordersValid)
                    this.setState(
                        {
                            listOrders: data,
                            metadata: {
                                pageCount: headers['x-page-count'],
                                page: headers['x-page-number'],
                                size: headers['x-page-size'],
                                total: headers['x-total-count'],
                            },
                        },
                        () => {
                            if (this.mainLayoutElement && this.mainLayoutElement.scrollLayoutElement)
                                this.mainLayoutElement.scrollLayoutElement.scrollTop = 0
                        }
                    )
                }
                if (cb) cb()
                this.setState({ showProgressBar: false, isLoading: false })
            })
        })
    }

    /**
     * get loans
     */
    getThirdPatiesLoans = data => {
        if (Array.isArray(data) && data.length === 0) return
        let codes = data?.map(item => item?.code)
        codes = codes.join(',')
        let queryString = `?orderCodes=${codes}`
        BifinService.getThirdPatiesLoans(queryString, (err, res) => {
            if (!err) {
                this.setState({
                    loanCredits: res?.loanCredits || [],
                })
            }
        })
    }

    onPageChanged = (page, pageSize) => {
        this.setState({ page, pageSize }, () => {
            let locationQuery = lodash.get(this.props.location, 'search', '')
            let queryObj = locationQuery ? parseQueryStringToObject(locationQuery) : {}
            queryObj.page = page
            queryObj.size = pageSize
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: queryString.stringify(queryObj),
            })
            // window.scrollTo(0, 0);
        })
    }

    /**
     * lấy ra danh sách services
     */
    getOrderServices = () => {
        CategoriesService.getOrderServices((err, res, headers) => {
            if (!err) {
                // console.log('headers', headers)
                this.setState({
                    listServices: res,
                    metadataListServices: {
                        pageCount: headers['x-page-count'],
                        page: headers['x-page-number'],
                        size: headers['x-page-size'],
                        total: headers['x-total-count'],
                    },
                })
            }
        })
    }

    /**
     * lấy ra danh sách nhóm services
     */
    getGroupOrderServices = () => {
        CategoriesService.getOrderServicesGroup((err, res, headers) => {
            if (!err) {
                this.setState({
                    listGroupServices: res,
                    metadataListGroupServices: {
                        pageCount: headers['x-page-count'],
                        page: headers['x-page-number'],
                        size: headers['x-page-size'],
                        total: headers['x-total-count'],
                    },
                })
            }
        })
    }

    /**
     * lấy ra danh sách trạng thái đơn
     */
    getOrderStatuses = () => {
        CategoriesService.getOrderStatuses((err, res, headers) => {
            if (!err) {
                this.setState({
                    listStatuses: res,
                })
            }
        })
    }

    /**
     * export excel data
     */
    exportExcel = (password, cb, setError) => {
        const { t } = this.props
        const { page, pageSize } = this.state
        let queryString = this.buildQueryString()
        if (queryString) {
            queryString = queryString + `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        } else {
            queryString = `?page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        }

        if (!password) {
            if (setError) setError(t('cartCheckout.incorrect_pin'))

            return
        }

        this.setState({ showProgressBar: true, onExport: true }, () => {
            OrderServices.exportCsvOrders(queryString, { secret: password }, (err, res, headers) => {
                if (!err) {
                    this.downloadFile(res)
                    if (cb) cb()
                } else {
                    if (err.title === 'invalid_pin' || err.title === 'invalid_password') {
                        if (setError) setError(t('cartCheckout.incorrect_pin'))
                    }
                }
                this.setState({ showProgressBar: false, onExport: false })
            })
        })
    }

    downloadFile = res => {
        if (res && res.headers && res.headers.map) {
            const fileName = res.headers.map['content-disposition'].split('filename=')[1]
            saveAs(res._bodyBlob, fileName)
        }
    }

    /**
     * lấy ra danh sách nguồn hàng
     */
    getMarketplaces = () => {
        CategoriesService.getMarketplaces((err, res, headers) => {
            if (!err) {
                this.setState({
                    listMarketplaces: res,
                })
            }
        })
    }

    /**
     * Lấy danh sách lịch sử các mốc đặt hàng trong đơn
     */
    getListMilestones = orderCode => {
        this.setState(
            {
                isLoadingListMilestones: true,
            },
            () => {
                OrdersService.getOrderMileStones(orderCode, (err, res) => {
                    if (!err) {
                        this.setState({
                            listMilestones: res,
                        })
                    }
                    this.setState({ isLoadingListMilestones: false })
                })
            }
        )
    }

    /**
     * lấy ra prop giá trị của obj prop
     * @param props
     * @returns {*}
     */
    getValuePropOfProp = props => {
        switch (props) {
            case 'statuses':
            case 'marketplaces':
            case 'services':
                return 'code'
            default:
                return 'id'
        }
    }

    /**
     * xây dựng query string theo các giá trị trong state
     * @returns {string}
     */
    buildQueryString = () => {
        let {
            listChecked,
            startValue,
            endValue,
            querySearch,
            typeSearch,
            cutOffStatus,
            handlingTimeFrom,
            handlingTimeTo,
            milestoneStatusFrom,
            milestoneStatus,
            milestoneStatusTo,
            note,
            needPaid

        } = this.state
        let queryString = ''
        for (let p in listChecked) {
            let value = ''
            if (Array.isArray(listChecked[p]) && listChecked[p].length > 0) {
                listChecked[p].map(x => {
                    value = value + x + ','
                })

                value = value.slice(0, -1)
            }
            if (value) queryString = updateQueryStringParameter(queryString, p, value)
        }

        if (startValue !== 'undefined') {
            queryString = updateQueryStringParameter(queryString, 'timestampFrom', startValue)
        }

        if (note) {
            queryString = updateQueryStringParameter(queryString, 'note', note.trim())
        }

        if (endValue !== 'undefined') {
            queryString = updateQueryStringParameter(queryString, 'timestampTo', endValue)
        }

        if (querySearch) {
            queryString = updateQueryStringParameter(queryString, 'query', querySearch.trim())
        }

        if (note) {
            queryString = updateQueryStringParameter(queryString, 'note', note.trim())
        }

        if (needPaid) {
            queryString = updateQueryStringParameter(queryString, 'needPaid', needPaid)
        }

        if (milestoneStatusTo && milestoneStatusFrom) {
            queryString = updateQueryStringParameter(queryString, 'milestoneStatus', milestoneStatus)
            queryString = updateQueryStringParameter(queryString, 'milestoneStatusFrom', milestoneStatusFrom)
            queryString = updateQueryStringParameter(queryString, 'milestoneStatusTo', milestoneStatusTo)
        }

        if (handlingTimeFrom || handlingTimeTo) {
            queryString = updateQueryStringParameter(queryString, 'typeSearch', typeSearch)
            queryString = updateQueryStringParameter(queryString, 'cutOffStatus', cutOffStatus)
            if (handlingTimeFrom) {
                queryString = updateQueryStringParameter(queryString, 'handlingTimeFrom', handlingTimeFrom)
            }
            if (handlingTimeTo) {
                queryString = updateQueryStringParameter(queryString, 'handlingTimeTo', handlingTimeTo)
            }
        }

        return queryString
    }

    onClearSearch = () => {
        this.setState(
            {
                page: 1,
                note: null,
                querySearch: '',
                startValue: null,
                endValue: null,
                listChecked: {},
                cutOffStatus: '',
                handlingTimeFrom: '',
                handlingTimeTo: '',
                typeSearch: '',
                milestoneStatusFrom: null,
                milestoneStatusTo: null,
                milestoneStatus: '',
                needPaid: false
            },
            () => {
                this.props.history.push({
                    pathname: this.props.location.pathname,
                    search: this.buildQueryString(),
                })
            }
        )
    }

    /**
     * xử lý với dữ liệu search trong state
     */
    onSubmitSearch = () => {
        this.setState({ page: 1 }, () => {
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: this.buildQueryString(),
            })
        })
    }

    dateTimeChange = date => {
        // console.log('dateTimeChange ', date, dateString);

        this.setState({
            datetimeRange: date.length > 0 ? [date[0].startOf('day').toISOString(), date[1].endOf('day').toISOString()] : [],
        })
    }

    onCheckBoxCheck = (item, prop) => {
        let { listChecked } = this.state

        let arrayPropChecked = listChecked[prop] || []
        if (arrayPropChecked.find(x => x === item[this.getValuePropOfProp(prop)])) {
            arrayPropChecked = arrayPropChecked.filter(x => x !== item[this.getValuePropOfProp(prop)])
        } else {
            arrayPropChecked.push(item[this.getValuePropOfProp(prop)])
        }

        listChecked[prop] = arrayPropChecked
        this.setState({
            listChecked,
        })
    }

    /**
     * render check box search
     * @param item
     * @param index
     * @param prop
     */
    renderCheckboxSearch = (item, index, prop) => {
        const arrayPropChecked = this.state.listChecked[prop] || []
        return (
            <Checkbox
                className={`_order-${prop}-item txt-size-h7 txt-color-gray robotoregular mgbt10 mgr5`}
                key={index}
                onChange={() => this.onCheckBoxCheck(item, prop)}
                checked={
                    Array.isArray(arrayPropChecked) &&
                    arrayPropChecked.find(x => x === item[this.getValuePropOfProp(prop)]) !== undefined
                }
            >
                {item.name}
            </Checkbox>
        )
    }

    /**
     * render orderStatus Search
     * @param item
     * @param index
     * @param prop
     */
    renderOrderStatusSearchItem = (item, index, prop) => {
        const ordersStatistics = LocalStore.getInstance().read('ordersStatistics') || []
        const arrayPropChecked = this.state.listChecked[prop] || []
        // console.log('arrayPropChecked', arrayPropChecked)
        let checked =
            Array.isArray(arrayPropChecked) && arrayPropChecked.find(x => x === item[this.getValuePropOfProp(prop)]) !== undefined

        return (
            <Tag.CheckableTag
                key={index}
                onChange={() => this.onCheckBoxCheck(item, prop)}
                className={`_order-${prop}-item cursor-pointer txt-size-h7 robotoregular txt-capitalize box-shadow-blue pdl10 pdr10`}
                checked={checked}
            >
                <Typography.Link style={checked ? { color: '#ffffff' } : {}}>
                    {item.name}{' '}
                    {prop === 'statuses' && ordersStatistics.find(x => x.status === item.code)
                        ? `(${quantityFormat(ordersStatistics.find(x => x.status === item.code).total)})`
                        : ''}
                </Typography.Link>
            </Tag.CheckableTag>
        )
    }

    /**
     * render list check box theo group
     * @param group
     * @param index
     */
    renderGroupServices = (group, index) => {
        const { listServices = [] } = this.state

        if (listServices.filter(x => x.serviceGroup && x.serviceGroup.id === group.id).length === 0) {
            return
        }

        return (
            <React.Fragment key={index}>
                <Divider
                    type="vertical"
                    className={'mgr20'}
                />
                {_.sortBy(
                    listServices.filter(x => x.serviceGroup && x.serviceGroup.id === group.id),
                    'position'
                ).map((x, y) => this.renderCheckboxSearch(x, y, 'services'))}
            </React.Fragment>
        )
    }

    renderInputFromTypeSearch = typeSearch => {
        const { t } = this.props
        const { handlingTimeFrom, handlingTimeTo } = this.state
        switch (typeSearch) {
            case 'range':
                return (
                    <Input.Group compact>
                        <Input
                            style={{ width: '50%' }}
                            placeholder={t('order.cut_off_days_from')}
                            value={handlingTimeFrom}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    this.onSubmitSearch()
                                }
                            }}
                            onChange={e => this.setState({ handlingTimeFrom: e.target.value })}
                        />
                        <Input
                            style={{ width: '50%' }}
                            placeholder={t('order.cut_off_days_to')}
                            value={handlingTimeTo}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    this.onSubmitSearch()
                                }
                            }}
                            onChange={e => this.setState({ handlingTimeTo: e.target.value })}
                        />
                    </Input.Group>
                )
            case 'equal':
                return (
                    <Input
                        placeholder={t('order.cut_off_days')}
                        value={handlingTimeFrom}
                        onChange={e =>
                            this.setState({
                                handlingTimeFrom: e.target.value,
                                handlingTimeTo: e.target.value,
                            })
                        }
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                this.onSubmitSearch()
                            }
                        }}
                    />
                )
            case 'from':
                return (
                    <Input
                        placeholder={t('order.cut_off_days')}
                        value={handlingTimeFrom}
                        onChange={e =>
                            this.setState({
                                handlingTimeFrom: e.target.value,
                            })
                        }
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                this.onSubmitSearch()
                            }
                        }}
                    />
                )
            case 'to':
                return (
                    <Input
                        placeholder={t('order.cut_off_days')}
                        value={handlingTimeTo}
                        onChange={e =>
                            this.setState({
                                handlingTimeTo: e.target.value,
                            })
                        }
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                this.onSubmitSearch()
                            }
                        }}
                    />
                )
            default:
                return (
                    <Input.Group compact>
                        <Input
                            style={{ width: '50%' }}
                            placeholder={t('order.cut_off_days_from')}
                            value={handlingTimeFrom}
                            onChange={e => this.setState({ handlingTimeFrom: e.target.value })}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    this.onSubmitSearch()
                                }
                            }}
                        />
                        <Input
                            style={{ width: '50%' }}
                            placeholder={t('order.cut_off_days_to')}
                            value={handlingTimeTo}
                            onChange={e => this.setState({ handlingTimeTo: e.target.value })}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    this.onSubmitSearch()
                                }
                            }}
                        />
                    </Input.Group>
                )
        }
    }

    /**
     * render header page
     * @returns {*}
     */
    renderHeaderPage = () => {
        const {
            cutOffStatus,
            typeSearch,
            listServices = [],
            listGroupServices = [],
            listStatuses = [],
            listMarketplaces = [],
            startValue,
            endValue,
            querySearch,
            note,
            showMoreSearch,
            milestoneStatus,
            milestoneStatusFrom,
            milestoneStatusTo,
            needPaid
        } = this.state
        const { t } = this.props
        return (
            <div className="searchbox bg-color-white mgbt20 pd25 pdbt0">
                <Row className={'pdbt15 borderdotted-bootom-2x bd-color-gray pdbt25'}>
                    <Col
                        span={24}
                        className="dpl-flex"
                    >
                        <span className={'searchbox__label txt-color-black txt-size-h7 robotoregular'}>
                            {t('order.order_status')}:
                        </span>
                        <div className={'txt-size-h7 robotoregular txt-capitalize width-100-percent flex-1-1'}>
                            {listStatuses.map((item, index) => this.renderOrderStatusSearchItem(item, index, 'statuses'))}
                        </div>
                    </Col>
                </Row>
                <Row className={'pdt15 pdbt15 dpl-flex borderdotted-bootom-2x bd-color-gray pdbt25'}>
                    <Col
                        span={6}
                        className="pdr10"
                    >
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('order.input_orderCode_shop')}:
                        </div>
                        <Input
                            value={querySearch}
                            onChange={e => this.setState({ querySearch: e.target.value })}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    this.onSubmitSearch()
                                }
                            }}
                            className={'_search-box-input-query'}
                        />
                    </Col>
                    <Col
                        span={6}
                        className="pdr10"
                    >
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('order.note_title')}:
                        </div>
                        <Input
                            value={note}
                            onChange={e => this.setState({ note: e.target.value })}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    this.onSubmitSearch()
                                }
                            }}
                            className={'_search-box-input-note'}
                        />
                    </Col>
                    <Col
                        span={12}
                        className="pdl10"
                    >
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('order.created_time')}:
                        </div>
                        {/*<DatePicker.RangePicker*/}
                        {/*className={'_search-date-time width-100-percent'}*/}
                        {/*format={'DD/MM/YYYY'}*/}
                        {/*value={datetimeRange.length > 0 ? [moment(datetimeRange[0]), moment(datetimeRange[1])] : []}*/}
                        {/*onChange={this.dateTimeChange}*/}
                        {/*/>*/}

                        <Row gutter={20}>
                            <Col span={12}>
                                <DatePicker
                                    disabledDate={this.disabledStartDate}
                                    className="_datepicker"
                                    style={{ minWidth: '100%' }}
                                    format={'DD/MM/YYYY'}
                                    value={this.checkDateTime(startValue)}
                                    placeholder={t('order.start')}
                                    onChange={this.onStartChange}
                                    // onOpenChange={this.handleStartOpenChange}
                                />
                            </Col>
                            <Col span={12}>
                                <DatePicker
                                    disabledDate={this.disabledEndDate}
                                    style={{ minWidth: '100%' }}
                                    format={'DD/MM/YYYY'}
                                    value={this.checkDateTime(endValue)}
                                    placeholder={t('order.finish')}
                                    onChange={this.onEndChange}
                                    // onOpenChange={this.handleEndOpenChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Checkbox
                            className={'_check-box-confirm mgt10'}
                            onChange={
                                (e)=>{
                                    this.setState({
                                        needPaid: e.target.checked,
                                    
                                    })
                                }}
                            checked={needPaid}
                        >
                            {t('orders.needPayment')}
                        </Checkbox>
                    </Col>
                </Row>
                    
                

                {showMoreSearch && (
                    <React.Fragment>
                        <Row className={'pdbt5 borderdotted-bootom-2x bd-color-gray'}>
                            <Col
                                span={24}
                                className="dpl-flex pdt15"
                            >
                                <span className={'searchbox__label2 txt-color-black txt-size-h7 robotoregular pdr15'}>
                                    {t('order.source_site')}:
                                </span>
                                <span>
                                    {listMarketplaces.map((item, index) =>
                                        this.renderCheckboxSearch(item, index, 'marketplaces')
                                    )}
                                </span>
                            </Col>
                        </Row>

                        {/*<Row className={'pdbt15'}>*/}
                        {/*<Col span={24} className="dpl-flex pdl25 pdr25">*/}
                        {/*<span className={'searchbox__label2 txt-color-black txt-size-h7 robotoregular pdr15'}>Loại đơn:</span>*/}
                        {/*{orderTypes.map((item, index) => this.renderCheckboxSearch(item, index, 'types'))}*/}
                        {/*</Col>*/}
                        {/*</Row>*/}

                        <Row className={'pdbt5 borderdotted-bootom-2x bd-color-gray'}>
                            <Col
                                span={24}
                                className="dpl-flex pdt15"
                            >
                                <span className={'searchbox__label2 txt-color-black txt-size-h7 robotoregular pdr15'}>
                                    {t('order.service')}:
                                </span>
                                <span>
                                    {_.sortBy(
                                        listServices.filter(x => !x.serviceGroup),
                                        'position'
                                    ).map((item, index) => this.renderCheckboxSearch(item, index, 'services'))}

                                    {_.sortBy(listGroupServices, 'position').map((item, index) =>
                                        this.renderGroupServices(item, index)
                                    )}
                                </span>
                            </Col>
                        </Row>

                        <Row className={'pdbt15 mgbt10 borderdotted-bootom-2x bd-color-gray'}>
                            <Col
                                span={24}
                                className="mgt10"
                            >
                                <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                                    {t('order.order_cut_off_time')}:
                                </div>

                                <Row
                                    gutter={10}
                                    className=""
                                >
                                    <Col span={6}>
                                        <Select
                                            className="flex-none width-100-percent"
                                            showSearch
                                            placeholder={t('order.order_status')}
                                            optionFilterProp="children"
                                            value={cutOffStatus || []}
                                            onChange={value => this.setState({ cutOffStatus: value })}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {listStatuses.map((item, index) => (
                                                <Select.Option
                                                    key={index}
                                                    value={item.code}
                                                >
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Col>

                                    <Col span={6}>
                                        <Select
                                            className="flex-none width-100-percent"
                                            showSearch
                                            placeholder={t('order.cut_off_range')}
                                            optionFilterProp="children"
                                            value={typeSearch || []}
                                            onChange={value =>
                                                this.setState({
                                                    typeSearch: value,
                                                    handlingTimeFrom: '',
                                                    handlingTimeTo: '',
                                                })
                                            }
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Select.Option value="range">{t('order.cut_off_range')}</Select.Option>
                                            <Select.Option value="equal">{t('order.cut_off_equal')}</Select.Option>
                                            <Select.Option value="from">{t('order.cut_off_from')}</Select.Option>
                                            <Select.Option value="to">{t('order.cut_off_to')}</Select.Option>
                                        </Select>
                                    </Col>

                                    <Col span={12}>{this.renderInputFromTypeSearch(typeSearch)}</Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className={'pdbt15 mgbt10 borderdotted-bootom-2x bd-color-gray'}>
                            <Col
                                span={24}
                                className="mgt10"
                            >
                                <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                                    {t('order.filter_by_time')}:
                                </div>

                                <Row
                                    gutter={10}
                                    className=""
                                >
                                    <Col span={6}>
                                        <Select
                                            className="flex-none width-100-percent"
                                            showSearch
                                            placeholder={t('order.filter_by_time')}
                                            optionFilterProp="children"
                                            value={milestoneStatus || []}
                                            onChange={value => this.setState({ milestoneStatus: value })}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {listStatuses.map((item, index) => (
                                                <Select.Option
                                                    key={index}
                                                    value={item.code}
                                                >
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Col>

                                    <Col span={12}>
                                        <Row
                                            gutter={12}
                                            className=""
                                        >
                                            <Col span={12}>
                                                <DatePicker
                                                    disabledDate={this.disabledStartDate}
                                                    className="_datepicker"
                                                    style={{ minWidth: '100%' }}
                                                    format={'DD/MM/YYYY'}
                                                    value={this.checkDateTime(milestoneStatusFrom)}
                                                    placeholder={t('order.start')}
                                                    onChange={value => this.onChangeDatePicker('milestoneStatusFrom', value)}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <DatePicker
                                                    disabledDate={this.disabledEndDate}
                                                    style={{ minWidth: '100%' }}
                                                    format={'DD/MM/YYYY'}
                                                    value={this.checkDateTime(milestoneStatusTo)}
                                                    placeholder={t('order.finish')}
                                                    onChange={value => this.onChangeDatePicker('milestoneStatusTo', value)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}

                <div className=" dpl-flex align-items-center justify-content-between pdt10 pdbt10">
                    <span
                        className="_btn-show-more-search txt-size-h7 cursor-pointer robotoregular"
                        onClick={() => this.setState({ showMoreSearch: !this.state.showMoreSearch })}
                    >
                        {showMoreSearch ? (
                            <AntdIcon.UpOutlined className={'mgr5'} />
                        ) : (
                            <AntdIcon.DownOutlined className={'mgr5'} />
                        )}{' '}
                        {!showMoreSearch ? t('order.search_expand') : t('order.search_collapse')}
                    </span>

                    <div className="dpl-flex align-items-center">
                        <span
                            onClick={this.onClearSearch}
                            className="_btn-clear mgr20 txt-color-gray txt-size-h8 cursor-pointer robotoregular"
                        >
                            <AntdIcon.SyncOutlined
                                type={'sync'}
                                className={'mgr5'}
                            />{' '}
                            {t('order.filter_refresh')}
                        </span>
                        <Button
                            onClick={this.onSubmitSearch}
                            type="primary"
                            className={'_btn-search min-width-200'}
                        >
                            {t('order.search')}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    /**
     * xác nhận đã nhận hàng
     */
    confirmReceivedOrder = item => {
        const { isSubmit } = this.state
        const { t } = this.props
        if (isSubmit === true) return
        this.setState({ showProgressBar: true, isSubmit: true }, () => {
            OrdersService.confirmReceivedOrder(item.code, (err, res, headers) => {
                if (!err) {
                    this.loadData()
                    notification['success']({
                        message: t('orderDetail.successfully_change_status'),
                    })
                } else {
                    if (err.title === 'order_status_not_allow') {
                        notification['error']({
                            message: t('orderDetail.changed_status'),
                        })
                    } else {
                        if (err.statusCode !== 'Network fail')
                            notification['error']({
                                message: t(err.message),
                            })
                    }
                }
                this.setState({ showProgressBar: false, isSubmit: false })
            })
        })
    }

    renderModalConfirmPin = () => {
        const { t } = this.props
        const { isSubmit, showModalConfirm, password, savePassword, passwordError, itemSelected } = this.state

        return (
            <Modal
                title={t('cartCheckout.confirm_pin')}
                open={showModalConfirm}
                onOk={() => this.confirmPayOrder(password, itemSelected)}
                confirmLoading={isSubmit}
                onCancel={() =>
                    this.setState({
                        showModalConfirm: false,
                        password: '',
                    })
                }
                okText={t('modal.confirm')}
                cancelText={t('modal.cancel')}
                okButtonProps={{ className: '_btn-submit-confirm' }}
                cancelButtonProps={{ className: '_btn-cancel-confirm' }}
            >
                <p className="mgbt5">{t('modal.confirm_payment')}</p>
                <div className="dpl-flex align-items-start mgbt5">
                    <Avatar
                        className="_avatar-seller mgr10 flex-none"
                        shape="square"
                        size={40}
                        src={itemSelected && itemSelected.image}
                    />
                    <div className="txt-color-black txt-size-h7 break-word">
                        <div className="">#{itemSelected && itemSelected.code}</div>
                        <div className="">
                            {itemSelected && itemSelected.totalUnpaid >= 0 ? t('order.need_payment') : t('order.excess_cash')}:{' '}
                            {itemSelected && moneyFormat(moneyCeil(itemSelected.totalUnpaid), null, true)}
                        </div>
                    </div>
                </div>
                <p className="mgbt5">{t('cartCheckout.please_input_pin')}</p>
                <Input.Password
                    className={'_input-password'}
                    placeholder={t('cartCheckout.input_pin')}
                    value={password}
                    onChange={e => this.setState({ password: e.target.value })}
                    onKeyDown={e => {
                        if (e.keyCode === 13) {
                            this.confirmPayOrder(password, itemSelected)
                        }
                    }}
                />
                {passwordError && <p className="_txt-error-confirm mgt5 mgbt0 txt-error ">{passwordError}</p>}

                <Checkbox
                    className={'_check-box-confirm mgt10'}
                    onChange={() => this.setState({ savePassword: !savePassword })}
                    checked={savePassword}
                >
                    {t('modal.save_password_60m')}
                </Checkbox>
            </Modal>
        )
    }

    /**
     * xác nhận thanh toán
     */
    confirmPayOrder = (password, item) => {
        const { isSubmit, showModalConfirm, savePassword } = this.state
        const { t } = this.props

        if (showModalConfirm && !password) {
            this.setState({
                passwordError: t('orderDetail.input_pin_error'),
            })
            return
        }

        let body = {}

        if (password) {
            body.password = password

            if (savePassword) {
                body.savePassword = true
            }
        }

        if (isSubmit === true) return
        this.setState(
            {
                isSubmit: true,
                itemSelected: item,
            },
            () => {
                OrdersService.confirmPayOrder(item?.code, body, (err, res, headers) => {
                    if (!err) {
                        LocalStore.getInstance().save('pinToken', headers['x-pin-token'] || '')

                        notification['success']({
                            message: t('orderDetail.successfully_pay'),
                        })

                        // lấy lại số tiền còn lại của khách
                        // if (this.mainLayoutElement && this.mainLayoutElement.BalanceComponent) this.mainLayoutElement.BalanceComponent.getBalanceUser();
                        this.loadData()
                        this.setState({
                            password: '',
                            passwordError: '',
                            showModalConfirm: false,
                        })
                    } else {
                        if (err.title === 'empty_password') {
                            LocalStore.getInstance().save('pinToken', '')
                            this.setState({
                                password: '',
                                passwordError: '',
                                showModalConfirm: true,
                            })
                        } else if (err.title === 'password_not_match' || err.title === 'invalid_password') {
                            LocalStore.getInstance().save('pinToken', '')
                            if (!showModalConfirm) {
                                this.setState({
                                    password: '',
                                    passwordError: '',
                                    showModalConfirm: true,
                                })
                            } else {
                                this.setState({
                                    passwordError: t('orderDetail.incorrect_pin'),
                                })
                            }
                        } else if (err.title === 'insufficient_balance') {
                            notification['error']({
                                message: t('cartCheckout.not_enough_money'),
                            })
                        } else if (err.title === 'order_status_not_allow') {
                            notification['error']({
                                message: t('message.changed_status'),
                            })
                        } else {
                            if (err.statusCode !== 'Network fail')
                                notification['error']({
                                    message: t(err.message),
                                })
                        }
                    }
                    this.setState({ isSubmit: false })
                })
            }
        )
    }

    showModalConfirm = item => {
        const { t } = this.props
        Modal.confirm({
            bodyStyle: {
                padding: 20,
            },
            maskClosable: true,
            icon: null,
            title: t('orderDetail.confirm_question'),
            okText: t('button.agree'),
            cancelText: t('button.disagree'),
            onOk: () => this.confirmReceivedOrder(item),
        })
    }

    renderButton = item => {
        const { t } = this.props
        if (item.status !== 'DELIVERING') return
        if (item.totalUnpaid === 0) {
            return (
                <Button
                    ghost
                    style={{ marginRight: 8 }}
                    type="primary"
                    className={`_btn-received  rounded`}
                    onClick={() => {
                        this.showModalConfirm(item)
                    }}
                >
                    {t('orderDetail.received')}
                </Button>
            )
        }
    }

    /**
     * render item of list
     * @param item
     * @param index
     * @returns {*}
     */
    renderListItem = (item, index) => {
        const { listStatuses = [], loanCredits } = this.state
        const { t } = this.props
        const itemStatus = listStatuses.find(x => x.code === item.status) || {}
        const hasInspection = item.services.find(x => x.code === 'inspection')

        const orderHasLending = lodash.get(item, 'contractWithShopkeeper', false)
        const loanCredit = loanCredits.find(loanCredit => loanCredit?.orderCode === item?.code)

        let totalNeedPay = item?.totalUnpaid
        if (loanCredit) totalNeedPay += loanCredit?.status === 'ACTIVE' ? loanCredit?.totalAmountPay : 0
        const totalNeedPayFormat = moneyFormat(moneyCeil(totalNeedPay), null, true)

        return (
            <div
                className={`_order-item listorders__items bg-color-gray mgbt15`}
                key={index}
            >
                <div className="order-item-head dpl-flex justify-content-between pd15 border-bottom-1x bd-color-gray">
                    <div
                        className="order-item-head__left dpl-flex align-items-center"
                        style={{ maxWidth: '100%' }}
                    >
                        <Typography.Paragraph
                            style={{ marginBottom: 0 }}
                            copyable={{ text: item.code }}
                        >
                            <Link
                                to={`/orders/${item.code}`}
                                className={'_order-item-code listorders__items_codFe txt-color-blue txt-size-h7 robotoregular'}
                            >
                                #{item.code}
                            </Link>
                        </Typography.Paragraph>
                        <Divider
                            type="vertical"
                            className={'listorders__items_vertical'}
                        />
                        <div className="merchantUrl dpl-flex align-items-center">
                            {item.merchantUrl ? (
                                <a
                                    href={item.merchantUrl}
                                    target="_blank" rel="noreferrer"
                                >
                                    <Avatar
                                        className={`_order-avatar-seller`}
                                        shape="square"
                                        size={18}
                                        src={item.marketplace ? item.marketplace.image : ''}
                                    />
                                </a>
                            ) : (
                                <Avatar
                                    className={`_order-avatar-seller`}
                                    shape="square"
                                    size={18}
                                    src={item.marketplace ? item.marketplace.image : ''}
                                />
                            )}
                            <div className=" w-[300px] overflow-hidden whitespace-nowrap text-ellipsis">
                                {item.merchantUrl ? (
                                    <a
                                        href={item.merchantUrl}
                                        target="_blank" rel="noreferrer"
                                    >
                                        <span className={'pdl5 txt-color-gray'}>
                                            <span className={'_order-seller-name txt-color-black'}>
                                                {item.merchantName || item.merchantCode}
                                            </span>
                                        </span>
                                    </a>
                                ) : (
                                    <span className={'pdl5 txt-color-gray'}>
                                        <span className={'_order-seller-name txt-color-black'}>
                                            {item.merchantName || item.merchantCode}
                                        </span>
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={'listorders__items_right'}>
                        <span className={'_order-item-status'}>
                            {this.renderButton(item, index)}
                            <Popover
                                trigger={'click'}
                                overlayClassName={'white-tool-tip timeline-tooltip'}
                                onVisibleChange={visible => {
                                    if (visible) this.getListMilestones(item.code)
                                }}
                                placement="left"
                                title={null}
                                content={this.renderTimeLine(item)}
                            >
                                <Tag
                                    style={{ backgroundColor: itemStatus.color || '#898989' }}
                                    className={'listorders__items_right--status listorders__items_right--blue'}
                                >
                                    {itemStatus.name} <AntdIcon.InfoCircleOutlined type="info-circle" />
                                </Tag>
                            </Popover>
                        </span>
                    </div>
                </div>

                <div className="order-item-head pdl15 pdr15 pdt10 pdbt10 border-bottom-1x bd-color-gray">
                    {item && item.services && Array.isArray(item.services) && item.services.length > 0 ? (
                        <>
                            <span className={'searchbox__label2 txt-color-gray txt-size-h7 robotoregular pdr15'}>
                                {t('order.service')}:
                            </span>
                            {item.services.map((x, y) => {
                                let indexMax = item.services.length - 1
                                return (
                                    <Fragment key={y}>
                                        <span
                                            className={`${
                                                x.approved === null ? 'txt-color-yellow' : 'txt-color-black'
                                            } txt-size-h8 ${x.approved === false ? 'line-through' : ''}`}
                                        >
                                            {x.name}
                                        </span>
                                        <span className="mx-4">{`${y === indexMax ? '' : '|'}`}</span>
                                    </Fragment>
                                )
                            })}
                        </>
                    ) : null}
                    {orderHasLending && (
                        <span className="float-right mr-2">
                            <span className="robotoregular txt-color-black">
                                {item.status === 'CANCELLED'
                                    ? t('order.loansStatusCancelled')
                                    : item.status === 'PENDING'
                                    ? t('order.loansStatusPending')
                                    : t('order.loansStatusApprove')}
                            </span>
                        </span>
                    )}
                </div>

                <div className="order-item-body pdl15 pdr15 pdt10 pdbt10 border-bottom-1x bd-color-gray w-full">
                    <Row>
                        <Col
                            span={3}
                            className="dpl-flex align-items-center"
                        >
                            <Link to={`/orders/${item.code}`}>
                                <Avatar
                                    className={`_order-avatar-product imgthumb32`}
                                    shape="square"
                                    src={item.image}
                                />
                            </Link>
                            <div className={'total-number pdl5'}>
                                <p className={'mgbt0 txt-color-gray txt-size-h8 robotoregular'}>
                                    {t('order.quantity')}{' '}
                                    <Tooltip
                                        placement="top"
                                        title={t('order.order_buy_receive')}
                                    >
                                        <AntdIcon.QuestionCircleOutlined
                                            type="question-circle"
                                            theme="filled"
                                            className={'txt-size-h8 txt-color-gray opacity6 mgl5'}
                                        />
                                    </Tooltip>
                                </p>
                                <p className={'_total-number robotomedium txt-size-h7 txt-color-black mgbt0'}>
                                    {quantityFormat(item.orderedQuantity)}/{quantityFormat(item.purchasedQuantity)}
                                    {hasInspection ? `/${quantityFormat(item.receivedQuantity)}` : ''}
                                </p>
                            </div>
                        </Col>
                        <Col span={3}>
                            <div className={'total-number pdl5'}>
                                <p className={'mgbt0 txt-color-gray txt-size-h8 robotoregular'}>{t('order.fee_total')}</p>
                                <p className={'_total-grandTotal mgbt0 txt-size-h7 txt-color-black robotomedium'}>
                                    {moneyFormat(moneyCeil(item.grandTotal))}
                                </p>
                            </div>
                        </Col>
                        <Col span={3}>
                            <div className={'total-number pdl5'}>
                                <p className={'mgbt0 txt-color-gray txt-size-h8 robotoregular'}>{t('order.goods_money')}</p>
                                <p className={'_product-totalValue mgbt0 txt-size-h7 txt-color-black robotomedium'}>
                                    {moneyFormat(moneyCeil(item.exchangedTotalValue))}
                                </p>
                            </div>
                        </Col>
                        <Col span={3}>
                            <div className={'total-number pdl5'}>
                                <p className={'mgbt0 txt-color-gray txt-size-h8 robotoregular'}>{t('order.discountAmount')}</p>
                                <p className={'_product-totalValue mgbt0 txt-size-h7 txt-color-black robotomedium'}>
                                    {item.discountAmount
                                        ? `${moneyFormat(moneyCeil(item.exchangedDiscountAmount))} (${moneyFormat(
                                              item.discountAmount,
                                              'CNY'
                                          )})`
                                        : '---'}
                                </p>
                            </div>
                        </Col>
                        <Col span={3}>
                            <div className={'total-number pdl5'}>
                                <p className={'mgbt0 txt-color-gray txt-size-h8 robotoregular'}>
                                    {item.totalUnpaid < 0 ? t('order.excess_cash') : t('order.need_payment')}
                                </p>
                                {itemStatus.negativeEnd ? (
                                    <p className={`_refund-money mgbt0 txt-color-red txt-size-h7 robotomedium`}>
                                        {t('order.canceled_order')}
                                    </p>
                                ) : (
                                    <p
                                        className={`_refund-money mgbt0 ${
                                            item.totalUnpaid < 0 ? 'txt-color-green' : 'txt-color-red'
                                        } txt-size-h7 robotomedium`}
                                    >
                                        {moneyFormat(moneyCeil(item.totalUnpaid), null, true)}
                                    </p>
                                )}
                            </div>
                        </Col>
                        <Col
                            span={3}
                            className="mt-1"
                        >
                            <div className={'total-number pdl5'}>
                                <p className={'mgbt0 txt-color-gray txt-size-h8 robotoregular'}>
                                    {t('orderDetail.costing_weight')}
                                </p>
                                <p className={'_refund-createdAt mgbt0 txt-color-black txt-size-h7 robotomedium'}>
                                    {item.actualWeight ? (
                                        <span className={'txt-size-h7 txt-color-black robotomedium mgr5 _actual-weight'}>
                                            {item.actualWeight}kg
                                        </span>
                                    ) : (
                                        '---'
                                    )}
                                </p>
                            </div>
                        </Col>

                        <Col
                            span={3}
                            className="mt-1"
                        >
                            <div className={'total-number pdl5'}>
                                <p className={'mgbt0 txt-color-gray txt-size-h8 robotoregular'}>
                                    {t('orderDetail.totalPackages')}
                                </p>
                                <p className={'_refund-createdAt mgbt0 txt-color-black txt-size-h7 robotomedium'}>
                                    {item.totalPackages ? (
                                        <span className={'txt-size-h7 txt-color-black robotomedium mgr5 _actual-weight'}>
                                            {item.totalPackages}
                                        </span>
                                    ) : (
                                        '---'
                                    )}
                                </p>
                            </div>
                        </Col>
                        <Col span={3}>
                            <div className={'total-number pdl5'}>
                                <p className={'mgbt0 txt-color-gray txt-size-h8 robotoregular'}>{t('order.created_at')}</p>
                                <p className={'_refund-createdAt mgbt0 txt-color-black txt-size-h7 robotomedium'}>
                                    {moment(item.createdAt).format('HH:mm DD/MM/YYYY')}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>

                {totalNeedPay > 0 && (
                    <>
                        {item.contractWithShopkeeper && (
                            <div className="v2-layout order-item-head pdl15 pdr15 pdt10 pdbt10 border-bottom-1x bd-color-gray w-full flex gap-3">
                                <span className={'_note txt-color-gray txt-size-h7 robotoregular mr-1'}>
                                    {t('order_pay.bifin')}:
                                </span>
                                <span className="txt-size-h7 text-blue-primary robotomedium mr-3">
                                    {moneyFormat(
                                        moneyCeil(loanCredit?.status !== 'ACTIVE' ? 0 : loanCredit?.totalAmountPay || 0),
                                        null,
                                        true
                                    )}
                                </span>
                            </div>
                        )}
                        <div className="v2-layout order-item-head pdl15 pdr15 pdt10 pdbt10 border-bottom-1x bd-color-gray w-full flex gap-3">
                            <span className={'_note txt-color-gray txt-size-h7 robotoregular mr-1'}>
                                {t('order_pay.amount_need_pay')}:
                            </span>
                            <span className="txt-size-h7 robotomedium mr-3 text-blue-primary">{totalNeedPayFormat}</span>
                            {(item.status === 'READY_FOR_DELIVERY' || item.status === 'DELIVERING') && (
                                <ModalPaymentBifin
                                    t={t}
                                    contractWithShopkeeper={item.contractWithShopkeeper}
                                    code={item.code}
                                    refetchData={this.loadFromQueryString}
                                    totalNeedPay={totalNeedPayFormat}
                                />
                            )}
                        </div>
                    </>
                )}
                <div className="order-item-head pdl15 pdr15 pdt10 pdbt10 border-bottom-1x bd-color-gray w-full">
                    <span className={'_note txt-color-gray txt-size-h7 robotoregular pdr15'}>{t('order.note_title')}:</span>
                    <span className="txt-size-h7 robotoregular">{item.note ? item.note : '---'}</span>
                </div>
            </div>
        )
    }

    renderModalSuccess = () => {
        const { t } = this.props
        return (
            <Modal
                className={'ant-modal-confirm ant-modal-confirm-success'}
                title=""
                centered
                onCancel={() => this.setState({ modalSuccessShow: false })}
                visible={this.state.modalSuccessShow}
                footer={null}
            >
                <div className="ant-modal-confirm-body-wrapper">
                    <div className="ant-modal-confirm-body">
                        <AntdIcon.CheckCircleOutlined type="check-circle" />
                        <span className="ant-modal-confirm-title">{t('order.successfully_handle')}</span>
                        <div className="ant-modal-confirm-content">{t('order.data_sent_email')}</div>
                    </div>
                    <div className="ant-modal-confirm-btns">
                        <button
                            onClick={() => this.setState({ modalSuccessShow: false })}
                            type="button"
                            className="ant-btn ant-btn-primary"
                        >
                            <span>{t('order.ok')}</span>
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }

    renderTimeLine = () => {
        const { t } = this.props
        const { listMilestones = [], isLoadingListMilestones, listStatuses = [] } = this.state

        if (isLoadingListMilestones === true) {
            return (
                <div className="mgt25 mgbt25 txt-center">
                    <Spin
                        spinning={isLoadingListMilestones === true}
                        indicator={
                            <AntdIcon.Loading3QuartersOutlined
                                type="loading"
                                style={{ fontSize: 24 }}
                                spin
                            />
                        }
                    />
                </div>
            )
        }

        return (
            <Timeline>
                {Array.isArray(listMilestones) &&
                    listMilestones.map((item, index) => {
                        const status = listStatuses.find(x => x.code === item.status)
                        let day = ''
                        if (item.handlingTime > 1) {
                            day = t('label.days')
                        } else {
                            day = t('label.day')
                        }

                        return (
                            <Timeline.Item color={index === 0 ? 'green' : 'gray'}>
                                <p className="mgbt0">
                                    {status ? status.name : '---'} - {moment(item.timestamp).format('HH:mm DD/MM')}
                                </p>
                                <p className="mgbt0">
                                    {item.handlingTime === null
                                        ? '(' + t('orderDetail.undefined') + ')'
                                        : '(' + item.handlingTime + ' ' + day + ')'}
                                </p>
                            </Timeline.Item>
                        )
                    })}
            </Timeline>
        )
    }

    render() {
        const { listOrders, isLoading, metadata, onExport } = this.state
        const { t } = this.props
        const ordersStatistics = LocalStore.getInstance().read('ordersStatistics') || []
        let locationQuery = _.get(this.props, 'location.search', '')
        // console.log(moment('2019-03-06T04:54:25.715Z').format('hh:mm DD/MM/YYYY'))
        return (
            <MainLayout
                ref={ref => (this.mainLayoutElement = ref)}
                {...this.props}
                showProgressBar={this.state.showProgressBar}
                headerTitle={t('order.order_list')}
                title={t('order.title')}
            >
                <div className={'container-common pdbt30 mgr20'}>
                    {ordersStatistics.find(x => x.status === 'DELIVERY_READY') &&
                    ordersStatistics.find(x => x.status === 'DELIVERY_READY').total !== '0' ? (
                        <Alert
                            className="mgbt20"
                            message={<span className="bold txt-size-h6">{t('order.notification')}</span>}
                            description={
                                <span className="txt-size-h7">
                                    {t('order.have')}{' '}
                                    <span className="_DELIVERY_READY bold">
                                        {quantityFormat(ordersStatistics.find(x => x.status === 'DELIVERY_READY').total)}
                                    </span>{' '}
                                    {t('order.order_at_stock')}, {t('order.please')}{' '}
                                    <Link to={'/delivery/create'}>{t('order.delivery_request')}</Link> {t('order.to_delivery')}
                                </span>
                            }
                            type="success"
                            showIcon
                            closable
                        />
                    ) : null}
                    {this.renderHeaderPage()}
                    <div className="dpl-flex justify-content-between align-items-center bg-color-white pd25 pdt15 pdbt15 border-bottom-1x bd-color-gray">
                        {metadata ? (
                            <span className={'txt-color-black txt-size-h5 robotomedium'}>
                                {t('order.order_list')} (
                                <span className={'_total-item-in-page'}>{quantityFormat(metadata.total)}</span>)
                            </span>
                        ) : (
                            <span />
                        )}
                        <ExportExcel
                            {...this.props}
                            onExport={onExport}
                            exportExcel={this.exportExcel}
                        />
                    </div>
                    {Array.isArray(listOrders) && listOrders.length > 0 ? (
                        <List
                            className={'_list-orders listorders bg-color-white'}
                            // header={this.renderHeaderList()}
                            dataSource={listOrders}
                            renderItem={this.renderListItem}
                            // loadMore={this.renderLoadMore()}
                            pagination={{
                                className: '_pagination-order txt-right mgt20',
                                hideOnSinglePage: true,
                                current: metadata ? parseFloat(metadata.page) + 1 : 1,
                                total: metadata ? parseFloat(metadata.total) : 1,
                                pageSize: metadata ? parseFloat(metadata.size) : pageSize,
                                onChange: (page, pageSize) => this.onPageChanged(page, pageSize),
                            }}
                        />
                    ) : isLoading ? (
                        <Loading />
                    ) : (
                        <div className="pdt50 pdbt60 bg-color-white">
                            <Empty
                                image={require('../../resources/images/empty-image.png')}
                                description={locationQuery === '' ? t('order.order_yet') : t('message.empty')}
                            >
                                {locationQuery === '' && (
                                    <Link
                                        to={'/carts'}
                                        className={'_btn-create-in-table ant-btn ant-btn-primary'}
                                    >
                                        {t('order.to_cart')}
                                    </Link>
                                )}
                            </Empty>
                        </div>
                    )}
                    {this.renderModalSuccess()}
                    {this.renderModalConfirmPin()}
                </div>
            </MainLayout>
        )
    }
}
export default withNamespaces()(Orders)
