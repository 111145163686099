import * as React from "react";
import {Button, Modal} from "antd";
import {withNamespaces} from "react-i18next";

class ModalConfirm extends React.Component{

    render(){
        const {title, visible, onCancel, onSubmit, content, t, loading, className} = this.props;
        return(
            <Modal
                className={`_modal-confirm ${className}`}
                title={<span style={{color: '#1890FF', fontWeight: '500'}}>{title?title:t('message.title_confirm')}</span>}
                visible={visible}
                onCancel={onCancel}
                footer={[
                    <Button
                        className={"_btn-cancel buttonm buttonm__cancel"}
                        key="cancel"
                        onClick={onCancel}
                    >
                        <span className="mgr10"> <i className="fal fa-times"></i></span> {t('button.cancel')}

                    </Button>,
                    <Button
                        className={"_btn-submit buttonm"} key="submit" type=""
                        autoFocus={true}
                        onClick={onSubmit}
                        loading={loading ? loading : false}
                    >
                        <span className="mgr10"><i className="fal fa-check"></i></span>{t('button.confirm')}
                    </Button>,
                ]}
            >
                <p className={'_p-content'} dangerouslySetInnerHTML={{__html: content}}/>
            </Modal>
        );
    }
}
export default withNamespaces()(ModalConfirm);
