import { v4 as uuidv4 } from 'uuid';
import PolimClient from '../utils/api/PolimClient';
import {LocalStore} from "../utils/LocalStore";

export default class OrderService {

    /**
     * lấy ra danh sách orders
     * @param username
     * @param query
     * @param cb
     */
    static getListOrders (username, query, cb) {
        PolimClient.request({
            endpoint: `/customer/orders${query || ''}`,
            method: 'GET',
        }, cb)
    }

    /**
     * xuất csv order
     * @param query
     * @param body
     * @param cb
     */
    static exportCsvOrders(query, body, cb) {
        PolimClient.request({
            endpoint: `/customer/orders/export_excel${query || ''}`,
            method: 'POST',
            body
        }, cb)
    }

    /**
     * xuất csv order
     * @param query
     * @param body
     * @param cb
     */
    static exportCsvWaybills(query, body, cb) {
        PolimClient.request({
            endpoint: `/customer/customer_waybills/export_excel${query || ''}`,
            method: 'POST',
            body
        }, cb)
    }

    /**
     * xuất csv product
     * @param code
     * @param body
     * @param cb
     */
    static exportCsvOrderProducts(code, body, cb) {
        PolimClient.request({
            endpoint: `/customer/orders/${code}/products/export_excel`,
            method: 'POST',
            body
        }, cb)
    }

    /**
     * tạo mới order customer
     * @param username
     * @param body
     * @param cb
     */
    static createCustomerOrder (username, body, cb) {
        let pinToken = LocalStore.getInstance().read('pinToken') || "";

        let headers = {};

        if (pinToken) {
            headers["X-PIN-TOKEN"] = pinToken
        } else if (body.password) {
            headers["X-PIN"] = body.password;
            if (body.savePassword) {
                headers["X-REMEMBER-PIN"] = true
            }
        }

        PolimClient.request({
            endpoint: `/customer/orders`,
            method: 'POST',
            headers,
            body: body
        }, cb)
    }

    /**
     * tạo mới 1 draft order
     * @param username
     * @param body
     * @param cb
     */
    static createDraftOrder (username, body, cb) {
        PolimClient.request({
            endpoint: `/customer/draft_orders`,
            method: 'POST',
            body: body
        }, cb)
    }

    /**
     * update 1 draft order
     * @param username
     * @param id
     * @param body
     * @param cb
     */
    static updateDraftOrder (username, id, body, cb) {
        PolimClient.request({
            endpoint: `/customer/draft_orders/${id}`,
            method: 'PATCH',
            body: body
        }, cb)
    }

    static checkShoppingCartLoanable (body, cb) {
        PolimClient.request({
            endpoint: `/customer/third-parties/shopkeeper/create`,
            method: 'POST',
            body
        }, cb)
    }
    

	static getDraftOrder (id, cb) {
		PolimClient.request({
			endpoint: `/customer/draft_orders/${id}`,
			method: 'GET'
		}, cb)
	}

	static gotoShopkeeperUrl (draftOrderId) {
		return `${process.env.REACT_APP_API_URL}/customer/draft_orders_shopkeeper/${draftOrderId}/go-to-shopkeeper`
	}

    static gotoBiffinUrl (data) {
        const { username, partnerId, redirectUri, phone, email, fullname } = data
        const randomStringNumber = uuidv4()
        LocalStore.getInstance().save('FinUuid', randomStringNumber);
        return `${redirectUri}?partner_id=${partnerId}&identify_id=${username}&nonce=${randomStringNumber}&phone=${phone}&email=${email}&full_name=${fullname}`
    }

    /**
     * Lưu access token to db
     * @param {*} body 
     */
    static saveTokenBiffin (body, cb) {
        PolimClient.request({
            endpoint: `/customer/third-parties/shopkeeper`,
            method: 'POST',
            body: body
        }, cb)
    }

    /**
     * lấy ra danh sách carts
     * @param username
     * @param query
     * @param cb
     */
    static getListCarts (username, query, cb) {
        PolimClient.request({
            endpoint: `/customer/cart${query || ''}`,
            method: 'GET',
        }, cb)
    }

    /**
     * thêm sản phẩm từ đơn hàng
     * @param orderCode
     * @param cb
     */
    static addProductFromOrder (orderCode, cb) {
        PolimClient.request({
            endpoint: `/customer/orders/${orderCode}/products/cart`,
            method: 'POST',
        }, cb)
    }

    /**
     * xóa sku item
     * @param id
     * @param username
     * @param cb
     */
    static deleteSkuItem (username, id, cb) {
        PolimClient.request({
            endpoint: `/customer/skus/${id}`,
            method: 'DELETE',
        }, cb)
    }

    /**
     * xóa các sku items
     * @param skus
     * @param username
     * @param cb
     */
    static deleteSkusItem (username, skus, cb) {
        PolimClient.request({
            endpoint: `/customer/skus?skus=${skus}`,
            method: 'DELETE',
        }, cb)
    }

    /**
     * xóa all các item
     * @param cb
     */
    static deleteAllCart (cb) {
        PolimClient.request({
            endpoint: `/customer/cart/delete_all`,
            method: 'DELETE',
        }, cb)
    }

    /**
     * xóa cart item
     * @param id
     * @param username
     * @param cb
     */
    static deleteCartItem (username, id, cb) {
        PolimClient.request({
            endpoint: `/customer/cart/${id}`,
            method: 'DELETE',
        }, cb)
    }

    /**
     * cập nhật cart item
     * @param id
     * @param username
     * @param body
     * @param cb
     */
    static updateCartItem (username, id, body, cb) {
        PolimClient.request({
            endpoint: `/customer/cart/${id}`,
            method: 'PATCH',
            body: body
        }, cb)
    }

    /**
     * cập nhật sku item
     * @param id
     * @param username
     * @param body
     * @param cb
     */
    static updateSkuItem (username, id, body, cb) {
        PolimClient.request({
            endpoint: `/customer/skus/${id}`,
            method: 'PATCH',
            body: body
        }, cb)
    }

    /**
     * thêm services vào cart
     * @param id
     * @param username
     * @param body
     * @param cb
     */
    static addServicesToCart (username, id, body, cb) {
        PolimClient.request({
            endpoint: `/customer/cart/${id}/services`,
            method: 'POST',
            body: body
        }, cb)
    }

    /**
     * Create note order
     * @param data
     * @param cb
     */
    static addNoteOrder (data, cb) {
        PolimClient.request({
            endpoint: `/`,
            method: 'POST',
            body: data
        }, cb)
    }

    /**
     * Update note order
     * @param data
     * @param cb
     */
    static updateNoteOrder (data, cb) {
        PolimClient.request({
            endpoint: `/`,
            method: 'PATCH',
            body: data
        }, cb)
    }

    /**
     * Get data related service
     * @param cb
     */
    static getRelatedService (cb) {
        PolimClient.request({
            endpoint: `/`,
            method: 'GET'
        }, cb)
    }

    /**
     * Lấy thông tin chi tiết đơn
     * @param orderId
     * @param cb
     */
    static getOrderDetail(orderId, cb) {
        PolimClient.request({
            endpoint: `/customer/orders/${orderId}`,
            method: 'GET'
        }, cb)
    }

    /**
     * Update note chi tiết đơn
     * @param orderCode
     * @param data
     * @param cb
     */
    static updateCustomerOrder(orderCode, data, cb) {
        PolimClient.request({
            endpoint: `/customer/orders/${orderCode}`,
            method: 'PATCH',
            body: data
        }, cb)
    }

    /**
     * Lấy danh sách sản phẩm trong chi tiết đơn
     * @param orderId
     * @param cb
     */
    static getListProductOrder(orderId, cb) {
        PolimClient.request({
            endpoint: `/customer/orders/${orderId}/products?size=6969`,
            method: 'GET'
        }, cb)
    }

    /**
     * Update a Customer Order Product
     * @param orderId
     * @param productId
     * @param data
     * @param cb
     */
    static updateCustomerOrderProduct(orderId, productId, data, cb) {
        PolimClient.request({
            endpoint: `/customer/orders/${orderId}/products/${productId}`,
            method: 'PATCH',
            body: data
        }, cb);
    }

    /**
     * Lấy danh sách comment
     * @param page
     * @param code
     * @param cb
     */
    static getComments(code, page, cb) {
        PolimClient.request({
            endpoint: `/customer/orders/${code}/comments?sort=timestamp:DESC&page=${page}&size=25`,
            method: 'GET'
        }, cb)
    }

    /**
     * Lấy danh sách comment ticket
     * @param page
     * @param code
     * @param cb
     */
    static getCommentsTicket(code, page, cb) {
        PolimClient.request({
            endpoint: `/customer/ticket/${code}/comment?sort=timestamp:DESC&page=${page}&size=25`,
            method: 'GET'
        }, cb)
    }


    /**
     * Lấy danh sách tín dụng khách hàng
     * @param code
     * @param cb
     */
    static getCredits(code, cb) {
        PolimClient.request({
            endpoint: `/customer/orders/${code}/credits`,
            method: 'GET'
        }, cb)
    }

    
    /**
     * get loans
     * @param code
     * @param cb
     */
    static getLoans(code, cb) {
        PolimClient.request({
            endpoint: `/customer/orders/${code}/loans`,
            method: 'GET'
        }, cb)
    }

    /**
     * Delete Comment bản thân
     * @param code
     * @param id
     * @param cb
     */
    static deleteComment(code, id , cb) {
        PolimClient.request({
            endpoint: `/customer/orders/${code}/comments/${id}`,
            method: 'DELETE'
        }, cb)
    }

    /**
     * Tạo comment
     * @param code
     * @param data
     * @param cb
     */
    static createComment(code, data, cb) {
        PolimClient.request({
            endpoint: `/customer/orders/${code}/comments`,
            method: 'POST',
            body: data
        }, cb)
    }

    /**
     * lấy ra danh sách package thuộc order
     * @param code
     * @param cb
     */
    static getOrderPackages(code, cb) {
        PolimClient.request({
            endpoint: `/customer/orders/${code}/packages?size=10000&sort=createdAt:DESC`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra danh sách package mile stones
     * @param packageCode
     * @param cb
     */
    static getOrderPackageMileStones(packageCode, cb) {
        PolimClient.request({
            endpoint: `/customer/packages/${packageCode}/milestones`,
            method: 'GET',
        }, cb)
    }

    /**
     * Lấy danh sách giao dịch trong chi tiết đơn
     * @param orderCode
     * @param type
     * @param cb
     */
    static getListFinancialOrder(orderCode, type, cb) {
        PolimClient.request({
            endpoint: `/customer/orders/${orderCode}/financial${type ? `?type=${type}` : ''}`,
            method: 'GET'
        }, cb)
    }

    /**
     * Lấy danh sách giao dịch trong chi tiết đơn
     * @param code
     * @param cb
     */
    static getOrderFees(code, cb) {
        PolimClient.request({
            endpoint: `/customer/orders/${code}/fees`,
            method: 'GET'
        }, cb)
    }

    /**
     * lấy ra danh sách count theo từng trạng thái
     * @param cb
     */
    static getOrdersStatistics(cb) {
        PolimClient.request({
            endpoint: `/customer/orders/statistics`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra lịch sử các mốc đặt hàng
     * @param code
     * @param cb
     */
    static getOrderMileStones(code, cb) {
        PolimClient.request({
            endpoint: `/customer/orders/${code}/milestones?sort=timestamp:ASC`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra phí dịch vụ của merchant
     * @param id
     * @param cb
     */
    static getMerchantFees(id, cb) {
        PolimClient.request({
            endpoint: `/customer/cart/${id}/fees`,
            method: 'GET',
        }, cb)
    }

    /**
     * lấy ra danh sách khiếu nại của đơn
     * @param code
     * @param queryString
     * @param cb
     */
    static getOrderTickets(code, queryString, cb) {
        PolimClient.request({
            // endpoint: `/customer/orders/${code}/tickets${queryString || ''}`,
            endpoint: `/customer/canines/claims/orderCode/${code}${queryString || ''}`,
            method: 'GET',
        }, cb)
    }

    /**
     * xác nhận đã nhận hàng
     * @param code
     * @param cb
     */
    static confirmReceivedOrder(code, cb) {
        PolimClient.request({
            endpoint: `/customer/orders/${code}/confirm`,
            method: 'POST',
        }, cb)
    }

    /**
     * xác nhận hủy đơn hàng
     * @param code
     * @param cb
     */
    static confirmCancelOrder(code, cb) {
        PolimClient.request({
            endpoint: `/customer/orders/${code}/cancel`,
            method: 'POST',
        }, cb)
    }

    /**
     * xác nhận thanh toán đơn hàng
     * @param code
     * @param body
     * @param cb
     */
    static confirmPayOrder(code, body, cb) {
        let pinToken = LocalStore.getInstance().read('pinToken') || "";

        let headers = {};

        if (pinToken) {
            headers["X-PIN-TOKEN"] = pinToken
        } else if (body.password) {
            headers["X-PIN"] = body.password;
            if (body.savePassword) {
                headers["X-REMEMBER-PIN"] = true
            }
        }

        PolimClient.request({
            endpoint: `/customer/orders/${code}/pay`,
            method: 'POST',
            headers,
            body
        }, cb)
    }

    /**
     * lấy danh sách log
     * @param code
     * @param page
     * @param cb
     */
    static getLog(code, page, cb) {
         PolimClient.request({
            endpoint: `/customer/orders/${code}/logs?sort=timestamp:desc&page=${page || 0}&size=25`,
            method: 'GET'
        },cb);
    }

    /**
     * xác nhận sự thay đổi ở product
     * @param orderCode
     * @param productCode
     * @param cb
     */
    static confirmProduct(orderCode, productCode, body, cb) {
        PolimClient.request({
            endpoint: `/customer/orders/${orderCode}/products/${productCode}/need_confirm`,
            method: 'PATCH',
            body
        },cb);
    }

    /**
     * @param orderCode
     * @param cb
     */
    static getProductsActivities(orderCode, cb) {
        PolimClient.request({
            endpoint: `/customer/orders/${orderCode}/products/activities?size=9999`,
            method: 'GET',
        },cb);
    }
    /**
     * Lấy ra hành trình của kiện
     * @param waybillCode
     * @param cb
     */
    static getPackageIOHistories(code, cb) {
        PolimClient.request({
            endpoint: `/customer/packages/${code}/io-histories?sort=createdAt:desc`,
            method: 'GET',
        }, cb)
    }

    static applyCouponDraftOrder (id, body, cb) {
        PolimClient.request({
            endpoint: `/customer/draft_orders/${id}/apply_coupon`,
            method: 'POST',
            body: body
        }, cb)
    }
    static createCommentInOrder(code,body, cb){
        PolimClient.request({
            endpoint: `/customer/comments/orders/${code}`,
            method: 'POST',
            body: body
        }, cb)
    }

    static getCommentsPosedon(code, page, cb) {
        PolimClient.request({
            endpoint: `/customer/comments/orders/${code}?sort=createdAt:DESC&page=${page}&size=25`,
            method: 'GET',
        }, cb)
    }

    static getAttachmentPosedon(code, cb) {
        PolimClient.request({
            endpoint: `/customer/comments/orders/${code}/attachments`,
            method: 'GET',
        }, cb)
    }
}
