import MainLayout from '../../components/Layout/MainLayout'

import { withNamespaces } from 'react-i18next'
import _ from 'lodash'
import { Tabs } from 'antd'
import StatisticDetail from './Detail'
import { LocalStore } from '../../utils/LocalStore'

const Statistics = (props: any) => {
    const { t } = props

    const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
    const enableOrder = !_.get(currentProjectInfo, 'tenantConfig.orderConfig.disable')
    const enableShipment = _.get(currentProjectInfo, 'tenantConfig.shipmentConfig.enable')
    const requestPayment = _.get(currentProjectInfo, 'tenantConfig.peerPaymentConfig.enabled')

    const items: any = [
        ...(enableOrder
            ? [
                  {
                      key: 'order',
                      label: t('statistic.order'),
                      children: <StatisticDetail type="order" />,
                  },
              ]
            : []),
        ...(enableShipment
            ? [
                  {
                      key: 'shipment',
                      label: t('statistic.shipment'),
                      children: <StatisticDetail type="shipment" />,
                  },
              ]
            : []),
        ...(requestPayment
            ? [
                  {
                      key: 'request_payment',
                      label: t('statistic.request_payment'),
                      children: <StatisticDetail type="request_payment" />,
                  },
              ]
            : []),
    ]

    return (
        <MainLayout
            {...props}
            headerTitle={t('header.statistics')}
            title={t('header.statistics')}
        >
            <div className={'container-common pdbt30 mgr20'}>
                <div className=" bg-white rounded-xl px-6 pt-3 pb-0">
                    <Tabs
                        items={items}
                        className="tab-common-antd"
                    />
                </div>
            </div>
        </MainLayout>
    )
}

export default withNamespaces()(Statistics)
