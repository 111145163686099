/* eslint-disable array-callback-return */
import React from 'react'
import {Link} from 'react-router-dom'
import moment from 'moment'
import MainLayout from '../../components/Layout/MainLayout'
import Loading from '../../components/Loading'
import {
    Row, Col, Empty, Input, DatePicker, Pagination,
    Table, Button, notification,
} from 'antd';
import * as AntdIcon from "@ant-design/icons"
import CategoriesService from "../../services/categories.service";

import ProfileSidebar from '../../components/Profile/Leftbar'
import VipLevelBox from '../../components/Profile/VipLevelBox'

import {getUrlQueryString} from "../../utils/helper";
import {
    parseQueryStringToObject,
    quantityFormat,
    updateQueryStringParameter
} from "../../utils/stringUtils";
import UserService from "../../services/user.service";
import {withNamespaces} from "react-i18next";
import {LocalStore} from "../../utils/LocalStore";

let pageSize = 25;

class VipLevels extends React.Component {
    constructor(props) {
        super(props);
        this.isUnmounted = false;
        this.state = {
            page: 1,
            pageSize: pageSize,
            isLoading: true,
            listTransactions: [],
            listChecked: {},
            startValue: null,
            endValue: null,
            orderCode: '',
        }
    }

    componentDidMount() {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null;
        window.addEventListener("popstate", this.onPopState.bind(this));

        this.setState({isLoading: true}, () => {
            this.loadFromQueryString();
            this.getListFees();
            this.getShipmentsFees()
        });
        if (typeof LocalStore.getInstance().read('currentProjectInfo') === 'object') {
            let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
            if (currentProjectInfo && currentProjectInfo.tenantConfig.generalConfig && currentProjectInfo.tenantConfig.generalConfig.enableRewardPoint && currentProjectInfo.tenantConfig.generalConfig.enableCustomerLevel) {
                this.setState({is404: false});
            } else {
                this.setState({is404: true});
            }
        }
    }

    /**
     * Khi unmount ra thì bỏ sự kiện popstate
     */
    componentWillUnmount() {
        this.isUnmounted = true;
        window.removeEventListener("popstate", this.onPopState.bind(this));
    }

    /**
     * On popstate
     */
    onPopState() {
        if (!this.isUnmounted) this.setState({isLoading: true}, ()=> {
            this.loadFromQueryString();
        });
    };

    /**
     * Load từ QueryString (dành cho lần load đầu tiên)
     */
    loadFromQueryString = () => {
        let queryString = getUrlQueryString();
        let queryObj = parseQueryStringToObject(queryString) || {};

        // console.log('queryObj', queryObj);
        // chuyển giá trị từ queryObj vào state;
        //reset các param khi load từ url
        let listChecked = {};
        let orderCode = '';
        // let datetimeRange = [];
        let arrayValue = [];
        let startValue = null;
        let endValue = null;
        for (let p in queryObj) {
            if (p === 'types') {
                // xử lý với obj list checked
                arrayValue = queryObj[p].split(',');
                // console.log('arrayValue', arrayValue)
                listChecked[p] = arrayValue;
            } else if (p === 'trxTimeFrom' || p === 'trxTimeTo') {
                // xử lý với với timestamp
                // datetimeRange[0] = decodeURIComponent(queryObj['trxTimeFrom']);
                // datetimeRange[1] = decodeURIComponent(queryObj['trxTimeTo']);
                startValue = decodeURIComponent(queryObj['trxTimeFrom']);
                endValue = decodeURIComponent(queryObj['trxTimeTo']);
            } else {
                // xử lý với query
                orderCode = decodeURIComponent(queryObj['orderCode'] || '')
            }
        }

        this.setState({
            orderCode,
            startValue,
            endValue,
            listChecked,
            page: queryObj.page,
            pageSize: queryObj.size || pageSize,
        }, ()=> {
            this.loadData();
        });
    };

    /**
     * load data
     */
    loadData = (cb) => {
        const {page, pageSize} = this.state;
        const {t} = this.props;
        let queryString = this.buildQueryString();
        if (queryString) {
            queryString = queryString + `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        } else {
            queryString = `?page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        }
        this.setState({showProgressBar: true}, () => {
            UserService.getRewardPointTransactions(queryString, (err, res, headers) => {
                if (!err) {
                    this.setState({
                        expandedRow: [],
                        listTransactions: res,
                        metadata: {
                            pageCount: headers['x-page-count'],
                            page: headers['x-page-number'],
                            size: headers['x-page-size'],
                            total: headers['x-total-count'],
                        }
                    }, () => {
                        if (this.mainLayoutElement && this.mainLayoutElement.scrollLayoutElement) this.mainLayoutElement.scrollLayoutElement.scrollTop = 0;
                        console.log('metadata', this.state.metadata);
                        //window.scrollTo(0, 0);
                    });
                } else {
                    if (err.title === 'total_record_limit_exceeded') {
                        notification.error({message: t('customer_info.bigdata')})
                    }
                }
                if(cb) cb();
                this.setState({showProgressBar: false, isLoading: false})
            })
        })
    };

    onPageChanged = (page) => {
        this.setState({page: page}, () => {
            let queryString = getUrlQueryString();
            queryString = updateQueryStringParameter(queryString ? '?'+ queryString : '', 'page', page);
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: queryString
            });
            // window.scrollTo(0, 0);
        });
    };

    /**
     * thay đổi sort ở column
     * @param current
     * @param pageSize
     */
    onSizeChange = (current, pageSize) => {
        let queryString = this.buildQueryString();
        queryString = updateQueryStringParameter(queryString, 'size', pageSize);
        queryString = updateQueryStringParameter(queryString, 'page', null);

        this.setState({
            page: 1,
            pageSize: pageSize
        }, () => {
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: queryString
            });
        })
    };

    /**
     * lấy ra danh sách phí
     */
    getListFees = () => {
        CategoriesService.getListFees((err, res, headers) => {
            if (!err) {
                this.setState({
                    listFees: res,
                });
            }
        })
    };

    /**
     * lấy ra danh sách phí
     */
    getShipmentsFees = () => {
        CategoriesService.getShipmentFees((err, res, headers) => {
            if (!err) {
                this.setState({
                    shipmentFees: res,
                });
            }
        })
    };

    /**
     * lấy ra danh sách phí
     */
    getRewardPointTransactionsHistory = (id) => {
        this.setState({isLoadingHistory: true}, () => {
            UserService.getRewardPointTransactionsHistory(id,(err, res, headers) => {
                if (!err) {
                    this.setState({
                        rewardPointTransactionsHistory: res,
                        isLoadingHistory: false
                    });
                } else {
                    this.setState({
                        isLoadingHistory: false
                    });
                }
            })
        })
    };

    /**
     * xây dựng query string theo các giá trị trong state
     * @returns {string}
     */
    buildQueryString = () => {
        let {listChecked, startValue, endValue, orderCode} = this.state;
        let queryString = '';
        for (let p in listChecked) {
            let value = '';
            if (Array.isArray(listChecked[p]) && listChecked[p].length > 0) {
                listChecked[p].map(x => {
                    value = value + x + ',';
                });

                value = value.slice(0, -1)
            }
            if (value) queryString = updateQueryStringParameter(queryString, p, value)
        }

        // if (datetimeRange.length > 0) {
        //     queryString = updateQueryStringParameter(queryString, 'trxTimeFrom', datetimeRange[0]);
        //     queryString = updateQueryStringParameter(queryString, 'trxTimeTo', datetimeRange[1]);
        // }
        if (startValue !== 'undefined') {
            queryString = updateQueryStringParameter(queryString, 'trxTimeFrom', startValue);
        }

        if (endValue !== 'undefined') {
            queryString = updateQueryStringParameter(queryString, 'trxTimeTo', endValue);
        }

        if (orderCode) {
            queryString = updateQueryStringParameter(queryString, 'orderCode', orderCode.trim())
        }

        return queryString
    };

    onClearSearch = () => {
        this.setState({
            page: 1,
            orderCode: '',
            startValue: null,
            endValue: null,
            listChecked: {},
        }, () => {
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: this.buildQueryString()
            });
        })
    };

    /**
     * xử lý với dữ liệu search trong state
     */
    onSubmitSearch = () => {
        this.setState({page: 1}, () => {
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: this.buildQueryString()
            });
        })
    };

    dateTimeChange = (date) => {
        // console.log('dateTimeChange ', date, dateString);

        this.setState({
            datetimeRange: date.length > 0 ? [date[0].startOf('day').toISOString(), date[1].endOf('day').toISOString()] : []
        })
    };

    checkDateTime = (value) => {
        if (value === 'undefined' || value === null) {
            return null;
        }
        return moment(value);

    };

    disabledStartDate = (startValue) => {
        const endValue = this.state.endValue;
        if (!startValue || !endValue) {
            return false;
        }
        return startValue.valueOf() > endValue.valueOf();
    };

    disabledEndDate = (endValue) => {
        const startValue = this.state.startValue;
        if (!endValue || !startValue) {
            return false;
        }
        return endValue.valueOf() <= startValue.valueOf();
    };

    onChange = (field, value) => {
        this.setState({
            [field]: value,
        });
    };

    onStartChange = (value) => {
        if (value !== null) {
            this.onChange('startValue', value ? value.startOf('day').toISOString(): undefined);
        } else {
            this.onChange('startValue', value);
        }
    };

    onEndChange = (value) => {
        if (value !== null) {
            this.onChange('endValue', value ? value.endOf('day').toISOString() : undefined);
        } else {
            this.onChange('endValue', value);
        }
    };

    renderExpandedRow = (record) => {
        const {listFees = [], shipmentFees = []} = this.state;
        const {t} = this.props;
        // let dataList = [];
        // rewardPointTransactionsHistory.map((x, index) => {
        //     x.spendingSources.map((y, indexY) => {
        //         let obj = {...x};
        //         if (indexY === x.spendingSources.length - 1) obj.index = "Lần thứ " + (index + 1);
        //         obj.spendingSource = y;
        //         obj.idx = x.id + index + indexY;
        //         dataList.push(obj)
        //     })
        // });
        //
        // if (isLoadingHistory) return <Loading />;

        const fees = [...listFees, ...shipmentFees, {code: 'x_order_cogs_amount', name: 'Giá vốn'}, {code: 'x_order_grand_total', name: 'Tiền hàng'}];
        const columns = [
            // {
            //     title: 'Thời gian',
            //     dataIndex: 'indexx',
            //     key: 'indexx',
            //     render: (text, record) => <span className="_expanded-indexx txt-size-h7 bold">{record.index}</span>,
            // },
            // {
            //     title: '',
            //     dataIndex: 'trxTime',
            //     key: 'trxTime',
            //     render: (text) => <span className="_expanded-trxTime txt-size-h7 robotoregular">{moment(text).format("HH:mm DD/MM/YYYY")}</span>,
            // },
            {
                title: t('customer_info.fee'),
                dataIndex: 'source',
                key: 'source',
                render: (text, record) => {
                    const currentFee = fees.find(x => x.code === record.source);
                    return <span className="_expanded-source txt-size-h7 robotoregular">{currentFee ? currentFee.name : t('orderDetail.undefined')}</span>
                }
            },
            {
                title: t('customer_info.point'),
                dataIndex: 'pointAmount',
                key: 'pointAmount',
                className: 'txt-right',
                render: (text, record) => <span className={`txt-size-h7 ${record.pointAmount >= 0 ? 'txt-color-green' : 'txt-color-red'} robotoregular __expanded-source txt-right`}>
                    {record.pointAmount >= 0 ? '+' : ''}{quantityFormat(record.pointAmount)}
                </span>
            }
        ];

        if (!record || !Array.isArray(record.spendingSources) || record.spendingSources.length === 0) return <Empty
            image={require('../../resources/images/empty-image.png')}
            description={t('message.empty')}
        />;

        return <Table
            rowKey={"idx"}
            columns={columns}
            dataSource={record.spendingSources}
            pagination={{hideOnSinglePage: true}}
        />
    };

    /**
     * render search box
     * @returns {*}
     */
    renderSearchBox = () => {
        const {
            startValue,
            endValue,
            orderCode,
        } = this.state;
        const {t} = this.props;

        return (
            <div className="searchbox bg-color-white mgbt20 border-1px-solid pd15">
                <Row className={'dpl-flex bd-color-gray borderdotted-bootom-2x pdbt15'}>
                    <Col span={12} className="pdr10">
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('customer_info.input_order_code')}:
                        </div>
                        <Input
                            value={orderCode}
                            onChange={(e) => this.setState({orderCode: e.target.value})}
                            onKeyDown={(e) => {
                                // console.log('onKeyDown')
                                if (e.keyCode === 13) {
                                    this.onSubmitSearch()
                                }
                            }}
                            className={'_search-box-input-query'}
                        />
                    </Col>
                    <Col span={12} className="pdl10">
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('customer_info.time')}:
                        </div>
                        {/*<DatePicker.RangePicker*/}
                            {/*className={'_search-date-time width-100-percent'}*/}
                            {/*format={'DD/MM/YYYY'}*/}
                            {/*value={datetimeRange.length > 0 ? [moment(datetimeRange[0]), moment(datetimeRange[1])] : []}*/}
                            {/*onChange={this.dateTimeChange}*/}
                        {/*/>*/}
                        <Row gutter={20}>
                            <Col span={12}>
                                <DatePicker
                                    disabledDate={this.disabledStartDate}
                                    className="_datepicker"
                                    style={{minWidth: "100%"}}
                                    // showTime
                                    format={'DD/MM/YYYY'}
                                    value={this.checkDateTime(startValue)}
                                    placeholder={t('order.start')}
                                    onChange={this.onStartChange}
                                    onOpenChange={this.handleStartOpenChange}
                                />
                            </Col>
                            <Col span={12}>
                                <DatePicker
                                    disabledDate={this.disabledEndDate}
                                    // showTime
                                    style={{minWidth: "100%"}}
                                    format={'DD/MM/YYYY'}
                                    value={this.checkDateTime(endValue)}
                                    placeholder={t('order.finish')}
                                    onChange={this.onEndChange}
                                    onOpenChange={this.handleEndOpenChange}
                                />
                            </Col>
                        </Row>

                    </Col>
                </Row>
                <div
                    className=" dpl-flex align-items-center justify-content-between pdt10"
                >
                    <div />
                    <div className="dpl-flex align-items-center">
                        <span onClick={this.onClearSearch} className="_btn-clear mgr20 txt-color-gray txt-size-h8 cursor-pointer robotoregular">
                            <AntdIcon.SyncOutlined type={'sync'} className={'mgr5'}/> {t('order.filter_refresh')}
                        </span>
                        <Button onClick={this.onSubmitSearch} type="primary" className={'_btn-search min-width-200'}>{t('order.search')}</Button>
                    </div>
                </div>
            </div>

        )
    };

    render() {
        const {
            listTransactions, isLoading,
            metadata, expandedRow = [],
        } = this.state;
        const {t} = this.props;

        const columns = [{
            title: t('customer_info.time'),
            dataIndex: 'trxTime',
            key: 'trxTime',
            className: 'profiletableth',
            render: (text) => <span className='txt-size-h7 txt-color-black robotoregular _trxTime whitespace'>{moment(text).format('HH:mm DD/MM/YYYY')}</span>
        }, {
            title: t('customer_info.content'),
            dataIndex: 'detail',
            key: 'detail',
            className: 'profiletableth',
            render: (text, record) => (
                <div className={'break-word'}>
                    <p className="txt-size-h7 txt-color-gray robotobold _level-history-hash mgbt0 txt-color-gray break-word white-space-pre-wrap">{t('customer_info.code')}: {record.id}</p>
                    <p className="txt-size-h7 txt-color-black robotoregular _level-history-memo mgbt0 txt-color-black break-word white-space-pre-wrap">{record.memo}</p>
                </div>
            ),
        }, {
            title: t('customer_info.order_code'),
            dataIndex: 'refId',
            key: 'refId',
            className: 'profiletableth',
            render: (text, record) => {
                let check = Array.isArray(record.spendingSources) ? !!record.spendingSources.find(x => x.source.indexOf('shipment') > -1) : false;
                return text ? <Link className="_order-link" target={'_blank'} to={`/${check ? 'shipments' : 'orders'}/${text}`}>#{text}</Link> : <span className="_order-link">---</span>
            }
        }, {
            title: t('customer_info.point_number'),
            dataIndex: 'amount',
            key: 'amount',
            className: 'profiletableth txt-right',
            render: (text) => (
                <span className={`txt-size-h7 ${text >= 0 ? 'txt-color-green' : 'txt-color-red'} robotoregular _level-history-amount txt-right`}>
                    {text >= 0 ? '+' : ''}{quantityFormat(text)}
                </span>
            ),
        }];

        console.log('metadata', metadata);

        return <MainLayout
            ref={ref => this.mainLayoutElement = ref}
            {...this.props}
            showProgressBar={this.state.showProgressBar}
            headerTitle={t('customer_info.accumulated_history_list')}
            title={t('customer_info.accumulated_history_list')}
        >
            <div className={'container-common pdbt30 pdt20 mgr20'}>
                <Row>
                    <Col span={6} className={'pdr10'}>
                        <ProfileSidebar currentRoute={'vip-levels'}/>
                    </Col>
                    <Col span={18} className={'pdl10'}>

                        <VipLevelBox />

                        <div className="pd20 bg-color-white">

                            {this.renderSearchBox()}

                            <div className="border-1px-solid mgt20">
                                <div className="dpl-flex justify-content-between align-items-center bg-color-white pd20 pdt15 pdbt15 border-bottom-1x bd-color-gray">
                                    {
                                        metadata
                                            ?
                                            <span className={'txt-color-black txt-size-h7 robotobmedium'}>{t('customer_info.accumulated_history_list')} (<span className={'_total-item-in-page'}>{quantityFormat(metadata.total)}</span>)</span>
                                            :
                                            <span />
                                    }
                                    {/*<Button className={'_btn-export-csv'} type="primary" ghost>Xuất CSV</Button>*/}
                                </div>
                                <div className="tableresponsive pd15">
                                {
                                    Array.isArray(listTransactions) && listTransactions.length > 0
                                        ? <>
                                        <Table
                                            className={'_table-data custom-empty'}
                                            rowKey={'id'}
                                            columns={columns}
                                            dataSource={listTransactions}
                                            rowClassName={() => `_table-row vertical-align-text-top`}
                                            expandedRowKeys={expandedRow}
                                            expandedRowRender={(record) => this.renderExpandedRow(record)}
                                            expandIcon={(expand) => {
                                                if (expand.record.refId) {
                                                    return expand.expanded ? <AntdIcon.DownOutlined
                                                        onClick={(e) => expand.onExpand(expand.record, e)}
                                                        type={expand.expanded ? 'down' : "right"}
                                                    />
                                                    :
                                                    <AntdIcon.RightOutlined onClick={(e) => expand.onExpand(expand.record, e)}/>
                                                } else {
                                                    return null
                                                }
                                            }}
                                            onExpand={(expanded, record) => {
                                                if (expanded) {
                                                    this.setState({
                                                        expandedRow: [record.id]
                                                    }, ()  => {
                                                        // this.getRewardPointTransactionsHistory(record.id)
                                                    });
                                                } else {
                                                    this.setState({
                                                        expandedRow: []
                                                    });
                                                }
                                            }}
                                            pagination={false}
                                            // pagination={{
                                            //     className: '_pagination text-center width-100-percent mgt20',
                                            //     // hideOnSinglePage: true,
                                            //     current: metadata ? parseFloat(metadata.page) + 1 : 1,
                                            //     total: metadata ? parseFloat(metadata.total) : 1,
                                            //     pageSize: metadata ? parseFloat(metadata.size) : pageSize + 1,
                                            //     showSizeChanger: true,
                                            //     // onShowSizeChange: this.onSizeChange,
                                            //     // pageSizeOptions: ['26', '51', '76', '101'],
                                            //     onChange: (page) => this.onPageChanged(page)
                                            // }}
                                        />
                                        <Pagination
                                            className={'_pagination text-right width-100-percent mgt20'}
                                            hideOnSinglePage={true}
                                            current={metadata ? parseFloat(metadata.page) + 1 : 1}
                                            total={metadata ? parseFloat(metadata.total) : 1}
                                            pageSize={metadata ? parseFloat(metadata.size) : pageSize}
                                            onChange={(page) => this.onPageChanged(page)}
                                        /></>                                        
                                        :
                                        isLoading ?
                                        <Loading/> :
                                        <div className="pdt50 pdbt60 bg-color-white">
                                            <Empty
                                                image={require('../../resources/images/empty-image.png')}
                                                description={t('message.empty')}
                                            />
                                        </div>
                                }
                                </div>
                            </div>
                        </div>
                    </Col>

                </Row>
            </div>
        </MainLayout>;
    }
}
export default withNamespaces()(VipLevels);
