import React from "react";
import _ from 'lodash';
import {notification, Input, Avatar, Spin, Modal} from 'antd'
import * as AntdIcon from "@ant-design/icons"
import DataTransfer from 'fbjs/lib/DataTransfer';
import CameraDropzone from '../ReactDropzone/Camera';
import Download from '../Download';
import {validateMaxLength, validateRequired} from "../../utils/validate";
import {checkIsLink, formatFileName, linkify, timeAgo} from "../../utils/stringUtils";
import InfiniteScroll from "react-infinite-scroller";
import { LocalStore } from '../../utils/LocalStore'
import TextEditor from '../commons/TextEditor'
import moment from 'moment'

class ChatBox extends React.Component {
    constructor(props) {
        super(props);
        this.isUnmounted = false;
        this.state = {
            commentContent: '',
            validateError: {},
            // isMobile: window.innerWidth < 981,
            fileList: [],
            openModal:false
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.windowResize);
        this.scrollToBottom();
        // if (this.chatbox) this.chatbox.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        this.isUnmounted = true;
        window.removeEventListener('resize', this.windowResize);
        // if (this.chatbox) this.chatbox.removeEventListener('scroll', this.handleScroll);
    }

    pasteHandle = (pasteEvent) => {
        const {uploadImageComment, t} = this.props;
        const data = new DataTransfer(pasteEvent.clipboardData);
        const files = data.getFiles();

        if (Array.isArray(files) && files.length > 0) {
            // validate nếu số lượng file lớn hơn 10 file thì thông báo lỗi
            if (files.length <= 10) {
                // validate nếu có file có size lớn hơn 10Mb hoặc là ko đúng định dạng ảnh thì sẽ hiển thị thông báo
                if(files.find(x => x.size > 10485760) || files.find(x => x.type.indexOf('image') < 0)){
                    notification['error']({
                        message: t('error.upload_image')
                    });
                } else {
                    uploadImageComment(files);
                }
            } else {
                notification['error']({
                    message:t('error.upload_picture')
                });
            }
            pasteEvent.preventDefault();
        }
    };

    /**
     * khi window resize
     */
    windowResize = () => {
        // const windowWidth = window.innerWidth;
        this.scrollToBottom();
        // if (!this.isUnmounted) this.setState({
        //     isMobile: windowWidth < 981
        // })
    };

    /**
     * scroll to bottom
     */
    scrollToBottom = () => {
        this.chatScroller.scrollTop = this.chatbox?.scrollHeight
    };

    /**
     * thay đổi dữ liệu khi typing
     * @param e
     */
    onChangeInput = (e) => {
        this.setState({
            commentContent: e.target.value
        })
    };

    /**
     * validate khi blur
     * @param field
     */
    onBlurInput = (field) => {
        let {validateError} = this.state;
        switch (field) {
            case 'content':
                // clear error
                validateError.content = '';
                this.setState({
                    validateError
                });
                break;
            default:
                break;
        }
    };

    /**
     * sự kiện keydown của textarea
     * @param e
     */
    textAreaKeyDown = (e) => {
        const {createComment} = this.props;
        const {commentContent} = this.state;
        // bắt sự kiện submit khi ctr enter hoặc cmd enter
        if ((e.metaKey||e.ctrlKey) && e.keyCode === 13) {
            //bắt sự kiện khi ctrl + enter hoặc cmd + enter
            if (!this.createValidate()) {
                this.setState({
                    commentContent: ''
                }, () => {
                    this.scrollToBottom();
                    createComment({content: commentContent.trim()});
                })
            }
        }
    };

    /**
     * validate khi create
     * @returns {boolean}
     */
    createValidate = () => {
        let {commentContent, validateError} = this.state;
        let validate = false;

        //validate content
        if (validateRequired(commentContent.trim())) {
            validateError.content = validateRequired(commentContent.trim());
            this.setState({
                validateError
            });
            validate = true
        } else if (validateMaxLength(commentContent, 'Comment', 1000)) {
            validateError.content = validateMaxLength(commentContent, 'Comment', 1000);
            this.setState({
                validateError
            });
            validate = true
        } else {
            validateError.content = '';
            this.setState({
                validateError
            })
        }

        return validate
    };

    /**
     * kiểm tra quyền xóa comment
     * @param item
     * @returns {boolean}
     */
    checkDeleteItem = (item) => {
        // const {currentLoggedUser, currentCommentTab, dataDetail} = this.props;
        // kiểm tra nếu comment của khách hàng thì chỉ có khách hàng có thể xóa
        // if (item.author.username === dataDetail.createdBy && currentCommentTab === customerTab) {
        //     if (currentLoggedUser.username === dataDetail.createdBy) {
        //         return UserService.isAllowed(TICKETS_SCOPES.TICKETS_COMMENT_DELETE) || UserService.isAllowed(TICKETS_SCOPES.TICKETS_COMMENT_DELETE_ALL);
        //     } else return false
        // } else {
        //     // nếu không phải là comment của khách hàng thì sẽ check quyền xóa all hoặc xóa của bản thân
        //     if (UserService.isAllowed(TICKETS_SCOPES.TICKETS_COMMENT_DELETE_ALL)) {
        //         return true
        //     } else if (UserService.isAllowed(TICKETS_SCOPES.TICKETS_COMMENT_DELETE) && currentLoggedUser.username === item.author.username) {
        //         // quyền xóa của bản thân
        //         return true
        //     }
        // }
        return true
    };

    /**
     * kiểm tra quyền xóa comment internal
     * @param item
     * @returns {boolean}
     */
    checkDeleteItemInternal = (item) => {
        // const {currentLoggedUser} = this.props;
        // check quyền xóa all hoặc xóa của bản thân
        // if (UserService.isAllowed(TICKETS_SCOPES.TICKETS_COMMENT_INTERNAL_DELETE_ALL)) {
        //     return true
        // } else if (UserService.isAllowed(TICKETS_SCOPES.TICKETS_COMMENT_INTERNAL_DELETE) && currentLoggedUser.username === item.author.username) {
        //     // quyền xóa của bản thân
        //     return true
        // }
        return true
    };

    handlePreview = (item) => {
        this.setState({
            previewItem: item,
            previewVisible: true,
        });
    };

    /**
     * render row image
     * @param item
     * @param index
     * @param comment
     * @returns {*}
     */
    renderRowImage = (item, index, comment) => {
        if (item.type === null || (item.type.indexOf('video') < 0 && item.type.indexOf('image') < 0)) {
            return <div className={`mgbt5 ${comment.author.internal ? 'mgr5' : 'mgl5'}`} key={index}>
                <Download
                    file={item.name}
                    content={item.url}
                >
                    <div
                        className="_chat-attachments-item chat-attachments-item cursor-pointer position-re dpl-flex align-items-center justify-content overflow-hidden"
                        style={{fontSize: 30, border: '1px solid #595959'}}
                    >
                        <AntdIcon.FileUnknownOutlined type="file-unknown" />
                    </div>
                </Download>
                <div className="_file-name txt-color-gray txt-size-h9 text-center mgbt0">
                    {formatFileName(item.name, 12)}
                </div>
            </div>
        }

        return <div className={`mgbt5 ${comment.author.internal ? 'mgr5' : 'mgl5'}`} key={index}>
            <div
                onClick={() => this.handlePreview(item)}
                className="_chat-attachments-item chat-attachments-item cursor-pointer position-re dpl-flex align-items-center justify-content overflow-hidden"
                style={item.type.indexOf('video') > -1 ? {fontSize: 30, border: '1px solid #595959'} : {}}
            >
                {
                    item.type.indexOf('video') > -1 ?
                        <AntdIcon.PlayCircleOutlined type="play-circle" />
                        :
                        <div className="image-preview" style={{backgroundImage: `url(${item.url})`}} />
                }
            </div>
            <div className="_file-name txt-color-gray txt-size-h9 text-center mgbt0">
                {formatFileName(item.name, 12)}
            </div>
        </div>
    };

    renderRowImagePosedon = (item, index, comment) => {
        // const { fileDownloadIcon } = this.state
        if (item.mimeType.indexOf('video') < 0 && item.mimeType.indexOf('image') < 0) {
            return (
                <div
                    className={'mgbt5 mgr5'}
                    key={item?.id}
                >
                    <Download
                        file={item.name}
                        content={item.location}
                      
                    >
                         <div
                        className="_chat-attachments-item chat-attachments-item cursor-pointer position-re dpl-flex align-items-center justify-content overflow-hidden"
                        style={{fontSize: 30, border: '1px solid #595959'}}
                    >
                        <AntdIcon.FileUnknownOutlined type="file-unknown" />
                    </div>
                    </Download>
                    <p className="_file-name txt-color-gray txt-size-h9 text-center mgbt0">{formatFileName(item.name, 12)}</p>
                </div>
            )
        }
        return <div className={`mgbt5 ${comment?.from?.trusted?.customer ? 'mgr5' : 'mgl5'}`} key={index}>
            <div
                onClick={() => this.handlePreview(item)}
                className="_chat-attachments-item chat-attachments-item cursor-pointer position-re dpl-flex align-items-center justify-content overflow-hidden"
                style={item.mimeType.indexOf('video') > -1 ? {fontSize: 30, border: '1px solid #595959'} : {}}
            >
                {
                    item.mimeType.indexOf('video') > -1 ?
                        <AntdIcon.PlayCircleOutlined type="play-circle" />
                        :
                        <div className="image-preview" style={{backgroundImage: `url(${item.location})`}} />
                }
            </div>
            <div className="_file-name txt-color-gray txt-size-h9 text-center mgbt0">
                {formatFileName(item.name, 12)}
            </div>
        </div>
    }

    updateModal = () => {
        this.setState({
            openModal : true
        })
    };


    renderModalConfirm = () => {
        return <Modal
            title="Thông báo"
            visible={this.state.openModal}
            onCancel={() => this.setState({
                openModal: false
            })}
            onOk={() => this.setState({
                openModal: false
            })}
            okText={'Xác nhận'}
            cancelText={'Hủy'}
            okButtonProps={{className:"_btn-submit-confirm"}}
            cancelButtonProps={{className:"_btn-cancel-confirm"}}
        >
            <p className="mgbt5">Chức năng này chưa hoạt động</p>

        </Modal>
    };

    /**
     * render dòng comment
     * @param item
     * @param index
     */
    renderRowComment = (item, index) => {
        const { t} = this.props;
        return <div className={`${index !== 0 && 'mgt20'} ${!item.author.internal ? 'customer' : ''} comment-item dpl-flex`} key={index}>
            {
                item.author.internal && <Avatar src={item.author.avatar} />
            }
            <div className={`${item.author.internal ? 'pdl10' : 'pdr10'} width-100-percent flex-1-1`}>
                <div className="dpl-flex justify-content-between align-items-center">
                    {
                        item.author.internal ?
                        <span className="_comment-author txt-size-h9 robotoregular txt-color-gray2">
                            {item.author.fullname}, <span className="_comment-createdAt">
                                {timeAgo(item.timestamp, t)}
                            </span>
                        </span>
                        :
                        <React.Fragment>
                            <span/>
                            <span className="_comment-author txt-size-h9 robotoregular txt-color-gray2 ">
                                <span className="_comment-createdAt">
                                    {timeAgo(item.timestamp, t)}
                                </span>, {item.author.fullname}
                            </span>
                        </React.Fragment>
                    }

                </div>

                {
                    item.content &&
                    <div className={`${!item.author.internal ? 'txt-right' : ''} bd-rdu`}>
                        {
                            checkIsLink(item.content)
                                ?
                                <div
                                    className={`_txt-comment-item is-link line-height134 txt-color-black robotomedium dpl-il-block break-word txt-size-h8 whitespace-pre-wrap max-width-100`}
                                    dangerouslySetInnerHTML={{__html: linkify(item.content)}}
                                />
                                :
                                <div
                                    className={`_txt-comment-item line-height134 txt-color-black robotomedium dpl-il-block break-word txt-size-h8 whitespace-pre-wrap max-width-100`}
                                >
                                    {item.content}
                                </div>
                        }

                    </div>
                }

                {
                    item.attachments.length > 0 &&
                    <div className={`_chat-attachments-wrapper chat-attachments-wrapper ${!item.author.internal ? 'justify-content-end' : ''}`}>
                        {
                            item.attachments.map((i, j) => this.renderRowImage(i, j, item))
                        }
                    </div>

                }

            </div>
            {
                !item.author.internal && <Avatar src={item.author.avatar} />
            }
        </div>
    };

    renderRecall = (item, index) => {
        const {currentLoggedUser, t} = this.props;
        return <div className={`${index !== 0 && 'mgt20'} comment-item _item-recalled`} key={index}>
            <span className="_comment-author txt-size-h8 utmhelve-bold txt-color-gray2 line-height167">
                {item.author.fullname}
            </span>
            <div className={`${currentLoggedUser.username === item.author.username ? 'bg-color-yellow2' : 'bg-color-gray3'} pd10 bd-rdu mgbt5`}>
                <p className={'_txt-comment-item utmhelve-regular line-height134 dpl-il-block break-word txt-size-h7 whitespace-pre-wrap break-word max-width-100 txt-color-gray'}>
                    {t('message.deleted_comment')}
                </p>
            </div>
            <div className="">
                <span className="_comment-createdAt txt-size-h9 utmhelve-regular txt-color-gray">
                    {timeAgo(item.recallTime || new Date(), t)}
                </span>
            </div>
        </div>
    };

    /**
     * render dòng comment
     * @param item
     * @param index
     */
    renderRowCommentPosedon = (item, index) => {
        const {listAttachments, t} = this.props;
        const attachments = listAttachments?.filter((i) => i?.messageId === item?.id)

        return <div className={`${index !== 0 && 'mgt20'} ${item?.from?.trusted?.customer ? 'customer' : ''} comment-item dpl-flex`} key={index}>
            {
                !item?.from?.trusted?.customer && <Avatar src={item?.from?.card?.photo} />
            }
            <div className={`${item?.from?.trusted?.customer ?  'pdr10' : 'pdl10'} width-100-percent flex-1-1`}>
                <div className="dpl-flex justify-content-between align-items-center">
                    {
                        !item?.from?.trusted?.customer ?
                        <span className="_comment-author txt-size-h9 robotoregular txt-color-gray2">
                            {item?.from?.card?.fn}, <span className="_comment-createdAt">
                                {timeAgo(item.createdAt, t)}
                            </span>
                        </span>
                        :
                        <React.Fragment>
                            <span/>
                            <span className="_comment-author txt-size-h9 robotoregular txt-color-gray2 ">
                                <span className="_comment-createdAt">
                                    {timeAgo(item.createdAt, t)}
                                </span>, {item?.from?.card?.fn}
                            </span>
                        </React.Fragment>
                    }

                </div>

                {
                    item.content?.plainText &&
                    <div className={`${item?.from?.trusted?.customer ? 'txt-right' : ''} bd-rdu w-full h-full`}>
                        {
                            checkIsLink(item.content?.plainText)
                                ?
                                <div
                                    className={`_txt-comment-item is-link line-height134 txt-color-black dpl-il-block break-word txt-size-h8 whitespace-pre-wrap w-full h-full`}
                                    dangerouslySetInnerHTML={{__html: linkify(item.content?.plainText)}}
                                />
                                :
                                <div
                                    className={`_txt-comment-item line-height134 txt-color-black dpl-il-block break-word txt-size-h8 whitespace-pre-wrap w-full h-full`}
                                    dangerouslySetInnerHTML={{__html: item.content?.plainText}}
                                >
                                </div>
                        }

                    </div>
                }
                {
                    attachments?.length > 0 &&
                        <div className={`_chat-attachments-wrapper chat-attachments-wrapper ${!item.author.internal ? 'justify-content-end' : ''}`}>
                            {
                                attachments?.map((i, j) => this.renderRowImagePosedon(i, j, item))
                            }
                        </div>
                }
            </div>
            {
                item?.from?.trusted?.customer && <Avatar src={item?.from?.card?.photo} />
            }
        </div>
    };

    renderRecallPosedon = (item, index) => {
        const {currentLoggedUser, t} = this.props;
        return <div className={`${index !== 0 && 'mgt20'} comment-item _item-recalled`} key={index}>
            <span className="_comment-author txt-size-h8 utmhelve-bold txt-color-gray2 line-height167">
                {item?.from?.card?.fn}
            </span>
            <div className={`${currentLoggedUser.username === item?.from?.card?.username ? 'bg-color-yellow2' : 'bg-color-gray3'} pd10 bd-rdu mgbt5`}>
                <p className={'_txt-comment-item utmhelve-regular line-height134 dpl-il-block break-word txt-size-h7 whitespace-pre-wrap break-word max-width-100 txt-color-gray'}>
                    {t('message.deleted_comment')}
                </p>
            </div>
            <div className="">
                <span className="_comment-createdAt txt-size-h9 utmhelve-regular txt-color-gray">
                    {timeAgo(item.recallTime || new Date(), t)}
                </span>
            </div>
        </div>
    };

    renderCommentLog = () => {
        const {listComments = [], dataDetail} = this.props;
        const newListComments = _.cloneDeep(listComments);
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const taiyiConfig = _.get(currentProjectInfo, 'tenantConfig.taiyiConfig.enabled')
        const enableTime = _.get(currentProjectInfo, 'tenantConfig.taiyiConfig.updateToNewCommentTime')
        const checkTimeNew = moment(dataDetail?.createdAt)?.isAfter(moment(enableTime))
        if(taiyiConfig && checkTimeNew){
            return <div
            className="boxchat__content pd15"
            ref={e => this.chatbox = e}
        >
            {
                _.reverse(newListComments).map((item, index) => {
                    if (item.recall) return this.renderRecallPosedon(item, index);
                    return this.renderRowCommentPosedon(item, index)
                })
            }
        </div>
        }
        return <div
            className="boxchat__content pd15"
            ref={e => this.chatbox = e}
        >
            {
                _.reverse(newListComments).map((item, index) => {
                    if (item.recall) return this.renderRecall(item, index);
                    return this.renderRowComment(item, index)
                })
            }
        </div>
    };

    render () {
        const {
            createComment, isUploading, hasMore,
            nextCommentPage, uploadImageComment, commentLoading, t, isCommenting, dataDetail, 
        } = this.props;
        const {commentContent, previewVisible, previewItem = {}} = this.state;
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const taiyiConfig = _.get(currentProjectInfo, 'tenantConfig.taiyiConfig.enabled')
        const enableTime = _.get(currentProjectInfo, 'tenantConfig.taiyiConfig.updateToNewCommentTime')
        const checkTimeNew = moment(dataDetail?.createdAt)?.isAfter(moment(enableTime))
        return <React.Fragment>

            <div
                className="dpl-flex flex-direction-column height500"
            >
                <div
                    ref={e => this.chatScroller = e}
                    className={'height-100-percent boxchatwrapper'}
                >
                    <InfiniteScroll
                        initialLoad={false}
                        pageStart={0}
                        loadMore={nextCommentPage}
                        hasMore={!commentLoading === true && hasMore}
                        useWindow={false}
                        isReverse={true}
                    >
                        {
                           dataDetail && this.renderCommentLog()
                        }
                        {
                            commentLoading && hasMore && (
                                <div className="demo-loading-container text-center pdt15 pdbt15">
                                    <Spin indicator={<AntdIcon.Loading3QuartersOutlined type="loading" style={{ fontSize: 24 }} spin />} />
                                </div>
                            )
                        }
                    </InfiniteScroll>
                </div>
                <div ref={e => this.textereaContainer = e} className="boxchat__ip pd15 bg-color-gray border-bootom-1x bd-color-gray">
                {taiyiConfig && checkTimeNew ? (
                        <div>
                            <TextEditor
                                actionButton={
                                    <CameraDropzone
                                        t={t}
                                        maxLength={1}
                                        addFiles={uploadImageComment}
                                        isUploading={isUploading}
                                    />
                                }
                                onSubmit={(values, cb) => {
                                    this.scrollToBottom();
                                    createComment({content: values.trim()});
                                }}
                                isLoading={isCommenting}

                            />
                              {/* {files.length > 0 && (
                                    <div className="pd5 dpl-flex bg-color-white flex-wrap">{this.renderPreviewList()}</div>
                                )} */}
                        </div>
                    ) : (
                    <div className="position-re bg-color-white bd1px bd-color-gray border-radius2 pd5 pdr0">
                        <Input.TextArea
                            ref={e => this.textarea = e}
                            autosize={{ minRows: 1, maxRows: 3 }}
                            name="commentContent"
                            placeholder={t('comment.place_holder')}
                            className="_chatbox-textarea boxchat__textarea mgbt5"
                            maxLength={1000}
                            value={commentContent}
                            onChange={this.onChangeInput}
                            onBlur={() => this.onBlurInput('content')}
                            onKeyDown={this.textAreaKeyDown}
                            onPaste={this.pasteHandle}
                        />
                        <div className="dpl-flex justify-content-end pdr10">
                            <CameraDropzone
                                isUploading={isUploading}
                                maxLength={10}
                                addFiles={uploadImageComment}
                            />
                            <div
                                onClick={() => {
                                    if (!this.createValidate()) {
                                        this.setState({
                                            commentContent: ''
                                        }, () => {
                                            this.scrollToBottom();
                                            this.createValidate();
                                            createComment({content: commentContent.trim()});
                                        })
                                    }
                                }}
                                className={commentContent.trim() ? '_chatbox-btn-send btn--blue cursor-pointer txt-color-white' : '_chatbox-btn-send btn--blue cursor-pointer txt-color-white classDisabled' }
                            >
                                {t('comment.send')}
                            </div>
                        </div>

                    </div>)}

                    {
                        previewVisible && <Modal
                            width={700}
                            className={'modal-preview-image'}
                            visible={previewVisible}
                            footer={null}
                            onCancel={() => this.setState({ previewVisible: false })}
                        >
                            {
                                previewItem.type && previewItem.type.indexOf('video') > -1 ?
                                    <video width="100%" height="500" controls>
                                        <source src={previewItem.url} type={previewItem.type} />
                                        {previewItem.name}
                                    </video>
                                    :
                                    <img alt={previewItem.name} style={{ width: '100%' }} src={previewItem.url} />
                            }

                        </Modal>
                    }

                </div>


            </div>
        </React.Fragment>
    }
}
export default ChatBox;