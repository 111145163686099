import React, { useCallback } from 'react'
import AddressChina from './AddressChina'
import { useState } from 'react';
import CreateAddressChina from '../../../../components/AddressChina/CreateAddressChina';
import { AddressChinaResponseInterface } from '../../../../types/AddressChina';
import AddressChinaService from '../../../../services/addressChina.service';
import { useEffect } from 'react';
import ListAddressChinaModal from '../../../../components/AddressChina/ListAddressChinaModal';
import { notification } from 'antd';
import ProductList from './ProductList';

interface Step2Props {
    // Other props go here
    t: any,
    checkedItems: any
    accountsConnect: any
    selectedAddressForOrder: string | undefined
    accountsConnectSelected: string | undefined
    handleSelectedAddress: (selectedAddress: string) => void
    handleSetAccountsConnectSelected: (account: string) => void
}

const Step2: React.FC<Step2Props> = (props) => {
    const [isModalOpenCreate, setIsModalOpenCreate] = useState(false);
    const [isOpenModalListAddress, setIsOpenModalListAddress] = useState(false);
    const [ListAddressChina, setListAddressChina] = useState<AddressChinaResponseInterface[]>([])
    const [selectedAddress, setSelectedAddress] = useState<any>()
    const { t, checkedItems, selectedAddressForOrder, accountsConnectSelected, handleSelectedAddress, accountsConnect, handleSetAccountsConnectSelected } = props
    const [loadingAddress, setLoadingAddress] = useState(false)
    // modal create
    const showModalCreate = () => {
        setSelectedAddress({})
        setIsModalOpenCreate(true);
    };

    const handleSubmitCreateAddress = (id: any, values: any) => {

        let data = {
            zipCode: values?.zipCode,
            address1: values?.address1,
            phone: values?.phone,
            city: values?.city,
            district: values?.district,
            recipient: values?.recipient,
            state: values?.state,
            addressName: values?.addressName,
            defaultAddress: values?.defaultAddress ? true : false,
            nation: "CN"
        }
        if (!id) {
            AddressChinaService.createCustomerChinaAddress(data, (err: any, res: any) => {
                if (!err) {
                    setIsModalOpenCreate(false)
                    getListAddress()
                    notification.success({ message: ("Thêm mới địa chỉ thành công") })
                }
                else {
                    notification.error({ message: ("Thêm mới địa chỉ thất bại") })
                }
            })
        }
        else {
            AddressChinaService.updateCustomerChinaAddress(id, data, (err: any, res: any) => {
                if (!err) {
                    setIsModalOpenCreate(false)
                    getListAddress()
                    notification.success({ message: ("Cập nhật địa chỉ thành công") })
                }
                else {
                    notification.error({ message: ("Cập nhật địa chỉ thất bại") })
                }
            })
        }

        // setIsModalOpenCreate(false);
    };

    const handleCancelModalAddress = () => {
        setIsModalOpenCreate(false);
    };

    // modal liist
    const showModalListAddress = () => {
        setIsOpenModalListAddress(true);
    };

    const handleSubmitModalAddressList = () => {
        setIsOpenModalListAddress(false);
    };

    const handleCancelModalAddressList = () => {
        setIsOpenModalListAddress(false);
    };

    // 
    const showModalEditAddress = (item: any) => {
        setIsOpenModalListAddress(false);
        setSelectedAddress(item)
        setIsModalOpenCreate(true);
    }

    const getListAddress = useCallback(() => {
        setLoadingAddress(true)
        AddressChinaService.getListAddressesChinaUser((err: any, res: any) => {
            if (!err) {
                const sortByDefaultAddress = res.sort((a: any, b: any) => {
                    if (a.defaultAddress !== b.defaultAddress) {
                        return b.defaultAddress ? 1 : -1;
                    }
                    // Nếu defaultAddress bằng nhau, sắp xếp theo modifiedAt giảm dần
                    return new Date(b.modifiedAt).getTime() - new Date(a.modifiedAt).getTime();
                })
                // console.log(res.find((address: any) => address?.defaultAddress)?.id, 'res.find((address: any) => address?.defaultAddress)?.id')
                // handleSelectedAddress(res.find((address: any) => address?.defaultAddress)?.id)
                setListAddressChina(sortByDefaultAddress)
            }
            setLoadingAddress(false)
        })
    }, [])
    useEffect(() => {
        getListAddress()
    }, [getListAddress])

    return (
        <>
            <AddressChina
                showModalCreate={showModalCreate}
                ListAddressChina={ListAddressChina}
                showModalListAddress={showModalListAddress}
                showModalEditAddress={showModalEditAddress}
                selectedAddress={selectedAddressForOrder}
                accountsConnect={accountsConnect}
                accountsConnectSelected={accountsConnectSelected}
                handleSelectedAddress={handleSelectedAddress}
                handleSetAccountsConnectSelected={handleSetAccountsConnectSelected}
                loadingAddress={loadingAddress}
                t={t}
            />
            <ProductList checkedItems={checkedItems} t={t} />


            {/* <ProductList /> */}
            {isModalOpenCreate &&
                <CreateAddressChina
                    showModalCreate={showModalCreate}
                    isModalOpenCreate={isModalOpenCreate}
                    handleSubmitCreateAddress={handleSubmitCreateAddress}
                    handleCancelModalAddress={handleCancelModalAddress}
                    selectedAddress={selectedAddress}
                    t={t}
                />
            }

            {isOpenModalListAddress &&
                <ListAddressChinaModal
                    ListAddressChina={ListAddressChina}
                    showModalListAddress={showModalListAddress}
                    isOpenModalListAddress={isOpenModalListAddress}
                    handleSubmitModalAddressList={handleSubmitModalAddressList}
                    handleCancelModalAddressList={handleCancelModalAddressList}
                    showModalEditAddress={showModalEditAddress}

                />
            }
        </>

    )
}

export default Step2