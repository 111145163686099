import i18n from 'i18next';
import {reactI18nextModule} from "react-i18next";
import en from './locals/en/en.json'
import vn from './locals/vn/vn.json'
import km from './locals/km/km.json'
import zh from './locals/zh/zh.json'
import th from './locals/th/th.json'
import tl from './locals/tl/tl.json'


const resources = {
    en: {
        translation: en
    },
    vi: {
        translation: vn
    },
    km:{
        translation: km
    },
    zh:{
        translation: zh
    },
    th:{
        translation: th
    },
    tl:{
        translation: tl
    }
};

i18n.use(reactI18nextModule)
    .init({
        resources,
        lng: "vi",
        fallbackLng: "vi",
        interpolation: {
            escapeValue: false
        }
    });
export default i18n;
