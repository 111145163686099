import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Col, Divider, Modal, notification, Row, Table, Tooltip } from 'antd'
import * as AntdIcon from '@ant-design/icons'
import { LocalStore } from '../../utils/LocalStore'
import { moneyCeil, moneyFormat, formatPriceByUnit } from '../../utils/stringUtils'
import DetailFee from '../ConfigGroupDetail/DetailFee'
import UserService from '../../services/user.service'
import { ModalFinancial } from '../../containers/PeerPayments/ModalFinancial'

export default class TabFees extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isShowModalFinancial: false,
        }
    }

    componentDidMount() {
        this.getListFees()
        // this.getListServices()
        // this.getServices()
        this.getFees()
    }

    /**
     * khi window resize
     */
    windowResize = () => {
        const windowWidth = window.innerWidth
        if (!this.isUnmounted)
            this.setState({
                windowWidth: windowWidth,
            })
    }

    getServices = () => {
        const { dataDetail = {}, t } = this.props
        this.setState({ isLoadingDetailServices: true }, () => {
            UserService.getDetailPeerPaymentServices(dataDetail.code, (err, res, headers) => {
                if (!err) {
                    this.setState({
                        isLoadingDetailServices: false,
                        listDetailServices: res,
                    })
                } else {
                    this.setState({
                        isLoadingDetailServices: false,
                    })
                    notification.error({ message: t(err.message) })
                }
            })
        })
    }

    getFees = () => {
        const { dataDetail = {}, t } = this.props
        this.setState({ isLoadingDetailFees: true }, () => {
            UserService.getDetailPeerPaymentFees(dataDetail.code, (err, res, headers) => {
                if (!err) {
                    this.setState({
                        isLoadingDetailFees: false,
                        listDetailFees: res,
                    })
                } else {
                    this.setState({
                        isLoadingDetailFees: false,
                    })
                    notification.error({ message: t(err.message) })
                }
            })
        })
    }

    getListServices = () => {
        const { dataDetail = {}, t } = this.props
        UserService.getPeerPaymentService(dataDetail.configGroupId, (err, res, headers) => {
            if (!err) {
                this.setState({
                    listServices: res,
                })
            } else {
                notification.error({ message: t(err.message) })
            }
        })
    }

    getListFees = () => {
        const { dataDetail = {}, t } = this.props
        UserService.getPeerPaymentFees(dataDetail.configGroupId, (err, res, headers) => {
            if (!err) {
                this.setState({
                    listFees: res,
                })
            } else {
                notification.error({ message: t(err.message) })
            }
        })
    }

    getCoupon = () => {
        const { isLoading } = this.state
        const { orderDetail = {}, t } = this.props
        this.setState({ validating: true }, () => {
            if (isLoading) return
            UserService.getCouponOrder(orderDetail.code, false, (err, res, headers) => {
                if (!err) {
                    this.setState({
                        isLoading: false,
                        listCoupon: res,
                    })
                } else {
                    notification.error({ message: t(err.message) })
                }
            })
        })
    }

    renderListCoupon = () => {
        const { listCoupon = [] } = this.state
        return (
            <div className="txt-color-black">
                {listCoupon.map((x, y) => (
                    <p key={y}>
                        {x.code} - {x.description}
                    </p>
                ))}
            </div>
        )
    }

    renderModal = () => {
        const { orderDetail = {}, t } = this.props
        const { currentFee } = this.state
        return (
            <Modal
                title={t('config_group.feeTable')}
                visible={this.state.visibleModal}
                onOk={() => this.setState({ visibleModal: false }, () => this.setState({ currentFee: null }))}
                onCancel={() => this.setState({ visibleModal: false }, () => this.setState({ currentFee: null }))}
                width={currentFee ? 1100 : 300}
                okText={t('button.close')}
                cancelButtonProps={{ className: 'dpl-none' }}
            >
                {currentFee && (
                    <DetailFee
                        isShipment={false}
                        configGroupId={orderDetail.configGroupId}
                        feeObj={currentFee}
                        orderDetail={orderDetail}
                    />
                )}
            </Modal>
        )
    }

    renderTooltip = (reason, customerDiscountLevel) => {
        const { t } = this.props
        let currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {}

        return (
            <ul className="list-disc pl-4 mb-0">
                {reason && <li>{reason}</li>}
                {customerDiscountLevel && (
                    <li>
                        {currentLoggedUser?.customerLevel?.name} -{' '}
                        {t('fee_tab.discountCustomer', {
                            value:
                                customerDiscountLevel?.discountType === 'PERCENT'
                                    ? `${customerDiscountLevel?.discountValue}%`
                                    : formatPriceByUnit(customerDiscountLevel?.discountValue),
                        })}
                    </li>
                )}
            </ul>
        )
    }

    renderListItem = (item, index, props) => {
        const { t, orderDetail } = this.props
        const { listFees = [], listServices = [] } = this.state
        let currentFee =
            props === 'fees'
                ? listFees.find(x => x.code === item.feeCode) || {}
                : listServices.find(x => x.code === item.feeCode) || {}
        const customerDiscountLevel = orderDetail?.customerDiscountLevels?.find(i => i?.feeCode === item?.type?.code)
        return (
            <li
                className={`_listItem dpl-flex justify-content-between align-items-center pdt5 pdbt5`}
                key={index}
            >
                <span className={'_service-name'}>
                    {currentFee.name || item.feeCode || '---'}
                    {/*{*/}
                    {/*currentFee &&*/}
                    {/*<Icon onClick={() => this.setState({visibleModal: true, currentFee: currentFee})} className="txt-size-h8 mgl5" type="dollar" />*/}
                    {/*}*/}
                </span>
                {item.free ? (
                    <span className={'_service-value'}>
                        <span className="txt-color-gray pdr5 txt-through">{moneyFormat(moneyCeil(item.actualAmount))}</span>{' '}
                        {t('shipment.tab_fees_free')}{' '}
                        {item.reason || customerDiscountLevel ? (
                            <Tooltip title={this.renderTooltip(item?.reason, customerDiscountLevel)}>
                                <AntdIcon.QuestionCircleOutlined
                                    className="txt-size-h8 mgl10"
                                    type="question-circle"
                                />
                            </Tooltip>
                        ) : (
                            <span style={{ width: 22, display: 'inline-block' }} />
                        )}
                    </span>
                ) : item.manual && item.provisionalAmount !== null ? (
                    <span className={'_service-value'}>
                        <span className="txt-color-gray pdr5 txt-through">{moneyFormat(moneyCeil(item.provisionalAmount))}</span>{' '}
                        {moneyFormat(moneyCeil(item.actualAmount))}{' '}
                        {item.reason || customerDiscountLevel ? (
                            <Tooltip title={this.renderTooltip(item?.reason, customerDiscountLevel)}>
                                <AntdIcon.QuestionCircleOutlined
                                    className="txt-size-h8 mgl10"
                                    type="question-circle"
                                />
                            </Tooltip>
                        ) : (
                            <span style={{ width: 22, display: 'inline-block' }} />
                        )}
                    </span>
                ) : (
                    <span className={'_service-value'}>
                        {moneyFormat(moneyCeil(item.actualAmount))}{' '}
                        {item.reason || customerDiscountLevel ? (
                            <Tooltip title={this.renderTooltip(item?.reason, customerDiscountLevel)}>
                                <AntdIcon.QuestionCircleOutlined
                                    className="txt-size-h8 mgl10"
                                    type="question-circle"
                                />
                            </Tooltip>
                        ) : (
                            <span style={{ width: 22, display: 'inline-block' }} />
                        )}
                    </span>
                )}
            </li>
        )
    }

    renderListItemAdditionalFees = (item, index) => {
        const { t, shipmentFees = [], orderDetail } = this.props
        let currentFee = item.type
            ? shipmentFees.find(
                  x => x.code === item.type.code && x.feeMetadata && x.feeMetadata.template && x.feeMetadata.template !== 'custom'
              )
            : null
        const customerDiscountLevel = orderDetail?.customerDiscountLevels?.find(i => i?.feeCode === item?.type?.code)
        return (
            <li
                className={`_listItem dpl-flex justify-content-between align-items-center pdt5 pdbt5`}
                key={index}
            >
                <span className={'_service-name'}>
                    {item.type && item.type.name ? item.type.name : '---'}{' '}
                    {item.type && !item.type.minFee && !item.type.maxFee ? null : (
                        <Tooltip
                            title={
                                <div>
                                    <div>
                                        {t('fee_tab.min_fee')}: {item.type && moneyFormat(moneyCeil(item.type.minFee))}
                                    </div>
                                    <div>
                                        {t('fee_tab.max_fee')}: {item.type && moneyFormat(moneyCeil(item.type.maxFee))}
                                    </div>
                                </div>
                            }
                        >
                            <AntdIcon.InfoCircleOutlined
                                className="txt-size-h8 mgl5"
                                type="info-circle"
                            />
                        </Tooltip>
                    )}
                    {currentFee && (
                        <AntdIcon.DollarOutlined
                            onClick={() => this.setState({ visibleModal: true, currentFee: currentFee })}
                            className="txt-size-h8 mgl5"
                            type="dollar"
                        />
                    )}
                </span>
                {item.free ? (
                    <span className={'_service-value'}>
                        <span className="txt-color-gray pdr5 txt-through">{moneyFormat(moneyCeil(item.actualAmount))}</span>{' '}
                        {t('shipment.tab_fees_free')}{' '}
                        {item.reason || customerDiscountLevel ? (
                            <Tooltip title={this.renderTooltip(item?.reason, customerDiscountLevel)}>
                                <AntdIcon.QuestionCircleOutlined
                                    className="txt-size-h8 mgl10"
                                    type="question-circle"
                                />
                            </Tooltip>
                        ) : (
                            <span style={{ width: 22, display: 'inline-block' }} />
                        )}
                    </span>
                ) : (
                    <span className={'_service-value'}>
                        {moneyFormat(moneyCeil(item.actualAmount))}{' '}
                        {item.reason || customerDiscountLevel ? (
                            <Tooltip title={this.renderTooltip(item?.reason, customerDiscountLevel)}>
                                <AntdIcon.QuestionCircleOutlined
                                    className="txt-size-h8 mgl10"
                                    type="question-circle"
                                />
                            </Tooltip>
                        ) : (
                            <span style={{ width: 22, display: 'inline-block' }} />
                        )}
                    </span>
                )}
            </li>
        )
    }

    renderOrderFeesItem = () => {
        const { dataDetail = {}, t } = this.props
        // let orderStatus = {};
        // if (dataDetail) {
        //     orderStatus = listStatuses.find(x => x.code === dataDetail.status) || {};
        // }

        // let totalValue = (orderDetail.totalValue || 0) * (orderDetail.exchangeRate || 1);

        // let totalAmount = totalValue + (orderDetail.merchantShippingCost || 0) * (orderDetail.exchangeRate || 1) + (orderDetail.totalFee || 0);

        return (
            <ul className="_orderFees bg-color-blue pdl15 pdr15 pdt10 pdbt10 border-radius4">
                <li className="_orderFeesItem dpl-flex justify-content-between align-items-center pdt5 pdbt5 txt-color-white">
                    <span className={'_totalValue-name'}>{t('peer_payment.exchangedAmount')}</span>
                    <span className={'_totalValue-value'}>{moneyFormat(moneyCeil(dataDetail.exchangedAmount))}</span>
                </li>
                <li className="_orderFeesItem dpl-flex justify-content-between align-items-center pdt5 pdbt5 txt-color-white">
                    <span className={'_totalFee-name'}>{t('fee_tab.fee_total')}</span>
                    <span className={'_totalFee-value'}>{moneyFormat(moneyCeil(dataDetail.totalFee))}</span>
                </li>

                <Divider className="mgbt10 mgt10" />
                <li className="_orderFeesItem dpl-flex justify-content-between align-items-center pdt5 pdbt5 txt-color-white">
                    <span className={'_totalPaid-name'}>{t('fee_tab.paid')}</span>
                    <span className={'_totalPaid-value'}>{moneyFormat(moneyCeil(dataDetail.totalPaid))}</span>
                </li>
                <li className="_orderFeesItem dpl-flex justify-content-between align-items-center pdt5 pdbt5 txt-color-white">
                    <span className={'_totalRefund-name'}>{t('fee_tab.refunded_service')}</span>
                    <span className={'_totalRefund-value'}>{moneyFormat(moneyCeil(dataDetail.totalRefund))}</span>
                </li>
                <li className="_orderFeesItem dpl-flex justify-content-between align-items-center pdt5 pdbt5 txt-color-white">
                    <span className={'_needRefund-name'}>
                        {dataDetail.totalUnpaid >= 0 ? t('order.need_payment') : t('order.excess_cash')}
                    </span>
                    <span className={'_needRefund-value'}>{moneyFormat(moneyCeil(dataDetail.totalUnpaid), null, true)}</span>
                </li>

                {dataDetail.totalClaim && (
                    <>
                        <li className="_orderFeesItem dpl-flex justify-content-between align-items-center pdt5 pdbt5 txt-color-white">
                            <span className={'_needRefund-name'}>{t('order.totalClaim')}</span>
                            <span className={'_needRefund-value'}>
                                {moneyFormat(moneyCeil(dataDetail.totalClaim), null, true)}
                            </span>
                        </li>
                        <li className={'dpl-flex justify-content-flex-end'}>
                            <span
                                onClick={() => this.handleModalFinancialVisibleChange(true)}
                                className={'txt-color-white text-underline cursor-pointer'}
                            >
                                {t('peer_payment.viewDetailClaim')}
                            </span>
                        </li>
                    </>
                )}
            </ul>
        )
    }

    handleModalFinancialVisibleChange = visible => {
        this.setState({ isShowModalFinancial: visible })
    }

    renderModalTotalClaim = () => {
        const { listFinancialClaim, t } = this.props
        const columns = [
            {
                title: t('fee_tab.refunded_name'),
                dataIndex: 'reason',
                className: '_totalClaim-reason break-word',
            },
            {
                title: t('fee_tab.amount'),
                dataIndex: 'amount',
                className: 'txt-right',
                render: txt => <span className="_totalClaim-amount whitespace">{moneyFormat(moneyCeil(txt))}</span>,
            },
            {
                title: t('fee_tab.reason'),
                dataIndex: 'memo',
                className: '_totalClaim-memo break-word whitespace-pre-wrap',
            },
        ]

        return (
            <Modal
                width={700}
                title={t('fee_tab.refunded_list')}
                visible={this.state.modalVisible}
                // onOk={this.handleOk}
                onCancel={() => this.setState({ modalVisible: false })}
                footer={null}
            >
                <Table
                    columns={columns}
                    dataSource={listFinancialClaim}
                    pagination={{
                        hideOnSinglePage: true,
                        pageSize: 25,
                    }}
                    // scroll={{ y: 240 }}
                />
            </Modal>
        )
    }

    renderModalTotalCollect = () => {
        const { listFinancialCollect = [], t } = this.props

        const data = _.groupBy(_.sortBy(listFinancialCollect, 'createdAt').reverse(), 'txid')

        const columns = [
            {
                title: t('fee_tab.createdAt'),
                dataIndex: 'createdAt',
                className: 'vertical-align-text-top',
                render: (txt, row) => {
                    const obj = {
                        children: (
                            <span className="_totalCollect-createdAt whitespace">
                                {txt ? moment(txt).format('HH:mm DD/MM/YYYY') : '---'}
                            </span>
                        ),
                        props: {
                            rowSpan:
                                _.sortBy(data[row.txid], 'createdAt')
                                    .reverse()
                                    .findIndex(x => x.id === row.id) === 0
                                    ? data[row.txid].length
                                    : 0,
                        },
                    }

                    return obj
                },
            },
            {
                title: t('fee_tab.refundType'),
                dataIndex: 'refundType',
                className: 'vertical-align-text-top',
                render: (txt, row) => {
                    return <div className="_totalCollect-refundType">{row.financialAccount.name || '---'}</div>
                },
            },
            {
                title: t('fee_tab.amount'),
                dataIndex: 'amount',
                className: 'vertical-align-text-top txt-right',
                render: txt => {
                    return <div className="_totalCollect-amount">{moneyFormat(moneyCeil(txt))}</div>
                },
            },
            {
                title: t('fee_tab.reason'),
                dataIndex: 'memo',
                render: txt => {
                    return (
                        <div className="_totalClaim-memo break-word whitespace-pre-wrap">
                            {txt && txt.trim() ? txt.trim() : '---'}
                        </div>
                    )
                },
            },
        ]

        return (
            <Modal
                width={700}
                title={t('fee_tab.retrospectiveList')}
                visible={this.state.modalCollectVisible}
                // onOk={this.handleOk}
                onCancel={() => this.setState({ modalCollectVisible: false })}
                footer={null}
            >
                <Table
                    className={'table-refund'}
                    columns={columns}
                    dataSource={_.sortBy(listFinancialCollect, 'createdAt').reverse()}
                    bordered
                    pagination={{
                        hideOnSinglePage: true,
                        pageSize: 25,
                    }}
                    // scroll={{ y: 240 }}
                />
            </Modal>
        )
    }

    render() {
        const { dataDetail = {}, t } = this.props
        const { listDetailFees = [], isShowModalFinancial } = this.state

        // console.log('listDetailServices', listDetailServices)
        // console.log('listDetailFees', listDetailFees)
        // console.log('listServices', listServices)
        // console.log('listFees', listFees)

        // let feesArray = _.sortBy(listFees, 'position').filter(x => x.type && !x.type.shipping && !x.type.additional);
        // let shippingFeesArray = _.sortBy(listFees, 'position').filter(x => x.type && x.type.shipping && !x.type.additional);
        // let additionalArray = _.sortBy(listFees, 'position').filter(x => x.type && !x.type.shipping && x.type.additional);
        return (
            <div className={'box-financial'}>
                <Row className={'pdl15 pdr15'}>
                    <Col
                        xl={16}
                        sm={12}
                        className={'pdr15'}
                    >
                        {listDetailFees.length > 0 && (
                            <React.Fragment>
                                <h3 className="txt-size-h7 txt-color-black roboto-medium txt-uppercase mgbt5">
                                    {t('fee_tab.service_fee')}
                                </h3>
                                <ul className="_fees bg-color-gray pdl15 pdr15 pdt10 pdbt10 border-radius4">
                                    {listDetailFees.map((item, index) => this.renderListItem(item, index, 'fees'))}
                                </ul>
                            </React.Fragment>
                        )}

                        {/*{*/}
                        {/*    listDetailServices.length > 0 && <React.Fragment>*/}
                        {/*        <h3 className="txt-size-h7 txt-color-black roboto-medium txt-uppercase mgbt5">{t('fee_tab.service_fee')}</h3>*/}
                        {/*        <ul className="_fees bg-color-gray pdl15 pdr15 pdt10 pdbt10 border-radius4">*/}
                        {/*            {listDetailServices.map((item, index) => this.renderListItem(item, index, 'services'))}*/}
                        {/*        </ul>*/}
                        {/*    </React.Fragment>*/}
                        {/*}*/}

                        {/*{*/}
                        {/*    shippingFeesArray.length > 0 && <React.Fragment>*/}
                        {/*        <h3 className="txt-size-h7 txt-color-black roboto-medium txt-uppercase mgbt5">{t('fee_tab.transport_fee')}</h3>*/}
                        {/*        <ul className="_shippingFees bg-color-gray pdl15 pdr15 pdt10 pdbt10 border-radius4">*/}
                        {/*            {shippingFeesArray.map(this.renderListItem)}*/}
                        {/*        </ul>*/}
                        {/*    </React.Fragment>*/}
                        {/*}*/}

                        {/*{*/}
                        {/*    additionalArray.length > 0 && <React.Fragment>*/}
                        {/*        <h3 className="txt-size-h7 txt-color-black roboto-medium txt-uppercase mgbt5">{t('fee_tab.surcharge')}</h3>*/}
                        {/*        <ul className="_additionalFees bg-color-gray pdl15 pdr15 pdt10 pdbt10 border-radius4">*/}
                        {/*            {additionalArray.map(this.renderListItemAdditionalFees)}*/}
                        {/*        </ul>*/}
                        {/*    </React.Fragment>*/}
                        {/*}*/}
                    </Col>
                    <Col
                        xl={8}
                        sm={12}
                    >
                        <h3 className="txt-size-h7 txt-color-black roboto-medium txt-uppercase mgbt5">
                            {t('eventGroup.FINANCIAL')}
                        </h3>

                        {this.renderOrderFeesItem()}

                        {/*<Voucher orderDetail={dataDetail} listStatuses={listStatuses} t={t}/>*/}
                    </Col>
                </Row>
                {/*{this.renderModalTotalClaim()}*/}
                {/*{this.renderModalTotalCollect()}*/}
                {/*{this.renderModal()}*/}

                {isShowModalFinancial && (
                    <ModalFinancial
                        t={t}
                        currency={dataDetail.currency}
                        exchangeRate={dataDetail.exchangeRate}
                        peerPaymentCode={dataDetail.code}
                        onVisibleChange={this.handleModalFinancialVisibleChange}
                    />
                )}
            </div>
        )
    }
}
