/* eslint-disable array-callback-return */
/* eslint-disable no-useless-constructor */
import { Component } from 'react'
import Dropzone from 'react-dropzone'
// import {fromJS} from "immutable";
import { notification } from 'antd'
import * as AntdIcon from '@ant-design/icons'


class Camera extends Component {
    static defaultProps = {
        maxLength: 10,
        inputProps: {},
    }

    constructor(props) {
        super(props)
    }

    onReject = () => {
        const { t } = this.props
        notification['error']({
            message: t('error.upload_image'),
        })
    }

    checkImages = files => {
        let check = true
        let file_accepted = ['image/png', 'image/jpg', 'image/jpeg']
        Array.isArray(files) &&
            files.map((data, index) => {
                if (!file_accepted.includes(data.type)) {
                    check = false
                }
            })
        return check
    }

    /**
     * thêm ảnh
     * @param files
     */
    onDropAccepted = files => {
        const { t } = this.props
        if (files.length <= this.props.maxLength) {
            if (this.props.addFiles) {
                this.props.addFiles(files)
            }
        } else {
            notification['error']({
                message: t('error.upload_picture_more', { value: this.props.maxLength }),
            })
        }
    }

    render() {
        const { className, inputProps, isUploading, isUploadIcon, acceptedFiles } = this.props
        return (
            <div className="camera-dropzone">
                <Dropzone
                    disabled={isUploading}
                    accept={acceptedFiles}
                    className={className ? className : ''}
                    onDropAccepted={this.onDropAccepted}
                    onDropRejected={this.onReject}
                    maxSize={10485760} // max 10mb
                    inputProps={inputProps}
                >
                    <span className="_chatbox-camera ic-camera txt-size-h4 txt-color-gray position-ab cursor-pointer">
                        {isUploading ? (
                            <AntdIcon.Loading3QuartersOutlined
                                spin={true}
                                type="loading"
                            />
                        ) : isUploadIcon ? (
                            <AntdIcon.UploadOutlined />
                        ) : (
                            <AntdIcon.PaperClipOutlined type="paper-clip" />
                        )}
                    </span>
                </Dropzone>
            </div>
        )
    }
}

export default Camera
