/* eslint-disable array-callback-return */
import React from 'react'
import moment from 'moment'
import MainLayout from '../../components/Layout/MainLayout'
import Loading from '../../components/Loading'
import { Link } from 'react-router-dom'
import {
    Alert,
    Button,
    Col,
    DatePicker,
    Empty,
    Input,
    notification,
    Popconfirm,
    Row,
    Select,
    Table,
    Tag,
    Tooltip,
    Typography,
} from 'antd'
import * as AntdIcon from '@ant-design/icons'
import AntdIconSVG, { SwapRightOutlined } from '@ant-design/icons'
import { LocalStore } from '../../utils/LocalStore'
import { getUrlQueryString } from '../../utils/helper'
import { isInSuspensionTime } from './helper/checkIsInSuspensionTime'
import {
    hashCode,
    intToRGB,
    moneyFormat,
    parseQueryStringToObject,
    quantityFormat,
    updateQueryStringParameter,
} from '../../utils/stringUtils'
import CategoriesService from '../../services/categories.service'
import UserService from '../../services/user.service'
import { withNamespaces } from 'react-i18next'
import { ReactComponent as iconPayment } from '../../resources/images/payment-method.svg'
import { ReactComponent as iconDisabledPayment } from '../../resources/images/payment-method-disable.svg'
import ModalCreatePeerPaymentPay from '../../components/PeerPayment/ModalCreatePeerPaymentPay'
import ModalMarkupRateGroupsHardCode from '../../components/PeerPayment/ModalMarkupRateGroupsHardCode'
import ModalMarkupRateGroups from '../../components/PeerPayment/ModalMarkupRateGroups'
import ExportExcel from '../../components/ExportExcel'
import saveAs from 'file-saver'
import { ModalSelectedPeerPayment } from '../../components/PeerPayment/ModalSelectedPeerPayment'
import { ModalChargeRequestResult } from '../../components/PeerPayment/ModalChargeRequestResult'
import lodash from 'lodash'
import queryString from 'query-string'
import { MilestonePopover } from './MilestonePopover'
import ModalCreatePeerPayment from '../../components/PeerPayment/ModalCreatePeerPayment'
import { ModalConfirmPayment } from './ModalConfirmPayment'
import _ from 'lodash'
import TaobaoService from '../../services/taobao.service'

let currentLoggedUser = null
let pageSize = 10

const BIFIN = [
    {
        code: 'CONTRACTED',
        label: 'Đã duyệt khoản ứng vốn',
    },
    {
        code: 'NOSHOW',
        label: 'Đã hủy khoản ứng vốn',
    },
    {
        code: 'NONE',
        label: 'Không có khoản ứng vốn',
    },
]

const QUALIFY_LOAN = [
    {
        code: 'pass',
        label: 'Đủ điều kiện ứng vốn',
    },
    {
        code: 'fail',
        label: 'Không đủ điều kiện ứng vốn',
    },
]

class PeerPayments extends React.Component {
    constructor(props) {
        super(props)
        this.isUnmounted = false
        this.state = {
            page: 1,
            pageSize: pageSize,
            isLoading: true,
            listPayments: [],
            listChecked: {},
            startTime: undefined,
            endTime: undefined,
            querySearch: '',
            typeSearch: '',
            peerPaymentType: '',
            isLoadingExchange: true,
            originalReceiptCode: '',
            billTo: '',
            peerPaymentStatuses: [],
            milestoneStatus: undefined,
            milestoneFrom: null,
            milestoneTo: null,
            peerPaymentItemMilestoneVisible: null,
            peerPaymentItemMilestoneLoading: false,
            peerPaymentItemMilestoneStatuses: [],
            selectedRowKeys: [],
            selectedRows: [],
            isShowModalSelectedPeerPayment: false,
            isShowModalChargeRequestResult: false,
            chargeRequestResult: {},
            chargeRequestLoading: false,
            exchangeRatesByAmount: [],
            isEnabledBiffin: false,
            isOpenConfirmPayment: false,
            tenantConfigPayment: {},
            contractWithShopkeeper: undefined,
            hasCollateral: undefined,
            haveLoan: '',
            quanlifyLoan: '',
            userBalance: {
                balance: 0,
                creditLimit: 0,
            },
            currentProjectInfo: LocalStore.getInstance().read('currentProjectInfo') || {},
        }
    }

    componentDidMount() {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null
        // window.addEventListener('resize', this.windowResize);
        window.addEventListener('popstate', this.onPopState.bind(this))
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const tenantConfig = currentProjectInfo.tenantConfig || {}
        const generalConfig = tenantConfig.generalConfig || {}
        const peerPaymentConfig = tenantConfig.peerPaymentConfig || {}
        if (!generalConfig.peerPaymentEnabled) {
            this.props.history.push('/404')
        }
        currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser')
        const shopkeeperAuth = lodash.get(currentLoggedUser, 'customerAuthorities.shopkeeper')
        const bifinEnabled = lodash.get(currentProjectInfo, 'tenantConfig.externalIntegrationConfig.shopkeeper.enabled', false)
        const bifinPeerpaymentEnabled = lodash.get(
            currentProjectInfo,
            'tenantConfig.externalIntegrationConfig.shopkeeper.enabledPeerpayment',
            false
        )
        
        this.setState({ isLoading: true, currentLoggedUser, currentProjectInfo, peerPaymentConfig }, () => {
            // this.getSuspensionTimes();
            this.loadFromQueryString()
            // this.getListServices();
            this.getListFees()
            this.getPaymentMethods()
            this.getPaymentStatus()
            this.getPeerPaymentDaily()
            this.getTenantConfigPayment()
            this.getUserBalance()
            if (shopkeeperAuth && bifinEnabled && bifinPeerpaymentEnabled) this.setState({ isEnabledBiffin: true })
        })
    }

    getUserBalance = () => {
        this.getUserBalancePromise().then(response => {
            this.setState({
                userBalance: {
                    balance: response.balance,
                    creditLimit: response.creditLimit,
                },
            })
        })
    }

    getUserBalancePromise = () => {
        return new Promise((resolve, reject) => {
            UserService.getBalanceUser((error, response) => {
                if (error) reject(error)
                else resolve(response)
            })
        })
    }

    getTenantConfigPayment = () => {
        CategoriesService.getTenantConfigPayment((error, response) => {
            if (!error) {
                this.setState({
                    tenantConfigPayment: response,
                })
            } else {
                this.setState({
                    tenantConfigPayment: {},
                })
            }
        })
    }

    /**
     * Khi unmount ra thì bỏ sự kiện popstate
     */
    componentWillUnmount() {
        this.isUnmounted = true
        window.removeEventListener('popstate', this.onPopState.bind(this))
    }

    /**
     * On popstate
     */
    onPopState() {
        if (!this.isUnmounted)
            this.setState({ isLoading: true }, () => {
                this.loadFromQueryString()
            })
    }

    /**
     * Load từ QueryString (dành cho lần load đầu tiên)
     */

    loadFromQueryString = () => {
        let queryString = getUrlQueryString()
        let queryObj = parseQueryStringToObject(queryString) || {}

        // console.log('queryObj', queryObj);
        // chuyển giá trị từ queryObj vào state;
        let arrayValue = []
        //reset các param khi load từ url
        let listChecked = {}
        let querySearch = ''
        let paymentAccount = ''
        let beneficiaryAccount = ''
        let peerPaymentType = ''
        let startTime = undefined
        let endTime = undefined
        let originalReceiptCode = ''
        let billTo = ''
        let milestoneStatus = undefined
        let milestoneFrom = ''
        let milestoneTo = ''
        let contractWithShopkeeper = ''
        let hasCollateral = undefined
        let quanlifyLoan = ''

        for (let p in queryObj) {
            if (p === 'statuses' || p === 'paymentMethod') {
                // xử lý với obj list checked
                arrayValue = queryObj[p].split(',')
                // console.log('arrayValue', arrayValue)
                listChecked[p] = arrayValue
            } else if (p === 'timestampFrom') {
                // xử lý với với timestamp
                startTime = decodeURIComponent(queryObj['timestampFrom'])
            } else if (p === 'timestampTo') {
                // xử lý với với timestamp
                endTime = decodeURIComponent(queryObj['timestampTo'])
            } else if (p === 'paymentAccount') {
                // xử lý với với timestamp
                paymentAccount = decodeURIComponent(queryObj['paymentAccount'] || '')
            } else if (p === 'beneficiaryAccount') {
                // xử lý với với timestamp
                beneficiaryAccount = decodeURIComponent(queryObj['beneficiaryAccount'] || '')
            } else if (p === 'peerPaymentType') {
                // xử lý với với timestamp
                peerPaymentType = decodeURIComponent(queryObj['peerPaymentType'])
            } else if (p === 'originalReceiptCode') {
                originalReceiptCode = decodeURIComponent(queryObj['originalReceiptCode'])
            } else if (p === 'billTo') {
                billTo = decodeURIComponent(queryObj['billTo'])
            } else if (p === 'milestoneStatus') {
                milestoneStatus = decodeURIComponent(queryObj['milestoneStatus'])
            } else if (p === 'contractWithShopkeeper') {
                contractWithShopkeeper = decodeURIComponent(queryObj['contractWithShopkeeper'])
            } else if (p === 'hasCollateral') {
                hasCollateral = decodeURIComponent(queryObj['hasCollateral'])
                if (hasCollateral === 'true') {
                    quanlifyLoan = 'pass'
                } else {
                    quanlifyLoan = 'fail'
                }
            } else if (p === 'milestoneFrom') {
                milestoneFrom = decodeURIComponent(queryObj['milestoneFrom'])
            } else if (p === 'milestoneTo') {
                milestoneTo = decodeURIComponent(queryObj['milestoneTo'])
            } else {
                // xử lý với query
                querySearch = decodeURIComponent(queryObj['query'] || '')
            }
        }

        this.setState(
            {
                querySearch,
                paymentAccount,
                beneficiaryAccount,
                peerPaymentType: peerPaymentType || 'payment',
                startTime,
                endTime,
                listChecked,
                page: queryObj.page,
                pageSize: queryObj.size || pageSize,
                originalReceiptCode,
                billTo,
                milestoneStatus,
                milestoneFrom,
                milestoneTo,
                contractWithShopkeeper,
                hasCollateral,
                quanlifyLoan,
            },
            () => {
                this.loadData()
                this.getPaymentAccounts()
            }
        )
    }

    getPeerPaymentDaily = () => {
        const { t } = this.props
        UserService.getPeerPaymentDaily((err, res, headers) => {
            if (!err) {
                this.setState({
                    daily: res,
                })
            } else {
                notification.error({ message: t(err.message) })
            }
        })
    }

    /**
     * load data
     */
    loadData = cb => {
        const { t } = this.props
        const { page, pageSize } = this.state
        let queryString = this.buildQueryString()
        if (queryString) {
            queryString = queryString + `&offset=${page ? (page - 1) * pageSize : 0}&limit=${pageSize + 1}&sort=createdAt:desc`
        } else {
            queryString = `?offset=${page ? (page - 1) * pageSize : 0}&limit=${pageSize + 1}&sort=createdAt:desc`
        }
        this.setState({ showProgressBar: true }, () => {
            UserService.getPeerPayments(queryString, (err, res, headers) => {
                if (!err) {
                    this.setState(
                        {
                            listPayments: res.slice(0, pageSize),
                            hasMore: res.length > pageSize,
                        },
                        () => {
                            //window.scrollTo(0, 0);
                            if (this.mainLayoutElement && this.mainLayoutElement.scrollLayoutElement)
                                this.mainLayoutElement.scrollLayoutElement.scrollTop = 0
                        }
                    )
                    let queryString = getUrlQueryString()
                    let parseQueryString = parseQueryStringToObject(queryString)
                    if (parseQueryString.peerPaymentType ==='taobao_global') {
                        this.getAlias(res) 
                    }
                } else {
                    notification['error']({
                        message: t(err.message) || t('message.unconnected_error'),
                    })
                }
                if (cb) cb()
                this.setState({ showProgressBar: false, isLoading: false })
            })
        })
    }

    onPageChanged = page => {
        this.setState({ page: page }, () => {
            let queryString = getUrlQueryString()
            queryString = updateQueryStringParameter(queryString ? '?' + queryString : '', 'page', page)
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: queryString,
            })
            // window.scrollTo(0, 0);
        })
    }

    getListServices = () => {
        const { t } = this.props
        const { currentLoggedUser = {} } = this.state
        UserService.getPeerPaymentService(currentLoggedUser.peerPaymentConfigGroupId, (err, res, headers) => {
            if (!err) {
                this.setState({
                    listServices: res,
                })
            } else {
                notification.error({ message: t(err.message) })
            }
        })
    }

    getListFees = () => {
        const { t } = this.props
        const { currentLoggedUser = {} } = this.state
        UserService.getPeerPaymentFees(currentLoggedUser.peerPaymentConfigGroupId, (err, res, headers) => {
            if (!err) {
                this.setState({
                    listFees: res,
                })
            } else {
                notification.error({ message: t(err.message) })
            }
        })
    }

    /**
     * Gọi yêu cầu thanh toán
     */
    chargeRequest = async code => {
        const { t } = this.props
        const { chargeRequest, listPayments, isLoadingExchange } = this.state
        if (chargeRequest || isLoadingExchange) return
        await this.getUserBalancePromise()
        this.setState({ chargeRequest: true }, () => {
            UserService.chargeRequest(code, (err, res, headers) => {
                this.getUserBalance()

                const payment = listPayments.find(it => it.code === code)

                if (err === null) {
                    this.setState({
                        chargeRequest: false,
                    })
                    this.loadData()
                    notification['success']({
                        message: t('message.success'),
                    })
                } else {
                    if (err.title === 'invalid_amount') {
                        notification['error']({
                            message: 'Đã có thay đổi về dữ liệu, vui lòng tải lại trang.',
                        })
                    } else if (err.title === 'insufficient_balance') {
                        const { balance, creditLimit } = this.state.userBalance
                        const totalMoney = payment.totalFee + payment.exchangedAmount
                        const topUpMoney = totalMoney - (balance + creditLimit)
                        const compiled = lodash.template(t('cartCheckout.notEnoughMoney'))
                        notification['error']({
                            message: compiled({
                                money: moneyFormat(topUpMoney),
                            }),
                        })
                    } else {
                        notification['error']({
                            message: t(err.message),
                        })
                    }
                    this.setState({
                        chargeRequest: false,
                    })
                }
            })
        })
    }

    chargeRequestWithCredit = async code => {
        const { t } = this.props
        const { chargeRequest, listPayments, isLoadingExchange } = this.state
        if (chargeRequest || isLoadingExchange) return
        await this.getUserBalancePromise()
        this.setState({ chargeRequest: true }, () => {
            UserService.chargeRequestWithCredit(code, (err, res, headers) => {
                this.getUserBalance()
                const payment = listPayments.find(it => it.code === code)
                if (err === null) {
                    this.setState({
                        chargeRequest: false,
                        isOpenConfirmPayment: false,
                    })
                    this.loadData()
                    notification['success']({
                        message: t('message.success'),
                    })
                } else {
                    if (err.title === 'ask_for_loan_timeout') {
                        notification['error']({
                            message: 'YCTT có thay đổi tỷ giá vui lòng thao tác lại.',
                        })
                    } else if (err.title === 'insufficient_balance') {
                        const { balance, creditLimit } = this.state.userBalance
                        const totalMoney = payment.totalFee + payment.exchangedAmount
                        const topUpMoney = totalMoney - (balance + creditLimit)
                        const compiled = lodash.template(t('cartCheckout.notEnoughMoney'))
                        notification['error']({
                            message: compiled({
                                money: moneyFormat(topUpMoney),
                            }),
                        })
                    } else {
                        notification['error']({
                            message: t(err.message),
                        })
                    }
                    this.setState({
                        chargeRequest: false,
                    })
                }
            })
        })
    }

    /**
     * lấy ra danh sách trạng thái của package
     */
    getPaymentMethods = () => {
        CategoriesService.getPaymentMethods((err, res, headers) => {
            if (err === null) {
                this.setState({
                    listPaymentMethods: res || [],
                })
            }
        })
    }

    /**
     * lấy ra danh sách trạng thái của package
     */
    getPaymentStatus = () => {
        CategoriesService.getPaymentStatus((err, res, headers) => {
            if (err === null) {
                this.setState({
                    listPaymentStatus: res || [],
                })
            }
        })
    }

    /**
     * lấy ra danh sách trạng thái của package
     */
    getPaymentAccounts = () => {
        const { listChecked = [] } = this.state
        let paymentMethodCode =
            Array.isArray(listChecked['paymentMethod']) && listChecked['paymentMethod'].length > 0
                ? listChecked['paymentMethod'][0]
                : ''

        let queryString = paymentMethodCode ? `?paymentMethodCode=${paymentMethodCode}&limit=1000` : '?limit=1000'
        CategoriesService.getPaymentAccounts(queryString, (err, res, headers) => {
            if (err === null) {
                this.setState({
                    listPaymentAccounts: res || [],
                })
            }
        })
    }

    /**
     * lấy ra prop giá trị của obj prop
     * @param props
     * @returns {*}
     */
    getValuePropOfProp = props => {
        switch (props) {
            case 'statuses':
                return 'code'
            case 'paymentMethod':
                return 'code'
            default:
                return 'id'
        }
    }

    /**
     * xây dựng query string theo các giá trị trong state
     * @returns {string}
     */
    buildQueryString = () => {
        let {
            listChecked,
            startTime,
            endTime,
            querySearch,
            paymentAccount,
            beneficiaryAccount,
            peerPaymentType,
            originalReceiptCode,
            billTo,
            milestoneStatus,
            milestoneFrom,
            milestoneTo,
            contractWithShopkeeper,
            hasCollateral,
        } = this.state
        let queryString = ''
        for (let p in listChecked) {
            let value = ''
            if (Array.isArray(listChecked[p]) && listChecked[p].length > 0) {
                listChecked[p].map(x => {
                    value = value + x + ','
                })

                value = value.slice(0, -1)
            }
            if (value) queryString = updateQueryStringParameter(queryString, p, value)
        }

        if (startTime) {
            queryString = updateQueryStringParameter(queryString, 'timestampFrom', startTime)
        }

        if (endTime) {
            queryString = updateQueryStringParameter(queryString, 'timestampTo', endTime)
        }

        if (contractWithShopkeeper) {
            queryString = updateQueryStringParameter(queryString, 'contractWithShopkeeper', contractWithShopkeeper)
        }

        if (hasCollateral !== undefined) {
            queryString = updateQueryStringParameter(queryString, 'hasCollateral', hasCollateral)
        }

        if (paymentAccount) {
            queryString = updateQueryStringParameter(queryString, 'paymentAccount', paymentAccount)
        }

        if (beneficiaryAccount) {
            queryString = updateQueryStringParameter(queryString, 'beneficiaryAccount', beneficiaryAccount)
        }

        if (querySearch) {
            queryString = updateQueryStringParameter(queryString, 'query', querySearch.trim())
        }

        if (peerPaymentType) {
            queryString = updateQueryStringParameter(queryString, 'peerPaymentType', peerPaymentType.trim())
        } else {
            queryString = updateQueryStringParameter(queryString, 'peerPaymentType', 'payment')
        }

        if (originalReceiptCode) {
            queryString = updateQueryStringParameter(queryString, 'originalReceiptCode', originalReceiptCode.trim())
        }

        if (billTo) {
            queryString = updateQueryStringParameter(queryString, 'billTo', billTo.trim())
        }

        if (milestoneStatus) {
            queryString = updateQueryStringParameter(queryString, 'milestoneStatus', milestoneStatus.trim())
        }

        if (milestoneFrom) {
            queryString = updateQueryStringParameter(queryString, 'milestoneFrom', milestoneFrom.trim())
        }

        if (milestoneTo) {
            queryString = updateQueryStringParameter(queryString, 'milestoneTo', milestoneTo.trim())
        }

        return queryString
    }

    /**
     * submit
     */
    onSubmit = () => {
        const { stringNewWaybills, onSubmit } = this.state
        const { t } = this.props

        if (!stringNewWaybills || !stringNewWaybills.trim()) {
            this.setState({
                stringNewWaybillsError: t('shipment.require'),
            })
            return
        }
        if (onSubmit) return

        let arrString = stringNewWaybills.trim().split(/[^A-Za-z0-9]/)
        arrString = arrString.filter(x => !!x)
        let body = { waybillNumbers: arrString }
        this.setState({ onSubmit: true }, () => {
            UserService.createNewWaybills(body, (err, res, headers) => {
                if (!err) {
                    notification['success']({
                        message: t('message.success'),
                    })
                    this.setState({
                        onSubmit: false,
                    })
                    this.loadData()
                } else {
                    if (err.title === 'too_much_waybill') {
                        notification['error']({
                            message: t('waybill.create_waybill_too_much'),
                        })
                    } else if (err.title === 'default_address_not_found') {
                        notification['error']({
                            message: t('waybill.default_address_not_found'),
                        })
                    } else {
                        notification['error']({
                            message: t(err.message),
                        })
                    }
                    this.setState({ onSubmit: false })
                }
            })
        })
    }

    onClearSearch = () => {
        this.setState(
            {
                page: 1,
                querySearch: '',
                paymentAccount: '',
                beneficiaryAccount: '',
                startTime: undefined,
                endTime: undefined,
                listChecked: {},
                originalReceiptCode: '',
                billTo: '',
                milestoneStatus: undefined,
                milestoneFrom: null,
                milestoneTo: null,
                contractWithShopkeeper: undefined,
                hasCollateral: undefined,
                quanlifyLoan: '',
            },
            () => {
                this.props.history.push({
                    pathname: this.props.location.pathname,
                    search: this.buildQueryString(),
                })
            }
        )
    }

    /**
     * xử lý với dữ liệu search trong state
     */
    onSubmitSearch = () => {
        this.setState({ page: 1 }, () => {
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: this.buildQueryString(),
            })
        })
    }

    startTimeChange = date => {
        this.setState({
            startTime: date ? date.startOf('day').toISOString() : undefined,
        })
    }

    endTimeChange = date => {
        this.setState({
            endTime: date ? date.endOf('day').toISOString() : undefined,
        })
    }

    disabledEndDate = endValue => {
        const startValue = this.state.startValue
        if (!endValue || !startValue) {
            return false
        }
        return endValue.valueOf() <= startValue.valueOf()
    }

    onCheckBoxCheck = (item, prop, single, cb) => {
        let { listChecked } = this.state

        let arrayPropChecked = listChecked[prop] || []
        if (single) {
            if (arrayPropChecked.find(x => x === item[this.getValuePropOfProp(prop)])) {
                arrayPropChecked = arrayPropChecked.filter(x => x !== item[this.getValuePropOfProp(prop)])
            } else {
                arrayPropChecked = [item[this.getValuePropOfProp(prop)]]
            }
        } else {
            if (arrayPropChecked.find(x => x === item[this.getValuePropOfProp(prop)])) {
                arrayPropChecked = arrayPropChecked.filter(x => x !== item[this.getValuePropOfProp(prop)])
            } else {
                arrayPropChecked.push(item[this.getValuePropOfProp(prop)])
            }
        }

        listChecked[prop] = arrayPropChecked

        this.setState(
            {
                listChecked,
            },
            () => {
                if (cb) cb()
            }
        )
    }

    /**
     * lấy ra danh sách trạng thái của package
     */
    getExchangeRate = (query = '') => {
        UserService.getExchangeRatePeerPayMent(query, (err, res, headers) => {
            if (err === null) {
                this.setState(
                    {
                        exchangeRateByAmount: res || {},
                    },
                    () => {
                        setTimeout(() => {
                            this.setState({ isLoadingExchange: false })
                        }, 1000)
                    }
                )
            } else {
                this.setState({
                    isLoadingExchange: false,
                })
            }
        })
    }

    /**
     * render orderStatus Search
     * @param item
     * @param index
     * @param prop
     * @param single
     * @param cb
     */
    renderStatusSearchItem = (item, index, prop, single, cb) => {
        const arrayPropChecked = this.state.listChecked[prop] || []
        // console.log('arrayPropChecked', arrayPropChecked)
        let checked =
            Array.isArray(arrayPropChecked) && arrayPropChecked.find(x => x === item[this.getValuePropOfProp(prop)]) !== undefined
        return (
            <Tag.CheckableTag
                key={index}
                onChange={() => this.onCheckBoxCheck(item, prop, single, cb)}
                className={`_check-${prop}-item cursor-pointer txt-size-h7 robotoregular txt-capitalize pdl10 pdr10 box-shadow-blue`}
                checked={checked}
            >
                <Typography.Link style={checked ? { color: '#ffffff' } : {}}>{item.name}</Typography.Link>
            </Tag.CheckableTag>
        )
    }
    /**
     * export excel data
     */
    exportExcel = (password, cb, setError) => {
        const { t } = this.props
        const { page, pageSize } = this.state
        let queryString = this.buildQueryString()
        if (queryString) {
            queryString = queryString + `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        } else {
            queryString = `?page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`
        }

        if (!password) {
            if (setError) setError(t('cartCheckout.incorrect_pin'))

            return
        }

        this.setState({ showProgressBar: true, onExport: true }, () => {
            UserService.exportPayments(queryString, { pin: password }, (err, res, headers) => {
                if (!err) {
                    this.downloadFile(res)
                    if (cb) cb()
                } else {
                    if (err.title === 'invalid_pin' || err.title === 'invalid_password') {
                        if (setError) setError(t('cartCheckout.incorrect_pin'))
                    } else
                        notification['error']({
                            message: t(`message.${err.title}`),
                        })
                }
                this.setState({ showProgressBar: false, onExport: false })
            })
        })
    }

    checkExportBtn = () => {
        let urlQueryString = getUrlQueryString()
        let queryObj = parseQueryStringToObject(urlQueryString) || {}
        let datetimeRange = []
        for (let p in queryObj) {
            if (p === 'timestampFrom') {
                // xử lý với với timestamp
                datetimeRange[0] = decodeURIComponent(queryObj['timestampFrom'])
            }
            if (p === 'timestampTo') {
                // xử lý với với timestamp
                datetimeRange[1] = decodeURIComponent(queryObj['timestampTo'])
            }
        }

        if (!datetimeRange[0] || !datetimeRange[1]) {
            return false
        }
        if (moment(datetimeRange[0]).add(3, 'M').isBefore(moment(datetimeRange[1]).startOf('date'))) {
            return false
        }
        return true
    }

    downloadFile = res => {
        if (res && res.headers && res.headers.map) {
            const fileName = res.headers.map['content-disposition'].split('filename=')[1]
            saveAs(res._bodyBlob, fileName)
        }
    }
    /**
     * render header page
     * @returns {*}
     */
    renderSearchBox = () => {
        const {
            listPaymentAccounts = [],
            listPaymentStatus = [],
            listPaymentMethods = [],
            startTime,
            endTime,
            querySearch,
            paymentAccount,
            beneficiaryAccount,
            peerPaymentType,
            originalReceiptCode,
            billTo,
            milestoneStatus,
            milestoneFrom,
            milestoneTo,
            isEnabledBiffin,
        } = this.state
        const { t } = this.props

        return (
            <div className="searchbox border-radius8 bg-color-white pd20 mgbt20">
                <Row className={'pdbt10'}>
                    <Col
                        span={24}
                        className="dpl-flex"
                    >
                        <span className={'searchbox__label txt-color-black txt-size-h7 robotoregular'}>
                            {t('delivery.status')}:
                        </span>
                        <div className={'txt-size-h7 txt-color-blue robotoregular txt-capitalize width-100-percent flex-1-1'}>
                            {listPaymentStatus.map((item, index) => this.renderStatusSearchItem(item, index, 'statuses'))}
                        </div>
                    </Col>
                </Row>
                <Row className={'pdbt10'}>
                    <Col
                        span={24}
                        className="dpl-flex"
                    >
                        <span className="txt-color-black txt-size-h7 pdt3 pdr15">{t('peer_payment.payment_method')}:</span>
                        <div className={'txt-size-h7 txt-color-blue robotoregular txt-capitalize width-100-percent flex-1-1'}>
                            {listPaymentMethods.map((item, index) =>
                                this.renderStatusSearchItem(item, index, 'paymentMethod', true, () => {
                                    this.setState({ paymentAccount: '' })
                                    this.getPaymentAccounts()
                                })
                            )}
                        </div>
                    </Col>
                </Row>
                <Row
                    gutter={20}
                    className={'dpl-flex bd-color-gray borderdotted-bootom-2x pdbt20 pdt5'}
                >
                    <Col span={8}>
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('peer_payment.code')}:
                        </div>
                        <Input
                            value={querySearch}
                            onChange={e => this.setState({ querySearch: e.target.value })}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    this.onSubmitSearch()
                                }
                            }}
                            className={'_search-box-input-query'}
                        />
                    </Col>

                    {peerPaymentType !== 'transfer' ? (
                        <Col span={8}>
                            <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                                {t('peer_payment.paymentAccount')}:
                            </div>

                            <Select
                                value={paymentAccount || []}
                                className="_search-box-input-paymentAccount width-100-percent"
                                onChange={value => this.setState({ paymentAccount: value })}
                                placeholder={t('peer_payment.select_paymentAccount')}
                            >
                                {Array.isArray(listPaymentAccounts) &&
                                    listPaymentAccounts.map((item, index) => (
                                        <Select.Option
                                            key={index}
                                            value={item.account}
                                        >
                                            {item.displayName}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Col>
                    ) : (
                        <Col span={['payment', 'taobao_global'].includes(peerPaymentType) ? 6 : 8}>
                            <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                                {t('peer_payment.beneficiaryAccount')}:
                            </div>

                            <Input
                                value={beneficiaryAccount}
                                onChange={e => this.setState({ beneficiaryAccount: e.target.value })}
                                onKeyDown={e => {
                                    if (e.keyCode === 13) {
                                        this.onSubmitSearch()
                                    }
                                }}
                                className={'_search-box-input-beneficiaryAccount'}
                            />
                        </Col>
                    )}
                    <Col span={8}>
                        <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                            {t('peer_payment.trxTime')}:
                        </div>
                        <Row gutter={20}>
                            <Col
                                span={12}
                                className=""
                            >
                                <DatePicker
                                    disabledDate={this.disabledStartDate}
                                    className="_datepicker width-100-percent"
                                    format={'DD/MM/YYYY'}
                                    value={startTime ? moment(startTime) : undefined}
                                    placeholder={t('package.start_date')}
                                    onChange={this.startTimeChange}
                                // onOpenChange={this.handleStartOpenChange}
                                />
                            </Col>
                            <Col
                                span={12}
                                className=""
                            >
                                <DatePicker
                                    disabledDate={this.disabledEndDate}
                                    className="_datepicker width-100-percent"
                                    format={'DD/MM/YYYY'}
                                    value={endTime ? moment(endTime) : undefined}
                                    placeholder={t('package.end_date')}
                                    onChange={this.endTimeChange}
                                // onOpenChange={this.handleEndOpenChange}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col
                        span={24}
                        className={'mgt20'}
                    >
                        <Row gutter={20}>
                            <Col span={4}>
                                <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                                    {t('peer_payment.originalReceiptCode')}:
                                </div>
                                <Input
                                    value={originalReceiptCode}
                                    onChange={e => this.setState({ originalReceiptCode: e.target.value })}
                                    onKeyDown={e => {
                                        if (e.keyCode === 13) {
                                            this.onSubmitSearch()
                                        }
                                    }}
                                    className={'_search-box-input-query'}
                                />
                            </Col>

                            <Col span={4}>
                                <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                                    {t('peer_payment.billTo')}:
                                </div>
                                <Input
                                    value={billTo}
                                    onChange={e => this.setState({ billTo: e.target.value })}
                                    onKeyDown={e => {
                                        if (e.keyCode === 13) {
                                            this.onSubmitSearch()
                                        }
                                    }}
                                    className={'_search-box-input-query'}
                                />
                            </Col>

                            <Col span={16}>
                                <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                                    {t('peer_payment.filterByMilestoneStatusTime')}:
                                </div>
                                <Row className={`bd1px bd-color-gray `}>
                                    <Col
                                        span={7}
                                        className=""
                                    >
                                        <Select
                                            bordered={false}
                                            allowClear
                                            className={'_milestone-status width100 border-right-1x bd-color-gray'}
                                            placeholder={t('peer_payment.filterByMilestoneStatusTime')}
                                            dropdownMatchSelectWidth={false}
                                            value={milestoneStatus}
                                            onChange={value => this.setState({ milestoneStatus: value })}
                                        >
                                            {listPaymentStatus.map(item => (
                                                <Select.Option
                                                    key={item.code}
                                                    value={item.code}
                                                >
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Col>
                                    <Col
                                        span={17}
                                        className=""
                                    >
                                        <Row className={'dpl-flex align-items-center justify-content-center'}>
                                            <Col span={11}>
                                                <DatePicker
                                                    className="_milestone-from w100"
                                                    locale={'vi'}
                                                    showTime={{
                                                        format: 'HH:mm',
                                                        defaultValue: moment('00:00:00', 'HH:mm'),
                                                    }}
                                                    value={milestoneFrom ? moment(milestoneFrom) : null}
                                                    bordered={false}
                                                    format={'HH:mm DD-MM-YYYY'}
                                                    placeholder={t('peer_payment.filterMilestoneFrom')}
                                                    onChange={value => {
                                                        if (value) {
                                                            this.setState({
                                                                milestoneFrom: value.toISOString(),
                                                            })
                                                        } else {
                                                            this.setState({ milestoneFrom: null })
                                                        }
                                                    }}
                                                />
                                            </Col>
                                            <SwapRightOutlined />
                                            <Col span={11}>
                                                <DatePicker
                                                    value={milestoneTo ? moment(milestoneTo) : null}
                                                    showTime={{
                                                        format: 'HH:mm',
                                                        defaultValue: moment('23:59:59', 'HH:mm'),
                                                    }}
                                                    className="_milestone-to w100"
                                                    locale={'vi'}
                                                    bordered={false}
                                                    format={'HH:mm DD-MM-YYYY'}
                                                    placeholder={t('peer_payment.filterMilestoneTo')}
                                                    onChange={value => {
                                                        if (value) {
                                                            this.setState({
                                                                milestoneTo: value.toISOString(),
                                                            })
                                                        } else {
                                                            this.setState({ milestoneTo: null })
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        {peerPaymentType === 'payment' && isEnabledBiffin && (
                            <Row
                                gutter={20}
                                className={'mgt20'}
                            >
                                <Col span={4}>
                                    <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                                        Điều kiện ứng vốn:
                                    </div>

                                    <Select
                                        value={this.state.quanlifyLoan}
                                        className="_search-box-input-paymentAccount width-100-percent"
                                        onChange={value => {
                                            let hasCollateral = false
                                            if (value === 'pass') {
                                                hasCollateral = true
                                            }
                                            this.setState({ hasCollateral, quanlifyLoan: value })
                                        }}
                                    >
                                        {QUALIFY_LOAN.map(item => {
                                            return <Select.Option value={item.code}>{item.label}</Select.Option>
                                        })}
                                    </Select>
                                </Col>
                                <Col span={4}>
                                    <div className={'search-box__label txt-color-black txt-size-h7 robotoregular mgbt5'}>
                                        Tình trạng ứng vốn:
                                    </div>

                                    <Select
                                        value={this.state.contractWithShopkeeper}
                                        className="_search-box-input-paymentAccount width-100-percent"
                                        onChange={value => {
                                            let contractWithShopkeeper = value
                                            this.setState({ contractWithShopkeeper })
                                        }}
                                    >
                                        {BIFIN.map(item => {
                                            return <Select.Option value={item.code}>{item.label}</Select.Option>
                                        })}
                                    </Select>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
                <div className=" dpl-flex align-items-center justify-content-between pdt10">
                    <div />
                    <div className="dpl-flex align-items-center v2-layout">
                        <span
                            onClick={this.onClearSearch}
                            className="_btn-clear mgr20 txt-color-gray txt-size-h8 cursor-pointer robotoregular"
                        >
                            <AntdIcon.SyncOutlined
                                type={'sync'}
                                className={'mgr5'}
                            />{' '}
                            {t('order.filter_refresh')}
                        </span>
                        <Button
                            onClick={this.onSubmitSearch}
                            type="primary"
                            className={'_btn-search min-width-200'}
                        >
                            {t('order.search')}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    renderDailyMessage = () => {
        const { daily = [], currentProjectInfo = {} } = this.state
        const { t } = this.props
        let payment = daily.find(x => x.paymentMethodCode === 'alipay') || {}
        let transfer = daily.find(x => x.paymentMethodCode === 'bank_transfer') || {}
        return t('peer_payment.daily_message', {
            date: moment().format('DD/MM/YYYY'),
            paymentNum: quantityFormat(payment.totalPeerPayment || 0),
            paymentAmount: moneyFormat(payment.totalAmount || 0, 'CNY'),
            transferNum: quantityFormat(transfer.totalPeerPayment || 0),
            transferAmount: moneyFormat(transfer.totalAmount || 0, 'CNY'),
            tenantName: currentProjectInfo.name,
        })
    }

    processChargeRequest = code => {
        return new Promise((resolve, reject) => {
            UserService.chargeRequest(code, (error, response) => {
                if (error) {
                    reject(error)
                } else {
                    resolve(response)
                }
            })
        })
    }

    handleChargeSelectedRequest = async () => {
        this.handleModalSelectedPeerPaymentVisibleChange(false)
        this.handleModalChargeRequestResultVisibleChange(true)
        this.setState({ chargeRequestLoading: true })

        const { selectedRows, chargeRequestResult } = this.state
        const { t } = this.props
        for (let item of selectedRows) {
            if (!lodash.has(chargeRequestResult, item.code)) {
                chargeRequestResult[item.code] = { error: null, success: null, loading: false }
            }

            chargeRequestResult[item.code].loading = true
            await this.getUserBalancePromise()
            await this.processChargeRequest(item.code)
                .then(() => {
                    chargeRequestResult[item.code].success = t('message.success')
                    this.setState({
                        chargeRequestResult: { ...chargeRequestResult },
                    })
                })
                .catch(error => {
                    chargeRequestResult[item.code].error = t(error.message)
                    if (error.title === 'invalid_amount') {
                        chargeRequestResult[item.code].error = 'Đã có thay đổi về dữ liệu, vui lòng tải lại trang.'
                    } else if (error.title === 'insufficient_balance') {
                        const { balance, creditLimit } = this.state.userBalance
                        const totalMoney = item.totalFee + item.exchangedAmount
                        const enoughMoney = totalMoney - (balance + creditLimit)
                        const compiled = lodash.template(t('cartCheckout.notEnoughMoney'))
                        chargeRequestResult[item.code].error = compiled({
                            money: moneyFormat(enoughMoney),
                        })
                    }
                    this.setState({
                        chargeRequestResult: { ...chargeRequestResult },
                    })
                })
                .finally(() => {
                    chargeRequestResult[item.code].loading = false
                    this.setState({
                        chargeRequestResult: { ...chargeRequestResult },
                    })
                })
        }

        this.setState({ chargeRequestLoading: false }, () => {
            const params = queryString.parse(this.props.location.search)
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: queryString.stringify({
                    ...params,
                    t: Date.now(),
                }),
            })
        })
    }

    handleModalSelectedPeerPaymentVisibleChange = visible => {
        this.setState({ isShowModalSelectedPeerPayment: visible })
        if (visible === true) {
            const { selectedRows } = this.state
            const data = []
            selectedRows.map(item => {
                data.push({
                    amount: item.amount,
                    paymentMethodCode: item.paymentMethodCode,
                    refId: `${item.amount}|${item.paymentMethodCode}`,
                })
            })
            CategoriesService.getExchangeRatesBatch(data, (error, response) => {
                this.setState({
                    exchangeRatesByAmount: [...response],
                })
            })
        }
    }

    handleModalChargeRequestResultVisibleChange = visible => {
        this.setState({ isShowModalChargeRequestResult: visible })
    }

    handleTableGetCheckboxProps = row => {
        return {
            disabled: row.status !== 'WAIT_FOR_PAYMENT' && row.status !== 'REQUEST_FOR_PAY',
        }
    }

    handleChangeSelectRowKeys = selectedRowKeys => {
        const { listPayments } = this.state
        let selectedRows = []
        for (let code of selectedRowKeys) {
            let row = listPayments.find(item => item.code === code)
            selectedRows.push(row)
        }

        this.setState({
            selectedRowKeys,
            selectedRows,
        })
    }

    handleCheckPeerPaymentPay = () => {
        const { t } = this.props
        const { selectedRows } = this.state
        if (selectedRows.length > 10) {
            notification.error({
                message: t('peer_payment.maxPeerPaymentCharge10Item'),
            })
        } else {
            this.handleModalSelectedPeerPaymentVisibleChange(true)
        }
    }

    handleConfirmedChargeSuccess = () => {
        this.handleModalChargeRequestResultVisibleChange(false)
        this.setState({
            selectedRows: [],
            selectedRowKeys: [],
            chargeRequestResult: {},
            exchangeRatesByAmount: [],
        })
    }

    openConfirmPayment = item => {
        const { isOpenConfirmPayment } = this.state
        if (!isOpenConfirmPayment) {
            let query = `?amount=${item.amount}&paymentMethodCode=${item.paymentMethodCode}`
            if (item.peerPaymentType === 'taobao_global') {
                query += `&peerPaymentType=taobao_global`
            }
            this.setState(
                {
                    isLoadingExchange: true,
                },
                () => this.getExchangeRate(query)
            )
        }
        this.setState({
            peerPaymentData: item,
            isOpenConfirmPayment: !isOpenConfirmPayment,
        })
    }

    renderIconCredit = data => {
        const { t } = this.props
        const { peerPaymentType } = this.state
        const contractWithShopkeeper = lodash.get(data, 'contractWithShopkeeper')
        if (peerPaymentType !== 'payment') return
        let hasCredit = contractWithShopkeeper === 'CONTRACTED' || contractWithShopkeeper === 'NOSHOW'

        if (contractWithShopkeeper === 'NONE') return

        return (
            <>
                {hasCredit && (
                    <Tooltip
                        placement="top"
                        title={
                            <span>
                                Tín dụng: {contractWithShopkeeper !== 'NOSHOW' ? `${t('peerPayment.loanApproved')}` :`${t('peerPayment.loanCanceled')}`}
                            </span>
                        }
                    >
                        <i
                            className={`fa-regular fa-credit-card text-base cursor-pointer ${contractWithShopkeeper === 'NOSHOW' ? 'text-red-500' : 'text-stone-500'
                                }`}
                        />
                    </Tooltip>
                )}
            </>
        )
    }

    renderIconQrCode = () => {
        return (
            <Tooltip
                placement="top"
                title={<span>YCTT này tạo với QR Code</span>}
            >
                <i className="fa-light fa-qrcode"></i>
            </Tooltip>
        )
    }

    getAlias = (res) =>{
        const peerPaymentsUniqueByAcc = _.uniqBy(res,'billRef[0].billTo')
        
        let emailUnique = []
        
        for (let i = 0; i < peerPaymentsUniqueByAcc.length; i++) {
            emailUnique.push(peerPaymentsUniqueByAcc[i]?.billRef[0]?.billTo)
        }
        
        TaobaoService.getAccountAlias(emailUnique.join(','),(error, response)=>{
            if (!error) {
                this.setState({listAlias:response})
            }
        })

    }

    render() {
        const {
            listPayments = [],
            isLoading,
            metadata,
            listPaymentStatus = [],
            hasMore,
            page,
            peerPaymentType,
            listPaymentMethods = [],
            exchangeRateByAmount = {},
            isLoadingExchange,
            listFees = [],
            daily,
            onExport,
            peerPaymentConfig,
            selectedRows,
            selectedRowKeys,
            isShowModalSelectedPeerPayment,
            isShowModalChargeRequestResult,
            chargeRequestResult,
            chargeRequestLoading,
            exchangeRatesByAmount,
            tenantConfigPayment,
            userBalance,
            peerPaymentData,
            isOpenConfirmPayment,
            isEnabledBiffin,
            listAlias = []
        } = this.state
        const { t } = this.props
        const { suspensionSchedule } = tenantConfigPayment
        const isInSuspensionSchedule =
            suspensionSchedule && isInSuspensionTime(suspensionSchedule.startTime, suspensionSchedule.endTime)
        let columns = [
            {
                title: t('peer_payment.code'),
                dataIndex: 'code',
                key: 'code',
                className: 'whitespace',
                render: (text, row) => {
                    return (
                        <span>
                            {text ? (
                                <Typography.Paragraph
                                    className="_table-row-code"
                                    style={{ marginBottom: 0 }}
                                    copyable={{ text: row.code }}
                                >
                                    <Link to={`/peer-payments/${row.code}`}>{text}</Link>
                                </Typography.Paragraph>
                            ) : (
                                '---'
                            )}
                            {this.renderIconCredit(row)}
                        </span>
                    )
                },
            },
            {
                title: t('peer_payment.trxTime'),
                dataIndex: 'trxTime',
                key: 'trxTime',
                render: text => (
                    <span className="_table-row-createdAt">{text ? moment(text).format('HH:mm DD/MM/YYYY') : '---'}</span>
                ),
            },
            {
                title: t('peer_payment.amount'),
                dataIndex: 'amount',
                key: 'amount',
                className: 'whitespace-pre-wrap',
                render: (text, row) => <div className="_table-row-amount">{moneyFormat(text, row.currency)}</div>,
            },
            {
                title: t('peer_payment.exchangedAmount'),
                dataIndex: 'exchangedAmount',
                key: 'exchangedAmount',
                className: 'whitespace-pre-wrap',
                render: (text, row) => <div className="_table-row-exchangedAmount">{moneyFormat(text)}</div>,
            },
            {
                title: t('fee_tab.service_fee'),
                dataIndex: 'totalFee',
                key: 'totalFee',
                className: 'whitespace-pre-wrap',
                render: (text, row) => <div className="_table-row-totalFee">{moneyFormat(text)}</div>,
            },
            {
                title: t('orderDetail.total_money'),
                dataIndex: 'total',
                key: 'total',
                className: 'whitespace-pre-wrap',
                render: (text, row) => <div className="_table-row-total">{moneyFormat(row.totalFee + row.exchangedAmount)}</div>,
            },
            {
                title: peerPaymentType === 'transfer' ? t('peer_payment.beneficiaryAccount') : t('peer_payment.paymentAccount'),
                dataIndex: peerPaymentType === 'transfer' ? 'beneficiaryAccount' : 'paymentAccount',
                key: peerPaymentType === 'transfer' ? 'beneficiaryAccount' : 'paymentAccount',
                render: (text, row) => {
                    if (peerPaymentType === 'transfer') {
                        return (
                            <div>
                                <Typography.Paragraph
                                    className="_table-row-beneficiaryAccount"
                                    style={{ marginBottom: 0 }}
                                    copyable={{ text: row.beneficiaryAccount }}
                                >
                                    {row.beneficiaryAccount}
                                </Typography.Paragraph>
                                <Typography.Paragraph
                                    className="_table-row-beneficiaryBank"
                                    style={{ marginBottom: 0 }}
                                    copyable={{ text: row.beneficiaryBank }}
                                >
                                    {row.beneficiaryBank}
                                </Typography.Paragraph>
                                <Typography.Paragraph
                                    className="_table-row-beneficiaryName"
                                    style={{ marginBottom: 0 }}
                                    copyable={{ text: row.beneficiaryName }}
                                >
                                    {row.beneficiaryName}
                                </Typography.Paragraph>
                                {!!row.beneficiaryBankBranch && (
                                    <Typography.Paragraph
                                        className="_table-row-beneficiaryBankBranch"
                                        style={{ marginBottom: 0 }}
                                        copyable={{ text: row.beneficiaryBankBranch }}
                                    >
                                        {row.beneficiaryBankBranch}
                                    </Typography.Paragraph>
                                )}
                            </div>
                        )
                    }

                    return row?.qrcode ? (
                        this.renderIconQrCode()
                    ) : text ? (
                        <Typography.Paragraph
                            className="_table-row-paymentAccount"
                            style={{ marginBottom: 0 }}
                            copyable={{ text: text }}
                        >
                            {text}
                        </Typography.Paragraph>
                    ) : (
                        '---'
                    )
                },
            },
            peerPaymentType === 'transfer'
                ? {
                    title: t('peer_payment.memo'),
                    dataIndex: 'memo',
                    key: 'memo',
                    render: text => <div className="_table-row-memo">{text || '---'}</div>,
                }
                : {
                    title: t('shipment.originalReceiptCode'),
                    dataIndex: 'billRef',
                    width: 400,
                    key: 'billRef',
                    render: (text, row) => {
                    
                        if (!Array.isArray(row.billRef) || row.billRef.length === 0) {
                            return '---'
                        }

                          const originalReceipts = lodash.get(row, 'billDocument.originalReceipts', [])
                          const result = originalReceipts.join(', ')
                          const email = lodash.get(row, 'billRef[0].billTo', '')
                          const nameAlias = listAlias.find((alias)=> alias.account === email)?.name
                          return (
                              <div className="">
                                  {row.billRef.map((item, index) => (
                                      <div
                                          key={index}
                                          className={index > 0 ? 'pdt10 border-top-1x mgt10 bd-color-gray2' : ''}
                                      >
                                          
                                        { peerPaymentType === 'payment' && 
                                        <>
                                            <div
                                                className="bold txt-color-black"
                                                style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
                                            >
                                                {item.code}
                                            </div>
                                            <div>{moneyFormat(item.amount, row.currency)}</div>
                                            <div>{item.billTo || '---'}</div>
                                        </>
                                        }

                                        { peerPaymentType === 'taobao_global' && 
                                            <>
                                                {/* <div>{moneyFormat(item.amount, row.currency)}</div> */}
                                                <div>{nameAlias || '---'}</div>
                                            </>
                                        }
                                      </div>
                                  ))}
                                  <div className="flex flex-wrap">
                                      {originalReceipts && <div>{result}</div>}
                                  </div>
                              </div>
                          )
                      },
                  },
            {
                title: t('peer_payment.shipmentCode'),
                dataIndex: 'shipmentCode',
                key: 'shipmentCode',
                render: text => {
                    if (text) {
                        return (
                            <Link
                                to={`/shipments/${text}`}
                                target={'_blank'}
                            >
                                {text}
                            </Link>
                        )
                    }

                    return <div className="_table-row-note">{text || '---'}</div>
                },
            },
            {
                title: t('peer_payment.note'),
                dataIndex: 'note',
                key: 'note',
                render: text => <div className="_table-row-note">{text || '---'}</div>,
            },
            {
                title: '',
                dataIndex: 'action',
                key: 'action',
                render: (text, row) => {
                    const contractWithShopkeeper = row.contractWithShopkeeper
                    const isShowPaymentWithBifin = lodash.isNull(contractWithShopkeeper) || contractWithShopkeeper === 'NONE'
                    if (row.status === 'WAIT_FOR_PAYMENT' || row.status === 'REQUEST_FOR_PAY') {
                        // return <div
                        //     onClick={() => this.chargeRequest(row.code)}
                        //     className="_btn-payment btn-add-peer-payment dpl-il-block whitespace"
                        // >
                        //     <Icon component={iconPayment} style={{fontSize: 14}}
                        //           className=""/> {t('orderDetail.payment')}
                        // </div>

                        let newExchangedAmount = row.amount * exchangeRateByAmount.rate
                        let arr = [
                            {
                                id: 1,
                                oldLabel: `${t('peer_payment.old_exchangeRate')}:`,
                                newLabel: `${t('peer_payment.new_exchangeRate')}:`,
                                old: moneyFormat(row.exchangeRate),
                                new: moneyFormat(exchangeRateByAmount.rate),
                            },
                            {
                                id: 2,
                                oldLabel: `${t('peer_payment.old_amount')}:`,
                                newLabel: `${t('peer_payment.new_amount')}:`,
                                old: moneyFormat(row.exchangedAmount),
                                new: moneyFormat(newExchangedAmount),
                            },
                        ]

                        const col = [
                            {
                                title: '',
                                dataIndex: 'oldLabel',
                                key: 'oldLabel',
                            },
                            {
                                title: '',
                                dataIndex: 'old',
                                key: 'old',
                                render: text => <div className="_old-value txt-color-red">{text}</div>,
                            },
                            {
                                title: '',
                                dataIndex: 'abc',
                                key: 'abc',
                                render: () => {
                                    return '-->'
                                },
                            },
                            {
                                title: '',
                                dataIndex: 'newLabel',
                                key: 'newLabel',
                            },
                            {
                                title: '',
                                dataIndex: 'new',
                                key: 'new',
                                render: text => <div className="_new-value txt-color-green">{text}</div>,
                            },
                        ]

                        return (
                            <div className="flex flex-col">
                                <Popconfirm
                                    placement="topRight"
                                    disabled={isInSuspensionSchedule}
                                    overlayClassName={`_pay-popconfirm-overlay`}
                                    className={`_pay-popconfirm`}
                                    title={
                                        <div>
                                            <div>{t('message.delete_confirm')}</div>

                                            {isLoadingExchange && <Loading />}

                                            {!isLoadingExchange &&
                                                exchangeRateByAmount.rate !== row.exchangeRate &&
                                                !row.fixedExchangeRate && (
                                                    <div className="dpl-flex pdt10">
                                                        <div className="width-300">
                                                            {t('peer_payment.amount')}:{' '}
                                                            <span className="_amount-popup bold txt-color-green">
                                                                {moneyFormat(row.amount, row.currency)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}

                                            {!isLoadingExchange &&
                                                exchangeRateByAmount.rate !== row.exchangeRate &&
                                                !row.fixedExchangeRate && (
                                                    <Table
                                                        className="_table-data custom-empty table-hidden-header table-expand-row border-bottom-radius table-mini"
                                                        rowKey={(x, y) => y}
                                                        columns={col}
                                                        dataSource={arr}
                                                        rowClassName={() => `_table-row`}
                                                        pagination={{
                                                            hideOnSinglePage: true,
                                                            pageSize: arr.length,
                                                        }}
                                                    />
                                                )}
                                        </div>
                                    }
                                    onConfirm={() => {
                                        this.chargeRequest(row.code)
                                    }}
                                    okText={t('button.yes')}
                                    okButtonProps={{ disabled: this.state.isLoadingExchange }}
                                    cancelText={t('button.no')}
                                    onOpenChange={open => {
                                        if (open) {
                                            let query =
                                                `?amount=${row.amount}&paymentMethodCode=${row.paymentMethodCode}` +
                                                (row.peerPaymentType === 'taobao_global' ? `&peerPaymentType=taobao_global` : '')
                                            this.setState(
                                                {
                                                    isLoadingExchange: true,
                                                },
                                                () => this.getExchangeRate(query)
                                            )
                                        }
                                    }}
                                >
                                    <div
                                        // onClick={() => this.chargeRequest(row.code)}
                                        className={`_btn-payment text-center ${isInSuspensionSchedule ? 'btn-disabled' : ''
                                            } btn-add-peer-payment dpl-il-block whitespace`}
                                    >
                                        <AntdIconSVG
                                            component={isInSuspensionSchedule ? iconDisabledPayment : iconPayment}
                                            style={{ fontSize: 14 }}
                                        />{' '}
                                        {t('orderDetail.payment')}
                                    </div>
                                </Popconfirm>
                                {isEnabledBiffin && row.hasCollateral && isShowPaymentWithBifin && (
                                    <Button
                                        type="primary"
                                        disabled={isInSuspensionSchedule}
                                        onClick={() => this.openConfirmPayment(row)}
                                        className={`_btn-payment ${isInSuspensionSchedule ? 'btn-disabled' : ''
                                            } text-white btn-add-peer-payment mt-2 space-x-1 dpl-il-block whitespace`}
                                    >
                                        <i className="fa-duotone text-white fa-credit-card-front" />{' '}
                                        {t('orderDetail.payment_with_bifin')}
                                    </Button>
                                )}
                            </div>
                        )
                    }

                    return <div />
                },
            },
            {
                title: t('tickets.status'),
                dataIndex: 'status',
                key: 'status',
                render: (text, row) => {
                    const { peerPaymentItemMilestoneVisible, peerPaymentItemMilestoneLoading, peerPaymentItemMilestoneStatuses } =
                        this.state
                    let itemStatus = listPaymentStatus.find(x => x.code === text) || {}

                    return (
                        <>
                            <MilestonePopover
                                t={this.props.t}
                                milestones={listPaymentStatus}
                                visible={peerPaymentItemMilestoneVisible === row.code}
                                loading={peerPaymentItemMilestoneLoading}
                                statuses={peerPaymentItemMilestoneStatuses}
                                onVisibleChange={visible => {
                                    if (visible) {
                                        this.setState({ peerPaymentItemMilestoneVisible: row.code })
                                        this.setState({ peerPaymentItemMilestoneLoading: true })
                                        UserService.getHistoryPeerPayment(row.code, (err, res) => {
                                            if (!err) {
                                                this.setState({
                                                    peerPaymentItemMilestoneStatuses: res,
                                                })
                                            }
                                            this.setState({ peerPaymentItemMilestoneLoading: false })
                                        })
                                    } else {
                                        this.setState({ peerPaymentItemMilestoneVisible: null })
                                    }
                                }}
                            >
                                <Tag
                                    style={{ backgroundColor: `#${intToRGB(hashCode(text))}` }}
                                    className={'_table-row-status listorders__items_right--status'}
                                >
                                    {itemStatus.name || text || '---'}
                                </Tag>
                            </MilestonePopover>
                        </>
                    )
                },
            },
        ]

        const isShowBtnPayment =
            (lodash.get(tenantConfigPayment, 'config.paymentAlipay') === true ||
                lodash.get(tenantConfigPayment, 'config.payment1688Business') === true) &&
            !isInSuspensionSchedule
        const isShowBtnTransfer =
            (lodash.get(tenantConfigPayment, 'config.transferAlipay') === true ||
                lodash.get(tenantConfigPayment, 'config.transferBank') === true) &&
            !isInSuspensionSchedule
        const isHideTabPayment =
            lodash.get(tenantConfigPayment, 'config.paymentAlipay') === false &&
            lodash.get(tenantConfigPayment, 'config.payment1688Business') === false &&
            !listPayments.length &&
            !isLoading

        const m17Config = this.state.currentProjectInfo?.tenantConfig?.m24Config?.enabled
        const isHideTabPaymentTaobao = !m17Config
        // lodash.get(tenantConfigPayment, 'config.paymentAlipay') === false &&
        // lodash.get(tenantConfigPayment, 'config.payment1688Business') === false &&
        // !listPayments.length &&
        // !isLoading

        const isHideTabTransfer =
            lodash.get(tenantConfigPayment, 'config.transferAlipay') === false &&
            lodash.get(tenantConfigPayment, 'config.transferBank') === false

        const isShowColumnShipmentCode = lodash.get(tenantConfigPayment, 'config.shipmentRequired') === true
        if (!isShowColumnShipmentCode) {
            columns = columns.filter(c => c.key !== 'shipmentCode')
        }

        return (
            <MainLayout
                ref={ref => (this.mainLayoutElement = ref)}
                {...this.props}
                showProgressBar={this.state.showProgressBar}
                headerTitle={t('peer_payment.title_page')}
                title={t('peer_payment.title_page')}
            >
                <div className={'container-common pdbt30 mgr20'}>
                    {daily && Array.isArray(daily) && (
                        <Alert
                            className="mgbt20"
                            message={<span className="bold txt-size-h6">{t('order.notification')}</span>}
                            description={
                                <div
                                    className="txt-size-h7 white-space-pre-wrap break-word"
                                    style={{ display: 'inline' }}
                                    dangerouslySetInnerHTML={{ __html: this.renderDailyMessage() }}
                                />
                            }
                            type="success"
                            showIcon
                            closable
                        />
                    )}
                    {isInSuspensionSchedule && suspensionSchedule.description !== null && (
                        <Alert
                            className="custom-alert-suspension-schedule white-space-pre-wrap break-word mgbt20"
                            message={<div className="bold txt-size-h6">{t('cart.suspension_notify')}</div>}
                            description={
                                <div
                                    className="txt-size-h7"
                                    dangerouslySetInnerHTML={{
                                        __html: suspensionSchedule ? suspensionSchedule.description : '',
                                    }}
                                />
                            }
                            type="warning"
                            showIcon
                            closable
                        />
                    )}

                    {this.renderSearchBox()}
                    <div className="border-radius8 v2-layout">
                        <div className="dpl-flex justify-content-between align-items-center bg-color-white pd10 border-bottom-1x bd-color-gray border-top-radius">
                            <div />
                            <Row className='flex items-center gap-3'>

                                {peerPaymentConfig && peerPaymentConfig.manualExchangeRateBoard ? (
                                    <ModalMarkupRateGroupsHardCode
                                        peerPaymentConfig={peerPaymentConfig}
                                        listPaymentMethods={listPaymentMethods}
                                    />
                                ) : (
                                    <ModalMarkupRateGroups peerPaymentType={peerPaymentType} listPaymentMethods={listPaymentMethods} />
                                )}

                                <Col>
                                    <Button
                                        disabled={isInSuspensionSchedule || !selectedRowKeys.length}
                                        onClick={() => this.handleCheckPeerPaymentPay()}
                                        type={'default'}
                                        className={'_btn-payment btn btn--peer-payment-pay'}
                                        icon={<i className="fa-regular fa-wallet mr-2" />}
                                    >
                                        &nbsp;{t('peer_payment.btnPayment')}
                                    </Button>
                                </Col>

                                {isShowBtnPayment === true && peerPaymentType === 'payment' && (
                                    <ModalCreatePeerPaymentPay
                                        // listServices={listServices}
                                        listFees={listFees}
                                        loadFromQueryString={this.loadFromQueryString}
                                        tenantConfigPayment={tenantConfigPayment}
                                    />
                                )}

                                {isShowBtnTransfer === true && peerPaymentType === 'transfer' && (
                                    <ModalCreatePeerPayment
                                        // listServices={listServices}
                                        listFees={listFees}
                                        loadFromQueryString={this.loadFromQueryString}
                                        tenantConfigPayment={tenantConfigPayment}
                                    />
                                )}

                                <div>
                                    <ExportExcel
                                        {...this.props}
                                        validate={() => {
                                            if (!this.checkExportBtn()) {
                                                notification['error']({
                                                    message: t('transaction.export_csv_btn_error'),
                                                })
                                                return true
                                            }
                                            return false
                                        }}
                                        onExport={onExport}
                                        exportExcel={this.exportExcel}
                                    />
                                </div>
                            </Row>
                        </div>

                        <div className="dpl-flex justify-content-between align-items-center">
                            {!isLoading && !isHideTabPayment && (
                                <div
                                    onClick={() => {
                                        if (peerPaymentType === 'payment') return
                                        this.setState({ peerPaymentType: 'payment' }, () => {
                                            this.onClearSearch()
                                        })
                                    }}
                                    className={`width-100-percent txt-center pd15 txt-size-h5 btn-peer-payments ${peerPaymentType === 'payment' ? 'active' : ''
                                        }`}
                                >
                                    <AntdIcon.PayCircleOutlined
                                        className="txt-size-h2 mgr5"
                                        type="pay-circle"
                                    />{' '}
                                    {t('peer_payment.request_payment')}
                                </div>
                            )}

                            {!isLoading && !isHideTabTransfer && (
                                <div
                                    onClick={() => {
                                        if (peerPaymentType === 'transfer') return
                                        this.setState({ peerPaymentType: 'transfer' }, () => {
                                            this.onClearSearch()
                                        })
                                    }}
                                    className={`width-100-percent txt-center pd15 txt-size-h5 btn-peer-payments ${peerPaymentType === 'transfer' ? 'active' : ''
                                        }`}
                                >
                                    <AntdIcon.TransactionOutlined
                                        className="txt-size-h2 mgr5"
                                        type="transaction"
                                    />{' '}
                                    {t('peer_payment.request_transfer')}
                                </div>
                            )}

                            {!isLoading && !isHideTabPaymentTaobao && (
                                <div
                                    onClick={() => {
                                        if (peerPaymentType === 'taobao_global') return
                                        this.setState({ peerPaymentType: 'taobao_global' }, () => {
                                            this.onClearSearch()
                                        })
                                    }}
                                    className={`width-100-percent txt-center pd15 txt-size-h5 btn-peer-payments ${peerPaymentType === 'taobao_global' ? 'active' : ''
                                        }`}
                                >
                                    <AntdIcon.PayCircleOutlined
                                        className="txt-size-h2 mgr5"
                                        type="pay-circle"
                                    />{' '}
                                    {t('peer_payment.request_payment_TBG')}
                                </div>
                            )}
                        </div>

                        <div className="bg-color-white position-re border-bottom-radius">
                            {Array.isArray(listPayments) && listPayments.length > 0 ? (
                                <div className="table-responsive">
                                    <Table
                                        loading={isLoading}
                                        // ref={ref => this.table = ref}
                                        className={'_table-data custom-empty table-expand-row border-bottom-radius'}
                                        rowKey={'code'}
                                        columns={columns}
                                        dataSource={listPayments}
                                        rowClassName={() => `_table-row`}
                                        pagination={{
                                            className: '_pagination text-center width-100-percent mgt20',
                                            hideOnSinglePage: true,
                                            current: metadata ? parseFloat(metadata.page) + 1 : 1,
                                            total: metadata ? parseFloat(metadata.total) : 1,
                                            pageSize: metadata ? parseFloat(metadata.size) : pageSize,
                                            onChange: page => this.onPageChanged(page),
                                        }}
                                        rowSelection={{
                                            selectedRowKeys,
                                            onChange: this.handleChangeSelectRowKeys,
                                            getCheckboxProps: this.handleTableGetCheckboxProps,
                                        }}
                                    />
                                </div>
                            ) : isLoading ? (
                                <Loading />
                            ) : (
                                <div className="pdt50 pdbt60 bg-color-white border-bottom-radius">
                                    <Empty
                                        image={require('../../resources/images/empty-image.png')}
                                        description={t('message.empty')}
                                    />
                                </div>
                            )}
                            {page > 1 && (
                                <div
                                    onClick={() => this.onPageChanged(page ? parseFloat(page) - 1 : 1)}
                                    className="peer-payment-nav left"
                                >
                                    <AntdIcon.DoubleLeftOutlined type="double-left" />
                                </div>
                            )}
                            {hasMore && (
                                <div
                                    onClick={() => this.onPageChanged(page ? parseFloat(page) + 1 : 2)}
                                    className="peer-payment-nav right"
                                >
                                    <AntdIcon.DoubleRightOutlined type="double-right" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {isOpenConfirmPayment && (
                    <ModalConfirmPayment
                        t={t}
                        chargeRequestWithCredit={this.chargeRequestWithCredit}
                        userBalance={userBalance}
                        exchangeRateByAmount={exchangeRateByAmount}
                        isLoadingExchange={isLoadingExchange}
                        peerPaymentData={peerPaymentData}
                        onVisibleChange={() => {
                            this.openConfirmPayment()
                        }}
                    />
                )}
                {isShowModalSelectedPeerPayment && (
                    <ModalSelectedPeerPayment
                        t={t}
                        items={selectedRows}
                        tenantConfigPayment={tenantConfigPayment}
                        exchangeRatesByAmount={exchangeRatesByAmount}
                        userBalance={userBalance}
                        onVisibleChange={this.handleModalSelectedPeerPaymentVisibleChange}
                        onSubmit={this.handleChargeSelectedRequest}
                    />
                )}

                {isShowModalChargeRequestResult && (
                    <ModalChargeRequestResult
                        loading={chargeRequestLoading}
                        t={t}
                        items={selectedRows}
                        exchangeRatesByAmount={exchangeRatesByAmount}
                        result={chargeRequestResult}
                        onVisibleChange={this.handleModalChargeRequestResultVisibleChange}
                        onConfirmedChargeSuccess={this.handleConfirmedChargeSuccess}
                    />
                )}
            </MainLayout>
        )
    }
}
export default withNamespaces()(PeerPayments)
