import React from 'react'
import _ from 'lodash'
import { Modal, Row, Col, Select, Input, Checkbox, Form, notification, Spin, Empty } from 'antd'
import * as AntdIcon from '@ant-design/icons'

import { LocalStore } from '../../utils/LocalStore'
import AddressService from '../../services/address.service'
import { withNamespaces } from 'react-i18next'

const { TextArea } = Input
let currentLoggedUser = null
let timeout = null

class CreateAddressModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fullname: '',
            phone: '',
            detail: '',
            location: '',
            note: '',
            defaultAddress: false,
            country: '',
            province: '',
            city: '',
            district: '',
            ward: '',
            addressName: '',
            zipCode:'',
            confirmLoading: false,
            disabledCountry: false
        }
    }

    componentDidMount() {
        if (typeof LocalStore.getInstance().read('currentLoggedUser') === 'object') {
            currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {}
        }

        this.splitString()
    }

    calcNumberText = number => {
        let result = ''
        for (let i = 0; i < number; i++) {
            result += '.parent'
        }
        return result
    }

    recursivePosition = (data, position) => {
        if (data?.parent) {
            position = position + 1
            return this.recursivePosition(data?.parent, position)
        } else {
            return position
        }
    }

    calcAddressPosition = location => {
        let position = 0
        const result = this.recursivePosition(location, position)
        return result
    }

    splitString = () => {
        const { dataItem } = this.props
        if (dataItem) {
            let location = ['country', 'province', 'city', 'district', 'ward']
            let arrayLocation = []

            const position = this.calcAddressPosition(dataItem?.location)
            for (let i = 0; i <= position; i++) {
                arrayLocation.unshift(_.get(dataItem, `location${this.calcNumberText(i)}.code`))
            }
            if (arrayLocation?.length < 5) {
                location = location.filter(i => i !== 'city')
            }
            const addressSelection = {}
            arrayLocation?.map((el, index) => {
                addressSelection[`${location[index]}`] = el
                return null
            })
            this.setState(
                {
                    originData: _.cloneDeep(dataItem),
                    fullname: dataItem.fullname,
                    phone: dataItem.phone,
                    detail: dataItem.detail,
                    location: dataItem.location,
                    note: dataItem.note,
                    defaultAddress: dataItem.defaultAddress,
                    province: addressSelection?.province,
                    district: addressSelection?.district,
                    ward: addressSelection?.ward,
                    country: addressSelection?.country,
                    city: addressSelection?.city,
                    addressName: dataItem.addressName,
                    loadDataEdit: true,
                    isDefault: dataItem.defaultAddress,
                    zipCode: dataItem.zipCode
                },
                () => {
                    location?.map((i, index) => {
                        this.loadDataLocations(i?.toUpperCase(), '', index > 0 ? addressSelection[location[index - 1]] : null)
                        return null
                    })
                }
            )
        } else {
            this.loadDataLocations('COUNTRY')
        }
    }

    /**
     * lấy thông tin tỉnh/thành phố, quận/huyện, phường/xã
     */

    loadDataLocations = (type, search, parent) => {
        const queryString = `?${type === 'COUNTRY' ? 'type=COUNTRY&' : ''}${search ? `query=${search}` : ''}${
            parent ? `&parent=${parent}` : ''
        }&size=1000`
        clearTimeout(timeout)
        this.setState({ [`${type.toLowerCase()}Loading`]: true, fetching: true }, () => {
            setTimeout(() => {
                AddressService.getLocations(queryString, (err, res) => {
                    if (!err) {
                        if (Array.isArray(res) && res.length > 0) {
                            if (type === 'COUNTRY' && res.length === 1 ) {
                                this.setState({ [`${type.toLowerCase()}List`]: res, disabledCountry: true })
                                this.handleSelectChange(res[0].code,'country')
                            }
                            else{
                                this.setState({ [`${type.toLowerCase()}List`]: res })
                            }
                        } else {
                            if (type === 'COUNTRY') {
                                this.setState({ countryList: [], provinceList: [], districtList: [], wardList: [] })
                            } else if (type === 'PROVINCE') {
                                this.setState({ provinceList: [], city: [], districtList: [], wardList: [] })
                            } else if (type === 'CITY') {
                                this.setState({ city: [], districtList: [], wardList: [] })
                            } else if (type === 'DISTRICT') {
                                this.setState({ districtList: [], wardList: [] })
                            } else {
                                this.setState({ wardList: [] })
                            }
                        }
                    }
                    this.setState({ [`${type.toLowerCase()}Loading`]: false, loadDataEdit: false, fetching: false })
                })
            }, 500)
        })
    }

    onValidate = () => {
        let { country, province, district, city, ward, fullname, phone, zipCode, detail, error = {} } = this.state

        const { t } = this.props
        let check = 0

        if (!fullname || !fullname.trim()) {
            error.fullname = t('message.warning_fullname')
            check = check + 1
        } else {
            error.fullname = ''
        }

        if (!detail || !detail.trim()) {
            error.detail = t('message.warning_address')
            check = check + 1
        } else {
            error.detail = ''
        }
        let re = /^[0-9+.() -]+$/ //regex number and some special characters
        if (!phone) {
            error.phone = t('message.required_phone')
            check = check + 1
        } else if (!re.test(phone)) {
            error.phone = t('message.format_phone')
            check = check + 1
        } else {
            error.phone = ''
        }

        if ( zipCode && !re.test(zipCode)) {
            error.zipCode = t('message.formatZipcode')
            check = check + 1
        } else {
            error.zipCode = ''
        }

        if (!province || !province.trim()) {
            error.province = t('message.required_province')
            check = check + 1
        } else {
            error.province = ''
        }

        if (!district || !district.trim()) {
            error.district = t('message.required_district')
            check = check + 1
        } else {
            error.district = ''
        }

        if (!ward || !ward.trim()) {
            error.ward = t('message.required_ward')
            check = check + 1
        } else {
            error.ward = ''
        }

        if (!country || !country.trim()) {
            error.country = t('message.required_country')
            check = check + 1
        } else {
            error.country = ''
        }

        if (country === 'CN' && (!city || !city.trim())) {
            error.city = t('message.required_city')
            check = check + 1
        } else {
            error.city = ''
        }

        this.setState({ error })
        if (check > 0) {
            return false
        }
        return true
    }

    /**
     * Check sự thay đổi khi edit dữ liệu
     * @return boolean
     */
    checkDifferentData = currentData => {
        const { originData } = this.state
        if (
            currentData.fullname !== originData.fullname ||
            currentData.phone !== originData.phone ||
            currentData.detail !== originData.detail ||
            currentData.location !== originData.location.code ||
            currentData.note !== originData.note ||
            currentData.defaultAddress !== originData.defaultAddress ||
            currentData.addressName !== originData.addressName ||
            currentData.zipCode !== originData.zipCode
        ) {
            return true
        } else {
            return false
        }
    }

    /**
     * Handle submit create a customer address
     * @param e
     */
    handleSubmit = e => {
        const { isLoadingSubmit, ward, fullname, phone, detail, note, defaultAddress, addressName, zipCode} = this.state
        const { toggleCreateModal, toggleListAddressModal, dataItem, loadDataList, t, isCheckReceivingAddress } = this.props

        if (!this.onValidate()) {
            return
        }
        // REQUIRED WARD
        let payload = {
            fullname: fullname.trim(),
            phone: phone,
            detail: detail,
            location: ward,
            note: note?.trim() || '',
            addressName: addressName?.trim() || '',
            defaultAddress: defaultAddress,
            receivingAddress: isCheckReceivingAddress ? isCheckReceivingAddress : false,
            zipCode: zipCode
        }

        this.setState({ confirmLoading: true, isLoadingSubmit: true }, () => {
            if (isLoadingSubmit !== true) {
                if (!dataItem) {
                    AddressService.createCustomerAddress(currentLoggedUser.username, payload, (err, res) => {
                        if (err === null) {
                            notification['success']({
                                message: t('message.success'),
                            })
                            setTimeout(() => {
                                loadDataList()
                                toggleCreateModal()
                                if (toggleListAddressModal) toggleListAddressModal()
                            }, 500)
                        } else {
                            if(err?.title === 'invalid_receiving_location') {
                                notification['error']({
                                    message: t('message.invalid_receiving_location')
                                })
                            } else if (err?.title ==='invalid_location') {
                                notification['error']({
                                    message: t('message.invalid_location')
                                })
                            } else if (err.statusCode !== 'Network fail') {
                                notification['error']({
                                    message:
                                        err.title === 'location_bad_request'
                                            ? t('message.location_incorrect')
                                            : t(err.message) || t('message.unconnected_error'),
                                })
                            }
                        }
                        this.setState({ isLoadingSubmit: false, confirmLoading: false })
                    })
                } else {
                    if (this.checkDifferentData(payload)) {
                        AddressService.updateCustomerAddress(currentLoggedUser.username, dataItem.id, payload, (err, res) => {
                            if (err === null) {
                                notification['success']({
                                    message: t('message.success'),
                                })
                                setTimeout(() => {
                                    loadDataList()
                                    toggleCreateModal()
                                    if (toggleListAddressModal) toggleListAddressModal()
                                }, 500)
                            } else {
                                if(err?.title === 'invalid_receiving_location') {
                                    notification['error']({
                                        message: t('message.invalid_receiving_location')
                                    })
                                } else if (err?.title ==='invalid_location') {
                                    notification['error']({
                                        message: t('message.invalid_location')
                                    })
                                } else if (err.statusCode !== 'Network fail') {
                                if (err.statusCode !== 'Network fail')
                                    notification['error']({
                                        message:
                                            err.title === 'location_bad_request'
                                                ? t('message.location_incorrect')
                                                : t(err.message) || t('message.unconnected_error'),
                                    })}
                            }
                            this.setState({ isLoadingSubmit: false, confirmLoading: false })
                        })
                    } else {
                        notification['warning']({
                            message: t('message.any_change'),
                        })
                        this.setState({ isLoadingSubmit: false, confirmLoading: false })
                    }
                }
            }
        })
    }

    /**
     * Handle change select
     * @param value
     * @param prop
     */
    handleSelectChange = (value, prop) => {
        const { country, province, city, district, ward } = this.state
        if (prop === 'country' && country !== value) {
            this.setState(
                {
                    provinceList: [],
                    cityList: [],
                    districtList: [],
                    wardList: [],
                    province: '',
                    city: '',
                    district: '',
                    ward: '',
                },
                () => {
                    // call lai api "COUNTRY" cho truong hop tim kiem "COUNTRY" chinh xac tuyet doi
                    // this.loadDataLocations('COUNTRY', '', province);
                    this.loadDataLocations('PROVINCE', null, value)
                }
            )
        }

        if (prop === 'province' && province !== value) {
            this.setState(
                {
                    cityList: [],
                    districtList: [],
                    wardList: [],
                    city: '',
                    district: '',
                    ward: '',
                },
                () => {
                    if (country === 'CN') {
                        this.loadDataLocations('CITY', null, value)
                    } else this.loadDataLocations('DISTRICT', null, value)
                }
            )
        }

        if (prop === 'city' && city !== value) {
            this.setState(
                {
                    districtList: [],
                    wardList: [],
                    district: '',
                    ward: '',
                },
                () => {
                    this.loadDataLocations('DISTRICT', null, value)
                }
            )
        }

        if (prop === 'district' && district !== value) {
            this.setState(
                {
                    wardList: [],
                    ward: '',
                },
                () => {
                    this.loadDataLocations('WARD', null, value)
                }
            )
        }

        if (prop === 'ward' && ward !== value) {
            this.loadDataLocations('WARD', null, district)
        }

        this.setState({ [prop]: value, fetching: false })
    }

    /**
     * Handle change radio
     * @param e
     */
    onChangeRadio = e => {
            this.setState({ defaultAddress: e.target.checked })
    }

    /**
     * Handle cancel create a customer address
     * @param e
     */
    handleCancel = e => {
        e.preventDefault()
        const { toggleCreateModal, toggleListAddressModal, toggleAddressOnList, toggleEditAddress } = this.props

        this.setState({
            fullname: '',
            phone: '',
            detail: '',
            note: '',
            defaultAddress: false,
            receivingAddress: false,
            country: '',
            province: '',
            district: '',
            ward: '',
            addressName: '',
            zipCode:''
        })
        toggleCreateModal()
        if ((toggleAddressOnList === true || toggleEditAddress === true) && toggleListAddressModal) {
            toggleListAddressModal()
        }
    }

    onInputChange = (value, prop, cb) => {
        this.setState(
            {
                [prop]: value,
            },
            () => {
                if (cb) cb()
            }
        )
    }

    render() {
        const { t, modalCreateAddressStatus, toggleEditAddress, dataItem, isCheckReceivingAddress } = this.props
        const {
            confirmLoading,
            countryList,
            provinceList,
            cityList,
            districtList,
            wardList,
            province,
            city,
            district,
            ward,
            country,
            fullname,
            phone,
            detail,
            note,
            defaultAddress,
            addressName,
            isDefault,
            fetching,
            zipCode,
            error = {},
            disabledCountry
        } = this.state
       
        return (
            <Modal
                className="_modalAddress modalform"
                title={
                    !isCheckReceivingAddress ? !dataItem   ? t('customerAddress.new_address').toUpperCase() : t('customerAddress.edit_address').toUpperCase() : 
                    !dataItem   ? t('customerAddress.newReceivingAddress').toUpperCase() : t('customerAddress.editReceivingAddress').toUpperCase()
                }
                visible={modalCreateAddressStatus}
                onOk={this.handleSubmit}
                confirmLoading={confirmLoading}
                onCancel={this.handleCancel}
                okText={(toggleEditAddress === true ? t('button.save') : t('button.add_address')).toUpperCase()}
                okButtonProps={{ className: '_btn-add-address' }}
                cancelText={t('button.cancel').toUpperCase()}
                cancelButtonProps={{ className: '_btn-cancel-address' }}
            >
                <div className={'formeditaddress'}>
                    <Row gutter={12}>
                        <Col md={12}>
                            <h5 className="_fullnameTitle txt-size-h7 txt-color-black robotoregular">
                                {t('customerAddress.fullname')}
                                <span className={'txt-color-red mgl5'}>*</span>
                            </h5>
                            <Form.Item className={'_fullname'}>
                                <Input
                                    className="_fullnameValue"
                                    placeholder={t('customerAddress.fullname')}
                                    value={fullname}
                                    onChange={e => this.onInputChange(e.target.value, 'fullname')}
                                    onKeyDown={e => {
                                        if (e.keyCode === 13) {
                                            this.handleSubmit()
                                        }
                                    }}
                                />
                                {error.fullname && (
                                    <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">
                                        {error.fullname}
                                    </div>
                                )}
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <h5 className="_phoneTitle txt-size-h7 txt-color-black robotoregular">
                                {t('customerAddress.phone')}
                                <span className={'txt-color-red mgl5'}>*</span>
                            </h5>
                            <Form.Item className={'_phone'}>
                                <Input
                                    className="_phoneValue"
                                    placeholder={t('customerAddress.phone')}
                                    onChange={e => this.onInputChange(e.target.value, 'phone')}
                                    onKeyDown={e => {
                                        if (e.keyCode === 13) {
                                            this.handleSubmit()
                                        }
                                    }}
                                    value={phone}
                                />
                                {error.phone && (
                                    <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">{error.phone}</div>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col md={12}>
                            <h5 className="_countryTitle txt-size-h7 txt-color-black robotoregular">
                                {t('customerAddress.country')} <span className={'txt-color-red mgl5'}>*</span>
                            </h5>
                            <Form.Item className={'_country'}>
                                <Select
                                    showSearch
                                    defaultActiveFirstOption={false}
                                    notFoundContent={
                                        fetching ? (
                                            <div className="txt-center">
                                                <Spin
                                                    indicator={
                                                        <AntdIcon.Loading3QuartersOutlined
                                                            type="loading"
                                                            style={{ fontSize: 24 }}
                                                            spin
                                                        />
                                                    }
                                                />
                                            </div>
                                        ) : Array.isArray(countryList) && countryList.length === 0 ? (
                                            <Empty />
                                        ) : null
                                    }
                                    // disabled={dataItem}
                                    placeholder={t('customerAddress.select_country')}
                                    onChange={value => this.handleSelectChange(value, 'country')}
                                    optionFilterProp="children"
                                    // value={country ? country : null}
                                    value={country ? country : countryList && disabledCountry ? countryList[0].code : null}
                                    disabled={disabledCountry}
                                    // defaultValue={countryList && countryList[0].code}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {Array.isArray(countryList) &&
                                        countryList.map((item, index) => {
                                            return (
                                                !item?.ghost && <Select.Option
                                                    className="_provinceValue"
                                                    key={item.id}
                                                    value={item.code}
                                                >
                                                    {item.name}
                                                </Select.Option>
                                            )
                                        })}
                                </Select>
                                {error.country && (
                                    <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">{error.country}</div>
                                )}
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <h5 className="_provinceTitle txt-size-h7 txt-color-black robotoregular">
                                {t('customerAddress.province')} <span className={'txt-color-red mgl5'}>*</span>
                            </h5>
                            <Form.Item className={'_province'}>
                                <Select
                                    showSearch
                                    defaultActiveFirstOption={false}
                                    disabled={!country && !disabledCountry}
                                    notFoundContent={
                                        fetching ? (
                                            <div className="txt-center">
                                                <Spin
                                                    indicator={
                                                        <AntdIcon.Loading3QuartersOutlined
                                                            type="loading"
                                                            style={{ fontSize: 24 }}
                                                            spin
                                                        />
                                                    }
                                                />
                                            </div>
                                        ) : Array.isArray(provinceList) && provinceList.length === 0 ? (
                                            <Empty />
                                        ) : null
                                    }
                                    placeholder={t('customerAddress.select_province')}
                                    onChange={value => this.handleSelectChange(value, 'province')}
                                    optionFilterProp="children"
                                    value={province ? province : null}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {Array.isArray(provinceList) &&
                                        provinceList.map((item, index) => {
                                            return (
                                                !item?.ghost && <Select.Option
                                                    className="_provinceValue"
                                                    key={item.id}
                                                    value={item.code}
                                                >
                                                    {item.name}
                                                </Select.Option>
                                            )
                                        })}
                                </Select>
                                {error.province && (
                                    <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">
                                        {error.province}
                                    </div>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={12}>
                        {country === 'CN' && (
                            <Col
                                md={12}
                                span={24}
                            >
                                <h5 className="_provinceTitle txt-size-h7 txt-color-black robotoregular">
                                    {t('customerAddress.city')} <span className={'txt-color-red mgl5'}>*</span>
                                </h5>
                                <Form.Item className={'city'}>
                                    <Select
                                        showSearch
                                        defaultActiveFirstOption={false}
                                        disabled={!province}
                                        notFoundContent={
                                            fetching ? (
                                                <div className="txt-center">
                                                    <Spin
                                                        indicator={
                                                            <AntdIcon.Loading3QuartersOutlined
                                                                type="loading"
                                                                style={{ fontSize: 24 }}
                                                                spin
                                                            />
                                                        }
                                                    />
                                                </div>
                                            ) : Array.isArray(cityList) && cityList.length === 0 ? (
                                                <Empty />
                                            ) : null
                                        }
                                        placeholder={t('customerAddress.select_province')}
                                        onChange={value => this.handleSelectChange(value, 'city')}
                                        optionFilterProp="children"
                                        value={city ? city : null}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {Array.isArray(cityList) &&
                                            cityList.map((item, index) => {
                                                return (
                                                    !item?.ghost && <Select.Option
                                                        className="_cityValue"
                                                        key={item.id}
                                                        value={item.code}
                                                    >
                                                        {item.name}
                                                    </Select.Option>
                                                )
                                            })}
                                    </Select>
                                    {error.city && (
                                        <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">
                                            {error.city}
                                        </div>
                                    )}
                                </Form.Item>
                            </Col>
                        )}
                        <Col
                            md={12}
                            span={24}
                        >
                            <h5 className="_districtTitle txt-size-h7 txt-color-black robotoregular">
                                {t('customerAddress.district')} <span className={'txt-color-red mgl5'}>*</span>
                            </h5>
                            <Form.Item className={'_district'}>
                                <Select
                                    dropdownClassName="_districtSelect"
                                    showSearch
                                    placeholder={t('customerAddress.select_district')}
                                    disabled={!province}
                                    defaultActiveFirstOption={false}
                                    notFoundContent={
                                        fetching ? (
                                            <div className="txt-center">
                                                <Spin
                                                    indicator={
                                                        <AntdIcon.Loading3QuartersOutlined
                                                            type="loading"
                                                            style={{ fontSize: 24 }}
                                                            spin
                                                        />
                                                    }
                                                />
                                            </div>
                                        ) : Array.isArray(districtList) && districtList.length === 0 ? (
                                            <Empty />
                                        ) : null
                                    }
                                    onChange={value => this.handleSelectChange(value, 'district')}
                                    optionFilterProp="children"
                                    value={district ? district : null}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {Array.isArray(districtList) &&
                                        districtList.map((item, index) => {
                                            return (
                                                !item?.ghost && <Select.Option
                                                    className="_districtValue"
                                                    key={item.id}
                                                    value={item.code}
                                                >
                                                    {item.name}
                                                </Select.Option>
                                            )
                                        })}
                                </Select>
                                {error.district && (
                                    <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">
                                        {error.district}
                                    </div>
                                )}
                            </Form.Item>
                        </Col>
                        <Col
                            md={12}
                            span={24}
                        >
                            <h5 className="_wardTitle txt-size-h7 txt-color-black robotoregular">
                                {t('customerAddress.ward')} <span className={'txt-color-red mgl5'}>*</span>
                            </h5>
                            <Form.Item className={'_ward'}>
                                <Select
                                    className="_wardSelect"
                                    showSearch
                                    defaultActiveFirstOption={false}
                                    notFoundContent={
                                        fetching ? (
                                            <div className="txt-center">
                                                <Spin
                                                    indicator={
                                                        <AntdIcon.Loading3QuartersOutlined
                                                            type="loading"
                                                            style={{ fontSize: 24 }}
                                                            spin
                                                        />
                                                    }
                                                />
                                            </div>
                                        ) : Array.isArray(wardList) && wardList.length === 0 ? (
                                            <Empty />
                                        ) : null
                                    }
                                    placeholder={t('customerAddress.select_ward')}
                                    disabled={!district}
                                    onChange={value => this.handleSelectChange(value, 'ward')}
                                    optionFilterProp="children"
                                    value={ward ? ward : null}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {Array.isArray(wardList) &&
                                        wardList.map((item, index) => {
                                            return (
                                                !item?.ghost && <Select.Option
                                                    className="_wardValue"
                                                    key={item.id}
                                                    value={item.code}
                                                >
                                                    {item.name}
                                                </Select.Option>
                                            )
                                        })}
                                </Select>
                                {error.ward && (
                                    <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">{error.ward}</div>
                                )}
                            </Form.Item>
                        </Col>
                        <Col
                            md={12}
                            span={24}
                        >
                            <h5 className="_addressTitle txt-size-h7 txt-color-black robotoregular">
                                {t('customerAddress.address')} <span className={'txt-color-red mgl5'}>*</span>
                            </h5>
                            <Form.Item className={'_detail'}>
                                <Input
                                    className="_addressValue"
                                    placeholder={t('customerAddress.address')}
                                    onChange={e => this.onInputChange(e.target.value, 'detail')}
                                    onKeyDown={e => {
                                        if (e.keyCode === 13) {
                                            this.handleSubmit()
                                        }
                                    }}
                                    value={detail}
                                />
                                {error.detail && (
                                    <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">{error.detail}</div>
                                )}
                            </Form.Item>
                        </Col>
                        <Col
                            md={12}
                            span={24}
                        >
                            <h5 className="_phoneTitle txt-size-h7 txt-color-black robotoregular">
                                {t('customerAddress.addressName')}
                            </h5>
                            <Form.Item className={'_address-name'}>
                                <Input
                                    className="_addressName"
                                    placeholder={t('customerAddress.addressName')}
                                    onChange={e => this.onInputChange(e.target.value, 'addressName')}
                                    onKeyDown={e => {
                                        if (e.keyCode === 13) {
                                            this.handleSubmit()
                                        }
                                    }}
                                    value={addressName || ''}
                                />
                                {error.addressName && (
                                    <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">
                                        {error.addressName}
                                    </div>
                                )}
                            </Form.Item>
                        </Col>
                        <Col
                            md={12}
                            span={24}
                        >
                            <h5 className="_phoneTitle txt-size-h7 txt-color-black robotoregular">
                                {t('customerAddress.zipCode')}
                            </h5>
                            <Form.Item className={'_address-name'}>
                                <Input
                                    maxLength={10}
                                    className="_addressName"
                                    placeholder={t('customerAddress.zipCode')}
                                    onChange={e => this.onInputChange(e.target.value, 'zipCode')}
                                    onKeyDown={e => {
                                        if (e.keyCode === 13) {
                                            this.handleSubmit()
                                        }
                                    }}
                                    value={zipCode || ''}
                                />
                                {error.zipCode && (
                                    <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">
                                        {error.zipCode}
                                    </div>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={12}>
                        <Col md={24}>
                            <Form.Item
                                labelCol={{ span: 5 }}
                                className={'_note'}
                            >
                                <TextArea
                                    className="_noteValue"
                                    placeholder={t('customerAddress.typing_note')}
                                    maxLength={1000}
                                    onChange={e => this.onInputChange(e.target.value, 'note')}
                                    onKeyDown={e => {
                                        if (e.keyCode === 13) {
                                            this.handleSubmit()
                                        }
                                    }}
                                    value={note || ''}
                                />
                                {error.note && (
                                    <div className="pdt3 texterror txt-size-h8 txt-color-red2 robotoregular">{error.note}</div>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col md={12}>
                            <Form.Item>
                                <Checkbox
                                    className="_defaultAddress"
                                    value={defaultAddress}
                                    onChange={e => this.onChangeRadio(e)}
                                    disabled={isDefault === true}
                                    name='defaultAddress'
                                >
                                    {t('customerAddress.default_address')}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Modal>
        )
    }
}

export default withNamespaces()(CreateAddressModal)
