import { useEffect, useState } from 'react'
import { Col, Row, Button, Divider, Avatar, Space, notification, Pagination, Spin, Tooltip } from 'antd'
import { withNamespaces } from 'react-i18next'
import MainLayout from '../../components/Layout/MainLayout'
import ProfileSidebar from '../../components/Profile/Leftbar'
import InputItem from '../../components/commons/Input'
import UserService from '../../services/user.service'
import ModalInfoCoupon from './ModalInfoCoupon'
import bgVoucher from '../../resources/images/voucher.svg'
import moment from 'moment'
import NoData from '../../components/NoData'
import { LoadingOutlined } from '@ant-design/icons'

const Coupon = props => {
    const { t } = props
    const [voucher, setVoucher] = useState(null)
    const [checking, setChecking] = useState(false)
    const [infoVoucher, setInfoVoucher] = useState(null)
    const [visible, setVisible] = useState(false)
    const [listCoupon, setListCoupon] = useState([])
    const [metadata, setMetadata] = useState([])
    const [page, setPage] = useState(1)
    const [pageSize] = useState(20)
    const [loading, setLoading] = useState(false)

    const onCheckCoupon = () => {
        setChecking(true)
        setInfoVoucher(null)
        UserService.checkCouponPost(voucher, undefined, undefined, (err, res) => {
            if (!err && res.code) {
                setChecking(false)
                setInfoVoucher(res)
                setVisible(true)
            } else {
                setChecking(false)
                notification.error({
                    message: t(`message.${err?.title || 'coupon_invalid_for_you'}`),
                })
            }
        })
    }

    const getListCoupon = (page, size) => {
        setLoading(true)
        UserService.getListCoupon(page - 1, size, (err, res, headers) => {
            if (!err && res) {
                setListCoupon(res)
                setMetadata({
                    pageCount: headers['x-page-count'],
                    page: headers['x-page-number'],
                    size: headers['x-page-size'],
                    total: headers['x-total-count'],
                })
                setLoading(false)
            } else {
                notification.error({
                    message: t(`message.${err?.title}`),
                })
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        getListCoupon(page, pageSize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onPageChanged = page => {
        setPage(page)
        getListCoupon(page, pageSize)
    }

    return (
        <MainLayout
            {...props}
            headerTitle={t('customer_info.coupon')}
            title={t('customer_info.coupon')}
        >
            <div className={'container-common pdbt30 pdt20 mgr20'}>
                <Row>
                    <Col
                        span={6}
                        className={'pdr10'}
                    >
                        <ProfileSidebar currentRoute={'coupon'} />
                    </Col>
                    <Col
                        span={18}
                        className={'pdl10'}
                    >
                        <div className="bg-color-white pd20 h-100">
                            <div className=" text-base font-medium text-black-100 mb-4">{t('customer_info.listCoupon')}</div>
                            <div className="p-4 bg-gray-100 rounded-xl">
                                <Row justify="center">
                                    <Col span={16}>
                                        <Row
                                            gutter={12}
                                            justify="space-between"
                                        >
                                            <Col flex="auto">
                                                <InputItem
                                                    title={t('coupon.voucherCode')}
                                                    className={'_search-box-input-query'}
                                                    onChange={e => setVoucher(e.target.value)}
                                                    value={voucher || ''}
                                                    onPressEnter={() => onCheckCoupon()}
                                                    allowClear
                                                />
                                            </Col>
                                            <Col flex="none">
                                                <Button
                                                    className={
                                                        'btn-cancel _btn-cancel-confirm h-11 hover:bg-gallery-100 border-none'
                                                    }
                                                    onClick={() => onCheckCoupon()}
                                                    loading={checking}
                                                >
                                                    {t('button.check')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <Divider className="my-4" />
                            <Spin
                                spinning={loading}
                                indicator={<LoadingOutlined />}
                            >
                                <Row gutter={[16, 16]}>
                                    {listCoupon?.length > 0 ? (
                                        listCoupon?.map(i => {
                                            return (
                                                <Col
                                                    xxl={12}
                                                    span={24}
                                                >
                                                    <Row>
                                                        <Col flex="none">
                                                            <div className="relative w-full">
                                                                <img
                                                                    src={bgVoucher}
                                                                    alt="coupon"
                                                                    className="w-full h-[120px]"
                                                                ></img>
                                                                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
                                                                    {i?.image ? (
                                                                        <Avatar
                                                                            size={72}
                                                                            src={i?.image}
                                                                        />
                                                                    ) : null}
                                                                    <div className="text-white font-medium mt-1">{i?.code}</div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col
                                                            flex="1 1"
                                                            className="border-solid border border-gallery-100 rounded-r-md border-l-0"
                                                        >
                                                            <div className="px-4 py-2 h-full flex flex-col justify-between">
                                                                <div>
                                                                    <Space>
                                                                        <div className=" font-bold text-black-100">
                                                                            {i?.title}
                                                                        </div>
                                                                        <div className=" text-[10px] text-red-100">
                                                                            {t('coupon.voucherRemaining', {
                                                                                value: i?.remaining,
                                                                            })}
                                                                        </div>
                                                                    </Space>
                                                                    <Tooltip
                                                                        placement="top"
                                                                        title={<div className="text-white">{i?.description}</div>}
                                                                    >
                                                                        <div className="text-black-100 line-clamp-2">
                                                                            {i?.description}
                                                                        </div>
                                                                    </Tooltip>
                                                                    <div className="text-black-100 text-xs">
                                                                        {t('coupon.voucherCustomerLimit', {
                                                                            value: i?.customerLimit,
                                                                        })}
                                                                    </div>
                                                                </div>
                                                                <Row justify="space-between">
                                                                    <Col>
                                                                        <div className="text-grey-200 text-xs">
                                                                            {t('coupon.voucherValidTo', {
                                                                                value: moment(i?.validTo).format('DD/MM/YYYY'),
                                                                            })}
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <div
                                                                            className=" underline text-blue-400 text-xs cursor-pointer"
                                                                            onClick={() => {
                                                                                setInfoVoucher(i)
                                                                                setVisible(true)
                                                                            }}
                                                                        >
                                                                            {t('button.detail')}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )
                                        })
                                    ) : (
                                        <Col
                                            span={24}
                                            className=" text-center"
                                        >
                                            <NoData />
                                        </Col>
                                    )}
                                </Row>
                            </Spin>
                            {metadata?.total > 20 && (
                                <Pagination
                                    className={'text-center mt-4'}
                                    hideOnSinglePage={true}
                                    showQuickJumper
                                    current={metadata ? parseFloat(metadata.page) + 1 : 1}
                                    total={metadata ? parseFloat(metadata.total) : 1}
                                    pageSize={metadata ? parseFloat(metadata.size) : pageSize}
                                    onChange={page => onPageChanged(page)}
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
            <ModalInfoCoupon
                visible={visible}
                setVisible={value => {
                    setVisible(value)
                    setInfoVoucher(null)
                }}
                infoVoucher={infoVoucher}
                t={t}
            />
        </MainLayout>
    )
}

export default withNamespaces()(Coupon)
