import { useState } from 'react'
import { Button, notification } from 'antd'
import { withNamespaces } from 'react-i18next'
import cartTaobaoGlobal from '../../../../../services/cartTaobaoGlobal.service'
import Modal from '../../../../../components/commons/Modal'

const DeleteProduct = props => {
    const { t, itemSku, onClearParams, checkedItems, getListCartItem, type } = props
    const [isLoading, setIsLoading] = useState(false)
    const [visible, setVisible] = useState(false)

    const onSubmit = async () => {
        setIsLoading(true)
        const queryParams =
            type === 'all'
                ? checkedItems.map(item => `cartItemId=${encodeURIComponent(item?.sku?.id)}`).join('&')
                : `cartItemId=${itemSku.id}`
        cartTaobaoGlobal.deleteProduct(queryParams, (err, res) => {
            if (!err) {
                notification.success({ message: t('cartTbg.message_delele_product_success') })
                onClearParams()
                getListCartItem({})
                setVisible(false)
            } else {
                notification.error({ message: t('cartTbgs.message_delele_product_fail') })
            }
            setIsLoading(false)
        })
    }

    return (
        <>
            {type === 'all' ? (
                <span
                    className="text-grey-200 p-2 robotoregular hover:text-red-100 cursor-pointer"
                    onClick={e => {
                        setVisible(true)
                    }}
                >
                    <i className="fa-light fa-trash" />
                    {t('cart.delete_all')}
                </span>
            ) : (
                <i
                    className="fa-light fa-trash p-2 hover:text-red-100 text-grey-200 cursor-pointer"
                    onClick={e => {
                        setVisible(true)
                    }}
                />
            )}
            <Modal
                title={t('cartTbg.modal_title_delete_product')}
                className="v2-layout modal-delete-common"
                visible={visible}
                onCancel={() => setVisible(false)}
                listAction={[
                    <Button
                        className="btn-cancel"
                        onClick={() => setVisible(false)}
                    >
                        {t('modal.cancel')}
                    </Button>,
                    <Button
                        className="btn-confirm-cancel"
                        onClick={onSubmit}
                        loading={isLoading}
                    >
                        {t('action.delete')}
                    </Button>,
                ]}
            >
                <div>
                    <span className="robotoregular text-grey-200">
                        {type === 'all'
                            ? t('cartTbg.confirm_delete_products', { value: checkedItems.length })
                            : t('cartTbg.confirm_delete_product')}
                    </span>
                </div>
            </Modal>
        </>
    )
}
export default withNamespaces('translation')(DeleteProduct)
