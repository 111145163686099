/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */

import React from 'react'
import moment from 'moment'
import {
    Input,
    Spin,
    Table,
    Button,
    Empty,
    Popconfirm,
    Tag,
    Tooltip,
    Col,
    Row,
    notification,
    Modal,
    Timeline,
    Popover,
} from 'antd'
import lodash from 'lodash'
import * as AntdIcon from '@ant-design/icons'

import { withNamespaces } from 'react-i18next'
import ShipmentsService from '../../services/shipments.service'
import _ from 'lodash'
import { quantityFormat } from '../../utils/stringUtils'
import { LocalStore } from '../../utils/LocalStore'
import ImportExcel from '../ReactDropzone/ImportExcel'
import uploadFile from '../../utils/api/uploadFile'
import TooltipCommon from '../commons/Tooltip'
import { renderTimeMidMile } from '../../utils/helper'
import CategoriesService from '../../services/categories.service'

class TabWaybill extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            listcouries: [],
        }
    }
    componentDidMount() {
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        this.getCategoriesCouriers()
        this.setState({
            showPackageIoHistory: lodash.get(currentProjectInfo, 'tenantConfig.generalConfig.showPackageIoHistory', false),
        })
    }

    /**
     * lấy ra danh sách couries
     */
    getCategoriesCouriers = () => {
        CategoriesService.getCouriers((err, res, headers) => {
            this.setState({
                listcouries: res || [],
            })
        })
    }

    /**
     * lấy ra danh sách Mã vận đơn
     */
    getParcelMileStone = paramCode => {
        this.setState({ isLoadingMilestone: true }, () => {
            ShipmentsService.getParcelMileStone(paramCode, (err, res, headers) => {
                if (!err) {
                    this.setState({
                        isLoadingMilestone: false,
                        listPackageMileStones: res || [],
                    })
                } else {
                    this.setState({ isLoadingMilestone: false })
                }
            })
        })
    }

    /**
     * lấy ra danh sách Mã vận đơn
     */
    printParcels = (waybill, parcel) => {
        const { orderDetail = {}, t, listWaybillParcels = [] } = this.props
        let listPackages = []
        if (!waybill.emptyWaybill) {
            listPackages = _.sortBy(
                listWaybillParcels.filter(x => x.waybillCode === waybill.code),
                'modifiedAt'
            ).reverse()
        } else {
            listPackages = _.sortBy(
                listWaybillParcels.filter(x => !x.waybillCode),
                'modifiedAt'
            ).reverse()
        }
        let query = `?parcels=`

        if (parcel) {
            query = query + parcel.code
        } else {
            listPackages.map((x, y) => (query = query + (y === 0 ? x.code : `,${x.code}`)))
        }

        this.setState({ isLoadingPrint: true }, () => {
            ShipmentsService.printParcels(orderDetail.code, query, (err, res, headers) => {
                if (!err) {
                    //console.log(res)
                    this.setState({
                        printData: res,
                        onPrint: true,
                        isLoadingPrint: false,
                    })
                } else {
                    if (err.title === 'action_do_not_allow') {
                        notification['error']({
                            message: t('waybill.action_do_not_allow'),
                        })
                    } else {
                        notification['error']({
                            message: t(err.message),
                        })
                    }
                    this.setState({ isLoadingPrint: false })
                }
            })
        })
    }

    checkDuplicateCode = (code = '', cb) => {
        const { orderDetail = {}, t, createWaybill } = this.props
        this.setState({ checkDuplicateCode: true }, () => {
            ShipmentsService.checkWallbillDuplicate(
                orderDetail.code,
                encodeURIComponent(code.replace(/ /g, '')),
                (err, res, headers) => {
                    if (!err) {
                        this.setState(
                            {
                                checkDuplicateCode: res,
                            },
                            () => {
                                if (!res.alreadyExists) {
                                    if (cb) cb()
                                } else {
                                    Modal.confirm({
                                        title: (
                                            <div>
                                                {t('waybill.checkDuplicateCode_title')}{' '}
                                                <a
                                                    href={`/#/shipments/${res.shipmentCode}`}
                                                    target={'_blank'}
                                                    rel="noreferrer"
                                                >
                                                    {res.shipmentCode}
                                                </a>
                                            </div>
                                        ),
                                        content: t('orderDetail.confirm_question'),
                                        okText: t('button.create'),
                                        onOk: () => {
                                            createWaybill(code, () =>
                                                this.setState({ isCreate: false, code: '', checkDuplicateCode: null })
                                            )
                                        },
                                        cancelText: t('button.no'),
                                        onCancel: () => {
                                            this.setState({ isCreate: false, code: '', checkDuplicateCode: null })
                                        },
                                        autoFocusButton: 'cancel',
                                    })
                                }
                            }
                        )
                    } else {
                        if (err.title === 'duplicate_code') {
                            notification['error']({
                                message: t('waybill.duplicate_code'),
                            })
                        } else if (err.title === 'action_do_not_allow') {
                            notification['error']({
                                message: t('waybill.action_do_not_allow'),
                            })
                        } else {
                            notification['error']({
                                message: t(err.message),
                            })
                        }
                    }
                }
            )
        })
    }

    /**
     * upload file
     */
    onSubmitImport = () => {
        const { t, orderDetail = {} } = this.props
        const { isSubmitting, isUploading, disabled, fileDisabled } = this.state

        const file = this.importExcel.state.file

        if (isSubmitting === true || isUploading === true || disabled || fileDisabled) return
        this.setState({ isSubmitting: true, showProgressBar: true }, () => {
            let apiServiceUrl = `/customer/shipments/${orderDetail.code}/import_parcel`

            uploadFile(apiServiceUrl, [file], {})
                .then(response => {
                    this.setState(
                        {
                            excelImported: response,
                            isSubmitting: false,
                            showProgressBar: false,
                        },
                        () => {
                            if (response.status === 'SUCCESS') {
                                if (this.importExcel)
                                    this.importExcel.setState({
                                        file: '',
                                    })
                                this.setState({
                                    modalImportShow: false,
                                })
                                notification['success']({
                                    message: t('message.import_packages_success'),
                                })
                            }
                        }
                    )
                })
                .catch(error => {
                    if (error.title === 'data_sheet_not_found') {
                        notification['error']({
                            message: t('shipment.data_sheet_not_found'),
                        })
                    } else if (error.title === 'action_do_not_allow') {
                        notification['error']({
                            message: t('waybill.action_do_not_allow'),
                        })
                    } else {
                        notification['error']({
                            message: t(error.message),
                        })
                    }
                    this.setState({ isSubmitting: false, showProgressBar: false })
                })
        })
    }

    fileUpLoaded = file => {
        const { isUploading, isSubmitting } = this.state
        if (isSubmitting === true || isUploading === true) return
        this.setState({ isUploading: true, excelImported: {}, fileDisabled: false }, () => {
            this.setState({ isUploading: false, fileErrors: [] })
        })
    }

    getPackageIOHistories = code => {
        this.setState(
            {
                isLoadingListMilestones: true,
            },
            () => {
                ShipmentsService.getPackageIOHistories(code, (err, res) => {
                    if (!err) {
                        this.setState({
                            listMilestones: res,
                        })
                    }
                    this.setState({ isLoadingListMilestones: false })
                })
            }
        )
    }
    renderTimeLine = () => {
        const { t } = this.props
        const { listMilestones = [], isLoadingListMilestones } = this.state

        if (isLoadingListMilestones === true) {
            return (
                <div className="mgt25 mgbt25 txt-center">
                    <Spin
                        spinning={isLoadingListMilestones === true}
                        indicator={
                            <AntdIcon.Loading3QuartersOutlined
                                type="loading"
                                style={{ fontSize: 24 }}
                                spin
                            />
                        }
                    />
                </div>
            )
        }

        return (
            <Timeline>
                {Array.isArray(listMilestones) &&
                    listMilestones.map((item, index) => {
                        // const status = listStatuses.find(x => x.code === item.status);
                        return (
                            <Timeline.Item color={index === 0 ? 'green' : 'gray'}>
                                <p className="mgbt0">
                                    {moment(item.createdAt).format('HH:mm DD/MM')} -{' '}
                                    {t(`package.${item.statusWarehouse === 'IN' ? 'inWarehouse' : 'outWarehouse'}`, {
                                        value: lodash.get(item, 'warehouse.mask', '---'),
                                    })}
                                </p>
                            </Timeline.Item>
                        )
                    })}
            </Timeline>
        )
    }
    renderSteps = record => {
        const { listPackageMileStones = [], isLoadingMilestone } = this.state
        const { statusPackageList = [] } = this.props
        if (isLoadingMilestone)
            return (
                <div className="dpl-flex align-items-center justify-content pdt10 pdbt10">
                    <Spin
                        indicator={
                            <AntdIcon.Loading3QuartersOutlined
                                type="loading"
                                style={{ fontSize: 24 }}
                                spin
                            />
                        }
                    />
                </div>
            )
        const { t } = this.props
        const current = statusPackageList.findIndex(x => x.code === record.status)
        const currentPackageStatus = statusPackageList.find(x => x.code === record.status) || {}
        let currentMileStone = []

        if (!currentPackageStatus.negativeEnd) {
            return (
                <div>
                    {statusPackageList
                        .filter(x => !x.negativeEnd)
                        .map((x, i) => {
                            currentMileStone = listPackageMileStones.filter(y => y.status === x.code)
                            let dayOrDays = true
                            let checkNull = false
                            let day = ''
                            if (currentMileStone.length > 0) {
                                currentMileStone.map(item => {
                                    if (item.handlingTime === 0) {
                                        dayOrDays = false
                                    } else dayOrDays = true
                                    if (item.handlingTime === null) {
                                        checkNull = true
                                    } else {
                                        checkNull = false
                                    }
                                })
                            }
                            if (dayOrDays && !checkNull) {
                                day = t('label.days')
                            } else if (!dayOrDays && !checkNull) {
                                day = t('label.day')
                            }
                            moment(_.sortBy(currentMileStone, 'timestamp').reverse()).format('HH:mm DD/MM')
                            return (
                                <div
                                    className={`pd5 ${i <= current ? 'active' : 'opacity6'}`}
                                    key={x.id}
                                >
                                    <div className="txt-size-h7 txt-color-black bold">{x.name}</div>
                                    <div className="txt-size-h8 txt-color-gray2">
                                        {currentMileStone.length > 0
                                            ? currentMileStone.map((item, y) => {
                                                  return (
                                                      <Col>
                                                          <Row style={y === 0 ? { fontWeight: 'bold' } : { fontWeight: '100' }}>
                                                              {moment(item.timestamp).format('HH:mm DD/MM')}
                                                          </Row>
                                                          <Row style={y === 0 ? { fontWeight: 'bold' } : { fontWeight: '100' }}>
                                                              {item.handlingTime === null
                                                                  ? '( ' + t('orderDetail.undefined') + ')'
                                                                  : '( ' + item.handlingTime + ' ' + day + ')'}
                                                          </Row>
                                                      </Col>
                                                  )
                                              })
                                            : t('orderDetail.undefined')}
                                    </div>
                                </div>
                            )
                        })}
                </div>
            )
        } else {
            // trường hợp trạng thái cuối cùng của package là thất lạc
            let newArray = _.sortBy(
                listPackageMileStones.filter(x => x.status !== currentPackageStatus.code),
                'timestamp'
            ).reverse()
            const lastMileStones = newArray.length > 0 ? newArray[0] : null
            const lastMileStonesIndex = lastMileStones ? statusPackageList.findIndex(x => x.code === lastMileStones.status) : 0
            // tạo ra mảng chỉ chứa các phần tử từ đầu đến lastActiveStatus
            const newListPackageStatuses = _.cloneDeep(statusPackageList).slice(0, lastMileStonesIndex + 1)

            const mileStonesNegativeEnd = listPackageMileStones.filter(y => y.status === currentPackageStatus.code)

            return (
                <div>
                    {lastMileStones &&
                        newListPackageStatuses
                            .filter(x => !x.negativeEnd)
                            .map((x, i) => {
                                currentMileStone = listPackageMileStones.filter(y => y.status === x.code)
                                return (
                                    <div
                                        className="pd5 active"
                                        key={x.id}
                                    >
                                        <div className="txt-size-h7 txt-color-black bold">{x.name}</div>
                                        <div className="txt-size-h8 txt-color-gray2">
                                            {currentMileStone.length > 0
                                                ? moment(_.sortBy(currentMileStone, 'timestamp').reverse()[0].timestamp).format(
                                                      'HH:mm DD/MM'
                                                  )
                                                : t('orderDetail.undefined')}
                                        </div>
                                    </div>
                                )
                            })}
                    <div
                        className="pd5 active"
                        key={currentPackageStatus.id}
                    >
                        <div className="txt-size-h7 txt-color-black bold">{currentPackageStatus.name}</div>
                        <div className="txt-size-h8 txt-color-gray2">
                            {mileStonesNegativeEnd.length > 0
                                ? moment(_.sortBy(mileStonesNegativeEnd, 'timestamp').reverse()[0].timestamp).format(
                                      'HH:mm DD/MM'
                                  )
                                : t('orderDetail.undefined')}
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderExpandRow = waybill => {
        const { listWaybillParcels = [], t, statusPackageList = [], listStatuses = [], orderDetail = {} } = this.props
        const { listcouries } = this.state
        let listPackages = []
        if (!waybill.emptyWaybill) {
            listPackages = _.sortBy(
                listWaybillParcels.filter(x => x.waybillCode === waybill.code),
                'modifiedAt'
            ).reverse()
        } else {
            listPackages = _.sortBy(
                listWaybillParcels.filter(x => !x.waybillCode),
                'modifiedAt'
            ).reverse()
        }
        const { showPackageIoHistory } = this.state
        const currentStatus = listStatuses.find(x => x.code === orderDetail.status) || {}
        let currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {}
        const tenant = LocalStore.getInstance().read('currentProjectInfo')
        const showPackageNote = _.get(tenant, 'tenantConfig.generalConfig.showPackageNote', false)

        if (Array.isArray(listPackages) && listPackages.length > 0) {
            const columns = [
                {
                    title: t('delivery.package_code'),
                    dataIndex: 'code',
                    key: 'code',
                    render: (text, record) => {
                        return (
                            <div className="flex flex-col justify-start">
                                <span className="_table-row-code txt-uppercase whitespace-pre-wrap">{text}</span>
                                {showPackageNote && record?.note && (
                                    <TooltipCommon
                                        placement={'top'}
                                        content={record?.note}
                                        label={
                                            <span className="text-blue-primary cursor-pointer underline">
                                                {t('delivery.note')}
                                            </span>
                                        }
                                    />
                                )}
                                {record?.status === 'TRANSPORTING' &&
                                    record?.courier &&
                                    renderTimeMidMile(record, listcouries, orderDetail?.deliveryWarehouse?.code)}
                            </div>
                        )
                    },
                },
                {
                    title: t('package.volumetric'),
                    dataIndex: 'volumetric',
                    key: 'volumetric',
                    render: text => (
                        <span className="_table-row-volume">
                            {text ? quantityFormat(text) + ' cm3' : t('orderDetail.undefined')}
                        </span>
                    ),
                },
                {
                    title: t('package.weight'),
                    dataIndex: 'weight',
                    key: 'weight',
                    render: (text, record) => {
                        const weight = record.actualWeight //Math.max(record.actualWeight, record.dimensionalWeight);
                        return (
                            <span className="_table-row-weight">
                                {Number.isFinite(weight) ? `${quantityFormat(weight)} kg` : t('orderDetail.undefined')}
                            </span>
                        )
                    },
                },
                {
                    title: t('package.information'),
                    dataIndex: 'information',
                    key: 'information',
                    render: (text, record) => {
                        return (
                            <div className="whitespace">
                                <div className={'_length whitespace'}>
                                    {t('package.length')}: {Number.isFinite(record.length) ? `${record.length} cm` : '---'}
                                </div>
                                <div className={'_width whitespace'}>
                                    {t('package.width')}: {Number.isFinite(record.width) ? `${record.width} cm` : '---'}
                                </div>
                                <div className={'_height whitespace'}>
                                    {t('package.height')}: {Number.isFinite(record.height) ? `${record.height} cm` : '---'}
                                </div>
                            </div>
                        )
                    },
                },
                {
                    title: t('package.status'),
                    dataIndex: 'status',
                    key: 'status',
                    render: (text, record) => {
                        const itemStatus = statusPackageList.find(x => x.code === text) || {}
                        if (!showPackageIoHistory)
                            return (
                                <Tag
                                    style={{ backgroundColor: itemStatus.color || '#898989' }}
                                    className={`_table-row-status listorders__items_right--status ${
                                        showPackageIoHistory && 'cursor-pointer'
                                    }`}
                                >
                                    {itemStatus.name}
                                </Tag>
                            )
                        return (
                            <Popover
                                trigger={'click'}
                                overlayClassName={'white-tool-tip timeline-tooltip'}
                                onVisibleChange={visible => {
                                    if (visible) this.getPackageIOHistories(record.code)
                                }}
                                placement="left"
                                title={null}
                                content={this.renderTimeLine(record)}
                            >
                                <Tag
                                    style={{ backgroundColor: itemStatus.color || '#898989' }}
                                    className={'_table-row-status listorders__items_right--status cursor-pointer'}
                                >
                                    {itemStatus.name}
                                </Tag>
                            </Popover>
                        )
                    },
                },
                {
                    title: t('shipment.modifiedAt'),
                    dataIndex: 'modifiedAt',
                    key: 'modifiedAt',
                    render: text => <span className="_table-row-createdAt">{moment(text).format('HH:mm DD/MM/YYYY')}</span>,
                },
                {
                    title: '',
                    dataIndex: 'action',
                    key: 'action',
                    className: 'txt-right',
                    render: (text, record) => (
                        <div>
                            <Tooltip
                                overlayClassName={'white-tool-tip'}
                                onVisibleChange={visible => {
                                    if (visible) this.getParcelMileStone(record.code)
                                }}
                                placement="right"
                                title={this.renderSteps(record)}
                            >
                                <span className="txt-color-blue">{t('fee_tab.detail')}</span>
                            </Tooltip>
                            {currentLoggedUser.customerAuthorities &&
                                currentLoggedUser.customerAuthorities.printParcelStamp &&
                                !currentStatus.negativeEnd && (
                                    <span
                                        onClick={() => this.printParcels([], record)}
                                        className="_address-print-btn cursor-pointer mgl20"
                                    >
                                        <AntdIcon.PrinterOutlined type="printer" />
                                    </span>
                                )}
                        </div>
                    ),
                },
            ]

            return (
                <Table
                    className={'_table-data custom-empty'}
                    rowKey={'code'}
                    columns={columns}
                    dataSource={listPackages}
                    rowClassName={() => `_table-row`}
                    pagination={{
                        hideOnSinglePage: true,
                        pageSize: listPackages.length,
                    }}
                />
            )
        } else {
            return (
                <div className="pdt10 pdbt10 bg-color-white">
                    <Empty
                        image={require('../../resources/images/empty-image.png')}
                        description={t('message.empty')}
                    />
                </div>
            )
        }
    }

    renderModalImportFileExcel = () => {
        const { t } = this.props
        const {
            modalImportShow,
            isUploading,
            isSubmitting,
            fileErrors = [],
            disabled,
            excelImported = {},
            fileDisabled,
        } = this.state
        return (
            <Modal
                className="_modalAddress modal-import"
                title={t('shipment.modal_import_packages')}
                visible={modalImportShow}
                onCancel={() => this.setState({ modalImportShow: false })}
                footer={
                    <div className="dpl-flex align-items-center width-100-percent justify-content-between">
                        <a
                            href={'//cdn.gobiz.vn/import_parcel_to_shipment.xlsx'}
                            download
                            className="txt-color-blue cursor-pointer"
                        >
                            {t('shipment.download_template')}
                        </a>

                        <div>
                            <Button
                                onClick={() => this.setState({ modalImportShow: false })}
                                className="_btn-cancel"
                            >
                                {t('button.cancel')}
                            </Button>
                            <Button
                                onClick={this.onSubmitImport}
                                disabled={disabled || fileErrors.filter(x => x).length > 0 || fileDisabled}
                                loading={isUploading || isSubmitting}
                                type="primary"
                                className="_btn-add"
                            >
                                {t('cartCheckout.confirm')}
                            </Button>
                        </div>
                    </div>
                }
            >
                <ImportExcel
                    t={t}
                    ref={ref => (this.importExcel = ref)}
                    // isUploading={isUploading}
                    fileUpLoaded={this.fileUpLoaded}
                />
                {fileErrors.filter(x => x).length > 0 && (
                    <div className="pdt10">
                        {fileErrors.map((x, y) => {
                            if (!x) return
                            return (
                                <div
                                    key={y}
                                    className="pdbt5 txt-color-red txt-size-h8"
                                >
                                    {t('shipment.row')} {y + 3}, {x.substring(0, x.length - 2)}
                                </div>
                            )
                        })}
                    </div>
                )}

                {excelImported && Array.isArray(excelImported.errorCells) && excelImported.errorCells.length > 0 && (
                    <div className="pdt5 txt-color-red txt-size-h8">
                        {t('shipment.error_cell')}{' '}
                        {excelImported.errorCells.map((x, y) => {
                            if (y === 0) return x
                            return ', ' + x
                        })}
                    </div>
                )}
            </Modal>
        )
    }

    renderPrint = () => {
        const { printData = [] } = this.state
        let printHtmls = []

        printData.map((item, index) => {
            let html = item.template
            html = html.replace(
                '[[imageBarcode]]',
                `<img src="http://barcodes4.me/barcode/c128a/${item.parcelCode}.jpg?height=90" \/>`
            )

            printHtmls.push(
                <div
                    className={'sheet'}
                    style={{ marginTop: 20, marginBottom: 100 }}
                    key={index}
                    dangerouslySetInnerHTML={{ __html: html }}
                />
            )
            if (index < printData.length - 1)
                printHtmls.push(
                    <div
                        key={index + 'break'}
                        className="page-break"
                    />
                )
        })

        return printHtmls
    }

    print() {
        let content = document.getElementById('printarea')
        let pri = document.getElementById('ifmcontentstoprint').contentWindow

        let css = '<style type="text/css">' + '.sheet { page-break-after: always; }' + '</style>'
        pri.document.open()
        pri.document.write(css + content.innerHTML)
        pri.document.close()
        pri.focus()

        setTimeout(() => {
            pri.print()
        }, 500)
    }

    render() {
        const {
            t,
            isLoading,
            listWaybills = [],
            createWaybill,
            submittingWaybill,
            deleteWaybill,
            listStatuses = [],
            orderDetail = {},
            listWaybillParcels = [],
        } = this.props
        const { isCreate, code, expandedRow = [] } = this.state
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        let shipmentWaybillThreshold = 0

        if (currentProjectInfo.tenantConfig && currentProjectInfo.tenantConfig.generalConfig) {
            shipmentWaybillThreshold = currentProjectInfo.tenantConfig.generalConfig.shipmentWaybillThreshold
        }

        const currentStatus = listStatuses.find(x => x.code === orderDetail.status) || {}

        let listData = []

        if (currentStatus.updatable === false) {
            if (
                listWaybillParcels.find(x => !x.waybillCode || !listWaybills.find(y => y.code === x.waybillCode)) &&
                listWaybillParcels.length > 0
            ) {
                if (listWaybillParcels.find(x => x.waybillCode && !listWaybills.find(y => y.code === x.waybillCode))) {
                    listWaybillParcels
                        .filter(x => x.waybillCode && !listWaybills.find(y => y.code === x.waybillCode))
                        .map(x => {
                            if (!listData.find(y => y.code === x.waybillCode)) {
                                listData.push({
                                    code: x.waybillCode,
                                    createdAt: x.createdAt,
                                })
                            }
                        })
                }
                if (listWaybillParcels.find(x => !x.waybillCode)) {
                    listData = [{ code: t('shipments.empty_code'), emptyWaybill: true }, ...listData, ...listWaybills]
                } else {
                    listData = [...listData, ...listWaybills]
                }
            } else {
                listData = [...listWaybills]
            }
        } else {
            if (
                listWaybillParcels.find(x => !x.waybillCode || !listWaybills.find(y => y.code === x.waybillCode)) &&
                listWaybillParcels.length > 0
            ) {
                if (listWaybillParcels.find(x => x.waybillCode && !listWaybills.find(y => y.code === x.waybillCode))) {
                    listWaybillParcels
                        .filter(x => x.waybillCode && !listWaybills.find(y => y.code === x.waybillCode))
                        .map(x => {
                            if (!listData.find(y => y.code === x.waybillCode)) {
                                listData.push({
                                    code: x.waybillCode,
                                    createdAt: x.createdAt,
                                })
                            }
                        })
                }
                if (listWaybillParcels.find(x => !x.waybillCode)) {
                    listData = [
                        { code: null },
                        { code: t('shipments.empty_code'), emptyWaybill: true },
                        ...listData,
                        ...listWaybills,
                    ]
                } else {
                    listData = [{ code: null }, ...listData, ...listWaybills]
                }
            } else {
                listData = [{ code: null }, ...listWaybills]
            }
        }

        if (shipmentWaybillThreshold !== undefined && shipmentWaybillThreshold !== null) {
            if (listData.filter(x => x.code && !x.emptyWaybill).length >= shipmentWaybillThreshold) {
                listData = listData.filter(x => x.code !== null)
            }
        }
        let currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {}
        const columns = [
            {
                title: t('waybill.waybill_code') + ` (${listData.filter(x => x.code).length}/${shipmentWaybillThreshold})`,
                dataIndex: 'code',
                className: '_code break-word',
                render: (text, row, index) => {
                    if (index === 0 && listData.find(x => x.code === null)) {
                        return {
                            children: !isCreate ? (
                                <div
                                    onClick={() => this.setState({ isCreate: true })}
                                    className="txt-color-blue cursor-pointer"
                                >
                                    <AntdIcon.PlayCircleOutlined
                                        type="plus-circle"
                                        className="pdr5"
                                    />{' '}
                                    {t('waybill.create_waybill')}
                                </div>
                            ) : (
                                <div className="dpl-flex align-items-center">
                                    <Input
                                        value={code}
                                        onChange={e =>
                                            this.setState({
                                                code: /^([a-zA-Z0-9_.:-])*$/.test(e.target.value) ? e.target.value : code,
                                            })
                                        }
                                        onKeyDown={e => {
                                            if (e.keyCode === 13 && code) {
                                                this.checkDuplicateCode(code, () => {
                                                    createWaybill(code, () =>
                                                        this.setState({ isCreate: false, code: '', checkDuplicateCode: null })
                                                    )
                                                })
                                            }
                                        }}
                                        className="width-150 flex-none"
                                        placeholder={t('waybill.waybill_code')}
                                    />
                                    <Button
                                        disabled={!code}
                                        onClick={() => {
                                            this.checkDuplicateCode(code, () => {
                                                createWaybill(code, () =>
                                                    this.setState({ isCreate: false, code: '', checkDuplicateCode: null })
                                                )
                                            })
                                        }}
                                        type="primary"
                                        size="small"
                                        ghost
                                        className="mgl10"
                                        loading={submittingWaybill}
                                    >
                                        {t('button.save')}
                                    </Button>
                                    <Button
                                        onClick={() => this.setState({ isCreate: false, code: '' })}
                                        type="danger"
                                        size="small"
                                        ghost
                                        className="mgl10"
                                    >
                                        {t('button.cancel')}
                                    </Button>
                                </div>
                            ),
                            props: {
                                colSpan: 4,
                            },
                        }
                    }
                    return text || '---'
                },
            },
            {
                title: t('delivery.total_packages'),
                dataIndex: 'total',
                className: 'txt-right',
                render: (txt, row, index) => {
                    if (index === 0 && listData.find(x => x.code === null)) {
                        return {
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        if (row.emptyWaybill) {
                            const listPackages = listWaybillParcels.filter(x => !x.waybillCode)
                            return <span className="_waybill-amount whitespace">{quantityFormat(listPackages.length)}</span>
                        }
                        const listPackages = listWaybillParcels.filter(x => x.waybillCode === row.code)
                        return <span className="_waybill-amount whitespace">{quantityFormat(listPackages.length)}</span>
                    }
                },
            },
            {
                title: t('waybill.created_at'),
                dataIndex: 'createdAt',
                render: (text, row, index) =>
                    index === 0 && listData.find(x => x.code === null) ? (
                        {
                            props: {
                                colSpan: 0,
                            },
                        }
                    ) : (
                        <span className="_waybill-createdAt break-word">
                            {text ? moment(text).format('HH:mm DD/MM/YYYY') : '---'}
                        </span>
                    ),
            },
            {
                title: '',
                dataIndex: 'actions',
                className: 'txt-right',
                render: (text, row, index) => {
                    const listPackages = listWaybillParcels.filter(x => x.waybillCode === row.code)
                    if (index === 0 && listData.find(x => x.code === null)) {
                        return {
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return (
                            <div>
                                {currentStatus.updatable === true && row.customer && listPackages.length === 0 ? (
                                    <Popconfirm
                                        overlayClassName={`_delete-popconfirm-overlay`}
                                        className={`_delete-popconfirm`}
                                        title={t('message.delete_confirm')}
                                        onConfirm={() => deleteWaybill(row.code)}
                                        okText={t('button.yes')}
                                        cancelText={t('button.no')}
                                    >
                                        <a className="_address-delete-btn cursor-pointer">{t('button.delete')}</a>
                                    </Popconfirm>
                                ) : null}
                                {currentLoggedUser.customerAuthorities &&
                                    currentLoggedUser.customerAuthorities.printParcelStamp &&
                                    !currentStatus.negativeEnd && (
                                        <a
                                            onClick={() => this.printParcels(row)}
                                            className="_address-print-btn cursor-pointer mgl10"
                                        >
                                            <AntdIcon.PrinterOutlined type="printer" />
                                        </a>
                                    )}
                            </div>
                        )
                    }
                },
            },
        ]

        return (
            <div className={'box-financial pdbt20'}>
                <Spin
                    spinning={isLoading === true || this.state.isLoadingPrint === true}
                    indicator={
                        <AntdIcon.Loading3QuartersOutlined
                            type="loading"
                            style={{ fontSize: 24 }}
                            spin
                        />
                    }
                >
                    <div className="pdl10 pdr10 pdbt10">
                        {currentLoggedUser.customerAuthorities &&
                            currentLoggedUser.customerAuthorities.importParcel &&
                            currentStatus.updatable && (
                                <div className="dpl-flex justify-content-end pdbt15">
                                    <a
                                        onClick={() => {
                                            this.setState({ modalImportShow: true })
                                        }}
                                        className={'_btn-create ant-btn ant-btn-background-ghost ant-btn-primary'}
                                    >
                                        {t('shipment.modal_import_packages')}
                                    </a>
                                </div>
                            )}

                        <Table
                            className={`custom-empty custom-table-expand-row ${
                                listData.find(x => x.code === null) && 'hidden-expand-row'
                            }`}
                            rowKey={'code'}
                            columns={columns}
                            dataSource={_.sortBy(listData, 'createdAt').reverse()}
                            expandedRowKeys={expandedRow}
                            expandedRowRender={this.renderExpandRow}
                            expandIcon={expand => {
                                return expand.expanded ? (
                                    <AntdIcon.DownOutlined
                                        onClick={e => expand.onExpand(expand.record, e)}
                                        type={expand.expanded ? 'down' : 'right'}
                                    />
                                ) : (
                                    <AntdIcon.RightOutlined onClick={e => expand.onExpand(expand.record, e)} />
                                )
                            }}
                            onExpand={(expanded, record) => {
                                if (expanded) {
                                    this.setState({
                                        expandedRow: [record.code],
                                    })
                                    // this.getOrderPackageMileStones(record.code)
                                } else {
                                    this.setState({
                                        expandedRow: [],
                                    })
                                }
                            }}
                            pagination={{
                                hideOnSinglePage: true,
                                pageSize: 9999,
                            }}
                        />
                        {listWaybills.length === 0 && listWaybillParcels.length === 0 && (
                            <Empty
                                image={require('../../resources/images/empty-image.png')}
                                description={<span>{t('waybill.list_empty')}</span>}
                            ></Empty>
                        )}
                    </div>
                </Spin>
                {this.renderModalImportFileExcel()}

                {this.state.onPrint && this.state.printData && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            background: 'white',
                            zIndex: 999999,
                            overflow: 'auto',
                        }}
                    >
                        <div
                            className={'button-back-print'}
                            onClick={() => this.setState({ onPrint: false })}
                        >
                            <AntdIcon.LeftOutlined type="left" /> {t('login.go_back')}
                        </div>
                        <iframe
                            id="ifmcontentstoprint"
                            style={{
                                height: '0px',
                                width: '0px',
                                position: 'absolute',
                            }}
                            title="jsx-a11y/iframe-has-title"
                        />
                        <div
                            id={'printarea'}
                            ref={ref => (this.componentPrintRef = ref)}
                        >
                            {this.renderPrint()}
                        </div>

                        <div
                            onClick={this.print}
                            className="btn-print"
                            style={{ right: 80, top: '40%', fontSize: 40, padding: 20, height: 'auto', borderRadius: 8 }}
                        >
                            <AntdIcon.PrinterOutlined type="printer" />
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default withNamespaces('translation')(TabWaybill)
