import React from 'react';
import UserServices from '../../services/user.service';

export default class TotalSkusInCart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalSkus: {
                quantity: 0
            },
        }
    }

    static getDerivedStateFromProps(nextProps, preState) {
        let nextState = {...preState};
        if (nextProps.totalSkus !== preState.totalSkus && nextProps.totalSkus !== undefined) {
            nextState.totalSkus = nextProps.totalSkus;
            return nextState;
        }
        return null;
    }

    componentDidMount() {
        this.getTotalSkusInCart();
    }

    /**
     * lấy ra số lượng skus trong giỏ
     */
    getTotalSkusInCart = () => {
        UserServices.getTotalSkusInCart((err, res) => {
            if (!err) {
                this.setState({
                    totalSkus: res
                })
            }
        })
    };

    render() {
        const {totalSkus} = this.state;

        return (<span
            className={'_header-total-product txt-color-black robotobold txt-size-h7 mgr5 mgl5'}>{totalSkus.quantity > 999 ? '999+' : totalSkus.quantity || 0}</span>)
    }
}