/* eslint-disable array-callback-return */
import React from 'react'
import _, { isEmpty } from 'lodash'
import { Empty } from 'antd'
import { withNamespaces } from 'react-i18next'
import { LocalStore } from '../../utils/LocalStore'
import UserService from '../../services/user.service'
import { moneyFormat } from '../../utils/stringUtils'
import Modal from '../commons/Modal'

class ModalMarkupRateGroups extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentLoggedUser: {},
        }
    }

    // scroll top khi bắt đầu load trang
    componentDidMount() {
        this.setState({
            currentProjectInfo: LocalStore.getInstance().read('currentProjectInfo') || {},
            currentLoggedUser: LocalStore.getInstance().read('currentLoggedUser') || {},
        })
        const m17Config = _.get(LocalStore.getInstance().read('currentProjectInfo'), 'tenantConfig.m24Config.enabled')
        const body = [
            {
                refId: 'payment',
                peerPaymentType: 'payment',
            },
        ]
        if (m17Config) {
            body.push({
                refId: 'taobao_global',
                peerPaymentType: 'taobao_global',
            })
        }
        this.getExchangeRateTbg(this.getMarkupRateGroups, body)
    }

    /**
     * lấy ra danh sách trạng thái của package
     */
    getMarkupRateGroups = () => {
        const { exchangeRate = {}, exchangeRateTbg, currentProjectInfo } = this.state
        const m17Config = _.get(currentProjectInfo,'tenantConfig.m24Config.enabled') 

        UserService.getMarkupRateGroups((err, res, headers) => {
            if (err === null) {
                let listExchangeRate = []
                if (Array.isArray(res.listMarkupRates)) {
                    res.listMarkupRates.map(item => {
                        let arr = []
                        try {
                            arr = JSON.parse(item.viewTemplate)
                        } catch (err) {
                            arr = []
                        }

                        if (Array.isArray(arr) && arr.length > 0) {
                            let currency = ''
                            if (item.exchangeRate) {
                                currency = item.exchangeRate.split('/')
                            }

                            arr.map((x, y) => {
                                let res = 0
                                if (item.scope && item.scope.toLocaleLowerCase() === 'value') {
                                    if (item?.exchangeRateSource !== 'black') {
                                        res = exchangeRateTbg?.rate + parseFloat(x?.price)
                                    } else {
                                        res = exchangeRate.rate + parseFloat(x?.price)
                                    }
                                }

                                if (item.scope && item.scope.toLocaleLowerCase() === 'rate') {
                                    if (item?.exchangeRateSource !== 'black') {
                                        res = exchangeRateTbg?.rate + exchangeRateTbg?.rate * (parseFloat(x?.price) / 100)
                                    } else {
                                        res = exchangeRate.rate + exchangeRate.rate * (parseFloat(x?.price) / 100)
                                    }
                                }

                                listExchangeRate.push({
                                    base: currency[0],
                                    value: res,
                                    currency: currency[1],
                                })
                            })
                        }
                    })
                }

                const markupRateGroupsCurrent = res;
                const filteredListMarkupRates = markupRateGroupsCurrent?.listMarkupRates?.filter(item => item?.exchangeRateSource !== "market");
                const markupRateGroupsNews = {
                    ...markupRateGroupsCurrent,
                    listMarkupRates: filteredListMarkupRates,
                };

                this.setState({
                    markupRateGroups: m17Config ? markupRateGroupsCurrent : markupRateGroupsNews,
                    listExchangeRate: _.sortBy(listExchangeRate, ['value']),
                })
            }
        })
    }

    /**
     * lấy ra danh sách trạng thái của package
     */
    // getExchangeRate = cb => {
    //     UserService.getExchangeRatePeerPayMent('', (err, res, headers) => {
    //         if (err === null) {
    //             this.setState(
    //                 {
    //                     exchangeRate: res || {},
    //                 },
    //                 () => {
    //                     if (cb) cb()
    //                 }
    //             )
    //         }
    //     })
    // }

    getExchangeRateTbg = (cb, body) => {
        UserService.getExchangeRatePeerPayMentTbg(body, (err, res, headers) => {
            if (err === null) {
                let exchangeRatePeerPayMent
                let exchangeRatePeerPayMentTbg
                res?.forEach(item => {
                    if (item.refId === 'payment') {
                        exchangeRatePeerPayMent = item.exchangeRate
                    } else {
                        exchangeRatePeerPayMentTbg = item.exchangeRate
                    }
                })

                this.setState(
                    {
                        exchangeRate: exchangeRatePeerPayMent || {},
                        exchangeRateTbg: exchangeRatePeerPayMentTbg || {},
                    },
                    () => {
                        if (cb) cb()
                    }
                )
            }
        })
    }

    renderPrice = price => <strong className="text-blue-primary text-sm robotomedium">{price}</strong>

    renderTableMarkupRates = (item, index) => {
        const { t, listPaymentMethods = [] } = this.props
        const { exchangeRate = {}, exchangeRateTbg } = this.state
        let arr = []

        try {
            arr = JSON.parse(item.viewTemplate)
        } catch (err) {
            arr = []
        }

        if (!Array.isArray(arr) || arr.length === 0) return <div key={index} />
        let currency = ''
        if (item.exchangeRate) {
            currency = item.exchangeRate.split('/')
        }

        let group = listPaymentMethods.find(x => x.code === item.paymentMethodCode) || {}

        return (
            <div
                key={index}
                className={`rangeExchangeItem`}
            >
                <div className="bg-color-white">
                    <div className="text-xs robotomedium text-blue-primary mb-3">
                        {group.name || '---'}{' '}
                        {item.paymentMethodCode === 'alipay' &&
                            (item?.exchangeRateSource === 'black' ? '(YCTTH 1688)' : '(YCTTH TBG)')}
                    </div>
                    {!Array.isArray(arr) || arr.length === 0 ? (
                        <div className="bg-color-white border-bottom-radius">
                            <Empty
                                image={require('../../resources/images/empty-image.png')}
                                description={t('message.empty')}
                            />
                        </div>
                    ) : (
                        <div className="flex flex-col gap-3 exchange-range-item">
                            {arr.map(i => {
                                let res = 0
                                if (item.scope && item.scope.toLocaleLowerCase() === 'value') {
                                    if (item?.exchangeRateSource !== 'black') {
                                        res = exchangeRateTbg?.rate + parseFloat(i?.price)
                                    } else {
                                        res = exchangeRate.rate + parseFloat(i?.price)
                                    }
                                }

                                if (item.scope && item.scope.toLocaleLowerCase() === 'rate') {
                                    if (item?.exchangeRateSource !== 'black') {
                                        res = exchangeRateTbg?.rate + exchangeRateTbg?.rate * (parseFloat(i?.price) / 100)
                                    } else {
                                        res = exchangeRate.rate + exchangeRate.rate * (parseFloat(i?.price) / 100)
                                    }
                                }

                                return (
                                    <div className="pb-3">
                                        <div className="flex justify-between mb-1">
                                            <span className="text-grey-400 robotoregular">{t('peer_payment.range')}</span>
                                            <span>
                                                {i.toAmount ? (
                                                    <span></span>
                                                ) : (
                                                    <span className="robotoregular">
                                                        Trên{' '}
                                                        <span className="text-blue-primary robotomedium">
                                                            {moneyFormat(i.fromAmount, currency[0])}
                                                        </span>
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                        <div className="flex justify-between">
                                            <span className="text-grey-400 robotoregular">{t('peer_payment.exchage_rate')}</span>
                                            <span className="robotoregular">{moneyFormat(res, currency[1])}</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>
            </div>
        )
    }

    renderModalAddProduct = () => {
        const { t } = this.props
        const { markupRateGroups = {}, listExchangeRate = [], exchangeRateTbg } = this.state
        const checkExchangeRateTbg = markupRateGroups?.listMarkupRates?.some(
            item => item?.paymentMethodCode === 'alipay' && item.exchangeRateSource !== 'black'
        )
        if (!listExchangeRate[0]) return <div />
        let firstItem = listExchangeRate[0]
        let lastItem = null
        for (let i = listExchangeRate.length - 1; i >= 0; i--) {
            if ( listExchangeRate[i].value > 0 ) {
                lastItem = listExchangeRate[i];
                break;
            }
        }

        let from = firstItem?.value 
        // > exchangeRateTbg?.rate ? exchangeRateTbg?.rate : firstItem?.value
        let to = lastItem?.value 
        //> exchangeRateTbg?.rate ? lastItem?.value : exchangeRateTbg?.rate

        let title =''
        if (from === to) {
            title =`${t('peer_payment.exchange_range')} : ${moneyFormat(1, firstItem.base)} = ${moneyFormat(
                exchangeRateTbg && checkExchangeRateTbg ? from : firstItem?.value,
                firstItem.currency
            )} `
        }else{
            title = `${t('peer_payment.exchange_range')} : ${moneyFormat(1, firstItem.base)} = ${moneyFormat(
                exchangeRateTbg && checkExchangeRateTbg ? from : firstItem?.value,
                firstItem.currency
            )} ${
                listExchangeRate?.length > 1 ? `- ${moneyFormat(exchangeRateTbg && checkExchangeRateTbg ? to : lastItem?.value, lastItem.currency)}` : ``
            }`
        }

        let currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {}
        const customerGroup = _.get(currentLoggedUser, 'customerGroup')

        return (
            <Modal
                className="modal-log-package-damage"
                title={<span className="capitalize">{title}</span>}
                visible={this.state.showModalAddProduct}
                onCancel={() =>
                    this.setState({
                        showModalAddProduct: false,
                    })
                }
                width={400}
                cancelText={t('modal.confirm')}
                okButtonProps={{ className: 'dpl-none' }}
                cancelButtonProps={{ className: '_btn-cancel-confirm' }}
                // bodyStyle={{
                //     height: "50vh",
                //     maxHeight: "50vh",
                //     overflowY: "scroll"
                // }}
            >
                <div>
                    {customerGroup?.displayName && (
                        <div className="robotomedium mb-3">Nhóm khách hàng: {customerGroup.displayName}</div>
                    )}
                    {Array.isArray(markupRateGroups.listMarkupRates) &&
                        markupRateGroups.listMarkupRates.map((item, index) => {
                            let arr = []
                            try {
                                arr = JSON.parse(item.viewTemplate)
                            } catch (err) {
                                arr = []
                            }
                            if (isEmpty(arr)) return
                            return this.renderTableMarkupRates(item, index)
                        })}
                </div>
            </Modal>
        )
    }

    render() {
        const { t } = this.props
        const { listExchangeRate = [], markupRateGroups, exchangeRateTbg } = this.state
        const checkExchangeRateTbg = markupRateGroups?.listMarkupRates?.some(
            item => item?.paymentMethodCode === 'alipay' && item.exchangeRateSource !== 'black'
        )
        if (!listExchangeRate[0]) return <div />
        let firstItem = listExchangeRate[0]
        let lastItem = null
        for (let i = listExchangeRate.length - 1; i >= 0; i--) {
            if ( listExchangeRate[i].value > 0 ) {
                lastItem = listExchangeRate[i];
                break;
            }
        }

        let from = firstItem?.value 
        let to = lastItem?.value
        // > exchangeRateTbg?.rate ? exchangeRateTbg?.rate : firstItem?.value
        // > exchangeRateTbg?.rate ? lastItem?.value : exchangeRateTbg?.rate

        return (
            <div className="dpl-il-block">
                {firstItem?.value > 0 ? <div
                    onClick={() => {
                        this.setState({ showModalAddProduct: true })
                    }}
                >
                    <span className="text-grey-200 hover:text-blue-primary robotoregular underline cursor-pointer">
                        {t('peer_payment.exchange_range')} : {moneyFormat(1, firstItem.base)} ={' '}
                        {from === to ?
                        <>
                            {moneyFormat(exchangeRateTbg && checkExchangeRateTbg ? from : firstItem.value, firstItem.currency)}
                        </>:
                        <>
                            {moneyFormat(exchangeRateTbg && checkExchangeRateTbg ? from : firstItem.value, firstItem.currency)}
                            {listExchangeRate?.length > 1 &&
                                ` - ${moneyFormat( exchangeRateTbg && checkExchangeRateTbg ? to : lastItem?.value, lastItem.currency)}`}
                        </>}
                    </span>
                </div>:null}

                {this.renderModalAddProduct()}
            </div>
        )
    }
}
export default withNamespaces()(ModalMarkupRateGroups)
