import React, { ReactNode } from 'react'
import { Table } from "antd"
import { TableProps } from "antd/es/table"
import { TableRowSelection } from 'antd/lib/table/interface'

interface ITableCommon extends TableProps<any> {
    isLoading?: boolean
    actions?: ReactNode
    hidePagination?: boolean
    rowSelectionCustom?: TableRowSelection<any>
    onChangePage?: (page: any, pageSize: any) => void
    tableTitle?: ReactNode
    dataTable: any
}

const TableCommon: React.FC<ITableCommon> = ({
    isLoading,
    rowSelectionCustom,
    onChangePage = () => null,
    tableTitle,
    className = '',
    columns = [],
    dataTable = [],
    ...props
}) => {
    return <Table
        {...props}
        className="table-common-antd"
        columns={columns}
        dataSource={dataTable}
        loading={isLoading}
        bordered={false}
        rowClassName={(record: any, index: number): string => `_row-order cursor-pointer ${index % 2 !== 0 ? 'tr-even row_bg' : ''}`}
    />
}

export default TableCommon