/* eslint-disable array-callback-return */
export const convertFlatArrayToTree = (arr) => {
    let tree = [],
        mappedArr = {},
        arrElem,
        mappedElem;

    // First map the nodes of the array to an object -> create a hash table.
    for(let i = 0, len = arr.length; i < len; i++) {
        arrElem = arr[i];
        mappedArr[arrElem.id] = arrElem;
        mappedArr[arrElem.id]['children'] = [];
    }


    for (let id in mappedArr) {
        if (mappedArr.hasOwnProperty(id)) {
            mappedElem = mappedArr[id];
            // If the element is not at the root level, add it to its parent array of children.
            if (mappedElem.parent) {
                if (mappedArr[mappedElem['parent'].id])
                    mappedArr[mappedElem['parent'].id]['children'].push(mappedElem);
            }
            // If the element is at the root level, add it to first level elements array.
            else {
                tree.push(mappedElem);
            }
        }
    }
    return tree;
};
/**
 * cắt mảng
 * @param list
 * @param chunkSize
 * @returns {Array}
 */
export const chunk = function(list, chunkSize) {
    if (!list.length) {
        return [];
    }
    if (typeof chunkSize === "undefined") {
        chunkSize = 10;
    }

    var i, j, t, chunks = [];
    for (i = 0, j = list.length; i < j; i += chunkSize) {
        t = list.slice(i, i + chunkSize);
        chunks.push(t);
    }

    return chunks;
};

/**
 * sort array by key
 * @param array
 * @param key
 * @returns {*}
 */
export const sortByKey = (array, key) => {
    return array.sort(function(a, b) {
        let x = a[key]; let y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
};

/**
 * thay đổi cấu trúc của datasource Order
 * @param obj
 * @returns {Array}
 */
export const modifyArrayDataSource = (obj) => {
    if (!obj || !Array.isArray(obj.products)) return [];

    let newArray = [];

    obj.products.map(x => {
        x.skus.map(y => {
            newArray.push({...y, product: x, dataSourceId: obj.id})
        })
    });

    return newArray
};
