import {useState} from 'react';
import {Input, Modal} from "antd";

const PinConfirmModal = (props) => {
    const [value, setValue] = useState('');
    const {visible, loading, passwordError, t, onSubmit, onCancel} = props;
    return (
        <Modal
            title={<span className={'txt-color-blue'}>{t('modal.confirm_pin')}</span>}
            visible={visible}
            onOk={() => onSubmit(value)}
            confirmLoading={loading}
            onCancel={onCancel}
            okText={t('cartCheckout.confirm')}
            cancelText={t('cartCheckout.cancel')}
            okButtonProps={{
                className: "_btn-submit-confirm buttonm",
                icon: <span className={'mgr5'}><i className="fal fa-check"></i></span>,
                disabled: !value
            }}
            cancelButtonProps={{className: "_btn-cancel-confirm buttonm buttonm__cancel"}}
        >
            <Input.Password
                className={'_input-password'}
                placeholder={t('orderDetail.input_pin')}
                autoFocus={true}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        onSubmit(value);
                    }
                }}
            />

            {
                passwordError && <p className="_txt-error-confirm mgt5 mgbt0 txt-error ">{passwordError}</p>
            }
            {/*<span className='mgt10 txt-color-gray txt-size-h8'>{t('cartCheckout.default_pin')}</span>*/}

            {/*<Checkbox*/}
            {/*    className={'_check-box-confirm mgt10'}*/}
            {/*    onChange={() => this.setState({savePassword: !savePassword})}*/}
            {/*    checked={savePassword}*/}
            {/*>*/}
            {/*    {t('modal.save_password_60m')}*/}
            {/*</Checkbox>*/}
        </Modal>
    );
}
export default PinConfirmModal;
